interface IpdbBin {
  binSize: number | string | null;
  lessThan: number | null;
  greaterThan: number | null;
}

interface MinMax {
  min: number;
  max: number;
}

interface BinValues {
  bin: number;
  min: number;
  max: number;
  diff: number;
  steps: number;
  numOfColors: number;
}

export function calculateBinValues(ipdbBin: IpdbBin, minMax: MinMax): BinValues {
  let bin = ipdbBin?.binSize;
  let min = ipdbBin?.lessThan;
  let max = ipdbBin?.greaterThan;
  bin = typeof bin === "string" ? parseFloat(bin) : bin;
  min = typeof min === "string" ? parseFloat(min) : min;
  max = typeof max === "string" ? parseFloat(max) : max;

  // If the min or max values are not provided, we will use the min and max values from the data.
  // Note: This is to be consistent with other parts of the app like the focus/group by bins.
  const isBinLessThanValueANumber = !isNaN(min) && min != null;
  const isBinGreaterThanValueANumber = !isNaN(max) && max != null;
  min = isBinLessThanValueANumber ? min : minMax?.min;
  max = isBinGreaterThanValueANumber ? max : minMax?.max;

  const diff = max - min;
  const minSteps = diff / 4;
  const binDoesNotProduceMinimumSteps =
    bin > minSteps && !ipdbBin?.greaterThan && !ipdbBin?.lessThan;
  if (Math.abs(bin) < 1e-10 || binDoesNotProduceMinimumSteps) {
    if (diff > 0) {
      bin = diff / 7;
    } else {
      bin = 1.0;
    }
  }

  if (isNaN(bin)) {
    return null;
  }

  bin = bin ?? 1.0;

  const steps = bin;
  if (steps === 0 || diff === 0) {
    return null;
  }

  let numOfColors = Math.ceil(diff / steps);

  // Add 1 if lessThan is provided because we have an extra bin for "< min"
  if (isBinLessThanValueANumber) {
    numOfColors = numOfColors + 1;
  }

  // Add 1 if greaterThan is provided because we have an extra bin for ">= max"
  if (isBinGreaterThanValueANumber) {
    numOfColors = numOfColors + 1;
  }

  if (!isBinLessThanValueANumber) {
    min = min + steps;
  }

  return { bin, min, max, diff, steps, numOfColors };
}
