import { IFilterState } from "../../../../models/filter";

/**
 * Determines if the new filter state only has polygon filter changes, such as map movement.
 * Omits the polygon filter from the filter state, and then compares to the filter history.
 *
 * Created to address bug 821. We do not want to add map movement to the filter history stack,
 * and be able to "undo" those map movements.
 * https://turinganalytics.atlassian.net/jira/software/c/projects/EVA/boards/1?modal=detail&selectedIssue=EVA-821
 * @param {string[]} filterHistoryJSON the query to get the predicate models for.
 * @param {IFilterState} filterState The list of counts for the predicates.
 * @returns {IPredicateModel} The predicate model representation of the query.
 */
const hasOnlyPolygonFilterChanges = (
  filterHistoryJSON: string[],
  filterState: IFilterState
): boolean => {
  if (!filterHistoryJSON.length) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterStateOmitted = (({ polygonFilter, ...omittedFilterState }) =>
    omittedFilterState)(filterState);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const lastFilterStateOmitted = (({ polygonFilter, ...omittedFilterState }) =>
    omittedFilterState)(JSON.parse(filterHistoryJSON[filterHistoryJSON.length - 1]));

  return JSON.stringify(filterStateOmitted) === JSON.stringify(lastFilterStateOmitted);
};
export default hasOnlyPolygonFilterChanges;
