import axios from "axios";

const orgDataSourceServiceRoot = process.env.REACT_APP_ORGANIZATION_DATASOURCE_SVC;

export interface DataSourceModel {
  hasSource: boolean;
  isActive: boolean;
  name: string;
  order: number;
  canRemove: boolean;
  source: string;
  dataSourceKey: string;
}

export interface DataSourceAccess {
  dataSourceKey: string;
  dataTypesAccess: DataTypeAccessResult[];
}

export interface DataTypeAccessResult {
  dataType: "Frac" | "Pden" | "Survey" | "Header";
  wellCount: number;
}

export interface UserDataSourcePreferenceItem {
  dataSourceName: string;
  isActive: boolean;
  order: number;
  source: string;
}

export interface UserDataSourcePreferenceCategory {
  category: string;
  dataSources: UserDataSourcePreferenceItem[];
}

export interface Ok<T> {
  ok: boolean;
  value: T;
}
export interface Error<E = string> {
  ok: false;
  error: E;
}

export type Result<T, E = string> = Ok<T> | Error<E>;

export interface IProdViewData {
  username: string;
  password: string;
  subscriptionKey: string;
}

export interface IGdcInputModel {
  username: string;
  password: string;
  subscriptionKey: string;
}

export async function addProdViewCredential(
  payload: IProdViewData
): Promise<Result<boolean>> {
  try {
    const response = await axios.post(`${orgDataSourceServiceRoot}/prodView`, {
      Password: payload.password,
      SubscriptionKey: payload.subscriptionKey,
      Username: payload.username
    });
    if (response.status !== 200) {
      return {
        ok: false,
        error: response.data
      };
    }
    return {
      ok: true,
      value: response.data
    };
  } catch (error) {
    return {
      ok: false,
      error: error.response?.data ?? ""
    };
  }
}
export interface IGdcDataModel {
  username: string;
  password: string;
  host: string;
  serviceName: string;
}

export interface CredentialModel {
  username?: string;
  password: string;
  host?: string;
  serviceName?: string;
  email?: string;
  dataSource: string;
}

export interface ShaleProfileModel {
  email: string;
  password: string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function addShaleProfileCredential(
  orgId: string,
  payload: ShaleProfileModel
): Promise<Result<boolean>> {
  try {
    const response = await axios.post(
      `${orgDataSourceServiceRoot}/organizationDataSource/${orgId}/shaleprofile`,
      payload
    );
    if (response.status !== 200) {
      return {
        ok: false,
        error: response.data
      };
    }
    return {
      ok: true,
      value: response.data
    };
  } catch (error) {
    return {
      ok: false,
      error: error.response?.data ?? ""
    };
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function addGeoscoutCredential(
  orgId: string,
  payload: IGdcDataModel
): Promise<Result<boolean>> {
  try {
    const response = await axios.post(
      `${orgDataSourceServiceRoot}/organizationDataSource/credential/${orgId}/gdc`,
      {
        host: payload.host,
        organizationId: orgId,
        password: payload.password,
        serviceName: payload.serviceName,
        source: "Geoscout",
        username: payload.username
      }
    );
    if (response.status !== 200) {
      return {
        ok: false,
        error: response.data
      };
    }
    return {
      ok: true,
      value: response.data
    };
  } catch (error) {
    const response = {
      ok: false,
      error: error.response?.data?.title
        ? "Invalid credentials. Please try again."
        : error.response?.data
        ? error.response?.data?.toString()
        : "We're sorry, an error ocurred."
    } as Result<boolean>;
    return response;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function addCredential(
  orgId: string,
  source: string,
  payload: CredentialModel
): Promise<Result<DataSourceAccess>> {
  try {
    const response = await axios.post(
      `${orgDataSourceServiceRoot}/organizationDataSource/credential/${orgId}/${source}`,
      {
        host: payload.host,
        organizationId: orgId,
        password: payload.password,
        serviceName: payload.serviceName,
        source: "Geoscout",
        username: payload.username
      }
    );
    if (response.status !== 200) {
      return {
        ok: false,
        error: response.data
      };
    }
    return {
      ok: true,
      value: response.data
    };
  } catch (error) {
    return {
      ok: false,
      error: error.response?.data ?? ""
    };
  }
}

export async function saveUserDataSourcePreference(
  preference: UserDataSourcePreferenceCategory
): Promise<Result<boolean>> {
  try {
    const response = await axios.post<boolean>(
      `${orgDataSourceServiceRoot}/user-data-source/`,
      preference
    );
    if (response.status === 200) {
      return {
        ok: true,
        value: response.data
      };
    } else {
      return {
        ok: false,
        error: "error occurred"
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: err.response?.data ?? ""
    };
  }
}

export async function fetchUserDataSources(): Promise<
  Result<UserDataSourcePreferenceCategory[]>
> {
  try {
    const response = await axios.get<UserDataSourcePreferenceCategory[]>(
      `${orgDataSourceServiceRoot}/user-data-source/`
    );
    if (response.status === 200) {
      return {
        ok: true,
        value: response.data
      };
    } else {
      return {
        ok: false,
        error: "error occurred"
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: err.response?.data ?? ""
    };
  }
}

export async function fetchDataSources(
  orgId: string
): Promise<Result<DataSourceModel[]>> {
  try {
    const response = await axios.get<DataSourceModel[]>(
      `${orgDataSourceServiceRoot}/organizationDataSource/${orgId}`
    );
    if (response.status === 200) {
      return {
        ok: true,
        value: response.data
      };
    } else {
      return {
        ok: false,
        error: "error occurred"
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: err.response?.data ?? ""
    };
  }
}

export async function deleteDataSource(
  orgId: string,
  source: string
): Promise<Result<boolean>> {
  try {
    const response = await axios.delete<boolean>(
      `${orgDataSourceServiceRoot}/organizationDataSource/${orgId}/${source}`
    );
    if (response.status === 200) {
      return {
        ok: true,
        value: response.data
      };
    } else {
      return {
        ok: false,
        error: "error occurred"
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: err?.response?.data
    };
  }
}

export async function getDataSourceAccess(orgId: string, dataSourceKey: string) {
  return await axios.get<DataSourceAccess>(
    `${orgDataSourceServiceRoot}/organizationDataSource/${orgId}/datasource/${dataSourceKey}`
  );
}

export async function getAccessibleDataSources(orgId: string) {
  try {
    const response = await axios.get<DataSourceModel[]>(
      `${orgDataSourceServiceRoot}/organizationDataSource/column/${orgId}`
    );
    if (response.status === 200) {
      return {
        ok: true,
        value: response.data
      };
    } else {
      return {
        ok: false,
        error: "error occurred"
      };
    }
  } catch (err) {
    return {
      ok: false,
      error: err.response?.data ?? ""
    };
  }
}
