import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { IGroupBy, INormalizeBySetting } from "models";
import { PdenTimeStepT } from "models/chart";
import { PdenSourceT } from "models/pdenDataSourceSetting";

import { EntityKind } from "../models/entityKind";

export interface GlobalProductionSettings {
  normalizeBy?: INormalizeBySetting;
  source: PdenSourceT;
  timeStep: PdenTimeStepT;
  groupBy: IGroupBy;
}

const useGlobalProductionSettings = (
  entityKind: EntityKind
): GlobalProductionSettings => {
  const normalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const { timeStep, source } = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );
  const groupBy = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.groupBy.globalGroupBy
      : state.groupBy.globalFacilityFocus
  );

  return useMemo(() => {
    return {
      normalizeBy: useNormalizeBy ? normalizeBy : null,
      source,
      timeStep,
      groupBy
    };
  }, [useNormalizeBy, normalizeBy, source, timeStep, groupBy]);
};

export default useGlobalProductionSettings;
