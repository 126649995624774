import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_SHAPEFILES_QUERY_KEYS } from "components/project/shapefiles/queries";

export interface IUseSortProjectShapefileInFolderMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
  projectId: string;
}

export type SortProjectShapefileInFolderParamsT = {
  shapefileNodeId: string;
  order: string;
};
async function SortShapefilesInFolder(params) {
  {
    let shapefileNodeId = params.shapefileNodeId;
    if (shapefileNodeId === "") {
      shapefileNodeId = "root";
    }
    const url = `${projectServiceEndpoint}/shapefile/sort/${params.projectId}/${shapefileNodeId}/${params.order}`;
    return axios.put(url);
  }
}

export function useSortProjectShapefilesInFolderMutation({
  onError,
  onMutate,
  onSettled,
  projectId
}: IUseSortProjectShapefileInFolderMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: SortProjectShapefileInFolderParamsT) =>
      SortShapefilesInFolder({ ...params, projectId }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
