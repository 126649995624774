import { memo } from "react";

function IWB() {
  return (
    <svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1">
      <defs>
        <path d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z" id="path_1331" />
        <path d="M0 0L7.02574 0L7.02574 11.2422L0 11.2422L0 0Z" id="path_212" />
        <clipPath id="mask_1">
          <use href="#path_1331" />
        </clipPath>
        <clipPath id="mask_2">
          <use href="#path_212" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPIWB">
        <g id="Group-2-Copy-2" transform="translate(4.607739 -1.1368684E-13)">
          <path
            d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z"
            id="Mask-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <g id="Group-2" transform="translate(0 1.2340614)">
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                id="Stroke-1-Copy-5"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                transform="translate(0 4.0531893)"
                id="Stroke-4-Copy-4"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
            </g>
          </g>
        </g>
        <g id="Group-7-Copy-5" transform="translate(20.764282 13.396588)">
          <path
            d="M2.70318 0C2.92296 3.76904 -0.208724 3.77485 0.0110584 7.54389"
            transform="translate(0 1.1181614)"
            id="Stroke-1-Copy-6"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M2.89278 1.80883e-17C3.88457 4.96573 -0.566641 4.8551 0.0609129 10.4272"
            transform="translate(3.3843336 0)"
            id="Stroke-3-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M2.70318 0C2.92296 3.76904 -0.208724 3.77485 0.0110584 7.54389"
            transform="translate(6.9757495 1.7725434)"
            id="Stroke-5-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M3.83595 0L3.83595 3.83595L2.12938e-16 3.83595"
            transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 4.5181155 9.7026415)"
            id="Rectangle-Copy-15"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
        <path
          d="M0 26.5666L27 0"
          transform="translate(2.5311532 1.9575263)"
          id="Path-8-Copy-5"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <path
          d="M6.02899 12.058C9.3587 12.058 12.058 9.3587 12.058 6.02899C12.058 2.69927 9.3587 0 6.02899 0C2.69927 0 0 2.69927 0 6.02899C0 9.3587 2.69927 12.058 6.02899 12.058Z"
          transform="translate(0 17.899551)"
          id="Oval-Copy-18"
          fill="#FFFFFF"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Group-5-Copy" transform="translate(2.515969 18.619246)">
          <path
            d="M0 5.198L2.82 5.198L1.989 9.378L5.861 4.18L3.041 4.18L3.872 0L0 5.198Z"
            transform="translate(0.58251846 0.93201697)"
            id="Fill-1-Copy-2"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
          <g id="Group-4">
            <path
              d="M0 0L7.02574 0L7.02574 11.2422L0 11.2422L0 0Z"
              id="Clip-3-Copy"
              fill="none"
              fillRule="evenodd"
              stroke="none"
            />
            <g clipPath="url(#mask_2)">
              <path
                d="M5.861 4.18L3.042 4.18L3.872 0L0 5.198L2.82 5.198L1.989 9.378L5.861 4.18Z"
                transform="translate(0.58221847 0.932117)"
                id="Stroke-2-Copy"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinejoin="bevel"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(IWB);
