import { memo } from "react";

import classnames from "classnames";
import styled from "styled-components/macro";

export type UserMenuItemT = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

function UserMenuItem({ children, onClick = null }) {
  const wrapperClassNames = classnames({ "no-pointer": !onClick });

  return (
    <Wrapper className={wrapperClassNames} role="button" tabIndex={0} onClick={onClick}>
      {children}
    </Wrapper>
  );
}

export default memo(UserMenuItem);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.8rem;
  background-color: var(--white);
  transition: background-color var(--duration-short) var(--ease);
  padding: 0 var(--space-5);
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: var(--color-text-06);
  }

  &.no-pointer {
    pointer-events: none;
  }
`;
