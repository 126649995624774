import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { useScreenshotContext } from "components/screenshot/hooks";
import { applyAbbreviations } from "components/screenshot/utils";
import { useUserSettings } from "components/user/hooks";

import { EntityKind } from "../../../models/entityKind";
import { getChart } from "../charts/getChart";
import { useChartState } from "../context";
import { applyRemoveUUID } from "../utils";
import { addNormalizationBasisToTitle } from "../utils/addNormalizationBasisToTitle";

const useChartLegendTitle = (): string => {
  const chartState = useChartState();
  // state from store
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const globalGroupBy = useSelector((state: RootState) =>
    chartState.entityKind == EntityKind.Well
      ? state.groupBy.globalGroupBy
      : state.groupBy.globalFacilityFocus
  );

  const { userAbbreviations } = useUserSettings();
  const screenshotState = useScreenshotContext();

  // state
  const [value, setValue] = useState<string>("");

  const abbreviations = userAbbreviations?.abbreviations ?? [];

  useEffect(() => {
    const legendTitle =
      chartState.entityKind == EntityKind.Well
        ? txnId.legend.title
        : txnId.facilityLegend.title;
    const chart = getChart(chartState.settings.chartType);
    if (chart?.getCustomLegendTitle(chartState)) {
      setValue(chart.getCustomLegendTitle(chartState));
      return;
    }

    if (!legendTitle) {
      return;
    }

    const title = chartState.settings.useChartFocus
      ? chartState.settings.groupBy.title
      : globalGroupBy.title;
    const isForecastEnabled = title.includes("Forecast");

    let nextTitle = isForecastEnabled ? applyRemoveUUID(title) : title;
    nextTitle = chartState.settings.useNormalizeBy
      ? addNormalizationBasisToTitle(title, chartState.settings.normalizeBy)
      : nextTitle;

    const formattedTitle =
      chartState.screenshot && screenshotState?.settings?.applyAbbreviations
        ? applyAbbreviations(nextTitle, abbreviations)
        : nextTitle;

    setValue(formattedTitle);
  }, [
    globalGroupBy,
    txnId.legend.title,
    chartState.settings?.useChartFocus,
    chartState.settings?.groupBy?.title,
    chartState.settings.useNormalizeBy,
    chartState.settings.normalizeBy,
    chartState.screenshot,
    abbreviations
  ]);

  return value;
};

export default useChartLegendTitle;
