import { memo } from "react";

function OilCut() {
  return (
    <svg width="30px" height="32px" viewBox="0 0 30 32">
      <defs>
        <path d="M0 0L13.6955 0L13.6955 8.85703L0 8.85703L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use href="#path_1" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPOil-Cut">
        <path
          d="M5.06893 13.7617C7.86842 13.7617 10.1379 11.4922 10.1379 8.69274C10.1379 5.89324 5.06893 1.95565e-13 5.06893 1.95565e-13C5.06893 1.95565e-13 0 5.89324 0 8.69274C0 11.4922 2.26944 13.7617 5.06893 13.7617Z"
          transform="translate(0.2557837 1.1368684E-13)"
          id="Oval-Copy-24"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 26.5666L27 0"
          id="Path-8-Copy-12"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <g
          id="IconsChart---PRPOil-+-Cond-Copy"
          transform="translate(15.373693 11.420574)">
          <path
            d="M7.26179 6.22664C7.26179 8.23193 5.63618 9.85754 3.63089 9.85754C1.62561 9.85754 0 8.23193 0 6.22664C0 4.22135 3.63089 0 3.63089 0C3.63089 0 7.26179 4.22135 7.26179 6.22664ZM11.5872 4.81009C11.5872 5.45907 11.0611 5.98517 10.4122 5.98517C9.7632 5.98517 9.2371 5.45907 9.2371 4.81009C9.2371 4.16112 10.4122 2.79496 10.4122 2.79496C10.4122 2.79496 11.5872 4.16112 11.5872 4.81009ZM10.0234 8.21921C10.0234 8.86818 9.49726 9.39428 8.84828 9.39428C8.19931 9.39428 7.67321 8.86818 7.67321 8.21921C7.67321 7.57023 8.84828 6.20407 8.84828 6.20407C8.84828 6.20407 10.0234 7.57023 10.0234 8.21921ZM13.2273 8.21921C13.2273 8.86818 12.7012 9.39428 12.0522 9.39428C11.4032 9.39428 10.8771 8.86818 10.8771 8.21921C10.8771 7.57023 12.0522 6.20407 12.0522 6.20407C12.0522 6.20407 13.2273 7.57023 13.2273 8.21921Z"
            transform="matrix(1 0 0 1 1.8189894E-12 0)"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
        <g id="Group-2-Copy-8" transform="translate(15.373693 22.7385)">
          <path
            d="M0 0L13.6955 0L13.6955 8.85703L0 8.85703L0 0Z"
            id="Mask-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <g id="Group-2" transform="translate(0 1.1655965)">
              <path
                d="M18.4578 1.37283C17.5269 1.37283 16.6597 1.09699 15.935 0.622561C14.6656 -0.20752 13.0214 -0.20752 11.752 0.622561C11.0267 1.09699 10.1602 1.37283 9.22923 1.37283C8.29763 1.37283 7.43115 1.09699 6.70578 0.622561C5.4364 -0.20752 3.7922 -0.20752 2.52281 0.622561C1.79809 1.09699 0.930969 1.37283 0 1.37283"
                id="Stroke-1-Copy-5"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="1.8375"
              />
              <path
                d="M18.4578 1.37283C17.5269 1.37283 16.6597 1.09699 15.935 0.622561C14.6656 -0.20752 13.0214 -0.20752 11.752 0.622561C11.0267 1.09699 10.1602 1.37283 9.22923 1.37283C8.29763 1.37283 7.43115 1.09699 6.70578 0.622561C5.4364 -0.20752 3.7922 -0.20752 2.52281 0.622561C1.79809 1.09699 0.930969 1.37283 0 1.37283"
                transform="translate(0 3.828321)"
                id="Stroke-4-Copy-4"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="1.8375"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(OilCut);
