// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiDelete } from "@mdi/js";
import { Alert, Button, Input, Popconfirm, Row } from "antd";
import styled from "styled-components";

import { useColumns } from "hooks";

import { ColorPicker } from "components/base";
import { GeomBinProvider } from "components/geom-bin/contexts/GeomBinContext";
import GroupBy from "components/groupBy";

import { useRefreshColorPalettes } from "./hooks";
import { PreferredColor } from "./models/UserPreferredColors";

const PreferredColors = ({
  preferredColorsFromPalette,
  selectedPalette,
  isNewPalette
}) => {
  const columns = useColumns();
  const { refreshColorPalettes, isSuccess, isError, mutationError } =
    useRefreshColorPalettes();

  const [currentText, setCurrentText] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#000000");
  const [currentColumn, setCurrentColumn] = useState(
    columns.find((c) => "Header.ResourcePlay" === c.property)
  );
  const [currentProperty, setCurrentProperty] = useState("Header.ResourcePlay");
  const [error, setError] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateMutationError: any = mutationError;

  async function onSavePreferredColor(
    text?: string,
    color?: string,
    type: "add" | "update" = "add"
  ) {
    if (type === "update" && selectedPalette?.preferredColors) {
      const preferredColor = selectedPalette.preferredColors.find(
        (x) => x.value === text
      );
      if (preferredColor) {
        preferredColor.color = color;
      }
      refreshColorPalettes(selectedPalette);
      return;
    }

    if (
      !selectedPalette?.preferredColors ||
      !currentText ||
      !currentColor ||
      !currentProperty
    ) {
      return;
    }

    if (
      selectedPalette.preferredColors.find(
        (x) => x.value === currentText && x.property === currentProperty
      )
    ) {
      setError("Preferred color with the same name and focus field already exists");
      return;
    }

    selectedPalette.preferredColors.push({
      color: currentColor,
      value: currentText,
      property: currentProperty
    });
    refreshColorPalettes(selectedPalette);
    setCurrentText("");
    setCurrentColor("#000000");
    setError("");
  }

  async function handleDeletePreferredColor(color: PreferredColor) {
    if (!selectedPalette) return;
    selectedPalette.preferredColors = selectedPalette.preferredColors.filter(
      (x) => !(x.value === color.value && x.property === color.property)
    );
    refreshColorPalettes(selectedPalette);
  }

  return (
    <GeomBinProvider>
      <Wrapper>
        <SectionContainer>
          <p className="info">Target text must be an exact match to focus bin text.</p>
          {preferredColorsFromPalette &&
            preferredColorsFromPalette.map((preferredColor, i) => {
              return (
                <PreferredColorEntry key={i}>
                  <GroupBy
                    excludedCategoryFilters={["Shapefile"]}
                    value={columns.find((c) => preferredColor.property === c.property)}
                    editable={false}
                    isDisabled={true}
                    onChange={() => {
                      //
                    }}>
                    <StyledButton>
                      {columns.find((c) => preferredColor.property === c.property)?.title}
                    </StyledButton>
                  </GroupBy>
                  <CurrentText>
                    <Input value={preferredColor.value} disabled={true} />
                  </CurrentText>
                  <ColorPicker
                    values={{
                      color: preferredColor.color,
                      strokeColor: preferredColor.color,
                      thickness: 0,
                      opacity: 1
                    }}
                    hasOutline={false}
                    onChange={(_, newColor) => {
                      onSavePreferredColor(preferredColor.value, newColor, "update");
                    }}
                    includeOpacity={false}
                  />
                  <PreferredColorsActions>
                    <Popconfirm
                      onConfirm={() => handleDeletePreferredColor(preferredColor)}
                      title={`Please confirm you wish to delete the preferred color for ${preferredColor.value}.`}>
                      <DeleteButton
                        shape="circle"
                        type="text"
                        icon={<Icon path={mdiDelete} size={1.5} />}
                      />
                    </Popconfirm>
                  </PreferredColorsActions>
                </PreferredColorEntry>
              );
            })}
          {!isNewPalette && (
            <PreferredColorEntry>
              <GroupBy
                excludedCategoryFilters={["Shapefile"]}
                value={currentColumn}
                onChange={(groupBy) => {
                  setCurrentProperty(groupBy.property);
                  setCurrentColumn(columns.find((c) => groupBy.property === c.property));
                }}>
                <StyledButton>{currentColumn.title}</StyledButton>
              </GroupBy>
              <PreferredColorCurrent>
                <Input
                  placeholder="Target text"
                  onChange={(e) => setCurrentText(e.target.value)}
                  value={currentText}
                />
              </PreferredColorCurrent>
              <ColorPicker
                values={{
                  color: currentColor,
                  strokeColor: currentColor,
                  thickness: 0,
                  opacity: 1
                }}
                hasOutline={false}
                onChange={(_, color) => {
                  setCurrentColor(color);
                }}
                includeOpacity={false}
              />
              <AddPreferredColorWrapper>
                <Button type="text" onClick={() => onSavePreferredColor()}>
                  + Add
                </Button>
              </AddPreferredColorWrapper>
            </PreferredColorEntry>
          )}
          <ContentRow>
            {isSuccess && (
              <Alert
                type="success"
                closable
                banner
                message={`Successfully updated ${selectedPalette?.name ?? ""}`}></Alert>
            )}
            {isError && (
              <Alert
                type="error"
                closable
                banner
                message={
                  <div>{updateMutationError?.response?.data ?? updateMutationError}</div>
                }
              />
            )}
          </ContentRow>
        </SectionContainer>
        {isNewPalette && (
          <Alert type="info" message="Save palette to add preferred colours" />
        )}
        {error && <Alert type="error" closable banner message={<div>{error}</div>} />}
      </Wrapper>
    </GeomBinProvider>
  );
};

export default PreferredColors;
const SectionContainer = styled.div``;
const AddPreferredColorWrapper = styled.div``;
const Wrapper = styled.div`
  ${SectionContainer}:not(:last-child) {
    padding-bottom: 18px;
  }
`;
const PreferredColorsActions = styled.div`
  opacity: 0;
`;
const PreferredColorCurrent = styled.div``;

const PreferredColorEntry = styled.div`
  width: 500px;
  display: grid;
  align-items: center;
  vertical-align: middle;
  gap: 12px;
  grid-template-columns: 180px 180px 20px 70px;
  padding: 3px;

  .ant-input {
    min-height: 30px;
    font-size: 14px;
  }
  &:hover ${PreferredColorsActions} {
    opacity: 1;
  }
`;
const CurrentText = styled.div``;

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a2aaad;

  &:hover {
    color: #ff4d4f;
  }
`;
const StyledButton = styled(Button)`
  max-width: 180px;
  width: 180px;

  & > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ContentRow = styled(Row)`
  padding: 5px;
  padding-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  .ant-card {
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }

  .interopolate-title {
    gap: 4px;
    display: flex;
    align-items: center;
  }
`;
