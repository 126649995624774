import { productStyles } from "components/multiphase-chart/constants/shared.constants";
import {
  IProductStyles,
  ISeriesStyle
} from "components/multiphase-chart/models/shared.models";

const getDefaultProductStylesMergedWithCustomStyles = (
  customStyles: ISeriesStyle[]
): IProductStyles => {
  if (!customStyles?.length) return productStyles;

  const newStyles: IProductStyles = {};

  customStyles.forEach((style) => {
    newStyles[style.product] = {
      color: style.color,
      width: parseInt(style.width as unknown as string),
      dashed: style.dashed
    };
  });

  return { ...productStyles, ...newStyles };
};

export default getDefaultProductStylesMergedWithCustomStyles;
