import { ReactNode, useEffect, useState } from "react";

import { Subscription } from "rxjs";

import { useProjectContext } from "../context";
import { useProjectInitialization } from "../hooks";

let projectSub: Subscription = null;

export interface ProjectWrapperModel {
  children: ReactNode;
}

/**
 * Used to initialize the project context. With the way things are set up in the Home.tsx,
 * loading it here instead passing the ProjectProvider an initial value (essentially what's in the useEffect),
 * allows the layout to be unnaffected by the project context not being fully initialized.
 */
export default function ProjectWrapper({ children }: ProjectWrapperModel): JSX.Element {
  // state variables
  const [, setRefreshTxn] = useState<string>(null);

  // hooks
  const { projectRefreshObserver } = useProjectContext();
  useProjectInitialization();

  // Monitor project refresh requests.
  useEffect(() => {
    if (projectRefreshObserver && !projectSub) {
      projectSub = projectRefreshObserver.subscribe((refreshTxn: string) => {
        setRefreshTxn(refreshTxn);
      });
    }
    return () => {
      if (projectSub) {
        projectSub.unsubscribe();
        projectSub = null;
      }
    };
  }, [projectRefreshObserver]);

  return <>{children}</>;
}
