import { FC, useContext, useState } from "react";

import { Divider, Typography } from "antd";
import styled from "styled-components";

import { CollapseContext } from "./Collapse";
import Toggle from "./Toggle";

const { Text } = Typography;

type CollapsePanelT = {
  children?: React.ReactNode;
  name: string;
  extra?: React.ReactNode;
  loading?: boolean;
  title?: string | React.ReactNode;
  toggleTooltipText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const CollapsePanel: FC<CollapsePanelT> = ({
  children = null,
  name,
  extra = null,
  loading = false,
  title = "",
  toggleTooltipText = "Toggle",
  ...props
}) => {
  const { active, setActive } = useContext(CollapseContext);

  // derived state
  const isActive = active.includes(name);
  const titleMarkup =
    typeof title === "string" ? <StyledText strong>{title}</StyledText> : title;

  // local state
  const [open, setOpen] = useState(isActive);

  // handlers
  function handleToggle(value: boolean) {
    setOpen(value);
    value ? setActive([...active, name]) : setActive(active.filter((k) => k !== name));
  }

  return (
    <Wrapper {...props}>
      <Head>
        {title && titleMarkup}
        {extra && extra}
        {children && (
          <Toggle
            checked={open}
            loading={loading}
            tooltipText={toggleTooltipText}
            onToggle={handleToggle}
          />
        )}
      </Head>

      <StyledDivider />
      <Body hidden={!open}>
        {children}
        <StyledDivider />
      </Body>
    </Wrapper>
  );
};

export default CollapsePanel;

const Wrapper = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const Head = styled.div`
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin-right: 12px;
  white-space: nowrap;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const Body = styled.div`
  min-height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  overflow-y: overlay;
`;
