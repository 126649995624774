import axios from "axios";

import { IGroupByListColumn, IGroupByListItem } from "../../../models";

const rootUrl = process.env.REACT_APP_COLUMN_SET_SERVICE;

const userColumnUrl = `${process.env.REACT_APP_DATA_ROOT}/api/v1/user-defined-column`;
const GeomBinPrefix = "GeomBin";
const OrgDefinedColumnPropertyPrefix = "Org_Focus_Fields";

export const fetchOrgDefinedColumns = async (): Promise<IGroupByListItem> => {
  try {
    let orgList = null;

    const orgDefinedColumnsResponse = await axios.get(`${rootUrl}/org-defined-columns`);

    if (orgDefinedColumnsResponse.status === 200) {
      orgList = orgDefinedColumnsResponse.data;
    }

    if (orgList) {
      const orgGeomBinsResponse = await axios.get(`${userColumnUrl}/geombins?isOrg=true`);

      if (orgGeomBinsResponse.status == 200 && orgGeomBinsResponse.data) {
        const columns = orgGeomBinsResponse.data.map((bin) => {
          return {
            title: bin.name,
            id: bin.id,
            canBin: false,
            dataType: "Text",
            canNormalize: false,
            group: GeomBinPrefix,

            subgroup: bin.group,
            property: `${OrgDefinedColumnPropertyPrefix}_${GeomBinPrefix}_${btoa(
              bin.group
            )}_${bin.id}`,
            fieldType: "geom",
            withinSubgroupOrder: bin.withinSubgroupOrder
          } as IGroupByListColumn;
        });

        orgList.columns = orgList.columns.concat(columns);
      }
    }

    return orgList;
  } catch {
    //ignore
  }
};
