import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "store/rootReducer";
import { checkEmail } from "utils";

import { sendPasswordResetEmail } from "api/auth";

import { BaseButton, BaseInput, Heading } from "components/base";
import FieldErrorMessage from "components/base/FieldErrorMessage";
import { IconSpinner } from "components/icons";

import "./ForgotPassword.scss";

export default function ForgotPassword() {
  const data = {
    emailLabel: "Email address",
    passwordLabel: "password",
    buttonLabel: "Login"
  };
  const [headerTitle, setHeaderTitle] = useState("Password Recovery");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [isBusy, setBusy] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user, history]);
  function checkForm() {
    if (!checkEmail(email)) {
      setErrors("Please enter a valid email");
    }
  }

  function sendResetEmail(event) {
    event.preventDefault();
    checkForm();
    setErrors("");
    setBusy(true);
    sendPasswordResetEmail(
      email,
      () => {
        setResetSent(true);
        setHeaderTitle("Your reset request has been sent!");
        setBusy(false);
      },
      () => {
        setBusy(false);
        setErrors(
          "We're sorry. We've received an error. Please check that the email address is valid."
        );
      }
    );
  }
  return (
    <div className="content-container">
      <div>
        <Heading element="h3" className="align-center medium">
          {headerTitle}
        </Heading>
        {!resetSent && (
          <>
            <form name="login" className="forgot-form" onSubmit={sendResetEmail}>
              <div>You will receive an email with a password reset link.</div>
              <fieldset className="fieldset">
                <BaseInput
                  type="email"
                  value={email}
                  label={data.emailLabel}
                  required
                  onChange={setEmail}
                  placeholder={"email@gmail.com"}
                />

                <div className="flex items-center justify-center">
                  <BaseButton type="submit" appearance="primary" className="justify-end">
                    Reset Password
                  </BaseButton>
                </div>
              </fieldset>
              {errors.length > 0 && <FieldErrorMessage message={errors} />}
            </form>
          </>
        )}
        {resetSent && (
          <fieldset className="fieldset">
            <div>Password recovery link has been sent to your email.</div>{" "}
            <div className="flex items-center justify-center full-width">
              <BaseButton
                appearance="primary"
                className="justify-end"
                onClick={() => {
                  history.push("/login");
                }}>
                Back to Login
              </BaseButton>
            </div>
          </fieldset>
        )}
        <div className={`busy-indicator ${!isBusy ? "hidden" : ""}`}>
          <IconSpinner></IconSpinner>
        </div>
      </div>
    </div>
  );
}
