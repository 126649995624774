import { memo } from "react";

function CrossPlot() {
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
      <g id="Iconschart-typeCross-plot" transform="translate(1.125 1.125)">
        <g id="dot-plot">
          <path
            d="M0 0L0 20L20 20"
            transform="translate(0 2)"
            id="Path-3-Copy-3"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M2.35885 4.75772C3.6616 4.75772 4.7177 3.69267 4.7177 2.37886C4.7177 1.06505 3.6616 0 2.35885 0C1.05609 0 0 1.06505 0 2.37886C0 3.69267 1.05609 4.75772 2.35885 4.75772Z"
            transform="translate(3.9385996 13.035954)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
          <path
            d="M2.35885 4.75772C3.6616 4.75772 4.7177 3.69267 4.7177 2.37886C4.7177 1.06505 3.6616 0 2.35885 0C1.05609 0 0 1.06505 0 2.37886C0 3.69267 1.05609 4.75772 2.35885 4.75772Z"
            transform="translate(5.511683 6.439674)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
          <path
            d="M2.3604 4.76085C3.66401 4.76085 4.7208 3.6951 4.7208 2.38042C4.7208 1.06575 3.66401 0 2.3604 0C1.05679 0 0 1.06575 0 2.38042C0 3.6951 1.05679 4.76085 2.3604 4.76085Z"
            transform="translate(8.901653 10.924894)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
          <path
            d="M2.35885 4.75772C3.6616 4.75772 4.7177 3.69267 4.7177 2.37886C4.7177 1.06505 3.6616 0 2.35885 0C1.05609 0 0 1.06505 0 2.37886C0 3.69267 1.05609 4.75772 2.35885 4.75772Z"
            transform="translate(11.263608 4.757716)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
          <path
            d="M2.3604 4.76085C3.66401 4.76085 4.7208 3.6951 4.7208 2.38042C4.7208 1.06575 3.66401 0 2.3604 0C1.05679 0 0 1.06575 0 2.38042C0 3.6951 1.05679 4.76085 2.3604 4.76085Z"
            transform="translate(17.48606 4.757716)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
          <path
            d="M2.3604 4.76085C3.66401 4.76085 4.7208 3.6951 4.7208 2.38042C4.7208 1.06575 3.66401 0 2.3604 0C1.05679 0 0 1.06575 0 2.38042C0 3.6951 1.05679 4.76085 2.3604 4.76085Z"
            transform="translate(14.552641 9.513866)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
          <path
            d="M2.35885 4.75772C3.6616 4.75772 4.7177 3.69267 4.7177 2.37886C4.7177 1.06505 3.6616 0 2.35885 0C1.05609 0 0 1.06505 0 2.37886C0 3.69267 1.05609 4.75772 2.35885 4.75772Z"
            transform="translate(14.555746 0)"
            id="Oval"
            fill="currentColor"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(CrossPlot);
