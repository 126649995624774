import { IProductStyles } from "../models/shared.models";

export const tabs = Object.freeze({
  chartSettings: "Chart Settings",
  primary: "Primary",
  secondary: "Secondary"
});

// Master list of colors
const colors = {
  gas: "#9c1717",
  oil: "#1c8017",
  water: "#163494",
  boe: "#ffa500",
  gor: "#2f4f4f",
  wgr: "#2e8b57",
  "total fluid": "#29AAB1",
  "oil cut": "#7fff00",
  "water cut": "#4169e1",
  isor: "#174c80",
  cgr: "#c961fa",
  csor: "#f08080",
  cwb: "#da70d6",
  iwb: "#d8bfd8",
  "gas inj": "#eee8aa",
  "steam inj": "#98fb98",
  "water inj": "#98fb98",
  wor: "#ff00ff",
  "1+wor": "#0000ff"
};

// Use for future additions to forecast color if desired
// const lightenColors = (color: string) => lightenColorByPercentage(color, 25);
// Currently forecast/backfit keys do not mapping to the chart

export const productStyles: IProductStyles = {
  gas: {
    color: colors.gas,
    width: 2,
    dashed: false
  },
  "gas-fcst": {
    color: colors.gas,
    width: 2,
    dashed: true
  },
  "gas-backfit": {
    color: colors.gas,
    width: 2,
    dashed: false
  },
  oil: {
    color: colors.oil,
    width: 2,
    dashed: false
  },
  "oil-fcst": {
    color: colors.oil,
    width: 2,
    dashed: true
  },
  "oil-backfit": {
    color: colors.oil,
    width: 2,
    dashed: false
  },
  water: {
    color: colors.water,
    width: 2,
    dashed: false
  },
  "water-fcst": {
    color: colors.water,
    width: 2,
    dashed: true
  },
  "water-backfit": {
    color: colors.water,
    width: 2,
    dashed: false
  },
  boe: {
    color: colors.boe,
    width: 2,
    dashed: false
  },
  "boe-fcst": {
    color: colors.boe,
    width: 2,
    dashed: true
  },
  "boe-backfit": {
    color: colors.boe,
    width: 2,
    dashed: false
  },
  gor: {
    color: colors.gor,
    width: 2,
    dashed: false
  },
  "gor-fcst": {
    color: colors.gor,
    width: 2,
    dashed: true
  },
  "gor-backfit": {
    color: colors.gor,
    width: 2,
    dashed: false
  },
  wgr: {
    color: colors.wgr,
    width: 2,
    dashed: false
  },
  "wgr-fcst": {
    color: colors.wgr,
    width: 2,
    dashed: true
  },
  "wgr-backfit": {
    color: colors.wgr,
    width: 2,
    dashed: false
  },
  "total fluid": {
    color: colors["total fluid"],
    width: 2,
    dashed: false
  },
  "total fluid-fcst": {
    color: colors["total fluid"],
    width: 2,
    dashed: true
  },
  "total fluid-backfit": {
    color: colors["total fluid"],
    width: 2,
    dashed: false
  },
  "oil cut": {
    color: colors["oil cut"],
    width: 2,
    dashed: false
  },
  "oil cut-fcst": {
    color: colors["oil cut"],
    width: 2,
    dashed: true
  },
  "oil cut-backfit": {
    color: colors["oil cut"],
    width: 2,
    dashed: false
  },
  "water cut": {
    color: colors["water cut"],
    width: 2,
    dashed: false
  },
  "water cut-fcst": {
    color: colors["water cut"],
    width: 2,
    dashed: true
  },
  "water cut-backfit": {
    color: colors["water cut"],
    width: 2,
    dashed: false
  },
  isor: {
    color: colors.isor,
    width: 2,
    dashed: false
  },
  "isor-fcst": {
    color: colors.isor,
    width: 2,
    dashed: true
  },
  "isor-backfit": {
    color: colors.isor,
    width: 2,
    dashed: true
  },
  cgr: {
    color: colors.cgr,
    width: 2,
    dashed: false
  },
  "cgr-fcst": {
    color: colors.cgr,
    width: 2,
    dashed: true
  },
  "cgr-backfit": {
    color: colors.cgr,
    width: 2,
    dashed: true
  },
  csor: {
    color: colors.csor,
    width: 2,
    dashed: false
  },
  "csor-fcst": {
    color: colors.csor,
    width: 2,
    dashed: true
  },
  "csor-backfit": {
    color: colors.csor,
    width: 2,
    dashed: false
  },
  cwb: {
    color: colors.cwb,
    width: 2,
    dashed: false
  },
  "cwb-fcst": {
    color: colors.cwb,
    width: 2,
    dashed: true
  },
  "cwb-backfit": {
    color: colors.cwb,
    width: 2,
    dashed: false
  },
  iwb: {
    color: colors.iwb,
    width: 2,
    dashed: false
  },
  "iwb-fcst": {
    color: colors.iwb,
    width: 2,
    dashed: true
  },
  "iwb-backfit": {
    color: colors.iwb,
    width: 2,
    dashed: false
  },
  "gas inj": {
    color: colors["gas inj"],
    width: 2,
    dashed: false
  },
  "gas inj-fcst": {
    color: colors["gas inj"],
    width: 2,
    dashed: true
  },
  "gas inj-backfit": {
    color: colors["gas inj"],
    width: 2,
    dashed: false
  },
  "steam inj": {
    color: colors["steam inj"],
    width: 2,
    dashed: false
  },
  "steam inj-fcst": {
    color: colors["steam inj"],
    width: 2,
    dashed: true
  },
  "steam inj-backfit": {
    color: colors["steam inj"],
    width: 2,
    dashed: false
  },
  "water inj": {
    color: colors["water inj"],
    width: 2,
    dashed: false
  },
  "water inj-fcst": {
    color: colors["water inj"],
    width: 2,
    dashed: true
  },
  "water inj-backfit": {
    color: colors["water inj"],
    width: 2,
    dashed: false
  },
  wor: {
    color: colors.wor,
    width: 2,
    dashed: false
  },
  "wor-fcst": {
    color: colors.wor,
    width: 2,
    dashed: true
  },
  "wor-backfit": {
    color: colors.wor,
    width: 2,
    dashed: false
  },
  "1+wor": {
    color: colors["1+wor"],
    width: 2,
    dashed: false
  },
  "1+wor-fcst": {
    color: colors["1+wor"],
    width: 2,
    dashed: true
  },
  "1+wor-backfit": {
    color: colors["1+wor"],
    width: 2,
    dashed: false
  }
};

export const McfToBcf = 0.000001;
export const bblMcfToMbblMmcf = 0.001;

export const UNFOCUSED_OPACITY = 0.5;
export const HOVER_LINE_WIDTH = 6;
