import { FC, memo, useEffect, useRef } from "react";

import classnames from "classnames";
import styled from "styled-components";

import IconSpinner from "../icons/IconSpinner";
import "./BaseButton.scss";

type AppearanceT =
  | "default"
  | "danger"
  | "none"
  | "outline"
  | "plain"
  | "primary"
  | "stroked"
  | "subtle"
  | "warning";

type BaseButtonT = {
  autoFocus?: boolean;
  appearance?: AppearanceT;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  isDisabled?: boolean;
  iconSize?: number;
  isLoading?: boolean;
  isSelected?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  className?: string;
  ellipses?: boolean;
  noTabIndex?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const BaseButton: FC<BaseButtonT> = ({
  autoFocus = false,
  appearance = "default",
  iconBefore = null,
  iconAfter = null,
  isDisabled = false,
  iconSize = 16,
  isLoading = false,
  isSelected = false,
  fullWidth = false,
  children = null,
  className = "",
  ellipses = true,
  noTabIndex = false,
  type = "button",
  onClick,
  ...rest
}) => {
  const ref = useRef(null);

  // autofocus button
  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus]);

  function shouldInvert() {
    if (isSelected) {
      return true;
    }
    switch (appearance) {
      case "danger":
      case "primary":
      case "warning":
        return true;
      default:
        return false;
    }
  }

  // derived classes
  const buttonClasses = classnames(
    className,
    {
      button: true,
      "base-button": true,
      fullWidth,
      isDisabled,
      isLoading,
      isSelected
    },
    appearance
  );
  const labelClasses = classnames({ label: true, ellipses });
  const loaderClassNames = classnames({
    "absolute-center": true,
    invert: shouldInvert()
  });
  const tabIndex = isDisabled || noTabIndex ? -1 : 0;

  return (
    <button
      ref={ref}
      className={buttonClasses}
      type={type}
      tabIndex={tabIndex}
      onClick={onClick}
      {...rest}>
      {isLoading ? (
        <LoadingIndicator className={loaderClassNames}>
          <IconSpinner size={iconSize} />
        </LoadingIndicator>
      ) : (
        <>
          {!!iconBefore && <IconBeforeWrapper>{iconBefore}</IconBeforeWrapper>}
          {children && <Label className={labelClasses}>{children}</Label>}
          {!!iconAfter && <IconAfterWrapper>{iconAfter}</IconAfterWrapper>}
        </>
      )}
    </button>
  );
};

export default memo(BaseButton);

const Label = styled.span`
  line-height: 3rem;
  display: flex;
  align-items: center;
`;

const IconBeforeWrapper = styled.span`
  display: inline-flex;
  margin-right: 3px;
`;
const IconAfterWrapper = styled.span`
  display: inline-flex;
  margin-left: 3px;
`;

const LoadingIndicator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
