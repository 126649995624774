import styled from "styled-components";

import { Tooltip } from "components/base";
import { useChartSettings } from "components/multiphase-chart/context";
import { setStyle } from "components/multiphase-chart/context/reducer";
import {
  getCurrentSeriesStyleByProductLabel,
  getDefaultSeriesStyleByProductLabel,
  modifySeriesStyles
} from "components/multiphase-chart/util/productStyles";
import { ColorEditor } from "components/shared";

export type PerformanceTypeT = "forecast" | "backfit";

interface IProductStyleSelector {
  isHidden: boolean;
  /**
   * Used to match the product key in product styles
   * E.g: Gas Forecast (Product Label) -> gas-fcst
   */
  productLabel: string;
}

const ProductStyleSelector: React.FC<IProductStyleSelector> = ({
  isHidden,
  productLabel
}) => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  if (isHidden) {
    return <LineOverlayFiller />;
  }

  const { currentPreset } = chartSettings;

  const defaultProductStyle = getDefaultSeriesStyleByProductLabel(productLabel);
  const currentStyle = getCurrentSeriesStyleByProductLabel(
    productLabel,
    currentPreset?.style
  );

  const onStyleChange = (color: string, thickness: number): void => {
    const currentStyles = currentPreset?.style?.length ? [...currentPreset.style] : [];

    const productStyleKeys = [
      getDefaultSeriesStyleByProductLabel(productLabel)?.product,
      getDefaultSeriesStyleByProductLabel(productLabel, "forecast")?.product,
      getDefaultSeriesStyleByProductLabel(productLabel, "backfit")?.product
    ];

    const newStyles = modifySeriesStyles(
      currentStyles,
      productStyleKeys,
      color,
      thickness
    );

    setStyle(chartSettingsDispatch, newStyles);
  };

  return (
    <ColorEditor
      defaultStyle={defaultProductStyle}
      color={currentStyle.color}
      thickness={currentStyle.width}
      onChange={onStyleChange}>
      <Tooltip title={`Customize Style`}>
        <LineOverlayWrapper
          onClick={(e) =>
            // Allows popover to work ontop of another button
            e.stopPropagation()
          }>
          <LineOverlay color={currentStyle.color} thickness={currentStyle.width} />
        </LineOverlayWrapper>
      </Tooltip>
    </ColorEditor>
  );
};

export default ProductStyleSelector;

// Created padding so the line is easy to click even if it is small
const LineOverlayWrapper = styled.div`
  width: 100%;
  padding-bottom: 15px;
  padding-top: 5px;
`;

const LineOverlay = styled.div`
  position: absolute;
  width: 100%;
  border-color: "grey";
  height: ${(props) => props.thickness || 2}px;
  background-color: ${(props) => props.color || "black"};
`;

// Solely keeps sizing consistent when the line is not available
const LineOverlayFiller = styled.div`
  height: 20px;
`;
