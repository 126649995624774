import { Icon } from "@mdi/react";
import React, { useEffect, useState } from "react";

import { mdiCircleOutline, mdiLasso, mdiSquareOutline } from "@mdi/js";
import { Dropdown } from "antd";
import classnames from "classnames";
import styled from "styled-components";

import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import { useMapContext } from "components/map/hooks/useMapContext";

import { useGeomBinDispatch } from "../hooks/useGeomBinDispatch";

export interface LassoSelectionComponentModel {
  drawRef;
  portalRef?: React.MutableRefObject<HTMLDivElement>;
}

export default function AddPolygon({
  portalRef,
  drawRef
}: LassoSelectionComponentModel): JSX.Element {
  const { mapbox } = useMapContext();

  const geomBinDispatch = useGeomBinDispatch();
  const { isLassoSelected, isActive: isGeomBinOpen } = useGeomBinContext();

  const [selectedShape, setSelectedShape] = useState("draw_polygon");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const shapeLabels = {
    draw_polygon: "Polygon",
    draw_rectangle: "Square",
    draw_circle: "Circle"
  };

  useEffect(() => {
    if (!mapbox) {
      return;
    }

    if (isLassoSelected) {
      mapbox.getCanvas().style.cursor = "crosshair";
      drawRef.current.changeMode(selectedShape);
    } else {
      mapbox.getCanvas().style.cursor = "pointer";
    }
  }, [isLassoSelected, mapbox, selectedShape]);

  useEffect(() => {
    if (!portalRef) {
      return;
    }
  }, [portalRef]);

  if (!mapbox || !portalRef?.current || !isGeomBinOpen) {
    return <></>;
  }

  const containerClassNames = classnames("icon-toggle lasso-toggle", {
    isActive: isLassoSelected
  });

  const handleDropdownSelect = (shape) => {
    if (selectedShape === shape && isLassoSelected) {
      geomBinDispatch({
        payload: {
          isLassoSelected: false
        }
      });
      setSelectedShape("");
      setIsDropdownVisible(false);
      return;
    }
    geomBinDispatch({
      payload: {
        isLassoSelected: true
      }
    });
    setSelectedShape(shape);
    setIsDropdownVisible(false);
  };

  const handleDropDownOpen = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const toggleLasso = () => {
    if (isLassoSelected) {
      geomBinDispatch({
        payload: {
          isLassoSelected: false
        }
      });
      drawRef.current.changeMode("simple_select");
    } else {
      geomBinDispatch({
        payload: {
          isLassoSelected: true
        }
      });
      drawRef.current.changeMode(selectedShape);
    }
  };

  const renderIcon = () => {
    let path;
    switch (selectedShape) {
      case "draw_polygon":
        path = mdiLasso;
        break;
      case "draw_circle":
        path = mdiCircleOutline;
        break;
      case "draw_rectangle":
        path = mdiSquareOutline;
        break;
      default:
        path = mdiLasso;
        break;
    }

    return <Icon path={path} className="activity-action-icon" size={1} />;
  };

  return (
    <div>
      <div
        className={`BaseTooltip`}
        data-tip={!isDropdownVisible ? "Lasso Selection" : null}>
        <StyledButton
          role="button"
          aria-pressed={isLassoSelected}
          className={containerClassNames}
          disabled={false}
          size={36}
          tabIndex={0}
          onClick={toggleLasso}>
          {renderIcon()}
        </StyledButton>
        <DropDownTrigger onClick={handleDropDownOpen}></DropDownTrigger>
      </div>
      <Dropdown
        overlayClassName="lasso-dropdown"
        menu={{
          onClick: (v) => handleDropdownSelect(v.key),
          selectedKeys: [selectedShape],
          items: [
            {
              key: "draw_polygon",
              icon: <Icon path={mdiLasso} className="activity-action-icon" size={1} />,
              label: shapeLabels["draw_polygon"]
            },
            {
              key: "draw_rectangle",
              icon: (
                <Icon path={mdiSquareOutline} className="activity-action-icon" size={1} />
              ),
              label: shapeLabels["draw_rectangle"]
            },
            {
              key: "draw_circle",
              icon: (
                <Icon path={mdiCircleOutline} className="activity-action-icon" size={1} />
              ),
              label: shapeLabels["draw_circle"]
            }
          ]
        }}
        trigger={["click"]}
        open={isDropdownVisible}
        onOpenChange={(visible) => setIsDropdownVisible(visible)}>
        <StyledDiv />
      </Dropdown>
    </div>
  );
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: var(--color-text);

  background-color: transparent;
  border: none;

  position: relative;
  padding: var(--space-2);
  transition: color var(--duration-short) var(--ease);
  user-select: none;

  &:hover:not(:disabled),
  &.isActive:not(:disabled) {
    color: var(--color-primary);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const DropDownTrigger = styled.button`
  position: absolute;
  bottom: 0;
  opacity: 0;
  right: 0;
  padding: 0px;
  cursor: pointer;
  margin: 0px;
  width: 10px;
  height: 10px;
`;

const StyledDiv = styled.div`
  width: 0px;
  height: 0px;
`;
