// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  mdiContentCopy,
  mdiDelete,
  mdiDotsVertical,
  mdiFolderMove,
  mdiPencil
} from "@mdi/js";
import { Input, List, Popconfirm, Popover, Select, Typography } from "antd";
import styled from "styled-components";

import { setPadScenario } from "../../store/features";
import {
  ActionListWrapper,
  ShapefileAction
} from "../project/layers/components/layer-managers/shared";
import { useProjectListQuery } from "../project/projects/queries";
import { ScenarioToastContainerId } from "./PadScenario";
import PadScenarioModal from "./PadScenarioModal";
import { IconButton } from "./ScenarioConfigurationTable";
import { usePadScenario } from "./contexts/usePadScenario";
import useCloneScenario from "./hooks/useCloneScenario";
import useDeleteScenario from "./hooks/useDeleteScenario";
import useMoveScenarioToProject from "./hooks/useMoveScenarioToProject";
import useGetScenarioList from "./hooks/useScenarioList";
import useScenarioSticks from "./hooks/useScenarioSticks";
import { Scenario } from "./models/scenario";

const Title = Typography.Title;

interface ScenarioListProps {
  title?: ReactNode;
}

export default function ScenarioList({ title }: ScenarioListProps) {
  const { data: scenarios, isLoading, isError } = useGetScenarioList();
  const { state, dispatch: scenarioDispatch } = usePadScenario();
  const { data: sticks } = useScenarioSticks(state.scenario?._id);
  const [showEditModal, setShowEditModal] = useState(false);
  const { mutateAsync: deleteScenario } = useDeleteScenario();
  const { mutateAsync: cloneScenario } = useCloneScenario();
  const { data: projects } = useProjectListQuery();
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (scenarios) {
      scenarioDispatch({ type: "SET_SCENARIO_LIST", payload: scenarios });
      if (scenarios.length > 0) {
        const currentScenario = scenarios.find(
          (s) => s.scenarioId === state.scenario.scenarioId
        );
        scenarioDispatch({
          type: "SET_SCENARIO",
          payload: currentScenario ?? scenarios[0]
        });
      }
    }
  }, [scenarios]);

  const handleClick = (item: Scenario) => {
    if (!item.scenarioId || item.scenarioId == state.scenario.scenarioId) {
      return;
    }
    scenarioDispatch({ type: "SET_SCENARIO", payload: item });
    dispatch(
      setPadScenario({
        scenarioId: item.scenarioId,
        play: state.scenario.play,
        showSticksOnMap: true
      })
    );
  };

  useEffect(() => {
    if (sticks) {
      dispatch(
        setPadScenario({
          scenarioId: state.scenario.scenarioId,
          showSticksOnMap: true,
          play: state.scenario.play,
          sticks: sticks
        })
      );
    }
  }, [sticks]);

  const ScenarioItem = (item: Scenario) => {
    const [moveToProjectId, setMoveToProjectId] = useState("");
    const [newScenarioId, setNewScenarioId] = useState("");
    const [popoverOpen, setPopoverOpen] = useState(false);
    const { mutateAsync: moveScenario } = useMoveScenarioToProject();

    const handleEdit = () => {
      //clicking on the edit button will set the scenario to the clicked item
      //show the edit modal
      setTimeout(() => setShowEditModal(true), 100);
    };
    const handleCloneScenario = async () => {
      if (!state.scenario.scenarioId) {
        return;
      }
      try {
        await cloneScenario({
          scenarioId: state.scenario._id.$oid,
          newScenarioId: newScenarioId
        });
      } catch (err) {
        toast.error("Failed to clone scenario", {
          containerId: ScenarioToastContainerId
        });
      }
    };
    const handleDelete = async (item: Scenario) => {
      if (!item.scenarioId) {
        return;
      }
      try {
        await deleteScenario(item._id);
        scenarioDispatch({
          type: "SET_SCENARIO",
          payload: {
            scenarioId: ""
          } as Scenario
        });
      } catch (err) {
        toast.error("Failed to delete scenario", {
          containerId: ScenarioToastContainerId
        });
      }
    };
    const handleMoveScenario = async () => {
      try {
        await moveScenario({
          scenarioId: state.scenario._id,
          projectId: moveToProjectId
        });
        scenarioDispatch({
          type: "SET_SCENARIO",
          payload: {
            scenarioId: ""
          } as Scenario
        });
      } catch (err) {
        toast.error("Failed to move scenario", {
          containerId: ScenarioToastContainerId
        });
      }
    };
    return (
      <ItemWrapper
        key={item.scenarioId}
        onClick={() => handleClick(item)}
        selected={item.scenarioId == state.scenario.scenarioId}>
        <ScenarioLabel title={`${item.scenarioId} - ${item.padName}`}>
          {item.scenarioId} - {item.padName}
        </ScenarioLabel>

        <ButtonGroup>
          <Popover
            overlayClassName={"popover-no-padding"}
            placement={"bottom"}
            open={popoverOpen}
            onOpenChange={setPopoverOpen}
            content={
              <ActionListWrapper style={{ minWidth: "130px" }}>
                <Popconfirm
                  onOpenChange={(open) => {
                    if (open) {
                      setTimeout(() => inputRef.current?.focus(), 0);
                    } else {
                      setNewScenarioId("");
                    }
                  }}
                  title={
                    <Input
                      autoFocus
                      onFocus={(e) => e.target.select()}
                      placeholder={"New Scenario Id"}
                      value={newScenarioId}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNewScenarioId(e.target.value);
                      }}
                    />
                  }
                  okText={"Clone"}
                  onConfirm={handleCloneScenario}>
                  <ShapefileAction onClick={() => setPopoverOpen(false)}>
                    <Icon path={mdiContentCopy} size={1} />
                    Clone
                  </ShapefileAction>
                </Popconfirm>
                <Popconfirm
                  okText={"Move"}
                  onConfirm={handleMoveScenario}
                  title={
                    <Select
                      value={moveToProjectId}
                      onChange={(value) => setMoveToProjectId(value)}
                      placeholder={"Select Project"}
                      style={{ width: "200px" }}
                      options={projects.map((p) => ({
                        label: p.projectName,
                        value: p.projectId
                      }))}
                    />
                  }>
                  <ShapefileAction onClick={() => setPopoverOpen(false)}>
                    <Icon path={mdiFolderMove} size={1} />
                    Move
                  </ShapefileAction>
                </Popconfirm>
                <ShapefileAction onClick={handleEdit}>
                  <Icon path={mdiPencil} size={1} />
                  Edit
                </ShapefileAction>
                <Popconfirm
                  placement="bottom"
                  onConfirm={async (evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    await handleDelete(item);
                  }}
                  okText="Delete"
                  okType="danger"
                  title={`Are you sure you want to delete scenario ${item.scenarioId}?`}>
                  <ShapefileAction danger onClick={() => setPopoverOpen(false)}>
                    <Icon path={mdiDelete} size={1} />
                    Delete
                  </ShapefileAction>
                </Popconfirm>
              </ActionListWrapper>
            }
            trigger={"click"}>
            <IconButton
              type="text"
              icon={<Icon path={mdiDotsVertical} size={1} />}
              title="Edit"
            />
          </Popover>
        </ButtonGroup>
      </ItemWrapper>
    );
  };

  const renderItem = (item: Scenario) => {
    return <ScenarioItem {...item} />;
  };

  if (isLoading) {
    return <RootContainer>Loading...</RootContainer>;
  }
  if (isError) {
    return <RootContainer>Error</RootContainer>;
  }

  return (
    <RootContainer>
      <TitleWrapper>{title ? title : <Title level={5}>Scenarios</Title>}</TitleWrapper>
      <List dataSource={state.scenarioList} renderItem={renderItem} />
      <PadScenarioModal
        open={showEditModal}
        mode={"edit"}
        scenario={state.scenario}
        onClose={() => setShowEditModal(false)}
      />
    </RootContainer>
  );
}

const RootContainer = styled.div`
  min-width: 200px;
  padding: 5px;
  height: 100%;
  overflow-y: auto;
  max-width: 200px;
`;
const ScenarioLabel = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const ButtonGroup = styled.div`
  display: flex;
  margin: auto;
  gap: 5px;
`;
const TitleWrapper = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemWrapper = styled.div`
  cursor: pointer;
  min-width: 120px;
  height: 45px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#f0f0f0" : "white")};
  transition: box-shadow 0.3s ease-in-out;
  z-index: 1;

  &:hover {
    box-shadow: 0 0 10px rgba(159, 169, 179, 0.5);
    z-index: 10; /* Ensure the hovered item is above others */
  }
`;
