import { Icon } from "@mdi/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { LockOpenOutlined, LockOutlined, SyncOutlined } from "@material-ui/icons";
import { mdiShieldSync, mdiShieldSyncOutline } from "@mdi/js";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Popover,
  Select,
  Switch,
  Tag,
  Tooltip
} from "antd";
import { IS_DEV_ENV } from "constants/app.constants";
import _sample from "lodash/sample";
import { getCategoryList, setSyncWells } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { SyncFill } from "components/icons";
import { useWellList } from "components/well-list/context";

const testWells = [
  "100042707713W600",
  "100132107713W600",
  "100131307714W600",
  "102080207813W600",
  "100023007713W600",
  "102160107813W600"
];

const { Option } = Select;
const signalRAddress = process.env.REACT_APP_SIGNAL_R_ADDRESS;

let connection: HubConnection = null;

interface OptionModel {
  value: string;
  label: string;
}

interface SyncModel {
  defaultJobs?: OptionModel[];
  currentSchema?: string;
}

export default function Sync(model: SyncModel) {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user);

  const [wellListState] = useWellList();

  const [currentSchema, setCurrentSchema] = useState(model.currentSchema ?? "");
  const [forecastRescat, setForecastRescat] = useState("2111");
  const [isJobLocked, setIsJobLocked] = useState(false);
  const [isSynced, setSyncState] = useState(false);
  const [schemas, setSchemas] = useState(model.defaultJobs ?? []);
  const [showWellsWithin, setShowWellsWithin] = useState(false);
  const [useFcstFromSyncDb, setUseFcstFromSyncDb] = useState(true);
  const [usePdenFromSyncDb, setUsePdenFromSyncDb] = useState(true);
  const [useSuperSync, setUseSuperSync] = useState(false);

  const [columnStatus, setColumnStatus] = useState({
    LastUpdated: null
  });

  const [fcstStatus, setFcstStatus] = useState({
    LastUpdated: null
  });

  const [loadingStatus, setLoadingStatus] = useState({
    column: false,
    fcst: false,
    pden: false,
    performance: false,
    superSync: false
  });

  const [performanceStatus, setPerformanceStatus] = useState({
    LastUpdated: null
  });

  const [pdenStatus, setPdenStatus] = useState({
    LastUpdated: null
  });

  const [xMiles, setXMiles] = useState(
    localStorage.getItem("xMiles") ? parseFloat(localStorage.getItem("xMiles")) : 5
  );
  const [isPerformancePending, setIsPerformancePending] = useState(false);

  const centroidRef = useRef(null);
  const forecastRescatRef = useRef(forecastRescat);
  const isJobLockedRef = useRef(isJobLocked);
  const isSyncedRef = useRef(isSynced);
  const schemaRef = useRef(currentSchema);
  const selectedEntitiesRef = useRef([]);
  const showWellsWithinRef = useRef(showWellsWithin);
  const useFcstFromSyncDbRef = useRef(useFcstFromSyncDb);
  const usePdenFromSyncDbRef = useRef(usePdenFromSyncDb);
  const xMilesRef = useRef(xMiles);

  useEffect(() => {
    isJobLockedRef.current = isJobLocked;
  }, [isJobLocked]);

  const updatePdenMutation = useMutation(
    () => {
      setLoadingStatus(Object.assign({}, loadingStatus, { pden: true }));
      return sendMessage("UpdatePden", user.email, currentSchema);
    },
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  const updateFcstMutation = useMutation(
    () => {
      setLoadingStatus(Object.assign({}, loadingStatus, { fcst: true }));
      if (currentSchema) {
        return sendMessage("UpdateFcst", user.email, currentSchema);
      }
      return null;
    },
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  const calculatePerformanceMutation = useMutation(
    () => {
      if (currentSchema) {
        if (loadingStatus.pden || loadingStatus.fcst) {
          setIsPerformancePending(true);
          const fieldsToUpdate = [];

          if (loadingStatus.pden) {
            fieldsToUpdate.push("Pden");
          }
          if (loadingStatus.fcst) {
            fieldsToUpdate.push("Fcst");
          }

          if (fieldsToUpdate.length > 0) {
            const fieldsToUpdateMessage = `Updating: ${fieldsToUpdate.join(" and ")}.
            Performance sync will start when completed.`;
            toast.info(fieldsToUpdateMessage);
          }
        } else {
          executePerformance();
        }
      }
      return null;
    },
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  const superSyncMutation = useMutation(
    () => {
      setLoadingStatus(Object.assign({}, loadingStatus, { superSync: true }));

      const filter = {
        Uwis: wellListState.wells.map((x) => x.id) ?? []
      };

      return sendMessage("SuperSync", user.email, currentSchema, JSON.stringify(filter));
    },
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  // Ensure performance is updated after fcst and pden have been updated if performance is in pending state
  useEffect(() => {
    if (isPerformancePending && !loadingStatus.fcst && !loadingStatus.pden) {
      executePerformance();
    }
  }, [loadingStatus.fcst, loadingStatus.pden, isPerformancePending]);

  const executePerformance = async () => {
    setIsPerformancePending(false);
    setLoadingStatus(Object.assign({}, loadingStatus, { performance: true }));
    await sendMessage("CalculatePerformance", user.email, currentSchema);
  };

  const updateColumnMutation = useMutation(
    () => {
      if (currentSchema) {
        setLoadingStatus(Object.assign({}, loadingStatus, { column: true }));
        return sendMessage("UpdateColumn", user.email, currentSchema);
      }
      return null;
    },
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  useEffect(() => {
    //add a time delay to ensure selectedEntitiesRef is the latwst
    setTimeout(() => {
      localStorage.setItem("xMiles", xMiles.toString());

      isSyncedRef.current = isSynced;
      xMilesRef.current = xMiles;
      showWellsWithinRef.current = showWellsWithin;
      usePdenFromSyncDbRef.current = usePdenFromSyncDb;
      forecastRescatRef.current = forecastRescat;
      useFcstFromSyncDbRef.current = useFcstFromSyncDb;
      const syncState = {
        activeWells: selectedEntitiesRef.current,
        isSync: isSynced,
        showWellsWithin,
        xMiles,
        usePdenFromClient: usePdenFromSyncDb,
        forecastRescat: forecastRescat,
        centroid: centroidRef.current,
        schema: schemaRef.current,
        useFcstFromClient: useFcstFromSyncDb
      };
      if (dispatch) {
        dispatch(setSyncWells(syncState));
      }
    }, 500);
  }, [
    xMiles,
    isSynced,
    showWellsWithin,
    usePdenFromSyncDb,
    useFcstFromSyncDb,
    forecastRescat,
    currentSchema,
    pdenStatus,
    fcstStatus,
    performanceStatus,
    columnStatus,
    dispatch
  ]);

  function onSyncStatusChange(status) {
    if (status === "Sync") {
      setSyncState(true);
    } else {
      setSyncState(false);
    }
  }

  const isForUser = useCallback(
    (email: string) => {
      return user.email === email;
    },
    [user.email]
  );

  const onEntitiesChanged = useCallback(
    (email: string, entities: string[], centroidJsonString: string) => {
      if (!isForUser(email)) {
        return;
      }
      // Created to test if the selected well is highlighting correctly between changes
      const filteredEntities = IS_DEV_ENV ? [_sample(testWells)] : entities;
      selectedEntitiesRef.current = filteredEntities;
      const centroid = JSON.parse(centroidJsonString);
      centroidRef.current = centroid;

      dispatch(
        setSyncWells({
          activeWells: filteredEntities,
          centroid,
          showWellsWithin: showWellsWithinRef.current,
          xMiles: xMilesRef.current,
          isSync: isSyncedRef.current,
          usePdenFromClient: usePdenFromSyncDbRef.current,
          forecastRescat: forecastRescatRef.current,
          useFcstFromClient: useFcstFromSyncDbRef.current,
          schema: schemaRef.current
        })
      );
    },
    [dispatch, isForUser]
  );

  useEffect(() => {
    if (dispatch) {
      dispatch(getCategoryList());
    }
  }, [currentSchema, dispatch]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.keyCode === 32 && useSuperSync) {
        if (loadingStatus.pden || loadingStatus.fcst || loadingStatus.performance) {
          // Ask user to try again if a bigger job is updating
          const fieldsToUpdate = [];

          if (loadingStatus.pden) {
            fieldsToUpdate.push("Pden");
          }
          if (loadingStatus.fcst) {
            fieldsToUpdate.push("Fcst");
          }
          if (loadingStatus.performance) {
            fieldsToUpdate.push("performance");
          }

          if (fieldsToUpdate.length > 0) {
            const fieldsToUpdateMessage = `Updating: ${fieldsToUpdate.join(" and ")}.
            Please try again later.`;
            toast.info(fieldsToUpdateMessage);
          }
        } else if (!loadingStatus.superSync) {
          // If super sync is already running, just skip this request
          superSyncMutation.mutate();
        }

        // Prevent further propagation
        event.preventDefault();
      }
    }

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [useSuperSync]);

  function onConnectionStart() {
    // eslint-disable-next-line no-console
    console.info("SignalR connection available", connection.state);
    connection.invoke("Connected", user.email);
    connection.invoke("FetchJobList", user.email);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function sendMessage(method, ...args: any[]): Promise<void> {
    try {
      if (connection.state !== "Connected") {
        await connection.start();
      }
      if (connection.state === "Connected") {
        return connection.invoke(method, ...args);
      } else {
        toast.error("Not connected to server.");
      }
    } catch (err) {
      toast.error("Unable to connect to sync server.");
    }
  }

  function onError(type: string, email: string, message: string) {
    if (!isForUser(email)) {
      return;
    }
    toast.error(message);

    setLoadingStatus(Object.assign({}, loadingStatus, { [`${type}`]: false }));
  }

  function onDone(type: string, email: string, message: string) {
    if (!isForUser(email)) {
      return;
    }
    toast.success(message);
    if (type === "column") {
      dispatch(getCategoryList());
    }
    setLoadingStatus(Object.assign({}, loadingStatus, { [`${type}`]: false }));
  }

  function onJobsLoaded(email: string, jobsJson: string) {
    if (!isForUser(email)) {
      return;
    }
    if (jobsJson.length === 0) {
      return;
    }
    const schemas = JSON.parse(jobsJson);
    const schemaOptions = schemas.map((schema) => ({
      label: schema.JobNumber,
      value: schema.SCHMA
    }));
    setSchemas(schemaOptions);
  }

  useEffect(() => {
    connection = new signalR.HubConnectionBuilder()
      //hardcoded because mcdan only
      .withUrl(signalRAddress, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    connection.on("done", onDone);
    connection.on("status", onSyncStatusChange);
    connection.on("entitiesChanged", onEntitiesChanged);
    connection.on("jobs", onJobsLoaded);
    connection.on("error", onError);
    connection.on(
      "schemaChanged",
      (
        email,
        schema,
        pdenMetadata,
        forecastMetadata,
        columnMetadata,
        performanceMetadata
      ) => {
        if (email != user.email) {
          return;
        }
        // if the current schema was the one that changed, update the metadata.
        let updateMetaData = schemaRef.current === schema;

        if (!isJobLockedRef.current) {
          setIsJobLocked(true);
          setCurrentSchema(schema);
          schemaRef.current = schema;

          // force the metadata to update
          updateMetaData = true;
        }

        if (updateMetaData) {
          if (pdenStatus.LastUpdated != pdenMetadata?.lastUpdated) {
            setPdenStatus({
              LastUpdated: pdenMetadata?.lastUpdated
            });
          }
          if (columnStatus.LastUpdated != columnMetadata?.lastUpdated) {
            setColumnStatus({
              LastUpdated: columnMetadata?.lastUpdated
            });
          }
          if (fcstStatus.LastUpdated != forecastMetadata?.lastUpdated) {
            setFcstStatus({
              LastUpdated: forecastMetadata?.lastUpdated
            });
          }
          if (performanceStatus.LastUpdated != performanceMetadata?.lastUpdated) {
            setPerformanceStatus({
              LastUpdated: performanceMetadata?.lastUpdated
            });
          }
        }
      }
    );

    connection.onclose(() => {
      if (isSyncedRef.current) {
        setSyncState(false);
        toast.error("Sync Disconnected. Please try syncing again.", {
          autoClose: 60000
        });
      }
    });

    connection
      .start()
      .then(onConnectionStart)
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        toast.error("Sync server unavailable");
        return false;
      });

    return () => {
      connection.stop();
      connection = null;
    };
  }, [onEntitiesChanged]);

  const getSettings = () => {
    return {
      Email: user.email,
      ShowWellsWithin: showWellsWithin,
      Miles: xMiles,
      Sync: isSynced,
      UsePden: usePdenFromSyncDb,
      UseFcst: useFcstFromSyncDb
    };
  };
  useEffect(() => {
    const settings = getSettings();
    if (connection && connection.state === "Connected") {
      connection.invoke("SyncSettings", user.email, JSON.stringify(settings));
    }
  }, [showWellsWithin, xMiles, usePdenFromSyncDb, useFcstFromSyncDb, isSynced]);

  function updateSyncState() {
    if (connection && connection.state !== "Connected") {
      connection && connection.start();
    }
    if (connection && connection.state === "Connected") {
      connection && connection.invoke("Sync", user.email, !isSynced);
      const settings = getSettings();
      connection &&
        connection.invoke("SyncSettings", user.email, JSON.stringify(settings));
      return;
    }
    toast.error("Unable to start connection to the server.");
  }

  function toDateTime(date: string): string {
    const dateObj = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(dateObj);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(dateObj);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(dateObj);
    const hr = new Intl.DateTimeFormat("en", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(dateObj);

    return `${mo}-${da}-${ye} ${hr}`;
  }

  function handleSchemaChange(val) {
    if (isJobLocked) {
      setIsJobLocked(false);
    }
    const schema = val;
    //send message to server that a new schema has been set
    connection.invoke("UserUpdateSchema", user.email, schema);
    if (schema) {
      setTimeout(() => {
        //add timeout here to give time for schema change to happen before locking the job
        //if timeout doesn't exist the lock will prevent the schema change
        setIsJobLocked(true);
      }, 1200);
    }
  }

  useHotkeys(
    "f",
    () => {
      updateFcstMutation.mutate();
    },
    []
  );

  return (
    <RootContainer>
      <Popover
        trigger="click"
        content={
          <OptionContainer>
            <OptionItem>
              <Tag color={currentSchema ? "green" : "red"}>
                {currentSchema ? currentSchema : "No Schema Selected"}
              </Tag>
              <Tag color={isSynced ? "green" : "orange"}>
                {isSynced ? "Synced" : "Not Synced"}
              </Tag>
              <Tag color={pdenStatus.LastUpdated ? "green" : "red"}>Pden</Tag>
              <Tag color={fcstStatus.LastUpdated ? "green" : "red"}>Fcst</Tag>
              <Tag color={columnStatus.LastUpdated ? "green" : "red"}>Column</Tag>
            </OptionItem>
            <Divider></Divider>
            <OptionItem>
              <SchemaWrapper>
                <div>Job</div>
                <Select
                  value={currentSchema}
                  options={schemas}
                  data-testid="sync-job-select"
                  popupClassName="modal-select"
                  onChange={handleSchemaChange}
                  filterOption={(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch></Select>
                <Switch
                  data-testid="sync-lock-switch"
                  title="Prevent selected job from changing when sync is on."
                  checkedChildren={
                    <LockIconWrapper>
                      <LockOutlined />
                    </LockIconWrapper>
                  }
                  unCheckedChildren={
                    <LockIconWrapper>
                      <LockOpenOutlined />
                    </LockIconWrapper>
                  }
                  checked={isJobLocked}
                  onChange={setIsJobLocked}
                />
              </SchemaWrapper>
            </OptionItem>
            <Divider />
            {currentSchema && (
              <>
                <OptionItem>
                  <Checkbox
                    disabled={!isSynced}
                    checked={showWellsWithin}
                    onChange={(e) => setShowWellsWithin(e.target.checked)}>
                    Show Wells Within
                  </Checkbox>
                  <Input
                    min={0}
                    max={6}
                    step={0.5}
                    value={xMiles}
                    onChange={(e) => setXMiles(parseFloat(e.target.value))}
                    type="number"></Input>
                  <label>Miles</label>
                </OptionItem>
                <OptionItem>
                  <Checkbox
                    disabled={!pdenStatus.LastUpdated || !isSynced}
                    checked={usePdenFromSyncDb}
                    onChange={(e) => setUsePdenFromSyncDb(e.target.checked)}>
                    Use Pden from Sync DB
                  </Checkbox>
                </OptionItem>
                <OptionItem>
                  <Checkbox
                    disabled={!fcstStatus.LastUpdated || !isSynced}
                    checked={useFcstFromSyncDb}
                    onChange={(e) => {
                      setUseFcstFromSyncDb(e.target.checked);
                    }}>
                    Use Fcst from Sync DB
                  </Checkbox>
                  <Select value={forecastRescat} onChange={setForecastRescat}>
                    <Option value="1111">PDP</Option>
                    <Option value="1211">PD</Option>
                    <Option value="1311">TP</Option>
                    <Option value="2111">P+PDP</Option>
                    <Option value="2211">P+PD</Option>
                    <Option value="2311">TPP</Option>
                    <Option value="3111">P+P+PDP</Option>
                    <Option value="3211">P+P+PD</Option>
                    <Option value="3311">TPPP</Option>
                  </Select>
                </OptionItem>

                <Divider></Divider>
                <OptionItemWithStatus>
                  <div>
                    <Tag color={pdenStatus.LastUpdated ? "green" : "red"}>
                      {pdenStatus.LastUpdated
                        ? "Pden: " + toDateTime(pdenStatus.LastUpdated)
                        : "Warning: No Pden Found"}
                    </Tag>
                  </div>
                  <Button
                    icon={<SyncOutlined></SyncOutlined>}
                    loading={loadingStatus.pden}
                    shape="circle"
                    onClick={() => updatePdenMutation.mutate()}
                    type="primary"></Button>
                </OptionItemWithStatus>
                <OptionItemWithStatus>
                  <div>
                    <Tag color={fcstStatus.LastUpdated ? "green" : "red"}>
                      {fcstStatus.LastUpdated
                        ? "Fcst: " + toDateTime(fcstStatus.LastUpdated)
                        : "Warning: No Fcst Found"}
                    </Tag>
                  </div>
                  <Button
                    shape="circle"
                    icon={<SyncOutlined></SyncOutlined>}
                    loading={loadingStatus.fcst}
                    onClick={() => updateFcstMutation.mutate()}
                    type="primary"></Button>
                </OptionItemWithStatus>
                <OptionItemWithStatus>
                  <div>
                    <Tag color={columnStatus.LastUpdated ? "green" : "red"}>
                      {columnStatus.LastUpdated
                        ? "Columns: " + toDateTime(columnStatus.LastUpdated)
                        : "Warning: No Columns Found"}
                    </Tag>
                  </div>
                  <Button
                    shape="circle"
                    type="primary"
                    loading={loadingStatus.column}
                    onClick={() => updateColumnMutation.mutate()}
                    icon={<SyncOutlined></SyncOutlined>}></Button>
                </OptionItemWithStatus>
                <OptionItemWithStatus>
                  <div>
                    <Tag color={performanceStatus.LastUpdated ? "green" : "red"}>
                      {performanceStatus.LastUpdated
                        ? "Performance: " + toDateTime(performanceStatus.LastUpdated)
                        : "Warning: No Performance Found"}
                    </Tag>
                  </div>
                  <Button
                    shape="circle"
                    type="primary"
                    loading={loadingStatus.performance}
                    onClick={() => calculatePerformanceMutation.mutate()}
                    icon={<SyncOutlined></SyncOutlined>}></Button>
                </OptionItemWithStatus>
                <Divider></Divider>
              </>
            )}
            <OptionItem>
              <SyncButton onClick={updateSyncState} synced={isSynced}>
                Sync is {isSynced ? "ON" : "OFF"}
              </SyncButton>

              {IS_DEV_ENV && (
                <Button onClick={() => sendMessage("SimulateEntityChanged", user.email)}>
                  Simulate ValNav Click
                </Button>
              )}

              <Tooltip title="When activated, pressing the space bar will sync pden, fcast, and performance for only the active wells.">
                <Switch
                  data-testid="super-sync-switch"
                  checkedChildren={
                    <LockIconWrapper>
                      <Icon path={mdiShieldSync} size={1} />
                    </LockIconWrapper>
                  }
                  unCheckedChildren={
                    <LockIconWrapper>
                      <Icon path={mdiShieldSyncOutline} size={1} />
                    </LockIconWrapper>
                  }
                  checked={useSuperSync}
                  onChange={setUseSuperSync}
                />
              </Tooltip>
            </OptionItem>
          </OptionContainer>
        }>
        <StyledButton
          synced={isSynced}
          shape="round"
          icon={<SyncFill className="source-icon" />}
          data-testid="sync-popover-trigger">
          {isSynced && <EllipsisText>{currentSchema}</EllipsisText>}
        </StyledButton>
      </Popover>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  .rotate {
    background-color: transparent;
    outline: 2px dashed;
    transform: rotate(180deg);
  }
`;
const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-divider-horizontal {
    margin: 5px 0 !important;
  }
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  .ant-select {
    width: 100px;
  }

  .ant-input {
    height: 25px;
    max-width: 80px;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  padding: 2px 0;
`;

const SchemaWrapper = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  .ant-select {
    min-width: 200px;
  }
`;

const OptionItemWithStatus = styled(OptionItem)`
  justify-content: space-between;
  align-items: center;

  .ant-btn {
    align-items: center;
  }
`;

const SyncButton = styled(Button)`
  color: white;
  background-color: ${(props) =>
    props.synced ? "var(--color-primary)" : "var(--color-text)"};
  border-color: ${(props) =>
    props.synced ? "var(--color-primary)" : "var(--color-text)"};

  &:hover {
    color: white;
  }

  &:focus {
    background-color: ${(props) =>
      props.synced ? "var(--color-primary)" : "var(--color-text)"};
    border-color: ${(props) =>
      props.synced ? "var(--color-primary)" : "var(--color-text)"};
    color: white;
  }
`;

const EllipsisText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  padding-left: 2px;
`;

const LockIconWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const StyledButton = styled(Button)`
  background: ${(props) => (props.synced ? "var(--color-primary)" : "transparent")};
  font-size: 1.3rem;
  color: ${(props) => (props.synced ? "white" : "#a2aaad")};
  display: flex;
  align-items: center;
  &:hover {
    color: ${(props) => (props.synced ? "var(--color-primary)" : "var(--color-text)")};
  }
`;
