import { ReactNode, useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { Subscription } from "rxjs";

import { useUser } from "hooks";

import { WORKSPACE, WorkspaceClientName } from "api/workspace";

import { WorkspaceQuery } from "models/workspace";

import { useWorkspaceContext } from "./hooks/useWorkspaceContext";
import { useWorkspaceDispatch } from "./hooks/useWorkspaceDispatch";
import { getWorkspacePermissions } from "./utils";

let workspaceSub: Subscription = null;

export interface WorkspaceWrapperModel {
  children: ReactNode;
}

export default function WorkspaceWrapper({
  children
}: WorkspaceWrapperModel): JSX.Element {
  const [refreshTxn, setRefreshTxn] = useState<string>(null);

  const { workspaceId, workspace, workspaceRefreshObserver } = useWorkspaceContext();
  const workspaceDispatch = useWorkspaceDispatch();
  const { user, isAtLeastPowerUser } = useUser();

  const [fetchWorkspace, { data, loading, error, refetch: refetchWorkspace }] =
    useLazyQuery<WorkspaceQuery>(WORKSPACE, {
      context: {
        clientName: WorkspaceClientName
      }
    });

  // Fetch the workspace when the selected workspace ID changes
  useEffect(() => {
    if (workspaceId === undefined) return;

    fetchWorkspace({
      variables: {
        id: workspaceId
      }
    });
  }, [fetchWorkspace, workspaceId]);

  // Refetch the workspace when there is a refresh request.
  useEffect(() => {
    if (workspaceId === undefined || workspaceId !== workspace?.workspaceId) return;

    refetchWorkspace({
      variables: {
        id: workspaceId
      }
    });
  }, [refetchWorkspace, workspaceId, refreshTxn, workspace?.workspaceId]);

  // Update the loading state when the apollo query loading value changes
  useEffect(() => {
    workspaceDispatch({
      payload: {
        isLoading: loading
      }
    });
  }, [loading, workspaceDispatch]);

  // Update the error state when the apollo query error value changes
  useEffect(() => {
    workspaceDispatch({
      payload: {
        error: error
      }
    });
  }, [error, workspaceDispatch]);

  // Update the workspace when the apollo query successfully finishes
  useEffect(() => {
    if (!data?.workspace) return;

    getWorkspacePermissions(data?.workspace, user, isAtLeastPowerUser);

    // Workspace was loaded, make sure to set workspaceId on workspace context since a lot of useEffect rely on this
    workspaceDispatch({
      payload: {
        workspaceId: data?.workspace.workspaceId,
        workspace: data?.workspace
      }
    });
  }, [data, user, isAtLeastPowerUser, workspaceDispatch]);

  // Update the workspace permissions when the workspace loads.
  useEffect(() => {
    if (!workspace) return;

    const permissions = getWorkspacePermissions(workspace, user, isAtLeastPowerUser);

    workspaceDispatch({
      payload: {
        permissions
      }
    });

    sessionStorage.setItem(`workspaceid`, workspace.workspaceId);
  }, [workspace, user, isAtLeastPowerUser, workspaceDispatch]);

  // Component mounting
  useEffect(() => {
    // Initially load the default workspace
    const sessionWorkspaceId = sessionStorage.getItem(`workspaceid`);

    // Root cause for bug EVA-1415 was this.
    // On first load, the workspaceId was set to ""
    // After reload, the workspaceId was loaded from session
    if (sessionWorkspaceId) {
      workspaceDispatch({
        payload: {
          workspaceId: sessionWorkspaceId
        }
      });
    } else {
      workspaceDispatch({
        payload: {
          workspaceId: ""
        }
      });
    }

    // Monitor workspace refresh requests.
    if (workspaceRefreshObserver && !workspaceSub) {
      workspaceSub = workspaceRefreshObserver.subscribe((refreshTxn: string) => {
        setRefreshTxn(refreshTxn);
      });
    }
    return () => {
      if (workspaceSub) {
        workspaceSub.unsubscribe();
        workspaceSub = null;
      }
    };
  }, []);

  return <>{children}</>;
}
