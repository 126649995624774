import { Progress } from "antd";
import styled from "styled-components/macro";

import { useFilesContext } from "../context";

const PendingFilesList = () => {
  const { uploadingFilesProgress } = useFilesContext();

  const pendingFiles = Object.entries(uploadingFilesProgress);

  return (
    <Wrapper>
      {pendingFiles?.length > 0
        ? pendingFiles.map(([filename, file]) => (
            <FileUploadWrapper key={filename}>
              <FileNameDisplay>{filename}</FileNameDisplay>
              <Progress type="circle" percent={file.progress} width={40} />
            </FileUploadWrapper>
          ))
        : null}
    </Wrapper>
  );
};

export default PendingFilesList;

const Wrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // put a border at the bottom of each child except the last one
  & > *:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
`;

const FileUploadWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
`;

const FileNameDisplay = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
