import {
  DEFAULT_AXIS_FONT_SIZE,
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE
} from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";

import { IChartResult } from "models/model";

import {
  IAxisMinMax,
  LegendT
} from "components/multiphase-chart/context/ChartSettingsContext";
import {
  IChartPreset,
  IMultiphaseChartSettings
} from "components/multiphase-chart/models/shared.models";

import { shared } from "./";

export const getCumTimeChartOptions = (
  option: IChartPreset,
  data: IChartResult,
  settings: IMultiphaseChartSettings,
  axisMinMax: IAxisMinMax,
  legend: LegendT
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const series = shared.getDefaultLineSeries(option, data);
  const legendData = series
    .map((series) => series.name)
    .filter((name) => !name.includes("Forecast"));

  let shownSplitLineY = false;

  return {
    grid: {
      right: 100,
      left: 100,
      top: 50,
      bottom: 100
    },
    title: {
      text: data.title,
      textStyle: {
        fontSize: DEFAULT_TITLE_FONT_SIZE,
        fontWeight: "bold",
        color: "#041C2C"
      },
      padding: [14, 0, 10, 16]
    },
    textStyle: { fontFamily: THEME_FONT_FAMILY, fontSize: 12, color: "#041C2C" },
    series: series,
    xAxis: data.layout.xAxis2.map((axis, i) => {
      const showMinorSplitLineX = settings.logs && settings.logs[i].x;
      return {
        id: i,
        name: axis.title,
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: { fontSize: DEFAULT_AXIS_FONT_SIZE },
        axisLabel: { fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE },
        min: axisMinMax[i].xMin !== null ? String(axisMinMax[i].xMin) : null,
        max: axisMinMax[i].xMax !== null ? String(axisMinMax[i].xMax) : null,
        offset: 0,
        alignTicks: shared.alignTicks,
        position: "bottom",
        splitLine: {
          show: true
        },
        minorSplitLine: {
          show: showMinorSplitLineX,
          lineStyle: { color: "#F2F2F2" }
        },
        axisLine: {
          onZero: false
        }
      };
    }),
    yAxis: data.layout.yAxis2.map((axis, i) => {
      const position = axis.isSecondaryYAxis ? "right" : "left";
      let showSplitLine = false;
      const showMinorSplitLineY = settings.logs && settings.logs[i].y;
      if (!shownSplitLineY && position === "left") {
        shownSplitLineY = true;
        showSplitLine = true;
      }
      return {
        id: i,
        type: settings.logs[i].y ? "log" : "value",
        min:
          axisMinMax[i].yMin !== null
            ? Number(axisMinMax[i].yMin)
            : settings.logs[i].y
            ? 0.1
            : 0,
        max: axisMinMax[i].yMax !== null ? Number(axisMinMax[i].yMax) : null,
        name: axis.title,
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: { fontSize: DEFAULT_AXIS_FONT_SIZE },
        axisLabel: { fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE },
        offset: 0,
        alignTicks: shared.alignTicks,
        position: position,
        splitLine: {
          show: showSplitLine || showMinorSplitLineY
        },
        minorSplitLine: {
          show: showMinorSplitLineY,
          lineStyle: { color: "#F2F2F2" }
        },
        axisLine: {
          onZero: false
        }
      };
    }),
    legend: {
      top: "bottom",
      data: legendData,
      show: legend.type === "bottom" && legend.visible
    },
    tooltip: shared.tooltip,
    dataZoom: shared.defaultDataZoom
  };
};
