import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { fetchDimensionsFromPlay } from "api/ipdp";

const useGeoRadius = () => {
  const plays = useSelector((state: RootState) => state.filter.currentPlayList);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      setData(null);
      try {
        const response = await fetchDimensionsFromPlay(plays[0]);
        if (response) {
          setData(response);
        }
      } catch (err) {
        setError(err);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    if (plays) {
      fetchData();
    } else {
      setData(null);
      setLoading(false);
    }
  }, [plays]);

  return { data, loading, error };
};

export default useGeoRadius;
