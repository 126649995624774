import styled from "styled-components";

import { useChartSettings } from "components/multiphase-chart/context";

const ChartLegendItem = ({ legend, ...rest }) => {
  const [chartSettings] = useChartSettings();
  const { screenshot } = chartSettings;

  // derived state
  const fontSize = screenshot.visible
    ? Number(screenshot.preset?.legendFontSize) || 14
    : 14;

  return (
    <Wrapper {...rest}>
      <Indicator color={legend.color} />
      <Title fontSize={fontSize}>{legend.title}</Title>
    </Wrapper>
  );
};

export default ChartLegendItem;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  background-color: transparent;
  backface-visibility: hidden;

  &.highlighted,
  &:hover {
    background-color: #dbdbdb;
  }
`;

const Indicator = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const Title = styled.div`
  font-size: ${(props) => props.fontSize}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;
