import React, { createContext, useContext, useMemo, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { WellCardTypeLogPdf } from "api/data";

import { SelectedWellT, WellInfoT } from "components/well-list/context/WellListContext";

import { TypeLogAction, TypeLogState, WellDetailsProviderT } from "./types";

const TypeLogContext =
  createContext<[TypeLogState, React.Dispatch<TypeLogAction>]>(undefined);
TypeLogContext.displayName = "TypeLogContext";

const initialState: TypeLogState = {
  document: null,
  previouslySelectedWells: [],
  selectedWell: undefined,
  wells: [],
  typeLogErrorMessage: null,
  uwidFormat: "entityName"
};

const typeLogReducer = (draft: TypeLogState, action: TypeLogAction) => {
  switch (action.type) {
    case "set type log pdf": {
      draft.document = action.payload;
      break;
    }
    case "set previously selected wells": {
      // keep track of the last 10 selected wells.
      if (draft.selectedWell) {
        const existingIndex = draft.previouslySelectedWells.findIndex(
          (w) => w.Uwi === draft.selectedWell.Uwi
        );
        if (existingIndex !== -1) {
          draft.previouslySelectedWells.splice(existingIndex, 1);
        }

        draft.previouslySelectedWells.unshift(draft.selectedWell);
        if (draft.previouslySelectedWells.length > 10) {
          draft.previouslySelectedWells.pop();
        }
      }
      draft.selectedWell = action.payload;
      break;
    }
    case "set wells": {
      draft.wells = action.payload;
      break;
    }
    case "set selected well": {
      draft.selectedWell = action.payload;
      break;
    }
    case "update type log error message": {
      draft.typeLogErrorMessage = action.payload;
      break;
    }
    case "update uwid format": {
      draft.uwidFormat = action.payload;
      break;
    }
    default:
      throw new Error("invalid action type");
  }
};

const curriedReducer = produce(typeLogReducer);

const TypeLogProvider = ({ children }: WellDetailsProviderT) => {
  const [state, dispatch] = useReducer(curriedReducer, initialState);
  const value: [TypeLogState, React.Dispatch<TypeLogAction>] = useMemo(
    () => [state, dispatch],
    [state]
  );

  return <TypeLogContext.Provider value={value}>{children}</TypeLogContext.Provider>;
};

const useTypeLog = () => {
  const context = useContext(TypeLogContext);
  if (context === undefined) {
    throw new Error("useTypeLog must be used within TypeLogProvider");
  }
  return context;
};

const setTypeLogDocument = (
  dispatch: React.Dispatch<TypeLogAction>,
  payload: WellCardTypeLogPdf
) => {
  dispatch({ type: "set type log pdf", payload: payload.wellData });
};
const setPreviouslySelectedWells = (
  dispatch: React.Dispatch<TypeLogAction>,
  payload: SelectedWellT
) => {
  dispatch({ type: "set previously selected wells", payload });
};

const setWells = (dispatch: React.Dispatch<TypeLogAction>, payload: WellInfoT[]) => {
  dispatch({ type: "set wells", payload });
};

const setSelectedWell = (
  dispatch: React.Dispatch<TypeLogAction>,
  payload: SelectedWellT
) => {
  dispatch({ type: "set selected well", payload });
};

const setTypeLogErrorMessage = (
  dispatch: React.Dispatch<TypeLogAction>,
  payload: string
) => {
  dispatch({ type: "update type log error message", payload });
};

const updateUwidFormat = (dispatch: React.Dispatch<TypeLogAction>, payload: string) => {
  dispatch({ type: "update uwid format", payload });
};

export {
  TypeLogProvider,
  useTypeLog,
  setTypeLogDocument,
  setPreviouslySelectedWells,
  setWells,
  setSelectedWell,
  setTypeLogErrorMessage,
  updateUwidFormat
};
