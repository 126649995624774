import { IpdbBin } from "components/vis/context/types";

export interface IAllXdaDefaultBins {
  Porosity: IpdbBin;
  BulkVolumeHydrocarbon: IpdbBin;
  OGIPDensity: IpdbBin;
  OOIPDensity: IpdbBin;
  Pressure: IpdbBin;
  Sw: IpdbBin;
  Temperature: IpdbBin;
}

export const ALL_XDA_DEFAULT_BINS: IAllXdaDefaultBins = {
  Porosity: {
    lessThan: 1,
    binSize: 0.5,
    greaterThan: 8
  },
  BulkVolumeHydrocarbon: {
    lessThan: 0.01,
    binSize: 0.005,
    greaterThan: 0.07
  },
  OGIPDensity: {
    lessThan: 10,
    binSize: 5,
    greaterThan: 50
  },
  OOIPDensity: {
    lessThan: 1000,
    binSize: 1000,
    greaterThan: 10000
  },
  Pressure: {
    lessThan: 0,
    binSize: 1000,
    greaterThan: 50000
  },
  Sw: {
    lessThan: 0,
    binSize: 5,
    greaterThan: 50
  },
  Temperature: {
    lessThan: 60,
    binSize: 1,
    greaterThan: 85
  }
};
