import { memo } from "react";

function ChangeUserType({ size = 24, className = "" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      version="1.1"
      className={className}>
      <g id="IconsInactive-users-Copy">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="Rectangle"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M5 8L9.5 0L14 8L5 8ZM20 14.5C20 16.9853 17.9853 19 15.5 19C13.0147 19 11 16.9853 11 14.5C11 12.0147 13.0147 10 15.5 10C17.9853 10 20 12.0147 20 14.5ZM0 19L0 11L8 11L8 19L0 19Z"
          transform="translate(2 2)"
          id="Combined-Shape"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}
export default memo(ChangeUserType);
