import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import axios, { AxiosError } from "axios";
import { useDebouncedCallback } from "use-debounce";

import { MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { RootState } from "../../../store/rootReducer";
import { CoverageType } from "../toolbar/MapVis";
import { emptyFeatureCollection } from "../utils/emptyFeatureCollection";

const API_URL = `${MAP_SERVICE_ADDRESS}/map-vis/coverage`;

export const GetMapCoverageKey = "mapCoverage";

const fetchCoverage = async (
  txnId: string,
  show: boolean,
  size: number,
  coverageType: CoverageType
) => {
  if (!txnId || !show) return Promise.resolve({ ...emptyFeatureCollection });
  const response = await axios.get<GeoJSON.FeatureCollection>(
    `${API_URL}/${txnId}?size=${size}&coverageType=${coverageType}`
  );
  return response.data;
};

const useGetGeoMapSettings = (coverageOptions: {
  showCoverage: boolean;
  radius: number;
  coverageType: CoverageType;
}) => {
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const { radius, coverageType, showCoverage } = coverageOptions;
  const [enabled, setEnabled] = useState(false);
  const debouncedFetch = useDebouncedCallback(() => {
    if (!txnId?.id || !coverageOptions.showCoverage || !coverageOptions.coverageType)
      return;
    setEnabled(true);
  }, 1000);
  useEffect(() => {
    if (!coverageOptions.showCoverage || !txnId?.id) {
      return;
    }
    debouncedFetch();
  }, [txnId, coverageOptions]);
  return useQuery<GeoJSON.FeatureCollection, AxiosError>(
    [GetMapCoverageKey, radius, coverageType],
    () => fetchCoverage(txnId.id, showCoverage, radius, coverageType),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
      onSuccess: () => {
        setEnabled(false);
      }
    }
  );
};

export default useGetGeoMapSettings;
