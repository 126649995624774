import { memo } from "react";

function LiquidsShrinkage() {
  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
      <g transform="translate(0, 0) scale(0.5)">
        <path
          d="M14.89,12.06V7.11H18.31V18.31H7.11V14.89H12.06L5.69,8.5L8.5,5.69L14.89,12.06Z"
          fill="currentColor"
        />
      </g>
      <g transform="translate(20, 0) scale(0.5)">
        <path
          d="M15.5,5.69L18.31,8.5L11.94,14.89H16.89V18.31H5.69V7.11H9.12V12.06L15.5,5.69Z"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0, 22) scale(0.5)">
        <path
          d="M8.5,18.31L5.69,15.5L12.06,9.12H7.11V5.69H18.31V16.89H14.89V11.94L8.5,18.31Z"
          fill="currentColor"
        />
      </g>

      <g transform="translate(20, 22) scale(0.5)">
        <path
          d="M9.12,11.94V16.89H5.69V5.69H16.89V9.12H11.94L18.31,15.5L15.5,18.31L9.12,11.94Z"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.65, 8.7) scale(0.7)">
        <path
          fill="currentColor"
          d="M20 13C20.55 13 21 12.55 21 12S20.55 11 20 11H19V5H20C20.55 5 21 4.55 21 4S20.55 3 20 3H4C3.45 3 3 3.45 3 4S3.45 5 4 5H5V11H4C3.45 11 3 11.45 3 12S3.45 13 4 13H5V19H4C3.45 19 3 19.45 3 20S3.45 21 4 21H20C20.55 21 21 20.55 21 20S20.55 19 20 19H19V13H20M12 16C10.34 16 9 14.68 9 13.05C9 11.75 9.5 11.38 12 8.5C14.47 11.36 15 11.74 15 13.05C15 14.68 13.66 16 12 16Z"
        />
      </g>
    </svg>
  );
}

export default memo(LiquidsShrinkage);
