import { memo } from "react";

function Flared() {
  return (
    <svg
      viewBox="62 65 360 360"
      width="35"
      height="35"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <g
        id="SVGRepo_bgCarrier"
        strokeWidth="25.6"
        transform="matrix(1, 0, 0, 1, -125.305038, -202.139084)"
      />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="matrix(1, 0, 0, 1, -125.305038, -202.139084)"
      />
      <g
        id="SVGRepo_iconCarrier"
        transform="matrix(0.384628, 0, 0, 0.480103, 147.038574, 104.852837)">
        <g>
          <g>
            <path
              fill="currentColor"
              transform="translate(0,140)"
              d="M453.333,490.667h-33.301L357.237,9.291c-0.691-5.312-5.214-9.287-10.571-9.291H165.333 c-5.356,0.003-9.88,3.979-10.571,9.291L91.968,490.667H58.667c-5.891,0-10.667,4.776-10.667,10.667S52.776,512,58.667,512h394.667 c5.891,0,10.667-4.776,10.667-10.667S459.224,490.667,453.333,490.667z M363.52,222.315l-93.376-107.381L339.2,35.563 L363.52,222.315z M323.2,21.333L256,98.677l-67.2-77.344H323.2z M172.8,35.563l69.024,79.371L148.48,222.315L172.8,35.563z M256,131.2l107.68,123.819L256,350.272l-107.68-95.253L256,131.2z M141.312,277.333l98.592,87.221L115.595,474.485 L141.312,277.333z M129.483,490.667L256,378.752l126.517,111.915H129.483z M272.096,364.512l98.592-87.179l25.717,197.152 L272.096,364.512z"
              stroke="currentColor"
              strokeWidth="25.6"
            />
          </g>
        </g>
      </g>
      <path
        fill="currentColor"
        d="M 272.348 107.642 C 271.212 106.154 269.829 104.862 268.545 103.572 C 265.236 100.594 261.482 98.46 258.321 95.333 C 250.962 88.085 249.332 76.123 254.024 66.941 C 249.332 68.082 245.233 70.663 241.726 73.492 C 228.934 83.817 223.896 102.033 229.922 117.668 C 230.12 118.165 230.317 118.662 230.317 119.307 C 230.317 120.399 229.576 121.391 228.588 121.788 C 227.453 122.285 226.267 121.987 225.329 121.192 C 225.032 120.945 224.835 120.697 224.638 120.349 C 219.056 113.251 218.167 103.075 221.921 94.936 C 213.672 101.686 209.179 113.102 209.82 123.873 C 210.117 126.355 210.413 128.837 211.252 131.319 C 211.944 134.296 213.278 137.275 214.759 139.905 C 220.094 148.493 229.329 154.647 239.257 155.889 C 249.826 157.229 261.137 155.293 269.236 147.947 C 278.274 139.707 281.435 126.504 276.793 115.187 L 276.151 113.896 C 275.113 111.613 272.348 107.642 272.348 107.642 M 256.741 138.913 C 255.358 140.104 253.086 141.395 251.308 141.891 C 245.776 143.876 240.245 141.097 236.985 137.821 C 242.863 136.431 246.369 132.064 247.405 127.646 C 248.245 123.675 246.665 120.399 246.023 116.577 C 245.43 112.904 245.529 109.777 246.862 106.352 C 247.801 108.238 248.789 110.125 249.974 111.613 C 253.777 116.577 259.754 118.761 261.037 125.511 C 261.235 126.206 261.334 126.901 261.334 127.646 C 261.482 131.716 259.704 136.183 256.741 138.913 Z"
      />
    </svg>
  );
}

export default memo(Flared);
