import { useMutation } from "react-query";

import { DataFreshnessResult, getDataFreshness } from "api/appTools";

export default function useDataFreshnessAnalyzer(
  handlePostData: (postData: DataFreshnessResult) => void,
  handleError: (err) => void = null
) {
  const { isLoading, isError, error, mutate, data } = useMutation(
    "getDataFreshness",
    async (includePerformance?: boolean) => {
      const result = await getDataFreshness(includePerformance);
      return result;
    },
    {
      onSuccess: (postData) => {
        handlePostData(postData);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );

  return { isLoading, isError, error, mutate, data };
}
