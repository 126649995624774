import { memo } from "react";

function AccountSecurity() {
  return (
    <svg width="24" height="24px" viewBox="0 0 22 16" version="1.1">
      <path
        d="M8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8ZM17.25 3.5C17.25 3.22386 17.4739 3 17.75 3L18.25 3C18.5261 3 18.75 3.22386 18.75 3.5L18.81 3.92017C18.8638 4.29637 19.301 4.47747 19.605 4.24946L19.9445 3.9948C20.1398 3.79953 20.4564 3.79953 20.6516 3.9948L21.0052 4.34835C21.2004 4.54361 21.2004 4.8602 21.0052 5.05546L20.7505 5.395C20.5225 5.69902 20.7036 6.13622 21.0798 6.18996L21.5 6.25C21.7761 6.25 22 6.47387 22 6.75L22 7.25C22 7.52613 21.7761 7.75 21.5 7.75L21.0798 7.81004C20.7036 7.86378 20.5225 8.30098 20.7505 8.60498L21.0052 8.94453C21.2004 9.13982 21.2004 9.4564 21.0052 9.65164L20.6516 10.0052C20.4564 10.2005 20.1398 10.2005 19.9445 10.0052L19.605 9.75053C19.301 9.52253 18.8638 9.70364 18.81 10.0798L18.75 10.5C18.75 10.7761 18.5261 11 18.25 11L17.75 11C17.4739 11 17.25 10.7761 17.25 10.5L17.19 10.0798C17.1362 9.70364 16.699 9.52253 16.395 9.75053L16.0555 10.0052C15.8602 10.2005 15.5436 10.2005 15.3484 10.0052L14.9948 9.65164C14.7995 9.4564 14.7995 9.13982 14.9948 8.94453L15.2495 8.60498C15.4775 8.30098 15.2964 7.86378 14.9202 7.81004L14.5 7.75C14.2239 7.75 14 7.52613 14 7.25L14 6.75C14 6.47387 14.2239 6.25 14.5 6.25L14.9202 6.18996C15.2964 6.13622 15.4775 5.69902 15.2495 5.395L14.9948 5.05546C14.7995 4.8602 14.7995 4.54361 14.9948 4.34835L15.3484 3.9948C15.5436 3.79953 15.8602 3.79953 16.0555 3.9948L16.395 4.24946C16.699 4.47747 17.1362 4.29637 17.19 3.92017L17.25 3.5ZM19 7C19 7.55227 18.5523 8 18 8C17.4477 8 17 7.55227 17 7C17 6.44773 17.4477 6 18 6C18.5523 6 19 6.44773 19 7ZM8 10C10.67 10 16 11.34 16 14L16 15C16 15.55 15.55 16 15 16L1 16C0.45 16 0 15.55 0 15L0 14C0 11.34 5.33 10 8 10Z"
        id="Icon"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}
export default memo(AccountSecurity);
