import { Dispatch, ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";
import { Subject } from "rxjs";

import DashboardWrapper from "../DashboardWrapper";
import {
  DashboardContextState,
  UpdateDashboardCapabilities,
  UpdateDashboardMode,
  UpdateDashboardPermissions,
  UpdateDashboardState,
  UpdateErrorState,
  UpdateLoadingState,
  UpdateLockMapState,
  UpdateModifiedState,
  UpdateModifiedWidgetState
} from "./DashboardContextState";

export const initialDashboardContextState: DashboardContextState = {
  isLoading: false,
  isModified: false,
  hasModifiedWidgets: false,
  dashboardRefreshObserver: new Subject(),
  dashboardMode: "explore",
  lockMap: false
};
export const DashboardContext = createContext(initialDashboardContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const DashboardDispatchContext =
  createContext<Dispatch<Action<DashboardUpdates, "update">>>(undefined);

export type DashboardUpdates =
  | UpdateLoadingState
  | UpdateModifiedState
  | UpdateModifiedWidgetState
  | UpdateErrorState
  | UpdateDashboardState
  | UpdateDashboardPermissions
  | UpdateDashboardCapabilities
  | UpdateDashboardMode
  | UpdateLockMapState;

function DashboardReducer(
  state: DashboardContextState,
  action: Action<DashboardUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface DashboardProviderModel {
  children: ReactNode;
  state?: DashboardContextState;
  useWrapper?: boolean;
}

export function DashboardProvider({
  children,
  state: overrideState,
  useWrapper
}: DashboardProviderModel) {
  const [state, dispatch] = useReducer(
    DashboardReducer,
    overrideState ?? initialDashboardContextState
  );

  if (useWrapper === false) {
    return (
      <DashboardContext.Provider value={state}>
        <DashboardDispatchContext.Provider value={dispatch}>
          {children}
        </DashboardDispatchContext.Provider>
      </DashboardContext.Provider>
    );
  }

  return (
    <DashboardContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        <DashboardWrapper>{children}</DashboardWrapper>
      </DashboardDispatchContext.Provider>
    </DashboardContext.Provider>
  );
}
