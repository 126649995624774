import { useState } from "react";
import QRCode from "react-qr-code";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { updateUser } from "store/features/auth/authSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { MfaDeliveryMethod } from "api/auth";
import { getAuthenticationAppUri, setupAuthenticationApp } from "api/users";

import { BaseButton, BaseDivider, BaseInput } from "components/base";

interface uriResponse {
  uri: string;
}

export default function AuthenticationAppSetup() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [setupAuthApp, setSetupAuthApp] = useState(false);
  const [isSettingUpAuth, setIsSettingUpAuth] = useState("");

  const handleCancelSetupAuthApp = () => {
    setSetupAuthApp(false);
    setIsSettingUpAuth("");
  };

  const { data, refetch, isSuccess } = useQuery<uriResponse>(
    "getAuthenticationAppUri",
    async () => await getAuthenticationAppUri(),
    {
      enabled: false
    }
  );

  const saveAuthApp = useMutation(
    async () => {
      await setupAuthenticationApp({
        userId: user.id,
        code: isSettingUpAuth
      });
    },
    {
      onSuccess: () => {
        setSetupAuthApp(false);
        setIsSettingUpAuth("");
        dispatch(updateUser({ ...user, preferredMFAMethod: MfaDeliveryMethod.App }));
        toast.success("Sucessfully updated mfa method");
      },
      onError: (err) => {
        toast.error(err);
      }
    }
  );
  return (
    <>
      <BaseDivider />
      <AppMFASection>
        <SectionHeading>
          <div className="section-heading">
            Authentication Method: {MfaDeliveryMethod[user.preferredMFAMethod]}
          </div>
          {!setupAuthApp && user.preferredMFAMethod !== MfaDeliveryMethod.App && (
            <BaseButton
              data-testid="auth-app-setup-button"
              appearance="subtle"
              onClick={() => {
                refetch();
                setSetupAuthApp(true);
              }}>
              Setup Authentication App
            </BaseButton>
          )}
        </SectionHeading>
        {setupAuthApp && isSuccess && (
          <>
            <p>Scan this QR code in your authentication app</p>
            <AppMFAQRCode value={data.uri} />
            <BaseInput
              label="Verification Code"
              value={isSettingUpAuth}
              placeholder="Enter the code displayed in the app"
              onChange={(value) => setIsSettingUpAuth(value)}
              data-testid="auth-app-code-input-field"
            />
            <AppMFAActionButtons>
              <BaseButton
                onClick={() => handleCancelSetupAuthApp()}
                data-testid="cancel-auth-app-button">
                Cancel
              </BaseButton>
              <BaseButton
                isDisabled={isSettingUpAuth.length !== 6}
                onClick={() => saveAuthApp.mutate()}
                data-testid="save-auth-app-button">
                Save
              </BaseButton>
            </AppMFAActionButtons>
          </>
        )}
      </AppMFASection>
    </>
  );
}

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .base-button.subtle {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
  }
`;

const AppMFASection = styled.div`
  padding: 33px 0;
`;

const AppMFAQRCode = styled(QRCode)`
  margin: 5px;
`;

const AppMFAActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  .button {
    margin-left: 5px;
    margin-top: 5px;
  }
`;
