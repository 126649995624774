import { useQuery } from "react-query";

import axios from "axios";

import { IUser } from "models/User";

import { ApiResult } from "./apiResult";

///hook to fetch organizaitons
const userRoot = process.env.REACT_APP_USER_SERVICE;
export default function useUsers(token: string, orgId: string): ApiResult<IUser[]> {
  const { data, isLoading, isError, refetch } = useQuery<IUser[]>(
    "fetchUsersInOrg",
    async () => {
      if (!token) {
        return [];
      }
      const result = await axios.get<IUser[]>(`${userRoot}/organization/${orgId}/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return result.data;
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
