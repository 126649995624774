import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import {
  FilesContextState,
  FilesUpdates,
  UploadFileState,
  UploadingFileUpdates
} from "./FilesContextState";

export const initialFilesContextState: FilesContextState = {
  filesType: "userToEVA",
  selectedFiles: [],
  searchTerm: "",
  isFileUploaderOpen: false,
  allowMultipleDownloads: false,
  isDownloadingFiles: false,
  isUploadingFiles: false,
  uploadingFilesProgress: {},
  downloadFileProgress: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancelDownload: () => {},
  error: ""
};
export const FilesContext = createContext(initialFilesContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const FilesDispatchContext =
  createContext<
    React.Dispatch<
      Action<FilesUpdates | UploadingFileUpdates, "update" | "updateFileProgress">
    >
  >(undefined);

function FilesReducer(
  state: FilesContextState,
  action: Action<FilesUpdates | UploadingFileUpdates, "update" | "updateFileProgress">
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "updateFileProgress": {
        const { filename } = action.payload as UploadingFileUpdates;
        if (!draft.uploadingFilesProgress[filename]) {
          draft.uploadingFilesProgress[filename] = {
            progress: 0,
            completed: false,
            failed: false
          } as UploadFileState;
        }
        draft.uploadingFilesProgress[filename] = Object.assign(
          draft.uploadingFilesProgress[filename],
          action.payload
        );
        break;
      }
      case "update":
      default:
        Object.assign(draft, action.payload);
        break;
    }
  });
}

export interface FilesProviderModel {
  children: ReactNode;
  state?: FilesContextState;
}

export function FilesProvider({ children, state: overrideState }: FilesProviderModel) {
  const [state, dispatch] = useReducer(
    FilesReducer,
    overrideState ?? initialFilesContextState
  );

  return (
    <FilesContext.Provider value={state}>
      <FilesDispatchContext.Provider value={dispatch}>
        {children}
      </FilesDispatchContext.Provider>
    </FilesContext.Provider>
  );
}
