import { GeoJSONSource } from "mapbox-gl";

import { IEditableProjectLayer } from "components/project/layers/hooks";
import { Shapefile } from "components/project/shapefiles/queries";

import { shapefileTypes } from "../constants";
import { getColor } from "../getColor";
import { getDifference } from "../getDifference";
import { addGeoTiffLayer } from "./addGeoTiffLayer";
import { addShapefileLayers } from "./addShapefileLayers";

interface IOnShapefilesChangedParams {
  addLayer;
  checkedShapefiles: Set<string>;
  lastCheckedShapefiles: React.MutableRefObject<Set<string>>;
  layerList: IEditableProjectLayer[];
  mapbox: mapboxgl.Map;
  shapefileFeatures;
  shapefileListSorted: Shapefile[];
}
export function onShapefilesChanged(params: IOnShapefilesChangedParams) {
  const {
    addLayer,
    checkedShapefiles,
    layerList,
    mapbox,
    shapefileFeatures,
    shapefileListSorted
  } = params;

  // console.time("~~ loadShapefileData ");

  /**
   * Remove layers that are no longer checked
   */
  const shapefilesToRemove: string[] = getDifference(
    params.lastCheckedShapefiles.current,
    checkedShapefiles
  );

  for (const shapefile of shapefilesToRemove) {
    try {
      for (const shapefileType of shapefileTypes) {
        const layerName = `${shapefile}${shapefileType}`;
        if (mapbox.getLayer(layerName)) mapbox.removeLayer(layerName);
      }
      if (mapbox.getSource(shapefile)) mapbox.removeSource(shapefile);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Unable to remove layer", err);
    }
  }

  params.lastCheckedShapefiles.current = checkedShapefiles;

  if (!shapefileListSorted) {
    return;
  }

  /**
   * Either add the new shapefile sources or update the existing ones
   */
  for (const shapefile of shapefileListSorted) {
    const layerName = shapefile.projectShapefileId || shapefile.shapefileId;
    // Make sure the color from shapefile only render with 6 chars HEX
    // If we have more than 6 (8 chars HEX), just strip the last 2 chars for Alpha channel
    // Alpha channel should be cover by opacity
    shapefile.featureCollection.features.forEach((feat) => {
      feat.properties.color = getColor(feat.properties.color ?? "");
      feat.properties.strokeColor = getColor(feat.properties.strokeColor ?? "");
      feat.properties.opacity = !isNaN(parseFloat(feat.properties?.opacity))
        ? parseFloat(feat.properties.opacity)
        : 1;
      feat.properties.thickness = !isNaN(parseFloat(feat.properties?.thickness))
        ? parseFloat(feat.properties.thickness)
        : 2;
    });

    const isGeoTiff = shapefile.fileType === "GeoTiff";

    if (!mapbox.getSource(layerName)) {
      isGeoTiff
        ? addGeoTiffLayer({ mapbox, layerName, shapefile, addLayer })
        : addShapefileLayers({
            addLayer,
            mapbox,
            layerList,
            layerName,
            shapefileFeatures,
            shapefile
          });
    } else {
      // Would need to change if geotiff required source update
      if (isGeoTiff) {
        if (
          shapefile?.boundingBox?.length === 4 &&
          mapbox.getPaintProperty(layerName, "raster-opacity") !== shapefile.opacity &&
          mapbox.getSource(layerName)
        ) {
          mapbox.setPaintProperty(layerName, "raster-opacity", shapefile.opacity || 0.8);
        }
      } else {
        const source = mapbox.getSource(layerName) as GeoJSONSource;
        source.setData(shapefile.featureCollection);
      }
    }
  }

  // console.timeEnd("~~ loadShapefileData ");
}
