import { Card, CardContent } from "@material-ui/core";

import { BaseButton } from "components/base";

interface ConfirmDialogProps {
  message: string;
  title: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

function ConfirmDialog({
  onConfirm,
  onClose,
  message,
  title,
  confirmButtonLabel = "Confirm",
  cancelButtonLabel = "Cancel"
}: ConfirmDialogProps): JSX.Element {
  return (
    <Card variant="outlined">
      <CardContent>
        <div className="child-container">
          <div className="child-wrapper">
            <div className="title">{title}</div>
            <div className="content">
              <p>{message}</p>

              <div className="actions flex-row justify-center">
                <BaseButton autoFocus onClick={onConfirm} appearance="primary">
                  {confirmButtonLabel}
                </BaseButton>
                {onClose && (
                  <BaseButton autoFocus onClick={onClose} appearance="stroked">
                    {cancelButtonLabel}
                  </BaseButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default ConfirmDialog;
