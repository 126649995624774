import { memo } from "react";

import { capitalizeFirstLetter } from "utils";

import { TimeScaleSelect, TimeStepIcon } from "components/shared";

import { useChartDispatch, useChartState } from "../../context";
import ToolbarButton from "../ToolbarButton";

const TimeStepToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();

  function handleTimestepChange(value) {
    const chartCopy = { ...settings, timeStep: value };
    dispatch({ type: "settings", payload: chartCopy });
  }

  // derived state
  const icon = TimeStepIcon[settings.timeStep];
  const label = capitalizeFirstLetter(settings.timeStep);

  return (
    <TimeScaleSelect
      trigger={["hover"]}
      value={settings.timeStep}
      onChange={handleTimestepChange}
      isDataSourcePublic={settings.source === "Public"}
      isDataSourcePrivate={settings.source === "Private"}>
      <ToolbarButton
        data-testid="timestep-trigger"
        aria-label={settings.timeStep}
        active={false}
        icon={icon}
        overflowLabel={label}
        onToggle={null}
      />
    </TimeScaleSelect>
  );
};

export default memo(TimeStepToggle);
