import { LegendItemModel } from "models";

const createLegendItem = (
  title: string,
  inFilter: boolean,
  color: string,
  textColor: string = "#555555"
): LegendItemModel => {
  const item = new LegendItemModel(title);
  item.inFilter = inFilter;
  item.color = color;
  item.fontColor = textColor;
  return item;
};

export default createLegendItem;
