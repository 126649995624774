import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { FieldTemplate } from "../models/mapLayer";

const postFieldTemplate = async (template: FieldTemplate) => {
  const response = await axios.post(
    `${GEO_MAP_SERVICE_ADDRESS}/field-templates`,
    template
  );
  if (response.status !== 200) {
    throw new Error("Failed to post field template");
  }
  return response.data;
};

export const usePostFieldTemplate = () => {
  return useMutation<string, AxiosError, unknown, unknown>(postFieldTemplate);
};
