import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_SHAPEFILE_FEATURES_QUERY_KEYS } from "../../queries/useProjectShapefileFeaturesQuery";
import { SHAPEFILE_LIST_QUERY_KEYS } from "../../queries/useShapefileListQuery";

export interface EditableGeomProperties {
  color?: string;
  opacity?: number;
  thickness?: number;
  strokeColor?: string;
}
export interface IUseUpdateBatchProjectGeomsMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
}
export interface IUpdateBatchProjectGeomsParamsT {
  shapeFileGeomIds: string[];
  update: EditableGeomProperties;
}

async function UpdateBatchProjectGeoms(params: IUpdateBatchProjectGeomsParamsT) {
  const response = await axios.put(
    `${projectServiceEndpoint}/shapefile-geom/update`,
    params
  );
  return response.data;
}

export function useUpdateBatchProjectGeomsMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess
}: IUseUpdateBatchProjectGeomsMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: IUpdateBatchProjectGeomsParamsT) => UpdateBatchProjectGeoms({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
        queryClient.invalidateQueries({
          queryKey: [SHAPEFILE_LIST_QUERY_KEYS.currentProjectShapefiles]
        });
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILE_FEATURES_QUERY_KEYS.projectShapefileFeatures]
        });
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
