import { memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import { Divider, Space } from "antd";
import { logoutUser } from "store/features/auth/authSlice";
import {
  setActiveSetting,
  setApplicationToolsOpen,
  setOrganizationSettingsOpen,
  setUserSettingsOpen
} from "store/features/userSettings/userSettingsSlice";
import styled from "styled-components/macro";

import { useUser } from "hooks";
import useBetaFeatures from "hooks/useBetaFeatures";

import UserMenuItem from "./UserMenuItem";
import UserProfileHeader from "./UserProfileHeader";

interface UserMenuProps {
  onClose?: () => void;
}
function UserMenu({ onClose = null }: UserMenuProps) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, isAdmin } = useUser();
  const { hasFeature } = useBetaFeatures();

  const signout = () => () => {
    dispatch(logoutUser());
    sessionStorage.clear();

    client.resetStore();
    dispatch(setUserSettingsOpen(false));
    dispatch(setOrganizationSettingsOpen(false));
    dispatch(setApplicationToolsOpen(false));
    history.push("/login");
  };

  const openUserSettings = () => {
    dispatch(setActiveSetting("Profile"));
    dispatch(setUserSettingsOpen(true));
    onClose && onClose();
  };

  const openOrganizationSettings = () => {
    dispatch(setActiveSetting("OrganizationProfile"));
    dispatch(setOrganizationSettingsOpen(true));
    onClose && onClose();
  };

  const openApplicationTools = () => {
    dispatch(setActiveSetting("Subscriptions"));
    dispatch(setApplicationToolsOpen(true));
    onClose && onClose();
  };

  if (!history || !user) return <>Loading your info...</>;

  return (
    <Wrapper direction="vertical" split={<StyledDivider />}>
      <UserMenuItem>
        <UserProfileHeader />
      </UserMenuItem>

      <UserMenuItem onClick={() => openUserSettings()}>Your Settings</UserMenuItem>

      {isAdmin && (
        <UserMenuItem onClick={() => openOrganizationSettings()}>
          Your Organization Settings
        </UserMenuItem>
      )}

      {hasFeature("App Tools") && (
        <UserMenuItem onClick={() => openApplicationTools()}>
          Application Tools
        </UserMenuItem>
      )}

      <UserMenuItem onClick={signout()}>Sign out</UserMenuItem>
    </Wrapper>
  );
}

export default memo(UserMenu);

const Wrapper = styled(Space)`
  width: 30rem;
  color: var(--color-text-60);
  margin: -4px -16px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;
