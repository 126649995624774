import {
  DEFAULT_AXIS_FONT_SIZE,
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_LEGEND_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE
} from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import { EChartOption } from "echarts";
import { applySpacingToChartOptions } from "utils/chart";

import { IChartType } from "models/chart";
import { IChartResult } from "models/model";

import {
  IChartPreset,
  IMultiphaseChartSettings
} from "components/multiphase-chart/models/shared.models";

import { ALL_CHART_TYPES } from "../../../constants";
import { IAxisMinMax, LegendT } from "../context/ChartSettingsContext";
import { chart, shared } from "./chart";

function optionSelector(chartType: IChartType): (
  opt: IChartPreset,
  data: IChartResult,
  chartSettings: IMultiphaseChartSettings,
  axisMinMax: IAxisMinMax,
  legend: LegendT
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any {
  switch (chartType) {
    case ALL_CHART_TYPES.RateCum:
      return chart.getRateCumChartOptions;
    case ALL_CHART_TYPES.RateTime:
      return chart.getRateTimeChartOptions;
    case ALL_CHART_TYPES.RateDate:
      return chart.getRateDateChartOptions;
    case ALL_CHART_TYPES.CumTime:
      return chart.getCumTimeChartOptions;
    default:
      return null;
  }
}

export function getEchartOptionsFromResult(
  chartOption: IChartPreset,
  chartData: IChartResult,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartInstance: any,
  chartSettings: IMultiphaseChartSettings,
  axisMinMax: IAxisMinMax,
  legend: LegendT
): EChartOption {
  if (!chartData) {
    return {};
  }
  const chartType = shared.getChartType(chartOption.chartTypeId);
  const selector = optionSelector(chartType);
  if (!selector) {
    throw `${chartType?.label} is not supported`;
  }

  // Use the chart type selector to get the options.
  const options = selector(chartOption, chartData, chartSettings, axisMinMax, legend);

  // Apply spacing to the chart
  const spacedOptions = applySpacingToChartOptions(
    options,
    chartInstance,
    {
      titleFontSize: DEFAULT_TITLE_FONT_SIZE,
      axisFontSize: DEFAULT_AXIS_FONT_SIZE,
      axisLabelFontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
      legendFontSize: DEFAULT_LEGEND_FONT_SIZE,
      fontFamily: THEME_FONT_FAMILY
    },
    true
  );

  return spacedOptions;
}
