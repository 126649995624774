import { useDispatch, useSelector } from "react-redux";

import {
  ForecastActivityType,
  MCDANIEL_FOLDER_ID,
  NOVI_FOLDER_ID,
  TYPE_WELLS,
  USER_ARPS
} from "constants/settings.constants";
import { setCheckedKeys } from "store/features";
import { RootState } from "store/rootReducer";
import { getDefaultRateUnits, productStringToProductTypeEnum } from "utils";
import { convert } from "utils/units/unitConversion";

import { ForecastFolder, UserArpsItem } from "models/UserArpsModel";

import useForecastPreferenceQuery from "../queries/useForecastPreferenceQuery";
import { numberOfMonthsBetweenDates } from "../utils/dates";
import { groupArpsSegmentsByProduct } from "../utils/groupArpsSegmentsByProduct";

export default function useForecastFolderFetcher(type) {
  const dispatch = useDispatch();
  const forecastPreference = useForecastPreferenceQuery().data;
  // Arps Selectors
  const checkedKeys = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "checkedTypeWellKeys" : "checkedForecastKeys"]
  );

  const useMcDanielForecasts = useSelector(
    (state: RootState) => state.arps.useMcDanielForecasts
  );
  const useNoviForecasts = useSelector((state: RootState) => state.arps.useNoviForecasts);
  function getForecastFoldersFromFoldersData(data, type: ForecastActivityType) {
    const groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const userArps =
      data?.folders?.flatMap((f) => {
        const folders = [];
        if (f.forecasts && f.forecasts.length > 0) {
          // Forecast
          if (type === USER_ARPS) {
            // Separate folders by reserve category values
            const grouped = groupBy(f.forecasts, "reserveCategory");
            for (const [reserveCategory, forecasts] of Object.entries(grouped)) {
              const folderItem = {
                folderId: f.folderId,
                reserveCategory: reserveCategory,
                folderName: f.name,
                name: f.name,
                projectId: f.projectId,
                forecasts: [],
                wellData: f.wellData,
                wellList: [],
                parentId: f.parentId,
                order: f.order
              };

              folderItem.forecasts.push(
                ...(forecasts as UserArpsItem[]).map((f) => {
                  let segments = [];
                  const arpsGroupedByProduct = groupArpsSegmentsByProduct(f.arps ?? []);
                  for (const product of Object.keys(arpsGroupedByProduct)) {
                    const productSegments = arpsGroupedByProduct[product];
                    if (productSegments.length > 2) {
                      const seg1 = productSegments[0];
                      //set switch month for 3 segments
                      const switchMonth =
                        seg1.switchMonth ??
                        numberOfMonthsBetweenDates(
                          new Date(seg1.startDate),
                          new Date(seg1.endDate)
                        );
                      segments = segments
                        .concat([{ ...seg1, switchMonth }])
                        .concat([...productSegments.slice(1)]);
                    } else {
                      segments = segments.concat(productSegments);
                    }
                  }
                  const constantsWithUnits = [];
                  if (f.constants) {
                    for (const constant of f.constants) {
                      const constantWithUnit = { ...constant };

                      //if it has a unit we convert it to the default ratio unit,
                      //otherwise we assume it is database unit and convert it to the default ratio unit
                      constantWithUnit.value = constant.unit
                        ? convert(
                            constantWithUnit.value,
                            constantWithUnit.unit,
                            getDefaultRateUnits(
                              productStringToProductTypeEnum(constant.product)
                            )
                          )
                        : constantWithUnit.value * 1000;
                      constantWithUnit.unit = getDefaultRateUnits(
                        productStringToProductTypeEnum(constant.product)
                      );
                      constantsWithUnits.push(constantWithUnit);
                    }
                  }
                  return { ...f, arps: segments, constants: constantsWithUnits };
                })
              );
              folderItem.forecasts.sort((a, b) => {
                const orderComparison = a.order - b.order;

                if (orderComparison === 0) {
                  return a.name.localeCompare(b.name);
                }

                return orderComparison;
              });

              folders.push(new ForecastFolder(folderItem));
            }
          } else if (type === TYPE_WELLS) {
            const folderItem = {
              reserveCategory: "",
              folderId: f.folderId,
              folderName: f.name,
              name: f.name,
              projectId: f.projectId,
              forecasts: [],
              wellData: f.wellData,
              wellList: [],
              parentId: f.parentId,
              order: f.order
            };

            folderItem.forecasts.push(
              ...(f.forecasts as UserArpsItem[]).map((forecast) => {
                // Deep copy the forecast to avoid mutating the original data
                const copiedForecast = JSON.parse(JSON.stringify(forecast));
                let segments = [];
                const arpsGroupedByProduct = groupArpsSegmentsByProduct(
                  copiedForecast.arps ?? []
                );
                for (const product of Object.keys(arpsGroupedByProduct)) {
                  const productSegments = arpsGroupedByProduct[product];
                  if (productSegments.length > 2) {
                    const seg1 = productSegments[0];
                    // Set switch month for 3 segments
                    const switchMonth =
                      seg1.switchMonth ??
                      numberOfMonthsBetweenDates(
                        new Date(seg1.startDate),
                        new Date(seg1.endDate)
                      );
                    segments = segments
                      .concat([{ ...seg1, switchMonth }])
                      .concat([...productSegments.slice(1)]);
                  } else {
                    segments = segments.concat(productSegments);
                  }
                }
                const constantsWithUnits = [];
                if (copiedForecast.constants) {
                  for (const constant of copiedForecast.constants) {
                    const constantWithUnit = { ...constant };

                    // If it has a unit we convert it to the default ratio unit,
                    // otherwise we assume it is database unit and convert it to the default ratio unit
                    constantWithUnit.value = constant.unit
                      ? convert(
                          constantWithUnit.value,
                          constantWithUnit.unit,
                          getDefaultRateUnits(
                            productStringToProductTypeEnum(constant.product)
                          )
                        )
                      : constantWithUnit.value * 1000;
                    constantWithUnit.unit = getDefaultRateUnits(
                      productStringToProductTypeEnum(constant.product)
                    );
                    constantsWithUnits.push(constantWithUnit);
                  }
                }
                return {
                  ...copiedForecast,
                  arps: segments,
                  constants: constantsWithUnits
                };
              })
            );

            folderItem.forecasts.sort((a, b) => {
              const orderComparison = a.order - b.order;

              if (orderComparison === 0) {
                return a.name.localeCompare(b.name);
              }

              return orderComparison;
            });

            folders.push(new ForecastFolder(folderItem));
          }
        } else {
          folders.push(
            new ForecastFolder({
              folderId: f.folderId,
              reserveCategory: null,
              name: f.name,
              folderName: f.name,
              projectId: f.projectId,
              forecasts: [],
              parentId: f.parentId,
              order: f.order
            })
          );
        }
        return folders;
      }) || [];

    if (type === USER_ARPS) {
      if (forecastPreference) {
        const sortedData = forecastPreference.sort((a, b) => a.order - b.order);
        const forecastSources = {
          McDaniel: {
            folderId: MCDANIEL_FOLDER_ID,
            name: "McDaniel"
          },
          Novi: {
            folderId: NOVI_FOLDER_ID,
            name: "Novi Labs"
          }
        };

        for (const source of sortedData) {
          const dataSourceDetails = forecastSources[source.dataSourceName];
          if (dataSourceDetails) {
            userArps.push(
              new ForecastFolder({
                folderId: dataSourceDetails.folderId,
                reserveCategory: null,
                name: dataSourceDetails.name,
                folderName: "",
                projectId: "",
                forecasts: [],
                parentId: "",
                order: 0
              })
            );
          }
        }
      }

      // TODO BF: Not sure if this is the right place to do this, I think the intention was to initalize the checked keys in the project forecasts,
      // but now this hook is being used in other places as well, but I can't foresee negative side effects atm.
      if (useMcDanielForecasts && checkedKeys.indexOf(MCDANIEL_FOLDER_ID) === -1) {
        dispatch(
          setCheckedKeys({
            type: type,
            checkedKeys: [...checkedKeys, MCDANIEL_FOLDER_ID]
          })
        );
      }

      if (useNoviForecasts && checkedKeys.indexOf(NOVI_FOLDER_ID) === -1) {
        dispatch(
          setCheckedKeys({ type: type, checkedKeys: [...checkedKeys, NOVI_FOLDER_ID] })
        );
      }
    }

    return userArps;
  }

  return { getForecastFoldersFromFoldersData };
}
