export const addNormalizationBasisToTitle = (title, normalizeBy) => {
  const units = [
    "bbl/d",
    "Mcf/d",
    "bbl/MMcf",
    "scf/bbl",
    "Mbbl/d",
    "MMcf/d",
    "bbl/bbl",
    "Mbbl",
    "MMcf",
    "MMbbl/d",
    "Bcf/d",
    "Bcf",
    "BOE",
    "BOE/d",
    "MBOE",
    "MBOE/d"
  ];

  const foundUnit = units.find((unit) => title.includes(unit));
  return title.replace(foundUnit, `${foundUnit}/${normalizeBy.per}${normalizeBy.unit}`);
};
