import { IProjectLayer } from "components/project/layers/queries";

import { FILL_GEOM_BIN_TYPE, STROKE_GEOM_BIN_TYPE } from "../constants";

interface AddGeomBinLayersParams {
  addLayer;
  mapbox: mapboxgl.Map;
  layerList: IProjectLayer[];
  layerName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geomBin: any;
}

export function addGeomBinLayers(params: AddGeomBinLayersParams) {
  const { addLayer, mapbox, layerName, geomBin } = params;

  geomBin.geom = JSON.parse(geomBin.geom);
  mapbox.addSource(layerName, {
    type: "geojson",
    data: geomBin.geom
  });

  const layerStrokeName = `${layerName}${STROKE_GEOM_BIN_TYPE}`;
  const layerFillName = `${layerName}${FILL_GEOM_BIN_TYPE}`;

  // Stroke Layer
  addLayer(
    {
      id: layerStrokeName,
      type: "line",
      source: layerName,
      layout: {},
      paint: {
        "line-color": geomBin.strokeColor,
        "line-width": geomBin.thickness,
        "line-opacity": 1
      }
    },
    null
  );

  // Fill Layer
  addLayer(
    {
      id: layerFillName,
      type: "fill",
      source: layerName,
      layout: {},
      paint: {
        "fill-color": geomBin.color,
        "fill-opacity": geomBin.opacity
      }
    },
    layerStrokeName
  );
}
