function roundSteps(value: number): number {
  if (value <= 1) {
    return value;
  }

  let divisor: number;
  if (value <= 100) {
    divisor = 1;
  } else if (value <= 1000) {
    divisor = 10;
  } else {
    divisor = 100;
  }

  return Math.round(value / divisor) * divisor;
}

export interface IpdbBin {
  lessThan: number;
  binSize: number;
  greaterThan: number;
}

export const getIpdbBin = (
  minVal: number,
  binCount: number,
  maxVal: number,
  toRound: boolean
): IpdbBin => {
  if (minVal === null && maxVal === null && binCount === null) {
    return { lessThan: null, binSize: null, greaterThan: null };
  }

  const updatedMin = toRound ? Math.round(minVal) : minVal;
  let updatedMax = toRound ? Math.round(maxVal) : maxVal;

  if (updatedMin == updatedMax) {
    updatedMax += 1;
  }

  const steps = (updatedMax - updatedMin) / binCount;
  let updatedSteps = toRound ? roundSteps(steps) : steps;
  if (updatedMax - updatedMin < updatedSteps) {
    updatedSteps = updatedMax - updatedMin;
  }

  return { lessThan: updatedMin, binSize: updatedSteps, greaterThan: updatedMax };
};
