// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect } from "react";

import { mdiCheckCircle } from "@mdi/js";
import { Table } from "antd";
import styled from "styled-components";

import { useUser } from "hooks";

import { Heading } from "../base";
import { useGetPlaysList } from "./hooks";

const Subscriptions = () => {
  const user = useUser();
  const { data, mutate, isLoading } = useGetPlaysList();

  useEffect(() => {
    mutate();
  }, []);

  const modules: { [play: string]: number[] } = JSON.parse(user?.user?.modules ?? "{}");

  const subscriptionModules = data
    ?.filter((x) => !!x)
    ?.sort((a, b) => a.localeCompare(b))
    ?.map((x) => ({
      key: x,
      play: x,
      production: modules[x]?.includes(0),
      forecast: modules[x]?.includes(1),
      geology: modules[x]?.includes(2),
      threeDGeoModel: modules[x]?.includes(3)
    }));

  const moduleColumns = [
    {
      title: "Play",
      dataIndex: "play",
      key: "play",
      render: (value) => {
        return <span data-testid={`play-${value}`}>{value}</span>;
      }
    },
    {
      title: "Production",
      key: "production",
      dataIndex: "production",
      render: (value, record) => {
        return value ? (
          <Icon path={mdiCheckCircle} size={1.3} data-testid={`${record.play}-prod`} />
        ) : null;
      }
    },
    {
      title: "Forecast",
      key: "forecast",
      dataIndex: "forecast",
      render: (value, record) => {
        return value ? (
          <Icon
            path={mdiCheckCircle}
            size={1.3}
            data-testid={`${record.play}-forecast`}
          />
        ) : null;
      }
    },
    {
      title: "Geology",
      key: "geology",
      dataIndex: "geology",
      render: (value, record) => {
        return value ? (
          <Icon path={mdiCheckCircle} size={1.3} data-testid={`${record.play}-geo`} />
        ) : null;
      }
    },
    {
      title: "3D Geo Model",
      key: "threeDGeoModel",
      dataIndex: "threeDGeoModel",
      render: (value, record) => {
        return value ? (
          <Icon path={mdiCheckCircle} size={1.3} data-testid={`${record.play}-3d`} />
        ) : null;
      }
    }
  ];

  return (
    <>
      <RootContainer>
        <Container>
          <Heading element="h4">Subscriptions</Heading>
        </Container>
        <SectionContainer>
          <Table
            dataSource={subscriptionModules}
            columns={moduleColumns}
            rowKey="key"
            pagination={false}
            loading={isLoading}
          />
        </SectionContainer>
      </RootContainer>
    </>
  );
};

export default Subscriptions;

const RootContainer = styled.div`
  max-width: 800px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  padding: 18px 0;

  svg {
    color: var(--color-primary);
  }

  .ant-table-cell {
    padding: 2px 10px;
  }
`;
