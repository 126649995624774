import axios from "axios";

import { fileServiceEndpoint } from "api/files";

import { useFilesContext, useFilesDispatch } from "../context";

export function useDownloadFile() {
  const { filesType } = useFilesContext();
  const dispatch = useFilesDispatch();

  const downloadFile = (filename: string, fileSize: number) => {
    const cancelTokenSource = axios.CancelToken.source();

    const downloadChunks = async () => {
      try {
        const CHUNK_SIZE = 1024 * 1024 * 4;
        const fileData = [];
        let downloadedChunks = 0;
        const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
          if (cancelTokenSource.token.reason) {
            break;
          }

          const response = await axios.get(
            `${fileServiceEndpoint}/files/${filesType}/${filename}`,
            {
              params: { chunkIndex },
              responseType: "blob",
              cancelToken: cancelTokenSource.token
            }
          );

          fileData.push(response.data);
          downloadedChunks += 1;
          const progress = Math.round((downloadedChunks / totalChunks) * 100);
          dispatch({ payload: { downloadFileProgress: progress } });
        }

        if (!cancelTokenSource.token.reason) {
          // Combine all the chunks into a single Blob
          const completeFile = new Blob(fileData);
          const url = window.URL.createObjectURL(completeFile);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.error("Download error:", error);
        }
      }
    };

    const cancelDownload = () => {
      cancelTokenSource.cancel("Download cancelled by the user.");
    };
    return {
      downloadChunks,
      cancelDownload
    };
  };

  return { downloadFile };
}
