import { Suspense, lazy } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { CSSTransition } from "react-transition-group";

import { RootState } from "store/rootReducer";
import { retry } from "utils";
import * as Views from "views";

import OrganizationSettingsLayout from "components/org-settings/OrganizationSettingsLayout";
import FullPost from "components/user-documentation/FullPost";
import { useInitialUserContextLoader } from "components/user/hooks";

import "./App.css";

const Home = lazy(() => retry(() => import("./views/Home")));
const Components = lazy(() => retry(() => import("./views/Components")));

type AppRouterPropsT = {
  doneLoading: boolean;
};
const AppRouter = ({
  //tells the loading screen to redirect to either /app or /login
  doneLoading = false
}: AppRouterPropsT): JSX.Element => {
  // selectors
  const user = useSelector((state: RootState) => state.auth.user);
  const userSettingsOpen = useSelector(
    (state: RootState) => state.userSetting.userSettingsOpen
  );
  const organizationSettingsOpen = useSelector(
    (state: RootState) => state.userSetting.organizationSettingsOpen
  );
  const appToolsOpen = useSelector(
    (state: RootState) => state.userSetting.applicationToolsOpen
  );

  // hooks
  useInitialUserContextLoader();

  const reCaptchaV3SiteKey = "6Lfo0tEgAAAAABh8YWjqn7BcRtWhRyEv3sRrQQlY";

  return (
    <Router>
      {organizationSettingsOpen ? (
        <OrganizationSettingsLayout authenticated={!!user && user.role === "Admin"} />
      ) : null}
      {userSettingsOpen ? <Views.SettingsLayout authenticated={!!user} /> : null}
      {appToolsOpen ? <Views.AppTools authenticated={!!user} /> : null}
      <Route exact path="/loading">
        <Views.Loading redirect={doneLoading} />
      </Route>
      <Route exact path="/">
        {user ? (
          <Suspense fallback={<Views.Loading />}>
            <Redirect to="/app" />
          </Suspense>
        ) : (
          <Views.Loading redirect={doneLoading} />
        )}
      </Route>
      <Route exact path="/app">
        {user ? (
          <Suspense fallback={<Views.Loading />}>
            <Home />
          </Suspense>
        ) : (
          <Views.Loading redirect={doneLoading} />
        )}
      </Route>
      <Route path="/user-documentation-post/:id">
        {user ? (
          <Suspense fallback={<Views.Loading />}>
            <FullPost />
          </Suspense>
        ) : (
          <Views.Loading redirect={doneLoading} />
        )}
      </Route>

      <Route path="/single/chart/:id">
        <Views.SingleComponentChartPane />
      </Route>
      <Route path="/single/multiphase-chart/:id">
        <Views.SingleComponentMultiphaseChartPane />
      </Route>

      <Route path="/standalone/dashboard/:id">
        <Views.StandaloneDashboard />
      </Route>

      <Route path="/(forgot-password|login|reset-password|mfa-login|signup|admin-signup)">
        <Views.CardWrapper>
          <Route exact path="/forgot-password">
            {({ match }) => {
              return (
                <CSSTransition
                  in={match != null}
                  classNames="page"
                  unmountOnExit
                  timeout={300}>
                  <Views.ForgotPassword />
                </CSSTransition>
              );
            }}
          </Route>
          <Route exact path="/login">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                classNames="page"
                unmountOnExit
                timeout={300}>
                <GoogleReCaptchaProvider reCaptchaKey={reCaptchaV3SiteKey}>
                  <Views.Login />
                </GoogleReCaptchaProvider>
              </CSSTransition>
            )}
          </Route>
          <Route exact path="/mfa-login">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                classNames="page"
                unmountOnExit
                timeout={300}>
                <Views.TwoFactorAuth />
              </CSSTransition>
            )}
          </Route>
          <Route path="/reset-password">
            {({ match, ...props }) => (
              <CSSTransition
                in={match != null}
                classNames="page"
                unmountOnExit
                timeout={300}>
                <Views.ResetPassword {...props} />
              </CSSTransition>
            )}
          </Route>
          <Route exact path="/signup">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                classNames="page"
                unmountOnExit
                timeout={300}>
                <Views.SignUp />
              </CSSTransition>
            )}
          </Route>
          <Route exact path="/admin-signup">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                classNames="page"
                unmountOnExit
                timeout={300}>
                <Views.AdminSignup />
              </CSSTransition>
            )}
          </Route>
        </Views.CardWrapper>
      </Route>
      <Route path="/components">
        <Components />
      </Route>
    </Router>
  );
};

export default AppRouter;
