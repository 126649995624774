import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { GetScenarioListKey } from "./useScenarioList";

const useMoveScenarioToProject = () => {
  const client = useQueryClient();
  return useMutation(
    async (input: { scenarioId: { $oid: string }; projectId: string }) => {
      const response = await axios.post<string>(
        `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario/${input.scenarioId.$oid}/move/${input.projectId}`
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        await client.invalidateQueries(GetScenarioListKey);
      }
    }
  );
};

export default useMoveScenarioToProject;
