import { UserDataSourcePreferenceCategory } from "api/dataSource";

export const MidstreamSources: UserDataSourcePreferenceCategory[] = [
  {
    category: "Volumetrics",
    dataSources: [
      {
        dataSourceName: "McDaniel",
        isActive: true,
        order: 1,
        source: ""
      }
    ]
  },
  {
    category: "HeaderData",
    dataSources: [
      {
        dataSourceName: "McDaniel",
        isActive: true,
        order: 1,
        source: ""
      }
    ]
  }
];
