// eslint-disable-next-line import/no-named-as-default
import { useState } from "react";

import { Alert, Collapse, List, Row } from "antd";
import styled from "styled-components";

import { WellListRecordCollection } from "models/UserArpsModel";

import BusyIndicator from "../../data-table/BusyIndicator";
import { CopyWellListButton } from "./CopyWellListButton";

export interface ForecastWellListRecords {
  data: WellListRecordCollection;
  isLoading: boolean;
}

export const ForecastWellListRecords = function ({
  data,
  isLoading
}: ForecastWellListRecords) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  function getListItem(wellListRecord) {
    const changeDate = new Date(wellListRecord.date);

    const renderWellRecords = () => {
      return wellListRecord?.wellList?.map((record) => {
        return <StyledRow key={record}>{record}</StyledRow>;
      });
    };

    return (
      <List.Item key={wellListRecord.changeId}>
        <List.Item.Meta
          title={
            wellListRecord.changedBy +
            " " +
            changeDate.toLocaleDateString() +
            " " +
            changeDate.toLocaleTimeString() +
            " "
          }
          description={
            <div onClick={(e) => e.stopPropagation()}>
              <div></div>
              <Collapse collapsible={"icon"} ghost>
                <Collapse.Panel
                  header={
                    <HeaderWrapper>
                      <HeaderText>{`View Well List (${
                        wellListRecord?.wellList?.length || "0"
                      })`}</HeaderText>
                      <ButtonWrapper>
                        <CopyWellListButton
                          wellListRecord={wellListRecord}
                          setErrorMessage={setErrorMessage}
                        />
                      </ButtonWrapper>
                    </HeaderWrapper>
                  }
                  key={`wellList${wellListRecord.changeId}`}>
                  {renderWellRecords()}
                </Collapse.Panel>
              </Collapse>
            </div>
          }
        />
      </List.Item>
    );
  }

  return (
    <>
      {<BusyIndicator visible={isLoading} />}
      {errorMessage && (
        <AlertWrapper>
          <Alert message={errorMessage} type="error" />
        </AlertWrapper>
      )}
      {!isLoading && (
        <ListWrapper>
          <List
            dataSource={data?.wellListRecords ?? []}
            renderItem={(item) => getListItem(item)}
          />
        </ListWrapper>
      )}
    </>
  );
};

const ListWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const AlertWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #eeeeee76;
`;

const HeaderWrapper = styled.div`
  /* To align button with the header text */
  height: 23px;
  align-items: center;

  display: flex;
  gap: 12px;
`;

const HeaderText = styled.div``;

const ButtonWrapper = styled.div`
  /* To align button with the header text */
  padding-top: 2px;

  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
  }
  .ant-btn:hover {
    color: var(--color-primary);
  }
  .ant-btn.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  .ant-btn.active:hover {
    background-color: #fff;
    color: var(--color-primary);
  }
`;

const StyledRow = styled(Row)`
  font-family: var(--fontMono);
`;
