import { useQuery } from "react-query";

import axios from "axios";
import { IS_DEV_ENV, SAM_SERVICE_URI } from "constants/app.constants";

import { getXAxisMaxValue } from "../util/getXAxisMaxValue";

const useWaterBalanceQuery = (wellList) => {
  const {
    data: chartData,
    refetch: updateData,
    isFetching
  } = useQuery(
    "sam-water-balance",
    async () => {
      if (IS_DEV_ENV) {
        return devResponse;
      }
      const response = await axios.post(`${SAM_SERVICE_URI}/SamChart/water-balance`, {
        Ooip: 295.9596797619047,
        PoreVolume: 393.79703517887043,
        ShowForecast: true,
        WellPairUwis: wellList
      });
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  const xAxisMaxValue = getXAxisMaxValue(chartData?.series);
  return { chartData, updateData, isFetching, xAxisMaxValue };
};

const devResponse = {
  title: "Water Balance",
  series: [
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        2.41898515462462, 47.83214947439707, -58.16420859177579, -52.505923054005684,
        -54.21642486958783, -57.53033371437857, -27.346284172355052, -13.744237093748335,
        -0.6434086148061069, -21.7605226545857, -42.79803728746197, 7.601706679384446,
        11.780396280981865, -39.325966807238174, -23.44786015672089, -37.95122329494783,
        -17.701143298776746, -23.69441708678486, -18.11397752257783, -24.834092248679905,
        -53.477268495512156, -19.657505764941607, -15.746471304622423, -35.63175812221671,
        -27.035916976145813, -31.315228804531603, -34.991009231919875, -24.43496419713691,
        -29.28065945523266, -41.19758243688708, -16.54156987238854, -15.263734883067803,
        -10.938520040276023, -8.417290967019719, -15.605268832497565, -9.540783000537434,
        -9.393620222077736, -2.058579621262735, -23.131724975487543, -15.41335148433759,
        -13.030030771774204, -14.944201952528157, -6.6919066162851255, -7.473408374319446,
        -6.664635798641183, -13.3939779334038, -19.293925014179504, -5.6773538551079135,
        1.4431513118665862, -7.761096266829625, -18.567076962869045, -15.542891586070851,
        -20.81428748711094, -20.152625959544636, -23.22567422759292, -16.006972994501247,
        -12.346037821150757, -16.123785253416944, -6.113867043578258, -10.279093348740565,
        -18.137675008773858, -11.97806425741983, 0.7086133448755083, -12.653285734315512,
        -23.463548377195586, -20.63821169290231, -3.712602681861221, -12.895793329824635,
        -18.863774599250483, -20.67279246742688, -12.660657803135788, -12.195564770060653,
        -2.1638144003365056, 16.91140702126166, 10.429723135707626, 4.541306786137489,
        1.831003708079737, -5.1977254602603935, 2.8310242552762155, -4.422131280560859,
        -7.9628622763536665, -6.313099531293853, 7.064897375029489, -4.228886705849256,
        -2.470683720961564, -6.442161333737628, -8.940481032062275, -10.367405638037974,
        -7.4227874135322836, -3.234647844677585, -1.061693551402898, -2.882429412473675,
        -7.862556235366858, -8.961882367930574, -5.63035059150383, -12.14378586530146,
        -1.7863463857707096, -3.3186206536326313, -0.7874824451293172, -6.727597859387524,
        -4.3750998034839395, -2.305853510314726, 3.153182181013081, 8.906034518484862,
        3.5869680026439976, 6.07236039686585, 9.449317560659187, 12.264069175049563,
        4.850694905856612, 7.384367696659155, 7.280924391797683, 13.111231933094425,
        14.671065588626275, 19.704180504938318, 14.016774453648239, 14.196226871066408,
        8.413746686810208, 11.840630124624575, 15.135264572331518, 16.71425462141205,
        13.18096074241646, 16.682064325757075, 18.39284870126803, 19.84902125040524,
        18.418419082962156, 17.905710001060406, 18.897109271015843, 15.3492972187782,
        11.481858545531786, 14.73465771364495, 11.394625487275633, 14.251821231858042,
        26.205833214808784, 18.81770844688262, 10.936039023283882, 11.63174907443234,
        13.131343804760377, 13.494912074752685, 11.433430399243228, 8.779046383890154,
        -1.779979934432574, 7.266127485067031, 5.02433482706568, 6.298672661498328,
        7.3016937399539295, 3.9963461517399157, 4.342435559307867, 6.0385686050645315,
        6.815452235639463, 12.695156638081473, 9.009589582270827, 10.034434520377534,
        -16.93288532673637, 11.041601365666963, 6.712408209055498, 7.1904256342079975,
        8.311718860131156, 8.925613808229361, 13.854498647567205, 10.514747565916306,
        7.489319359392521, 7.512323127147891, 5.1213428131345, 8.620709377011426,
        12.868481031112577, 6.642372581411382, 8.67647897379884, 4.8739189322785315,
        6.873486158945896, 5.80975218093287, 8.677570307098105, 11.191887814618307,
        9.853468763166195, 11.610755562468222, 6.706469313344221, 6.470326073075139,
        4.359524253366054, 8.867229129419663, 7.080663940085619, 5.519707359014148,
        -0.7103373254662038, -2.4136284662167884, 4.596530924583675, 2.1751059616011093,
        -1.302016688013552, -0.182846830634098, -0.8678315186505969, 5.006476132997295,
        -0.3590682605514872, 11.348378710430417, 7.940295497218974, 8.425636545624737,
        2.1970023806069605, -2.548392147948597, 5.0919496040575, 7.530066859306239,
        -1.2349593296481554, 5.223883340075353, 4.011383256594936, 1.970276228488177,
        4.383409170250041, 4.403147367330762, 4.292613893166375, 2.0033627754934447,
        -0.20845990360330857, 3.567199915074726, 11.959755861997245, 10.003608613389869,
        4.737070088232498, 13.343008384092748, 9.647634593186961, 14.771334868778974,
        10.082524304549679, 14.656836841111653, 8.778096629571564, 3.3308657185888713,
        5.158499043455844, 9.584944538486816, 12.284217935008467, 11.425313398652115,
        12.629506077313524, 9.161422780040084, 10.255644940765784, 10.294648222545622,
        13.112901735113457, 11.875241298204608, 15.457141416483244, 10.605853600505167,
        7.910223741636013, 9.692481550605446, 10.47283510289887, 12.111981217921786,
        5.615153637311976, 12.136162469424793, 14.768702346781737, 17.62227289921674
      ],
      label: "IWB",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        2.41898515462462, 43.40951236263735, -5.651805330551918, -19.922947573237458,
        -28.386755708349586, -35.460347137497635, -33.72297885681412, -30.64443793203857,
        -26.54862820816254, -26.076492697066268, -27.696400608983684, -27.027364260200994,
        -25.75971424123513, -26.754871506082623, -26.566238191790823, -27.41868056661756,
        -26.69456485335463, -26.43723113858404, -25.626088052004615, -25.601856091257204,
        -27.565374423251154, -27.119595797162567, -26.19233093046641, -27.07089673117331,
        -27.067808734367915, -27.44666378511942, -28.115821537033025, -27.776608308919883,
        -27.877598685966465, -28.72272750295085, -27.783550194154394, -26.891071434780667,
        -25.761793935869992, -24.551056630655076, -23.99585642772647, -23.064399065968676,
        -22.224841192001254, -21.136544192286145, -21.241645975270984,
        -20.930685666778224, -20.60415108903876, -20.358556804775574, -19.73930122209877,
        -19.179219506967883, -18.576935377105396, -18.43282394794724, -18.459488023413662,
        -17.92565559001762, -17.25789054590291, -16.920653398880848, -16.975462278226683,
        -16.93235216206267, -17.053650383971764, -17.143970135254328, -17.33277122802913,
        -17.293924483105027, -17.158923364318813, -17.138752257274746,
        -16.810570230189615, -16.596788449182476, -16.635239177777862, -16.50654281691566,
        -16.137423406474284, -16.057259411564353, -16.250158677087857, -16.37205356281801,
        -16.035637655403935, -15.955496290857528, -16.041805880797543, -16.19314651194783,
        -16.076900365754366, -15.94620503485995, -15.548055877329137, -14.781599757083752,
        -14.22529050764888, -13.742451670166622, -13.326745372905101, -13.127022148876943,
        -12.716623215078737, -12.51757947120572, -12.394169870255611, -12.23826796864863,
        -11.754059195513522, -11.570975421993424, -11.423959404174578,
        -11.317417419329711, -11.266741065264085, -11.247443480201158,
        -11.167599783005667, -11.029058109297218, -10.830444470787825,
        -10.667719041053669, -10.604554908596453, -10.56833884143652, -10.455077127506417,
        -10.496519014243887, -10.343125983627699, -10.146967002258812, -9.882117261506817,
        -9.789410245745012, -9.625632953655582, -9.420405684733579, -9.033976251983855,
        -8.498739435480774, -8.144946079453808, -7.729477697092079, -7.214485523245928,
        -6.599864605396288, -6.2331632146529365, -5.7673244862297635, -5.2924764274632885,
        -4.609057391794091, -3.9000493495990107, -3.1513661630865775, -2.5447639837767433,
        -1.9625297782237205, -1.6756842013574968, -1.1974693995840058,
        -0.6446534565605067, -0.03264732583253549, 0.3896442949272938, 0.8881539037594921,
        1.413121920903615, 1.9333084452714422, 2.3647020183177103, 2.758550534829163,
        3.2064135890248036, 3.5248764198552163, 3.7400803146807826, 4.015888664959499,
        4.204282378362323, 4.447893426589156, 4.9062392955266265, 5.214040293750188,
        5.344848736038821, 5.494020333545335, 5.669727843590233, 5.835560471304357,
        5.963231269389335, 6.025972248329059, 5.8740011377748536, 5.903725117528547,
        5.883937207558176, 5.893677373143058, 5.925159810222391, 5.879193050946596,
        5.843303880434325, 5.847724032485169, 5.869472194649017, 6.008643139588643,
        6.075871784273779, 6.160651460234945, 6.0604491383353665, 6.13243325850561,
        6.145062288854652, 6.167631110428995, 6.213163892142659, 6.273957221301529,
        6.4301596433395725, 6.517671386798751, 6.538743573716532, 6.5577487208103635,
        6.527248828172083, 6.558263284556909, 6.620663307840724, 6.620932111957578,
        6.659793416755999, 6.624506037974909, 6.6291909673672045, 6.613741665484896,
        6.649636220390098, 6.7298617616195155, 6.783353533310055, 6.857050935103359,
        6.854482755020652, 6.848343803760426, 6.806266433225439, 6.838814483753075,
        6.842728694777778, 6.821604017609051, 6.702194178374143, 6.558155346789785,
        6.528593825253481, 6.4640391832372135, 6.352565887157602, 6.264973535800467,
        6.161547756955499, 6.150449404472602, 6.067222838820929, 6.132576506060161,
        6.155855745722949, 6.182492222640623, 6.130458006872352, 6.014286881632858,
        6.00364909758333, 6.022816131737008, 5.928072602112033, 5.919925144556304,
        5.898328312077261, 5.856514093379728, 5.840154006358844, 5.823826814574717,
        5.806330433791751, 5.770154605971323, 5.702921698144903, 5.685828063014409,
        5.754926131096605, 5.80685864444761, 5.793717304718871, 5.872036207141718,
        5.9159224330031845, 6.011240270549689, 6.056175720095958, 6.136570251351237,
        6.166201033231156, 6.138550622882355, 6.12794331224078, 6.166188445087983,
        6.229421684141001, 6.274685872585062, 6.340561783994336, 6.36757849673113,
        6.408933704155051, 6.448274856493108, 6.506339473016509, 6.528496405756923,
        6.599877341279425, 6.639556889720444, 6.651776230231032, 6.682620838170639,
        6.719317343053777, 6.771327415530792, 6.76004507483241, 6.806657859920473,
        6.879120328431898, 6.969427514901416
      ],
      label: "CWB",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    }
  ],
  layout: null,
  requestId: ""
};

export default useWaterBalanceQuery;
