import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ForecastActivityType, TYPE_WELLS } from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import { transformFolderToNestedTreeData } from "utils/arps/treeDataHelper";

export const useTreeData = (type: ForecastActivityType) => {
  const filteredForecastFolders = useSelector(
    (state: RootState) =>
      state.arps[
        type === TYPE_WELLS ? "filteredTypeWellFolders" : "filteredForecastFolders"
      ]
  );

  const treeData = useMemo(() => {
    return type === TYPE_WELLS
      ? transformFolderToNestedTreeData(filteredForecastFolders)
      : filteredForecastFolders;
  }, [type, filteredForecastFolders]);

  return {
    treeData
  };
};
