import { useQuery } from "react-query";

import axios from "axios";

import { useMapContext } from "components/map/hooks/useMapContext";

const mapServiceRoot = process.env.REACT_APP_MAP_SERVICE;

export interface MapExtent {
  minx: number;
  miny: number;
  maxx: number;
  maxy: number;
}

export interface TownshipModel {
  township: number;
  minY: number;
  maxY: number;
  centerY: number;
  scaleFactor: number;
  centerX: number;
  townshipDir: string;
}

export interface RangeModel {
  mer: number;
  merDir: string;
  range: number;
  minX: number;
  maxX: number;
  centerY: number;
  scaleFactor: number;
  centerX: number;
}

export interface TownshipRangeGridModel {
  leftTownships: TownshipModel[];
  rightTownships: TownshipModel[];
  topRanges: RangeModel[];
  bottomRanges: RangeModel[];
  extent: MapExtent;
}

export default function useTownshipRangeGrid() {
  const { bounds } = useMapContext();

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["townshipRangeGrid"],
    () => {
      if (!bounds) {
        return undefined;
      }
      return axios.get<TownshipRangeGridModel>(
        `${mapServiceRoot}/map/township-range-grid?minx=${bounds.getWest()}&miny=${bounds.getSouth()}&maxy=${bounds.getNorth()}&maxx=${bounds.getEast()}`
      );
    },
    {
      enabled: false
    }
  );
  return {
    data: data?.data,
    isLoading,
    refetch,
    isError,
    error
  };
}
