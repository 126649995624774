const getObjectPropertyValueByKey = (object, key) => {
  if (!key) return null;

  for (const prop in object) {
    if (prop === key) {
      return object[prop];
    }
  }

  return null;
};

export default getObjectPropertyValueByKey;
