import { memo } from "react";

function WOR() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="31px"
      height="33px"
      viewBox="0 0 231 235">
      <g>
        <line
          fill="none"
          stroke="currentColor"
          strokeWidth="14"
          strokeMiterlimit="10"
          x1="110.96301078796387"
          y1="41.54701042175293"
          x2="110.96301078796387"
          y2="54.561994552612305"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeWidth="14"
          strokeMiterlimit="10"
          x1="41.546003341674805"
          y1="110.9629955291748"
          x2="54.56200981140137"
          y2="110.9629955291748"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeWidth="14"
          strokeMiterlimit="10"
          x1="167.36300468444824"
          y1="110.9629955291748"
          x2="180.37899589538574"
          y2="110.9629955291748"
        />
        <polyline
          fill="none"
          stroke="currentColor"
          strokeWidth="14"
          strokeMiterlimit="10"
          points="76.25399780273438,76.25401306152344 110.9630126953125,119.63899230957031 145.6710205078125,97.94700622558594 "
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="14"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M123.97500663757324,192.37399894714355 c-4.239,0.6720000000000007 -8.586,1.02 -13.013,1.02 c-45.525,0 -82.432,-36.907 -82.432,-82.431 c0,-45.524 36.907,-82.432 82.432,-82.432 c45.525,0 82.432,36.907 82.432,82.432 c0,5.952 -0.6320000000000005,11.761 -1.831,17.354 "
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="14"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M127.16400663757327,218.22099894714353 c-5.286,0.792 -10.696,1.204 -16.201,1.204 c-59.902,0 -108.463,-48.562 -108.463,-108.462 c0,-59.902 48.56,-108.463 108.463,-108.463 c59.899,0 108.463,48.561 108.463,108.463 c0,7.439 -0.7470000000000004,14.702 -2.174,21.717 "
        />
        <g>
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="14"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M158.68500663757322,154.34599894714353 c-9.584,0 -17.354,7.771 -17.354,17.356 c0,9.584 7.771,17.354 17.354,17.354 c9.584,0 17.355,-7.771 17.355,-17.354 C176.04000663757324,162.11599894714357 168.26900663757323,154.34599894714353 158.68500663757322,154.34599894714353 z"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="14"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M210.74700663757324,232.44099894714356 L210.74700663757324,232.44099894714356 c-9.582,0 -17.353,-7.771 -17.353,-17.355 l0,0 c0,-9.581 7.771,-17.352 17.353,-17.352 l0,0 c9.585,0 17.355,7.77 17.355,17.352 l0,0 C228.10200663757325,224.66999894714354 220.33200663757322,232.44099894714356 210.74700663757324,232.44099894714356 z"
          />

          <line
            fill="none"
            stroke="currentColor"
            strokeWidth="14"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="150.00799369812012"
            y1="232.44099235534668"
            x2="219.42599296569824"
            y2="154.34599113464355"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(WOR);
