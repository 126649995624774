import { useEffect } from "react";
import { useSelector } from "react-redux";

import { AnyLayer } from "mapbox-gl";

import { RootState } from "../../store/rootReducer";
import { useMapContext } from "./hooks/useMapContext";
import { waitForStyleToLoad } from "./utils";
import { clearGeojsonSource } from "./utils/geojson";

const STICKS_LAYER = "sticks";
export default function ShowSticksOnMap() {
  const padScenario = useSelector((state: RootState) => state.app.padScenario);
  const { mapbox } = useMapContext();

  useEffect(() => {
    if (!padScenario || !mapbox) {
      return;
    }

    async function addSticksLayer() {
      if (!mapbox.isStyleLoaded()) {
        await waitForStyleToLoad(mapbox, 1000);
      }
      clearGeojsonSource(mapbox, STICKS_LAYER);
      if (!padScenario.showSticksOnMap || !padScenario.sticks) {
        return;
      }

      if (!mapbox.getSource(STICKS_LAYER)) {
        const collection = {
          type: "FeatureCollection",
          features: padScenario.sticks.map((stick) => ({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: stick.survey.map((s) => [s.x, s.y])
            },
            properties: {
              value: stick.id
            }
          }))
        };
        const layer = {
          id: STICKS_LAYER,
          type: "line",
          source: {
            type: "geojson",
            data: collection
          },
          paint: {
            "line-color": "black",
            "line-width": 3
          }
        } as AnyLayer;
        mapbox.addLayer(layer);
      }
    }

    addSticksLayer();

    return () => {
      if (mapbox && mapbox.getLayer(STICKS_LAYER)) {
        mapbox.removeLayer(STICKS_LAYER);
        mapbox.removeSource(STICKS_LAYER);
      }
    };
  }, [padScenario, mapbox]);

  return null;
}
