import { useEffect, useState } from "react";

import { ALL_CHART_TYPES } from "constants/chart.constants";

const useDataTypeFilter = (axisType, chartType) => {
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);

  const isProbit = chartType === ALL_CHART_TYPES.Probit.label;
  const isBoxPlot = chartType === ALL_CHART_TYPES.BoxPlot.label;
  const isCrossPlot = chartType === ALL_CHART_TYPES.CrossPlot.label;
  const isStackedBar = chartType === ALL_CHART_TYPES.StackedBar.label;
  const isDateTrend = chartType === ALL_CHART_TYPES.TrendDate.label;

  useEffect(() => {
    axisType === "x"
      ? setX([
          ...(isCrossPlot ? ["Number", "Integer"] : []),
          ...(isProbit ? ["Number", "Integer"] : []),
          ...(isDateTrend ? ["Date", "Integer"] : [])
        ])
      : setY([
          ...(isCrossPlot ? ["Number", "Integer"] : []),
          ...(isBoxPlot || isStackedBar || isDateTrend ? ["Number", "Integer"] : [])
        ]);
  }, [axisType, chartType, isBoxPlot, isCrossPlot, isStackedBar, isProbit, isDateTrend]);

  return axisType === "x" ? x : y;
};

export default useDataTypeFilter;
