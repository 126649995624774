import { useMutation } from "@apollo/client";

import { NEW_COMMENT } from "api/userArps";

export interface IUseAddCommentMutationParams {
  commentInput: string;
  selectedTypeWellId: string;
  onCompleted: () => void;
}

export function useAddCommentMutation({
  commentInput,
  selectedTypeWellId,
  onCompleted
}: IUseAddCommentMutationParams) {
  return useMutation(NEW_COMMENT, {
    variables: {
      input: {
        comment: commentInput,
        forecastId: selectedTypeWellId
      }
    },
    refetchQueries: ["changeRecords"],
    onCompleted: () => {
      onCompleted && onCompleted();
    }
  });
}
