import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import { Remarkable } from "remarkable";
import styled from "styled-components/macro";

import { Logo } from "components/base";
import DocumentationTag from "components/user-documentation/DocumentationTag";

import DocumentationArea from "./DocumentationArea";
import VideoPlayer from "./VideoPlayer";
import { SINGLE_POST_URL, TagT } from "./constants";
import { useNewPostsCountManager } from "./hooks";
import "./styles/markdown.css";

function FullPost() {
  const md = new Remarkable({
    html: true
  });
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const iframify = require("@noticeable/remarkable-iframify");
  md.use(iframify);
  md.use(VideoPlayer);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [post, setPost] = useState<any>({});
  const { id } = useParams();
  useNewPostsCountManager();

  const retrievePosts = useCallback(() => {
    axios.get(`${SINGLE_POST_URL}/${id}`, {}).then((response) => {
      if (response.data != null) {
        setPost(response.data);
      } else {
        setPost({});
      }
    });
  }, [id]);

  useEffect(() => retrievePosts(), [retrievePosts]);

  const getMarkup = () => {
    return { __html: md.render(post.content) };
  };

  const getDate = (date) => {
    const dateObj = new Date(Date.parse(date));
    const options = {
      month: "short" as const,
      day: "numeric" as const,
      year: "numeric" as const
    };
    return new Intl.DateTimeFormat("en-US", options).format(dateObj);
  };

  return (
    <Page>
      <DocumentationHeader>
        <Logo />
        <DocumentationTitle>Notifications</DocumentationTitle>
      </DocumentationHeader>
      <DocumentationWrapper>
        <DocumentationSideBar>
          <DocumentationArea openDocInNewTab={false} closeable={false} />
        </DocumentationSideBar>
        <PostWrapper>
          <PostContent>
            <h1>{post.title}</h1>
            <h5>{post.date !== undefined && getDate(post.date)}</h5>

            {post?.tag &&
              post.tag.map((t) => <DocumentationTag key={t} value={t as TagT} />)}

            <div dangerouslySetInnerHTML={getMarkup()} />
          </PostContent>
        </PostWrapper>
      </DocumentationWrapper>
    </Page>
  );
}

export default FullPost;

const Page = styled.div`
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-rows: auto minmax(0px, 1fr);
  align-content: start;
  padding: 0px;
  overflow: hidden;
`;

const DocumentationWrapper = styled.div`
  padding: 0px;
  display: flex;
  grid-template-rows: 100%;
`;

const DocumentationHeader = styled.div`
  width: 100%;
  height: var(--navbar-height, 4.5rem);
  display: flex;
  gap: 16px;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: 0 1px 1px var(--color-text-10);
  overflow: hidden;
  z-index: 1;
  padding-left: var(--space-4);
`;

const DocumentationTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: var(--color-text);
`;

const DocumentationSideBar = styled.div`
  width: 450px;
  height: 100%;
  border: 1px solid var(--color-text-10);
`;

const PostWrapper = styled.main`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const PostContent = styled.main`
  grid-column: 2;
  max-width: 150rem;
  min-width: 80rem;
  padding: 1.2rem 8.5rem;
`;
