import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { useChartSettings } from "../context";
import { changeSettings } from "../context/reducer/chartSettingsReducer";

/**
 * Helps syncronize global normalizeBy values,
 * with local context values
 */
const useGlobalNormalizeBy = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const globalUseNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );

  const globalUseNormalizeByRef = useRef(globalUseNormalizeBy);

  useEffect(() => {
    let values;

    if (globalUseNormalizeBy && !globalUseNormalizeByRef.current) {
      values = { useNormalizeBy: true, normalizeBy: { ...globalNormalizeBy } };
    }
    if (globalNormalizeBy && globalUseNormalizeByRef.current) {
      values = { normalizeBy: { ...globalNormalizeBy } };
    }
    if (!globalUseNormalizeBy && globalUseNormalizeByRef.current) {
      values = { useNormalizeBy: false };
    }

    changeSettings(chartSettingsDispatch, {
      ...chartSettings.settings,
      ...values
    });

    globalUseNormalizeByRef.current = globalUseNormalizeBy;
  }, [globalUseNormalizeBy, JSON.stringify(globalNormalizeBy)]);
};

export default useGlobalNormalizeBy;
