import { useMutation } from "react-query";

import axios from "axios";

import { fileServiceEndpoint } from "api/files";

import { FilesType } from "../context";

export interface IUploadFileParams {
  filesType: FilesType;
  fileChunk: Blob;
  filename: string;
  chunkIndex: number;
  totalFileSize: number;
}

export async function uploadFile(upload: IUploadFileParams) {
  const formData = new FormData();
  formData.append("chunk", upload.fileChunk);
  formData.append("filename", upload.filename);
  formData.append("chunkIndex", upload.chunkIndex.toString());
  formData.append("totalFileSize", upload.totalFileSize.toString());

  return await axios.post(
    `${fileServiceEndpoint}/files/${upload.filesType}/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
}

export const useUploadFileMutation = () => {
  return useMutation({
    mutationFn: uploadFile,
    retry: false
  });
};
