import { ProductsForMultiProductChart } from "constants/chart.constants";
import styled from "styled-components";
import { getProductIcon } from "utils/chart/getProductIcon";

import { BaseButton } from "components/base";
import ProductHeader from "components/chart/ProductHeader";
import { useChartSettings } from "components/multiphase-chart/context";
import { addProduct, setProducts } from "components/multiphase-chart/context/reducer";
import { getCurrentSeriesStyleByProductLabel } from "components/multiphase-chart/util/productStyles";

import ProductStyleSelector from "./ProductStyleSelector";

export interface IProducts {
  isPrimary: boolean;
}

const Products = ({ isPrimary }: IProducts) => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const productList = ProductsForMultiProductChart;

  const { currentPreset, isViewMode, currentTab } = chartSettings;

  const primaryCount = currentPreset.products?.filter(
    (p: { axis: string }) => p.axis === "primary"
  ).length;

  const secondaryCount = currentPreset.products?.filter(
    (p: { axis: string }) => p.axis === "secondary"
  ).length;

  const isPrimaryContaining = (prodName: string) => {
    return (
      currentPreset.products.filter(
        (p: { axis: string; name: string }) => p.axis === "primary" && p.name === prodName
      ).length > 0
    );
  };

  const isSecondaryContaining = (
    prodName: string // for newPreset
  ) =>
    currentPreset.products.filter(
      (p: { axis: string; name: string }) => p.axis === "secondary" && p.name === prodName
    ).length > 0;
  const isProductMaxed = isPrimary ? primaryCount === 2 : secondaryCount === 2;

  return (
    <>
      {productList.map((header) => {
        return (
          <ProductHeader key={header.name} header={header.name}>
            {header.items.map((prod) => {
              const isPrimaryContainingProduct = isPrimaryContaining(prod);
              const isSecondaryContainingProduct = isSecondaryContaining(prod);

              const isProductColorSelectorHidden = isViewMode
                ? true
                : !isPrimaryContainingProduct && !isSecondaryContainingProduct;

              const currentStyleColor = getCurrentSeriesStyleByProductLabel(
                prod,
                currentPreset?.style
              ).color;

              return (
                <ProductButton
                  svgcolor={currentStyleColor}
                  key={prod}
                  ellipses={false}
                  onClick={() => {
                    if (!isViewMode) {
                      const productObj = {
                        name: prod,
                        axis: isPrimary ? "primary" : "secondary"
                      };
                      if (isPrimary) {
                        if (!isPrimaryContaining(prod)) {
                          if (!isSecondaryContaining(prod)) {
                            if (primaryCount < 2) {
                              addProduct(chartSettingsDispatch, {
                                name: prod,
                                axis: "primary"
                              });
                            }
                          }
                        } else {
                          const filteredProducts = currentPreset.products.filter(
                            (a) => JSON.stringify(a) !== JSON.stringify(productObj)
                          );

                          setProducts(chartSettingsDispatch, filteredProducts);
                        }
                      } else {
                        // isSecondary
                        if (!isSecondaryContaining(prod)) {
                          if (!isPrimaryContaining(prod)) {
                            if (secondaryCount < 2) {
                              addProduct(chartSettingsDispatch, {
                                name: prod,
                                axis: "secondary"
                              });
                            }
                          }
                        } else {
                          const filteredProducts = currentPreset.products.filter(
                            (a) => JSON.stringify(a) !== JSON.stringify(productObj)
                          );

                          setProducts(chartSettingsDispatch, filteredProducts);
                        }
                      }
                    }
                  }}
                  appearance=""
                  className={
                    !isViewMode
                      ? isPrimary
                        ? `${
                            isPrimaryContaining(prod)
                              ? "isSelected"
                              : isSecondaryContaining(prod)
                              ? "isAlreadyChosen"
                              : isProductMaxed
                              ? "isViewMode"
                              : null
                          }`
                        : `${
                            isSecondaryContainingProduct
                              ? "isSelected"
                              : isPrimaryContainingProduct
                              ? "isAlreadyChosen"
                              : isProductMaxed
                              ? "isViewMode"
                              : null
                          }`
                      : isPrimary
                      ? `${
                          isPrimaryContainingProduct
                            ? "isSelectedViewMode"
                            : isSecondaryContainingProduct
                            ? "isAlreadyChosen"
                            : "isViewMode"
                        }`
                      : `${
                          isSecondaryContainingProduct
                            ? "isSelectedViewMode"
                            : isPrimaryContainingProduct
                            ? "isAlreadyChosen"
                            : "isViewMode"
                        }`
                  }>
                  {isPrimaryContainingProduct || isSecondaryContainingProduct ? (
                    <PrimarySecondaryIndicator
                      isactive={
                        (currentTab === "Primary" && isPrimaryContainingProduct) ||
                        (currentTab === "Secondary" && isSecondaryContainingProduct)
                      }>
                      {isPrimaryContainingProduct ? "P" : "S"}
                    </PrimarySecondaryIndicator>
                  ) : null}
                  {getProductIcon(prod)}
                  <div>{prod}</div>
                  <ProductStyleSelector
                    isHidden={isProductColorSelectorHidden}
                    productLabel={prod}
                  />
                </ProductButton>
              );
            })}
          </ProductHeader>
        );
      })}
    </>
  );
};

export default Products;

const ProductButton = styled(BaseButton)`
  position: relative;
  display: inline-block;
  min-height: 60px;
  height: 90px;
  border-radius: 4px;
  color: #041c2c;
  background-color: unset;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;

  &.isSelected {
    cursor: pointer;
    background: #d8eeee;
    color: #041c2c;
    svg {
      color: ${(props) => props.svgcolor || "#a2aaad"};
    }
  }

  span {
    margin-top: 0;
  }
  .label {
    line-height: 18px;
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.isSelected:hover {
    cursor: pointer;

    background: #b8e0e0;
    svg {
      color: ${(props) => props.svgcolor || "#a2aaad"};
    }
  }

  svg {
    min-height: 40px;
    color: #a2aaad;
    transition: color var(--duration-short);
  }

  &.isAlreadyChosen {
    cursor: default;
    background: rgba(var(--color-shadow-rgb), 0.2);
    color: black;
    svg {
      color: ${(props) => props.svgcolor || "#a2aaad"};
    }
  }

  &.isAlreadyChosen:hover {
    cursor: default;
  }

  &.isViewMode:hover {
    cursor: default;
    background: none;
    svg {
      color: #a2aaad;
    }
  }

  &.isSelectedViewMode {
    cursor: default;
    background: #d8eeee;
    color: #041c2c;
    svg {
      color: ${(props) => props.svgcolor || "#a2aaad"};
    }
  }

  &.isSelectedViewMode:hover {
    cursor: default;
    background: #d8eeee;
    svg {
      color: ${(props) => props.svgcolor || "#a2aaad"};
    }
  }

  caret-color: transparent;
`;

const PrimarySecondaryIndicator = styled.div`
  position: absolute;
  top: -1px;
  left: 44px;
  width: 15px;
  height: 15px;
  background-color: ${(props) => (props.isactive ? "#50b1a5" : "#a2aaad")};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #f8f8f8;
`;
