import DocBugFixIcon from "components/icons/DocBugFixIcon";
import DocForecastUpdateIcon from "components/icons/DocForecastUpdateIcon";
import DocKnownIssueIcon from "components/icons/DocKnownIssueIcon";
import DocNewFeatureIcon from "components/icons/DocNewFeatureIcon";
import DocProductionUpdateIcon from "components/icons/DocProductionUpdateIcon";
import DocReleaseNoteIcon from "components/icons/DocReleaseNoteIcon";
import DocTechnicalReferenceIcon from "components/icons/DocTechnicalReferenceIcon";
import DocTipIcon from "components/icons/DocTipIcon";

const BASE_URL = process.env.REACT_APP_USER_DOCUMENTATION_SERVICE;

export const DELETE_POST_URL = `${BASE_URL}/remove`;
export const DELETE_FILE_URL = `${BASE_URL}/delete`;
export const DOWNLOAD_URL = `${BASE_URL}/download`;
export const POST_URL = `${BASE_URL}/create`;
export const SINGLE_POST_URL = `${BASE_URL}/posts`;
export const UPDATE_URL = `${BASE_URL}/update`;
export const UPLOAD_URL = `${BASE_URL}/upload`;

export const tagMap = Object.freeze({
  "Bug Fix": {
    label: "Bug Fix",
    icon: DocBugFixIcon,
    color: "#cf1322",
    bgColor: "#fff1f0",
    borderColor: "#ffa39e"
  },
  "Forecast Update": {
    label: "Forecast Update",
    icon: DocForecastUpdateIcon,
    color: "#c41d7f",
    bgColor: "#fff0f6",
    borderColor: "#ffadd2"
  },
  "Known Issue": {
    label: "Known Issue",
    icon: DocKnownIssueIcon,
    color: "#d4380d",
    bgColor: "#fff2e8",
    borderColor: "#ffbb96"
  },
  "New Feature": {
    label: "New Feature",
    icon: DocNewFeatureIcon,
    color: "#08979c",
    bgColor: "#e6fffb",
    borderColor: "#87e8de"
  },
  "Production Update": {
    label: "Production Update",
    icon: DocProductionUpdateIcon,
    color: "#389e0d",
    bgColor: "#f6ffed",
    borderColor: "#b7eb8f"
  },
  "Release Notes": {
    label: "Release Notes",
    icon: DocReleaseNoteIcon,
    color: "#531dab",
    bgColor: "#f9f0ff",
    borderColor: "#d3adf7"
  },
  "Technical Reference": {
    label: "Technical Reference",
    icon: DocTechnicalReferenceIcon,
    color: "#1d39c4",
    bgColor: "#f0f5ff",
    borderColor: "#adc6ff"
  },
  Tip: {
    label: "Tip",
    icon: DocTipIcon,
    color: "#d48806",
    bgColor: "#fffbe6",
    borderColor: "#ffe58f"
  }
});

export const tags = Object.keys(tagMap);
export type TagT = keyof typeof tagMap;
