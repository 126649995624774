import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { Scenario } from "../models/scenario";

export interface PadScenarioContextState {
  scenario: Scenario;
  scenarioList: Scenario[];
}

type PadScenarioContextAction = { type: "SET_SCENARIO"; payload: Scenario };
type ScenarioListAction = { type: "SET_SCENARIO_LIST"; payload: Scenario[] };

export type PadActions = PadScenarioContextAction | ScenarioListAction;

// Reducer function to handle state updates
function reducer(state: PadScenarioContextState, action: PadActions) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_SCENARIO":
        draft.scenario = action.payload;
        break;
      case "SET_SCENARIO_LIST":
        draft.scenarioList = action.payload;
        break;
      default:
        break;
    }
  });
}

// Create the context with a default value
export const PadScenarioContext = createContext<
  | {
      state: PadScenarioContextState;
      dispatch: React.Dispatch<PadActions>;
    }
  | undefined
>(undefined);

// Create a provider component
export const PadScenarioProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    scenario: {} as Scenario,
    scenarioList: []
  });

  return (
    <PadScenarioContext.Provider value={{ state, dispatch }}>
      {children}
    </PadScenarioContext.Provider>
  );
};
