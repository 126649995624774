import { LegendDimensionsT, LegendPositionT, ParentDimensionsT } from "../Legend";

export type PositionsT = "NE" | "NW" | "SE" | "SW";

export const getPositionFromChartSettings = (
  legendDimensions: LegendDimensionsT,
  parentDimensions: ParentDimensionsT,
  position: PositionsT
): LegendPositionT => {
  if (!legendDimensions || !parentDimensions || !position) return { x: 0, y: 0 };

  const getNE = () => ({
    x: parentDimensions?.width - legendDimensions?.width - 20,
    y: 50 // Extra to offset the title and toolbar
  });

  switch (position) {
    case "NE": {
      const ne = getNE();
      return ne;
    }
    case "NW": {
      const nw = { x: 20, y: 50 }; // Extra Y to offset the title and toolbar
      return nw;
    }
    case "SE": {
      const se = {
        x: parentDimensions.width - legendDimensions.width - 20,
        y: parentDimensions.height - legendDimensions.height - 20
      };
      return se;
    }
    case "SW": {
      const sw = {
        x: 20,
        y: parentDimensions.height - legendDimensions.height - 20
      };
      return sw;
    }
    default: {
      // eslint-disable-next-line no-console
      console.warn("Please provide a position");
      const ne = getNE();
      return ne;
    }
  }
};
