import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { IGroupByListColumn } from "models";

export default function useColumns(): IGroupByListColumn[] {
  // Get category properties for column and header
  const categories = useSelector((state: RootState) => state.groupBy.categoryList);

  return useMemo(() => {
    return categories.reduce((list, category) => {
      list.push(...category.columns);
      return list;
    }, []);
  }, [categories]);
}

export function useFacilityColumns(): IGroupByListColumn[] {
  // Get category properties for column and header
  const facilityCategories = useSelector(
    (state: RootState) => state.groupBy.facilityFields
  );

  return useMemo(() => {
    return facilityCategories.reduce((list, category) => {
      list.push(...category.columns);
      return list;
    }, []);
  }, [facilityCategories]);
}
