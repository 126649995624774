const RADIUS = 6378137.0; /* in meters on the equator */

export const radianToDegree = (angle) => {
  return angle * (180.0 / Math.PI);
};

export const mercatorScaleFactor = (lat) => {
  const latRadian = degreeToRadian(lat);
  const scale = 1 / Math.cos(latRadian);
  return scale;
};

export const degreeToRadian = (angle) => {
  return (Math.PI * angle) / 180.0;
};

/* These functions take their length parameter in meters and return an angle in degrees */

export const y2Lat = (aY) => {
  return radianToDegree(Math.atan(Math.exp(aY / RADIUS)) * 2 - Math.PI / 2);
};

export const x2Lon = (aX) => {
  return radianToDegree(aX / RADIUS);
};

export const lat2Y = (aLat) => {
  return Math.log(Math.tan(Math.PI / 4 + degreeToRadian(aLat) / 2)) * RADIUS;
};

export const lon2X = (aLong) => {
  return degreeToRadian(aLong) * RADIUS;
};
