import { gql } from "@apollo/client";

export const QUERY_SAVED_FILTERS = gql`
  query savedFilters($req: SavedFilterInput!) {
    savedFilters(req: $req) {
      id
      name
      projectID
      filterJSON
      owner
    }
  }
`;

export const SAVE_FILTER = gql`
  mutation saveFilter($input: SaveFilterReq!) {
    saveFilter(input: $input) {
      name
      projectID
      filterJSON
      owner
      createdDate
    }
  }
`;
export const UPDATE_FILTER_NAME = gql`
  mutation updateName($input: UpdateNameReq!) {
    updateName(input: $input)
  }
`;

export const DELETE_FILTER = gql`
  mutation deleteFilter($input: DeleteFilterReq!) {
    deleteFilter(input: $input)
  }
`;
