import { useEffect } from "react";
import { useQueryClient } from "react-query";

import { useFilesContext, useFilesDispatch } from "../context";
import { useFilesListQueryKeys } from "../queries";

export function useFileUploadWatcher() {
  // hooks
  const { uploadingFilesProgress, isUploadingFiles, filesType } = useFilesContext();
  const queryClient = useQueryClient();

  // dispatch
  const dispatch = useFilesDispatch();

  useEffect(() => {
    const hasPendingUploads = !Object.values(uploadingFilesProgress).every(
      (file) => file.progress === 100
    );

    if (!hasPendingUploads) {
      dispatch({ payload: { isUploadingFiles: false } });

      queryClient.invalidateQueries([useFilesListQueryKeys.currentFilesList, filesType]);
    }
  }, [isUploadingFiles, uploadingFilesProgress]);
}
