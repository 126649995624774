// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo, useCallback, useEffect, useState } from "react";

import { mdiCamera } from "@mdi/js";

import { useDashboardDispatch } from "components/dashboard/hooks";
import { useScreenshotContext, useScreenshotDispatch } from "components/screenshot/hooks";

import XdaToolbarButton from "./xda/XdaToolbarButton";

interface VisScreenshotToggleProps {
  onToggle?: (a) => void;
  type: string;
}

const VisScreenshotToggle = ({ onToggle, type }: VisScreenshotToggleProps) => {
  const screenshotDispatch = useScreenshotDispatch();
  const dashboardDispatch = useDashboardDispatch();
  const { settings, widget } = useScreenshotContext();

  const [screenshotOverlayVisible, setScreenshotOverlayVisible] = useState(false);

  useEffect(() => {
    const screenshotActive = widget?.widgetId === type;
    setScreenshotOverlayVisible(screenshotActive);
  }, [settings, widget]);

  useEffect(() => {
    const typeToComponentType = {
      ml: "threeDViewer",
      xda: "xdaViewer"
    };

    const componentType = typeToComponentType[type];

    const widget = screenshotOverlayVisible
      ? {
          widgetId: type,
          widgetComponentType: componentType
        }
      : null;

    screenshotDispatch({
      payload: {
        widget
      }
    });
  }, [screenshotDispatch, screenshotOverlayVisible]);

  const toggleScreenshot = useCallback(() => {
    setScreenshotOverlayVisible(!screenshotOverlayVisible);
    dashboardDispatch({ payload: { lockMap: screenshotOverlayVisible } }); //lock map
    onToggle && onToggle(screenshotOverlayVisible);
  }, [screenshotOverlayVisible]);

  return (
    <XdaToolbarButton
      key="Screenshot"
      active={screenshotOverlayVisible}
      icon={<Icon path={mdiCamera} size={1.5} />}
      tooltipText="Screenshot"
      overflowLabel="Screenshot"
      onToggle={toggleScreenshot}
    />
  );
};

export default memo(VisScreenshotToggle);
