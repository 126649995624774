import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Dropdown, Typography } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { IGroupBy } from "models";
import { EntityKind } from "models/entityKind";

import GroupBy from "components/groupBy";

import { useRecentFocusItems } from "../hooks";

const { Text } = Typography;

export const PresetDropdownContext = createContext<boolean>(false);

const PresetDropdown = ({ onGroupByChange, onRecentFocusItemClick, entityKind }) => {
  const { recentFocusItems, isPresetFieldSelected } = useRecentFocusItems(entityKind);

  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const facilityGroupBy = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );
  const [groupByState, setGroupByState] = useState<IGroupBy>(
    entityKind === EntityKind.Well ? groupBy : facilityGroupBy
  );
  useEffect(() => {
    const gb = entityKind === EntityKind.Well ? groupBy : facilityGroupBy;
    setGroupByState(gb);
  }, [groupBy, facilityGroupBy, entityKind]);

  return (
    <Wrapper>
      <GroupBy
        value={groupByState}
        placement="rightTop"
        onChange={onGroupByChange}
        entityKind={entityKind}>
        <Dropdown.Button
          className={isPresetFieldSelected ? "isSelected" : ""}
          placement="bottomRight"
          trigger={["click"]}
          icon={<AccessTimeOutlinedIcon />}
          menu={{
            items: recentFocusItems,
            onClick: onRecentFocusItemClick
          }}>
          <StyledText ellipsis>
            {isPresetFieldSelected ? groupByState.title : "More Fields"}
          </StyledText>
          <KeyboardArrowRightIcon fontSize="large" />
        </Dropdown.Button>
      </GroupBy>
    </Wrapper>
  );
};

export default PresetDropdown;

const Wrapper = styled.div`
  min-width: 0;

  .isSelected .ant-btn-compact-first-item {
    color: var(--color-white);
    background-color: var(--color-text);
    font-weight: var(--fontWeightMedium);
  }

  .ant-btn-compact-first-item {
    min-width: 0;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledText = styled(Text)`
  color: inherit;
`;
