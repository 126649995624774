import { BetaFeatures } from "hooks/useBetaFeatures";

import { DashboardCapabilities } from "models/dashboard";
import { Dashboard } from "models/workspace";

import {
  TypeWellDetailsWidgetKey,
  TypeWellEditorWidgetKey
} from "../constants/widgets.constants";

/**
 * Get the capabilities of a specific dashboard.
 *
 * @param Dashboard dashboard The dashboard to the capabilities for.
 * @returns DashboardCapabilities The capabilities of the dashboard.
 */
export const getDashboardCapabilities = (
  dashboard: Dashboard,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasFeature: (feature: BetaFeatures) => boolean
): DashboardCapabilities => {
  let hasMapWidget = false;
  let hasXdaWidget = false;
  let hasSingleWellWidget = false;
  let hasThreeDWidget = false;
  let hasMultiProductWidget = false;
  let hasWellCardWidget = false;
  let hasTypeWellEditorWidget = false;
  let hasTypeWellDetailsWidget = false;
  let hasFacilityTicketWidget = false;
  let hasTypeLogWidget = false;

  if (dashboard && dashboard.widgets?.length > 0) {
    hasMapWidget = dashboard.widgets.filter((d) => d.type === "map").length > 0;

    hasXdaWidget = dashboard.widgets.filter((d) => d.type === "xda").length > 0;

    hasSingleWellWidget =
      dashboard.widgets.filter(
        (d) => d.type === "info" || d.type === "completion" || d.type === "survey-vis"
      ).length > 0;

    hasThreeDWidget = dashboard.widgets.filter((d) => d.type === "threed").length > 0;

    hasMultiProductWidget =
      dashboard.widgets.filter((d) => d.type === "multiphase-chart").length > 0;

    hasWellCardWidget =
      dashboard.widgets.filter((d) => d.type === "well-card").length > 0;

    hasFacilityTicketWidget =
      dashboard.widgets.filter((d) => d.type === "facility-ticket").length > 0;

    hasTypeLogWidget = dashboard.widgets.filter((d) => d.type === "type-log").length > 0;

    hasTypeWellEditorWidget =
      dashboard.widgets.filter((d) => d.type === TypeWellEditorWidgetKey).length > 0;

    hasTypeWellDetailsWidget =
      dashboard.widgets.filter((d) => d.type === TypeWellDetailsWidgetKey).length > 0;
  }

  return {
    hasMapWidget,
    hasXdaWidget,
    hasSingleWellWidget,
    hasThreeDWidget,
    hasMultiProductWidget,
    hasWellCardWidget,
    hasFacilityTicketWidget,
    hasTypeLogWidget,
    hasTypeWellEditorWidget,
    hasTypeWellDetailsWidget
  };
};
