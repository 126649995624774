import { getGeomBin } from "api/data";

import { IProjectLayer } from "components/project/layers/queries";

import { getDifference } from "../../../manage-mapbox-shapefile-loading/utils/getDifference";
import { FILL_GEOM_BIN_TYPE, STROKE_GEOM_BIN_TYPE, geomBinTypes } from "../constants";
import { addGeomBinLayers } from "./addGeomBinLayers";

interface IOnGeomBinsChangedParams {
  addLayer;
  checkedGeomBins: Set<string>;
  lastCheckedGeomBins: React.MutableRefObject<Set<string>>;
  layerList: IProjectLayer[];
  mapbox: mapboxgl.Map;
  isActive: boolean;
  selectedGeomBinId: string;
}
export async function onGeomBinsChanged(params: IOnGeomBinsChangedParams) {
  const { addLayer, checkedGeomBins, layerList, mapbox, isActive, selectedGeomBinId } =
    params;

  /**
   * Remove layers that are no longer checked
   */
  const geomBinsToRemove: string[] = getDifference(
    params.lastCheckedGeomBins.current,
    checkedGeomBins
  );

  if (isActive && selectedGeomBinId) {
    // add the selected id to geomBinsToREmove if its not already in that list
    if (!geomBinsToRemove.includes(selectedGeomBinId)) {
      geomBinsToRemove.push(selectedGeomBinId);
    }
  }

  for (const geomBinId of geomBinsToRemove) {
    let geomBinData;
    try {
      geomBinData = await getGeomBin(geomBinId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching geomBin:", error);
    }
    geomBinData?.nodes.forEach((geomBin) => {
      const sourceName = `${geomBin.binId}-${geomBin.order}`;
      try {
        for (const geomBinType of geomBinTypes) {
          const layerName = `${geomBin.binId}-${geomBin.order}${geomBinType}`;
          if (mapbox.getLayer(layerName)) mapbox.removeLayer(layerName);
        }
        if (mapbox.getSource(sourceName)) mapbox.removeSource(sourceName);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Unable to remove layer", err);
      }
    });
  }

  params.lastCheckedGeomBins.current = checkedGeomBins;

  /**
   * Either add the new geomBin sources or update the existing ones
   */
  for (const geomBinId of checkedGeomBins) {
    let geomBinData;
    try {
      geomBinData = await getGeomBin(geomBinId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching geomBin:", error);
    }
    geomBinData?.nodes.forEach((geomBin) => {
      const layerName = `${geomBin.binId}-${geomBin.order}`;
      const layerStrokeName = `${layerName}${STROKE_GEOM_BIN_TYPE}`;
      const layerFillName = `${layerName}${FILL_GEOM_BIN_TYPE}`;

      if (
        !mapbox?.getSource(layerName) &&
        !geomBinsToRemove.includes(selectedGeomBinId)
      ) {
        addGeomBinLayers({
          addLayer,
          mapbox,
          layerList,
          layerName,
          geomBin
        });
      } else {
        mapbox.setPaintProperty(layerFillName, "fill-color", geomBin.color);
        mapbox.setPaintProperty(layerStrokeName, "line-color", geomBin.strokeColor);
        mapbox.setPaintProperty(layerFillName, "fill-opacity", geomBin.opacity);
        mapbox.setPaintProperty(layerStrokeName, "line-width", geomBin.thickness);
      }
    });
  }
}
