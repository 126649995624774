import { Icon } from "@mdi/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useQuery } from "@apollo/client";
import { mdiMenu } from "@mdi/js";
import { Tabs, Tooltip } from "antd";
import { TYPE_WELLS } from "constants/settings.constants";
import { setExpandedKeys, setSelectedTypeWell } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { FOLDERS } from "api/userArps";

import EditTypeWellData from "components/arps/EditTypeWellData";
import { TypeWellEditorHeader } from "components/arps/widget/TypeWellEditor/TypeWellEditorHeader";
import TypeWellEditorChangeRecords from "components/arps/widget/TypeWellEditor/tabs/TypeWellEditorChangeRecords";
import TypeWellEditorWellList from "components/arps/widget/TypeWellEditor/tabs/TypeWellEditorWellList";
import ProjectForecasts from "components/project/forecasts/ProjectForecasts";
import { useSelectedProject } from "components/project/projects/hooks";
import { DEFAULT_DECLINE_TYPE_SETTING } from "components/user-settings/constants/userSetting.constants";
import { useUserSettings } from "components/user/hooks";

import {
  useTypeWellResetter,
  useTypeWellSaver,
  useTypeWellUpdater,
  useUndoRedo,
  useValNavTypeWellUpdater
} from "../../hooks";
import TypeWellCommentField from "./TypeWellCommentField";
import TypeWellInputTable from "./tabs/TypeWellInputTable";

const ARPS_EDITOR_KEY = "1";
const WELL_DATA_EDITOR_KEY = "2";
const TYPE_WELL_EDITOR_KEY = "3";
const TYPE_WELL_SELECTOR_KEY = "4";
const WELL_LIST_KEY = "5";
const CHANGE_RECORDS_KEY = "6";

export function TypeWellEditorWidget() {
  const dispatch = useDispatch();
  const { selectedProject: project } = useSelectedProject();

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const { data: foldersData } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId,
        type: TYPE_WELLS
      }
    },
    skip: !project?.projectId
  });

  const { declineType: declineTypeSetting } = useUserSettings();
  const declineType = declineTypeSetting?.decline ?? DEFAULT_DECLINE_TYPE_SETTING;

  const undoRedoManager = useUndoRedo();

  const { onSaveTypeWell, onCloneTypeWell, saveStatus } =
    useTypeWellSaver(undoRedoManager);

  const type = TYPE_WELLS;
  const selectedTypeWellRef = useRef(selectedTypeWell);
  const { resetTypeWell } = useTypeWellResetter(type, undoRedoManager);
  const { onTypeWellValueChange } = useTypeWellUpdater(undoRedoManager);
  const { onUpdateValNavTypeWell } = useValNavTypeWellUpdater(type);

  const expandedKeys = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "expandedTypeWellKeys" : "expandedForecastKeys"]
  );

  const filteredForecastFolders = useSelector(
    (state: RootState) =>
      state.arps[
        type === TYPE_WELLS ? "filteredTypeWellFolders" : "filteredForecastFolders"
      ]
  );

  const [activeMenu, setActiveMenu] = useState(TYPE_WELL_SELECTOR_KEY);
  const [activeTab, setActiveTab] = useState(ARPS_EDITOR_KEY);

  useEffect(() => {
    selectedTypeWellRef.current = selectedTypeWell;
  }, [selectedTypeWell]);

  useEffect(() => {
    if (selectedTypeWellRef.current && foldersData?.folders) {
      const { folders } = foldersData;
      const selectedTypeWellFolderIndex = folders.findIndex(
        (folder) => folder.folderId === selectedTypeWellRef.current?.folderId
      );
      const selectedTypeWellFolder = folders[selectedTypeWellFolderIndex];
      const updatedSelectedTypeWell = selectedTypeWellFolder?.forecasts.find(
        (x) => x.id === selectedTypeWellRef.current?.id
      );

      if (updatedSelectedTypeWell) {
        const updatedUserArps = {
          ...selectedTypeWellRef.current,
          arps: updatedSelectedTypeWell.arps
        };
        onTypeWellValueChange(updatedUserArps);
      }
    }
  }, [foldersData]);
  useEffect(() => {
    if (selectedTypeWell) {
      setActiveMenu(TYPE_WELL_EDITOR_KEY);
    } else {
      setActiveMenu(TYPE_WELL_SELECTOR_KEY);
    }
  }, [selectedTypeWell]);

  const isEditorTabActive = activeMenu === TYPE_WELL_EDITOR_KEY;

  const setTabToEditor = () => {
    setActiveMenu(TYPE_WELL_EDITOR_KEY);
  };

  const toggleView = () => {
    if (activeMenu === TYPE_WELL_SELECTOR_KEY) {
      setActiveMenu(TYPE_WELL_EDITOR_KEY);
    } else {
      // When the user switches to the selector, always expand the folder that the selected type well is in.
      // Note: This is an edge case because when a type well is edited, it should be expanded,
      // however you can still close the folder from the projects tab.
      const selectedTypeWellFolderIndex = filteredForecastFolders.findIndex(
        (folder) => folder.folderId === selectedTypeWell?.folderId
      );
      const selectedTypeWellFolder = filteredForecastFolders[selectedTypeWellFolderIndex];
      if (
        selectedTypeWellFolder?.id &&
        !expandedKeys.includes(selectedTypeWellFolder.id)
      ) {
        dispatch(
          setExpandedKeys({
            type: type,
            expandedKeys: [...expandedKeys, selectedTypeWellFolder?.id]
          })
        );
      }
      dispatch(setSelectedTypeWell(null));
      setActiveMenu(TYPE_WELL_SELECTOR_KEY);
    }
  };

  const TypeWellEditor = () => {
    return (
      <TypeWellEditorHeader
        handleSave={onSaveTypeWell}
        handleClone={onCloneTypeWell}
        isEditTypeWellPanelOpen={isEditorTabActive ? true : null}
        isWidget={true}
        saveStatus={saveStatus}
        type={type}
        undoRedoManager={undoRedoManager}
        hideButtons={!isEditorTabActive}
      />
    );
  };

  return (
    <TypeWellWidgetContainer>
      <Header>
        {/* The switch to editor opens a new type well which is not actually editable
        since it's not part of a folder so, we disable that button.*/}
        {activeMenu === TYPE_WELL_EDITOR_KEY && (
          <Tooltip title={"Switch to Selector"}>
            <StyledButton data-testid="switch-to-selector-button" onClick={toggleView}>
              <Icon path={mdiMenu} size={1.2} />
            </StyledButton>
          </Tooltip>
        )}
        {activeMenu === TYPE_WELL_EDITOR_KEY && TypeWellEditor()}
      </Header>
      {activeMenu === TYPE_WELL_SELECTOR_KEY ? (
        <ProjectForecasts
          type={TYPE_WELLS}
          location="Widget"
          widgetProps={{ setWidgetActiveTabKey: setTabToEditor }}
        />
      ) : (
        <Tabs
          defaultActiveKey={ARPS_EDITOR_KEY}
          activeKey={activeTab}
          onTabClick={setActiveTab}
          items={[
            {
              key: ARPS_EDITOR_KEY,
              label: "Arps",
              children: (
                <TypeWellInputWrapper>
                  <TypeWellInputTable
                    arps={selectedTypeWell}
                    handleReload={onUpdateValNavTypeWell}
                    handleReset={resetTypeWell}
                    handleSave={onSaveTypeWell}
                    declineType={declineType}
                    onChange={onTypeWellValueChange}
                    saveStatus={saveStatus}
                    selectedTypeWellTitle={selectedTypeWell?.title}
                    selectedTypeWellRescat={selectedTypeWell?.reserveCategory}
                  />
                </TypeWellInputWrapper>
              )
            },
            {
              key: WELL_DATA_EDITOR_KEY,
              label: "Normalization",
              children: (
                <EditTypeWellData
                  selectedTypeWell={selectedTypeWell}
                  onChange={(wellData) => {
                    const userArps = {
                      ...selectedTypeWell,
                      wellData
                    };
                    onTypeWellValueChange(userArps);
                  }}
                />
              )
            },
            {
              key: WELL_LIST_KEY,
              label: "Well List",
              children: <TypeWellEditorWellList arpsItem={selectedTypeWell} />
            },
            {
              key: CHANGE_RECORDS_KEY,
              label: "Change Records",
              children: (
                <>
                  <TypeWellCommentField selectedTypeWell={selectedTypeWell} />
                  <TypeWellEditorChangeRecords forecastId={selectedTypeWell?.id} />
                </>
              )
            }
          ]}
        />
      )}
    </TypeWellWidgetContainer>
  );
}

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  color: ${(props) => (props.active ? "var(--color-primary)" : "#a2aaad")};
  background-color: transparent;
  border: none;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const Header = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 100%;
  grid-template-columns: 36px 1fr;
  height: 48px;
  margin: 0; // Reset margin
  padding: 0; // Reset padding
`;

const TypeWellWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
  display: grid;
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    caret-color: transparent;
  }
  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 1.4rem;
    color: #a2aaad;
    letter-spacing: 0.5px;
    padding: 0 15px 0;
  }
  .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: var(--color-text);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-text);
    font-weight: 700;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
    left: ${(props) => (props.activeKey - 1) * 157}px !important;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-na {
    margin: 0;
  }
  .ant-tabs-ink-bar {
    background: var(--color-primary);
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs {
    min-height: 0;
  }
  .ant-tabs .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-content {
    display: grid;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  .ant-tree {
    background: transparent;
  }

  .ant-tree-switcher {
    background: transparent;
  }
`;

const TypeWellInputWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  display: grid;
  margin-top: 15px;
`;
