import { FC } from "react";

import { ICheckedForecast } from "store/features";
import styled from "styled-components";

import TypeWellInputTable from "components/arps/widget/TypeWellEditor/tabs/TypeWellInputTable";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForecastingProps {
  // wellHierarchy: WellHierarchy;
}
const Forecasting: FC<ForecastingProps> = () => {
  return (
    <RootContainer>
      <h5>Forecasting</h5>
      <EditorWrapper>
        <TypeWellInputTable
          declineType={""}
          selectedTypeWellTitle={""}
          selectedTypeWellRescat={""}
          onChange={function (item: ICheckedForecast): void {
            // eslint-disable-next-line no-console
            console.log(item);
          }}
        />
      </EditorWrapper>
      )
    </RootContainer>
  );
};
const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EditorWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export default Forecasting;
