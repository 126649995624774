import axios from "axios";

import { ArpSegment, ForecastConstant, WellData } from "../models/UserArpsModel";

const dataServiceEndPoint = process.env.REACT_APP_DATA_ROOT;
const arpsServiceUrl = process.env.REACT_APP_USER_ARPS_SERVICE;

export interface TypeWellId {
  id: string;
  folderId: string;
  name: string;
}

export interface ExportForecastRequest {
  filterId: string;
  format?: "CSV" | "XML";
  settings?: ForecastExportSettings;
  typeWellIds: TypeWellId[];
}

export interface ForecastExportSettings {
  thirdPartyFormat?: string;
  source?: string;
}

export interface UploadForecastRequest {
  projectId: string;
  uploadType: string;
  folderName: string;
  folderId: string;
  formData: FormData;
}

export type Rescat =
  | "P+PDP"
  | "P+P+PDP"
  | "PDP"
  | "TP"
  | "TPP"
  | "TPPP"
  | "DP"
  | "P+PD"
  | "P+P+PD";

export interface ValNavFolderRequest {
  folderId: string;
  projectId: string;
  oldForecasts: string[];
  forecasts: string[];
  wellData: { [id: string]: WellData };
  segments: { [id: string]: ArpSegment[] };
  constants: { [id: string]: ForecastConstant[] };
  reserveCategory: Rescat;
  source: string;
}

export interface UpdateForecastResponse {
  message: string;
}

async function exportForecasts(request: ExportForecastRequest) {
  return axios.post(
    `${dataServiceEndPoint}/api/v1/data/forecasts/export?exportFormat=${request.format}`,
    request,
    {
      responseType: "blob"
    }
  );
}

async function updatePerformanceData(folderName: string) {
  return axios.post(`${dataServiceEndPoint}/api/v1/performancedata`, {
    FolderName: folderName
  });
}

async function uploadForecasts(request: UploadForecastRequest) {
  return axios.post(
    `${arpsServiceUrl}/project/${request.projectId}/${request.uploadType}`,
    request.formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
}

async function saveValNavFolder(request: ValNavFolderRequest) {
  return axios.post(
    `${arpsServiceUrl}/project/${request.projectId}/valnav-type-wells`,
    request
  );
}

export { exportForecasts, uploadForecasts, saveValNavFolder, updatePerformanceData };
