import { useMutation } from "react-query";

import axios from "axios";

import { IShapefileNode } from "models/projects";

export interface IUseAddShapefilesParams {
  onMutate?: () => void;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  onSettled?: () => void;
}

export type AddShapefileListParamsT = {
  isAddingExistingFolders: boolean;
  node: IShapefileNode;
  projectId: string;
};

async function addShapefileList(params: AddShapefileListParamsT) {
  const url = `${process.env.REACT_APP_PROJECT_SERVICE}/project/addShapefileToProject/${params.projectId}?isAddingExistingFolders=${params.isAddingExistingFolders}`;
  const body = params.node;
  return await axios.put(url, body);
}

/**
 * Handles both Project and Linked shapefiles.
 * Linked refers to System or organization shapefiles.
 * @param param0
 * @returns
 */
export function useAddShapefileListMutation({
  onMutate,
  onSuccess,
  onError,
  onSettled
}: IUseAddShapefilesParams) {
  return useMutation(
    (params: AddShapefileListParamsT) => addShapefileList({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
