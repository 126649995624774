import * as _filter from "lodash/filter";
import * as _forEach from "lodash/forEach";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { Lut } from "three/examples/jsm/math/Lut.js";
import { CSS2DObject, CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";

import { ThreeDViewerOptionT } from "models";

import { IZoneVisWellData } from "./model";

interface ControlWell {
  UWI: string;
  Survey: Coordinate[];
  Values: number[]; //
}

export interface Coordinate {
  X: number;
  Y: number;
  Z: number;
  Values?: number[];
}

export interface HzWell {
  Centroid: number[];
  Color: number;
  FullSurvey: Coordinate[];
  UnformattedUwi: string;
  Value: string;
  UWI: string;
}

export enum Mode {
  // eslint-disable-next-line no-unused-vars
  Well,
  // eslint-disable-next-line no-unused-vars
  Zone
}

const GRAY_COLOR = { r: 0.8, g: 0.8, b: 0.8 };
const LIGHT_BLUE_COLOR = { r: 0.196, g: 0.658, b: 0.6431 };

export class ThreeDViewer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  container: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stats: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  camera: THREE.Camera;
  camera2: THREE.Camera;
  lut: Lut;
  scene: THREE.Scene;
  orientationScene: THREE.Scene;
  uiScene: THREE.Scene;
  sprite: THREE.Sprite;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls: any;
  renderer: THREE.Renderer;
  renderer2: THREE.Renderer;
  renderer3: THREE.Renderer;
  orthoCamera: THREE.OrthographicCamera;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  boxHelper: any;
  raycaster;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelRenderer: any;
  orientationLabelRenderer: CSS2DRenderer;
  mouse = new THREE.Vector2();
  options: ThreeDViewerOptionT;

  constructor(sceneContainer, option: ThreeDViewerOptionT) {
    this.options = option;
    this.container = sceneContainer;
  }

  initialize() {
    this.mode = Mode.Well;
    this.init();
    this.setupInset();
    this.setupOrientation();
    this.lut = new Lut();
    this.setupColorRenderer();
    this.animate();
  }

  ///0 is pay, 1 is porosity, 2 is sw, 3 temperature, 4 pressure
  ipdbVerticalWellFieldIndex(field: string) {
    if (!field) {
      return -1; //pay
    }
    field = field.toLowerCase();
    if (field === "pay") {
      return 0;
    }
    if (field == "porosity") {
      return 1;
    }
    if (field == "sw") {
      return 2;
    }
    if (field == "temp") {
      return 3;
    }
    if (field == "pressure") {
      return 4;
    }
    return -1;
  }

  isRedraw = false;

  redrawScene() {
    if (!this.container) {
      return;
    }
    this.isRedraw = true;
    //this.createBoxHelper();
    this.clearScene();

    this.addIPDBPlane(this.ipdbInfo);
    this.addHzWells(this.hzWells, this.highlightWells);

    this.addVerticalWells(this.vWells);
    this.isRedraw = false;
  }

  setData(hzWells, ipdbInfo, vertical) {
    this.hzWells = hzWells;
    this.ipdbInfo = ipdbInfo;
    this.vWells = vertical;
    let min: number, max: number;
    if (this.options.showIPDBDrainage && hzWells) {
      [min, max] = this.getIpdbMeshMinMax(hzWells);
    } else if (this.options.showVertical) {
      [min, max] = this.getControlWellsMinMax(vertical);
    }
    if (isNaN(min) || isNaN(max) || !min || !max) {
      //hide data
      this.hideIpdbLegend();
      return;
    }
    this.setColorMap(min, max);
    this.setIpdbLabel(min, max);
  }

  updateScaleZ() {
    this.redrawScene();
    // this.focusCameraOnWell(Object.keys(this.wellGeoms)[0]);
    // this.controls.update();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hzWells: HzWell[];
  highlightWells: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vWells: any;
  mats = [];

  createBoxHelper() {
    if (this.boxHelper == null) {
      this.boxHelper = new THREE.BoxHelper(this.scene);
      this.scene.add(this.boxHelper);
    }
  }

  clearWellLabels() {
    const node = document.getElementById("well-label-container");
    if (!node) return;
    node.innerHTML = "";
  }

  wellGeoms = {};
  addHzWells = (hzWells, highlightWells: string[] = []) => {
    if (!this.scene) return;
    this.mats = [];
    this.hzWells = hzWells;
    this.wellGeoms = {};
    this.highlightWells = highlightWells;
    this.clearWellLabels();
    _forEach(hzWells, (well) => {
      if (!this.options.showMultiLegWells && well.IsAMultiLeg) {
        return;
      }
      this.mats = [];
      let wellColor = well.Color;

      const positions = [];
      const colors = [];
      const color = new THREE.Color();
      if (well.FullSurvey.length === 0) return;

      const isHighlightWell =
        highlightWells.length > 0 && highlightWells.indexOf(well.UWI) >= 0;

      for (let i = 0; i < well.FullSurvey.length; i++) {
        if (!this.options.showVerticalPortion && i < well.HzLegIndex) {
          continue;
        }
        const c = well.FullSurvey[i];
        const x = c.X;
        const y = c.Y;
        const z = c.Z * this.options.scaleZ;
        if (i > well.HzLegIndex && this.options.highlightHzLeg) {
          wellColor = this.options.hzLegColor;
        }
        // positions
        positions.push(x, y, z);
        color.setHex(wellColor);
        // colors
        if (isHighlightWell) {
          colors.push(0, 0, 0);
        } else {
          colors.push(color.r, color.g, color.b);
        }
      }
      const geometry = new LineGeometry();

      geometry.colors = colors;
      geometry.setPositions(positions);
      geometry.setColors(colors);
      const material = new LineMaterial({
        color: 0xffffff,
        linewidth: this.options.lineWidth, // in pixels
        vertexColors: true,
        //resolution:  // to be set by renderer, eventually
        dashed: false
      });
      if (this.container) {
        material.resolution.set(this.container.clientWidth, this.container.clientHeight);
      }
      this.mats.push(material);
      const line = new Line2(geometry, material);
      line.name = well.UWI;
      line.computeLineDistances();
      this.wellGeoms[well.UWI] = { obj: line, well };
      this.scene.add(line);
      if (this.options.showDrainage && !this.options.showIPDBDrainage) {
        this.addEllipticShape(well.Color, well.EllipticShape);
      }

      if (this.options.showIPDBMesh) {
        this.addIpdbMesh(well.OriginalIpdbMesh);
      }
      if (this.options.showIPDBDrainage) {
        if (well.IntersectedIpdbMesh?.length > 0) {
          this.addIpdbMesh(well.IntersectedIpdbMesh);
        } else {
          this.addEllipticShape(well.Color, well.EllipticShape);
        }
      }
      if (this.options.showWellLabel) {
        this.addWellLabel(well, well.Centroid);
      }
    });
  };

  updateWellColorAndValue(well) {
    this.hzWells.forEach((hzWell) => {
      if (hzWell.UnformattedUwi in well) {
        const x = well[hzWell.UnformattedUwi];
        hzWell.Value = x.Value;
        hzWell.Color = x.Color;
      }
    });
    this.redrawScene();
  }

  highlightWell(item) {
    const found = this.wellGeoms[item];
    for (const key in this.wellGeoms) {
      this.unHighlight(this.wellGeoms[key].obj);
    }
    if (found) {
      this.highlight(found.obj);
    }
  }

  addIpdbMesh(ipdbMesh) {
    if (!ipdbMesh || ipdbMesh.length === 0) {
      return;
    }
    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    const index = [];
    const colors = [];
    let k = 0;
    for (let i = 0; i < ipdbMesh.length; i++) {
      try {
        const ipdb = ipdbMesh[i];
        const cell = ipdb.Cell;
        const verts = ipdb.Verts;
        const faces = ipdb.Faces;
        const value = cell.Values[this.options.ipdbField] ?? 0;
        const color = this.lut.getColor(value) ?? GRAY_COLOR;
        for (let j = 0; j < verts.length; j++) {
          const v = verts[j];
          vertices.push(v[0], v[1], v[2] * this.options.scaleZ);
          colors.push(color.r, color.g, color.b);
        }

        for (let j = 0; j < faces.length; j++) {
          const v = faces[j];
          index.push(v[0] + k, v[1] + k, v[2] + k);
        }
        k += verts.length;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(vertices), 3)
    );
    geometry.setAttribute(
      "color",
      new THREE.BufferAttribute(new Float32Array(colors), 3)
    );
    geometry.setIndex(index);
    geometry.computeBoundingSphere();
    geometry.computeVertexNormals();
    geometry.normalizeNormals();
    const material = new THREE.MeshStandardMaterial({
      flatShading: false,
      wireframe: false,
      vertexColors: true,
      side: THREE.DoubleSide
    });
    const mesh = new THREE.Mesh(geometry, material);
    this.scene.add(mesh);
  }

  addEllipticShape(color, shape) {
    const threeColor = new THREE.Color();
    threeColor.setHex(color);
    for (let i = 0; i < shape.length; i++) {
      try {
        const colors = [];
        const geometry = new THREE.BufferGeometry();
        const verts = shape[i].Item1;
        const faces = shape[i].Item2;
        const vertices = [];
        for (let j = 0; j < verts.length; j++) {
          const v = verts[j];
          vertices.push(v.X);
          vertices.push(v.Y);
          vertices.push(v.Z * this.options.scaleZ);
          colors.push(threeColor.r, threeColor.g, threeColor.b);
        }
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(new Float32Array(vertices), 3)
        );
        geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

        geometry.setIndex(faces);
        geometry.computeBoundingSphere();
        geometry.computeVertexNormals();
        geometry.normalizeNormals();
        const material = new THREE.MeshStandardMaterial({
          flatShading: false,
          wireframe: false,
          vertexColors: true,
          color: color,
          side: THREE.DoubleSide
        });
        const mesh = new THREE.Mesh(geometry, material);
        this.scene.add(mesh);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }

  showDrainage(well) {
    const ellipses = well.SausageShape;
    for (let e = 0; e < ellipses.length; e++) {
      const shape = new THREE.BufferGeometry();
      const ellipse = ellipses[e].Ellipse;
      const verts = [];
      for (let i = 0; i < ellipse.length; i++) {
        verts.push(ellipse[i].X);
        verts.push(ellipse[i].Y);
        verts.push(ellipse[i].Z);
      }
      verts.push(ellipse[0].X);
      verts.push(ellipse[0].Y);
      verts.push(ellipse[0].Z);
      shape.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array(verts), 3)
      );

      // material
      const material = new THREE.LineBasicMaterial({
        color: 0xffffff,
        linewidth: 1
      });

      // line
      const line = new THREE.Line(shape, material);
      this.scene.add(line);
    }
    return [];
  }

  addVerticalWells = (vWells) => {
    this.mats = [];
    this.vWells = vWells;

    const valueIndex = this.ipdbVerticalWellFieldIndex(this.options.ipdbField);
    _forEach(vWells, (well) => {
      this.mats = [];
      const positions = [];
      const colors = [];
      for (let i = 0; i < well.Survey.length; i++) {
        const c = well.Survey[i];
        const x = c.X;
        const y = c.Y;
        const z = c.Z * this.options.scaleZ;
        const zone = c.Zone;
        const val = c.Values && c.Values.length > 0 ? c.Values[valueIndex] ?? NaN : NaN;
        const color = !isFinite(val) ? undefined : this.lut.getColor(val) ?? GRAY_COLOR;
        const pointColor = !zone || !color ? GRAY_COLOR : color;
        // positions
        positions.push(x, y, z);
        // colors
        colors.push(pointColor.r, pointColor.g, pointColor.b);
      }
      const geometry = new LineGeometry();

      geometry.colors = colors;
      geometry.setPositions(positions);
      geometry.setColors(colors);
      const material = new LineMaterial({
        color: 0xffffff,
        linewidth: this.options.lineWidth, // in pixels
        vertexColors: true,
        //resolution:  // to be set by renderer, eventually
        dashed: false,

        transparent: true
      });
      material.resolution.set(this.container.clientWidth, this.container.clientHeight);
      this.mats.push(material);
      const line = new Line2(geometry, material);
      line.name = well.UWI;
      line.computeLineDistances();
      this.scene.add(line);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ipdbInfo: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ipdbZones: any[] = [];

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  BasisGeometry(rows, cols) {
    const g = new THREE.BufferGeometry();

    const indices = [];

    let index = 0;
    const indexArray = [];

    for (let y = 0; y <= rows; y++) {
      const indexRow = [];

      for (let x = 0; x <= cols; x++) {
        indexRow.push(index++);
      }

      indexArray.push(indexRow);
    }

    let a, b, c, d;

    for (let j = 0; j < rows; j++) {
      for (let i = 0; i < cols; i++) {
        a = indexArray[j][i];
        b = indexArray[j + 1][i];
        c = indexArray[j + 1][i + 1];
        d = indexArray[j][i + 1];

        indices.push(a, b, d);
        indices.push(b, c, d);
      }
    }

    const verticesCount = (cols + 1) * (rows + 1);
    g.setIndex(new THREE.BufferAttribute(new Uint32Array(indices), 1));
    g.setAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(verticesCount * 3), 3)
    );

    return g;
  }

  getIpdbMeshMinMax(wells) {
    //default min max if no ipdb mesh
    let min = undefined;
    let max = undefined;
    for (const well of wells) {
      const ipdbMesh = well.IntersectedIpdbMesh;
      if (!ipdbMesh) continue;
      for (let i = 0; i < ipdbMesh.length; i++) {
        const ipdb = ipdbMesh[i];
        const val = ipdb.Cell?.Values[this.options.ipdbField] ?? NaN;
        if (!isNaN(val)) {
          min = Math.min(min ?? 10000000000, val);
          max = Math.max(max ?? -10000000000, val);
        }
      }
    }
    if (min == max) {
      max += 1;
    }
    return [min, max];
  }

  getControlWellsMinMax(controlWells: ControlWell[]): number[] {
    let min = undefined;
    let max = undefined;
    if (!controlWells) {
      return [NaN, NaN];
    }
    const valueIndex = this.ipdbVerticalWellFieldIndex(this.options.ipdbField);
    controlWells.forEach((w) => {
      for (const survey of w.Survey) {
        const value = survey.Values ? survey.Values[valueIndex] ?? NaN : NaN;
        if (!isNaN(value)) {
          min = Math.min(min ?? 10000000000, value);
          max = Math.max(max ?? -1000000000, value);
        }
      }
    });

    if (min == max) {
      max = min + 1;
    }
    return [min, max];
  }

  setColorMap(min: number, max: number) {
    const minToUse = !isFinite(this.options.colorMapMin)
      ? min
      : this.options.colorMapMin ?? min;
    const maxToUse = !isFinite(this.options.colorMapMax)
      ? max
      : this.options.colorMapMax ?? max;
    this.lut.setMin(minToUse);
    this.lut.setMax(maxToUse);
    this.lut.setColorMap(this.options.colorMap);
    this.updateLut();
  }

  setIpdbLabel(min: number, max: number) {
    const minToUse = !isFinite(this.options.colorMapMin)
      ? min
      : this.options.colorMapMin ?? min;
    const maxToUse = !isFinite(this.options.colorMapMax)
      ? max
      : this.options.colorMapMax ?? max;
    const minValue = document.getElementById("min-label");
    const maxValue = document.getElementById("max-label");
    const titleLabel = document.getElementById("title-label");
    if ((this.options.showIPDBPlane || this.options.showIPDBDrainage) && titleLabel) {
      this.showIpdbLegend();
      titleLabel.innerHTML =
        this.options.ipdbField +
        (this.options.ipdbFieldUnit ? " (" + this.options.ipdbFieldUnit + ")" : "");
      minValue.innerHTML = `${
        this.options.colorMapMin && isFinite(this.options.colorMapMin) ? "<" : ""
      }${this.numberWithCommas(minToUse.toFixed(2))}`;
      maxValue.innerHTML = `${
        this.options.colorMapMax && isFinite(this.options.colorMapMax) ? ">" : ""
      }${this.numberWithCommas(maxToUse.toFixed(2))}`;
    } else {
      this.hideIpdbLegend();
    }
  }

  hideIpdbLegend() {
    const canvas = document.getElementById("inset-canvas");
    if (!canvas) {
      return;
    }
    const minValue = document.getElementById("min-label");
    const maxValue = document.getElementById("max-label");
    const titleLabel = document.getElementById("title-label");
    minValue.style.display = "none";
    maxValue.style.display = "none";
    titleLabel.style.display = "none";
    canvas.style.display = "none";
  }

  showIpdbLegend() {
    const canvas = document.getElementById("inset-canvas");
    if (!canvas) {
      return;
    }
    const minValue = document.getElementById("min-label");
    const maxValue = document.getElementById("max-label");
    const titleLabel = document.getElementById("title-label");
    canvas.style.display = "block";
    minValue.style.display = "block";
    maxValue.style.display = "block";
    titleLabel.style.display = "block";
  }

  addIPDBPlane = (ipdbInfo) => {
    if (!this.isRedraw) {
      this.ipdbZones = [];
      ipdbInfo.Zones.forEach((z) => {
        this.ipdbZones.push(z);
      });
    }
    if (!this.options.showIPDBPlane) {
      return;
    }
    ipdbInfo.Zones.forEach((z) => {
      this.addIpdbLabel(z);
      //need at least 2 columns
      if (z.Columns <= 1) {
        return;
      }

      const geometry = this.BasisGeometry(z.Rows - 1, z.Columns - 1);
      const positions = geometry.attributes.position;

      const cells = z.Cells;
      const count = cells.length;
      const visible = new THREE.BufferAttribute(new Float32Array(count), 1);
      const colors = [];

      for (let row = 0; row < z.Rows; row++) {
        for (let col = 0; col < z.Columns; col++) {
          const i = row * z.Columns + col;

          const c = cells[i];
          // positions
          positions.setXYZ(i, c.X, c.Y, c.Z * this.options.scaleZ);
          const color = this.lut.getColor(c[this.options.ipdbField]) ?? LIGHT_BLUE_COLOR;
          if (color) {
            // colors
            colors.push(color.r, color.g, color.b, 0.5);
          }
          if (!c.IsSet && col < z.Columns - 1) {
            const idx = (row * (z.Columns - 1) + col) * 6;
            geometry.index.array[idx] = 0;
            geometry.index.array[idx + 1] = 0;
            geometry.index.array[idx + 2] = 0;
            geometry.index.array[idx + 3] = 0;
            geometry.index.array[idx + 4] = 0;
            geometry.index.array[idx + 5] = 0;
          }
        }
      }
      geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 4));
      geometry.attributes["position"] = positions;
      geometry.attributes["visible"] = visible;
      const material = new THREE.MeshBasicMaterial({
        color: 0xd1d1cf,
        side: THREE.DoubleSide,
        wireframe: this.options.wireframe,
        vertexColors: true,
        transparent: true
      });

      const plane = new THREE.Mesh(geometry, material);
      this.scene.add(plane);
    });
  };
  mode: Mode;

  getScaledZ(z: number): number {
    return z * this.options.scaleZ;
  }

  pointCameraToWell(well: HzWell): void {
    if (!well || !well.FullSurvey || well.FullSurvey.length < 3) {
      return;
    }
    const center = well.FullSurvey[Math.floor(well.FullSurvey.length / 2)];

    if (!center) {
      return;
    }
    this.camera.position.set(center.X * 8, center.Y * 8, this.getScaledZ(center.Z));
    this.controls.target.set(center.X, center.Y, -Math.abs(this.getScaledZ(center.Z)));
    this.controls.update();
  }

  add2DDrainageShape(wells: IZoneVisWellData[]) {
    this.mode = Mode.Zone;
    const extrudeSettings = {
      depth: 2000,
      bevelEnabled: true,
      bevelSegments: 1,
      steps: 1,
      bevelSize: 0,
      bevelThickness: 1
    };
    for (let i = 0; i < wells.length; i++) {
      const well = wells[i];

      const shape = new THREE.Shape(well.Shape);
      const color = new THREE.Color(parseInt(well.Color.replace(/^#/, ""), 16));
      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      geometry.scale(1, 6, 1);
      const edges = new THREE.EdgesGeometry(geometry);
      const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0x3a2c06 })
      );

      const mesh = new THREE.Mesh(
        geometry,
        new THREE.MeshBasicMaterial({ color: color })
      );
      this.scene.add(mesh);
      this.scene.add(line);
    }

    this.mats = [];
    _forEach(wells, (well) => {
      this.mats = [];
      const positions = [];
      const colors = [];

      const c = well.CrossSectionPointWithOffset;
      const x = c.x;
      const y = c.y * 6;
      // positions
      positions.push(x, y, -20);
      positions.push(x, y, 2200);
      const color = new THREE.Color(0x000000);
      // colors
      colors.push(color.r, color.g, color.b);
      colors.push(color.r, color.g, color.b);
      const geometry = new LineGeometry();

      geometry.colors = colors;
      geometry.setPositions(positions);
      geometry.setColors(colors);
      const edges = new THREE.EdgesGeometry(geometry);
      const segments = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0x3a2c06 })
      );
      this.scene.add(segments);
      const material = new LineMaterial({
        color: 0xffffff,
        linewidth: 5, // in pixels
        vertexColors: true,
        //resolution:  // to be set by renderer, eventually
        dashed: false
      });
      material.resolution.set(this.container.clientWidth, this.container.clientHeight);
      this.mats.push(material);
      const line = new Line2(geometry, material);
      line.name = well.UWI;
      line.computeLineDistances();
      line.scale.set(1, 1, 1);
      this.scene.add(line);
    });
  }

  // addIPDB(geoms) {
  //   //material that the geometry will use
  //   const material = new THREE.MeshBasicMaterial({
  //     flatShading: true,
  //     wireframe: false,
  //     side: THREE.DoubleSide,
  //   });
  //   const boxGeometry = new THREE.BoxBufferGeometry(1, 1, 1, 1, 1, 1);
  //   //the instance group
  //   const cluster = new THREE.InstancedMesh(
  //     boxGeometry, //this is the same
  //     material,
  //     geoms.length //instance count
  //   );
  //   const _v3 = new THREE.Vector3();
  //   const _q = new THREE.Quaternion();

  //   const _color = new THREE.Color();

  //   for (const i = 0; i < geoms.length; i++) {
  //     const position = geoms[i].Position;
  //     cluster.setQuaternionAt(i, _q);
  //     const z = position.Z - geoms[i].Depth;
  //     cluster.setPositionAt(i, _v3.set(position.X, position.Y, z));
  //     cluster.setScaleAt(i, _v3.set(geoms[i].Width, geoms[i].Height, 2));
  //     _color.setHex(geoms[i].Color);
  //     cluster.setColorAt(i, _color);
  //   }
  //   this.scene.add(cluster);
  // }

  roundRect(ctx, x, y, w, h, r) {
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + r);
    ctx.lineTo(x + w, y + h - r);
    ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    ctx.lineTo(x + r, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  makeTextSprite(message, parameters) {
    if (parameters === undefined) parameters = {};
    const fontface = "Arial";
    const fontsize = 18;
    const borderThickness = 4;
    const borderColor = { r: 0, g: 0, b: 0, a: 1.0 };
    const backgroundColor = { r: 255, g: 255, b: 255, a: 1.0 };
    const textColor = { r: 0, g: 0, b: 0, a: 1.0 };

    const canvas = document.createElement("canvas");
    canvas.id = "label-canvas";
    const context = canvas.getContext("2d");
    context.font = "Bold " + fontsize + "px " + fontface;
    const metrics = context.measureText(message);
    const textWidth = metrics.width;

    context.fillStyle =
      "rgba(" +
      backgroundColor.r +
      "," +
      backgroundColor.g +
      "," +
      backgroundColor.b +
      "," +
      backgroundColor.a +
      ")";
    context.strokeStyle =
      "rgba(" +
      borderColor.r +
      "," +
      borderColor.g +
      "," +
      borderColor.b +
      "," +
      borderColor.a +
      ")";

    context.lineWidth = borderThickness;
    this.roundRect(
      context,
      borderThickness / 2,
      borderThickness / 2,
      (textWidth + borderThickness) * 1.1,
      fontsize * 1.4 + borderThickness,
      8
    );

    context.fillStyle =
      "rgba(" + textColor.r + ", " + textColor.g + ", " + textColor.b + ", 1.0)";
    context.fillText(message, borderThickness, fontsize + borderThickness);

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;

    const spriteMaterial = new THREE.SpriteMaterial({
      map: texture
      // useScreenCoordinates: false,
    });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(0.5 * fontsize, 0.25 * fontsize, 0.75 * fontsize);
    return sprite;
  }

  addWellLabel(well, centroid) {
    const geom = new THREE.SphereGeometry(1, 16, 16);
    geom.translate(centroid[0], centroid[1], centroid[2] * this.options.scaleZ);
    const mat = new THREE.MeshBasicMaterial({
      color: 0xd1d1cf
    });
    const mesh = new THREE.Mesh(geom, mat);
    this.scene.add(mesh);
    const div = document.createElement("div");
    div.className = "well-labels";
    div.innerHTML = well.Value + "<br>" + well.UWI;
    div.style.marginTop = "-1em";
    const label = new CSS2DObject(div);
    label.position.set(centroid[0], centroid[1], centroid[2] * this.options.scaleZ);
    mesh.add(label);
  }

  addIpdbLabel(ipdb) {
    if (!ipdb.Cells || ipdb.Cells.length === 0) {
      return;
    }
    const geom = new THREE.SphereGeometry(1, 16, 16);

    geom.translate(
      ipdb.Cells[0].X,
      ipdb.Cells[0].Y,
      ipdb.Cells[0].Z * this.options.scaleZ
    );
    const mat = new THREE.MeshBasicMaterial({
      color: 0xd1d1cf
    });
    const mesh = new THREE.Mesh(geom, mat);
    this.scene.add(mesh);
    const div = document.createElement("div");
    div.className = "well-labels";
    div.textContent = ipdb.Name;
    div.style.marginTop = "-1em";
    const label = new CSS2DObject(div);
    label.position.set(
      ipdb.Cells[0].X,
      ipdb.Cells[0].Y,
      ipdb.Cells[0].Z * this.options.scaleZ
    );
    mesh.add(label);
  }

  fitCameraToSelection(camera, controls, selection, fitOffset = 1.2) {
    const box = new THREE.Box3();

    for (const object of selection) box.expandByObject(object);

    const size = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);

    controls.maxDistance = distance * 10;
    controls.target.copy(center);

    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);

    controls.update();
  }

  resetCameraPosition(mode) {
    if (mode === Mode.Well) {
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.container.clientWidth / this.container.clientHeight,
        1,
        1000000
      );
      this.camera.position.set(-100, -100, 5000);
      this.camera.up = new THREE.Vector3(0, 0, 1);

      this.camera.position.set(
        this.camera.position.x,
        this.camera.position.y,
        2000 * this.options.scaleZ
      );
      this.camera.lookAt(0, 0, 0);
    } else {
      this.camera = new THREE.PerspectiveCamera(
        50,
        this.container.clientWidth / this.container.clientHeight,
        1,
        10000
      );
      this.camera.position.set(0, 150, -2200);
      this.camera.up = new THREE.Vector3(0, -1, 0);
    }
    this.controls = new OrbitControls(this.camera, this.labelRenderer.domElement);
    this.controls.addEventListener("change", this.animate);
    this.controls.screenSpacePanning = true;
    if (this.hzWells.length > 0) {
      this.pointCameraToWell(this.hzWells[Math.floor(this.hzWells.length / 2)]);
    }
    setTimeout(() => {
      this.animate();
    }, 500);
  }

  init() {
    if (!this.container) return;
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.container.clientWidth / this.container.clientHeight,
      1,
      1000000
    );
    this.camera.position.set(-100, -100, 5000);
    this.camera.up = new THREE.Vector3(0, 0, 1);

    this.camera.position.set(
      this.camera.position.x,
      this.camera.position.y,
      2000 * this.options.scaleZ
    );
    this.camera.lookAt(0, 0, 0);
    this.camera2 = new THREE.PerspectiveCamera(
      45,
      this.container.clientWidth / this.container.clientHeight,
      10,
      1000000
    );

    this.scene = new THREE.Scene();
    this.orientationScene = new THREE.Scene();

    this.scene.background = new THREE.Color(0xffffff);
    this.scene.add(new THREE.AmbientLight(0xdddddd));
    const dirLight = new THREE.DirectionalLight(0xdddddd, 0.2);
    dirLight.position.set(-100, -100, 5000);
    dirLight.lookAt(0, 0, -1000);
    dirLight.castShadow = false;
    this.scene.add(dirLight);
    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.Line.threshold = 50;

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true
    });

    this.renderer.autoClear = false;

    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.domElement.id = "well-label-container";

    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0px";
    this.labelRenderer.domElement.style.right = "0px";
    this.labelRenderer.domElement.style.bottom = "0px";
    this.labelRenderer.domElement.style.left = "0px";

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);

    this.labelRenderer.setSize(this.container.clientWidth, this.container.clientHeight);
    this.renderer.domElement.id = "threejs-canvas";

    this.container.appendChild(this.renderer.domElement);
    this.container.appendChild(this.labelRenderer.domElement);

    this.controls = new OrbitControls(this.camera, this.labelRenderer.domElement);
    this.controls.addEventListener("change", this.animate);
    this.controls.screenSpacePanning = true;

    this.container.addEventListener("mousemove", this.onDocumentMouseMove, false);
    //
    window.addEventListener("resize", this.onWindowResize, false);

    setTimeout(() => {
      if (this.hzWells.length > 0) {
        this.pointCameraToWell(this.hzWells[Math.floor(this.hzWells.length / 2)]);
      }
      this.animate();
    }, 500);
  }

  updateLut() {
    const map = this.sprite.material.map;
    this.lut.updateCanvas(map.image);
    map.needsUpdate = true;
  }

  setupColorRenderer() {
    this.orthoCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 2);
    this.orthoCamera.position.set(0.5, 0, 1);
    this.uiScene = new THREE.Scene();
    if (this.sprite) {
      this.uiScene.remove(this.sprite);
      this.sprite = null;
    }
    this.sprite = new THREE.Sprite(
      new THREE.SpriteMaterial({
        map: new THREE.CanvasTexture(this.lut.createCanvas())
      })
    );
    this.uiScene.add(this.sprite);
    this.sprite.scale.x = 0.2;
    const labelDiv = document.createElement("div");
    labelDiv.className = "ipdb-value-label-container";
    const titleLabel = document.createElement("div");
    titleLabel.className = "value-label title-label";
    titleLabel.id = "title-label";
    titleLabel.innerHTML = "";
    labelDiv.appendChild(titleLabel);
    const minLabel = document.createElement("div");
    minLabel.className = "value-label min-label";
    minLabel.id = "min-label";
    minLabel.innerHTML = "";
    labelDiv.appendChild(minLabel);

    const maxLabel = document.createElement("div");
    maxLabel.className = "value-label max-label";
    maxLabel.innerHTML = "";
    maxLabel.id = "max-label";
    labelDiv.appendChild(maxLabel);
    const insetDiv = document.getElementById("inset");
    if (!insetDiv) return;
    insetDiv.appendChild(labelDiv);
  }

  setupInset() {
    const insetWidth = 150,
      insetHeight = 200;
    const container2 = document.getElementById("inset");
    if (!container2) return;
    container2.style.width = insetWidth + "px";
    container2.style.height = insetHeight + "px";

    // renderer
    this.renderer2 = new THREE.WebGLRenderer({
      alpha: true,
      preserveDrawingBuffer: true
    });
    this.renderer2.domElement.id = "inset-canvas";
    this.renderer2.setClearColor(0x000000, 0);
    this.renderer2.setSize(insetWidth, insetHeight);
    container2.appendChild(this.renderer2.domElement);
  }

  setupOrientation() {
    const orientationWidth = 150,
      orientationHeight = 150;
    const container3 = document.getElementById("orientation");
    if (!container3) return;
    container3.style.width = orientationWidth + "px";
    container3.style.height = orientationHeight + "px";

    this.renderer3 = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true
    });
    this.renderer3.domElement.id = "orientation-canvas";
    this.renderer3.setClearColor(0x000000, 0);
    this.renderer3.setSize(orientationWidth, orientationHeight);

    this.orientationScene.add(new THREE.AxesHelper(500));

    this.orientationLabelRenderer = new CSS2DRenderer();
    this.orientationLabelRenderer.domElement.id = "orientation-label-container";
    this.orientationLabelRenderer.setSize(orientationWidth, orientationHeight);
    this.orientationLabelRenderer.domElement.style.position = "absolute";
    this.orientationLabelRenderer.domElement.style.bottom = "0px";
    this.orientationLabelRenderer.domElement.style.right = "0px";

    container3.appendChild(this.renderer3.domElement);
    container3.appendChild(this.orientationLabelRenderer.domElement);

    this.addOrientationLabels(575, 0, 0, "E");
    this.addOrientationLabels(0, 575, 0, "N");
    this.addOrientationLabels(0, 0, 575, "");
  }

  addOrientationLabels(x: number, y: number, z: number, text: string) {
    const geom = new THREE.SphereGeometry(1, 16, 16);
    geom.translate(x, y, z);
    const mat = new THREE.MeshBasicMaterial({
      color: 0xd1d1cf
    });
    const mesh = new THREE.Mesh(geom, mat);
    this.orientationScene.add(mesh);
    const div = document.createElement("div");
    div.className = "orientation-labels";
    div.textContent = text;
    const label = new CSS2DObject(div);
    label.position.set(x, y, z);
    mesh.add(label);
  }

  updateOptions(options: ThreeDViewerOptionT) {
    this.options = options;
  }

  clearSceneObj = (obj) => {
    if (typeof obj === "undefined") {
      return;
    }
    const meshes = _filter(obj.children, (child) => {
      return (
        child.type === "Mesh" || child.type === "Line2" || child.type === "LineSegments"
      );
    });
    meshes.forEach((mesh) => {
      obj.remove(mesh);
      if (mesh.geometry) mesh.geometry.dispose();
      if (mesh.material) {
        mesh.material.dispose();
      }
      if (mesh.texture) mesh.texture.dispose();
    });
    this.boxHelper = null;
  };

  clearScene() {
    this.clearSceneObj(this.scene);
  }

  clearEntireSceneAndDisposeRenderer() {
    THREE.Cache.clear();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.scene.children.forEach((mesh: any) => {
      this.scene.remove(mesh);
      if (mesh.geometry) mesh.geometry.dispose();
      if (mesh.material) mesh.material.dispose();
      if (mesh.texture) mesh.texture.dispose();
    });
    this.boxHelper = null;
    if (this.renderer) {
      this.renderer.forceContextLoss();
      this.renderer.domElement = null;
      this.renderer.dispose();
      this.renderer = null;
    }
    if (this.renderer2) {
      this.renderer2.forceContextLoss();
      this.renderer2.domElement = null;
      this.renderer2.dispose();
      this.renderer2 = null;
    }
    if (this.renderer3) {
      this.renderer3.forceContextLoss();
      this.renderer3.domElement = null;
      this.renderer3.dispose();
      this.renderer3 = null;
    }
    const canvas = document.getElementById("threejs-canvas");
    if (canvas) {
      canvas.parentNode.removeChild(canvas);
    }

    const insetCanvas = document.getElementById("inset-canvas");
    if (insetCanvas) {
      insetCanvas.parentNode.removeChild(insetCanvas);
    }

    const orientationCanvas = document.getElementById("orientation-canvas");
    if (orientationCanvas) {
      orientationCanvas.parentNode.removeChild(orientationCanvas);
    }

    const label = document.getElementById("well-label-container");
    if (label) {
      label.parentNode.removeChild(label);
    }

    const orientationLabel = document.getElementById("orientation-label-container");
    if (orientationLabel) {
      orientationLabel.parentNode.removeChild(orientationLabel);
    }

    //cleanup label container
    const labelContainers = document.getElementsByClassName("ipdb-value-label-container");
    for (const container of labelContainers) {
      container.parentNode.removeChild(container);
    }
  }

  onWindowResize = () => {
    if (!this.renderer) {
      return;
    }
    this.clearWellLabels();
    this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    this.labelRenderer.setSize(this.container.clientWidth, this.container.clientHeight);
    this.animate();
  };
  onDocumentMouseMove = (event) => {
    event.preventDefault();
    this.mouse.x = (event.clientX / this.container.clientWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / this.container.clientHeight) * 2 + 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment

    this.mouse.screenx = event.clientX;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    this.mouse.screeny = event.clientY;
  };
  //
  animate = () => {
    //requestAnimationFrame(this.animate);
    this.updateOrientationCamera();
    this.render();
    if (this.container) {
      //this.stats.update();
      this.mats.forEach((mat) =>
        mat.resolution.set(this.container.clientWidth, this.container.clientHeight)
      ); // resolution of the viewport
    }
  };

  insertAt(from, index, s) {
    return from.substring(0, index) + s + from.substring(index);
  }

  headerInfo = {};

  toString = (data) => {
    if (data === null) {
      return "";
    }
    return data;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intersected: any;

  render() {
    if (!this.controls || !this.renderer || !this.renderer2 || !this.renderer3) return;

    this.renderer.render(this.scene, this.camera);
    if (this.options.showIPDBPlane || this.options.showIPDBDrainage) {
      this.renderer2.render(this.uiScene, this.orthoCamera);
    }
    this.labelRenderer.render(this.scene, this.camera);
    this.renderer3.render(this.orientationScene, this.camera2);
    this.orientationLabelRenderer.render(this.orientationScene, this.camera2);
  }

  highlight(well) {
    const color = new THREE.Color();
    const length = well.geometry.colors.length / 3;
    const selectedColor = [];
    for (let i = 0; i < length; i++) {
      color.setHex(0x110000);
      selectedColor.push(color.r, color.g, color.b);
    }
    well.geometry.setColors(selectedColor);
  }

  unHighlight(well) {
    well.geometry.setColors(well.geometry.colors);
  }

  updateOrientationCamera() {
    this.camera2 = this.camera.clone();
    this.camera2.position.sub(this.controls.target);
    this.camera2.position.setLength(1600);
    this.camera2.lookAt(this.orientationScene.position);
    this.camera2.aspect = 1;
    this.camera2.updateProjectionMatrix();
  }
}
