import { useEditableProjectLayerList } from "components/project/layers/hooks";
import { useProjectContext } from "components/project/projects/context";

import { useShapefileListQuery } from "../queries";

export function useCheckedShapefileList() {
  const { selectedProjectId } = useProjectContext();
  const layerList = useEditableProjectLayerList({ projectId: selectedProjectId });
  // Context

  // Queries
  const shapefileListQuery = useShapefileListQuery({
    projectId: selectedProjectId,
    shapefileIds: layerList.checkedProjectShapefileIds,
    projectLinkedShapefileIds: layerList.checkedProjectLinkedShapefileIds
  });

  return {
    ...shapefileListQuery
  };
}
