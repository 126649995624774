// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiEye } from "@mdi/js";

import { updateXDASettings, useVisState } from "../../context";
import XdaToolbarButton from "./XdaToolbarButton";

function XdaTopsToggle() {
  const [{ xda }, visDispatch] = useVisState();
  const { showAllTops } = xda.settings;

  const handleToggle = (active: boolean) => {
    const nextSettings = { ...xda.settings, showAllTops: active };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <XdaToolbarButton
      icon={<Icon path={mdiEye} size={1.5} />}
      active={showAllTops}
      tooltipText={"Show All Tops"}
      overflowLabel={"Show All Tops"}
      onToggle={handleToggle}
    />
  );
}

export default XdaTopsToggle;
