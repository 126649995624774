import { FunctionComponent, ReactNode } from "react";

import { TooltipPlacement } from "antd/es/tooltip";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

type ToolbarButtonT = {
  testId: string;
  active?: boolean;
  activeIcon?: ReactNode;
  icon: ReactNode;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  overflowLabel?: string;
  tooltipDelay?: number;
  onToggle?: (b: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const ToolbarButton: FunctionComponent<ToolbarButtonT> = ({
  testId,
  active = false,
  activeIcon,
  icon,
  tooltipText = undefined,
  overflowLabel = "",
  tooltipPlacement = "topRight",
  onToggle = null,
  ...rest
}) => {
  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <StyledButton
        active={active}
        data-testid={testId}
        data-qa={testId}
        onClick={() => onToggle(!active)}
        {...rest}>
        {active && activeIcon ? activeIcon : icon}
        {overflowLabel}
      </StyledButton>
    </Tooltip>
  );
};

export default ToolbarButton;

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  background: ${(props) =>
    props.isGlobalSetting && props.active ? "var(--color-primary)" : "transparent"};
  color: ${(props) =>
    props.isGlobalSetting && props.active
      ? "white"
      : props.active
      ? "var(--color-primary)"
      : "#a2aaad"};
  &:hover {
    color: var(--color-primary);
    background: ${(props) =>
      props.isGlobalSetting && props.active ? "white" : "transparent"};
  }
  border: none;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;
