import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import InfoIcon from "@material-ui/icons/Info";
import { Switch } from "antd";
import { AxiosError } from "axios";
import { updateUserOrganization } from "store/features";
import { RootState } from "store/rootReducer";

import {
  disableOrganizationMfa,
  enableOrganizationMfa,
  updateOrganizationUserDefinedField
} from "api/users";

import { BaseDivider, Heading, Tooltip } from "components/base";

import "./OrganizationSecurity.scss";

export default function OrganizationSecurity(): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const { organization } = user;

  const [mfaIsOn, setMfaOn] = useState(organization.requireMfaForOrganization);
  const [allowUserDefinedFields, setAllowUserDefinedFields] = useState(
    organization.allowUserDefinedFields
  );

  const userDefinedFieldMutation = useMutation(
    (allow: boolean) => {
      return updateOrganizationUserDefinedField(organization.id, allow);
    },
    {
      onSuccess: () => {
        dispatch(
          updateUserOrganization({
            ...organization,
            allowUserDefinedFields: allowUserDefinedFields
          })
        );
        toast.success(
          ` ${
            allowUserDefinedFields ? "Turned ON" : "Turned OFF"
          } Allow User Defined Fields.`
        );
      },
      onError: (err: AxiosError) => {
        toast.error(`${err.response?.data ? err.response.data : "Unable to save"}`);
        setAllowUserDefinedFields(!allowUserDefinedFields);
      }
    }
  );

  useEffect(() => {
    if (allowUserDefinedFields !== organization.allowUserDefinedFields) {
      userDefinedFieldMutation.mutate(allowUserDefinedFields);
    }
  }, [allowUserDefinedFields]);

  async function handleChange(value) {
    if (value) {
      setMfaOn(true);
      try {
        await enableOrganizationMfa(organization.id);
        dispatch(
          updateUserOrganization({
            ...organization,
            requireMfaForOrganization: true
          })
        );
      } catch (e) {
        setMfaOn(false);
      }
    } else {
      setMfaOn(false);
      try {
        await disableOrganizationMfa(organization.id);
        dispatch(
          updateUserOrganization({
            ...organization,
            requireMfaForOrganization: false
          })
        );
      } catch (e) {
        setMfaOn(true);
      }
    }
  }
  //   function closeDialog() {
  //     setShowModal(false);
  //   }
  //   function openDialog() {
  //     setShowModal(true);
  //   }
  return (
    <>
      <div className="organizationAccountHeader">
        <Heading element="h4">Account & Security </Heading>
      </div>
      <div className="mfaForm">
        <BaseDivider />
        <span className="mfa-label">
          Multi-factor Authentication
          <Tooltip
            title="Users will be required to enter a MFA code regardless of their MFA status."
            className="inline-flex">
            <InfoIcon
              style={{
                color: "#A2AAAD",
                fontSize: "16px",
                top: "4px",
                left: "7px"
              }}
            />
          </Tooltip>
        </span>
        <div className="mfa-container">
          <span>
            Status: <strong>{mfaIsOn ? "On  " : "Off"}</strong>
          </span>
          <Switch
            disabled={organization.maximumUsers < 999 ? true : false}
            checked={mfaIsOn}
            onChange={handleChange}
          />
        </div>
        <BaseDivider />
        <span className="mfa-label">
          Allow User Defined Fields
          <Tooltip
            title="When toggled on, users will be allowed to enter custom user defined fields only visible to the user."
            className="inline-flex">
            <InfoIcon
              style={{
                color: "#A2AAAD",
                fontSize: "16px",
                top: "4px",
                left: "7px"
              }}
            />
          </Tooltip>
        </span>
        <div className="mfa-container">
          <span>
            Status: <strong>{allowUserDefinedFields ? "On  " : "Off"}</strong>
          </span>
          <Switch checked={allowUserDefinedFields} onChange={setAllowUserDefinedFields} />
        </div>
        <BaseDivider />
      </div>
      {/* <BaseModal
        open={showModal}
        onClose={closeDialog}
        title="Request to deactivate"
        size="sm"
        className="deactivate-account-dialog"
      >
        <div className="flex-column align-center">
          <p>
            If you would like to deactivate your account, please send us a
            request and we will coordinate with you on your request.
          </p>
          <TextArea
            value={deactivateMessage}
            onChange={setDeactivateMessage}
            placeholder="Message(optional)"
          />
          <BaseButton appearance="primary" onClick={closeDialog}>
            Send Request
          </BaseButton>
        </div>
      </BaseModal> */}
    </>
  );
}
