import { ALL_CHART_TYPES, ChartTypeLabels } from "constants/chart.constants";

export const getLassoToggleDisabledStatus = (chartType: ChartTypeLabels) => {
  if (
    [
      ALL_CHART_TYPES.TotalRateDate.label,
      ALL_CHART_TYPES.Pie.label,
      ALL_CHART_TYPES.Mosaic.label,
      ALL_CHART_TYPES.MaterialBalanceTime.label,
      ALL_CHART_TYPES.TotalRateCum.label,
      ALL_CHART_TYPES.WellContribution.label
    ].includes(chartType)
  ) {
    return true;
  }
  return false;
};
