import { Table } from "antd";
import styled from "styled-components";

import { Heading } from "../base";

const EnvironmentSettings = () => {
  // loop through all process.env properties and map to an object of keys and values
  const envVariables = Object.keys(process.env)
    .map((key) => {
      return {
        key,
        value: process.env[key]
      };
    })
    .filter((x) => x.key.startsWith("REACT"));

  const urlValues = envVariables
    .filter((x) => x.value.startsWith("http"))
    .map((x) => {
      const url = new URL(x.value);
      const hostname = url.hostname;
      return hostname.split(/[/]/)[0];
    });
  const uniqueUrlValues = [...new Set(urlValues)];

  const colors = ["#00C774", "#FFBD00", "#008DC7", "#D44A4B", "#8E4AD4"];

  const columns = [
    {
      title: "Variable",
      dataIndex: "key",
      key: "key"
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render(value) {
        const parts = value.split(new RegExp(`(${uniqueUrlValues.join("|")})`, "g"));
        return parts.map((part) => {
          const index = uniqueUrlValues.indexOf(part);
          if (index >= 0) {
            return (
              <DomainSpan key={part} style={{ backgroundColor: colors[index % 5] }}>
                {part}
              </DomainSpan>
            );
          }
          return <>{part}</>;
        });
      }
    }
  ];

  return (
    <>
      <div />
      <RootContainer>
        <Container>
          <Heading element="h4">Environment</Heading>
        </Container>
        <SectionContainer>
          <SectionHeading>Variables</SectionHeading>
          <Table
            dataSource={envVariables}
            columns={columns}
            rowKey="key"
            pagination={false}
          />
        </SectionContainer>
      </RootContainer>
    </>
  );
};

export default EnvironmentSettings;

const RootContainer = styled.div`
  max-width: 800px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  padding: 18px 0;
`;

const SectionHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 1.6rem;
  padding-bottom: 30px;
  margin-bottom: 3px;
  font-weight: var(--fontWeightBold);
  padding: 1px;
`;

const DomainSpan = styled.span`
  padding: 2px 4px;
  color: var(--color-text);
  border-radius: 5px;
`;
