import { useMemo, useRef } from "react";

import classnames from "classnames";
import styled from "styled-components/macro";

import { useChartDependencies } from "components/chart/hooks";
import { useChartSettings } from "components/multiphase-chart/context";
import { useOverflowIndex } from "components/multiphase-chart/hooks";
import useGlobalNormalizeBy from "components/multiphase-chart/hooks/useGlobalNormalizeBy";
import { visibilityToggleStyles } from "components/shared/SharedStyles";

import { EntityKind } from "../../../../models/entityKind";
import {
  DevDebugToggle,
  ForecastToggle,
  FullscreenToggle,
  LegendToggle,
  NormalizeToggle,
  Popout,
  ProducingToggle,
  ScreenshotToggle
} from "../settings";
import BackfitToggle from "../settings/toggles/forecast/BackfitToggle";
import OverflowToggle from "../settings/toggles/overflow/OverflowToggle";
import ToolbarHeader from "./ToolbarHeader";

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;

export const components = [
  <NormalizeToggle key="NormalizeToggle" />,
  <Divider key="globalSettingsDivider" />,
  <DevDebugToggle key="debugModeToggle" />,
  <ProducingToggle key="ProducingToggle" />,
  <ForecastToggle key="ForecastToggle" />,
  <BackfitToggle key="BackfitToggle" />,
  <ScreenshotToggle key="ScreenshotToggle" />,
  <LegendToggle key="LegendToggle" />,
  <Popout key="Popout" />,
  <FullscreenToggle key="FullscreenToggle" />
];

const ChartToolbar = () => {
  const ref = useRef<HTMLDivElement>(null);

  useGlobalNormalizeBy();
  useOverflowIndex(ref);

  const [chartSettings] = useChartSettings();
  const { selectedWells } = useChartDependencies(EntityKind.Well);

  const visibleComponents = useMemo(() => {
    // if chart is in read only mode (popped out), remove the fullscreen option
    // otherwise, if the only item in the overflow menu is the last item, just show it in the toolbar
    let visible = chartSettings?.isReadOnly
      ? components.slice(0, components.length - 1)
      : components.slice(
          0,
          chartSettings.overflowIndex === components.length - 1
            ? chartSettings.overflowIndex + 1
            : chartSettings.overflowIndex
        );

    if (visible[visible.length - 1]?.key === "globalSettingsDivider") {
      visible = visible.slice(0, visible.length - 1); // remove divider if it is the last element
    }
    return visible;
  }, [chartSettings]);

  const visible = chartSettings.isMouseOver && Object.keys(selectedWells).length;
  const wrapperClassnames = classnames({ visible });

  return (
    <Wrapper ref={ref} className={wrapperClassnames}>
      <ToolbarHeader />

      {visibleComponents.map((component) => component)}

      {
        /* more-toggle for overflowing toolbar icons 
          don't display for popped out charts*/
        !chartSettings?.isReadOnly && <OverflowToggle />
      }
    </Wrapper>
  );
};

export default ChartToolbar;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--chart-toolbar-height);
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 14px 16px 0 rgb(45 53 63 / 10%), inset 0 7px 8px -10px rgb(45 53 63 / 15%);
  z-index: 10;
`;
