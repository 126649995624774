import { useMutation } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { MapLayer } from "../models/mapLayer";

const postMapLayer = async (layer: MapLayer) => {
  try {
    const response = await axios.put(
      `${GEO_MAP_SERVICE_ADDRESS}/map-layer/${layer._id}`,
      layer
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to mutate GeoMapLayers");
  }
};

const useUpdateMapLayer = () => {
  return useMutation(postMapLayer);
};

export default useUpdateMapLayer;
