import { memo } from "react";

function OilCond() {
  return (
    <svg width="30px" height="22px" viewBox="0 0 30 22" version="1.1">
      <g id="IconsChart---PRPOil-+-Cond">
        <path
          d="M15.5081 13.2974C15.5081 17.5799 12.0365 21.0515 7.75403 21.0515C3.4716 21.0515 0 17.5799 0 13.2974C0 9.015 7.75403 0 7.75403 0C7.75403 0 15.5081 9.015 15.5081 13.2974ZM24.7454 10.2723C24.7454 11.6582 23.6219 12.7817 22.2359 12.7817C20.85 12.7817 19.7265 11.6582 19.7265 10.2723C19.7265 8.88636 22.2359 5.96882 22.2359 5.96882C22.2359 5.96882 24.7454 8.88636 24.7454 10.2723ZM21.4056 17.5527C21.4056 18.9386 20.2821 20.0621 18.8961 20.0621C17.5102 20.0621 16.3867 18.9386 16.3867 17.5527C16.3867 16.1668 18.8961 13.2492 18.8961 13.2492C18.8961 13.2492 21.4056 16.1668 21.4056 17.5527ZM28.2478 17.5527C28.2478 18.9386 27.1243 20.0621 25.7384 20.0621C24.3524 20.0621 23.2289 18.9386 23.2289 17.5527C23.2289 16.1668 25.7384 13.2492 25.7384 13.2492C25.7384 13.2492 28.2478 16.1668 28.2478 17.5527Z"
          transform="matrix(1 0 0 1 1.8189894E-12 0)"
          id="Combined-Shape"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default memo(OilCond);
