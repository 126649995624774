import { useMutation } from "react-query";

import { postMultiPhase } from "api/multiProduct";

import { IMultiPhaseInput } from "../models/shared.models";

// TODO: Update  data interface to match the API
// e.g product key is required, not product name
export default function usePostMultiPhase(handlePostData: (postData) => void) {
  const mutation = useMutation(
    "postMultiPhase",
    async (state: IMultiPhaseInput) => {
      const result = await postMultiPhase(state);
      return result;
    },
    {
      onSuccess: (postData) => {
        handlePostData(postData);
        // Since calculations for the legend width and grid paddings are based on existing chart ref data,
        // We need to recalculate after the chart has been rendered
        handlePostData(postData);
      },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  );

  return mutation;
}
