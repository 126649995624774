// eslint-disable-next-line import/no-named-as-default
import { useEffect, useMemo, useState } from "react";

import { Alert, Col, Form, Input, Modal, Row, Select } from "antd";
import styled from "styled-components";

import useOriginalPlayTo3DDataSources from "hooks/useOriginalPlayTo3DDataSources";
import usePlayTo3DFields from "hooks/usePlayTo3DFields";

import { CutoffSource } from "api/ipdp";

import { usePostCutoffGeoMap } from "../hooks/usePostCutoffGeoMap";

const { TextArea } = Input;
export const CreateCutoffGeoModel = ({
  requestShowModal,
  sources,
  onClose,
  onSuccess
}: CreateCutoffGeoModelProps) => {
  const [isModalVisible, setIsModalVisible] = useState(requestShowModal);
  const [cutoffValidationStatus, setCutoffValidationStatus] = useState<boolean>(true);
  const [sourceValidationStatus, setSourceValidationStatus] = useState<boolean>(true);
  const [errors, setErrors] = useState<string>("");
  const [form] = Form.useForm();

  const { mutateAsync, isError, error } = usePostCutoffGeoMap();

  const [originalModelSource, setOriginalModelSource] = useState("");

  const { data: fields, refetch: refetchPlayFields } =
    usePlayTo3DFields(originalModelSource);

  const memoizedFieldNames = useMemo(() => {
    return fields?.map((f) => f.displayName.replaceAll(" ", "_")).join(", ");
  }, [fields]);

  const { data: modelSources, refetch: refetchDataSources } =
    useOriginalPlayTo3DDataSources();

  useEffect(() => {
    refetchDataSources();
  }, []);

  useEffect(() => {
    refetchPlayFields();
  }, [originalModelSource]);

  useEffect(() => {
    setIsModalVisible(requestShowModal);
  }, [requestShowModal]);

  const validateName = (_, value) => {
    if (!value) {
      setErrors("");
      setSourceValidationStatus(true);
      return Promise.resolve();
    }

    const invalidCharRegex = /[^a-zA-Z0-9-_ ]/;

    if (invalidCharRegex.test(value)) {
      setSourceValidationStatus(false);
      return Promise.reject(
        new Error("Invalid character(s) found in cutoff geo model name")
      );
    }

    setSourceValidationStatus(true);
    return Promise.resolve();
  };

  const validateCutoffDefinitions = (_, value) => {
    if (!value) {
      setErrors("");
      setCutoffValidationStatus(true);
      return Promise.resolve();
    }

    const expressionPattern =
      /^\s*(@\w+|\d+(\.\d+)?|[<>]=?|!=|<>|==|AND|OR|NOT|and|or|not|\(|\))(\s+(@\w+|\d+(\.\d+)?|[<>]=?|!=|<>|==|AND|OR|NOT|and|or|not|\(|\)))*\s*$/;
    if (value && !expressionPattern.test(value)) {
      setCutoffValidationStatus(false);
      return Promise.reject(new Error("Invalid cutoff definitions expression format"));
    }

    const params = value.match(/@\S* /g) || [];

    if (
      params.some(
        (param) =>
          !fields.some(
            (f) =>
              f.displayName === param.trim().slice(1) ||
              f.displayName.replaceAll(" ", "_") === param.trim().slice(1)
          )
      )
    ) {
      setCutoffValidationStatus(false);
      return Promise.reject(new Error("Invalid field name in cutoff definitions"));
    }

    setErrors("");
    setCutoffValidationStatus(true);
    return Promise.resolve();
  };

  const handleOk = async () => {
    try {
      if (!sourceValidationStatus || !cutoffValidationStatus) {
        setErrors("All errors need to be fixed before submitting new cutoff geo model");
        return;
      }

      const values = await form.validateFields();
      const invalidCharRegex = /[^a-zA-Z0-9-_ ]/;

      if (invalidCharRegex.test(values.source)) {
        setErrors("Invalid character(s) found in cutoff geo model name.");
        return;
      }

      if (sources.some((job) => job.source === values.source.trim())) {
        setErrors("Duplicated cutoff geo model name.");
        return;
      }

      if (
        sources.filter((job) => job.originalSource === values.originalSource).length >= 2
      ) {
        setErrors("Can't create more than 2 cutoff geo models per original source.");
        return;
      }

      const expressionPattern =
        /^\s*(@\w+|\d+(\.\d+)?|[<>]=?|!=|<>|==|AND|OR|NOT|and|or|not|\(|\))(\s+(@\w+|\d+(\.\d+)?|[<>]=?|!=|<>|==|AND|OR|NOT|and|or|not|\(|\)))*\s*$/;
      if (!expressionPattern.test(values.cutoff))
        setErrors("Invalid cutoff definitions expression format");

      const params = values.cutoff.match(/@\S* /g) || [];

      if (
        params.some(
          (param) =>
            !fields.some(
              (f) =>
                f.displayName === param.trim().slice(1) ||
                f.displayName.replaceAll(" ", "_") === param.trim().slice(1)
            )
        )
      ) {
        setErrors("Invalid field name in cutoff definitions");
      }

      const postData: CutoffSource = {
        ...values
      };

      await mutateAsync(postData);

      setErrors("");
      onSuccess && onSuccess();
    } catch (e) {
      setErrors("Error occurred while creating new cutoff geo model");
      return;
    }
  };

  const requiredRule = { required: true, message: "This field is required" };

  return (
    <RootContainer>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={onClose}
        title={"Add Cutoff Geo Model"}
        closable={false}
        maskClosable={false}>
        <Form
          name="edit"
          form={form}
          initialValues={{
            remember: true,
            leftWidth: 300,
            rightWidth: 300,
            upHeight: 50,
            downHeight: 50
          }}
          onFinish={handleOk}
          requiredMark={false}>
          <Form.Item
            name="source"
            label={<Label>Name</Label>}
            rules={[requiredRule, { validator: validateName }]}>
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="originalSource"
                label={<Label>Source Model</Label>}
                rules={[requiredRule]}>
                <Select
                  data-testid="model-source-select"
                  value={originalModelSource}
                  defaultValue={originalModelSource}
                  options={(modelSources || []).map((source) => ({
                    label: source,
                    value: source
                  }))}
                  onChange={(e) => {
                    setOriginalModelSource(e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<Label>Cutoff Parameters</Label>}>
                <span>{memoizedFieldNames}</span>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="cutoff"
                label={<Label>Cutoff Definitions</Label>}
                rules={[requiredRule, { validator: validateCutoffDefinitions }]}
                help={
                  cutoffValidationStatus
                    ? "@Porosity > 5.0 AND @Pressure < 1000.0"
                    : undefined
                }>
                <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {(isError || errors) && (
          <ErrorMessage
            type="error"
            message={`${
              errors ??
              error?.response?.data ??
              "Error occurred while creating new cutoff geo model"
            }`}
          />
        )}
      </Modal>
    </RootContainer>
  );
};

const RootContainer = styled.div``;

const Label = styled.span`
  display: inline-block;
  width: 80px;
  white-space: normal;
  word-wrap: break-word;
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 1em;
`;

interface CreateCutoffGeoModelProps {
  requestShowModal: boolean;
  sources: CutoffSource[];
  onClose: () => void;
  onSuccess: () => void;
}
