import { memo } from "react";

function GOR() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
      <g id="IconsChart---PRPGOR">
        <path
          d="M0 26.5666L27 0"
          transform="translate(0.879209 1.5388219)"
          id="Path-8-Copy-10"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <path
          d="M10.2441 8.78083C10.0666 5.91552 5.04663 0 5.04663 0C3.91117 5.0971 -0.00453337 5.67788 3.93994e-06 9.03322C0.00283976 11.2973 1.02941 12.612 2.31006 13.3102C1.90454 11.9541 2.06902 10.3802 3.28672 9.2425C5.53496 7.14173 5.78224 5.72892 5.78224 5.72892C8.10818 9.85844 7.53761 12.4532 6.66134 13.7617C8.79502 13.1724 10.4069 11.404 10.2441 8.78083"
          id="Fill-1-Copy-5"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M5.06893 13.7617C7.86842 13.7617 10.1379 11.4922 10.1379 8.69274C10.1379 5.89324 5.06893 1.95565e-13 5.06893 1.95565e-13C5.06893 1.95565e-13 0 5.89324 0 8.69274C0 11.4922 2.26944 13.7617 5.06893 13.7617Z"
          transform="translate(18.965815 15.581296)"
          id="Oval-Copy-23"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default memo(GOR);
