import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "antd/dist/antd.variable.min.css";
import initStore from "store/store";
import { uid } from "utils";

import { ThemeProvider } from "api/theme.context";

import "./antd.css";
import "./index.css";
import "./main.scss";
import reportWebVitals from "./reportWebVitals";
import "./wdyr";

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require("./App").default;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let channel = urlParams.get("channel");
  if (!channel) {
    channel = uid();
  }
  const store = initStore({ channel });
  const rootEl = document.getElementById("root");
  if (process.env.REACT_APP_ENV === "dev") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require("./mocks/browser");
    worker.start();
  }
  // eslint-disable-next-line import/no-named-as-default-member
  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </Provider>,
    rootEl
  );
};
render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
