import { InputNumber, Select } from "antd";
import styled from "styled-components";

import { IconSpinner } from "components/icons";

export const SubsectionContainer = styled.div``;

export const SectionContainer = styled.div`
  padding: 18px 0;

  ${SubsectionContainer}:not(:last-child) {
    padding-bottom: 18px;
  }
`;

export const SectionActionsContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-btn-text {
    display: flex;
    align-items: center;
    color: var(--color-primary);
  }
  .ant-btn-text:hover {
    color: var(--color-text);
  }
`;

export const SectionHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 1.6rem;
  padding-bottom: 30px;
  margin-bottom: 3px;
  font-weight: var(--fontWeightBold);
  padding: 1px;
`;

export const SubsectionHeading = styled.div`
  color: var(--color-text);
  font-weight: var(--fontWeightMedium);
  transition: color var(--duration) var(--ease);
  padding-bottom: 3px;
`;

export const SectionInputWrapper = styled.div`
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .anticon-loading {
    font-size: 18px;
    color: var(--color-primary);
  }
`;
export const StyledNumberInput = styled(InputNumber)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  height: 50px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100% !important;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;

export const StyledSpinner = styled(IconSpinner)`
  align-self: flex-start;
`;
