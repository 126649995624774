import styled from "styled-components";

import { IShapefile } from "models/projects";

import { ColorPicker } from "components/base";
import { ShapefileActions } from "components/project/layers/components/layer-managers/shared/shapefile-actions/ShapefileActions";
import { useDeleteProjectShapefileMutation } from "components/project/shapefiles/mutations";

export default function SystemShapefileNode({ node }) {
  const deleteShapefile = useDeleteProjectShapefileMutation({
    projectId: "system"
  });

  const sf = node as IShapefile;

  return (
    <LeafTitle>
      <FlexItem>
        <ColorPicker
          isReadOnly={true}
          values={{
            color: sf.color,
            strokeColor: sf.strokeColor,
            thickness: sf.thickness,
            opacity: (sf.opacity ?? 1) * 100
          }}
        />
        <label>{node.title}</label>
      </FlexItem>
      <FlexItem>
        <ShapefileActions
          deleteShapefile={() => {
            deleteShapefile.mutate({
              shapefileId: sf.shapefileId,
              projectId: "system"
            });
          }}
        />
      </FlexItem>
    </LeafTitle>
  );
}

//Styled Components

const ActionButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled] > svg {
    color: lightgray;
  }
`;

const FlexItem = styled.div`
  display: inline-flex;
  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 24px;
  }
  .ant-btn:hover {
    color: var(--color-primary);
  }
  .ant-btn.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  .ant-btn.active:hover {
    background-color: #fff;
    color: var(--color-primary);
  }
`;

const LeafTitle = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  align-items: center;
  justify-content: space-between;

  .drag-container {
    position: absolute;
    opacity: 0.5;
    left: -20px;
    top: 4px;
    color: #a2aaad;
    cursor: move;
  }

  ${ActionButton} {
    svg {
      opacity: 0;
    }
  }

  &:hover,
  &.in-edit-mode {
    svg {
      opacity: 1;
    }
  }

  input[type="text"] {
    height: 24px;
    outline: none;
  }

  input[type="text"]:focus {
    outline: none 0;
  }

  label {
    margin-left: 5px !important;
  }
`;
