// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddCircle, CloudDownload } from "@material-ui/icons";
import {
  mdiCloudUpload,
  mdiDelete,
  mdiDotsVertical,
  mdiFilterPlus,
  mdiFolderPlus,
  mdiFormTextbox
} from "@mdi/js";
import { Popconfirm, Popover, Upload } from "antd";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import { TYPE_WELLS, USER_ARPS } from "constants/settings.constants";
import {
  filterWellsFromList,
  setCheckedForecasts,
  setExpandedKeys,
  setFilteredForecastFolders,
  setForecastFolders,
  setHasViewItemsChanged
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { ForecastFolder, ForecastFolderItem, UserArpsItem } from "models/UserArpsModel";

import { ButtonIconCentered } from "components/activity/shared";
import {
  useTypeWellSaver,
  useTypeWellUpdater,
  useValNavTypeWellUpdater
} from "components/arps/hooks";
import { Tooltip } from "components/base";
import { IconSpinner } from "components/icons";

import { useDeleteForecast } from "../../arps/hooks/useDeleteForecast";
import useUndoRedo from "../../arps/hooks/useUndoRedo";
import { useSelectedProject } from "../projects/hooks";
import ExportForecastsButton, { WellSource } from "./ExportForecastsButton";
import ValNavTypeWellImporter from "./ValNavTypeWellImporter";

export default function FolderNodeActions({
  fNode,
  type,
  source,
  valnavFolderMutation,
  setNameField,
  setError,
  onAcceptFiles,
  uploadValNav,
  location
}) {
  const dispatch = useDispatch();

  const filteredForecastFolders = useSelector(
    (state: RootState) =>
      state.arps[
        type === TYPE_WELLS ? "filteredTypeWellFolders" : "filteredForecastFolders"
      ]
  );
  const forecastFolders = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "typeWellFolders" : "forecastFolders"]
  );

  const checkedForecasts = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "checkedTypeWells" : "checkedForecasts"]
  );

  const expandedKeys = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "expandedTypeWellKeys" : "expandedForecastKeys"]
  );

  function handleFolderWellListFilter(folder) {
    const filteredWells = folder.children.map((child) => child.uniqueID);
    if (filteredWells?.length > 0) {
      dispatch(filterWellsFromList(filteredWells));
    }
  }
  const undoRedoManager = useUndoRedo();
  const { selectedProject: project } = useSelectedProject();

  const { onSaveTypeWell } = useTypeWellSaver(undoRedoManager);
  const { changeSelectedTypeWell } = useTypeWellUpdater(undoRedoManager);
  const { updateFolderWithValNavData } = useValNavTypeWellUpdater(type);

  const deleteForecast = useDeleteForecast(type);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const typeWellUploadProps = {
    multiple: false,
    accept: ".csv,.xlsx,.xls,.xml",
    fileList: [], // This is important to clear the files after they are uploaded.
    showUploadList: false,
    onChange: async (info) => {
      const fileList = info.fileList.map((file) => file.originFileObj);
      const files = onAcceptFiles(fileList);
      await uploadValNav(fNode.folderName, fNode.folderId, files);

      // FIXME: A new folder is not expanded after uploading the type well.
      //  The reason for that is because the folder id changes after the file is added,
      //  and the reserve category is appended to it. For ex. "123", "123_P+PDP".
      //  Note: This still works for other folders that have a reserve category already.
      // Using set to remove duplicate checked keys since this can get called multiple times.
      const updatedExpandedKeys = Array.from(new Set([...expandedKeys, fNode.id]));
      dispatch(
        setExpandedKeys({
          type: type,
          expandedKeys: updatedExpandedKeys
        })
      );
    }
  };

  function newFolder(parentId) {
    const foldersCopy = JSON.parse(JSON.stringify(forecastFolders));
    foldersCopy.forEach((folder) => {
      folder.isEdit = false;
    });
    const folderName = "New Folder";
    const folderItem: ForecastFolderItem = {
      folderId: null,
      projectId: project?.projectId,
      forecasts: null,
      name: folderName,
      folderName: folderName,
      reserveCategory: "",
      parentId: parentId
    };
    const folder = new ForecastFolder(folderItem);
    folder.isEdit = true;
    folder.editLocation = location;
    setNameField(folder.folderName);

    dispatch(
      setExpandedKeys({
        type: type,
        expandedKeys: [...expandedKeys, parentId]
      })
    );
    dispatch(
      setForecastFolders({ type: type, forecastFolders: [...foldersCopy, folder] })
    );
  }

  function setFolderToEditable(folder) {
    const copy: ForecastFolder = JSON.parse(JSON.stringify(folder));
    const folders = JSON.parse(JSON.stringify(forecastFolders));
    folders.forEach((folder) => (folder.isEdit = false));
    folders.forEach((folder) =>
      folder.children.forEach((child) => (child.isEdit = false))
    );

    copy.isEdit = true;
    copy.editLocation = source;
    copy.children.forEach((child) => (child.isEdit = false));

    const idx = folders.findIndex((folder) => folder.key === copy.key);
    if (idx >= 0) {
      folders.splice(idx, 1, copy);
    }
    dispatch(setForecastFolders({ type: type, forecastFolders: folders }));
    setNameField(folder.folderName);
  }
  const collectFoldersToDelete = (node) => {
    const foldersToDelete = [];

    const traverse = (currentNode) => {
      if (currentNode.children && currentNode.children.length > 0) {
        currentNode.children.forEach((child) => {
          foldersToDelete.push({
            folderId: child.folderId,
            id: child.id,
            type: child.type ?? "forecast"
          });
          traverse(child);
        });
      }
    };

    traverse(node);
    return foldersToDelete;
  };

  function deleteFolder() {
    if (!project?.projectId) {
      return;
    }
    const foldersToDelete = collectFoldersToDelete(fNode);

    foldersToDelete.push({
      folderId: fNode.folderId,
      id: fNode.id,
      type: fNode.type ?? "forecast"
    });

    deleteForecast
      .delete(foldersToDelete)
      .then(async () => {
        setError(null);
        return;
      })
      .catch((err) => {
        setError(`Error deleting. ${err.message}`);
      });
  }

  const onAddNewTypeWell = async (tw: ForecastFolder, twName: string) => {
    if (!tw?.isFolder) {
      return;
    }
    tw.children = tw.children.filter((child) => !child.isFolder);

    const key = uuidv4();
    const newTw = {
      arps: [],
      folderId: tw.folderId,
      id: key,
      reserveCategory: tw?.reserveCategory,
      title: twName,
      uniqueID: `${key}${tw?.reserveCategory ? " (" + tw?.reserveCategory + ")" : ""}`,
      type: "forecast",
      children: [],
      color: "#000",
      thickness: 4,
      wellData: undefined,
      constants: [],
      folderName: tw.folderName,
      isChecked: false,
      isFolder: false,
      key: key,
      wellList: []
    } as UserArpsItem;

    if (newTw.children.findIndex((child) => child.uniqueID === tw.uniqueID) < 0) {
      const folderIndex = filteredForecastFolders.findIndex(
        (item) => item.id === tw?.id && item.reserveCategory === tw?.reserveCategory
      );
      if (folderIndex < 0) {
        return;
      }

      const newTwFolder = JSON.parse(JSON.stringify(tw));
      const newCheckedTw = [newTw];
      newTwFolder.children = tw.children.concat(newCheckedTw);
      const newFolders = JSON.parse(JSON.stringify(filteredForecastFolders));
      newFolders.splice(folderIndex, 1, newTwFolder);
      dispatch(
        setFilteredForecastFolders({
          type: type,
          filteredForecastFolders: newFolders
        })
      );

      const folderToAddTypeWellTo = filteredForecastFolders[folderIndex];
      dispatch(
        setExpandedKeys({
          type: type,
          expandedKeys: [...expandedKeys, folderToAddTypeWellTo.id]
        })
      );

      dispatch(
        setCheckedForecasts({
          type: type,
          checkedForecasts: [...newCheckedTw, ...checkedForecasts]
        })
      );

      changeSelectedTypeWell(newTw);

      await onSaveTypeWell(newTw);
      setTimeout(() => {
        dispatch(
          setCheckedForecasts({
            type: type,
            checkedForecasts: [...newCheckedTw, ...checkedForecasts]
          })
        );

        changeSelectedTypeWell(newTw);
      }, 200);

      dispatch(setHasViewItemsChanged(true));
    }
  };

  const TypeWellFolderMoreOptions = () => {
    return (
      <ActionListWrapper>
        {type === TYPE_WELLS && (
          <TypeWellAction
            onClick={(evt) => {
              dispatch(setHasViewItemsChanged(true));
              newFolder(fNode.folderId);
              evt.stopPropagation();
              evt.preventDefault();
            }}>
            <Icon path={mdiFolderPlus} size={1} />
            Add Folder
          </TypeWellAction>
        )}
        <TypeWellAction
          onClick={(evt) => {
            setFolderToEditable(fNode);
            evt.stopPropagation();
            evt.preventDefault();
          }}>
          <Icon path={mdiFormTextbox} size={1} />
          Rename
        </TypeWellAction>
        <Popconfirm
          placement="bottom"
          onConfirm={(evt) => {
            deleteFolder();
            evt.stopPropagation();
            evt.preventDefault();
          }}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Delete"
          okType="danger"
          title={`Are you sure you want to delete the folder and its contents?`}>
          <TypeWellAction>
            <Icon path={mdiDelete} size={1} />
            Delete
          </TypeWellAction>
        </Popconfirm>

        {type === TYPE_WELLS && (
          <ExportForecastsButton
            wellSource={WellSource.TypeWells}
            node={fNode}
            type="folder"
          />
        )}
      </ActionListWrapper>
    );
  };

  return (
    <div className="edit-icon">
      <ForecastActionButtonContainer>
        {type === USER_ARPS && fNode.children?.length > 0 && (
          <Tooltip placement="top" title="Filter to Well List">
            <ForecastActionButton
              onClick={(evt) => {
                handleFolderWellListFilter(fNode);
                evt.stopPropagation();
                evt.preventDefault();
              }}>
              <Icon path={mdiFilterPlus} size={1.4} />
            </ForecastActionButton>
          </Tooltip>
        )}
        {type === TYPE_WELLS && (
          <>
            <Tooltip placement="top" title="Add new type well to folder">
              <ForecastActionButton
                data-testid="new-tw-button"
                onClick={async () => {
                  await onAddNewTypeWell(fNode as ForecastFolder, "New Type Well");
                }}>
                <AddCircle fontSize="large" />
              </ForecastActionButton>
            </Tooltip>
            <StyledUpload {...typeWellUploadProps}>
              <Tooltip placement="top" title="Upload type well to folder">
                <ForecastActionButton>
                  <Icon path={mdiCloudUpload} size={1.5} />
                </ForecastActionButton>
              </Tooltip>
            </StyledUpload>
          </>
        )}

        {type === TYPE_WELLS && IS_INTERNAL_ENV && (
          /*import type well from valnav*/
          <ValNavTypeWellImporter
            onOk={async (job, rescat, typeWells) => {
              const ok = await updateFolderWithValNavData(fNode, {
                jobNumber: job,
                rescat,
                typeWells
              });
              if (!ok) {
                setError("Unable to update folder.");
              }
            }}>
            <Tooltip placement="top" title="Sync client job type well">
              <ButtonIconCentered
                shape="circle"
                type="text"
                icon={
                  valnavFolderMutation.isLoading ? (
                    <IconSpinner />
                  ) : (
                    <CloudDownload fontSize="large" />
                  )
                }
              />
            </Tooltip>
          </ValNavTypeWellImporter>
        )}
        {
          <Tooltip className={"visible-on-hover"} title="More">
            <Popover
              arrowPointAtCenter={true}
              onOpenChange={(isOpen) => {
                setIsPopoverOpen(isOpen);
              }}
              content={TypeWellFolderMoreOptions()}
              open={isPopoverOpen}
              overlayClassName="popover-no-padding"
              placement="bottomLeft"
              trigger="click">
              <ForecastActionButton
                type={"text"}
                onClick={(event) => event.stopPropagation()}>
                <Icon path={mdiDotsVertical} size={1} />
              </ForecastActionButton>
            </Popover>
          </Tooltip>
        }
      </ForecastActionButtonContainer>
    </div>
  );
}

// This is necessary to align the upload icon with the other folder icons.
const StyledUpload = styled(Upload)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ForecastActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionListWrapper = styled.div`
  padding: 6px;
`;

const ForecastActionButton = styled.button`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 3px;
  cursor: pointer;
  background: transparent;
  color: ${(props) => props.color?.toString() ?? "#a2aaad"};

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled]:hover > svg {
    cursor: default;
    color: lightgray;
  }
`;

const TypeWellAction = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);
  cursor: pointer;
`;
