import { useMemo } from "react";

import { useChartScreenshotCalculatedDefaults } from "components/chart/hooks";
import { useMapScreenshotCalculatedDefaults } from "components/map/hooks";
import useMPCScreenshotCalculatedDefaults from "components/multiphase-chart/hooks/useMPCScreenshotCalculatedDefaults";
import { useSamScreenshotCalculatedDefaults } from "components/sam/hooks";
import { useXDAScreenshotCalculatedDefaults } from "components/vis/hooks";

import { useScreenshotPresetContext } from "./useScreenshotPresetContext";

export function useScreenshotCalculatedDefaults() {
  // hooks
  const { selectedPreset } = useScreenshotPresetContext();
  const chartDefaults = useChartScreenshotCalculatedDefaults();
  const mapDefaults = useMapScreenshotCalculatedDefaults();
  const samDefaults = useSamScreenshotCalculatedDefaults();
  const mpcDefaults = useMPCScreenshotCalculatedDefaults();
  const XDADefaults = useXDAScreenshotCalculatedDefaults();

  return useMemo(() => {
    return {
      ...chartDefaults,
      ...mapDefaults,
      ...samDefaults,
      ...mpcDefaults,
      ...XDADefaults,
      ...selectedPreset
    };
  }, [chartDefaults, mapDefaults, samDefaults, mpcDefaults, XDADefaults, selectedPreset]);
}
