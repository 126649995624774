import { DialogContent, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

import { BaseIconToggle } from ".";
import "./BaseModal.scss";

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other} style={{ padding: "16px 46px 0 46px" }}>
      {onClose ? (
        <CloseIconContainer>
          <BaseIconToggle size={28}>
            <CloseIcon onClick={onClose} style={{ fontSize: "28px" }} />
          </BaseIconToggle>
        </CloseIconContainer>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
};

export default function BaseModal({
  onClose,
  open,
  size,
  title,
  subtitle = "",
  className = "",
  children
}) {
  return (
    <DialogMigrate
      open={open}
      onClose={onClose}
      maxWidth={size}
      className={className}
      fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </DialogTitle>
      <DialogContent style={{ padding: "16px 46px" }}>{children}</DialogContent>
    </DialogMigrate>
  );
}

function DialogMigrate({
  children,
  disableBackdropClick = true,
  open,
  onClose,
  ...rest
}) {
  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }

    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} {...rest}>
      {children}
    </Dialog>
  );
}

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  right: -26px;
`;

const Title = styled.div`
  font-size: 3.2rem;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontSans);
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 1.6rem;
  font-weight: var(--fontWeightBold);
  font-family: var(--fontSans);
`;
