import { ProjectTreeNodeT } from "components/project/layers/hooks";

export function findParentNode(
  data: ProjectTreeNodeT[],
  id: string,
  source: string
): string {
  function searchChildren(
    parent: ProjectTreeNodeT,
    children: ProjectTreeNodeT[]
  ): string {
    for (const child of children) {
      if (child.key === id) {
        //found shapefile so return the nodeId
        return parent.key;
      }
      if (child.children) {
        const found = searchChildren(child, child.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }
  const shapefiles = data.filter((node) => node.source === source);
  const rootNode = shapefiles.length > 0 ? shapefiles[0] : undefined;
  if (!rootNode) {
    return null;
  }
  return searchChildren(rootNode, rootNode.children);
}
