import { cloneElement, memo, useCallback, useEffect, useRef, useState } from "react";
import { PortalWithState } from "react-portal";
import ReactTooltip from "react-tooltip";

import styled from "styled-components";

import { isIntersectingViewport, parseTransform, uid } from "../../utils";
import "./BaseMenu.scss";

const viewport_gap = 30;

export const align_options = Object.freeze({
  start: "start",
  center: "center",
  end: "end"
});

export const place_options = Object.freeze({
  top: "top",
  left: "left",
  bottom: "bottom",
  right: "right"
});

interface MenuProps {
  align?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: (_: any) => JSX.Element;
  hasBlockLabel?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  place?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggle?: (_: any) => void;
  trigger?: JSX.Element;
}

const FormContainer = styled.div`
  ${(props: MenuProps) => props.hasBlockLabel} {
    display: block;
  }
`;

const BaseMenu: React.FC<MenuProps> = ({
  align = "start",
  children = null,
  onClose = null,
  onOpen = null,
  place = place_options.bottom,
  toggle = null,
  trigger = null
}) => {
  const [isOpen, setOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const menuRef = useRef(null);
  const targetId = uid();
  const [top, setTop] = useState(0);
  const triggerRef = useRef(null);

  function position() {
    return `${place}-${align}`;
  }

  function close() {
    setOpen(false);
    onClose && onClose();
    toggle && toggle(isOpen);
  }

  function open() {
    setOpen(true);
    reposition();
    onOpen && onOpen();
  }

  const adjustOffsets = useCallback(() => {
    if (
      triggerRef == null ||
      triggerRef.current == null ||
      menuRef == null ||
      menuRef.current == null
    ) {
      return;
    }
    // get bounding-rect for trigger and menu elements
    const trigger = triggerRef.current.getBoundingClientRect();
    const menu = menuRef.current.getBoundingClientRect();

    // add adjustments for initial scale value
    const { scaleX, scaleY } = parseTransform(menuRef.current);
    menu.width += menu.width * (1 - scaleX);
    menu.height += 2 * menu.height * (1 - scaleY);

    /*
          calculate central co-ordinates based on trigger and menu position and dimensions,
          this will automatically align the menu at center of trigger component.
        */
    const centralX = trigger.left + trigger.width / 2 - menu.width / 2;
    const centralY = trigger.top + trigger.height / 2 - menu.height / 2;

    let offsets;
    switch (place) {
      case "top":
        offsets = {
          x: centralX,
          y: trigger.top - menu.height
        };
        break;
      case "bottom":
        offsets = {
          x: centralX,
          y: trigger.top + trigger.height
        };
        break;
      case "right":
        offsets = {
          x: trigger.left + trigger.width,
          y: centralY
        };
        break;
      case "left":
        offsets = {
          x: trigger.left - menu.width,
          y: centralY
        };
        break;
      default:
        offsets = {
          x: trigger.left,
          y: trigger.top
        };
    }

    /*
          when placed at top or bottom of trigger,
          reposition for alignment across x-axis (offsetLeft), else
          reposition for alignment across y-axis (offsetTop)
        */
    const prop = ["top", "bottom"].indexOf(place) >= 0 ? "x" : "y";

    /*
          when adjusting offsetTop, use component-height, else
          use the component-width
        */
    const dimension = prop === "y" ? "height" : "width";

    switch (align) {
      case "start":
        offsets[prop] =
          Math.floor(offsets[prop]) -
          Math.floor(trigger[dimension] / 2 - menu[dimension] / 2);
        break;
      case "end":
        offsets[prop] =
          Math.floor(offsets[prop]) +
          Math.ceil(trigger[dimension] / 2 - menu[dimension] / 2);
        break;
      default:
    }

    // apply the offsets
    const { x, y } = offsets;
    setTop(y);
    setLeft(x);
  }, [setTop, setLeft, align, place]);

  const adjustOverflow = useCallback(() => {
    if (!menuRef || !menuRef.current) return;

    const element = menuRef.current;
    const menu = menuRef.current.getBoundingClientRect();
    const isIntersecting = isIntersectingViewport(element);

    if (!isIntersecting.any) return;

    const { offset } = isIntersecting;
    const { top, left, right, bottom } = offset;

    // shift element by overflow values
    if (isIntersecting.top) {
      const newTop = menu.top + top + viewport_gap;
      setTop(newTop);
      element.style.top = `${newTop}px`;
    }

    if (isIntersecting.left) {
      const newLeft = menu.left + left + viewport_gap;
      setLeft(newLeft);
      element.style.left = `${newLeft}px`;
    }

    if (isIntersecting.right) {
      const newLeft = menu.left - right - viewport_gap;
      setLeft(newLeft);
      element.style.left = `${newLeft}px`;
    }

    if (isIntersecting.bottom) {
      const newTop = menu.top - bottom - viewport_gap;
      setTop(newTop);
      element.style.top = `${newTop}px`;
    }
  }, [menuRef]);

  const reposition = useCallback(() => {
    adjustOverflow();
    adjustOffsets();
  }, [adjustOffsets, adjustOverflow]);

  useEffect(() => {
    reposition();

    ReactTooltip.rebuild();
  }, [reposition, menuRef, triggerRef, isOpen]);

  function styleObject() {
    return {
      top: `${Math.round(top)}px`,
      left: `${Math.round(left)}px`
    };
  }

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc onOpen={open} onClose={close}>
      {({ openPortal, closePortal, isOpen, portal }) => (
        <>
          {cloneElement(
            <FormContainer className="trigger-container">{trigger}</FormContainer>,
            {
              onClick: (e) => {
                openPortal(e);
              },
              ref: triggerRef
            }
          )}
          {portal(
            <div
              ref={menuRef}
              className={`BaseMenu menu-${targetId} ${position()} ${
                isOpen ? "isOpen" : ""
              }`}
              style={styleObject()}
              onClick={(e) => {
                // momentary fix for modal inside modal
                if (e.currentTarget.classList.contains("BaseMenu")) e.stopPropagation();
              }}>
              <div className="content">
                {children &&
                  children({
                    openMenu: openPortal,
                    closeMenu: closePortal,
                    isOpen
                  })}
              </div>
            </div>
          )}
        </>
      )}
    </PortalWithState>
  );
};

export default memo(BaseMenu);
