import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { feature, featureCollection } from "@turf/helpers";
import {
  TYPE_LOG_LAYER,
  TYPE_LOG_VERTICAL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import { AnyLayer, GeoJSONSource } from "mapbox-gl";
import { RootState } from "store/rootReducer";

import { waitForStyleToLoad } from "../utils";
import { emptyFeatureCollection } from "../utils/emptyFeatureCollection";

export default function useTypeLogWellsLayer(
  mapbox: mapboxgl.Map,
  hasTypeLogWidget: boolean,
  addLayer
) {
  const typeLogWells = useSelector((state: RootState) => state.map.typeLogWells);
  const filterId = useSelector((state: RootState) => state.filter.filterId);

  const setGeojsonSource = (layer: string, geojson: GeoJSON.FeatureCollection) => {
    if (!mapbox) {
      return false;
    }
    if (mapbox.getLayer(layer) && mapbox.getSource(layer)) {
      const source = mapbox.getSource(layer) as GeoJSONSource;
      if (source) {
        source.setData(geojson);
        return true;
      }
    }
    return false;
  };
  const clearGeojsonSource = (layer: string) => {
    if (!mapbox) {
      return;
    }
    if (mapbox.getLayer(layer)) {
      (mapbox.getSource(layer) as GeoJSONSource)?.setData({ ...emptyFeatureCollection });
    }
  };
  function clearHighlightedWells() {
    clearGeojsonSource(TYPE_LOG_LAYER);
    clearGeojsonSource(TYPE_LOG_VERTICAL_LAYER);
  }

  useEffect(() => {
    if (!hasTypeLogWidget) {
      clearHighlightedWells();
    } else {
      highlightTypeLogWells();
    }
  }, [hasTypeLogWidget, filterId]);

  const highlightTypeLogWells = useCallback(() => {
    if (!mapbox || !typeLogWells || !hasTypeLogWidget) return;

    function setHighlightedHzWells(collection: GeoJSON.FeatureCollection) {
      if (
        !setGeojsonSource(TYPE_LOG_LAYER, collection) &&
        !mapbox.getLayer(TYPE_LOG_LAYER)
      ) {
        const layer = {
          id: TYPE_LOG_LAYER,
          type: "symbol",
          source: {
            type: "geojson",
            data: collection
          },
          paint: {
            "icon-color": "black"
          },
          layout: {
            "icon-image": "type-log",
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              6,
              0.15 / 2,
              10,
              1.0 / 6,
              12,
              1.0 / 6,
              22,
              1.0 / 6
            ],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-rotate": ["get", "angle"],
            "icon-rotation-alignment": "map",
            "icon-keep-upright": true,
            "icon-offset": [-90, -130] // Offset the icon to the top right
          }
        } as AnyLayer;
        addLayer(layer);
      } else {
        mapbox.setPaintProperty(TYPE_LOG_LAYER, "icon-color", "black");
      }
    }

    function setHiglightedVerticalWells(collection: GeoJSON.FeatureCollection) {
      if (
        !setGeojsonSource(TYPE_LOG_VERTICAL_LAYER, collection) &&
        !mapbox.getLayer(TYPE_LOG_VERTICAL_LAYER)
      ) {
        const layer = {
          id: TYPE_LOG_VERTICAL_LAYER,
          type: "symbol",
          source: {
            type: "geojson",
            data: collection
          },
          paint: {
            "icon-color": "black"
          },
          layout: {
            "icon-image": "type-log",
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              6,
              0.15 / 2,
              10,
              1.0 / 6,
              12,
              1.0 / 6,
              22,
              1.0 / 6
            ],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-rotate": ["get", "angle"],
            "icon-rotation-alignment": "map",
            "icon-keep-upright": true,
            "icon-offset": [-90, -130] // Offset the icon to the top right
          }
        } as AnyLayer;
        addLayer(layer);
      } else {
        mapbox.setPaintProperty(TYPE_LOG_VERTICAL_LAYER, "icon-color", "black");
      }
    }

    async function highlightWells() {
      if (!mapbox.isStyleLoaded()) {
        await waitForStyleToLoad(mapbox, 1000);
      }
      clearHighlightedWells();
      if (typeLogWells.length === 0) {
        return;
      }
      const ids = typeLogWells.reduce((obj, item) => {
        return { ...obj, [item.id]: 1 };
      }, {});

      // Query respective layers for hz and vert wells
      const foundHzWells = mapbox.querySourceFeatures(WELL_LAYER, {
        sourceLayer: "eva-wells",
        filter: ["has", ["get", "Uwi"], ["literal", ids]]
      });
      const foundVerticalWells = mapbox.querySourceFeatures(WELL_LAYER_POINT, {
        sourceLayer: "eva-wells",
        filter: ["has", ["get", "Uwi"], ["literal", ids]]
      });

      const hzFeatures = foundHzWells.map((feat) => {
        return feature(feat.geometry, {
          ...feat.properties,
          value: ids[feat.id]
        });
      });
      const vFeatures = foundVerticalWells.map((feat) => {
        return feature(feat.geometry, {
          ...feat.properties,
          value: ids[feat.id]
        });
      });

      // Highlight wells with type log icon
      if (hzFeatures.length > 0) {
        setHighlightedHzWells(featureCollection(hzFeatures));
      }
      if (vFeatures.length > 0) {
        setHiglightedVerticalWells(featureCollection(vFeatures));
      }
    }
    highlightWells();
  }, [typeLogWells, hasTypeLogWidget]);
}
