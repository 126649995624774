import { ScreenshotT } from "../context";

/**
 * Handles the y-axis titles overflowing between screenshot and dashboard view
 *
 * @param {DOMRect} bounds Dimensions of the chart
 * @param {string} screenshot Screenshot settings.
 * @returns {number} Maximum width for the y axis title.
 */
export const getAxisTitleTruncatingWidth = (bounds: DOMRect, screenshot: ScreenshotT) => {
  // Difference between percentage values was based on feedback
  const truncatingWidth = bounds?.height ? bounds.height * 0.85 : null;

  const screenShotTruncatingWidth = screenshot?.preset?.height
    ? screenshot.preset.height * 0.9
    : null;

  return screenshot?.preset ? screenShotTruncatingWidth : truncatingWidth;
};
