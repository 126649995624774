import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxEmptyIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styled, { css } from "styled-components/macro";

import ExcludeCheckboxIcon from "components/icons/ExcludeCheckbox";

const iconStyles = css`
  color: var(--color-primary);
  font-size: 2.2rem !important;
`;

export const StyledCheckBoxIcon = styled(CheckBoxIcon)`
  ${iconStyles}
`;

export const StyledCheckBoxEmptyIcon = styled(CheckBoxEmptyIcon)`
  ${iconStyles}
`;

export const StyledExcludeIcon = styled(ExcludeCheckboxIcon)`
  margin-left: 3px;
  margin-right: 3px;
`;

export const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  color: var(--color-${(props) => (props.visible ? "primary" : "text")});
  transform: rotate(${(props) => (props.visible ? "-180" : "0")}deg);
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1), transform 0.15s ease !important;
`;
