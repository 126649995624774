import { createSlice } from "@reduxjs/toolkit";

export interface DataTableState {
  mapIdleTime: "";
}
const initialState: DataTableState = {
  mapIdleTime: ""
};

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    setMapIdleTime(state, action) {
      state.mapIdleTime = action.payload;
    }
  }
});

export const { setMapIdleTime } = dataTableSlice.actions;

export default dataTableSlice.reducer;
