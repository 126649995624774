import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { geoMapServiceEndpoint } from "../../../../../api/project";
import { PROJECT_LAYER_LIST_QUERY_KEYS } from "../../../layers/queries";
import { PROJECT_SHAPEFILES_QUERY_KEYS } from "../../queries";
import {
  IUploadProjectShapefileListMutationParams,
  UploadShapefilesParams
} from "../upload-project-shapefile-list/useUploadProjectShapefileListMutation";

export function uploadGeoTiff(files, visibility, projectId, shapefileNodeId, thickness) {
  const formData = new FormData();
  for (const file of files) {
    formData.append("payload", file);
  }
  formData.append("projectId", projectId);
  if (shapefileNodeId) {
    formData.append("shapefileNodeId", shapefileNodeId);
  }
  formData.append("thickness", thickness);
  formData.append("visibility", visibility);
  return axios.post(`${geoMapServiceEndpoint}/geo-tiff/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function useUploadProjectGeoTiffMutation({
  onError,
  onSuccess,
  onSettled,
  onMutate,
  projectId
}: IUploadProjectShapefileListMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    async (params: UploadShapefilesParams) => {
      for (const file of params.files) {
        await uploadGeoTiff(
          [file],
          params.visibility,
          projectId,
          params.shapefileNodeId,
          params.thickness
        );
      }
    },
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });

        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
