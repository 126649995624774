import { useMutation } from "react-query";

import axios from "axios";

import { IColorPalette } from "../models";

const COLOR_SVC_URI = process.env.REACT_APP_COLOR_SERVICE;

export type ColorPaletteType = "org" | "user" | "both";

export default function useUpdateColorPalette() {
  const result = useMutation("update-palette", async (value: IColorPalette) => {
    if (value.colors.length === 0 || value.thickness.length === 0) {
      throw "At least one colour is required to create a new palette";
    }
    const response = await axios.post(`${COLOR_SVC_URI}/palette/user`, value);
    if (response.status !== 200) {
      throw "Unable to fetch color palettes.";
    }
    return response.data;
  });
  return result;
}
