import { FC } from "react";

import { Switch, Typography } from "antd";
import styled from "styled-components/macro";

const { Text } = Typography;

type NormalizeToggleT = {
  checked: boolean;
  onToggle: (v: boolean) => void;
};

const NormalizeToggle: FC<NormalizeToggleT> = ({ checked, onToggle }) => {
  return (
    <Wrapper>
      <StyledText strong>Normalize</StyledText>
      <Switch size="small" checked={checked} onChange={onToggle} />
    </Wrapper>
  );
};

export default NormalizeToggle;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  background: var(--normalize-section-bg, #ffffff);
  text-align: left;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.25px;
  white-space: nowrap;
`;
