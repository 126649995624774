// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";

import { mdiOneUp } from "@mdi/js";
import { Alert, Button, Popover, Select } from "antd";
import styled from "styled-components";

import { useGetPlaysList } from "components/tools/hooks";

import { ShapefileAction } from "../..";

interface IShapefilePromoteProps {
  isDisabled?: boolean;
  closePopover: () => void;
  promoteShapefile: (play) => void;
}

export function ShapefilePromoteAction({
  isDisabled,
  closePopover,
  promoteShapefile
}: IShapefilePromoteProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedPlay, setSelectedPlay] = useState<string>(null);

  const { data, mutate } = useGetPlaysList();

  useEffect(() => {
    mutate();
  }, []);

  const playList = data?.map((play) => ({ label: play, value: play })) ?? [];
  const playOptions = [{ label: "", value: null }, ...playList];

  const onClose = () => {
    setIsPopoverOpen(false);
    closePopover();
    setSelectedPlay(null);
  };

  const promoteMenu = (
    <PromoteMenu>
      <PlaySelector
        options={playOptions}
        placeholder="Select Play"
        onChange={(value) => setSelectedPlay(value)}
        value={selectedPlay} // show placeholder if empty string
        showSearch={true}
      />
      {!selectedPlay && (
        <WarningAlert
          message="Warning: No play selected"
          type="warning"
          showIcon={true}
        />
      )}
      <PromoteButtons>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton
          onClick={() => {
            promoteShapefile(selectedPlay ?? "");
            onClose();
          }}>
          Submit
        </SubmitButton>
      </PromoteButtons>
    </PromoteMenu>
  );
  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(open) => setIsPopoverOpen(open)}
      placement="bottom"
      trigger="click"
      content={promoteMenu}
      title={`Promote Shapefile to System/McDan Shapefile`}>
      <ShapefileAction disabled={isDisabled}>
        <Icon path={mdiOneUp} size={1} />
        Promote
      </ShapefileAction>
    </Popover>
  );
}

const PromoteMenu = styled.div`
  width: 300px;
`;

const PlaySelector = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
`;

const WarningAlert = styled(Alert)`
  margin-bottom: 10px;
`;

const PromoteButtons = styled.div`
  display: flex;
  justify-content: right;
`;

const SubmitButton = styled(Button)`
  background-color: var(--color-primary);
  color: white;
`;
