import { useFileUpload } from "./useFileUpload";

export function useMultiFileUpload() {
  // hooks
  const fileUpload = useFileUpload();

  async function mutate(files: File[]) {
    if (!files?.length) return;

    files.forEach(async (file) => {
      fileUpload.mutate(file);
    });
  }

  return {
    mutate
  };
}
