// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiCog } from "@mdi/js";
import { Popover } from "antd";

import { ThreeDViewerOptionT } from "models";

import ThreeDVSettingsOptions from "./ThreeDVSettingsOptions";
import ThreeDVToolbarButton from "./ThreeDVToolbarButton";

function ThreeDVSettingsToggle(options: ThreeDViewerOptionT) {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<ThreeDVSettingsOptions {...options} />}
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ThreeDVToolbarButton
        icon={<Icon path={mdiCog} size={1.5} />}
        active={false}
        tooltipText={"3DV Settings"}
        overflowLabel={"Settings"}
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
}

export default ThreeDVSettingsToggle;
