import { useQuery } from "react-query";

import axios, { AxiosError } from "axios";

import { DATA_SERVICE_ADDRESS } from "../../../constants/app.constants";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/midstream/well-midstream-network`;

const WellNetworkMapKey = "well-network-map";

export interface WellNetworkResult {
  startDate: Date;
  endDate: Date;
  date: string;
  maxDepth: number;
  wellFeatureCollections: { [wellId: string]: GeoJSON.FeatureCollection };
}

const fetchNetworkMap = async (request: WellNetworkRequest) => {
  if (!request || request.wellIds.length === 0) {
    return Promise.resolve(null);
  }
  const wellIds = request.wellIds.map((id) => `wellIds=${id}`).join("&");
  const response = await axios.get<WellNetworkResult>(
    `${API_URL}?${wellIds}${
      request.date ? `&date=${request.date}` : ""
    }&onlyPrimaryPath=${request.onlyPrimaryPath ? "true" : "false"}${
      request.hideConnectionsLessThanPercent
        ? `&hideConnectionsLessThanPercent=${request.hideConnectionsLessThanPercent}`
        : ""
    }`
  );
  return response.data;
};

export interface WellNetworkRequest {
  wellIds: string[];
  date?: string;
  onlyPrimaryPath?: boolean;
  hideConnectionsLessThanPercent?: number;
}

export const useGetWellNetworkMap = (request: WellNetworkRequest) => {
  return useQuery<WellNetworkResult, AxiosError>(
    [WellNetworkMapKey, request],
    () => fetchNetworkMap(request),
    {
      refetchOnWindowFocus: false
    }
  );
};
