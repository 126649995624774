import { useDispatch, useSelector } from "react-redux";

import { addToLockedColor, removeLockedColor } from "store/features";
import { RootState } from "store/rootReducer";

const useRefreshPreferredColorsInStore = () => {
  const storeDispatch = useDispatch();
  const lockedColors = useSelector((state: RootState) => state.app.lockedColors);

  const refreshPreferredColorsInStore = (match) => {
    for (const key of Object.keys(lockedColors)) {
      const preferredColor = lockedColors[key];
      if (preferredColor.isPreferred) {
        storeDispatch(
          removeLockedColor({
            color: preferredColor.color,
            value: preferredColor.value,
            property: preferredColor.property
          })
        );
      }
    }
    if (match?.preferredColors) {
      for (const preferredColor of match?.preferredColors) {
        storeDispatch(
          addToLockedColor({
            color: preferredColor.color,
            value: preferredColor.value,
            property: preferredColor.property,
            isPreferred: true
          })
        );
      }
    }
  };

  return refreshPreferredColorsInStore;
};

export default useRefreshPreferredColorsInStore;
