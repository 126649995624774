import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "../queries";

export interface UseSetProjectLayerOrderMutationParams {
  projectId: string;
  onMutate?: () => void;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  onSettled?: () => void;
}

export type SetProjectLayerOrderParams = {
  projectId: string;
  layerId: string;
  order: number;
};

async function setProjectLayerOrder(params: SetProjectLayerOrderParams) {
  const url = `${process.env.REACT_APP_PROJECT_SERVICE}/project/projectLayers/move/${params.projectId}`;
  const body = { layerId: params.layerId, order: params.order };

  return await axios.put(url, body);
}

export function useSetProjectLayerOrderMutation({
  projectId,
  onMutate,
  onSuccess,
  onError,
  onSettled
}: UseSetProjectLayerOrderMutationParams) {
  const queryClient = useQueryClient();

  return useMutation(
    (params: SetProjectLayerOrderParams) => setProjectLayerOrder({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
