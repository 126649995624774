// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { mdiRestore } from "@mdi/js";
import { Alert, Button, Popover, Tooltip } from "antd";
import { TYPE_WELLS } from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useRestoreForecast } from "./mutations/useRestoreForecast";

export type RestoreForecastButtonProps = {
  forecastId: string;
  restoreDate: string;
  disabled: boolean;
};

export const RestoreForecastButton = function (props: RestoreForecastButtonProps) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const restoreForecastMutation = useRestoreForecast(TYPE_WELLS);

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  useEffect(() => {
    let isCancelled = false;

    if (showSuccessMessage && !isCancelled) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);
    }

    return () => {
      isCancelled = true;
    };
  }, [showSuccessMessage]);

  const restoreForecast = useCallback(() => {
    restoreForecastMutation.restore({
      forecastId: props.forecastId,
      folderId: selectedTypeWell.folderId,
      restoreDate: props.restoreDate
    });
    setShowSuccessMessage(true);
  }, []);

  return (
    <Wrapper>
      <Tooltip title="Restore Forecast">
        <Popover
          open={showSuccessMessage}
          trigger="click"
          placement={"right"}
          overlayClassName="overlay-no-padding"
          content={<Alert type="success" message="Forecast Restored" />}>
          <Button
            disabled={props.disabled}
            onClick={() => {
              restoreForecast();
            }}>
            <Icon path={mdiRestore} className="activity-action-icon" size={1} />
          </Button>
        </Popover>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
  }
  .ant-btn:hover {
    color: var(--color-primary);
  }
  .ant-btn.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  .ant-btn.active:hover {
    background-color: #fff;
    color: var(--color-primary);
  }
`;
