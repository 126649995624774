import { useQuery } from "react-query";

import { ApiResult } from "../api/apiResult";
import { CutoffSource, fetchPlayTo3DCutoffSources } from "../api/ipdp";

///hook to fetch ipdb fields
export default function useCutoffSources(): ApiResult<CutoffSource[]> {
  const { data, isLoading, isError, refetch } = useQuery<CutoffSource[]>(
    "fetchPlayTo3DCutoffSources",
    fetchPlayTo3DCutoffSources,
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
