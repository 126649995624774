import { memo } from "react";

function Stats() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <path
        d="M4 4L4 20L22 20"
        id="LShapeLine"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7 17 Q13 -7, 19 17"
        id="BellCurve"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export default memo(Stats);
