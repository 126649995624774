import styled from "styled-components";

interface IWidgetOverlayMessage {
  testId: string;
  message: string;
}
export default function WidgetOverlayMessage({ message, testId }: IWidgetOverlayMessage) {
  return (
    <OverlayContainer data-testid={`${testId}`} data-qa={`${testId}`}>
      <MessageContainer>
        <Message>{message}</Message>
      </MessageContainer>
    </OverlayContainer>
  );
}
const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 4;
`;
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: inherit;
`;

const Message = styled.div`
  color: var(--color-white);
  background-color: var(--color-text);
  border-radius: 5px;
  padding: 2px 20px;
  margin: 0 20px;
`;
