import { useDispatch, useSelector } from "react-redux";

import { setLayers } from "store/features";
import { RootState } from "store/rootReducer";

interface IUpdateLayerZoomParams {
  layerName: string;
  minAndMax: [number, number];
}

export function useUpdateLayerZoom() {
  const dispatch = useDispatch();
  const mapLayers = useSelector((state: RootState) => state.map.layers);

  const updateLayerZoom = ({ layerName, minAndMax }: IUpdateLayerZoomParams) => {
    const updatedMapLayers = mapLayers.map((layer) => {
      if (layer.id === layerName)
        return { ...layer, minzoom: minAndMax[0], maxzoom: minAndMax[1] };
      return { ...layer };
    });

    dispatch(setLayers(updatedMapLayers));
  };

  return { updateLayerZoom };
}
