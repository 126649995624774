import { Dashboard } from "models/workspace";

import {
  IconAllCharts,
  IconDataTableView,
  IconHorizontalMap,
  IconLargeMap,
  IconSplitView
} from "components/icons";

export const getDashboardFormatIcon = (
  dashboard: Dashboard | string,
  iconSize?: number
): React.ReactNode => {
  const size = iconSize ?? 24;

  const d = dashboard as Dashboard;
  let format: string = dashboard as string;

  if (d?.layout?.format) {
    format = d.layout.format;
  }

  return (
    <>
      {format === "split" && (
        <IconSplitView size={size} data-testid={`dashboard-format-icon-${format}`} />
      )}
      {format === "large-map" && (
        <IconLargeMap size={size} data-testid={`dashboard-format-icon-${format}`} />
      )}
      {format === "horizontal-map" && (
        <IconHorizontalMap size={size} data-testid={`dashboard-format-icon-${format}`} />
      )}
      {format === "all-charts" && (
        <IconAllCharts size={size} data-testid={`dashboard-format-icon-${format}`} />
      )}
      {format === "full" && (
        <IconDataTableView size={size} data-testid={`dashboard-format-icon-${format}`} />
      )}
      {!format && (
        <IconAllCharts size={size} data-testid={`dashboard-format-icon-no-format`} />
      )}
    </>
  );
};
