import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import { PROJECT_SHAPEFILES_QUERY_KEYS } from "components/project/shapefiles/queries";

interface IUseDeleteProjectLinkedMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
  projectId: string;
}

interface IDeleteProjectLinkedShapefileFolderParams {
  shapefileNodeId: string;
  projectId: string;
  isOrg: boolean;
}

export async function DeleteProjectLinkedShapefileFolder(
  params: IDeleteProjectLinkedShapefileFolderParams
) {
  return await axios.delete(
    `${projectServiceEndpoint}/project-shapefile-node/${params.shapefileNodeId}?projectId=${params.projectId}&isOrg=${params.isOrg}`
  );
}

export function useDeleteProjectLinkedShapefileFolderMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess,
  projectId
}: IUseDeleteProjectLinkedMutationParams) {
  const queryClient = useQueryClient();

  return useMutation(
    (params: IDeleteProjectLinkedShapefileFolderParams) =>
      DeleteProjectLinkedShapefileFolder(params),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });

        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
