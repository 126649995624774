import { useDispatch, useSelector } from "react-redux";

import { setDisplaySettings } from "store/features";
import { RootState } from "store/rootReducer";

export function useUpdateLayerLineThickness() {
  const dispatch = useDispatch();
  const displaySettings = useSelector((state: RootState) => state.map.displaySettings);

  const updateLayerLineThickness = (newThickness: number) => {
    dispatch(setDisplaySettings({ ...displaySettings, wellLineThickness: newThickness }));
  };
  return { updateLayerLineThickness };
}
