import { useEffect, useState } from "react";

import { useTable } from "../context";

/**
 * Simple `hook` that abstracts parsing rows using context state value:
 * colorMap, uwis and wells.
 *
 * @returns an array of row objects with well data and hex color on map.
 */
const useRows = () => {
  const [rows, setRows] = useState([]);
  const [{ colorMap, uwis, wells }] = useTable();

  // parse and set rows from wells, uwi and colorMap
  useEffect(() => {
    if (!colorMap || !Object.keys(colorMap).length) return;

    const nextRows = [];

    for (let i = 0; i < uwis.length; i++) {
      const name = uwis[i];
      const color = colorMap[name];
      const row = { color, uwi: name, ...wells[name] };

      nextRows.push(row);
    }

    setRows(nextRows);
  }, [uwis, colorMap]);

  return rows;
};

export default useRows;
