import { createContext, useContext, useMemo, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { PostT } from "../types";

type Action =
  | { type: "set post list"; payload: PostT[] }
  | { type: "append to post list"; payload: PostT[] }
  | { type: "toggle new post modal"; payload: boolean }
  | { type: "editing post"; payload: string }
  | { type: "update posts total"; payload: number }
  | { type: "update new posts total"; payload: number };

type State = {
  posts: PostT[];
  totalPosts: number;
  totalNewPosts: number;
  newPostModalVisible: boolean;
  editPostId: string;
};

export type Dispatch = (action: Action) => void;

const PostContext = createContext<[State, React.Dispatch<Action>] | undefined>(undefined);
PostContext.displayName = "PostContext";

const initialState: State = {
  posts: [],
  totalPosts: 0,
  totalNewPosts: 0,
  newPostModalVisible: false,
  editPostId: null
};

const postReducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "set post list": {
      draft.posts = action.payload;
      break;
    }
    case "append to post list": {
      const toAdd = action.payload as PostT[];
      draft.posts.push(...toAdd);
      break;
    }
    case "toggle new post modal":
      draft.newPostModalVisible = action.payload;
      break;
    case "editing post":
      draft.editPostId = action.payload;
      break;
    case "update posts total":
      draft.totalPosts = action.payload;
      break;
    case "update new posts total":
      draft.totalNewPosts = action.payload;
      break;
  }
};

const curriedReducer = produce(postReducer);

const PostProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(curriedReducer, initialState);
  const value: [State, Dispatch] = useMemo(() => [state, dispatch], [state]);
  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};

const usePost = () => {
  const context = useContext(PostContext);
  if (context === undefined) {
    throw new Error("usePostContext must be used within a PostProvider");
  }
  return context;
};

// context module functions
const showNewPostModal = (dispatch: Dispatch) =>
  dispatch({ type: "toggle new post modal", payload: true });
const hideNewPostModal = (dispatch: Dispatch) =>
  dispatch({ type: "toggle new post modal", payload: false });
const setEditPostId = (dispatch: Dispatch, value: string) =>
  dispatch({ type: "editing post", payload: value });
const setPostList = (dispatch: Dispatch, value: PostT[]) =>
  dispatch({ type: "set post list", payload: value });
const appendToPostList = (dispatch: Dispatch, value: PostT[]) =>
  dispatch({ type: "append to post list", payload: value });
const updateTotalPosts = (dispatch: Dispatch, value: number) =>
  dispatch({ type: "update posts total", payload: value });
const updateTotalNewPosts = (dispatch: Dispatch, value: number) =>
  dispatch({ type: "update new posts total", payload: value });

export {
  PostProvider,
  usePost,
  showNewPostModal,
  hideNewPostModal,
  setEditPostId,
  setPostList,
  appendToPostList,
  updateTotalPosts,
  updateTotalNewPosts
};
