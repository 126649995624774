import { memo } from "react";

import { Histogram } from "components/icons";

import { useChartDispatch, useChartState } from "../context";
import { getHistogramToggleDisabledStatus } from "../utils/toggles";
import ToolbarButton from "./ToolbarButton";

const HistogramToggle = () => {
  const dispatch = useChartDispatch();
  const { settings, options } = useChartState();

  const toggle = () => (value) => {
    const next = { ...settings, showHistogram: value };
    dispatch({ type: "settings", payload: next });
  };

  if (!options || getHistogramToggleDisabledStatus(options.chartType)) {
    return <></>;
  }

  return (
    <ToolbarButton
      icon={<Histogram />}
      active={settings.showHistogram}
      overflowLabel="Histogram"
      tooltipText="Toggle Histogram"
      onToggle={toggle()}
    />
  );
};

export default memo(HistogramToggle);
