import { hexToRgb, hslToRgb, rgbToHex, rgbToHsl } from "./rgbHelpers";

export function lightenColor(hexColor, lightnessFactor) {
  // Remove the leading '#' from the hex color
  const color = hexColor.replace("#", "");

  // Convert hex color to RGB
  const rgbColor = hexToRgb(color);

  // Convert RGB to HSL
  const hslColor = rgbToHsl(rgbColor.r, rgbColor.g, rgbColor.b);

  // Adjust the lightness
  hslColor.l += (1 - hslColor.l) * lightnessFactor;

  // Convert modified HSL to RGB
  const modifiedRgbColor = hslToRgb(hslColor.h, hslColor.s, hslColor.l);

  // Convert RGB to hex color
  return rgbToHex(modifiedRgbColor.r, modifiedRgbColor.g, modifiedRgbColor.b);
}
