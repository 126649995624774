/**
 *
 * @param {number} num number to round
 * @param {number} decimalPlaces number of decimal places to round to, defaults to 2
 * @returns {number} rounded number
 */
const with2Decimals = (num, decimalPlaces = 2): number => {
  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`);
  return num.toString().match(regex)[0];
};

export default with2Decimals;
