import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Close, Pause, PlayArrow, Settings } from "@material-ui/icons";
import turfCircle from "@turf/circle";
import { featureCollection } from "@turf/helpers";
import { Alert, Button, Form, Input, Popover, Select, Slider } from "antd";
import addMonths from "date-fns/addMonths";
import mapboxgl, { AnySourceData, Map } from "mapbox-gl";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import {
  InitializeTimelineResultModel,
  useInitializeTimeline,
  useTimeline
} from "api/useTimeline";

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;
export interface TimelineModel {
  map: Map;
  onClose: () => void;
}

let currentTimelineIndex = 0;
let playInterval = undefined;

type Mode = "Play" | "Paused";

type ProductKey = "BOE" | "Oil" | "Gas";

const Products = [
  { label: "BOE", value: "BOE" as ProductKey },
  { label: "Oil (Mbbl)", value: "Oil" as ProductKey },
  { label: "Gas (MMcf)", value: "Gas" as ProductKey }
];

export default function Timeline({ map, onClose }: TimelineModel) {
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const { mutation } = useInitializeTimeline(onInitialized);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(500);
  const [maxRadius, setMaxRadius] = useState<number>(800);
  const [product, setProduct] = useState<ProductKey>("BOE");
  const [model, setModel] = useState<InitializeTimelineResultModel | undefined>(
    undefined
  );
  const [mode, setMode] = useState<Mode>("Paused");
  const { data, refetch } = useTimeline(
    model?.timelineId,
    currentIndex,
    maxValue,
    maxRadius
  );

  useEffect(() => {
    refetch();
    currentTimelineIndex = currentIndex;
  }, [currentIndex]);

  function play(model: InitializeTimelineResultModel) {
    if (currentTimelineIndex > model?.maxIndex) {
      setCurrentIndex(0);
      currentTimelineIndex = 0;
    }
    setMode("Play");
    playInterval = setInterval(() => {
      if (model && currentTimelineIndex > model?.maxIndex) {
        pause();
      }
      currentTimelineIndex += 1;
      setCurrentIndex(currentTimelineIndex);
    }, 500);
  }

  function pause() {
    clearInterval(playInterval);
    playInterval = undefined;
    setMode("Paused");
  }

  useEffect(() => {
    if (!map || !data || !map.getLayer("timeline")) {
      return;
    }
    map.setFilter("timeline", ["has", ["get", "Uwi"], ["literal", data.uwisInScene]]);
    const features = [];
    for (const item of data.bubbleSize) {
      if (item.location.length === 0) {
        continue;
      }
      const circleFeat = turfCircle(item.location, item.size, {
        units: "meters",
        properties: {
          Uwi: item.uwi,
          color: item.color
        }
      });
      features.push(circleFeat);
    }
    const source = map.getSource("timeline.bubble") as mapboxgl.GeoJSONSource;
    if (!source) {
      return;
    }
    source.setData(featureCollection(features));
  }, [data]);
  function hideWellLayer() {
    const wellLayer: mapboxgl.Layer = map.getLayer("Wells");
    if (wellLayer) {
      map.setLayoutProperty("Wells", "visibility", "none");
      map.setLayoutProperty("Wells Label", "visibility", "none");
    }
  }

  function showWellLayer() {
    const wellLayer: mapboxgl.Layer = map.getLayer("Wells");
    if (wellLayer) {
      map.setLayoutProperty("Wells", "visibility", "visible");
      map.setLayoutProperty("Wells Label", "visibility", "visible");
    }
  }
  function onInitialized(model: InitializeTimelineResultModel) {
    if (!model || !map) {
      return;
    }
    setModel(model);
    setMaxValue(model.maxCum);
    hideWellLayer();
    clearLayer();

    const source = {
      type: "vector",
      id: "timeline-source",
      tiles: [`${mapServiceEndpoint}/timeline/{z}/{x}/{y}?timelineId=${model.timelineId}`]
    } as AnySourceData;
    map.addSource("timeline-source", source);
    map.addLayer({
      id: "timeline",
      type: "line",
      source: "timeline-source",
      "source-layer": "timeline",
      paint: {
        "line-color": ["get", "color"],
        "line-width": 2
      }
    });
    map.addSource("timeline.bubble", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    });
    map.addLayer({
      id: "timeline.bubble",
      type: "fill",
      source: "timeline.bubble",
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": 0.5
      }
    });
    map.setFilter("timeline", ["in", ["get", "uwi"], ["literal", []]]);

    play(model);
  }
  function tipFormatter(index) {
    if (!model) {
      return <div></div>;
    }
    const date = new Date(model.minTime);
    const newDate = addMonths(date, index);
    return <div>{newDate.toLocaleDateString()}</div>;
  }

  const onPlay = useCallback(
    (product: ProductKey) => {
      mutation.mutate({
        filterId,
        txnId: txnId.id,
        product: product
      });
    },
    [product, filterId, txnId]
  );

  if (!map) {
    return <div>Loading...</div>;
  }
  function clearLayer() {
    if (!map) {
      return;
    }
    try {
      if (map.getLayer("timeline")) {
        map.removeLayer("timeline");
      }
      if (map.getLayer("timeline.bubble")) {
        map.removeLayer("timeline.bubble");
      }
      if (map.getSource("timeline-source")) {
        map.removeSource("timeline-source");
      }
      if (map.getSource("timeline.bubble")) {
        map.removeSource("timeline.bubble");
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
  function clearLayerAndClose() {
    clearLayer();
    showWellLayer();
    onClose && onClose();
  }

  return (
    <RootContainer>
      <ControlContainer>
        <ToolbarContainer>
          <ButtonIconCentered
            onClick={() => clearLayerAndClose()}
            ghost
            type="text"
            icon={<Close></Close>}></ButtonIconCentered>
        </ToolbarContainer>
        <PlayAndSlider>
          <Popover
            content={
              <PopoverContent
                product={product}
                maxRadius={maxRadius}
                onChange={(model: PopoverContentModel) => {
                  setMaxRadius(model.maxRadius);
                  setMaxValue(model.maxValue);

                  setProduct(model.product);

                  if (model.product !== product) {
                    setTimeout(() => {
                      pause();
                      onPlay(model.product);
                    }, 100);
                  }
                }}
                maxValue={maxValue}></PopoverContent>
            }>
            <ButtonIconCentered
              shape="circle"
              ghost
              dashed
              type="primary"
              icon={<Settings fontSize="large"></Settings>}></ButtonIconCentered>
          </Popover>
          {mode === "Play" && (
            <ButtonIconCentered
              type="primary"
              onClick={pause}
              icon={<Pause fontSize="large"></Pause>}
              shape="circle"></ButtonIconCentered>
          )}
          {mode === "Paused" && (
            <ButtonIconCentered
              type="primary"
              onClick={() => onPlay(product)}
              loading={mutation.isLoading}
              icon={<PlayArrow fontSize="large"></PlayArrow>}
              shape="circle"></ButtonIconCentered>
          )}
          {!mutation.isError && (
            <SliderWrapper>
              <Slider
                max={model?.maxIndex}
                value={currentIndex}
                onChange={setCurrentIndex}
                tipFormatter={tipFormatter}></Slider>
            </SliderWrapper>
          )}
          {mutation.isError && (
            <Alert type="error" message="Unable to connect to backend services."></Alert>
          )}
        </PlayAndSlider>
      </ControlContainer>
    </RootContainer>
  );
}

interface PopoverContentModel {
  product: ProductKey;
  maxRadius: number;
  maxValue: number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (model: PopoverContentModel) => void;
}
const PopoverContent = ({
  product,
  maxValue,
  maxRadius,
  onChange
}: PopoverContentModel) => {
  const [productKey, setProductKey] = useState<ProductKey>(product);
  const [maxValueLabel, setMaxValueLabel] = useState("");
  const [value, setMaxValue] = useState(maxValue);
  const [radius, setMaxRadius] = useState(maxRadius);
  useEffect(() => {
    const label = Products.filter((p) => p.value === productKey)[0]?.label;
    setMaxValueLabel("Max " + label);
  }, [productKey]);

  return (
    <div>
      <Form layout="vertical">
        <h5>Settings</h5>
        <Form.Item label="Product">
          <Select
            options={Products}
            value={productKey}
            popupClassName="modal-select"
            onChange={(key) => {
              setProductKey(key);
              onChange &&
                onChange({
                  maxValue: value,
                  product: key,
                  maxRadius: radius
                });
            }}></Select>
        </Form.Item>
        <Form.Item label={maxValueLabel}>
          <Input
            value={value}
            type="number"
            min={1}
            onChange={(evt) => setMaxValue(parseFloat(evt.target.value))}
            onBlur={() =>
              onChange &&
              onChange({
                maxValue: value,
                product: productKey,
                maxRadius: radius
              })
            }></Input>
        </Form.Item>
        <Form.Item label="Max Radius">
          <Input
            type="number"
            value={radius}
            min={100}
            onChange={(evt) => setMaxRadius(parseFloat(evt.target.value))}
            onBlur={() =>
              onChange &&
              onChange({
                maxValue: value,
                product: productKey,
                maxRadius: radius
              })
            }></Input>
        </Form.Item>
      </Form>
    </div>
  );
};

const RootContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  user-select: none;
`;

const ControlContainer = styled.div`
  position: absolute;
  bottom: 50px;
  z-index: 20;
  left: 0;
  right: 0;
  width: 100%;
  padding: 10px 50px;
`;

const PlayAndSlider = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  border-radius: 5px;
  padding: 10px 10px;
  gap: 10px;

  pointer-events: auto;
`;

const SliderWrapper = styled.div`
  width: 100%;
`;

const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  justify-content: flex-end;
`;

const ButtonIconCentered = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
