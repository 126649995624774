import { ITypeWellProductSettings } from "api/typeWell";

export function compareUserTypeWellProductsWithDefaultTypeWellProducts(
  defaultTypeWellProductSettings: ITypeWellProductSettings[],
  userTypeWellProductSettings
) {
  const defaultTypeWellProducts = defaultTypeWellProductSettings.map(
    (defaultTypeWellProduct) => defaultTypeWellProduct.product
  );
  const missingProducts = defaultTypeWellProducts.filter(
    (defaultTypeWellProduct) =>
      !userTypeWellProductSettings.data[0].settings.some(
        (typeWellProduct: ITypeWellProductSettings) =>
          typeWellProduct.product === defaultTypeWellProduct
      )
  );

  return missingProducts;
}
