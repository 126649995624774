// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiSortAlphabeticalAscending, mdiSortAlphabeticalDescending } from "@mdi/js";

import { ShapefileAction } from "../..";

interface IShapefileSortActionProps {
  isDisabled?: boolean;
  sortShapefiles: () => void;
  sortType?: string;
  setPopoverOpen: (isOpen: boolean) => void;
}

export function ShapefileFolderSortAction({
  isDisabled,
  sortShapefiles,
  sortType,
  setPopoverOpen
}: IShapefileSortActionProps) {
  return (
    <ShapefileAction
      disabled={isDisabled}
      danger
      onClick={(evt) => {
        sortShapefiles();
        setPopoverOpen(false);
        evt.stopPropagation();
        evt.preventDefault();
      }}>
      {sortType === "ascend" ? (
        <Icon path={mdiSortAlphabeticalDescending} size={1} />
      ) : (
        <Icon path={mdiSortAlphabeticalAscending} size={1} />
      )}
      Value {sortType === "ascend" ? "Ascending" : "Descending"}
    </ShapefileAction>
  );
}
