import { useEffect, useState } from "react";

import { Switch } from "antd";
import styled from "styled-components";

import { useUserSettingMutation } from "hooks";

import { Heading } from "components/base";
import { initialUserState, useUserContext } from "components/user/context";
import { updateGeoModelSettings } from "components/user/context/reducer/UserReducer";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

import { USER_GEO_MODEL_SETTINGS } from "./constants/userSetting.constants";
import { GeoModelSettings } from "./models";

export default function OrgGeoModelsToggle(): JSX.Element {
  const [, userDispatch] = useUserContext();
  const userSettingsDefaultsQuery = useUserSettingsDefaultsQuery();
  const geoModelSettings =
    Object.values(userSettingsDefaultsQuery?.data?.GeoModelSettings ?? {})?.[0] ??
    undefined;

  const defaultGeoModelSetting = initialUserState.settings.geoModelSettings; // for internal env, turn org geo models off
  const [organizationGeoModelsEnabled, setOrganizationGeoModelsEnabled] = useState(
    geoModelSettings?.organizationEnabled ?? defaultGeoModelSetting.organizationEnabled
  );

  const geoModelSettingMutation = useUserSettingMutation<GeoModelSettings>(
    USER_GEO_MODEL_SETTINGS
  );

  const onOrganizationGeoModelsSettingChanged = (enabledStatus) => {
    setOrganizationGeoModelsEnabled(enabledStatus);
    geoModelSettingMutation.mutate({
      id: geoModelSettings?.id,
      organizationEnabled: enabledStatus
    });
  };

  useEffect(() => {
    if (!geoModelSettingMutation?.data) return;
    updateGeoModelSettings(userDispatch, {
      id: geoModelSettingMutation.data.id,
      organizationEnabled: geoModelSettingMutation.data.organizationEnabled
    });
  }, [geoModelSettingMutation?.data]);

  return (
    <>
      <GeoModelsHeader>
        <GeoModelsHeading element="h5">Organization Geo Models</GeoModelsHeading>
        <GeoModelsToggle>
          <span>
            Status: <strong>{organizationGeoModelsEnabled ? "On" : "Off"}</strong>
          </span>
          <Switch
            checked={organizationGeoModelsEnabled}
            onChange={onOrganizationGeoModelsSettingChanged}
          />
        </GeoModelsToggle>
      </GeoModelsHeader>
    </>
  );
}

const GeoModelsHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const GeoModelsHeading = styled(Heading)`
  margin-right: 10px;
`;

const GeoModelsToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9e1e2;
  gap: 25px;
  border-radius: 21.5px;
  padding: 10px 15px;
  margin: 10px 0;
  width: 180px;
  .ant-switch-checked {
    :focus {
      box-shadow: none;
    }
  }
`;
