import { useFilesContext } from "../context/useFilesContext";
import { useFilesListQuery } from "../queries/useFilesListQuery";

export function useFilesList() {
  const { filesType, searchTerm } = useFilesContext();

  const filesListQuery = useFilesListQuery({ filesType });

  if (searchTerm) {
    const filteredQuery = {
      ...filesListQuery,
      data: {
        files: filesListQuery.data.files.filter((file) =>
          file.fileName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      }
    };

    return filteredQuery;
  }

  return filesListQuery;
}
