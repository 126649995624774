import { memo, useEffect, useState } from "react";

import { BaseTreeNode } from "./";

function BaseTree({ nodes = [], itemTemplate = null, nodeTemplate }) {
  const [localNodes, setNodes] = useState(nodes);
  useEffect(() => {
    if (!localNodes[0] || !nodes[0]) return;
    setNodes(nodes);
  }, [localNodes, nodes]);

  return (
    <ul className="no-list-style flex-grow" role="tree">
      {localNodes.map((item) => (
        <BaseTreeNode
          key={item.id}
          depth={1}
          node={item}
          nodeTemplate={nodeTemplate}
          leafTemplate={itemTemplate}
        />
      ))}
    </ul>
  );
}
export default memo(BaseTree);
