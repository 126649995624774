import { memo, useEffect, useState } from "react";

import BaseButton from "./BaseButton";
import "./BaseInput.scss";

function BaseInput({
  label = "",
  actionButton = null,
  tooltip = null,
  onActionClick = null,
  value,
  disabled = false,
  blur = null,
  focus = null,
  keypress = null,
  input = null,
  className = "",
  id = "",
  placeholder = "",
  required = false,
  onChange = null,
  innerRef = null,
  ...otherProps
}) {
  const [hovered, setHover] = useState(false);
  const [focused, setFocus] = useState(false);
  const [localValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);
  function handleMouseenter() {
    setHover(true);
  }

  function handleMouseleave() {
    setHover(false);
  }

  function handleBlur(evt) {
    setValue(evt.target.value);
    setFocus(false);
    blur && blur(evt.target.value);
  }

  function handleFocus(event) {
    setFocus(true);
    focus && focus(event);
  }

  function handleChange(evt) {
    setValue(evt.target.value);
    onChange && onChange(evt.target.value);
  }

  function handleKeypress(event) {
    keypress && keypress(event);
  }

  function handleInput(event) {
    input && input(event);
  }
  return (
    <div
      className={`BaseInput flex-column  ${disabled ? "disabled" : ""} ${
        focused ? "focused" : ""
      } ${hovered ? "hovered" : ""} ${className}`}
      onMouseEnter={handleMouseenter}
      onMouseLeave={handleMouseleave}>
      {!!label && (
        <div className="flex-row justify-between">
          <label className="label" htmlFor={id}>
            {label} {!!tooltip && <span className="label-tooltip">{tooltip}</span>}
          </label>
          {!!actionButton && (
            <BaseButton
              appearance="subtle"
              onClick={() => {
                onActionClick();
              }}>
              {actionButton}
            </BaseButton>
          )}
        </div>
      )}

      <textarea
        ref={innerRef}
        tabIndex={disabled ? -1 : 0}
        value={localValue}
        placeholder={placeholder}
        id={id}
        required={required}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeypress}
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
}

export default memo(BaseInput);
