import { useDispatch } from "react-redux";

import { TYPE_WELLS } from "constants/settings.constants";
import {
  setCheckedForecasts,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setExpandedKeys,
  setSelectedTypeWell
} from "store/features";

export function useClearTypewells() {
  const dispatch = useDispatch();

  function clearTypeWells() {
    dispatch(setCheckedKeys({ type: TYPE_WELLS, checkedKeys: [] }));
    dispatch(setExpandedKeys({ type: TYPE_WELLS, expandedKeys: [] }));
    dispatch(setCheckedGlobalTypeWells([]));
    dispatch(setCheckedForecasts({ type: TYPE_WELLS, checkedForecasts: [] }));
    dispatch(setSelectedTypeWell(null));
  }

  return { update: clearTypeWells };
}
