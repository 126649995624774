// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { ApolloError, useMutation } from "@apollo/client";
import { mdiAlert } from "@mdi/js";
import styled from "styled-components/macro";
import { uid } from "utils";

import { UPDATE_WORKSPACE, WorkspaceClientName } from "api/workspace";

import { WorkspaceDashboard, WorkspaceDashboardInput } from "models/workspace";

import { IconSpinner } from "components/icons";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";
import { useWorkspaceDispatch } from "components/workspace/hooks/useWorkspaceDispatch";

import DashboardTab from "./DashboardTab";
import { useDashboardContext } from "./hooks/useDashboardContext";

function DashboardNav() {
  const workspaceDispatch = useWorkspaceDispatch();
  const {
    workspace,
    activeDashboardId,
    isLoading: workspaceLoading,
    error: workspaceError,
    workspaceRefreshObserver
  } = useWorkspaceContext();

  const [dashboardList, setDashboardList] = useState([]);
  useEffect(() => {
    if (!workspace?.dashboards?.length) return;
    const filteredDashboards = workspace?.dashboards;
    setDashboardList(filteredDashboards);
  }, [workspace?.dashboards]);

  const { isModified: isDashboardModified } = useDashboardContext();
  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

  const updateDashboard = useCallback(
    (value) => {
      if (!isDashboardModified) {
        workspaceDispatch({
          payload: {
            activeDashboardId: value
          }
        });
      } else {
        if (
          // eslint-disable-next-line no-alert
          window.confirm(
            "Are you sure you want to go to leave without saving your changes?"
          )
        ) {
          workspaceDispatch({
            payload: {
              activeDashboardId: value
            }
          });
        }
      }
    },
    [workspaceDispatch, isDashboardModified]
  );

  const mutateWorkspace = (list: WorkspaceDashboard[]) => {
    const inputList: WorkspaceDashboardInput[] = list.reduce((acc, d) => {
      acc.push({
        dashboardId: d.dashboardId,
        favourite: false
      });
      return acc;
    }, []);

    updateWorkspace({
      variables: {
        workspace: {
          workspaceId: workspace.workspaceId,
          dashboards: inputList
        }
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: () => {
        workspaceRefreshObserver.next(uid());
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
      }
    });
  };

  const handleDragEnd = () => (result) => {
    const { source, destination } = result;

    // dropped outside
    if (!destination) return;

    // dropped at same location
    if (destination.index === source.index) return;

    // re-order list
    const nextList = [...dashboardList];
    const tab = { ...dashboardList[source.index] };
    nextList.splice(source.index, 1);
    nextList.splice(destination.index, 0, tab);
    setDashboardList(nextList);

    // update workspace
    mutateWorkspace(nextList);
  };

  const refreshWorkspace = (d) => () => {
    const nextDashboardId =
      activeDashboardId === d.dashboardId
        ? d.dashboardId
        : workspace.dashboards[0].dashboardId;

    updateDashboard(nextDashboardId);
    workspaceRefreshObserver.next(uid());
  };

  if (workspaceError) {
    return (
      <ErrorContainer>
        <Icon path={mdiAlert} size={1} />
        Error loading Workspace.
      </ErrorContainer>
    );
  }

  // TODO: Add empty dashboard component, when no dashboards in list
  if (workspaceLoading || !dashboardList.length)
    return <StyledSpinner data-testid="dashboard-nav-loading" />;

  return (
    <DragDropContext onDragEnd={handleDragEnd()}>
      <Droppable droppableId="dashboard-tabs" direction="horizontal">
        {(provided) => (
          <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
            {dashboardList.map((d, index) => (
              <DashboardTab
                key={d.dashboardId}
                index={index}
                dashboard={d}
                active={activeDashboardId === d.dashboardId}
                onClick={(id) => updateDashboard(id)}
                refreshWorkspace={refreshWorkspace(d)}
              />
            ))}
            {provided.placeholder}
          </Wrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DashboardNav;

const Wrapper = styled.div`
  min-width: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--color-text-06);

  & > div:first-child > span::after {
    opacity: 0;
  }

  &::after,
  &::before {
    /* content: ""; */
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: var(--color-text-20);
  }
  &::after {
    right: -1px;
  }
  &::before {
    left: -1px;
  }
`;

const StyledSpinner = styled(IconSpinner)`
  align-self: flex-start;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
