import { useEffect, useRef, useState } from "react";

import "./BaseRangeSlider.scss";

export default function BaseRangeSlider({
  label = "",
  value,
  min = 0,
  max = 100,
  step = 1,
  prefix = null,
  suffix = null,
  unit = null,
  onChange = null
}) {
  const [rangeValue, setRange] = useState(value);
  const rangeRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleMouseDownTrack() {}

  function updateValue(evt) {
    const value = evt.target.value;
    setRange(value);
    onChange && onChange(value);
  }
  useEffect(() => {
    function percent() {
      return (rangeValue / (max - min)) * 100;
    }
    function setProgress() {
      rangeRef.current.style.setProperty("--progress", `${percent()}%`);
    }

    function setOutputFactor() {
      const factor = percent() / 100 - 0.5;
      rangeRef.current.style.setProperty("--output-factor", `${factor}`);
    }
    setProgress();
    setOutputFactor();
  }, [rangeValue, min, max, onChange]);
  return (
    <div ref={rangeRef} className="BaseRangeSlider">
      {label && <span className="label">{label}</span>}

      {prefix && <div className="prefix">{prefix}</div>}

      <div className="track flex" onMouseDown={handleMouseDownTrack}>
        <input
          defaultValue={rangeValue}
          className="input"
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={updateValue}
        />

        <label className="output">
          <span>{`${rangeValue}${unit}`}</span>
        </label>
      </div>

      {suffix && <div className="suffix">{suffix}</div>}
    </div>
  );
}
