import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import {
  ORGANIZATION_SHAPEFILES_QUERY_KEYS,
  PROJECT_SHAPEFILES_QUERY_KEYS,
  SYSTEM_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

export interface IUseMoveProjectShapefileMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  projectId: string;
}

export type MoveProjectShapefileParamsT = {
  moveToId: string;
  dropPosition: number;
  shapefileId: string;
  type: string;
};

async function MoveShapefile(params) {
  const { projectId, moveToId, shapefileId, dropPosition, type } = params;
  const url =
    type === "system"
      ? `${projectServiceEndpoint}/system-shapefile/move/${shapefileId}${
          moveToId ? `/${moveToId}` : ""
        }`
      : `${projectServiceEndpoint}/shapefile/move/${shapefileId}${
          moveToId ? `/${moveToId}` : ""
        }`;
  const response = await axios.put(url, {
    order: dropPosition,
    projectId: projectId === "organization" ? null : projectId,
    type: type
  });
  return response;
}

export function useMoveProjectShapefileMutation({
  onError,
  onMutate,
  onSettled,
  projectId
}: IUseMoveProjectShapefileMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: MoveProjectShapefileParamsT) => MoveShapefile({ ...params, projectId }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: () => {
        if (projectId === "system") {
          queryClient.invalidateQueries({
            queryKey: [SYSTEM_SHAPEFILES_QUERY_KEYS.systemShapefiles]
          });
        } else if (projectId === "organization") {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });
        }
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });

        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
