import { ScreenshotSetting } from "components/screenshot/components";
import {
  SettingsControl,
  SettingsGroups,
  useScreenshotContext
} from "components/screenshot/hooks";

function useXDAScreenshotSettingsControls(): SettingsControl[] {
  // hooks
  const { widget, settings } = useScreenshotContext();

  const XDASettingsGroups = {
    threeDGeoLegend: "3D Geo Model Legend"
  };

  const controls: SettingsControl[] = [];

  if (widget?.widgetComponentType !== "xdaViewer") {
    return controls;
  }

  controls.push(
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="topsLabelsFontSize"
          name="topsLabelsFontSize"
          label="Tops Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="dataLabelsFontSize"
          name="dataLabelsFontSize"
          label="Data Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="gridLabelsFontSize"
          name="gridLabelsFontSize"
          label="Grid Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="legendFontSize"
          name="legendFontSize"
          label="Legend Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="legendVisible"
          name="legendVisible"
          label="Show Legend"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    }
  );
  if (settings?.legendVisible) {
    controls.push(
      {
        group: XDASettingsGroups.threeDGeoLegend,
        control: (
          <ScreenshotSetting.Item
            key="legendBorderVisible"
            name="legendBorderVisible"
            label="Legend Border"
            valuePropName="checked">
            <ScreenshotSetting.Input type="boolean" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: XDASettingsGroups.threeDGeoLegend,
        control: (
          <ScreenshotSetting.Item
            key="legendOpacity"
            name="legendOpacity"
            label="Legend Opacity">
            <ScreenshotSetting.Input type="number" step={0.1} min={0} max={1} />
          </ScreenshotSetting.Item>
        )
      }
    );
  }
  return controls;
}

export default useXDAScreenshotSettingsControls;
