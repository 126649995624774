import React from "react";

import styles from "./FieldErrorMessage.module.scss";

export default function FieldErrorMessage({ message }) {
  return (
    <div className={`${styles.errorMessage} flex items-center`}>
      <div className={`${styles.messageIcon} flex flex-centered`}>
        {/* <Icon /> */}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          role="presentation">
          <g fillRule="evenodd">
            <path
              d="M13.416 4.417a2.002 2.002 0 0 0-2.832 0l-6.168 6.167a2.002 2.002 0 0 0 0 2.833l6.168 6.167a2.002 2.002 0 0 0 2.832 0l6.168-6.167a2.002 2.002 0 0 0 0-2.833l-6.168-6.167z"
              fill="currentColor"></path>
            <path
              d="M12 14a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2"
              fill="white"></path>
          </g>
        </svg>
      </div>
      <div className="message">{message}</div>
    </div>
  );
}
