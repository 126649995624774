import { useState } from "react";

import { Divider, Slider, Switch, Typography } from "antd";
import { THEME_COLOR } from "constants/style.constants";
import styled from "styled-components/macro";

import { ColorChooser, SimpleColorPicker } from "components/base";

import { useChartDispatch, useChartState } from "./context";

const { Text } = Typography;

function SeriesStyleSelector() {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const { averageSettings, p10Settings, p50Settings, p90Settings } = settings;

  const color = averageSettings?.color || THEME_COLOR;
  const thickness = averageSettings?.thickness || 3.0;

  const p10Color = p10Settings?.color || THEME_COLOR;
  const p10Thickness = p10Settings?.thickness || 3.0;

  const p50Color = p50Settings?.color || THEME_COLOR;
  const p50Thickness = p50Settings?.thickness || 3.0;

  const p90Color = p90Settings?.color || THEME_COLOR;
  const p90Thickness = p90Settings?.thickness || 3.0;

  const [customColors, setCustomColors] = useState([]);
  const [showColorChooser, setShowColorChooser] = useState("");

  const updateAverage = () => (value) => {
    const next = { ...settings, average: value };
    dispatch({ type: "settings", payload: next });
  };
  const updateP10 = () => (value) => {
    const next = { ...settings, showP10: value };
    dispatch({ type: "settings", payload: next });
  };
  const updateP50 = () => (value) => {
    const next = { ...settings, showP50: value };
    dispatch({ type: "settings", payload: next });
  };
  const updateP90 = () => (value) => {
    const next = { ...settings, showP90: value };
    dispatch({ type: "settings", payload: next });
  };

  const updateAverageSettings = (key) => (value) => {
    const next = {
      color,
      thickness,
      [key]: value
    };
    const nextSettings = { ...settings, averageSettings: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const updateP90Settings = (key) => (value) => {
    const next = {
      color: p90Color,
      thickness: p90Thickness,
      [key]: value
    };
    const nextSettings = { ...settings, p90Settings: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const updateP50Settings = (key) => (value) => {
    const next = {
      color: p50Color,
      thickness: p50Thickness,
      [key]: value
    };
    const nextSettings = { ...settings, p50Settings: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const updateP10Settings = (key) => (value) => {
    const next = {
      color: p10Color,
      thickness: p10Thickness,
      [key]: value
    };
    const nextSettings = { ...settings, p10Settings: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const showPercentiles =
    settings.chartType == "Rate Cum" ||
    settings.chartType == "Rate Time" ||
    settings.chartType == "Cum Time";

  return (
    <Wrapper>
      {showPercentiles ? (
        <>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                setColor={() => setShowColorChooser("p10")}
                values={{
                  name: "p10",
                  color: p10Color,
                  strokeColor: "#ffffff",
                  thickness: p10Thickness,
                  opacity: null
                }}
              />
              <StyledText strong>P10</StyledText>
            </FlexItem>
            <Switch size="small" checked={settings.showP10} onChange={updateP10()} />
          </Head>
        </>
      ) : (
        <></>
      )}

      <Head>
        <FlexItem>
          <SimpleColorPicker
            setColor={() => setShowColorChooser("average")}
            values={{
              name: "average",
              color: color,
              strokeColor: "#ffffff",
              thickness: thickness,
              opacity: null
            }}
          />
          <StyledText strong>Average</StyledText>
        </FlexItem>
        <Switch size="small" checked={settings.average} onChange={updateAverage()} />
      </Head>

      {showPercentiles ? (
        <>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                setColor={() => setShowColorChooser("p50")}
                values={{
                  name: "p50",
                  color: p50Color,
                  strokeColor: "#ffffff",
                  thickness: p50Thickness,
                  opacity: null
                }}
              />
              <StyledText strong>P50</StyledText>
            </FlexItem>
            <Switch size="small" checked={settings.showP50} onChange={updateP50()} />
          </Head>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                setColor={() => setShowColorChooser("p90")}
                values={{
                  name: "p90",
                  color: p90Color,
                  strokeColor: "#ffffff",
                  thickness: p90Thickness,
                  opacity: null
                }}
              />
              <StyledText strong>P90</StyledText>
            </FlexItem>
            <Switch size="small" checked={settings.showP90} onChange={updateP90()} />
          </Head>
        </>
      ) : (
        <></>
      )}

      {showColorChooser === "average" && (
        <>
          <StyledDivider />
          <LinePreview color={color} thickness={thickness} />
          <ColorChooser
            selectedColor={color}
            onChange={updateAverageSettings("color")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={thickness}
              onChange={updateAverageSettings("thickness")}
            />
          </SliderWrapper>
        </>
      )}
      {showColorChooser === "p90" && (
        <>
          <StyledDivider />
          <LinePreview color={p90Color} thickness={p90Thickness} />
          <ColorChooser
            selectedColor={p90Color}
            onChange={updateP90Settings("color")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={p90Thickness}
              onChange={updateP90Settings("thickness")}
            />
          </SliderWrapper>
        </>
      )}

      {showColorChooser === "p50" && (
        <>
          <StyledDivider />
          <LinePreview color={p50Color} thickness={p50Thickness} />
          <ColorChooser
            selectedColor={p50Color}
            onChange={updateP50Settings("color")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={p50Thickness}
              onChange={updateP50Settings("thickness")}
            />
          </SliderWrapper>
        </>
      )}

      {showColorChooser === "p10" && (
        <>
          <StyledDivider />
          <LinePreview color={p10Color} thickness={p10Thickness} />
          <ColorChooser
            selectedColor={p10Color}
            onChange={updateP10Settings("color")}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={p10Thickness}
              onChange={updateP10Settings("thickness")}
            />
          </SliderWrapper>
        </>
      )}
    </Wrapper>
  );
}

export default SeriesStyleSelector;

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
`;

const Head = styled.div`
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  padding-left: 2px;
  padding-top: 1px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const LinePreview = styled.div`
  background-color: ${(p) => p.color};
  height: ${(p) => p.thickness}px;
  width: 80%;
  margin: 8px auto;
`;

const SliderWrapper = styled.div`
  display: grid;
`;

const FlexItem = styled.div`
  display: inline-flex;
`;
