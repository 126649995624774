import { useMutation } from "react-query";

import {
  DataAnalyzerRequest,
  WellColumnDataAnalyzerResult,
  postWellColumnDataAnalysis
} from "api/appTools";

export default function useWellColumnDataAnalyzer(
  handlePostData: (postData: WellColumnDataAnalyzerResult) => void,
  handleError: (err) => void = null
) {
  const { isLoading, isError, error, mutate, data } = useMutation(
    "postWellColumnDataAnalysis",
    async (request: DataAnalyzerRequest) => {
      const result = await postWellColumnDataAnalysis(request);
      return result;
    },
    {
      onSuccess: (postData) => {
        handlePostData(postData);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );

  return { isLoading, isError, error, mutate, data };
}
