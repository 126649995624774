import { ReactNode } from "react";

import { useChartScreenshotSettingsControls } from "components/chart/hooks";
import { useMapScreenshotSettingsControls } from "components/map/hooks";
import useMPCScreenshotSettingsControls from "components/multiphase-chart/hooks/useMPCScreenshotSettingsControls";
import { useSamChartScreenshotSettingsControls } from "components/sam/hooks";
import { useXDAScreenshotSettingsControls } from "components/vis/hooks";

import { useScreenshotContext } from ".";
import { ScreenshotSetting } from "../components";

export interface SettingsControl {
  group: string;
  control: ReactNode;
}

export const SettingsGroups = {
  preset: "Preset",
  quick: "Quick Actions"
};

export function useScreenshotSettingsControls(): SettingsControl[] {
  // hooks
  const { widget, settings } = useScreenshotContext();
  const chartControls = useChartScreenshotSettingsControls();
  const mapControls = useMapScreenshotSettingsControls();
  const mpcControls = useMPCScreenshotSettingsControls();
  const samChartControls = useSamChartScreenshotSettingsControls();
  const XDAControls = useXDAScreenshotSettingsControls();

  const controls: SettingsControl[] = [];

  if (!widget || !settings) return controls;

  controls.push(
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item key="height" name="height" label="Height">
          <ScreenshotSetting.Input type="number" min={0} unit="px" step={10} />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item key="width" name="width" label="Width">
          <ScreenshotSetting.Input type="number" min={0} unit="px" step={10} />
        </ScreenshotSetting.Item>
      )
    }
  );

  controls.push(...chartControls);
  controls.push(...mapControls);
  controls.push(...mpcControls);
  controls.push(...samChartControls);
  controls.push(...XDAControls);

  return controls;
}
