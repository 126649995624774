import { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { EntityKind } from "../../../models/entityKind";

export type SortDirectionT = "asc" | "desc";
type HashMap = Record<string, string>;

export type Action =
  | { type: "update color map"; payload: HashMap }
  | { type: "toggle pagination"; payload: boolean }
  | { type: "change page"; payload: number }
  | { type: "change page size"; payload: number }
  | { type: "change sort column"; payload: string }
  | { type: "change sort direction"; payload: SortDirectionT }
  | { type: "update toolbar height"; payload: number }
  | { type: "update wells"; payload: Record<string, string[]> | null }
  | { type: "update uwis"; payload: Array<string> }
  | { type: "update overflow index"; payload: number };

export type State = {
  colorMap: HashMap;
  pagination: boolean;
  page: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirectionT;
  toolbarHeight: number;
  wells: Record<string, string[]> | null;
  uwis: string[];
  overflowindex: number;
  entityKind?: EntityKind;
};

export type Dispatch = (action: Action) => void;

const TableContext = createContext<[State, React.Dispatch<Action>] | undefined>(
  undefined
);
TableContext.displayName = "TableContext";

const initialState: State = {
  colorMap: null,
  pagination: false,
  page: 1,
  pageSize: 50,
  sortColumn: "",
  sortDirection: "asc",
  toolbarHeight: 50,
  wells: null,
  uwis: [],
  overflowindex: 0,
  entityKind: EntityKind.Well
};

const tableReducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "update color map":
      draft.colorMap = action.payload;
      break;
    case "toggle pagination":
      draft.pagination = action.payload;
      break;
    case "change page":
      draft.page = action.payload;
      break;
    case "change page size":
      draft.pageSize = action.payload;
      break;
    case "change sort column":
      draft.sortColumn = action.payload;
      break;
    case "change sort direction":
      draft.sortDirection = action.payload;
      break;
    case "update toolbar height":
      draft.toolbarHeight = action.payload;
      break;
    case "update wells":
      draft.wells = action.payload;
      break;
    case "update uwis":
      draft.uwis = action.payload;
      break;
    case "update overflow index":
      draft.overflowindex = action.payload;
      break;
    default:
      throw new Error("invalid data-table action");
  }
};

const curriedReducer = produce(tableReducer);

// provider
type TableProviderT = { children: ReactNode; entityKind: EntityKind };

const TableProvider = ({ children, entityKind }: TableProviderT) => {
  const [state, dispatch] = useReducer(curriedReducer, { ...initialState, entityKind });
  const value: [State, Dispatch] = useMemo(() => [state, dispatch], [state]);

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

// consumer hook
const useTable = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error("useTable must be used within a TableProvider");
  }
  return context;
};

// context module functions
// pagination
const turnPaginationOff = (dispatch: Dispatch) =>
  dispatch({ type: "toggle pagination", payload: false });
const turnPaginationOn = (dispatch: Dispatch) =>
  dispatch({ type: "toggle pagination", payload: true });
const changePage = (dispatch: Dispatch, value: number) =>
  dispatch({ type: "change page", payload: value });
const changePageSize = (dispatch: Dispatch, value: number) =>
  dispatch({ type: "change page size", payload: value });
const changeSortColumn = (dispatch: Dispatch, value: string) =>
  dispatch({ type: "change sort column", payload: value });
const changeSortDirection = (dispatch: Dispatch, value: SortDirectionT) =>
  dispatch({ type: "change sort direction", payload: value });

// (8+8)px padding on top and bottom
const updateToolbarHeight = (dispatch: Dispatch, value: number) =>
  dispatch({ type: "update toolbar height", payload: value + 16 });

const updateWells = (dispatch: Dispatch, value: Record<string, string[]>) => {
  if (value) {
    const uwis = Object.keys(value);
    dispatch({ type: "update wells", payload: value });
    dispatch({ type: "update uwis", payload: uwis });
  }
};

const updateOverflowIndex = (dispatch: Dispatch, value: number) =>
  dispatch({ type: "update overflow index", payload: value });

const updateColorMap = (dispatch: Dispatch, value: HashMap) =>
  dispatch({ type: "update color map", payload: value });

export {
  changePage,
  changePageSize,
  changeSortColumn,
  changeSortDirection,
  turnPaginationOff,
  turnPaginationOn,
  updateColorMap,
  updateToolbarHeight,
  updateWells,
  TableProvider,
  useTable,
  updateOverflowIndex
};
