import { useUser } from "hooks";

import styles from "./UserProfileHeader.module.scss";

function UserProfileHeader() {
  const { email, fullName } = useUser();

  return (
    <div className={`${styles.UserProfileHeader} flex items-center`}>
      <div className="flex-column">
        <span className={styles.name}>{fullName}</span>
        {email}
      </div>
    </div>
  );
}

export default UserProfileHeader;
