import { memo } from "react";

function OnePlusWOR() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
      <g id="Group-2" transform="translate(4.607739 1.2340614)">
        <path
          d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
          id="Stroke-1-Copy-5"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
          transform="translate(0 4.0531893)"
          id="Stroke-4-Copy-4"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
      </g>
      <path
        d="M0 18.464L18.5964 0"
        transform="translate(11.085341 1.8905864)"
        id="Path-8-Copy-7"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2.025"
      />
      <path
        d="M5.06893 13.7617C7.86842 13.7617 10.1379 11.4922 10.1379 8.69274C10.1379 5.89324 5.06893 1.95565e-13 5.06893 1.95565e-13C5.06893 1.95565e-13 0 5.89324 0 8.69274C0 11.4922 2.26944 13.7617 5.06893 13.7617Z"
        transform="translate(18.965815 15.581296)"
        id="Oval-Copy-23"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
      <text
        fontSize="10px"
        fill="currentColor"
        fillRule="evenodd"
        transform="translate(0 26.5)"
        strokeWidth="0.5"
        stroke="currentColor">
        1+
      </text>
    </svg>
  );
}

export default memo(OnePlusWOR);
