import _max from "lodash/max";
import _min from "lodash/min";

import { IChartSeries } from "models/chart";

type AxisType = "x" | "y";

const getSeriesMinMax = (
  axis: AxisType,
  series: Array<IChartSeries>
): [number | string, number | string] => {
  if (!series || !series.length || !series[0][axis]) return [0, 0];

  const allMinList = series.map((s) => _min(s[axis]));
  const allMaxList = series.map((s) => _max(s[axis]));

  const min = _min(allMinList);
  const max = _max(allMaxList);

  return [min, max];
};

export default getSeriesMinMax;
