import InfoIcon from "@material-ui/icons/Info";
import { Typography } from "antd";
import styled from "styled-components";

import RadioTabs from "components/base/RadioTabs";

const { Title } = Typography;

export const InfoTitle = styled(Title)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const InfoIconWrapper = styled(InfoIcon)`
  color: #a2aaad;
  font-size: 16px;
`;
export const Info = styled.span`
  display: inline-block;
  max-width: 300px;
  white-space: pre-wrap;
`;
export const StyledRadioTabs = styled(RadioTabs)`
  & > .RadioTab.isChecked {
    border-color: var(--color-text);
    background-color: var(--color-text) !important;
  }
`;
