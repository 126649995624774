import { Tooltip as AntdTooltip, TooltipProps } from "antd";

const delay: number = 0.6;

// TODO: Replace implementations of BaseTooltip + react-tooltip library.
// https://github.com/ant-design/ant-design/issues/25214
// Simply adding a div or fragment around children will break styles.

const Tooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <AntdTooltip
      mouseEnterDelay={props.mouseEnterDelay || delay}
      mouseLeaveDelay={props.mouseLeaveDelay || 0}
      {...props}>
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
