import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Checkbox from "antd/lib/checkbox/Checkbox";
import styled from "styled-components";

import { IGroupByListColumn } from "models";

import { ChevronDown, ChevronRight } from "components/icons";

import FieldListItem from "./FieldListItem";
import { useGroupByState, useGroupByUpdater } from "./group-by.context";
import { useSelectedCategory } from "./hooks";
import useCustomColumnOrderMutation from "./hooks/useMutationCustomColumnOrder";
import useGeomBinOrderMutation from "./hooks/useMutationGeomBinOrder";

type FieldGroup = {
  groupName: string;
  fields: IGroupByListColumn[];
  groups: FieldGroup[];
  dragHandleProps;
  isDragging: boolean;
  shortcutsEnabled: boolean;
};

const FieldGroup = ({
  groupName,
  fields,
  groups,
  dragHandleProps,
  isDragging,
  shortcutsEnabled
}: FieldGroup) => {
  const titleRow = <FieldGroupTitle>{groupName}</FieldGroupTitle>;
  const [fieldsList, setFieldsList] = useState<IGroupByListColumn[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { isUserDefinedColumn, isOrgDefinedColumn } = useSelectedCategory();
  const stateDispatch = useGroupByUpdater();
  const { customFieldsEditToggle, selectedTab, checkedFields } = useGroupByState();
  const prevFieldsRef = useRef(fields);
  const isCustomColumn = useMemo(() => {
    return isOrgDefinedColumn || isUserDefinedColumn;
  }, [isOrgDefinedColumn, isUserDefinedColumn]);
  const enabled =
    (isUserDefinedColumn || isOrgDefinedColumn) &&
    customFieldsEditToggle &&
    fields.length > 1;

  useEffect(() => {
    if (isDragging) {
      setIsCollapsed(true);
    }
  }, [isDragging]);

  useEffect(() => {
    if (
      JSON.stringify(fields) !== JSON.stringify(prevFieldsRef.current) ||
      fieldsList.length === 0
    ) {
      setFieldsList(fields);
    }

    prevFieldsRef.current = fields;
  }, [fields]);
  const { mutate: fieldMutate } = useCustomColumnOrderMutation(isOrgDefinedColumn);
  const { mutate: geomBinMutate } = useGeomBinOrderMutation();

  if (!fieldsList.length) {
    return null;
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = JSON.parse(JSON.stringify(fieldsList));
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    reorderedItems.forEach((item, index) => {
      item.withinSubgroupOrder = index;
    });

    const updatedItems = reorderedItems.map((item, index) => ({
      id: item.id,
      withinSubgroupOrder: index
    }));

    setFieldsList(reorderedItems);
    if (selectedTab === "fields") {
      fieldMutate(updatedItems);
    } else {
      geomBinMutate(updatedItems);
    }
  };

  const onCheckboxChange = () => {
    const fieldsInGroup = fields.map((f) => f.id);
    const hasCheckedItem =
      fields.filter((obj) => checkedFields.includes(obj.id)).map((obj) => obj.id).length >
      0;
    stateDispatch({
      type: "checked_fields",
      payload: hasCheckedItem
        ? checkedFields.filter((item) => !fieldsInGroup.includes(item))
        : [...new Set(checkedFields.concat(fieldsInGroup))]
    });
  };
  return (
    <Wrapper>
      <StyledSpan {...dragHandleProps} onClick={toggleCollapse}>
        {customFieldsEditToggle && (
          <DragHandleIconWrapper dragEnabled={customFieldsEditToggle}>
            <DragHandleIcon />
          </DragHandleIconWrapper>
        )}

        {isCustomColumn ? (
          isCollapsed ? (
            <ChevronRight size={18} />
          ) : (
            <ChevronDown size={18} />
          )
        ) : null}
        <>
          {(isUserDefinedColumn || isOrgDefinedColumn) && customFieldsEditToggle && (
            <CheckboxItem
              checked={
                fields
                  .filter((obj) => checkedFields.includes(obj.id))
                  .map((obj) => obj.id).length > 0
              }
              onClick={(evt) => evt.stopPropagation()}
              onChange={onCheckboxChange}></CheckboxItem>
          )}
        </>
        {titleRow}
      </StyledSpan>

      {enabled ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <>
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fieldsList.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          {!isCollapsed && (
                            <FieldListItem
                              value={item}
                              dragEnabled={fieldsList.length > 1}
                              groups={groups}
                              shortcutsEnabled={shortcutsEnabled}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <>
          {(!isCollapsed || !isCustomColumn) &&
            fieldsList.map((item) => (
              <div key={item.id}>
                <FieldListItem
                  value={item}
                  dragEnabled={false}
                  groups={groups}
                  shortcutsEnabled={shortcutsEnabled}
                />
              </div>
            ))}
        </>
      )}
    </Wrapper>
  );
};

const FieldGroupTitle = styled.div`
  font-size: 1.4rem;
  font-weight: var(--fontWeightBold);
`;

const StyledSpan = styled.span`
  padding-left: var(--space-4);
  padding-top: var(--space-4);
  gap: var(--space-1);
  border-radius: var(--border-radius);
  &:first-child {
    padding-top: var(--space-0);
  }
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  min-width: 0;
`;
const Wrapper = styled.div`
  max-width: var(--field-col-width);
  cursor: pointer;
`;

const CheckboxItem = styled(Checkbox)`
  overflow-x: visible !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
`;

const DragHandleIconWrapper = styled.span`
  visibility: ${({ dragEnabled }) => (dragEnabled ? "visible" : "hidden")};
  color: #c9d1d2;
  font-size: 16px;
`;

export default FieldGroup;
