// Custom hook to use the PadScenarioContext
import { useContext } from "react";

import { PadScenarioContext } from "./PadScenarioProvider";

export const usePadScenario = () => {
  const context = useContext(PadScenarioContext);
  if (!context) {
    throw new Error("usePadScenario must be used within a PadScenarioProvider");
  }
  return context;
};
