import { useDispatch } from "react-redux";

import { KeyboardBackspace, Refresh } from "@material-ui/icons";
import styled from "styled-components";

import { useUser } from "hooks";

import { GeomT } from "models/model";

import {
  BaseDropdown,
  BaseIconToggle,
  BaseTooltip,
  ColorPicker,
  ErrorBoundary
} from "components/base";
import { useUpdateProjectShapefileMutation } from "components/project/shapefiles/mutations";

import OrganizationShapefileFeatureGroup from "./OrganizationShapefileFeatureGroup";

export function OrganizationShapefileDetails({
  selectedProjectId,
  selectedShapefile,
  shapefileFeatures,
  selectedFeatureProperty,
  setSelectedFeatureProperty,
  setSelectedShapefile
}) {
  const dispatch = useDispatch();

  const { isAtLeastPowerUser } = useUser();
  const shapefileFeaturePropertyKey: string =
    selectedFeatureProperty?.[selectedShapefile?.key];
  const updateProjectShapefile = useUpdateProjectShapefileMutation({
    projectId: "organization"
  });

  const features = shapefileFeatures?.[selectedShapefile?.key]?.features;
  const groupedFeatures = groupFeaturesByProperty(features, shapefileFeaturePropertyKey);
  const properties = groupedFeatures?.[0]?.[0]?.Properties;
  // Filter groupedFeatures and list of properites to include only those whose geoms values are a number, string, or null (no objects etc...)
  const filteredGroupedFeatures = groupedFeatures?.filter((featureGroup) => {
    const geomValue = featureGroup?.[0]?.Properties?.[shapefileFeaturePropertyKey];
    return (
      typeof geomValue === "number" ||
      typeof geomValue === "string" ||
      typeof geomValue === "boolean" ||
      geomValue === null
    );
  });
  const selectableProperties = properties
    ? Object.keys(properties).filter((key) => {
        const geomValue = properties[key];
        return (
          typeof geomValue === "number" ||
          typeof geomValue === "string" ||
          typeof geomValue === "boolean" ||
          geomValue === null
        );
      })
    : [];

  // Turn into useMutation. UpdateProjectShapefileProp Mutation/ and UpdateProjectLinkedShapefileProp
  function updateShapefileProp(prop, value) {
    updateProjectShapefile.mutate(
      {
        body: {
          key: prop,
          value
        },
        projectId: selectedProjectId,
        shapefileId: selectedShapefile.shapefileId
      },
      {
        onSuccess: () => {
          dispatch(
            setSelectedShapefile({
              ...selectedShapefile,
              layer: {
                ...selectedShapefile.layer,
                style: { ...selectedShapefile.layer.style, [prop]: value }
              }
            })
          );
        }
      }
    );
  }
  return (
    <ShapefileFeatureContainer>
      <ShapefileHeaderContainer>
        <BaseIconToggle size={0}>
          <KeyboardBackspace
            fontSize="large"
            onClick={() => setSelectedShapefile(null)}
          />
        </BaseIconToggle>
        <ShapefileLeftContainer>
          <ColorPicker
            isReadOnly={!isAtLeastPowerUser}
            onChange={updateShapefileProp}
            values={{
              color: selectedShapefile.layer.style.color,
              strokeColor: selectedShapefile.layer.style.strokeColor,
              thickness: selectedShapefile.layer.style.thickness,
              opacity: (selectedShapefile.layer.style.opacity ?? 1) * 100
            }}
          />
          <ShapefileTitle>{selectedShapefile.title}</ShapefileTitle>
        </ShapefileLeftContainer>

        <ShapefileRightContainer>
          <BaseTooltip text="Reset All">
            <BaseIconToggle size={0}>
              <Refresh fontSize="large" />
            </BaseIconToggle>
          </BaseTooltip>
        </ShapefileRightContainer>
      </ShapefileHeaderContainer>

      <PropertySelectorContainer>
        <BaseDropdown
          options={selectableProperties}
          className="property-dropdown"
          value={selectedFeatureProperty[selectedShapefile?.key]}
          onChange={(val) => {
            setSelectedFeatureProperty({
              ...selectedFeatureProperty,
              [selectedShapefile?.key]: val
            });
          }}
        />
      </PropertySelectorContainer>

      <ErrorBoundary>
        <FeaturesContainer>
          <OrganizationShapefileFeatureGroup
            shapefileFeaturePropertyKey={shapefileFeaturePropertyKey}
            groups={filteredGroupedFeatures}
            shapefileFeatures={shapefileFeatures}
            selectedShapefile={selectedShapefile}
          />
        </FeaturesContainer>
      </ErrorBoundary>
    </ShapefileFeatureContainer>
  );
}

export const FeatureBackContainer = styled.div`
  padding: 10px 23px 0px 16px;
  display: flex;
  align-items: flex-start;
`;

const ShapefileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
  padding-left: 14px;
  padding-right: 23px;

  font-weight: var(--fontWeightBold);

  svg {
    color: #a2aaad;
    transition: color var(--duration);
    &:hover {
      color: var(--color-text);
    }
  }
`;

const ShapefileLeftContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ShapefileRightContainer = styled.div`
  width: 16px;
`;

const ShapefileTitle = styled.div`
  padding-right: 8px;
  padding-left: 6px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PropertySelectorContainer = styled.div`
  padding: 10px 23px 0px 14px;
  font-weight: var(--fontWeightBold);
  align-items: center;

  .property-dropdown {
    width: 100%;
    height: 30px;
  }
`;

const FeaturesContainer = styled.div`
  padding: 10px 16px 10px 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

export const ShapefileFeatureContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  min-width: 0;
`;

function groupFeaturesByProperty(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shapefileFeatures: any[],
  groupByProperty: string
): GeomT[][] {
  if (!shapefileFeatures?.length) return;

  const keyedShapefileFeature: { [key: string]: GeomT[] } = {};

  // Group by unique property values
  shapefileFeatures.forEach((shapefileFeature) => {
    const uniquePropertyValue = shapefileFeature.Properties?.[groupByProperty];

    if (!keyedShapefileFeature[uniquePropertyValue]) {
      keyedShapefileFeature[uniquePropertyValue] = [];
    }

    keyedShapefileFeature[uniquePropertyValue].push(shapefileFeature);
  });

  const groupedShapedFileFeatures: GeomT[][] = [];
  for (const shapefileFeature in keyedShapefileFeature) {
    groupedShapedFileFeatures.push(keyedShapefileFeature[shapefileFeature]);
  }

  return groupedShapedFileFeatures;
}
