import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import {
  ORGANIZATION_SHAPEFILES_QUERY_KEYS,
  PROJECT_SHAPEFILES_QUERY_KEYS,
  SYSTEM_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

export interface IUseMoveProjectShapefileFolderMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
  projectId: string;
}

export type MoveProjectShapefileFolderParamsT = {
  moveToId: string;
  dropPosition: number;
  shapefileNodeId: string;
  type: string;
};

async function MoveShapefileFolder(params) {
  const { moveToId, shapefileNodeId, dropPosition, type, projectId } = params;
  const response = await axios.put(
    type === "system"
      ? `${projectServiceEndpoint}/system-shapefile-node/move/${shapefileNodeId}${
          moveToId ? `/${moveToId}` : ""
        }`
      : `${projectServiceEndpoint}/shapefile-node/move/${shapefileNodeId}${
          moveToId ? `/${moveToId}` : ""
        }`,
    {
      order: dropPosition,
      type: type,
      projectId: projectId === "organization" ? null : projectId
    }
  );
  return response;
}
export function useMoveProjectShapefileFolderMutation({
  onError,
  onMutate,
  onSettled,
  projectId,
  onSuccess
}: IUseMoveProjectShapefileFolderMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: MoveProjectShapefileFolderParamsT) =>
      MoveShapefileFolder({ ...params, projectId }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: () => {
        if (projectId === "system") {
          queryClient.invalidateQueries({
            queryKey: [SYSTEM_SHAPEFILES_QUERY_KEYS.systemShapefiles]
          });
        } else if (projectId === "organization") {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });
        }
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });

        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });
        onSuccess && onSuccess();
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
