/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useReducer } from "react";

import { MCDANIEL_SEARCH_FOLDER_NAME } from "constants/settings.constants";

import { IGroupByListColumn, IGroupByListItem } from "models";
import { EntityKind } from "models/entityKind";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import { GroupByT } from "./GroupBy";

export type PropsT = Omit<GroupByT, "children" | "placement">;

export type MetadataFilterT = { key: string; value: string };
export type MetadataFiltersT = { [key: string]: string };

export const IGNORE_METADATA_FILTERS_FOR_GROUPS = ["Performance", "Forecast"];
export const MAX_SEARCH_RESULTS = 50;
const MINIMUM_SEARCH_LENGTH = 2;

type Action =
  | { type: "props"; payload: PropsT }
  | { type: "store_list_updated"; payload: IGroupByListItem[] }
  | { type: "selected_category"; payload: IGroupByListItem }
  | { type: "selected_field"; payload: IGroupByListColumn }
  | { type: "selected_product"; payload: string }
  | { type: "filter"; payload: string }
  | { type: "selected_forecast_folder"; payload: string }
  | { type: "selected_forecast_folder_name"; payload: string }
  | { type: "selected_rescat"; payload: string }
  | { type: "forecast_toggle"; payload: boolean }
  | { type: "selected_pden_source"; payload: PdenSourceEnum }
  | { type: "checked_fields"; payload: string[] }
  | { type: "custom_fields_edit_toggle"; payload: boolean }
  | { type: "selected_tab"; payload: string }
  | { type: "selected_metadata_filter"; payload: MetadataFilterT }
  | { type: "hovered_field"; payload: IGroupByListColumn };

export type Dispatch = (action: Action) => void;

export type State = {
  props: PropsT;
  allColumns: IGroupByListColumn[];
  categoryList: IGroupByListItem[];
  categoryColors: { [key: string]: string };
  selectedCategory: IGroupByListItem;
  selectedField: IGroupByListColumn;
  selectedProduct: string;
  filterText: string;
  isForecastToggleOn: boolean;
  selectedForecastFolder: string;
  selectedForecastFolderName: string;
  selectedRescat: string;
  selectedPdenSource: number;
  selectedMetadataFilters: MetadataFiltersT;
  checkedFields: string[];
  customFieldsEditToggle: boolean;
  selectedTab: string;
  isFiltering?: boolean;
  entityKind: EntityKind;
  hoveredField?: IGroupByListColumn;
};

type GroupByProviderProps = { children: React.ReactNode; entityKind?: EntityKind };

const GroupByStateContext = createContext<State | undefined>(undefined);

const GroupByUpdaterContext = createContext<Dispatch | undefined>(undefined);

const initialState = {
  props: null,
  allColumns: null,
  categoryColors: {},
  categoryList: null,
  selectedCategory: null,
  selectedField: null,
  selectedProduct: null,
  filterText: "",
  selectedPdenSource: 100,
  isForecastToggleOn: true,
  selectedForecastFolder: MCDANIEL_SEARCH_FOLDER_NAME,
  selectedRescat: "P+PDP",
  selectedForecastFolderName: MCDANIEL_SEARCH_FOLDER_NAME,
  selectedMetadataFilters: {
    type: "XDA",
    radius: "Gross"
  },
  checkedFields: [],
  customFieldsEditToggle: false,
  selectedTab: "fields",
  isFiltering: false,
  entityKind: EntityKind.Well,
  hoveredField: null
};

const actionMap = {
  store_list_updated: (s, a) => ({
    ...s,
    categoryList: a.payload,
    allColumns: getColumns(a.payload),
    categoryColors: a.payload.reduce((acc, curr) => {
      acc[curr.name] = curr.hexColor;
      return acc;
    }, {})
  }),
  selected_pden_source: (s, a) => ({ ...s, selectedPdenSource: a.payload }),
  selected_category: (s, a) => ({ ...s, selectedCategory: a.payload }),
  selected_field: (s, a) => ({ ...s, selectedField: a.payload }),
  selected_product: (s, a) => ({ ...s, selectedProduct: a.payload }),
  filter: (s, a) => ({
    ...s,
    filterText: a.payload,
    isFiltering: a.payload?.length >= MINIMUM_SEARCH_LENGTH
  }),
  forecast_toggle: (s, a) => ({ ...s, isForecastToggleOn: a.payload }),
  props: (s, a) => ({ ...s, props: a.payload }),
  selected_forecast_folder: (s, a) => ({
    ...s,
    selectedForecastFolder: a.payload
  }),
  selected_forecast_folder_name: (s, a) => ({
    ...s,
    selectedForecastFolderName: a.payload
  }),
  selected_metadata_filter: (s, a) => ({
    ...s,
    selectedMetadataFilters: {
      ...s.selectedMetadataFilters,
      [a.payload.key]: a.payload.value
    }
  }),
  selected_rescat: (s, a) => ({ ...s, selectedRescat: a.payload }),
  checked_fields: (s, a) => ({ ...s, checkedFields: a.payload }),
  custom_fields_edit_toggle: (s, a) => ({ ...s, customFieldsEditToggle: a.payload }),
  selected_tab: (s, a) => ({ ...s, selectedTab: a.payload }),
  hovered_field: (s, a) => ({ ...s, hoveredField: a.payload })
};

function groupByReducer(state: State, action: Action) {
  if (!Object.prototype.hasOwnProperty.call(actionMap, action.type)) {
    throw new Error(`Invalid action type: ${action.type}`);
  }

  const handler = actionMap[action.type];
  return handler(state, action);
}

const getColumns = (list) => {
  return list.reduce((prev, curr) => [...prev, ...curr.columns], []);
};

function GroupByProvider({
  children,
  entityKind = EntityKind.Well
}: GroupByProviderProps) {
  const [state, dispatch] = useReducer(groupByReducer, {
    ...initialState,
    entityKind,
    isForecastToggleOn: entityKind === EntityKind.Well
  });

  return (
    <GroupByStateContext.Provider value={state}>
      <GroupByUpdaterContext.Provider value={dispatch}>
        {children}
      </GroupByUpdaterContext.Provider>
    </GroupByStateContext.Provider>
  );
}

function useGroupByState(): State {
  const context = useContext(GroupByStateContext);

  if (context === undefined) {
    throw new Error("useGroupByState must be used within a GroupByProvider");
  }

  return context;
}

function useGroupByUpdater(): Dispatch {
  const context = useContext(GroupByUpdaterContext);

  if (context === undefined) {
    throw new Error("useGroupByUpdater must be used within a GroupByProvider");
  }

  return context;
}

export { GroupByProvider, useGroupByState, useGroupByUpdater };
