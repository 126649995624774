import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { InfoRounded, RemoveCircleOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { Table, Tabs } from "antd";
import {
  GDC_SOURCE,
  IHS_SOURCE,
  SHALE_PROFILE_SOURCE
} from "constants/datasource.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { DataSourceModel, fetchDataSources } from "api/dataSource";

import {
  BaseButton,
  BaseIconToggle,
  BaseSlideToggle,
  ErrorBoundary,
  Heading
} from "components/base";
import { IconSpinner } from "components/icons";

import AddGdcDataSourceModal from "./AddGdcDataSourceModal";
import AddIhsDataSourceModal from "./AddIhsDataSourceModal";
import AddShaleProfileModal from "./AddShaleProfileModal";
import DataSourceAccessModal from "./DataSourceAccessModal";
import "./OrganizationDataSources.scss";
import RemoveDataSourceModal from "./RemoveGdcDataSourceModal";
import RemoveProdViewDataSourceModal from "./RemoveProdViewDataSourceModal";

function sourceKey(model: DataSourceModel) {
  if (model.source === "Client" && model.name) {
    return `${model.source}_${model.name.split(" ")[0]}`;
  }
  return model.source;
}

const StyledSpinner = styled(IconSpinner)`
  align-self: flex-start;
`;
const loadingIcon = <StyledSpinner />;
interface IDataSourceTableModel {
  sources: DataSourceModel[];
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  onRemoveSource(s: string): void;
  // eslint-disable-next-line no-unused-vars
  openAddModal(s: string, source: DataSourceModel): void;
  // eslint-disable-next-line no-unused-vars
  onShowAccess(source: DataSourceModel): void;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DataSourcesTable(model: IDataSourceTableModel) {
  const [expandedRows] = useState([]);

  function recordIsExpanded(key) {
    return expandedRows.some((r) => r === key);
  }

  function canShowWellAccess(source) {
    const valid = [GDC_SOURCE, SHALE_PROFILE_SOURCE, IHS_SOURCE];

    return valid.includes(source);
  }

  const columns = [
    {
      title: "Data Source",
      dataIndex: "name",
      width: "45%",
      defaultSortOrder: "ascend" as const
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "45%",
      render: (_, record: DataSourceModel) => {
        return (
          <div className={`flex-row status-row`} key={record.name + "status"}>
            {record.isActive && record.hasSource && (
              <div className="flex-row">
                <BaseSlideToggle checked={record.isActive} classic /> Active
              </div>
            )}
            {!record.hasSource && <span>Not Added</span>}
          </div>
        );
      }
    },
    {
      title: "",
      dataIndex: "actions",
      width: "10%",
      render: (ex, record: DataSourceModel) => {
        return record.hasSource && record.isActive ? (
          <div className="flex-row hover-actions" key={record.name + "active"}>
            {record.hasSource && record.isActive && record.canRemove && (
              <BaseIconToggle size={32}>
                <RemoveCircleOutlined
                  style={{ fontSize: "22px" }}
                  onClick={() =>
                    model.onRemoveSource && model.onRemoveSource(sourceKey(record))
                  }
                  className="delete-source"
                />
              </BaseIconToggle>
            )}
            {record.hasSource && record.isActive && canShowWellAccess(record.source) && (
              <BaseIconToggle size={32}>
                <InfoRounded
                  style={{ fontSize: "22px" }}
                  onClick={() => model.onShowAccess && model.onShowAccess(record)}
                />
              </BaseIconToggle>
            )}
          </div>
        ) : (
          <div className="add-source" key={record.name + "active"}>
            <BaseButton
              appearance="subtle"
              onClick={() => {
                model.openAddModal && model.openAddModal(sourceKey(record), record);
              }}>
              <AddIcon fontSize="large" />
              Add Source
            </BaseButton>
          </div>
        );
      }
    }
  ];

  return (
    <div className="table-container">
      <Table
        loading={{
          indicator: loadingIcon,
          spinning: model.loading
        }}
        columns={columns}
        sortDirections={["ascend", "descend", "ascend"]}
        dataSource={model.sources}
        pagination={{
          pageSize: 24,
          showSizeChanger: false,
          hideOnSinglePage: true
        }}
        rowClassName={(record) =>
          `${recordIsExpanded(record.name) ? "row-expanded" : ""}`
        }
        size="small"
      />
    </div>
  );
}

export default function OrganizationDataSources(): JSX.Element {
  const orgId = useSelector((state: RootState) => state.auth.user.organization.id);
  const [showAddModal, setShowAddModal] = useState({
    Client_Peloton: false,
    Gdc: false,
    Ihs: false,
    Shaleprofile: false
  });
  const [showRemoveModal, setShowRemoveModal] = useState({
    Client_Peloton: false,
    Gdc: false,
    Ihs: false,
    Shaleprofile: false
  });
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState<DataSourceModel>();

  const { isLoading, isError, data, error, refetch } = useQuery("dataSouces", () => {
    return fetchDataSources(orgId);
  });

  function openAddModal(sourceName, dataSource) {
    const modal = Object.assign({}, showAddModal, { [sourceName]: true });
    setShowAddModal(modal);
    setSelectedDataSource(dataSource);
  }
  function closeAddModal() {
    setShowAddModal({
      Gdc: false,
      Ihs: false,
      Client_Peloton: false,
      Shaleprofile: false
    });
    refetch();
  }
  function openRemoveModal(source: string) {
    setShowRemoveModal(Object.assign({}, showRemoveModal, { [source]: true }));
  }
  function openAccessModel(dataSource: DataSourceModel) {
    setShowAccessModal(true);
    setSelectedDataSource(dataSource);
  }
  function closeAccessModal() {
    setShowAccessModal(false);
  }

  function closeRemoveModal() {
    setShowRemoveModal({
      Gdc: false,
      Ihs: false,
      Shaleprofile: false,
      Client_Peloton: false
    });
    setTimeout(() => refetch(), 200);
  }

  if (isLoading) {
    return <div>Loading..</div>;
  }
  if (isError) {
    return <div>Error loading: {error}</div>;
  }
  return (
    <div className="OrganizationDataSources">
      <div className="organizationAccountHeader flex-row">
        <Heading element="h4">Data Source</Heading>
      </div>
      <ErrorBoundary>
        <Tabs
          items={[
            {
              key: "sources",
              label: "Your Public Data Sources",
              children: (
                <DataSourcesTable
                  sources={data.ok ? data.value : []}
                  onRemoveSource={openRemoveModal}
                  onShowAccess={openAccessModel}
                  loading={isLoading}
                  openAddModal={openAddModal}
                />
              )
            }
          ]}
        />
        <AddShaleProfileModal
          open={showAddModal.Shaleprofile}
          onClose={closeAddModal}
          orgId={orgId}
          name={selectedDataSource?.name}
        />
        <AddIhsDataSourceModal
          open={showAddModal.Ihs}
          onClose={closeAddModal}
          orgId={orgId}
          name={selectedDataSource?.name}
        />
        <AddGdcDataSourceModal
          open={showAddModal.Gdc}
          onClose={closeAddModal}
          orgId={orgId}
          name={selectedDataSource?.name}
        />
        <RemoveDataSourceModal
          source={"gdc"}
          sourceName="gDC"
          open={showRemoveModal.Gdc}
          onClose={closeRemoveModal}
          orgId={orgId}
        />
        <RemoveDataSourceModal
          source={"shaleprofile"}
          sourceName="Novi"
          open={showRemoveModal.Shaleprofile}
          onClose={closeRemoveModal}
          orgId={orgId}
        />
        <RemoveDataSourceModal
          source={"Ihs"}
          sourceName="IHS"
          open={showRemoveModal.Ihs}
          onClose={closeRemoveModal}
          orgId={orgId}
        />
        <RemoveProdViewDataSourceModal
          open={showRemoveModal.Client_Peloton}
          onClose={closeRemoveModal}
          orgId={orgId}
        />
        <DataSourceAccessModal
          dataSource={selectedDataSource}
          open={showAccessModal}
          onClose={closeAccessModal}
          orgId={orgId}
        />
        <ReactTooltip />
      </ErrorBoundary>
    </div>
  );
}
