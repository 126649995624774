const getSeriesNameStrippingForecast = (seriesName: string): string => {
  if (!seriesName) return null;

  const forecastLabelIndex = seriesName.indexOf(" Forecast");
  const newTraceName =
    forecastLabelIndex > 0
      ? seriesName.substring(0, forecastLabelIndex).trim()
      : seriesName;

  return newTraceName;
};

export default getSeriesNameStrippingForecast;
