import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { Button, Table } from "antd";
import styled from "styled-components";

import { GET_WELL_LIST_RECORDS } from "api/userArps";

import { convertDecline, declineTypes } from "components/arps/utils/arpsUtils";

import { ForecastActivityType, TYPE_WELLS } from "../../../constants";
import { UserArpsItem, WellListRecordCollection } from "../../../models/UserArpsModel";
import { SubHeading } from "../../base";
import { ForecastChangeRecords } from "./ForecastChangeRecords";
import { ForecastWellListRecords } from "./ForecastWellListRecords";

export let arpsWasm: typeof import("wasm/arps") = undefined;
import("wasm/arps.js").then((x) => {
  arpsWasm = x;
});

export interface ForecastDetailsModel {
  arpsItem: UserArpsItem;
  declineType: string;
  getTitle: (item, type) => string;
  type: ForecastActivityType;
  hasTypeWellSaved: boolean;
}

// TODO: move out
const getChangeRecordsButtonTitlePrefix = (mode) => {
  if (mode == "ChangeRecords") {
    return "Hide";
  } else if (mode === "WellListHistory") {
    return "View";
  } else {
    return "View";
  }
};

const getWellListHistoryButtonTitlePrefix = (mode) => {
  if (mode == "WellListHistory") {
    return "Hide";
  } else if (mode === "ChangeRecords") {
    return "View";
  } else {
    return "View";
  }
};

export default function ForecastDetails({
  arpsItem,
  declineType,
  getTitle,
  type,
  hasTypeWellSaved
}: ForecastDetailsModel) {
  const [mode, setMode] = useState<"Details" | "ChangeRecords" | "WellListHistory">(
    "Details"
  );

  const {
    data: wellListRecordData,
    loading: isWellListRecordLoading,
    refetch: refetchWellListRecords
  } = useQuery<WellListRecordCollection>(GET_WELL_LIST_RECORDS, {
    variables: {
      forecastId: arpsItem.id
    }
  });

  useEffect(() => {
    if (hasTypeWellSaved) {
      refetchWellListRecords();
    }
  }, [hasTypeWellSaved]);

  function addKeyToData(data) {
    return data.map((x, i) => {
      const copy = { ...x };
      copy.key = i + 1;

      return copy;
    });
  }

  function addWellDataToSource(data) {
    const source = [];
    const copy = { ...data };
    copy.key = 1;
    source.push(copy);
    return source;
  }

  const calculateCumVolume = (arps) => {
    let cumulativeVolume = 0;
    return arps.map((item, index) => {
      // Calculations for the Cum Volume column, will reset upon product change
      if (index != 0 && item.product !== arps[index - 1].product) {
        cumulativeVolume = 0;
      }

      cumulativeVolume += item.trendCum * 1e-3;

      //The final Cum Volume value of a product should be bolded
      const isBold =
        (index < arps.length - 1 && item.product !== arps[index + 1].product) ||
        index == arps.length - 1;

      return {
        ...item,
        di: convertDecline(item.di, item.b, declineType),
        df: convertDecline(item.df, item.b, declineType),
        cumulativeVolume:
          isNaN(cumulativeVolume) || !isFinite(cumulativeVolume) ? "-" : cumulativeVolume,
        bold: isBold
      };
    });
  };
  function getArps(arps) {
    const arpsWithCumVolume = calculateCumVolume(arps);
    return arpsWithCumVolume;
  }

  function getDeclineWithType(title) {
    return `${title} (${declineTypes[declineType]})`;
  }

  const columns = [
    {
      title: "Product",
      dataIndex: "product"
    },
    {
      title: "Segment",
      dataIndex: "segmentIndex"
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: formatDate
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: formatDate
    },
    {
      title: "Qi",
      dataIndex: "qi",
      render: roundToOneDecimalPlace
    },
    {
      title: getDeclineWithType("Di"),
      dataIndex: "di",
      render: roundToOneDecimalPlace
    },
    {
      title: "B",
      dataIndex: "b",
      render: roundToOneDecimalPlace
    },
    {
      title: "Qf",
      dataIndex: "qf",
      render: roundToOneDecimalPlace
    },
    {
      title: "Trend Cum",
      dataIndex: "trendCum",
      render: (value) => {
        return <span>{roundToOneDecimalPlace(value * 1e-3)}</span>;
      }
    },
    {
      title: getDeclineWithType("Df"),
      dataIndex: "df",
      render: roundToOneDecimalPlace
    },
    {
      title: "Max Rate",
      dataIndex: "maxRate",
      render: roundToOneDecimalPlace
    },
    {
      title: "Cum Volume",
      dataIndex: "cumulativeVolume",
      render: (value, record) => {
        const fontWeight = record.bold ? "bold" : "normal";
        return (
          <span style={{ fontWeight }}>
            {typeof value == "number" ? roundToOneDecimalPlace(value) : value}
          </span>
        );
      }
    }
  ];

  const RenderDetails = () => (
    <>
      <SubHeading>Arps Segments</SubHeading>
      <TableWrapper className="arps-table">
        <Table
          pagination={false}
          dataSource={addKeyToData(getArps(arpsItem.arps))}
          columns={columns}
        />
      </TableWrapper>

      {arpsItem.constants && (
        <>
          <SubHeading>Constants</SubHeading>
          <TableWrapper className="arps-table">
            <Table
              pagination={false}
              dataSource={addKeyToData(arpsItem.constants ?? [])}
              columns={constantColumns}
            />
          </TableWrapper>
        </>
      )}

      {type === TYPE_WELLS ? (
        <div style={{ paddingTop: "18px" }}>
          <SubHeading>Well Data</SubHeading>
          <div className="arps-table">
            <Table
              pagination={false}
              dataSource={addWellDataToSource(
                arpsItem.wellData ?? {
                  Stage: "-",
                  StageSpacing: "-",
                  Proppant: "-",
                  ProppantIntensity: "-",
                  HzLength: "-"
                }
              )}
              columns={wellDataColumns}
            />
          </div>
        </div>
      ) : null}
    </>
  );

  const changeRecordsButtonTitlePrefix = useMemo(
    () => getChangeRecordsButtonTitlePrefix(mode),
    [mode]
  );
  const wellListHistoryButtonTitlePrefix = useMemo(
    () => getWellListHistoryButtonTitlePrefix(mode),
    [mode]
  );

  const RenderChangeRecords = () => {
    return (
      <OverlayWrapper>
        <ForecastChangeRecords forecastId={arpsItem.id} />
      </OverlayWrapper>
    );
  };

  if (arpsItem && arpsItem.arps) {
    return (
      <>
        <HeadingWrapper>
          <HeaderLeftItems>
            <SubHeading element="h1">
              {getTitle(arpsItem, type)} {arpsItem?.source ? ` (${arpsItem.source})` : ""}
            </SubHeading>
          </HeaderLeftItems>
          <HeaderRightItems>
            <Button
              onClick={(e) => {
                setMode(() => {
                  if (mode == "WellListHistory") {
                    return "Details";
                  } else if (mode === "ChangeRecords") {
                    return "WellListHistory";
                  } else {
                    return "WellListHistory";
                  }
                });
                e.stopPropagation();
              }}
              type="default"
              title={
                "View Well Lists"
              }>{`${wellListHistoryButtonTitlePrefix} Well Lists`}</Button>
            <Button
              onClick={(e) => {
                setMode(() => {
                  if (mode == "ChangeRecords") {
                    return "Details";
                  } else if (mode === "WellListHistory") {
                    return "ChangeRecords";
                  } else {
                    return "ChangeRecords";
                  }
                });
                e.stopPropagation();
              }}
              type="default"
              title={
                "View Change Records"
              }>{`${changeRecordsButtonTitlePrefix} Change Records`}</Button>
          </HeaderRightItems>
        </HeadingWrapper>
        <RenderDetails />
        {/* change records is positioned over the details
        to retain the width and height of the popover*/}
        {mode == "ChangeRecords" && <RenderChangeRecords />}
        {mode == "WellListHistory" && (
          <OverlayWrapper>
            <ForecastWellListRecords
              data={wellListRecordData}
              isLoading={isWellListRecordLoading}
            />
          </OverlayWrapper>
        )}
      </>
    );
  }
  return null;
}

function roundToOneDecimalPlace(value) {
  return value?.toFixed(1) ?? undefined;
}

function formatDate(date) {
  const dateAndTime = date.split("T");
  return dateAndTime[0];
}

const constantColumns = [
  {
    title: "Ratio",
    dataIndex: "product"
  },
  {
    title: "Constant",
    dataIndex: "value"
  }
];

const wellDataColumns = [
  {
    title: "Stages Actual",
    dataIndex: "Stage"
  },
  {
    title: "Stages Spacing (m)",
    dataIndex: "StageSpacing"
  },
  {
    title: "Proppant Total (t)",
    dataIndex: "Proppant"
  },
  {
    title: "Proppant Intensity (t/m)",
    dataIndex: "ProppantIntensity"
  },
  {
    title: "Hz Length (m)",
    dataIndex: "HzLength"
  }
];

const TableWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftItems = styled.div`
  margin-right: auto;
`;

const HeaderRightItems = styled.div`
  display: flex;
  gap: 10px;
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0px 20px 20px 20px;
  background: white;
`;
