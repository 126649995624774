// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert } from "@mdi/js";
import { Select, Typography } from "antd";
import styled from "styled-components";

import { useUserSettingMutation } from "hooks";

import { DEFAULT_DECLINE_TYPE_SETTING } from "components/user-settings/constants/userSetting.constants";
import { useUserContext } from "components/user/context";
import { updateSettingDeclineType } from "components/user/context/reducer";
import { useUserSettings } from "components/user/hooks";

import { DefaultDeclineType as DefaultDecline, DefaultDeclineTypeT } from "../../models";

const { Title } = Typography;
const { Option } = Select;

const DefaultDeclineType = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const { declineType } = useUserSettings();
  const mutation = useUserSettingMutation<DefaultDecline>(DEFAULT_DECLINE_TYPE_SETTING);

  // state
  const [modifiedDeclineType, setModifiedDeclineType] = useState<DefaultDeclineTypeT>(
    declineType?.decline
  );
  const [updateError, setUpdateError] = useState("");

  const handleDeclineChange = useCallback(
    (decline: DefaultDeclineTypeT) => {
      mutation.mutate({
        id: declineType?.id,
        decline: decline
      });
      setModifiedDeclineType(decline);
    },
    [declineType?.id]
  );

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingDeclineType(dispatch, {
      id: mutation.data.id,
      decline: mutation.data.decline
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update preferred decline type.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  const isLoading = !declineType;

  return (
    <Wrapper>
      <Title level={5}>Decline Type</Title>

      <StyledSelect
        data-testid="decline-type-dropdown"
        value={!isLoading ? modifiedDeclineType : ""}
        onChange={handleDeclineChange}
        loading={isLoading}>
        <Option key="Tangent">Tangent</Option>
        <Option key="Nominal">Nominal</Option>
        <Option key="Secant">Secant</Option>
      </StyledSelect>

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </Wrapper>
  );
};

export default DefaultDeclineType;

//Styled  Components
const Wrapper = styled.div`
  .anticon-loading {
    font-size: 18px;
    color: var(--color-primary);
  }
`;

const StyledSelect = styled(Select)`
  width: 560px;
  height: 50px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100% !important;
  }
`;

const ErrorContainer = styled.div`
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
