import { Action, configureStore } from "@reduxjs/toolkit";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { ThunkAction } from "redux-thunk";

import { setChannel } from "./features/channel/channelSlice";
import rootReducer, { RootState } from "./rootReducer";

export default function initStore({ channel }) {
  const config = {
    channel
  };
  const middlewares = createStateSyncMiddleware(config);
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(middlewares),
    devTools: true
  });
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./rootReducer", () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const newRootReducer = require("./rootReducer").default;
      store.replaceReducer(newRootReducer);
    });
  }
  initMessageListener(store);

  store.dispatch(setChannel(channel));
  return store;
}

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
