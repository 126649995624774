/**
 * Gets alias of chart type from back end names
 *
 * @param {string} chartType chart type from back end
 * @returns {string} front end chart type
 */
const getChartTypeAlias = (chartType: string): string => {
  switch (chartType) {
    case "monthlyRateCum":
      return "Rate Cum";
    case "monthlyRateTime":
      return "Rate Time";
    case "rateDate":
      return "Rate Date";
    case "cumTime":
      return "Cum Time";
  }
  return "";
};

export default getChartTypeAlias;
