import { useMutation } from "react-query";

import { ExportDataRequest, exportData } from "api/data";

export default function useExportMutation(onSuccess, onError) {
  return useMutation(async (request: ExportDataRequest) => await exportData(request), {
    onSuccess: onSuccess,
    onError: onError
  });
}
