// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAccount, mdiAlert, mdiDelete, mdiDomain, mdiLaptop } from "@mdi/js";
import { Button, Input, Popconfirm, Popover } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { IScreenshotAbbreviation } from "models/screenshot";

import { USER_ABBREVIATIONS_SETTING } from "components/user-settings/constants/userSetting.constants";
import { UserAbbreviations } from "components/user-settings/models";
import {
  Info,
  InfoIconWrapper,
  InfoTitle
} from "components/user-settings/shared/SharedStyle";
import { DEFAULT_USER_ABBREVIATIONS } from "components/user/constants/userAbbreviations";
import { useUserContext } from "components/user/context";
import { updateSettingAbbreviations } from "components/user/context/reducer";
import { useUserSettingMutation, useUserSettings } from "components/user/hooks";

const abbreviationTooltipText = `Abbreviations are used to shorten common words found in screenshots in order to maximize the use of space and readability.`;

const ScreenshotAbbreviations = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const { userAbbreviations } = useUserSettings();
  const mutation = useUserSettingMutation<UserAbbreviations>(USER_ABBREVIATIONS_SETTING);

  // state
  const [updateError, setUpdateError] = useState("");
  const [currentText, setCurrentText] = useState(undefined);
  const [abbreviationText, setAbbreviationText] = useState(undefined);

  const debouncedSettingsChange = _debounce((settings: UserAbbreviations) => {
    mutation.mutate(settings);
  }, 400);

  const handleAddAbbreviation = useCallback(() => {
    if (!currentText) return;

    const newAbbreviations = userAbbreviations.abbreviations.filter(
      (x) => x.type === "user"
    );
    newAbbreviations.push({
      type: "user",
      current: currentText,
      abbreviation: abbreviationText,
      caseSensitive: true
    });
    debouncedSettingsChange({
      id: userAbbreviations?.id,
      abbreviations: newAbbreviations
    });
    setCurrentText("");
    setAbbreviationText("");
  }, [userAbbreviations, currentText, abbreviationText]);

  const handleDeleteAbbreviation = useCallback(
    (toRemove: IScreenshotAbbreviation) => {
      const modifiedAbbreviations = userAbbreviations.abbreviations.filter(
        (x) =>
          x.type === "user" &&
          x.current !== toRemove.current &&
          x.abbreviation !== toRemove.abbreviation
      );

      debouncedSettingsChange({
        id: userAbbreviations?.id,
        abbreviations: modifiedAbbreviations
      });
    },
    [userAbbreviations]
  );

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingAbbreviations(dispatch, {
      id: mutation.data.id,
      abbreviations: [...DEFAULT_USER_ABBREVIATIONS, ...mutation.data.abbreviations]
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update user abbreviations.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  return (
    <Wrapper>
      <SectionContainer>
        <InfoTitle level={5} type="secondary">
          Abbreviations
          <Popover content={<Info>{abbreviationTooltipText}</Info>} placement="right">
            <InfoIconWrapper />
          </Popover>
        </InfoTitle>

        <AbbreviationWrapper>
          <AbbreviationEntry>
            <AbbreviationType></AbbreviationType>
            <AbbreviationCurrent>
              <label>Text</label>
            </AbbreviationCurrent>
            <AbbreviationText>
              <label>Abbreviation</label>
            </AbbreviationText>
            <AbbreviationActions></AbbreviationActions>
          </AbbreviationEntry>
          {userAbbreviations?.abbreviations &&
            userAbbreviations.abbreviations.map((abbreviation, i) => {
              const isDisabled = abbreviation.type !== "user";
              return (
                <AbbreviationEntry key={i}>
                  <AbbreviationType>
                    {abbreviation.type === "system" && (
                      <Icon path={mdiLaptop} size={1.5} />
                    )}
                    {abbreviation.type === "org" && <Icon path={mdiDomain} size={1.5} />}
                    {abbreviation.type === "user" && (
                      <Icon path={mdiAccount} size={1.5} />
                    )}
                  </AbbreviationType>
                  <AbbreviationCurrent>
                    <Input value={abbreviation.current} disabled={true} />
                  </AbbreviationCurrent>
                  <AbbreviationText>
                    <Input value={abbreviation.abbreviation} disabled={true} />
                  </AbbreviationText>
                  <AbbreviationActions>
                    {!isDisabled && (
                      <Popconfirm
                        onConfirm={() => handleDeleteAbbreviation(abbreviation)}
                        title={`Please confirm you wish to delete the abbreviation for ${abbreviation.current}.`}>
                        <DeleteButton
                          shape="circle"
                          type="text"
                          icon={<Icon path={mdiDelete} size={1.5} />}
                        />
                      </Popconfirm>
                    )}
                  </AbbreviationActions>
                </AbbreviationEntry>
              );
            })}
          <AbbreviationEntry>
            <AbbreviationType>
              <Icon path={mdiAccount} size={1.5} />
            </AbbreviationType>
            <AbbreviationCurrent>
              <Input
                placeholder="Text to replace"
                onChange={(e) => setCurrentText(e.target.value)}
                value={currentText}
              />
            </AbbreviationCurrent>
            <AbbreviationText>
              <Input
                placeholder="Abbreviated text"
                onChange={(e) => setAbbreviationText(e.target.value)}
                value={abbreviationText}
              />
            </AbbreviationText>
            <AbbreviationActions>
              <Button type="text" onClick={handleAddAbbreviation}>
                + Add
              </Button>
            </AbbreviationActions>
          </AbbreviationEntry>
        </AbbreviationWrapper>
      </SectionContainer>

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </Wrapper>
  );
};

export default ScreenshotAbbreviations;
const SectionContainer = styled.div``;

const Wrapper = styled.div`
  ${SectionContainer}:not(:last-child) {
    padding-bottom: 18px;
  }
`;

const ErrorContainer = styled.div`
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;

const AbbreviationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  gap: 12px;

  label {
    font-weight: 500;
  }
`;
const AbbreviationActions = styled.div`
  opacity: 0;
`;
const AbbreviationEntry = styled.div`
  width: 500px;
  display: grid;
  align-items: center;
  vertical-align: middle;
  gap: 12px;
  grid-template-columns: 24px 260px 260px 100px;

  .ant-input {
    min-height: 40px;
    font-size: 16px;
  }
  &:hover ${AbbreviationActions} {
    opacity: 1;
  }
`;
const AbbreviationCurrent = styled.div``;
const AbbreviationText = styled.div``;
const AbbreviationType = styled.div`
  color: #a2aaad;
`;

const DeleteButton = styled(Button)`
  color: #a2aaad;

  &:hover {
    color: #ff4d4f;
  }
`;
