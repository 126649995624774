// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";

import { mdiChartMultiline } from "@mdi/js";
import styled from "styled-components";

import { useChartSettings } from "components/multiphase-chart/context";
import { changeSettings } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

import ToolbarButton from "../../../toolbar/ToolbarButton";

const BackfitToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const toggle = (value) => {
    const next = { ...chartSettings.settings, backfit: value };
    changeSettings(chartSettingsDispatch, next);
  };

  return (
    <ToolbarButton
      testId="multiphasechart-toolbar-backfit-toggle"
      icon={<FlippedIcon path={mdiChartMultiline} size={1.4} />}
      overflowLabel="Backfit"
      tooltipText="Toggle Backfit"
      active={chartSettings.settings?.backfit}
      onToggle={toggle}
    />
  );
};
const FlippedIcon = styled(Icon)`
  transform: scaleX(-1);
`;
export default memo(BackfitToggle);
