import { useCallback, useEffect, useState } from "react";

import styled from "styled-components";

import { BaseButton, BaseInput } from "components/base";

export interface DataTableSaveColumnSetInputModel {
  selectedColumnSetName: string;
  onSave: (columnSetName: string) => void;
  isLoading: boolean;
  error: string;
  visible: boolean;
}

function DataTableSaveColumnSet(input: DataTableSaveColumnSetInputModel): JSX.Element {
  const [columnSetName, setColumnSetName] = useState(input.selectedColumnSetName);

  useEffect(() => {
    setColumnSetName(input.selectedColumnSetName);
  }, [input.selectedColumnSetName]);

  const onKeyUp = useCallback(
    (key) => {
      if (key.code !== "Enter") {
        return;
      }
      input.onSave && input.onSave(columnSetName);
    },
    [columnSetName]
  );

  return (
    <AddContainer>
      <FlexRow>
        <BaseInput
          autoFocus
          value={columnSetName}
          onChange={setColumnSetName}
          keypress={onKeyUp}
          placeholder="Enter a column set name"></BaseInput>
        <BaseButton
          appearance="default"
          isDisabled={input.isLoading}
          isLoading={input.isLoading}
          onClick={() => {
            input.onSave && input.onSave(columnSetName);
          }}>
          Save
        </BaseButton>
      </FlexRow>

      {input.error !== "" && <ErrorContainer>{input.error}</ErrorContainer>}
    </AddContainer>
  );
}

export default DataTableSaveColumnSet;

const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  .input {
    height: 40px;
  }
  .button {
    height: 40px;
    border-radius: 4px;
    width: 100px;
    left: -5px;
  }
`;

const FlexRow = styled.div`
  display: inline-flex;
`;

const ErrorContainer = styled.div`
  display: inline-flex;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;
