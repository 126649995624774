import { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import styled from "styled-components";

import RangeInput from "components/base/RangeInput";
import { useUpdateLayerZoom } from "components/project/layers/hooks";

export interface IZoomSliderProps {
  defaultMin?: number;
  defaultMax?: number;
  layerName: string;
  isShapefileLayer: boolean;
}

export function ZoomSlider({
  defaultMin,
  defaultMax,
  layerName,
  isShapefileLayer
}: IZoomSliderProps) {
  const zoom = useSelector((state: RootState) => state.map.mapState.zoom);

  const { updateLayerZoom } = useUpdateLayerZoom();

  const [zoomRange, setZoomRange] = useState([defaultMin || 0, defaultMax || 22]);

  const onZoomChange = (minAndMax: [number, number]) => {
    setZoomRange([...minAndMax]);
    updateLayerZoom({
      minAndMax,
      layerName: layerName
    });

    if (isShapefileLayer) {
      updateLayerZoom({
        minAndMax,
        layerName: `${layerName}-stroke`
      });
      updateLayerZoom({
        minAndMax,
        layerName: `${layerName}-point`
      });
      updateLayerZoom({
        minAndMax,
        layerName: `${layerName}-property`
      });
    }
  };

  return (
    <>
      <LabelWrapper>
        <label>
          Zoom Level <SubText>{`Current: ${zoom.toFixed(2)}`}</SubText>
        </label>
      </LabelWrapper>

      <RangeWrapper>
        <RangeInput
          values={zoomRange}
          onChange={onZoomChange}
          step={1}
          min={0}
          max={22}
        />
      </RangeWrapper>
    </>
  );
}

const SubText = styled.div`
  font-size: 0.8em;
  line-height: 0.8em;
  color: #a2aaad;
`;

const LabelWrapper = styled.div`
  width: 100%;
`;

const RangeWrapper = styled.div`
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
`;
