import { memo, useEffect, useRef, useState } from "react";

import VisibilityIcon from "@material-ui/icons/Visibility";

import { BaseInput } from ".";
import "./BaseInput.scss";
import "./PasswordInput.scss";

function PasswordInput({ value, iconSize = 24, ...props }) {
  const [currentType, setCurrentType] = useState("password");
  const [topOffset, setTopOffset] = useState(0);
  const inputRef = useRef(null);
  useEffect(() => {
    const ref = inputRef.current;
    if (!ref) return;
    const top = ref.offsetTop + (ref.clientHeight - iconSize) / 2;
    setTopOffset(top);
  }, [inputRef]);

  function handleChangeType() {
    setCurrentType(currentType === "password" ? "text" : "password");
  }
  return (
    <div className="password-container">
      <BaseInput type={currentType} value={value} innerRef={inputRef} {...props} />
      <VisibilityIcon
        className={`${currentType === "text" ? "not-visible" : ""}`}
        onClick={handleChangeType}
        style={{ top: topOffset, fontSize: iconSize }}
      />
    </div>
  );
}

export default memo(PasswordInput);
