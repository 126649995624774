import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext();

function ThemeProvider(props) {
  const [theme, setTheme] = useState("light");
  const value = [theme, setTheme];
  return <ThemeContext.Provider value={value} {...props}></ThemeContext.Provider>;
}

function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error(`useTheme must be used within ThemeProvider`);
  }
  return context;
}

export { ThemeProvider, useTheme };
