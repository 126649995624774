import { useEffect, useRef, useState } from "react";

import { Error } from "@material-ui/icons";

import { ChevronDown, IconSpinner } from "../icons";
import { BaseMenu, DropdownList } from "./";
import "./BaseDropdown.scss";

export default function BaseDropdown({
  className = "",
  errors = null,
  getOptionLabel = (option) => {
    if (
      option &&
      typeof option == "object" &&
      Object.prototype.hasOwnProperty.call(option, labelKey)
    ) {
      return option[labelKey];
    }

    return option;
  },
  isLoading = false,
  inputLabel = "",
  inlineLabel = "",
  itemTemplate = null,
  labelKey = "label",
  options = [],
  selectedTemplate = null,
  showErrors = true,
  useTriggerWidth = false,
  value = null,
  onChange = null,
  testid = ""
}) {
  const [isOpen] = useState<boolean>(false);
  const [dropdownWidth, setDropdownWidth] = useState<string>("");
  const triggerRef = useRef(null);

  useEffect(() => {
    if (triggerRef.current && useTriggerWidth)
      setDropdownWidth(triggerRef.current.offsetWidth);
  }, [triggerRef.current]);

  const trigger = (
    <>
      {!!inputLabel && <label className="label">{inputLabel}</label>}
      <div
        aria-haspopup="true"
        className={`BaseDropdown ${className} inline-flex items-center
          ${isOpen ? "isOpen" : ""}
          ${errors ? "has-errors" : ""}`}
        role="button"
        tabIndex={0}
        data-testid={testid}
        ref={triggerRef}>
        {inlineLabel && <div className="inline-label capitalize">{inlineLabel}</div>}
        <div className="selected-option">
          {(selectedTemplate && selectedTemplate(value)) || getOptionLabel(value)}
        </div>
        <div className="flex items-center justify-center ml-auto">
          {isLoading && <IconSpinner size={14} className="icon spinner" />}
          {!isLoading && <ChevronDown size={20} />}
        </div>
      </div>
    </>
  );

  function select(value) {
    onChange && onChange(value);
  }

  return (
    <>
      <BaseMenu hasBlockLabel={true} trigger={trigger}>
        {({ closeMenu }) => {
          return (
            <DropdownList
              getOptionLabel={getOptionLabel}
              items={options}
              itemTemplate={itemTemplate}
              labelKey={labelKey}
              onClose={() => {
                closeMenu();
              }}
              onSelect={(option) => {
                select(option);
                closeMenu();
              }}
              triggerWidth={dropdownWidth}
              value={value}
            />
          );
        }}
      </BaseMenu>
      {showErrors && errors && (
        <div className="error-message">
          {errors && (
            <div className="flex-row items-center">
              {" "}
              <Error style={{ marginRight: "5px" }} /> {errors}
            </div>
          )}
        </div>
      )}
    </>
  );
}
