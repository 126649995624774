import { RateDto } from "arps_wasm";
import { ChartTypeLabels } from "constants/chart.constants";
import { getChartTypeAlias } from "utils/chart";
import { OptionSeries, ProductRates } from "wasm/arps";

import { IProductType } from "models/chart";

import {
  McfToBcf,
  bblMcfToMbblMmcf
} from "components/multiphase-chart/constants/shared.constants";
import { IChartPreset } from "components/multiphase-chart/models/shared.models";

export function getForecastSeries(
  product: string,
  x,
  y: number,
  chartType: ChartTypeLabels,
  rateCum: RateDto[], // this is forecast data, not regular cum
  isBackfitSeries: boolean
) {
  const isGas = product.toUpperCase() === "GAS";
  if (chartType == "Rate Cum") {
    return rateCum.map((rc) => {
      return [rc.cum * bblMcfToMbblMmcf + x, rc.rate, undefined];
    });
  }
  const totalLength = rateCum.length;
  if (chartType == "Rate Time") {
    return rateCum
      .map((rc, i) => {
        const month = isBackfitSeries ? x - totalLength + i : i + 1 + x;
        if (month <= 0) {
          return undefined;
        }
        return [month, rc.rate, undefined];
      })
      .filter((item) => !!item);
  }
  if (chartType == "Cum Time") {
    return rateCum
      .map((rc, i) => {
        //backfit months starts at 0 going forward to x and forecast starts at x going forward
        const month = isBackfitSeries ? x - totalLength + i : i + 1 + x;
        if (month <= 0) {
          return undefined;
        }
        //cum time gas is in bcf instead of mmcf
        const cum = isGas ? rc.cum * McfToBcf : rc.cum * bblMcfToMbblMmcf;
        return [month, cum + y, undefined];
      })
      .filter((item) => !!item);
  }
  if (chartType == "Rate Date") {
    return rateCum.map((rc) => {
      const date = new Date(rc.year, rc.month - 1, rc.day);
      //gas is in MMcf/d instead of Mcf/d
      const rate = isGas ? rc.rate * 0.001 : rc.rate;
      return [date.toJSON(), rate, undefined];
    });
  }
}

export function addForecastSeriesNonPden(
  series: ProductRates,
  nonPdenProducts: OptionSeries,
  productType: IProductType,
  preset: IChartPreset
) {
  // added indexOf check here because "Total Fluid" key does not match product name
  // from series.product (Total)
  if (series.product !== productType.key && productType.key.indexOf(series.product) < 0) {
    return;
  }

  return {
    name: productType.label + " Forecast (MCD)",
    type: "line",
    showSymbol: false,
    xAxisIndex: nonPdenProducts.xAxisIndex,
    yAxisIndex: nonPdenProducts.yAxisIndex,
    itemStyle: {
      type: "dashed",
      color: nonPdenProducts.itemStyle.color ?? "#000000",
      width: nonPdenProducts.itemStyle.width ?? 2
    },
    lineStyle: {
      type: "dashed",
      color: nonPdenProducts.lineStyle.color ?? "#000000",
      originalColor: nonPdenProducts.lineStyle.originalColor ?? "#000000",
      width: nonPdenProducts.lineStyle.width ?? 2,
      originalWidth: nonPdenProducts.lineStyle.originalWidth ?? 2
    },
    // Forecast series should start from the end of production series
    data: getForecastSeries(
      series.product,
      0,
      0,
      getChartTypeAlias(preset.chartType) as ChartTypeLabels,
      series.rate_cum,
      false
    )
  };
}

export function addForecastSeries(
  ser: OptionSeries,
  preset: IChartPreset,
  series: ProductRates,
  productType: IProductType,
  isBackfitSeries: boolean
) {
  //added indexOf check here because "Total Fluid" key does not match product name
  //from series.product (Total)
  if (series.product !== productType.key && productType.key.indexOf(series.product) < 0) {
    return;
  }
  // Custom forecast colours will need to be applied here if required in future
  // see getCurrentSeriesStyleByProductLabel usage
  return {
    name: ser.name + " Forecast (MCD)",
    type: "line",
    showSymbol: false,
    xAxisIndex: ser.xAxisIndex,
    yAxisIndex: ser.yAxisIndex,
    itemStyle: {
      type: "dashed",
      color: ser.itemStyle.color ?? "#000000",
      width: ser.itemStyle.width ?? 2
    },
    lineStyle: {
      type: "dashed",
      color: ser.lineStyle.color ?? "#000000",
      originalColor: ser.lineStyle.originalColor ?? "#000000",
      width: ser.lineStyle.width ?? 2,
      originalWidth: ser.lineStyle.originalWidth ?? 2
    },
    // Forecast series should start from the end of production series
    data: getForecastSeries(
      series.product,
      ser.data[ser.data.length - 1][0],
      ser.data[ser.data.length - 1][1],
      getChartTypeAlias(preset.chartType) as ChartTypeLabels,
      series.rate_cum,
      isBackfitSeries
    )
  };
}

export function populateNonPdenForecastData(options, seriesData, preset) {
  const forecastNames = [];
  const nonForecastNames = [];
  const forecasts = [];

  options.forEach((item) => {
    if (item.name.includes("Forecast")) {
      forecastNames.push(item);
    } else {
      nonForecastNames.push(item);
    }
  });

  const nonPdenProducts = forecastNames.filter((forecastObj) => {
    return !nonForecastNames.some((nonForecastObj) => {
      return nonForecastObj.name === forecastObj.name.replace(" Forecast (MCD)", "");
    });
  });

  nonPdenProducts.forEach((a) => (a.name = a.name.replace(" Forecast (MCD)", "")));
  for (let i = 0; i < nonPdenProducts.length; i++) {
    const seriesProduct = seriesData.find((a) => a.product === nonPdenProducts[i].name);

    const productType = {
      id: 2,
      key: seriesProduct.product,
      label: seriesProduct.product,
      icon: null
    };

    const fcst = addForecastSeriesNonPden(
      seriesProduct,
      nonPdenProducts[i],
      productType,
      preset
    );

    forecasts.push(fcst);
  }

  return forecasts;
}
