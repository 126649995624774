import { useEffect, useState } from "react";

import { LegendItemModel } from "models";

import { useChartState } from "../context";
import { createLegendItem, verifyChartTypeCanUseStats } from "../utils";

const useP90LegendItem = (): LegendItemModel[] => {
  const { settings } = useChartState();
  const [items, setItems] = useState<LegendItemModel[]>([]);

  const isP90Enabled = Boolean(settings.showP90);
  const canChartShowsStats =
    verifyChartTypeCanUseStats(settings.chartType) &&
    settings.chartType !== "Material Balance Time"; // at this time material balance does not support the p90 stat.

  useEffect(() => {
    if (!isP90Enabled || !canChartShowsStats) {
      setItems([]);
      return;
    }

    const color = settings?.p90Settings ? settings.p90Settings.color : "#000";
    const p90 = createLegendItem("P90", true, color);

    setItems([p90]);
  }, [isP90Enabled, canChartShowsStats, settings?.p90Settings]);

  return items;
};

export default useP90LegendItem;
