import { useDispatch, useSelector } from "react-redux";

import { TYPE_WELLS } from "constants/settings.constants";
import { ICheckedForecast } from "store/features";
import {
  setCheckedForecasts,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setIsTypeWellSavable,
  setSelectedTypeWell
} from "store/features/arps/arpsSlice";
import { RootState } from "store/rootReducer";

import { UserArpsItem } from "models/UserArpsModel";

import "../context/reducer/arpsReducer";
import useForecastFolderUpdater from "./useTypeWellFolderUpdater";
import { Command, IUndoRedoManager } from "./useUndoRedo";

export default function useTypeWellUpdater(undoRedoManager: IUndoRedoManager) {
  const dispatch = useDispatch();

  const checkedForecasts = useSelector((state: RootState) => state.arps.checkedTypeWells);
  const filteredTypeWellFolders = useSelector(
    (state: RootState) => state.arps.filteredTypeWellFolders
  );

  const checkedTypeWells = checkedForecasts
    .map((forecast) =>
      filteredTypeWellFolders
        .flatMap((folder) => folder.children)
        .find((child) => child.id === forecast.id)
    )
    .filter(Boolean) as ICheckedForecast[];

  const { updateTypeWellDataWithParentNode } = useForecastFolderUpdater();

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  const checkedTypeWellKeys = useSelector(
    (state: RootState) => state.arps.checkedTypeWellKeys
  );

  const checkedGlobalTypeWells = useSelector(
    (state: RootState) => state.arps.checkedGlobalTypeWells
  );

  const changeSelectedTypeWell = (typeWellToBeSelected: UserArpsItem) => {
    // Update well data of selected well without erasing in progress changes of selectedTypeWell.
    if (selectedTypeWell?.id !== typeWellToBeSelected?.id) {
      dispatch(setSelectedTypeWell(typeWellToBeSelected));
    } else if (selectedTypeWell?.wellData !== typeWellToBeSelected.wellData) {
      const selectedTypeWellWithUpdatedWellData = {
        ...selectedTypeWell,
        wellData: typeWellToBeSelected?.wellData
      };
      dispatch(setSelectedTypeWell(selectedTypeWellWithUpdatedWellData));
    } else {
      dispatch(setSelectedTypeWell(typeWellToBeSelected));
    }

    dispatch(setIsTypeWellSavable(true));

    if (
      typeWellToBeSelected &&
      !typeWellToBeSelected.isFolder &&
      !checkedTypeWellKeys.includes(typeWellToBeSelected.key)
    ) {
      const res: ICheckedForecast = {
        id: typeWellToBeSelected.key,
        type: "forecast",
        folderId: typeWellToBeSelected.folderId,
        children: typeWellToBeSelected.children,
        title: typeWellToBeSelected.title,
        reserveCategory: typeWellToBeSelected.reserveCategory,
        color: typeWellToBeSelected.color,
        thickness: typeWellToBeSelected.thickness,
        uniqueID: typeWellToBeSelected.uniqueID,
        arps: typeWellToBeSelected.arps,
        constants: typeWellToBeSelected.constants,
        isFolder: false,
        folderName: undefined,
        wellData: typeWellToBeSelected.wellData
      };

      dispatch(
        setCheckedKeys({
          type: TYPE_WELLS,
          checkedKeys: [...checkedTypeWellKeys, res.id]
        })
      );
      dispatch(setCheckedGlobalTypeWells([...checkedGlobalTypeWells, res]));
    }
  };

  const onTypeWellValueChange = (typeWell: UserArpsItem) => {
    //When editing values the type well type will reset to undefined, so it must be manually set
    //eslint-disable-next-line
    const { isChecked, key, ...rest } = typeWell;
    const updatedTypeWell: ICheckedForecast = {
      ...rest,
      title: typeWell.title ?? selectedTypeWell.title,
      id: typeWell.key,
      type: typeWell.isFolder ? "folder" : "forecast",
      isFolder: false,
      folderName: undefined
    };
    const idx = checkedTypeWells.findIndex((f) => f.id === updatedTypeWell.id);
    const undoFcst = [...checkedTypeWells];
    undoRedoManager.executeCommand(
      new Command(
        () => {
          const selectedForecastTypeWells = [...checkedTypeWells];
          if (idx >= 0) {
            selectedForecastTypeWells.splice(idx, 1, updatedTypeWell);
          } else {
            selectedForecastTypeWells.push(updatedTypeWell);
          }
          dispatch(setIsTypeWellSavable(true));
          dispatch(setCheckedGlobalTypeWells(selectedForecastTypeWells));
          dispatch(
            setCheckedForecasts({
              type: TYPE_WELLS,
              checkedForecasts: selectedForecastTypeWells
            })
          );
          dispatch(
            setCheckedKeys({
              type: TYPE_WELLS,
              checkedKeys: selectedForecastTypeWells.map((x) => x.id)
            })
          );
          dispatch(
            setSelectedTypeWell({
              ...selectedTypeWell,
              title: typeWell.title
            })
          );
          changeSelectedTypeWell(typeWell);
          updateTypeWellDataWithParentNode(typeWell);
        },
        () => {
          const oldVal = idx >= 0 ? undoFcst[idx] : null;
          dispatch(setIsTypeWellSavable(true));
          dispatch(setCheckedGlobalTypeWells(undoFcst));

          dispatch(
            setCheckedForecasts({
              type: TYPE_WELLS,
              checkedForecasts: undoFcst
            })
          );
          dispatch(
            setCheckedKeys({ type: TYPE_WELLS, checkedKeys: undoFcst.map((x) => x.id) })
          );
          changeSelectedTypeWell(oldVal as UserArpsItem);
          dispatch(
            setSelectedTypeWell({
              ...selectedTypeWell,
              title: oldVal.title
            })
          );
          updateTypeWellDataWithParentNode(oldVal as UserArpsItem);
        }
      )
    );
  };

  return { onTypeWellValueChange, changeSelectedTypeWell };
}
