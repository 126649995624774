// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";

import { mdiCheck, mdiContentCopy } from "@mdi/js";
import { Alert, Button, DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";
import styled from "styled-components";

import { usePostApiToken } from "../hooks/usePostApiToken";

export const CreateAccessTokenModal = ({
  requestShowModal,
  onClose
}: CreateAccessTokenModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(requestShowModal);
  const [showToken, setShowToken] = useState(false);
  const [newToken, setNewToken] = useState<string>(undefined);

  const [copied, setCopied] = useState(false);
  const [form] = Form.useForm();
  const { mutateAsync, isError, error } = usePostApiToken();
  useEffect(() => {
    const initialExpiryDate = moment().add(1, "years");
    form.resetFields();
    setCopied(false);
    setNewToken(undefined);
    setShowToken(false);
    form.setFieldsValue({ expiresAt: initialExpiryDate });
    setIsModalVisible(requestShowModal);
  }, [requestShowModal]);

  const handleOk = (e) => {
    e.preventDefault();
    form.validateFields().then(async (values) => {
      await mutateAsync(values).then((result) => {
        form.resetFields();
        setNewToken(result);
        setShowToken(true);
      });
    });
  };

  function disabledDate(current) {
    const minDate = moment();
    return current && current < minDate.endOf("day");
  }

  const handleCloseTokenModal = () => {
    setShowToken(false);
    setNewToken(null);
    onClose && onClose();
  };
  return (
    <RootContainer>
      <Modal
        open={isModalVisible}
        onOk={(e) => (showToken ? handleCloseTokenModal() : handleOk(e))}
        onCancel={onClose}
        title={"Create New Access Token"}
        closable={false}
        maskClosable={false}>
        {!showToken && (
          <Form form={form} layout="vertical">
            <FormItemWrapper
              label="Token Name"
              name="name"
              rules={[
                { required: true, message: "Please input the name of the token!" }
              ]}>
              <Input placeholder="Enter token name" />
            </FormItemWrapper>
            <FormItemWrapper label="Expiry Date" name="expiresAt">
              <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
            </FormItemWrapper>
          </Form>
        )}

        {isError && (
          <ErrorMessage
            type="error"
            message={`${error?.response?.data ?? "Error occurred while creating token"}`}
          />
        )}
        {showToken && (
          <TokenInformation>
            <p>Your new access token is:</p>
            <p>
              <strong>{newToken}</strong>
              <Button
                type="primary"
                icon={
                  !copied ? (
                    <Icon path={mdiContentCopy} size={1} />
                  ) : (
                    <Icon path={mdiCheck} size={1} />
                  )
                }
                onClick={() =>
                  navigator.clipboard.writeText(newToken).then(() => setCopied(true))
                }>
                {copied ? "Copied" : "Copy Token"}
              </Button>
            </p>

            <p style={{ color: "red" }}>
              Warning: This token will not be visible after the modal is closed. Make sure
              to save it somewhere secure.
            </p>
          </TokenInformation>
        )}
      </Modal>
    </RootContainer>
  );
};

const RootContainer = styled.div``;

const FormItemWrapper = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 1em;
`;

const TokenInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .ant-btn {
    width: 120px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

interface CreateAccessTokenModalProps {
  requestShowModal: boolean;
  onClose: () => void;
}
