// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";

import { mdiAccount, mdiAccountMultiple, mdiAlert, mdiDomain } from "@mdi/js";
import { Select } from "antd";
import styled from "styled-components";

import { useProjectListQuery } from "components/project/projects/queries";
import { DEFAULT_PROJECT_SETTING } from "components/user-settings/constants/userSetting.constants";
import { DefaultProject as DefaultProjectModel } from "components/user-settings/models";
import { useUserContext } from "components/user/context";
import { updateSettingProject } from "components/user/context/reducer";
import { useUserDefaults, useUserSettingMutation } from "components/user/hooks";

import {
  ErrorContainer,
  SectionContainer,
  SectionHeading,
  SectionInputWrapper,
  StyledSelect
} from "./shared";

const { Option } = Select;

const DefaultProject = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const defaults = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultProjectModel>(DEFAULT_PROJECT_SETTING);

  // state
  const [currentDefaultProject, setCurrentDefaultProject] = useState(undefined);
  const [updateError, setUpdateError] = useState("");

  const projectListQuery = useProjectListQuery({});

  useEffect(() => {
    if (!projectListQuery.isLoading && !currentDefaultProject) {
      setCurrentDefaultProject(
        projectListQuery.data?.find((project) => {
          return project.projectId === defaults?.project?.projectId;
        })?.projectId || projectListQuery.data?.[0]?.projectId
      );
    }
  }, [projectListQuery.isLoading]);

  const handleDefaultProjectChange = (projectId: string) => {
    mutation.mutate({
      id: defaults?.project?.id,
      projectId: projectId
    });
    setCurrentDefaultProject(projectId);
  };

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingProject(dispatch, {
      id: mutation.data.id,
      projectId: mutation.data.projectId
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update default project.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  // I would base this off the user default query, but I want to base this off the context since
  // everything else is, and I want to verify the data is flowing correctly, and the default is being applied
  // const userSettingsDefaults = useUserSettingsDefaultsQuery();

  const currentValue =
    currentDefaultProject ||
    // userSettingsDefaults.data?.Project.DefaultProject.projectId ||
    defaults?.project?.projectId ||
    undefined;

  return (
    <SectionContainer>
      <SectionHeading>Project</SectionHeading>
      <SectionInputWrapper>
        <StyledSelect
          data-testid="default-project-dropdown"
          loading={projectListQuery.isLoading}
          value={!projectListQuery.isLoading && currentValue ? currentValue : ""}
          onChange={handleDefaultProjectChange}>
          {projectListQuery.data?.map((project) => {
            return (
              <Option key={project.projectId}>
                <ProjectNameWrapper>
                  {project.projectType === "Organization" && <Icon path={mdiDomain} />}
                  {project.projectType === "Shared" && <Icon path={mdiAccountMultiple} />}
                  {project.projectType === "Personal" && <Icon path={mdiAccount} />}
                  {project.projectName}
                </ProjectNameWrapper>
              </Option>
            );
          })}
        </StyledSelect>
      </SectionInputWrapper>

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </SectionContainer>
  );
};

export default DefaultProject;

const ProjectNameWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  height: 100%;

  svg {
    color: #a2aaad;
    width: 18px;
    height: 18px;
  }
`;
