import { useMutation } from "react-query";

import axios from "axios";

export interface IUseUpdateProjectLinkedShapefileMutationParams {
  projectId: string;
  onMutate?: () => void;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  onSettled?: () => void;
}

export type PropertyT = {
  key: string;
  value: string;
};

export type UpdateProjectLinkedShapefileParamsT = {
  body: {
    key: string;
    value: string;
  };
  projectId: string;
  projectShapefileId: string;
};

async function updateProjectLinkedShapefile(params: UpdateProjectLinkedShapefileParamsT) {
  const url = `${process.env.REACT_APP_PROJECT_SERVICE}/projectShapefile/update/${params.projectShapefileId}`;
  const body = params.body;
  return await axios.put(url, body);
}

/**
 * Linked refers to System or organization shapefiles.
 * @param param0
 * @returns
 */
export function useUpdateProjectLinkedShapefileMutation({
  onMutate,
  onSuccess,
  onError,
  onSettled
}: IUseUpdateProjectLinkedShapefileMutationParams) {
  return useMutation(
    (params: UpdateProjectLinkedShapefileParamsT) =>
      updateProjectLinkedShapefile({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
