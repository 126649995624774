import { removeCount, removeForecast } from "utils";

import { IChartResult } from "models/model";

import { ChartDataTableColumn, ChartDataTableRow } from "../hooks/useDataTableData";
import prepareBoxPlotData from "./prepareBoxPlotData";

// NOTE: Changing this logic should result in changes to the chart-svc backend export logic.
const boxPlotDataTable = (
  responseData: IChartResult
): { columns: ChartDataTableColumn[]; rows: ChartDataTableRow[] } => {
  const columns = [
    { key: "Min", name: "Min", resizable: false },
    { key: "10th", name: "10th", resizable: false },
    { key: "Q1", name: "Q1", resizable: false },
    { key: "Median", name: "Median", resizable: false },
    { key: "Mean", name: "Mean", resizable: false },
    { key: "Q3", name: "Q3", resizable: false },
    { key: "90th", name: "90th", resizable: false },
    { key: "Max", name: "Max", resizable: false }
  ];
  const rows = responseData.series?.reduce((list, series) => {
    if (series.label && series.type === "box") {
      const label = removeForecast(removeCount(series.label));
      const data = prepareBoxPlotData([series.y], {
        layout: "vertical"
      }).boxData[0];
      const row = {
        SeriesFull: label,
        Series: label,
        SeriesColor: series.style.hexColor,
        Min: data ? data[0].toFixed(2) : "n/a",
        "10th": data ? data[6].toFixed(2) : "n/a",
        Q1: data ? data[1].toFixed(2) : "n/a",
        Median: data ? data[2].toFixed(2) : "n/a",
        Mean: data ? data[5].toFixed(2) : "n/a",
        Q3: data ? data[3].toFixed(2) : "n/a",
        "90th": data ? data[7].toFixed(2) : "n/a",
        Max: data ? data[4].toFixed(2) : "n/a"
      };

      list.push(row);
    }
    return list;
  }, []);
  return {
    columns,
    rows
  };
};

export default boxPlotDataTable;
