import { memo } from "react";

function Probit() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <g id="Iconschart-typeprobit" transform="translate(1.125 1.125)">
        <path
          d="M0 16.9074L8.41152 0"
          transform="translate(5.638672 0)"
          id="Path-4"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0 16.9074L8.41152 0"
          transform="translate(12.367676 0)"
          id="Path-4-Copy"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0 0L0 20L20 20"
          transform="translate(0 1.3092041)"
          id="Path-3-Copy-4"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
      </g>
    </svg>
  );
}

export default memo(Probit);
