import { useContext } from "react";

import { ScreenshotSettingContext } from "../contexts/ScreenshotSettingContext";

export function useScreenshotSettingContext() {
  const context = useContext(ScreenshotSettingContext);
  if (context === undefined) {
    throw new Error(
      "useScreenshotSettingContext must be within a screenshot setting provider"
    );
  }
  return context;
}
