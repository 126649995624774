export default class View3DControl {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _map: any;
  _container: HTMLDivElement;
  button: HTMLButtonElement;

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this.button = document.createElement("button");
    this._container.appendChild(this.button);
    this.button.setAttribute("type", "button");
    const layerIcon = "3D";

    this.button.innerHTML = layerIcon;

    this.button.onclick = () => {
      if (map.getPitch() === 0) {
        map.easeTo({ pitch: 65 });
        this.button.innerHTML = "2D";
      } else {
        map.easeTo({ pitch: 0 });
        this.button.innerHTML = "3D";
      }
    };

    this._container.title =
      "The 3D perspective can be also adjusted by holding the right click and dragging.";

    return this._container;
  }

  onRemove() {
    if (this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    this._map = undefined;
  }
}
