import { removeCount, removeForecast } from "utils";

import { IChartResult } from "models/model";

import { ChartDataTableColumn, ChartDataTableRow } from "../hooks/useDataTableData";

// NOTE: Changing this logic should result in changes to the chart-svc backend export logic.
const probitDataTable = (
  responseData: IChartResult
): { columns: ChartDataTableColumn[]; rows: ChartDataTableRow[] } => {
  const columns = [
    { key: "P90", name: "P90", resizable: false },
    { key: "P50", name: "P50", resizable: false },
    { key: "Mean", name: "Mean", resizable: false },
    { key: "Swanson's Mean", name: "Swanson's Mean", resizable: false },
    { key: "P10", name: "P10", resizable: false },
    { key: "P10/P90", name: "P10/P90", resizable: false }
  ];

  const rows = responseData.series?.reduce((list, series) => {
    if (series.label) {
      const section = series.label.split(" Fit - ");
      const label = removeForecast(removeCount(section[0]));
      const data = section[1];
      if (data) {
        const kvp = data.split(",").map((label) => label.split(":"));
        const row = {
          SeriesFull: section[0],
          Series: label,
          SeriesColor: series.style.hexColor
        };
        kvp.forEach((v) => {
          row[v[0]] = v[1];
        });

        list.push(row);
      }
    }
    return list;
  }, []);

  return {
    columns,
    rows
  };
};

export default probitDataTable;
