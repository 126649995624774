import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";
import { ForecastActivityType } from "constants/settings.constants";

import { FOLDERS, RESTORE_FORECAST } from "api/userArps";

import { useSelectedProject } from "components/project/projects/hooks";

export interface RestoreForecastRequest {
  forecastId: string;
  folderId: string;
  restoreDate: string;
}

export const useRestoreForecast = (forecastType: ForecastActivityType) => {
  const { selectedProject: project } = useSelectedProject();
  const [restoreForecastMutation, { loading, error }] = useMutation(RESTORE_FORECAST);

  const restoreForecast = async (RestoreForecastRequest: RestoreForecastRequest) => {
    const input = {
      forecastId: RestoreForecastRequest.forecastId,
      folderId: RestoreForecastRequest.folderId,
      restoreDate: RestoreForecastRequest.restoreDate
    };
    await restoreForecastMutation({
      variables: {
        input: input
      },
      refetchQueries: [
        {
          query: FOLDERS,
          variables: { req: { projectId: project?.projectId, type: forecastType } }
        }
      ],
      onError: (err) => {
        toast.error(err.message);
      }
    });
  };

  return { restore: restoreForecast, loading, error };
};
