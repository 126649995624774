import { EChartOption } from "echarts";

import {
  HOVER_LINE_WIDTH,
  UNFOCUSED_OPACITY
} from "components/multiphase-chart/constants/shared.constants";
import { IMPCSeriesLine } from "components/multiphase-chart/models/options.models";

const getOptionsHighlighted = (
  options: EChartOption,
  traceToHighlight: string
): EChartOption => {
  if (!options?.series) return { ...options };

  const setFocusedStyle = (trace: IMPCSeriesLine) => {
    trace.lineStyle.color = trace.lineStyle.originalColor;
    trace.lineStyle.width = HOVER_LINE_WIDTH;
    trace.lineStyle.opacity = 1.0;
    trace.z = 3;
  };

  const setUnfocusedStyle = (trace: IMPCSeriesLine) => {
    trace.lineStyle.color = undefined;
    trace.lineStyle.width = trace.lineStyle.originalWidth;
    trace.lineStyle.opacity = UNFOCUSED_OPACITY;
    trace.z = 2;
  };

  const updatedSeries: EChartOption.Series[] = options.series?.map(
    (trace: IMPCSeriesLine) => {
      const isSeries = trace.name && trace.name.indexOf(traceToHighlight) >= 0;
      isSeries ? setFocusedStyle(trace) : setUnfocusedStyle(trace);
      return trace;
    }
  );

  const newOptions: EChartOption = { ...options, series: updatedSeries };
  return newOptions;
};

export default getOptionsHighlighted;
