import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";

export enum MapKind {
  Native,
  LSD
}

const mapMap = async (data: { id: number; kind: MapKind }) => {
  const response = await axios.post(
    `${GEO_MAP_SERVICE_ADDRESS}/map-layer/${data.id}/${
      data.kind == MapKind.Native ? "make-map" : "make-lsd-map"
    }`
  );
  return response.data;
};

const useMakeMap = () => {
  return useMutation<unknown, AxiosError, unknown, unknown>(mapMap);
};

export default useMakeMap;
