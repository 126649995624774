import { FunctionComponent, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components/macro";

import { TagT, tagMap } from "./constants";

type DocumentationTagT = {
  value: TagT;
  defaultSelected?: boolean;
  selected?: boolean;
  selectable?: boolean;
  showLabel?: boolean;
  onSelectionChange?: (b: boolean) => void;
};

const DocumentationTag: FunctionComponent<DocumentationTagT> = ({
  value,
  defaultSelected = false,
  selected = false,
  selectable = false,
  showLabel = true,
  onSelectionChange = null
}) => {
  const [isSelected, setIsSelected] = useState(defaultSelected);

  // sync selected prop with local state
  useEffect(() => setIsSelected(selected), [selected]);

  if (!value) return null;

  const handleClick = () => () => {
    if (!selectable) return;

    const nextValue = !isSelected;
    setIsSelected(nextValue);
    if (onSelectionChange) onSelectionChange(nextValue);
  };

  // derived state
  const { icon, label, color, bgColor, borderColor } = tagMap[value];
  const IconComponent = icon;
  let tagColor = isSelected ? "white" : color;
  let tagBgColor = isSelected ? color : bgColor;
  let tagBorderColor = isSelected ? color : borderColor;

  tagColor = showLabel ? tagColor : color;
  tagBgColor = showLabel ? tagBgColor : borderColor;
  tagBorderColor = showLabel ? tagBorderColor : borderColor;

  return (
    <StyledTag
      color={tagColor}
      bgColor={tagBgColor}
      borderColor={tagBorderColor}
      showLabel={showLabel}
      onClick={handleClick()}>
      <IconComponent />
      {showLabel ? label : null}
      {selectable && <CloseIcon />}
    </StyledTag>
  );
};

export default DocumentationTag;

const StyledTag = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  border-color: ${(props) => props.borderColor};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 2px;
  padding: ${({ showLabel }) => (showLabel ? "4px 8px" : "3px")};
`;
