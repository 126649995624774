import { useCallback } from "react";

import * as _isEqual from "lodash/isEqual";

import "./DropdownList.scss";

export default function DropdownList({
  items = [],
  triggerWidth = "100%",
  value = null,
  onSelect = null,
  onClose = null,
  itemTemplate = null,
  labelKey = "label",
  getOptionLabel = (option) => {
    if (
      option &&
      typeof option == "object" &&
      Object.prototype.hasOwnProperty.call(option, labelKey)
    ) {
      return option[labelKey];
    }
    return option;
  }
}) {
  function shouldShowList() {
    return items ? items.length > 0 : false;
  }
  const optionSelected = useCallback(
    (option) => {
      return _isEqual(option, value);
    },
    [value]
  );
  function select(option) {
    if (optionSelected(option)) {
      onClose && onClose();
      return;
    }

    onSelect && onSelect(option);
    onClose && onClose();
  }
  return (
    <div className="DropdownList" role="listbox" style={{ width: triggerWidth }}>
      {shouldShowList() && (
        <ul role="listbox" className="list">
          {items.map((option, index) => {
            return (
              <li
                key={index}
                tabIndex={0}
                className={`option ${optionSelected(option) ? "selected" : ""}`}
                onMouseDown={() => select(option)}>
                <span className="option-label">
                  {itemTemplate
                    ? itemTemplate({ value: option })
                    : getOptionLabel(option)}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
