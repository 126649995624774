import { ISeriesStyle } from "components/multiphase-chart/models/shared.models";

import { getDefaultSeriesStyleByProductLabel } from "..";
import getPerformanceTypeFromLabel from "../getPerformanceTypeFromLabel/getPerformanceTypeFromlabel";

const getCurrentSeriesStyleByProductLabel = (
  label: string,
  currentStyles: ISeriesStyle[]
) => {
  const defaultProductStyle = getDefaultSeriesStyleByProductLabel(
    label,
    getPerformanceTypeFromLabel(label)
  );

  const currentStyle =
    currentStyles?.find((item) => item.product === defaultProductStyle.product) ||
    defaultProductStyle;

  return currentStyle;
};

export default getCurrentSeriesStyleByProductLabel;
