import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { useProjectContext } from "components/project/projects/context";

import { useSetProjectLayerOrderMutation } from "../../mutations";
import {
  IProjectLayer,
  PROJECT_LAYER_LIST_QUERY_KEYS,
  useProjectLayerListQuery
} from "../../queries";
import { createEditableLayerList, createReorderedLayerList } from "./utils";

export interface IEditableProjectLayer extends IProjectLayer {
  isChecked: boolean;
  dependantLayer?: string;
  isMoving: boolean;
  zoomLevel: number;
}

export type EditableProjectLayerListReturnT = {
  data: IEditableProjectLayer[];
  checkedShapefiles: Set<string>;
  checkedProjectShapefileIds: string[];
  checkedProjectLinkedShapefileIds: string[];
  error: string;
  isLoadingLayers: boolean;
  isReorderingLayers: boolean;
  reorderLayerList: (params: {
    layerId: string;
    listOrder: number;
    order: number;
  }) => void;
};

export type LayerListFilterTypesT = "dependantLayers" | "unselectedLayers";

export type EditableProjectLayerListParamsT = {
  projectId: string;
  isAutoFetching?: boolean;
  filters?: LayerListFilterTypesT[];
};

export function useEditableProjectLayerList({
  projectId,
  isAutoFetching,
  filters
}: EditableProjectLayerListParamsT): EditableProjectLayerListReturnT {
  const queryClient = useQueryClient();

  // Layer list edge case:
  // Each row uses this hook, and as each row is added to the virtual list on scroll,
  // it will create a new query for each new row rendered and auto refetch, which just isn't needed.
  const nextIsAutoFetching =
    isAutoFetching === false
      ? !queryClient.getQueryData([
          PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList,
          projectId
        ])
      : undefined;

  // Queries
  const layersQuery = useProjectLayerListQuery({
    projectId: projectId,
    isAutoRefetching: nextIsAutoFetching
  });

  // Mutations
  const setLayerOrderMutation = useSetProjectLayerOrderMutation({
    projectId: projectId
  });

  // Context
  const projectContext = useProjectContext();

  // State
  const [layerList, setLayerList] = useState<IEditableProjectLayer[]>([]);

  const [checkedShapefiles, setCheckedShapefiles] = useState<Set<string>>(
    new Set<string>()
  );

  const [checkedProjectShapefileIds, setCheckedProjectShapefileIds] = useState<string[]>(
    []
  );

  const [checkedProjectLinkedShapefileIds, setCheckedProjectLinkedShapefileIds] =
    useState<string[]>([]);

  // Order 0 is the top of the list
  const reorderLayerList = ({ layerId, listOrder, order }) => {
    const layerBeingRepositioned = layerList.find((layer) => layer.order === listOrder);

    if (layerBeingRepositioned?.layerId === layerId) {
      return;
    }

    const reorderedLayerList = createReorderedLayerList({
      layerList: layerList,
      layerId: layerId,
      order: listOrder
    });

    if (JSON.stringify(reorderedLayerList) === JSON.stringify(layerList)) {
      // eslint-disable-next-line no-console
      console.error(`The same list was returned, no change possible`);
      return;
    }

    // Setting layer list to avoid waiting for server response, and avoid flicker
    // from the list displaying the old order before the new order is fetched
    setLayerList(reorderedLayerList);
    setLayerOrderMutation.mutate({ projectId, layerId: layerId, order: order });
  };

  // Sync server data with local state
  useEffect(() => {
    const {
      list,
      checkedProjectLinkedShapefileIds,
      checkedProjectShapefileIds,
      checkedShapefiles
    } = createEditableLayerList({
      layerList: layersQuery.data,
      checkedKeys: projectContext.checkedLayerKeys,
      filters: filters
      // other state, cache, global, etc
    });

    // TODO BF: just make this a single state object
    setLayerList(list);
    setCheckedShapefiles(checkedShapefiles);
    setCheckedProjectShapefileIds(checkedProjectShapefileIds);
    setCheckedProjectLinkedShapefileIds(checkedProjectLinkedShapefileIds);
  }, [
    layersQuery.data,
    projectContext.checkedLayerKeys,
    projectContext.isHidingUnselectedLayers
  ]);

  return {
    data: layerList,
    checkedShapefiles,
    checkedProjectShapefileIds,
    checkedProjectLinkedShapefileIds,
    reorderLayerList: reorderLayerList,
    isLoadingLayers: layersQuery.isLoading,
    isReorderingLayers: setLayerOrderMutation.isLoading,
    error: null
  };
}
