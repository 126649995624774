import { Form } from "antd";

import { ScreenshotSettingProvider } from "../contexts/ScreenshotSettingContext";
import { useScreenshotCalculatedDefaults } from "../hooks";

interface InputWihUnitModel {
  label: string;
  name: string;
  valuePropName?: string;
  children?: React.ReactNode;
}

export default function ScreenshotSettingItem({
  name,
  valuePropName,
  label,
  children
}: InputWihUnitModel) {
  const defaultSettings = useScreenshotCalculatedDefaults();

  const state = {
    name,
    calculatedValue: defaultSettings[name]
  };

  return (
    <ScreenshotSettingProvider state={state}>
      <Form.Item label={label} name={name} valuePropName={valuePropName}>
        {children}
      </Form.Item>
    </ScreenshotSettingProvider>
  );
}
