import { useDispatch } from "react-redux";

import { setHasViewItemsChanged } from "store/features";

import { useUpdateMapLayersVisibility } from "components/map/hooks";
import {
  setCheckedLayersToSession,
  setCheckedTreeKeysToSession
} from "components/project/layers/session";
import {
  useProjectContext,
  useProjectDispatch
} from "components/project/projects/context";

interface IHandleCheckedLayersChangeParams {
  checkedLayerKeys: string[];
  checkedTreeKeys: string[];
}

/**
 * Handles updating dependencies when a user checks/unchecks a layer in the layer tree/list
 *
 * Possibly redundant but have separated checked state for the List and Tree to make it easier to handle synchronicity between the two.
 * It may be possible with just the tree state, and then filter out the layers for the list as a calculated value. Leaving for now.
 *
 * Without having folders in the "checked state" for the tree, there can be edge cases like:
 * An empty child folder in the tree will stop you from being able to check/uncheck the parent folder,
 * not because it can't add the parent folder to the state, but because it has no children to calculate the state from I suppose
 *
 * Otherwise, if checking from the layer list, you'll be passing the tree it's most child layers, and it'll automatically calculate the state
 * of the tree.
 */
export function useCheckedLayersChangeHandler() {
  const dispatch = useDispatch();
  const { selectedProjectId, selectedViewId } = useProjectContext();
  const projectDispatch = useProjectDispatch();

  const { updateMapLayersVisibility } = useUpdateMapLayersVisibility();

  function handleCheckedLayersChange({
    checkedLayerKeys,
    checkedTreeKeys
  }: IHandleCheckedLayersChangeParams) {
    projectDispatch({
      payload: {
        checkedLayerKeys,
        checkedTreeKeys
      }
    });

    // We're only handling cache right now when a view is not selected (per project),
    // cache has not been implemented for views/type wells/forecasts/filters yet.
    if (selectedViewId) {
      dispatch(setHasViewItemsChanged(true));
    } else {
      setCheckedLayersToSession({
        checkedLayerKeys,
        projectId: selectedProjectId
      });

      setCheckedTreeKeysToSession({
        checkedTreeKeys,
        projectId: selectedProjectId
      });
    }

    updateMapLayersVisibility(checkedLayerKeys);
  }

  return { handleCheckedLayersChange };
}
