import { useContext } from "react";

import { GeomBinContext } from "../contexts/GeomBinContext";

export function useGeomBinContext() {
  const context = useContext(GeomBinContext);
  if (context === undefined) {
    throw new Error("useGeomBinContext must be within a Geom Bin provider");
  }
  return context;
}
