import {
  NormalizeBySettings,
  NormalizeByToggle,
  NormalizeByWrapper
} from "components/filter";

import { useChartDispatch, useChartState } from "../../context";

const Normalize = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const { normalizeBy, useNormalizeBy } = settings;

  const updateChartSettings = (key) => (value) => {
    const nextSettings = { ...settings, [key]: value };
    dispatch({ type: "settings", payload: nextSettings });
  };

  return (
    <NormalizeByWrapper>
      <NormalizeByToggle
        checked={useNormalizeBy}
        onToggle={updateChartSettings("useNormalizeBy")}
      />

      {useNormalizeBy && (
        <NormalizeBySettings
          value={normalizeBy}
          onChange={updateChartSettings("normalizeBy")}
        />
      )}
    </NormalizeByWrapper>
  );
};

export default Normalize;
