import axios from "axios";

import {
  IAuthenticationAppSetupModel,
  IMemberProfile,
  IOrganization,
  IOrganizationLogoUpdatePayload,
  IOrganizationProfile,
  IProfilePictureUpdatePayload,
  ISmsValidationModel,
  IUser
} from "models/User";
import { ScreenshotPresetModel } from "models/model";

const userServiceRoot = process.env.REACT_APP_USER_SERVICE;

export interface MemberAccountModel {
  firstName: string;
  lastName: string;
  password: string;
  inviteToken: string;
}

export async function postAdminAccount(payload, onSuccess, onError) {
  axios
    .post(`${userServiceRoot}/user/admin-account`, payload)
    .then(onSuccess)
    .catch(onError);
}

async function getUserById(id) {
  const response = await axios.get(`${userServiceRoot}/user/${id}`);
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function getOrganizationById(id) {
  const response = await axios.get(`${userServiceRoot}/Organization/${id}`);
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function getOrganizationLogo(orgId) {
  const response = await axios.get(`${userServiceRoot}/Organization/logo/${orgId}.png`, {
    responseType: "blob"
  });
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function enableOrganizationMfa(organizationId) {
  const response = await axios.post(
    `${userServiceRoot}/Organization/enable-mfa/${organizationId}`
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function disableOrganizationMfa(organizationId) {
  const response = await axios.post(
    `${userServiceRoot}/Organization/disable-mfa/${organizationId}`
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function updateOrganizationUserDefinedField(
  organizationId: string,
  enable: boolean
): Promise<void> {
  return axios.post(
    `${userServiceRoot}/organization/user-defined-field/${organizationId}/${enable}`
  );
}

export async function getUsersInOrg(orgId: string) {
  const response = await axios.get(`${userServiceRoot}/organization/users/${orgId}`);
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export function getOrgUserList() {
  return axios.get(`${userServiceRoot}/user/organization/userlist`);
}

export async function postMemberInvite(payload, onSuccess, onError) {
  axios
    .post(`${userServiceRoot}/user/member-invite`, payload)
    .then(onSuccess)
    .catch(onError);
}

export async function postMemberResendInvite(payload, onSuccess, onError) {
  axios
    .post(`${userServiceRoot}/user/member-invite-resend`, payload)
    .then(onSuccess)
    .catch(onError);
}

export function postMemberAccount(payload: MemberAccountModel) {
  return axios.post(`${userServiceRoot}/user/member-account`, payload);
}

export async function updateMemberProfile(payload: IMemberProfile): Promise<IUser> {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_USER_SERVICE}/user/profile`,
      payload
    );
    if (response.status !== 200) {
      return null;
    }
    const user = await getUserById(payload.id);
    return user;
  } catch (error) {
    return null;
  }
}

export async function updateMemberPassword(payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_USER_SERVICE}/user/password`,
      payload
    );
    if (response.status !== 200) {
      return null;
    }
    return "Success";
  } catch (error) {
    return null;
  }
}

export async function getDefaultUnit() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/user/setting/DefaultUnit`
    );
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function postDefaultUnit(payload) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SERVICE}/user/setting/DefaultUnit`,
      payload
    );
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function editDefaultUnit(payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_USER_SERVICE}/user/setting/DefaultUnit/${payload.id}`,
      payload
    );
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getScreenshotPreset(): Promise<ScreenshotPresetModel[]> {
  const response = await axios.get<ScreenshotPresetModel[]>(
    `${process.env.REACT_APP_USER_SERVICE}/user/setting/ScreenshotPreset`
  );
  if (response.status !== 200) {
    return [];
  }
  return response.data;
}

export async function addScreenshotPreset(payload: ScreenshotPresetModel) {
  const response = await axios.post(
    `${process.env.REACT_APP_USER_SERVICE}/user/setting/ScreenshotPreset`,
    payload
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function editScreenshotPreset(payload: ScreenshotPresetModel) {
  const response = await axios.put(
    `${process.env.REACT_APP_USER_SERVICE}/user/setting/ScreenshotPreset/${payload.id}`,
    payload
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function deleteScreenshotPreset(presetId: string) {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_USER_SERVICE}/user/setting/ScreenshotPreset/${presetId}`
    );
    if (response.status !== 200) {
      return null;
    }
    const presets = await getScreenshotPreset();
    return presets;
  } catch (error) {
    return null;
  }
}

export async function updateProfilePicture(
  payload: IProfilePictureUpdatePayload
): Promise<IUser> {
  try {
    const response = await axios.post(
      `${userServiceRoot}/user/profile-picture`,
      payload.data
    );
    if (response.status !== 200) {
      return null;
    }
    const user = await getUserById(payload.userId);
    return user;
  } catch (error) {
    return null;
  }
}

export async function deleteUserProfilePicture(userId): Promise<IUser> {
  try {
    const response = await axios.delete(`${userServiceRoot}/user/profile-picture`);
    if (response.status !== 200) {
      return null;
    }
    const user = await getUserById(userId);
    return user;
  } catch (error) {
    return null;
  }
}

export async function updateOrganizationLogo(payload: IOrganizationLogoUpdatePayload) {
  try {
    const response = await axios.post(
      `${userServiceRoot}/Organization/${payload.organizationId}/logo`,
      payload.data
    );
    if (response.status !== 200) {
      return null;
    }
    const organization = await getOrganizationById(payload.organizationId);
    return organization;
  } catch (error) {
    return null;
  }
}

export async function updateOrganizationProfile(
  organizationId,
  payload: IOrganizationProfile
): Promise<IOrganization> {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_USER_SERVICE}/Organization/${organizationId}`,
      payload
    );
    if (response.status !== 200) {
      return null;
    }
    const organization = await getOrganizationById(payload.id);
    return organization;
  } catch (error) {
    return null;
  }
}

export async function getUserOrgScreenshots(): Promise<ScreenshotPresetModel[]> {
  const response = await axios.get<ScreenshotPresetModel[]>(
    `${process.env.REACT_APP_USER_SERVICE}/user/Organization/ScreenshotPresets`
  );
  if (response.status !== 200) {
    return [];
  }
  return response.data;
}
export async function getOrganizationScreenshots(organizationId) {
  const response = await axios.get(
    `${process.env.REACT_APP_USER_SERVICE}/Organization/${organizationId}/ScreenshotPresets`
  );
  if (response.status !== 200) {
    throw response.data;
  } else {
    return response.data;
  }
}

export async function addOrganizationScreenshot(
  organizationId: string,
  payload: ScreenshotPresetModel
) {
  const response = await axios.post(
    `${process.env.REACT_APP_USER_SERVICE}/Organization/${organizationId}/ScreenshotPresets`,
    payload
  );
  if (response.status !== 200) {
    throw new Error(response?.data);
  }
  return response.data;
}

export async function editOrganizationScreenshot(
  organizationId: string,
  presetId: string,
  payload: ScreenshotPresetModel
) {
  const response = await axios.put(
    `${process.env.REACT_APP_USER_SERVICE}/Organization/${organizationId}/ScreenshotPresets/${presetId}`,
    payload
  );
  if (response.status !== 200) {
    throw new response.data();
  }
  return response.data;
}

export async function deleteOrganizationScreenshot(organizationId, presetId) {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_USER_SERVICE}/Organization/${organizationId}/ScreenshotPresets/${presetId}`
    );
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function submitFeedback(payload) {
  try {
    const recipients = payload.To.split(";");
    for (const recipient of recipients) {
      payload.To = recipient;
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE}/user/email-feedback`,
        payload
      );
      if (response.status !== 200) {
        return null;
      }
    }
  } catch (error) {
    return null;
  }
}

export async function requestPhoneNumberCode(phoneNumber: string) {
  return await axios.post(
    `${process.env.REACT_APP_USER_SERVICE}/user/send-phone-number-verification`,
    { phoneNumber: phoneNumber }
  );
}

export async function resendPhoneNumberCode(payload: ISmsValidationModel) {
  return await axios.post(
    `${process.env.REACT_APP_USER_SERVICE}/user/resend-phone-number-verification`,
    payload
  );
}

export async function verifyPhoneNumberCode(payload: ISmsValidationModel) {
  return await axios.post(
    `${process.env.REACT_APP_USER_SERVICE}/user/verify-phone-number`,
    payload
  );
}

export async function getAuthenticationAppUri() {
  const response = await axios.get(
    `${process.env.REACT_APP_USER_SERVICE}/user/get-authentication-app-uri`
  );
  if (response.status === 200) {
    return response.data;
  }
  throw response.data;
}

export async function setupAuthenticationApp(payload: IAuthenticationAppSetupModel) {
  return await axios.post(
    `${process.env.REACT_APP_USER_SERVICE}/user/setup-authentication-app`,
    payload
  );
}
