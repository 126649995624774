import { useMutation } from "react-query";

import axios, { CancelTokenSource } from "axios";

import { VIS_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { ThreeDViewerOptionT } from "../../../models";

let get3DSceneCancellationSource: CancelTokenSource = null;
export default function useGet3DScene() {
  return useMutation(
    "get3DScene",
    (params: { selectedWells; options: ThreeDViewerOptionT }) => {
      if (get3DSceneCancellationSource != null) {
        get3DSceneCancellationSource.cancel();
      }
      get3DSceneCancellationSource = axios.CancelToken.source();
      return axios.post(
        `${VIS_SERVICE_ADDRESS}/3d/scene`,
        {
          data: params.selectedWells,
          options: params.options
        },
        {
          cancelToken: get3DSceneCancellationSource.token
        }
      );
    }
  );
}
