import { useSelector } from "react-redux";

import {
  GDC_SOURCE,
  IHS_SOURCE,
  SHALE_PROFILE_SOURCE
} from "constants/datasource.constants";
import { RootState } from "store/rootReducer";

export default function useDataSouceAvailability() {
  const dataSources = useSelector((state: RootState) => state.auth.dataSources);
  return {
    hasGdc: dataSources ? dataSources.indexOf(GDC_SOURCE) >= 0 : false,
    hasIhs: dataSources ? dataSources.indexOf(IHS_SOURCE) >= 0 : false,
    hasShaleProfile: dataSources ? dataSources.indexOf(SHALE_PROFILE_SOURCE) >= 0 : false
  };
}
