import { useQuery } from "react-query";

import axios from "axios";
import { IS_DEV_ENV, SAM_SERVICE_URI } from "constants/app.constants";

import { getXAxisMaxValue } from "../util/getXAxisMaxValue";

const useFourProductRateTimeQuery = (wellList) => {
  const {
    data: chartData,
    refetch: updateData,
    isFetching
  } = useQuery(
    "four-product",
    async () => {
      if (IS_DEV_ENV) {
        return devResponse;
      }
      const response = await axios.post(
        `${SAM_SERVICE_URI}/SamChart/four-product-rate-time`,
        {
          Ooip: 0,
          PoreVolume: 0,
          ShowForecast: true,
          WellPairUwis: wellList
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  const xAxisMaxValue = getXAxisMaxValue(chartData?.series);
  return { chartData, updateData, isFetching, xAxisMaxValue };
};

const devResponse = {
  title: "",
  series: [
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        0, 0, 0, 0, 1061.5665998810446, 2694.9759026471284, 3862.015626617624,
        4472.615526608353, 5085.735281281148, 4245.421936945441, 2569.926982406353,
        1124.982491611198, 954.4396199106413, 2416.1779542488043, 2041.742705514652,
        3174.306414058678, 3268.8009646361, 4180.196337549557, 5766.887729106148,
        2404.086170368972, 3062.110628657731, 5197.638076059825, 7014.642087933726,
        6591.294321598146, 6392.192645732387, 6691.8381473790205, 9710.171375831183,
        12193.532147431799, 10337.25724238309, 8396.127187896114, 11247.571662596602,
        12562.960166131774, 11973.971876960179, 12822.813752018967, 13118.46226612345,
        12559.229103103606, 14674.931302833891, 13586.93600151029, 11995.909634384245,
        12035.479608055686, 11373.977154851296, 11635.494484748782, 15933.932982962564,
        16169.08942168523, 16776.142308184186, 9559.582992469534, 7942.25175134637,
        12541.324872467883, 13393.430243534076, 15237.982140428445, 18915.717492081472,
        16793.275154733557, 13521.551050401831, 14094.293234730569, 13984.767194252783,
        16940.301638243873, 18548.539343633343, 12809.66165334676, 17002.868330826335,
        16808.96673101634, 12199.211293604349, 15045.420312978946, 14576.007383634342,
        14838.039791239109, 14544.325150572144, 18168.99952353983, 18151.01264460914,
        18631.762213412207, 20729.14065782823, 22317.685062823824, 21200.00974379899,
        27364.72866564763, 26523.48289388123, 24167.321328161, 23316.19575021501,
        27953.25412436741, 28187.592489966933, 29220.538130298373, 31957.245022035204,
        28946.237912445205, 26099.882059433123, 28359.931197952716, 28346.781805891344,
        26104.043473583148, 18567.239317852876, 25295.734448998322, 26027.667652549666,
        25658.345868324734, 27321.632654718334, 26163.012307056113, 24577.575864609913,
        26606.968304910544, 27612.119661971374, 26156.126109118373, 27552.154699048577,
        28158.23929552334, 23066.977114928784, 35205.44191173335, 37776.24651270113,
        40493.03622866262, 43241.78222203276, 45297.49001278105, 49430.53584804547,
        55954.124976401734, 54409.860724573264, 59672.05755878591, 67565.71481930328,
        77001.08196767901, 76138.67388249113, 81083.42924885459, 78399.93287605143,
        87079.78344406757, 91860.43631919876, 95651.96382498628, 95467.83429857845,
        100168.35525349781, 79331.00700132558, 108521.17550814923, 112028.29355630389,
        119014.02904058086, 118367.08409372182, 114181.8964274767, 125169.36211272632,
        119983.2325459126, 115759.78287567932, 132615.92365849492, 137620.4424902714,
        134792.56602269626, 134518.58528158566, 137253.0782623288, 140750.13042480935,
        134635.59206773562, 134066.41075661284, 134528.3493801523, 142685.16770499028,
        145252.86173346243, 143608.3723592782, 149859.53414781057, 147201.9869230098,
        150808.61284165038, 118134.22489094055, 146943.15440605787, 152345.29181662982,
        161235.67610800194, 166217.96404673505, 167419.6796320093, 177405.03010089567,
        173714.68126612547, 179944.7648395013, 181466.67274254144, 185939.3732707295,
        189377.6351393871, 28643.784978715896, 132718.16332095736, 183389.0556837812,
        189665.4629015004, 198418.28708073762, 202235.3695843254, 199187.50655507308,
        200463.419139663, 201081.56168738232, 203067.05502665482, 200831.53174550016,
        150754.68294422422, 98848.34385866621, 132534.09686824837, 198145.93774930405,
        212181.49449576845, 217775.3655108762, 217089.161850591, 213863.7383844106,
        219596.8706165592, 213293.8912470237, 219308.7731600098, 217037.82420968637,
        211250.2254945149, 214475.2659752631, 218545.67574937598, 220647.5566409065,
        225072.49995432596, 228144.1067270782, 222115.43965847982, 222760.66117089332,
        208519.24704793358, 191480.38755960474, 209415.7519530372, 215122.98501281912,
        160654.68923709434, 186182.13596253507, 213685.9438256457, 216515.962575692,
        204580.60050221157, 222313.57372097418, 218013.0769902216, 206306.12715792994,
        226593.64907301965, 220024.29859871982, 215282.38712435376, 202350.1840157038,
        191260.0843244603, 195599.72067776264, 208017.9976087095, 204180.92204757835,
        183416.4601184277, 205871.77228200456, 152853.5730307885, 223629.76947118907,
        234336.09204642117, 236992.41404649828, 243886.1844530148, 247148.76142105192,
        247327.37608289803, 241405.5633032291, 216616.323705242, 241888.6121379317,
        204133.19773213085, 240971.57720302898, 246712.38132357333, 247139.30316950878,
        204125.30119503642, 233585.6571278935, 239842.40855111156, 247231.84760698382,
        246102.9933085814, 223255.03784856922, 113178.2560359113, 212506.83588397584,
        235110.46017120656, 243837.3293058964, 252357.87755698612, 258001.55224131065,
        254213.15778255495, 256539.1649970262, 261065.51776836836, 260716.4128204035,
        252723.53323684272
      ],
      label: "Bitumen",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 51.93915823333334, 100.29841122580612,
        136.36462074193517, 213.84972324999964, 206.43611351612873, 51.8208458,
        138.42554699999968, 95.47813369999967, 198.53589619354807, 169.22494496774164,
        198.173325833333, 176.55268277419322, 229.052870933333, 219.14515877419322,
        220.5191096129029, 265.69592171428536, 276.507606290322, 290.220398966666,
        297.0023729677413, 281.11034159999934, 28.165992193548387, 0, 0, 0, 0, 0, 0, 0, 0,
        9.9382444, 40.8750374516129, 29.223171033333333, 32.28784470967742,
        81.40658719354839, 94.7682591, 27.82250448387097, 0, 0, 0, 0, 10.533623096774194,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15.6172412, 85.52843970967741, 121.7434939,
        292.53703274193487, 311.6578485806445, 358.74019964285645, 414.131681967741,
        492.53465996666563, 503.5529823870959, 536.5468851666657, 603.0499222903212,
        600.6455083225794, 597.5961007666652, 512.941646451612, 524.1240796666657,
        489.813473999999, 505.4994127419345, 541.532909142856, 556.6790814838697,
        583.9901709333319, 182.73546154838678, 551.217626899999, 543.7410444193539,
        737.6971044838696, 883.2023148333319, 928.7907669677403, 778.850748633332,
        827.5763885161277, 929.4777423870954, 888.5671717241365, 922.0355086774177,
        897.873056566665, 141.86042409677387, 565.6517437666657, 931.309676838708,
        922.0355086774177, 937.0344719999987, 747.7727439677407, 803.8146720666653,
        490.61494532258007, 359.5171361290316, 382.9519940357136, 738.4985758064504,
        598.7792250999987, 685.8304603225794, 689.9981111999987, 694.0741653548374,
        8341.599029516114, 15618.424324333302, 14982.361416612875, 17055.09220229997,
        16591.14335283868, 11288.952570354815, 15883.190648357117, 12768.926615451588,
        12217.770053033311, 10322.378155322562, 5778.970806166658, 2181.2614523548364,
        1374.1798305161276, 1163.7210942666652, 2196.9473910967713, 3516.245518666661,
        4827.0327840967675, 4615.215363129025, 6534.26892967856, 1227.7395702903216,
        1041.1494133333326, 342.1137588387097, 813.1613542999992, 2601.0034335806417,
        4403.283446258057, 6219.684620333323, 5938.4445167096665, 18185.21256549996,
        6141.560249032248, 1998.8694785161272, 2560.933815586206, 2434.182902580645,
        3517.665267866666, 4869.62526009677, 6189.869887133329, 8303.357397838698,
        8846.983946354829, 8354.277542533322, 12240.528022064493, 7701.902785133324,
        6029.583255677412, 5443.593222967738, 6224.079082142853, 5915.888823774185,
        5425.334942933326, 5227.997437193545, 5132.7482952999935, 5976.915140193542,
        11374.023026451598, 10069.807825866652, 10569.231322677408, 11155.087776833321,
        6764.87594619354, 8098.4097310645075, 12079.403662249983, 7112.828996096768,
        3792.2684256333323, 3266.224631322577, 1061.6174642999993, 1529.4362752903226,
        1799.4176150967746, 3806.3476051999983, 2774.808214677418, 3309.6720100666653,
        2573.7534086129026, 2619.093786290321, 1961.6623991071424, 2308.9243844516104,
        3816.1675371666656
      ],
      label: "GasInjection",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        0, 0, 0, 0, 10.150435032029831, 5.550400579733485, 4.067248003952671,
        3.0874014529389417, 2.696101127990612, 2.674201549468359, 4.6506872037914695,
        2.118785975928833, 4.5975912617305115, 4.259388704988826, 4.2951015040683815,
        3.7934489969496012, 3.9613669672355116, 4.3180642698538145, 3.5589515961547273,
        2.8570020068056894, 5.376125319862642, 2.7756823400743382, 3.134221370776372,
        4.198530340220696, 4.654020706515495, 4.772604002984948, 3.7086039925428755,
        3.2709433824055787, 3.0135399177197177, 4.144545804959527, 3.6794175559802853,
        3.388792046938292, 3.677179277221999, 3.761437063840785, 3.372740594482262,
        3.909687179768997, 3.5108262173419593, 3.408642489926358, 4.110724084206329,
        4.242242698092235, 3.627271526630092, 4.30840668393472, 3.1082453540975794,
        3.3422211670811564, 3.451769543142294, 3.719783909034656, 4.979488922740332,
        4.438555741876169, 3.671892937408477, 3.335447042915854, 2.6924877657138353,
        2.735421965206123, 3.6413556523044583, 3.5876832122182534, 3.718339891917628,
        3.0841597582946894, 2.6095311576597275, 2.8440365169689272, 3.2647024762505117,
        3.7540157841663193, 4.179219117806363, 3.7347555011070344, 3.158593833197579,
        3.2980150570556517, 3.9105939560408887, 3.802135260370152, 3.378308283668305,
        3.3519879941276605, 3.493758574798685, 3.8172819988890456, 4.04925135753387,
        3.321823323274314, 3.128604915639478, 2.781729695858914, 2.8470248382218393,
        2.7503768964645814, 2.907380201356782, 2.929999492370951, 2.599763955735983,
        2.8355198796481615, 3.531865431056943, 3.2616784134354746, 3.1693327661695396,
        3.4212902623761603, 3.354595122215745, 3.223396443844538, 3.2974532102638823,
        3.3293435676400978, 3.106775954903742, 3.0583093810077666, 3.4233466557312333,
        3.4275744737211387, 3.596239903369355, 3.9276726003876687, 3.8421368666823343,
        4.1234941973882835, 3.799541135902342, 3.92993517823391, 3.946741280921223,
        3.812616178691098, 3.789402402894403, 3.6875747610162453, 3.57508710286269,
        3.265554164740707, 3.2849477529749915, 3.1827238706021554, 2.8767956294095502,
        2.743504686632913, 3.0061362200630954, 3.021409043796973, 3.564766318132686,
        3.2915677968768677, 3.207916799072225, 3.0382707767712294, 3.1750800455714336,
        3.1888252236601224, 3.1852445693616094, 3.1923742589682504, 2.963269936719355,
        3.011621590136317, 2.9300288822406286, 2.9030731540808272, 2.7651063757049155,
        2.702685976070892, 2.6678520386422253, 2.5617906902531717, 2.511341148691225,
        2.571352073758249, 2.642878743887577, 2.546470371218288, 2.5099172196104016,
        2.5536466864643312, 2.3519379636345885, 2.436295909447638, 2.5098047258031553,
        2.5365536895526355, 2.5462255016291024, 2.5422879826993823, 2.574570649656436,
        2.594761192678377, 2.8564975925932283, 2.659298543186576, 2.6762149378355238,
        2.7026324947046425, 2.638134079175336, 2.7675540728067882, 2.7079731076698237,
        2.6541937820988504, 2.6022984825804922, 2.5547171891539207, 2.6214302027868834,
        2.5982549123856367, 3.382682294711818, 2.5494303606106663, 2.7502669321783073,
        2.694757719392861, 2.6750317548163447, 2.742016769018421, 2.700482294084605,
        2.75907959384113, 2.8459802941740637, 2.864370011027703, 2.9072023189640976,
        2.834243921742363, 2.8200396058960755, 2.7554887407969657, 2.77580668542483,
        2.7638378777299923, 2.700669379373253, 2.6774520705858493, 2.636607763052145,
        2.568867541773502, 2.6090372364750043, 2.543289164898904, 2.638142588404004,
        2.6668922002452193, 2.735643985491648, 2.6329831242355124, 2.62894401816743,
        2.5838807945204074, 2.6575192661778604, 2.675063492328966, 2.668926310226807,
        2.7558511914218853, 2.9473845630626814, 2.823828664619286, 2.725686276175029,
        2.523387263247383, 2.840212575107204, 2.506045911230433, 2.5232515262706454,
        2.4624516400461607, 2.639541608108051, 2.706778591967697, 2.5764815715582525,
        2.5029893449295617, 2.7152339558988645, 2.659952291349748, 2.618865200771051,
        2.7223194663883596, 2.7177539498360903, 2.732633332264442, 2.740801725446668,
        2.564417275009187, 2.8227192859562726, 2.639652807584918, 2.5940569745979363,
        2.6917167768779446, 2.70806237807013, 2.4863218711005413, 2.5121300769843815,
        2.4282408086685607, 2.4962527786198367, 2.4575407558040987, 2.58481029739909,
        2.6890291695629376, 2.6410039904810643, 2.580210835170243, 2.5125459689258225,
        2.5031062664229933, 2.630172278941714, 2.6455440256228124, 2.6020922625205674,
        2.5974653414780238, 2.4053860825029107, 2.332104233361325, 2.347232319943968,
        2.65486151613253, 2.593035012302079, 2.58385452198371, 2.5170065825952936,
        2.4865677548499665, 2.517650501382453, 2.4549654452482956, 2.3520453012659073,
        2.3354844075272014
      ],
      label: "ISOR",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        0, 0, 0, 0, 41.12731618701596, 16.092044875063742, 9.854046861409001,
        7.366298597844002, 5.957453238900032, 5.314108956423239, 5.241672631933004,
        5.099219776954487, 5.0811109083552175, 5.010207795069869, 4.96307419154751,
        4.851083033043606, 4.771230533510426, 4.728664710605655, 4.581904720263893,
        4.498801741499848, 4.551116232460969, 4.392725909828328, 4.25347656047374,
        4.2483018679669895, 4.281249750166156, 4.320929079494423, 4.258563966468216,
        4.143275797067088, 4.041542181884093, 4.047925217286585, 4.01690689963507,
        3.964524520605017, 3.942714550696366, 3.9294950551235357, 3.8896456513263984,
        3.890930893665962, 3.865231271065979, 3.8374908422065546, 3.850974685665576,
        3.8700185619866168, 3.859343917151094, 3.8768778038658236, 3.8339188017126644,
        3.808335864527773, 3.789496551752356, 3.7875229029063378, 3.815806710521735,
        3.8382978594050887, 3.832310192618474, 3.812144623515978, 3.7600924252582635,
        3.718178984993844, 3.7157294690495037, 3.711868378858549, 3.712068942403711,
        3.6900564017391204, 3.6488329797351122, 3.628822932964447, 3.6168149937361003,
        3.6211467692651915, 3.6332534464752775, 3.635984173941452, 3.624239184981991,
        3.616009616454823, 3.6231181390402525, 3.627863087657165, 3.6207554136562314,
        3.6133604835342745, 3.6096932496731986, 3.6161197478589377, 3.6288934605211107,
        3.6176327858536586, 3.60137085988095, 3.576487358171466, 3.5563806667282147,
        3.5297668268130407, 3.509711421086035, 3.4927328770772252, 3.4621502466152383,
        3.443886648911118, 3.446212615099368, 3.4412212457005125, 3.4338318977144415,
        3.433525674618546, 3.432221037827265, 3.4274723180523297, 3.424593426130074,
        3.4224924095435494, 3.4152470880094246, 3.4083001335647958, 3.408598664845734,
        3.408985063176718, 3.4129866426717332, 3.42287555301473, 3.4314641951663556,
        3.44565529753801, 3.451316330253274, 3.4630940481366563, 3.4751447006061977,
        3.48420823293726, 3.4927172514590086, 3.4978995295612805, 3.500222091677785,
        3.492733750804982, 3.4862782868767304, 3.4765885598040667, 3.454993841880965,
        3.426951060364061, 3.411656621313132, 3.3966487687782596, 3.4024883414291907,
        3.3982358403444217, 3.3908379559071844, 3.3784032609133416, 3.3707764505847635,
        3.3641005509806545, 3.3588866514698013, 3.352699480468511, 3.3378522156001376,
        3.3251532204254803, 3.310884109873703, 3.2967141007243184, 3.2778149549676208,
        3.258251344125804, 3.2394909192549957, 3.2179175028716047, 3.192987000642293,
        3.1728700078547125, 3.155754239820572, 3.136925807168748, 3.117044657207589,
        3.100459567415244, 3.0798115588081387, 3.061917060529567, 3.0465959447781494,
        3.0321295797231036, 3.0188755472747335, 3.006929708502125, 2.995456851325364,
        2.985185308208704, 2.9825693612614073, 2.974848024500062, 2.9673970880639433,
        2.960585541906424, 2.952516527968525, 2.9478214559770763, 2.9417365448083856,
        2.934540012739292, 2.926144220143091, 2.9174956708246933, 2.9101326106800744,
        2.902670622769506, 2.9044589342419758, 2.8986255691336957, 2.89522476951331,
        2.8905822875255733, 2.885644361354812, 2.8822606350841244, 2.878268367665809,
        2.875606915105666, 2.8749578587955904, 2.874750425480585, 2.875431961807339,
        2.874813029861025, 2.8742609762094036, 2.8727277671165994, 2.870832689884125,
        2.868638397830385, 2.8652851628250393, 2.8615004183848027, 2.857261698643718,
        2.8516074363130004, 2.847074427288029, 2.8418921903339855, 2.8381537717299836,
        2.835244188892919, 2.8335000580746494, 2.8300962971901873, 2.826595987439846,
        2.822362867530404, 2.819590091235791, 2.8171851038967297, 2.8148287160415104,
        2.8139357496916055, 2.8157657230271753, 2.8158734839930903, 2.8145231464449445,
        2.8114065176688903, 2.811771125596408, 2.8075326661721687, 2.8034652318512516,
        2.798916537125654, 2.7967118522547945, 2.7954686029368108, 2.7927309281355037,
        2.7886774147341504, 2.787693093493293, 2.7861450177078835, 2.784132643763407,
        2.7834598628046683, 2.782712701077728, 2.7821333949080387, 2.7816540798352123,
        2.779414359178689, 2.7798939591969893, 2.778712382247665, 2.7765356141839463,
        2.775466598630312, 2.774618251957212, 2.771284575972134, 2.7679654805271534,
        2.7638034249707077, 2.7605377810583285, 2.7573599867991176, 2.7552967833671094,
        2.7546347696351616, 2.7533526011037783, 2.751310097505237, 2.7486104806911684,
        2.7462640021065914, 2.7450080182773324, 2.7440199453994105, 2.7424289228785748,
        2.740880194031279, 2.7375536102723865, 2.7355908647216145, 2.731977177710492,
        2.7311913792031235, 2.7297927317365884, 2.7282296316100743, 2.72601477251952,
        2.7234853680099205, 2.7213142756676114, 2.7187567966489925, 2.7149044805021605,
        2.7112019744958684
      ],
      label: "CSOR",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    }
  ],
  layout: null,
  requestId: ""
};

export default useFourProductRateTimeQuery;
