// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiFormatListCheckbox } from "@mdi/js";

import { ShapefileAction } from "../..";

interface IShapefileViewDetailsActionProps {
  isDisabled?: boolean;
  closePopover: () => void;
  viewShapefileDetails: () => void;
}

export function ShapefileViewDetailsAction({
  isDisabled,
  closePopover,
  viewShapefileDetails
}: IShapefileViewDetailsActionProps) {
  return (
    <ShapefileAction
      disabled={isDisabled}
      placement="bottom"
      onClick={(evt) => {
        viewShapefileDetails();
        closePopover();
        evt.stopPropagation();
        evt.preventDefault();
      }}>
      <Icon path={mdiFormatListCheckbox} size={1} />
      View Shapefile Details
    </ShapefileAction>
  );
}
