import { IStructuredNodeState, StructuredNodeDispatchT } from "../StructuredNodeContext";

type StructuredNodeReducerActionsT =
  | { type: "setAcceptChanges"; payload: boolean }
  | { type: "setCanDrop"; payload: boolean }
  | { type: "setInEditMode"; payload: boolean };

const structuredNodeReducer = (
  draft: IStructuredNodeState,
  action: StructuredNodeReducerActionsT
) => {
  switch (action.type) {
    case "setAcceptChanges": {
      draft.acceptChanges = action.payload;
      break;
    }
    case "setCanDrop": {
      draft.canDrop = action.payload;
      break;
    }
    case "setInEditMode": {
      draft.inEditMode = action.payload;
      break;
    }

    default: {
      throw new Error("invalid structured node reducer action");
    }
  }
};

// Module functions
const setAcceptChanges = (dispatch: StructuredNodeDispatchT, payload: boolean) =>
  dispatch({ type: "setAcceptChanges", payload });

const setInEditMode = (dispatch: StructuredNodeDispatchT, payload: boolean) =>
  dispatch({ type: "setInEditMode", payload });

const setCanDrop = (dispatch: StructuredNodeDispatchT, payload: boolean) =>
  dispatch({ type: "setCanDrop", payload });

export { setInEditMode, setAcceptChanges, setCanDrop, structuredNodeReducer };

export type { StructuredNodeReducerActionsT };
