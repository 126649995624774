import { ALL_CHART_TYPES, ChartTypeLabels } from "constants/chart.constants";

export const getForecastToggleDisabledStatus = (chartType: ChartTypeLabels) => {
  if (
    chartType === ALL_CHART_TYPES.Pie.label ||
    chartType === ALL_CHART_TYPES.CAGR.label
  ) {
    return true;
  }
  return false;
};
