import { useContext } from "react";

import { FilesContext } from "./FilesContext";

export function useFilesContext() {
  const context = useContext(FilesContext);
  if (context === undefined) {
    throw new Error("useProjectContext must be within a Project provider");
  }

  return context;
}
