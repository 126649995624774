import { useQuery } from "react-query";

import axios, { AxiosError } from "axios";

import { DATA_SERVICE_ADDRESS } from "../../../constants/app.constants";

const API_URL = `${DATA_SERVICE_ADDRESS}/api/v1/midstream/network-facility`;

const GetFacilityFractionKey = "network-facility";

export interface FacilityFraction {
  facilityId: string;
  gasPct: number;
}

const fetchNetworkFraction = async (wellId?: string) => {
  if (!wellId) {
    return Promise.resolve([]);
  }
  const response = await axios.get<FacilityFraction[]>(`${API_URL}/${wellId}`);
  return response.data;
};

const useGetFacilityNetworkFraction = (wellId: string) => {
  return useQuery<FacilityFraction[], AxiosError>(
    [GetFacilityFractionKey, wellId],
    () => fetchNetworkFraction(wellId),
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useGetFacilityNetworkFraction;
