import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useQuery } from "@apollo/client";
import { setFilterState, setSelectedSavedFilter } from "store/features";

import { QUERY_SAVED_FILTERS } from "api/savedFilters";

import { useSelectedProject } from "components/project/projects/hooks";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

export function useClearFilter() {
  const dispatch = useDispatch();
  const { selectedProject: project } = useSelectedProject();

  const [isClearingFilter, setIsClearingFilter] = useState(false);
  const [currentProjectId, setProjectId] = useState(project?.projectId);

  const savedFiltersQuery = useQuery(QUERY_SAVED_FILTERS, {
    variables: {
      req: {
        projectID: project?.projectId
      }
    },
    context: {
      clientName: "saved-filters"
    },
    skip: !project?.projectId
  });

  const userSettingsDefaultsQuery = useUserSettingsDefaultsQuery();

  const clearFilter = (nextProjectId) => {
    setIsClearingFilter(true);
    setProjectId(nextProjectId);
  };

  useEffect(() => {
    if (
      isClearingFilter &&
      currentProjectId === project?.projectId &&
      userSettingsDefaultsQuery.isSuccess
    ) {
      const defaultUserFilterId =
        userSettingsDefaultsQuery?.data?.Filter?.DefaultFilter?.filterId;

      const defaultUserFilter = savedFiltersQuery?.data?.savedFilters?.find(
        (f) => f?.id === defaultUserFilterId
      );

      const defaultFilter = savedFiltersQuery?.data?.savedFilters?.find(
        (f) => f?.name === "System Default"
      );

      const nextFilter = defaultUserFilter || defaultFilter;

      if (!nextFilter) return;

      dispatch(setSelectedSavedFilter(nextFilter));
      const parsedFilter = JSON.parse(nextFilter.filterJSON);
      dispatch(setFilterState(parsedFilter));
      setIsClearingFilter(false);
    }
  }, [
    savedFiltersQuery.data,
    userSettingsDefaultsQuery.isSuccess,
    userSettingsDefaultsQuery.data,
    isClearingFilter,
    currentProjectId,
    project?.projectId
  ]);

  return {
    update: clearFilter,
    isLoading: savedFiltersQuery.loading && userSettingsDefaultsQuery.isLoading
  };
}
