// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";

import { mdiCalculator } from "@mdi/js";

import {
  updateIsProbitDataFromLineOfBestFit,
  useChartDispatch,
  useChartState
} from "../context";
import { getProbitToggleEnabled } from "../utils/toggles";
import ToolbarButton from "./ToolbarButton";

const ProbitDataSourceToggle = () => {
  const dispatch = useChartDispatch();
  const { isProbitDataFromLineOfBestFit, options } = useChartState();
  const toggle = () => (value) => {
    updateIsProbitDataFromLineOfBestFit(dispatch, value);
  };
  if (options && getProbitToggleEnabled(options.chartType)) {
    return (
      <ToolbarButton
        icon={<Icon path={mdiCalculator} size={1.3} />}
        active={isProbitDataFromLineOfBestFit}
        overflowLabel="Use line of best fit for stats"
        tooltipText="Use line of best fit for stats"
        onToggle={toggle()}
      />
    );
  } else {
    return null;
  }
};

export default memo(ProbitDataSourceToggle);
