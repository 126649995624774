import { IS_DEV_ENV } from "./app.constants";

export const THEME_COLOR = "#000000";
export const THEME_FONT_FAMILY = "Bai Jamjuree";

export const SUPPORTED_FONT_FAMILIES = [
  { value: THEME_FONT_FAMILY, label: "EVA (default)" },
  { value: "Arial", label: "Arial" },
  { value: "Bell MT", label: "Bell MT" },
  { value: "Bodoni", label: "Bodoni" },
  { value: "Calibri", label: "Calibri" },
  { value: "Candara", label: "Candara" },
  { value: "Corbel", label: "Corbel" },
  { value: "Franklin Gothic", label: "Franklin Gothic" },
  { value: "Garamond", label: "Garamond" },
  { value: "Palatino", label: "Palatino" },
  { value: "Segoe UI", label: "Segoe" },
  { value: "Tahoma", label: "Tahoma" },
  { value: "Tw Cen MT", label: "Tw Cen MT" },
  { value: "Verdana", label: "Verdana" }
];

if (IS_DEV_ENV) {
  SUPPORTED_FONT_FAMILIES.push({ value: "Wingdings", label: "Wingdings" });
}
