import { CrossKindProduct } from "constants/chart.constants";
import { ProductRates } from "wasm/arps";

import { IProductType } from "models/chart";

/**
 * Util used to check whether products from different kinds show type wells on chart if they belong to the same group
 *
 * @param {ProductRates} series Series of chart
 * @param {IProductType} chartProduct Currently selected product in the chart
 * @returns {boolean} True if series product and chart product belong to the same group
 */
export function checkSeriesProductBelongsToSameGroupAsChartProduct(
  series: ProductRates,
  chartProduct: IProductType
): boolean {
  return (
    series?.product ===
    CrossKindProduct.find((group) =>
      group?.items.some((item) => item?.key === chartProduct?.key)
    )?.group
  );
}
