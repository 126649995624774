import { Ipdb3dModel } from "models/xdaData";

/**
 * Check whether all values of a field in the ipdb3d model are 0
 * @param {Ipdb3dModel[]} ipdb3d_boxes Array of ipdb3d models containing field values
 * @param {string} field Field being checked for 0 data values
 * @returns {boolean} True if all values for a field are 0
 */
function checkDataForFieldIsAllZeroes(ipdb3d_boxes: Ipdb3dModel[]): boolean {
  for (const cell of ipdb3d_boxes) {
    if (cell.val) {
      return false;
    }
  }
  return true;
}

export default checkDataForFieldIsAllZeroes;
