import { FunctionComponent } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Select } from "antd";
import styled from "styled-components/macro";

import { tagMap, tags } from "./constants";

const { Option } = Select;

type TagSelectT = {
  value: string[];
  onChange: (value: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const TagSelect: FunctionComponent<TagSelectT> = ({ value, onChange, ...rest }) => {
  return (
    <Select
      allowClear
      clearIcon={<CloseIcon className="anticon" />}
      removeIcon={<CloseIcon className="anticon" />}
      suffixIcon={<CloseIcon className="anticon" />}
      mode="multiple"
      optionLabelProp="label"
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      {...rest}>
      {tags.map((tag) => {
        const label = tagMap[tag].label;
        const Icon = tagMap[tag].icon;

        return (
          <Option value={tag} label={label} key={tag}>
            <StyledLabel>
              <Icon /> {label}
            </StyledLabel>
          </Option>
        );
      })}
    </Select>
  );
};

export default TagSelect;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
