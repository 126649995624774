import placeholderSrc from "assets/placeholder-user.png";
import { ORG_ACCOUNT_TYPE } from "constants/auth.constants";

import { IUser } from "models/User";

import * as models from "components/user-settings/models";

import { IUserState, UserDispatchT, UserSettingsT } from "../UserContext";

const userEndpoint = process.env.REACT_APP_USER_SERVICE + "/user";

type UserReducerActionsT =
  | { type: "update user"; user: IUser }
  | { type: "update session"; sessionId: string }
  | { type: "update settings"; settings: UserSettingsT }
  | { type: "update settings - decline type"; setting: models.DefaultDeclineType }
  | { type: "update settings - user dashboard"; setting: models.UserDashboardSettings }
  | { type: "update settings - user doc"; setting: models.UserDocSetting }
  | { type: "update settings - user abbreviations"; setting: models.UserAbbreviations }
  | { type: "update settings default - project"; setting: models.DefaultProject }
  | { type: "update settings default - filter"; setting: models.DefaultFilter }
  | { type: "update settings default - facility filter"; setting: models.DefaultFilter }
  | { type: "update settings default - map"; setting: models.DefaultMapSettings }
  | { type: "update settings default - focus"; setting: models.DefaultFocusTiles }
  | { type: "update settings default - bin settings"; setting: models.DefaultBinSettings }
  | {
      type: "update settings default - facility focus";
      setting: models.DefaultFocusTiles;
    }
  | { type: "update settings default - palette"; setting: models.DefaultPalette }
  | { type: "update settings default - facility palette"; setting: models.DefaultPalette }
  | {
      type: "update settings default - dashboard";
      setting: models.DefaultTypeWellDashboard;
    }
  | { type: "update settings - midstream"; setting: models.MidstreamSettings }
  | { type: "update settings - geo model"; setting: models.GeoModelSettings };

const UserReducer = (draft: IUserState, action: UserReducerActionsT) => {
  switch (action.type) {
    case "update user": {
      const user = action.user;
      if (!user) break;
      draft.user = user;

      const { firstName, lastName, email, profilePictureImageSrc: userImg } = user;

      draft.email = email;
      draft.fullName = `${firstName} ${lastName}`;
      draft.initials =
        firstName && lastName && (firstName[0] + lastName[0]).toLocaleUpperCase();
      draft.imgSrc = userImg ? `${userEndpoint}/${userImg}` : placeholderSrc;

      draft.hasTrialAccount =
        user?.organization?.organizationAccountType === ORG_ACCOUNT_TYPE.Trial;

      draft.isAdmin = user?.role === "Admin";
      draft.isReadonly = user?.role === "Readonly";
      draft.isUser = user?.role === "User";
      draft.isSuperUser = user?.role === "SuperUser";
      draft.isPowerUser = user?.role === "PowerUser";

      draft.isAtLeastAdmin = draft.isAdmin || draft.isSuperUser;
      draft.isAtLeastPowerUser = draft.isPowerUser || draft.isAdmin || draft.isSuperUser;
      draft.isAtLeastUser =
        draft.isUser || draft.isPowerUser || draft.isAdmin || draft.isSuperUser;
      break;
    }
    case "update session": {
      draft.sessionId = action.sessionId;
      break;
    }
    case "update settings": {
      draft.settings = action.settings;
      break;
    }
    case "update settings - decline type": {
      draft.settings.declineType = action.setting;
      break;
    }
    case "update settings - user dashboard": {
      draft.settings.userDashboardSettings = action.setting;
      break;
    }
    case "update settings - user doc": {
      draft.settings.userDocSettings = action.setting;
      break;
    }
    case "update settings - user abbreviations": {
      draft.settings.userAbbreviations = action.setting;
      break;
    }
    case "update settings default - project": {
      draft.settings.defaults.project = action.setting;
      break;
    }
    case "update settings default - filter": {
      draft.settings.defaults.filter = action.setting;
      break;
    }
    case "update settings default - facility filter": {
      draft.settings.defaults.facilityFilter = action.setting;
      break;
    }
    case "update settings default - map": {
      draft.settings.defaults.mapSettings = action.setting;
      break;
    }
    case "update settings default - focus": {
      draft.settings.defaults.focusTiles = action.setting;
      break;
    }
    case "update settings default - facility focus": {
      draft.settings.defaults.facilityFocusTiles = action.setting;
      break;
    }
    case "update settings default - palette": {
      draft.settings.defaults.colorPalette = action.setting;
      break;
    }
    case "update settings default - facility palette": {
      draft.settings.defaults.facilityPalette = action.setting;
      break;
    }
    case "update settings default - dashboard": {
      draft.settings.defaults.typeWellDashboard = action.setting;
      break;
    }
    case "update settings - midstream": {
      draft.settings.midstreamSettings = action.setting;
      break;
    }
    case "update settings default - bin settings": {
      draft.settings.defaults.binSettings = action.setting;
      break;
    }
    case "update settings - geo model": {
      draft.settings.geoModelSettings = action.setting;
      break;
    }
    default: {
      throw new Error("invalid well list reducer action");
    }
  }
};

// module functions

const updateUser = (dispatch: UserDispatchT, user?: IUser) =>
  dispatch({ type: "update user", user });

const updateSession = (dispatch: UserDispatchT, sessionId?: string) =>
  dispatch({ type: "update session", sessionId });

const updateSettings = (dispatch: UserDispatchT, settings: UserSettingsT) =>
  dispatch({ type: "update settings", settings });

const updateSettingDeclineType = (
  dispatch: UserDispatchT,
  setting: models.DefaultDeclineType
) => dispatch({ type: "update settings - decline type", setting });

const updateSettingUserDoc = (dispatch: UserDispatchT, setting: models.UserDocSetting) =>
  dispatch({ type: "update settings - user doc", setting });

const updateSettingDashboard = (
  dispatch: UserDispatchT,
  setting: models.UserDashboardSettings
) => dispatch({ type: "update settings - user dashboard", setting });

const updateSettingAbbreviations = (
  dispatch: UserDispatchT,
  setting: models.UserAbbreviations
) => dispatch({ type: "update settings - user abbreviations", setting });

const updateSettingProject = (dispatch: UserDispatchT, setting: models.DefaultProject) =>
  dispatch({ type: "update settings default - project", setting });

const updateSettingFilter = (dispatch: UserDispatchT, setting: models.DefaultFilter) =>
  dispatch({ type: "update settings default - filter", setting });

const updateSettingFacilityFilter = (
  dispatch: UserDispatchT,
  setting: models.DefaultFilter
) => dispatch({ type: "update settings default - facility filter", setting });

const updateSettingMap = (dispatch: UserDispatchT, setting: models.DefaultMapSettings) =>
  dispatch({ type: "update settings default - map", setting });

const updateSettingBinSettings = (
  dispatch: UserDispatchT,
  setting: models.DefaultBinSettings
) => dispatch({ type: "update settings default - bin settings", setting });

const updateSettingFocus = (dispatch: UserDispatchT, setting: models.DefaultFocusTiles) =>
  dispatch({ type: "update settings default - focus", setting });

const updateSettingFacilityFocus = (
  dispatch: UserDispatchT,
  setting: models.DefaultFocusTiles
) => dispatch({ type: "update settings default - facility focus", setting });

const updateSettingPalette = (dispatch: UserDispatchT, setting: models.DefaultPalette) =>
  dispatch({ type: "update settings default - palette", setting });

const updateSettingFacilityPalette = (
  dispatch: UserDispatchT,
  setting: models.DefaultPalette
) => dispatch({ type: "update settings default - facility palette", setting });

const updateSettingTypeWellDashboard = (
  dispatch: UserDispatchT,
  setting: models.DefaultTypeWellDashboard
) => dispatch({ type: "update settings default - dashboard", setting });

const updateMidstreamSettings = (
  dispatch: UserDispatchT,
  setting: models.MidstreamSettings
) => dispatch({ type: "update settings - midstream", setting });

const updateGeoModelSettings = (
  dispatch: UserDispatchT,
  setting: models.GeoModelSettings
) => dispatch({ type: "update settings - geo model", setting });

export {
  updateUser,
  updateSession,
  updateSettings,
  updateSettingBinSettings,
  updateSettingProject,
  updateSettingFilter,
  updateSettingFacilityFilter,
  updateSettingDeclineType,
  updateSettingMap,
  updateSettingPalette,
  updateSettingFocus,
  updateSettingFacilityFocus,
  updateSettingUserDoc,
  updateSettingDashboard,
  updateSettingAbbreviations,
  updateSettingTypeWellDashboard,
  updateSettingFacilityPalette,
  updateMidstreamSettings,
  updateGeoModelSettings,
  UserReducer
};

export type { UserReducerActionsT };
