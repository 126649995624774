import { useContext } from "react";

import { ShapefileDispatchContext } from "./ShapefileContext";

export function useShapefileDispatch() {
  const context = useContext(ShapefileDispatchContext);
  if (context === undefined) {
    throw new Error("useShapefileDispatch must be used within a Shapefile Provider");
  }
  return context;
}
