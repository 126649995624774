import { TypeWellSeries } from "../../../models/UserArpsModel";
import { IChartResult } from "../../../models/model";
import { removeCount, removeForecast } from "../../../utils";
import { getPrecisionBasedOnUnit } from "../utils/getPrecisionBasedOnUnit";

interface TypeWellDataTableParams {
  responseData: IChartResult;
  typeWellSeries: TypeWellSeries[];
  axisMinMax: { xMin: number | null; xMax: number | null };
}

export default function typeWellDataTable({
  responseData,
  typeWellSeries,
  axisMinMax
}: TypeWellDataTableParams) {
  const xPrecision = getPrecisionBasedOnUnit(responseData?.layout?.xAxis?.title);
  const yPrecision = getPrecisionBasedOnUnit(responseData?.layout?.yAxis?.title);
  const min = axisMinMax?.xMin ?? null;
  const max = axisMinMax?.xMax ?? null;

  const rows = typeWellSeries.reduce((list, series) => {
    for (let i = 0; i < series.data.length; i++) {
      const [x, y] = series.data[i];
      if ((!min || x >= min) && (!max || x <= max)) {
        const row = {
          SeriesColor: series.itemStyle.color,
          SeriesFull: series.name,
          Series: removeForecast(removeCount(series.name)),
          X: typeof x === "number" ? x.toFixed(xPrecision) : x,
          production: null,
          forecast: "Type Well",
          Y: typeof y === "number" ? y.toFixed(yPrecision) : y
        };
        list.push(row);
      }
    }
    return list;
  }, []);

  return {
    rows
  };
}
