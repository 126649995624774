// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { mdiBell } from "@mdi/js";
import { Badge, Tooltip } from "antd";
import { display } from "store/features/userDocumentation/documentationSlice";
import styled from "styled-components/macro";

import { usePost } from "./context";
import { useNewPostsCountManager } from "./hooks";

const DocumentationToggle = ({ className = "" }) => {
  // hooks
  const [{ totalNewPosts }] = usePost();
  useNewPostsCountManager();

  // dispatches
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(display());
  }, []);

  return (
    <Tooltip title="Notifications" placement="bottomRight" arrowPointAtCenter>
      <NotificationButton className={className} onClick={handleClick}>
        <Badge
          size="small"
          count={totalNewPosts}
          overflowCount={10}
          color="var(--color-primary)">
          <Icon path={mdiBell} size="24px" />
        </Badge>
      </NotificationButton>
    </Tooltip>
  );
};

export default DocumentationToggle;

const NotificationButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 24px;
  transition: color 0.2s, background-color 0.2s;
  cursor: pointer;

  svg {
    color: var(--color-text-40);
  }

  &:hover svg {
    color: var(--color-text);
  }
`;
