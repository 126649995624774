// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";

import {
  mdiFolderAccount,
  mdiFolderDownload,
  mdiFolderKey,
  mdiFolderTable,
  mdiInformation
} from "@mdi/js";
import classNames from "classnames";
import styled from "styled-components/macro";

import { useUser } from "hooks";
import useBetaFeatures from "hooks/useBetaFeatures";

import { FilesType, useFilesContext, useFilesDispatch } from "../context";

type IFileTypeOption = {
  title: string;
  icon: string;
  type: FilesType;
  description?: string;
};
const FilesTypesList = () => {
  const { isAdmin } = useUser();
  const { filesType } = useFilesContext();
  const filesDispatch = useFilesDispatch();
  const { hasFeature } = useBetaFeatures();

  const [selectedOption, setSelectedOption] = useState<IFileTypeOption>();

  const changeFilesType = (filesType: FilesType) => {
    filesDispatch({
      payload: {
        filesType: filesType,
        isFileUploaderOpen: false,
        uploadingFilesProgress: {}
      }
    });
  };

  const filesTypes: IFileTypeOption[] = [
    {
      title: "User Shares",
      icon: mdiFolderAccount,
      type: "userToEVA",
      description: "Files securely uploaded by you for sharing with EVA support."
    },
    {
      title: "EVA Shares",
      icon: mdiFolderDownload,
      type: "userFromEVA",
      description: "Files securely uploaded by EVA support for sharing with you."
    }
  ];

  if (isAdmin) {
    filesTypes.push({
      title: "EVA Admin Shares",
      icon: mdiFolderKey,
      type: "adminFromEVA",
      description:
        "Files securely uploaded by EVA support for sharing with administrators."
    });

    if (hasFeature("Prod Data Import Files")) {
      filesTypes.push({
        title: "Production Data Imports",
        icon: mdiFolderTable,
        type: "productionData",
        description:
          "The complete list of production data imports provided by the organization.  Limited to administrators."
      });
    }
  }

  useEffect(() => {
    setSelectedOption(filesTypes.find((option) => option.type === filesType));
  }, [filesType]);

  return (
    <Wrapper>
      <FolderList>
        {filesTypes.map((folder) => (
          <FolderContainer
            key={folder.type}
            className={classNames({ active: filesType === folder.type })}
            onClick={() => changeFilesType(folder.type)}>
            <FolderIcon>
              <Icon path={folder.icon} />
            </FolderIcon>
            <FolderTitle>{folder.title}</FolderTitle>
          </FolderContainer>
        ))}
      </FolderList>
      {selectedOption?.description && (
        <SelectedFilesTypeInfo>
          <Icon path={mdiInformation} />
          <span>{selectedOption.description}</span>
        </SelectedFilesTypeInfo>
      )}
    </Wrapper>
  );
};

export default FilesTypesList;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const FolderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const FolderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  transition: background-color 0.2s;
  border-radius: var(--border-radius);
  &.active {
    background-color: var(--color-text);
    color: #fff;
    svg {
      color: var(--color-primary);
    }
  }
  &.active:hover {
    background-color: var(--color-text);
    cursor: default;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(var(--color-text-rgb), 0.05);
    svg {
      color: var(--color-primary);
    }
  }
`;
const FolderIcon = styled.div`
  color: var(--color-text-40);

  svg {
    width: 36px;
    height: 36px;
  }
`;
const FolderTitle = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
`;

const SelectedFilesTypeInfo = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 18px 1fr;
  font-style: italic;
  gap: 8px;
  margin-top: 25px;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  background-color: rgba(var(--color-text-rgb), 0.05);
  color: var(--color-text-80);
`;
