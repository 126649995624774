import { memo } from "react";

function LegendFilled({ style = {} }) {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      style={{ ...style }}>
      <path
        d="M0 0L36 0L36 36L0 36L0 0Z"
        id="36x36"
        fill="none"
        fillRule="evenodd"
        stroke="none"
      />
      <g id="iconmaplist" transform="translate(6 5)">
        <g id="iconmaplist">
          <path
            d="M0 0L24 0L24 24L0 24L0 0Z"
            id="Rectangle-Copy-4"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M6.01754 0L0 0L0 5.98931L6.01754 5.98931L6.01754 0ZM4.29765 1.71107L4.29765 4.27743L1.71919 4.27743L1.71919 1.71107L4.29765 1.71107ZM9.94737 4.33512L9.94737 2.16756L21 2.16756L21 4.33512L9.94737 4.33512ZM6.01754 10.0107L0 10.0107L0 16L6.01754 16L6.01754 10.0107ZM4.29765 11.7218L4.29765 14.2881L1.71919 14.2881L1.71919 11.7218L4.29765 11.7218ZM9.94737 14.0891L9.94737 11.9216L21 11.9216L21 14.0891L9.94737 14.0891Z"
            transform="translate(2 4)"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(LegendFilled);
