// Format property name and remove fcst and sync portitions of the string
const formatPropertyName = (propertyName: string) => {
  if (propertyName.includes("fcst")) {
    propertyName = propertyName.substring(0, propertyName.indexOf(".fcst"));
  }
  if (propertyName.includes("sync")) {
    propertyName = propertyName.substring(0, propertyName.indexOf(".sync"));
  }
  return propertyName;
};
export default formatPropertyName;
