import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Alert } from "antd";
import axios from "axios";
import _debounce from "lodash/debounce";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import useUserModules from "hooks/useUserModules";

import { KeyValue, ThreeDViewerOptionT } from "models";
import { ThreeDViewer as D3Viewer, HzWell, Mode } from "models/threed-viewer";

import { BusyOverlay, ErrorOverlay } from "components/activity/shared";
import { IconSpinner } from "components/icons";
import { useScreenshotContext } from "components/screenshot/hooks";
import { Screenshot } from "components/vis";

import ThreeDVToolbar from "./ThreeDVToolbar";
import "./ThreeDViewer.scss";
import useGet3DScene from "./hooks/useGet3DScene";

function renderScene(
  self,
  hzWells,
  ipdb,
  vertical,
  options,
  resetCamera: boolean,
  attentionWells: string[] = []
) {
  if (!hzWells || !ipdb || !ipdb.Zones) {
    return;
  }
  const container = document.getElementById("three-d-scene");
  if (!container) {
    return;
  }
  if (self.viewer == null) {
    self.viewer = new D3Viewer(container, options);
    self.viewer.initialize();
  } else {
    self.viewer.container = container;
    self.viewer.onWindowResize();
    self.viewer.clearScene();
    self.viewer.updateOptions(options);
  }
  self.viewer.setData(hzWells, ipdb, vertical);
  if (resetCamera) {
    self.viewer.resetCameraPosition(Mode.Well);
  }
  self.viewer.addIPDBPlane(ipdb);
  self.viewer.addHzWells(hzWells, attentionWells);
  self.viewer.addVerticalWells(vertical);
  container.style.visibility = "visible";
}

const threeDViewerData = {
  viewer: null,
  data: { hzWells: [], ipdb: [], control: null }
};

function ThreeDViewer({ wells, onFullscreenToggle }): JSX.Element {
  const wellsRef = useRef(wells);
  const containerRef = useRef(null);
  const { widget, settings } = useScreenshotContext();
  const { has3dGeoModel } = useUserModules();
  const { mutateAsync: post3dScene, isLoading } = useGet3DScene();
  const dimensions = useSelector((state: RootState) => state.app.visSettings);

  const screenshotBounds = {
    width: settings?.width || 1152,
    height: settings?.height || 681
  };

  const screenshotOverlayVisible = useMemo(() => {
    return widget?.widgetId === "ml";
  }, [widget]);

  const [error, setError] = useState<string>(undefined);

  function onError(message: string) {
    if (typeof message === "string") {
      setError(message);
    } else {
      setError("an error occurred");
    }
  }

  const attentionWells = useSelector((state: RootState) => state.map.attentionWells);

  function onOptionChange(option: KeyValue, refreshData: boolean) {
    optionsRef.current = Object.assign({}, optionsRef.current, option);
    setOptions(optionsRef.current);
    if ("ipdbField" in option && threeDViewerData.viewer && threeDViewerData.data) {
      threeDViewerData.viewer.setData(
        threeDViewerData.data.hzWells,
        threeDViewerData.data.ipdb,
        threeDViewerData.data.control
      );
    }
    if (refreshData === true) {
      updateSceneDebounce(wellsRef.current, optionsRef.current, true);
    }
  }

  const [options, setOptions] = useState({
    scaleZ: 10,
    showDrainage: false,
    showIPDBDrainage: false,
    showVerticalPortion: false,
    lineWidth: 4,
    showIPDBPlane: true,
    showIPDBMesh: false,
    highlightHzLeg: false,
    showMultiLegWells: true,
    hzLegColor: 0xd8b365,
    wireframe: false,
    showWellLabel: false,
    ipdbSource: "McDaniel",
    showWellDetail: false,
    colorMap: "rainbow",
    colorMapMin: null,
    colorMapMax: null,
    ipdbField: "Porosity",
    ipdbFieldUnit: "%",
    rightLateralDistance: dimensions.rightWidth,
    upVerticalDistance: dimensions.upHeight,
    leftLateralDistance: dimensions.leftWidth,
    downVerticalDistance: dimensions.downHeight,
    maxValue: 2000,
    modelSource: "McDaniel",
    scaleDrainage: false,
    onOptionChange,
    showVertical: has3dGeoModel,
    availableWells: Object.keys(wells)
  });

  const optionsRef = useRef(options);

  useEffect(() => {
    onOptionChange(
      {
        ...optionsRef.current,
        rightLateralDistance: dimensions.rightWidth ?? 100,
        upVerticalDistance: dimensions.upHeight ?? 10,
        leftLateralDistance: dimensions.leftWidth ?? 100,
        downVerticalDistance: dimensions.downHeight ?? 10
      },
      true
    );
  }, [dimensions]);

  async function updateScene(
    selectedWells,
    options: ThreeDViewerOptionT,
    forceRefresh = false
  ) {
    if (!forceRefresh) {
      //just update the color

      const data = threeDViewerData.data;
      if (!data) {
        return;
      }
      const hzWells: HzWell[] = data.hzWells;
      data.hzWells = hzWells.map((well) => {
        const foundWell = selectedWells[well.UnformattedUwi];
        if (foundWell) {
          well.Color = parseInt(foundWell.color.substr(1), 16);
        }
        return well;
      });
      renderScene(
        threeDViewerData,
        data.hzWells,
        data.ipdb,
        data.control,
        options,
        false,
        attentionWells
      );
    }

    // eslint-disable-next-line import/no-named-as-default-member
    setError("");
    try {
      const mutationResult = await post3dScene({ selectedWells, options });
      if (mutationResult.status !== 200) {
        onError(mutationResult.data);
        return;
      }
      const data = mutationResult.data;
      threeDViewerData.data = data;
      renderScene(
        threeDViewerData,
        data.hzWells,
        data.ipdb,
        data.control,
        options,
        !forceRefresh,
        attentionWells
      );
    } catch (err) {
      if (axios.isCancel(err)) {
        onError("");
        return;
      }
      if (err.response?.data) {
        onError(err.response.data);
      } else {
        onError("network error occurred");
      }
    }
  }

  const updateSceneDebounce = useRef(
    _debounce(
      async (selectedWells, options: ThreeDViewerOptionT, forceRefresh = false) => {
        await updateScene(selectedWells, options, forceRefresh);
      },
      600
    )
  ).current;

  useEffect(() => {
    wellsRef.current = wells;
    onOptionChange({ ...optionsRef.current, availableWells: Object.keys(wells) }, true);
    updateScene(wells, options);
  }, [wells]);

  function refreshScene(data, attentionWells: string[] = []) {
    if (!data?.hzWells) return;

    renderScene(
      threeDViewerData,
      data.hzWells,
      data.ipdb,
      data.control,
      options,
      false,
      attentionWells
    );
  }

  useEffect(
    () => refreshScene(threeDViewerData.data, attentionWells),
    [options, attentionWells]
  );

  useEffect(() => {
    const self = threeDViewerData;
    return () => {
      if (self?.viewer) {
        self.viewer.clearScene();
        self.viewer.clearEntireSceneAndDisposeRenderer();
        self.viewer = null;
      }
    };
  }, []);

  useEffect(() => {
    if (threeDViewerData?.viewer) {
      //give time for the container size to update before calling resize
      setTimeout(() => {
        if (threeDViewerData?.viewer) {
          threeDViewerData.viewer.onWindowResize();
        }
      }, 900);
    }
  }, [screenshotBounds]);

  function resetCamera() {
    const data = threeDViewerData.data;
    if (!data || !data.hzWells) {
      return;
    }
    renderScene(
      threeDViewerData,
      data.hzWells,
      data.ipdb,
      data.control,
      options,
      true,
      attentionWells
    );
  }

  const handleFullscreenToggle = (v) => {
    // refresh scene with new bounds
    if (threeDViewerData?.viewer) {
      //give time for the container size to update before calling resize
      setTimeout(() => {
        threeDViewerData?.viewer?.onWindowResize();
      }, 900);
    }
    onFullscreenToggle(v);
  };

  const handleScreenshotToggle = (v) => {
    handleFullscreenToggle(v);
  };

  useEffect(() => {
    handleScreenshotToggle(screenshotOverlayVisible);
  }, [screenshotOverlayVisible]);

  return (
    <Wrapper
      className="three-d-viewer"
      ref={containerRef}
      isScreenshot={screenshotOverlayVisible}>
      <Screenshot key="screenshot" containerId="three-d-screenshot-overlay" />

      <ThreeDViewerContainer
        className="three-d-viewer-container"
        isScreenshot={screenshotOverlayVisible}
        screenshotWidth={screenshotBounds.width}
        screenshotHeight={screenshotBounds.height}>
        <ScreenshotContainer id={"three-d-screenshot-overlay"} />
        <SceneContainer id="three-d-scene" />
        <OrientationContainer id="orientation" />
        <InsetContainer id="inset" />
      </ThreeDViewerContainer>

      {!screenshotOverlayVisible && (
        <ThreeDVToolbar
          mouseoverRef={containerRef}
          onFullscreenToggle={handleFullscreenToggle}
          resetCamera={resetCamera}
          options={options}
        />
      )}

      {isLoading && (
        <BusyOverlay onContextMenu={(e) => e.preventDefault()}>
          <IconSpinner />
        </BusyOverlay>
      )}
      {error && (
        <ErrorOverlay>
          <Alert type="error" message={`Unable to render: ${error}`} />
        </ErrorOverlay>
      )}
    </Wrapper>
  );
}

export default ThreeDViewer;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: inherit;

  ${(props) =>
    props.isScreenshot &&
    `
    display: grid;
    background-color: rgba(46, 72, 88, 0.24);
  `}
`;

const ThreeDViewerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: inherit;

  ${(props) =>
    props.isScreenshot &&
    `
    justify-self: center;
    width: ${props.screenshotWidth}px;
    height: ${props.screenshotHeight}px;
    background-color: white;
    top: 60px;
  `}
`;

const SceneContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const InsetContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const ScreenshotContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  pointer-events: none;
`;

const OrientationContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
`;
