import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useMutation } from "@apollo/client";
import { Business, Check, Group, Person, Save } from "@material-ui/icons";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import { ProjectModel } from "api/project";
import { SAVE_FILTER } from "api/savedFilters";

import { LegendItemModel } from "models/LegendItem";

import { ButtonIconCentered } from "components/activity/shared";
import { BaseButton, BaseInput, BaseMenu } from "components/base";

import { EntityKind } from "../../models/entityKind";

interface SaveActiveFilterParams {
  project: ProjectModel;
  entityKind: EntityKind;
  filterName: string;
  checkedLegendItems: React.MutableRefObject<LegendItemModel[]>;
  onSaveFilter: (name: string) => void;
}

let componentFilterName = "";
let currentFilterJSON = "";
let currentFacilityFilterJSON = "";

export default function SaveActiveFilter(props: SaveActiveFilterParams) {
  const [filterName, setName] = useState(props.filterName);

  const [saveFilter] = useMutation(SAVE_FILTER);
  const [nameError, setNameError] = useState("");
  const filterState = useSelector((state: RootState) => {
    const obj = {
      ...(state.filter.polygonFilter?.source && {
        polygonFilter:
          props.entityKind == EntityKind.Well
            ? state.filter.polygonFilter
            : state.facilityFilter.polygonFilter
      }),
      excludePolygonsFilter:
        props.entityKind == EntityKind.Well
          ? state.filter.excludePolygonsFilter
          : state.facilityFilter.excludePolygonsFilter,
      wellListFilter:
        props.entityKind == EntityKind.Well
          ? state.filter.wellListFilter
          : state.facilityFilter.wellListFilter,
      propertiesFilter:
        props.entityKind == EntityKind.Well
          ? state.filter.propertiesFilter
          : state.facilityFilter.propertiesFilter,
      entityKind: props.entityKind
    };
    return obj;
  });
  const savedFilters = useSelector((state: RootState) =>
    props.entityKind == EntityKind.Well
      ? state.filter.savedFilters
      : state.facilityFilter.savedFilters
  );
  const projectIdRef = useRef(props.project.projectId);

  const updateFilterState = useDebouncedCallback((filterState) => {
    const state = Object.assign({}, filterState);
    if (props.entityKind == EntityKind.Well) {
      currentFilterJSON = JSON.stringify(state);
    } else {
      currentFacilityFilterJSON = JSON.stringify(state);
    }
  }, 500);

  useEffect(() => {
    projectIdRef.current = props.project.projectId;
  }, [props.project]);

  useEffect(() => {
    if (props.filterName !== "Active Filter") {
      setName(props.filterName);
    } else {
      setName("");
    }
  }, [props.filterName]);

  const onEnterKey = async (ev, closeMenu) => {
    if (ev.key === "Enter" && projectIdRef.current && componentFilterName !== "") {
      const data = {
        projectID: projectIdRef.current,
        filterJSON:
          props.entityKind == EntityKind.Well
            ? currentFilterJSON
            : currentFacilityFilterJSON,
        name: componentFilterName,
        entityKind: props.entityKind
      };
      if (
        componentFilterName.toLowerCase() === "system default" ||
        componentFilterName.toLowerCase() === "hz with production"
      ) {
        setNameError("You cannot overwrite the System Default filter.");
        return;
      }
      if (
        savedFilters.some(
          (filter) => filter.name.toLowerCase() === componentFilterName.toLowerCase()
        )
      ) {
        setNameError("Duplicate name detected.");
        return;
      }
      const response = await saveFilter({
        variables: {
          input: data
        },
        context: {
          clientName: "saved-filters"
        }
      });
      if (response && response.data) {
        setNameError("");
        props.onSaveFilter(componentFilterName);
        closeMenu && closeMenu();
      }
    }
  };

  useEffect(() => {
    if (!filterState) {
      return;
    }
    updateFilterState(filterState);
  }, [filterState]);

  useEffect(() => {
    componentFilterName = filterName;
  }, [filterName]);

  return (
    <BaseMenu
      trigger={
        <TriggerWrapper>
          <ButtonIconCentered
            data-testid="focus-save-filter-button"
            type="text"
            icon={<Save></Save>}
            shape="circle"
          />
        </TriggerWrapper>
      }>
      {({ closeMenu }) => (
        <>
          <FilterHeaderWrapper className="align-left">
            {props.project.projectType === "Organization" ? (
              <Business style={{ color: "#A2AAAD", fontSize: "18px", top: "2px" }} />
            ) : props.project.projectType === "Shared" ||
              (!props.project.projectType && props.project.public) ? (
              <Group style={{ color: "#A2AAAD", fontSize: "18px", top: "2px" }} />
            ) : (
              <Person style={{ color: "#A2AAAD", fontSize: "18px", top: "2px" }} />
            )}
            {props.project.projectName}
          </FilterHeaderWrapper>
          <InputWrapper>
            <BaseInput
              value={filterName}
              autoFocus
              type="text"
              onChange={setName}
              onKeyDown={(evt) => onEnterKey(evt, closeMenu)}
              placeholder="Filter Name"></BaseInput>
            <BaseButton
              appearance="outline"
              data-testid="save-filter-button"
              onClick={() => onEnterKey({ key: "Enter" }, closeMenu)}>
              <Check />
            </BaseButton>
          </InputWrapper>
          {nameError && <ErrorComponent>{nameError}</ErrorComponent>}
        </>
      )}
    </BaseMenu>
  );
}

const TriggerWrapper = styled.div`
  .icon-toggle {
    color: #b5b5b5;
  }

  .icon-toggle:hover {
    color: var(--color-primary);
  }
`;
const InputWrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;

  input {
    min-height: 32px;
  }

  .error-message {
    margin-top: 0;
  }

  .base-button {
    padding: 5px;
    margin: 2px;
  }
`;
const FilterHeaderWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  gap: 5px;
`;

const ErrorComponent = styled.div`
  color: red;
  width: 100%;
  max-width: fit-content;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
`;
