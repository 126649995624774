// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiAxisZArrow } from "@mdi/js";

import { ThreeDViewerOptionT } from "models";

import ThreeDVToolbarButton from "./ThreeDVToolbarButton";

function ThreeDVVerticalPortionToggle(options: ThreeDViewerOptionT) {
  const handleToggle = (active: boolean) => {
    options.onOptionChange &&
      options.onOptionChange({ showVerticalPortion: active }, false);
  };

  return (
    <ThreeDVToolbarButton
      icon={<Icon path={mdiAxisZArrow} size={1.5} />}
      active={options.showVerticalPortion}
      tooltipText={"Show Vertical Portion of Hz Well"}
      overflowLabel={"Vertical Portion"}
      onToggle={handleToggle}
    />
  );
}

export default ThreeDVVerticalPortionToggle;
