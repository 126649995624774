import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useChartState } from "../context";

const useTypeWellOnlyToast = (series, typeWellSeries) => {
  const { settings, id } = useChartState();

  const [hasAlreadyDisplayedToast, setHasAlreadyDisplayedToast] = useState(false);

  useEffect(() => {
    if (series?.length === 0 && settings.typewells && typeWellSeries.length !== 0) {
      if (hasAlreadyDisplayedToast === false) {
        const typeWellOnlyIdWarn = `type-well-only-${id}`;
        toast.dismiss(typeWellOnlyIdWarn);
        toast.warning("No wells in map - Showing only type wells", {
          containerId: id,
          toastId: typeWellOnlyIdWarn
        });
      }
      if (hasAlreadyDisplayedToast !== true) setHasAlreadyDisplayedToast(true);
    } else {
      if (hasAlreadyDisplayedToast !== false) setHasAlreadyDisplayedToast(false);
    }
  }, [settings.typewells, typeWellSeries, series, hasAlreadyDisplayedToast]);
};

export default useTypeWellOnlyToast;
