import { useContext } from "react";

import { ScreenshotDispatchContext } from "../contexts/ScreenshotContext";

export function useScreenshotDispatch() {
  const context = useContext(ScreenshotDispatchContext);
  if (context === undefined) {
    throw new Error("useScreenshotDispatch must be used within a Screenshot Provider");
  }
  return context;
}
