import { useProjectLayerListQuery } from "components/project/layers/queries";
import { useProjectContext } from "components/project/projects/context";

import { useCheckedLayersChangeHandler } from "../checked-layers-change-handler/useCheckedLayersChangeHandler";

export function useLayerTreeOnCheckHandler() {
  const { handleCheckedLayersChange } = useCheckedLayersChangeHandler();
  const { selectedProjectId } = useProjectContext();
  const layerList = useProjectLayerListQuery({ projectId: selectedProjectId });
  function handleCheckingLayers(checkedKeys: string[]) {
    // Do not include folders
    const checkedLayerKeys = checkedKeys.filter((key) =>
      layerList.data?.some(
        (layer) =>
          layer.name ||
          layer.projectShapefileId === key ||
          layer.shapefileId === key ||
          layer.geomBinId === key
      )
    );

    handleCheckedLayersChange({
      checkedLayerKeys,
      checkedTreeKeys: checkedKeys
    });
  }

  return { handleCheckingLayers };
}
