import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_SHAPEFILE_FEATURES_QUERY_KEYS } from "../../queries/useProjectShapefileFeaturesQuery";
import { SHAPEFILE_LIST_QUERY_KEYS } from "../../queries/useShapefileListQuery";

export interface IUseUpdateProjectLinkedGeomMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
}
export interface IUpdateProjectLinkedGeomParamsT {
  shapeFileGeomId: string;
  body: {
    projectShapefileId?: string;
    color?: string;
    thickness?: number;
    strokeColor?: string;
  };
}

async function UpdateProjectLinkedGeom(params: IUpdateProjectLinkedGeomParamsT) {
  const url = `${projectServiceEndpoint}/project-shapefile-geom/update/${params.shapeFileGeomId}`;
  const response = await axios.put(url, params.body);
  return response.data;
}

export function useUpdateProjectLinkedGeomMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess
}: IUseUpdateProjectLinkedGeomMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: IUpdateProjectLinkedGeomParamsT) => UpdateProjectLinkedGeom({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
        queryClient.invalidateQueries({
          queryKey: [SHAPEFILE_LIST_QUERY_KEYS.currentProjectShapefiles]
        });
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILE_FEATURES_QUERY_KEYS.projectShapefileFeatures]
        });
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
