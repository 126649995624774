import { useMutation } from "react-query";

import axios from "axios";

import { IUser } from "models/User";

const userRoot = process.env.REACT_APP_USER_SERVICE;

export interface ImpersonateModel {
  organizationId: string;
  userId: string;
  mfa: string;
  impersonateRequestId: string;
  token: string;
}

export default function useImpersonateMutation(onSuccess, onError) {
  return useMutation(
    async (data: ImpersonateModel) => {
      const response = await axios.post<IUser>(`${userRoot}/user/impersonate`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      });
      return response.data;
    },
    {
      onSuccess: onSuccess,
      onError: onError
    }
  );
}
