import { IChartPreset } from "components/multiphase-chart/models/shared.models";

/**
 * Get X axis position depending on unit
 *
 * @param {IChartPreset} preset contains unit positions
 * @param {string} title contains unit
 * @returns {string} axis position
 */
const getXAxisPositionFromUnit = (preset: IChartPreset, title: string): string => {
  const lowerCaseTitle = title.toLowerCase();
  if (lowerCaseTitle.includes("bbl") || lowerCaseTitle.includes("boe")) {
    return preset.mBblPosition;
  } else if (lowerCaseTitle.includes("mcf")) {
    return preset.mMcfPosition;
  }
  return "bottom";
};

export default getXAxisPositionFromUnit;
