import { useUpdateMapLayersVisibility } from "components/map/hooks";
import {
  DEFAULT_CHECKED_LAYERS,
  DEFAULT_EXPANDED_TREE_KEYS
} from "components/project/layers/constants/projectLayerConstants";
import { useProjectDispatch } from "components/project/projects/context";

import {
  getCheckedLayersFromSession,
  getCheckedTreeKeysFromSession
} from "../../session";
import { getLayerTreeExpandedKeysFromSession } from "../../session/layer-tree-expanded-keys/getLayerTreeExpandedKeysFromSession";

export function useClearLayers() {
  const projectDispatch = useProjectDispatch();
  const { updateMapLayersVisibility } = useUpdateMapLayersVisibility();

  function clearLayers(nextProjectId) {
    const checkedLayerKeys =
      getCheckedLayersFromSession(nextProjectId) || DEFAULT_CHECKED_LAYERS;

    const checkedTreeKeys =
      getCheckedTreeKeysFromSession(nextProjectId) || DEFAULT_CHECKED_LAYERS;

    const layerTreeExpandedKeys =
      getLayerTreeExpandedKeysFromSession(nextProjectId) || DEFAULT_EXPANDED_TREE_KEYS;

    projectDispatch({
      payload: {
        checkedLayerKeys: checkedLayerKeys || DEFAULT_CHECKED_LAYERS,
        checkedTreeKeys: checkedTreeKeys || DEFAULT_CHECKED_LAYERS,
        layerTreeExpandedKeys: layerTreeExpandedKeys || DEFAULT_EXPANDED_TREE_KEYS
      }
    });

    updateMapLayersVisibility(DEFAULT_CHECKED_LAYERS);
  }

  return { update: clearLayers };
}
