import { useEffect } from "react";

import { getPresets } from "api/multiProduct";

// This ensures that the first preset (if available) is selected for when a user selects a well.
// Otherwise, the default preset state will be used.
const useInitialPresetLoader = (chartSettingsDispatch, chartId: string) => {
  useEffect(() => {
    const fetchData = async () => {
      const presetData = await getPresets();
      const session = JSON.parse(sessionStorage.getItem(chartId));
      // check if preset is blank
      const hasPresetInSessionStorage = !!session && session?.currentPreset.id !== "";

      if (presetData[0] && !hasPresetInSessionStorage) {
        chartSettingsDispatch({
          type: "on inital load preset",
          preset: presetData[0]
        });
      }
    };

    fetchData();
  }, []);
};

export default useInitialPresetLoader;
