import {
  DEFAULT_AXIS_FONT_SIZE,
  DEFAULT_AXIS_FONT_WEIGHT,
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_LEGEND_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE
} from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import { applySpacingToChartOptions } from "utils/chart";

import { IChartScreenshotSettings } from "models/chart";
import { IScreenshotAbbreviation } from "models/screenshot";

import { applyAbbreviations } from "components/screenshot/utils";

const applyScreenshotSettings = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartInstance: any,
  screenshotSettings: IChartScreenshotSettings,
  abbreviations: IScreenshotAbbreviation[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (!options) return options;

  const removeDataZoomSlider = () =>
    options.dataZoom.map((dataZoom) => ({ ...dataZoom, show: false }));

  if (screenshotSettings?.titleText && options.title) {
    options.title.text = screenshotSettings?.applyAbbreviations
      ? applyAbbreviations(screenshotSettings?.titleText, abbreviations)
      : screenshotSettings?.titleText;
  }

  if (screenshotSettings?.titleFontSize && options.title?.textStyle) {
    options.title.textStyle.fontSize = screenshotSettings?.titleFontSize;
  }
  if (options.xAxis) {
    if (screenshotSettings?.xAxisTitleText) {
      options.xAxis.name = screenshotSettings?.applyAbbreviations
        ? applyAbbreviations(screenshotSettings?.xAxisTitleText, abbreviations)
        : screenshotSettings?.xAxisTitleText;
    }
    if (screenshotSettings?.gridShowXLines !== undefined && options.xAxis.splitLine) {
      options.xAxis.splitLine.show = screenshotSettings?.gridShowXLines;
    }
    if (screenshotSettings?.gridShowXMinorLines !== undefined) {
      options.xAxis.minorSplitLine = {
        show: screenshotSettings?.gridShowXMinorLines,
        lineStyle: { color: "#F2F2F2" }
      };
    }
  }
  if (options.yAxis && options.yAxis.length > 0) {
    if (screenshotSettings?.yAxisTitleText) {
      options.yAxis[0].name = screenshotSettings?.applyAbbreviations
        ? applyAbbreviations(screenshotSettings?.yAxisTitleText, abbreviations)
        : screenshotSettings?.yAxisTitleText;
    }
    if (screenshotSettings?.gridShowYLines !== undefined && options.yAxis[0].splitLine) {
      options.yAxis[0].splitLine.show = screenshotSettings?.gridShowYLines;
    }
    if (screenshotSettings?.gridShowYMinorLines !== undefined) {
      options.yAxis[0].minorSplitLine = {
        show: screenshotSettings?.gridShowYMinorLines,
        lineStyle: { color: "#F2F2F2" }
      };
    }
  }
  if (screenshotSettings?.fontFamily && options.textStyle) {
    options["textStyle"]["fontFamily"] = screenshotSettings?.fontFamily;
  }

  options.yAxis?.forEach((axis) => {
    if (!axis.nameTextStyle) {
      axis.nameTextStyle = {};
    }
    axis.nameTextStyle.fontSize =
      screenshotSettings.axisFontSize ?? DEFAULT_AXIS_FONT_SIZE;
    axis.nameTextStyle.fontWeight =
      screenshotSettings.axisFontWeight ?? DEFAULT_AXIS_FONT_WEIGHT;
    axis.nameTextStyle.fontFamily = screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;

    if (!axis.axisLabel) {
      axis.axisLabel = {};
    }
    axis.axisLabel.fontSize =
      screenshotSettings.axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE;
    axis.axisLabel.fontFamily = screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;

    if (screenshotSettings?.applyAbbreviations) {
      axis.name = applyAbbreviations(axis.name, abbreviations);
    }
  });

  if (Array.isArray(options.xAxis)) {
    options.xAxis.forEach((axis) => {
      if (!axis.nameTextStyle) {
        axis.nameTextStyle = {};
      }
      axis.nameTextStyle.fontSize =
        screenshotSettings.axisFontSize ?? DEFAULT_AXIS_FONT_SIZE;
      axis.nameTextStyle.fontWeight =
        screenshotSettings.axisFontWeight ?? DEFAULT_AXIS_FONT_WEIGHT;
      axis.nameTextStyle.fontFamily = screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;

      if (!axis.axisLabel) {
        axis.axisLabel = {};
      }
      axis.axisLabel.fontSize =
        screenshotSettings.axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE;
      axis.axisLabel.fontFamily = screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;
      if (screenshotSettings?.applyAbbreviations) {
        axis.name = applyAbbreviations(axis.name, abbreviations);
      }
    });
  } else if (options.xAxis) {
    if (!options.xAxis.nameTextStyle) {
      options.xAxis.nameTextStyle = {};
    }
    options.xAxis.nameTextStyle.fontSize =
      screenshotSettings.axisFontSize ?? DEFAULT_AXIS_FONT_SIZE;
    options.xAxis.nameTextStyle.fontWeight =
      screenshotSettings.axisFontWeight ?? DEFAULT_AXIS_FONT_WEIGHT;
    options.xAxis.nameTextStyle.fontFamily =
      screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;

    if (!options.xAxis.axisLabel) {
      options.xAxis.axisLabel = {};
    }
    options.xAxis.axisLabel.fontSize =
      screenshotSettings.axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE;
    options.xAxis.axisLabel.fontFamily =
      screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;
  }

  if (options.legend) {
    options.legend.show =
      screenshotSettings.legendType === "bottom" &&
      screenshotSettings.legendVisible === true;
    if (options.legend.textStyle) {
      options.legend.textStyle.fontSize =
        screenshotSettings.legendFontSize ?? DEFAULT_AXIS_FONT_SIZE;
      options.legend.textStyle.fontFamily =
        screenshotSettings.fontFamily ?? THEME_FONT_FAMILY;
    }
  }

  if (screenshotSettings?.applyAbbreviations) {
    options.series?.forEach((series) => {
      if (series?.name) {
        series.name = applyAbbreviations(series.name, abbreviations);
      }
    });
  }

  const screenshotOptions = {
    ...options,
    ...(options.dataZoom && { dataZoom: removeDataZoomSlider() }),
    ...applySpacingToChartOptions(
      options,
      chartInstance,
      {
        titleFontSize: screenshotSettings.titleFontSize ?? DEFAULT_TITLE_FONT_SIZE,
        axisFontSize: screenshotSettings.axisFontSize ?? DEFAULT_AXIS_FONT_SIZE,
        axisLabelFontSize:
          screenshotSettings.axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE,
        legendFontSize: screenshotSettings.legendFontSize ?? DEFAULT_LEGEND_FONT_SIZE,
        fontFamily: screenshotSettings.fontFamily ?? THEME_FONT_FAMILY
      },
      false
    )
  };

  return screenshotOptions;
};

export default applyScreenshotSettings;
