import { memo } from "react";

function DisplaySettings({ size = 18 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" version="1.1">
      <path
        d="M8.4375 0C7.8162 0 7.3125 0.50368 7.3125 1.125L7.1774 2.07038C7.0565 2.91684 6.0728 3.32431 5.38875 2.81128L4.62478 2.23829C4.18544 1.79895 3.47313 1.79895 3.03379 2.23829L2.23829 3.03379C1.79895 3.47313 1.79895 4.18544 2.23829 4.62478L2.81127 5.38875C3.32431 6.0728 2.91684 7.0565 2.07037 7.1774L1.125 7.3125C0.50368 7.3125 0 7.8162 0 8.4375L0 9.5625C0 10.1838 0.50368 10.6875 1.125 10.6875L2.07038 10.8226C2.91685 10.9435 3.32432 11.9272 2.81128 12.6112L2.23829 13.3752C1.79895 13.8146 1.79895 14.5269 2.23829 14.9662L3.03379 15.7617C3.47313 16.2011 4.18544 16.2011 4.62478 15.7617L5.38875 15.1887C6.0728 14.6757 7.0565 15.0832 7.1774 15.9296L7.3125 16.875C7.3125 17.4963 7.8162 18 8.4375 18L9.5625 18C10.1838 18 10.6875 17.4963 10.6875 16.875L10.8226 15.9296C10.9435 15.0832 11.9272 14.6757 12.6112 15.1887L13.3752 15.7617C13.8146 16.2011 14.5269 16.2011 14.9662 15.7617L15.7617 14.9662C16.201 14.5269 16.201 13.8146 15.7617 13.3752L15.1887 12.6112C14.6757 11.9272 15.0832 10.9435 15.9296 10.8226L16.875 10.6875C17.4963 10.6875 18 10.1838 18 9.5625L18 8.4375C18 7.8162 17.4963 7.3125 16.875 7.3125L15.9296 7.1774C15.0832 7.0565 14.6757 6.0728 15.1887 5.38875L15.7617 4.62478C16.201 4.18544 16.201 3.47313 15.7617 3.03379L14.9662 2.23829C14.5269 1.79895 13.8146 1.79895 13.3752 2.23829L12.6112 2.81128C11.9272 3.32431 10.9435 2.91684 10.8226 2.07038L10.6875 1.125C10.6875 0.50368 10.1838 0 9.5625 0L8.4375 0ZM9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.7574 10.2426 6.75 9 6.75C7.7574 6.75 6.75 7.7574 6.75 9C6.75 10.2426 7.7574 11.25 9 11.25Z"
        id="Icon"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}
export default memo(DisplaySettings);
