import {
  useProjectContext,
  useProjectDispatch
} from "components/project/projects/context";

import { setLayerTreeExpandedKeysToSession } from "../../session/layer-tree-expanded-keys/setLayerTreeExpandedKeysToSession";
import { ProjectTreeNodeT } from "../project-layer-tree/useProjectLayerTree";
import { getKeysExcludingDescendantFolders } from "./util/getKeysExcludingDescendantFolders";

interface IUpdateLayerTreeExpandedKeysParams {
  expandedKeys: string[];
  isExpanded: boolean;
  node: ProjectTreeNodeT;
}

export function useUpdateLayerTreeExpandedKeys() {
  const { selectedProjectId, selectedViewId } = useProjectContext();
  const projectDispatch = useProjectDispatch();

  function updateLayerTreeExpandedKeys(params: IUpdateLayerTreeExpandedKeysParams) {
    const { expandedKeys, isExpanded, node } = params;

    const nextExpandedKeys = getKeysExcludingDescendantFolders(
      expandedKeys,
      node,
      isExpanded
    );

    projectDispatch({
      payload: {
        layerTreeExpandedKeys: nextExpandedKeys
      }
    });

    // We're only handling cache right now when a view is not selected,
    // cache has not been implemented for views/type wells/forecasts/filters yet.
    if (!selectedViewId) {
      setLayerTreeExpandedKeysToSession({
        expandedKeys: nextExpandedKeys,
        projectId: selectedProjectId
      });
    }
  }

  return { updateLayerTreeExpandedKeys };
}
