import { removeCount, removeForecast } from "utils";

import { IChartResult } from "models/model";

import { ChartDataTableColumn, ChartDataTableRow } from "../hooks/useDataTableData";
import { getPrecisionBasedOnUnit } from "../utils/getPrecisionBasedOnUnit";

// NOTE: Changing this logic should result in changes to the chart-svc backend export logic.
const crossPlotDataTable = (
  responseData: IChartResult
): { columns: ChartDataTableColumn[]; rows: ChartDataTableRow[] } => {
  const columns = [
    { key: "UWI", name: "UWI", resizable: false },
    { key: "X", name: responseData.layout.xAxis.title, resizable: false },
    { key: "Y", name: responseData.layout.yAxis.title, resizable: false },
    { key: "productionSource", name: "Production Source", resizable: false }
  ];
  const xPrecision = getPrecisionBasedOnUnit(responseData?.layout?.xAxis?.title);
  const yPrecision = getPrecisionBasedOnUnit(responseData?.layout?.yAxis?.title);
  const rows = responseData.series?.reduce((list, series) => {
    for (let i = 0; i < series.x.length; i++) {
      let x = series.x[i];
      const y = series.y[i];
      const xData = responseData.layout?.xAxis?.data;
      if (xData && xData.length > 0) {
        const dataIndex = parseInt(x as string);
        if (dataIndex !== undefined && !Number.isNaN(dataIndex) && dataIndex >= 0) {
          x = xData[dataIndex];
        }
      }

      const row = {
        SeriesColor: series.style.hexColor,
        SeriesFull: series.label,
        Series: removeForecast(removeCount(series.label)),
        UWI: responseData.uwis[i],
        X: typeof x === "number" ? x.toFixed(xPrecision) : x,
        Y: typeof y === "number" ? y.toFixed(yPrecision) : y,
        productionSource:
          responseData.uwiCoordinates[responseData.uwis[i]]?.productionSource || ""
      };
      list.push(row);
    }
    return list;
  }, []);
  return {
    columns,
    rows
  };
};

export default crossPlotDataTable;
