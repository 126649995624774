import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { WellDetailCardRequest, getWellDetailCard } from "api/data";

import { Uwi } from "models";
import { EntityKind } from "models/entityKind";

import { useUserSettings } from "components/user/hooks";

import {
  appendWellDetails,
  resetWellDetailsContext,
  setActiveGroup,
  setGroupWellDetails,
  setTypeLogPdf,
  useWellDetails
} from "../context";

/**
 * Simple `hook` that abstracts API call to fetch well data
 */
interface UseWellDetailsDataProps {
  entityKind: EntityKind;
}

const useWellDetailsData = ({ entityKind }: UseWellDetailsDataProps) => {
  // store values
  const wellCategories = useSelector((state: RootState) => state.groupBy.categoryList);
  const facilityCategories = useSelector(
    (state: RootState) => state.groupBy.facilityFields
  );
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);
  const selectedFacilities = useSelector(
    (state: RootState) => state.map.selectedFacilities
  );
  const isWell = entityKind === EntityKind.Well;
  const isFacility = entityKind === EntityKind.Facility;

  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);
  const userSettings = useUserSettings();
  const isMidstreamDataSourceEnabled = userSettings?.midstreamSettings?.enabled;

  const categories =
    entityKind === EntityKind.Facility ? facilityCategories : wellCategories;
  // states
  const [selectedWell, setSelectedWell] = useState<string>(null);
  const [selectedFacility, setSelectedFacility] = useState<string>(null);
  const [error, setError] = useState(null);
  const [typeLogMessage, setTypeLogMessage] = useState(null);
  // custom hooks
  const [{ currentGroup, loadedGroups }, wellDetailsDispatch] = useWellDetails();

  // memos
  const skippedGroups = useMemo(() => ["Performance", "Forecast"], []);
  const headerProperties = useMemo(
    () => [
      "Header.EntityName",
      "Dates.Vintage_YearMonth",
      "Header.ResourcePlay",
      "Header.ResourceType",
      "Header.WellType",
      "Header.Status",
      "Header.Operator_Short"
    ],
    []
  );

  const facilityHeaderProperties = useMemo(
    () => [
      "Header.FacilityId",
      "Header.FacilityName",
      "Header.FacilityType",
      "Header.OperatorName",
      "Header.OperationalStatus",
      "Header.FacilityStartDate_YearMonth"
    ],
    []
  );

  // mutations
  const getInfoMutation = useMutation(
    async (request: WellDetailCardRequest) => await getWellDetailCard(request),
    {
      onSuccess: (response, variables) => {
        if (variables.group === "Type Log" && entityKind === EntityKind.Well) {
          setTypeLogPdf(wellDetailsDispatch, {
            group: variables.group,
            wellData: response.data
          });
        } else if (variables.group) {
          setGroupWellDetails(wellDetailsDispatch, {
            group: variables.group,
            wellData: response.data
          });
        } else {
          appendWellDetails(wellDetailsDispatch, response.data);
        }
        setError(null);
        setTypeLogMessage(null);
      },
      onError: (error, request) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err) {
          if (err && err.response?.status === 401) {
            setTypeLogMessage("Requires subscription.");
          } else if (err && err.response?.status === 404) {
            setTypeLogMessage("No type log available.");
          } else {
            if (err && err.response?.data) {
              // eslint-disable-next-line no-console
              console.error(err.response?.data);
            }
            setError(
              `Error loading details for well ${new Uwi().toFormatted(request.uwid)}.`
            );
          }
        }
      }
    }
  );

  useEffect(() => {
    if (
      selectedWells &&
      Object.keys(selectedWells).length > 0 &&
      entityKind === EntityKind.Well &&
      activeEntities.includes(EntityKind.Well)
    ) {
      const newSelectedWell = selectedWells[Object.keys(selectedWells)[0]].Uwi;
      if (newSelectedWell !== selectedWell) {
        setSelectedWell(newSelectedWell);
        resetWellDetailsContext(wellDetailsDispatch);
      }
    }
  }, [selectedWells, entityKind, wellDetailsDispatch, selectedWell]);

  useEffect(() => {
    if (
      selectedFacilities &&
      Object.keys(selectedFacilities).length > 0 &&
      entityKind === EntityKind.Facility
    ) {
      const newSelectedFacility =
        selectedFacilities[Object.keys(selectedFacilities)[0]].Uwi;
      if (newSelectedFacility !== selectedFacility) {
        setSelectedFacility(newSelectedFacility);
        resetWellDetailsContext(wellDetailsDispatch);
      }
    }
    if (
      (entityKind === EntityKind.Facility &&
        !activeEntities.includes(EntityKind.Facility)) ||
      !isMidstreamDataSourceEnabled
    ) {
      setSelectedFacility(null);
    }

    if (entityKind === EntityKind.Well && !activeEntities.includes(EntityKind.Well)) {
      setSelectedWell(null); // Remove from widget. Note: can still see in background.
      resetWellDetailsContext(wellDetailsDispatch); // Clear well details on widget.
    }
  }, [
    selectedFacilities,
    entityKind,
    wellDetailsDispatch,
    activeEntities,
    isMidstreamDataSourceEnabled,
    selectedFacility
  ]);

  useEffect(() => {
    if (!selectedWell && isWell) return;
    if (!selectedFacility && isFacility) return;
    if (loadedGroups.includes(currentGroup)) return;
    // default to header tab when switching from well to well while in type log tab
    if (currentGroup === "Type Log" && !loadedGroups.includes("Header")) {
      setActiveGroup(wellDetailsDispatch, "Header");
    }
    const uwid = new Uwi().toUnformatted(isWell ? selectedWell : selectedFacility);
    const fields = categories.reduce((list, group) => {
      if (currentGroup === group.name) {
        list.push(...group.columns.map((c) => c.property));
      }
      if (currentGroup === "Licensed Volumes" && group.name == "Performance") {
        list.push(...group.columns.map((c) => c.property));
      }
      return list;
    }, []);
    // check only TypeLog property for PDF
    if (currentGroup === "Type Log") {
      fields.push("Geology.TypeLog");
    } else if (!loadedGroups.length) {
      // add the top level header properties for the first request
      if (isFacility) {
        fields.push(...facilityHeaderProperties);
      } else {
        fields.push(...headerProperties);
      }
    }

    setError(null);
    getInfoMutation.mutate({
      uwid,
      group: currentGroup,
      fields: [...new Set(fields)],
      entityKind: entityKind
    });
  }, [selectedWell, selectedFacility, currentGroup, categories, isWell, isFacility]);

  return {
    selectedWell,
    selectedFacility,
    headerProperties,
    facilityHeaderProperties,
    skippedGroups,
    isLoading: getInfoMutation.isLoading,
    error,
    typeLogMessage
  };
};

export default useWellDetailsData;
