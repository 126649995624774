import { useEffect, useMemo } from "react";

import { useGlobalProductionSettings } from "hooks";

import { EntityKind } from "../../../models/entityKind";
import { useChartDispatch, useChartState } from "../context";

export interface GlobalSettings {
  usingGlobalFocus: boolean;
  usingGlobalTimestep: boolean;
  usingGlobalProduction: boolean;
  usingGlobalNormalizeBy: boolean;
  usingAllGlobalSettings: boolean;
}
const useChartVsGlobalSettings = (): GlobalSettings => {
  const { settings, instance } = useChartState();
  const globalSettings = useGlobalProductionSettings(EntityKind.Well);
  const dispatch = useChartDispatch();

  useEffect(() => {
    openLegendConditionally(!settings.legend.visible && settings?.useChartFocus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.useChartFocus]);

  //Below causes the legend to appear when changing Normalize, timeStep and Source settings
  /*
  useEffect(() => {
    openLegendConditionally(
      !legend.visible && !isUsingGlobalNormalizeBy(settings, globalSettings)
    );
  }, [settings?.useNormalizeBy, JSON.stringify(settings?.normalizeBy)]);

  useEffect(() => {
    openLegendConditionally(
      !legend.visible && settings?.timeStep !== globalSettings?.timeStep
    );
  }, [settings?.timeStep]);

  useEffect(() => {
    openLegendConditionally(
      !legend.visible && settings?.source !== globalSettings?.source
    );
  }, [settings?.source]);
  */

  const isUsingGlobalNormalizeBy = (settings, globalSettings): boolean => {
    return (
      settings?.useNormalizeBy === undefined ||
      (!settings?.useNormalizeBy && !globalSettings?.normalizeBy) ||
      (settings?.useNormalizeBy &&
        !!globalSettings?.normalizeBy &&
        JSON.stringify(settings?.normalizeBy) ===
          JSON.stringify(globalSettings?.normalizeBy))
    );
  };

  const openLegendConditionally = (condition) => {
    if (instance && condition) {
      dispatch({
        type: "settings",
        payload: {
          ...settings,
          legend: {
            visible: !settings.legend.visible,
            position: settings.legend.position
          }
        }
      });
    }
  };

  return useMemo(() => {
    const usingGlobalFocus: boolean = !settings?.useChartFocus;
    const usingGlobalTimestep: boolean =
      !settings?.timeStep || globalSettings?.timeStep === settings?.timeStep;
    const usingGlobalProduction: boolean =
      !settings?.source || globalSettings?.source === settings?.source;
    const usingGlobalNormalizeBy: boolean = isUsingGlobalNormalizeBy(
      settings,
      globalSettings
    );

    return {
      usingGlobalFocus,
      usingGlobalTimestep,
      usingGlobalProduction,
      usingGlobalNormalizeBy,
      usingAllGlobalSettings:
        usingGlobalFocus &&
        usingGlobalTimestep &&
        usingGlobalProduction &&
        usingGlobalNormalizeBy
    };
  }, [
    JSON.stringify(globalSettings?.normalizeBy),
    globalSettings?.source,
    globalSettings?.timeStep,

    JSON.stringify(settings?.normalizeBy),
    settings?.useNormalizeBy,
    settings?.useChartFocus,
    settings?.timeStep,
    settings?.source
  ]);
};

export default useChartVsGlobalSettings;
