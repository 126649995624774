import { memo } from "react";

function BoxPlot() {
  return (
    <svg width="23px" height="25px" viewBox="0 0 23 25">
      <g id="Iconschart-typebox-plot" transform="translate(1.125 1.125)">
        <path
          d="M0 0L0 20L20 20"
          transform="translate(0 2)"
          id="Path-3-Copy-5"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.776448 15.7947L0.776448 0"
          transform="translate(6.312912 3.1589339)"
          id="Path-4"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="1.5794669"
        />
        <path
          d="M0.776448 15.7947L0.776448 0"
          transform="translate(15.630285 0)"
          id="Path-4-Copy-2"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="1.5794669"
        />
        <path
          d="M0 0L6.21158 0L6.21158 9.4768L0 9.4768L0 0Z"
          transform="translate(3.983569 6.3178678)"
          id="Rectangle"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 0L6.21158 0L6.21158 9.4768L0 9.4768L0 0Z"
          transform="translate(13.300942 3.1589339)"
          id="Rectangle-Copy"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default memo(BoxPlot);
