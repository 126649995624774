import { memo, useEffect, useState } from "react";

import "./BaseRadioInput.scss";

function BaseRadioInput({
  label = "",
  name = "",
  value = "",
  modelValue = "",
  disabled = false,
  onChange
}) {
  function update(event) {
    const checked = event.target.checked;
    setChecked(checked);
    onChange && onChange(modelValue);
  }
  useEffect(() => {
    setChecked(modelValue === value);
  }, [modelValue, value]);
  const [isChecked, setChecked] = useState(modelValue === value);

  return (
    <div className={`radio-input inline-flex items-center ${isChecked ? "checked" : ""}`}>
      <span className="radio flex flex-centered" />

      {!!label && (
        <label className={`radio-label ${isChecked ? "active" : ""}`}>{label}</label>
      )}

      <input
        className="input absolute-center fill"
        type="radio"
        name={name}
        disabled={disabled}
        defaultChecked={isChecked}
        onChange={update}
      />
    </div>
  );
}

export default memo(BaseRadioInput);
