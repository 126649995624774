import { useMutation } from "react-query";

import { getFavouriteColumns } from "api/column";

import { EntityKind } from "../../../models/entityKind";

export default function useGetFavouriteColumns(
  entityKind: EntityKind,
  handleSuccess: (postData) => void,
  handleError: (err) => void = null
) {
  const { isLoading, isError, error, mutate, data } = useMutation(
    "useGetFavouriteColumns",
    async () => {
      return await getFavouriteColumns(entityKind);
    },
    {
      onSuccess: (postData) => {
        handleSuccess(postData.data);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );

  return { isLoading, isError, error, mutate, data };
}
