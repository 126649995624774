import { useCallback } from "react";

import { Business, Group, Person, Warning } from "@material-ui/icons";
import { Input, Select } from "antd";
import styled from "styled-components/macro";

import { useUser } from "hooks";

import InputCollectionHeader from "components/base/InputCollectionHeader";
import { useChartSettings } from "components/multiphase-chart/context";
import {
  changePreset as changePresetAction,
  setName,
  setRoleType
} from "components/multiphase-chart/context/reducer";
import { IChartPreset } from "components/multiphase-chart/models/shared.models";

import Tabs from "../Tabs";

export interface ISettings {
  savedPresets: IChartPreset[];
}

const Settings = ({ savedPresets }: ISettings) => {
  const { user, isReadonly, isUser } = useUser();
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const changePreset = useCallback(
    (selectedPreset) => {
      const currentPreset = savedPresets.find(
        (currentPreset) => currentPreset.name === selectedPreset
      );

      changePresetAction(chartSettingsDispatch, currentPreset);
    },
    [chartSettingsDispatch, savedPresets]
  );

  const savedPresetOptions = savedPresets?.map((currentPreset: { name }) => ({
    label: currentPreset.name,
    value: currentPreset.name
  }));

  // TODO: Create a separate component for the role type. Duplicated from ProjectInput
  const getRoleTypeOptions = useCallback(() => {
    const options = [
      {
        label: (
          <ProjectTypeItem>
            <Person /> Personal
          </ProjectTypeItem>
        ),
        value: "Personal"
      },
      {
        label: (
          <ProjectTypeItem>
            <Group /> Team
          </ProjectTypeItem>
        ),
        value: "Shared"
      },
      {
        label: (
          <ProjectTypeItem>
            <Business /> Organization
          </ProjectTypeItem>
        ),
        value: "Organization",
        disabled: isUser || (isReadonly && !chartSettings.isViewMode)
      }
    ];

    return options;
  }, [chartSettings.isViewMode, user.role]);

  return (
    <ColumnSetDropdownWrapper data-testid={"settingsContainer"}>
      <ChartTypeMenuContainer>
        {chartSettings.isViewMode ? (
          <>
            <SelectWrapper
              placeholder={"Select a Preset"}
              popupClassName="modal-select"
              options={savedPresetOptions}
              onChange={(selectedPreset: string) => {
                changePreset(selectedPreset);
              }}
              // replace with savedPresets with selected Preset
              value={chartSettings.storedPreset?.name ?? undefined}
            />
          </>
        ) : (
          <>
            <Input
              onChange={(e) => setName(chartSettingsDispatch, e.target.value)}
              placeholder={"New Name"}
              required={true}
              value={chartSettings.currentPreset?.name}
            />
          </>
        )}
        <InputCollectionHeader header="Type" />
        <SelectWrapper
          popupClassName="modal-select"
          value={chartSettings.currentPreset?.roleType ?? undefined}
          disabled={chartSettings.isViewMode}
          onChange={(roleType: string) => setRoleType(chartSettingsDispatch, roleType)}
          options={getRoleTypeOptions()}
        />
        {chartSettings.currentPreset?.roleType !== chartSettings.storedPreset?.roleType &&
          chartSettings.currentPreset?.id && (
            <WarningContainer>
              <Warning style={{ top: 3 }} /> Changing the preset type can impact users who
              previously had access to the preset.
            </WarningContainer>
          )}
        <Tabs />
      </ChartTypeMenuContainer>
    </ColumnSetDropdownWrapper>
  );
};

export default Settings;

const ColumnSetDropdownWrapper = styled.div`
  width: 375px;
  height: 472px;
  overflow-y: auto;
`;

const ChartTypeMenuContainer = styled.div`
  padding-bottom: 25px;
`;

const SelectWrapper = styled(Select)`
  width: 100%;
`;

const ProjectTypeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const WarningContainer = styled.div`
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
`;
