import { ICheckedForecast } from "store/features";

import { IArpsState } from "../ArpsContext";

type ArpsReducerActionsT = { type: "setCheckedForecasts"; forecasts: ICheckedForecast[] };

const arpsReducer = (draft: IArpsState, action: ArpsReducerActionsT) => {
  switch (action.type) {
    case "setCheckedForecasts": {
      // draft.checkedForecasts = action.forecasts;
      break;
    }

    default: {
      throw new Error("invalid chart settings reducer action");
    }
  }
};

// module functions
// const setCheckedForecasts = (dispatch: ArpsDispatchT, forecasts: ICheckedForecast[]) =>
//   dispatch({ type: "setCheckedForecasts", forecasts });

export { arpsReducer };

export type { ArpsReducerActionsT };
