import { MinMaxT } from "../ChartWrapper";
import { BaseChart } from "./baseChart";

export class TotalRateDate extends BaseChart {
  getSeriesStackGroup(item): string {
    return item?.label !== "Well Count" ? "stack" : "";
  }

  getDefaultAxisMinMax(): MinMaxT {
    return {
      xMin: "2000-01",
      xMax: "2050-01",
      yMin: null,
      yMax: null
    };
  }

  getDefaultAxis(sessionMinMax?: MinMaxT): MinMaxT {
    const defaultMinMax = this.getDefaultAxisMinMax();
    if (!sessionMinMax) {
      return defaultMinMax;
    }
    const yMax = !isNaN(parseFloat(sessionMinMax?.yMax?.toString()))
      ? parseFloat(sessionMinMax?.yMax?.toString())
      : defaultMinMax.yMax;
    const yMin = !isNaN(parseFloat(sessionMinMax?.yMin?.toString()))
      ? parseFloat(sessionMinMax?.yMin?.toString())
      : defaultMinMax.yMin;
    const xMax = sessionMinMax?.xMax ?? defaultMinMax.xMax;
    const xMin = sessionMinMax?.xMin ?? defaultMinMax.xMin;
    return {
      xMin,
      xMax,
      yMin,
      yMax
    };
  }
}
