import { Typography } from "antd";
import styled from "styled-components";

import { BaseDivider } from "components/base";

import { DefaultDeclineType, TypeWellSettings } from "./components/declines";
import DefaultDashboard from "./components/declines/DefaultDashboard";

const { Title } = Typography;

export default function DeclineSettings() {
  return (
    <>
      <RootContainer>
        <HeaderContainer>
          <Title level={3}>Decline Settings</Title>
        </HeaderContainer>
        <ContentContainer>
          <BaseDivider />
          <SectionBody>
            <Wrapper>
              <DefaultDeclineType />
              <TypeWellSettings />
              <DefaultDashboard />
            </Wrapper>
          </SectionBody>
        </ContentContainer>
      </RootContainer>
    </>
  );
}

//Styled  Components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
  padding-right: 100px;
`;

const ContentContainer = styled.div`
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
  padding-right: 100px;
`;

const RootContainer = styled.div`
  gap: 10px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  text-align: left;
  padding: 32px 100px;
  overflow: auto;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  .section {
    padding: 33px 0;
  }
  .section-heading {
    font-size: 1.6rem;
    padding-bottom: 22px;
    font-weight: var(--fontWeightMedium);
    padding: 0;
  }
`;

const SectionBody = styled.div`
  padding: 33px 0;
`;
