import { useState } from "react";

import { Popover } from "antd";

import IconToggleButton from "../ToggleButton";
import { StyledArrowDownIcon } from "../shared";
import BatchOperations from "./BatchOperations";

const BatchOperationsToggle = ({ onSelect }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<BatchOperations onSelect={onSelect} />}
      overlayClassName="batch-options"
      placement="bottomLeft"
      trigger="click"
      onOpenChange={(v) => setVisible(v)}>
      <IconToggleButton value={visible} onClick={() => setVisible(true)}>
        <StyledArrowDownIcon fontSize="large" visible={visible.toString()} />
      </IconToggleButton>
    </Popover>
  );
};

export default BatchOperationsToggle;
