import PresetSelect from "./PresetSelect";
import ScreenshotSettingItem from "./ScreenshotSettingItem";
import ScreenshotSettingItemInput from "./ScreenshotSettingItemInput";

type CompoundComponent = {
  Item: typeof ScreenshotSettingItem;
  Input: typeof ScreenshotSettingItemInput;
};

const ScreenshotSetting: CompoundComponent = {
  Item: ScreenshotSettingItem,
  Input: ScreenshotSettingItemInput
};

export { ScreenshotSetting, PresetSelect };
