import { memo } from "react";

function TubingPressure() {
  return (
    <svg viewBox="0 0 24 24" width="36" height="36" fill="currentColor">
      <defs />
      <path d="M 16.403 5.799 L 16.403 7.116 L 16.985 7.116 L 16.985 16.329 C 16.985 17.782 18.028 18.961 19.313 18.961 C 20.599 18.961 21.642 17.782 21.642 16.329 L 21.642 7.116 L 22.224 7.116 L 22.224 5.799 L 16.403 5.799 M 18.731 15.013 C 18.382 15.013 18.149 14.749 18.149 14.354 C 18.149 13.96 18.382 13.696 18.731 13.696 C 19.08 13.696 19.313 13.96 19.313 14.354 C 19.313 14.749 19.08 15.013 18.731 15.013 M 19.896 12.38 C 19.547 12.38 19.313 12.117 19.313 11.722 C 19.313 11.328 19.547 11.064 19.896 11.064 C 20.245 11.064 20.478 11.328 20.478 11.722 C 20.478 12.117 20.245 12.38 19.896 12.38 M 20.478 9.09 L 18.149 9.09 L 18.149 7.116 L 20.478 7.116 L 20.478 9.09 Z" />
      <path d="M 8.311 4.859 C 4.283 4.859 1.018 8.188 1.018 12.294 C 1.018 16.401 4.283 19.73 8.311 19.73 C 12.339 19.73 15.604 16.401 15.604 12.294 C 15.604 8.188 12.339 4.859 8.311 4.859 M 8.311 6.346 C 11.533 6.346 14.146 9.009 14.146 12.294 C 14.146 14.079 13.416 15.64 12.177 16.756 C 11.156 15.789 9.77 15.269 8.311 15.269 C 6.853 15.269 5.54 15.789 4.446 16.756 C 3.206 15.64 2.477 14.079 2.477 12.294 C 2.477 9.009 5.089 6.346 8.311 6.346 M 9.77 7.751 C 9.493 7.759 9.23 7.945 9.113 8.234 L 8.173 10.636 L 8.1 10.807 C 7.582 10.904 7.152 11.253 6.955 11.744 C 6.656 12.51 7.02 13.373 7.771 13.677 C 8.523 13.982 9.369 13.61 9.668 12.845 C 9.857 12.354 9.77 11.789 9.456 11.372 L 9.529 11.179 L 10.47 8.792 L 10.477 8.77 C 10.623 8.391 10.441 7.959 10.069 7.803 C 9.974 7.766 9.879 7.751 9.77 7.751 M 6.853 7.833 C 6.45 7.833 6.123 8.166 6.123 8.577 C 6.123 8.988 6.45 9.32 6.853 9.32 C 7.255 9.32 7.582 8.988 7.582 8.577 C 7.582 8.166 7.255 7.833 6.853 7.833 M 4.665 10.064 C 4.262 10.064 3.935 10.397 3.935 10.807 C 3.935 11.218 4.262 11.551 4.665 11.551 C 5.067 11.551 5.394 11.218 5.394 10.807 C 5.394 10.397 5.067 10.064 4.665 10.064 M 11.958 10.064 C 11.555 10.064 11.228 10.397 11.228 10.807 C 11.228 11.218 11.555 11.551 11.958 11.551 C 12.36 11.551 12.687 11.218 12.687 10.807 C 12.687 10.397 12.36 10.064 11.958 10.064 Z" />
    </svg>
  );
}

export default memo(TubingPressure);
