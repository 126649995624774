import { useQuery } from "react-query";

import axios from "axios";

const JOB_SERVICE_URL = process.env.REACT_APP_JOB_SERVICE;

export const VALNAV_HIERARCHIES_QUERY_KEYS = {
  valNavHierarchies: "valNavHierarchies"
};

const fetchHierarchies = async ({ queryKey }) => {
  try {
    const [, job] = queryKey;
    return await axios.get<string[]>(`${JOB_SERVICE_URL}/api/v1/hierarchy/${job}`);
  } catch (error) {
    throw new Error("Unable to fetch Hierarchies for job");
  }
};

export default function useValNavHierarchiesQuery(
  job: string,
  isAutoRefetching?: boolean
) {
  const nextIsAutoFetching =
    isAutoRefetching === undefined ? !!job : !!(isAutoRefetching && job);

  const queryInfo = useQuery({
    queryKey: [VALNAV_HIERARCHIES_QUERY_KEYS.valNavHierarchies, job],
    queryFn: fetchHierarchies,
    enabled: nextIsAutoFetching
  });

  return {
    data: queryInfo.data?.data,
    error: queryInfo.error,
    isLoading: queryInfo.isFetching,
    isError: queryInfo.isError,
    refetch: queryInfo.refetch
  };
}
