import { useQuery } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { MapLayer } from "../models/mapLayer";

const API_URL = `${GEO_MAP_SERVICE_ADDRESS}/map-layer`;

const getGeoMapLayers = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data.map((layer) => {
      layer.maps = layer.maps.filter((map) => {
        return (map.Calculation ?? map.Raster)?.deleted == null;
      });
      return layer;
    });
  } catch (error) {
    // If the request fails, return an empty array
    return [];
  }
};

const useGeoMapLayers = () => {
  return useQuery<MapLayer[]>("geoMapLayers", getGeoMapLayers);
};

export default useGeoMapLayers;
