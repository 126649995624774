import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import type { MenuProps } from "antd";
import _findIndex from "lodash/findIndex";
import { RootState } from "store/rootReducer";

import { EntityKind } from "models/entityKind";

import { useUserDefaults } from "components/user/hooks";

const useRecentFocusItems = (entityKind: EntityKind) => {
  // state
  const [list, setList] = useState<MenuProps["items"]>([
    {
      key: "-1",
      label: "No recent items."
    }
  ]);

  const defaults = useUserDefaults();
  const presetFields =
    (entityKind === EntityKind.Well
      ? defaults.focusTiles?.tiles
      : defaults.facilityFocusTiles?.tiles) ?? [];

  // state from store
  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const facilityGroupBy = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );
  const recentFocus = useSelector((state: RootState) =>
    entityKind === EntityKind.Well
      ? state.groupBy.recentWellFocus
      : state.groupBy.recentFacilityFocus
  );

  // derived state
  const isPresetFieldSelected =
    _findIndex(presetFields, (p) => {
      const property =
        entityKind === EntityKind.Well ? groupBy.property : facilityGroupBy.property;
      return p === property;
    }) < 0;

  // update list
  useEffect(() => {
    if (!recentFocus.length) return;

    const nextList = recentFocus.map((preset) => ({
      key: JSON.stringify({ property: preset.property, dataType: preset.dataType }),
      label: preset.title
    })) as MenuProps["items"];

    setList(nextList);
  }, [recentFocus]);

  return { recentFocusItems: list, isPresetFieldSelected };
};

export default useRecentFocusItems;
