import { memo } from "react";

function WellList({ size = 24 }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 24 24">
      <g id="edit-2">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="Rectangle"
          fill="currentColor"
          fillOpacity="0"
          stroke="none"
        />
        <path
          d="M15 0L1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2L15 2C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0Z"
          transform="translate(4 20)"
          id="Path"
          fill="currentColor"
          stroke="none"
        />
        <path
          d="M1.00421 15.9422L1.09421 15.9422L5.26421 15.5622C5.721 15.5167 6.14824 15.3155 6.47421 14.9922L15.4742 5.99224C16.2015 5.22391 16.1702 4.01201 15.4042 3.28224L12.6642 0.542243C11.9239 -0.153103 10.78 -0.183205 10.0042 0.472243L1.00421 9.47224C0.680973 9.79821 0.479712 10.2254 0.434208 10.6822L0.00420769 14.8522C-0.0230663 15.1488 0.0832446 15.4421 0.294208 15.6522C0.482927 15.8394 0.738405 15.9438 1.00421 15.9422ZM11.2742 1.94224L14.0042 4.67224L12.0042 6.62224L9.32421 3.94224L11.2742 1.94224Z"
          transform="translate(3.9960938 2.0578613)"
          id="Shape"
          fill="currentColor"
          stroke="none"
        />
      </g>
    </svg>
  );
}
export default memo(WellList);
