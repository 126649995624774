import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Clear } from "@material-ui/icons";
import { Button } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { IGroupByMap } from "models";

import { BaseButton } from "components/base";
import GroupBy from "components/groupBy";

export interface XdaDataPickerModel {
  onChange: (fields: IGroupByMap[]) => void;
}

///component for picking 4 data fields in clockwise direction starting with south
export default function XdaDataPicker(input: XdaDataPickerModel) {
  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);

  const [bottomValue, setBottomValue] = useState<IGroupByMap>(groupBy);
  const [topValue, setTopValue] = useState<IGroupByMap>({
    title: "Entity Name",
    property: "Header.EntityName" //need something here or else group by will throw error
  });
  const [leftValue, setLeftValue] = useState<IGroupByMap>({
    title: "Zone",
    property: "Geology.Zone"
  });
  const [rightValue, setRightValue] = useState<IGroupByMap>({
    title: "Vintage (Year)",
    property: "Dates.Vintage_Year"
  });

  useEffect(() => {
    if (!input.onChange) {
      return;
    }
    input.onChange([bottomValue, leftValue, topValue, rightValue]);
  }, [bottomValue, topValue, leftValue, rightValue]);

  useEffect(() => {
    setBottomValue(groupBy);
  }, [groupBy]);

  return (
    <Container>
      <BottomFieldSelector>
        <GroupBy onChange={setBottomValue} value={bottomValue}>
          <ButtonOverlay>
            <BaseButton>{bottomValue?.title ?? "Select Field"}</BaseButton>
            {bottomValue?.title && (
              <Button
                className="clear-btn"
                type="link"
                title="Clear"
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  setBottomValue({ title: undefined, property: "None.Bottom" });
                }}
                icon={<Clear />}
              />
            )}
          </ButtonOverlay>
        </GroupBy>
      </BottomFieldSelector>
      <LeftFieldSelector>
        <GroupBy onChange={setLeftValue} value={leftValue}>
          <ButtonOverlay>
            <BaseButton>{leftValue?.title ?? "Select Field"}</BaseButton>
            {leftValue?.title && (
              <Button
                className="clear-btn"
                type="link"
                title="Clear"
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  setLeftValue({ title: undefined, property: "None.Left" });
                }}
                icon={<Clear />}
              />
            )}
          </ButtonOverlay>
        </GroupBy>
      </LeftFieldSelector>
      <TopFieldSelector>
        <GroupBy onChange={setTopValue} value={topValue}>
          <ButtonOverlay>
            <BaseButton>{topValue?.title ?? "Select Field"}</BaseButton>
            {topValue?.title && (
              <Button
                className="clear-btn"
                type="link"
                title="Clear"
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  setTopValue({ title: undefined, property: "None.Top" });
                }}
                icon={<Clear />}
              />
            )}
          </ButtonOverlay>
        </GroupBy>
      </TopFieldSelector>

      <RightFieldSelector>
        <GroupBy onChange={setRightValue} value={rightValue}>
          <ButtonOverlay>
            <BaseButton>{rightValue?.title ?? "Select Field"}</BaseButton>
            {rightValue?.title && (
              <Button
                className="clear-btn"
                type="link"
                title="Clear"
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  setRightValue({ title: undefined, property: "None.Right" });
                }}
                icon={<Clear />}
              />
            )}
          </ButtonOverlay>
        </GroupBy>
      </RightFieldSelector>
      <DotWrapper>
        <Dot />
      </DotWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 120px;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(1fr, 80px) minmax(1fr, 80px) minmax(1fr, 80px);
  grid-template-rows: minmax(1fr, 80px) minmax(1fr, 80px) minmax(1fr, 80px);
`;

const FieldSelectorWrapper = styled.div`
  align-self: center;
  justify-self: center;
  span.label {
    width: 80px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const DotWrapper = styled.div`
  grid-row-start: 2;
  grid-column-start: 2;
  align-self: center;
  justify-self: center;
`;

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
`;

const ButtonOverlay = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .clear-btn {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: var(--color-primary);
    }
  }
`;

const TopFieldSelector = styled(FieldSelectorWrapper)`
  grid-row-start: 1;
  grid-column-start: 2;
`;
const LeftFieldSelector = styled(FieldSelectorWrapper)`
  grid-row-start: 2;
  grid-column-start: 1;
`;
const BottomFieldSelector = styled(FieldSelectorWrapper)`
  grid-row-start: 3;
  grid-column-start: 2;
`;
const RightFieldSelector = styled(FieldSelectorWrapper)`
  grid-row-start: 2;
  grid-column-start: 3;
`;
