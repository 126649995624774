import { getProductLabel } from "constants/chart.constants";
import { MCDANIEL_SEARCH_FOLDER_NAME } from "constants/settings.constants";
import { getCumPlusForecastTitle } from "utils";

import { IGroupByListColumn } from "models";
import { ColumnLabelContext } from "models/columns";
import { EntityKind } from "models/entityKind";

import { geoVolumeCategoryTitle } from "components/groupBy/hooks/useCategories";

const getColumnLabelWithContext = (
  property: IGroupByListColumn,
  globalUseNormalizeBy: boolean,
  globalNormalizeBy: {
    field: string;
    display: string;
    per: number;
    unit: string;
    useMultilinearNormalization: boolean;
  },
  isForecastToggleOn?: boolean,
  selectedForecastFolder?: string,
  entityKind: string = EntityKind.Well
): ColumnLabelContext => {
  const contextLabels = {
    fullContextTitle: "",
    fullContextTitleWithNormalization: "",
    lightContextTitle: "",
    partialContextTitle: ""
  };

  if (!property || !property.group) {
    return contextLabels;
  }

  let title = property.title;
  if (isForecastToggleOn) {
    title = getCumPlusForecastTitle(title, "", property.subgroup);
  }

  // Full Context
  if (property.group === "Forecast") {
    if (
      selectedForecastFolder !== MCDANIEL_SEARCH_FOLDER_NAME &&
      selectedForecastFolder !== ""
    ) {
      contextLabels.fullContextTitle = `${getProductLabel(
        property.product,
        entityKind
      )}: ${title} (${selectedForecastFolder})`;
      contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
    } else {
      contextLabels.fullContextTitle = `${getProductLabel(
        property.product,
        entityKind
      )}: ${title}`;
      contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
    }
  } else if (property.group === "Performance") {
    if (isForecastToggleOn && selectedForecastFolder !== MCDANIEL_SEARCH_FOLDER_NAME) {
      if (property.defaultUnit) {
        contextLabels.fullContextTitle = `${getProductLabel(
          property.product,
          entityKind
        )}: ${title} (${selectedForecastFolder})`;
        contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
      } else {
        contextLabels.fullContextTitle = `${getProductLabel(
          property.product,
          entityKind
        )}: ${title} (${selectedForecastFolder})`;
        contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
      }
    } else {
      if (property.defaultUnit) {
        contextLabels.fullContextTitle = `${getProductLabel(
          property.product,
          entityKind
        )}: ${title}`;
        contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
      } else {
        contextLabels.fullContextTitle = `${getProductLabel(
          property.product,
          entityKind
        )}: ${title}`;
        contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
      }
    }
  } else if (
    property.property.startsWith("Org_Focus_Fields") ||
    property.property.startsWith("My_Focus_Fields")
  ) {
    if (property.defaultUnit) {
      contextLabels.fullContextTitle = `${property.group}: ${property.title} (${property.defaultUnit})`;
      contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
    } else {
      contextLabels.fullContextTitle = `${property.group}: ${property.title}`;
      contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
    }
  } else {
    if (property.defaultUnit) {
      contextLabels.fullContextTitle = `${property.title}`;
      contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
    } else {
      contextLabels.fullContextTitle = `${property.title}`;
      contextLabels.fullContextTitleWithNormalization = contextLabels.fullContextTitle;
    }
  }

  // Full Context with Normalization for Performance fields
  if (property.group === "Performance" && globalNormalizeBy && globalUseNormalizeBy) {
    const fieldUnit = property.title.match(/\((.*?)\)/);
    const unitlessField = title.replace(/\s*\(.*?\)\s*/g, "");
    const unitlessNormalize = globalNormalizeBy.display.replace(/\s*\(.*?\)\s*/g, "");
    const normalizeUnit = globalNormalizeBy.useMultilinearNormalization
      ? " (Normalized)"
      : `/${globalNormalizeBy.per}${globalNormalizeBy.unit}`;
    if (isForecastToggleOn && selectedForecastFolder !== MCDANIEL_SEARCH_FOLDER_NAME) {
      contextLabels.fullContextTitleWithNormalization = `${getProductLabel(
        property.product,
        entityKind
      )}: ${unitlessField} (${selectedForecastFolder})/${unitlessNormalize} ${
        fieldUnit ? `${fieldUnit[0]}${normalizeUnit}` : `${normalizeUnit}`
      }`;
    } else {
      contextLabels.fullContextTitleWithNormalization = `${getProductLabel(
        property.product,
        entityKind
      )}: ${unitlessField}/${unitlessNormalize} ${
        fieldUnit ? `${fieldUnit[0]}${normalizeUnit}` : `${normalizeUnit}`
      }`;
    }
  }

  // Light Context
  if (property.group === "Forecast") {
    contextLabels.lightContextTitle = `${title}`;
  } else if (property.group === "Performance") {
    contextLabels.lightContextTitle = `${title}`;
  } else if (
    property.property.startsWith("Org_Focus_Fields") ||
    property.property.startsWith("My_Focus_Fields")
  ) {
    if (property.defaultUnit) {
      contextLabels.lightContextTitle = `${property.title} (${property.defaultUnit})`;
    } else {
      contextLabels.lightContextTitle = `${property.title}`;
    }
  } else {
    contextLabels.lightContextTitle = `${property.title}`;
  }

  // Partial Context
  if (property.group === "Forecast") {
    contextLabels.partialContextTitle = `${getProductLabel(
      property.product,
      entityKind
    )}: ${title}`;
  } else if (property.group === "Performance") {
    contextLabels.partialContextTitle = `${getProductLabel(
      property.product,
      entityKind
    )}: ${title}`;
  } else if (property.group === geoVolumeCategoryTitle) {
    contextLabels.partialContextTitle = `${property.title}`;
  }

  if (property.group === geoVolumeCategoryTitle) {
    if (
      property?.metadata?.type === "Summary" &&
      !contextLabels.fullContextTitle.includes(property?.metadata?.radius)
    ) {
      contextLabels.fullContextTitle = `${contextLabels.fullContextTitle} (${property?.metadata?.radius})`;
      contextLabels.fullContextTitleWithNormalization = `${contextLabels.fullContextTitleWithNormalization} (${property?.metadata?.radius})`;
      contextLabels.partialContextTitle = `${contextLabels.partialContextTitle} (${property?.metadata?.radius})`;
    }
  }
  return contextLabels;
};

export default getColumnLabelWithContext;
