import { useMutation, useQueryClient } from "react-query";

import axios, { AxiosError } from "axios";

import { GEO_CALCULATION_SERVICE_ADDRESS } from "../modals/constants";
import { GeoCalculationsJob } from "../models/GeoCalculations";

export function useUpdateGeoCalculation() {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError, GeoCalculationsJob>(
    async (data: GeoCalculationsJob) => {
      const response = await axios.put(
        `${GEO_CALCULATION_SERVICE_ADDRESS}/geo-calculation/jobs/${data.jobId}`,
        data
      );
      if (response.status !== 200) {
        throw new Error("Error updating geo calculation job.");
      }

      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("geoCalculations");
      }
    }
  );
}
