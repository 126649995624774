import { memo } from "react";

function Navigate() {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
      <path
        d="M8.31186 14.5573L8.0072 13.948C7.02064 11.9734 6.02958 10.0593 4.76004 8.33921C3.94639 7.23437 3.60281 5.86977 3.79369 4.49546C4.11632 2.17648 6.04004 0.300651 8.36951 0.0356682C9.86134 -0.13949 11.3577 0.336545 12.47 1.32984C13.5831 2.32388 14.2215 3.74911 14.2215 5.24019C14.2215 6.23051 13.9423 7.19539 13.4139 8.03076L12.9872 8.7022C12.0508 10.1738 11.0822 11.6956 10.297 13.2668L9.65172 14.5573C9.5245 14.811 9.26548 14.9712 8.98179 14.9712C8.69811 14.9712 8.43913 14.811 8.31186 14.5573ZM11.2274 5.24023C11.2274 4.00216 10.2199 2.99462 8.98179 2.99462C7.74372 2.99462 6.73618 4.00216 6.73618 5.24023C6.73618 6.4783 7.74372 7.48584 8.98179 7.48584C10.2199 7.48584 11.2274 6.4783 11.2274 5.24023ZM8.9829 5.98875C9.39609 5.98875 9.73143 5.65342 9.73143 5.24023C9.73143 4.82703 9.39609 4.4917 8.9829 4.4917C8.56971 4.4917 8.23438 4.82703 8.23438 5.24023C8.23438 5.65342 8.56971 5.98875 8.9829 5.98875ZM13.6451 10.9844C13.2439 10.8871 12.8396 11.1378 12.7446 11.5398C12.6488 11.9417 12.898 12.3452 13.3 12.4403C15.6249 12.9919 16.4678 13.8078 16.4678 14.2225C16.4678 15.117 13.4827 16.4682 8.98246 16.4682C4.48298 16.4682 1.49709 15.117 1.49709 14.2225C1.49709 13.8078 2.3407 12.9919 4.66414 12.4403C5.06684 12.3452 5.31537 11.9417 5.21954 11.5398C5.12449 11.1378 4.72028 10.887 4.31905 10.9844C1.53375 11.6446 0 12.7943 0 14.2225C0 16.6792 4.51968 17.9652 8.98246 17.9652C13.446 17.9652 17.9649 16.6792 17.9649 14.2225C17.9649 12.7943 16.4312 11.6446 13.6451 10.9844Z"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}
export default memo(Navigate);
