import { PreferredColor } from "./UserPreferredColors";

export enum PaletteType {
  Continuous = 0,
  Discrete = 1
}

interface ColorScale {
  index: number;
  rgbColor: string;
}

type ColorPaletteBase = {
  id: string;
  name: string;
  preferredColors?: PreferredColor[];
  thickness: number[];
  shared: boolean;
  paletteType: PaletteType;
  reverse?: boolean;
  specialColors?: ColorPaletteSpecialColors;
};

type ColorPaletteSpecialColors = {
  notApplicableColor?: string;
  otherBinColor?: string;
};

type ColorPaletteWithColors = ColorPaletteBase & { colors: string[]; colorScale?: never };
type ColorPaletteWithColorScale = ColorPaletteBase & {
  colorScale: ColorScale[];
  colors?: never;
};

export type IColorPalette = ColorPaletteWithColors | ColorPaletteWithColorScale;

export interface Palettes {
  palettes: IColorPalette[];
}

export const DEFAULT_COLOR_PALETTE_NAME = "Portland";

export const DEFAULT_FACILITY_COLOR_PALETTE_NAME = "Viridis";

// Matches the color scale for the default facility color palette name.
// Taken from the "defaultColorPalette" collection in MongoDB.
export const DEFAULT_FACILITY_COLOR_SCALE: ColorScale[] = [
  {
    index: 0,
    rgbColor: "rgb(68, 1, 84)"
  },
  {
    index: 0.13,
    rgbColor: "rgb(71, 44, 122)"
  },
  {
    index: 0.25,
    rgbColor: "rgb(59, 81, 139)"
  },
  {
    index: 0.38,
    rgbColor: "rgb(44, 113, 142)"
  },
  {
    index: 0.5,
    rgbColor: "rgb(33, 144, 141)"
  },
  {
    index: 0.63,
    rgbColor: "rgb(39, 173, 129)"
  },
  {
    index: 0.75,
    rgbColor: "rgb(92, 200, 99)"
  },
  {
    index: 0.88,
    rgbColor: "rgb(170, 220, 50)"
  },
  {
    index: 1,
    rgbColor: "rgb(253, 231, 37)"
  }
];

export const DEFAULT_COLOR_SCALE: ColorScale[] = [
  {
    index: 0,
    rgbColor: "rgb(12, 51, 131)"
  },
  {
    index: 0.25,
    rgbColor: "rgb(10, 136, 186)"
  },
  {
    index: 0.5,
    rgbColor: "rgb(242, 211, 56)"
  },
  {
    index: 0.75,
    rgbColor: "rgb(242, 143, 56)"
  },
  {
    index: 1,
    rgbColor: "rgb(217, 30, 30)"
  }
];
