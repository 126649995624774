// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert } from "@mdi/js";
import { Typography } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { RadioTab } from "components/base/RadioTabs";
import { getWidgetPreview } from "components/dashboard/utils";
import { ColorEditor } from "components/shared";
import { USER_DASHBOARD_SETTING } from "components/user-settings/constants/userSetting.constants";
import { UserDashboardSettings } from "components/user-settings/models";
import { StyledRadioTabs } from "components/user-settings/shared/SharedStyle";
import { useUserContext } from "components/user/context";
import { updateSettingDashboard } from "components/user/context/reducer";
import { useUserSettingMutation, useUserSettings } from "components/user/hooks";

const { Title } = Typography;
const DashboardSettings = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const { userDashboardSettings } = useUserSettings();
  const mutation = useUserSettingMutation<UserDashboardSettings>(USER_DASHBOARD_SETTING);

  // state
  const [widgetBorderType, setWidgetBorderType] = useState(
    userDashboardSettings?.borderType
  );
  const [borderColor, setBorderColor] = useState(userDashboardSettings?.borderColor);
  const [borderThickness, setBorderThickness] = useState(
    userDashboardSettings?.borderThickness
  );
  const [updateError, setUpdateError] = useState("");

  const debouncedSettingsChange = _debounce((settings: UserDashboardSettings) => {
    mutation.mutate(settings);
  }, 400);

  const handleWidgetBorderTypeChange = useCallback(
    (value) => {
      setWidgetBorderType(value);

      debouncedSettingsChange({
        id: userDashboardSettings?.id,
        borderType: value,
        borderColor: userDashboardSettings.borderColor,
        borderThickness: userDashboardSettings.borderThickness
      });
    },
    [userDashboardSettings]
  );

  const handleBorderStyleChange = useCallback(
    (color: string, thickness: number) => {
      setBorderColor(color);
      setBorderThickness(thickness);

      debouncedSettingsChange({
        id: userDashboardSettings?.id,
        borderType: userDashboardSettings.borderType,
        borderColor: color,
        borderThickness: thickness
      });
    },
    [userDashboardSettings]
  );

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingDashboard(dispatch, {
      id: mutation.data.id,
      borderType: mutation.data.borderType,
      borderColor: mutation.data.borderColor,
      borderThickness: mutation.data.borderThickness
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update preferred dashboard layout.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  return (
    <Wrapper>
      <SectionContainer>
        <ExampleDashboard
          BorderType={widgetBorderType}
          borderColor={borderColor}
          borderThickness={borderThickness}>
          <ExampleWidget position={1}>{getWidgetPreview("map")}</ExampleWidget>
          <ExampleWidget position={2}>{getWidgetPreview("chart")}</ExampleWidget>
          <ExampleWidget position={3}>{getWidgetPreview("chart")}</ExampleWidget>
        </ExampleDashboard>
      </SectionContainer>

      <SectionContainer>
        <Title level={5} type="secondary">
          Widget Border Type
        </Title>

        <StyledRadioTabs
          data-testid="radio-border-type"
          name="unitsMeasures"
          value={widgetBorderType}
          onChange={handleWidgetBorderTypeChange}>
          <RadioTab
            value="borderless"
            label="Borderless"
            data-testid="radio-border-type-borderless"
          />
          <RadioTab
            value="bordered"
            label="Bordered"
            data-testid="radio-border-type-bordered"
          />
          <RadioTab
            value="topBorder"
            label="Top Border"
            data-testid="radio-border-type-topBorder"
          />
        </StyledRadioTabs>
      </SectionContainer>

      {widgetBorderType !== "borderless" && (
        <SectionContainer>
          <Title level={5} type="secondary">
            Widget Border Style
          </Title>
          <ColorEditorWrapper>
            <ColorEditor
              data-testid="color-editor-border"
              color={borderColor}
              thickness={borderThickness}
              onChange={handleBorderStyleChange}
            />
          </ColorEditorWrapper>
        </SectionContainer>
      )}

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </Wrapper>
  );
};

export default DashboardSettings;
const SectionContainer = styled.div``;

const Wrapper = styled.div`
  ${SectionContainer}:not(:last-child) {
    padding-bottom: 18px;
  }
`;

const ExampleWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.color};
  grid-column-start: ${(props) => (props.position === 1 ? 1 : 2)};
  grid-column-end: ${(props) => (props.position === 1 ? 2 : 3)};
  grid-row-start: ${(props) => (props.position === 1 ? 1 : props.position === 2 ? 1 : 2)};
  grid-row-end: ${(props) => (props.position === 1 ? 3 : props.position === 2 ? 2 : 3)};

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ExampleDashboard = styled.div`
  width: 200px;
  display: grid;
  grid-template-columns: auto auto;
  border: 1px solid #000;

  ${ExampleWidget} {
    border-style: solid;
    border-width: ${(props) => `${props.borderThickness}px`};
    border-color: ${(props) =>
      props.BorderType === "bordered" ? props.borderColor : "#fff"};
    border-top-color: ${(props) =>
      props.BorderType !== "borderless" ? props.borderColor : "#fff"};
  }
`;

const ColorEditorWrapper = styled.div`
  width: 100px;
  div {
    width: 100%;
  }
`;

const ErrorContainer = styled.div`
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
