import { useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import { Geometry, MultiPolygon, Polygon } from "@turf/helpers";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import { EXPLOITED_LAYER, UNEXPLOITED_LAYER } from "constants/mapLayers.constants";
import mapboxgl from "mapbox-gl";
import { RootState } from "store/rootReducer";
import { x2Lon, y2Lat } from "utils/sphericalMercator";

export default function useShowExploitableInMap(mapbox: mapboxgl.Map) {
  const dispatch = useDispatch();
  useHotkeys("esc", () => {
    removeLayer(mapboxRef.current, UNEXPLOITED_LAYER);
    removeLayer(mapboxRef.current, EXPLOITED_LAYER);
  });
  const mapboxRef = useRef(mapbox);

  const exploitableArea = useSelector((state: RootState) => state.map.exploitableArea);
  useEffect(() => {
    if (!IS_INTERNAL_ENV || !exploitableArea || exploitableArea.length < 2 || !mapbox) {
      return;
    }
    mapboxRef.current = mapbox;
    const unexploited = geomToLatLng(exploitableArea[0]);
    const exploited = geomToLatLng(exploitableArea[1]);

    if (unexploited) {
      removeLayer(mapbox, UNEXPLOITED_LAYER);
      addLayer(mapbox, UNEXPLOITED_LAYER, unexploited, "#9ecedb");
    }
    if (exploited) {
      removeLayer(mapbox, EXPLOITED_LAYER);
      addLayer(mapbox, EXPLOITED_LAYER, exploited, "#d6c149");
    }
    return () => {
      mapboxRef.current = null;
    };
  }, [dispatch, mapbox, exploitableArea]);

  return <></>;
}
function coordToLatLng(coord: number[]) {
  return [x2Lon(coord[0]), y2Lat(coord[1])];
}
function geomToLatLng(geom: Geometry): GeoJSON.Geometry {
  const geomCopy = Object.assign({}, geom);
  if (geomCopy.type === "Polygon") {
    const poly = geomCopy as Polygon;
    if (poly.coordinates) {
      const coords = poly.coordinates.map((item) => {
        return item.map(coordToLatLng);
      });
      poly.coordinates = coords;
      return poly;
    }
  }
  if (geomCopy.type === "MultiPolygon") {
    const polys = geomCopy as MultiPolygon;
    if (polys && polys.coordinates) {
      const coords = polys.coordinates.map((poly) =>
        poly.map((item) => {
          return item.map(coordToLatLng);
        })
      );
      polys.coordinates = coords;
      return polys;
    }
  }
  return null;
}
function removeLayer(mapbox: mapboxgl.Map, layer: string) {
  try {
    if (mapbox.getLayer(layer)) {
      mapbox.removeLayer(layer);
    }
    if (mapbox.getSource(layer)) {
      mapbox.removeSource(layer);
    }
    return true;
  } catch (err) {
    return false;
  }
}

function addLayer(
  mapbox: mapboxgl.Map,
  layer: string,
  geometry: GeoJSON.Geometry,
  color: string
) {
  try {
    mapbox.addSource(layer, {
      type: "geojson",
      data: geometry
    });
    const paint = {
      "fill-color": color,
      "fill-opacity": 0.8
    };

    mapbox.addLayer({
      id: layer,
      type: "fill",
      source: layer,
      layout: {
        visibility: "visible"
      },
      paint
    });
    return true;
  } catch (err) {
    return false;
  }
}
