import { removeCount, removeForecast } from "utils";

import { IChartResult } from "models/model";

import { EntityKind } from "../../../models/entityKind";
import { ChartDataTableColumn, ChartDataTableRow } from "../hooks/useDataTableData";
import { getPrecisionBasedOnUnit } from "../utils/getPrecisionBasedOnUnit";

// NOTE: Changing this logic should result in changes to the chart-svc backend export logic.
const getTotalRateDateDataTable = (
  responseData: IChartResult,
  xMinMax,
  entityKind: string
): { columns: ChartDataTableColumn[]; rows: ChartDataTableRow[] } => {
  const wellCounts = responseData.series?.find((series) => series.label === "Well Count");
  const columns = [
    { key: "X", name: responseData.layout.xAxis.title, resizable: false },
    { key: "Y", name: responseData.layout.yAxis.title, resizable: false },
    {
      key: "Y2",
      name: `${entityKind === EntityKind.Facility ? "Facility" : "Well"} Count`,
      resizable: false
    },
    { key: "production", name: "Production Source", resizable: false },
    { key: "forecast", name: "Forecast Source", resizable: false }
  ];
  const xPrecision = getPrecisionBasedOnUnit(responseData?.layout?.xAxis?.title);
  const yPrecision = getPrecisionBasedOnUnit(responseData?.layout?.yAxis?.title);
  const minDate = xMinMax?.xMin ?? null;
  const maxDate = xMinMax?.xMax ?? null;

  const rows = responseData.series?.reduce((list, series) => {
    for (let i = 0; i < series.y.length; i++) {
      const dateString = responseData.layout.xAxis.data[i];
      const x = new Date(dateString);
      if (
        (!minDate || x >= new Date(minDate)) &&
        (!maxDate || x <= new Date(maxDate)) &&
        series?.y[i] !== "" &&
        series.label !== "Well Count"
      ) {
        const x = responseData.layout.xAxis.data[i];
        const y = series.y[i];
        const row = {
          SeriesColor: series.style.hexColor,
          SeriesFull: series.label,
          Series: removeForecast(removeCount(series.label)),
          X: typeof x === "number" ? x.toFixed(xPrecision) : x,
          Y: typeof y === "number" ? y.toFixed(yPrecision) : y,
          Y2: wellCounts?.y ? wellCounts.y[i] : "",
          production: series.productionSource,
          forecast: series.forecastSource
        };
        list.push(row);
      }
    }
    return list;
  }, []);

  return {
    columns,
    rows
  };
};

export default getTotalRateDateDataTable;
