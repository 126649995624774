// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiAlert, mdiCalendarClock } from "@mdi/js";
import { Table } from "antd";
import styled from "styled-components";

import {
  DataSourcePerformanceResult,
  WellPerformanceDataAnalyzerResult
} from "api/appTools";

import { Tooltip } from "components/base";

export interface PerformanceDataAnalyzerTableProps {
  data: WellPerformanceDataAnalyzerResult;
  isLoading: boolean;
  analyzerError: string;
  analyzerErrorDetails: string;
}

const PerformanceDataAnalyzerTable = ({
  data,
  isLoading,
  analyzerError,
  analyzerErrorDetails
}: PerformanceDataAnalyzerTableProps) => {
  // State
  const [showAnalyzerErrorDetails, setShowAnalyzerErrorDetails] =
    useState<boolean>(false);

  // Table Columns
  const tableColumns = [
    {
      dataIndex: "pdenDataSource",
      key: "pdenDataSource",
      title: "Pden Data Source"
    },
    {
      dataIndex: "forecastSource",
      key: "forecastSource",
      title: "Forecast Source",
      render: (text) => {
        if (text.startsWith("unknown project")) {
          return (
            <span>
              <i>unknown project</i>
              {text.replace("unknown project", "")}
            </span>
          );
        } else if (text.startsWith("unknown folder")) {
          return (
            <span>
              <i>unknown folder</i>
              {text.replace("unknown folder", "")}
            </span>
          );
        }
        return <span>{text}</span>;
      }
    },
    {
      dataIndex: "rescat",
      key: "rescat",
      title: "Rescat"
    },
    {
      dataIndex: "dataFetcherResult",
      key: "dataFetcherResult",
      title: "Cached Value",
      render: (text) => {
        const modifiedText = text == "null" ? <i>{text}</i> : text;
        return <span>{modifiedText}</span>;
      }
    },
    {
      dataIndex: "mongoValue",
      key: "mongoValue",
      title: "Mongo Value",
      render: (text, record: DataSourcePerformanceResult) => {
        const modifiedText = text == "null" ? <i>{text}</i> : text;
        if (record.mongoValueLastUpdated) {
          const utcDate = new Date(record.mongoValueLastUpdated);
          const localDate = utcDate.toLocaleString();
          return (
            <DateUpdatedWrapper>
              {modifiedText}{" "}
              <Tooltip title={localDate}>
                <Icon path={mdiCalendarClock} size={1.3} />
              </Tooltip>
            </DateUpdatedWrapper>
          );
        }
        return <span>{modifiedText}</span>;
      }
    }
  ];

  return (
    <ResultsContainer>
      {data && !analyzerError && (
        <ResultsTable>
          <Table
            dataSource={data?.dataSourceResults}
            columns={tableColumns}
            rowKey="key"
            pagination={false}
            loading={isLoading}
          />
        </ResultsTable>
      )}
      {analyzerError && (
        <ErrorContainer>
          <ErrorMessage>
            <Icon path={mdiAlert} size={1} /> {analyzerError}
            <ErrorDetailsToggle
              onClick={() => setShowAnalyzerErrorDetails(!showAnalyzerErrorDetails)}>
              {showAnalyzerErrorDetails ? "hide details" : "show details"}
            </ErrorDetailsToggle>
          </ErrorMessage>
          {showAnalyzerErrorDetails && (
            <ErrorMessageDetails>{analyzerErrorDetails}</ErrorMessageDetails>
          )}
        </ErrorContainer>
      )}
    </ResultsContainer>
  );
};

export default PerformanceDataAnalyzerTable;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ResultsTable = styled.div`
  width: 100%;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 1px solid var(--color-danger);
  border-radius: 5px;
  padding: 3px 15px;
  font-weight: var(--fontWeightMedium);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-danger);
`;

const ErrorDetailsToggle = styled.span`
  color: #a2aaad;
  cursor: pointer;
`;

const ErrorMessageDetails = styled.div`
  width: 100%;
  color: var(--color-text);
  font-family: var(--fontMono);
  text-align: left;
`;

const DateUpdatedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    color: #a2aaad;
  }
`;
