import { FC, useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";

import { Divider } from "antd";
import styled from "styled-components";

import { useScreenshotContext } from "../../screenshot/hooks";
import { useMapContext } from "../hooks/useMapContext";

interface BubbleVisLegendProps {
  parentDimensions: { width: number; height: number };
  screenshotLegend?: boolean;
}

export const BubbleVisLegend: FC<BubbleVisLegendProps> = ({
  parentDimensions,
  screenshotLegend = false
}) => {
  const { widget, settings } = useScreenshotContext();
  const [size, setSize] = useState({ width: 210, height: 210 });
  const xOffset = screenshotLegend ? size.width + 270 : size.width + 10;
  const { bubbleVisLegend } = useMapContext();
  const previousParentDimensionsRef = useRef<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  const [position, setPosition] = useState({
    x: parentDimensions.width - xOffset,
    y: parentDimensions.height - size.height
  });

  useEffect(() => {
    if (position.x < 0) setPosition({ x: 0, y: position.y });
    if (position.y < 0) setPosition({ x: position.x, y: 0 });

    /** Bugfix: The check for parentDimensions.width - size.width > 0 is to prevent infinite rerender,
     * if position is set to a negative number, line 33/34 will retrigger, this occured when opening the datatable */
    if (
      parentDimensions.width - size.width > 0 &&
      position.x > parentDimensions.width - size.width
    )
      setPosition({ x: parentDimensions.width - size.width - 5, y: position.y });
    if (
      parentDimensions.height - size.height > 0 &&
      position.y > parentDimensions.height - size.height
    )
      setPosition({ x: position.x, y: parentDimensions.height - size.height - 5 });
  }, [position, size]);

  useEffect(() => {
    if (settings?.showLegendInScreenshot) {
      setPosition({
        x: parentDimensions.width - xOffset,
        y: parentDimensions.height - size.height - 5
      });
    } else {
      setPosition({ x: parentDimensions.width, y: parentDimensions.height });
    }
  }, [widget, settings?.showLegendInScreenshot]);

  useEffect(() => {
    const previousParentDimensions = previousParentDimensionsRef.current;
    if (
      previousParentDimensions.width !== parentDimensions.width ||
      previousParentDimensions.height !== parentDimensions.height
    ) {
      const diffX = parentDimensions.width - previousParentDimensions.width;
      const diffY = parentDimensions.height - previousParentDimensions.height;
      setPosition({ x: position.x + diffX, y: position.y + diffY });
      previousParentDimensions.width = parentDimensions.width;
      previousParentDimensions.height = parentDimensions.height;
    }
  }, [parentDimensions]);

  if (!bubbleVisLegend || !bubbleVisLegend.visible) {
    return null;
  }
  if ((screenshotLegend && !widget) || (!screenshotLegend && widget)) {
    return null;
  }
  return (
    <StyledRnd
      default={{
        x: position.x,
        y: position.y,
        ...size
      }}
      position={position}
      bounds={"parent"}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      enableResizing={true}
      onResizeStop={(e, direction, ref, delta, position) => {
        setPosition({ x: position.x, y: position.y });
        setSize({ width: ref.offsetWidth, height: ref.offsetHeight });
      }}
      disableDragging={false}>
      <LegendContainer className="bubble-vis-legend">
        <Title className="bubble-vis-legend-title">{bubbleVisLegend.title}</Title>
        <BubbleSize>
          <ItemContainer>
            <BubbleWrapper>
              <div className="max-circle" />
            </BubbleWrapper>
            <div>{bubbleVisLegend.maxValue.toFixed(1)}</div>
          </ItemContainer>
          <ItemContainer>
            <BubbleWrapper>
              <div className="min-circle" />
            </BubbleWrapper>
            <div>{bubbleVisLegend.minValue.toFixed(1)}</div>
          </ItemContainer>
          {bubbleVisLegend.colors.length > 1 ? <Divider /> : null}
          {bubbleVisLegend.colors.map((color) => {
            return (
              <>
                <ItemContainer key={color.title}>
                  <BubbleWrapper>
                    <div
                      className="half-circle"
                      style={{ backgroundColor: color.color }}
                    />
                  </BubbleWrapper>
                  <div title={color.title} className={"bubble-vis-legend-item-title"}>
                    {color.title}
                  </div>
                </ItemContainer>
              </>
            );
          })}
        </BubbleSize>
      </LegendContainer>
    </StyledRnd>
  );
};

const StyledRnd = styled(Rnd)`
  position: absolute;
  margin: 10px;
  background: ${(props) =>
    props.backgroundOpacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundOpacity})`};
  border: ${(props) => (props.showLegendBorder ? "1px solid gray" : "")};
  overflow: hidden;
  z-index: 9998; /* Set a high z-index value to ensure legend is on top within the screenshot component. */

  & > div:last-child {
    position: unset;
  }
`;

const Title = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 1em;
  justify-content: center;
  align-items: center;
`;
const BubbleWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

const BubbleSize = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .min-circle,
  .max-circle {
    border-radius: 50%;
    background-color: #000;
  }

  .half-circle {
    width: 12px;
    height: 12px;
    margin-bottom: 6px;
    background-color: #000;
    border-radius: 12px 0 0 0;
    overflow: hidden;
    transform: rotate(45deg);
  }

  .min-circle {
    width: 5px;
    height: 5px;
  }

  .max-circle {
    width: 20px;
    height: 20px;
  }
`;
const LegendContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid black;
  padding: 5px;

  .ant-divider {
    margin: 5px 0;
  }

  .bubble-vis-legend-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
