import { useMemo } from "react";

import { THEME_COLOR } from "constants/style.constants";
import _debounce from "lodash/debounce";

import { ColorPicker } from "components/base";
import { useProjectContext } from "components/project/projects/context";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { useUpdateProjectShapefileFolderColourMutation } from "components/project/shapefiles/mutations";

export interface IProjectShapefileColorPickerProps {
  shapefileNodeId: string;
}

export function ProjectShapefileFolderColorPicker(
  props: IProjectShapefileColorPickerProps
) {
  const { selectedProjectId } = useProjectContext();

  const projectPermissions = useSelectedProjectPermissions();
  const updateProjectShapefileFolderColour =
    useUpdateProjectShapefileFolderColourMutation({});

  // TODO BF: repeated code
  const debouncedUpdateProjectShapefileOpacity = useMemo(
    () =>
      _debounce(
        (value) =>
          updateProjectShapefileFolderColour.mutate({
            body: {
              key: "opacity",
              value
            },
            projectId: selectedProjectId,
            shapefileNodeId: props.shapefileNodeId
          }),
        500
      ),
    [selectedProjectId]
  );
  return (
    <ColorPicker
      isAtFolderLevel={true}
      onChange={(key, value) =>
        updateProjectShapefileFolderColour.mutate({
          body: {
            key,
            value
          },
          projectId: selectedProjectId,
          shapefileNodeId: props.shapefileNodeId
        })
      }
      onChangeOpacity={(key, value) =>
        debouncedUpdateProjectShapefileOpacity(value, props.shapefileNodeId)
      }
      values={{
        color: THEME_COLOR,
        strokeColor: THEME_COLOR,
        thickness: 2,
        opacity: 100
      }}
      isReadOnly={!projectPermissions.canEditShapefiles}
    />
  );
}
