import { useContext } from "react";

import { MapContext } from "../contexts/MapContext";

export function useMapContext() {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error("useContext must be within a Map provider");
  }
  return context;
}
