const excludePropertiesByKeys = (object, keys: string[]) => {
  const updatedObject = { ...object };

  keys.forEach((key) => {
    if (key in updatedObject) {
      delete updatedObject[key];
    }
  });

  return updatedObject;
};

export default excludePropertiesByKeys;
