import { useCallback } from "react";
import { useSelector } from "react-redux";

import { ALL_CHART_TYPES } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import { removeCount } from "utils/removeCountFromTitle";

import { LegendItemModel } from "models";
import { ChartSeries } from "models/model";

import { useScreenshotContext } from "components/screenshot/hooks";
import { applyAbbreviations } from "components/screenshot/utils";
import { useUserSettings } from "components/user/hooks";

import { getChart } from "../charts/getChart";
import { useChartState } from "../context";
import { createLegendItem } from "../utils";
import useAverageLegendItem from "./useAverageLegendItem";
import useChartDependencies from "./useChartDependencies";
import useP10LegendItem from "./useP10LegendItem";
import useP50LegendItem from "./useP50LegendItem";
import useP90LegendItem from "./useP90LegendItem";
import useSlopeLegendItem from "./useSlopeLegendItem";
import useTypeWellLegendItems from "./useTypeWellLegendItems";

const getFilteredItems = (series: ChartSeries[]): ChartSeries[] => {
  return series.filter(
    (series) =>
      !["Average", "P10", "P50", "P90"].includes(series.groupTitle) &&
      !series.isForecast &&
      !series.isSecondaryYAxis &&
      !series.label?.includes(" Fit - ")
  );
};

/**
 * A simple hook to get the legend items for the chart
 *
 * @returns list of legend items
 */
const useChartLegendItems = (): LegendItemModel[] => {
  // custom hooks
  const avgLegendItem = useAverageLegendItem();
  const p90LegendItem = useP90LegendItem();
  const p50LegendItem = useP50LegendItem();
  const p10LegendItem = useP10LegendItem();
  const slopeLegendItem = useSlopeLegendItem();

  const typeWellLegendItems = useTypeWellLegendItems();
  const { userAbbreviations } = useUserSettings();
  const screenshotState = useScreenshotContext();

  // state from store
  const txnId = useSelector((state: RootState) => state.map.txnId);

  const { entityKind, screenshot, settings, response } = useChartState();
  const { showGroupsNotInFilter } = useChartDependencies(entityKind);

  const abbreviations = userAbbreviations?.abbreviations ?? [];

  // derived state
  const { id, legend } =
    entityKind === "Well"
      ? { id: txnId.id, legend: txnId.legend }
      : {
          id: txnId.facilityId,
          legend: txnId.facilityLegend
        };
  const { legendItems } = legend;
  const hasLegendItems = Boolean(id) && Boolean(legendItems.length);

  const createAndFormatLegendItem = useCallback(
    (title: string, inFilter: boolean, color: string): LegendItemModel => {
      const formattedTitle =
        screenshot && screenshotState?.settings?.applyAbbreviations
          ? applyAbbreviations(title, abbreviations)
          : title;
      return createLegendItem(formattedTitle, inFilter, color);
    },
    [screenshot, screenshotState?.settings?.applyAbbreviations, abbreviations]
  );

  if (!hasLegendItems) return [...avgLegendItem, ...typeWellLegendItems];
  const chart = getChart(settings.chartType);
  if (settings.useChartFocus) {
    if (!response?.series?.length) {
      return [...typeWellLegendItems];
    }
    switch (settings.chartType) {
      case ALL_CHART_TYPES.Pie.label: {
        const itemColors = response.series[0].style.itemColors;
        const filteredLegendItems = response.series[0].y.map((title, index) => {
          return createAndFormatLegendItem(title, true, itemColors[index]);
        });
        return [...filteredLegendItems, ...typeWellLegendItems];
      }
      case ALL_CHART_TYPES.Mosaic.label: {
        const filteredLegendItems = response.series
          .filter(
            (series) =>
              series.groupTitle != "Average" &&
              !series.isForecast &&
              !series.isSecondaryYAxis
          )
          .map((series) => {
            const title = series.groupTitle ? series.groupTitle : series.label;
            return {
              title: removeCount(title),
              hexColor: series.style.hexColor
            };
          })
          .filter(
            (value, index, array) =>
              array.map((a) => a.title).indexOf(value.title) === index
          )
          .map((series) => {
            return createAndFormatLegendItem(series.title, true, series.hexColor);
          });
        return [...filteredLegendItems, ...typeWellLegendItems];
      }
      case ALL_CHART_TYPES.BoxPlot.label: {
        const filteredLegendItems = getFilteredItems(response.series)
          .filter(
            (value, index, array) =>
              array.map((a) => a.label).indexOf(value.label) === index
          )
          .map((series) => {
            return createAndFormatLegendItem(series.label, true, series.style.hexColor);
          });
        return [...filteredLegendItems, ...typeWellLegendItems];
      }
      default: {
        const uniqueLegendItemSeries = getFilteredItems(response.series).reduce(
          (accumulator, currentSeries) => {
            const isLegendItemDuplicate = accumulator.some(
              (item) =>
                (item.label && item.label === currentSeries.label) ||
                (item.groupTitle && item.groupTitle === currentSeries.groupTitle)
            );

            if (!isLegendItemDuplicate) {
              accumulator.push(currentSeries);
            }

            return accumulator;
          },
          []
        );

        const formattedLegendItems = uniqueLegendItemSeries.map((series) => {
          const title = series.groupTitle || series.label;
          return createAndFormatLegendItem(title, true, series.style.hexColor);
        });

        return [
          ...p10LegendItem,
          ...avgLegendItem,
          ...p50LegendItem,
          ...p90LegendItem,
          ...formattedLegendItems,
          ...typeWellLegendItems,
          ...slopeLegendItem
        ];
      }
    }
  }

  //custom legendItems allows the chart to override the legend that is returned from the txnId
  const customLegendItems = chart?.getCustomLegendItems(response?.series) ?? [];

  // legend items in filter
  const filteredLegendItems = (
    customLegendItems.length > 0 ? customLegendItems : legendItems
  )
    .filter((item) => item.inFilter && (showGroupsNotInFilter || item.count > 0))
    .map((item) => {
      const title = item.groupTitle ? item.groupTitle : item.title;

      return createAndFormatLegendItem(title, item.inFilter, item.color);
    });

  return [
    ...p10LegendItem,
    ...avgLegendItem,
    ...p50LegendItem,
    ...p90LegendItem,
    ...slopeLegendItem,
    ...filteredLegendItems,
    ...typeWellLegendItems
  ];
};

export default useChartLegendItems;
