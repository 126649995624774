// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { mdiAlert } from "@mdi/js";
import { RootState } from "store/rootReducer";

import { BaseMenu } from "components/base";
import ColorPaletteMenu from "components/filter/focus/ColorPaletteList";
import {
  DEFAULT_COLOR_PALETTE_SETTING,
  DEFAULT_FACILITY_COLOR_PALETTE_SETTING
} from "components/user-settings/constants/userSetting.constants";
import {
  DEFAULT_COLOR_PALETTE_NAME,
  DEFAULT_FACILITY_COLOR_PALETTE_NAME,
  DefaultPalette as DefaultPaletteModel,
  IColorPalette
} from "components/user-settings/models";
import { useUserContext } from "components/user/context";
import { updateSettingPalette } from "components/user/context/reducer";
import { useUserDefaults, useUserSettingMutation } from "components/user/hooks";

import { EntityKind } from "../../../../models/entityKind";
import { updateSettingFacilityPalette } from "../../../user/context/reducer/UserReducer";
import {
  ErrorContainer,
  SectionContainer,
  SectionHeading,
  SectionInputWrapper,
  StyledSpinner
} from "./shared";

interface DefaultColorPaletteProps {
  entityKind: EntityKind;
}

const DefaultColorPalette = ({ entityKind }: DefaultColorPaletteProps) => {
  // hooks
  const [, dispatch] = useUserContext();
  const { colorPalette, facilityPalette } = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultPaletteModel>(
    entityKind == EntityKind.Well
      ? DEFAULT_COLOR_PALETTE_SETTING
      : DEFAULT_FACILITY_COLOR_PALETTE_SETTING
  );

  // selectors
  const colorPalettes = useSelector(
    (state: RootState) => state.userSetting.colorPalettes
  );
  const getPalette = useCallback(() => {
    return entityKind == EntityKind.Well ? colorPalette : facilityPalette;
  }, [colorPalette, facilityPalette]);

  // state
  const [currentColorPalette, setCurrentColorPalette] = useState<IColorPalette>();
  const [updateError, setUpdateError] = useState("");

  const handleDefaultPaletteChange = useCallback(
    (scheme: IColorPalette) => {
      mutation.mutate({
        id: getPalette()?.id,
        name: scheme.name,
        reverse: scheme.reverse
      });
      setCurrentColorPalette(scheme);
    },
    [getPalette]
  );

  // Match the current preference.
  useEffect(() => {
    if (!colorPalettes) return;
    const palette = getPalette();

    const paletteName =
      getPalette()?.name ??
      (entityKind == EntityKind.Well
        ? DEFAULT_COLOR_PALETTE_NAME
        : DEFAULT_FACILITY_COLOR_PALETTE_NAME);

    const match = colorPalettes.find((p: IColorPalette) => {
      return p.name === paletteName;
    });

    if (match) {
      if (getPalette()?.reverse) {
        const clone = { ...match };
        clone.colors = [...match.colors.slice().reverse()];
        clone.reverse = palette.reverse;
        setCurrentColorPalette(clone);
      } else {
        setCurrentColorPalette(match);
      }
    } else {
      const defaultPalette = colorPalettes.find(
        (p: IColorPalette) => p.name === DEFAULT_COLOR_PALETTE_NAME
      );
      handleDefaultPaletteChange(defaultPalette);
    }
  }, [colorPalettes, getPalette]);

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;
    if (entityKind == EntityKind.Well) {
      updateSettingPalette(dispatch, {
        id: mutation.data.id,
        name: mutation.data.name,
        reverse: mutation.data.reverse
      });
    } else {
      updateSettingFacilityPalette(dispatch, {
        id: mutation.data.id,
        name: mutation.data.name,
        reverse: mutation.data.reverse
      });
    }
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update default colour palette.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  const isLoading = !currentColorPalette;

  return (
    <SectionContainer>
      <SectionHeading>{entityKind} Colour Palette</SectionHeading>
      <SectionInputWrapper>
        {isLoading && <StyledSpinner />}
        {!isLoading && (
          <BaseMenu
            data-testid={"default-color-palette-menu"}
            trigger={
              <div
                key={"colour-palette-menu"}
                data-testid={`${entityKind}-default-color-palette-menu-trigger`}
                className={`color-scheme-item`}>
                <div className="color-scheme-descriptor">{getPalette()?.name}</div>
                <div key={getPalette()?.name} className="color-scheme-colors">
                  {currentColorPalette?.colors?.map((color) => (
                    <div
                      key={color}
                      className="color"
                      style={{
                        backgroundColor: color
                      }}
                    />
                  ))}
                </div>
              </div>
            }>
            {({ closeMenu }) => (
              <ColorPaletteMenu
                selected={getPalette()}
                onSelect={(scheme: IColorPalette) => {
                  handleDefaultPaletteChange(scheme);
                  closeMenu();
                }}
              />
            )}
          </BaseMenu>
        )}
      </SectionInputWrapper>

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </SectionContainer>
  );
};

export default DefaultColorPalette;
