import { memo } from "react";

function ChevronDown({ size = 24, onClick = null }) {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill="currentColor"
      onClick={onClick}>
      <g id="arrow-ios-down">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="Rectangle"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0.292893 0.292893C-0.0675907 0.653377 -0.0953203 1.22061 0.209705 1.6129L0.292893 1.70711L5.29289 6.70711C5.65338 7.06759 6.22061 7.09532 6.6129 6.7903L6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3466 -0.0675907 10.7794 -0.0953203 10.3871 0.209705L10.2929 0.292893L6 4.585L1.70711 0.292893C1.34662 -0.0675907 0.779392 -0.0953203 0.387101 0.209705L0.292893 0.292893Z"
          transform="translate(6 9)"
          id="Icon"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}
export default memo(ChevronDown);
