// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiDownload, mdiUpload } from "@mdi/js";
import { Button, Input, Progress } from "antd";
import _debounce from "lodash/debounce";
import styled, { keyframes } from "styled-components";

import { useUser } from "hooks";

import { useFilesContext, useFilesDispatch } from "../context";
import { useDownloadFile, useFileUploadWatcher } from "../hooks";
import FileUploaderInput from "./FileUploaderInput";
import FilesList from "./FilesList";

const FilesManager = () => {
  // hooks
  const {
    filesType,
    isFileUploaderOpen,
    searchTerm,
    selectedFiles,
    allowMultipleDownloads,
    isDownloadingFiles,
    downloadFileProgress,
    cancelDownload
  } = useFilesContext();
  const { isAdmin } = useUser();
  const { downloadFile } = useDownloadFile();
  useFileUploadWatcher();

  // dispatch
  const filesDispatch = useFilesDispatch();

  // state
  const [inputValue, setInputValue] = useState(searchTerm);

  // use switch statement to set canUpload
  let canUpload = false;
  switch (filesType) {
    case "productionData":
      canUpload = isAdmin;
      break;
    case "userToEVA":
      canUpload = true;
      break;
    default:
      canUpload = false;
  }

  // Debounce searchTerm update
  useEffect(() => {
    const debouncedUpdate = _debounce((value) => {
      filesDispatch({ payload: { searchTerm: value } });
    }, 500);

    debouncedUpdate(inputValue);

    // Cleanup function to clear the debounce timeout
    return () => debouncedUpdate.cancel();
  }, [inputValue, filesDispatch]);

  const handleDownload = async () => {
    if (selectedFiles?.length < 1) {
      return;
    }

    filesDispatch({ payload: { isDownloadingFiles: true } });

    try {
      if (allowMultipleDownloads) {
        // TODO: Backend currently not implemented.
      } else {
        const filename = selectedFiles[0];
        // eslint-disable-next-line no-console
        console.log(`Download file ${filename.filename} of size ${filename.fileSize}`);
        const { downloadChunks, cancelDownload } = downloadFile(
          filename.filename,
          filename.fileSize
        );
        await downloadChunks();
        filesDispatch({ payload: { cancelDownload } });
      }
    } catch (error) {
      filesDispatch({
        payload: { error: `Failed to download {filename}, please try again later.` }
      });
    } finally {
      filesDispatch({ payload: { isDownloadingFiles: false, downloadFileProgress: 0 } });
    }
  };

  const handleCancelDownload = useCallback(() => {
    if (cancelDownload) {
      cancelDownload();
    } else {
      // eslint-disable-next-line no-console
      console.error("Cancel download function not available.");
    }
  }, [cancelDownload]);

  return (
    <Wrapper>
      {isDownloadingFiles && (
        <DownloadingOverlay>
          <Progress percent={downloadFileProgress} type="circle" width={80} />
          <DownloadingText>Downloading</DownloadingText>
          <Button onClick={handleCancelDownload}>Cancel Download</Button>
        </DownloadingOverlay>
      )}
      {!isFileUploaderOpen && (
        <FilesActionsWrapper>
          <LeftActions>
            {canUpload && (
              <FileActionButton
                onClick={() => filesDispatch({ payload: { isFileUploaderOpen: true } })}>
                <Icon path={mdiUpload} size={1} color="#333" />
                <span>Upload File</span>
              </FileActionButton>
            )}
            <FileSearchWrapper>
              <Input
                placeholder="Search Files"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </FileSearchWrapper>
          </LeftActions>
          {}
          <RightActions>
            <FileActionButton
              disabled={selectedFiles?.length === 0}
              onClick={() => handleDownload()}>
              <Icon path={mdiDownload} size={1} color="#333" />
            </FileActionButton>
          </RightActions>
        </FilesActionsWrapper>
      )}
      {isFileUploaderOpen && <FileUploaderInput />}
      {!isFileUploaderOpen && <FilesList />}
    </Wrapper>
  );
};

export default FilesManager;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FilesActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const FileActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FileSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 350px;
`;
const LeftActions = styled.div`
  display: flex;
  gap: 10px;
`;
const RightActions = styled.div`
  display: flex;
  gap: 10px;
`;
const DownloadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  z-index: 1005;
`;
const dotsAnimation = keyframes`
  0%, 15% {
    content: "   ";
  }
  30%, 45% {
    content: ".  ";
  }
  55%, 70% {
    content: ".. ";
  }
  85%, 100% {
    content: "...";
  }
`;
const DownloadingText = styled.span`
  &:after {
    display: inline-block;
    content: "";
    width: 18px;
    text-align: left;
    animation: ${dotsAnimation} 1.5s infinite;
  }
`;
