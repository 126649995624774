export class Point2F {
  X: number;
  Y: number;
}
export interface IZoneVisOptions {
  zoneDepthFontSize: number;
  lsdHeaderFontSize: number;
  valueFontSize: number;
  legendFontSize: number;
  legendHeaderFontSize: number;
  showValue: boolean;
  showWellLabel: boolean;
  showXDAOutline: boolean;
  fillXDA: boolean;
  fillColor: string;
  showPadOutline: boolean;
  padOutlineColor: string;
  padOutlineWidth: number;
  padOutlineDashed: boolean;
  showIpdb: boolean;
  ipdbField: string;
}

export class IpdbLsdValue {
  uwi: string;
  val: number;
  cat: string;
  color: string;
}

export class StationSurvey {
  Location: Point2F;
  DepthSS: number;
  MeasuredDepth: number;
}

export class WellSurvey {
  Uwi: string;
  Surveys: StationSurvey[];
}
export class Point {
  lon: number;
  lat: number;
}
export interface IGeoObj {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geometry: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
}
export interface IGeoJSON {
  features: IGeoObj[];
}

export interface LegFeature {
  UWI: string;
  Survey: Point2F[];
}

export interface IUWIFeat {
  UWI: string;
  Color: string;
  Value: string;
  ValueN: string;
  ValueE: string;
  ValueW: string;
  IsSelected: boolean;
  Operator: string;
  Vintage: string;
  UniqueId: string;
  Thickness: number;
  FormattedUWI: string;
  Midpoint: number[];
  Surface: number[];
  BottomHole: number[];
  Survey: Point2F[];
  RenderSurfaceToBottomHoleLine: boolean;
  WellIds: string[];
  Legs: LegFeature[];
}

export class XYZRecord {
  X: number;
  Y: number;
  Z: number;
}

export class UWIList {
  UWIFeatures: IUWIFeat[];
  Distance: number;
}
export class Perf {
  MeasuredDepth: number;
  StageNumber: number;
  Id: number;
}

export class WellVisData {
  feat: IUWIFeat;
  well: WellSurvey;
  lsds: LsdInfo[];
  completions: Perf[];
}

export interface IZoneData {
  min: number;
  max: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locationValues: any;
}

export interface IZoneVisWellData {
  AverageToeLsdDepth: number;
  Color: string;
  DepthOffset: number;
  CrossSectionPointWithOffset: Point2F;
  Shape: Point2F[];
}

export class WellWkt {
  wkt: string;
  name: string;
  entity: string;
  isParent: boolean;
}
export class KrigeSettings {
  method = "ordinary";
  variogram = "spherical";
  row = 100;
  col = 100;
  weight = false;
}

export class ClientCustomField {
  ObjectId = "";
  Name = "";
  DefType = 0;
}

export class SaveMapSettings {
  layerName = "";
  zone = "";
  gridX = 0;
  gridY = 0;
  id = "";
}
export class WellInfo {
  Uwi: string;
  AverageToeLsdSSDepth: number;
  CrossSectionPointWithOffset: Point2F;
  Location: Point2F;
  Survey: string;
  Color: string;
  Value: string;
  Shape: Point2F[];
}

export enum Direction {
  X,
  Y
}

export class ZoneInfo {
  Name: string;
  Pay: number;
  DepthSS: number;
  value: number;
}

export class Spacing {
  Lateral = 0.0;
  Vertical = 0.0;
}
export class LegendItem {
  Title = "";
  Color = "";
}
export class LegendInfo {
  GroupByTitle = "";
  LegendItems: LegendItem[] = [];
}
export class LsdInfo {
  Survey: string;
  Formatted: string;
  Zones: ZoneInfo[];
  NLat: number;
  WLon: number;
  SLat: number;
  ELon: number;

  ZoneOffset: number;
  getWidth(direction: Direction) {
    if (direction === Direction.X) {
      const width = Math.abs(this.WLon - this.ELon);

      return width;
    }
    return this.NLat - this.SLat;
  }
}
