import { useUpdateMapLayersVisibility } from "components/map/hooks";
import { useProjectDispatch } from "components/project/projects/context";

interface IChangeLayersParams {
  checkedLayerKeys: string[];
  checkedTreeKeys: string[];
  layerTreeExpandedKeys: string[];
}

export function useChangeLayers() {
  const projectDispatch = useProjectDispatch();
  const { updateMapLayersVisibility } = useUpdateMapLayersVisibility();

  function changeLayers(params: IChangeLayersParams) {
    const { checkedLayerKeys, checkedTreeKeys, layerTreeExpandedKeys } = params;

    projectDispatch({
      payload: {
        checkedLayerKeys: checkedLayerKeys,
        checkedTreeKeys: checkedTreeKeys,
        layerTreeExpandedKeys: layerTreeExpandedKeys
      }
    });

    updateMapLayersVisibility(checkedLayerKeys);
  }

  return { update: changeLayers };
}
