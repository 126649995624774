import axios from "axios";

export interface SavedMapLocation {
  id?: string;
  owner?: string;
  name: string;
  centerLatitude: number;
  centerLongitude: number;
  zoomLevel: number;
  type?: number;
}

const userServiceEndpoint = process.env.REACT_APP_USER_SERVICE;

export async function getSavedMapLocations() {
  const response = await axios.get<SavedMapLocation[]>(
    `${userServiceEndpoint}/saved-map-location/saved-locations`
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function saveMapLocation(location: SavedMapLocation) {
  const response = await axios.post(
    `${userServiceEndpoint}/saved-map-location/saved-location`,
    location
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function deleteMapLocation(id: string) {
  const response = await axios.delete<boolean>(
    `${userServiceEndpoint}/saved-map-location/saved-location/${id}`
  );
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}
