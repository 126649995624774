// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removePropertyFromObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  const newObj = { ...obj };
  // eslint-disable-next-line no-prototype-builtins
  if (newObj.hasOwnProperty(key)) {
    delete newObj[key];
  }
  return newObj;
};

export default removePropertyFromObject;
