import { useQueryClient } from "react-query";

import { useUser } from "hooks";

import { useProjectListQueryKeys } from "../queries";
import { getProjectPermissions } from "../utils";
import { useSelectedProject } from "./useSelectedProject";

export function useSelectedProjectPermissions() {
  const queryClient = useQueryClient();
  const { isAtLeastPowerUser, isAtLeastAdmin, isReadonly, user } = useUser();

  // Exceptional case for the project layer list where we want to avoid refetching the project list
  // In most cases we can don't care about the refetch as its called, but for the layer list:
  // Each row uses this hook, and as each row is added to the virtual list on scroll, it will create a new query for each new row rendered and auto refetch, which just isn't needed.
  // We could also probably pass in the permissions from the project layer list, but I'd rather have this hook, and access cache than pass so many props.
  const isAutoRefetching = !queryClient.getQueryData(
    useProjectListQueryKeys.currentProjectList
  );

  const { selectedProject } = useSelectedProject({ isAutoRefetching });

  const permissions = getProjectPermissions(
    selectedProject,
    user,
    isAtLeastPowerUser,
    isAtLeastAdmin,
    isReadonly
  );

  return permissions;
}
