import { useCallback, useEffect, useRef, useState } from "react";
// TODO: find alternative using latest APIs
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { useHistory, useParams } from "react-router-dom";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EditIcon from "@material-ui/icons/Edit";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// TODO: replace with marked
import { Remarkable } from "remarkable";
import styled from "styled-components/macro";

import { useUser } from "hooks";

import { YoutubeLinkT, parseVideoId } from "./LinkParser";
import PostDate from "./PostDate";
import UpdatePost from "./UpdatePost";
import { setEditPostId, usePost } from "./context";

function PostPreview({
  title,
  date,
  content,
  id,
  onUpdate,
  openDocInNewTab
}): JSX.Element {
  // context and dispatch
  const [, dispatch] = usePost();
  const history = useHistory();
  const { id: activePostId } = useParams();
  const { user, isAdmin } = useUser();

  // state
  const [videoModal, setVideoModal] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState<YoutubeLinkT | null>(null);
  const [body, setBody] = useState(null);
  const [activePost, setActivePost] = useState(false);

  const md = useRef(new Remarkable());

  const getMarkup = useCallback(() => {
    const links = /(\[(.*?)\]\()(.*?)(?=\))/g[Symbol.match](content); //read all links
    let modifiedContent = content;
    if (links !== null) {
      for (const url of links) {
        const video = parseVideoId(url);

        if (video) {
          setYoutubeLink(video);
          const toReplace = new RegExp(
            `\\[(.*?)\\]\\(\\b${url.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}\\b\\)`
          );
          modifiedContent = content.replace(toReplace, "");
          break;
        }
      }
    }
    return { __html: md.current.render(modifiedContent) };
  }, [content, md]);

  useEffect(() => setBody(getMarkup()), [getMarkup]);

  function openPost() {
    if (openDocInNewTab) {
      window.open("/user-documentation-post/" + id);
    } else {
      history.push("/user-documentation-post/" + id);
    }
  }

  const handleEditClick = () => (e) => {
    e.stopPropagation();
    setEditPostId(dispatch, id);
  };

  // derived state
  // derived state
  const isSystemAdmin =
    isAdmin &&
    (user?.organization?.emailDomain === "mcdan.com" ||
      user?.organization?.emailDomain === "turinganalytics.net");

  useEffect(() => {
    setActivePost(id.toString() === activePostId);
  }, [activePostId, id]);

  return (
    <>
      <Wrapper onClick={openPost} active={activePost} data-testid="post-preview">
        <PostTitle>{title}</PostTitle>
        <PostDate value={date} />
        {Boolean(youtubeLink) && (
          <PreviewVideo
            onClick={(evt) => {
              evt.stopPropagation();
              setVideoModal(true);
            }}>
            <PlayCircleFilledIcon fontSize="inherit" />
            <span className="label">Play Video</span>
          </PreviewVideo>
        )}
        <PostContent dangerouslySetInnerHTML={body} />

        <ReadMoreWrapper>
          <ReadMoreButton>
            Read More
            {openDocInNewTab ? <ArrowForwardIcon fontSize="large" /> : null}
          </ReadMoreButton>
        </ReadMoreWrapper>

        {isSystemAdmin && (
          <EditButton onClick={handleEditClick()}>
            <EditIcon fontSize="large" />
          </EditButton>
        )}
      </Wrapper>

      <UpdatePost postId={id} onUpdate={onUpdate} />

      {Boolean(youtubeLink) && (
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={videoModal}
          videoId={youtubeLink.id}
          onClose={() => setVideoModal(false)}
        />
      )}
    </>
  );
}

export default PostPreview;

const EditButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: 16px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-60);
  background-color: transparent;
  border-radius: var(--border-radius);
  border: none;
  font-weight: 600;
  padding: 0px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s, background-color 0.2s;
  cursor: pointer;
  z-index: 50;

  &:hover {
    color: var(--color-text);
  }
`;

const ReadMoreButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-60);
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  & > svg {
    transition: translate 0.2s ease-in-out;
    rotate: -45deg;
  }
`;

const Wrapper = styled.div`
  --gradient-color: 255 255 255;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.active ? "rgba(var(--color-text-rgb), 0.1)" : " var(--color-white)"};
  padding: 16px 20px 0;
  cursor: pointer;

  &:hover {
    --gradient-color: 250 250 250;
    background-color: #fafafa;

    ${EditButton} {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.3s;
    }

    ${ReadMoreButton} svg {
      translate: 3px -3px;
    }
  }
`;

const PostTitle = styled.h2`
  color: var(--color-primary);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-right: 100px;
  margin-bottom: 8px;
`;

const PostContent = styled.div`
  word-wrap: break-word;
  overflow: hidden;
  min-height: 36px;
  max-height: 254px;

  & > * {
    color: var(--color-text-80);
  }
  &:hover > * {
    color: var(--color-text);
  }

  a,
  .link {
    color: #1890ff;
    text-decoration: underline;
  }

  h1,
  h2,
  h3 {
    font-size: 16px;
    font-weight: bold;
  }
`;

const ReadMoreWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(var(--gradient-color) / 100%) 60%,
    rgba(var(--gradient-color) / 0%) 100%
  );
  padding: 20px 20px 16px;
`;

const PreviewVideo = styled.div`
  grid-area: body;
  font-size: 30px;
  color: var(--color-primary);
  transition: color var(--duration);
  display: flex;
  place-self: center;
  align-items: center;
  &:hover {
    color: var(--color-text);
  }
  .label {
    font-size: 25px;
    padding-left: 5px;
  }
`;
