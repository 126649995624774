import { useQuery } from "react-query";

import { getUserSetting } from "api/userSettings";

export default function useUserSettingFetcher<T>(
  setting: string,
  onSuccess: (data: T) => void = null,
  handleError: (err) => void = null
) {
  const { data, isLoading, isError, error, refetch } = useQuery<T | null>(
    ["user-setting", setting],
    async (setting) => getUserSetting(setting.queryKey[1] as string),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data as T);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
      enabled: false
    }
  );

  return { data, isLoading, isError, error, refetch };
}
