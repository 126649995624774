import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { ShapefileContextState, ShapefileUpdates } from "./ShapefileContextState";

export const initialShapefileContextState: ShapefileContextState = {
  isLoadingColors: false,
  isLoadingDetails: false,
  error: ""
};
export const ShapefileContext = createContext(initialShapefileContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const ShapefileDispatchContext =
  createContext<React.Dispatch<Action<ShapefileUpdates, "update">>>(undefined);

function ShapefileReducer(
  state: ShapefileContextState,
  action: Action<ShapefileUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface ShapefileProviderModel {
  children: ReactNode;
  state?: ShapefileContextState;
}

export function ShapefileProvider({
  children,
  state: overrideState
}: ShapefileProviderModel) {
  const [state, dispatch] = useReducer(
    ShapefileReducer,
    overrideState ?? initialShapefileContextState
  );

  return (
    <ShapefileContext.Provider value={state}>
      <ShapefileDispatchContext.Provider value={dispatch}>
        {children}
      </ShapefileDispatchContext.Provider>
    </ShapefileContext.Provider>
  );
}
