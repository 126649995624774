import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { GeoMapSettings } from "../models/settings";

const saveSettings = async (template: GeoMapSettings) => {
  const response = await axios.put(
    `${GEO_MAP_SERVICE_ADDRESS}/settings/${template.id.$oid}`,
    template
  );
  return response.data;
};

export const useUpdateSettings = () => {
  return useMutation<string, AxiosError, unknown, unknown>(saveSettings);
};
