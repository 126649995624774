import { useQuery } from "react-query";

import axios from "axios";

import { uid } from "../../../utils";
import { AccessToken } from "../models/AccessToken";
import { TokenUsageHistory } from "../models/TokenUsageHistory";

const SERVICE_ACCOUNT_TOKEN_ADDRESS =
  process.env.REACT_APP_SERVICE_ACCOUNT_SERVICE ?? "http://localhost";

export function useGetApiTokens() {
  return useQuery("apiTokens", async () => {
    const response = await axios.get<AccessToken[]>(
      `${SERVICE_ACCOUNT_TOKEN_ADDRESS}/organization`
    );
    if (response.status !== 200) {
      throw new Error("Error fetching api tokens");
    }
    return response.data.map((token) => ({
      ...token,
      createdAt: new Date(token.createdAt).toISOString().substring(0, 10),
      lastUsedAt: token.lastUsedAt
        ? new Date(token.lastUsedAt).toISOString().substring(0, 10)
        : undefined,
      expiresAt: token.expiresAt
        ? new Date(token.expiresAt).toISOString().substring(0, 10)
        : undefined
    }));
  });
}

export function useTokenUsageHistory(tokenId: string) {
  return useQuery(["token-usage-history", tokenId], async (queryData) => {
    const response = await axios.get<TokenUsageHistory[]>(
      `${SERVICE_ACCOUNT_TOKEN_ADDRESS}/usage-history/${queryData.queryKey[1]}`
    );
    if (response.status !== 200) {
      throw new Error("Error fetching api tokens");
    }
    return response.data.map((token) => ({
      ...token,
      id: uid(),
      createdAt: new Date(token.createdAt).toLocaleString()
    }));
  });
}
