import "./IconDashSpinner.scss";

function IconDashSpinner({ size = 20 }) {
  return (
    <svg className="dashSpinner" viewBox="0 0 50 50" width={size} height={size}>
      <circle
        className="circle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="8"></circle>
    </svg>
  );
}
export default IconDashSpinner;
