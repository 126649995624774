import { useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { FileCopy } from "@material-ui/icons";
import { Dropdown } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { Uwi } from "models";

import { PresetsPopover } from "../settings";

const ToolbarHeader = () => {
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);
  const selectedWellUWI = useMemo(() => {
    if (!selectedWells || !Object.keys(selectedWells).length) return;

    return new Uwi().toFormatted(Object.keys(selectedWells)[0]);
  }, [selectedWells]);

  const handleDropdownSelect = (f) => {
    if (!selectedWellUWI) return;
    let clipboardText;
    if (f === "unformatted") {
      clipboardText = new Uwi().toUnformatted(selectedWellUWI);
    } else {
      clipboardText = new Uwi().toFormatted(selectedWellUWI);
    }

    navigator.clipboard.writeText(clipboardText);
    toast.success(`Well UWI copied to clipboard`);
  };

  return (
    <Wrapper>
      <PresetsPopover />
      <Divider />
      <WellUWICell data-qa="multiphasechart-toolbar-UWI-label">
        {selectedWellUWI}
        <Dropdown
          menu={{
            onClick: (v) => handleDropdownSelect(v.key),
            items: [
              {
                key: "formatted",
                label: "Entity Name"
              },
              {
                key: "unformatted",
                label: "Unformatted UWID"
              }
            ]
          }}
          trigger={["click"]}>
          <WellUWICopyButton data-qa="multiphasechart-toolbar-UWI-copy-button">
            <FileCopy />
          </WellUWICopyButton>
        </Dropdown>
      </WellUWICell>
    </Wrapper>
  );
};

export default ToolbarHeader;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;

const WellUWICopyButton = styled.span`
  cursor: pointer;
  color: var(--color-action-button);
  opacity: 0;
  &:hover {
    color: var(--color-primary);
  }
`;

const WellUWICell = styled.div`
  padding-left: 8px;
  display: flex;
  gap: 8px;
  grid-column: 1 / -1;
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 800;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover > ${WellUWICopyButton} {
    opacity: 1;
  }
`;
