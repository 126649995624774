import { getCheckedTreeKeysSessionKey } from "./getCheckedTreeKeysSessionKey";

export function setCheckedTreeKeysToSession({
  checkedTreeKeys,
  projectId
}: {
  checkedTreeKeys: string[];
  projectId: string;
}) {
  const sessionKey = getCheckedTreeKeysSessionKey(projectId);
  sessionStorage.setItem(sessionKey, JSON.stringify(checkedTreeKeys));
}
