import { IMapLegend, MapLegendItem } from "api/map";

/**
 * Takes the colours from the map legend and copies them over to the map screenshot
 * legend, preserving the well count for the categories of the map screenshot legend.
 * This is because the map screenshot has its own filterId which can return
 * a different colour set for the items as the categories may be different.
 * @param {IMapLegend} mapLegend The map legend containing the colours to be copied
 * @param {IMapLegend} mapScreenshotLegend The screenshot map legend containing the item which need their colours replaced
 * @returns {IMapLegend} The screenshot map legend with the copied colours
 */
export const copyMapColors = (
  mapLegend: IMapLegend,
  mapScreenshotLegend: IMapLegend
): IMapLegend => {
  mapScreenshotLegend.legendItems.forEach((screenshotLegendItem: MapLegendItem) => {
    const matchedLegendItem = mapLegend.legendItems.find(
      (legendItem: MapLegendItem) => legendItem.title === screenshotLegendItem.title
    ) as MapLegendItem;
    if (matchedLegendItem) {
      screenshotLegendItem.color = matchedLegendItem.color;
    }
  });
  return mapScreenshotLegend;
};
