/**
 * Component imports valnav type curves. Lets users select job, hierarchy and Type Curve (TC).
 */
import { useState } from "react";
import { useQueryClient } from "react-query";

import { Button, Popconfirm, Select, Spin } from "antd";
import styled from "styled-components";

import useValNavEntitiesQuery, {
  VALNAV_ENTITIES_QUERY_KEYS
} from "hooks/useValNavEntitiesQuery";
import useValNavHierarchiesQuery, {
  VALNAV_HIERARCHIES_QUERY_KEYS
} from "hooks/useValNavHierarchiesQuery";
import useValNavJobsQuery, { VALNAV_JOBS_QUERY_KEYS } from "hooks/useValNavJobsQuery";

import { Rescat } from "api/userForecasts";

import { IconSpinner } from "components/icons";
import { RESERVE_CATEGORIES } from "components/sync/util";

const { Option } = Select;

export interface ValNavTypeWellImporterModel {
  children: JSX.Element;
  onOk: (job: string, rescat: Rescat, typeWells: string[]) => void;
}

export default function ValNavTypeWellImporter({
  children,
  onOk
}: ValNavTypeWellImporterModel) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState("99-HZANLG");
  const [selectedReserveCategory, setSelectedReserveCategory] = useState<Rescat>("TPP");
  const [typeWells, setTypeWells] = useState<string[]>([]);

  //Queries
  const queryClient = useQueryClient();

  // Autorefetching tracking for jobs, hierarchies and entities is needed because
  // each folder uses these hooks and as each row is added to the list
  // on scroll, new fetches are called for all three which is not needed
  // Jobs Query
  const isJobsAutoRefetching =
    isOpen && !queryClient.getQueryData([VALNAV_JOBS_QUERY_KEYS.valNavJobs]);

  const { data: jobs } = useValNavJobsQuery(isJobsAutoRefetching);

  // Hierarchies Query
  const isHierarchiesAutoRefetching =
    isOpen &&
    !queryClient.getQueryData([
      VALNAV_HIERARCHIES_QUERY_KEYS.valNavHierarchies,
      selectedJob
    ]);

  const valNavHierarchiesQuery = useValNavHierarchiesQuery(
    selectedJob,
    isHierarchiesAutoRefetching
  );

  // Entities Query
  const [selectedHierarchy, setSelectedHierarchy] = useState(
    valNavHierarchiesQuery?.data?.[0]
  );

  const nextHierarchy = selectedHierarchy || valNavHierarchiesQuery?.data?.[0];
  const isEntitiesAutoRefetching =
    isOpen &&
    !queryClient.getQueryData([
      VALNAV_ENTITIES_QUERY_KEYS.valNavEntities,
      selectedJob,
      nextHierarchy
    ]);

  const valNavEntitiesQuery = useValNavEntitiesQuery(
    selectedJob,
    nextHierarchy,
    isEntitiesAutoRefetching
  );

  return (
    <Container>
      <Popconfirm
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
        open={isOpen}
        overlayClassName="valnav-tw-importer-popover"
        onConfirm={() => {
          onOk && onOk(selectedJob, selectedReserveCategory, typeWells);
          setTypeWells([]);
        }}
        placement="bottom"
        trigger="click"
        icon={null}
        title={
          <ContentContainer>
            <OptionItemVertical>
              <label>Job</label>
              <Select
                showSearch
                popupClassName="modal-select"
                onChange={(val) => setSelectedJob(val)}
                value={selectedJob}
                options={(jobs ?? []).map((j) => ({ label: j, value: j }))}
              />
            </OptionItemVertical>
            <OptionItemVertical>
              <label>Reserve Category</label>
              <Select
                value={selectedReserveCategory}
                onChange={setSelectedReserveCategory}>
                {RESERVE_CATEGORIES.map((rc) => {
                  return (
                    <Option key={rc} value={rc}>
                      {rc}
                    </Option>
                  );
                })}
              </Select>
            </OptionItemVertical>
            <OptionItemVertical>
              <label>
                Hierarchy
                <Spin
                  indicator={<IconSpinner />}
                  spinning={valNavHierarchiesQuery.isLoading}
                />
              </label>
              <Select
                value={selectedHierarchy || valNavHierarchiesQuery?.data?.[0]}
                onChange={setSelectedHierarchy}
                options={(valNavHierarchiesQuery.data ?? []).map((h) => ({
                  label: h,
                  value: h
                }))}
              />
            </OptionItemVertical>
            <OptionItemVertical>
              <label>
                Type Wells
                <Spin
                  indicator={<IconSpinner />}
                  spinning={valNavEntitiesQuery.isLoading}
                />
              </label>
              <Select
                mode="multiple"
                allowClear
                popupClassName="modal-select"
                listHeight={180}
                value={typeWells}
                autoClearSearchValue={false}
                onChange={(items) => {
                  setTypeWells(items);
                }}
                options={(valNavEntitiesQuery.data ?? []).map((w) => ({
                  label: w,
                  value: w
                }))}
              />
            </OptionItemVertical>
            {valNavHierarchiesQuery.isError && (
              <OptionItemVertical>
                <ErrorContainer>
                  {valNavHierarchiesQuery.error instanceof Error
                    ? valNavHierarchiesQuery.error.message
                    : "An error occured"}
                </ErrorContainer>
              </OptionItemVertical>
            )}
            {valNavEntitiesQuery.isError && !valNavHierarchiesQuery.isError && (
              <OptionItemVertical>
                <ErrorContainer>
                  {valNavEntitiesQuery.error instanceof Error
                    ? valNavEntitiesQuery.error.message
                    : "An error occured"}
                </ErrorContainer>
              </OptionItemVertical>
            )}
            <OptionItem>
              <Button />
            </OptionItem>
          </ContentContainer>
        }>
        {children}
      </Popconfirm>
    </Container>
  );
}

const Container = styled.div``;

const ContentContainer = styled.div`
  gap: 10px;
  display: flex;
  min-height: 300px;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  width: 320px;
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  label {
    min-width: 100px;
  }
  .ant-typography {
    margin-bottom: 0;
  }
`;

const OptionItemVertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  .ant-select {
    width: 320px;
  }
`;
