import classnames from "classnames";
import styled from "styled-components/macro";

import { IconSpinner } from "components/icons";

const BusyIndicator = ({ visible = false }) => {
  const wrapperClassnames = classnames({ visible });

  return (
    <Wrapper className={wrapperClassnames}>
      <IconSpinner />
    </Wrapper>
  );
};

export default BusyIndicator;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--color-text-rgb), 0.06);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0 linear 0.2s;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
`;
