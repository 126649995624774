import { memo, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { updateUser } from "store/features";
import styled from "styled-components";

import { deleteUserProfilePicture, updateProfilePicture } from "api/users";

import { IUser } from "models/User";

import { BaseButton } from ".";
import BaseModal from "../base/BaseModal";
import Avatar from "./Avatar";
import "./AvatarField.scss";

const userServiceRoot = process.env.REACT_APP_USER_SERVICE + "/user";

const RemovePhotoLink = styled.a`
  min-width: 66px;
  font-size: 1.2rem;
  color: #b5b5b5;
  transition: color 150ms;
  cursor: pointer;
  padding: 0 10px;
  .MuiSvgIcon-root {
    vertical-align: sub;
    margin-right: 2px;
  }
  &:hover {
    color: var(--color-danger);
  }
`;

export interface IAvatarFieldProps {
  user: IUser;
}

function AvatarField(props: IAvatarFieldProps) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const updatePic = useMutation(
    async (data: FormData) => {
      return await updateProfilePicture({
        userId: props.user.id,
        data
      });
    },
    {
      onSuccess: (data) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedUser = data as any;
        dispatch(
          updateUser({
            ...props.user,
            profilePictureImageSrc: updatedUser.profilePictureImageSrc
          })
        );
        toast.success("Profile picture updated.");
      },
      onError: () => {
        toast.error("Error updating profile picture.");
      }
    }
  );

  useEffect(() => {
    if (!selectedFile) {
      setPreview(`${userServiceRoot}/${props.user.profilePictureImageSrc}`);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    const data = new FormData();
    data.append("profile", selectedFile);
    updatePic.mutateAsync(data);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (props.user.profilePictureImageSrc) {
      setPreview(
        `${userServiceRoot}/${
          props.user.profilePictureImageSrc
        }?timestamp=${new Date().getTime()}`
      );
    } else {
      setPreview(null);
    }
  }, [props.user, updatePic.data]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const deleteAvatar = useMutation(
    async () => {
      closeModal();
      return await deleteUserProfilePicture(props.user.id);
    },
    {
      onSuccess: (data) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedUser = data as any;
        dispatch(
          updateUser({
            ...props.user,
            profilePictureImageSrc: updatedUser.profilePictureImageSrc
          })
        );
        setSelectedFile(null);
        toast.success("Successfully deleted profile picture. ");
      },
      onError: () => {
        toast.error("Error deleting profile picture.");
      }
    }
  );

  function openModal() {
    setShowRemoveDialog(true);
  }

  function closeModal() {
    setShowRemoveDialog(false);
  }

  function handleRemove() {
    deleteAvatar.mutateAsync();
  }

  return (
    <div className="avatar-container flex-row">
      <input type="file" onChange={onSelectFile} ref={inputRef} />
      <div className="input-container">
        {selectedFile ||
        (preview &&
          props.user.profilePictureImageSrc &&
          props.user.profilePictureImageSrc.length > 0) ? (
          <img src={preview} />
        ) : (
          <Avatar user={props.user} initials={true} />
        )}
      </div>
      <div className="avatar-actions">
        {selectedFile ||
        (preview &&
          props.user.profilePictureImageSrc &&
          props.user.profilePictureImageSrc.length > 0) ? (
          <div className="flex-column">
            <BaseButton appearance="subtle" onClick={handleClick}>
              Change photo
            </BaseButton>
            <RemovePhotoLink onClick={openModal}>
              <RemoveCircleOutlineIcon />
              Remove photo
            </RemovePhotoLink>
          </div>
        ) : (
          <BaseButton appearance="subtle" onClick={handleClick}>
            Upload photo
          </BaseButton>
        )}
      </div>
      <BaseModal
        open={showRemoveDialog}
        onClose={closeModal}
        title={
          <div className="flex-row items-center">
            <ErrorOutlineIcon style={{ fontSize: "40px" }} />
            Remove profile photo
          </div>
        }
        size="xs"
        className="remove-avatar-dialog">
        <div className="flex-row">
          <p>
            If you remove your profile photo, your avatar will revert back to your
            initals.
          </p>
        </div>
        <div className="flex-row" style={{ marginTop: "37px", gap: "24px" }}>
          <BaseButton autoFocus onClick={closeModal} appearance="stroked">
            Cancel
          </BaseButton>
          <BaseButton autoFocus onClick={handleRemove} appearance="danger">
            Remove
          </BaseButton>
        </div>
      </BaseModal>
    </div>
  );
}

export default memo(AvatarField);
