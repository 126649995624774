// eslint-disable-next-line import/no-named-as-default
import { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { ScreenshotPresetModel } from "models/model";

export interface ScreenshotPresetContextState {
  selectedPreset?: ScreenshotPresetModel;
}

export type UpdateScreenshotPreset = {
  selectedPreset?: ScreenshotPresetModel;
};

export const initialContextState: ScreenshotPresetContextState = {};

export const ScreenshotPresetContext = createContext(initialContextState);

export interface ScreenshotPresetAction<T, Type> {
  type?: Type;
  payload: T;
}
export const ScreenshotPresetDispatchContext =
  createContext<
    React.Dispatch<ScreenshotPresetAction<ScreenshotPresetUpdates, "update">>
  >(undefined);

export type ScreenshotPresetUpdates = UpdateScreenshotPreset;

function ScreenshotPresetReducer(
  state: ScreenshotPresetContextState,
  action: ScreenshotPresetAction<ScreenshotPresetUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface ScreenshotPresetProviderModel {
  children: ReactNode;
  state?: ScreenshotPresetContextState;
}

export function ScreenshotPresetProvider({
  children,
  state: overrideState
}: ScreenshotPresetProviderModel) {
  const [state, dispatch] = useReducer(
    ScreenshotPresetReducer,
    overrideState ?? initialContextState
  );

  return (
    <ScreenshotPresetContext.Provider value={state}>
      <ScreenshotPresetDispatchContext.Provider value={dispatch}>
        {children}
      </ScreenshotPresetDispatchContext.Provider>
    </ScreenshotPresetContext.Provider>
  );
}
