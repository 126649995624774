import { memo, useState } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Popover } from "antd";
import styled from "styled-components/macro";

import { components } from "components/multiphase-chart/components/toolbar/Toolbar";
import ToolbarButton from "components/multiphase-chart/components/toolbar/ToolbarButton";
import { useChartSettings } from "components/multiphase-chart/context";

const OverflowToggle = () => {
  const [overflowVisible, setOverflowVisible] = useState(false);

  const [chartSettings] = useChartSettings();
  const overflowIndex = chartSettings.overflowIndex;
  // if only one item is in the overflow menu, don't overflow
  const isOverflowing = overflowIndex < components.length - 1;
  const hiddenComponents = components
    .slice(overflowIndex)
    .filter((c) => c.key !== "globalSettingsDivider");

  if (!isOverflowing) return null;

  return (
    <Popover
      arrowPointAtCenter
      content={
        <OverflowWrapper>
          {hiddenComponents.map((component) => component)}
        </OverflowWrapper>
      }
      placement="bottomRight"
      trigger="click"
      open={overflowVisible}
      onOpenChange={(v) => setOverflowVisible(v)}>
      <ToolbarButton
        active={overflowVisible}
        icon={<MoreVertIcon fontSize="large" />}
        overflowLabel=""
        tooltipText="More"
        onToggle={(v) => setOverflowVisible(v)}
        testId="multiproduct-overflow"
      />
    </Popover>
  );
};

export default memo(OverflowToggle);

const OverflowWrapper = styled.div`
  display: grid;
  max-height: 300px;
  border-radius: var(--border-radius);
  padding: 8px 12px;
  margin: -12px -16px;
  overflow: hidden scroll;

  & > button {
    width: auto !important;
  }
`;
