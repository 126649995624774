import { Popover as AntdPopOver, PopoverProps } from "antd";

const delay: number = 0.6;

const PopOver = ({ children, ...props }: PopoverProps) => {
  return (
    <AntdPopOver mouseEnterDelay={props.mouseEnterDelay || delay} {...props}>
      {children}
    </AntdPopOver>
  );
};

export default PopOver;
