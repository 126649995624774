import { memo } from "react";

function GasEnergy() {
  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
      <g transform="translate(5, 0)">
        <path
          d="M19.4786 16.6962C19.141 11.248 9.59585 0 9.59585 0C7.43684 9.69182 -0.00861992 10.7961 7.49155e-06 17.1761C0.00539963 21.4812 1.95735 23.981 4.39244 25.3085C3.62136 2.73 3.93411 19.7374 6.24949 17.574C10.5244 13.5796 10.9946 10.8932 10.9946 10.8932C15.4172 18.7452 14.3323 23.679 12.6661 26.1669C16.7232 25.0465 19.7881 21.6839 19.4786 16.6962"
          id="Gas"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
      <g>
        <rect
          x="16" // Set the x position to 50% for centering
          y="16" // Set the y position to 50% for centering
          width="15" // Set the width to 50% for the bottom right position
          height="11" // Set the height to 50% for the bottom right position
          fill="#FFF"
        />
      </g>
      <g transform="translate(12, 16) scale(0.60)">
        <path
          d="M16.67,4H15V2H9V4H7.33A1.33,1.33 0 0,0 6,5.33V20.66C6,21.4 6.6,22 7.33,22H16.66C17.4,22 18,21.4 18,20.67V5.33C18,4.6 17.4,4 16.67,4M11,20V14.5H9L13,7V12.5H15"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default memo(GasEnergy);
