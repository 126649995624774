import useResizeObserver from "@react-hook/resize-observer";
import type { MutableRefObject } from "react";

import { updateToolbarHeight, useTable } from "components/data-table/context";

const useColumnChooserSize = (ref: MutableRefObject<HTMLDivElement>) => {
  const [, dispatch] = useTable();

  useResizeObserver(ref, (client) => {
    if (!client?.contentRect) return;
    updateToolbarHeight(dispatch, client.contentRect.height);
  });
};

export default useColumnChooserSize;
