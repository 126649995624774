import { useMutation } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";

const deleteMapLayer = async (data: { id: number }) => {
  const response = await axios.delete(`${GEO_MAP_SERVICE_ADDRESS}/map-layer/${data.id}`);
  if (response.status !== 200) {
    throw new Error("Failed to delete GeoMapLayers");
  }
  return response.data;
};

const useDeleteMapLayer = () => {
  return useMutation(deleteMapLayer);
};

export default useDeleteMapLayer;
