import { FunctionComponent, useRef } from "react";
import { useSelector } from "react-redux";

import type { PaginationProps } from "antd";
import { Pagination } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { changePage, changePageSize, useTable } from "./context";
import { useLowercasePaginationJumper } from "./hooks";

const showTotal: PaginationProps["showTotal"] = (total) => `Total ${total} items`;

const DataTablePagination: FunctionComponent = () => {
  const ref = useRef<HTMLDivElement>();
  useLowercasePaginationJumper(ref);

  const [{ page, pagination, pageSize }, dispatch] = useTable();

  // state from store
  const filterCounts = useSelector((state: RootState) => state.filter.filterCounts);

  // derive total results from filterCounts state
  const totalResults = filterCounts.Active ?? filterCounts.UniqueId ?? 0;

  // save page and pageSize to context
  const handleChange: PaginationProps["onChange"] = (page, pageSize) => {
    changePage(dispatch, page);
    changePageSize(dispatch, pageSize);
  };

  return (
    <Wrapper ref={ref}>
      <Pagination
        current={page}
        pageSize={pagination ? pageSize : totalResults}
        pageSizeOptions={
          pagination ? ["20", "50", "100", String(pageSize)] : [String(totalResults)]
        }
        size="small"
        total={totalResults}
        showTotal={showTotal}
        showSizeChanger
        showQuickJumper
        onChange={handleChange}
        className="dataTablePaginationToolbar"
      />
    </Wrapper>
  );
};

export default DataTablePagination;

const Wrapper = styled.div`
  --ant-primary-color: var(--color-primary);
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;

  .ant-pagination.ant-pagination-mini {
    display: flex;
    width: 100%;

    .ant-pagination-options {
      flex-shrink: 0;
      margin-left: auto;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid rgba(0 0 0 / 0.1);
    }
    .ant-pagination-prev {
      translate: -280px -50%;
    }
    .ant-pagination-next {
      translate: 120px -50%;
    }
  }
  .ant-pagination-total-text {
    margin-right: auto;
    white-space: nowrap;
  }
  .ant-pagination-item > a {
    text-decoration: none;
  }
  .ant-pagination-item-active {
    border-width: 1.5px;
  }
  .ant-pagination-item-active:hover {
    border-color: var(--color-primary-hover);
  }
  .ant-pagination-item-active:hover > a {
    color: var(--color-primary-hover);
  }

  .ant-pagination-options-quick-jumper {
    display: inline-flex;
    align-items: center;
    gap: 4px;

    input {
      order: 10;
    }
  }
`;
