import axios from "axios";

export const userServiceRoot = process.env.REACT_APP_USER_SERVICE;
const colorServiceRoot = process.env.REACT_APP_COLOR_SERVICE;

interface UserSettingT {
  id?: string;
}

export async function getMySettings(group) {
  const response = await axios.get(`${userServiceRoot}/user/setting/my/${group}`);
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}
export async function getPublicSettings(group) {
  const response = await axios.get(`${userServiceRoot}/user/setting/public/${group}`);
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function getOrganizationTypes(onSuccess, onError): Promise<void> {
  axios.get(`${userServiceRoot}/organization/org-types`).then(onSuccess).catch(onError);
}

export async function getOrganizationSizes(onSuccess, onError): Promise<void> {
  axios.get(`${userServiceRoot}/organization/org-sizes`).then(onSuccess).catch(onError);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getOrganizationPalettes(): Promise<any> {
  const response = await axios.get(`${colorServiceRoot}/palette`);
  if (response.status !== 200) {
    return null;
  }
  return response.data;
}

export async function updateUserSetting<T extends UserSettingT>(
  setting: string,
  value: T
): Promise<T> {
  if (value.id) {
    const response = await axios.put(
      `${userServiceRoot}/user/setting/${setting}/${value.id}`,
      value
    );
    if (response.status !== 200) {
      throw "Unable to update setting.";
    }
    return response.data;
  } else {
    const response = await axios.post(
      `${userServiceRoot}/user/setting/${setting}`,
      value
    );
    if (response.status !== 200) {
      throw "Unable to update setting.";
    }
    return response.data;
  }
}

export async function getUserSetting<T extends UserSettingT>(
  setting: string
): Promise<T> {
  const response = await axios.get<T[]>(`${userServiceRoot}/user/setting/${setting}`);
  if (response.status !== 200) {
    throw "Unable to fetch user setting.";
  }
  if (response.data.length) {
    return response.data[0] as T;
  }
  return null;
}
