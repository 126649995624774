import {
  CopyWellListFormatT,
  IWellGroupMap,
  IWellListState,
  SelectedWellT,
  UwidFormatT,
  WellInfoT,
  WellListDispatchT
} from "../WellListContext";

type WellListReducerActionsT =
  | { type: "update well list"; wells: WellInfoT[] }
  | { type: "update selected well"; well?: SelectedWellT }
  | { type: "update well group map"; mapping?: IWellGroupMap }
  | { type: "update setting uwi format"; format: UwidFormatT }
  | { type: "update setting copy format"; format: CopyWellListFormatT }
  | { type: "update setting scroll to well"; enabled: boolean }
  | { type: "update setting show focus color"; enabled: boolean };

const wellListReducer = (draft: IWellListState, action: WellListReducerActionsT) => {
  switch (action.type) {
    case "update well list": {
      draft.wells = action.wells;
      break;
    }
    case "update selected well": {
      // keep track of the last 10 selected wells.
      if (draft.selectedWell) {
        const existingIndex = draft.previouslySelectedWells.findIndex(
          (w) => w.Uwi === draft.selectedWell.Uwi
        );
        if (existingIndex !== -1) {
          draft.previouslySelectedWells.splice(existingIndex, 1);
        }

        draft.previouslySelectedWells.unshift(draft.selectedWell);
        if (draft.previouslySelectedWells.length > 10) {
          draft.previouslySelectedWells.pop();
        }
      }
      draft.selectedWell = action.well;
      break;
    }
    case "update well group map": {
      draft.wellGroupMap = action.mapping;
      break;
    }
    case "update setting uwi format": {
      draft.settings.uwidFormat = action.format;
      break;
    }
    case "update setting copy format": {
      draft.settings.copyFormat = action.format;
      break;
    }
    case "update setting scroll to well": {
      draft.settings.scrollToSelected = action.enabled;
      break;
    }
    case "update setting show focus color": {
      draft.settings.showFocusColor = action.enabled;
      break;
    }
    default: {
      throw new Error("invalid well list reducer action");
    }
  }
};

// module functions
const updateWellList = (dispatch: WellListDispatchT, wells: WellInfoT[]) =>
  dispatch({ type: "update well list", wells });

const updateSelectedWell = (dispatch: WellListDispatchT, well?: SelectedWellT) =>
  dispatch({ type: "update selected well", well });

const updateWellGroupMap = (dispatch: WellListDispatchT, mapping?: IWellGroupMap) =>
  dispatch({ type: "update well group map", mapping });

const updateSettingUwiFormat = (dispatch: WellListDispatchT, format: UwidFormatT) =>
  dispatch({ type: "update setting uwi format", format });

const updateSettingCopyFormat = (
  dispatch: WellListDispatchT,
  format: CopyWellListFormatT
) => dispatch({ type: "update setting copy format", format });

const updateSettingScrollToWell = (dispatch: WellListDispatchT, enabled: boolean) =>
  dispatch({ type: "update setting scroll to well", enabled });

const updateSettingShowFocusColor = (dispatch: WellListDispatchT, enabled?: boolean) =>
  dispatch({ type: "update setting show focus color", enabled });

export {
  updateSelectedWell,
  updateSettingUwiFormat,
  updateSettingCopyFormat,
  updateSettingScrollToWell,
  updateSettingShowFocusColor,
  updateWellGroupMap,
  updateWellList,
  wellListReducer
};

export type { WellListReducerActionsT };
