import { memo, useState } from "react";

import { Popover } from "antd";

import StatsIcon from "components/icons/Stats";

import SeriesStyleSelector from "../../SeriesStyleSelector";
import { useChartState } from "../../context";
import { getAverageSeriesToggleDisabledStatus } from "../../utils/toggles";
import ToolbarButton from "../ToolbarButton";

const AverageSeriesToggle = () => {
  const { settings } = useChartState();
  const [visible, setVisible] = useState(false);
  const isDisabled = getAverageSeriesToggleDisabledStatus(settings.chartType);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={(v) => setVisible(v)}
      content={<SeriesStyleSelector />}>
      <ToolbarButton
        active={
          visible ||
          settings.average ||
          settings.showP10 ||
          settings.showP50 ||
          settings.showP90
        }
        icon={<StatsIcon />}
        overflowLabel={"Statistics"}
        tooltipText={"Show Statistics"}
        onToggle={null}
        disabled={isDisabled}
      />
    </Popover>
  );
};

export default memo(AverageSeriesToggle);
