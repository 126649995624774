import { IUser } from "models/User";
import { DashboardPermissions } from "models/dashboard";
import { Dashboard, WorkspaceDashboard } from "models/workspace";

export const getDashboardPermissions = (
  dashboard: Dashboard | WorkspaceDashboard,
  user: IUser,
  isAtLeastPowerUser: boolean,
  canEditWorkspace?: boolean
): DashboardPermissions => {
  let viewable = false;
  let editable = false;
  let deletable = false;

  switch (dashboard?.type) {
    case "Personal":
      viewable = dashboard.owner === user.id;
      editable = dashboard.owner === user.id;
      deletable = editable && canEditWorkspace;
      break;
    case "Shared":
      viewable = true;
      editable = dashboard.owner === user.id || isAtLeastPowerUser;
      deletable = editable;
      break;
    case "Organization":
      viewable = true;
      editable = isAtLeastPowerUser;
      deletable = editable;
      break;
    case "System":
      viewable = true;
      editable = false;
      deletable = false;
      break;
  }

  return {
    canView: viewable,
    canEdit: editable,
    canDelete: deletable
  };
};
