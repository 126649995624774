import { useIpdbContext } from "components/map/contexts/IpdbContext";
import { IPDBLegendBase } from "components/map/shared/IPDBLegendBase";
import { useScreenshotContext } from "components/screenshot/hooks";

const dimensionsStorageKey = `map-screenshot-ipdb-legend-dimensions`;
const positionStorageKey = `map-screenshot-ipdb-legend-position`;
const parentDimensionsStorageKey = `map-screenshot-ipdb-parent-dimensions`;

type IpdbLegendWrapperT = {
  isScreenshotOverlayVisible: boolean;
};

export const IpdbLegendWrapper: React.FC<IpdbLegendWrapperT> = ({
  isScreenshotOverlayVisible
}) => {
  // Hooks
  const { settings } = useScreenshotContext();

  const { showIpdb } = useIpdbContext();

  const mapScreenshotScale = isNaN(Number(settings?.mapScreenshotScale))
    ? 1
    : Number(settings?.mapScreenshotScale);
  const screenshotBounds = {
    width: (settings?.width || 1152) * mapScreenshotScale,
    height: (settings?.height || 681) * mapScreenshotScale
  };
  const backgroundOpacity = isScreenshotOverlayVisible
    ? Number(settings?.ipdbLegendOpacity) ?? 1
    : 1;
  const ipdbLabelsSize = isScreenshotOverlayVisible
    ? (settings?.ipdbLabelsSize ?? 14) * mapScreenshotScale
    : 14;
  const ipdbTitleSize = isScreenshotOverlayVisible
    ? (settings?.ipdbTitleSize ?? 15) * mapScreenshotScale
    : 15;
  return (
    <IPDBLegendBase
      parentDimensions={screenshotBounds}
      dimensionsStorageKey={dimensionsStorageKey}
      positionStorageKey={positionStorageKey}
      parentDimensionsStorageKey={parentDimensionsStorageKey}
      showLegend={(showIpdb && settings?.showIpdbLegendInScreenshot) ?? true}
      showLegendBorder={(showIpdb && settings?.showIpdbLegendBorderInScreenshot) ?? true}
      ipdbLabelsSize={ipdbLabelsSize}
      ipdbTitleSize={ipdbTitleSize}
      isScreenshotOverlayVisible={isScreenshotOverlayVisible}
      backgroundOpacity={backgroundOpacity}
    />
  );
};
