import { memo } from "react";

function ChevronRight({ size = 18 }) {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} fill="currentColor">
      <path
        fill="currentColor"
        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
      />{" "}
    </svg>
  );
}
export default memo(ChevronRight);
