import { useCallback, useRef, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import LockIcon from "@material-ui/icons/Lock";
import classnames from "classnames";
import styled from "styled-components/macro";

import "./AxisInput.scss";

function AxisInput({
  className = "",
  disabled = false,
  defaultValue,
  axisInput = null,
  setAxisInput = null,
  inputFormat = "number",
  type = "Number",
  step = "",
  min = "",
  max = "",
  id = "",
  placeholder = "0",
  onSave = null,
  ...rest
}) {
  const dateInputRef = useRef(null);
  const [hoveringLock, setHoveringLock] = useState(false);
  const [currValue, setCurrValue] = useState(
    inputFormat === "number" && typeof axisInput.value === "string"
      ? Number(axisInput.value.replace(/,/g, ""))
      : inputFormat === "number" && typeof axisInput.value === "number"
      ? axisInput.value
      : inputFormat === "YYYY-MM"
      ? axisInput.value.toString()
      : axisInput.value.toString().substr(0, 4)
  );

  function handleHide() {
    setAxisInput({ type: "", value: currValue });
  }

  function handleBlur(e) {
    if (e.currentTarget === e.target) {
      !hoveringLock && handleHide();
    }
  }

  function handleChange(evt) {
    setCurrValue(evt.target.value);
  }

  function handleSave() {
    setAxisInput({ type: "", value: currValue });
    onSave &&
      onSave({
        [axisInput.type]: currValue
      });
  }

  function handleKeypress(event) {
    if (event.key === "Enter") {
      handleSave();
    }
    if (event.key === "Escape") {
      handleHide();
    }
  }

  function handleDatetimeChange(value) {
    const formattedValue = typeof value === "object" ? value.format(inputFormat) : value;
    setCurrValue(formattedValue);

    setAxisInput({ type: "", value: formattedValue });
    onSave &&
      onSave({
        [axisInput.type]: formattedValue
      });
  }

  function handelDatetimeClose(value) {
    if (value) {
      const newValue = value.format(inputFormat);
      setCurrValue(() => newValue);
      setAxisInput({ type: "", value: newValue });
    } else {
      setCurrValue(() => "");
      setAxisInput({ type: "", value: "" });
    }
  }

  const innerRef = useCallback((input) => {
    if (input !== null) {
      input.select();
    }
  }, []);

  const wrapperClassnames = classnames(
    "multiphase-axis-input",
    className,
    inputFormat,
    axisInput?.type
  );

  return (
    <Wrapper className={wrapperClassnames}>
      <LockIcon
        onClick={handleSave}
        onMouseEnter={() => setHoveringLock(true)}
        onMouseLeave={() => setHoveringLock(false)}
      />
      {inputFormat === "number" ? (
        <input
          className="input"
          ref={innerRef}
          tabIndex={disabled ? -1 : 0}
          value={currValue || defaultValue}
          placeholder={placeholder}
          type={type}
          step={step}
          id={id}
          min={min}
          max={max}
          onBlur={handleBlur}
          onKeyDown={handleKeypress}
          onChange={handleChange}
          {...rest}
        />
      ) : (
        <Datetime
          inputProps={{
            autoFocus: true,
            className: "input",
            ref: dateInputRef,
            onKeyDown: handleKeypress
          }}
          dateFormat={inputFormat}
          timeFormat={false}
          value={currValue || defaultValue}
          onChange={handleDatetimeChange}
          onClose={handelDatetimeClose}
          closeOnSelect
        />
      )}
    </Wrapper>
  );
}

export default AxisInput;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
  width: 90px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px 0 rgba(var(--color-text-rgb), 10%);
  background-color: var(--white);
  z-index: 1;
`;
