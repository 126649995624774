import { useMutation } from "react-query";

import { deleteChartPreset, getPresets } from "api/multiProduct";

import { initialPresetState } from "../context";
import { IChartPreset } from "../models/shared.models";

const useChartPresetRemover = (successHandler, errorHandler) => {
  const removeQuery = async (preset: IChartPreset) => {
    const deleteResponse = await deleteChartPreset(preset);
    const presets = await getPresets();

    return {
      ...deleteResponse,
      nextPreset: presets[presets?.length - 1] ?? initialPresetState
    };
  };

  const removePresetMutation = useMutation(removeQuery, {
    onSuccess: (response) => {
      successHandler(response);
    },
    onError: (error) => {
      errorHandler(error);
    }
  });

  const removePreset = async (preset) => {
    try {
      const result = await removePresetMutation.mutateAsync(preset);
      return result;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return {
    removePreset,
    isLoading: removePresetMutation.isLoading,
    error: removePresetMutation.error,
    data: removePresetMutation.data
  };
};

export default useChartPresetRemover;
