import { useQuery } from "react-query";

import axios from "axios";

import { Organization } from "models/organization";

import { ApiResult } from "./apiResult";

///hook to fetch organizaitons
const userRoot = process.env.REACT_APP_USER_SERVICE;
export default function useOrganizations(token: string): ApiResult<Organization[]> {
  const { data, isLoading, isError, refetch } = useQuery<Organization[]>(
    "fetchOrganizations",
    async () => {
      if (!token) {
        return [];
      }
      const result = await axios.get<Organization[]>(`${userRoot}/organization`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return result.data;
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
