import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Add,
  ArrowBack,
  Delete,
  ErrorOutlineOutlined,
  List,
  MoreVert
} from "@material-ui/icons";
import {
  Alert,
  Button,
  Dropdown,
  InputRef,
  MenuProps,
  Popover,
  Select,
  Tooltip
} from "antd";
import _debounce from "lodash/debounce";
import {
  ICheckedForecast,
  setIsTypeWellSavable,
  setSelectedTypeWell
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { isPrimaryProduct, isRatio } from "utils/arps";

import { ArpSegment, UserArpsItem } from "models/UserArpsModel";

import { ErrorBoundary } from "components/base";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { ArpInput } from "components/shared";
import { RESERVE_CATEGORIES } from "components/sync/util";

import {
  getDefaultRateUnitsFromString,
  productStringToProductTypeEnum
} from "../../../../../utils";
import ChevronDown from "../../../../icons/ChevronDown";
import useSegmentTemplates from "../../../hooks/useSegmentTemplates";
import { SegmentTemplate } from "../../../models/SegmentTemplate";
import { arpsWasm } from "../../../utils/UpdateSegment";
import {
  convertDecline,
  getRampUpSegmentCumVolume,
  getTypewellTemplateFields
} from "../../../utils/arpsUtils";
import { getDurationInYears, numberOfMonthsAfterDate } from "../../../utils/dates";
import { getDeclineTitleWithType } from "../../../utils/declineHelpers";
import { groupArpsSegmentsByProduct } from "../../../utils/groupArpsSegmentsByProduct";
import { typeWellProductsMenuItems } from "./TypeWellProductOptions";

export interface TypeWellInputTableModel {
  arps?: ICheckedForecast;
  declineType: string;
  selectedTypeWellTitle: string;
  selectedTypeWellRescat: string;
  handleReload?: (item?: UserArpsItem) => void;
  handleReset?: () => void;
  handleSave?: (item?: UserArpsItem) => void;
  onChange: (item: ICheckedForecast) => void;
  saveStatus?;
}

export default function TypeWellInputTable({
  arps,
  declineType,
  selectedTypeWellTitle,
  selectedTypeWellRescat,
  handleReload,
  handleReset,
  handleSave,
  onChange,
  saveStatus
}: TypeWellInputTableModel) {
  const dispatch = useDispatch();

  const typeWellSettings = useSelector(
    (state: RootState) => state.userSetting.typeWellSettings
  );

  const projectPermissions = useSelectedProjectPermissions();

  const segmentTemplates = useSegmentTemplates();
  const inputRefs = useRef<MutableRefObject<InputRef>[]>([]);

  const [segmentTemplate, setSegmentTemplate] = useState<SegmentTemplate>(
    segmentTemplates[0]
  );
  const [mode, setMode] = useState<"Product" | "Segment">("Product");
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [templateError, setTemplateError] = useState(undefined);

  const [products, setProducts] = useState(
    getAvailableProducts(arps, typeWellProductsMenuItems)
  );

  const [calculatedFields, setCalculatedFields] = useState<{
    [product: string]: string[];
  }>({
    OIL: ["EUR"],
    GAS: ["EUR"],
    "SALES GAS": ["EUR"],
    WATER: ["EUR"],
    "COND.": ["EUR"],
    CONDENSATE: ["EUR"]
  });

  const hasSource = !!arps?.source;

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  useEffect(() => {
    setSegmentTemplateAndUpdateHeader(getData(arps, arpsWasm));
    setMode("Product");
  }, [declineType]);

  useEffect(() => {
    if (!arpsWasm) {
      return;
    }

    setProducts(getAvailableProducts(arps, typeWellProductsMenuItems));
    setSegmentTemplateAndUpdateHeader(getData(arps, arpsWasm));
    setMode("Product");
  }, [arps, setProducts]);

  // On first load of the page validateSegments() gets ran without segmentTemplate updating
  // Need this to fix warnings for two/three segment templates
  useEffect(() => {
    if (!arps || arps?.isFolder) {
      return;
    }
    if (arps?.arps) {
      validateSegments(arps?.arps);
    }
  }, [arps, segmentTemplate]);

  const debounceUpdate = useMemo(
    () =>
      _debounce(
        (arps: ICheckedForecast, product: string, header: string, val, fn) =>
          fn(arps, product, header, val),
        600
      ),
    []
  );

  function getUpdatedHeader(header) {
    if (header === "Di" || header === "D1f" || header === "Df" || header === "D2f") {
      return getDeclineTitleWithType(header, declineType);
    } else {
      return header;
    }
  }

  function updateHeaders(template) {
    const updatedRowHeaders = template.rowHeaders.map((header) => {
      return getUpdatedHeader(header);
    });

    const updatedTemplate = {
      ...template
    };
    updatedTemplate.rowHeaders = updatedRowHeaders;

    return updatedTemplate;
  }

  const setSegmentTemplateAndUpdateHeader = (newTemplate) => {
    const updatedTemplate = updateHeaders(newTemplate);
    setSegmentTemplate(updatedTemplate);
  };

  // validate segments
  const validateSegments = (arpsSegments: ArpSegment[]) => {
    const errors = {};
    const warnings = {};
    const primaryEndDateIndex = segmentTemplate.numberOfSegments - 1;
    const primaryEndDate = arpsSegments[primaryEndDateIndex]?.endDate
      ? new Date(Date.parse(arpsSegments[primaryEndDateIndex]?.endDate))
      : undefined;
    for (const segment of arpsSegments) {
      if (segment.qf && segment.qi < segment.qf && segment.di > 0) {
        errors[segment.product] = "invalid segment: qi cannot be less than qf.";
      }
      if (segment.qf && segment.qi > segment.qf && segment.di < 0) {
        errors[segment.product] =
          "invalid segment: negative decline requires qf to be greater than qi.";
      }
      if (segment.di === 0) {
        errors[segment.product] = "invalid segment: no decline (di is 0).";
      }
      if (isNaN(segment.b) || typeof segment.b == "undefined" || segment.b == null) {
        errors[segment.product] = "invalid b";
      }
      //check end dates
      try {
        const endDate = new Date(Date.parse(segment.endDate));
        const dateDiff = endDate.getUTCFullYear() - new Date().getUTCFullYear();
        if (dateDiff > 100) {
          errors[segment.product] = "invalid end date - segments run past 100 years";
        }
        if (numberOfMonthsAfterDate(primaryEndDate, endDate) >= 6) {
          warnings[segment.product] =
            "Warning: Primary product end date is 6+ months earlier";
        } else {
          delete warnings[segment.product];
        }
      } catch (err) {
        errors[segment.product] = "invalid end date - segments run past 100 years";
      }
    }
    setErrors(errors);
    setWarnings(warnings);
  };

  function getAvailableProducts(arps: ICheckedForecast, typeWellProductsMenuItems) {
    const arpsProducts = [
      ...new Set(arps?.arps?.map((item) => item.product)),
      ...new Set(arps?.constants?.map((item) => item.product))
    ];

    const hasCondensate =
      arpsProducts.indexOf("CONDENSATE") >= 0 || arpsProducts.indexOf("COND.") >= 0;
    const hasOil = arpsProducts.indexOf("OIL") >= 0;
    const hasWater = arpsProducts.indexOf("WATER") >= 0;
    const hasOGR = arpsProducts.indexOf("OGR") >= 0;
    const hasCGR = arpsProducts.indexOf("CGR") >= 0;
    const hasWGR = arpsProducts.indexOf("WGR") >= 0;
    const hasGOR = arpsProducts.indexOf("GOR") >= 0;
    const hasGas = arpsProducts.indexOf("GAS") >= 0;
    const hasWOR = arpsProducts.indexOf("WOR") >= 0;

    if (arpsProducts.length == 0) {
      //don't include ratios if no primary product
      arpsProducts.push("CGR");
      arpsProducts.push("OGR");
      arpsProducts.push("WGR");
      arpsProducts.push("GOR");
      arpsProducts.push("WOR");
      arpsProducts.push("O+W");
    }
    if (hasOGR || hasCGR) {
      arpsProducts.push("OIL");
      arpsProducts.push("CONDENSATE");
    }
    if (hasCondensate) {
      arpsProducts.push("CONDENSATE");
      arpsProducts.push("OIL");
      arpsProducts.push("CGR");
      arpsProducts.push("OGR");
    }
    if (hasOil) {
      arpsProducts.push("CONDENSATE");
      arpsProducts.push("CGR");
      arpsProducts.push("OGR");
    } else {
      arpsProducts.push("WOR");
    }
    if (!hasCondensate && !hasOil) {
      arpsProducts.push("GOR");
    }
    if (hasWater) {
      arpsProducts.push("WOR");
      arpsProducts.push("WGR");
    }
    if (hasGOR) {
      arpsProducts.push("GAS");
    }
    if (hasWOR) {
      arpsProducts.push("WATER");
      arpsProducts.push("WGR");
    }
    if (hasWGR) {
      arpsProducts.push("WATER");
      arpsProducts.push("WOR");
    }
    if (hasGas) {
      arpsProducts.push("GOR");
      arpsProducts.push("O+W");
    } else {
      arpsProducts.push("WGR");
    }

    const availableProducts = [
      ...typeWellProductsMenuItems.filter(
        (item) => arpsProducts.indexOf(item.key ?? "") < 0
      )
    ];

    return availableProducts;
  }

  const deleteProduct = (product: string) => {
    const removeProductFromList = (list) => {
      const idx = list.findIndex((item) => item.product == product);
      if (idx < 0) {
        return list;
      }
      const len = list.filter((item) => item.product === product).length;
      const newList = [...list];
      newList.splice(idx, len);
      return newList;
    };

    const arpsDecline = removeProductFromList(arps.arps);

    // If there is no primary prod in the arps, remove all constants
    const forecastConstants = arpsDecline.length
      ? removeProductFromList(arps.constants)
      : [];

    const userArps = Object.assign({}, arps, {
      arps: arpsDecline,
      constants: forecastConstants
    });
    onChange && onChange(userArps);
    validateSegments(userArps.arps);
  };

  const switchToSegmentMode = (product: string) => {
    const idx = segmentTemplate.productCols.indexOf(product);
    if (idx < 0) {
      return;
    }
    const productArps = arps.arps.filter((segment) => segment.product === product);
    const isRampUp =
      productArps.length > 2 &&
      productArps[0].qi <= productArps[0].qf &&
      productArps[0].di < 0;
    const clone = {
      productCols: productArps.map((item) => item.product),
      name: `${product} Details`,
      productData: productArps.map((item, i) => {
        const vol =
          isRampUp && i == 0
            ? getRampUpSegmentCumVolume(item)
            : arpsWasm.getSegmentVolume(
                item.qi,
                item.di,
                item.b,
                item.qf,
                BigInt(new Date(item.startDate).getTime() / 1000),
                BigInt(new Date(item.endDate).getTime() / 1000)
              );

        return {
          "Start Date": item.startDate,
          Qi: item.qi,
          [getDeclineTitleWithType("Di", declineType)]: convertDecline(
            item.di,
            item.b,
            declineType
          ),
          B: item.b,
          [getDeclineTitleWithType("Df", declineType)]: convertDecline(
            item.df,
            item.b,
            declineType
          ),
          Qf: item.qf,
          "End Date": item.endDate,
          Duration: getDurationInYears(item.startDate, item.endDate),
          Volume: (vol * 0.001).toFixed(2)
        };
      }),
      rowHeaders: [
        "Start Date",
        "Duration",
        "Qi",
        getDeclineTitleWithType("Di", declineType),
        "B",
        getDeclineTitleWithType("Df", declineType),
        "Qf",
        "End Date",
        "Volume"
      ],
      displayHeaders: [
        "Start Date",
        "Duration",
        "Q.i",
        "D.i",
        "B",
        "D.f",
        "Q.f",
        "End Date",
        "Volume"
      ]
    } as SegmentTemplate;

    setSegmentTemplate(clone);
    setMode("Segment");
  };

  const moreOptions: (product, isSync) => MenuProps["items"] = function (
    product,
    isSync
  ) {
    const options: {
      key: string;
      onClick: () => void;
      label: JSX.Element;
      danger?: boolean;
      disabled?: boolean;
    }[] = [
      {
        key: "details",
        onClick: () => {
          switchToSegmentMode(product);
        },
        label: (
          <div>
            <List /> {"View Segment Detail"}
          </div>
        )
      }
    ];
    if (!isSync) {
      options.push({
        disabled: !projectPermissions.canEditTypeWells,
        key: "delete",
        danger: true,
        onClick: () => deleteProduct(product),
        label: (
          <div>
            <Delete /> {"Delete"}
          </div>
        )
      });
    }
    return options;
  };

  const segmentMoreOptions: (segmentIndex) => MenuProps["items"] = function (
    segmentIndex
  ) {
    return [
      {
        key: "delete",
        danger: true,
        disabled: !projectPermissions.canEditTypeWells,
        onClick: () => {
          segmentTemplate.productCols.splice(segmentIndex, 1);
          setSegmentTemplate({ ...segmentTemplate });
        },
        label: (
          <div>
            <Delete /> {"Delete"}
          </div>
        )
      }
    ];
  };

  const onAddNewProduct = (item) => {
    if (!arps || !segmentTemplate) {
      return;
    }
    const arpsClone = {
      ...arps,
      title: selectedTypeWellTitle,
      reserveCategory: selectedTypeWellRescat
    };
    arpsClone.arps = arpsClone.arps ?? [];
    arpsClone.constants = arpsClone.constants ?? [];

    const template = segmentTemplate;
    const primaryProductSegment = arpsClone.arps.filter((item) =>
      isPrimaryProduct(item.product)
    );

    const primaryProducts = Array.from(
      new Set(primaryProductSegment.map((item) => item.product.toUpperCase()))
    );
    if (primaryProducts.findIndex((p) => p.toUpperCase() === item.key) >= 0) {
      return;
    }
    const newProduct = item.key;
    const newList = isPrimaryProduct(newProduct)
      ? [...arpsClone.arps].concat(
          template.initialData(newProduct, primaryProductSegment[0]?.startDate)
        )
      : [...arpsClone.arps];
    // ensure not null
    const constants = isRatio(newProduct)
      ? [...arpsClone.constants].concat([
          {
            product: newProduct,
            value: 0,
            unit: getDefaultRateUnitsFromString(newProduct),
            uniqueId: arpsClone.uniqueID
          }
        ])
      : [...arpsClone.constants];
    const userArps = {
      ...arpsClone,
      arps: newList,
      constants
    };
    setCalculatedFields({ ...calculatedFields, [newProduct.label]: ["EUR"] });
    onChange && onChange(userArps);
  };

  const switchToProductMode = () => {
    if (!arpsWasm) {
      return;
    }
    setSegmentTemplateAndUpdateHeader(getData(arps, arpsWasm));
    setMode("Product");
  };

  function getProductInputs(
    product: string,
    refs: MutableRefObject<MutableRefObject<InputRef>[]>,
    i: number,
    forecastConstant: boolean,
    isRatio: boolean,
    isSync: boolean
  ) {
    return (
      <ProductColumnWrapper key={product + "" + i} width={120}>
        <ProductHeaderContainer>
          {mode == "Product" ? (
            <label data-testid="type-well-product-label">
              {i == 0 ? (
                <PrimaryProductIndicator title={"Primary Product"}>
                  P
                </PrimaryProductIndicator>
              ) : null}{" "}
              {product === "CONDENSATE" || product === "COND." ? "COND" : product}
            </label>
          ) : (
            <SegmentHeader>Segment {i + 1}</SegmentHeader>
          )}
          {errors[product] ? (
            <Popover content={<div>{errors[product]}</div>}>
              <ErrorWrapper>
                <ErrorOutlineOutlined />
              </ErrorWrapper>
            </Popover>
          ) : null}
          {warnings[product] ? (
            <Popover content={<div>{warnings[product]}</div>}>
              <WarningWrapper>
                <ErrorOutlineOutlined />
              </WarningWrapper>
            </Popover>
          ) : null}
          {(mode !== "Segment" || !isSync) && (
            <Dropdown
              menu={{
                items:
                  mode === "Product"
                    ? moreOptions(product, isSync)
                    : segmentMoreOptions(i)
              }}
              trigger={["click"]}>
              <Button icon={<MoreVert />} shape="circle" type="text" />
            </Dropdown>
          )}
        </ProductHeaderContainer>
        <ColumnContainer>
          {(segmentTemplate?.rowHeaders ?? []).map((header) =>
            ArpInput(
              header,
              productStringToProductTypeEnum(product),
              mode,
              segmentTemplate.productData[i],
              calculatedFields[product]?.indexOf(header) >= 0,
              forecastConstant,
              isRatio,
              isSync,
              handleReload,
              handleReset,
              handleSave,
              (val) => {
                if (header === "EUR") {
                  setCalculatedFields({
                    ...calculatedFields,
                    [product]: ["Di (tan)", "Di (sec)", "Di (nom)"]
                  });
                } else {
                  setCalculatedFields({ ...calculatedFields, [product]: ["EUR"] });
                }
                header === "EUR"
                  ? debounceUpdate(arps, product, header, val, onArpsValueChange)
                  : onArpsValueChange(arps, product, header, val);
              },
              refs,
              null,
              !projectPermissions.canEditTypeWells
            )
          )}
        </ColumnContainer>
      </ProductColumnWrapper>
    );
  }

  const updateArps = (
    template: SegmentTemplate,
    productArps: ArpSegment[],
    header: string,
    val
  ): ArpSegment[] => {
    if (productArps.length == 0 || !arpsWasm) {
      return [];
    }
    try {
      return template.update(productArps, declineType, header, val);
    } catch (err) {
      throw { [productArps[0].product]: err?.toString() };
    }
  };

  const onSelectedTemplateChanged = (templateName) => {
    const templateIndex = segmentTemplates.findIndex(
      (item) => item.name === templateName
    );
    if (templateIndex === -1) {
      return;
    }
    const toBeRampUp = templateName.includes("Ramp-Up");
    const template = segmentTemplates[templateIndex];
    const arpsGroupedByProduct = groupArpsSegmentsByProduct(arps.arps);
    const keys = Object.keys(arpsGroupedByProduct);
    let allArps = [];
    for (const product of keys) {
      const productArps = arpsGroupedByProduct[product];

      const extendedProductArps = extendArps(segmentTemplates, productArps, templateName);

      if (extendedProductArps.length == 0) {
        continue;
      }
      const updatedArps = updateArps(
        template,
        extendedProductArps,
        "Qi",
        toBeRampUp ? extendedProductArps[1].qi : extendedProductArps[0].qi
      );
      allArps = allArps.concat(updatedArps);
    }
    if (allArps.length === 0) {
      return;
    }
    const userArps = Object.assign({}, arps, {
      arps: allArps,
      reserveCategory: selectedTypeWellRescat
    });
    setSegmentTemplate(template);
    onChange && onChange(userArps);
  };

  const getData = (
    arps: ICheckedForecast,
    arpsLib: typeof import("wasm/arps")
  ): SegmentTemplate => {
    setTemplateError(undefined);

    if (!arps || !arps.arps) {
      return segmentTemplates[1];
    }
    if (arps.arps.length == 0) {
      return segmentTemplates[0];
    }

    const primaryProductSegments = [...arps.arps].sort((item1, item2) =>
      isPrimaryProduct(item1.product) === isPrimaryProduct(item2.product)
        ? 0
        : isPrimaryProduct(item1.product)
        ? -1
        : 1
    );

    const productGroup: { [product: string]: ArpSegment[] } = {};
    if (!primaryProductSegments) {
      return segmentTemplates[1];
    }
    for (const segment of primaryProductSegments) {
      if (!(segment.product in productGroup)) {
        productGroup[segment.product] = [];
      }
      productGroup[segment.product].push(segment);
    }

    const maxSegments = Math.max(
      ...Object.keys(productGroup).map((product) => productGroup[product].length)
    );

    const useRampUpTemplate =
      maxSegments > 2 && arps.arps.some((a) => a.qi <= a.qf && a.di < 0);

    // If this has less than 2 segments, we don't need to check for ramp-up
    const isAllRampUpTw = Object.values(productGroup).every(
      (v) => v.length > 2 && v[0].di < 0 && v[1].di > 0 && v[2].di > 0
    );

    const foundSegment = segmentTemplates.find(
      // Make sure to consider ramp-up in the number of segments
      (item) =>
        item.numberOfSegments === maxSegments &&
        useRampUpTemplate === item.name.includes("Ramp-Up") &&
        useRampUpTemplate === isAllRampUpTw
    );

    if (!foundSegment) {
      if (maxSegments > 0) {
        // Warn the user that the number of segments is not supported
        setTemplateError("Type well format is not editable.");
      }

      // If no segment template is found, use the second one
      return segmentTemplates[1];
    }
    const clonedDataTemplates = Object.assign({}, foundSegment);
    clonedDataTemplates.productData = [];

    clonedDataTemplates.productCols = Object.keys(productGroup);

    for (const product of clonedDataTemplates.productCols) {
      const productSegments = productGroup[product] as ArpSegment[];
      if (!productSegments || !arpsLib) {
        continue;
      }

      if (productSegments.length > 0) {
        const defaultTypeWellSettings = typeWellSettings.settings?.find(
          (s) =>
            s.product === productSegments[0].product ||
            (s.product == "CONDENSATE" && product == "COND.")
        );

        clonedDataTemplates.productData.push(
          getTypewellTemplateFields(productSegments, declineType, defaultTypeWellSettings)
        );
      }
    }

    //forecast constants
    const ratioConstants = arps.constants ?? [];
    clonedDataTemplates.productCols.push(...ratioConstants.map((c) => c.product));
    clonedDataTemplates.productData.push(...ratioConstants.map((c) => c));
    return clonedDataTemplates;
  };

  if (!segmentTemplates?.length || !segmentTemplate) {
    return <></>;
  }

  return (
    <RootContainer data-testid="type-well-input-table">
      <ErrorBoundary>
        <TemplateSelectorWrapper>
          {mode === "Product" ? (
            <RowHeading>Templates</RowHeading>
          ) : (
            <SwitchToProductModeWrapper>
              <Button icon={<ArrowBack />} type="primary" onClick={switchToProductMode}>
                Back
              </Button>
            </SwitchToProductModeWrapper>
          )}
          {templateError && (
            <Popover content={<div>{templateError}</div>}>
              <ErrorWrapper>
                <ErrorOutlineOutlined />
              </ErrorWrapper>
            </Popover>
          )}
          {mode === "Product" ? (
            <Tooltip
              placement="top"
              title={
                segmentTemplate?.productCols.length === 0 ? "Select a product first" : ""
              }>
              <Select
                className="template-select"
                value={segmentTemplate?.name}
                onChange={onSelectedTemplateChanged}
                options={segmentTemplates.map((item) => ({
                  label: item.name,
                  value: item.name
                }))}
                disabled={
                  hasSource ||
                  segmentTemplate?.productCols.length === 0 ||
                  !projectPermissions.canEditTypeWells
                }
              />
            </Tooltip>
          ) : (
            <strong>
              {segmentTemplate.productCols.length > 0
                ? segmentTemplate.productCols[0]
                : ""}
              {" Segments"}
            </strong>
          )}

          {mode === "Product" ? (
            <Tooltip
              placement="top"
              title={
                segmentTemplate?.productCols.length === 0 ? "Select a product first" : ""
              }>
              <Select
                className="rescat-select"
                value={selectedTypeWellRescat}
                onChange={(e) => {
                  dispatch(
                    setSelectedTypeWell({
                      ...selectedTypeWell,
                      reserveCategory: e
                    })
                  );
                  dispatch(setIsTypeWellSavable(true));
                }}
                options={[
                  { label: "", value: "" },
                  ...RESERVE_CATEGORIES.map((rc) => ({
                    label: rc,
                    value: rc
                  }))
                ]}
                disabled={
                  hasSource ||
                  segmentTemplate?.productCols.length === 0 ||
                  !projectPermissions.canEditTypeWells
                }
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </TemplateSelectorWrapper>
        <Wrapper>
          <TableContainer>
            <ProductColumnContainer>
              <ProductColumnWrapper>
                <ColumnContainer>
                  {/*Header Column*/}
                  <RowHeading />
                  {(segmentTemplate?.rowHeaders ?? []).map((header, idx) => {
                    // Normal text will be before the dot
                    // Subscript text will be after the dot
                    const displayHeader = segmentTemplate.displayHeaders[idx].split(".");
                    const headerUnit = header.split(" ");
                    return (
                      <RowHeading key={header} className="header" width={80}>
                        {displayHeader[0]}{" "}
                        {displayHeader.length > 1 ? (
                          <sub>
                            {displayHeader[1]}{" "}
                            {headerUnit.length > 1 ? headerUnit[1] : null}
                          </sub>
                        ) : null}
                      </RowHeading>
                    );
                  })}
                </ColumnContainer>
              </ProductColumnWrapper>

              {/*Product Columns*/}
              {}
              {(segmentTemplate?.productCols ?? []).map((product, i) =>
                getProductInputs(
                  product,
                  inputRefs,
                  i,
                  arps?.constants?.some((p) => p.product === product),
                  isRatio(product),
                  hasSource
                )
              )}

              {mode === "Product" && !hasSource && !templateError && (
                <NewProductWrapper width={120}>
                  <Dropdown
                    disabled={!projectPermissions.canEditTypeWells}
                    menu={{ items: products, onClick: onAddNewProduct }}
                    trigger={["click"]}>
                    <Button
                      data-testid="add-new-tw-product-button"
                      type="primary"
                      icon={<Add />}>
                      Product <ChevronDown />
                    </Button>
                  </Dropdown>
                </NewProductWrapper>
              )}
            </ProductColumnContainer>
          </TableContainer>
        </Wrapper>
      </ErrorBoundary>
      {saveStatus?.error && (
        <StyledAlert type="error" message={saveStatus.error.toString()} />
      )}
    </RootContainer>
  );

  function onArpsValueChange(
    forecast: ICheckedForecast,
    product: string,
    header: string,
    val
  ) {
    try {
      if (!forecast || !forecast.arps) {
        return;
      }
      setErrors({});
      setWarnings({});
      const productArps = forecast.arps.filter((f) => f.product === product);

      const arpsIndex = forecast.arps.findIndex((f) => f.product === product);
      const constantIndex = (forecast.constants ?? []).findIndex(
        (f) => f.product.toUpperCase() === product
      );
      if (arpsIndex < 0 && constantIndex < 0) {
        return;
      }
      const newList = [...forecast.arps];
      if (arpsIndex >= 0) {
        try {
          const newArps = updateArps(segmentTemplate, productArps, header, val);
          newList.splice(arpsIndex, productArps.length, ...newArps);
        } catch (err) {
          setErrors(err);
          return;
        }
      }
      const newConstants = [...(forecast.constants ?? [])];
      if (constantIndex >= 0) {
        const existingConstant = newConstants[constantIndex];
        newConstants.splice(constantIndex, 1, {
          value: val,
          product: existingConstant.product,
          uniqueId: existingConstant.uniqueId,
          unit: existingConstant.unit
        });
      }
      const userArps = {
        ...forecast,
        arps: newList,
        constants: newConstants
      };
      if (userArps?.arps) {
        validateSegments(userArps.arps);
      }

      onChange && onChange(userArps);
    } catch (err) {
      setErrors({ [product]: "An error occurred." });
    }
  }
}

const extendArps = (
  segmentTemplates: SegmentTemplate[],
  arps: ArpSegment[],
  template: string
): ArpSegment[] => {
  const templateSegment = segmentTemplates.find((item) => item.name === template);
  if (arps.length === 0) {
    return arps;
  }
  return templateSegment.extendArps(arps);
};

const StyledAlert = styled(Alert)`
  margin-top: 10px;
  height: 100px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  color: red;
  align-items: center;
  justify-content: center;
`;

const WarningWrapper = styled.div`
  display: flex;
  color: orange;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  white-space: nowrap;

  .product-select {
    .ant-select {
      min-width: 180px;
    }
  }
`;

const ProductHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 26px !important;
    width: 26px;
    height: 26px;

    &.ant-btn-circle {
      border-radius: 50%;
    }

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-accent);
    }
  }

  .ant-select {
    width: 100px;
  }

  gap: 2px;
`;

const PrimaryProductIndicator = styled.span`
  font-weight: bold;
  color: teal;
  margin-right: 2px;
`;

const RowHeading = styled.div`
  min-width: 80px;
  width: 80px;
  max-width: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 27px;
  padding-right: 5px;
  background-color: white;

  &:last-of-type {
    min-height: 26px;
  }
`;

const ProductColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 110px;
  max-width: 110px;
  min-width: 110px;
  justify-content: flex-start;

  &:first-child {
    min-width: 80px;
    max-width: 80px;
    position: sticky;
    left: 0;
    z-index: 10;
    height: 28px;
    width: 100%;
    background-color: white;
    display: inline-block;
  }
`;

const SwitchToProductModeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-btn {
    display: flex;
    align-items: center;
  }
`;

const SegmentHeader = styled.div`
  width: 100%;
  text-align: left;
`;
const NewProductWrapper = styled.div`
  .ant-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TableContainer = styled.div`
  font-size: 1.3rem;

  .ant-btn {
    max-height: 26px;
  }

  .ant-select {
    height: 26px;
    font-size: 1.3rem;

    .ant-select-selection-search {
      display: flex;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      height: 26px;
    }
  }

  .ant-input {
    padding: 0 3px;
    font-size: 1.3rem;
    height: 27px;
  }

  .ant-picker {
    padding: 0 5px;
  }

  .ant-picker-input > input {
    font-size: 1.3rem;
    height: 25px;
  }

  .sticky-col {
    position: sticky;
    background-color: white;
  }
`;

const TemplateSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 5px 0;

  .template-select {
    min-width: 165px;
  }

  .rescat-select {
    min-width: 90px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const RootContainer = styled.div`
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
`;
