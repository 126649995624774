import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

export default function PasswordChecklist({ password, setValidity }) {
  const containsUpper = password.match(/[A-Z]/g)?.length > 0;
  const containsDigit = password.match(/[0-9]/g)?.length > 0;
  const enoughChars = password.length >= 12;

  if (containsUpper && containsDigit && enoughChars) {
    setValidity(true);
  } else {
    setValidity(false);
  }

  return (
    <PasswordChecklistArea>
      {containsUpper ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <CloseIcon style={{ color: "red" }} />
      )}
      Password contains at least one uppercase character
      {containsDigit ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <CloseIcon style={{ color: "red" }} />
      )}{" "}
      Password contains at least one digit (0-9)
      {enoughChars ? (
        <CheckIcon style={{ color: "green" }} />
      ) : (
        <CloseIcon style={{ color: "red" }} />
      )}{" "}
      Password contains at least 12 characters
    </PasswordChecklistArea>
  );
}

const PasswordChecklistArea = styled.div`
  width: 60%;
  justify-self: center;
  align-items: center;
  text-align: left;
  display: grid;
  grid-template-columns: 5% 95%;
`;
