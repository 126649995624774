import { createSlice } from "@reduxjs/toolkit";

export const filesSlice = createSlice({
  name: "files",
  initialState: {
    display: false
  },
  reducers: {
    display: (state) => {
      state.display = true;
    },
    hide: (state) => {
      state.display = false;
    }
  }
});

export const { display, hide } = filesSlice.actions;

export default filesSlice.reducer;
