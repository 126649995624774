import { useQuery } from "react-query";
import { QueryFunctionContext } from "react-query/types/core/types";

import axios from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { useProjectContext } from "../../project/projects/context";
import { Scenario } from "../models/scenario";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

export const GetScenarioListKey = "ScenarioList";
const fetchSettings = async (keys: QueryFunctionContext) => {
  const projectId = keys.queryKey.length > 1 ? keys.queryKey[1] : "";
  const response = await axios.get<Scenario[]>(`${API_URL}/${projectId}`);
  return response.data;
};

const useGetScenarioList = () => {
  const { selectedProjectId } = useProjectContext();
  return useQuery<Scenario[]>([GetScenarioListKey, selectedProjectId], fetchSettings, {
    refetchOnWindowFocus: false
  });
};

export default useGetScenarioList;
