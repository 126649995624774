import { useQuery } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { GeoMapSettings } from "../models/settings";

const API_URL = `${GEO_MAP_SERVICE_ADDRESS}/settings`;

export const GetGeoMapSettingsKey = "geoMapSettings";
const fetchSettings = async () => {
  const response = await axios.get<GeoMapSettings>(API_URL);
  const obj = response.data;
  return new GeoMapSettings(
    obj?.id,
    obj?.customFields,
    obj?.systemFields,
    obj?.created,
    obj?.updated
  );
};

const useGetGeoMapSettings = () => {
  return useQuery<GeoMapSettings>(GetGeoMapSettingsKey, fetchSettings, {
    refetchOnWindowFocus: false
  });
};

export default useGetGeoMapSettings;
