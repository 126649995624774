import axios, { CancelToken } from "axios";

import { ExploitableReportT } from "../models/exploitableReport";

const EXPLOITABLE_SERVICE_URL = process.env.REACT_APP_EXPLOITABLE_SERVICE;

export async function getExploitableReport(
  folderId: string,
  txnId: string,
  spacing: number,
  cancelToken: CancelToken
): Promise<ExploitableReportT> {
  const response = await axios.get<ExploitableReportT>(
    `${EXPLOITABLE_SERVICE_URL}/exploitablereport/${folderId}/${txnId}?spacing=${spacing}`,
    {
      cancelToken: cancelToken
    }
  );
  if (response.status === 200) {
    return response.data;
  }
  return null;
}
