import { useMutation, useQueryClient } from "react-query";

import axios, { AxiosError } from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { GetScenarioListKey } from "./useScenarioList";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

export interface PadLayerDeleteInput {
  scenarioId: string;
  padLayerId: string;
}

const updatePadLayer = async (data: PadLayerDeleteInput) => {
  const response = await axios.delete(
    `${API_URL}/${data.scenarioId}/layer/${data.padLayerId}`
  );
  return response.data;
};

const useUpdatePadLayer = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, PadLayerDeleteInput, unknown>(updatePadLayer, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetScenarioListKey);
    }
  });
};

export default useUpdatePadLayer;
