import { toast } from "react-toastify";

import { useFilesContext, useFilesDispatch } from "../context";
import { useUploadFileMutation } from "../mutations";

const CHUNK_SIZE = 1024 * 1024 * 4;

export function useFileUpload() {
  // hooks
  const { filesType } = useFilesContext();
  const uploadFileMutation = useUploadFileMutation();

  // dispatch
  const dispatch = useFilesDispatch();

  async function mutate(file: File) {
    if (!file) return;

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedChunks = 0;
    let errorOccurred = false;
    for (let start = 0; start < file.size; start += CHUNK_SIZE) {
      if (errorOccurred) break; // Check the flag before each iteration

      const chunk = file.slice(start, start + CHUNK_SIZE);

      await uploadFileMutation
        .mutateAsync({
          filesType,
          fileChunk: chunk,
          filename: file.name,
          chunkIndex: Math.floor(start / CHUNK_SIZE),
          totalFileSize: file.size
        })
        .then(() => {
          uploadedChunks += 1;
          const progress = Math.round(Math.min(uploadedChunks / totalChunks, 1) * 100);

          dispatch({
            type: "updateFileProgress",
            payload: {
              filename: file.name,
              progress
            }
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Failed to upload chunk ${uploadedChunks + 1}:`, error);
          errorOccurred = true;

          toast.error(`Failed to upload ${file.name}, please try again later.`);
        });
    }
  }

  return {
    ...uploadFileMutation,
    mutate
  };
}
