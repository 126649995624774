// iterate over series x values and find the largest x value
export const getXAxisMaxValue = (series) => {
  if (!series?.length) {
    return null;
  }
  let maxX = null;
  for (const seriesI of series) {
    const seriesMaxX = Math.max(...seriesI.x);
    if (seriesMaxX > maxX) {
      maxX = seriesMaxX;
    }
  }
  return maxX;
};
