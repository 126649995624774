import {
  mdiFileCad,
  mdiFileCode,
  mdiFileDelimited,
  mdiFileDocument,
  mdiFileExcel,
  mdiFileImage,
  mdiFileMusic,
  mdiFilePowerpoint,
  mdiFileVideo,
  mdiFileWord
} from "@mdi/js";

interface IFileIcon {
  icon: string;
  color: string;
}

const getFileIcon = (fileType: string): IFileIcon => {
  // convert mime type to icon
  switch (fileType) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return { icon: mdiFileWord, color: "rgb(0, 0, 255)" };
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return { icon: mdiFileExcel, color: "rgb(66, 161, 73)" };
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return { icon: mdiFilePowerpoint, color: "rgb(135, 50, 143)" };
    case "text/csv":
      return { icon: mdiFileDelimited, color: "rgb(66, 161, 73)" };
    case "application/pdf":
      return { icon: mdiFileDocument, color: "rgb(135, 50, 143)" };
    case "application/json":
    case "application/xml":
      return { icon: mdiFileCode, color: "var(--color-text-40)" };
    case "image/jpeg":
    case "image/png":
    case "image/gif":
      return { icon: mdiFileImage, color: "rgb(255, 165, 0)" };
    case "audio/mpeg":
    case "audio/ogg":
      return { icon: mdiFileMusic, color: "rgb(50, 205, 50)" };
    case "video/mp4":
    case "video/x-matroska":
      return { icon: mdiFileVideo, color: "rgb(30, 144, 255)" };
    case "application/dbf":
    case "application/vnd.shp":
    case "application/vnd.shx":
    case "application/prj":
      return { icon: mdiFileCad, color: "rgb(128, 0, 32)" };
    default:
      return { icon: mdiFileDocument, color: "var(--color-text-40)" };
  }
};

export default getFileIcon;
