import { useEffect, useState } from "react";

import { LegendItemModel } from "models";

import { useChartState } from "../context";
import { createLegendItem, verifyChartTypeCanUseStats } from "../utils";

const useP10LegendItem = (): LegendItemModel[] => {
  const { settings } = useChartState();
  const [items, setItems] = useState<LegendItemModel[]>([]);

  const isP10Enabled = Boolean(settings.showP10);
  const canChartShowsStats =
    verifyChartTypeCanUseStats(settings.chartType) &&
    settings.chartType !== "Material Balance Time"; // at this time material balance does not support the p10 stat.

  useEffect(() => {
    if (!isP10Enabled || !canChartShowsStats) {
      setItems([]);
      return;
    }

    const color = settings?.p10Settings ? settings.p10Settings.color : "#000";
    const p10 = createLegendItem("P10", true, color);

    setItems([p10]);
  }, [isP10Enabled, canChartShowsStats, settings?.p10Settings]);

  return items;
};

export default useP10LegendItem;
