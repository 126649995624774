import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef } from "react";

import {
  DEFAULT_AXIS_FONT_SIZE,
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_LEGEND_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE
} from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import * as echarts from "hd-echarts/echarts";
import styled from "styled-components";
import { applyScreenshotSettings } from "utils";

import { IChartScreenshotSettings } from "models/chart";
import { IScreenshotAbbreviation } from "models/screenshot";

import { BusyIndicator } from "components/activity/shared";
import { useChartState } from "components/chart";
import IconSpinner from "components/icons/IconSpinner";

import { useFourProductRateTimeQuery } from "./hooks";
import { useSamChartsMaxXValue } from "./hooks/useSamChartsMaxXValue";

export interface FourProductRateTimeModel {
  id: string;
  wellList: string[];
  setting: IChartScreenshotSettings;
  abbreviations: IScreenshotAbbreviation[];
}
export default function FourProductRateTime({
  id,
  wellList,
  setting,
  abbreviations
}: FourProductRateTimeModel) {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const { chartData, updateData, isFetching } = useFourProductRateTimeQuery(wellList);
  const { xAxisMaxValue } = useSamChartsMaxXValue();
  const { screenshot } = useChartState();

  useEffect(() => {
    updateData();
  }, [updateData, wellList]);

  useEffect(() => {
    const doc = document.getElementById(id);
    chartRef.current = echarts.init(doc);
  }, [id]);

  useEffect(() => {
    if (!chartRef.current || !chartData) {
      return;
    }

    const axisFontSize = 12;
    const axisLabelFontSize = 12;
    const fontFamily = THEME_FONT_FAMILY;

    const option = {
      title: {
        fontFamily: fontFamily,
        text: ""
      },
      grid: {
        left: 80 + +axisFontSize * 1.5 + +axisLabelFontSize,
        bottom: 70,
        top: 10,
        right: 40
      },
      tooltip: {
        trigger: "axis",
        confine: true,
        enterable: true,
        className: "chart-tooltip-container",
        extraCssText: "border-style:none;",
        valueFormatter: (value) => Number(value.toFixed(2)).toLocaleString("en"),

        axisPointer: {
          type: "cross",
          label: {
            show: true,
            precision: "2"
          }
        }
      },
      legend: {
        top: "bottom",
        show: true,
        textStyle: {
          fontFamily: fontFamily,
          fontSize: 12
        }
      },
      xAxis: {
        type: "value",
        triggerEvent: true,
        name: "Consecutive Months on Stream",
        id: "xAxis",
        nameLocation: "middle",
        data: null,
        min: 0,
        max: xAxisMaxValue,
        nameGap: 30,
        useY: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "rgb(155,155,155)",
            width: 1
          }
        },
        splitLine: {
          lineStyle: {
            color: "rgb(217,225,226)"
          }
        },
        axisTick: {
          lineStyle: {
            width: 1
          }
        },
        axisLabel: {
          fontWeight: 500,
          color: "#5B6770",
          fontFamily: fontFamily,
          fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,
          padding: [5, 0, 0, 2]
        },
        nameTextStyle: {
          color: "#5B6770",
          fontFamily: fontFamily,
          fontSize: axisFontSize
        }
      },
      dataZoom: [
        {
          show: true,
          type: "inside",
          filterMode: "none",
          xAxisIndex: [0]
        },
        {
          show: true,
          type: "inside",
          filterMode: "none",
          yAxisIndex: [0]
        }
      ],
      yAxis: [
        {
          id: "yAxis",
          triggerEvent: true,
          min: 0,
          max: null,
          type: "value",
          name: "Bitumen Production (bbl/d), Gas (Mcf/d)",
          nameLocation: "middle",
          nameGap: 60 + axisLabelFontSize * 1.5,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "rgb(155,155,155)",
              width: 1
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgb(217,225,226)"
            }
          },
          axisTick: {
            lineStyle: {
              width: 1
            }
          },
          axisLabel: {
            fontWeight: 500,
            color: "#5B6770",
            padding: [0, 5, 0, 0],
            fontFamily: fontFamily,
            fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,
            margin: 8
          },
          yAxisScaleToX: 1000,
          nameTextStyle: {
            color: "#5B6770",
            fontFamily: fontFamily,
            fontSize: axisFontSize
          }
        },
        {
          id: "yAxis2",
          triggerEvent: true,
          min: 0,
          max: 12,
          alignTicks: true,
          type: "value",
          name: "SOR (bbl/bbl)",
          nameGap: 30,
          nameLocation: "middle",
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "rgb(155,155,155)",
              width: 1
            }
          },
          splitLine: null,
          axisTick: {
            lineStyle: {
              width: 1
            }
          },
          axisLabel: {
            fontWeight: 500,
            color: "#5B6770",
            padding: [0, 5, 0, 0],
            fontFamily: fontFamily,
            fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,
            margin: 8,
            formatter: function (label) {
              return label.toFixed(1);
            }
          },
          yAxisScaleToX: 1000,
          nameTextStyle: {
            color: "#5B6770",
            fontFamily: fontFamily,
            fontSize: axisFontSize
          },
          position: "right"
        }
      ],
      series: (chartData.series ?? []).map((cd) => {
        return {
          name: cd.label,
          type: cd.label == "ISOR" ? "scatter" : "line",
          animation: false,
          yAxisIndex: cd.label.indexOf("SOR") > 0 ? 1 : 0,
          itemStyle: {
            color:
              cd.label === "Bitumen"
                ? "rgb(104, 150, 132)"
                : cd.label === "GasInjection"
                ? "rgb(205, 148, 141)"
                : "rgb(152, 72, 86)"
          },
          zlevel: 0,
          blendMode: "source-over",
          large: true,
          largeThreshold: 2000,
          step: true,
          showSymbol: false,
          symbolSize: 4,
          lineStyle: {
            color:
              cd.label === "Bitumen"
                ? "rgb(104, 150, 132)"
                : cd.label === "GasInjection"
                ? "rgb(205, 148, 141)"
                : "rgb(152, 72, 86)",
            width: cd.label === "ISOR" ? 0 : 3,
            type: "solid"
          },
          data: cd.x.map((v, i) => [cd.x[i], cd.y[i]])
        };
      })
    };
    const newOptions = screenshot?.visible
      ? applyScreenshotSettings(
          option,
          chartRef.current,
          {
            titleFontSize: setting?.titleFontSize ?? DEFAULT_TITLE_FONT_SIZE,
            axisFontSize: setting?.axisFontSize ?? DEFAULT_AXIS_FONT_SIZE,
            axisLabelFontSize: setting?.axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE,
            legendFontSize: setting?.legendFontSize ?? DEFAULT_LEGEND_FONT_SIZE,
            fontFamily: setting?.fontFamily ?? THEME_FONT_FAMILY,
            legendType: "bottom",
            legendVisible: setting?.legendVisible,
            applyAbbreviations: setting?.applyAbbreviations ?? false,
            xAxisTitleText: option?.xAxis.name
          },
          abbreviations
        )
      : option;
    chartRef.current.setOption(newOptions);
  }, [chartData, setting, screenshot?.visible, xAxisMaxValue]);

  useResizeObserver(containerRef.current, () => {
    if (!chartRef.current) {
      return;
    }
    chartRef.current.resize();
  });

  return (
    <Wrapper ref={containerRef}>
      <div id={id} className="four-products" />
      <BusyIndicator
        className={`${!isFetching ? "hidden" : ""}`}
        onContextMenu={(e) => e.preventDefault()}>
        <IconSpinner showBackground />
      </BusyIndicator>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100%;
  .four-products {
    width: 100%;
    height: 100%;
  }
`;
