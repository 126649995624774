// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect } from "react";

import { mdiCheckCircle } from "@mdi/js";
import { Table } from "antd";
import styled from "styled-components";

import useBetaFeatures, { BetaFeatures } from "hooks/useBetaFeatures";

import { Heading } from "../base";
import { useGetBetaFeatures } from "./hooks";

const BetaFeaturesList = () => {
  const { data, mutate, isLoading } = useGetBetaFeatures();
  const { hasFeature } = useBetaFeatures();

  useEffect(() => {
    mutate();
  }, []);

  const betaFeatures = data
    ?.map((x) => ({
      key: x.id,
      value: hasFeature(x.featureName as BetaFeatures),
      label: x.featureName
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const betaFeatureColumns = [
    {
      title: "Feature",
      dataIndex: "label",
      key: "label"
    },
    {
      title: "Enabled",
      key: "value",
      render: (_, record) => {
        return record.value ? (
          <Icon
            path={mdiCheckCircle}
            size={1.3}
            data-testid={`hasfeature-${record.key}`}
          />
        ) : null;
      }
    }
  ];

  return (
    <>
      <div />
      <RootContainer>
        <Container>
          <Heading element="h4">Beta Features</Heading>
        </Container>
        <SectionContainer>
          <Table
            dataSource={betaFeatures}
            columns={betaFeatureColumns}
            rowKey="key"
            pagination={false}
            loading={isLoading}
          />
        </SectionContainer>
      </RootContainer>
    </>
  );
};

export default BetaFeaturesList;

const RootContainer = styled.div`
  max-width: 800px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContainer = styled.div`
  padding: 18px 0;

  svg {
    color: var(--color-primary);
  }
`;
