import { useState } from "react";

import { Select } from "antd";
import styled from "styled-components";

import { getTownshipByDls, getTownshipByNts } from "api/map";

import { BaseButton, BaseInput } from "components/base";
import FieldErrorMessage from "components/base/FieldErrorMessage";

const initialTownshipValues = {
  DLS: { township: null, range: null, meridian: null },
  NTS: { block: null, sheet: null, mus: null, grid: null },
  Geo: { latitude: null, longitude: null }
};

export default function MapTownshipSelector({ closeMenu, goToPointAndHighlightPolygon }) {
  const [canSubmitTownship, setCanSubmitTownship] = useState(false);
  const [townshipErrors, setTownshipErrors] = useState("");
  const [townshipOption, setTownshipOption] = useState("DLS");
  const [townshipValues, setTownshipValues] = useState(initialTownshipValues);

  function canSubmitTownshipForm(values, option) {
    return Object.keys(values[option]).every((val) => {
      return (
        values[option][val] && values[option][val] !== initialTownshipValues[option][val]
      );
    });
  }

  function handleTownshipOptionChange(newOption) {
    setTownshipOption(newOption);
    const canSubmit = canSubmitTownshipForm(townshipValues, newOption);
    setCanSubmitTownship(canSubmit);
  }

  function handleTownshipChange(prop, val) {
    const newValues = {
      DLS: { ...townshipValues.DLS },
      NTS: { ...townshipValues.NTS },
      Geo: { ...townshipValues.Geo },
      [townshipOption]: { ...townshipValues[townshipOption], [prop]: val }
    };
    setTownshipValues(newValues);
    const canSubmit = canSubmitTownshipForm(newValues, townshipOption);
    setCanSubmitTownship(canSubmit);
  }

  function onTownshipKeyDown(event, closeMenu) {
    if (event.key === "Enter" && canSubmitTownship) {
      gotoTownship(closeMenu);
    }
  }

  async function gotoTownship(closeMenu = null) {
    let location = null;
    setTownshipErrors("");
    try {
      if (townshipOption === "Geo") {
        goToPointAndHighlightPolygon(
          {
            Centroid: {
              coordinates: [townshipValues.Geo.longitude, townshipValues.Geo.latitude]
            }
          },
          5
        );
        closeMenu && closeMenu();
        return;
      }
      if (townshipOption === "DLS") location = await getTownshipByDls(townshipValues.DLS);
      if (townshipOption === "NTS") location = await getTownshipByNts(townshipValues.NTS);
      goToPointAndHighlightPolygon(location.data, 11);
      closeMenu();
    } catch (err) {
      setTownshipErrors("Unable to find location");
    }
  }

  return (
    <TownshipContainer>
      <div className="flex-row">
        <TownshipOptionSelector
          value={townshipOption}
          options={[
            { value: "DLS", label: "DLS" },
            { value: "NTS", label: "NTS" },
            { value: "Geo", label: "Geo" }
          ]}
          onChange={handleTownshipOptionChange}
        />
      </div>
      <div className="flex-row justify-between items-center">
        {townshipOption === "DLS" && (
          <>
            <div className="township">
              <BaseInput
                placeholder="Township"
                type="number"
                min="1"
                max="126"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("township", val)}
                value={townshipValues.DLS.township}
              />
            </div>
            <div className="range">
              <BaseInput
                placeholder="Range"
                type="number"
                min="1"
                max="28"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("range", val)}
                value={townshipValues.DLS.range}
              />
            </div>
            W
            <div className="meridian">
              <BaseInput
                placeholder="Meridian"
                type="number"
                min="1"
                max="6"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("meridian", val)}
                value={townshipValues.DLS.meridian}
              />
            </div>
          </>
        )}
        {townshipOption === "NTS" && (
          <>
            <div className="block">
              <BaseInput
                placeholder="Block"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("block", val)}
                value={townshipValues.NTS.block}
              />
            </div>
            <div className="sheet">
              <BaseInput
                type="number"
                min="0"
                max="120"
                placeholder="Sheet"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("sheet", val)}
                value={townshipValues.NTS.sheet}
              />
            </div>
            <div className="mus">
              <BaseInput
                placeholder="Mus"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("mus", val)}
                value={townshipValues.NTS.mus}
              />
            </div>
            <div className="grid">
              <BaseInput
                type="number"
                min="1"
                max="16"
                placeholder="Grid"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("grid", val)}
                value={townshipValues.NTS.grid}
              />
            </div>
          </>
        )}
        {townshipOption === "Geo" && (
          <>
            <div className="latitude">
              <BaseInput
                type="number"
                placeholder="Latitude"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("latitude", val)}
                value={townshipValues.Geo.latitude}
              />
            </div>
            <div className="longitude">
              <BaseInput
                type="number"
                placeholder="Longitude"
                onKeyDown={(e) => onTownshipKeyDown(e, closeMenu)}
                onChange={(val) => handleTownshipChange("longitude", val)}
                value={townshipValues.Geo.longitude}
              />
            </div>
          </>
        )}
        <BaseButton
          appearance="default"
          ellipses={false}
          onClick={() => gotoTownship(closeMenu)}
          isDisabled={!canSubmitTownship}>
          Go
        </BaseButton>
      </div>
      {townshipErrors.length > 0 && (
        <div className="flex-row justify-center">
          <FieldErrorMessage message={townshipErrors} />
        </div>
      )}
    </TownshipContainer>
  );
}

const TownshipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .flex-row {
    gap: 8px;
    div {
      flex-shrink: 0;
    }
  }
  .trigger-container {
    width: 100%;
  }
  .township {
    width: 100px;
  }
  .range {
    width: 80px;
  }
  .meridian {
    width: 95px;
  }
  .block {
    width: 75px;
  }
  .sheet {
    width: 78px;
  }
  .mus {
    width: 66px;
  }
  .grid {
    width: 66px;
  }
  .latitude {
    width: 40%;
  }
  .longitude {
    width: 40%;
  }
  .BaseInput .input {
    height: 40px;
    padding: 6px 8px;
  }
  .button {
    width: 45px;
    height: 40px;
    border-radius: 4px;
    color: var(--color-white);
  }
`;

const TownshipOptionSelector = styled(Select)`
  width: 100%;
`;
