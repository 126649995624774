/**
 * Create a unique id
 *
 * @returns {string} a unique id
 */
export const uid = () => {
  return Math.random() // 0.829329082367118
    .toString(16) // "0.d44ee92663724"
    .slice(2); // "d44ee92663724"
};
