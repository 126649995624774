// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert } from "@mdi/js";
import { Select } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { BinType } from "models";
import { QuantileType } from "models/quantileType";

import { DefaultBinSettings as DefaultBinSettingsModel } from "components/user-settings/models";
import { useUserContext } from "components/user/context";
import { updateSettingBinSettings } from "components/user/context/reducer/UserReducer";
import { useUserDefaults, useUserSettingMutation } from "components/user/hooks";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

import { DEFAULT_BIN_SETTINGS_SETTING } from "../../constants/userSetting.constants";
import {
  ErrorContainer,
  SectionContainer,
  SectionHeading,
  SectionInputWrapper,
  StyledNumberInput,
  SubsectionContainer,
  SubsectionHeading
} from "./shared";

const quantileTypeDisplayMap = {
  NumberOfQuantile: "# of Quantile",
  IQR: "IQR"
};

const DefaultBinSettings = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const defaults = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultBinSettingsModel>(
    DEFAULT_BIN_SETTINGS_SETTING
  );
  const { refetch } = useUserSettingsDefaultsQuery();

  const binType = defaults?.binSettings;

  // state
  const [currentDefaultBinSettings, setCurrentDefaultBinSettings] = useState(binType);

  const [updateError, setUpdateError] = useState("");
  const debouncedSettingsChange = _debounce((settings: DefaultBinSettingsModel) => {
    mutation.mutate(settings);
  }, 400);

  const updateBinSettings = useCallback(
    (settings: DefaultBinSettingsModel) => {
      debouncedSettingsChange({
        id: binType?.id,
        type: settings.type,
        quantile: {
          quantileType: settings.quantile.quantileType,
          numQuantiles: settings.quantile.numQuantiles
        },
        useDynamicBins: settings.useDynamicBins
      });
      refetch();
    },
    [binType?.id]
  );

  const handleDefaultBinTypeChange = useCallback(
    (type: BinType) => {
      let newType = type;
      // Show type as dynamic but function as bin size besides useDynamicBins
      if (type === "Dynamic") {
        newType = "BinSize";
      }
      const newSettings = {
        ...currentDefaultBinSettings,
        type: newType,
        quantile: {
          quantileType: "NumberOfQuantile" as QuantileType,
          numQuantiles: 4
        },
        useDynamicBins: type === "Dynamic"
      };
      setCurrentDefaultBinSettings(newSettings);
      updateBinSettings(newSettings);
    },
    [
      currentDefaultBinSettings?.type,
      currentDefaultBinSettings?.quantile?.quantileType,
      currentDefaultBinSettings?.quantile?.numQuantiles,
      currentDefaultBinSettings?.useDynamicBins,
      updateBinSettings
    ]
  );

  const handleDefaultQuantileTypeChange = useCallback(
    (type: QuantileType) => {
      const newSettings = {
        ...currentDefaultBinSettings,
        quantile: {
          quantileType: type,
          numQuantiles: currentDefaultBinSettings?.quantile?.numQuantiles
        }
      };
      setCurrentDefaultBinSettings(newSettings);
      updateBinSettings(newSettings);
    },
    [
      currentDefaultBinSettings?.type,
      currentDefaultBinSettings?.quantile?.quantileType,
      currentDefaultBinSettings?.quantile?.numQuantiles,
      currentDefaultBinSettings?.useDynamicBins,
      updateBinSettings
    ]
  );

  const handleNumberOfQuantilesChanged = useCallback(
    (value: number) => {
      const newSettings = {
        ...currentDefaultBinSettings,
        quantile: {
          quantileType: currentDefaultBinSettings?.quantile?.quantileType,
          numQuantiles: value
        }
      };
      setCurrentDefaultBinSettings(newSettings);
      updateBinSettings(newSettings);
    },
    [
      currentDefaultBinSettings?.type,
      currentDefaultBinSettings?.quantile?.quantileType,
      currentDefaultBinSettings?.quantile?.numQuantiles,
      currentDefaultBinSettings?.useDynamicBins,
      updateBinSettings
    ]
  );

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingBinSettings(dispatch, {
      id: mutation.data.id,
      type: mutation.data.type,
      quantile: mutation.data.quantile
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update bin settings.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  return (
    <SectionContainer>
      <SectionHeading>Bin Settings</SectionHeading>
      <SectionInputWrapper>
        <SubsectionContainer>
          <SubsectionHeading>Bin Type</SubsectionHeading>
          <SectionInputWrapper>
            <StyledSelect
              data-testid="user-settings-bin-type-select"
              value={
                currentDefaultBinSettings?.type === "BinSize" &&
                currentDefaultBinSettings?.useDynamicBins
                  ? "Dynamic"
                  : currentDefaultBinSettings?.type
              }
              onChange={handleDefaultBinTypeChange}
              options={["Bin Size", "Quantile", "Dynamic"].map((item) => ({
                value: item.replace(" ", ""),
                label: item
              }))}
            />
          </SectionInputWrapper>
        </SubsectionContainer>

        <SubsectionContainer>
          <SubsectionHeading>Quantile Type</SubsectionHeading>
          <SectionInputWrapper>
            <StyledSelect
              data-testid="user-settings-quantile-type-select"
              value={
                quantileTypeDisplayMap[currentDefaultBinSettings?.quantile?.quantileType]
              }
              onChange={handleDefaultQuantileTypeChange}
              options={["# of Quantile", "IQR"].map((item) => ({
                value: item
                  .replace(/#/g, "Number")
                  .replace(/ /g, "")
                  .replace(/of/g, "Of"),
                label: item
              }))}
              disabled={currentDefaultBinSettings?.type !== "Quantile"}
            />
          </SectionInputWrapper>
        </SubsectionContainer>
        <SubsectionContainer>
          <SubsectionHeading>Number of Quantiles</SubsectionHeading>
          <SectionInputWrapper>
            <StyledNumberInput
              data-testid="user-settings-number-of-quanties-input"
              step={1}
              value={currentDefaultBinSettings?.quantile?.numQuantiles}
              onChange={handleNumberOfQuantilesChanged}
              disabled={
                currentDefaultBinSettings?.type !== "Quantile" ||
                currentDefaultBinSettings?.quantile?.quantileType !==
                  ("NumberOfQuantile" as QuantileType)
              }
            />
          </SectionInputWrapper>
        </SubsectionContainer>
      </SectionInputWrapper>

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </SectionContainer>
  );
};

export default DefaultBinSettings;

export const StyledSelect = styled(Select)`
  height: 50px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100% !important;
  }
`;
