import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Switch, Tag } from "antd";
import { setActiveEntities } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";

import {
  UserDataSourcePreferenceCategory,
  UserDataSourcePreferenceItem,
  fetchUserDataSources,
  saveUserDataSourcePreference
} from "api/dataSource";

import { EntityKind } from "models/entityKind";

import { BaseDivider, BaseIconToggle, BaseSlideToggle, Heading } from "components/base";
import { useUserContext } from "components/user/context";
import { updateMidstreamSettings } from "components/user/context/reducer/UserReducer";
import { useUserSettingMutation } from "components/user/hooks";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

import "./DataSource.scss";
import { MidstreamSources } from "./MidstreamSources";
import OrgGeoModelsToggle from "./OrgGeoModelsToggle";
import { USER_MIDSTREAM_SETTINGS } from "./constants/userSetting.constants";
import { MidstreamSettings } from "./models";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SourceContainer = styled.div`
  width: 100%;
`;

interface SourceListInput {
  category: UserDataSourcePreferenceCategory;
  // eslint-disable-next-line no-unused-vars
  onOrderChanged: (category: UserDataSourcePreferenceCategory) => void;
  isDisabled: boolean;
}
function SourceList(input: SourceListInput) {
  const [internalList, setInternalList] = useState(input.category.dataSources);
  const [expanded, setExpanded] = useState(false);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const list: UserDataSourcePreferenceItem[] = reorder(
      internalList,
      result.source.index,
      result.destination.index
    );
    let order = 0;
    for (const item of list) {
      item.order = ++order;
    }
    setInternalList(list);
    input.onOrderChanged && input.onOrderChanged(input.category);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <>
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {internalList.map((source: UserDataSourcePreferenceItem, id) => {
                return (
                  <Draggable
                    draggableId={`${input.category}-${source.dataSourceName}`}
                    key={`${input.category}-${source.dataSourceName}`}
                    index={id}>
                    {(provided) => (
                      <div
                        key={id}
                        className="flex-row items-center list-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className="list-order">{id + 1}</div>
                        <SourceContainer className="source-container flex-row justify-between">
                          <div className="flex-row items-center">
                            <DragHandleIcon
                              style={{
                                color: "#D9E1E2",
                                fontSize: "20px",
                                marginRight: "3px"
                              }}
                            />
                            <span className="source-name">{source.dataSourceName}</span>
                          </div>
                          <BaseSlideToggle
                            checked={source.isActive || input.isDisabled}
                            disabled={input.isDisabled}
                            classic
                            onChange={(val) => {
                              source.isActive = val;
                              input.onOrderChanged &&
                                input.onOrderChanged(input.category);
                            }}
                          />
                        </SourceContainer>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
            {internalList.length > 3 ? (
              <div className="expand-container flex-row">
                {expanded ? (
                  <BaseIconToggle toggle={() => setExpanded(false)} size={0}>
                    <KeyboardArrowUpIcon style={{ color: "#00C7B1" }} fontSize="large" />
                  </BaseIconToggle>
                ) : (
                  <BaseIconToggle toggle={() => setExpanded(true)} size={0}>
                    <KeyboardArrowDownIcon fontSize="large" />
                  </BaseIconToggle>
                )}
              </div>
            ) : (
              <div style={{ marginTop: "22px" }} />
            )}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}
interface DataSourcesGridInput {
  categories: UserDataSourcePreferenceCategory[];
  section: "Wells" | "Midstream" | "GeoModels";
  // eslint-disable-next-line no-unused-vars
  onPreferenceChanged: (cats: UserDataSourcePreferenceCategory) => void;
}
function DataSourcesGrid(data: DataSourcesGridInput): JSX.Element {
  return (
    <div className="grid-container">
      {data.categories.map((row: UserDataSourcePreferenceCategory) => {
        const isSourceDisabled: boolean =
          row.category === "ForecastData" || data.section === "Midstream";
        let sourceList = row.dataSources;
        if (!sourceList) {
          sourceList = [];
        }
        sourceList.sort((a, b) => a.order - b.order);
        const orderedList = [...sourceList];
        row.dataSources = orderedList;
        return (
          <div className="source-category-item" key={row.category}>
            <div className="source-category-header">{row.category}</div>
            <div className="source-category-sources">
              <SourceList
                onOrderChanged={data.onPreferenceChanged}
                category={row}
                isDisabled={isSourceDisabled}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function DataSource(): JSX.Element {
  const dispatch = useDispatch();
  const { hasFeature } = useBetaFeatures();
  const showMidstream = hasFeature("Facility");
  const [, userDispatch] = useUserContext();
  const [selectedView] = useState("Grid");
  const userSettingsDefaultsQuery = useUserSettingsDefaultsQuery();
  const midstreamSettings =
    Object.values(userSettingsDefaultsQuery?.data?.MidstreamSettings ?? {})?.[0] ??
    undefined;
  const queryClient = useQueryClient();
  const { isLoading, isError, data, error, refetch } = useQuery(
    "dataSouces",
    () => {
      return fetchUserDataSources();
    },
    {
      refetchOnWindowFocus: false
    }
  );
  const [midstreamEnabled, setMidstreamEnabled] = useState(
    midstreamSettings?.enabled ?? false
  );
  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);

  const midstreamSettingMutation = useUserSettingMutation<MidstreamSettings>(
    USER_MIDSTREAM_SETTINGS
  );

  const onMidstreamSettingChanged = (enabledStatus) => {
    setMidstreamEnabled(enabledStatus);
    // if facilities are currently enabled, disable them
    if (!enabledStatus && activeEntities.includes(EntityKind.Facility)) {
      dispatch(
        setActiveEntities(
          activeEntities.filter((activeEntity) => activeEntity !== EntityKind.Facility)
        )
      );
    }
    midstreamSettingMutation.mutate({
      id: midstreamSettings?.id,
      enabled: enabledStatus
    });
  };

  useEffect(() => {
    if (!midstreamSettingMutation?.data) return;
    updateMidstreamSettings(userDispatch, {
      id: midstreamSettingMutation.data.id,
      enabled: midstreamSettingMutation.data.enabled
    });
  }, [midstreamSettingMutation?.data]);

  const mutation = useMutation(
    async (preference: UserDataSourcePreferenceCategory) => {
      const result = await saveUserDataSourcePreference(preference);
      return result.ok;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("dataSources");
      }
    }
  );
  async function onPreferenceChanged(
    preferences: UserDataSourcePreferenceCategory
  ): Promise<void> {
    const ok = await mutation.mutateAsync(preferences);
    if (!ok) {
      toast.error("An error occurred.");
      refetch();
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error occurred: {error}</div>;
  }
  return (
    <div className="userAccount">
      <div className="userAccountHeader">
        <Heading element="h4">Data Source</Heading>
      </div>

      <div className="userAccountContent">
        <div className="main">
          <div className="DataSource">
            <div className="flex-row justify-between">
              <p className="description">
                Your organization has access to the following data sources. For each
                category, set the order of priority in which data is retrieved.
              </p>
              <div className="view-toggle flex-row">
                {/* <BaseTooltip text="Table View">
                  <BaseIconToggle
                    value={selectedView === "Table"}
                    toggle={() => setSelectedView("Table")}
                  >
                    <TableView size={26} />
                  </BaseIconToggle>
                </BaseTooltip> */}

                {/* <BaseTooltip text="Grid View">
                  <BaseIconToggle
                    value={selectedView === "Grid"}
                    toggle={() => setSelectedView("Grid")}
                  >
                    <GridView size={26} />
                  </BaseIconToggle>
                </BaseTooltip> */}
              </div>
            </div>
            <Heading element="h5">Wells</Heading>
            <BaseDivider />
            {selectedView === "Grid" && (
              <DataSourcesGrid
                section="Wells"
                onPreferenceChanged={onPreferenceChanged}
                categories={data.ok ? data.value : []}
              />
            )}
            {showMidstream && (
              <>
                <MidstreamHeader>
                  <MidstreamHeading element="h5">Midstream</MidstreamHeading>
                  <Tag color="cyan">BETA</Tag>
                  <MidstreamToggle>
                    <span>
                      Status: <strong>{midstreamEnabled ? "On" : "Off"}</strong>
                    </span>
                    <Switch
                      checked={midstreamEnabled}
                      onChange={onMidstreamSettingChanged}
                    />
                  </MidstreamToggle>
                </MidstreamHeader>
                <BaseDivider />
                {midstreamEnabled && (
                  <DataSourcesGrid
                    section="Midstream"
                    onPreferenceChanged={null}
                    categories={MidstreamSources}
                  />
                )}
                <OrgGeoModelsToggle />
              </>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip place="top" delayShow={500} />
    </div>
  );
}

const MidstreamHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const MidstreamHeading = styled(Heading)`
  margin-right: 10px;
`;

const MidstreamToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9e1e2;
  gap: 25px;
  border-radius: 21.5px;
  padding: 10px 15px;
  margin: 10px 0;
  width: 180px;
  .ant-switch-checked {
    :focus {
      box-shadow: none;
    }
  }
`;
