import { useState } from "react";

import { format, formatDistance } from "date-fns";
import styled from "styled-components/macro";

import { useUserSettings } from "components/user/hooks";

const PostDate = ({ value }) => {
  const { userDocSettings } = useUserSettings();
  const [hovered, setHovered] = useState(false);

  const postsLastVisited = new Date(Date.parse(userDocSettings?.userDocsLastVisited));
  // derived state
  const postDate = new Date(Date.parse(value));
  const now = new Date();
  const dateString = formatDistance(postDate, now, {
    addSuffix: true,
    includeSeconds: true
  });

  const distance = dateString.replace("about ", "");
  const enUS = format(postDate, "MMMM d, yyyy");

  if (postsLastVisited < postDate) {
    return (
      <Wrapper>
        <NewPostMessage>NEW</NewPostMessage>
      </Wrapper>
    );
  }

  return (
    <Wrapper onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Content shift={hovered}>
        <DistanceWrapper>{distance}</DistanceWrapper>
        <DateWrapper visible={hovered}>{enUS}</DateWrapper>
      </Content>
    </Wrapper>
  );
};

export default PostDate;

const Wrapper = styled.div`
  width: 100px;
  height: 20px;
  overflow: hidden;
  position: absolute;
  top: 16px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 500;
  font-size: 12px;
  color: var(--color-text-40);
`;

const Content = styled.div`
  height: 20px;
  translate: 0 ${({ shift }) => (shift ? "-20" : "0")}px;
  transition: translate 0.2s ease-in-out;
`;

const DistanceWrapper = styled.div`
  height: 20px;
`;

const DateWrapper = styled.div`
  height: 20px;
`;

const NewPostMessage = styled.div`
  padding: 3px 8px;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 5px;
`;
