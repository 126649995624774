import { ArpSegment } from "../../../models/UserArpsModel";

export const groupArpsSegmentsByProduct = (
  arps?: ArpSegment[]
): Record<string, ArpSegment[]> => {
  return (arps ?? []).reduce((acc, seg) => {
    if (!acc[seg.product]) {
      acc[seg.product] = [];
    }
    acc[seg.product].push(seg);
    return acc;
  }, {} as Record<string, ArpSegment[]>);
};
