import { IGroupByListColumn } from "models";

const columnsToDisable = [
  "Performance.Oil.Remaining", // Remaining (Mbbl)
  "Performance.Oil.EUR" // "EUR (Mbbl)"
];

// Created to disable columns based on the forecast toggle, extend if neccesary.
// https://turinganalytics.atlassian.net/browse/EVA-997
const authorizeColumnsByForecast = (
  columns: IGroupByListColumn[],
  isForecastToggleOn: boolean
): IGroupByListColumn[] => {
  return columns.map((column) => {
    const isDisabled =
      column.group === "Performance" &&
      columnsToDisable.includes(column.property) &&
      !isForecastToggleOn;

    return {
      ...column,
      isDisabled,
      tooltip: isDisabled ? "Toggle on Cum + Forecast to use this option" : column.tooltip
    };
  });
};

export default authorizeColumnsByForecast;
