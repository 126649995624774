// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React from "react";

import { mdiDotsVertical, mdiPlus, mdiRename } from "@mdi/js";
import { Button, Dropdown, MenuProps } from "antd";
import styled from "styled-components";

import { Group } from "./GeoMap";

interface LayerPopoverActionsProps {
  group: Group;
  onAddNewMapLayer: (group) => void;
  onRenameGroup: (group) => void;
}

export const LayerPopoverActions: React.FC<LayerPopoverActionsProps> = ({
  group,
  onAddNewMapLayer,
  onRenameGroup
}) => {
  const items: MenuProps["items"] = [
    ["Add New Layer", onAddNewMapLayer, <Icon key="plus" path={mdiPlus} size={1} />],
    ["Rename", onRenameGroup, <Icon key="rename" path={mdiRename} size={1} />]
  ].map(([title, fn, icon]) => ({
    key: title as string,
    icon,
    label: (
      <Button
        style={{ width: "100%" }}
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          (fn as (group: Group) => void)(group);
        }}>
        {title}
      </Button>
    ),
    onClick: (e) => {
      e.domEvent.stopPropagation();
      (fn as (group: Group) => void)(group);
    }
  }));

  return (
    <DropdownWrapper>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button
          type="text"
          icon={<Icon path={mdiDotsVertical} size={1} />}
          onClick={(e) => e.stopPropagation()}
        />
      </Dropdown>
    </DropdownWrapper>
  );
};
const DropdownWrapper = styled.div`
  .ant-btn {
    min-width: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
