import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useLayoutEffect } from "react";

import { useChartSettings } from "../context";
import { changeChartBounds } from "../context/reducer/chartSettingsReducer";

/**
 * Saves the chart bounds to context, syncs on resize
 * @param target reference of chart's wrapper element
 */
const useChartSize = (target: MutableRefObject<HTMLDivElement>): void => {
  const [, chartSettingsDispatch] = useChartSettings();
  // save
  useLayoutEffect(() => {
    if (!target.current) return;
    const next = target.current.getBoundingClientRect();
    changeChartBounds(chartSettingsDispatch, next);
  }, [target]);

  // sync
  useResizeObserver(target, () => {
    if (!target.current) return;
    const next = target.current.getBoundingClientRect();
    changeChartBounds(chartSettingsDispatch, next);
  });
};

export default useChartSize;
