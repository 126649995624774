import { FORECAST_KEY } from "constants/settings.constants";

import getCumPlusForecastTitle from "./getCumPlusForecastTitle";

const addLabel = (field) =>
  Object.assign({}, field, {
    property: field.property + `.${FORECAST_KEY}`,
    title: getCumPlusForecastTitle(field.title, "", field.subgroup)
  });

const removeLabel = (field) =>
  Object.assign({}, field, {
    property: field.property.replace(`.${FORECAST_KEY}`, ""),
    title: field.title.replace(" + Forecast", "")
  });

const toggleForecastLabels = (field, showForecastLabels) => {
  const copy = Object.assign({}, { ...field });
  const { group, title } = copy;

  if (group && group !== "Performance") return copy;

  const cumIndex = title.indexOf("Cum");
  if (cumIndex < 5) return copy;

  const hasForecastLabel = title.includes(" + Forecast");

  if (hasForecastLabel && showForecastLabels) return copy;
  if (hasForecastLabel && !showForecastLabels) return removeLabel(copy);
  if (!hasForecastLabel && showForecastLabels) return addLabel(copy);
  return copy;
};

export default toggleForecastLabels;
