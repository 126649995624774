import { memo } from "react";

import TrendingDownIcon from "@material-ui/icons/TrendingDown";

import { useChartDispatch, useChartState } from "../context";
import { getForecastToggleDisabledStatus } from "../utils/toggles";
import ToolbarButton from "./ToolbarButton";

const ForecastToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const isDisabled = getForecastToggleDisabledStatus(settings.chartType);

  const toggle = () => (value) => {
    const next = { ...settings, forecast: value, isDefaultLockOn: true };
    dispatch({ type: "settings", payload: next });
  };

  return (
    <ToolbarButton
      active={settings.forecast}
      icon={<TrendingDownIcon fontSize="large" />}
      overflowLabel="Forecast"
      tooltipText="Toggle Forecast"
      onToggle={toggle()}
      disabled={isDisabled}
    />
  );
};

export default memo(ForecastToggle);
