import { useEffect, useRef } from "react";

import { useVisState } from "../context";

export function useShowXdaInfoRef() {
  const [{ xda }] = useVisState();
  const { showXdaValueInfo } = xda.settings;

  const ref = useRef(showXdaValueInfo);
  useEffect(() => {
    ref.current = showXdaValueInfo;
  }, [showXdaValueInfo]);
  return ref;
}
