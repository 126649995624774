import { useQuery } from "react-query";

import axios from "axios";
import { IS_DEV_ENV, SAM_SERVICE_URI } from "constants/app.constants";

import { getXAxisMaxValue } from "../util/getXAxisMaxValue";

const useTypeWellQuery = (wellList) => {
  const {
    data: chartData,
    refetch: updateData,
    isFetching
  } = useQuery(
    "sam-type-well",
    async () => {
      if (IS_DEV_ENV) {
        return devResponse;
      }
      const response = await axios.post(`${SAM_SERVICE_URI}/SamChart/type-well`, {
        Ooip: 295.9596797619047,
        PoreVolume: 393.79703517887043,
        ShowForecast: true,
        WellPairUwis: wellList
      });
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  const xAxisMaxValue = getXAxisMaxValue(chartData?.series);
  return { chartData, updateData, isFetching, xAxisMaxValue };
};

const devResponse = {
  title: "Type Well",
  series: [
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        9.55627929293815, 41.045920530351765, 76.62508762187828, 152.4782409123514,
        241.8754073170509, 389.0462429704213, 483.018442033452, 569.406901087214,
        608.3440256373999, 658.0936673000987, 675.0558271910994, 676.911597888586,
        706.4303838361913, 694.04456599234, 763.8478627666941, 809.3731116147435,
        795.0475026610383, 853.8760936619552, 828.5295246138216, 839.1194279948305,
        787.8192748957222, 872.3022858046619, 855.8752338302038, 890.9153546097824,
        912.4680889646495, 870.338527652331, 919.139624577341, 909.8186445397234,
        931.9269517240348, 1008.6340536827516, 898.3559847611527, 907.2696493458772,
        854.4977484791257, 929.3505609659406, 932.9011559326543, 908.9096481838574,
        927.0663409064604, 919.0279300160935, 936.8217728505526, 936.4157857827435,
        903.1789465429124, 999.7856519472527, 914.9584449460016, 903.1723508877346,
        881.0836053240749, 899.7048064972496, 924.2157043977602, 958.5119840109776,
        989.5141785489117, 928.581967217653, 984.3719906255301, 1011.2268679351683,
        968.7490715248364, 958.5050639193192, 900.0958109476629, 856.4496993088645,
        879.7019090666887, 974.6364929510473, 947.6012851318291, 941.5353418401833,
        1021.8229924982176, 1003.0126313536895, 1058.8226488106927, 995.7097052898959,
        901.131886842157, 921.1770174484054, 964.9039050416138, 1030.1822988682272,
        1005.8949983861829, 1041.435539644694, 983.7179891536971, 978.4101679563744,
        958.6890146487281, 934.078242989104, 954.733092057861, 959.0116926915839,
        964.1684012456833, 1068.7913355362402, 910.6679965013218, 916.7552416843067,
        913.9386529082775, 972.7531244413511, 913.3183542622521, 930.4917999740667,
        976.3709508172022, 923.666797987788, 915.1843200459855, 926.7108262342621,
        861.6046156440427, 942.1720340108386, 848.2652659592051, 821.8197176381543,
        780.6649421044671, 863.6040131237186, 803.000087049381, 816.7630574951968,
        842.5278499083176, 814.1229961918843, 873.559988926912, 874.6464835078341,
        809.6331587494168, 970.1283962511854, 944.4656028541003, 1001.586514978529,
        893.9033578303317, 933.229679075494, 892.9090613615364, 979.4358348047651,
        939.1067844660301, 926.3558770141065, 892.4664355285455, 831.2767440941027,
        915.9863964698719, 950.1983039623874, 850.1641989766526, 870.1524279787119,
        703.8162404651338, 765.4116709297775, 832.2995473073978, 793.8853760662607,
        815.9724150184899, 812.7915781097441, 792.79633750718, 807.5450221643747,
        753.1318921819935, 825.8325135802955, 700.9252863030961, 829.8332803748169,
        726.0606577322676, 713.2222883780371, 663.6968379342336, 750.9088087512757,
        735.6213341318767, 705.1486527832932, 730.3501448648153, 736.1101807136382,
        747.4466669677134, 903.5148890592674, 794.9939492748266, 810.075625492787,
        798.8906159454167, 727.7506937580897, 633.0319631312457, 594.1937784490174,
        641.5774127913074, 715.0436136502503, 726.8716044721332, 655.7119976614882,
        657.0619198125942, 774.1336996217769, 795.0422332787274, 821.1765569609632,
        674.3307735561416, 890.0362888846938, 644.8770961855057, 867.6101912017557,
        893.0946237912448, 818.6810956089976, 778.3902838084451, 789.3004967307525,
        769.2150760929802, 857.4721091003895, 837.1039805448816, 838.7703731672017,
        738.8357378187288, 551.6109747655906, 650.2889645386616, 673.9471114710904,
        660.1378138569, 697.8508833362765, 677.2533719296036, 665.7064964749778,
        773.0943260640532, 783.9747566007715, 663.9221632857715, 773.4421515807206,
        803.4496171420907, 798.3828937198771, 746.0002407842634, 812.7601691213474,
        677.7669082747431, 802.8798093168066, 803.355434656171, 860.6339633031554,
        887.4866184237895, 1005.2574069942391, 751.1088646241229, 631.1492412430051,
        653.8630441387328, 750.8396320302433, 630.9425614327355, 421.59072480556955,
        577.7982296058566, 674.2201408384946, 699.5600234933821, 682.6926785500414,
        668.8509016058998, 668.674417746762, 471.1558175554839, 566.6572273613996,
        547.0229649162337, 582.1298219117741, 546.7413082268113, 570.6872017230285,
        523.1236758752334, 579.1419773010084, 572.3601409602921, 614.3922000889121,
        583.4226514906998, 632.7769922392215, 608.4132484346848, 673.5288917656296,
        771.5414081007508, 651.8889098651229, 606.2114688573974, 349.60953755524037,
        636.4241814024712, 495.0255876221273, 519.4607010257379, 553.8334744154229,
        481.63293891247025, 516.195150894037, 619.2590246863209, 587.7645627504039,
        916.2165234531212, 589.3587565288527, 393.56828068636946, 447.81891149585584,
        597.5761961697397, 532.0824731385792, 554.3861305141276, 809.8720923387351,
        709.3993150921297, 596.4404829328372, 832.1610325178993, 818.303861724666
      ],
      label: "Bitumen",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        54.756154754979306, 28.107242962401372, 15.292181611569147, 7.1641622311000654,
        4.837148127118954, 3.926063866333852, 3.7346996489494746, 3.5183681260502326,
        3.5099965710250784, 3.5043009225778587, 3.405752864011976, 3.3522619294851226,
        3.2042315232909564, 3.2214699411733876, 3.409337144914043, 3.2670895529897233,
        3.211439769925833, 3.3709087705992014, 3.3621611270457756, 3.346066421293497,
        3.5385590668308495, 3.396572508978338, 3.3048856448292923, 3.2427871243989137,
        3.0391584038013155, 3.0187292203765623, 3.0566509343845625, 2.958737659355677,
        3.015226022123608, 3.078410159024424, 3.0504944343703335, 3.054817080874608,
        2.8158081296123036, 2.8407670472249102, 2.771746714131474, 2.6943156229285123,
        2.774235191207339, 2.7779685551719844, 2.866317892142155, 2.7282044746660947,
        2.738618932411705, 2.6576350371548902, 2.798151646114544, 2.630994386444222,
        2.5500435022301096, 2.555131529292297, 2.4903862905496776, 2.5295191272525925,
        2.620408221057186, 2.571741271335331, 2.4453124199137264, 2.413390046020131,
        2.4371505313613944, 2.3611337691217686, 2.2188183227957885, 2.1204812693576742,
        2.3109980097830842, 2.28228242152785, 2.4107343206991896, 2.2781245577982747,
        2.422972844463971, 2.438899507432022, 2.2651223022776508, 2.2697708644589443,
        2.231631008519067, 2.4712370963954857, 2.229458873548141, 2.3235111928483825,
        2.448715823130784, 2.3921284366113715, 2.4244157950217993, 2.379017563593068,
        2.394177546515617, 2.4410236940687517, 2.614007876576929, 2.5674141816403906,
        2.5167498019511902, 2.463524331322913, 2.3638058387660386, 2.405395397103497,
        2.540504865998566, 2.5763456495772115, 2.6183941239721236, 2.3594585905754575,
        2.295862707547882, 2.2377351270056214, 2.0710138244328715, 2.3437254113885833,
        2.307938675482795, 2.204543455926483, 2.1031460773952153, 1.9682772084588824,
        2.0034279093855423, 2.072868113606501, 2.213590353276022, 2.031467962372717,
        2.1512863260128965, 2.1710806803479836, 2.242015806894658, 2.17100218147299,
        2.1863818447874404, 2.117558541893605, 2.041223943637133, 1.7957121809954555,
        1.8584951397552019, 2.1977192173327613, 1.9611920618147411, 2.215604525339333,
        2.0482345419696135, 2.076426400321342, 2.156430558527714, 2.393802171079744,
        2.1453091787799905, 2.1340224698459718, 2.0738330864472285, 1.9084462706010161,
        1.920744262160122, 2.1892369703212804, 2.2643186985244688, 2.198671467459902,
        1.9969927806068122, 2.1655144803348256, 2.274103529863274, 2.0549493379850214,
        2.107745335197395, 2.007123850260324, 1.7558040174148881, 1.8328133211334874,
        2.3437351735122673, 2.4695506163991214, 2.8804780125054417, 2.8997673267465167,
        3.2734292420224413, 2.6515824280109914, 3.1696149146564214, 2.468713071312648,
        2.917786914899396, 2.606090595520685, 2.2078251559869635, 2.9231103160078526,
        2.603935067720621, 2.554281204274676, 2.7710048111188463, 3.500799989067765,
        3.257449179230181, 3.621072519024375, 3.699751674860001, 3.6342318914609906,
        4.251158156526845, 2.9258124013667794, 2.9070775390044044, 3.0937035017159262,
        3.6381989797991814, 3.1464599924111543, 3.651846197431377, 3.0971167726946223,
        3.459391918076872, 3.439427770180722, 3.1994586102263107, 3.148163960228997,
        2.7884063478491496, 3.551079140461902, 3.347586541376174, 3.1075732681116466,
        3.061273580499278, 5.685684081233754, 3.960973447459933, 3.3689211311428746,
        3.9479866224986697, 4.1733934306059774, 3.011939900949928, 4.011915253048566,
        2.713892225788876, 3.879079374362149, 3.555601078694558, 2.164693119706263,
        1.8651209344359467, 1.9430271404232442, 2.168630061288054, 1.767865292526923,
        2.385712235842925, 2.137886808238731, 2.176345061028005, 2.0054528339512356,
        2.383114236641062, 1.9865309258235508, 1.851029155811163, 2.502199048002393,
        2.088228192179264, 2.1479266063019744, 2.1647636683420672, 2.3513824391705365,
        2.4411675281240495, 2.0064017643428125, 1.775274455493045, 2.1730292476779156,
        2.393558985098182, 1.9594598431776602, 2.5629385882238895, 2.8863609021164764,
        2.9023796270526026, 2.5009571425354133, 4.427216908232738, 3.4819524601389023,
        2.9275979770538965, 2.550044252052682, 2.868609962022823, 2.1180688060132993,
        3.233945851908231, 1.9698305824331388, 2.5517154269263402, 2.8207942553077894,
        2.908558123014386, 1.9898801383637679, 1.5917808677890048, 2.8115111046596013,
        1.6122724763309466, 3.629972115148036, 4.345891759290107, 3.3744822783418242,
        1.7819925188346242, 2.8981735458028566, 2.8096849800039334, 2.4868399921486053,
        1.9045635569046024, 1.6698165251899704, 3.8308231896018157, 4.145928242786881,
        1.5148438391266574, 2.7368902962440154, 2.0020904868658778, 1.1924127732103467,
        2.5497546249266736, 1.8820204608399114, 1.4357223008245108, 0.7415088052087871
      ],
      label: "ISOR",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
        44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
        64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
        119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
        135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
        167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
        183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
        199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
        215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230,
        231, 232, 233, 234, 235, 236
      ],
      y: [
        54.756154754979306, 33.029376838821726, 22.46478022777921, 14.04163594461696,
        9.788788277784573, 7.384437177343667, 6.098086451343674, 5.358285776795596,
        4.9166673497888045, 4.647603886646724, 4.438967397267579, 4.282289153954822,
        4.14526451488621, 4.039684370803369, 3.97128081229195, 3.8964961581621775,
        3.8317822421354215, 3.7939451042461867, 3.758949588462075, 3.728565149603395,
        3.7159036475584974, 3.694621961940243, 3.6705935716242313, 3.6453300211785473,
        3.612118489028893, 3.5817164428061874, 3.5555909606867018, 3.5268651764439554,
        3.5028272963141887, 3.4841904905990355, 3.4666435016439783, 3.4509782799443753,
        3.428303648591426, 3.4070274256187525, 3.3840285049626484, 3.360529966741374,
        3.341454584850866, 3.3232801755106944, 3.309189153988708, 3.2912557689473148,
        3.275278535624069, 3.2579263867705666, 3.2452010568808616, 3.230315666833509,
        3.214092605488345, 3.1989208321090885, 3.1820177817124464, 3.1670360623055975,
        3.154778382475901, 3.142416008169067, 3.1275752541433137, 3.1117920946599233,
        3.097805231622979, 3.083934943129169, 3.067887249662129, 3.051976476979983,
        3.039870166654594, 3.0268275734319827, 3.016352588969922, 3.0041375933348995,
        2.9946490127464425, 2.9855954604137906, 2.9738013308654105, 2.962823803748146,
        2.9528646240442447, 2.9471421386575583, 2.9373875817269384, 2.9288862139293466,
        2.9222692936221506, 2.915114660952198, 2.908764025983603, 2.9020316888123867,
        2.895984537234183, 2.890594939813127, 2.8873915254198925, 2.883608037384399,
        2.8795570548337364, 2.8750076902360524, 2.8697881839137374, 2.865214387792002,
        2.86195297110459, 2.8590284430664186, 2.8566613747252463, 2.8517279736853167,
        2.8461831167038167, 2.8403072656186112, 2.8332491431909124, 2.8285943793465176,
        2.824031744550744, 2.818715879829709, 2.8126471332345786, 2.8062491636940563,
        2.800718594158128, 2.7956839980507877, 2.7918409138068623, 2.786946680119026,
        2.783011296236497, 2.7796515186314066, 2.7766036956672644, 2.7730724408088143,
        2.7700772153715234, 2.76677477888909, 2.7630678855905435, 2.758236762385181,
        2.754331761737214, 2.751901720504045, 2.7485033848044007, 2.746002923161736,
        2.742978110443144, 2.7400458023324243, 2.7376842449631424, 2.73635014780786,
        2.7338581482676396, 2.731520429579882, 2.728991141695529, 2.7258774038968387,
        2.7233318836984894, 2.7215777733810014, 2.719896547455789, 2.718074973678775,
        2.715577065831614, 2.7136609038838917, 2.712234635062901, 2.709997436370606,
        2.7080917262617197, 2.7059017724005763, 2.703176702411948, 2.700794866568816,
        2.700076479022646, 2.6996430666347977, 2.69994434160507, 2.7003077790873196,
        2.701294307090241, 2.701209691599516, 2.701993736134976, 2.701609098185633,
        2.7019637408301285, 2.7018278514029586, 2.701146688580306, 2.701390737332603,
        2.701281664210605, 2.701136768373155, 2.701193162170416, 2.70177899401377,
        2.7021332652404, 2.7026727317054853, 2.703248226218703, 2.703748855178436,
        2.7045822260612633, 2.704713465918848, 2.7048451732565053, 2.705097981614822,
        2.7056124586971473, 2.705922710520935, 2.706420863624819, 2.706697485033473,
        2.7072279928854184, 2.707716499651035, 2.7080181848986085, 2.7083009459109206,
        2.7083510667563835, 2.708881632357276, 2.7093159620499345, 2.7095783959767825,
        2.7097892138810664, 2.7110775084261665, 2.711736312679006, 2.7120951125822126,
        2.7127343891675553, 2.713559249536909, 2.713717418064941, 2.7144160180691768,
        2.7144156909355677, 2.7150815528415757, 2.7155318689199097, 2.7153156930346314,
        2.714957687680004, 2.714645234004499, 2.714431924303094, 2.714091144785856,
        2.713995766635068, 2.7137910116398496, 2.7136061137323457, 2.713336519691348,
        2.713206932443994, 2.7129153011070337, 2.712655255933831, 2.7126087941374575,
        2.7124612655453575, 2.712313074027977, 2.7121882954185814, 2.712133363406756,
        2.712084736044129, 2.711932068118106, 2.711728641618674, 2.7116106859705584,
        2.711542470812227, 2.711391642345555, 2.7113692213524794, 2.7113999768112707,
        2.711433453410521, 2.711395464842721, 2.711695967419013, 2.7118367498101263,
        2.7118717267465766, 2.711841721223922, 2.7118695166869817, 2.7117527583530525,
        2.7118502426828313, 2.711731523365085, 2.711704274567977, 2.711724172430921,
        2.711748453812907, 2.711675648766544, 2.7115671238075554, 2.711572709074131,
        2.7114644949894906, 2.7115371619990487, 2.71166845770822, 2.7117271153106075,
        2.7116555676726914, 2.711669461398797, 2.7116791578317065, 2.7116587291532332,
        2.7115406251381247, 2.7114457408623824, 2.711516092073958, 2.7116186620532217,
        2.711508160323733, 2.711510316449086, 2.7114495582445066, 2.711351370711864,
        2.711342221779665, 2.711306567236718, 2.71126421264744, 2.7112019744958715
      ],
      label: "CSOR",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    }
  ],
  layout: null,
  requestId: ""
};

export default useTypeWellQuery;
