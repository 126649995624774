import { useProjectContext } from "../context";
import { useProjectListQuery } from "../queries";

interface IUseSelectedProjectParams {
  isAutoRefetching?: boolean;
}

export function useSelectedProject(params?: IUseSelectedProjectParams) {
  const { selectedProjectId } = useProjectContext();

  const projectListQuery = useProjectListQuery({
    isAutoRefetching: params?.isAutoRefetching
  });

  const selectedProject = projectListQuery.data?.find(
    (project) => project.projectId === selectedProjectId
  );

  return { selectedProject };
}
