import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Geometry, Position } from "@turf/helpers";
import _debounce from "lodash/debounce";
import { AppThunk } from "store/store";

import { IMapResult } from "api/map";

import { IGroupBy, INormalizeBySetting } from "models";
import { mBinSize } from "models/binSize";

import { IColorPalette } from "../../../components/user-settings/models";
import { EntityKind } from "../../../models/entityKind";
import { ColorLockedLegendItem } from "../app/appSlice";

export interface ISelectedWell {
  color: string;
  Uwi: string;
  group: string;
}

export interface RasterLayerOpacity {
  layer: string;
  id: string;
  opacity: number;
}

export interface MapDisplaySettings {
  wellLineThickness: number;
  wellDotThickness: number;
  wellLabelSize: number;
  facilityLabelSize: number;
  shapefileDetailsLabelSize: number;
  shapefileNameLabelSize: number;
}
export interface MapState {
  attentionWells: string[];
  displaySettings: MapDisplaySettings;
  exploitableArea: Geometry[];
  flyToGroup: string;
  groupBy;
  hoveredWell;
  isDebuggingMap: boolean;
  layers;
  mapExtent;
  mapState;
  selectedWells;
  selectedFacilities;
  txnId;
  typeLogWells;
  viewLock;
  xdaInterceptData;
  xdaLineRequest;
  xdaMidpoint;
  /**
   * when set will draw attention to the polygon on the map
   * attention layers are on top of everything
   */
  attentionPolygon: GeoJSON.Polygon;
}

const initialState: MapState = {
  txnId: {
    id: "",
    facilityId: "",
    legend: {
      legendItems: [],
      title: ""
    },
    facilityLegend: {
      legendItems: [],
      title: ""
    },
    filterId: "",
    bBox: {
      sw: [],
      ne: []
    }
  },

  attentionPolygon: undefined,
  exploitableArea: [],
  mapExtent: {
    type: "Polygon",
    coordinates: [] as Position[][]
  } as GeoJSON.Polygon,
  groupBy: {},
  layers: [],
  selectedWells: {},
  selectedFacilities: {},
  typeLogWells: [],
  flyToGroup: undefined,
  xdaMidpoint: [],
  attentionWells: [],
  hoveredWell: [],
  xdaInterceptData: {
    line: [],
    points: []
  },
  xdaLineRequest: {
    line: [],
    wells: []
  },
  mapState: {
    lat: 55.869737,
    lng: -120.608666,
    zoom: 5
  },
  viewLock: false,
  isDebuggingMap: false,
  displaySettings: {
    wellLineThickness: 2,
    wellDotThickness: 2,
    wellLabelSize: 9,
    facilityLabelSize: 9,
    shapefileDetailsLabelSize: 9,
    shapefileNameLabelSize: 9
  }
};

const mapSlice = createSlice({
  name: "groupby",
  initialState,
  reducers: {
    setTxnId(state, action: PayloadAction<IMapResult>) {
      state.txnId = action.payload;
    },
    setMapExtent(state, action: PayloadAction<GeoJSON.Polygon>) {
      state.mapExtent = action.payload;
    },
    setXdaInterceptData(state, action) {
      state.xdaInterceptData = action.payload;
    },
    setXdaLineRequest(state, action) {
      state.xdaLineRequest = action.payload;
    },
    setXdaMidpoint(state, action) {
      state.xdaMidpoint = action.payload;
    },
    setFlyToGroup(state, action: PayloadAction<string>) {
      state.flyToGroup = action.payload;
    },
    setMapGroupBy(state, action) {
      state.groupBy = action.payload;
    },
    setSelectedWells(state, action: PayloadAction<{ [name: string]: ISelectedWell }>) {
      state.selectedWells = action.payload;
    },
    setSelectedFacilities(
      state,
      action: PayloadAction<{ [name: string]: ISelectedWell }>
    ) {
      state.selectedFacilities = action.payload;
    },
    setTypeLogWells(state, action: PayloadAction<ISelectedWell[]>) {
      state.typeLogWells = action.payload;
    },
    setAttentionWells(state, action: PayloadAction<string[]>) {
      if (action.payload.length !== state.attentionWells.length) {
        state.attentionWells = action.payload;
        return;
      }
      for (const item of action.payload) {
        if (state.attentionWells.indexOf(item) < 0) {
          state.attentionWells = action.payload;
          break;
        }
      }
    },
    setLayers(state, action) {
      state.layers = action.payload;
    },
    setHoveredWell(state, action) {
      state.hoveredWell = action.payload;
    },
    setAttentionPolygon(state, action: PayloadAction<GeoJSON.Polygon>) {
      state.attentionPolygon = action.payload;
    },
    setMapState(state, action) {
      state.mapState = action.payload;
    },
    setViewLock(state, action: PayloadAction<boolean>) {
      state.viewLock = action.payload;
    },
    setExploitableArea(state, action: PayloadAction<Geometry[]>) {
      state.exploitableArea = action.payload;
    },
    setIsDebuggingMap(state, action: PayloadAction<boolean>) {
      state.isDebuggingMap = action.payload;
    },
    setDisplaySettings(state, action: PayloadAction<MapDisplaySettings>) {
      state.displaySettings = action.payload;
    }
  }
});

export const {
  setAttentionPolygon,
  setAttentionWells,
  setExploitableArea,
  setFlyToGroup,
  setHoveredWell,
  setIsDebuggingMap,
  setLayers,
  setMapExtent,
  setMapGroupBy,
  setMapState,
  setSelectedWells,
  setSelectedFacilities,
  setTypeLogWells,
  setTxnId,
  setViewLock,
  setXdaInterceptData,
  setXdaLineRequest,
  setXdaMidpoint,
  setDisplaySettings
} = mapSlice.actions;

export const setSelectedEntities =
  (
    entityKind: EntityKind,
    selectedEntities: {
      [name: string]: ISelectedWell;
    }
  ): AppThunk =>
  (dispatch) => {
    if (entityKind === EntityKind.Well) {
      return dispatch(setSelectedWells(selectedEntities));
    } else {
      return dispatch(setSelectedFacilities(selectedEntities));
    }
  };

const update = _debounce((dispatch, layers) => {
  dispatch(setLayers(layers));
}, 500);

export const updateLayers = (layers) => (dispatch) => {
  update(dispatch, layers);
};

const debounce = _debounce((item, dispatch) => {
  dispatch(setHoveredWell(item));
}, 500);

export const updateHoveredWell =
  (item: []): AppThunk =>
  (dispatch) => {
    debounce(item, dispatch);
  };

export interface IGroupByMap {
  groupByField: string;
  dataType: string;
  canBin: boolean;
  bin: mBinSize;
}

export const clearWellMap = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  dispatch(setTxnId({ ...state.map.txnId, id: "clear" }));
};
export const clearFacilityMap = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  dispatch(setTxnId({ ...state.map.txnId, facilityId: "clear" }));
};

export interface IMapRequest {
  UserName: string;
  ColorPalette: IColorPalette;
  FilterId: string;
  GroupBy: IGroupBy;
  ReverseColor: boolean;
  LockStyle: boolean;
  Product: string;
  SortBy: number;
  ShowGroupsNotInFilter: boolean;
  GetBBox: boolean;
  HasCheckedBins: boolean;
  NormalizeBySetting: INormalizeBySetting;
  ColorLockedItems: { [key: string]: ColorLockedLegendItem };
  EntityKind: "Well" | "Facility";
}

export default mapSlice.reducer;
