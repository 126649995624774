import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { Button, Modal, Table } from "antd";
import styled from "styled-components/macro";

import {
  DataSourceAccess,
  DataSourceModel,
  DataTypeAccessResult,
  getDataSourceAccess
} from "api/dataSource";

import { IconSpinner } from "components/icons";

interface DataSourceAccessModalModel {
  open: boolean;
  onClose: () => void;
  orgId: string;
  dataSource: DataSourceModel;
}

interface GetDataSourceAccessInput {
  orgId: string;
  dataSourceKey: string;
}
export default function DataSourceAccessModal({
  open,
  onClose,
  orgId,
  dataSource
}: DataSourceAccessModalModel) {
  const [dataTypeAccessData, setDataTypeAccessData] = useState<DataTypeAccessResult[]>(
    []
  );

  const getDataSourceAccessMutation = useMutation(
    async (input: GetDataSourceAccessInput) =>
      await getDataSourceAccess(input.orgId, input.dataSourceKey),
    {
      onSuccess(response) {
        const access: DataSourceAccess = response?.data;

        if (access) {
          setDataTypeAccessData(access.dataTypesAccess);
        }
      }
    }
  );

  useEffect(() => {
    if (!open || !dataSource) return;

    getDataSourceAccessMutation.mutate({
      orgId,
      dataSourceKey: dataSource.dataSourceKey
    });
  }, [open]);

  const accessColumns = [
    {
      title: "Access Type",
      dataIndex: "dataType",
      key: "dataType"
    },
    {
      title: "Well Count",
      dataIndex: "wellCount",
      key: "wellCount"
    }
  ];

  return (
    <Modal
      open={open}
      onOk={onClose}
      onCancel={onClose}
      closable={true}
      title="Data Source Access"
      wrapClassName="edit-data-source-dialog"
      footer={[
        <Button key="cancel" onClick={onClose}>
          Close
        </Button>
      ]}>
      {getDataSourceAccessMutation.isLoading && <StyledSpinner />}
      {!getDataSourceAccessMutation.isLoading && (
        <Table
          dataSource={dataTypeAccessData}
          columns={accessColumns}
          pagination={false}></Table>
      )}
    </Modal>
  );
}

const StyledSpinner = styled(IconSpinner)`
  align-self: flex-start;
`;
