import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

///hook to fetch ipdb fields
export default function useDeleteCutoffSource() {
  const dataServiceEndPoint = process.env.REACT_APP_DATA_ROOT;

  const queryClient = useQueryClient();
  const deleteRecord = async (source: string) => {
    // Here you would make your HTTP request or whatever your deletion logic needs to be
    const response = await axios.delete(
      `${dataServiceEndPoint}/api/v1/data/ipdb/playto3d/sources/cutoff/${encodeURIComponent(
        source
      )}`
    );

    if (response.status !== 200) {
      throw new Error("Problem deleting record");
    }
  };
  return useMutation(deleteRecord, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("useDeleteCutoffSource");
    }
  });
}
