import { FC, useEffect, useRef } from "react";

import styled from "styled-components/macro";

import { useChartSettings } from "components/multiphase-chart/context";
import {
  changeScreenshot,
  toggleIsFullscreen
} from "components/multiphase-chart/context/reducer/chartSettingsReducer";
import { useScreenshotDispatch } from "components/screenshot/hooks";
import { useScreenshotContext } from "components/screenshot/hooks/useScreenshotContext";
import { Portal } from "components/ui";

import ChartLegend from "../legend/ChartLegend";

type ScreenshotT = {
  containerId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartInstance?: any;
};

const Screenshot: FC<ScreenshotT> = ({ containerId, chartInstance = null }) => {
  // context
  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const { settings, widget } = useScreenshotContext();

  // dispatch
  const screenshotDispatch = useScreenshotDispatch();

  // ref
  const overlayRef = useRef(null);

  // derived state
  const screenshotContainer = document.getElementById(containerId);

  useEffect(() => {
    const screenshotActive = !!chartSettings.id && widget?.widgetId === chartSettings.id;

    changeScreenshot(chartSettingsDispatch, {
      visible: screenshotActive,
      preset: settings
    });

    toggleIsFullscreen(chartSettingsDispatch, screenshotActive);
  }, [chartSettingsDispatch, chartSettings.id, settings, widget]);

  // Update the widgets state.
  useEffect(() => {
    const screenshotActive = !!chartSettings.id && widget?.widgetId === chartSettings.id;

    if (screenshotActive) {
      screenshotDispatch({
        payload: {
          widgetState: {
            chartSettings: chartSettings,
            chartInstance: chartInstance,
            copyOverlay: overlayRef.current
          }
        }
      });
    }
  }, [chartSettings, screenshotDispatch, chartSettings.id, widget, chartInstance]);

  if (!chartSettings.screenshot.visible || !screenshotContainer) return null;

  // Update the overlay.
  screenshotDispatch({
    payload: {
      screenshotOverlay: overlayRef.current
    }
  });

  const screenshotBounds = {
    width: settings?.width || 1152,
    height: settings?.height || 681
  };

  return (
    <Portal container={screenshotContainer}>
      <Overlay
        ref={overlayRef}
        width={screenshotBounds.width}
        height={screenshotBounds.height}>
        {settings?.legendVisible && settings?.legendType === "inplot" && (
          <ChartLegend
            parentDimensions={screenshotBounds}
            id={`${containerId}screenshot-legend`}
            disableDragging={false}
            width={250}
          />
        )}
      </Overlay>
    </Portal>
  );
};

export default Screenshot;

const Overlay = styled.div`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border: 2px solid rgb(255, 208, 0);
  z-index: 1;
  pointer-events: none;
`;
