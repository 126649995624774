import { memo } from "react";

function WGR() {
  return (
    <svg width="29px" height="30px" viewBox="0 0 29 30">
      <defs>
        <path d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use href="#path_1" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPWGR">
        <path
          d="M10.2441 8.78083C10.0666 5.91552 5.04663 0 5.04663 0C3.91117 5.0971 -0.00453337 5.67788 3.93994e-06 9.03322C0.00283976 11.2973 1.02941 12.612 2.31006 13.3102C1.90454 11.9541 2.06902 10.3802 3.28672 9.2425C5.53496 7.14173 5.78224 5.72892 5.78224 5.72892C8.10818 9.85844 7.53761 12.4532 6.66134 13.7617C8.79502 13.1724 10.4069 11.404 10.2441 8.78083"
          transform="translate(18.621473 15.621112)"
          id="Fill-1-Copy-6"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 26.5666L27 0"
          transform="translate(0.9234142 1.9575263)"
          id="Path-8-Copy-8"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <g id="Group-2-Copy-3">
          <path
            d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z"
            id="Mask-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <g id="Group-2" transform="translate(0 1.2340614)">
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                id="Stroke-1-Copy-5"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                transform="translate(0 4.0531893)"
                id="Stroke-4-Copy-4"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(WGR);
