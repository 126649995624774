/* Remove counts enclosed in parentheses (e.g., "(123)") from the input string.
 * Note: Converting input parameter to string to ensure consistent behavior and prevent runtime errors.
 */
export const removeCount = (title: string): string => {
  if (!title) {
    return "";
  }
  return String(title)
    .replace(/(\([0-9]+\))/g, "")
    .trim();
};
