import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

import { Tree } from "antd";

import { useUser } from "hooks";

import { IShapefileNode } from "models/projects";

import { Heading } from "components/base";
import { findParentNode } from "components/project/layers/utils";
import {
  useMoveProjectShapefileFolderMutation,
  useMoveProjectShapefileMutation
} from "components/project/shapefiles/mutations";

import { useSystemShapefiles } from "../hooks/useSystemShapefiles";
import "./OrganizationShapefiles.scss";
import SystemFolderNode from "./SystemFolderNode";
import SystemRootNode from "./SystemRootNode";
import SystemShapefileNode from "./SystemShapefileNode";

interface SystemShapefilesT {
  allowUpload?: boolean;
  showHeader?: boolean;
}
export default function SystemShapefiles({
  showHeader = true
}: SystemShapefilesT): JSX.Element {
  const treeContainer = useRef(null);
  const [, setTreeHeight] = useState(585);
  const { user, isAtLeastAdmin } = useUser();
  const isSystemAdmin =
    isAtLeastAdmin &&
    (user?.organization?.emailDomain === "mcdan.com" ||
      user?.organization?.emailDomain === "turinganalytics.net");
  useResizeObserver(treeContainer, (client) => {
    setTreeHeight(client.contentRect.height);
  });
  const { tree } = useSystemShapefiles();

  const moveProjectShapefile = useMoveProjectShapefileMutation({
    projectId: "system"
  });

  const moveProjectShapefileFolder = useMoveProjectShapefileFolderMutation({
    projectId: "system"
  });

  function getDropKey(dropKey) {
    if (dropKey === "McDaniel Shapefiles") {
      return "mcdanielShapefiles";
    }
    return dropKey;
  }

  const onDrop = useCallback(
    (info) => {
      const dragType = info.dragNode.type;
      let dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      let dropPosition = info.dropPosition;
      if (info.dropToGap) {
        const parentNodeId = findParentNode(tree, dropKey, "system");
        //drop to gap means that the user wishes the dragNode
        //to to be a sibiling of the drop target
        //the parent of the dropkey is the actual drop target
        dropKey = parentNodeId ?? "";
      } else {
        const dragParentNodeId = findParentNode(tree, dragKey, "");
        const dragNode = info.dragNode;
        const dropNode = info.node;
        const droppedToSameFolder =
          dragNode?.shapefileNodeId == dropNode.shapefileNodeId || //shapefileParentNode is same as folder
          dragNode?.parentId === dropNode.shapefileNodeId ||
          dragParentNodeId === dropNode.key; //parent node is same as drop node
        if (droppedToSameFolder) {
          //dropping to the same folder previously and dropToGap is false
          //so the user must want to drop to the top of the folder
          dropPosition = 0;
        }
      }

      if (info.node.type === "shapefile") {
        if (!info.dropToGap) {
          dropPosition += 1;
        }
        //can't drop on to a shapefile so we move it to the node that
        //holds the shapefile we're trying to drop to
        dropKey = info.node.shapefileNodeId;
      }

      const dropLevel = info.node.pos.split("-").length;
      const dropToRoot = dropLevel === 2;

      if (dropToRoot) {
        //dropped to root node so set dropPosition to 0 because
        //user wants to drop it at the top of the
        dropPosition = 0;
      }
      if (dragType === "node") {
        dropKey = getDropKey(dropKey);
        moveProjectShapefileFolder.mutate({
          shapefileNodeId: dragKey,
          moveToId: dropKey,
          dropPosition: dropPosition,
          type: "system"
        });
      } else if (dragType === "shapefile") {
        dropKey = getDropKey(dropKey);
        moveProjectShapefile.mutate({
          shapefileId: dragKey,
          moveToId: dropKey,
          dropPosition: dropPosition,
          type: "system"
        });
      } else if (dragKey === "") {
        // The user attempted to move the root shapefiles folder, so ignore.
      } else {
        toast.error("Unable to move layer");
      }
    },
    [tree]
  );

  const customTreeNode = useCallback((node: IShapefileNode | IShapefileNode) => {
    node = { ...node };
    if (node.type === "root") {
      return <SystemRootNode node={node} />;
    } else if (node.type === "shapefile") {
      return <SystemShapefileNode node={node} />;
    } else if (node.type === "node") {
      return <SystemFolderNode node={node} />;
    } else {
      return null;
    }
  }, []);

  if (!isSystemAdmin) {
    return null;
  }

  return (
    <>
      {showHeader && (
        <div className="organizationAccountHeader">
          <Heading element="h4">System Shapefiles </Heading>
        </div>
      )}
      {tree.length > 0 && (
        <div ref={treeContainer} className="org-tree-container">
          <Tree
            className="draggable-tree"
            selectable={false}
            showLine={{ showLeafIcon: true }}
            showIcon={true}
            onDrop={onDrop}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            draggable={(node: any) => node.type !== "root"}
            blockNode
            defaultExpandAll={true}
            treeData={tree}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            titleRender={customTreeNode}
          />
        </div>
      )}
    </>
  );
}
