import { useDispatch, useSelector } from "react-redux";

import { setDisplaySettings } from "store/features";
import { RootState } from "store/rootReducer";

export function useUpdateLayerCircleRadius() {
  const dispatch = useDispatch();
  const displaySettings = useSelector((state: RootState) => state.map.displaySettings);

  const updateLayerCircleRadius = (newRadius: number) => {
    dispatch(setDisplaySettings({ ...displaySettings, wellDotThickness: newRadius }));
  };
  return { updateLayerCircleRadius };
}
