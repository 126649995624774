import { useSelector } from "react-redux";

import { RootState } from "../store/rootReducer";

interface UserModuleInfo {
  hasProduction: boolean;
  has3dGeoModel: boolean;
  hasMontney3dGeo: boolean;
}

export enum ModuleType {
  Production = 0,
  Forecast = 1,
  Geology = 2,
  ThreeDGeoModel = 3
}

/**
 * Custom hook that retrieves user module information.
 * @returns An object containing information about the modules user has access to.
 */
export default function useUserModules(): UserModuleInfo {
  /**
   * Retrieves the user object from the application state.
   */
  const user = useSelector((state: RootState) => state.auth.user);

  /**
   * Parses the user's module information from JSON format.
   */
  const modules: { [play: string]: number[] } = JSON.parse(user?.modules ?? "{}");

  /**
   * Checks if the user has a specific module.
   * @param module - The module type to check.
   * @param modules - The user's module information.
   * @returns True if the user has the specified module; otherwise, false.
   */
  const hasModule = (
    module: ModuleType,
    modules: { [play: string]: number[] }
  ): boolean => {
    /**
     * Retrieves the list of plays from the user's module information.
     */
    const plays = Object.keys(modules);

    /**
     * Iterates over each play and checks if the module is present.
     */
    for (const play of plays) {
      if (modules[play].indexOf(module) >= 0) {
        return true;
      }
    }

    return false;
  };

  const hasMontney3dGeo = (modules: { [play: string]: number[] }): boolean => {
    if ("Montney" in modules) {
      if (modules["Montney"].indexOf(ModuleType.ThreeDGeoModel) > -1) {
        return true;
      }
    }
    return false;
  };

  /**
   * Constructs and returns the user module information.
   */
  return {
    /**
     * Indicates whether the user has the Production module.
     */
    hasProduction: hasModule(ModuleType.Production, modules),

    /**
     * Indicates whether the user has the 3D Geo Model module.
     */
    has3dGeoModel: hasModule(ModuleType.ThreeDGeoModel, modules),

    hasMontney3dGeo: hasMontney3dGeo(modules)
  };
}
