import { memo } from "react";

function TotalFluid() {
  return (
    <svg
      width="30px"
      height="41px"
      viewBox="0 0 30 41"
      version="1.1"
      style={{ top: "2px" }}>
      <defs>
        <path d="M0 0L29 0L29 22L0 22L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use href="#path_1" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPTotal-Fluid">
        <path
          d="M0 0L30 0L30 30L0 30L0 0Z"
          id="Rectangle-Copy-19"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Group-2-Copy-7" transform="translate(0.5 19)">
          <path
            d="M0 0L29 0L29 22L0 22L0 0Z"
            id="Mask-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <g id="Group-2" transform="translate(0 1.3242713)">
              <path
                d="M39.084 2.27331C37.1127 2.27331 35.2766 1.81654 33.742 1.03092C31.0541 -0.34364 27.5726 -0.34364 24.8847 1.03092C23.3487 1.81654 21.514 2.27331 19.5427 2.27331C17.57 2.27331 15.7353 1.81654 14.1993 1.03092C11.5114 -0.34364 8.02989 -0.34364 5.34199 1.03092C3.8074 1.81654 1.9713 2.27331 0 2.27331"
                transform="translate(0 5.9392138)"
                id="Stroke-1-Copy-5"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M39.084 2.27331C37.1127 2.27331 35.2766 1.81654 33.742 1.03092C31.0541 -0.34364 27.5726 -0.34364 24.8847 1.03092C23.3487 1.81654 21.514 2.27331 19.5427 2.27331C17.57 2.27331 15.7353 1.81654 14.1993 1.03092C11.5114 -0.34364 8.02989 -0.34364 5.34199 1.03092C3.8074 1.81654 1.9713 2.27331 0 2.27331"
                id="Stroke-4-Copy-6"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
            </g>
          </g>
        </g>
        <g id="IconsChart---PRPOil-+-Cond" transform="translate(3.35019 0)">
          <path
            d="M12.4855 10.7058C12.4855 14.1536 9.69056 16.9485 6.24277 16.9485C2.79499 16.9485 0 14.1536 0 10.7058C0 7.25798 6.24277 0 6.24277 0C6.24277 0 12.4855 7.25798 12.4855 10.7058ZM19.9225 8.27023C19.9225 9.38604 19.018 10.2906 17.9022 10.2906C16.7863 10.2906 15.8818 9.38604 15.8818 8.27023C15.8818 7.15441 17.9022 4.8055 17.9022 4.8055C17.9022 4.8055 19.9225 7.15441 19.9225 8.27023ZM17.2336 14.1317C17.2336 15.2475 16.3291 16.152 15.2133 16.152C14.0975 16.152 13.1929 15.2475 13.1929 14.1317C13.1929 13.0159 15.2133 10.667 15.2133 10.667C15.2133 10.667 17.2336 13.0159 17.2336 14.1317ZM22.7423 14.1317C22.7423 15.2475 21.8378 16.152 20.722 16.152C19.6062 16.152 18.7016 15.2475 18.7016 14.1317C18.7016 13.0159 20.722 10.667 20.722 10.667C20.722 10.667 22.7423 13.0159 22.7423 14.1317Z"
            transform="matrix(1 0 0 1 1.8189894E-12 0)"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(TotalFluid);
