import { useQueryClient } from "react-query";

import { IShapefileNode } from "models/projects";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import { useProjectContext } from "components/project/projects/context";

import { useAddShapefileListMutation } from "../mutations";
import { PROJECT_SHAPEFILES_QUERY_KEYS } from "../queries";

export function useAddShapefileList() {
  const { selectedProjectId, isAddingExistingFolders } = useProjectContext();
  const queryClient = useQueryClient();

  const addShapefileList = useAddShapefileListMutation({
    onSuccess() {
      // Will be needed if we add the shapefiles to checked list.
      // dispatch(setHasViewItemsChanged(true));

      queryClient.invalidateQueries({
        queryKey: [
          PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles,
          selectedProjectId
        ]
      });

      queryClient.invalidateQueries({
        queryKey: [
          PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList,
          selectedProjectId
        ]
      });
    }
  });

  function mutate(params: { node: IShapefileNode }) {
    addShapefileList.mutate({
      projectId: selectedProjectId,
      isAddingExistingFolders,
      ...params
    });
  }

  return { ...addShapefileList, mutate };
}
