import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { updateHoverLegendItem } from "store/features";
import styled from "styled-components";

import { FocusTreeMapInputModel } from "models/LegendItem";

import { ErrorBoundary } from "components/base";

let myChart = null;

export default function FocusTreeMap({
  items,
  onChange
}: FocusTreeMapInputModel): JSX.Element {
  const dispatch = useDispatch();

  const itemsRef = useRef(items);
  const chartIdRef = useRef<HTMLElement | null>(null);
  const [treemapOptions, setTreeMapOptions] = useState(undefined);

  useEffect(() => {
    itemsRef.current = items;
    setTreeMapOptions({
      chartType: "treemap",
      tooltip: {},
      breadcrumb: {
        show: false
      },
      series: [
        {
          breadcrumb: {
            show: false
          },
          name: "",
          type: "treemap",
          label: {
            show: true,
            formatter: "{b}"
          },
          itemStyle: {
            borderColor: "#fff"
          },
          levels: [
            {
              itemStyle: {
                borderColor: "#555",
                borderWidth: 4,
                gapWidth: 4
              }
            },
            {
              colorSaturation: [0.3, 0.6],
              itemStyle: {
                borderColorSaturation: 0.7,
                gapWidth: 2,
                borderWidth: 2
              }
            },
            {
              colorSaturation: [0.3, 0.5],
              itemStyle: {
                borderColorSaturation: 0.6,
                gapWidth: 1
              }
            },
            {
              colorSaturation: [0.3, 0.5]
            }
          ],

          data: items.map((li) => {
            return {
              value: li.count,
              name: li.value,
              select: false,
              itemStyle: {
                color: li.inFilter ? li.color : "#cfcfcf44"
              },
              path: li.property
            };
          })
        }
      ]
    });
  }, [items]);

  useEffect(() => {
    import("hd-echarts/echarts").then((echarts) => {
      myChart = echarts.init(chartIdRef.current, null, {
        renderer: "canvas",
        width: "auto",
        height: "auto"
      });
      myChart.on("hover", (e) => {
        const index = e.dataIndex - 1;
        const seriesIndex = e.seriesIndex;
        if (e.seriesIndex >= 0) {
          const option = myChart.getOption();
          const series = option.series[seriesIndex];
          if (series.data.length > index) {
            const data = series.data[index];
            if (data) {
              const seriesName = data.name;
              dispatch(updateHoverLegendItem(seriesName));
            }
          }
        }
      });
      myChart.on("click", (e) => {
        const index = e.dataIndex - 1;
        const seriesIndex = e.seriesIndex;
        if (e.seriesIndex >= 0) {
          const option = myChart.getOption();
          const series = option.series[seriesIndex];
          if (series.data.length > index) {
            const data = series.data[index];
            if (data) {
              const seriesName = data.name;
              const matches = itemsRef.current.filter((item) => item.value == seriesName);
              if (matches.length > 0) {
                onChange && onChange(matches);
              }
            }
          }
        }
      });
    });

    return () => {
      if (myChart) {
        myChart.off("hover");
        myChart.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (myChart) {
      myChart.setOption(treemapOptions);
    }
  }, [treemapOptions]);

  useResizeObserver(chartIdRef, () => {
    if (myChart) {
      myChart.resize();
    }
  });

  return (
    <ErrorBoundary>
      <RootContainer ref={chartIdRef}></RootContainer>
    </ErrorBoundary>
  );
}

const RootContainer = styled.div`
  max-width: 100%;
  min-width: 100%;
  min-height: 0;
  min-height: 100%;
  overflow-x: hidden;
  svg {
    text {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`;
