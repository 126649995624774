import { useMutation } from "react-query";

import { UpdateColumnSetRequest, updateColumnSet } from "api/column";

export default function useUpdateColumnSetMutation(onSuccess, onError) {
  return useMutation(
    async (request: UpdateColumnSetRequest) => await updateColumnSet(request),
    {
      onSuccess: onSuccess,
      onError: onError
    }
  );
}
