import { getLayerTreeExpandedKeysSessionKey } from "./getLayerTreeExpandedKeysSessionKey";

export function setLayerTreeExpandedKeysToSession({
  expandedKeys,
  projectId
}: {
  expandedKeys: string[];
  projectId: string;
}) {
  const sessionKey = getLayerTreeExpandedKeysSessionKey(projectId);
  sessionStorage.setItem(sessionKey, JSON.stringify(expandedKeys));
}
