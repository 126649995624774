import { useMemo } from "react";

import { ALL_CHART_TYPES, PARAM_FIELD } from "constants/chart.constants";

import { EntityKind } from "../../../models/entityKind";
import { CalculationMethod } from "../components/stacked-bar-options/StackedBarCalculation";
import { useChartState } from "../context";

const useDefaultChartTypeParam = () => {
  const { settings, entityKind } = useChartState();

  const { chartType, showHistogram } = settings;

  const isCrossPlot = chartType === ALL_CHART_TYPES.CrossPlot.label;
  const isProbit = chartType === ALL_CHART_TYPES.Probit.label;
  const isBoxPlot = chartType === ALL_CHART_TYPES.BoxPlot.label;
  const isStackedBar = chartType === ALL_CHART_TYPES.StackedBar.label;
  const isHistogram = chartType === ALL_CHART_TYPES.StackedBar.label && showHistogram;
  const isDateTrend = chartType === ALL_CHART_TYPES.TrendDate.label;

  return useMemo(() => {
    if (isCrossPlot) {
      if (entityKind === EntityKind.Well) {
        return {
          x: PARAM_FIELD.Vintage_YearMonth,
          y: PARAM_FIELD.Oil12MCalTime
        };
      } else {
        return {
          x: PARAM_FIELD.FacilityStartDateYear,
          y: PARAM_FIELD.CurrentCalRate
        };
      }
    }

    if (isDateTrend) {
      return {
        x: PARAM_FIELD.Vintage_YearMonth,
        y: PARAM_FIELD.Oil12MCalTime,
        calculationMethod: CalculationMethod.Average
      };
    }

    if (isProbit) {
      return {
        x: PARAM_FIELD.Oil12MCalTime,
        y: PARAM_FIELD.HzLength
      };
    }

    if (isBoxPlot) {
      return {
        x: PARAM_FIELD.ResourcePlay,
        y: PARAM_FIELD.Oil12MCalTime
      };
    }

    if (isHistogram) {
      return {
        x: PARAM_FIELD.ResourcePlay,
        y: PARAM_FIELD.Oil12MCalTime,
        calculationMethod: CalculationMethod.Count
      };
    }

    if (isStackedBar) {
      return {
        x: PARAM_FIELD.Vintage_Year,
        y: PARAM_FIELD.HzLength,
        calculationMethod: CalculationMethod.Count
      };
    }

    return null;
  }, [isCrossPlot, isDateTrend, isProbit, isBoxPlot, isHistogram, isStackedBar]);
};

export default useDefaultChartTypeParam;
