import React, { createContext, useContext, useMemo, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import {
  WellCardTypeLogPdf,
  WellDetailCardData,
  WellDetailCardDataGroup
} from "api/data";

import {
  WellDetailsAction,
  WellDetailsDispatch,
  WellDetailsProviderT,
  WellDetailsState
} from "./types";

const WellDetailsContext =
  createContext<[WellDetailsState, React.Dispatch<WellDetailsAction>]>(undefined);
WellDetailsContext.displayName = "WellDetailsContext";

const initialState: WellDetailsState = {
  wellDetails: {},
  loadedGroups: [],
  currentGroup: "Header"
};

const wellDetailsReducer = (draft: WellDetailsState, action: WellDetailsAction) => {
  switch (action.type) {
    case "update well details": {
      draft.wellDetails = action.payload;
      break;
    }
    case "append well details": {
      draft.wellDetails = {
        ...draft.wellDetails,
        ...action.payload
      };
      break;
    }
    case "set groups well details": {
      draft.wellDetails = {
        ...draft.wellDetails,
        ...action.payload.wellData
      };
      draft.loadedGroups.push(action.payload.group);
      break;
    }
    case "set active group": {
      draft.currentGroup = action.payload;
      break;
    }
    case "reset well details context": {
      draft.wellDetails = {};
      draft.loadedGroups = [];
      break;
    }
    case "set well type log pdf": {
      draft.wellDetails[0] = action.payload.wellData;
      draft.loadedGroups.push(action.payload.group);
      break;
    }
    default:
      throw new Error("invalid well details action");
  }
};

const curriedReducer = produce(wellDetailsReducer);

// provider
const WellDetailsProvider = ({ children }: WellDetailsProviderT) => {
  const [state, dispatch] = useReducer(curriedReducer, initialState);
  const value: [WellDetailsState, WellDetailsDispatch] = useMemo(
    () => [state, dispatch],
    [state]
  );

  return (
    <WellDetailsContext.Provider value={value}>{children}</WellDetailsContext.Provider>
  );
};

// consumer hook
const useWellDetails = () => {
  const context = useContext(WellDetailsContext);
  if (context === undefined) {
    throw new Error("useWellDetails must be used within WellDetailsProvider");
  }
  return context;
};

// module functions
const updateWellDetails = (dispatch: WellDetailsDispatch, value: WellDetailCardData) =>
  dispatch({ type: "update well details", payload: value });

const appendWellDetails = (dispatch: WellDetailsDispatch, value: WellDetailCardData) =>
  dispatch({ type: "append well details", payload: value });

const setGroupWellDetails = (
  dispatch: WellDetailsDispatch,
  value: WellDetailCardDataGroup
) => dispatch({ type: "set groups well details", payload: value });

const setTypeLogPdf = (dispatch: WellDetailsDispatch, value: WellCardTypeLogPdf) =>
  dispatch({ type: "set well type log pdf", payload: value });

const setActiveGroup = (dispatch: WellDetailsDispatch, value: string) =>
  dispatch({ type: "set active group", payload: value });

const resetWellDetailsContext = (dispatch: WellDetailsDispatch) =>
  dispatch({ type: "reset well details context", payload: undefined });

export {
  updateWellDetails,
  appendWellDetails,
  setGroupWellDetails,
  setActiveGroup,
  setTypeLogPdf,
  resetWellDetailsContext,
  WellDetailsProvider,
  useWellDetails
};
