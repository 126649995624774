// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiCalculator, mdiUndo } from "@mdi/js";
import { Form, Input, Switch } from "antd";
import styled from "styled-components";

import { useScreenshotSettingContext } from "../hooks";

interface InputWihUnitModel {
  type: string;
  value?: string | number | boolean;
  step?: number;
  disabled?: boolean;
  title?: string;
  min?: number;
  max?: number;
  unit?: string;
  valueProperty?: string;
  onChange?: (evt) => void;
}

export default function ScreenshotSettingItemInput({
  type,
  value,
  step,
  disabled,
  min,
  max,
  unit,
  onChange
}: InputWihUnitModel) {
  // state
  const [ownedValue, setOwnedValue] = useState<string | number | boolean>(value);
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [lastCalculatedValue, setLastCalculatedValue] = useState<
    string | number | boolean
  >();

  // hooks
  const { name, calculatedValue } = useScreenshotSettingContext();
  const form = Form.useFormInstance();

  // Handle the initial load of the form field value.
  useEffect(() => {
    const val = form.getFieldValue(name);

    if (type === "boolean") {
      const nextValue = val === undefined ? calculatedValue : val;
      setOwnedValue(nextValue);
    } else {
      if (calculatedValue && (!val || val === lastCalculatedValue)) {
        setOwnedValue(calculatedValue);
      }
    }
    setLastCalculatedValue(calculatedValue);
  }, [name, calculatedValue]);

  // Handle the values changing to determine if its Calculated or not.
  useEffect(() => {
    const isCalculated =
      type === "boolean"
        ? ownedValue === undefined || calculatedValue === ownedValue
        : ownedValue?.toString() === calculatedValue?.toString();

    setIsCalculated(isCalculated);
  }, [calculatedValue, ownedValue, type]);

  // Handle external value changing, such as resetting to a preset value
  useEffect(() => {
    if (value) {
      setOwnedValue(value);
    }
  }, [value]);

  // event handlers
  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      setOwnedValue(value);

      onChange && onChange(value);
    },
    [calculatedValue, onChange]
  );

  const handleReset = useCallback(() => {
    setOwnedValue(calculatedValue);
    onChange && onChange(calculatedValue);
  }, [calculatedValue]);

  const handleBooleanChange = useCallback(
    (checked) => {
      setOwnedValue(checked);

      onChange && onChange(checked);
    },
    [onChange]
  );

  const hasCalculatedValue = !!calculatedValue;

  if (type === "boolean") {
    return (
      <Switch
        size="small"
        checked={ownedValue === undefined ? !!calculatedValue : !!ownedValue}
        onChange={handleBooleanChange}
      />
    );
  }

  return (
    <Wrapper>
      <InputWrapper
        disabled={disabled}
        step={step}
        min={min}
        max={max}
        type={type}
        hasCalculated={hasCalculatedValue}
        isCalculated={isCalculated}
        title={(ownedValue ?? "").toString()}
        value={ownedValue}
        onChange={handleChange}
        data-qa={`screenshot-setting-${name}`}
        data-testid={`screenshot-setting-${name}`}
      />
      {hasCalculatedValue && isCalculated && (
        <IconWrapper>
          <Icon path={mdiCalculator} size={1} />
        </IconWrapper>
      )}
      {hasCalculatedValue && !isCalculated && (
        <IconWrapper>
          <StyledIcon path={mdiUndo} size={1} onClick={handleReset} />
        </IconWrapper>
      )}
      {unit && <UnitLabel>{unit}</UnitLabel>}
    </Wrapper>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputWrapper = styled(({ hasCalculated, isCalculated, ...props }) => (
  <Input {...props} />
))`
  background-color: ${(props) =>
    props.hasCalculated ? (props.isCalculated ? "#d5d5d5" : "#ebf8ef") : "#fff"};
  border-color: ${(props) =>
    props.hasCalculated ? (props.isCalculated ? "#d5f1ef" : "#a2efb8") : "#d5d5d5"};
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .ant-input {
    padding-right: 45px !important;
    width: 100%;
  }
`;
const IconWrapper = styled.div`
  font-size: 1rem;
  position: absolute;
  right: 25px;
  user-select: none;
  align-items: center;
  display: flex;
`;

const UnitLabel = styled.div`
  font-size: 1rem;
  position: absolute;
  right: 5px;
  user-select: none;
`;

const StyledIcon = styled(Icon)`
  &:hover {
    color: var(--color-primary);
  }
`;
