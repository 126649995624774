import { memo } from "react";

import { useMapContext } from "components/map/hooks/useMapContext";

import "./HorizontalLoading.scss";

function HorizontalLoading() {
  const { isLoading } = useMapContext();

  return (
    <div style={{ position: "relative" }}>
      <div className={`slider ${isLoading ? "" : "hidden"}`}>
        <div className="line"></div>
        <div className="subline inc"></div>
        <div className="subline dec"></div>
      </div>
    </div>
  );
}

export default memo(HorizontalLoading);
