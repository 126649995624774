import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { GEO_CALCULATION_SERVICE_ADDRESS } from "../modals/constants";

export function useCalculateGeoModel() {
  return useMutation<string, AxiosError, string>(async (jobId: string) => {
    const response = await axios.post(
      `${GEO_CALCULATION_SERVICE_ADDRESS}/geo-calculation/calculate/job/${jobId}`
    );

    if (response.status !== 200) {
      throw new Error("Error creating access token");
    }

    return response.data;
  });
}
