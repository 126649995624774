import { useState } from "react";
import { useMutation as rqUseMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useQuery } from "@apollo/client";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import { ForecastActivityType, TYPE_WELLS } from "constants/settings.constants";
import { ICheckedForecast } from "store/features";
import {
  setCheckedForecasts,
  setCheckedGlobalTypeWells
} from "store/features/arps/arpsSlice";
import { RootState } from "store/rootReducer";

import { FOLDERS } from "api/userArps";
import { Rescat, ValNavFolderRequest, saveValNavFolder } from "api/userForecasts";
import { fetchTypeWellSegmentsAndLengths } from "api/valnavTypeWellLength";

import { ForecastFolder, UserArpsItem, WellData } from "models/UserArpsModel";

import { useSelectedProject } from "components/project/projects/hooks";

import { ArpsSegmentToSegmentDto as arpsSegmentToSegmentDto } from "../../arps/utils/arpsUtils";
import "../context/reducer/arpsReducer";
import { groupArpsSegmentsByProduct } from "../utils/groupArpsSegmentsByProduct";
import useForecastFolderFetcher from "./useForecastFolderFetcher";

interface ValNavTypeWellInfo {
  jobNumber: string;
  rescat: Rescat;
  typeWells: string[];
}

let arpsWasm: typeof import("wasm/arps") = undefined;
import("wasm/arps.js").then((x) => {
  arpsWasm = x;
});

export default function useValNavTypeWellUpdater(type: ForecastActivityType) {
  const dispatch = useDispatch();

  // Arps Selectors
  const checkedForecasts = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "checkedTypeWells" : "checkedForecasts"]
  );

  const forecastFolders = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "typeWellFolders" : "forecastFolders"]
  );

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  const { selectedProject: project } = useSelectedProject();
  const { getForecastFoldersFromFoldersData } = useForecastFolderFetcher(type);

  const [valnavError, setValnavError] = useState(null);

  const { data: foldersData, refetch } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId,
        type: type
      }
    },
    skip: !project?.projectId
  });

  const valnavFolderMutation = rqUseMutation(
    "vnfolder",
    (data: ValNavFolderRequest) => {
      return saveValNavFolder(data);
    },
    {
      onSuccess: async () => {
        await refetch();
      }
    }
  );

  function recheck(foldersData: ForecastFolder[], folderId: string, id: string) {
    const folders = foldersData.filter((f) => f.folderId === folderId);
    if (folders?.length !== 1) {
      return;
    }
    const folder = folders[0];
    const child = folder.children.filter((item) => item.id === id);
    if (!child) {
      return;
    }
    const index = checkedForecasts.findIndex((f) => f.id === id);
    if (index < 0) {
      return;
    }
    const newChecked = [...checkedForecasts];
    newChecked.splice(index, 1, child[0] as ICheckedForecast);

    dispatch(setCheckedGlobalTypeWells(newChecked));
    //checkedforecast is immutable here because dispatch above
    dispatch(setCheckedForecasts({ type: type, checkedForecasts: newChecked }));
  }

  async function updateFolderWithValNavData(
    folder: ForecastFolder,
    valNavTypeWells: ValNavTypeWellInfo,
    modifyingSelectedTypeWell: boolean = false
  ): Promise<boolean> {
    if (
      !IS_INTERNAL_ENV ||
      type !== TYPE_WELLS ||
      !valNavTypeWells ||
      !valNavTypeWells.jobNumber
    ) {
      return false;
    }
    const rescat = valNavTypeWells.rescat ?? (folder.reserveCategory as Rescat);
    const result = await fetchTypeWellSegmentsAndLengths(
      valNavTypeWells.jobNumber,
      rescat,
      valNavTypeWells.typeWells
    );
    const { lengths, segments, constants } = result;

    const manuallyOverrideDi = [];
    if (arpsWasm) {
      for (const key of Object.keys(segments)) {
        const arpsGroupedByProduct = groupArpsSegmentsByProduct(segments[key]);
        Object.keys(arpsGroupedByProduct).forEach((product, idx) => {
          const productSegments = arpsGroupedByProduct[product];
          const segmentDtos = arpsSegmentToSegmentDto(arpsWasm, productSegments);
          const result = arpsWasm.calculateDi(segmentDtos);
          if (result.length == productSegments.length) {
            for (let i = 0; i < result.length; i++) {
              const currentDi = productSegments[i].di;
              const calculatedDi = result[i].di;
              const diffDiPercent =
                Math.abs(Math.abs(currentDi - calculatedDi) / currentDi) * 100.0;
              if (diffDiPercent > 1) {
                segments[key][i + idx].di = result[i].di;
                manuallyOverrideDi.push(
                  `${key}: ${currentDi.toFixed(8)} -> ${calculatedDi.toFixed(8)}`
                );
              }
            }
          }
        });
      }
      //let user know that Di has been recalculated
      if (manuallyOverrideDi.length > 0) {
        toast.warning(
          <div>
            Detected Di that differs from the database: <br />
            {manuallyOverrideDi.join(", ")}
          </div>
        );
      }
    }

    const folderName = folder.folderName;
    const foundFolder = foldersData.folders.filter((f) => f.name === folderName);
    const typeWells = (valNavTypeWells?.typeWells ?? []).map((tw) => tw);
    const wellData: { [id: string]: WellData } = {};
    const twWellsMap = {};
    for (const tw of typeWells) {
      twWellsMap[tw] = tw;
      if (lengths[tw] > 0) {
        wellData[tw] = {
          HzLength: lengths[tw],
          Proppant: null,
          Stage: null
        } as WellData;
      }
    }
    if (foundFolder.length > 0) {
      for (const fc of foundFolder[0].forecasts) {
        if (!wellData[fc.id]) {
          wellData[fc.id] = fc.wellData;
        }
      }
    }

    // When modifying the selected type well, send over old type well to make sure we update the existing and
    // not create new type well.
    const oldTypeWells =
      modifyingSelectedTypeWell &&
      selectedTypeWell &&
      typeWells.length &&
      selectedTypeWell.title !== typeWells[0]
        ? [selectedTypeWell.title]
        : [];

    const request: ValNavFolderRequest = {
      folderId: folderName,
      projectId: project?.projectId,
      oldForecasts: oldTypeWells,
      forecasts: typeWells,
      segments,
      constants,
      wellData,
      reserveCategory: rescat,
      source: valNavTypeWells.jobNumber
    };
    await valnavFolderMutation.mutateAsync(request);
    return true;
  }

  async function onUpdateValNavTypeWell(fcstTwNode?: UserArpsItem) {
    try {
      const node = fcstTwNode ?? selectedTypeWell;
      const folder = forecastFolders.filter((f) => f.folderId === node.folderId);
      if (!folder || folder.length === 0 || !node.source) {
        return;
      }
      const updateOk = await updateFolderWithValNavData(folder[0], {
        jobNumber: node.source,
        rescat: node.reserveCategory as Rescat,
        typeWells: [node.title]
      });
      if (!updateOk) {
        setValnavError("Unable to refresh type well with valnav.");
        return;
      }
      //update forecast folders
      const result = await refetch({
        req: {
          projectId: project?.projectId,
          type: type
        }
      });

      if (!result?.data) {
        setValnavError("Unable to refresh type well with valnav.");
        return;
      }
      const foldersData = getForecastFoldersFromFoldersData(result?.data, type);
      recheck(foldersData, node.folderId, node.id);
    } catch (err) {
      setValnavError(err?.data ?? "An error occurred.");
    }
  }

  return {
    onUpdateValNavTypeWell,
    updateFolderWithValNavData,
    valnavError,
    setValnavError
  };
}
