import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { fetchRawMapEntities } from "api/map";

import { TxnT } from "models";

/**
 * Simple `hook` that abstracts API call to fetch mapEntities
 */
const useMapEntitiesQuery = () => {
  const txnId: TxnT = useSelector((state: RootState) => state.map.txnId);
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);
  const uwiList = Object.keys(selectedWells) || [];
  // useQuery params
  // txnId/uwiList added to queryKey will make sure query is executed again on changes, as long as enabled resolves to true.
  const queryKey = ["mapEntities", txnId?.id, ...uwiList];
  const queryFn = () => fetchRawMapEntities(txnId?.id, uwiList);

  // query fires based on txnId/uwi expression
  const { data, refetch, isError } = useQuery(queryKey, queryFn, {
    enabled: false //disable, manually refresh when txnid or selected wells change
  });

  useEffect(() => {
    refetch();
  }, [txnId, selectedWells, refetch]);

  return {
    mapEntities: data,
    refetchMapEntities: refetch,
    mapEntitiesError: isError
  };
};

export default useMapEntitiesQuery;
