import { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";
import { Subject } from "rxjs";

import WorkspaceWrapper from "../WorkspaceWrapper";
import {
  UpdateActiveDashboardId,
  UpdateActivityBarResizingState,
  UpdateActivityBarWidth,
  UpdateErrorState,
  UpdateLoadingState,
  UpdateWorkspaceId,
  UpdateWorkspacePermissions,
  UpdateWorkspaceState,
  WorkspaceContextState
} from "./WorkspaceContextState";

export const initialWorkspaceContextState: WorkspaceContextState = {
  activityBarWidth: 420,
  isActivityBarResizing: false,
  isLoading: false,
  workspaceRefreshObserver: new Subject()
};
export const WorkspaceContext = createContext(initialWorkspaceContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const WorkspaceDispatchContext =
  createContext<React.Dispatch<Action<WorkspaceUpdates, "update">>>(undefined);

export type WorkspaceUpdates =
  | UpdateLoadingState
  | UpdateErrorState
  | UpdateWorkspaceId
  | UpdateWorkspaceState
  | UpdateActiveDashboardId
  | UpdateActivityBarWidth
  | UpdateWorkspacePermissions
  | UpdateActivityBarResizingState;

function WorkspaceReducer(
  state: WorkspaceContextState,
  action: Action<WorkspaceUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface WorkspaceProviderModel {
  children: ReactNode;
  state?: WorkspaceContextState;
  useWrapper?: boolean;
}

export function WorkspaceProvider({
  children,
  state: overrideState,
  useWrapper
}: WorkspaceProviderModel) {
  const [state, dispatch] = useReducer(
    WorkspaceReducer,
    overrideState ?? initialWorkspaceContextState
  );

  if (useWrapper === false) {
    return (
      <WorkspaceContext.Provider value={state}>
        <WorkspaceDispatchContext.Provider value={dispatch}>
          {children}
        </WorkspaceDispatchContext.Provider>
      </WorkspaceContext.Provider>
    );
  }

  return (
    <WorkspaceContext.Provider value={state}>
      <WorkspaceDispatchContext.Provider value={dispatch}>
        <WorkspaceWrapper>{children}</WorkspaceWrapper>
      </WorkspaceDispatchContext.Provider>
    </WorkspaceContext.Provider>
  );
}
