import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { Table } from "antd";
import { format, getYear, parseISO } from "date-fns";
import { RootState } from "store/rootReducer";

import { getUsersInOrg } from "api/users";

import { BaseButton, BaseDropdown, Heading } from "components/base";

import "./OrganizationBillingHistory.scss";

const year_options = ["", 2023, 2022, 2021, 2020, 2019, 2018];

const amountFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

function InvoicesTable({ invoices, yearFilter }) {
  const filterByYear = (invoice) => {
    if (!yearFilter) return true;
    const parsedDate = parseISO(invoice.created);
    return getYear(parsedDate) == yearFilter;
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "number",
      width: "30%",
      sorter: (a, b) => Number(a.number) - Number(b.number)
    },
    {
      title: "Invoice Amount",
      dataIndex: "amount",
      width: "30%",
      render: (text) => <span>{amountFormatter.format(text)}</span>,
      sorter: (a, b) => a.amount - b.amount
    },
    {
      title: "Invoice Date",
      dataIndex: "created",
      width: "30%",
      render: (text) => {
        return <span className="invoice-date">{format(parseISO(text), "MMMM y")}</span>;
      },
      filteredValue: [yearFilter],
      onFilter: (value, record) => filterByYear(record),
      sorter: (a, b) => a.created.localeCompare(b.created),
      defaultSortOrder: "ascend" as const
    },
    {
      title: "",
      dataIndex: "viewPdf",
      width: "10%",
      render: () => {
        return <BaseButton appearance="subtle">View PDF</BaseButton>;
      }
    }
  ];

  return (
    <div className="table-container">
      <Table
        columns={columns}
        sortDirections={["ascend", "descend", "ascend"]}
        dataSource={invoices}
        pagination={{
          pageSize: 24,
          showSizeChanger: false,
          hideOnSinglePage: true
        }}
        size="small"
      />
    </div>
  );
}

export default function OrganizationBillingHistory() {
  const orgId = useSelector((state: RootState) => state.auth.user.organization.id);
  const [, setUsers] = useState([]);
  const [yearFilter, setYearFilter] = useState(null);

  const updateUsers = useCallback(async () => {
    const users = await getUsersInOrg(orgId);
    setUsers(users);
  }, [orgId]);

  useEffect(() => {
    //
  }, [orgId, updateUsers]);

  const tableData = [];

  return (
    <div className="OrganizationBilling">
      <div className="organizationAccountHeader flex-row justify-between">
        <Heading element="h4">Billing History</Heading>
        <BaseDropdown
          value={yearFilter}
          inlineLabel={"Filter by Year: "}
          options={year_options}
          onChange={setYearFilter}
        />
      </div>

      <InvoicesTable invoices={tableData} yearFilter={yearFilter} />

      <ReactTooltip />
    </div>
  );
}
