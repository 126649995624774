import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { IGroupByListItem } from "models";

export default function useCategories(): {
  categories: IGroupByListItem[];
  facilityCategories: IGroupByListItem[];
} {
  // Get category properties for column and header
  const categories = useSelector((state: RootState) => state.groupBy.categoryList);
  const facilityCategories = useSelector(
    (state: RootState) => state.groupBy.facilityFields
  );

  return {
    categories,
    facilityCategories
  };
}
