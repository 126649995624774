import { useEffect, useState } from "react";

function useLocalStorage<T>(key: string, initialValue: T) {
  const getStoredValue = () => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      // console.warn(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  };

  // Being this is useState, not really reliable if you're referring to this in multiple components,
  // as new instances will be created and unmanaged. Best to rely on getStoredValue.
  // It may be possible to create/assign an event listener to the browser, see file history, last attempt didn't work out.
  const [storedValue, setValue] = useState<T>(getStoredValue());

  const appendToStoredValue = (value: T) => {
    const prevStoredValue = getStoredValue();
    const newStoredValue = { ...prevStoredValue, ...value };
    setValue(newStoredValue);
  };

  const setStoredValue = (value: T) => {
    setValue({ ...value });
  };

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  return {
    storedValue,
    appendToStoredValue,
    setStoredValue,
    getStoredValue
  } as const;
}

export default useLocalStorage;
