// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiLayersRemove } from "@mdi/js";
import { Popconfirm } from "antd";

import { ShapefileAction } from "../..";

interface IShapefileRemoveActionProps {
  isDisabled?: boolean;
  removeShapefile: () => void;
}

export function ShapefileRemoveAction({
  isDisabled,
  removeShapefile
}: IShapefileRemoveActionProps) {
  return (
    <Popconfirm
      disabled={isDisabled}
      placement="bottom"
      onConfirm={(evt) => {
        removeShapefile();
        evt.stopPropagation();
        evt.preventDefault();
      }}
      onCancel={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
      okText="Remove"
      okType="danger"
      title={`Are you sure you want to remove this shapefile from your project?`}>
      <ShapefileAction disabled={isDisabled} danger>
        <Icon path={mdiLayersRemove} size={1} />
        Remove
      </ShapefileAction>
    </Popconfirm>
  );
}
