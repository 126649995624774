import { ParentDimensionsT } from "components/chart/legend/Legend";
import { useScreenshotContext } from "components/screenshot/hooks";

import { useIpdbContext } from "../contexts/IpdbContext";
import { IPDBLegendBase } from "../shared/IPDBLegendBase";

export type IpdbLegendT = {
  height?: number;
  width?: number;
  parentDimensions: ParentDimensionsT;
  ipdbFontSize?: number;
  backgroundOpacity?: number;
  showLegendBorder?: boolean;
  location: "map" | "xda";
};

export const IpdbLegend: React.FC<IpdbLegendT> = ({
  width = 180,
  height = 250,
  parentDimensions = undefined,
  ipdbFontSize = undefined,
  backgroundOpacity = undefined,
  showLegendBorder = undefined,
  location = "map"
}) => {
  // Hooks
  const { widget } = useScreenshotContext();
  const { showIpdb } = useIpdbContext();
  return (
    <IPDBLegendBase
      width={width}
      height={height}
      parentDimensions={parentDimensions}
      dimensionsStorageKey={
        location === "map" ? `map-ipdb-legend-dimensions` : `xda-ipdb-legend-dimensions`
      }
      positionStorageKey={
        location === "map" ? `map-ipdb-legend-position` : `xda-ipdb-legend-position`
      }
      parentDimensionsStorageKey={
        location === "map" ? `map-ipdb-parent-dimensions` : `xda-ipdb-parent-dimensions`
      }
      showLegend={location === "map" ? showIpdb && !widget : true}
      ipdbTitleSize={Number(ipdbFontSize) + 1}
      ipdbLabelsSize={ipdbFontSize}
      backgroundOpacity={backgroundOpacity}
      showLegendBorder={showLegendBorder}
    />
  );
};
