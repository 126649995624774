import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { updateUserDefinedColumns } from "store/features";

import { deleteGeomBins } from "api/data";

import { useGroupByState, useGroupByUpdater } from "../group-by.context";

const useMutationGeomBinDelete = (ids) => {
  const dispatch = useDispatch();
  const { checkedFields } = useGroupByState();
  const stateDispatch = useGroupByUpdater();
  const { isLoading, mutate } = useMutation(
    () => {
      return deleteGeomBins(ids);
    },
    {
      onSuccess: () => {
        toast.success("Polygon bin was deleted.");
        dispatch(updateUserDefinedColumns());
        stateDispatch({
          type: "checked_fields",
          payload: checkedFields.filter((checkedItem) => !ids.includes(checkedItem))
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (err.response?.data) {
          toast.error(`${err?.response.data}`);
        } else {
          toast.error(
            "We're sorry a network error occurred. The bin could not be deleted."
          );
        }
      }
    }
  );

  return { isLoading, mutate };
};

export default useMutationGeomBinDelete;
