import { useQuery } from "react-query";

import { ApiResult } from "../api/apiResult";
import { IpdbField, fetchPlayZoneFields } from "../api/ipdp";

///hook to fetch ipdb fields
export default function usePlayZoneFields(
  modelSource: string,
  zones: string[]
): ApiResult<IpdbField[]> {
  const { data, isLoading, isError, refetch } = useQuery<IpdbField[]>(
    ["fetchPlayZoneFields", modelSource, zones],
    () => fetchPlayZoneFields(modelSource, zones),
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
