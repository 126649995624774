import React, { createContext, useContext, useMemo, useReducer } from "react";

import { IS_INTERNAL_ENV } from "constants/app.constants";
import { scaleByOptions } from "constants/visualization.constants";
// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import {
  Action,
  Dispatch,
  State,
  VisProviderT,
  XDASettingsT,
  XDA_RADIUS_TYPES
} from "./types";

const VisContext = createContext<[State, React.Dispatch<Action>]>(undefined);
VisContext.displayName = "VisContext";

const initialState: State = {
  xda: {
    settings: {
      bin: null,
      completionLength: 500,
      dataFields: [],
      downHeight: 0,
      leftWidth: 0,
      upHeight: 0,
      rightWidth: 0,
      hangWellsToTop: true,
      isUsingResourcePlayDefault: false,
      ipdbColor: "rainbow",
      ipdbField: "Porosity",
      ipdbUnit: "(%)",
      ipdbIsDefault: true,
      ipdbSource: "McDaniel",
      ipdbFontSize: 14,
      ipdbOpacity: 1,
      reverseColor: false,
      topsModelSource: ["McDaniel"],
      scaleByOption: scaleByOptions[0],
      scaleByValue: 1000,
      selectedPlays: [],
      showCompletion: false,
      showData: false,
      showGrid: false,
      showScreenshot: false,
      showIpdb: false, //show 3D geo model
      showIpdbLegend: IS_INTERNAL_ENV,
      showMeasurement: false,
      showTooltip: true,
      showXdaValueInfo: false,
      showAllTops: false,
      showOverlap: false,
      showRelativeDepth: false,
      showTVDSSDepth: false,
      selectedRadius: XDA_RADIUS_TYPES.Gross,
      widthScaled: 1
    }
  }
};

const visReducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "update XDA settings":
      draft.xda.settings = action.payload;
      break;
    default:
      throw new Error("invalid vis action");
  }
};

const curriedReducer = produce(visReducer);

// provider
const VisProvider = ({ children }: VisProviderT) => {
  const [state, dispatch] = useReducer(curriedReducer, initialState);
  const value: [State, Dispatch] = useMemo(() => [state, dispatch], [state]);

  return <VisContext.Provider value={value}>{children}</VisContext.Provider>;
};

// consumer hook
const useVisState = () => {
  const context = useContext(VisContext);
  if (context === undefined) {
    throw new Error("useVisState must be used within VisProvider");
  }
  return context;
};

// module functions
const updateXDASettings = (dispatch: Dispatch, value: XDASettingsT) =>
  dispatch({ type: "update XDA settings", payload: value });

export { updateXDASettings, VisProvider, useVisState };
