import { toast } from "react-toastify";

import { ErrorOutline } from "@material-ui/icons";

import { deleteDataSource } from "api/dataSource";

import { BaseButton } from "components/base";
import BaseModal from "components/base/BaseModal";

export default function RemoveDataSourceModal({
  open,
  onClose,
  orgId,
  source,
  sourceName
}): JSX.Element {
  async function removeDataSource() {
    const response = await deleteDataSource(orgId, source);
    if (response.ok) {
      toast.success("Data source removed successfully.");
      onClose && onClose(response);
    } else {
      toast.error("Error removing data source credentials.");
    }
  }

  return (
    <BaseModal
      size="xs"
      title={
        <div className="flex-row items-center">
          <ErrorOutline style={{ fontSize: "40px" }} />
          Remove {sourceName} Data Source
        </div>
      }
      open={open}
      onClose={() => onClose()}
      className="remove-data-source-dialog">
      <div className="flex-column container">
        <div className="flex-column">
          <p>Are you sure you want to delete this data source?</p>
        </div>
      </div>
      <div className="flex-row justify-center">
        <BaseButton appearance="danger" onClick={removeDataSource}>
          Remove Data Source
        </BaseButton>
      </div>
    </BaseModal>
  );
}
