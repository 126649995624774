import { useQuery } from "react-query";

import { ApiResult } from "../api/apiResult";
import { fetchPlayTo3DDataSources } from "../api/ipdp";

///hook to fetch ipdb fields
export default function usePlayTo3DDataSources(): ApiResult<string[]> {
  const { data, isLoading, isError, refetch } = useQuery<string[]>(
    "fetchPlayTo3DDataSources",
    fetchPlayTo3DDataSources,
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
