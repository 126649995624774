import { useContext } from "react";

import { DashboardDispatchContext } from "../contexts/DashboardContext";

export function useDashboardDispatch() {
  const context = useContext(DashboardDispatchContext);
  if (context === undefined) {
    throw new Error("useDashboardDispatch must be used within a Dashboard Provider");
  }
  return context;
}
