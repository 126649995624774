import { useMutation } from "react-query";

import { CreateColumnSetRequest, createColumnSet } from "api/column";

export default function useCreateColumnSetMutation(onSuccess, onError) {
  return useMutation(
    async (request: CreateColumnSetRequest) => await createColumnSet(request),
    {
      onSuccess: onSuccess,
      onError: onError
    }
  );
}
