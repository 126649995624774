import { PREDICATE_PROPERTY_OPERATOR, PREDICATE_TYPE } from "constants/filter.constants";
import { uid } from "utils";

import { IPredicateModel, IQuery } from "models/filter";

/**
 * Get the predicate model representation of a given query.
 *
 * @param {IQuery} query the query to get the predicate models for.
 * @param {[]} counts The list of counts for the predicates.
 * @returns {IPredicateModel} The predicate model representation of the query.
 */
const queryToPredicates = (query: IQuery, counts: []): IPredicateModel => {
  if (!query) return;

  const predicateChildren = query.predicates
    .filter((pred) => pred && pred.type)
    .reduce((result, pred) => {
      let title = "Polygon";
      let displayTitle = "";
      const op =
        pred.operator === PREDICATE_PROPERTY_OPERATOR.In
          ? PREDICATE_PROPERTY_OPERATOR.Equals
          : pred.operator === PREDICATE_PROPERTY_OPERATOR.NotIn
          ? PREDICATE_PROPERTY_OPERATOR.NotEqual
          : pred.operator;

      if (pred.type === PREDICATE_TYPE.Property) {
        if (pred.property === undefined || pred.value === undefined) {
          // skip predicate as its missing the property value.
          return result;
        }
        // TODO: REMOVE PROPERTY NAME SPLITTING
        const splitted = pred.property.split(".");
        title = splitted[splitted.length - 1];
        if (pred.value[0] === "" || pred.value === "") {
          title = `${title} ${op} n/a`;
          displayTitle = ` ${op} n/a`;
        } else {
          title = `${title} ${op} ${pred.value}`;
          if (Array.isArray(pred.value)) {
            const formattedPredValue = pred.value.join(" - ");
            displayTitle = `${op} ${pred.sourceKey ?? formattedPredValue}`;
          }
        }
      } else if (pred.type === PREDICATE_TYPE.Polygon) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (pred.source && pred.source === "selection") {
          title = `Polygon ${pred.operator} Selection`;
        } else {
          title = "Polygon = Map View";
        }
        displayTitle = title;
      } else if (pred.type === PREDICATE_TYPE.WellList) {
        title = `Well List ${op} ${pred.value.length} wells`;
        displayTitle = `Well List`;
      }

      const id = pred.id;
      let count = 0;
      if (id in counts) {
        count = counts[id];
      }
      if (pred.type in counts) {
        //for polygon/well list counts
        count = counts[pred.type];
      }
      const child: IPredicateModel = {
        id,
        title,
        displayTitle,
        name: title,
        expanded: true,
        operator: pred.operator,
        children: [],
        count,
        type: "",
        isChecked: false,
        actualValue: pred
      };

      result.push(child);
      return result;
    }, []);

  const children: IPredicateModel[] = query.children_nodes.map((node) =>
    queryToPredicates(node, counts)
  );
  let branchCount = 0;

  const allChildren = predicateChildren.concat(children);

  let countsKey = "Active";
  let formattedRootName = countsKey;
  if (query?.predicates?.length > 0 && query?.predicates[0]?.property) {
    // TODO: REMOVE PROPERTY NAME SPLITTING
    const splitted = query.predicates[0].property.split(".");
    countsKey = query.predicates[0].property;
    const rootSplit = splitted[splitted.length - 1].split("_");

    // rootSplit.length === 6 mean we're processing geom bin filter
    const propIndex = rootSplit.length === 6 ? 3 : 0;
    const unitIndex = rootSplit.length === 6 ? 5 : 1;

    const formattedProp = rootSplit[propIndex]
      .split(/(?=[A-Z][a-z])/)
      .join(" ")
      .replace("Geom", "Polygon");
    const formattedUnit = rootSplit.length > unitIndex ? `(${rootSplit[unitIndex]})` : "";

    formattedRootName = `${formattedProp} ${formattedUnit}`;
    if (splitted.length === 3) {
      const propertyName = splitted[1];
      formattedRootName = ` ${propertyName}: ${formattedRootName}`;
    }
    if (query.predicates[0].partialContextTitle) {
      formattedRootName = query.predicates[0].partialContextTitle;
    }
  }
  if (countsKey in counts) {
    branchCount = counts[countsKey];
  }
  const root: IPredicateModel = {
    id: uid(),
    title: query.operator,
    displayTitle: "",
    name: formattedRootName,
    expanded: true,
    count: branchCount,
    operator: query.operator,
    children: [],
    type: "",
    isChecked: false,
    actualValue: query
  };
  root.children = allChildren;
  return root;
};

export default queryToPredicates;
