import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { updateUser } from "store/features";

import { updateMemberProfile } from "api/users";

import { IUser } from "models/User";

import { BaseButton, BaseDropdown, BaseInput } from "components/base";
import AvatarField from "components/base/AvatarField";

interface IFormValues {
  firstName: string;
  lastName: string;
  jobTitle: string;
}

export interface IAccountProfileProps {
  user: IUser;
}

const requiredKeys = ["firstName", "lastName"];

function formatKey(key) {
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
}

function validateString(key, value) {
  let error = null;
  if (!value.length) {
    error = `${formatKey(key)} cannot be blank`;
  }
  return error;
}

export default function AccountProfile(props: IAccountProfileProps) {
  const [errors, setErrors] = useState({ firstName: null, lastName: null });
  const [formValues, setFormValues] = useState<IFormValues>({
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    jobTitle: props.user.jobTitle || ""
  });
  const hasErrors = Object.keys(errors).some((key) => errors[key]);
  const [savingProfile, setSavingProfile] = useState(false);

  const dispatch = useDispatch();

  function validateFields() {
    let currErrors = false;

    Object.keys(formValues).forEach((key) => {
      if (requiredKeys.includes(key) && !formValues[key].length) {
        handleChange(key, formValues[key]);
        currErrors = true;
      }
    });

    return currErrors;
  }

  function handleChange(name: string, value: string) {
    switch (name) {
      case "firstName":
      case "lastName":
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: validateString(name, value) });
        break;
      case "jobTitle":
        setFormValues({ ...formValues, [name]: value });
        break;
    }
  }

  const saveProfile = useMutation(
    async () => {
      return await updateMemberProfile({
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        jobTitle: formValues.jobTitle,
        id: props.user.id
      });
    },
    {
      onSuccess: (data) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedUser = data as any;
        dispatch(
          updateUser({
            ...props.user,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            jobTitle: updatedUser.jobTitle
          })
        );
        toast.success("Successfully updated profile.");
      },
      onError: () => {
        toast.error("Error updating profile.");
      },
      onSettled: () => {
        setSavingProfile(false);
      }
    }
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const currErrors = validateFields();
      if (currErrors) return;
      setSavingProfile(true);
      saveProfile.mutate();
    },
    [formValues.firstName, formValues.lastName, formValues.jobTitle]
  );

  return (
    <>
      <form className="profileForm" onSubmit={handleSubmit}>
        <AvatarField user={props.user} />
        <BaseInput
          errors={errors.firstName}
          id="firstName"
          label="Your first name"
          name="firstName"
          onChange={(value) => handleChange("firstName", value)}
          placeholder="First Name"
          value={formValues.firstName}
        />
        <BaseInput
          errors={errors.lastName}
          id="lastName"
          label="Your last name"
          name="lastName"
          onChange={(value) => handleChange("lastName", value)}
          placeholder="Last Name"
          value={formValues.lastName}
        />
        <BaseInput
          id="jobTitle"
          label="Your job title"
          name="jobTitle"
          onChange={(value: string) => handleChange("jobTitle", value)}
          placeholder="Job Title"
          value={formValues.jobTitle}
        />
        <BaseDropdown
          inputLabel="Timezone"
          onChange={(value: string) => handleChange("Timezone", value)}
          options={["Mountain Time (US & Canada) (UTC-07:00)"]}
          value="Mountain Time (US & Canada) (UTC-07:00)"
        />
        <div className="button-container">
          <BaseButton
            appearance="primary"
            className="justify-right"
            isDisabled={hasErrors}
            isLoading={savingProfile}
            type="submit">
            Save Changes
          </BaseButton>
        </div>
      </form>
    </>
  );
}
