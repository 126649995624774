import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { ScreenshotPresetModel } from "models/model";

type UseScreenshotPresetReturnT = {
  presets: ScreenshotPresetModel[];
};

export function useScreenshotPresets(): UseScreenshotPresetReturnT {
  // state from store
  const presets = useSelector((state: RootState) => state.userSetting.screenshotPreset);

  return { presets };
}
