import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { setColorPalettes } from "store/features/userSettings/userSettingsSlice";

import { getOrganizationPalettes } from "api/userSettings";

import useUpdateColorPalette from "./useUpdateColorPalette";

export default function useRefreshColorPalettes() {
  const storeDispatch = useDispatch();
  const queryClient = useQueryClient();
  const updateMutation = useUpdateColorPalette();

  const refreshColorPalettes = async (selectedPalette) => {
    await updateMutation.mutateAsync(selectedPalette);
    let organizationPalettes = await getOrganizationPalettes();
    organizationPalettes = organizationPalettes.map((p) => ({
      ...p,
      reverse: false
    }));

    storeDispatch(setColorPalettes(organizationPalettes));
    queryClient.invalidateQueries("color-palette");
  };
  return {
    refreshColorPalettes,
    isSuccess: updateMutation.isSuccess,
    isError: updateMutation.isError,
    mutationError: updateMutation.error
  };
}
