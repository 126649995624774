import { UserDefaultsT } from "../context";
import useUserSettings from "./useUserSettings";

const useUserDefaults = (): UserDefaultsT => {
  const settings = useUserSettings();

  return settings?.defaults;
};

export default useUserDefaults;
