import { memo } from "react";

function CO2Injection() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30px"
      height="30px">
      <path
        d="M5.61892 0L5.61892 5.61892L0 5.61892"
        transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 12.008963 19.749456)"
        id="Rectangle-Copy"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path
        transform="translate(-2, -2.5) scale(1.3, 1.3)"
        fill="currentColor"
        d="M5,7A2,2 0 0,0 3,9V15A2,2 0 0,0 5,17H8V15H5V9H8V7H5M11,7A2,2 0 0,0 9,9V15A2,2 0 0,0 11,17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H11M11,9H13V15H11V9M16,10.5V12H19V13.5H17.5A1.5,1.5 0 0,0 16,15V18H20.5V16.5H17.5V15H19A1.5,1.5 0 0,0 20.5,13.5V12A1.5,1.5 0 0,0 19,10.5H16Z"
      />
    </svg>
  );
}

export default memo(CO2Injection);
