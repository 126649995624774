import { IGroupByListColumn } from "models";

import { MAX_SEARCH_RESULTS } from "../group-by.context";

/**
 * Returns an array of objects filtered objects,
 * whose title matches the given string
 */
const filterByText = (
  searchString: string,
  list: IGroupByListColumn[]
): IGroupByListColumn[] => {
  if (searchString.length === 0) {
    return list;
  }

  const lowerCaseString = searchString.toLowerCase();
  const result: IGroupByListColumn[] = [];

  for (const column of list) {
    if (column.title.toLowerCase().includes(lowerCaseString)) {
      result.push(column);
      if (result.length === MAX_SEARCH_RESULTS) {
        break;
      }
    }
  }

  return result;
};

export default filterByText;
