export function calculateDisplayUnit(
  unit: string,
  field: string,
  display: string
): string {
  let displayUnit = unit;
  if (field.startsWith("Performance")) {
    if (unit === "bbl") {
      display.endsWith("(Mbbl)") ? (displayUnit = "Mbbl") : (displayUnit = "bbl/d");
    } else if (unit === "Mcf") {
      display.endsWith("(MMcf)") ? (displayUnit = "MMcf") : (displayUnit = "Mcf/d");
    } else if (unit === "BOE") {
      display.endsWith("(MBOE)") ? (displayUnit = "MBOE") : (displayUnit = "BOE/d");
    }
  }
  return displayUnit;
}
