import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import {
  PROJECT_SHAPEFILES_QUERY_KEYS,
  SYSTEM_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

interface IPromoteToMcdanShapefileMutation {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
}

export async function promoteToMcdanShapefile(params) {
  return await axios.post(
    `${projectServiceEndpoint}/shapefile/promote/${params.shapefileId}`,
    {
      shapefilePlay: params.shapefilePlay
    }
  );
}

/**
 * Promotes a shapefile to a McDan/System shapefile
 * @param param0
 * @returns
 */
export function usePromoteToMcdanShapefileMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess
}: IPromoteToMcdanShapefileMutation) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ shapefileId, shapefilePlay }: { shapefileId: string; shapefilePlay: string }) =>
      promoteToMcdanShapefile({ shapefileId, shapefilePlay }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: [SYSTEM_SHAPEFILES_QUERY_KEYS.systemShapefiles]
          }),
          queryClient.invalidateQueries({
            queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles]
          })
        ]);

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
