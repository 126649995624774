import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

const SERVICE_ACCOUNT_TOKEN_ADDRESS = process.env.REACT_APP_SERVICE_ACCOUNT_SERVICE;

export function useDeleteToken() {
  const queryClient = useQueryClient();
  const deleteRecord = async (tokenId: string) => {
    // Here you would make your HTTP request or whatever your deletion logic needs to be
    const response = await axios.delete(`${SERVICE_ACCOUNT_TOKEN_ADDRESS}/${tokenId}`);

    if (response.status !== 202 && response.status !== 200) {
      throw new Error("Problem deleting record");
    }
  };
  return useMutation(deleteRecord, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("apiTokens");
    }
  });
}
