import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef } from "react";

import {
  DEFAULT_AXIS_FONT_SIZE,
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_LEGEND_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE
} from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import * as echarts from "hd-echarts/echarts";
import styled from "styled-components";
import { applyScreenshotSettings } from "utils";

import { IChartScreenshotSettings } from "models/chart";
import { IScreenshotAbbreviation } from "models/screenshot";

import { BusyIndicator } from "components/activity/shared";
import { useChartState } from "components/chart";
import IconSpinner from "components/icons/IconSpinner";

import { useInjectionWellPairsQuery } from "./hooks";

export interface InjectionWellPairsModel {
  id: string;
  wellList: string[];
  setting: IChartScreenshotSettings;
  abbreviations: IScreenshotAbbreviation[];
}
export default function InjectionWellPairs({
  id,
  wellList,
  setting,
  abbreviations
}: InjectionWellPairsModel) {
  const chartRef = useRef(null);

  const containerRef = useRef(null);
  const { chartData, updateData, isFetching } = useInjectionWellPairsQuery(wellList);
  const { screenshot } = useChartState();

  useEffect(() => {
    updateData();
  }, [updateData, wellList]);

  useEffect(() => {
    const doc = document.getElementById(id);
    chartRef.current = echarts.init(doc);
  }, [id]);

  function getColor(label): string {
    switch (label) {
      case "Bitumen":
        return "rgb(104, 150, 132)";
      case "WellPairs":
        return "black";
      case "SteamInjection":
        return "rgb(97, 137, 171)";
      case "GasInjection":
        return "rgb(205, 148, 141)";
      case "GasProduction":
        return "rgb(152, 72, 86)";
      // commented out because of
      // https://turinganalytics.atlassian.net/browse/EVA-2468
      // case "Pressure":
      //   return "rgb(227, 136, 53)";
    }
  }

  useEffect(() => {
    if (!chartRef.current || !chartData) {
      return;
    }

    const axisFontSize = 12;
    const axisLabelFontSize = 12;
    const fontFamily = THEME_FONT_FAMILY;

    const option = {
      title: {
        fontFamily: fontFamily,
        text: ""
      },
      grid: {
        left: 80 + +axisFontSize * 1.5 + +axisLabelFontSize,
        bottom: 70,
        top: 10,
        right: 80
      },
      tooltip: {
        trigger: "axis",
        confine: true,
        enterable: true,
        className: "chart-tooltip-container",
        extraCssText: "border-style:none;",
        valueFormatter: (value) => Number(value.toFixed(2)).toLocaleString("en"),

        axisPointer: {
          type: "cross",
          label: {
            show: true,
            precision: "2"
          }
        }
      },
      legend: {
        top: "bottom",
        show: true,
        textStyle: {
          fontFamily: fontFamily,
          fontSize: 12
        }
      },
      xAxis: {
        type: "time",
        triggerEvent: true,
        name: "Consecutive Months on Stream",
        id: "xAxis",
        nameLocation: "middle",
        data: null,
        max: null,
        nameGap: 30,
        useY: false,
        axisLabel: {
          color: "#5B6770",
          fontFamily: fontFamily,
          fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,
          padding: [5, 0, 0, 2]
        },
        nameTextStyle: {
          color: "#5B6770",
          fontFamily: fontFamily,
          fontSize: axisFontSize
        }
      },
      dataZoom: [
        {
          show: true,
          type: "inside",
          filterMode: "none",
          xAxisIndex: [0]
        },
        {
          show: true,
          type: "inside",
          filterMode: "none",
          yAxisIndex: [0]
        }
      ],
      yAxis: [
        {
          id: "yAxis",
          min: 0,
          max: null,
          type: "value",
          name: "Prod/Inj Rate (bbl/d), Gas (Mcf/d)",
          nameLocation: "middle",
          nameGap: 65 + axisLabelFontSize * 1.5,

          axisLine: {
            onZero: false,
            lineStyle: {
              color: "rgb(155,155,155)",
              width: 1
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgb(217,225,226)"
            }
          },
          axisTick: {
            lineStyle: {
              width: 1
            }
          },
          axisLabel: {
            fontWeight: 500,
            color: "#5B6770",
            padding: [0, 5, 0, 0],
            fontFamily: fontFamily,
            fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,

            margin: 8
          },
          yAxisScaleToX: 1000,
          nameTextStyle: {
            color: "#5B6770",
            fontFamily: fontFamily,
            fontSize: axisFontSize
          }
        },
        {
          id: "yAxis2",
          triggerEvent: true,
          min: 0,
          type: "value",
          name: "Well Pairs on Stream",
          nameLocation: "middle",
          nameGap: 30,
          splitLine: null,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "rgb(155,155,155)",
              width: 1
            }
          },
          axisTick: {
            lineStyle: {
              width: 1
            }
          },
          axisLabel: {
            fontWeight: 500,
            color: "#5B6770",
            padding: [0, 5, 0, 0],
            fontFamily: fontFamily,
            fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,
            margin: 8
          },
          nameTextStyle: {
            color: "#5B6770",
            fontFamily: fontFamily,
            fontSize: axisFontSize
          },
          position: "right"
        }
        // commented out because of
        // https://turinganalytics.atlassian.net/browse/EVA-2468
        // {
        //   id: "yAxis3",
        //   triggerEvent: true,
        //   nameGap: 60,
        //   type: "value",
        //   name: "Pressure (kPa)",
        //   nameLocation: "middle",
        //   axisLine: {
        //     onZero: false,
        //     lineStyle: {
        //       color: "rgb(155,155,155)",
        //       width: 1
        //     }
        //   },
        //   splitLine: null,
        //   axisTick: {
        //     lineStyle: {
        //       width: 1
        //     }
        //   },
        //   axisLabel: {
        //     fontWeight: 500,
        //     color: "#5B6770",
        //     padding: [0, 5, 0, 0],
        //     fontSize: axisLabelFontSize > 0 ? axisLabelFontSize : 12,

        //     margin: 8
        //   },
        //   yAxisScaleToX: 1000,
        //   nameTextStyle: {
        //     color: "#5B6770",
        //     fontSize: axisFontSize
        //   }
        // }
      ],
      series: (chartData.series ?? []).map((cd) => {
        return {
          name: cd.label,
          type: "line",
          animation: false,
          yAxisIndex: cd.label === "WellPairs" ? 1 : 0,
          itemStyle: {
            color: getColor(cd.label)
          },
          zlevel: 0,
          blendMode: "source-over",
          large: true,
          largeThreshold: 2000,
          step: true,
          showSymbol: false,
          symbolSize: 4,
          lineStyle: {
            color: getColor(cd.label),
            width: cd.label === "ISOR" ? 0 : 3,
            type: "solid"
          },
          data: cd.x.map((v, i) => {
            return [new Date(cd.x[i].substring(0, 10)), cd.y[i]];
          })
        };
      })
    };
    const newOptions = screenshot?.visible
      ? applyScreenshotSettings(
          option,
          chartRef.current,
          {
            titleFontSize: setting?.titleFontSize ?? DEFAULT_TITLE_FONT_SIZE,
            axisFontSize: setting?.axisFontSize ?? DEFAULT_AXIS_FONT_SIZE,
            axisLabelFontSize: setting?.axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE,
            legendFontSize: setting?.legendFontSize ?? DEFAULT_LEGEND_FONT_SIZE,
            fontFamily: setting?.fontFamily ?? THEME_FONT_FAMILY,
            legendType: "bottom",
            legendVisible: setting?.legendVisible,
            applyAbbreviations: setting?.applyAbbreviations ?? false,
            xAxisTitleText: option?.xAxis.name
          },
          abbreviations
        )
      : option;
    chartRef.current.setOption(newOptions);
  }, [chartData, setting, screenshot?.visible]);

  useResizeObserver(containerRef.current, () => {
    if (!chartRef.current) {
      return;
    }
    chartRef.current.resize();
  });

  return (
    <Wrapper ref={containerRef}>
      <BusyIndicator
        className={`${!isFetching ? "hidden" : ""}`}
        onContextMenu={(e) => e.preventDefault()}>
        <IconSpinner showBackground />
      </BusyIndicator>
      <div id={id} className="four-products"></div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .four-products {
    width: 100%;
    height: 100%;
  }
`;
