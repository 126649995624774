import { useContext } from "react";

import { ScreenshotPresetDispatchContext } from "../contexts/ScreenshotPresetContext";

export function useScreenshotPresetDispatch() {
  const context = useContext(ScreenshotPresetDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useScreenshotPresetDispatch must be used within a Screenshot Preset Provider"
    );
  }
  return context;
}
