import { toast } from "react-toastify";

import { AddCircle, Delete, Error } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import ViewModule from "@material-ui/icons/ViewModule";
import { Button, Popconfirm, Popover, Tooltip, Typography } from "antd";
import styled from "styled-components/macro";

import useUser from "hooks/useUser";

import {
  initialChartSettingsState,
  useChartSettings
} from "components/multiphase-chart/context";
import * as reducerActions from "components/multiphase-chart/context/reducer";

import * as hooks from "../../../../hooks";
import { IChartPreset } from "../../../../models/shared.models";
import PresetsToggle from "./PresetsToggle";
import Settings from "./Tabs/Settings/Settings";

const { Text } = Typography;

const PresetsPopover = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const { isReadonly, isUser } = useUser();

  const { presets, refetchPresets } = hooks.useChartPresetFetcher();

  // Remove Preset Hook
  const onRemovePresetSuccess = (response) => {
    refetchPresets();
    reducerActions.onDeletePresetSuccess(chartSettingsDispatch, response.nextPreset);
    toast.success(`Successfully deleted preset.`);
  };

  const onRemovePresetError = (error) => {
    reducerActions.setError(
      chartSettingsDispatch,
      error?.response?.data ?? "An error occurred when deleting the preset."
    );
  };

  const { removePreset: removePresetHook } = hooks.useChartPresetRemover(
    onRemovePresetSuccess,
    onRemovePresetError
  );

  // Add Preset Hook
  //TODO: along with other hooks, seperate out with applicable components
  const onAddPresetSuccess = (response, oldPreset) => {
    refetchPresets();
    reducerActions.onAddPresetSuccess(
      chartSettingsDispatch,
      oldPreset.id ? oldPreset : response.newPreset
    );

    toast.success(`Successfully ${oldPreset.id ? "edited" : "created"} chart preset.`);
  };

  const onAddPresetError = (error) => {
    reducerActions.setError(
      chartSettingsDispatch,
      error?.response?.data ?? "An error occurred when creating chart preset"
    );
  };

  const { addPreset: addPresetHook } = hooks.useChartPresetAppender(
    onAddPresetSuccess,
    onAddPresetError
  );

  const isFormDirty = (chartPreset: IChartPreset) => {
    return JSON.stringify(chartPreset) === JSON.stringify(initialChartSettingsState);
  };

  const trySavePreset = () => {
    let errors = "";
    if (chartSettings.currentPreset.products.length === 0) errors += "No products set\n";
    if (!chartSettings.currentPreset.name?.trim()?.length) {
      errors += "Name required\n";
    }

    const currentPreset = chartSettings.currentPreset;
    const isNameUnique = currentPreset.id
      ? presets?.some(
          (preset: { name: string; id: string }) =>
            preset.name === currentPreset.name && preset.id !== currentPreset.id
        )
      : presets?.filter((a: { name: string }) => a.name === currentPreset.name).length >
        0;
    if (isNameUnique) {
      errors += "Name must be unique\n";
    }

    errors
      ? reducerActions.setError(chartSettingsDispatch, errors)
      : addPresetHook({
          ...currentPreset,
          chartType: currentPreset.chartType
        });
  };

  const hasReadOnlyPermissions =
    chartSettings.isReadOnly ||
    isReadonly ||
    (isUser && chartSettings.storedPreset.roleType === "Organization");

  return (
    <Popover
      trigger="click"
      placement="leftBottom"
      content={
        <>
          <Header>
            <HeaderText>Presets</HeaderText>
            {chartSettings.isViewMode ? (
              <TopActionsContainer>
                {!hasReadOnlyPermissions && (
                  <Tooltip placement="top" title="Edit Preset">
                    <ChartPresetActionButton
                      data-testid="edit-button"
                      data-qa="edit-button"
                      disabled={presets?.length === 0}
                      onClick={() => {
                        reducerActions.toggleIsViewMode(chartSettingsDispatch, false);
                      }}>
                      <Edit
                        fontSize="large"
                        color={presets?.length > 0 ? "inherit" : "disabled"}
                      />
                    </ChartPresetActionButton>
                  </Tooltip>
                )}

                {!chartSettings.isReadOnly && !isReadonly && (
                  <Tooltip placement="top" title="Add Preset">
                    <ChartPresetActionButton
                      data-testid="add-button"
                      data-qa="add-button"
                      onClick={() => {
                        reducerActions.addPreset(chartSettingsDispatch);
                      }}>
                      <AddCircle fontSize="large" />
                    </ChartPresetActionButton>
                  </Tooltip>
                )}

                {!hasReadOnlyPermissions && (
                  <Popconfirm
                    onConfirm={(evt) => {
                      removePresetHook(chartSettings.currentPreset);
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                    onCancel={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                    }}
                    okText="Delete"
                    okType="danger"
                    title={
                      <>
                        <div>{`Are you sure you want to delete chart preset "${chartSettings.currentPreset?.name}"?`}</div>
                        <i>
                          {chartSettings.currentPreset?.roleType === "Shared"
                            ? "This is a shared chart preset."
                            : ""}
                        </i>
                      </>
                    }>
                    <Tooltip placement="top" title="Delete Preset">
                      <ChartPresetActionButton
                        danger
                        data-testid="delete-button"
                        data-qa="delete-button"
                        disabled={presets?.length === 0}>
                        <Delete
                          fontSize="large"
                          color={presets?.length > 0 ? "inherit" : "disabled"}
                        />
                      </ChartPresetActionButton>
                    </Tooltip>
                  </Popconfirm>
                )}
              </TopActionsContainer>
            ) : isFormDirty(chartSettings.currentPreset) ? (
              <>
                <Popconfirm
                  onConfirm={(evt) => {
                    reducerActions.onLeavingDirtyForm(chartSettingsDispatch);
                    evt.stopPropagation();
                    evt.preventDefault();
                  }}
                  onCancel={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                  }}
                  okText="Leave without saving"
                  okType="danger"
                  title={<>{`You have unsaved changes that will be lost`}</>}>
                  <Actions>
                    <Tooltip placement="top" title="View Presets">
                      <ChartPresetActionButton data-testid="view-button">
                        <ViewModule fontSize="large" />
                      </ChartPresetActionButton>
                    </Tooltip>
                  </Actions>
                </Popconfirm>
              </>
            ) : (
              <Actions>
                <Tooltip placement="top" title="View Presets">
                  <ChartPresetActionButton
                    data-testid="view-button"
                    data-qa="view-button"
                    onClick={() => {
                      reducerActions.toggleIsViewMode(chartSettingsDispatch, true);
                    }}>
                    <ViewModule fontSize="large" />
                  </ChartPresetActionButton>
                </Tooltip>
              </Actions>
            )}
          </Header>
          <Settings savedPresets={presets} />
          {!!chartSettings.error &&
            chartSettings.error
              .split("\n")
              .filter((a) => a)
              .map((err) => (
                <ErrorContainer key={err}>
                  <Error style={{ top: 3 }} />
                  {err}
                </ErrorContainer>
              ))}
          {!chartSettings.isViewMode && (
            <BottomActionsContainer>
              <Actions>
                <Button
                  data-testid="cancel-button"
                  data-qa="cancel-button"
                  onClick={() => {
                    chartSettingsDispatch({
                      type: "toggle isSettingsOpen",
                      isSettingsOpen: false
                    });
                  }}>
                  Cancel
                </Button>
                <Button
                  data-testid="save-button"
                  data-qa="save-button"
                  type="primary"
                  disabled={false}
                  loading={false}
                  onClick={() => {
                    trySavePreset();
                  }}>
                  {"Save"}
                </Button>
              </Actions>
            </BottomActionsContainer>
          )}
        </>
      }
      onOpenChange={(isVisible) => {
        chartSettingsDispatch({
          type: "toggle isSettingsOpen",
          isSettingsOpen: isVisible
        });
      }}
      open={chartSettings.isSettingsOpen}>
      <PresetsToggle key="PresetsToggle" />
    </Popover>
  );
};

export default PresetsPopover;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const ChartPresetActionButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;
  &:hover {
    color: ${(props: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      danger: any;
    }) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const HeaderText = styled(Text)`
  font-weight: 700;
  text-transform: uppercase;
`;

const TopActionsContainer = styled.div`
  display: flex;
`;

const BottomActionsContainer = styled.div`
  padding-top: 1rem;
`;

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;
