import { memo } from "react";

import StraightenIcon from "@material-ui/icons/Straighten";

import { updateXDASettings, useVisState } from "../../context";
import XdaToolbarButton from "./XdaToolbarButton";

const XdaMeasurementToggle = () => {
  const [{ xda }, visDispatch] = useVisState();
  const { showMeasurement } = xda.settings;
  const handleToggle = () => (value) => {
    const nextSettings = { ...xda.settings, showMeasurement: value };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <XdaToolbarButton
      key={"FullscreenToggleButton" + showMeasurement}
      active={showMeasurement}
      icon={<StraightenIcon fontSize="large" />}
      tooltipText="Toggle Measurement Tool"
      overflowLabel={"Measurement"}
      onToggle={handleToggle()}
    />
  );
};

export default memo(XdaMeasurementToggle);
