import React from "react";

import styled from "styled-components/macro";

import { Tooltip } from "components/base";

export type ToggleButtonT = {
  children: React.ReactNode;
  tooltipText?: React.ReactNode | string;
  value: boolean;
  onClick: () => void;
  showBorder?: boolean;
  disabled?: boolean;
};

const ToggleButton = ({
  children,
  tooltipText = "",
  value,
  onClick,
  showBorder = false,
  disabled = false
}: ToggleButtonT) => {
  return (
    <Tooltip title={tooltipText}>
      <StyledButton
        isActive={value}
        onClick={onClick}
        showBorder={showBorder}
        disabled={disabled}>
        {children}
      </StyledButton>
    </Tooltip>
  );
};

export default ToggleButton;

const StyledButton = styled.button`
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: ${(props) => (props.showBorder ? "1px solid" : "none")};
  border-color: ${(props) => (props.isActive ? "var(--color-primary)" : "#d9d9d9")};
  border-radius: var(--border-radius);
  box-shadow: none;
  color: ${(props) =>
    props.disabled ? "#ddd" : props.isActive ? "var(--color-primary)" : "#a2aaad"};
  transition: color 0.15s;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.disabled ? "#ddd" : "var(--color-primary)")};
    border-color: var(--color-primary);
  }

  &[disabled]:hover > svg {
    cursor: default;
  }
`;
