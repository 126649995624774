import { useEffect, useState } from "react";

import { IGroupByListColumn } from "models";

import { EntityKind } from "../models/entityKind";
import useColumns, { useFacilityColumns } from "./useColumns";

export default function useColumnsDictionary(
  entityKind: EntityKind
): Record<string, IGroupByListColumn> {
  const [columnsDict, setColumnsDict] = useState<Record<string, IGroupByListColumn>>({});

  const columns = useColumns();
  const facilityColumns = useFacilityColumns();

  useEffect(() => {
    const newDict: Record<string, IGroupByListColumn> = {};
    for (const column of entityKind == EntityKind.Well ? columns : facilityColumns) {
      newDict[column.property] = column;
    }
    setColumnsDict(newDict);
  }, [columns, facilityColumns]);

  return columnsDict;
}
