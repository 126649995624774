import { useContext } from "react";

import { MapDispatchContext } from "../contexts/MapContext";

export function useMapDispatch() {
  const context = useContext(MapDispatchContext);
  if (context === undefined) {
    throw new Error("useMapDispatch must be used within a MapProvider");
  }
  return context;
}
