import styled from "styled-components";

export const TypeWellAction = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    color: ${(props) =>
      props.disabled
        ? "var(--color-text-50)"
        : props.danger
        ? "var(--color-danger)"
        : "var(--color-primary)"};
  }
`;
