import { useQuery } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { FieldTemplate } from "../models/mapLayer";

const API_URL = `${GEO_MAP_SERVICE_ADDRESS}/field-templates`;

export const GeoMapFieldTemplatesQueryKey = "geoMapFieldTemplates";
const fetchFieldTemplates = async () => {
  try {
    const response = await axios.get<FieldTemplate[]>(API_URL);
    return response.data ?? [];
  } catch (error) {
    // If the request fails, return an empty array
    return [];
  }
};

const useFetchFieldTemplates = () => {
  return useQuery<FieldTemplate[]>(GeoMapFieldTemplatesQueryKey, fetchFieldTemplates);
};

export default useFetchFieldTemplates;
