import { useEffect, useState } from "react";

import { LegendItemModel } from "models";

import { useChartState } from "../context";
import { createLegendItem, verifyChartTypeCanUseStats } from "../utils";

const useAverageLegendItem = (): LegendItemModel[] => {
  const { settings } = useChartState();
  const [items, setItems] = useState<LegendItemModel[]>([]);

  const isAverageEnabled = Boolean(settings.average);
  const canChartShowsStats = verifyChartTypeCanUseStats(settings.chartType);

  useEffect(() => {
    if (!isAverageEnabled || !canChartShowsStats) {
      setItems([]);
      return;
    }

    const color = settings?.averageSettings ? settings.averageSettings.color : "#000";
    const avg = createLegendItem("Average", true, color);

    setItems([avg]);
  }, [isAverageEnabled, canChartShowsStats, settings?.averageSettings]);

  return items;
};

export default useAverageLegendItem;
