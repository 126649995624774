import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";
import { ForecastActivityType } from "constants/settings.constants";

import { FOLDERS, MOVE_FORECAST } from "api/userArps";

import { useSelectedProject } from "components/project/projects/hooks";

export interface MoveForecastRequest {
  oldFolderId: string;
  newFolderId: string;
  forecastId: string;
  type: ForecastActivityType;
  order: number;
}

export const useMoveForecast = (forecastType: ForecastActivityType) => {
  const { selectedProject: project } = useSelectedProject();

  const [moveForecastMutation, { loading, error }] = useMutation(MOVE_FORECAST);

  const moveForecast = async (MoveForecastRequest: MoveForecastRequest) => {
    const input = {
      forecastId: MoveForecastRequest.forecastId,
      oldFolderId: MoveForecastRequest.oldFolderId,
      newFolderId: MoveForecastRequest.newFolderId,
      type: MoveForecastRequest.type,
      order: MoveForecastRequest.order
    };
    await moveForecastMutation({
      variables: {
        input: input
      },
      refetchQueries: [
        {
          query: FOLDERS,
          variables: { req: { projectId: project?.projectId, type: forecastType } }
        }
      ],
      onError: (err) => {
        toast.error(err.message);
      }
    });
  };

  return { move: moveForecast, loading, error };
};
