import { useDispatch, useSelector } from "react-redux";

import { setDisplaySettings } from "store/features";
import { RootState } from "store/rootReducer";

export function useUpdateLabelTextSize() {
  const dispatch = useDispatch();
  const displaySettings = useSelector((state: RootState) => state.map.displaySettings);

  const updateWellLabelTextSize = (textSize: number) => {
    dispatch(setDisplaySettings({ ...displaySettings, wellLabelSize: textSize }));
  };

  const updateFacilityLabelTextSize = (textSize: number) => {
    dispatch(setDisplaySettings({ ...displaySettings, facilityLabelSize: textSize }));
  };

  const updateShapefileDetailLabelTextSize = (textSize: number) => {
    dispatch(
      setDisplaySettings({ ...displaySettings, shapefileDetailsLabelSize: textSize })
    );
  };

  const updateShapefileNameLabelTextSize = (textSize: number) => {
    dispatch(
      setDisplaySettings({ ...displaySettings, shapefileNameLabelSize: textSize })
    );
  };

  return {
    updateWellLabelTextSize,
    updateFacilityLabelTextSize,
    updateShapefileDetailLabelTextSize,
    updateShapefileNameLabelTextSize
  };
}
