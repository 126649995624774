import { memo } from "react";

function GasSales() {
  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
      <g transform="translate(5, 0)">
        <path
          d="M19.4786 16.6962C19.141 11.248 9.59585 0 9.59585 0C7.43684 9.69182 -0.00861992 10.7961 7.49155e-06 17.1761C0.00539963 21.4812 1.95735 23.981 4.39244 25.3085C3.62136 2.73 3.93411 19.7374 6.24949 17.574C10.5244 13.5796 10.9946 10.8932 10.9946 10.8932C15.4172 18.7452 14.3323 23.679 12.6661 26.1669C16.7232 25.0465 19.7881 21.6839 19.4786 16.6962"
          id="Gas"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
      <g>
        <rect
          x="16" // Set the x position to 50% for centering
          y="16" // Set the y position to 50% for centering
          width="15" // Set the width to 50% for the bottom right position
          height="11" // Set the height to 50% for the bottom right position
          fill="#FFF"
        />
      </g>
      <g transform="translate(11, 13) scale(0.85)">
        <path
          d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default memo(GasSales);
