// Split parameters or split folder separator
function stripParameters(str: string): string {
  if (str.indexOf("?") > -1) return str.split("?")[0];
  if (str.indexOf("/") > -1) return str.split("/")[0];
  return str;
}

function getYoutubePlaylistId(str): string | null {
  const reg = new RegExp("[&?]list=([a-z0-9_-]+)", "i");
  const match = reg.exec(str);

  if (match && match[1].length) return match[1];
  return null;
}

function getYoutubeVideoId(str): string {
  // shortcode
  const shortcode = /youtube:\/\/|https?:\/\/youtu\.be\//g;

  if (shortcode.test(str)) {
    const shortcodeid = str.split(shortcode)[1];
    return stripParameters(shortcodeid);
  }

  // /v/ or /vi/
  const inlineV = /\/v\/|\/vi\//g;

  if (inlineV.test(str)) {
    const inlineId = str.split(inlineV)[1];
    return stripParameters(inlineId);
  }

  // v= or vi=
  const parameterV = /v=|vi=/g;

  if (parameterV.test(str)) {
    const arr = str.split(parameterV);
    return arr[1].split("&")[0];
  }

  // v= or vi=
  const parameterWebp = /\/an_webp\//g;

  if (parameterWebp.test(str)) {
    const webp = str.split(parameterWebp)[1];
    return stripParameters(webp);
  }

  // embed
  const embedreg = /\/embed\//g;

  if (embedreg.test(str)) {
    const embedId = str.split(embedreg)[1];
    return stripParameters(embedId);
  }

  // user
  const userreg = /\/user\//g;

  if (userreg.test(str)) {
    const elements = str.split("/");
    return stripParameters(elements.pop());
  }

  // attribution_link
  const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/;

  if (attrreg.test(str)) {
    return str.match(attrreg)[1];
  }
}

export type YoutubeLinkT = {
  id: string;
  service: "youtube" | "youtube-playlist";
};

export function parseVideoId(url): YoutubeLinkT | null {
  // remove surrounding whitespaces or linefeeds
  let str = url.trim();

  // remove the '-nocookie' flag from youtube urls
  str = str.replace("-nocookie", "");

  // remove any leading `www.`
  str = str.replace("/www.", "/");

  // Try to handle google redirection uri
  if (/\/\/google/.test(str)) {
    // Find the redirection uri
    const matches = str.match(/url=([^&]+)&/);

    // Decode the found uri and replace current url string - continue with final link
    if (matches) {
      // Javascript can get encoded URI
      str = decodeURIComponent(matches[1]);
    }
  }

  if (/youtube|youtu\.be|i.ytimg\./.test(str)) {
    const playlistId = getYoutubePlaylistId(str);
    if (playlistId) {
      return {
        id: playlistId,
        service: "youtube-playlist"
      };
    }

    return {
      id: getYoutubeVideoId(str),
      service: "youtube"
    };
  } else {
    return null;
  }
}
