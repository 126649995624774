import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";

import { Error, Settings } from "@material-ui/icons";
import { dequal } from "dequal";
import styled from "styled-components";

import { useProperty } from "hooks";

import { InfoRequest, getSingleWellInfo } from "api/data";

import { IGroupByMap } from "models";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";
import Uwi from "models/uwi";

import { BaseIconToggle } from "components/base";
import { useDashboardDispatch } from "components/dashboard/hooks/useDashboardDispatch";
import GroupBy from "components/groupBy";
import { IconSpinner } from "components/icons";

import { EntityKind } from "../../models/entityKind";
import "./Info.scss";

export default function Info({
  id = "",
  selectedWell = "",
  initialPropertyName = "",
  initialForecastToggle = false,
  initialForecastFolder = ""
}) {
  const dashboardDispatch = useDashboardDispatch();

  const [value, setValue] = useState(null);
  const [propertyName, setPropertyName] = useState(initialPropertyName);
  const [forecastToggleOn, setForecastToggleOn] = useState(initialForecastToggle);
  const [selectedForecastFolder, setSelectedForecastFolder] =
    useState(initialForecastFolder);
  const [selectedForecastFolderName, setSelectedForecastFolderName] =
    useState(initialForecastFolder);
  const [selectedPdenSource, setSelectedPdenSource] = useState(PdenSourceEnum.Public);
  const [error, setError] = useState(null);
  const [lastSettings, setLastSettings] = useState<string>();
  const property = useProperty(propertyName, forecastToggleOn, selectedForecastFolder);

  const getInfoMutation = useMutation(
    async (request: InfoRequest) => await getSingleWellInfo(request),
    {
      onSuccess: (response) => {
        let v = response?.data?.value;
        if (!v) {
          v = "n/a";
        }
        setValue(v);
        setError(null);
      },
      onError: (error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err && err.response?.data) {
          // eslint-disable-next-line no-console
          console.error(err.response?.data);
        }

        setValue("n/a");
        setError("Error");
      }
    }
  );

  const handlePropertyChange = useCallback(
    (
      newPropertyName: string,
      isForecastToggleOn: boolean,
      selectedForecastFolder: string,
      selectedForecastFolderName: string,
      selectedPdenSource: PdenSourceEnum
    ) => {
      setPropertyName(newPropertyName);
      setForecastToggleOn(isForecastToggleOn);
      setSelectedForecastFolder(selectedForecastFolder);
      setSelectedForecastFolderName(selectedForecastFolderName);
      setSelectedPdenSource(selectedPdenSource);
      setValue(null);

      const newSettings = JSON.stringify({
        AggregateField: newPropertyName,
        IsForecastToggleOn: isForecastToggleOn,
        SelectedForecastFolder: selectedForecastFolder
      });

      // Update the session storage.
      sessionStorage.setItem(`info::${id}`, newSettings);

      if (!!lastSettings && !dequal(lastSettings, newSettings)) {
        // The info settings have changed, so mark the dashboard as modified.
        dashboardDispatch({
          payload: {
            hasModifiedWidgets: true
          }
        });
      }
      setLastSettings(newSettings);
    },
    [dashboardDispatch, id, lastSettings]
  );

  useEffect(() => {
    if (!property || !selectedWell) return;

    const uwid = new Uwi().toUnformatted(selectedWell);

    getInfoMutation.mutate({
      uniqueId: uwid,
      aggregateProperty:
        forecastToggleOn && property.fullContextTitle.includes("Forecast")
          ? property.property + ".fcst"
          : property.property,
      aggregate: "First",
      forecastFolder: selectedForecastFolderName,
      pdenSource: selectedPdenSource
    });
  }, [selectedWell, property]);

  useEffect(() => {
    setLastSettings(null);
    handlePropertyChange(
      initialPropertyName,
      initialForecastToggle,
      initialForecastFolder,
      initialForecastFolder,
      PdenSourceEnum.Public
    );
  }, [initialPropertyName, initialForecastToggle, initialForecastFolder]);

  const getPropertyTitle = useCallback(() => {
    if (!property) return "";

    if (property.product) {
      return forecastToggleOn
        ? `${property.lightContextTitle} - ${property.product}`
        : `${property.title} - ${property.product}`;
    } else if (
      (property.property.startsWith("Org_Focus_Fields") ||
        property.property.startsWith("My_Focus_Fields")) &&
      property.subgroup
    ) {
      if (property.defaultUnit) {
        return `${property.title} (${property.defaultUnit}) - ${property.subgroup}`;
      }
      return `${property.title} - ${property.subgroup}`;
    }
    return property.title;
  }, [property]);

  const groupBy: IGroupByMap = property
    ? {
        title: property.title,
        property: property.property
      }
    : null;

  return (
    <div className="info-board">
      <div className="info">
        <GroupBy
          value={groupBy}
          entityKind={EntityKind.Well}
          onChange={(
            val,
            isForecastToggleOn,
            selectedForecastFolder,
            selectedForecastFolderName,
            selectedPdenSource
          ) =>
            handlePropertyChange(
              val.property,
              isForecastToggleOn,
              selectedForecastFolder,
              selectedForecastFolderName,
              selectedPdenSource
            )
          }>
          <div className="settings-button">
            <BaseIconToggle>
              <Settings />
            </BaseIconToggle>
          </div>
        </GroupBy>

        {getInfoMutation.isLoading && <IconSpinner />}
        {!getInfoMutation.isLoading && (
          <div className="info-vis">
            <h5 className="header">{getPropertyTitle()}</h5>
            <h2 className="value">
              {!error && (value ?? "n/a")}
              {error && (
                <ErrorContainer>
                  <Error style={{ top: 3 }} /> {error}
                </ErrorContainer>
              )}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;
