// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import {
  BubbleChart,
  CardMembership,
  CreditCard,
  Edit,
  Equalizer,
  LayersClear,
  List,
  ListAlt,
  Map,
  MultilineChart,
  ShowChart,
  TableChart,
  Timeline
} from "@material-ui/icons";
import { mdiChartPpf, mdiPauseBoxOutline, mdiPulse, mdiRectangleOutline } from "@mdi/js";

import { GridWidget, GridWidgetTemplate } from "models/dashboard";

import {
  ForecastingWidgetKey,
  GeoMapLoaderWidgetKey,
  MidstreamChartWidgetKey,
  MidstreamDataTableWidgetKey,
  PadScenarioWidgetKey,
  TypeWellDetailsWidgetKey,
  TypeWellEditorWidgetKey,
  WellDataAnalyzer
} from "../constants/widgets.constants";

export const getWidgetPreview = (
  widget: GridWidget | GridWidgetTemplate | string,
  iconSize?: "default" | "inherit" | "large" | "medium" | "small"
): React.ReactNode => {
  if (!widget) return <></>;
  const size = iconSize ?? "inherit";

  const w = widget as GridWidget;
  const wt = widget as GridWidgetTemplate;
  let type: string = widget as string;

  if (w?.component) {
    type = w.component;
  }
  if (wt?.component) {
    type = wt.component;
  }

  const key = type.toLowerCase();
  switch (key) {
    case "map":
      return <Map fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "xda":
      return <BubbleChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "threed":
      return <LayersClear fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "data-table":
      return <TableChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case MidstreamDataTableWidgetKey:
      return <TableChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "chart":
      return <Equalizer fontSize={size} data-testid={`widget-preview-${key}`} />;
    case MidstreamChartWidgetKey:
      return <Equalizer fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "well-card":
      return <CardMembership fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "type-log":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            height: "100%"
          }}>
          <Icon
            path={mdiPulse}
            size={3.0}
            data-testid={`widget-preview-${key}`}
            style={{ position: "absolute", transform: "rotate(-90deg)" }}
          />
          <Icon
            path={mdiRectangleOutline}
            size={5.5}
            data-testid={`widget-preview-${key}-rect-outline`}
            style={{ position: "absolute", transform: "rotate(-90deg)" }}
          />
        </div>
      );
    case "facility-ticket":
      return <CardMembership fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "info":
      return <CreditCard fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "xdaviewer":
      return <BubbleChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "survey-vis":
      return <ShowChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "completion":
      return <Timeline fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "sam":
    case "sam-table":
    case "sam-rf-pv":
    case "multiphase-chart":
      return <MultilineChart fontSize={size} data-testid={`widget-preview-${key}`} />;
    case "exploitable-report":
      return <List fontSize={size} data-testid={`widget-preview-${key}`} />;
    case TypeWellEditorWidgetKey:
      return <Edit fontSize={size} data-testid={`widget-preview-${key}`} />;
    case TypeWellDetailsWidgetKey:
      return <ListAlt fontSize={size} data-testid={`widget-preview-${key}`} />;
    case GeoMapLoaderWidgetKey:
      return <Map fontSize={size} data-testid={`widget-preview-${key}`} />;
    case PadScenarioWidgetKey:
      return (
        <Icon
          path={mdiPauseBoxOutline}
          size={4.0}
          data-testid={`widget-preview-${key}`}
        />
      );
    case ForecastingWidgetKey:
      return <Icon path={mdiChartPpf} size={4.0} data-testid={`widget-preview-${key}`} />;
    case WellDataAnalyzer:
      return <></>;
    default:
      return <></>;
  }
};
