import { ScreenshotSetting } from "components/screenshot/components";
import {
  SettingsControl,
  SettingsGroups,
  useScreenshotContext
} from "components/screenshot/hooks";

function useChartScreenshotSettingsControls(): SettingsControl[] {
  // hooks
  const { widget, settings } = useScreenshotContext();

  const MapSettingsGroups = {
    legend: "Map Legend",
    advanced: "Advanced Map Settings",
    ipdb_legend: "2D Geo Model Legend"
  };

  const controls: SettingsControl[] = [];

  if (widget?.widgetComponentType !== "map") {
    return controls;
  }

  controls.push(
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="legendFontSize"
          name="legendFontSize"
          label="Legend Labels Size">
          <ScreenshotSetting.Input type="number" unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="showLegendInScreenshot"
          name="showLegendInScreenshot"
          label="Legend"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="showIpdbLegendInScreenshot"
          name="showIpdbLegendInScreenshot"
          label="2D Geo Legend"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="showTownshipBorderInScreenshot"
          name="showTownshipBorderInScreenshot"
          label="Township Border"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="applyAbbreviations"
          name="applyAbbreviations"
          label="Use Abbreviations"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="mapScreenshotScale"
          name="mapScreenshotScale"
          label="Scale">
          <ScreenshotSetting.Input type="number" min={0.1} step={0.1} />
        </ScreenshotSetting.Item>
      )
    }
  );

  if (settings?.showLegendInScreenshot) {
    controls.push(
      {
        group: MapSettingsGroups.legend,
        control: (
          <ScreenshotSetting.Item
            key="legendShowCounts"
            name="legendShowCounts"
            label="Show Count"
            valuePropName="checked">
            <ScreenshotSetting.Input type="boolean" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: MapSettingsGroups.legend,
        control: (
          <ScreenshotSetting.Item
            key="showLegendBorderInScreenshot"
            name="showLegendBorderInScreenshot"
            label="Legend Border"
            valuePropName="checked">
            <ScreenshotSetting.Input type="boolean" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: MapSettingsGroups.legend,
        control: (
          <ScreenshotSetting.Item
            key="legendOpacity"
            name="legendOpacity"
            label="Opacity">
            <ScreenshotSetting.Input type="number" step={0.1} min={0} max={1} />
          </ScreenshotSetting.Item>
        )
      }
    );
  }

  if (settings?.showIpdbLegendInScreenshot) {
    controls.push(
      {
        group: MapSettingsGroups.ipdb_legend,
        control: (
          <ScreenshotSetting.Item
            key="showIpdbLegendBorderInScreenshot"
            name="showIpdbLegendBorderInScreenshot"
            label="Legend Border"
            valuePropName="checked">
            <ScreenshotSetting.Input type="boolean" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: MapSettingsGroups.ipdb_legend,
        control: (
          <ScreenshotSetting.Item
            key="ipdbTitleSize"
            name="ipdbTitleSize"
            label="Title Size">
            <ScreenshotSetting.Input type="number" unit="pt" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: MapSettingsGroups.ipdb_legend,
        control: (
          <ScreenshotSetting.Item
            key="ipdbLabelsSize"
            name="ipdbLabelsSize"
            label="Label Size">
            <ScreenshotSetting.Input type="number" unit="pt" />
          </ScreenshotSetting.Item>
        )
      },
      {
        group: MapSettingsGroups.ipdb_legend,
        control: (
          <ScreenshotSetting.Item
            key="ipdbLegendOpacity"
            name="ipdbLegendOpacity"
            label="Opacity">
            <ScreenshotSetting.Input type="number" step={0.1} min={0} max={1} />
          </ScreenshotSetting.Item>
        )
      }
    );
  }

  return controls;
}

export default useChartScreenshotSettingsControls;
