import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject } from "react";

import { updateOverflowIndex, useTable } from "../context";

const toggleIconSize = 36;
const leftOffset = 700;

const useOverflowIndex = (ref: MutableRefObject<HTMLDivElement>) => {
  const [, tableDispatch] = useTable();

  useResizeObserver(ref, (client) => {
    const { width } = client.contentRect;
    const availableWidth = width - leftOffset;
    const visibleCount = Math.floor(availableWidth / toggleIconSize);
    const index = Math.max(1, visibleCount);
    updateOverflowIndex(tableDispatch, index);
  });
};

export default useOverflowIndex;
