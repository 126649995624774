/**
 * Get the display name for a product
 *
 * @param {string} product the product
 * @returns {string} The display name for the product
 */
const getProductDisplayName = (product: string): string => {
  if (product?.toLocaleLowerCase() === "oil") {
    return "Oil + Cond";
  }
  return product ?? "";
};

export default getProductDisplayName;
