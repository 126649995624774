import React, { useEffect, useState } from "react";

import { IconCheck } from "../icons";
import "./BaseCheckboxInput.scss";

function BaseCheckboxInput({
  value,
  label = "",
  trueValue = true,
  onChange = null,
  falseValue = false,
  checkIcon = null,
  disabled = false,
  ...props
}) {
  const [isChecked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  function updateInput(event) {
    const checked = event.target.checked;
    const checkValue = checked ? trueValue : falseValue;
    setChecked(checkValue);
    onChange && onChange(checkValue);
  }
  return (
    <div
      {...props}
      title={props.title}
      className={`checkbox-input  inline-flex items-center ${
        disabled ? "disabled" : ""
      } ${isChecked ? "checked" : ""} ${props.className}`}>
      <span
        className={`checkbox flex flex-centered ${checkIcon ? "negate" : ""} ${
          disabled ? "disabled" : ""
        }`}>
        {checkIcon == null ? <IconCheck /> : checkIcon}
      </span>

      {!!label && <label className="checkbox-label">{label}</label>}

      <input
        className="input absolute-center fill"
        type="checkbox"
        disabled={disabled}
        defaultChecked={value}
        onChange={updateInput}
      />
    </div>
  );
}

export default BaseCheckboxInput;
