import React, { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

import { produce } from "immer";

import { ArpsReducerActionsT, arpsReducer } from "./reducer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IArpsState {}

export const initialArpsState: IArpsState = {};

export type ArpsDispatchT = (action: ArpsReducerActionsT) => void;
export type ArpsProviderT = {
  children: ReactNode;
  initialState?: IArpsState;
};

const curriedReducer = produce(arpsReducer);

const ArpsContext =
  createContext<[IArpsState, React.Dispatch<ArpsReducerActionsT>]>(undefined);
ArpsContext.displayName = "ArpsContext";

const ArpsProvider = ({ children, initialState }: ArpsProviderT) => {
  const newState = initialState ? initialState : initialArpsState;

  const [state, dispatch] = useReducer(curriedReducer, newState);
  const value: [IArpsState, ArpsDispatchT] = useMemo(() => [state, dispatch], [state]);

  return <ArpsContext.Provider value={value}>{children}</ArpsContext.Provider>;
};

const useArpsContext = () => {
  const context = useContext(ArpsContext);
  if (context === undefined) {
    throw new Error("useArpsContext must be used within ArpsProvider");
  }
  return context;
};

export { ArpsProvider, curriedReducer, useArpsContext };
