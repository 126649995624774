import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button, Form, Input, Typography } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import {
  addOrganizationScreenshot,
  deleteOrganizationScreenshot,
  editOrganizationScreenshot,
  getOrganizationScreenshots
} from "api/users";

import { ScreenshotPresetEditModel, ScreenshotPresetModel } from "models/model";

import ScreenshotPresetTable from "components/user-settings/components/screenshot/ScreenshotPresetTable";

import "./OrganizationDisplaySettings.scss";

const { Title } = Typography;

const OrganizationDisplaySettings = () => {
  // state from store
  const user = useSelector((state: RootState) => state.auth.user);

  // state
  const [currentPreset, setCurrentPreset] = useState<ScreenshotPresetEditModel>({
    title: "",
    width: "",
    height: "",
    titleFontSize: "",
    axisFontSize: "",
    axisLabelFontSize: "",
    topsLabelsFontSize: "",
    dataLabelsFontSize: "",
    gridLabelsFontSize: "",
    legendFontSize: ""
  });

  // query and mutations
  const {
    data: orgScreenshotPresets,
    isLoading,
    isError,
    refetch
  } = useQuery("OrgScreenshotPreset", () => {
    return getOrganizationScreenshots(user?.organization?.id);
  });
  const addMutation = useMutation(
    async (preset: ScreenshotPresetModel) => {
      await addOrganizationScreenshot(user.organization.id, preset);
    },
    {
      onSuccess: async () => {
        setCurrentPreset({
          title: "",
          width: "",
          height: "",
          axisFontSize: "",
          titleFontSize: "",
          axisLabelFontSize: "",
          topsLabelsFontSize: "",
          dataLabelsFontSize: "",
          gridLabelsFontSize: "",
          legendFontSize: ""
        });
        refetch();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data) {
          toast.error(`An error occurred. ${error.response.data}`);
        } else {
          toast.error("Unable to connect to network.");
        }
      }
    }
  );
  const deleteMutation = useMutation(
    async (preset: ScreenshotPresetModel) => {
      await deleteOrganizationScreenshot(user.organization?.id, preset.id);
    },
    {
      onSuccess: () => {
        toast.success("Successfully deleted preset.");
        refetch();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data) {
          toast.error(`An error occurred. ${error.response.data}`);
        } else {
          toast.error("Unable to connect to network.");
        }
      }
    }
  );
  const editMutation = useMutation(
    async (preset: ScreenshotPresetModel) => {
      return await editOrganizationScreenshot(user.organization?.id, preset.id, preset);
    },
    {
      onSuccess: () => {
        toast.success("Successfully updated screenshot preset.");
        refetch();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data) {
          toast.error(`An error occurred. ${error.response.data}`);
        } else if (error instanceof String) {
          toast.error(`An error occurred: ${error}`);
        } else {
          toast.error("Unable to connect to network.");
        }
        refetch();
      }
    }
  );

  // handlers
  function validPreset(preset: ScreenshotPresetEditModel) {
    return Object.keys(preset).every((k) => preset[k].length || preset[k]);
  }

  function handleFormChange(changedValue) {
    const key = Object.keys(changedValue)[0];
    const value = changedValue[key];
    const nextValue = key !== "title" ? value.replace(/[^\d]+/g, "") : value;
    setCurrentPreset({ ...currentPreset, [key]: nextValue });
  }

  async function handleAddPreset() {
    if (!validPreset(currentPreset)) return;

    await addMutation.mutate({
      title: currentPreset.title,
      width: Number(currentPreset.width),
      height: Number(currentPreset.height),
      axisFontSize: Number(currentPreset.axisFontSize),
      titleFontSize: Number(currentPreset.titleFontSize),
      axisLabelFontSize: Number(currentPreset.axisLabelFontSize),
      topsLabelsFontSize: Number(currentPreset.topsLabelsFontSize),
      dataLabelsFontSize: Number(currentPreset.dataLabelsFontSize),
      gridLabelsFontSize: Number(currentPreset.gridLabelsFontSize),
      legendFontSize: Number(currentPreset.legendFontSize)
    });
  }

  async function handleEditPreset(preset: ScreenshotPresetModel) {
    editMutation.mutate(preset);
  }

  async function handleDeletePreset(preset: ScreenshotPresetModel) {
    deleteMutation.mutate(preset);
  }

  const getTableTitleMarkup = (title: string) => {
    return (
      <Title level={5} type="secondary">
        {title}
      </Title>
    );
  };

  if (isError) {
    return <RootContainer>Unable to connect to backend services.</RootContainer>;
  }

  return (
    <RootContainer>
      <div className="organizationAccountHeader">
        <Title level={3}>Screenshot Presets</Title>
      </div>
      <ContentContainer>
        <div className="section-heading">Organization Screenshot Presets</div>
        <ScreenshotPresetTable
          dataSource={orgScreenshotPresets ?? []}
          editable
          loading={isLoading}
          title={getTableTitleMarkup("Organization Screenshot Presets")}
          onChange={handleEditPreset}
          onDelete={handleDeletePreset}
        />

        <StyledForm layout="vertical" size="large" onValuesChange={handleFormChange}>
          <Form.Item name="title" label="Title">
            <Input placeholder="Screenshot title" />
          </Form.Item>

          <Form.Item name="width" label="Width">
            <Input placeholder="px" />
          </Form.Item>

          <Form.Item name="height" label="Height">
            <Input placeholder="px" />
          </Form.Item>

          <Form.Item name="titleFontSize" label="Title Size">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="axisFontSize" label="Axis Title">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="axisLabelFontSize" label="Axis Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="topsLabelsFontSize" label="Tops Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="dataLabelsFontSize" label="Data Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="gridLabelsFontSize" label="Grid Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="legendFontSize" label="Legend Size">
            <Input placeholder="pt" />
          </Form.Item>

          <Button type="text" loading={addMutation.isLoading} onClick={handleAddPreset}>
            + Add
          </Button>
        </StyledForm>
      </ContentContainer>
    </RootContainer>
  );
};

export default OrganizationDisplaySettings;

const RootContainer = styled.div`
  gap: 10px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  text-align: left;
  overflow: auto;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  .section {
    padding: 33px 0;
  }
  .section-heading {
    font-size: 1.6rem;
    padding-bottom: 22px;
    font-weight: var(--fontWeightMedium);
    padding: 0;
  }
  .units-measurements {
    .section-heading {
      padding-bottom: 15px;
    }
    .RadioTab.isChecked {
      background: var(--color-text);
      border-color: var(--color-text);
    }
  }
  .preset-category {
    padding-left: 10px;
    margin-top: 5px;
    .category-heading {
      font-size: 1.4rem;
      font-weight: var(--fontWeightMedium);
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;
const ContentContainer = styled.div`
  min-width: 768px;
  overflow: auto;
`;

const StyledForm = styled(Form)`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 200px repeat(9, minmax(0, 1fr)) max-content;
  grid-column-gap: 12px;
  margin-top: 36px;

  .ant-form-item {
    margin-bottom: 0; // to adjust +Add button alignment with input fields
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-form-item-label > label {
    height: unset; // removes default ant label height of 40px
  }
  .ant-form-item:not(:first-child) .ant-input {
    text-align: right; // align text to the right for all input fields except the first one
  }
`;
