import { useState } from "react";

import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Popover, Slider } from "antd";
import styled from "styled-components";

import { BaseButton } from "components/base";
import ColorChooser from "components/base/ColorChooser";
import { ISeriesStyle } from "components/multiphase-chart/models/shared.models";

interface ColorEditorModel {
  color: string;
  dashed?: boolean;
  defaultStyle?: ISeriesStyle;
  isDisabled?: boolean;
  onChange: (color: string, thickness: number) => void;
  showDashed?: boolean;
  thickness: number;
}

interface PopoverTriggerModel {
  color: string;
  thickness: number;
}
function PopoverTrigger({ color, thickness }: PopoverTriggerModel) {
  return <ColorDiv data-testid="color-div" color={color} thickness={thickness} />;
}

const ColorDiv = styled.div`
  width: 40px;
  height: ${(props) => 2 * props.thickness}px;
  background-color: ${(props) => props.color};
`;

const ColorEditor: React.FC<ColorEditorModel> = ({
  children,
  color,
  defaultStyle,
  isDisabled,
  onChange,
  thickness,
  ...props
}) => {
  const [customColors, setCustomColors] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  return (
    <Popover
      placement="right"
      trigger="click"
      open={isPopoverVisible}
      onOpenChange={(visible) => {
        if (isDisabled) return;

        setIsPopoverVisible(visible);
      }}
      content={
        <div
          onClick={(e) => {
            // Ensure the parents event isn't triggered
            // e.g Product button click event being triggered
            e.preventDefault();
            e.stopPropagation();
          }}>
          <ColorChooser
            selectedColor={color}
            onChange={(c) => onChange && onChange(c, thickness)}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          Thickness:
          <Slider
            min={1}
            max={10}
            step={0.1}
            value={thickness}
            onChange={(thickness) => onChange && onChange(color, thickness)}></Slider>
          {defaultStyle && (
            <RestoreDefaultWrapper>
              <div>Restore Default</div>
              <ResetButton
                appearance="subtle"
                onClick={() =>
                  onChange && onChange(defaultStyle.color, defaultStyle.width)
                }>
                <SettingsBackupRestoreIcon
                  style={{ fontSize: "18px" }}></SettingsBackupRestoreIcon>
              </ResetButton>
            </RestoreDefaultWrapper>
          )}
        </div>
      }>
      {children || (
        <PopoverWrapper {...props} disabled={isDisabled}>
          <PopoverTrigger color={color} thickness={thickness} />
        </PopoverWrapper>
      )}
    </Popover>
  );
};

export default ColorEditor;

const PopoverWrapper = styled.div`
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const RestoreDefaultWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
  margin-left: auto;
`;

const ResetButton = styled(BaseButton)`
  height: auto;
  padding-left: 0px;
  padding-right: 0px;
`;
