import { memo } from "react";

function TwoFactorInactive({ ...rest }) {
  return (
    <svg width="49px" height="24px" viewBox="0 0 49 24" {...rest}>
      <defs>
        <filter id="filter_34">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.8509804 0 0 0 0 0.88235295 0 0 0 0 0.8862745 0 0 0 1 0"
          />
        </filter>
      </defs>
      <g id="Icons2FAActive">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          transform="translate(0 0.00375)"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M7.406 9.912L7.406 8.232L1.988 8.232C1.988 7.99867 2.04633 7.80267 2.163 7.644C2.27967 7.48533 2.45 7.34067 2.674 7.21L2.674 7.21L6.048 5.25C6.496 4.98867 6.825 4.67833 7.035 4.319C7.245 3.95967 7.35 3.528 7.35 3.024L7.35 3.024L7.35 2.576C7.35 2.072 7.22633 1.624 6.979 1.232C6.73167 0.84 6.384 0.536667 5.936 0.322C5.488 0.107333 4.97 0 4.382 0L4.382 0L3.066 0C2.10467 0 1.35333 0.284667 0.812 0.854C0.270667 1.42333 0 2.21667 0 3.234L0 3.234L1.932 3.234C1.932 2.74867 2.03467 2.373 2.24 2.107C2.44533 1.841 2.73 1.708 3.094 1.708L3.094 1.708L4.382 1.708C4.69 1.708 4.93967 1.78733 5.131 1.946C5.32233 2.10467 5.418 2.31467 5.418 2.576L5.418 2.576L5.418 3.024C5.418 3.192 5.369 3.36 5.271 3.528C5.173 3.696 5.04 3.82667 4.872 3.92L4.872 3.92L1.4 5.992C0.905333 6.29067 0.555333 6.64533 0.35 7.056C0.144667 7.46667 0.042 7.952 0.042 8.512L0.042 8.512L0.042 9.912L7.406 9.912ZM10.542 9.912L10.542 6.104L15.428 6.104L15.428 4.424L10.542 4.424L10.542 1.792L15.946 1.792L15.946 0.112L8.61 0.112L8.61 9.912L10.542 9.912ZM18.27 9.912L19.138 7.63L22.806 7.63L23.66 9.912L25.676 9.912L22.19 0.952C21.9753 0.392 21.5647 0.112 20.958 0.112L20.958 0.112L20.09 0.112L16.254 9.912L18.27 9.912ZM22.288 6.006L19.656 6.006L20.972 2.478L20.986 2.478L22.288 6.006Z"
          transform="translate(20.42 7.088)"
          id="2FA"
          fill="#D9E1E2"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="IconsLocked" transform="translate(1 3)" filter="url(#filter_34)">
          <g id="iconremove" transform="translate(3 3)">
            <path
              d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
              id="Oval-Copy"
              fill="none"
              fillRule="evenodd"
              stroke="#A2AAAD"
              strokeWidth="2"
            />
          </g>
          <path
            d="M0 8.51153L8.51926 0"
            transform="translate(4.786294 4.757801)"
            id="Path-8"
            fill="none"
            fillRule="evenodd"
            stroke="#D9E1E2"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
export default memo(TwoFactorInactive);
