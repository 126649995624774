import { ALL_CHART_TYPES } from "constants/chart.constants";

import { IGroupByListColumn } from "models";

/**
 * Returns an array of objects filtered by a given data types
 */
const filterByType = (
  dataTypeList: string[],
  list: IGroupByListColumn[],
  chartType?: string,
  axisType?: string
): IGroupByListColumn[] => {
  if (!dataTypeList.length) return list;

  // only numerical fields for cross plot, no Dates subgroup for y axis
  if (chartType === "Cross Plot" && (axisType === "x" || axisType === "y")) {
    const datesSubgroupToIgnore = ["Dates", "General"];
    return axisType === "x"
      ? list.filter((column) => {
          return (
            dataTypeList.includes(column.dataType) ||
            (column.group === "Dates" && !datesSubgroupToIgnore.includes(column.subgroup))
          );
        })
      : list.filter((column) => dataTypeList.includes(column.dataType));
  }

  /**
   * Probably temporary code, we also want to include 'Year-Qtr', but those fields not compatiable
   * with 'time' type x-axes, given the values are represented as '2014-Q1', for example. We'll need to figure out
   * some value/label solution that's compatible with echarts to use the 'time' type. */
  if (chartType === ALL_CHART_TYPES.TrendDate.label) {
    return list.filter((column) =>
      axisType === "x"
        ? column.dataType === "Date" ||
          // fields outside of Dates group can have the integer data type but are not date fields
          (column.dataType === "Integer" && column.group === "Dates") ||
          column.defaultUnit === "Year-Month"
        : dataTypeList.includes(column.dataType) || column.defaultUnit === "Year-Month"
    );
  }

  return list.filter((column) => dataTypeList.includes(column.dataType));
};

export default filterByType;
