import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { Select } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BaseDropdown } from "components/base";

import { EntityKind } from "../../models/entityKind";

const { Option } = Select;

function SavedFilterSelection({
  className = "",
  savedFilters,
  selectedFilter,
  onSelectedFilterChanged,
  activeFilterListener = true,
  useSelectControl = false,
  entityKind,
  ...props
}) {
  // The state of the filter.
  const filterState = useSelector((state: RootState) => {
    const filterSlice =
      entityKind == EntityKind.Well ? state.filter : state.facilityFilter;

    const obj = {
      ...(filterSlice.polygonFilter?.source && {
        polygonFilter: filterSlice.polygonFilter
      }),
      excludePolygonsFilter: filterSlice.excludePolygonsFilter,
      wellListFilter: filterSlice.wellListFilter,
      propertiesFilter: filterSlice.propertiesFilter
    };

    return obj;
  });

  // Listen for changes to the filter state
  useEffect(() => {
    if (activeFilterListener) {
      const state = Object.assign({}, filterState);

      const areEqual = (a, b): boolean => {
        const excludePolygonsFilterEqual =
          JSON.stringify(a.excludePolygonsFilter ?? []) ===
          JSON.stringify(b.excludePolygonsFilter ?? []);
        const wellListFilterEqual =
          JSON.stringify(a.wellListFilter ?? []) ===
          JSON.stringify(b.wellListFilter ?? []);
        const propertiesFilterEqual =
          JSON.stringify(a.propertiesFilter ?? []) ===
          JSON.stringify(b.propertiesFilter ?? []);

        return excludePolygonsFilterEqual && wellListFilterEqual && propertiesFilterEqual;
      };

      if (
        selectedFilter.filterJSON &&
        !areEqual(JSON.parse(selectedFilter.filterJSON), state) &&
        (state.excludePolygonsFilter.length > 0 ||
          state.wellListFilter.length > 0 ||
          state.propertiesFilter.length > 0)
      ) {
        // The filter changed from the saved version, switch to the active filter being selected.
        onSelectedFilterChanged && onSelectedFilterChanged(savedFilters[0]);
      }
    }
  }, [
    activeFilterListener,
    filterState,
    onSelectedFilterChanged,
    savedFilters,
    selectedFilter?.filterJSON
  ]);

  const handleFilterChange = useCallback(
    (value) => {
      const match = savedFilters.find((f) => f.id === value);

      if (match) {
        onSelectedFilterChanged && onSelectedFilterChanged(match);
      }
    },
    [savedFilters, onSelectedFilterChanged]
  );

  const isLoading = selectedFilter?.name === "Loading...";

  // The select control does not adjust the popover well based in the number
  // of items.  Therefore in some case we need to still use BaseDropdown
  if (useSelectControl) {
    return (
      <StyledSelect
        {...props}
        className={className}
        loading={isLoading}
        value={selectedFilter?.id}
        onChange={handleFilterChange}>
        {savedFilters.map((filter) => {
          return <Option key={filter.id}>{filter.name}</Option>;
        })}
      </StyledSelect>
    );
  }
  return (
    <BaseDropdown
      className={className}
      value={selectedFilter}
      isLoading={isLoading}
      testid={`saved-filter-selection-${entityKind}`}
      labelKey={"name"}
      onChange={(val) => {
        onSelectedFilterChanged && onSelectedFilterChanged(val);
      }}
      options={savedFilters}
    />
  );
}

export default SavedFilterSelection;

export const StyledSelect = styled(Select)``;
