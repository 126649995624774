import { useMutation } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";

const deleteTemplate = async (data: { id: string }) => {
  const response = await axios.delete(
    `${GEO_MAP_SERVICE_ADDRESS}/field-templates/${data.id}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete field template");
  }
  return response.data;
};

const useDeleteFieldTemplate = () => {
  return useMutation(deleteTemplate);
};

export default useDeleteFieldTemplate;
