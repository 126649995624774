import { ReactNode, memo } from "react";

import classnames from "classnames";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

type ToolbarButtonT = {
  icon: ReactNode;
  activeIcon?: ReactNode;
  className?: string;
  tooltipText?: string | ReactNode;
  overflowLabel: string;
  active: boolean;
  onToggle: (b: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const ToolbarButton = ({
  icon,
  activeIcon = null,
  className = "",
  tooltipText = "",
  overflowLabel = "",
  active = false,
  onToggle = null,
  disabled = false,
  ...rest
}: ToolbarButtonT) => {
  const buttonClassNames = classnames("toolbar-button", className);

  return (
    <Tooltip title={tooltipText}>
      <StyledButton
        className={buttonClassNames}
        aria-pressed={!disabled && active}
        active={!disabled && active}
        onClick={() => onToggle(!active)}
        disabled={disabled}
        {...rest}>
        {activeIcon && active ? activeIcon : icon}
        {overflowLabel}
      </StyledButton>
    </Tooltip>
  );
};

export default memo(ToolbarButton);

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  background: ${(props) =>
    props.isGlobalSetting && props.active ? "var(--color-primary)" : "transparent"};
  color: ${(props) =>
    props.isGlobalSetting && props.active
      ? "white"
      : props.active
      ? "var(--color-primary)"
      : "#a2aaad"};
  &:hover {
    color: var(--color-primary);
    background: ${(props) =>
      props.isGlobalSetting && props.active ? "white" : "transparent"};
  }
  border: none;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;
