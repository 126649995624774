import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { useProjectContext } from "components/project/projects/context";
import { PROJECT_SHAPEFILES_QUERY_KEYS } from "components/project/shapefiles/queries";

export interface IUseUpdateProjectShapefileFolderColourMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
}

export type UpdateProjectShapefileFolderColourParamsT = {
  body: {
    key: string;
    value: string;
  };
  shapefileNodeId: string;
  projectId: string;
};

export function UpdateShapefileColors(params: UpdateProjectShapefileFolderColourParamsT) {
  let shapefileNodeId = params.shapefileNodeId;
  if (shapefileNodeId === "Project Shapefiles") {
    shapefileNodeId = "root";
  }
  const url = `${projectServiceEndpoint}/shapefile/colors/${params.projectId}/${shapefileNodeId}`;
  return axios.put(url, params.body);
}

export function useUpdateProjectShapefileFolderColourMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess
}: IUseUpdateProjectShapefileFolderColourMutationParams) {
  const queryClient = useQueryClient();

  const { selectedProjectId } = useProjectContext();
  return useMutation(
    (params: UpdateProjectShapefileFolderColourParamsT) =>
      UpdateShapefileColors({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [
            PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles,
            selectedProjectId
          ]
        });
        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
