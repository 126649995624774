import { Icon } from "@mdi/react";

import {
  mdiAccount,
  mdiDomain,
  mdiImageFilterHdr,
  mdiLandslide,
  mdiLayers
} from "@mdi/js";
import classnames from "classnames";
import styled from "styled-components";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";

function CategoryListItem({ value }) {
  const { hexColor, name } = value;

  const { selectedCategory, isFiltering, hoveredField } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();

  const changeCategory = () => {
    return () => {
      updateGroupByState({ type: "selected_category", payload: value });
      updateGroupByState({ type: "filter", payload: "" });
    };
  };

  const rootClassNames = classnames({
    selected:
      (!isFiltering && name === selectedCategory?.name) ||
      (isFiltering && name === hoveredField?.group)
  });

  return (
    <Wrapper
      className={rootClassNames}
      role="button"
      tabIndex={0}
      onClick={changeCategory()}>
      <Swatch color={hexColor}>
        {hexColor === "#ccc" && <Icon path={mdiImageFilterHdr} size={1} />}
        {name === "Organization Fields" && <Icon path={mdiDomain} size={1} />}
        {name === "Organization Geo Fields" && <Icon path={mdiLandslide} size={1} />}
        {name === "My Fields" && <Icon path={mdiAccount} size={1} />}
        {name === "Shapefile" && <Icon path={mdiLayers} size={1} />}
      </Swatch>
      <Label>{name}</Label>
    </Wrapper>
  );
}

export default CategoryListItem;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: rgba(var(--color-text-rgb), 0.6);
  border-radius: var(--border-radius);
  font-weight: var(--fontWeightMedium);
  padding: var(--space-1) var(--space-4);
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--color-text);
    background-color: var(--color-accent-hover);
  }
  &.selected {
    color: var(--color-text);
    background-color: var(--color-accent-hover);
    font-weight: var(--fontWeightBold);
  }
`;

const Swatch = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.color};
  border-radius: var(--space-05);
  color: #fff;
`;

const Label = styled.span`
  max-width: calc(100% - var(--space-4));
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
