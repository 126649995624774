import { memo } from "react";

function PieChart() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30">
      <path
        d="M16 15L29.9648 15C29.9881 14.6697 30 14.3362 30 14C30 6.26801 23.732 0 16 0L16 15ZM14 30C21.3958 30 27.4521 24.2653 27.9648 17L14 17L14 2C6.26801 2 0 8.26801 0 16C0 23.732 6.26801 30 14 30Z"
        id="Iconschart-typepie"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}

export default memo(PieChart);
