// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert } from "@mdi/js";
import styled from "styled-components";

import { SavedFilterModel } from "models/filter";

import { SavedFilterSelection } from "components/filter";
import { useGetProjectSavedFilters } from "components/project/filters/hooks";
import { DefaultFilter as DefaultFilterModel } from "components/user-settings/models";
import { useUserContext } from "components/user/context";
import { updateSettingFilter } from "components/user/context/reducer";
import { useUserDefaults, useUserSettingMutation } from "components/user/hooks";

import { EntityKind } from "../../../../models/entityKind";
import { updateSettingFacilityFilter } from "../../../user/context/reducer/UserReducer";
import {
  DEFAULT_FACILITY_FILTER_SETTING,
  DEFAULT_FILTER_SETTING
} from "../../constants/userSetting.constants";
import {
  ErrorContainer,
  SectionContainer,
  SectionHeading,
  SectionInputWrapper
} from "./shared";

interface DefaultFilterProps {
  entityKind: EntityKind;
}

const DefaultFilter = ({ entityKind }: DefaultFilterProps) => {
  // hooks
  const [, dispatch] = useUserContext();
  const defaults = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultFilterModel>(
    entityKind == EntityKind.Well
      ? DEFAULT_FILTER_SETTING
      : DEFAULT_FACILITY_FILTER_SETTING
  );

  // state
  const [filters, setFilters] = useState<SavedFilterModel[]>([]);
  const [currentDefaultFilter, setCurrentDefaultFilter] = useState<SavedFilterModel>();
  const [updateError, setUpdateError] = useState("");

  const handleFiltersLoaded = (data) => {
    if (!data?.savedFilters?.length) return;
    setFilters(data?.savedFilters);
  };

  const { mutate: getFiltersMutate, isLoading: filtersLoading } =
    useGetProjectSavedFilters(handleFiltersLoaded);
  const defaultFilterId = () =>
    entityKind === EntityKind.Well
      ? defaults?.filter?.filterId
      : defaults?.facilityFilter?.filterId;

  const handleDefaultFilterChange = useCallback(
    (filter: SavedFilterModel) => {
      mutation.mutate({
        id:
          entityKind === EntityKind.Well
            ? defaults?.filter?.id
            : defaults?.facilityFilter?.id,
        filterId: filter.id,
        filterJson: ""
      });
      setCurrentDefaultFilter(filter);
    },
    [defaults?.project?.id]
  );

  // Handle the new project loading and selecting the new default.
  useEffect(() => {
    if (!filters?.length) return;

    const match = filters.find((f) => f.id === defaultFilterId()) ?? filters[0];
    setCurrentDefaultFilter(match);
  }, [filters, defaults?.filter?.filterId, defaults?.facilityFilter?.filterId]);

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    if (entityKind === EntityKind.Well) {
      updateSettingFilter(dispatch, {
        id: mutation.data.id,
        filterId: mutation.data.filterId,
        filterJson: mutation.data.filterJson
      });
    } else if (entityKind === EntityKind.Facility) {
      updateSettingFacilityFilter(dispatch, {
        id: mutation.data.id,
        filterId: mutation.data.filterId,
        filterJson: mutation.data.filterJson
      });
    }
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update default filter.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  useEffect(() => {
    if (!defaults?.project?.projectId) return;

    getFiltersMutate({ projectId: defaults?.project?.projectId, entityKind });
  }, [defaults?.project, entityKind]);

  return (
    <SectionContainer>
      <SectionHeading>{entityKind} Filter</SectionHeading>
      <SectionInputWrapper>
        <StyledSelect
          entityKind={entityKind}
          popupClassName={"default-filter-dropdown-popup"}
          data-testid="default-filter-dropdown"
          loading={filtersLoading}
          savedFilters={filters}
          selectedFilter={currentDefaultFilter}
          onSelectedFilterChanged={handleDefaultFilterChange}
          activeFilterListener={false}
          useSelectControl={true}
        />
      </SectionInputWrapper>

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </SectionContainer>
  );
};

export default DefaultFilter;

export const StyledSelect = styled(SavedFilterSelection)`
  height: 50px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100% !important;
  }
`;
