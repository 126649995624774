export const getCountOfSeriesCollectionData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seriesCollection: any[],
  dataProperty: string
): number => {
  let sum = 0;

  for (const series of seriesCollection) {
    sum += series?.[dataProperty]?.length;
  }

  return sum;
};
