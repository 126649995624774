import { useEffect } from "react";

import { useUser } from "hooks";

import { IUser } from "models/User";

const zohoWidgetUrl = "https://salesiq.zoho.com/widget";
const zohoWidgetCode =
  "398bb6abfc272df01d6f47297e8ef040a0d088a1a0a380dfa3c14611af5af4c0fae4d03d91b62ba356bef70645528293";

const useScript = (url: string, widgetCode: string, user: IUser) => {
  useEffect(() => {
    if (!(url && widgetCode)) return;

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    const code = `
      var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "${widgetCode}",
        values:{},
        ready:function(){
          $zoho.salesiq.visitor.id("${user?.id}");
          $zoho.salesiq.visitor.name("${user?.firstName} ${user?.lastName}");
          $zoho.salesiq.visitor.email("${user?.email}");
          $zoho.salesiq.visitor.info({"Organization" : "${user?.organization?.name}", "Role" : "${user?.role}", "Job Title": "${user?.jobTitle}"});
        }
      };
      var d=document;
      s=d.createElement("script");
      s.type="text/javascript";
      s.id="zsiqscript";
      s.defer=true;
      s.src="${url}";
      t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t);
      d.innerHTML = "<div id='zsiqwidget'></div>";
    `;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

/**
 * Add the chat widget if it is not a dev environment and the user is not being impersonated.
 */
function ChatWidget() {
  const { user } = useUser();

  const url = user?.isImpersonating ? "" : zohoWidgetUrl;
  const code = user?.isImpersonating ? "" : zohoWidgetCode;

  useScript(url, code, user);

  return <></>;
}

export default ChatWidget;
