import React, { memo } from "react";

function Step({ past, number, active, onClick, clickable }) {
  return (
    <div
      className={`flex step-child ${active ? "active" : ""}  ${past ? "past" : ""} ${
        clickable ? "clickable" : ""
      }`}
      onClick={() => clickable && onClick()}>
      <span>Step {number}</span>
    </div>
  );
}
export default memo(Step);
