import { memo } from "react";

function TotalRateCum() {
  return (
    <svg
      width="33px"
      height="23px"
      viewBox="0 0 33 23"
      version="1.1"
      id="svg1630"
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs1634" />
      <g id="iconRate-cum" transform="translate(1.125 1.125)">
        <g id="iconTotal-rate-date-Copy">
          <g id="area-chart-icon_3">
            <path
              d="M0 0L0 19L18 19"
              id="Path-3"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2.25"
            />
            <path
              d="M 13.675,2.09 V 0.579 H 9.823 L 11.439,3.074 9.823,5.596 H 13.675 V 4.084"
              id="Path-8"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path
              d="M6.26318 2.45765L6.26318 0L0 0L2.62677 4.05681L0 8.15837L6.26318 8.15837L6.26318 5.70073"
              transform="translate(21.469936 11.978326)"
              id="Path-8"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
      <path
        d="M 4.9227276,3.3328735 C 8.1358766,11.10052 6.4807966,12.352679 18.1321,15.086022"
        id="Path-2-5"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.25"
      />
    </svg>
  );
}

export default memo(TotalRateCum);
