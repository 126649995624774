import { useEffect, useReducer } from "react";

import { makeStyles } from "@material-ui/core";

import { GetMlModels } from "api/ml";

import { BaseButton, BaseDropdown } from "components/base";

const useStyle = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  ml: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    justifyContent: "center"
  }
});

function reducer(state, { type, value }) {
  switch (type) {
    case "setSelectedModel":
      return { ...state, selectedModel: value };
    case "setModels":
      return { ...state, models: value };
  }
  return {};
}
export default function Ml() {
  const [state, dispatch] = useReducer(reducer, {
    selectedModel: null,
    models: []
  });

  const classes = useStyle();
  function updateModels() {
    GetMlModels().then((response) => {
      const models = response.data;
      dispatch({ type: "setModels", value: models.Available_Models });
      dispatch({ type: "setSelectedModel", value: models.Available_Models[0] });
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function getPredictions() {}

  useEffect(() => {
    updateModels();
  }, []);

  return (
    <div className={classes.ml}>
      <div className={classes.toolbar}>
        <label>ML Model</label>
        <BaseDropdown value={state.selectedModel} options={state.models}></BaseDropdown>
        <BaseButton onClick={getPredictions} appearance="primary">
          Calculate
        </BaseButton>
      </div>
      <div className="grid"></div>
    </div>
  );
}
