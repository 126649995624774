// eslint-disable-next-line import/no-named-as-default
import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { Select, Typography } from "antd";
import styled from "styled-components";

import { useUserSettingMutation } from "hooks";

import { WORKSPACES, WorkspaceClientName } from "api/workspace";

import { WorkspaceQuery } from "models/workspace";

import { TypeWellEditorWidgetKey } from "components/dashboard/constants/widgets.constants";
import { DEFAULT_TYPE_WELL_DASHBOARD_SETTING } from "components/user-settings/constants/userSetting.constants";
import { useUserContext } from "components/user/context";
import { updateSettingTypeWellDashboard } from "components/user/context/reducer/UserReducer";
import { useUserDefaults } from "components/user/hooks";

import { DefaultTypeWellDashboard } from "../../models";

const { Title } = Typography;
const { Option } = Select;

const DefaultDashboard = () => {
  // hooks
  const [, dispatch] = useUserContext();
  const defaults = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultTypeWellDashboard>(
    DEFAULT_TYPE_WELL_DASHBOARD_SETTING
  );

  const [currentDefaultDashboard, setCurrentDefaultDashboard] = useState(undefined);

  const { data } = useQuery<WorkspaceQuery>(WORKSPACES, {
    context: {
      clientName: WorkspaceClientName
    },
    notifyOnNetworkStatusChange: true
  });

  // state
  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingTypeWellDashboard(dispatch, {
      dashboardId: mutation.data.dashboardId,
      workspaceId: mutation.data.workspaceId
    });
  }, [mutation?.data]);

  const ListOfDashboardsWithTypeWellEditor = useMemo(() => {
    if (!data?.workspaces[0]?.dashboards) return [];

    const dashboardsWithTypeWellEditor = data?.workspaces.flatMap((workspace) => {
      const dashboardsInWorkspace = workspace.dashboards;

      const dashboardsWithTypeWellEditorInWorkspace = dashboardsInWorkspace.filter(
        (dashboard) => {
          const widgetTypesInDashboard = dashboard.widgetTypes ?? [];
          return widgetTypesInDashboard.find(
            (widget) => widget === TypeWellEditorWidgetKey
          );
        }
      );

      return dashboardsWithTypeWellEditorInWorkspace.map((dashboard) => ({
        dashboardId: dashboard.dashboardId,
        title: dashboard.title,
        workspaceId: workspace.workspaceId,
        workspaceName: workspace.title
      }));
    });

    return dashboardsWithTypeWellEditor;
  }, [data]);

  useEffect(() => {
    if (!currentDefaultDashboard && ListOfDashboardsWithTypeWellEditor?.length > 0) {
      const defaultDashboard = ListOfDashboardsWithTypeWellEditor?.find((dashboard) => {
        return dashboard.dashboardId === defaults.typeWellDashboard?.dashboardId;
      });

      const defaultDashboardId = defaultDashboard
        ? `${defaultDashboard.dashboardId}_${defaultDashboard.workspaceId}`
        : undefined;
      setCurrentDefaultDashboard(defaultDashboardId);
    }
  }, [ListOfDashboardsWithTypeWellEditor]);
  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingTypeWellDashboard(dispatch, {
      id: mutation.data.id,
      dashboardId: mutation.data.dashboardId,
      workspaceId: mutation.data.workspaceId
    });
  }, [mutation?.data]);

  const handleDefaultDashboardChange = (combinedId: string) => {
    const [dashboardId, workspaceId] = combinedId.split("_");
    mutation.mutate({
      id: defaults?.typeWellDashboard?.id,
      workspaceId: workspaceId,
      dashboardId: dashboardId
    });
    setCurrentDefaultDashboard(combinedId);
  };

  return (
    <Wrapper>
      <Title level={5}>Default Dashboard</Title>

      <StyledSelect
        data-testid="decline-type-dropdown"
        value={currentDefaultDashboard}
        onChange={handleDefaultDashboardChange}>
        {ListOfDashboardsWithTypeWellEditor?.map((dashboard) => (
          <Option key={`${dashboard.dashboardId}_${dashboard.workspaceId}`}>
            {dashboard.workspaceName}: {dashboard.title}
          </Option>
        ))}
      </StyledSelect>
    </Wrapper>
  );
};

export default DefaultDashboard;

//Styled  Components
const Wrapper = styled.div`
  .anticon-loading {
    font-size: 18px;
    color: var(--color-primary);
  }
`;

const StyledSelect = styled(Select)`
  width: 560px;
  height: 50px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100% !important;
  }
`;
