import { memo } from "react";

import { LegendFilled } from "components/icons";

import { useChartDispatch, useChartState } from "../../context";
import ToolbarButton from "../ToolbarButton";

const LegendToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();

  //TODO clean up the legend position as it is not used anymore
  const toggle = () => (value) => {
    dispatch({
      type: "settings",
      payload: {
        ...settings,
        legend: { visible: value, position: settings.legend.position }
      }
    });
  };

  return (
    <ToolbarButton
      active={settings.legend.visible}
      icon={<LegendFilled />}
      overflowLabel="Legend"
      tooltipText="Legend"
      onToggle={toggle()}
    />
  );
};

export default memo(LegendToggle);
