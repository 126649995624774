// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { mdiCalculator, mdiKey, mdiLayers, mdiMapCheck } from "@mdi/js";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import {
  setActiveSetting,
  setOrganizationSettingsOpen
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useUser } from "hooks";

import { BaseButton, SubHeading } from "components/base";
import AccountSecurity from "components/icons/AccountSecurity";
import DataSource from "components/icons/DataSource";
import DisplaySettings from "components/icons/DisplaySettingsIcon";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import "./OrganizationSettingsSideNav.scss";

const useStyles = makeStyles({
  navItems: {
    fontSize: "1.2em",
    textAlign: "left",
    display: "inline"
  }
});

export default function OrganizationSettingsSideNav() {
  const role = useSelector((state: RootState) => state.auth.user.role);
  const { user, isAtLeastAdmin } = useUser();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { hasFeature } = useBetaFeatures();
  const activeSetting = useSelector(
    (state: RootState) => state.userSetting.activeSetting
  );
  const isSystemAdmin =
    isAtLeastAdmin &&
    (user?.organization?.emailDomain === "mcdan.com" ||
      user?.organization?.emailDomain === "turinganalytics.net");
  const showSystemShapefileManagement = isSystemAdmin && IS_INTERNAL_ENV;
  return (
    role === "Admin" && (
      <div className="OrganizationSettingSideNav">
        <ul className="settings-nav">
          <li className="back-dashboard">
            <BaseButton
              appearance="subtle"
              className={classes.navItems}
              onClick={() => dispatch(setOrganizationSettingsOpen(false))}>
              <div>
                <KeyboardBackspaceIcon style={{ fontSize: "24px" }} />
              </div>
              Back to dashboard
            </BaseButton>
          </li>
          <div className="settings-section">
            <SubHeading>General</SubHeading>
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationProfile" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationProfile"))}>
                <NavItem>
                  <IconWrapper>
                    <BusinessIcon style={{ fontSize: "24px" }}></BusinessIcon>
                  </IconWrapper>
                  <Title>Company Profile</Title>
                </NavItem>
              </BaseButton>
            </li>
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationSecurity" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationSecurity"))}>
                <NavItem className="inline-flex">
                  <IconWrapper>
                    <AccountSecurity></AccountSecurity>
                  </IconWrapper>
                  <Title>Account & Security</Title>
                </NavItem>
              </BaseButton>
            </li>
          </div>
          <div className="settings-section">
            <SubHeading>Subscription</SubHeading>
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationMembers" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationMembers"))}>
                <NavItem className="inline-flex">
                  <IconWrapper>
                    {" "}
                    <AccountBoxIcon style={{ fontSize: "24px" }}></AccountBoxIcon>
                  </IconWrapper>
                  <Title>Users</Title>
                </NavItem>
              </BaseButton>
            </li>
          </div>
          <div className="settings-section">
            <SubHeading>Integrations</SubHeading>
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationDataSources" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationDataSources"))}>
                <NavItem className="inline-flex">
                  <IconWrapper>
                    <DataSource />
                  </IconWrapper>
                  <Title>Data Source</Title>
                </NavItem>
              </BaseButton>
            </li>

            {hasFeature("Developer Api Tokens") && (
              <li
                className={`settings-nav-item ${
                  activeSetting === "DeveloperApiTokenManagement" ? "active" : ""
                }`}>
                <BaseButton
                  appearance="subtle"
                  className={classes.navItems}
                  onClick={() =>
                    dispatch(setActiveSetting("DeveloperApiTokenManagement"))
                  }>
                  <NavItem className="inline-flex">
                    <IconWrapper>
                      <Icon path={mdiKey} size={1.6} />
                    </IconWrapper>
                    <Title>Developer API Tokens</Title>
                  </NavItem>
                </BaseButton>
              </li>
            )}
          </div>
          <div className="settings-section">
            <SubHeading>Configuration</SubHeading>
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationShapefiles" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationShapefiles"))}>
                <NavItem className="inline-flex">
                  <IconWrapper>
                    <Icon path={mdiLayers} size={1.6} />
                  </IconWrapper>
                  <Title>Organization Shapefiles</Title>
                </NavItem>
              </BaseButton>
            </li>
            {showSystemShapefileManagement && (
              <li
                className={`settings-nav-item ${
                  activeSetting === "SystemShapefiles" ? "active" : ""
                }`}>
                <BaseButton
                  appearance="subtle"
                  className={classes.navItems}
                  onClick={() => dispatch(setActiveSetting("SystemShapefiles"))}>
                  <NavItem className="inline-flex">
                    <IconWrapper>
                      <Icon path={mdiLayers} size={1.6} />
                    </IconWrapper>
                    <Title>System Shapefiles</Title>
                  </NavItem>
                </BaseButton>
              </li>
            )}
            {hasFeature("Org Geo Fields") && hasFeature("Cutoff Geo Models") && (
              <li
                className={`settings-nav-item ${
                  activeSetting === "OrganizationGeoModelConfigurations" ? "active" : ""
                }`}>
                <BaseButton
                  appearance="subtle"
                  className={classes.navItems}
                  onClick={() =>
                    dispatch(setActiveSetting("OrganizationGeoModelConfigurations"))
                  }>
                  <NavItem className="inline-flex">
                    <IconWrapper>
                      <Icon path={mdiMapCheck} size={1.6} />
                    </IconWrapper>
                    <Title>Geo Model Configurations</Title>
                  </NavItem>
                </BaseButton>
              </li>
            )}
            {hasFeature("Org Geo Fields") && (
              <li
                className={`settings-nav-item ${
                  activeSetting === "OrganizationGeoCalculationJobs" ? "active" : ""
                }`}>
                <BaseButton
                  appearance="subtle"
                  className={classes.navItems}
                  onClick={() =>
                    dispatch(setActiveSetting("OrganizationGeoCalculationJobs"))
                  }>
                  <NavItem className="inline-flex">
                    <IconWrapper>
                      <Icon path={mdiCalculator} size={1.6} />
                    </IconWrapper>
                    <Title>Geo Model Calculations</Title>
                  </NavItem>
                </BaseButton>
              </li>
            )}
          </div>
          <div className="settings-section">
            <SubHeading>Preferences</SubHeading>
            <li
              className={`settings-nav-item ${
                activeSetting === "OrganizationDisplaySettings" ? "active" : ""
              }`}>
              <BaseButton
                appearance="subtle"
                className={classes.navItems}
                onClick={() => dispatch(setActiveSetting("OrganizationDisplaySettings"))}>
                <NavItem>
                  <IconWrapper>
                    <DisplaySettings />
                  </IconWrapper>
                  <Title> Display Settings</Title>
                </NavItem>
              </BaseButton>
            </li>
          </div>
        </ul>
      </div>
    )
  );
}

const Title = styled.div`
  height: 100%;
  display: flex;
`;

const IconWrapper = styled.div`
  min-width: 30px;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
