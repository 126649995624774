import { memo, useState } from "react";

import { Popover } from "antd";
import { ALL_CHART_TYPES } from "constants/chart.constants";

import SlopeStyleSelector from "components/chart/SlopeStyleSelector";
import SlopeLines from "components/icons/SlopeLines";

import { useChartState } from "../../context";
import ToolbarButton from "../ToolbarButton";

const SlopeLinesToggle = () => {
  const { settings, screenshot } = useChartState();
  const [visible, setVisible] = useState(false);
  const isDisabled =
    settings.chartType !== ALL_CHART_TYPES.MaterialBalanceTime.label ||
    screenshot.visible;

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={(v) => setVisible(v)}
      content={<SlopeStyleSelector />}>
      <ToolbarButton
        active={
          visible ||
          settings.showHalfSlope ||
          settings.showQuarterSlope ||
          settings.showUnitSlope
        }
        icon={<SlopeLines />}
        overflowLabel={"Slope Line"}
        tooltipText={"Show Slope Lines"}
        onToggle={null}
        disabled={isDisabled}
      />
    </Popover>
  );
};

export default memo(SlopeLinesToggle);
