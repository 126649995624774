/* eslint-disable no-console */
import { getMapLayersMatchedToLayerList } from ".";

/** Can confirm output of mapbox layers are what we expect from the ordered list
 * */
export function debugMapboxSorting(mapboxLayers, mapboxSources, orderedLayerList) {
  const matchedMapboxLayers = getMapLayersMatchedToLayerList({
    layerList: orderedLayerList,
    mapboxLayers: mapboxLayers
  });

  const isOrderedLayerDataSame =
    JSON.stringify(matchedMapboxLayers) === JSON.stringify(orderedLayerList);

  console.log("Mapbox layers:", mapboxLayers);
  console.log("Mapbox sources:", mapboxSources);
  console.log("Mapbox layers after sort: ", matchedMapboxLayers);
  console.log(
    `Layers matching the layer list have been sorted: ${
      isOrderedLayerDataSame ? "Successfully" : "Unsuccessfully"
    }.`
  );
}
