import { IChartResult } from "../../../models/model";
import { removeCount, removeForecast } from "../../../utils";
import { getPrecisionBasedOnUnit } from "../utils/getPrecisionBasedOnUnit";

interface WellContributionDataTableParams {
  responseData: IChartResult;
  axisMinMax: { xMin: number | null; xMax: number | null };
}

export default function wellContributionDataTable({
  responseData,
  axisMinMax
}: WellContributionDataTableParams) {
  const xPrecision = getPrecisionBasedOnUnit(responseData?.layout?.xAxis?.title);
  const yPrecision = getPrecisionBasedOnUnit(responseData?.layout?.yAxis?.title);
  const min = axisMinMax?.xMin ?? null;
  const max = axisMinMax?.xMax ?? null;

  // const wellCount = responseData.series?.find((series) => series.label === "Well Count");

  const rows = responseData.series?.reduce((list, series) => {
    for (let i = 0; i < series.y.length; i++) {
      const x = responseData.layout.xAxis.data[i];
      const y = series.y[i];

      const isAboveMinThreshold = !min || x >= min;
      const isBelowMaxThreshold = !max || x <= max;
      if (isAboveMinThreshold && isBelowMaxThreshold && series.label !== "Well Count") {
        const row = {
          SeriesColor: series.style.hexColor,
          SeriesFull: series.label,
          Series: removeForecast(removeCount(series.label)),
          X: typeof x === "number" ? x.toFixed(xPrecision) : x,
          Y: typeof y === "number" ? y.toFixed(yPrecision) : y,
          // wellCount: wellCount?.y ? wellCount.y[i] : "",
          production: series.productionSource
        };
        list.push(row);
      }
    }
    return list;
  }, []);

  const columns = [
    { key: "X", name: responseData.layout.xAxis.title, resizable: false },
    { key: "Y", name: responseData.layout.yAxis.title, resizable: false },
    // { key: "wellCount", name: "Well Count", resizable: false },
    { key: "production", name: "Production Source", resizable: false }
  ];

  return {
    columns,
    rows
  };
}
