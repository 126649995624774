import { useQuery } from "react-query";

import axios from "axios";

const JOB_SERVICE_URL = process.env.REACT_APP_JOB_SERVICE;

export const VALNAV_JOBS_QUERY_KEYS = {
  valNavJobs: "valNavJobs"
};

const fetchJobs = async () => {
  try {
    return await axios.get<string[]>(`${JOB_SERVICE_URL}/api/v1/job`);
  } catch (error) {
    throw new Error("Unable to retrieve ValNav jobs.");
  }
};

export default function useValNavJobsQuery(isAutoRefetching?: boolean) {
  const queryInfo = useQuery({
    queryKey: [VALNAV_JOBS_QUERY_KEYS.valNavJobs],
    queryFn: fetchJobs,
    enabled: isAutoRefetching
  });

  return {
    data: queryInfo.data?.data,
    error: queryInfo.error,
    isLoading: queryInfo.isFetching,
    isError: queryInfo.isError,
    refetch: queryInfo.refetch
  };
}
