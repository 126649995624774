import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppProviders from "AppProviders";
import AppRouter from "AppRouter";
import axios from "axios";
import { cookies } from "constants/settings.constants";
import { setUser, updateTokenFromRefreshToken } from "store/features";
import { RootState } from "store/rootReducer";

import useOnWindowFocus from "hooks/useOnWindowFocus";

import { refreshToken } from "api/auth";

import "./App.css";

let tokenInterval: NodeJS.Timeout = null;
function App(): JSX.Element {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const currentJwtToken = useSelector((state: RootState) => state.auth.jwtToken);

  const history = useHistory();
  //tells the loading screen to redirect to either /app or /login
  const [doneLoading, setDoneLoading] = useState(false);

  const updateFromRefreshResponse = (user) => {
    if (user) {
      dispatch(setUser(user));
    } else {
      localStorage.removeItem(cookies.LAST_LOGGED_IN);
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          //try to refresh token
          if (user) {
            //unauthroized so we try to get a new token from refresh token
            const user = await refreshToken();
            updateFromRefreshResponse(user);
          } else if (history) {
            history.push("/login");
          }
        }
        return Promise.reject(error);
      }
    );
  }, [history, user]);

  // Get Token on load
  useEffect(() => {
    const refreshTokenOnExpiry = async () => {
      const lastLoggedIn = localStorage.getItem(cookies.LAST_LOGGED_IN);
      if (!lastLoggedIn) {
        setDoneLoading(true);
        return;
      }

      const user = await refreshToken();
      updateFromRefreshResponse(user);
      setDoneLoading(true);
    };

    refreshTokenOnExpiry();
  }, []);

  async function updateTokenIfAboutToExpire(jwtToken) {
    if (!currentJwtToken) {
      return;
    }

    const jwtTokenParsed = JSON.parse(atob(jwtToken.split(".")[1]));
    const expires = new Date(jwtTokenParsed.exp * 1000);

    // Check if token is about to expire
    const nowPlusMinutes = new Date();
    nowPlusMinutes.setMinutes(nowPlusMinutes.getMinutes() + 2);

    if (nowPlusMinutes >= expires) {
      dispatch(updateTokenFromRefreshToken(user.username));
    }
  }

  // Check for token expiry on window focus
  useOnWindowFocus(() => {
    if (currentJwtToken) {
      updateTokenIfAboutToExpire(currentJwtToken);
    }
  });

  useEffect(() => {
    if (tokenInterval) {
      clearInterval(tokenInterval);
      tokenInterval = null;
    }

    //run every minute
    tokenInterval = setInterval(async () => {
      updateTokenIfAboutToExpire(currentJwtToken);
    }, 60000);

    return () => {
      if (tokenInterval) {
        clearInterval(tokenInterval);
        tokenInterval = null;
      }
    };
  }, [currentJwtToken, dispatch]);

  return (
    <AppProviders>
      <div className="App">
        <AppRouter doneLoading={doneLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          closeButton={false}
          limit={1}
          enableMultiContainer
          pauseOnHover
        />
      </div>
    </AppProviders>
  );
}

export default App;
