import { memo } from "react";

function WaterInjection() {
  return (
    <svg width="32px" height="31px" viewBox="0 0 32 31">
      <g id="Group-2-Copy-2" transform="translate(1.125 1.125)">
        <g clipPath="url(#mask_134)">
          <g id="Group-2" transform="translate(0 2.4942544)">
            <path
              d="M39.084 2.27331C37.1127 2.27331 35.2766 1.81654 33.742 1.03092C31.0541 -0.34364 27.5726 -0.34364 24.8847 1.03092C23.3487 1.81654 21.514 2.27331 19.5427 2.27331C17.57 2.27331 15.7353 1.81654 14.1993 1.03092C11.5114 -0.34364 8.02989 -0.34364 5.34199 1.03092C3.8074 1.81654 1.9713 2.27331 0 2.27331"
              transform="translate(0 6.769231)"
              id="Stroke-1-Copy-5"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2.25"
            />
            <path
              d="M39.084 2.27331C37.1127 2.27331 35.2766 1.81654 33.742 1.03092C31.0541 -0.34364 27.5726 -0.34364 24.8847 1.03092C23.3487 1.81654 21.514 2.27331 19.5427 2.27331C17.57 2.27331 15.7353 1.81654 14.1993 1.03092C11.5114 -0.34364 8.02989 -0.34364 5.34199 1.03092C3.8074 1.81654 1.9713 2.27331 0 2.27331"
              transform="translate(0 13.108681)"
              id="Stroke-4-Copy-4"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2.25"
            />
            <path
              d="M39.084 2.27331C37.1127 2.27331 35.2766 1.81654 33.742 1.03092C31.0541 -0.34364 27.5726 -0.34364 24.8847 1.03092C23.3487 1.81654 21.514 2.27331 19.5427 2.27331C17.57 2.27331 15.7353 1.81654 14.1993 1.03092C11.5114 -0.34364 8.02989 -0.34364 5.34199 1.03092C3.8074 1.81654 1.9713 2.27331 0 2.27331"
              id="Stroke-4-Copy-6"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2.25"
            />
          </g>
        </g>
      </g>
      <g id="IconsChart---PRPWater-Inj" transform="translate(0.8408203 0.28393555)">
        <g id="Water-Inj" transform="translate(7.1584797 13.902119)">
          <path
            d="M5.61892 0L5.61892 5.61892L0 5.61892"
            transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 7.9703193 6.58203)"
            id="Rectangle"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <g id="Water-Copy">
            <g id="Group-5" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(WaterInjection);
