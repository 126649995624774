import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { Heading } from "components/base";

import "./Profile.scss";
import AccountProfile from "./components/profile/AccountProfile";

export default function Profile() {
  const user = useSelector((state: RootState) => state.auth.user);
  return (
    <div className="userAccount">
      <div className="userAccountHeader">
        <Heading element="h4">Your Profile</Heading>
      </div>

      <div className="userAccountContent">
        <div className="main">
          <AccountProfile user={user} />
        </div>
      </div>
    </div>
  );
}
