import { useMutation } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";

const deleteMap = async (data: { id: number; mapId: string }) => {
  const response = await axios.delete(
    `${GEO_MAP_SERVICE_ADDRESS}/map-layer/${data.id}/map/${data.mapId}`
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete GeoMapLayers");
  }
  return response.data;
};

const useDeleteMapFromLayer = () => {
  return useMutation(deleteMap);
};

export default useDeleteMapFromLayer;
