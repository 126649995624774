import { memo } from "react";

import { ViewModule } from "@material-ui/icons";

import { useChartSettings } from "components/multiphase-chart/context";

import { useChartPresetFetcher } from "../../../../hooks";
import ToolbarButton from "../../../toolbar/ToolbarButton";

const PresetsToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const { refetchPresets } = useChartPresetFetcher();

  const toggleSettingHandle = () => {
    const isSettingsOpenNextState: boolean = !chartSettings.isSettingsOpen;

    if (isSettingsOpenNextState) {
      refetchPresets();
    }

    chartSettingsDispatch({
      type: "toggle isSettingsOpen",
      isSettingsOpen: isSettingsOpenNextState
    });
  };

  return (
    <ToolbarButton
      key={"Toggle Presets"}
      testId={"multiphasechart-toolbar-settings-popover"}
      icon={<ViewModule />}
      active={chartSettings.isSettingsOpen}
      tooltipText="Open Presets"
      onToggle={toggleSettingHandle}
    />
  );
};

export default memo(PresetsToggle);
