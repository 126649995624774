import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import axios from "axios";
import { GEO_MAP_SERVICE_ADDRESS } from "constants/app.constants";

import { EditableProjectLayerListReturnT } from "components/project/layers/hooks";

import { SHAPEFILE_LIST_QUERY_KEYS } from "../../queries";

interface GeoTIFFMutationVariables {
  shapefileId: string;
  opacity: number;
}

interface GeoTIFFMutationResponse {
  success: boolean;
}

async function setGeoTIFFOpacity({
  shapefileId,
  opacity
}: GeoTIFFMutationVariables): Promise<GeoTIFFMutationResponse> {
  const response = await axios.put(
    `${GEO_MAP_SERVICE_ADDRESS}/geo-tiff/${shapefileId}/opacity`,
    {
      opacity
    }
  );

  if (response.status !== 200) {
    throw new Error("Network response was not ok");
  }

  return response.data;
}

export function useGeoTIFFOpacityMutation(params: {
  selectedProjectId: string;
  layerList: EditableProjectLayerListReturnT;
}): UseMutationResult<
  GeoTIFFMutationResponse,
  unknown,
  GeoTIFFMutationVariables,
  unknown
> {
  const { selectedProjectId, layerList } = params;
  const queryClient = useQueryClient();

  return useMutation<GeoTIFFMutationResponse, unknown, GeoTIFFMutationVariables>(
    setGeoTIFFOpacity,
    {
      onSuccess: () => {
        // queryClient.invalidateQueries({
        //   queryKey: [
        //     PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList,
        //     selectedProjectId
        //   ]
        // });

        // queryClient.invalidateQueries({
        //   queryKey: [
        //     PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles,
        //     selectedProjectId
        //   ]
        // });

        queryClient.invalidateQueries({
          queryKey: [
            SHAPEFILE_LIST_QUERY_KEYS.currentProjectShapefiles,
            selectedProjectId,
            layerList.checkedProjectShapefileIds,
            layerList.checkedProjectLinkedShapefileIds
          ]
        });
      }
    }
  );
}
