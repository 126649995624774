import MapboxDraw from "@mapbox/mapbox-gl-draw";
import circle from "@turf/circle";
import distance from "@turf/distance";
import * as turfHelpers from "@turf/helpers";

let isDrawing = false;

const DrawCircle = {
  ...MapboxDraw.modes.draw_polygon,

  onSetup: function () {
    const polygon = this.newFeature({
      type: "Feature",
      properties: {
        isCircle: true,
        center: []
      },
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    });
    this.map.dragPan.disable();
    this.addFeature(polygon);
    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: "add" });
    this.activateUIButton("polygon");
    this.setActionableState({
      trash: true
    });

    return {
      polygon
    };
  },

  onMouseDown: function (state, e) {
    const currentCenter = state.polygon.properties.center;
    if (currentCenter.length === 0) {
      state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
    }
  },

  onMouseUp: function (state) {
    isDrawing = false;
    return this.changeMode("simple_select", { featureIds: [state.polygon.id] });
  },

  handleCircleUpdates: function (state, e) {
    const center = state.startClickLngLat || state.polygon.properties.center;
    if (center.length > 0) {
      const distanceFromCenter = distance(
        turfHelpers.point(center),
        turfHelpers.point([e.lngLat.lng, e.lngLat.lat]),
        { units: "kilometers" }
      );

      const circleFeature = circle(center, distanceFromCenter);
      state.polygon.incomingCoords(circleFeature.geometry.coordinates);
      state.polygon.properties.radiusInKm = distanceFromCenter;
      isDrawing = true;
    }
  },

  onMouseMove: function (state, e) {
    this.handleCircleUpdates(state, e);
  },

  onDrag: function (state, e) {
    this.handleCircleUpdates(state, e);
  },

  onClick: function (state) {
    if (isDrawing) {
      isDrawing = false;
      return this.changeMode("simple_select", { featureIds: [state.polygon.id] });
    }
  },

  toDisplayFeatures: function (state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.polygon.id;
    geojson.properties.active = isActivePolygon ? "active" : "inactive";
    return display(geojson);
  }
};
export default DrawCircle;
