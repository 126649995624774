import { INormalizeBySetting } from "models";

import { EntityKind } from "./entityKind";
import { IBin } from "./groupBy";
import { PdenSourceEnum } from "./pdenDataSourceSetting";

export interface IQuery {
  operator: string;
  predicates: IPredicate[];
  children_nodes: IQuery[];
}

export default class FilterProperty {
  property: string;
  value: Array<string> | Array<number> | number | string;
  operator: string;

  constructor() {
    this.property = "";
    this.value = [];
    this.operator = "";
  }
}

export interface PolygonFilter {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coordinates: any[];
  source: string;
  operator: string;
}

export interface MapScreenshotPolygon {
  coordinates: number[][][];
  id: string;
  type: string;
}

export interface WellListFilterModel {
  filterType: string;
  id: string;
  operator: string;
  type: string;
  value: string[];
}

export interface IFilterState {
  polygonFilter: PolygonFilter;
  excludePolygonsFilter: unknown[];
  wellListFilter: WellListFilterModel[];
  propertiesFilter: PropertyFilterModel[];
}

export interface IPredicateModel {
  id: string;
  title: string;
  displayTitle: string;
  count: number; //the number of wells that the predicate captures
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actualValue?: any;
  expanded: boolean;
  operator: string;
  children: IPredicateModel[];
  type: string;
  isChecked: boolean;
}

export interface FilterModel {
  query: IQuery;
  previousFilterId: string;
  job_id: string;
  user_id: string;
  entityKind: string;
}

export interface IPredicate {
  id?: string;
  type: string;
  property: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoryId: any;
  canBin?: boolean;
  operator: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  bin?: IBin;
  source?: string;
  normalizeBySetting?: INormalizeBySetting;
  sourceKey?: string;
  negatedOperator: boolean;
  groupByTitle?: string;
  partialContextTitle?: string;
  isForecastToggleOn?: boolean;
  selectedForecastFolderName?: string;
  pdenSource: PdenSourceEnum;
}

export interface PropertyFilterModel {
  predicates: IPredicate[];

  //where the property came from
  source: string;
}

export interface IPredicateType {
  Property: string;
  WellList: string;
  Polygon: string;
}

export interface IPredicateOperator {
  And: string;
  Or: string;
}

export interface IPredicatePolygonOperator {
  Equals: string;
  NotEqual: string;
}

export interface IPredicatePropertyOperator {
  Between: string;
  NotBetween: string;
  GreaterThan: string;
  GreaterThanOrEqual: string;
  Equals: string;
  NotEqual: string;
  LessThan: string;
  LessThanOrEqual: string;
  In: string;
  NotIn: string;
}

export interface IPropertyLegendFilter {
  legendFilters: PropertyFilterModel[];
  propertyName: string;
}

export type FilterItemT = {
  name: string;
  filterJSON: string;
  id: string;
  filterId?: string;
};

export const ActiveFilterItem: FilterItemT = {
  name: "Active Filter",
  filterJSON: "",
  id: "",
  filterId: ""
};

export enum FilterType {
  SystemDefault = "System",
  UserDefault = "User"
}

export interface SavedFilterModel {
  filterJSON: string;
  id: string;
  name: string;
  owner: string;
  projectID: string;
  entityKind?: EntityKind;
  filterType?: FilterType;
}
