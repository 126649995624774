import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Button, Divider, Select } from "antd";
import {
  setActiveSetting,
  setUserSettingsOpen
} from "store/features/userSettings/userSettingsSlice";
import styled from "styled-components";

import { useLocalStorage } from "hooks";

import {
  useScreenshotContext,
  useScreenshotDispatch,
  useScreenshotPresetContext,
  useScreenshotPresetDispatch,
  useScreenshotPresets
} from "../hooks";
import { getStoredScreenshotPresetKey } from "../utils";

const PresetSelect = () => {
  // context and hooks
  const dispatch = useDispatch();
  const { presets } = useScreenshotPresets();
  const { selectedPreset } = useScreenshotPresetContext();

  const { widget } = useScreenshotContext();

  // Local Storage
  const { getStoredValue: getStoredScreenshotPreset } = useLocalStorage(
    getStoredScreenshotPresetKey(widget?.widgetId),
    undefined
  );

  // dispatch
  const screenshotDispatch = useScreenshotDispatch();
  const presetDispatch = useScreenshotPresetDispatch();

  // state
  const [value, setValue] = useState("");

  // derived state
  const presetOptions = presets.map((p) => ({
    label: p.title,
    value: p.id || ""
  }));

  // event handlers
  const handleChange = (nextId) => {
    setValue(nextId);

    const newPreset = presets.find((p) => p.id === nextId);

    presetDispatch({
      payload: {
        selectedPreset: newPreset
      }
    });

    screenshotDispatch({
      payload: {
        isChangingPreset: true
      }
    });
  };

  const openDisplaySettings = () => {
    dispatch(setActiveSetting("DisplaySettings"));
    dispatch(setUserSettingsOpen(true));
  };

  // Initial load
  useEffect(() => {
    const storedPreset = getStoredScreenshotPreset();
    const storedPresetId = storedPreset?.id;

    const id = storedPresetId ?? selectedPreset?.id ?? presets?.[0]?.id ?? "";
    setValue(id);

    const newPreset = presets.find((p) => p.id === id);

    presetDispatch({
      payload: {
        selectedPreset: newPreset
      }
    });
  }, []);

  return (
    <StyledSelect
      dropdownRender={(menu) => (
        <>
          {menu}
          <StyledDivider />
          <StyledButton
            type="link"
            icon={<AddIcon className="anticon" />}
            onClick={openDisplaySettings}>
            Add new
          </StyledButton>
        </>
      )}
      dropdownClassName="screenshot-selector"
      suffixIcon={<KeyboardArrowDownIcon fontSize="large" />}
      options={presetOptions}
      value={value}
      onChange={handleChange}
    />
  );
};

export default PresetSelect;

const StyledSelect = styled(Select)`
  width: 100%;
  z-index: 90;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;
const StyledButton = styled(Button)`
  padding: 0 10px;
  margin-bottom: 4px;
`;
