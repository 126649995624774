// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React from "react";

import { mdiContentSave, mdiDelete, mdiDotsVertical } from "@mdi/js";
import { Button, Dropdown, MenuProps, Popconfirm } from "antd";
import styled from "styled-components";

interface FieldTemplatePopoverActionsProps {
  template: string;
  saveMapAsNewTemplate: () => void;
  deleteSelectedTemplate: () => void;
}

export const FieldTemplatePopoverActions: React.FC<FieldTemplatePopoverActionsProps> = ({
  template,
  saveMapAsNewTemplate,
  deleteSelectedTemplate
}) => {
  const items: MenuProps["items"] = [
    [
      "Save Maps As New Template",
      null,
      saveMapAsNewTemplate,
      <Icon key="plus" path={mdiContentSave} size={1} />,
      () => true
    ],
    // ["Overwrite Template", saveMapAsNewTemplate,
    //   <Icon key="plus" path={mdiContentSave} size={1} />, () => template !== "Single"],
    [
      "Delete Selected Template",
      <Popconfirm
        key="delete-confirm"
        title={`Please confirm you want to delete the template "${template}"?`}
        onConfirm={() => deleteSelectedTemplate()}
        disabled={template === "Single"}
        okText="Delete"
        cancelText="Cancel">
        <Button style={{ width: "100%" }} type="text" disabled={template === "Single"}>
          Delete Selected Template
        </Button>
      </Popconfirm>,
      deleteSelectedTemplate,
      <Icon key="rename" path={mdiDelete} size={1} />,
      () => template !== "Single"
    ]
  ].map(([title, labelOverride, fn, icon, enabledFn]) => ({
    key: title as string,
    icon,
    label: labelOverride ?? (
      <Button
        style={{ width: "100%" }}
        type="text"
        disabled={!(enabledFn as () => boolean)()}
        onClick={() => {
          (fn as () => void)();
        }}>
        {title}
      </Button>
    ),
    onClick: (e) => {
      e.domEvent.stopPropagation();
      if (!labelOverride) {
        (fn as () => void)();
      }
    },
    disabled: !(enabledFn as () => boolean)()
  }));

  return (
    <DropdownWrapper>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button
          type="text"
          icon={<Icon path={mdiDotsVertical} size={1} />}
          onClick={(e) => e.stopPropagation()}
        />
      </Dropdown>
    </DropdownWrapper>
  );
};
const DropdownWrapper = styled.div`
  .ant-btn {
    min-width: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
