import { useEffect, useState } from "react";

import {
  FORECAST_KEY,
  MCDANIEL_SEARCH_FOLDER_NAME as MCDANIEL_RESEARCH_FOLDER_NAME,
  SYNC_KEY
} from "constants/settings.constants";
import { getCumPlusForecastTitle } from "utils";

import { IGroupByListColumn } from "models";

import { isSyncFolder } from "components/sync/util";

import { useGroupByState } from "../group-by.context";
import useSelectedCategory from "./useSelectedCategory";

/**
 * Returns the field list with forecast labelled title and property, if:
 * - performance category is selected, and
 * - forecast toggle is on
 */
const useFieldsWithForecastLabel = (
  list: IGroupByListColumn[]
): IGroupByListColumn[] | null => {
  const {
    isForecastToggleOn,
    selectedProduct,
    selectedForecastFolder,
    selectedForecastFolderName,
    selectedRescat
  } = useGroupByState();
  const { hasPerformaceFields, hasForecastFields } = useSelectedCategory();

  const [value, setValue] = useState<IGroupByListColumn[] | null>(null);

  useEffect(() => {
    if (!hasPerformaceFields && !hasForecastFields) {
      setValue(list);
      return;
    }

    const isSync = isSyncFolder(selectedForecastFolder);
    if (hasPerformaceFields && isSync && !isForecastToggleOn) {
      //change properties to append sync job to property
      const labelledList = list.map((item) => {
        const newItem = Object.assign({}, item, {
          property: item.property + `.${SYNC_KEY}.${selectedForecastFolder}`,
          title: item.title
        });
        return newItem;
      });
      setValue(labelledList);
      return;
    }

    if (!(hasPerformaceFields && isForecastToggleOn) && !hasForecastFields) {
      setValue(list);
      return;
    }
    let forecastFolderkey = "";
    if (selectedForecastFolder !== MCDANIEL_RESEARCH_FOLDER_NAME) {
      forecastFolderkey = `.${selectedForecastFolder}.${selectedRescat}`;
    }

    const labelledList = list.map((item) => {
      if (
        item.title.indexOf("Cum") < 5 &&
        item.title.indexOf("EUR") < 0 &&
        item.title.indexOf("Remaining") < 0 &&
        !hasForecastFields
      ) {
        if (isSync) {
          const newItem = Object.assign({}, item, {
            property: item.property + `.${SYNC_KEY}.${selectedForecastFolder}`,
            title: item.title
          });
          return newItem;
        }
        //not cum or EUR or remaining field
        return item;
      }

      const newItem = Object.assign({}, item, {
        property: item.property + `.${FORECAST_KEY}${forecastFolderkey}`,
        title: getCumPlusForecastTitle(item.title, "", item.subgroup)
      });
      if (hasForecastFields && selectedForecastFolder === MCDANIEL_RESEARCH_FOLDER_NAME) {
        //don't include extra if mcdaniel research
        newItem.property = item.property;
      }
      newItem.forecastFolder = selectedForecastFolderName;
      newItem.isForecastToggleOn = isForecastToggleOn;
      return newItem;
    });
    setValue(labelledList);
  }, [
    hasPerformaceFields,
    isForecastToggleOn,
    selectedProduct,
    selectedForecastFolder,
    selectedRescat,
    list,
    hasForecastFields
  ]);

  return value;
};

export default useFieldsWithForecastLabel;
