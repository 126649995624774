import { useMemo } from "react";

import _debounce from "lodash/debounce";

import { ColorPicker } from "components/base";
import { useProjectContext } from "components/project/projects/context";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { useUpdateProjectLinkedShapefile } from "components/project/shapefiles/hooks";

interface IProjectLinkedShapefileColorPickerProps {
  projectShapefileId: string;
  color: string;
  strokeColor: string;
  thickness: number;
  opacity: number;
}

export function ProjectLinkedShapefileColorPicker(
  props: IProjectLinkedShapefileColorPickerProps
) {
  const { projectShapefileId, color, strokeColor, thickness, opacity } = props;

  const { selectedProjectId } = useProjectContext();

  const projectPermissions = useSelectedProjectPermissions();

  const { updateProjectLinkedShapefile } = useUpdateProjectLinkedShapefile();

  // TODO BF: repeated code
  const debouncedUpdateProjectLinkedShapefileOpacity = useMemo(
    () =>
      _debounce(
        (value, projectShapefileId) =>
          updateProjectLinkedShapefile.mutate({
            body: {
              key: "opacity",
              value
            },
            projectId: selectedProjectId,
            projectShapefileId: projectShapefileId
          }),
        500
      ),
    [selectedProjectId]
  );

  return (
    <ColorPicker
      onChange={(key, value) =>
        updateProjectLinkedShapefile.mutate({
          body: {
            key,
            value
          },
          projectId: selectedProjectId,
          projectShapefileId: projectShapefileId
        })
      }
      onChangeOpacity={(key, value) =>
        debouncedUpdateProjectLinkedShapefileOpacity(value, projectShapefileId)
      }
      values={{
        color: color,
        strokeColor: strokeColor,
        thickness: thickness,
        opacity: (opacity ?? 1) * 100
      }}
      isReadOnly={!projectPermissions.canEditShapefiles}
    />
  );
}
