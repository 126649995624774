import { useDispatch, useSelector } from "react-redux";

import { setCumPlusForecast } from "store/features";
import { RootState } from "store/rootReducer";

const useCumPlusForecast = (): [boolean, (bool: boolean) => void] => {
  const state = useSelector((state: RootState) => state.groupBy.cumPlusForecast);

  const dispatch = useDispatch();
  const setState = (value: boolean) => {
    dispatch(setCumPlusForecast(value));
  };

  return [state, setState];
};

export default useCumPlusForecast;
