import InfoIcon from "@material-ui/icons/Info";
import styled from "styled-components";

import { Tooltip } from "components/base";

export type FieldListItemInfoT = {
  value: string;
};

function FieldListItemInfo({ value }: FieldListItemInfoT) {
  if (!value?.length) return <Wrapper />;

  return (
    <Wrapper>
      <Tooltip title={value} placement="right">
        <IconWrapper>
          <InfoIcon />
        </IconWrapper>
      </Tooltip>
    </Wrapper>
  );
}

export default FieldListItemInfo;

const Wrapper = styled.span`
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  color: var(--color-action-button);
  font-size: 16px;
`;
