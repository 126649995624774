/**
 * Gets alias of chart type from back end names
 *
 * @param {string} chartType chart type from back end
 * @returns {string} front end chart type
 */
const getChartTypeSystemName = (chartType: string): string => {
  switch (chartType) {
    case "Rate Cum":
      return "monthlyRateCum";
    case "Rate Time":
      return "monthlyRateTime";
    case "Rate Date":
      return "rateDate";
    case "Cum Time":
      return "cumTime";
  }
};

export default getChartTypeSystemName;
