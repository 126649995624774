import axios from "axios";

import { rescatStringToNumber } from "components/sync/util";

import { ArpSegment, ForecastConstant } from "../models/UserArpsModel";
import { Rescat } from "./userForecasts";

const JOB_SERVICE_URL = process.env.REACT_APP_JOB_SERVICE;

export async function fetchTypeWellLengths(
  job: string,
  typeWells: string[]
): Promise<{ [name: string]: number }> {
  const result = await axios.post<{ [name: string]: number }>(
    `${JOB_SERVICE_URL}/api/v1/hierarchy/${job}/lengths`,
    typeWells
  );
  if (result.status !== 200) {
    throw "Unable to retrieve valnav jobs.";
  }
  return result.data;
}
type ArpSegmentAndLengths = {
  lengths: { [name: string]: number };
  constants: { [name: string]: ForecastConstant[] };
  segments: { [name: string]: ArpSegment[] };
};

export async function fetchTypeWellSegmentsAndLengths(
  job: string,
  rescat: Rescat,
  typeWells: string[]
): Promise<ArpSegmentAndLengths> {
  const rescatNum = rescatStringToNumber(rescat);
  const result = await axios.post<ArpSegmentAndLengths>(
    `${JOB_SERVICE_URL}/api/v1/hierarchy/${job}/forecast/${rescatNum}`,
    typeWells
  );
  if (result.status !== 200) {
    throw "Unable to retrieve valnav jobs.";
  }
  return result.data;
}
