import { ALL_CHART_TYPES, ChartTypeLabels } from "constants/chart.constants";

export const getAverageSeriesToggleDisabledStatus = (chartType: ChartTypeLabels) => {
  if (
    [
      ALL_CHART_TYPES.RateCum.label,
      ALL_CHART_TYPES.RateTime.label,
      ALL_CHART_TYPES.CumTime.label,
      ALL_CHART_TYPES.MaterialBalanceTime.label
    ].includes(chartType)
  ) {
    return false;
  }
  return true;
};
