import MapboxDraw from "@mapbox/mapbox-gl-draw";

let isDrawing = false;
const DrawRectangle = {
  ...MapboxDraw.modes.draw_polygon,
  onSetup: function () {
    const rectangle = this.newFeature({
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    });
    this.map.dragPan.disable();
    this.addFeature(rectangle);
    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: "add" });
    this.setActionableState({
      trash: true
    });
    return {
      rectangle
    };
  },
  onMouseDown: function (state, e) {
    const startPoint = [e.lngLat.lng, e.lngLat.lat];
    if (!state.startPoint) {
      state.startPoint = startPoint;
    }
  },

  onMouseUp: function (state, e) {
    isDrawing = false;
    this.updateUIClasses({ mouse: "pointer" });
    state.endPoint = [e.lngLat.lng, e.lngLat.lat];
    return this.changeMode("simple_select", { featureIds: [state.rectangle.id] });
  },

  handleRectangleUpdates: function (state, e) {
    if (state.startPoint) {
      state.rectangle.updateCoordinate("0.0", state.startPoint[0], state.startPoint[1]);
      state.rectangle.updateCoordinate("0.1", e.lngLat.lng, state.startPoint[1]);
      state.rectangle.updateCoordinate("0.2", e.lngLat.lng, e.lngLat.lat);
      state.rectangle.updateCoordinate("0.3", state.startPoint[0], e.lngLat.lat);
      state.rectangle.updateCoordinate("0.4", state.startPoint[0], state.startPoint[1]);
      isDrawing = true;
    }
  },

  onMouseMove: function (state, e) {
    this.handleRectangleUpdates(state, e);
  },

  onDrag: function (state, e) {
    this.handleRectangleUpdates(state, e);
  },

  onClick: function (state, e) {
    if (isDrawing) {
      isDrawing = false;
      if (
        state.startPoint &&
        state.startPoint[0] !== e.lngLat.lng &&
        state.startPoint[1] !== e.lngLat.lat
      ) {
        this.updateUIClasses({ mouse: "pointer" });
        state.endPoint = [e.lngLat.lng, e.lngLat.lat];
        return this.changeMode("simple_select", { featureIds: [state.rectangle.id] });
      }
    }
  },

  onStop: function (state) {
    this.updateUIClasses({ mouse: "none" });
    this.activateUIButton();

    if (this.getFeature(state.rectangle.id) === undefined) return;
    state.rectangle.removeCoordinate("0.4");
    if (state.rectangle.isValid()) {
      this.map.fire("draw.create", {
        features: [state.rectangle.toGeoJSON()]
      });
    } else {
      this.deleteFeature([state.rectangle.id], { silent: true });
      this.changeMode("simple_select", {}, { silent: true });
    }
  },
  toDisplayFeatures: function (state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.rectangle.id;
    geojson.properties.active = isActivePolygon ? "true" : "false";
    if (!isActivePolygon) return display(geojson);

    if (!state.startPoint) return;
    return display(geojson);
  },
  onTrash: function (state) {
    this.deleteFeature([state.rectangle.id], { silent: true });
    this.changeMode("simple_select");
  },
  clickAnywhere: null
};

export default DrawRectangle;
