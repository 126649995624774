import { useMutation } from "react-query";

import axios from "axios";

import { MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";

const API_URL = `${MAP_SERVICE_ADDRESS}`;

export const GetContributingWellsKey = "contributing-wells";

export default function useMutateWellsEnvelope() {
  const fetchWellsEnvelope = async (facilityId?: string) => {
    if (!facilityId) {
      return Promise.resolve(null);
    }
    const response = await axios.get<{
      minX: number;
      minY: number;
      maxX: number;
      maxY: number;
    }>(`${API_URL}/map/envelope/facility/${facilityId}`);
    if (response.status !== 200) {
      return null;
    }
    return response?.data;
  };
  return useMutation([GetContributingWellsKey], (facilityId: string) =>
    fetchWellsEnvelope(facilityId)
  );
}
