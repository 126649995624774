import { memo } from "react";

function Vented() {
  return (
    <svg
      viewBox="180 180 100 100"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30">
      <defs />
      <g
        id="IconsChart---PRPGas"
        transform="matrix(2.647696, 0, 0, 2.160617, 209.880271, 242.858467)">
        <path
          transform="translate(-2, -8)"
          d="M19.4786 16.6962C19.141 11.248 9.59585 0 9.59585 0C7.43684 9.69182 -0.00861992 10.7961 7.49155e-06 17.1761C0.00539963 21.4812 1.95735 23.981 4.39244 25.3085C3.62136 22.73 3.93411 19.7374 6.24949 17.574C10.5244 13.5796 10.9946 10.8932 10.9946 10.8932C15.4172 18.7452 14.3323 23.679 12.6661 26.1669C16.7232 25.0465 19.7881 21.6839 19.4786 16.6962"
          id="Gas"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <path
        transform="translate(-2.5, -15)"
        d="M 225.202 195.521 L 216.493 208.806 L 225.202 222.622 L 215.931 238.033 L 210.874 235.642 L 218.459 222.622 L 209.75 208.806 L 220.145 193.13 L 225.202 195.521 M 242.621 195.255 L 233.911 208.806 L 242.621 222.091 L 233.35 237.502 L 228.292 235.11 L 235.878 222.091 L 227.169 208.806 L 237.564 192.864 L 242.621 195.255 M 260.32 195.255 L 251.611 208.806 L 260.32 222.091 L 251.049 237.502 L 245.992 235.11 L 253.578 222.091 L 244.868 208.806 L 255.263 192.864 L 260.32 195.255"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export default memo(Vented);
