import { MutableRefObject, useEffect, useRef, useState } from "react";

const useHover = (): [MutableRefObject<HTMLElement>, boolean] => {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<HTMLElement | null>(null);

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);
    }

    return (): void => {
      if (node) {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      }
    };
  }, [ref.current]);

  return [ref, value];
};

export default useHover;
