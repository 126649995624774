import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { Error } from "@material-ui/icons";
import styled from "styled-components";

import { ShaleProfileModel, addShaleProfileCredential } from "api/dataSource";

import { BaseButton, BaseInput } from "components/base";
import BaseModal from "components/base/BaseModal";
import PasswordInput from "components/base/PasswordInput";

function formatKey(key) {
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
}

function validateString(key, value) {
  let error = null;
  if (!value.length) {
    error = `${formatKey(key)} cannot be blank`;
  }
  return error;
}
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 36px;

  .error-message {
    padding: 5px;
    color: red;
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function AddShaleProfileModal({
  open,
  onClose,
  orgId,
  name
}): JSX.Element {
  const [formValues, setFormValues] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });
  const mutation = useMutation(async (data: ShaleProfileModel) => {
    setSavingDataSource(true);
    const result = await addShaleProfileCredential(orgId, data);
    setSavingDataSource(false);
    if (result.ok) {
      onClose && onClose();
      toast.success("Succesfully added Novi data source.");
    }
    return result;
  });
  const [savingDataSource, setSavingDataSource] = useState(false);

  const hasErrors = Object.keys(errors).some((key) => errors[key]);

  function handleFormChange(key, value) {
    setFormValues((prevState) => ({ ...prevState, [key]: value }));
    setErrors((prevState) => ({
      ...prevState,
      [key]: validateString(key, value)
    }));
  }

  function validateFields() {
    let currErrors = false;
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key].length) {
        handleFormChange(key, formValues[key]);
        currErrors = true;
      }
    });
    return currErrors;
  }

  async function saveDataSource() {
    mutation.mutate(formValues);
  }

  function onSubmit() {
    const currErrors = validateFields();
    if (currErrors) return;
    saveDataSource();
    setSavingDataSource(true);
  }

  return (
    <BaseModal
      title={`Add ${name} Credentials`}
      open={open}
      onClose={() => onClose()}
      size="sm"
      className="add-data-source-dialog">
      <div className="flex-column container">
        <div className="flex-column">
          <p>Email</p>
          <div className="flex-row justify-between items-center">
            <div className="flex-column">
              <BaseInput
                value={formValues.email}
                onChange={(value) => handleFormChange("email", value)}
                errors={errors.email}
              />
            </div>
          </div>
          <p>Password</p>
          <PasswordInput
            value={formValues.password}
            onChange={(value) => handleFormChange("password", value)}
            errors={errors.password}
          />
        </div>
      </div>
      <div className="flex-row justify-center">
        <ButtonContainer className="flex-row justify-center">
          {savingDataSource && (
            <div>Checking access to Novi, this can take a minute...</div>
          )}
          <div className="error-message">
            {mutation.data && mutation.data.ok === false && <Error />}
            {mutation.data && mutation.data.ok === false
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                mutation.data?.error ?? "an error occurred"
              : ""}
          </div>
          <BaseButton
            appearance="primary"
            onClick={onSubmit}
            isDisabled={hasErrors || savingDataSource}
            isLoading={savingDataSource}>
            Add Data Source
          </BaseButton>
        </ButtonContainer>
      </div>
    </BaseModal>
  );
}
