import { useContext } from "react";

import { ScreenshotPresetContext } from "../contexts/ScreenshotPresetContext";

export function useScreenshotPresetContext() {
  const context = useContext(ScreenshotPresetContext);
  if (context === undefined) {
    throw new Error(
      "useScreenshotPresetContext must be within a screenshot preset provider"
    );
  }
  return context;
}
