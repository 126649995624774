import { Dashboard } from "models/workspace";

export const DASHBOARDLAYOUTHASSCROLLMESSAGE =
  "The current layout will result in scrolling to see all widgets.";
export const assessLayout = (dashboard: Dashboard): string => {
  if (!dashboard?.widgets) return "";

  const maxWidth = dashboard.layout?.width ?? 12;
  const maxHeight = dashboard.layout?.height ?? 12;

  let exceedsIdealSize = false;
  dashboard.widgets.forEach((w) => {
    if (w.y + w.height > maxHeight) {
      exceedsIdealSize = true;
      return;
    }
    if (w.x + w.width > maxWidth) {
      exceedsIdealSize = true;
      return;
    }
  });

  if (exceedsIdealSize) {
    return DASHBOARDLAYOUTHASSCROLLMESSAGE;
  }

  return "";
};
