import { memo } from "react";

import "./MapToolbar.scss";

function MapToolbar({ itemCount = 12, children, className = "" }) {
  return (
    <div className={`MapToolbar ${className}`}>
      <div className="backpane" />
      <div
        className="wrapper"
        style={{
          gridTemplateColumns: `repeat(${itemCount}, max-content)`
        }}>
        {children}
      </div>
    </div>
  );
}
export default memo(MapToolbar);
