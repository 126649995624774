import { Field } from "./mapLayer";

export class GeoMapSettings {
  id: { $oid: string };
  customFields: Field[];
  systemFields: Field[];
  created?: { date: string; userId: string };
  updated?: { date: string; userId: string };

  constructor(
    id: { $oid: string },
    customFields: Field[],
    systemFields: Field[],
    created?: {
      date: string;
      userId: string;
    },
    updated?: { date: string; userId: string }
  ) {
    this.id = id;
    this.customFields = customFields ?? [];
    this.systemFields = systemFields ?? [];
    this.created = created;
    this.updated = updated;
  }

  getAllSettings() {
    return this.systemFields.concat(this.customFields);
  }
}
