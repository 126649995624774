import { useEffect, useRef, useState } from "react";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { TreeSelect } from "antd";
import { getProductLabel } from "constants/chart.constants";
import styled from "styled-components/macro";

import useBetaFeatures from "hooks/useBetaFeatures";

import { IGroupColumn } from "models/columns";

import { EntityKind } from "../../models/entityKind";
import { useTable } from "./context";
import { useColumnChooserSize } from "./hooks";

export interface ColumnChooserInputModel {
  categories: IGroupColumn[];
  selectedPropertyKeys: string[];
  onPropertiesChanged: (propertyKeys: string[]) => void;
}

interface IColumnNode {
  children: IColumnNode[];
  key: string;
  value: string;
  name: string;
  type: "node" | "column";
  memberOf: string[];
  properties: string[];

  [x: string | number | symbol]: unknown;
}

interface IColumnNodeMap {
  [key: string]: IColumnNode;
}

export default function DataTableColumnChooser(
  input: ColumnChooserInputModel
): JSX.Element {
  const ref = useRef();
  useColumnChooserSize(ref);
  const [{ entityKind }] = useTable();
  const [searchText, setSearchText] = useState("");
  const [selectedNodes, setSelectedNodes] = useState<IColumnNode[]>([]);
  const [nodeMap, setNodeMap] = useState<IColumnNodeMap>({});
  const [treeData, setTreeData] = useState<IColumnNode[]>([]);
  const [defaultLoaded, setDefaultLoaded] = useState(false);
  const [publishPropertiesChanged, setPublishPropertiesChanged] = useState(false);

  const { hasFeature } = useBetaFeatures();

  useEffect(() => {
    const newTreeData: IColumnNode[] = input.categories.map((group) => {
      const properties = [];
      const children = [];
      children.push(
        ...group.subgroups
          .map((subgroup) => {
            const subkey = `${group.key}.${subgroup.key}`;
            const subproperties = [];
            const columns = [];
            subproperties.push(
              ...subgroup.columns
                .filter(
                  (column) =>
                    column.property !== "Header.EntityName" &&
                    column.property !== "Header.FacilityId"
                )
                .map((column) => column.property)
            );
            properties.push(...subproperties);
            columns.push(
              ...subgroup.columns.reduce((list, column) => {
                const key = column.property;
                // Entity name is always selected, so remove from tree.
                if (key === "Header.EntityName" || key === "Header.FacilityId") {
                  return list;
                }
                list.push({
                  title: (
                    <span
                      key={key}
                      style={{
                        color: group.hexColor
                      }}>
                      {column.title}
                    </span>
                  ),
                  key: key,
                  value: key,
                  name: column.title,
                  type: "column",
                  memberOf: [group.key, subkey],
                  children: [],
                  sortOrder: column.sortOrder
                });
                return list;
              }, [])
            );
            return {
              title: (
                <span
                  key={subkey}
                  style={{
                    color: group.hexColor
                  }}>
                  {subgroup.title}
                </span>
              ),
              key: subkey,
              value: subkey,
              name: subgroup.title,
              type: "node",
              memberOf: [group.key],
              properties: subproperties,
              children: columns,
              sortOrder: subgroup.sortOrder
            };
          })
          .filter(
            (subgroup) => hasFeature("3DV Columns") || subgroup.key !== "Geology.3DV"
          )
      );

      children.push(
        ...group.productGroups.map((productGroup) => {
          const prodchildren = [];
          const prodkey = `${group.key}.${productGroup.productKey}`;
          const prodproperties = [];
          prodchildren.push(
            ...productGroup.subgroups.map((subgroup) => {
              const subkey = `${group.key}.${productGroup.productKey}.${subgroup.key}`;
              const subproperties = [];
              const columns = [];
              subproperties.push(...subgroup.columns.map((column) => column.property));
              prodproperties.push(...subproperties);
              properties.push(...subproperties);
              columns.push(
                ...subgroup.columns.map((column) => {
                  const key = column.property;
                  return {
                    title: (
                      <span
                        key={key}
                        style={{
                          color: group.hexColor
                        }}>
                        {column.title}
                      </span>
                    ),
                    key: key,
                    value: key,
                    name: column.title,
                    type: "column",
                    memberOf: [group.key, prodkey, subkey],
                    children: [],
                    sortOrder: column.sortOrder
                  };
                })
              );
              return {
                title: (
                  <span
                    key={subkey}
                    style={{
                      color: group.hexColor
                    }}>
                    {subgroup.title}
                  </span>
                ),
                key: subkey,
                value: subkey,
                name: subgroup.title,
                type: "node",
                memberOf: [group.key, prodkey],
                properties: subproperties,
                children: columns,
                sortOrder: subgroup.sortOrder
              };
            })
          );
          return {
            title: (
              <span
                key={prodkey}
                style={{
                  color: group.hexColor
                }}>
                {getProductLabel(productGroup.productDisplayName, entityKind)}
              </span>
            ),
            key: prodkey,
            value: prodkey,
            name: productGroup.productDisplayName,
            type: "node",
            memberOf: [group.key],
            properties: prodproperties,
            children: prodchildren
          };
        })
      );
      return {
        title: (
          <span
            key={group.key}
            style={{
              color: group.hexColor
            }}>
            {group.title}
          </span>
        ),
        key: group.key,
        value: group.key,
        name: group.title,
        type: "node",
        memberOf: [],
        properties: properties,
        children: children,
        sortOrder: group.sortOrder
      };
    });

    setTreeData(newTreeData);
  }, [input.categories]);

  useEffect(() => {
    if (treeData && treeData.length > 0) {
      if (
        !defaultLoaded &&
        selectedNodes.length === 0 &&
        (!input.selectedPropertyKeys || input.selectedPropertyKeys.length === 0)
      ) {
        // Default the selection to the Header group.
        const defaultNode = treeData[0];
        setSelectedNodes([defaultNode]);
        setDefaultLoaded(true);
        setPublishPropertiesChanged(true);
      }

      const getColumnNodes = (node) => {
        const nodes = [];
        if (node.children) {
          nodes.push(
            ...node.children.flatMap((n) => {
              return getColumnNodes(n);
            })
          );
        }

        if (node.type === "column") {
          nodes.push(node);
        }

        return nodes;
      };
      const newNodeMap: IColumnNodeMap = {};
      treeData.forEach((node) => {
        const columnNodes = getColumnNodes(node);
        columnNodes.forEach((columnNode) => {
          newNodeMap[columnNode.key] = columnNode;
        });
      });
      setNodeMap(newNodeMap);
    }
  }, [treeData]);

  useEffect(() => {
    if (selectedNodes && publishPropertiesChanged) {
      const groupOrderMap = {};
      input.categories.forEach((c) => {
        groupOrderMap[c.key.split(" ").join("_")] = c.sortOrder;
      });
      const properties = selectedNodes
        .reduce((list, n) => {
          if (n.type === "column") {
            list.push(n.key);
          } else if (n.type === "node") {
            list.push(...n.properties);
          }
          return list;
        }, [])
        .sort((a, b) => {
          if (!a || !b || !a.includes(".") || !b.includes(".")) {
            return 0;
          }
          // TODO: REMOVE PROPERTY NAME SPLITTING
          const aGroup = groupOrderMap[a.split(".")[0]];
          const bGroup = groupOrderMap[b.split(".")[0]];
          if (aGroup || bGroup) {
            if (!aGroup) return 1;
            if (!bGroup) return -1;
            return aGroup - bGroup;
          }

          return 0;
        });

      // Entity name is always selected, so remove from tree.
      input.onPropertiesChanged &&
        input.onPropertiesChanged(
          entityKind == EntityKind.Well
            ? ["Header.EntityName", ...properties]
            : ["Header.FacilityId", ...properties]
        );
      setPublishPropertiesChanged(false);
    }
  }, [selectedNodes, publishPropertiesChanged]);

  useEffect(() => {
    if (input.selectedPropertyKeys) {
      const newSelectedNodes = [];
      input.selectedPropertyKeys.forEach((key) => {
        const node = nodeMap[key];
        if (node) {
          newSelectedNodes.push(node);
        }
      });
      setSelectedNodes(newSelectedNodes);
    }
  }, [nodeMap, input.selectedPropertyKeys]);

  if (!input.categories) {
    return null;
  }

  const onColumnSelected = (key, node) => {
    // Deselect any nodes that are a child of the newly selected node.
    const nodes = [...selectedNodes].filter(
      (n) => !n.memberOf || !n.memberOf.includes(key)
    );
    nodes.push(node);
    setSelectedNodes(nodes);
    setPublishPropertiesChanged(true);
  };

  const onColumnDeselected = (key, node) => {
    let modified = false;
    const nodes = [...selectedNodes];
    const index = nodes.map((n) => n.key).indexOf(key);
    if (index >= 0) {
      nodes.splice(index, 1);
      modified = true;
    }

    // Determine if any children need to be deselected
    if (node.type === "node") {
      node.properties.forEach((propertyKey) => {
        const cindex = nodes.map((n) => n.key).indexOf(propertyKey);
        if (cindex >= 0) {
          nodes.splice(cindex, 1);
          modified = true;
        }
      });
    }

    if (modified) {
      setSelectedNodes(nodes);
      setPublishPropertiesChanged(true);
    }
  };

  return (
    <Wrapper ref={ref}>
      <TreeSelect
        allowClear
        placeholder="Please select"
        searchValue={searchText}
        showArrow
        showSearch
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        suffixIcon={<KeyboardArrowDownIcon fontSize="large" />}
        switcherIcon={
          <SwitcherIcon role="img">
            <KeyboardArrowDownIcon fontSize="large" />
          </SwitcherIcon>
        }
        treeCheckable
        treeData={treeData}
        treeNodeFilterProp="name"
        value={selectedNodes.map((n) => n.key)}
        onClear={() => {
          setSelectedNodes([]);
          setPublishPropertiesChanged(true);
        }}
        onDeselect={onColumnDeselected}
        onSelect={onColumnSelected}
        onSearch={(v) => setSearchText(v)}
        onDropdownVisibleChange={(v) => v || setSearchText("")}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
`;

const SwitcherIcon = styled.span`
  transform: translateY(2px);
  color: rgba(var(--color-text-rgb), 0.3);
`;
