import { useMutation } from "react-query";

import { getUserDocumentationPostsCount } from "api/userDocumentation";

export default function useGetNewPostsCount(
  handlePostData: (postData) => void,
  handleError: (err) => void = null
) {
  const { isLoading, isError, error, mutate, data } = useMutation(
    "getNewPosts",
    async (since: Date) => {
      const result = await getUserDocumentationPostsCount(since);
      return result;
    },
    {
      onSuccess: (postData) => {
        handlePostData(postData);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );

  return { isLoading, isError, error, mutate, data };
}
