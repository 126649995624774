import styled, { css } from "styled-components";

import FieldList from "./FieldList";
import MetadataFilterToolbar from "./MetadataFilterToolbar";
import OrgGeoCategory from "./OrgGeoCategory";
import ProductToolbar from "./ProductToolbar";
import ShapefileCategory from "./ShapefileCategory";
import UserFieldToolbar from "./UserFieldToolbar";
import { MAX_SEARCH_RESULTS, useGroupByState } from "./group-by.context";

function FilterableFieldList(): JSX.Element {
  const { isFiltering } = useGroupByState();

  return (
    <Wrapper>
      <ToolbarContainer isVisible={!isFiltering}>
        <MetadataFilterToolbar />
        <ProductToolbar />
        <UserFieldToolbar />
        <OrgGeoCategory />
        <ShapefileCategory />
      </ToolbarContainer>
      <ScrollableContainer>
        <FieldList />
      </ScrollableContainer>
      <SearchInfoContainer isVisible={isFiltering}>
        {`Limited to ${MAX_SEARCH_RESULTS} results`}
      </SearchInfoContainer>
    </Wrapper>
  );
}

export default FilterableFieldList;

const Wrapper = styled.div`
  gap: 5px;
  width: var(--field-col-width);
  display: grid;
  align-content: start;
  justify-content: stretch;
  background-color: var(--color-white);
  font-size: 1.3rem;
  overflow: hidden;
  grid-template-rows: auto 1fr auto;
`;

const ScrollableContainer = styled.div`
  width: var(--field-col-width);
  overflow: hidden overlay;
`;

const ToolbarContainer = styled.div`
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  padding: 0;
  overflow: hidden;

  ${(props) => {
    if (props.isVisible) {
      return css`
        max-height: none;
        opacity: 1;

        &:not(:empty) {
          background-color: #f4f6f6;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          padding: var(--space-2) 0;
        }
      `;
    }
  }}
`;

const SearchInfoContainer = styled.div`
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;

  ${(props) =>
    props.isVisible &&
    css`
      max-height: none;
      opacity: 1;
      padding: var(--space-2);
    `}

  background-color: #f4f6f6;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  font-style: italic;
`;
