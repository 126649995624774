import { memo } from "react";

function CSOR() {
  return (
    <svg width="30px" height="31px" viewBox="0 0 30 31">
      <defs>
        <path d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use href="#path_1" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPCWB">
        <g id="Group-7-Copy-6" transform="translate(19.670078 13.396588)">
          <path
            d="M2.70318 0C2.92296 3.76904 -0.208724 3.77485 0.0110584 7.54389"
            transform="translate(0 1.1181614)"
            id="Stroke-1-Copy-6"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M2.89278 1.80883e-17C3.88457 4.96573 -0.566641 4.8551 0.0609129 10.4272"
            transform="translate(3.3843336 0)"
            id="Stroke-3-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M2.70318 0C2.92296 3.76904 -0.208724 3.77485 0.0110584 7.54389"
            transform="translate(6.9757495 1.7725434)"
            id="Stroke-5-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M3.83595 0L3.83595 3.83595L2.12938e-16 3.83595"
            transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 4.5181155 9.7026415)"
            id="Rectangle-Copy-15"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
        <g id="Group-2-Copy" transform="translate(3 -1.1368684E-13)">
          <path
            d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z"
            id="Mask-Copy"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <g id="Group-2" transform="translate(0 1.2340614)">
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                id="Stroke-1-Copy-3"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                transform="translate(0 4.0531893)"
                id="Stroke-4-Copy-3"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
            </g>
          </g>
        </g>
        <path
          d="M0 18.464L18.5964 0"
          transform="translate(11.085341 1.8905864)"
          id="Path-8-Copy-7"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <path
          d="M5.30686 2L5.30686 0L0 0L2.22569 3.30138L0 6.63918L5.30686 6.63918L5.30686 4.63918"
          transform="translate(3.1976929 21.112848)"
          id="Path-8"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default memo(CSOR);
