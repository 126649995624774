import { useMutation, useQueryClient } from "react-query";

import axios, { AxiosError } from "axios";

import { SERVICE_ACCOUNT_TOKEN_ADDRESS } from "../modals/constants";

export interface CreateTokenModel {
  name: string;
  expiresAt?: Date;
}

export function usePostApiToken() {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, CreateTokenModel>(
    async (data: CreateTokenModel) => {
      const response = await axios.post(`${SERVICE_ACCOUNT_TOKEN_ADDRESS}/organization`, {
        name: data.name,
        expiresAt: data.expiresAt?.toISOString()
      });

      if (response.status !== 201) {
        throw new Error("Error creating access token");
      }

      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("apiTokens");
      }
    }
  );
}
