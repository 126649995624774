import { useQuery } from "react-query";

import { fetchUserDataSources } from "api/dataSource";

interface UseForecastPreferenceQueryParamsT {
  onSuccess?: (data) => void;
  onError?: (error) => void;
  isAutoRefetching?: boolean;
}

interface UseForecastPreferenceQueryReturnT {
  data;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export default function useForecastPreferenceQuery(
  params: UseForecastPreferenceQueryParamsT = {}
): UseForecastPreferenceQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true } = params;

  const queryInfo = useQuery({
    queryKey: "dataSources",
    queryFn: async () => {
      const response = await fetchUserDataSources();
      if (response.ok) {
        const forecast = response.value.filter((ds) => ds.category === "ForecastData");
        if (forecast.length == 1) {
          return forecast[0].dataSources;
        }
      }
      return null;
    },
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching
  });

  return {
    data: queryInfo.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
