import { memo } from "react";

import "./BaseDivider.scss";

function BaseDivider({ vertical = false, sharp = false, className = "", style = {} }) {
  return (
    <div
      style={style}
      className={`base-divider ${className} ${sharp ? "sharp" : ""} ${
        vertical ? "vertical" : ""
      } ${!vertical ? "horizontal" : ""} `}></div>
  );
}

export default memo(BaseDivider);
