// temporary placing here
export const PROPERTY_SHAPEFILE_TYPE = "-property";
export const POINT_SHAPEFILE_TYPE = "-point";
export const STROKE_SHAPEFILE_TYPE = "-stroke";
export const LABEL_SHAPEFILE_TYPE = "-shapefile-label";

export const shapefileTypes = [
  "",
  PROPERTY_SHAPEFILE_TYPE,
  POINT_SHAPEFILE_TYPE,
  STROKE_SHAPEFILE_TYPE,
  LABEL_SHAPEFILE_TYPE
];
