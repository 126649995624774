// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  mdiBeta,
  mdiDatabaseClock,
  mdiDatabaseSearch,
  mdiViewModule,
  mdiWeb
} from "@mdi/js";
import {
  setActiveSetting,
  setApplicationToolsOpen
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BaseButton, ErrorBoundary, SubHeading } from "components/base";
import DataAnalyzer from "components/data-analyzer/DataAnalyzer";
import DataFreshnessAnalyzer from "components/data-analyzer/DataFreshnessAnalyzer";
import { NavigationBar } from "components/navigation";
import { BetaFeatures, EnvironmentSettings, Subscriptions } from "components/tools";
import DocumentationDrawer from "components/user-documentation/DocumentationDrawer";

const useStyles = makeStyles({
  navItems: {
    fontSize: "1.2em",
    textAlign: "left",
    display: "inline"
  }
});

export default function AppTools({ authenticated: isAuthenticated }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activeSetting = useSelector(
    (state: RootState) => state.userSetting.activeSetting
  );

  const components = {
    BetaFeatures: <BetaFeatures />,
    Environment: <EnvironmentSettings />,
    DataFreshness: <DataFreshnessAnalyzer />,
    DataAnalyzers: <DataAnalyzer />,
    Subscriptions: <Subscriptions />
  };
  return (
    <ViewOverlay>
      <Wrapper>
        <NavigationBar showDashboardButtons={false} />
        <div className="settings-content">
          <div className="settings-side-bar">
            <NavContainer>
              <ul className="settings-nav">
                <li className="back-dashboard">
                  <BaseButton
                    appearance="subtle"
                    className={classes.navItems}
                    onClick={() => dispatch(setApplicationToolsOpen(false))}>
                    <div>
                      <KeyboardBackspaceIcon style={{ fontSize: "24px" }} />
                    </div>
                    Back to dashboard
                  </BaseButton>
                </li>
                <div className="settings-section">
                  <SubHeading>General</SubHeading>
                  <li
                    className={`settings-nav-item ${
                      activeSetting === "Subscriptions" ? "active" : ""
                    }`}>
                    <BaseButton
                      appearance="subtle"
                      className={classes.navItems}
                      onClick={() => dispatch(setActiveSetting("Subscriptions"))}>
                      <NavItem>
                        <IconWrapper>
                          <Icon path={mdiViewModule} size={1.6} />
                        </IconWrapper>
                        <Title> Subscriptions</Title>
                      </NavItem>
                    </BaseButton>
                  </li>
                  <li
                    className={`settings-nav-item ${
                      activeSetting === "BetaFeatures" ? "active" : ""
                    }`}>
                    <BaseButton
                      appearance="subtle"
                      className={classes.navItems}
                      onClick={() => dispatch(setActiveSetting("BetaFeatures"))}>
                      <NavItem>
                        <IconWrapper>
                          <Icon path={mdiBeta} size={1.6} />
                        </IconWrapper>
                        <Title> Beta Features</Title>
                      </NavItem>
                    </BaseButton>
                  </li>
                  <li
                    className={`settings-nav-item ${
                      activeSetting === "Environment" ? "active" : ""
                    }`}>
                    <BaseButton
                      appearance="subtle"
                      className={classes.navItems}
                      onClick={() => dispatch(setActiveSetting("Environment"))}>
                      <NavItem>
                        <IconWrapper>
                          <Icon path={mdiWeb} size={1.6} />
                        </IconWrapper>
                        <Title> Environment</Title>
                      </NavItem>
                    </BaseButton>
                  </li>
                </div>
                <div className="settings-section">
                  <SubHeading>Data Tools</SubHeading>
                  <li
                    className={`settings-nav-item ${
                      activeSetting === "DataFreshness" ? "active" : ""
                    }`}>
                    <BaseButton
                      appearance="subtle"
                      className={classes.navItems}
                      onClick={() => dispatch(setActiveSetting("DataFreshness"))}>
                      <NavItem>
                        <IconWrapper>
                          <Icon path={mdiDatabaseClock} size={1.6} />
                        </IconWrapper>
                        <Title> Data Freshness</Title>
                      </NavItem>
                    </BaseButton>
                  </li>
                  <li
                    className={`settings-nav-item ${
                      activeSetting === "DataAnalyzers" ? "active" : ""
                    }`}>
                    <BaseButton
                      appearance="subtle"
                      className={classes.navItems}
                      onClick={() => dispatch(setActiveSetting("DataAnalyzers"))}>
                      <NavItem>
                        <IconWrapper>
                          <Icon path={mdiDatabaseSearch} size={1.6} />
                        </IconWrapper>
                        <Title> Data Analyzers</Title>
                      </NavItem>
                    </BaseButton>
                  </li>
                </div>
              </ul>
            </NavContainer>
          </div>
          <ComponentContainer>
            <ErrorBoundary>
              {isAuthenticated ? components[activeSetting] : null}
            </ErrorBoundary>
          </ComponentContainer>
        </div>
        <DocumentationDrawer />
      </Wrapper>
    </ViewOverlay>
  );
}

const ViewOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  width: 100%;
  height: 100%;

  .settings-content {
    background-color: var(--color-surface-02);
    display: grid;
    grid-template-columns: 320px minmax(0, 1fr);
    height: 100%;

    .settings-side-bar {
      background-color: var(--color-text);
    }
  }
`;

const Title = styled.div`
  height: 100%;
  display: flex;
`;

const IconWrapper = styled.div`
  min-width: 30px;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ComponentContainer = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 30px;
  text-align: left;
  padding: 32px 100px;
  width: 100%;
  min-width: 500px;
  margin: 0;
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
`;

const NavContainer = styled.div`
  height: calc(100vh - var(--navbar-height));
  .settings-nav {
    height: 100%;
    font-size: 1.6rem;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow-y: overlay;

    li.back-dashboard .button {
      svg {
        top: 5px;
        margin-right: 2px;
      }
    }

    .sub-heading,
    li.back-dashboard .button {
      color: rgba(var(--color-shadow-rgb), 0.5);
      font-weight: var(--fontWeightRegular);
      text-align: left;
      text-transform: none;
      font-size: 1.6rem;
      margin: 0;
    }
    li .button {
      margin: 0;
      padding: 0;
      .label {
        margin: 0;
      }
      &:hover {
        color: var(--color-primary);
      }
      svg {
        top: 5px;
        margin-right: 8px;
      }
      span > svg {
        font-size: 17px;
        top: 2px;
      }
    }
    .settings-section {
      display: grid;
      gap: 20px;
    }
    .settings-nav-item {
      button {
        width: 100%;
        min-height: 30px;
        font-size: 1.6rem;
        color: #b5b5b5;
        font-weight: var(--fontWeightRegular);
        &:hover {
          color: var(--color-primary);
        }
      }
      svg {
        top: 4px;
        margin-right: 10px;
      }
    }
    .settings-nav-item.active {
      .button {
        color: var(--color-primary);
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .settings-section.feedback {
    margin-top: auto;
  }
`;
