import { useState } from "react";

import { Divider, Input, Slider, Switch, Typography } from "antd";
import { THEME_COLOR } from "constants/style.constants";
import styled from "styled-components/macro";

import { ColorChooser, SimpleColorPicker } from "components/base";

import { useChartDispatch, useChartState } from "./context";

const { Text } = Typography;

function SlopeStyleSelector() {
  const dispatch = useChartDispatch();
  const chartState = useChartState();
  const { settings } = chartState;
  const {
    showUnitSlope,
    showHalfSlope,
    showQuarterSlope,
    unitSlopeStyle,
    halfSlopeStyle,
    quarterSlopeStyle
  } = settings;

  const unitSlopeColor = unitSlopeStyle?.color || THEME_COLOR;
  const unitSlopeThickness = unitSlopeStyle?.thickness || 3.0;
  const unitSlopeLength = unitSlopeStyle?.length || 50;

  const halfSlopeColor = halfSlopeStyle?.color || THEME_COLOR;
  const halfSlopeThickness = halfSlopeStyle?.thickness || 3.0;
  const halfSlopeLength = halfSlopeStyle?.length || 50;

  const quarterSlopeColor = quarterSlopeStyle?.color || THEME_COLOR;
  const quarterSlopeThickness = quarterSlopeStyle?.thickness || 3.0;
  const quarterSlopeLength = quarterSlopeStyle?.length || 50;

  const [customColors, setCustomColors] = useState([]);
  const [showColorChooser, setShowColorChooser] = useState("");

  const updateShowUnitSlope = (isShowing: boolean) => {
    const next = { ...settings, showUnitSlope: isShowing };
    dispatch({ type: "settings", payload: next });
  };

  const updateShowHalfSlope = (isShowing: boolean) => {
    const next = { ...settings, showHalfSlope: isShowing };
    dispatch({ type: "settings", payload: next });
  };

  const updateShowQuarterSlope = (isShowing: boolean) => {
    const next = { ...settings, showQuarterSlope: isShowing };
    dispatch({ type: "settings", payload: next });
  };

  const updateUnitSlopeStyle = (key) => (value) => {
    const next = {
      color: unitSlopeColor,
      thickness: unitSlopeThickness,
      length: unitSlopeLength,
      [key]: value
    };
    const nextSettings = { ...settings, unitSlopeStyle: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const updateHalfSlopeStyle = (key) => (value) => {
    const next = {
      color: halfSlopeColor,
      thickness: halfSlopeThickness,
      length: halfSlopeLength,
      [key]: value
    };
    const nextSettings = { ...settings, halfSlopeStyle: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const updateQuarterSlopeStyle = (key) => (value) => {
    const next = {
      color: quarterSlopeColor,
      thickness: quarterSlopeThickness,
      length: quarterSlopeLength,
      [key]: value
    };
    const nextSettings = { ...settings, quarterSlopeStyle: next };
    dispatch({ type: "settings", payload: nextSettings });
  };

  const showSlopes = settings.chartType == "Material Balance Time";
  return (
    <Wrapper>
      {showSlopes && (
        <>
          <Head>
            <FlexItem>
              <SimpleColorPicker
                setColor={() => {
                  setShowColorChooser(
                    showColorChooser === "unitSlope" ? "" : "unitSlope"
                  );
                }}
                values={{
                  name: "unitSlope",
                  color: unitSlopeColor,
                  strokeColor: unitSlopeColor,
                  thickness: unitSlopeThickness,
                  opacity: null
                }}
              />
              <StyledText strong>Unit Slope</StyledText>
            </FlexItem>
            <Switch size="small" checked={showUnitSlope} onChange={updateShowUnitSlope} />
          </Head>
          {settings.showUnitSlope && (
            <ScaleWrapper>
              <ScaleLabelWrapper>Length: </ScaleLabelWrapper>
              <ScaleInputWrapper>
                <Input
                  value={unitSlopeLength}
                  type={"number"}
                  step={1}
                  id={"unitSlopeLength"}
                  min={0}
                  suffix={"%"}
                  max={100}
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);
                    value = Math.max(0, Math.min(value, 100)); // Ensure value is between 0 and 100
                    updateUnitSlopeStyle("length")(value);
                  }}
                />
              </ScaleInputWrapper>
            </ScaleWrapper>
          )}

          <Head>
            <FlexItem>
              <SimpleColorPicker
                setColor={() =>
                  setShowColorChooser(showColorChooser === "halfSlope" ? "" : "halfSlope")
                }
                values={{
                  name: "halfSlope",
                  color: halfSlopeColor,
                  strokeColor: halfSlopeColor,
                  thickness: halfSlopeThickness,
                  opacity: null
                }}
              />
              <StyledText strong>Half Slope</StyledText>
            </FlexItem>
            <Switch size="small" checked={showHalfSlope} onChange={updateShowHalfSlope} />
          </Head>
          {settings.showHalfSlope && (
            <ScaleWrapper>
              <ScaleLabelWrapper>Length:</ScaleLabelWrapper>
              <ScaleInputWrapper>
                <Input
                  value={halfSlopeLength}
                  type={"number"}
                  step={1}
                  id={"halfSlopeLength"}
                  suffix={"%"}
                  min={0}
                  max={100}
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);
                    value = Math.max(0, Math.min(value, 100));
                    updateHalfSlopeStyle("length")(value);
                  }}
                />
              </ScaleInputWrapper>
            </ScaleWrapper>
          )}
          <Head>
            <FlexItem>
              <SimpleColorPicker
                setColor={() =>
                  setShowColorChooser(
                    showColorChooser === "quarterSlope" ? "" : "quarterSlope"
                  )
                }
                values={{
                  name: "quarterSlope",
                  color: quarterSlopeColor,
                  strokeColor: quarterSlopeColor,
                  thickness: quarterSlopeThickness,
                  opacity: null
                }}
              />
              <StyledText strong>Quarter Slope</StyledText>
            </FlexItem>
            <Switch
              size="small"
              checked={showQuarterSlope}
              onChange={updateShowQuarterSlope}
            />
          </Head>
          {settings.showQuarterSlope && (
            <ScaleWrapper>
              <ScaleLabelWrapper>Length:</ScaleLabelWrapper>
              <ScaleInputWrapper>
                <Input
                  value={quarterSlopeLength}
                  type={"number"}
                  step={1}
                  id={"quarterSlopeLength"}
                  suffix={"%"}
                  min={0}
                  max={100}
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);
                    value = Math.max(0, Math.min(value, 100));
                    updateQuarterSlopeStyle("length")(value);
                  }}
                />
              </ScaleInputWrapper>
            </ScaleWrapper>
          )}
        </>
      )}

      {showColorChooser === "unitSlope" && (
        <>
          <StyledDivider />
          <LinePreview color={unitSlopeColor} thickness={unitSlopeThickness} />
          <ColorChooser
            selectedColor={unitSlopeColor}
            onChange={(val) => {
              updateUnitSlopeStyle("color")(val);
            }}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={unitSlopeThickness}
              onChange={(val) => {
                updateUnitSlopeStyle("thickness")(val);
              }}
            />
          </SliderWrapper>
        </>
      )}

      {showColorChooser === "halfSlope" && (
        <>
          <StyledDivider />
          <LinePreview color={halfSlopeColor} thickness={halfSlopeThickness} />
          <ColorChooser
            selectedColor={halfSlopeColor}
            onChange={(val) => {
              updateHalfSlopeStyle("color")(val);
            }}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={halfSlopeThickness}
              onChange={(val) => {
                updateHalfSlopeStyle("thickness")(val);
              }}
            />
          </SliderWrapper>
        </>
      )}

      {showColorChooser === "quarterSlope" && (
        <>
          <StyledDivider />
          <LinePreview color={quarterSlopeColor} thickness={quarterSlopeThickness} />
          <ColorChooser
            selectedColor={quarterSlopeColor}
            onChange={(val) => {
              updateQuarterSlopeStyle("color")(val);
            }}
            customColors={customColors}
            setCustomColors={setCustomColors}
          />
          <SliderWrapper>
            Thickness:
            <Slider
              min={1}
              max={20}
              value={quarterSlopeThickness}
              onChange={(val) => {
                updateQuarterSlopeStyle("thickness")(val);
              }}
            />
          </SliderWrapper>
        </>
      )}
    </Wrapper>
  );
}

export default SlopeStyleSelector;

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
`;

const Head = styled.div`
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// new line, flex item, aligned right
const ScaleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  gap: 4px;
`;

// new line, flex item, aligned right
const ScaleInputWrapper = styled.div`
  width: 80%;
`;

const ScaleLabelWrapper = styled.div`
  margin-top: 5px;
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
  padding-left: 2px;
  padding-top: 1px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const LinePreview = styled.div`
  background-color: ${(p) => p.color};
  height: ${(p) => p.thickness}px;
  width: 80%;
  margin: 8px auto;
`;

const SliderWrapper = styled.div`
  display: grid;
`;

const FlexItem = styled.div`
  display: inline-flex;
  gap: 2px;
`;
