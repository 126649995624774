import { useQuery } from "react-query";

import { fetchPlayTo3DFields } from "../api/ipdp";

///hook to fetch ipdb fields
export default function usePlayTo3DFields(source: string) {
  const { data, isLoading, isError, refetch } = useQuery(
    ["fetchPlayTo3DFields", source],
    () => fetchPlayTo3DFields(source),
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
