import { IGroupByListColumn } from "models";

type GroupedFieldsT = Record<string, IGroupByListColumn[]>;

const groupFields = (items: IGroupByListColumn[]): GroupedFieldsT => {
  const result = {};
  if (!items?.length) return result;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const subgroup = item.subgroup;

    (result[subgroup] || (result[subgroup] = [])).push(item);
  }

  return result;
};

export default groupFields;
