import { CSSProperties, useRef, useState } from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Input } from "antd";
import axios from "axios";
import styled from "styled-components/macro";

import TagSelect from "./TagSelect";
import { DOWNLOAD_URL, UPLOAD_URL } from "./constants";

const { TextArea } = Input;

function PostForm({
  title,
  tags,
  content,
  replaceContent,
  addFile,
  onTitleChange,
  onTagSelection,
  onContentChange,
  onSubmit,
  onDelete = null
}): JSX.Element {
  // state
  const [contextAreaStyles, setContentAreaStyles] = useState<CSSProperties>();

  // refs
  const fileInputRef = useRef(null);
  const contentAreaRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  function handleTitleChange(e) {
    onTitleChange(e.target.value);
  }

  function handleContentChange(e) {
    onContentChange(e.target.value);
  }

  function addToContent(additionalContent, position) {
    onContentChange((content) => {
      if (!content) {
        return `${additionalContent}\n`;
      }

      const dropPosition = position ?? content.length;
      const beforeText = content.substring(0, dropPosition);
      const afterText = content.substring(dropPosition, content.length);
      let replacement = beforeText;
      if (beforeText !== "" && beforeText.charAt(beforeText.length - 1) !== "\n") {
        replacement += "\n";
      }
      replacement += additionalContent;
      if (afterText.charAt(0) !== "\n") {
        replacement += "\n";
      }
      replacement += afterText;
      return replacement;
    });
  }

  function dropHandler(e) {
    e.preventDefault();
    setContentAreaStyles((prev) => ({ ...prev, boxShadow: "none" }));
    uploadAllFiles(e);
  }

  function updateContentAreaStyles(e) {
    e.preventDefault();
    setContentAreaStyles((prev) => ({
      ...prev,
      boxShadow: "0 0 0 4px #00cc00"
    }));
  }

  function resetContentAreaStyles(e) {
    e.preventDefault();
    setContentAreaStyles((prev) => ({ ...prev, boxShadow: "none" }));
  }

  function pasteHandler(e) {
    const files = e.clipboardData.files;
    if (files.length > 0) {
      e.preventDefault();
      uploadAllFiles(e);
    }
  }

  function uploadButtonHandler(e) {
    e.preventDefault();
    fileInputRef.current.click();
  }

  function uploadAllFiles(e) {
    let files = null;
    switch (e.type) {
      case "paste":
        files = e.clipboardData.files;
        break;
      case "drop":
        files = e.dataTransfer.files;
        break;
      case "change":
        files = fileInputRef.current.files;
        break;
      default:
        return;
    }
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i], contentAreaRef.current.selectionStart);
    }
    if (e.type === "change") {
      fileInputRef.current.value = null;
    }
  }

  function uploadFile(file, cursor) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      const text = "![Uploading " + file.name + "...]()";
      addToContent(text, cursor);

      const params = new FormData();
      params.append("theFile", file);
      axios
        .post(UPLOAD_URL, params)
        .then((response) => {
          let replacement = new String();
          if (file.type.includes("image")) {
            replacement += "!";
          }
          replacement += `[${file.name}](${DOWNLOAD_URL}/${response.data._id})`;
          replaceContent(text, replacement);
          addFile(response.data._id);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Error:", error);
        });
    };
  }

  return (
    <StyledForm encType="multipart/form-data" onSubmit={handleSubmit}>
      <label htmlFor="postTitle">
        Title
        <Input
          id="postTitle"
          name="postTitle"
          value={title}
          onChange={handleTitleChange}
        />
      </label>

      <label htmlFor="postTags">
        Tags
        <TagSelect id="postTags" value={tags} onChange={onTagSelection} />
      </label>

      <Dropzone
        onDrop={dropHandler}
        onDragEnter={updateContentAreaStyles}
        onDragLeave={resetContentAreaStyles}
        onDragOver={updateContentAreaStyles}
        onPaste={pasteHandler}>
        <StyledContentLabel htmlFor="postContent">
          Content
          <TextArea
            ref={contentAreaRef}
            name="postContent"
            rows={15}
            style={contextAreaStyles}
            value={content}
            onChange={handleContentChange}
          />
        </StyledContentLabel>

        <input type="file" ref={fileInputRef} onChange={uploadAllFiles} multiple hidden />

        <Button block onClick={uploadButtonHandler}>
          Add files by clicking here, dragging and dropping, or pasting them in
        </Button>
      </Dropzone>
      <ActionContainer>
        <StyledButton type="primary" htmlType="submit" value="submit">
          Submit
        </StyledButton>

        {onDelete && (
          <StyledButton
            danger
            onClick={onDelete}
            icon={<DeleteIcon className="anticon" />}>
            Delete post
          </StyledButton>
        )}
      </ActionContainer>
    </StyledForm>
  );
}

export default PostForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #f4f6f8;
  padding: 20px 24px;
  overflow: hidden overlay;
`;

const Dropzone = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-rows: 1fr max-content;
  gap: 8px;
`;

const StyledContentLabel = styled.label`
  display: grid;
  grid-template-rows: max-content 1fr;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  font-weight: 500;
`;
