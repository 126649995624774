// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiAlertCircle } from "@mdi/js";
import { Progress, Table } from "antd";
import styled from "styled-components/macro";

import { useFilesContext, useFilesDispatch } from "../context";
import { useDownloadFile, useFilesList } from "../hooks";
import { getFileIcon } from "../utils";

const FilesList = () => {
  // hooks
  const { allowMultipleDownloads, uploadingFilesProgress } = useFilesContext();
  const { data, isLoading, isError } = useFilesList();
  const { downloadFile } = useDownloadFile();

  // dispatch
  const filesDispatch = useFilesDispatch();

  const rowSelection = {
    hideSelectAll: allowMultipleDownloads,
    onChange: (_, selectedRows) => {
      const selectedFiles = selectedRows.map((row) => ({
        filename: row.fileName,
        fileSize: row.fileSize
      }));
      filesDispatch({ payload: { selectedFiles } });
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === "pending"
    })
  };

  const handleDownloadFile = async (filename: string, fileSize: number) => {
    filesDispatch({ payload: { isDownloadingFiles: true } });
    // eslint-disable-next-line no-console
    console.log(`Download file ${filename} of size ${fileSize}`);
    try {
      const { downloadChunks, cancelDownload } = downloadFile(filename, fileSize);
      filesDispatch({ payload: { cancelDownload } });
      await downloadChunks();
    } catch (error) {
      filesDispatch({
        payload: { error: `Failed to download {filename}, please try again later.` }
      });
    } finally {
      filesDispatch({ payload: { isDownloadingFiles: false, downloadFileProgress: 0 } });
    }
  };

  // Table Columns
  const tableColumns = [
    {
      dataIndex: "fileName",
      key: "fileName",
      title: "Name",
      render: (filename, record) => {
        const icon = getFileIcon(record.mimeType);
        if (record.status === "pending") {
          return (
            <FileInfoCellWrapper>
              <ProgressBackground percent={record.progress} showInfo={false} />
              <FileInfoWrapper>
                <Icon path={icon.icon} color={icon.color} size="24px" />
                {filename}
              </FileInfoWrapper>
            </FileInfoCellWrapper>
          );
        }

        return (
          <FileInfoCellWrapper>
            <FileInfoWrapper
              onClick={() => handleDownloadFile(filename, record.fileSize)}>
              <Icon path={icon.icon} color={icon.color} size="24px" />
              {filename}
            </FileInfoWrapper>
          </FileInfoCellWrapper>
        );
      }
    },
    {
      dataIndex: "fileSizeFormatted",
      key: "fileSizeFormatted",
      title: "Size"
    },
    {
      dataIndex: "lastModified",
      key: "lastModified",
      title: "Date Modified",
      render: (date) => {
        if (!date) return "";
        return (
          new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString()
        );
      }
    }
  ];

  const rowData = data?.files?.map((file) => ({
    key: file.fileName,
    status: "available",
    fileName: file.fileName,
    fileSize: file.fileSize,
    fileSizeFormatted: file.fileSizeFormatted,
    lastModified: file.lastModified,
    mimeType: file.mimeType
  }));

  if (uploadingFilesProgress) {
    // prepend the pending files to the list
    const pendingFiles = Object.keys(uploadingFilesProgress)
      .filter((filename) => uploadingFilesProgress[filename].progress !== 100)
      .map((filename) => ({
        key: filename,
        status: "pending",
        fileName: filename,
        fileSize: 0,
        fileSizeFormatted: "Uploading...",
        lastModified: undefined,
        mimeType: "application/octet-stream",
        progress: uploadingFilesProgress[filename].progress
      }));

    rowData?.unshift(...pendingFiles);
  }

  return (
    <Wrapper>
      <StyledFileTable
        rowSelection={{
          type: allowMultipleDownloads ? "checkbox" : "radio",
          ...rowSelection
        }}
        dataSource={rowData}
        loading={isLoading}
        columns={tableColumns}
        locale={{ emptyText: "No files found" }}
      />
      {isError && (
        <ErrorContainer>
          {" "}
          <Icon path={mdiAlertCircle} size="24px" /> Unable to retrieve any files at this
          time, please try again later.
        </ErrorContainer>
      )}
    </Wrapper>
  );
};

export default FilesList;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ErrorContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: var(--color-danger);
  border-radius: 5px;
  border: 1px solid var(--color-danger);
  padding: 8px 20px;
  margin: 0 20px;
`;
const FileInfoCellWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const FileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
    text-decoration: underline;
  }
`;

const StyledFileTable = styled(Table)`
  width: 100%;
  .ant-table-row-selected > .ant-table-cell,
  .ant-table-row-selected > .ant-table-cell.ant-table-cell-row-hover {
    background-color: rgba(var(--color-text-rgb), 0.05);
  }
`;

const ProgressBackground = styled(Progress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .ant-progress-inner {
    height: 24px !important;
    border-radius: 0 !important;
    .ant-progress-bg {
      height: 24px !important;
      border-radius: 0 !important;
    }
  }
`;
