import { ALL_XDA_DEFAULT_BINS } from "constants/xdaBin.constants";

import { Ipdb3dModel } from "../../../models/xdaData";

/**
 * Calculate default bin values based on the percentiles of the data.
 * The percentiles and the bin size was chosen after discussing with the R&D team.
 */
export default function calculateXdaDefaultBinValues(
  ipdb3d_boxes: Ipdb3dModel[],
  field?: string
): {
  lessThan: number;
  binSize: number;
  greaterThan: number;
  isConstant?: boolean;
} {
  if (field && ALL_XDA_DEFAULT_BINS[field.replace(/\s/g, "")]) {
    return { ...ALL_XDA_DEFAULT_BINS[field.replace(/\s/g, "")], isConstant: true };
  }

  const flatArray = ipdb3d_boxes.map((box) => box.val);
  flatArray.sort((a, b) => a - b);

  // No data: Sometimes the ipdbField is not present in the data, so we return null values.
  if (flatArray.every((value) => value == undefined) || flatArray.length === 0) {
    return null;
  }

  const upperPercentileIndex = Math.floor(flatArray.length * 0.9);
  const lowerPercentileIndex = Math.floor(flatArray.length * 0.1);
  const upperPercentileValue = flatArray[upperPercentileIndex];
  const lowerPercentileValue = flatArray[lowerPercentileIndex];

  const lessThan = Math.floor(lowerPercentileValue);
  const greaterThan = Math.floor(upperPercentileValue);
  const binSize = 12;

  return {
    lessThan,
    binSize,
    greaterThan
  };
}
