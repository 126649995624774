export const getMaxDecimalPlaces = (numbers: number[], limit: number = 5) => {
  const getDecimalPlaces = (num: number) => {
    const numParts = num.toString().split(".");
    return numParts.length > 1 ? Math.min(numParts[1].length, limit) : 0;
  };

  let maxDecimalPlaces = 0;

  for (const num of numbers) {
    const decimalPlaces = num && getDecimalPlaces(num);
    if (decimalPlaces > maxDecimalPlaces) {
      maxDecimalPlaces = decimalPlaces;
    }
  }

  return maxDecimalPlaces;
};
