// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { ReactNode, useMemo, useState } from "react";

import { RemoveCircleOutline } from "@material-ui/icons";
import { mdiFactory } from "@mdi/js";
import styled from "styled-components";

import { GridWidget } from "models/dashboard";

import { ErrorBoundary, Tooltip } from "components/base";
import { UserDashboardSettings } from "components/user-settings/models";
import { useUserSettings } from "components/user/hooks";

import { getWidgetName } from "./utils";

export interface WidgetWrapperModel {
  children?: ReactNode | ReactNode[] | null;
  widget: GridWidget;
  designMode?: boolean;
  onRemoveWidget?: (widget: GridWidget) => void;
}
export default function WidgetWrapper(input: WidgetWrapperModel) {
  const settings = useUserSettings();
  const [isHover, setIsHover] = useState(false);

  const isInDesignMode = useMemo(
    () => input.designMode && input.widget && (input.widget.w > 0 || input.widget.h > 0),
    [input]
  );

  const wrapperStyleSelector = (
    isDesignMode: boolean,
    isMouseHover: boolean,
    userDashboardSettings: UserDashboardSettings
  ): React.CSSProperties => {
    if (isDesignMode) {
      // Return style for Edit Dashboard Layout
      return {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        height: "100%",
        border: "4px solid #ccc",
        backgroundColor: "#fff",
        // Handle mouse hover manually
        borderColor: isMouseHover ? "var(--color-primary)" : ""
      };
    } else if (userDashboardSettings?.borderType === "bordered") {
      return {
        height: "100%",
        borderStyle: "solid",
        borderWidth: `${userDashboardSettings.borderThickness ?? 1}px`,
        borderColor: userDashboardSettings.borderColor ?? "#ccc"
      };
    } else if (userDashboardSettings?.borderType === "topBorder") {
      return {
        height: "100%",
        borderTopStyle: "solid",
        borderWidth: `${userDashboardSettings.borderThickness ?? 1}px`,
        borderColor: userDashboardSettings.borderColor ?? "#ccc"
      };
    }
    // Return style for normal layout
    return {
      height: "100%"
    };
  };

  const wrapperStyle = useMemo(
    () => wrapperStyleSelector(isInDesignMode, isHover, settings?.userDashboardSettings),
    [isInDesignMode, isHover, settings?.userDashboardSettings]
  );

  const widgetName = getWidgetName(input.widget);
  const renderFacilityIcon =
    widgetName.includes("Facility") || widgetName.includes("Midstream");

  return (
    // Render design mode and normal mode in the same DOM to avoid children to be reloaded
    <ErrorBoundary>
      <div
        data-testid="wrapper-widget"
        style={wrapperStyle}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {isInDesignMode && (
          <WidgetHeaderBar className="widget-header">
            <WidgetTitle className="widget-title">
              {renderFacilityIcon && <StyledIcon path={mdiFactory} size={1.0} />}
              <TitleText>{widgetName}</TitleText>
            </WidgetTitle>
            <WidgetActions>
              <Tooltip title="Remove">
                <WidgetButton
                  data-testid="remove-button"
                  onClick={() => {
                    input.onRemoveWidget && input.onRemoveWidget(input.widget);
                  }}>
                  <RemoveCircleOutline fontSize="large" />
                </WidgetButton>
              </Tooltip>
            </WidgetActions>
          </WidgetHeaderBar>
        )}
        <WidgetContainer>
          {input.children}
          {isInDesignMode && <WidgetOverlay />}
        </WidgetContainer>
      </div>
    </ErrorBoundary>
  );
}

const WidgetContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

const WidgetOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-text);
  color: #fff;
  opacity: 0.4;
  z-index: 101;
  cursor: default;
`;

const WidgetTitle = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
`;

const TitleText = styled.span`
  margin-right: 8px;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 2px;
  margin-right: 5px;
`;

const WidgetHeaderBar = styled.div`
  display: flex;
  align-items: left;
  background-color: var(--color-text);
  color: #b5b5b5;
  padding: 5px;
`;

const WidgetActions = styled.div``;

const WidgetButton = styled.button`
  border: 0;
  background: transparent;
  color: #a2aaad;
  transition: color var(--duration);
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
  }
  z-index: 102;
`;
