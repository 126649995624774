import React from "react";

import { Card, CardContent } from "@material-ui/core";

import EVALogo from "../assets/logo-white.svg";
import "./CardWrapper.scss";

export default function CardWrapper({ children }) {
  return (
    <div className="aurora-login">
      <div className="logo">
        <img src={EVALogo} style={{ width: 150 }} />
      </div>
      <Card className="container  flex-column " variant="outlined">
        <CardContent className="full-width full-height">
          <div className="child-container">
            <div className="child-wrapper">
              <div>{children}</div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
