import { FunctionComponent, ReactNode } from "react";

import { TooltipPlacement } from "antd/es/tooltip";
import styled from "styled-components/macro";

import { Tooltip } from "components/base";

type ToolbarButtonT = {
  active?: boolean;
  activeIcon?: ReactNode;
  icon: ReactNode;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipDelay?: number;
  onToggle?: (b: boolean) => void;
};

const ToolbarButton: FunctionComponent<ToolbarButtonT> = ({
  active = false,
  activeIcon,
  icon,
  tooltipText = undefined,
  tooltipPlacement = "topRight",
  onToggle = null,
  ...rest
}) => {
  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <StyledButton active={active} onClick={() => onToggle(!active)} {...rest}>
        {active && activeIcon ? activeIcon : icon}
      </StyledButton>
    </Tooltip>
  );
};

export default ToolbarButton;

const StyledButton = styled.button`
  --color-fg: #53565a;
  --color-bg: #f2f3f4;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--${(p) => (p.active ? "white" : "color-fg")});
  background-color: var(--color-${(p) => (p.active ? "primary" : "bg")});
  border: 1px solid #d9d9d9;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: color 0.2s ease-out, border-color 0.2s ease-out;
  cursor: pointer;

  &:hover {
    color: var(--color-${(p) => (p.active ? "primary-hover" : "primary")});
  }

  & > svg {
    width: 18px;
    height: 18px;
  }
`;
