import { getProductType } from "utils";

import { IGroupByListColumn } from "models";

const filterByProduct = (
  product: string,
  columns: IGroupByListColumn[]
): IGroupByListColumn[] => {
  if (!product) return columns;

  const productObject = getProductType(product);
  const { key, label } = productObject;

  return columns.filter((column) => column.product === key || column.product === label);
};

export default filterByProduct;
