import { useEffect, useState } from "react";

import { LegendItemModel } from "models";

import { useTypeWellSeries } from "components/arps/hooks";

import { useChartState } from "../context";
import { createLegendItem } from "../utils";
import useChartDependencies from "./useChartDependencies";

const useTypeWellLegendItems = (): LegendItemModel[] => {
  // context
  const { settings, entityKind } = useChartState();

  // state from store

  // state
  const { checkedGlobalTypeWells, normalizeTypeWell } = useChartDependencies(entityKind);
  const { typeWellSeries } = useTypeWellSeries(normalizeTypeWell);

  const [items, setItems] = useState<LegendItemModel[]>([]);

  // derived state
  const isTypeWellsEnabled = Boolean(settings.typewells);

  // effects
  useEffect(() => {
    if (!isTypeWellsEnabled || !checkedGlobalTypeWells?.length) {
      setItems([]);
      return;
    }

    const nextList = checkedGlobalTypeWells
      .filter((item) => {
        // only include type wells in legend that are shown on the chart
        return (
          typeWellSeries.some((tw) => tw.name === item.title) || item.type === "folder"
        );
      })
      .map((item) => {
        return item.type === "folder"
          ? item.children
              .filter((item) => {
                return typeWellSeries.some((tw) => tw.name === item.title);
              })
              .map((child) => createLegendItem(child.title, true, child.color))
          : createLegendItem(item.title, true, item.color);
      })
      .flatMap((x) => x);

    setItems(nextList);
  }, [isTypeWellsEnabled, checkedGlobalTypeWells, typeWellSeries]);

  return items;
};

export default useTypeWellLegendItems;
