import { Alert } from "antd";
import styled from "styled-components";

import { useMapContext } from "./hooks/useMapContext";

/**
 * Use alert instead of Message component because we want to be center of the map
 * not screen
 * @returns
 */
export default function MapMessage() {
  const { mapAlert } = useMapContext();

  if (!mapAlert) {
    return null;
  }
  return (
    <AlertWrapper>
      <Alert type="error" showIcon message={mapAlert} closable />
    </AlertWrapper>
  );
}
const AlertWrapper = styled.div`
  position: absolute;
  width: 100%;
  user-select: none;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
