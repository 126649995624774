import { useMutation } from "react-query";

import { deleteColumnSet } from "api/column";

export default function useDeleteColumnSetMutation(onSuccess, onError) {
  return useMutation(async (columnSetId: string) => await deleteColumnSet(columnSetId), {
    onSuccess: onSuccess,
    onError: onError
  });
}
