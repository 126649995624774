import { useEffect, useState } from "react";

/**
 * Simple `hook` that debounces the updates for any value and returns the debounced value, updated only at most after 500ms or provided delay.
 *
 * @param {any} value the value for which you want to debounce the updates
 * @param {number} [delay=500] time in milliseconds
 * @returns
 */
function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    // clear timer if value or delay changes,
    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
