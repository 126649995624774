import { useMemo } from "react";

import { DEFAULT_AXIS_FONT_WEIGHT } from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";

import { IChartScreenshotSettings } from "models/chart";

import {
  useScreenshotContext,
  useScreenshotPresetContext
} from "components/screenshot/hooks";

function useMPCScreenshotCalculatedDefaults(): IChartScreenshotSettings {
  // hooks
  const { widget, widgetState } = useScreenshotContext();
  const { selectedPreset: preset } = useScreenshotPresetContext();

  return useMemo(() => {
    return widget?.widgetComponentType !== "mpc"
      ? {}
      : {
          axisFontSize: preset?.axisFontSize,
          titleFontSize: preset?.titleFontSize,
          axisLabelFontSize: preset?.axisLabelFontSize,
          titleText: widgetState?.chartSettings?.data?.title,
          axisFontWeight: DEFAULT_AXIS_FONT_WEIGHT,
          legendVisible: true,
          legendFontSize: preset?.legendFontSize,
          legendOpacity: 1,
          fontFamily: THEME_FONT_FAMILY,
          legendType: widgetState?.chartSettings?.legend?.type ?? "bottom",
          legendPosition: widgetState?.chartSettings?.legend?.position,
          applyAbbreviations: false
        };
  }, [
    widget?.widgetComponentType,
    preset?.axisFontSize,
    preset?.titleFontSize,
    preset?.axisLabelFontSize,
    preset?.legendFontSize,
    widgetState?.chartSettings?.data?.title,
    widgetState?.chartSettings?.legend?.type,
    widgetState?.chartSettings?.legend?.position
  ]);
}

export default useMPCScreenshotCalculatedDefaults;
