const periodOrder = [
  "Cretaceous",
  "Jurassic",
  "Triassic",
  "Permian",
  "Mississippian",
  "Devonian",
  "Cambrian"
];

export const sortTreeData = (list) => {
  const getOrderValue = (period) => {
    const index = periodOrder.indexOf(period);
    return index !== -1 ? index : periodOrder.length;
  };

  return list.sort((a, b) => getOrderValue(a.period) - getOrderValue(b.period));
};
