import {
  ATTENTION_WELL_LAYER,
  BASE_WELL_LAYER,
  COMPLETION_FRAC_LAYER,
  COMPLETION_PERF_LAYER,
  FACILITY_LABEL_LAYER,
  FACILITY_LAYER,
  GEO_MAP_LAYER,
  HIGHLIGHTED_PLAY_LAYER,
  IPDB_CONTROL_WELLS_LAYER,
  IPDB_LAYER,
  SELECTED_SINGLE_WELL_LAYER,
  SELECTED_WELL_LAYER,
  SELECTED_WELL_POINT_LAYER,
  SYNC_WELL_LAYER,
  WELL_LABEL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT,
  XDA_INTERCEPT_LAYER,
  XDA_LABEL,
  XDA_WELL_INTERCEPT_POINT
} from "constants/mapLayers.constants";
import mapboxgl, { GeoJSONSourceRaw, VectorSource } from "mapbox-gl";

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;
const map_server_root = process.env.REACT_APP_MAP_GRID_SERVER;
const geoserver_root = process.env.REACT_APP_GEOSERVER;

const emptyFeatureCollectionData = {
  type: "FeatureCollection",
  features: []
};

export const defaultWellLineThickness = 2;

type LayerWithBefore = mapboxgl.Layer & {
  before?: string;
};

interface MapLayersOptions {
  hasSurvey: boolean;
  // TODO: remove
  has3dGeoModel: boolean;
  hasGeoMap: boolean;
  orgId: string;
  hasGdc: boolean;
  hasIhs: boolean;
}

export function getMapLayers(options: MapLayersOptions): LayerWithBefore[] {
  const layers: LayerWithBefore[] = [
    {
      id: "Township",
      type: "line",
      minzoom: 8,
      maxzoom: 22,
      source: {
        type: "vector",
        tiles: [`${map_server_root}/grid.all_township/{z}/{x}/{y}.pbf`]
      },
      "source-layer": "grid.all_township",
      paint: {
        "line-color": "#000",
        "line-width": 2
      }
    },
    {
      id: "Section",
      type: "line",
      minzoom: 10,
      maxzoom: 22,
      source: {
        type: "vector",
        tiles: [`${map_server_root}/grid.all_section/{z}/{x}/{y}.pbf`]
      },
      "source-layer": "grid.all_section",
      paint: {
        "line-color": "#aaaaaa"
      }
    },
    {
      id: "LSD",
      type: "line",
      minzoom: 12,
      maxzoom: 22,
      source: {
        type: "vector",
        tiles: [`${map_server_root}/grid.lsd/{z}/{x}/{y}.pbf`]
      },
      "source-layer": "grid.lsd",
      paint: {
        "line-color": "#aaaaaa"
      }
    },
    {
      id: "LSD Label",
      type: "symbol",
      minzoom: 12,
      maxzoom: 22,
      source: {
        type: "vector",
        tiles: [`${map_server_root}/grid.lsd_label/{z}/{x}/{y}.pbf`]
      },
      "source-layer": "grid.lsd_label",
      layout: {
        "text-field": ["get", "label"],
        "text-size": 9,
        "text-allow-overlap": true,
        "text-ignore-placement": true
      },
      paint: {
        "text-color": "#202",
        "text-halo-width": 2
      }
    },
    {
      id: "Section Label",
      type: "symbol",
      minzoom: 10,
      maxzoom: 22,
      source: {
        type: "vector",
        tiles: [`${map_server_root}/grid.all_section_labels/{z}/{x}/{y}.pbf`]
      },
      "source-layer": "grid.all_section_labels",
      layout: {
        "text-field": ["get", "legal"],
        "text-size": 9,
        "text-allow-overlap": true,
        "text-ignore-placement": true
      },
      paint: {
        "text-color": "#202",
        "text-halo-width": 2
      }
    },
    // {
    //   id: Township Label",
    //   type: "symbol",
    //   minzoom: 8,
    //   maxzoom: 22,
    //   source: {
    //     type: "vector",
    //     tiles: [`${map_server_root}/grid.nts_block_point/{z}/{x}/{y}.pbf`]
    //   },
    //   "source-layer": "grid.nts_block_point",
    //   layout: {
    //     "text-field": ["get", "legal"],
    //     "text-size": 9,
    //     "text-allow-overlap": true,
    //     "text-ignore-placement": true
    //   },
    //   paint: {
    //     "text-color": "#202",
    //     "text-halo-width": 2
    //   }
    // },
    {
      id: "NTS Block Label",
      type: "symbol",
      minzoom: 8,
      maxzoom: 22,
      source: {
        type: "vector",
        tiles: [`${map_server_root}/grid.all_township_label/{z}/{x}/{y}.pbf`]
      },
      "source-layer": "grid.all_township_label",
      layout: {
        "text-field": ["get", "label"],
        "text-size": 9,
        "text-allow-overlap": true,
        "text-ignore-placement": true
      },
      paint: {
        "text-color": "#202",
        "text-halo-width": 2
      }
    },
    {
      id: WELL_LAYER,
      type: "line",
      source: {
        type: "vector",
        id: "well-source",
        promoteId: "Uwi",
        tiles: [
          `${mapServiceEndpoint}/well/{z}/{x}/{y}.hz.mvt?txn_id=27e30f3e-ba56-4511-985b-eacfd5517ba4`
        ]
      } as VectorSource,
      "source-layer": "eva-wells",
      paint: {
        "line-color": [
          "case",
          ["has", "color"],
          ["get", "color"],
          ["feature-state", "color"]
        ],
        "line-width": defaultWellLineThickness,
        "line-dasharray": [
          "match",
          ["get", "group"],
          "leg",
          ["literal", [1, 1]],
          ["literal", [2]]
        ]
      }
    },
    {
      id: WELL_LAYER_POINT,
      type: "circle",
      source: {
        type: "vector",
        promoteId: "Uwi",
        id: "well-source",
        tiles: [
          `${mapServiceEndpoint}/well/{z}/{x}/{y}.vert.mvt?txn_id=27e30f3e-ba56-4511-985b-eacfd5517ba4`
        ]
      } as VectorSource,
      "source-layer": "eva-wells",
      paint: {
        "circle-color": [
          "case",
          ["has", "color"],
          ["get", "color"],
          ["feature-state", "color"]
        ],
        "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 2, 15, 4, 22, 8]
      }
    },
    {
      id: FACILITY_LAYER,
      type: "symbol",
      source: {
        type: "vector",
        id: "facility-source",
        tiles: [`${mapServiceEndpoint}/well/facility/{z}/{x}/{y}?txn_id=clear`]
      } as VectorSource,
      "source-layer": "eva-facility",
      paint: {
        "icon-color": [
          "case",
          ["has", "color"],
          ["get", "color"],
          ["feature-state", "color"]
        ]
      },
      layout: {
        "icon-image": [
          "match",
          ["get", "facilityType"],
          "CompressorStation",
          "compressor-station",
          "GasPlant",
          "gas-plant",
          "Battery",
          "battery",
          "MeteringStation",
          "metering-station",
          "WastePlant",
          "waste-plant",
          "InjectionDisposalFacility",
          "injection-facility",
          "GatheringSystem",
          "gathering-system",
          /* default */ "default-facility"
        ],
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          6,
          0.2,
          10,
          1.0,
          12,
          1.5,
          22,
          2.0
        ],
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-rotate": ["get", "angle"],
        "icon-rotation-alignment": "map",
        "icon-keep-upright": true
      }
    },
    {
      id: COMPLETION_FRAC_LAYER,
      type: "line",
      minzoom: 9,
      maxzoom: 22,
      source: "completion",
      layout: {},
      paint: {
        "line-color": ["match", ["get", "ftype"], "stage", "black", "gray"],
        "line-width": ["match", ["get", "ftype"], "stage", 4, 2]
      }
    },
    {
      id: COMPLETION_PERF_LAYER,
      type: "circle",
      minzoom: 9,
      maxzoom: 22,
      source: "completion",
      layout: {},
      paint: {
        "circle-color": "orange",
        "circle-radius": ["match", ["get", "ftype"], "perfpoint", 2, 0]
      }
    },
    {
      id: SELECTED_WELL_LAYER,
      type: "line",
      source: SELECTED_WELL_LAYER,
      paint: {
        "line-color": ["get", "color"],
        "line-width": 5
      }
    },
    {
      id: SELECTED_WELL_POINT_LAYER,
      type: "circle",
      source: SELECTED_WELL_POINT_LAYER,
      paint: {
        "circle-color": ["get", "color"],
        "circle-radius": {
          base: 2,
          stops: [
            [6, 3],
            [10, 4],
            [15, 50],
            [22, 60]
          ]
        }
      }
    },
    {
      id: SYNC_WELL_LAYER,
      type: "line",
      source: SYNC_WELL_LAYER,
      paint: {
        "line-color": ["get", "color"],
        "line-width": 5
      }
    },
    {
      id: WELL_LABEL_LAYER,
      type: "symbol",
      source: WELL_LABEL_LAYER,
      layout: {
        "text-field": ["get", "value"],
        "text-size": 9,
        "icon-image": ["match", ["get", "wellType"], "Mine", "mine", ""],
        "icon-size": 1,
        "text-variable-anchor": ["top", "bottom", "left", "right"]
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2
      }
    },
    {
      id: FACILITY_LABEL_LAYER,
      type: "symbol",
      source: FACILITY_LABEL_LAYER,
      layout: {
        "text-field": ["get", "value"],
        "text-size": 9,
        "icon-size": 1,
        "text-variable-anchor": ["top", "bottom", "left", "right"]
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2
      }
    },
    {
      id: HIGHLIGHTED_PLAY_LAYER,
      type: "line",
      source: HIGHLIGHTED_PLAY_LAYER,
      paint: {
        "line-color": "#51b5a0",
        "line-width": 5
      }
    },
    {
      id: XDA_INTERCEPT_LAYER,
      type: "line",
      source: XDA_INTERCEPT_LAYER,
      paint: {
        "line-color": "#000",
        "line-width": 6
      }
    },
    {
      id: XDA_WELL_INTERCEPT_POINT,
      type: "circle",
      source: XDA_INTERCEPT_LAYER,
      paint: {
        "circle-color": "orange",
        "circle-radius": 4
      }
    },
    {
      id: ATTENTION_WELL_LAYER,
      type: "line",
      source: ATTENTION_WELL_LAYER,
      paint: {
        "line-color": "black",
        "line-width": 5
      }
    },
    {
      id: XDA_LABEL,
      type: "symbol",
      source: XDA_LABEL,
      layout: {
        "text-field": ["get", "label"],
        "text-size": 20,
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-variable-anchor": ["top"],
        "text-justify": "left"
      },
      paint: {
        "text-color": "#666",
        "text-halo-width": 6
      }
    },
    {
      id: SELECTED_SINGLE_WELL_LAYER,
      type: "line",
      source: SELECTED_SINGLE_WELL_LAYER,
      paint: {
        "line-color": "black",
        "line-width": 5
      }
    }
  ];
  if (options.hasSurvey || options.hasIhs) {
    layers.splice(0, 0, {
      id: BASE_WELL_LAYER,
      type: "raster",
      minzoom: 11,
      maxzoom: 22,
      source: {
        type: "raster",
        tiles: [
          `${geoserver_root}/geoserver/cite/wms?bbox={bbox-epsg-3857}&STYLE=well&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=cite:wells${
            !options.hasGdc && options.hasIhs ? `_ihs_${options.orgId.toLowerCase()}` : ""
          }`
        ],
        tileSize: 256
      },
      paint: {}
    });
  }
  if (options.hasGeoMap) {
    layers.splice(5, 0, {
      id: GEO_MAP_LAYER,
      type: "fill",
      layout: {
        visibility: "none"
      },
      before: BASE_WELL_LAYER,
      source: "eva.geomapsource",
      "source-layer": "map.geo_map",
      paint: {
        "fill-color": {
          property: "val",
          stops: [
            [0, "#fff"],
            [1, "#99ff99"],
            [2, "#33ccff"],
            [3, "#9966ff"],
            [4, "#ff66ff"],
            [5, "#ff6699"],
            [6, "#ff0000"]
          ]
        },
        "fill-opacity": [
          "case",
          ["==", ["get", "val"], null],
          0, // Set opacity to 0 for null values so values are transparent on layer, not black
          1 // Full opacity if not null
        ]
      }
    });
  }

  // Insert IPDB layers since custom might have private map
  layers.splice(options.hasGeoMap ? 6 : 5, 0, {
    id: IPDB_LAYER,
    type: "fill",
    layout: {
      visibility: "none"
    },
    before: BASE_WELL_LAYER,
    source: "eva.ipdbsource",
    "source-layer": "eva.ipdb",
    paint: {
      "fill-color": {
        property: "val",
        stops: [
          [0, "#fff"],
          [1, "#99ff99"],
          [2, "#33ccff"],
          [3, "#9966ff"],
          [4, "#ff66ff"],
          [5, "#ff6699"],
          [6, "#ff0000"]
        ]
      },
      "fill-opacity": [
        "case",
        ["==", ["get", "val"], null],
        0, // Set opacity to 0 for null values so values are transparent on layer, not black
        1 // Full opacity if not null
      ]
    }
  });
  layers.splice(options.hasGeoMap ? 7 : 6, 0, {
    id: IPDB_CONTROL_WELLS_LAYER,
    type: "circle",
    layout: {
      visibility: "visible"
    },
    before: BASE_WELL_LAYER,
    source: "eva.control-wells",
    "source-layer": "control-wells",
    paint: {
      "circle-color": "black",
      "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 2, 15, 4, 22, 8]
    }
  });

  return layers;
}

export function getMapLayerSources(
  // TODO: remove
  has3dGeoModel: boolean,
  hasGeoMap: boolean
): {
  [id: string]: mapboxgl.AnySourceData;
} {
  const sources: { [id: string]: mapboxgl.AnySourceData } = {
    completion: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [WELL_LABEL_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [FACILITY_LABEL_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [SELECTED_WELL_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [SYNC_WELL_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [SELECTED_SINGLE_WELL_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [HIGHLIGHTED_PLAY_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [XDA_INTERCEPT_LAYER]: {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "GeometryCollection",
          geometries: []
        }
      }
    } as GeoJSONSourceRaw,
    [ATTENTION_WELL_LAYER]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw,
    [XDA_LABEL]: {
      type: "geojson",
      data: { ...emptyFeatureCollectionData }
    } as GeoJSONSourceRaw
  };
  // Insert IPDB layers since custom might have private map
  sources["eva.ipdbsource"] = {
    type: "vector",
    tiles: [`${mapServiceEndpoint}/ipdb/pay/true/WyJUT1AgTU9OVE5FWSJd/{z}/{x}/{y}.mvt`]
  } as VectorSource;
  sources["eva.control-wells"] = {
    type: "vector",
    tiles: [`${mapServiceEndpoint}/ipdb/control/mcdan-two-d/abcd/{z}/{x}/{y}.mvt`]
  } as VectorSource;
  if (hasGeoMap) {
    sources["eva.geomapsource"] = {
      type: "vector",
      tiles: [`${mapServiceEndpoint}/ipdb/pay/true/WyJUT1AgTU9OVE5FWSJd/{z}/{x}/{y}.mvt`]
    } as VectorSource;
  }
  return sources;
}
