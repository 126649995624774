import axios from "axios";

import { GEO_CALCULATION_SERVICE_ADDRESS } from "../modals/constants";

export async function getJobStatus(id: string) {
  const response = await axios.get<string>(
    `${GEO_CALCULATION_SERVICE_ADDRESS}/geo-calculation/jobs/status/${id}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(`Error getting job status: ${response.status}`);
  }
}
