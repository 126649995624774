import { memo } from "react";

import styled from "styled-components/macro";

import { useGlobalSettings } from "components/multiphase-chart/hooks";

const MPCSpecificSettingsWrapper = ({ message = null, children = null }) => {
  const { usingAllGlobalSettings } = useGlobalSettings();

  if (usingAllGlobalSettings) {
    return <>{children}</>;
  }

  return (
    <NotGlobalSettingsContainer>
      {children}
      <GlobalSettingMessage data-testid="chart-specific-settings-message">
        {message ?? "Global Settings differ"}
      </GlobalSettingMessage>
    </NotGlobalSettingsContainer>
  );
};

export default memo(MPCSpecificSettingsWrapper);

const GlobalSettingMessage = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  background-color: var(--color-primary);
  padding: 2px 5px;
  border-top-right-radius: 5px;
  pointer-events: none;
  opacity: 1;
  animation: fadeOut 1s ease-in-out 4s forwards;
`;

const NotGlobalSettingsContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-width: 0;
  border: 2px dashed var(--color-primary);
  pointer-events: none;
`;
