import { memo } from "react";

import Flared from "./Flared";
import Fuel from "./Fuel";
import RatioIcon from "./RatioIcon";
import Vented from "./Vented";

interface RatioIconProps {
  denominatorIcon: JSX.Element;
}
function FFVRatioIcon({ denominatorIcon }: RatioIconProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 150 150"
        style={{ position: "relative" }}
        transform="scale(1.8) translate(5.5, 6.5)">
        <g style={{ position: "absolute", top: 0, left: 0 }}>
          <path
            d="M5.30686 2L5.30686 0L0 0L2.22569 3.30138L0 6.63918L5.30686 6.63918L5.30686 4.63918"
            transform="translate(2, 16) scale(2.7)"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="1.25"
          />
        </g>
        <g
          style={{ position: "absolute", top: 0, left: 0 }}
          transform="translate(27, 23) scale(0.65)">
          <Fuel />
        </g>
        <g
          style={{ position: "absolute", top: 0, left: 0 }}
          transform="translate(15, 25.5) scale(0.57)">
          <Vented />
        </g>
        <g
          style={{ position: "absolute", top: 0, left: 0 }}
          transform="translate(20.5, 4) scale(0.57)">
          <Flared />
        </g>
        <g style={{ position: "absolute", top: 0, left: 0 }} transform="scale(2.9)">
          <RatioIcon numeratorIcon={<div />} denominatorIcon={denominatorIcon} />
        </g>
      </svg>
    </div>
  );
}

export default memo(FFVRatioIcon);
