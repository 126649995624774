import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { login as loginUser } from "store/features/auth/authSlice";
import { RootState } from "store/rootReducer";

import { getOrganizationSizes, getOrganizationTypes } from "api/userSettings";
import { postAdminAccount } from "api/users";

import {
  BaseButton,
  BaseCheckboxInput,
  BaseDropdown,
  BaseInput,
  Heading
} from "components/base";
import PasswordInput from "components/base/PasswordInput";
import StepIndicator from "components/ui/StepIndicator";

import "./SignUp.scss";

const data = {
  stepsTitle: ["Set up user account", "Set up company account", "Set up integrations"],
  firstNameLabel: "Your first name",
  lastNameLabel: "Your last name",
  emailLabel: "Email address",
  passwordLabel: "Create a password",
  stepOneButtonLabel: "Next",
  companyNameLabel: "Your company name",
  companyTypeLabel: "Your company type",
  sizeOfBusinessLabel: "Size of your business",
  stepTwoButtonLabel: "Create Account"
};
export default function SignUp() {
  const regexLength = new RegExp(".{12}");

  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [sizeOfBusiness, setSizeOfBusiness] = useState("");
  const [agreeTerms, setAgreeTerms] = useState("");
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [errors, setErrors] = useState({
    FirstName: null,
    LastName: null,
    Password: null,
    CompanyName: null,
    CompanyType: null,
    SizeBusiness: null,
    AgreeTerms: null
  });
  const [submittedPassword, setSubmittedPassword] = useState(false);
  const hasErrors = Object.keys(errors).some((key) =>
    key === "Password" ? submittedPassword && errors[key] : errors[key]
  );

  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();
  const [orgTypes, setOrgTypes] = useState([]);
  const [orgSizes, setOrgSizes] = useState([]);
  //fill in form from b64 encoded token
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const tokenData = JSON.parse(atob(token));
  useEffect(() => {
    const email = tokenData.Email;
    const firstName = tokenData.FirstName;
    const lastName = tokenData.LastName;
    const organizationName = tokenData.OrganizationName;
    setEmail(email);
    setFirstName(firstName);
    setLastName(lastName);
    setCompanyName(organizationName);
    getOrganizationSizes(
      (response) => {
        setOrgSizes(response.data);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    );
    getOrganizationTypes(
      (response) => {
        setOrgTypes(response.data);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    );
  }, []);

  const createAdminAccount = useCallback(() => {
    const payload = {
      firstName,
      lastName,
      email,
      password,
      OrganizationName: companyName,
      organizationType: companyType,
      organizationSize: sizeOfBusiness,
      AgreeToPrivacyPolicy: agreeTerms,
      InviteToken: tokenData.InviteToken
    };
    setCreatingAccount(true);
    postAdminAccount(
      payload,
      () => {
        setAccountCreated(true);
        toast.success("Your account has successfully been created.");
        dispatch(
          loginUser(
            {
              email: email,
              password: password
            },
            () => {
              setCreatingAccount(false);
              setStep(step + 1);
            },
            () => {
              toast.error("Login service down. Please try again later.");
              history.push("/login");
              setCreatingAccount(false);
            }
          )
        );
      },
      (error) => {
        toast.error(
          `Error creating account: ${
            (error.response?.data?.title || error.response?.data) ?? ""
          }`
        );
        setCreatingAccount(false);
      }
    );
  }, [
    firstName,
    lastName,
    email,
    password,
    companyName,
    companyType,
    sizeOfBusiness,
    agreeTerms
  ]);

  useEffect(() => {
    if (user && !accountCreated) {
      history.push("/");
    }
  }, [user, history]);

  function goTo(path) {
    history.push(path);
  }

  function validatePassword(key, value) {
    setErrors({
      ...errors,
      [key]: !regexLength.test(value)
        ? "Password length must be atleast 12 characters."
        : null
    });
    return !regexLength.test(value);
  }

  function handleChange(name, value) {
    switch (name) {
      case "FirstName":
        setFirstName(value);
        setErrors({
          ...errors,
          FirstName: value.length ? null : "First Name cannot be blank"
        });
        break;
      case "LastName":
        setLastName(value);
        setErrors({
          ...errors,
          LastName: value.length ? null : "Last Name cannot be blank"
        });
        break;
      case "Password":
        setPassword(value);
        validatePassword("Password", value);
        break;
      case "CompanyName":
        setCompanyName(value);
        setErrors({
          ...errors,
          CompanyName: value.length ? null : "Company Name cannot be blank"
        });
        break;
      case "CompanyType":
        setCompanyType(value);
        setErrors({
          ...errors,
          CompanyType: value.length ? null : "Company Type cannot be blank"
        });
        break;
      case "SizeBusiness":
        setSizeOfBusiness(value);
        setErrors({
          ...errors,
          SizeBusiness: value.length ? null : "Size of Business cannot be blank"
        });
        break;
      case "AgreeTerms":
        setAgreeTerms(value);
        setErrors({
          ...errors,
          AgreeTerms: value ? null : "Please accept the Terms and Conditions"
        });
        break;
    }
  }

  return (
    <div className="content-container">
      <div>
        <StepIndicator
          steps={3}
          currentStep={step}
          onClick={() => setStep(step - 1)}></StepIndicator>
        <Heading className="align-center large" element="h3">
          {data.stepsTitle[step - 1]}
        </Heading>

        <form name="login" className="signup-form" onSubmit={() => 1}>
          <fieldset className="fieldset">
            {step === 1 && (
              <>
                <BaseInput
                  type="text"
                  value={firstName}
                  label={data.firstNameLabel}
                  onChange={(value) => {
                    handleChange("FirstName", value);
                  }}
                  errors={errors.FirstName}
                  placeholder={"Firstname"}
                  required
                />
                <BaseInput
                  type="text"
                  value={lastName}
                  label={data.lastNameLabel}
                  onChange={(value) => {
                    handleChange("LastName", value);
                  }}
                  errors={errors.LastName}
                  placeholder={"Lastname"}
                  required
                />
                <BaseInput
                  type="email"
                  value={email}
                  label={data.emailLabel}
                  required
                  disabled
                />
                <PasswordInput
                  value={password}
                  label={data.passwordLabel}
                  onChange={(value) => {
                    handleChange("Password", value);
                  }}
                  errors={submittedPassword && errors.Password}
                  required
                />

                <div className="flex items-center justify-center">
                  <BaseButton
                    appearance="primary"
                    className="justify-end login-btn"
                    isDisabled={hasErrors || !password.length}
                    onClick={() => {
                      if (!errors.Password) setStep(step + 1);
                      setSubmittedPassword(true);
                    }}>
                    {data.stepOneButtonLabel}
                  </BaseButton>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <BaseInput
                  type="text"
                  value={companyName}
                  label={data.companyNameLabel}
                  required
                  onChange={(value) => {
                    handleChange("CompanyName", value);
                  }}
                  errors={errors.CompanyName}
                  placeholder={"Company name"}
                />
                <label className="signup label">{data.companyTypeLabel}</label>
                <BaseDropdown
                  options={orgTypes}
                  value={companyType}
                  onChange={({ value }) => {
                    handleChange("CompanyType", value);
                  }}
                  errors={errors.CompanyType}
                  labelKey="value"
                />
                <label className="signup label">{data.sizeOfBusinessLabel}</label>
                <BaseDropdown
                  options={orgSizes}
                  value={sizeOfBusiness}
                  onChange={({ value }) => {
                    handleChange("SizeBusiness", value);
                  }}
                  errors={errors.SizeBusiness}
                  labelKey="value"
                />

                <div className="flex">
                  <BaseCheckboxInput
                    label="I agree to the terms and privacy policy"
                    value={agreeTerms}
                    onChange={(value) => {
                      handleChange("AgreeTerms", value);
                    }}
                    errors={errors.AgreeTerms}
                  />
                </div>

                <div className="flex-column items-center justify-center">
                  <BaseButton
                    appearance="primary"
                    className="justify-end login-btn"
                    isDisabled={
                      hasErrors ||
                      !companyType.length ||
                      !sizeOfBusiness.length ||
                      !agreeTerms
                    }
                    onClick={() => {
                      createAdminAccount();
                    }}
                    isLoading={creatingAccount}>
                    {data.stepTwoButtonLabel}
                  </BaseButton>
                  <BaseButton
                    appearance="subtle"
                    className="back-btn"
                    onClick={() => setStep(step - 1)}>
                    <KeyboardBackspaceIcon style={{ fontSize: 24 }} /> Back
                  </BaseButton>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <div className="flex-column items-center integration-container">
                  <span>Add data now</span>
                  <BaseButton
                    appearance="primary"
                    onClick={() => goTo("/organization-data-sources")}>
                    {"Go to Data Integrations"}
                  </BaseButton>
                  <span>Add data later</span>
                  <BaseButton appearance="outline" onClick={() => goTo("/profile")}>
                    {"Go to Your Account"}
                  </BaseButton>
                </div>
              </>
            )}
          </fieldset>
        </form>
      </div>
      <ReactTooltip effect="solid" delayShow={1000} place="top"></ReactTooltip>
    </div>
  );
}
