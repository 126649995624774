import { memo } from "react";

function SolventInjection() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30px"
      height="30px"
      transform="translate(0, 1)">
      <path
        d="M5.61892 0L5.61892 5.61892L0 5.61892"
        transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 12.008963 19.749456)"
        id="Rectangle-Copy"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0.65"
        transform="translate(0, -1)"
        d="M5,19A1,1 0 0,0 6,20H18A1,1 0 0,0 19,19C19,18.79 18.93,18.59 18.82,18.43L13,8.35V4H11V8.35L5.18,18.43C5.07,18.59 5,18.79 5,19M6,22A3,3 0 0,1 3,19C3,18.4 3.18,17.84 3.5,17.37L9,7.81V6A1,1 0 0,1 8,5V4A2,2 0 0,1 10,2H14A2,2 0 0,1 16,4V5A1,1 0 0,1 15,6V7.81L20.5,17.37C20.82,17.84 21,18.4 21,19A3,3 0 0,1 18,22H6M13,16L14.34,14.66L16.27,18H7.73L10.39,13.39L13"
      />
    </svg>
  );
}

export default memo(SolventInjection);
