import { useCallback, useEffect, useState } from "react";

import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { Warning } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { uid } from "utils";

import {
  ADD_DASHBOARD,
  ADD_WORKSPACE_DASHBOARD,
  DASHBOARDS,
  WORKSPACE,
  WorkspaceClientName
} from "api/workspace";

import { IDashboardGroup } from "models/dashboard";
import { Dashboard, DashboardInput, WorkspaceQuery } from "models/workspace";

import { ErrorBoundary } from "components/base";
import { IconSpinner } from "components/icons";
import { useUserContext } from "components/user/context";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";
import { useWorkspaceDispatch } from "components/workspace/hooks/useWorkspaceDispatch";

import AddDashboardLinks from "./AddDashboardLinks";
import NewDashboardInput from "./NewDashboardInput";
import { getNewDashboardName } from "./utils/dashboardName";

export default function AddDashboard() {
  const workspaceDispatch = useWorkspaceDispatch();

  const [visible, setVisible] = useState(false);
  const [dashboardGroups, setDashboardGroups] = useState<IDashboardGroup[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>(null);
  const [selectedDashboardId, setSelectedDashboardId] = useState("");
  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [canCopy, setCanCopy] = useState<boolean>(false);
  const [showCopyDashboard, setShowCopyDashboard] = useState(false);
  const [copyError, setCopyError] = useState("");
  const [addError, setAddError] = useState("");

  const { workspace, workspaceRefreshObserver } = useWorkspaceContext();

  const [fetchDashboards, { data, loading: isLoading, refetch: refetchDashboards }] =
    useLazyQuery<WorkspaceQuery>(DASHBOARDS, {
      context: {
        clientName: WorkspaceClientName
      }
    });
  const [, { refetch: refetchWorkspace }] = useLazyQuery<WorkspaceQuery>(WORKSPACE, {
    context: {
      clientName: WorkspaceClientName
    }
  });

  const [addWorkspaceDashboard] = useMutation(ADD_WORKSPACE_DASHBOARD);
  const [addDashboard, { loading: addDashboardLoading }] = useMutation(ADD_DASHBOARD);
  const [userContext] = useUserContext();
  const canUserAddDashboardToOrgWorkspace =
    workspace?.type.toLocaleLowerCase() !== "organization" ||
    (workspace?.type.toLocaleLowerCase() === "organization" &&
      userContext?.isAtLeastPowerUser);

  // Reset the component on the change in visibility.
  useEffect(() => {
    if (visible) {
      if (dashboardGroups.length > 0) {
        refetchDashboards();
      } else {
        fetchDashboards();
      }
      setSelectedDashboard(null);
      setCanAdd(false);
      setCanCopy(false);
      setCopyError("");
      setAddError("");
    }
  }, [visible]);

  // Update the copy and add state of new dashboards when a dashboard is selected.
  useEffect(() => {
    if (workspace && selectedDashboard) {
      // Determine if the dashboard is already part of the workspace
      const inWorkspace = workspace?.dashboards?.some(
        (d) => d.dashboardId === selectedDashboard?.dashboardId
      );
      setCanAdd(!inWorkspace);
    }

    setCanCopy(selectedDashboard !== null);
  }, [selectedDashboard, workspace]);

  // Set the selected dashboard based on the result of a clone.
  useEffect(() => {
    if (data?.dashboards && selectedDashboardId) {
      const match = data?.dashboards.find((d) => d.dashboardId === selectedDashboardId);
      if (match) {
        setSelectedDashboard(match);
        setSelectedDashboardId(null);
      }
    }
  }, [data?.dashboards, selectedDashboardId]);

  // Update the grouped dashboards state when the remote dashboards data is updated
  useEffect(() => {
    if (data?.dashboards) {
      const getDashboardTypeTitle = (type: string): [string, number, string] => {
        const title: string = type;
        let order = 0;
        let help = "";
        switch (type) {
          case "Personal":
            order = 0;
            help =
              "Dashboards you have personally created and are only available to you.";
            break;
          case "Shared":
            order = 1;
            help = "Dashboards shared by other users in your organization.";
            break;
          case "Organization":
            order = 2;
            help = "Dashboards created and maintained by your admins.";
            break;
          case "System":
            order = 3;
            help = "Dashboards created by the system that cannot be modified.";
            break;
        }
        return [title, order, help];
      };

      const groupBy = (xs: Dashboard[], key: string): IDashboardGroup[] => {
        const map = xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});

        const groups: IDashboardGroup[] = [];
        for (const [key, dashboards] of Object.entries(map)) {
          const [title, order, help] = getDashboardTypeTitle(key);
          groups.push({
            groupKey: key,
            groupTitle: title,
            order,
            help,
            dashboards: dashboards as Dashboard[]
          });
        }
        return groups.sort((a, b) =>
          a.order > b.order ? 1 : b.order > a.order ? -1 : 0
        );
      };

      const filteredDashboards = data.dashboards;
      setDashboardGroups(groupBy(filteredDashboards, "type"));
    }
  }, [data?.dashboards]);

  // Take action when a dashboard is selected.
  const handleDashboardSelected = useCallback((dashboard: Dashboard) => {
    setSelectedDashboard(dashboard);
  }, []);

  // Take action when a selected dashboard is selected to copied to the workspace.
  const handleCopyDashboard = useCallback(
    (newDashboardTitle: string, newDashboardType: string) => {
      if (!selectedDashboard) return;

      const newDashboard: DashboardInput = {
        parentId: selectedDashboard.dashboardId,
        title: newDashboardTitle,
        type: newDashboardType,
        layout: {
          format: selectedDashboard.layout.format,
          width: selectedDashboard.layout.width,
          height: selectedDashboard.layout.height
        },
        widgets: selectedDashboard.widgets.map((w) => {
          return {
            title: w.title,
            type: w.type,
            x: w.x,
            y: w.y,
            width: w.width,
            height: w.height,
            settings: w.settings
          };
        })
      };
      addDashboard({
        variables: {
          dashboard: newDashboard
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: (data) => {
          refetchDashboards();
          setSelectedDashboardId(data?.addDashboard?.dashboardId);
          setShowCopyDashboard(false);
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          setCopyError(error.message);
        }
      });
    },
    [addDashboard, refetchDashboards, selectedDashboard]
  );

  // Take action when a selected dashboard is selected to be added to the workspace.
  const handleAddDashboard = useCallback(() => {
    if (!selectedDashboard) return;

    addWorkspaceDashboard({
      variables: {
        workspaceId: workspace.workspaceId,
        dashboardId: selectedDashboard.dashboardId,
        position: workspace.dashboards.length
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: () => {
        workspaceDispatch({
          payload: {
            activeDashboardId: selectedDashboard.dashboardId
          }
        });
        // Fix for EVA-2417
        // Set workspace in context to ensure dashboard nav is updated with new dashboard
        refetchWorkspace({ id: workspace.workspaceId }).then((data) =>
          workspaceDispatch({
            payload: {
              workspace: data.data.workspace
            }
          })
        );
        refetchDashboards();
        workspaceRefreshObserver.next(uid());
        setVisible(false);
        setAddError("");
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
        setAddError("Failed to add the dashboard to the workspace");
      }
    });
  }, [
    refetchDashboards,
    addWorkspaceDashboard,
    selectedDashboard,
    workspace?.dashboards?.length,
    workspace?.workspaceId,
    workspaceDispatch,
    workspaceRefreshObserver
  ]);

  return (
    <RootContainer>
      <ErrorBoundary>
        <Modal
          open={visible && !showCopyDashboard}
          width={850}
          wrapClassName={"add-dashboard-modal-root"}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          closable={false}
          footer={[
            <Button
              key="cancel"
              onClick={() => setVisible(false)}
              data-testid="add-dashboard-cancel-button">
              Cancel
            </Button>,
            <Button
              key="copy"
              type="primary"
              onClick={() => setShowCopyDashboard(true)}
              disabled={!canCopy || !canUserAddDashboardToOrgWorkspace}
              data-testid="add-dashboard-clone-button">
              Clone Dashboard
            </Button>,
            <Button
              key="add"
              type="primary"
              onClick={handleAddDashboard}
              disabled={!canAdd || !canUserAddDashboardToOrgWorkspace}
              data-testid="add-dashboard-add-button">
              Add to Workspace
            </Button>
          ]}>
          <>
            <h5>Add Dashboard</h5>
            <AddDashboardHelp>
              Select the dashboard you wish to add to your workspace. You can also create
              a new copy of any existing dashboard.
            </AddDashboardHelp>
            {!canUserAddDashboardToOrgWorkspace && (
              <WarningContainer>
                <Warning style={{ top: 3, paddingRight: 2 }} />
                Please contact an Admin or Power User to make changes to an organization
                workspace
              </WarningContainer>
            )}
            {isLoading && <IconSpinner />}
            {!isLoading && (
              <>
                <DashboardsContainer>
                  <AddDashboardLinks
                    workspace={workspace}
                    dashboardGroups={dashboardGroups}
                    selectedDashboard={selectedDashboard}
                    onDashboardSelected={handleDashboardSelected}
                    showActive={true}
                    showDivider={true}
                  />
                </DashboardsContainer>
              </>
            )}
            {addError !== "" && <ErrorContainer>{addError}</ErrorContainer>}
          </>
        </Modal>

        <NewDashboardInput
          onAdd={(title: string, type: string) => {
            handleCopyDashboard(title.trim(), type);
          }}
          onCancel={() => setShowCopyDashboard(false)}
          isLoading={addDashboardLoading}
          error={copyError}
          visible={showCopyDashboard}
          title="Clone Dashboard"
          dashboardTitle={getNewDashboardName(workspace?.dashboards, selectedDashboard)}
          setType={true}
          addButtonLabel="Clone"
        />
      </ErrorBoundary>

      <StyledButton
        type="link"
        icon={<AddCircleIcon fontSize="large" />}
        onClick={() => setVisible(true)}
        data-testid="add-dashboard-button"
      />
    </RootContainer>
  );
}

const RootContainer = styled.div``;

const DashboardsContainer = styled.div`
  max-height: 400px;
  overflow-x: auto;
`;

const AddDashboardHelp = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const ErrorContainer = styled.div`
  display: inline-flex;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  padding-bottom: 8px;
`;

const WarningContainer = styled.div`
  display: inline-flex;
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  --ant-primary-color: #a2aaad;
  &:hover {
    color: var(--color-text);
  }
`;
