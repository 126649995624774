// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiGrid } from "@mdi/js";
import { Popover } from "antd";

import { updateXDASettings, useVisState } from "../../context";
import XdaGridOptions from "./XdaGridOptions";
import XdaToolbarButton from "./XdaToolbarButton";

function XdaGridToggle() {
  const [visible, setVisible] = useState(false);
  const [{ xda }, visDispatch] = useVisState();
  const { showGrid, showRelativeDepth, showTVDSSDepth } = xda.settings;

  const handleToggle = (active: boolean) => {
    const nextSettings = { ...xda.settings, showGrid: active };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <Popover
      content={<XdaGridOptions />}
      open={visible}
      onOpenChange={(v) => setVisible(v)}
      trigger="click"
      placement="bottomRight">
      <XdaToolbarButton
        icon={<Icon path={mdiGrid} size={1.5} />}
        active={showGrid || showRelativeDepth || showTVDSSDepth}
        tooltipText={"Show Grid"}
        overflowLabel={"Grid Toggle"}
        onToggle={handleToggle}
      />
    </Popover>
  );
}

export default XdaGridToggle;
