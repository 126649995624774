import { memo } from "react";

function IconLogo({ size = 73 }) {
  return (
    <svg width={size} viewBox="0 0 73 26">
      <defs>
        <path
          d="M0 0L26.009 0L26.009 25.5496L0 25.5496L0 0Z"
          transform="translate(0.06568533 0.0504)"
          id="path_55"
        />
        <clipPath id="mask_1">
          <use href="#path_55" />
        </clipPath>
      </defs>
      <g id="Group-14">
        <path
          d="M2.56507 5.441L0.115473 1.2552C-0.209927 0.6992 0.191073 0 0.835173 0L5.73457 0C6.37877 0 6.77967 0.6992 6.45437 1.2552L4.00457 5.441C3.68257 5.9913 2.88717 5.9913 2.56507 5.441"
          transform="translate(56.23706 18.276367)"
          id="Fill-1"
          fill="#00C7B1"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Group-5" transform="translate(22.300049 0)">
          <path
            d="M0 0L26.009 0L26.009 25.5496L0 25.5496L0 0Z"
            transform="translate(0.06568533 0.0504)"
            id="Clip-4"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <path
              d="M20.9502 0C20.7034 0 20.4841 0.1574 20.4053 0.3913L13.9742 19.456C13.7701 20.0609 13.2799 20.1513 13.0058 20.1513C12.7315 20.1513 12.2413 20.0611 12.0372 19.456L5.60414 0.3912C5.52524 0.1574 5.30594 0 5.05914 0L0.575545 0C0.182045 0 -0.0951553 0.3862 0.0306447 0.759L6.92214 21.1819C8.04694 24.5158 11.5285 26.3674 15.2235 25.198C17.0179 24.6303 18.409 23.1983 19.0106 21.415L25.9784 0.759C26.1041 0.3861 25.8269 0 25.4334 0L20.9502 0Z"
              transform="translate(0.06568533 0.05038)"
              id="Fill-3"
              fill="#041C2C"
              fillRule="evenodd"
              stroke="none"
            />
          </g>
        </g>
        <path
          d="M5.05884 25.3523C5.30564 25.3523 5.52494 25.1948 5.60374 24.961L11.9683 6.0938C12.1724 5.4886 12.6627 5.3983 12.9368 5.3983C13.2109 5.3983 13.7012 5.4886 13.9053 6.0938L20.272 24.961C20.3509 25.1949 20.5702 25.3523 20.817 25.3523L25.3004 25.3523C25.6939 25.3523 25.9712 24.9661 25.8453 24.5932L19.0203 4.3676C18.125 1.7142 15.7369 -0.0002 12.9366 0C10.1361 0.0002 7.74834 1.7148 6.85314 4.3684L0.0306373 24.5933C-0.0951627 24.9661 0.182137 25.3523 0.575637 25.3523L5.05884 25.3523Z"
          transform="translate(46.567627 0.14892578)"
          id="Fill-6"
          fill="#041C2C"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M17.8129 5.1152L0.5765 5.1152C0.2581 5.1152 0 4.8571 0 4.5387L0 0.5764C0 0.2581 0.2581 0 0.5765 0L17.8129 0C18.1317 0 18.3903 0.2585 18.3903 0.5773L18.3903 4.5379C18.3903 4.8567 18.1317 5.1152 17.8129 5.1152"
          transform="translate(0 0.05029297)"
          id="Fill-8"
          fill="#041C2C"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M17.8129 5.1152L0.5765 5.1152C0.2581 5.1152 0 4.8571 0 4.5387L0 0.5764C0 0.2581 0.2581 0 0.5765 0L17.8129 0C18.1317 0 18.3903 0.2585 18.3903 0.5773L18.3903 4.5379C18.3903 4.8567 18.1317 5.1152 17.8129 5.1152"
          transform="translate(0 20.484375)"
          id="Fill-10"
          fill="#041C2C"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M17.8129 5.1152L0.5765 5.1152C0.2581 5.1152 0 4.8571 0 4.5387L0 0.5764C0 0.2581 0.2581 0 0.5765 0L17.8129 0C18.1317 0 18.3903 0.2585 18.3903 0.5773L18.3903 4.5379C18.3903 4.8567 18.1317 5.1152 17.8129 5.1152"
          transform="translate(0 10.267578)"
          id="Fill-12"
          fill="#041C2C"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}
export default memo(IconLogo);
