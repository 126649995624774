// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { mdiFolderMultiple } from "@mdi/js";
import { Badge, Tooltip } from "antd";
import { display } from "store/features/files/filesSlice";
import styled from "styled-components/macro";

const FilesToggle = ({ className = "" }) => {
  // dispatches
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(display());
  }, []);

  return (
    <Tooltip title="Files" placement="bottomRight" arrowPointAtCenter>
      <FilesButton className={className} onClick={handleClick}>
        <Badge size="small" overflowCount={10} color="var(--color-primary)">
          <Icon path={mdiFolderMultiple} size="24px" />
        </Badge>
      </FilesButton>
    </Tooltip>
  );
};

export default FilesToggle;

const FilesButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 24px;
  transition: color 0.2s, background-color 0.2s;
  cursor: pointer;

  svg {
    color: var(--color-text-40);
  }

  &:hover svg {
    color: var(--color-text);
  }
`;
