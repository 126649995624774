// eslint-disable-next-line import/no-named-as-default
import { useDispatch, useSelector } from "react-redux";

import Modal from "antd/lib/modal/Modal";
import { hide } from "store/features/files/filesSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { FilesManager, FilesTypesList } from "./components";
import { FilesProvider } from "./context";

const FilesManagement = () => {
  // state from store
  const displayFilesManagement = useSelector((state: RootState) => state.files.display);
  const dispatch = useDispatch();

  return (
    <FilesProvider>
      <Modal
        title="EVA File Exchange"
        open={displayFilesManagement}
        onCancel={() => {
          dispatch(hide());
        }}
        footer={null}
        width={"80%"}>
        <Wrapper>
          <FoldersWrapper>
            <FilesTypesList />
          </FoldersWrapper>
          <FilesWrapper>
            <FilesManager />
          </FilesWrapper>
        </Wrapper>
      </Modal>
    </FilesProvider>
  );
};

export default FilesManagement;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  min-height: 500px;
`;
const FoldersWrapper = styled.div`
  padding-right: 24px;
  margin-right: 24px;
  border-right: 1px solid #ccc;
`;
const FilesWrapper = styled.div`
  width: 100%;
`;
