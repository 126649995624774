import { memo, useState } from "react";

interface ProducingRateProps {
  producing?: boolean;
}

function ProducingRate({ producing = false }: ProducingRateProps) {
  const [hovered, setHovered] = useState(false);

  const activeColor = hovered ? "currentColor" : "#00c7b1";
  const inactiveColor = hovered ? "currentColor" : "#a2aaab";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      transform="scale(1, 1)"
      fill="currentColor"
      style={{
        cursor: "pointer"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <path
        d="M11,7A2,2 0 0,0 9,9V15A2,2 0 0,0 11,17H13A2,2 0 0,0 15,15V14H13V15H11V9H13V10H15V9A2,2 0 0,0 13,7H11Z"
        transform="translate(4, 1.7) scale(0.6, 0.6)"
        fill={producing ? inactiveColor : activeColor}
      />
      <path
        d="M9,7V17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H9M11,9H13V15H11V9Z"
        transform="translate(8, 1.7) scale(0.6, 0.6)"
        fill={producing ? inactiveColor : activeColor}
      />
      <path
        d="M9,7V17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H9M11,9H13V15H11V9Z"
        transform="translate(8, 8.3) scale(0.6, 0.6)"
        fill={producing ? activeColor : inactiveColor}
      />
      <path
        d="M9,7V17H11V13H13A2,2 0 0,0 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9Z"
        transform="translate(4, 8.3) scale(0.6, 0.6)"
        fill={producing ? activeColor : inactiveColor}
      />
    </svg>
  );
}

export default memo(ProducingRate);
