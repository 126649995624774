import React from "react";

import { Delete, DeleteOutline } from "@material-ui/icons";
import { Popconfirm, Tooltip } from "antd";
import styled from "styled-components";

import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import { useMapContext } from "components/map/hooks/useMapContext";

import { useGeomBinDispatch } from "../hooks/useGeomBinDispatch";

export interface LassoSelectionComponentModel {
  drawRef;
  portalRef?: React.MutableRefObject<HTMLDivElement>;
}

export default function DeletePolygon({
  portalRef,
  drawRef
}: LassoSelectionComponentModel): JSX.Element {
  const { mapbox } = useMapContext();

  const geomBinDispatch = useGeomBinDispatch();
  const {
    isActive: isGeomBinOpen,
    geomBinItems,
    selectedGeomBinItem
  } = useGeomBinContext();

  function handleDeleteConfirm(e) {
    const selected = drawRef?.current?.getSelected();

    if (!selected?.features?.length) {
      return;
    }

    const newGeomBinItems = [];

    let order = 1;
    geomBinItems.forEach((item) => {
      if (item.id !== selected.features[0].id) {
        newGeomBinItems.push({ ...item, order: order });
        order++;
      }
    });

    geomBinDispatch({
      payload: {
        geomBinItems: newGeomBinItems,
        selectedGeomBinItem: undefined
      }
    });

    e.stopPropagation();
    e.preventDefault();
  }

  function handleDeleteCancel(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  if (!mapbox || !portalRef?.current || !isGeomBinOpen) {
    return <></>;
  }

  return (
    <Popconfirm
      onConfirm={(evt) => handleDeleteConfirm(evt)}
      onCancel={handleDeleteCancel}
      title={`Please confirm you wish to delete the selected polygon.`}>
      <Tooltip title="Delete Polygon">
        <StyledButton
          danger
          disabled={!selectedGeomBinItem}
          size={36}
          onClick={(e) => e.preventDefault()}>
          {selectedGeomBinItem ? (
            <Delete fontSize="large" />
          ) : (
            <DeleteOutline fontSize="large" />
          )}
        </StyledButton>
      </Tooltip>
    </Popconfirm>
  );
}

const StyledButton = styled.button`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled]:hover > svg {
    cursor: default;
    color: lightgray;
  }
`;
