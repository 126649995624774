import { memo } from "react";

function ISOR() {
  return (
    <svg width="30px" height="31px" viewBox="0 0 30 31">
      <g id="IconsChart---PRPISOR">
        <path
          d="M5.18287 14.071C8.04529 14.071 10.3657 11.7506 10.3657 8.88813C10.3657 6.02571 5.18287 1.99961e-13 5.18287 1.99961e-13C5.18287 1.99961e-13 0 6.02571 0 8.88813C0 11.7506 2.32045 14.071 5.18287 14.071Z"
          transform="translate(19.269167 15.244834)"
          id="Oval-Copy-19"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 26.5666L27 0"
          transform="translate(1.9273319 1.8905864)"
          id="Path-8-Copy-6"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <path
          d="M6.02899 12.058C9.3587 12.058 12.058 9.3587 12.058 6.02899C12.058 2.69927 9.3587 0 6.02899 0C2.69927 0 0 2.69927 0 6.02899C0 9.3587 2.69927 12.058 6.02899 12.058Z"
          transform="translate(0 18.554173)"
          id="Oval-Copy-21"
          fill="#FFFFFF"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Group-4" transform="translate(3.0981874 20.205883)">
          <path
            d="M5.861 4.18L3.042 4.18L3.872 0L0 5.198L2.82 5.198L1.989 9.378L5.861 4.18Z"
            transform="translate(0 0.0001)"
            id="Stroke-2-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinejoin="bevel"
          />
          <path
            d="M0 5.198L2.82 5.198L1.989 9.378L5.861 4.18L3.041 4.18L3.872 0L0 5.198Z"
            transform="translate(0.0003 0)"
            id="Fill-1-Copy-3"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
        <g
          id="Group-7-Copy"
          transform="matrix(0.9945219 0.104528464 -0.104528464 0.9945219 5.8718386 -1.1920929E-07)">
          <path
            d="M2.4563 0C3.22041 4.6472 -0.663982 5.06275 0.100131 9.70995"
            transform="translate(9.094947E-13 1.7975538)"
            id="Stroke-1-Copy-7"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M2.41627 0C4.29418 6.03114 -1.24241 6.4743 0.262692 13.3052"
            transform="translate(3.7986162 2.2737368E-13)"
            id="Stroke-3-Copy-3"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M2.4563 0C3.22041 4.6472 -0.663982 5.06275 0.100131 9.70995"
            transform="translate(7.8093104 1.7975538)"
            id="Stroke-5-Copy-3"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(ISOR);
