import axios from "axios";

const ROOT_ENDPOINT =
  process.env.REACT_APP_DATA_TOOLS_ROOT ?? process.env.REACT_APP_DATA_ROOT;
const DATA_ANALYZER_ENDPOINT = `${ROOT_ENDPOINT}/api/v1/dataanalyzer`;

export async function getBetaFeatures(): Promise<BetaFeatureEntry[]> {
  const response = await axios.get(`${process.env.REACT_APP_USER_SERVICE}/beta-feature`);
  return response.data as BetaFeatureEntry[];
}

export async function getDataFreshness(
  includePerformance: boolean = false
): Promise<DataFreshnessResult> {
  const response = await axios.get(
    `${DATA_ANALYZER_ENDPOINT}/data-freshness?includePerformance=${includePerformance}`
  );
  return response.data as DataFreshnessResult;
}

export async function postWellColumnDataAnalysis(
  request: DataAnalyzerRequest
): Promise<WellColumnDataAnalyzerResult> {
  const response = await axios.post(
    `${DATA_ANALYZER_ENDPOINT}/analyze/wellColumnData`,
    request
  );
  return response.data as WellColumnDataAnalyzerResult;
}

export async function postWellPerformanceDataAnalyzer(
  request: DataAnalyzerRequest
): Promise<WellPerformanceDataAnalyzerResult> {
  const response = await axios.post(
    `${DATA_ANALYZER_ENDPOINT}/analyze/wellPerformance`,
    request
  );
  return response.data as WellPerformanceDataAnalyzerResult;
}

export interface BetaFeatureEntry {
  id: string;
  featureName: string;
}
export interface DataAnalyzerRequest {
  uwi: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings?: any;
}
export interface WellColumnDataAnalyzerResult {
  data: Date;
  uwi: string;
  podId?: string;
  dataFetcherResult: string;
  dataSourceResults: DataSourceFetcherResult[];
  column?: ColumnInfoResult;
}

export interface ColumnInfoResult {
  cacheTier?: number;
  mongoCollection?: string;
  mongoProperty?: string;
  storageGroup?: string;
}

export interface DataSourceFetcherResult {
  dataSource: string;
  dataFetcherResult: string;
  dataFetcherName: string;
  mongoValue: string;
  mongoValueLastUpdated?: Date;
  mcdanMongoValue: string;
  mcdanMongoValueLastUpdated?: Date;
}

export interface WellPerformanceDataAnalyzerResult {
  data: Date;
  uwi: string;
  dataSourceResults: DataSourcePerformanceResult[];
}

export interface DataSourcePerformanceResult {
  pdenDataSource: string;
  forecastSource: string;
  rescat: string;
  dataFetcherResult: string;
  DataFetcherKey: string;
  mongoValue: string;
  mongoValueLastUpdated?: Date;
}

export interface DataFreshnessResult {
  dataSources: DataSourceFreshnessResult[];
  minLastUpdated?: Date;
  maxLastUpdated?: Date;
}

export interface DataSourceFreshnessResult {
  dataSourceName: string;
  collections: DataCollectionFreshnessResult[];
}

export interface DataCollectionFreshnessResult {
  collectionName: string;
  minLastUpdated?: Date;
  maxLastUpdated?: Date;
  queryDuration?: number;
}
