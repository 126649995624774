import { FC, useState } from "react";

import { LockOpenOutlined, LockOutlined } from "@material-ui/icons";
import styled from "styled-components/macro";

import BaseButton from "./BaseButton";

type LockButtonT = {
  isLockedDefault?: boolean;
  clickOff: (e: React.MouseEvent<HTMLButtonElement>) => void;
  clickOn: (e: React.MouseEvent<HTMLButtonElement>) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const LockButton: FC<LockButtonT> = ({ ...props }) => {
  const [isLocked, setIsLocked] = useState<boolean>(props.isLockedDefault ?? true);

  const handleLock = (e) => {
    setIsLocked(true);
    props.clickOn(e);
  };

  const handleUnlock = (e) => {
    setIsLocked(false);
    props.clickOff(e);
  };

  return (
    <SmallBaseButton
      onClick={isLocked ? handleUnlock : handleLock}
      appearance={isLocked ? "default" : "primary"}
      {...props}>
      {isLocked ? (
        <LockOutlined style={{ fontSize: "12px" }} />
      ) : (
        <LockOpenOutlined style={{ fontSize: "12px" }} />
      )}
    </SmallBaseButton>
  );
};

const SmallBaseButton = styled(BaseButton)`
  width: 22px;
  height: 22px;

  padding-left: 0;
  padding-right: 0;
`;

export default LockButton;
