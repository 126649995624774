import { memo } from "react";

function UserArps() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="22px"
      height="22px">
      <g>
        <path
          d=" M 5.813 10.406 C 5.594 9.947 4.875 8.016 4.219 7.453 C 3.562 6.891 3.047 8.016 2.625 7.687 C 2.203 7.359 1.969 6.844 1.594 6.141 C 1.219 5.437 1.172 5.203 1.687 4.781 C 2.26 4.302 2.761 4.14 3.281 3.844 C 4.496 3.298 4.716 3.229 5.366 3.593 C 6.017 3.958 6.765 5.13 7.102 5.823 C 8.842 9.411 10.334 12.501 12.047 15 C 12.721 15.984 13.912 16.837 15.734 17.246 C 17.556 17.656 19.161 17.747 20.202 17.747 C 21.244 17.747 21.981 17.993 21.937 19.312 Q 21.897 20.541 21 20.484 Q 19.219 20.672 18.094 20.531 C 16.969 20.391 15.196 20.299 14.016 19.969 C 12.844 19.641 11.016 18.656 10.078 17.438 C 9.141 16.219 8.482 15.313 7.594 13.734 C 6.706 12.156 6.031 10.865 5.813 10.406 Z "
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
export default memo(UserArps);
