import { useContext } from "react";

import { WorkspaceContext } from "../contexts/WorkspaceContext";

export function useWorkspaceContext() {
  const context = useContext(WorkspaceContext);
  if (context === undefined) {
    throw new Error("useWorkspaceContext must be within a Workspace provider");
  }
  return context;
}
