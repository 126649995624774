import { ReactNode, createContext, useReducer } from "react";

import { produce } from "immer";

import {
  GeomBinContextState,
  SelectGeomBin,
  SelectGeomBinItem,
  UpdateActiveState,
  UpdateBinItems,
  UpdateEditingState,
  UpdateGeomBinDetails,
  UpdateLassoSelector,
  UpdateLoadingState,
  UpdateValidation
} from "./GeomBinContextState";

export const initialGeomBinContextState: GeomBinContextState = {
  isActive: false,
  isLoading: false,
  isEditing: false,
  isLassoSelected: false,
  hasValidationErrors: false,
  validationErrors: undefined,

  selectedGeomBinId: undefined,

  geomBinGroupName: undefined,
  geomBinName: undefined,
  isOrg: false,
  geomBinItems: [],
  selectedGeomBinItem: undefined
};

export const GeomBinContext = createContext(initialGeomBinContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const GeomBinDispatchContext =
  createContext<React.Dispatch<Action<GeomBinUpdates, "update">>>(undefined);

export type GeomBinUpdates =
  | UpdateLassoSelector
  | UpdateLoadingState
  | UpdateActiveState
  | UpdateEditingState
  | SelectGeomBin
  | UpdateBinItems
  | UpdateGeomBinDetails
  | UpdateValidation
  | SelectGeomBinItem;

function GeomBinReducer(
  state: GeomBinContextState,
  action: Action<GeomBinUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface GeomBinProviderModel {
  children: ReactNode;
  state?: GeomBinContextState;
  useWrapper?: boolean;
}

export function GeomBinProvider({
  children,
  state: overrideState
}: GeomBinProviderModel) {
  const [state, dispatch] = useReducer(
    GeomBinReducer,
    overrideState ?? initialGeomBinContextState
  );

  return (
    <GeomBinContext.Provider value={state}>
      <GeomBinDispatchContext.Provider value={dispatch}>
        {children}
      </GeomBinDispatchContext.Provider>
    </GeomBinContext.Provider>
  );
}
