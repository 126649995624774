import { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import {
  ScreenshotContextState,
  UpdateCurrentFieldKey,
  UpdateIsChangingPreset,
  UpdateIsEditing,
  UpdateIsResettingPreset,
  UpdateLoadingState,
  UpdateScreenshotSettingsState,
  UpdateWidget,
  UpdateWidgetScreenshotOverlay,
  UpdateWidgetState
} from "./ScreenshotContextState";

export const initialScreenshotContextState: ScreenshotContextState = {
  isLoading: false,
  isEditing: false,
  isChangingPreset: false,
  isResettingPreset: false,
  currentFieldKey: ""
};

export const ScreenshotContext = createContext(initialScreenshotContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const ScreenshotDispatchContext =
  createContext<React.Dispatch<Action<ScreenshotUpdates, "update">>>(undefined);

export type ScreenshotUpdates =
  | UpdateCurrentFieldKey
  | UpdateIsChangingPreset
  | UpdateIsEditing
  | UpdateLoadingState
  | UpdateIsResettingPreset
  | UpdateScreenshotSettingsState
  | UpdateWidget
  | UpdateWidgetState
  | UpdateWidgetScreenshotOverlay;

function ScreenshotReducer(
  state: ScreenshotContextState,
  action: Action<ScreenshotUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface ScreenshotProviderModel {
  children: ReactNode;
  state?: ScreenshotContextState;
  useWrapper?: boolean;
}

export function ScreenshotProvider({
  children,
  state: overrideState
}: ScreenshotProviderModel) {
  const [state, dispatch] = useReducer(
    ScreenshotReducer,
    overrideState ?? initialScreenshotContextState
  );

  return (
    <ScreenshotContext.Provider value={state}>
      <ScreenshotDispatchContext.Provider value={dispatch}>
        {children}
      </ScreenshotDispatchContext.Provider>
    </ScreenshotContext.Provider>
  );
}
