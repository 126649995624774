import { memo } from "react";

function Line({ size = 18 }) {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} fill="currentColor">
      <path
        fill="currentColor"
        d="M15,3V7.59L7.59,15H3V21H9V16.42L16.42,9H21V3M17,5H19V7H17M5,17H7V19H5"
      />
    </svg>
  );
}
export default memo(Line);
