import { useEffect, useState } from "react";

import { useSystemShapefilesQuery } from "components/project/shapefiles/queries";

export interface OrgTreeT {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tree: any;
  isLoading: boolean;
  error: string;
}

export function useSystemShapefiles(): OrgTreeT {
  const systemShapefiles = useSystemShapefilesQuery({});

  // state
  const [tree, setTree] = useState([]);

  const parseShapefileChildren = (node): [] => {
    return {
      ...node,
      key:
        node.type === "root"
          ? "mcDanielLevelShapefiles"
          : node.shapefileId
          ? node.shapefileId
          : node.shapefileNodeId,
      className: node.children ? "shapefile-folder" : "shapefile-leaf",
      ...(node.children && {
        children: node.children.map((ch) => parseShapefileChildren(ch))
      }),
      source: "system",
      ...(node.type === "shapefile" && {
        layer: {
          style: {
            color: node.color,
            strokeColor: node.strokeColor,
            thickness: node.thickness,
            opacity: node.opacity
          }
        }
      })
    };
  };

  useEffect(() => {
    if (systemShapefiles.data) {
      const treeData = [];
      const shapefile = systemShapefiles.data;
      treeData.push(parseShapefileChildren(shapefile));
      setTree(treeData);
    }
  }, [systemShapefiles.data]);

  return {
    tree,
    isLoading: systemShapefiles.isLoading,
    error: null
  };
}
