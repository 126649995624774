import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef } from "react";

import axios from "axios";
import _debounce from "lodash/debounce";
import _map from "lodash/map";
import styled from "styled-components";

import { LsdInfo, ZoneInfo } from "models/xdaModels";
import * as zv from "models/zoneVisualization";

import "./WellSurveyVisualizer.scss";
import { Toolbar } from "./components";
import FullscreenToggleButton from "./toolbar/FullscreenToggleButton";

const visServiceEndpoint = process.env.REACT_APP_VIS_SERVICE;

function WellSurveyVisualizer({ well, onFullscreenToggle }) {
  const containerRef = useRef(null);
  const abortControllerRef = useRef<AbortController>();
  const selfRef = useRef({
    vis: null,
    lsds: null
  });

  useResizeObserver(containerRef.current, (client) => {
    if (!selfRef.current.vis) return;
    const bounce = _debounce(() => {
      selfRef.current.vis.UpdateWidthHeight(
        client.contentRect.width,
        client.contentRect.height
      );
    }, 500);
    bounce();
  });

  useEffect(() => {
    function DrawWellVizScene(data) {
      const self = selfRef.current;
      const lsds = _map(data.lsds, (w) => {
        const wi = new LsdInfo();
        wi.ELon = w.ELon;
        wi.NLat = w.NLat;
        wi.SLat = w.SLat;
        wi.Formatted = w.Formatted;
        wi.WLon = w.WLon;
        wi.Survey = w.Survey;
        wi.Zones = _map(w.Zones, (z) => {
          const zi = new ZoneInfo();
          zi.Name = z.Zone;
          zi.Pay = z.Pay;
          zi.DepthSS = z.DepthSS;
          return zi;
        });
        return wi;
      });
      data.lsds = lsds;
      self.lsds = lsds.map((lsd) => lsd.Survey);
      if (!self.vis) {
        return;
      }
      self.vis.DrawWellVizScene(data);
    }

    function showScene(uwi) {
      if (!uwi || uwi === "undefined" || uwi === "") {
        DrawWellVizScene({});
        return;
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      axios
        .get(`${visServiceEndpoint}/wellSurvey/${uwi}`, {
          signal: abortControllerRef.current.signal
        })
        .then((response) => {
          const data = response.data;
          DrawWellVizScene(data);
        })
        .catch(() => DrawWellVizScene({}));
    }

    if (containerRef.current == null) return;
    showScene(well);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [well]);

  //initialize
  useEffect(() => {
    if (containerRef.current == null) return;
    const self = selfRef.current;

    function initializeComponent() {
      const container = containerRef.current;
      const height = container.clientHeight;
      const width = container.clientWidth;

      self.vis = new zv.Visualization(
        width,
        height,
        { zoneDepthFontSize: 10 },
        container
      );
    }

    initializeComponent();
  }, [containerRef]);

  return (
    <Wrapper ref={containerRef} className={`acrylic shadow `}>
      <div id="wellvis" className="acrylic" />
      <Toolbar mouseoverRef={containerRef}>
        <FullscreenToggleButton onToggle={(v) => onFullscreenToggle(v)} />
      </Toolbar>
    </Wrapper>
  );
}

export default WellSurveyVisualizer;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
