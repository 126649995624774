import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import styled from "styled-components";

import { Tooltip } from "components/base";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";

const tooltip_text = "Toggle Cum + Forecast";

function ForecastToggle() {
  const { isForecastToggleOn } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();

  const toggleForecast = () => () =>
    updateGroupByState({
      type: "forecast_toggle",
      payload: !isForecastToggleOn
    });

  return (
    <Tooltip title={tooltip_text} placement="topRight">
      <Toggle
        data-testid="focus-forecast-toggle-btn"
        active={isForecastToggleOn}
        onClick={toggleForecast()}>
        <TrendingDownIcon fontSize="large" />
      </Toggle>
    </Tooltip>
  );
}

export default ForecastToggle;

const Toggle = styled.div`
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ active }) => (active ? "var(--color-primary)" : "#fff")};
  border: 1px solid rgb(217, 217, 217);
  color: ${({ active }) => (active ? "#fff" : "rgb(162, 170, 173)")};
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: ${({ active }) => (active ? "var(--color-primary)" : "var(--color-text)")};
    border-color: var(--color-primary);
  }
`;
