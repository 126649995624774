import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { GEO_CALCULATION_SERVICE_ADDRESS } from "../modals/constants";

type cancelJobParams = {
  jobId: string;
  runId: string;
};

export function useCancelGeoCalculationJob() {
  const queryClient = useQueryClient();
  const cancelJob = async (params: cancelJobParams) => {
    const response = await axios.post(
      `${GEO_CALCULATION_SERVICE_ADDRESS}/geo-calculation/jobs/cancel/${params.jobId}/${params.runId}`
    );

    if (response.status !== 200) {
      throw new Error("Problem cancelling job");
    }
  };
  return useMutation(cancelJob, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("geoCalculations");
    }
  });
}
