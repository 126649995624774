import classnames from "classnames";
import styled from "styled-components";

import { visibilityToggleStyles } from "../../shared/SharedStyles";
import useMouseOver from "../context/useMouseover";

export type ToolbarDirectionT = "row" | "column";
export type ToolbarT = {
  children: React.ReactNode;
  direction?: ToolbarDirectionT;
  mouseoverRef?: React.RefObject<HTMLDivElement>;
};

function Toolbar({ children, direction = "row", mouseoverRef }: ToolbarT) {
  const visible = useMouseOver(mouseoverRef);
  const wrapperClassnames = classnames({ visible });

  return (
    <Wrapper className={wrapperClassnames} direction={direction}>
      {children}
    </Wrapper>
  );
}

export default Toolbar;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  gap: 6px;
  z-index: 1201;
`;
