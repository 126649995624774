import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Lock, LockOpen, Warning } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, Input, Radio, Select } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BinType, QuantileState } from "models";
import { mBinSize } from "models/binSize";
import { EntityKind } from "models/entityKind";
import { QuantileType } from "models/quantileType";

import { Tooltip } from "components/base";
import { useUserDefaults } from "components/user/hooks";

interface BinSettingsModel {
  bin: mBinSize;
  entityKind?: EntityKind;
  canEditBin: boolean;
  onLock?: (boolean?) => void;
  onReset: () => void;
  onBinChange: (any) => void;
  onBinSettingChange: (
    binType: BinType,
    quantile: QuantileState,
    useDynamicBins: boolean
  ) => void;
  isMinCountWarningVisible?: boolean;
  groupByPropertyName?: string;
}

function BinSettings({
  bin,
  entityKind = EntityKind.Well,
  canEditBin,
  onLock,
  onReset,
  onBinChange,
  onBinSettingChange,
  isMinCountWarningVisible,
  groupByPropertyName
}: BinSettingsModel) {
  const numberRegex = /^-?\d*(\.\d*)?$/;
  const [numQuantile, setNumQuantile] = useState<number>(
    bin?.Quantile?.numQuantiles ?? 4
  );
  const [isEmptyBinSizeWarningVisible, setIsEmptyBinSizeWarningVisible] = useState(false);
  const viewLocked = useSelector((state: RootState) => state.map.viewLock);
  const userDefaults = useUserDefaults();

  const handleBinTextChange = (key) => (e) => {
    const value = e.target.value;

    if (value === "") {
      onBinChange({ [key]: null });
    } else if (numberRegex.test(value)) {
      onBinChange({ [key]: value });
    }
    if (key === "BinSize" && !value && value !== 0) {
      setIsEmptyBinSizeWarningVisible(true);
    } else if (key === "BinSize" && (value || value === 0)) {
      setIsEmptyBinSizeWarningVisible(false);
    }
  };
  const handleMaxBinSortOrderChange = () => (value) => {
    onBinChange({ ["MaxBinsSortOrder"]: value });
  };
  const [selectedQuantileType, setSelectedQuantileType] = useState<QuantileType>(
    bin.Quantile?.quantileType
  );
  const binType =
    bin.BinType === "BinSize" && bin.UseDynamicBins ? "Dynamic" : bin.BinType;
  const defaultBinType =
    userDefaults?.binSettings?.type === "BinSize" &&
    userDefaults?.binSettings?.useDynamicBins
      ? "Dynamic"
      : userDefaults?.binSettings?.type;
  const finalBinType = canEditBin ? binType ?? defaultBinType ?? "BinSize" : "BinSize";

  function onBinKeyDown(event, binType: BinType, quantileType) {
    if (event.key === "Enter" && !isEmptyBinSizeWarningVisible) {
      onBinSettingChange(
        binType,
        {
          quantileType,
          numQuantiles: numQuantile
        },
        binType === "Dynamic"
      );
      if (canEditBin) {
        onLock?.(true);
      }
    }
  }

  useEffect(() => {
    onBinSettingChange(
      bin.BinType,
      {
        quantileType: selectedQuantileType,
        numQuantiles: numQuantile
      },
      bin.BinType === "Dynamic"
    );
    //dont want exhaustive since it will cause infinite looping
  }, [selectedQuantileType, numQuantile]);

  useEffect(() => {
    if (bin.BinType === "Quantile" && !selectedQuantileType) {
      setSelectedQuantileType("NumberOfQuantile");
    }
  }, [selectedQuantileType, bin]);

  // If a user changes their group by focus field (resource play to Hz length) reset to default quantile type and number of quantile.
  useEffect(() => {
    setSelectedQuantileType(userDefaults.binSettings.quantile.quantileType);
    setNumQuantile(userDefaults.binSettings.quantile.numQuantiles);
  }, [groupByPropertyName]);

  // Handle warning message when bins are brought in from groupBy change
  useEffect(() => {
    if (bin.BinSize || !canEditBin || bin.BinSize === 0) {
      setIsEmptyBinSizeWarningVisible(false);
    } else if (!bin.BinSize && bin.BinSize !== 0 && canEditBin) {
      setIsEmptyBinSizeWarningVisible(true);
    }
  }, [bin.BinSize, canEditBin]);

  return (
    <Wrapper>
      <BinSettingsContent>
        <BinOptionsRow>
          <RadioGroupWrapper>
            <Select
              data-testid="bin-type-select"
              value={finalBinType}
              onChange={(val) => {
                onBinSettingChange(
                  val,
                  {
                    quantileType: selectedQuantileType,
                    numQuantiles: numQuantile
                  },
                  val === "Dynamic"
                );
              }}
              options={(canEditBin
                ? ["Bin Size", "Quantile", "Dynamic"]
                : ["Bin Size"]
              ).map((item) => ({
                value: item.replace(" ", ""),
                label: item
              }))}
            />
          </RadioGroupWrapper>
          {bin.UseDynamicBins && canEditBin && onLock && (
            <BinLockButton
              type="link"
              icon={bin.IsLocked ? <LockIconWrapper /> : <LockOpen />}
              onClick={() => onLock()}
            />
          )}
          <BinResetButton type="link" icon={<RefreshIcon />} onClick={() => onReset()}>
            Reset Default
          </BinResetButton>
        </BinOptionsRow>
        {finalBinType != "Quantile" && (
          <BinSettingsContentGrid columnCount={canEditBin ? 4 : 3}>
            <Tooltip title="Min Count">
              <BinInputWrapper>
                <BinInputLabel>Min count</BinInputLabel>
                <StyledInput
                  type="number"
                  min={0}
                  value={bin.MinSize}
                  onChange={handleBinTextChange("MinSize")}
                  onKeyDown={(evt) =>
                    onBinKeyDown(evt, bin.BinType, selectedQuantileType)
                  }
                  style={{ borderColor: isMinCountWarningVisible ? "red" : "black" }}
                />
              </BinInputWrapper>
            </Tooltip>
            {!canEditBin && (
              <>
                <Tooltip title="Max Bins">
                  <BinInputWrapper>
                    <BinInputLabel>Max Bins</BinInputLabel>
                    <StyledInput
                      type="text"
                      value={bin.MaxBins}
                      onChange={handleBinTextChange("MaxBins")}
                      onKeyDown={(evt) =>
                        onBinKeyDown(evt, bin.BinType, selectedQuantileType)
                      }
                    />
                  </BinInputWrapper>
                </Tooltip>
                <BinInputWrapper>
                  <BinInputLabel>Max Bin Criteria</BinInputLabel>
                  <StyledSelect
                    disabled={!bin.MaxBins}
                    value={bin.MaxBinsSortOrder ?? "WellCount"}
                    dropdownMatchSelectWidth={false}
                    onChange={handleMaxBinSortOrderChange()}
                    options={[
                      { value: "WellCount", label: `${entityKind} Count` },
                      {
                        value: "Production",
                        label:
                          entityKind === EntityKind.Well ? "Production" : "Gas Outlet"
                      }
                    ]}
                  />
                </BinInputWrapper>
              </>
            )}
            {canEditBin && (
              <>
                <Tooltip title="Less Than">
                  <BinInputWrapper>
                    <BinInputLabel>Less than</BinInputLabel>
                    <StyledInput
                      type="text"
                      disabled={!canEditBin}
                      value={bin.LessThan}
                      onChange={handleBinTextChange("LessThan")}
                      onKeyDown={(evt) =>
                        onBinKeyDown(evt, bin.BinType, selectedQuantileType)
                      }
                    />
                  </BinInputWrapper>
                </Tooltip>
                <Tooltip title="Bin Size">
                  <BinInputWrapper>
                    <BinInputLabel>Bin size</BinInputLabel>
                    <StyledInput
                      type="text"
                      disabled={!canEditBin}
                      value={bin.BinSize}
                      onChange={handleBinTextChange("BinSize")}
                      onKeyDown={(evt) =>
                        onBinKeyDown(evt, bin.BinType, selectedQuantileType)
                      }
                    />
                  </BinInputWrapper>
                </Tooltip>
                <Tooltip title="Greater Than">
                  <BinInputWrapper>
                    <BinInputLabel>Greater than</BinInputLabel>
                    <StyledInput
                      type="text"
                      disabled={!canEditBin}
                      value={bin.GreaterThan}
                      onChange={handleBinTextChange("GreaterThan")}
                      onKeyDown={(evt) =>
                        onBinKeyDown(evt, bin.BinType, selectedQuantileType)
                      }
                    />
                  </BinInputWrapper>
                </Tooltip>
              </>
            )}
          </BinSettingsContentGrid>
        )}
        {finalBinType == "Quantile" && (
          <>
            <Radio.Group
              value={
                selectedQuantileType ?? userDefaults?.binSettings?.quantile?.quantileType
              }
              onChange={(evt) => {
                setSelectedQuantileType(evt.target.value);
                onBinSettingChange(
                  bin.BinType,
                  {
                    quantileType: evt.target.value,
                    numQuantiles: numQuantile
                  },
                  bin.BinType === "Dynamic"
                );
              }}>
              <Radio.Button value="NumberOfQuantile"># of Quantile</Radio.Button>
              <Tooltip title="InterQuartile Range (Q1-Q3)">
                <Radio.Button value="IQR">IQR</Radio.Button>
              </Tooltip>
            </Radio.Group>
            <Input
              placeholder="Number of Quantiles"
              type="number"
              disabled={bin.Quantile?.quantileType != "NumberOfQuantile"}
              step={1}
              value={numQuantile ?? userDefaults?.binSettings?.quantile?.numQuantiles}
              onChange={(evt) => {
                setNumQuantile(parseInt(evt.target.value));
              }}
              min={2}
              max={12}
            />
            {viewLocked && (
              <LockMessageWrapper>
                <LockIconWrapper /> Map locked to prevent distribution changes
              </LockMessageWrapper>
            )}
          </>
        )}
        {isMinCountWarningVisible && (
          <WarningContainer data-testid="warning-message">
            <Warning style={{ top: 3 }} />{" "}
            {
              "Focus has been updated due to additional filters. Please review the min count bin."
            }
          </WarningContainer>
        )}
        {isEmptyBinSizeWarningVisible && canEditBin && (
          <WarningContainer data-testid="warning-empty-bin-size-message">
            <Warning style={{ top: 3 }} /> {"Numeric fields require a bin size."}
          </WarningContainer>
        )}
        {finalBinType != "Quantile" && (
          <BinUpdateButton
            onClick={() => {
              onBinSettingChange(
                bin.BinType,
                {
                  quantileType: selectedQuantileType,
                  numQuantiles: numQuantile
                },
                bin.BinType === "Dynamic"
              );
              if (canEditBin) {
                onLock?.(true);
              }
            }}
            disabled={isEmptyBinSizeWarningVisible && canEditBin}
            type="primary">
            Update Bin Settings
          </BinUpdateButton>
        )}
      </BinSettingsContent>
    </Wrapper>
  );
}

export default BinSettings;

const Wrapper = styled.div`
  min-height: 150px;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  .ant-select {
    min-width: 140px;
  }
  .ant-radio-button-wrapper {
    font-size: 11px;
  }
`;

const BinSettingsContent = styled.div`
  background: var(--bin-section-bg, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 6px 9px;
  padding: 10px 16px;
  overflow-y: overlay;
  height: 100%;
`;

const BinSettingsContentGrid = styled(BinSettingsContent)`
  background: var(--bin-section-bg, #ffffff);
  display: grid;
  grid-template-columns: ${({ columnCount }) => `repeat(${columnCount}, 1fr)`};
  gap: 6px 9px;
  padding: 0;
  overflow-y: overlay;
`;
const BinInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  ant-select-selector {
    height: 30px;
  }
`;

const BinOptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  grid-column: 1 / span 4;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  .ant-checkbox-wrapper {
    font-size: 0.9em;
    align-items: center;
    .ant-checkbox {
      top: 0;
    }
  }
`;

const BinInputLabel = styled.span`
  font-size: 1.2rem;
`;

const BinUpdateButton = styled(Button)`
  --ant-primary-color-hover: var(--color-primary-hover);
  grid-column: 1 / span 4;
  border-radius: 4px;
  font-weight: 500;
`;

const StyledInput = styled(Input)`
  height: 30px;
  border-radius: var(--border-radius);
`;

const StyledSelect = styled(Select)`
  height: 30px;
  ant-select-selector {
    height: 30px;
  }
`;

const BinResetButton = styled(Button)`
  justify-self: flex-end;
  grid-column: 1 / span 4;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #a2aaad;
  font-size: 1.2rem;
  font-weight: var(--fontWeightRegular);
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: var(--color-primary);
  }
`;

const BinLockButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  outline: none;
  color: #a2aaad;
  &:hover {
    color: var(--color-primary);
  }
`;

const LockMessageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LockIconWrapper = styled(Lock)`
  color: var(--color-primary);
`;

const WarningContainer = styled.div`
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
