import { useState } from "react";
import { useMutation } from "react-query";

import { Error } from "@material-ui/icons";
import { Button, Modal, Table, Typography } from "antd";
import { IHS_SOURCE } from "constants/datasource.constants";
import styled from "styled-components";

import {
  CredentialModel,
  DataSourceAccess,
  DataTypeAccessResult,
  Result,
  addCredential
} from "api/dataSource";

import { BaseButton, BaseInput } from "components/base";
import BaseModal from "components/base/BaseModal";
import PasswordInput from "components/base/PasswordInput";

const { Paragraph } = Typography;
function formatKey(key) {
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
}

function validateString(key, value) {
  let error = null;
  if (!value.length) {
    error = `${formatKey(key)} cannot be blank`;
  }
  return error;
}
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 36px;

  .error-message {
    padding: 5px;
    color: red;
  }
`;
const accessColumns = [
  {
    title: "Access Type",
    dataIndex: "dataType",
    key: "dataType"
  },
  {
    title: "Well Count",
    dataIndex: "wellCount",
    key: "wellCount"
  }
];
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function AddIhsDataSourceModal({
  open,
  onClose,
  orgId,
  name
}): JSX.Element {
  const [formValues, setFormValues] = useState<CredentialModel>({
    username: "",
    password: "",
    host: "db-ihsdata.enr.ihsenergy.com",
    serviceName: "ihsdata",
    dataSource: IHS_SOURCE
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    host: "",
    serviceName: ""
  });
  const [dataTypeAccessData, setDataTypeAccessData] =
    useState<DataTypeAccessResult[]>(null);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const mutation = useMutation(
    async (data: CredentialModel) => {
      setSavingDataSource(true);
      const result = await addCredential(orgId, IHS_SOURCE, data);
      setSavingDataSource(false);
      return result;
    },
    {
      onSuccess: (data: Result<DataSourceAccess>) => {
        if (data.ok) {
          onClose && onClose();
          const access = data.value?.dataTypesAccess ?? [];
          setDataTypeAccessData(access);
          setShowAccessModal(true);
        }
      }
    }
  );
  const [savingDataSource, setSavingDataSource] = useState(false);

  const hasErrors = Object.keys(errors).some((key) => errors[key]);

  function handleFormChange(key, value) {
    setFormValues((prevState) => ({ ...prevState, [key]: value }));
    setErrors((prevState) => ({
      ...prevState,
      [key]: validateString(key, value)
    }));
  }

  function validateFields() {
    let currErrors = false;
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key].length) {
        handleFormChange(key, formValues[key]);
        currErrors = true;
      }
    });
    return currErrors;
  }

  async function saveDataSource() {
    mutation.mutate(formValues);
  }

  function onSubmit() {
    const currErrors = validateFields();
    if (currErrors) return;
    saveDataSource();
    setSavingDataSource(true);
  }

  return (
    <>
      <BaseModal
        title={`Add ${name} Credentials`}
        open={open}
        onClose={() => onClose()}
        size="sm"
        className="add-data-source-dialog">
        <div className="flex-column container">
          <div className="flex-column">
            <p>User Name</p>
            <div className="flex-row justify-between items-center">
              <div className="flex-column">
                <BaseInput
                  value={formValues.username}
                  onChange={(value) => handleFormChange("username", value)}
                  errors={errors.username}
                />
              </div>
            </div>
            <p>Password</p>
            <PasswordInput
              value={formValues.password}
              onChange={(value) => handleFormChange("password", value)}
              errors={errors.password}
            />
            <p>Host</p>
            <div className="flex-row justify-between items-center">
              <div className="flex-column">
                <BaseInput
                  className="server-input"
                  value={formValues.host}
                  disabled
                  onChange={(value) => handleFormChange("host", value)}
                  errors={errors.host}
                />
              </div>
            </div>
            <p>Service Name</p>
            <div className="flex-row justify-between items-center">
              <div className="flex-column">
                <BaseInput
                  className="service-input"
                  value={formValues.serviceName}
                  disabled
                  onChange={(value) => handleFormChange("serviceName", value)}
                  errors={errors.serviceName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row justify-center">
          <ButtonContainer className="flex-row justify-center">
            {savingDataSource && (
              <div>Checking access to IHS, this can take a minute...</div>
            )}
            <div className="error-message">
              {mutation.data && mutation.data.ok === false && <Error />}
              {mutation.data && mutation.data.ok === false
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  mutation.data?.error ?? "an error occurred"
                : ""}
            </div>
            <BaseButton
              appearance="primary"
              onClick={onSubmit}
              isDisabled={hasErrors || savingDataSource}
              isLoading={savingDataSource}>
              Add Data Source
            </BaseButton>
          </ButtonContainer>
        </div>
      </BaseModal>
      <Modal
        open={showAccessModal}
        onOk={() => setShowAccessModal(false)}
        onCancel={() => setShowAccessModal(false)}
        closable={true}
        title={<CustomTitle>Success</CustomTitle>}
        wrapClassName="edit-data-source-dialog"
        footer={[
          <Button key="cancel" onClick={() => setShowAccessModal(false)}>
            Ok
          </Button>
        ]}>
        <BigFontParagraph>
          We have successfully confirmed your IHS credential. Your IHS credential has
          access to the following data set.
        </BigFontParagraph>
        <Table
          dataSource={dataTypeAccessData}
          columns={accessColumns}
          pagination={false}></Table>
        <BigFontParagraph>
          Please note that it will <strong>take around 5 hours</strong> to fetch all the
          data from IHS.
        </BigFontParagraph>
      </Modal>
    </>
  );
}

const CustomTitle = styled.div`
  font-size: 1.5em;
`;

const BigFontParagraph = styled(Paragraph)`
  font-size: 1.2em;
`;
