import { ALL_CHART_TYPES } from "constants/chart.constants";

import { IChartType } from "models/chart";

/**
 * Get chart-type for given label.
 *
 * @param {string} label label of chart-type
 * @returns {IChartType} chart-type
 */
const getChartType = (label: string): IChartType => {
  if (!label) return null;
  const chartType = Object.values(ALL_CHART_TYPES).find(
    (chartType) => chartType.label === label
  );

  if (!chartType) {
    throw "invalid label provided";
  }

  return chartType;
};

export default getChartType;
