import getChartType from "./getChartType";

/**
 * Get chart-type Id for given label.
 *
 * @param {string} label of chart-type
 * @returns {number} chart-type Id or -1 if chart-type is not found
 */
const getChartTypeId = (label: string): number => {
  if (!label) return -1;

  const chartType = getChartType(label);

  if (!chartType) return -1;

  return chartType.id;
};

export default getChartTypeId;
