import { memo } from "react";

function MapScreenshot({ style = {} }) {
  return (
    <svg
      className="MuiSvgIcon-root"
      width="16px"
      height="14px"
      viewBox="0 0 16 14"
      version="1.1"
      style={{ ...style }}>
      <path
        d="M12.618 2L13.9999 2C15.103 2 16 2.89698 16 3.99998L16 12C16 13.103 15.103 14 13.9999 14L2.00007 14C0.896998 14 0 13.1025 0 11.9995L0.00537203 4.00247C0.00632196 2.90189 0.901879 2.00541 2.002 2.00344L3.38192 2.00099L4.38148 0L11.6175 0L12.618 2ZM3.99961 7.49998C3.99961 9.70555 5.79409 11.5 7.99974 11.5C10.2054 11.5 11.9999 9.70555 11.9999 7.49998C11.9999 5.2944 10.2054 3.49997 7.99974 3.49997C5.79409 3.49997 3.99961 5.2944 3.99961 7.49998ZM9.41402 8.91419C10.1951 8.13314 10.1951 6.86682 9.41402 6.08577C8.63295 5.30472 7.36659 5.30472 6.58552 6.08577C5.80445 6.86682 5.80445 8.13314 6.58552 8.91419C7.36659 9.69524 8.63295 9.69524 9.41402 8.91419Z"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}

export default memo(MapScreenshot);
