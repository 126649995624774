import { Component } from "react";

import { Button } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

const title = "We're sorry, it looks like we're having issues.";
const buttonLabel = "Click to Retry";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false
  };
  static propTypes: { children: PropTypes.Validator<unknown> };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryRoot>
          <h5>{title}</h5>
          <Button type="primary" onClick={() => this.setState({ hasError: false })}>
            {buttonLabel}
          </Button>
        </ErrorBoundaryRoot>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundaryRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
