import { PerformanceTypeT } from "components/multiphase-chart/components/settings/toggles/presets/Tabs/Products/ProductStyleSelector";

const getPerformanceTypeFromLabel = (label?: string): PerformanceTypeT => {
  if (label?.toLowerCase()?.includes("forecast")) {
    return "forecast";
  }

  if (label?.toLowerCase()?.includes("backfit")) {
    return "backfit";
  }

  return undefined;
};

export default getPerformanceTypeFromLabel;
