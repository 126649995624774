// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiFolderPlus } from "@mdi/js";

import { ShapefileAction } from "../..";

interface IShapefileFolderAddActionProps {
  addFolder: () => void;
  isDisabled?: boolean;
}

export function ShapefileFolderAddAction({
  addFolder,
  isDisabled
}: IShapefileFolderAddActionProps) {
  return (
    <ShapefileAction
      disabled={isDisabled}
      placement="bottom"
      onClick={(evt) => {
        addFolder();
        evt.stopPropagation();
        evt.preventDefault();
      }}>
      <Icon path={mdiFolderPlus} size={1} />
      Add Folder
    </ShapefileAction>
  );
}
