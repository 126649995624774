import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import formatPropertyName from "utils/column/formatPropertyName";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";
import getGroupByListColumnByProperty from "utils/column/getGroupByListColumnByProperty";

import { IGroupByListColumn } from "models";

import { EntityKind } from "../models/entityKind";

export interface IProperty extends IGroupByListColumn {
  fullContextTitle: string;
  fullContextTitleWithNormalization: string;
  lightContextTitle: string;
  partialContextTitle: string;
}

export default function useProperty(
  propertyName: string,
  forecastToggleOn = false,
  selectedForecastFolder?: string,
  entityKind: EntityKind = EntityKind.Well
): IProperty {
  const [property, setProperty] = useState<IProperty>(null);
  // Get category properties for column and header
  const categories = useSelector((state: RootState) =>
    entityKind === EntityKind.Well
      ? state.groupBy.categoryList
      : state.groupBy.facilityFields
  );

  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );

  const globalUseNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );

  useEffect(() => {
    if (!categories) return;

    propertyName = formatPropertyName(propertyName);

    const newProp = getGroupByListColumnByProperty(categories, propertyName);
    if (newProp) {
      const contextTitle = getColumnLabelWithContext(
        newProp,
        globalUseNormalizeBy,
        globalNormalizeBy,
        forecastToggleOn,
        selectedForecastFolder,
        entityKind
      );
      const fullContextTitle = contextTitle.fullContextTitle;
      const fullContextTitleWithNormalization =
        contextTitle.fullContextTitleWithNormalization;
      const lightContextTitle = contextTitle.lightContextTitle;
      const partialContextTitle = contextTitle.partialContextTitle;

      setProperty({
        ...newProp,
        fullContextTitle,
        fullContextTitleWithNormalization,
        lightContextTitle,
        partialContextTitle
      });
    }
  }, [categories, globalNormalizeBy, globalUseNormalizeBy, propertyName]);

  useEffect(() => {
    if (!property) return;
  }, [property]);

  return property;
}
