// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { useQuery } from "@apollo/client";
import { mdiMessageOutline } from "@mdi/js";
import { List } from "antd";
import styled from "styled-components";

import { GET_CHANGE_RECORDS } from "../../../api/userArps";
import BusyIndicator from "../../data-table/BusyIndicator";
import {
  ChangeItem,
  ChangeRecord,
  ChangeRecordCollection
} from "../projects/models/ChangeRecord";
import { RestoreForecastButton } from "./RestoreForecastButton";

export interface ForecastChangeRecordsModel {
  forecastId: string;
}

interface ChangeItemModel {
  items: ChangeItem[];
}

export const ForecastChangeRecords = function ({
  forecastId
}: ForecastChangeRecordsModel) {
  const { data, loading } = useQuery<ChangeRecordCollection>(GET_CHANGE_RECORDS, {
    variables: {
      forecastId
    }
  });

  const ChangeItems = ({ items }: ChangeItemModel) => {
    const getDescription = (item: ChangeItem) => {
      let title = item.field;
      if (item.from && item.to) {
        title += `: ${item.from} -> ${item.to}`;
      }
      return title;
    };
    return (
      <div>
        {items.map((item) => (
          <div key={item.field}>{getDescription(item)}</div>
        ))}
      </div>
    );
  };

  function getListItem(changeRecord: ChangeRecord) {
    const changeDate = new Date(changeRecord.date);
    const isNameChangeOnly =
      changeRecord.changes.length === 1 &&
      changeRecord.changes[0].field === "Renamed Type Well";
    const title = (
      <TitleContainer>
        {changeRecord.changedBy +
          " " +
          changeDate.toLocaleDateString() +
          " " +
          changeDate.toLocaleTimeString() +
          " "}
        <RestoreForecastButton
          disabled={isNameChangeOnly}
          forecastId={forecastId}
          restoreDate={changeRecord.date}
        />
      </TitleContainer>
    );
    if (changeRecord.comment) {
      return (
        <List.Item key={changeRecord.changeId}>
          <List.Item.Meta
            title={
              changeRecord.changedBy +
              " " +
              changeDate.toLocaleDateString() +
              " " +
              changeDate.toLocaleTimeString() +
              " "
            }
            description={
              <CommentContainer>
                <CommentIcon path={mdiMessageOutline} size={1.5} />
                <>{changeRecord.comment}</>
              </CommentContainer>
            }
          />
        </List.Item>
      );
    }
    return (
      <List.Item key={changeRecord.changeId}>
        <List.Item.Meta
          title={title}
          description={<ChangeItems items={changeRecord.changes} />}
        />
      </List.Item>
    );
  }

  return (
    <>
      {loading ?? <BusyIndicator />}
      {!loading && (
        <ListWrapper>
          <List
            dataSource={data?.changeRecords ?? []}
            renderItem={(item) => getListItem(item)}
          />
        </ListWrapper>
      )}
    </>
  );
};

const ListWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const CommentIcon = styled(Icon)`
  margin: 5px;
`;

const CommentContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
