import { useCallback, useEffect } from "react";

import { getOptionsHighlighted } from "..";
import { getOptionsWithTooltips, getSeriesNameStrippingForecast } from "../../util";

/**
 * Handle on Hover event to highlight series.
 * Handle on Hover event to apply unique tooltips based on the highlighted series
 */
const useHoverOnSeries = (
  echarts,
  options,
  currentlyHighlightedTrace,
  setOptionsToChart
) => {
  const onHover = useCallback(
    (e) => {
      const hasSeriesData: boolean =
        e.seriesIndex >= 0 && e.seriesIndex < options.series?.length;

      if (!hasSeriesData) return;

      const series = options.series[e.seriesIndex];
      const traceName = getSeriesNameStrippingForecast(series?.name);

      if (traceName !== currentlyHighlightedTrace.current) {
        currentlyHighlightedTrace.current = traceName;

        const highlightedOptions = getOptionsHighlighted(options, traceName);
        const optionsWithTooltips = getOptionsWithTooltips(highlightedOptions, traceName);

        setOptionsToChart(optionsWithTooltips);
      }
    },
    [currentlyHighlightedTrace, options, setOptionsToChart]
  );

  useEffect(() => {
    if (!echarts) return;
    echarts.on("hover", onHover);

    return () => {
      echarts.off("hover", onHover);
    };
  }, [echarts, onHover]);
};

export default useHoverOnSeries;
