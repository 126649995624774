import { useMutation } from "react-query";

import { addNewChartPreset, getPresets } from "api/multiProduct";

import { initialPresetState } from "../context";
import { IChartPreset } from "../models/shared.models";

const useChartPresetAppender = (successHandler, errorHandler) => {
  const addQuery = async (preset: IChartPreset) => {
    const addResponse = await addNewChartPreset(preset);
    const presets = await getPresets();

    // Return the updated preset to be used in onSuccess, to avoid bugs of not having an ID
    return {
      ...addResponse,
      newPreset: presets[presets?.length - 1] ?? initialPresetState
    };
  };

  const addPresetMutation = useMutation(addQuery, {
    onSuccess: (response, oldPreset) => {
      successHandler(response, oldPreset);
    },
    onError: (error) => {
      errorHandler(error);
    }
  });

  const addPreset = async (preset) => {
    try {
      const result = await addPresetMutation.mutateAsync(preset);
      return result;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return {
    addPreset,
    isLoading: addPresetMutation.isLoading,
    error: addPresetMutation.error,
    data: addPresetMutation.data
  };
};

export default useChartPresetAppender;
