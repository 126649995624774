import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  COMPLETION_FRAC_LAYER,
  COMPLETION_PERF_LAYER,
  FACILITY_LABEL_LAYER,
  FACILITY_LAYER,
  NTS_BLOCK_LABEL_LAYER,
  POLYGON_BINS_LAYERS,
  POLYGON_BINS_MY_FIELDS_LAYERS,
  POLYGON_BINS_ORG_FIELDS_LAYERS,
  SELECTED_WELL_LAYER,
  SHAPEFILE_LABELS_LAYER,
  SHAPEFILE_NAME_LABELS_LAYER,
  TYPE_LOG_LAYER,
  WELL_LABEL_LAYER,
  WELL_LAYER,
  XDA_INTERCEPT_LAYER,
  XDA_LABEL,
  XDA_WELL_INTERCEPT_POINT
} from "constants/mapLayers.constants";

import { useColumns } from "hooks";

import { RasterAttributes } from "models/model";
import { IShapefile, IShapefileNode } from "models/projects";

import { groupFields } from "components/groupBy/helpers";
import { useProjectContext } from "components/project/projects/context";
import { useProjectShapefilesQuery } from "components/project/shapefiles/queries";

import useBetaFeatures from "../../../../../hooks/useBetaFeatures";
import { EntityKind } from "../../../../../models/entityKind";
import { RootState } from "../../../../../store/rootReducer";

type MapType = "map";
type ProjectShapefileType = "projectShapefile";
type OrgShapefileType = "orgShapefile";
type McdanShapefileType = "mcdanShapefile";
type PolygonBinType = "polygonBin";

type LayerType =
  | MapType
  | ProjectShapefileType
  | OrgShapefileType
  | McdanShapefileType
  | PolygonBinType;

export interface ProjectLayerTreeT {
  tree: ProjectTreeNodeT[];
  isLoading: boolean;
  error: string;
}

export interface ProjectTreeNodeT {
  type: "rootFolder" | "layer" | "folder";
  key: string;
  title: string;
  checked: boolean;
  folder?: ProjectShapefileFolderT;
  layer?: ProjectLayerT;
  children?: ProjectTreeNodeT[];
  source?: string;
  className?: string;
  shapefileId?: string;
  shapefileNodeId?: string;
  projectShapefileId?: string;
  projectId?: string;
  name?: string;
  properties?: string[];
  fieldSource?: string;
  originalIndex?: number;
  fileType?: "GeoTiff" | "Shapefile" | "shapefile";
  rasterAttributes?: RasterAttributes;
}

export interface ProjectShapefileFolderT {
  source: "map" | "project" | "org" | "mcdan";
}

export interface ProjectLayerT {
  name: string;
  type: LayerType;
  selected: boolean;
  style?: ProjectLayerStyleT;
  zoom: [number, number];
  shapefileId?: string;
}

export interface ProjectLayerStyleT {
  color: string;
  strokeColor: string;
  thickness: string | number;
  opacity: number;
}

export const projectTreeRootMap = {
  "Well Layers": [
    WELL_LABEL_LAYER,
    WELL_LAYER,
    SELECTED_WELL_LAYER,
    TYPE_LOG_LAYER,
    // HIGHLIGHTED_WELL_LAYER,
    XDA_INTERCEPT_LAYER,
    XDA_LABEL,
    XDA_WELL_INTERCEPT_POINT
  ],
  "Facility Layers": [FACILITY_LAYER, FACILITY_LABEL_LAYER],
  "Completion Layers": [COMPLETION_FRAC_LAYER, COMPLETION_PERF_LAYER],
  "Grid Layers": [
    "Section",
    "Township",
    "Section Label",
    NTS_BLOCK_LABEL_LAYER
    // "Township Label"
  ],
  "Base Layers": ["Base Well"],
  [POLYGON_BINS_LAYERS]: [POLYGON_BINS_MY_FIELDS_LAYERS, POLYGON_BINS_ORG_FIELDS_LAYERS],
  ["Shapefile Label Layers"]: [SHAPEFILE_LABELS_LAYER, SHAPEFILE_NAME_LABELS_LAYER],
  "Project Shapefiles": [],
  "Organization Shapefiles": [],
  "McDaniel Shapefiles": []
};

export function useProjectLayerTree(): ProjectLayerTreeT {
  // store values
  const activeEntityKinds = useSelector(
    (state: RootState) => state.app.activeEntityKinds
  );

  // context
  const { selectedProjectId } = useProjectContext();

  // hooks
  const projectShapefiles = useProjectShapefilesQuery({
    projectId: selectedProjectId
  });

  const { hasFeature } = useBetaFeatures();

  const columns = useColumns();
  const groupedColumns = groupFields(columns);

  const groupedBins = useMemo(() => {
    if (Object.keys(groupedColumns).length === 0) {
      return [];
    }
    return Object.keys(groupedColumns).reduce((acc, key) => {
      const list = groupedColumns[key];
      const geomFields = list
        .filter((l) => l.fieldType === "geom")
        .sort((a, b) => a.withinSubgroupOrder - b.withinSubgroupOrder)
        .map((field) => ({
          ...field,
          source: field.property.startsWith("My_Focus_Fields") ? "user" : "org"
        }));
      if (geomFields.length > 0) {
        acc.push({ key, geomFields });
      }
      return acc;
    }, []);
  }, [groupedColumns]);

  // state
  const [tree, setTree] = useState<ProjectTreeNodeT[]>([]);

  const parseShapefileChildren = (
    children: IShapefileNode[],
    source: "project" | "org" | "mcdan"
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (children) {
      children.forEach((node: IShapefileNode | IShapefile, i) => {
        const type = node.type === "node" ? "folder" : "layer";
        const treeNode: ProjectTreeNodeT = {
          type: type,
          originalIndex: i,
          key:
            node.type === "node"
              ? node.shapefileNodeId
              : node.projectShapefileId
              ? node.projectShapefileId
              : node.shapefileId,
          title: node.title,
          checked: false,
          children: [],
          source: source,
          fileType: "shapefile",
          className: node.children ? "shapefile-folder" : "shapefile-leaf"
        };

        if (treeNode.type === "folder") {
          if (source !== "org" && node.shapefileNodeId === "orgLevelShapefiles") return;

          treeNode.folder = {
            source: source
          };
          treeNode.shapefileNodeId = node.shapefileNodeId;
        } else if (treeNode.type === "layer") {
          const shapefile = node as IShapefile;
          const type =
            source === "org"
              ? "orgShapefile"
              : source === "mcdan"
              ? "mcdanShapefile"
              : "projectShapefile";
          if (shapefile) {
            const sfnode = node as IShapefile;
            treeNode.layer = {
              name: node.title,
              type: type,
              selected: true,
              zoom: [0, 22],
              style: {
                color: shapefile.color,
                strokeColor: shapefile.strokeColor,
                thickness: shapefile.thickness,
                opacity: shapefile.opacity
              }
            };
            treeNode.projectId = node.projectId;
            treeNode.shapefileId = node.shapefileId;
            treeNode.projectShapefileId = node.projectShapefileId;
            treeNode.shapefileNodeId = node.shapefileNodeId;
            treeNode.checked = treeNode.layer.selected;
            treeNode.name = node.name;
            treeNode.properties = sfnode.properties;
            treeNode.fileType = sfnode.fileType;
            treeNode.rasterAttributes = sfnode.rasterAttributes;
          }
        }

        if (node.children) {
          treeNode.children = parseShapefileChildren(node.children, source);
        }
        subTree.push(treeNode);
      });
    }

    return subTree;
  };

  const parsePolygonBins = (
    source: string,
    geomFields = groupedBins
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (geomFields) {
      geomFields.forEach((geomField) => {
        const geomFieldNode: ProjectTreeNodeT = {
          type: "layer",
          key: geomField?.id,
          title: geomField?.title,
          checked: false,
          layer: {
            name: geomField?.id,
            type: "polygonBin",
            selected: false,
            zoom: [0, 22],
            style: {
              color: "#0000FF",
              strokeColor: "#transparent",
              thickness: 1,
              opacity: 1
            }
          }
        };
        subTree.push(geomFieldNode);
      });
      return subTree;
    }
  };

  const parsePolygonBinFolders = (
    source: string,
    bins = groupedBins
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (bins) {
      bins.forEach((bin) => {
        const binNode: ProjectTreeNodeT = {
          type: "folder",
          key: bin?.key ?? bin?.id,
          title: bin?.key ?? bin?.title,
          checked: false,
          layer: {
            name: bin?.key ?? bin?.id,
            type: "polygonBin",
            selected: false,
            zoom: [0, 22],
            style: {
              color: "#0000FF",
              strokeColor: "#transparent",
              thickness: 1,
              opacity: 1
            }
          }
        };

        if (bin?.geomFields) {
          binNode.children = parsePolygonBins(source, bin.geomFields);
        }
        if (bin?.geomFields.some((field) => field.source === source)) {
          subTree.push(binNode);
        }
      });
      return subTree;
    }
  };

  useEffect(() => {
    const tree: ProjectTreeNodeT[] = [];
    Object.keys(projectTreeRootMap).forEach((nodeKey) => {
      if (
        (!hasFeature("Facility") || !activeEntityKinds.includes(EntityKind.Facility)) &&
        nodeKey === "Facility Layers"
      )
        return;
      let source: "map" | "project" | "org" | "mcdan" = "map";
      switch (nodeKey) {
        case "Project Shapefiles":
          source = "project";
          break;
        case "Organization Shapefiles":
          source = "org";
          break;
        case "McDaniel Shapefiles":
          source = "mcdan";
          break;
      }

      const polygonBinsFolder = projectTreeRootMap[nodeKey].includes(
        POLYGON_BINS_MY_FIELDS_LAYERS,
        POLYGON_BINS_ORG_FIELDS_LAYERS
      );

      tree.push({
        type: "rootFolder",
        key: nodeKey,
        title: `${nodeKey}`,
        checked: false,
        source: source,
        children: projectTreeRootMap[nodeKey].map((mapKey): ProjectTreeNodeT => {
          return {
            type: polygonBinsFolder ? "folder" : "layer",
            key: mapKey,
            title: mapKey,
            checked: polygonBinsFolder ? false : true,
            layer: {
              name: mapKey,
              type: "map",
              selected: true,
              zoom: [0, 22]
            }
          };
        })
      });
    });

    if (projectShapefiles.data?.Project) {
      tree.filter((x) => x.key === "Project Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.Project.children, "project");

      tree.filter((x) => x.key === "Organization Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.Organization.children, "org");

      tree.filter((x) => x.key === "McDaniel Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.McDaniel.children, "mcdan");
    }

    if (groupedColumns) {
      tree
        .filter((x) => x.key === "Polygon Bins")[0]
        .children.filter((y) => y.key === "My Fields")[0].children =
        parsePolygonBinFolders("user");

      tree
        .filter((x) => x.key === "Polygon Bins")[0]
        .children.filter((y) => y.key === "Organization Fields")[0].children =
        parsePolygonBinFolders("org");
    }

    setTree(tree);
  }, [projectShapefiles.data, activeEntityKinds, columns]);

  return {
    tree,
    isLoading: projectShapefiles.isLoading,
    error: null
  };
}
