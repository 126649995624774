import { useEffect, useState } from "react";

import { useOrganizationShapefilesQuery } from "components/project/shapefiles/queries";

export interface OrgTreeT {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tree: any;
  isLoading: boolean;
  error: string;
}

export function useOrgShapefiles(): OrgTreeT {
  const projectShapefiles = useOrganizationShapefilesQuery({});

  // state
  const [tree, setTree] = useState([]);

  const parseShapefileChildren = (node): [] => {
    return {
      ...node,
      key:
        node.type === "root"
          ? "orgLevelShapefiles"
          : node.shapefileId
          ? node.shapefileId
          : node.shapefileNodeId,
      className: node.children ? "shapefile-folder" : "shapefile-leaf",
      ...(node.children && {
        children: node.children.map((ch) => parseShapefileChildren(ch))
      }),
      source: "org",
      ...(node.type === "shapefile" && {
        layer: {
          style: {
            color: node.color,
            strokeColor: node.strokeColor,
            thickness: node.thickness,
            opacity: node.opacity
          }
        }
      })
    };
  };

  useEffect(() => {
    if (projectShapefiles.data) {
      const treeData = [];
      const shapefile = projectShapefiles.data;
      treeData.push(parseShapefileChildren(shapefile));
      setTree(treeData);
    }
  }, [projectShapefiles.data]);

  return {
    tree,
    isLoading: projectShapefiles.isLoading,
    error: null
  };
}
