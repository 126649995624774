// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";
import { toast } from "react-toastify";

import { mdiDelete, mdiKey } from "@mdi/js";
import { Button, List, Modal, Table } from "antd";
import styled from "styled-components";

import { Heading } from "../base";
import { useDeleteToken } from "./hooks/useDeleteToken";
import { useGetApiTokens, useTokenUsageHistory } from "./hooks/useGetApiTokens";
import { CreateAccessTokenModal } from "./modals/NewTokenModal";

export function DeveloperApiTokenManagement() {
  const { data: accessTokens, isError } = useGetApiTokens();
  const { mutateAsync } = useDeleteToken();
  const [showModal, setShowModal] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null); // Track the selected row key

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Expires",
      dataIndex: "expiresAt",
      key: "expiresAt"
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt"
    },
    {
      title: "Last used at",
      dataIndex: "lastUsedAt",
      key: "lastUsedAt"
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" danger onClick={() => handleDelete(record.id)}>
          <Icon path={mdiDelete} size={1.4} />
        </Button>
      )
    }
  ];
  const rowSelection = {
    selectedRowKeys: [selectedRowKey],
    onChange: (selectedKeys) => {
      setSelectedRowKey(selectedKeys[0]); // Only allow one selection
    },
    hideDefaultSelections: true // Hide checkboxes
  };

  async function handleDelete(key) {
    Modal.confirm({
      title: "Confirm Delete",
      content: (
        <>
          Be aware that all{" "}
          <strong>
            applications currently using this token will no longer be able to function
            properly
          </strong>
          . This can disrupt services and operations and you will not be able to undo this
          operation.
        </>
      ),

      onOk: async () =>
        await mutateAsync(key).catch(() => {
          toast.error("An error occurred while deleting the token.");
        })
    });
  }

  if (isError) {
    return <ErrorMessage>Error occurred while fetching tokens</ErrorMessage>;
  }

  return (
    <>
      <div />
      <RootContainer>
        <Container>
          <Heading element="h4">Developer API Token Management </Heading>
          <ButtonWrapper>
            <Button
              icon={<Icon path={mdiKey} size={1} />}
              type="primary"
              onClick={() => {
                setShowModal(true);
              }}>
              Create New Token
            </Button>
          </ButtonWrapper>
          <Table
            dataSource={accessTokens}
            columns={columns}
            rowKey="id"
            rowSelection={rowSelection}
            onRow={(record) => ({
              onClick: () => setSelectedRowKey(record.id)
            })}
          />
        </Container>
        <Container>
          <TokenUsageHistory tokenId={selectedRowKey} />
        </Container>
        <CreateAccessTokenModal
          requestShowModal={showModal}
          onClose={() => setShowModal(false)}
        />
      </RootContainer>
    </>
  );
}

interface TokenUsageHistoryProps {
  tokenId: string;
}

function TokenUsageHistory(props: TokenUsageHistoryProps) {
  const { data } = useTokenUsageHistory(props.tokenId);

  return (
    <ListContainer>
      <Heading element="h4">Token Usage History</Heading>
      <ListWrapper>
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={item.action}
                description={`${item.location} - ${item.createdAt}`}></List.Item.Meta>
            </List.Item>
          )}
        />
      </ListWrapper>
    </ListContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListContainer = styled.div`
  height: 100%;
`;

const ListWrapper = styled.div`
  height: calc(100% - 20px);
  overflow-y: auto;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 20px;
  margin: 20px;
  border: 1px solid red;
  padding: 10px;
  background-color: #ffe6e6;
  border-radius: 5px;
`;

const RootContainer = styled.div`
  max-width: 800px;
  display: grid;
  height: 100%;
  max-height: 100%;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-row-selected {
    .ant-table-cell {
      background-color: var(--color-accent);
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
