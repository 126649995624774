import {
  ALL_PRODUCT_TYPES,
  ChartProduct,
  SalesProductList,
  SummaryProduct
} from "constants/chart.constants";
import { getProductType } from "utils";

import { IProductType } from "models/chart";

import { isLabelInCategory } from "../../../utils/chart/isLabelInCategory";

const skipUnitList = ["(bbl", "(mcf", "(bbl/mmcf", "(scf/bbl"];

/**
 * Conditionally converts the value to scientific E notation.
 *
 * @param {string} axisTitle - chart axis title.
 * @param {boolean} hasLockUnits Has lock units.
 * @param {string} product Chart product. Example: "IWB", "CWB".
 * @param {number} value Y axis value.
 * @param {number} yMax Y axis maximum.
 * @returns {string} Transformed y axis value.
 */
export const getLabelWithScientificENotation = (
  axisTitle: string,
  hasLockUnits: boolean,
  product: string,
  value: number,
  yMax: number
): string => {
  if (!value) return "0";
  if (skipUnitList.some((s) => (axisTitle ?? "").toLowerCase().includes(s))) {
    return formatNumberWithCommas(value);
  }

  const productType: IProductType = getProductType(product);

  const isRatioProduct: boolean =
    (isLabelInCategory(ChartProduct, "Ratio", product) ||
      isLabelInCategory(SummaryProduct, "Ratio", product)) &&
    productType !== ALL_PRODUCT_TYPES.IWB &&
    productType !== ALL_PRODUCT_TYPES.CWB;

  const isNotatable: boolean =
    (isRatioProduct || hasLockUnits) &&
    yMax >= 1000 &&
    !SalesProductList.includes(product);

  if (isNotatable) {
    const divisor: number = yMax >= 1000000 ? 1000000 : 1000;
    const decimalPlace: number = value % divisor ? 1 : 0;
    const exponent: string = yMax >= 1000000 ? "e6" : "e3";

    return `${(value / divisor).toFixed(decimalPlace)}${exponent}`;
  }

  return formatNumberWithCommas(value);
};

const formatNumberWithCommas = (value: number): string => {
  return value.toLocaleString("en-US");
};
