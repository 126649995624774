import { useEffect } from "react";
import { useQueryClient } from "react-query";

import { useEditableProjectLayerList } from "components/project/layers/hooks";
import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import {
  useProjectContext,
  useProjectDispatch
} from "components/project/projects/context";

import { useUpdateProjectShapefileMutation } from "../mutations";
import { PROJECT_SHAPEFILES_QUERY_KEYS } from "../queries";
import { SHAPEFILE_LIST_QUERY_KEYS } from "../queries/useShapefileListQuery";

export function useUpdateProjectShapefile() {
  const queryClient = useQueryClient();

  const projectDispatch = useProjectDispatch();
  const { selectedProjectId } = useProjectContext();

  const layerList = useEditableProjectLayerList({
    projectId: selectedProjectId,
    isAutoFetching: false
  });

  const updateProjectShapefile = useUpdateProjectShapefileMutation({
    projectId: selectedProjectId,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList,
          selectedProjectId
        ]
      });

      queryClient.invalidateQueries({
        queryKey: [
          PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles,
          selectedProjectId
        ]
      });

      queryClient.invalidateQueries({
        queryKey: [
          SHAPEFILE_LIST_QUERY_KEYS.currentProjectShapefiles,
          selectedProjectId,
          layerList.checkedProjectShapefileIds,
          layerList.checkedProjectLinkedShapefileIds
        ]
      });
    }
  });

  useEffect(() => {
    projectDispatch({
      payload: {
        isUpdatingShapefile: updateProjectShapefile.isLoading
      }
    });
  }, [updateProjectShapefile.isLoading]);

  return { updateProjectShapefile };
}
