import { memo, useState } from "react";

import { AddCircle, Delete } from "@material-ui/icons";
import { Checkbox, Space, Tooltip } from "antd";
import styled from "styled-components/macro";

import ColorEditor from "components/shared/ColorEditor";

import { useChartDispatch, useChartState } from "../context";

const AddReferenceLineMenu = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const [selected, setSelected] = useState([]);

  const onAddLine = () => {
    const next = { ...settings, addReferenceLine: true };
    dispatch({ type: "settings", payload: next });
  };

  const onDeleteLines = () => {
    const newLines = settings.referenceLine.lines.filter(
      (line) => !selected.includes(line.id)
    );
    const next = {
      ...settings,
      referenceLine: { lines: newLines, points: [], useChartValues: false }
    };
    dispatch({ type: "settings", payload: next });
    setSelected([]);
  };

  const renderLineNode = (node) => {
    const onColorChange = (color, thickness) => {
      const newLines = [];
      settings.referenceLine.lines.map((line) => {
        if (line.id === node.id) {
          line.color = color;
          line.thickness = thickness;
        }
        newLines.push(line);
      });
      const next = {
        ...settings,
        referenceLine: { lines: newLines, points: [], useChartValues: false }
      };
      dispatch({ type: "settings", payload: next });
    };

    const handleCheckboxChange = () => {
      if (selected.includes(node.id)) {
        setSelected(selected.filter((selectedNode) => selectedNode !== node.id));
      } else {
        setSelected([...selected, node.id]);
      }
    };

    return (
      <StyledDiv>
        <StyledCheckbox
          checked={selected.includes(node.id)}
          onClick={(evt) => evt.stopPropagation()}
          onChange={handleCheckboxChange}></StyledCheckbox>
        Line {`${node.id}`}
        <StyledColorEditor
          onChange={onColorChange}
          color={node?.color ?? "#a2aaad"}
          thickness={node?.thickness ?? 2}
        />
      </StyledDiv>
    );
  };

  return (
    <StyledSpace>
      <Header>
        <Tooltip title="Add New Line">
          <ActionButton onClick={onAddLine}>
            <AddCircle fontSize="large"></AddCircle>
          </ActionButton>
        </Tooltip>
        <Tooltip title="Delete Checked Items">
          <ActionButton
            danger
            onClick={onDeleteLines}
            disabled={
              !settings.referenceLine?.lines ||
              settings.referenceLine.lines.length === 0 ||
              selected.length === 0
            }>
            <Delete fontSize="large"></Delete>
          </ActionButton>
        </Tooltip>
      </Header>

      <GridContainer>
        {settings.referenceLine?.lines && settings.referenceLine.lines.length !== 0 ? (
          settings.referenceLine.lines.map((e) => renderLineNode(e))
        ) : (
          <StyledDiv>No Lines Drawn</StyledDiv>
        )}
      </GridContainer>
    </StyledSpace>
  );
};

export default memo(AddReferenceLineMenu);

const ActionButton = styled.button`
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: 6px;
  height: 6px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled] > svg {
    cursor: default;
    color: lightgray;
  }
`;

const StyledColorEditor = styled(ColorEditor)`
  float: right;
`;

const StyledCheckbox = styled(Checkbox)`
  float: left;
`;

const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

const StyledDiv = styled.div`
  width: 150px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
`;

const Header = styled.div`
  width: 150px;
  align-items: right;
  justify-content: right;
  text-align: right;
`;

const StyledSpace = styled(Space)`
  width: 150px;
  display: flex;
  flex-flow: row wrap;
`;
