import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useEffect, useState } from "react";

import { changeChartOverflowIndex } from "components/multiphase-chart/context/reducer";

import { useChartSettings } from "../context";

const toggleIconSize = 36;
const leftOffset = 300;

/**
 * Calculates the overflow index for toolbar icons,
 * based on available space and saves it to context.
 */
const useOverflowIndex = (ref: MutableRefObject<HTMLDivElement>) => {
  const [, chartSettingsDispatch] = useChartSettings();
  const [overflowIndex, setOverflowIndex] = useState<number>(0);

  useEffect(() => {
    changeChartOverflowIndex(chartSettingsDispatch, overflowIndex);
  }, [overflowIndex]);

  useResizeObserver(ref, (client) => {
    const { width } = client.contentRect;
    const availableWidth = width - leftOffset;
    const visibleCount = Math.floor(availableWidth / toggleIconSize);

    const index = Math.max(0, visibleCount);
    setOverflowIndex(index);
  });
};

export default useOverflowIndex;
