import { removeCount, removeForecast } from "utils";

import { IChartResult } from "models/model";

import { ChartDataTableColumn, ChartDataTableRow } from "../hooks/useDataTableData";
import { getPrecisionBasedOnUnit } from "../utils/getPrecisionBasedOnUnit";

// NOTE: Changing this logic should result in changes to the chart-svc backend export logic.
const declineDataTable = (
  responseData: IChartResult,
  xMinMax
): { columns: ChartDataTableColumn[]; rows: ChartDataTableRow[] } => {
  const columns = [
    { key: "X", name: responseData.layout.xAxis.title, resizable: false },
    { key: "Y", name: responseData.layout.yAxis.title, resizable: false },
    { key: "production", name: "Production Source", resizable: false },
    { key: "forecast", name: "Forecast Source", resizable: false }
  ];
  const xPrecision = getPrecisionBasedOnUnit(responseData?.layout?.xAxis?.title);
  const yPrecision = getPrecisionBasedOnUnit(responseData?.layout?.yAxis?.title);
  const min = xMinMax?.xMin ?? null;
  const max = xMinMax?.xMax ?? null;

  const rows = responseData.series?.reduce((list, series) => {
    for (let i = 0; i < series.x.length; i++) {
      const x = series.x[i];
      const y = series.y[i];
      if ((!min || x >= min) && (!max || x <= max)) {
        const row = {
          SeriesColor: series.style.hexColor,
          SeriesFull: series.label,
          Series: removeForecast(removeCount(series.label)),
          X: typeof x === "number" ? x.toFixed(xPrecision) : x,
          production: series.productionSource,
          forecast: series.forecastSource,
          Y: typeof y === "number" ? y.toFixed(yPrecision) : y
        };
        list.push(row);
      }
    }
    return list;
  }, []);

  return {
    columns,
    rows
  };
};

export default declineDataTable;
