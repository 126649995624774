// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";
import { useSelector } from "react-redux";

import { mdiCog } from "@mdi/js";
import { Button, Popover, Tooltip } from "antd";
import {
  FACILITY_LABEL_LAYER,
  SHAPEFILE_LABELS_LAYER,
  SHAPEFILE_NAME_LABELS_LAYER,
  WELL_LABEL_LAYER,
  WELL_LAYER
} from "constants/mapLayers.constants";
import { RootState } from "store/rootReducer";

import { useUpdateLabelTextSize } from "components/map/hooks/useUpdateLabelFontSize";
import {
  useUpdateLayerCircleRadius,
  useUpdateLayerLineThickness
} from "components/project/layers/hooks";

import {
  IZoomSliderProps,
  LayerSettingsRowItem,
  LayerSettingsWrapper,
  RightInputNumber,
  StyledDivider,
  ZoomSlider
} from "..";

interface MapLayerSettingsProps {
  dotThicknessSettings?: {
    min?: number;
    max?: number;
    value: number;
  };
  lineThicknessSettings?: {
    min?: number;
    max?: number;
    value: number;
  };
  layerName: string;
  zoomSliderProps: IZoomSliderProps;
}

export const MapLayerSettings = ({
  layerName = null,
  zoomSliderProps
}: MapLayerSettingsProps): JSX.Element => {
  const displaySettings = useSelector((state: RootState) => state.map.displaySettings);
  const { updateLayerCircleRadius } = useUpdateLayerCircleRadius();
  const { updateLayerLineThickness } = useUpdateLayerLineThickness();
  const {
    updateWellLabelTextSize,
    updateFacilityLabelTextSize,
    updateShapefileDetailLabelTextSize,
    updateShapefileNameLabelTextSize
  } = useUpdateLabelTextSize();

  const [lineThickness, setLineThickness] = useState(displaySettings.wellLineThickness);
  const [dotThickness, setDotThickness] = useState(displaySettings.wellDotThickness);

  let defaultLabelSize = 0;
  switch (layerName) {
    case FACILITY_LABEL_LAYER:
      defaultLabelSize = displaySettings.facilityLabelSize;
      break;
    case WELL_LABEL_LAYER:
      defaultLabelSize = displaySettings.wellLabelSize;
      break;
    case SHAPEFILE_LABELS_LAYER:
      defaultLabelSize = displaySettings.shapefileDetailsLabelSize;
      break;
    case SHAPEFILE_NAME_LABELS_LAYER:
      defaultLabelSize = displaySettings.shapefileNameLabelSize;
      break;
    default:
      break;
  }
  const [labelSize, setLabelSize] = useState(defaultLabelSize);

  const showLabelSizeSettings =
    layerName === WELL_LABEL_LAYER ||
    layerName === FACILITY_LABEL_LAYER ||
    layerName === SHAPEFILE_LABELS_LAYER ||
    layerName === SHAPEFILE_NAME_LABELS_LAYER;

  const layerSettings = (
    <LayerSettingsWrapper direction="vertical" split={<StyledDivider />}>
      <LayerSettingsRowItem isFirst isSlider>
        <ZoomSlider
          defaultMin={zoomSliderProps.defaultMin}
          defaultMax={zoomSliderProps.defaultMax}
          layerName={zoomSliderProps.layerName}
          isShapefileLayer={zoomSliderProps.isShapefileLayer}
        />
      </LayerSettingsRowItem>
      {layerName === WELL_LAYER && (
        <LayerSettingsRowItem>
          <label>Line Thickness</label>
          <RightInputNumber
            data-testid="layerThickness"
            type="number"
            min={1}
            max={10}
            value={lineThickness}
            onChange={(val) => {
              const nextThickness =
                parseFloat(val.target.value) > 10 ? 10 : parseFloat(val.target.value);

              setLineThickness(nextThickness);
              updateLayerLineThickness(nextThickness);
            }}
          />
        </LayerSettingsRowItem>
      )}

      {layerName === WELL_LAYER && (
        <LayerSettingsRowItem isLast>
          <label>Dot Thickness</label>
          <RightInputNumber
            data-testid="dotThickness"
            type="number"
            min={1}
            max={10}
            value={dotThickness}
            onChange={(val) => {
              const nextThickness =
                parseFloat(val.target.value) > 10 ? 10 : parseFloat(val.target.value);

              setDotThickness(nextThickness);
              updateLayerCircleRadius(nextThickness);
            }}
          />
        </LayerSettingsRowItem>
      )}

      {showLabelSizeSettings && (
        <LayerSettingsRowItem isLast>
          <label>Label Size</label>
          <RightInputNumber
            data-testid="labelSize"
            type="number"
            min={6}
            max={48}
            value={labelSize}
            onChange={(val) => {
              const nextSize =
                parseFloat(val.target.value) > 48 ? 48 : parseFloat(val.target.value);
              setLabelSize(nextSize);
              switch (layerName) {
                case FACILITY_LABEL_LAYER:
                  updateFacilityLabelTextSize(nextSize);
                  break;
                case WELL_LABEL_LAYER:
                  updateWellLabelTextSize(nextSize);
                  break;
                case SHAPEFILE_LABELS_LAYER:
                  updateShapefileDetailLabelTextSize(nextSize);
                  break;
                case SHAPEFILE_NAME_LABELS_LAYER:
                  updateShapefileNameLabelTextSize(nextSize);
                  break;
                default:
                  break;
              }
            }}
          />
        </LayerSettingsRowItem>
      )}
    </LayerSettingsWrapper>
  );

  return (
    <Tooltip title="Settings">
      <Popover
        content={layerSettings}
        placement="bottomLeft"
        trigger="click"
        arrowPointAtCenter={true}>
        <Button type="text" aria-label="Edit">
          <Icon path={mdiCog} size={1} />
        </Button>
      </Popover>
    </Tooltip>
  );
};
