import { memo } from "react";

function PolymerInjection() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 30 30">
      <g id="IconsChart---PRPPolymerInj" transform="translate(5, 0.7)">
        <path
          d="M0 0L30 0L30 30L0 30L0 0Z"
          id="Rectangle-Copy-22"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Polymer-Inj">
          <path
            d="M7.27,10L9,7H14.42L15.58,5L15.5,4.5A1.5,1.5 0 0,1 17,3A1.5,1.5 0 0,1 18.5,4.5C18.5,5.21 18,5.81 17.33,5.96L16.37,7.63L17.73,10L18.59,8.5L18.5,8A1.5,1.5 0 0,1 20,6.5A1.5,1.5 0 0,1 21.5,8C21.5,8.71 21,9.3 20.35,9.46L18.89,12L20.62,15C21.39,15.07 22,15.71 22,16.5A1.5,1.5 0 0,1 20.5,18A1.5,1.5 0 0,1 19,16.5V16.24L17.73,14L16.37,16.37L17.33,18.04C18,18.19 18.5,18.79 18.5,19.5A1.5,1.5 0 0,1 17,21A1.5,1.5 0 0,1 15.5,19.5L15.58,19L14.42,17H10.58L9.42,19L9.5,19.5A1.5,1.5 0 0,1 8,21A1.5,1.5 0 0,1 6.5,19.5C6.5,18.79 7,18.19 7.67,18.04L8.63,16.37L4.38,9C3.61,8.93 3,8.29 3,7.5A1.5,1.5 0 0,1 4.5,6A1.5,1.5 0 0,1 6,7.5C6,7.59 6,7.68 6,7.76L7.27,10M10.15,9L8.42,12L10.15,15H14.85L16.58,12L14.85,9H10.15Z"
            id="PolymerInj"
            transform="translate(-7.5, 0) scale(1.25, 1)"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M5.61892 0L5.61892 5.61892L0 5.61892"
            transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 8.008963 19.749456)"
            id="Rectangle-Copy"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(PolymerInjection);
