// eslint-disable-next-line import/no-named-as-default
import { useProjectContext } from "components/project/projects/context";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { useDeleteProjectLinkedShapefileMutation } from "components/project/shapefiles/mutations";

import { ShapefileActions } from "./ShapefileActions";

interface IProjectLinkedShapefileActionsProps {
  setIsRenaming?: () => void;
  projectShapefileId: string;
  viewShapefileDetails?: () => void;
  canExportShapefile: boolean;
}

/**
 * Some actions and properties have been left for dev to implement based on use case, since the properties are different between
 * the layer tree layers, and layer list layers.
 * @param params
 * @returns
 */
export function ProjectLinkedShapefileActions(
  params: IProjectLinkedShapefileActionsProps
): JSX.Element {
  const { selectedProjectId } = useProjectContext();

  const projectPermissions = useSelectedProjectPermissions();

  const deleteProjectLinkedShapefileMutation = useDeleteProjectLinkedShapefileMutation({
    projectId: selectedProjectId
  });

  function removeShapefile() {
    deleteProjectLinkedShapefileMutation.mutate(params.projectShapefileId);
  }

  return (
    <ShapefileActions
      isDisabled={!projectPermissions.canEditShapefiles}
      exportShapefile={
        !params.canExportShapefile ? null : () => params.projectShapefileId
      }
      removeShapefile={removeShapefile}
      {...params}
    />
  );
}
