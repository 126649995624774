import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";

export type UploadRasterMapDataT = {
  id: number;
  file: File;
  name: string;
  expression: string;
  unit: string;
  min?: number;
  max?: number;
  projFile?: File;
  overrideFileName?: string;
};
const uploadRasterMap = async (data: UploadRasterMapDataT) => {
  const form = new FormData();
  form.append("payload", data.file);
  if (data.projFile) {
    form.append("projFile", data.projFile);
  }
  form.append("name", data.name);
  form.append("expression", data.expression);
  form.append("unit", data.unit);
  if (data.min !== undefined && data.min !== null) {
    form.append("min", data.min.toString());
  }
  if (data.max !== undefined && data.max !== null) {
    form.append("max", data.max.toString());
  }
  form.append("overrideFileName", data.overrideFileName);
  const response = await axios.post<string>(
    `${GEO_MAP_SERVICE_ADDRESS}/map-layer/${data.id}/map`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return response.data;
};
const useMutateGeoMapLayers = () => {
  return useMutation<string, AxiosError, UploadRasterMapDataT, unknown>(uploadRasterMap);
};

export default useMutateGeoMapLayers;
