import { toast } from "react-toastify";

import {
  useProjectContext,
  useProjectDispatch
} from "components/project/projects/context";

import {
  useUploadProjectGeoTiffMutation,
  useUploadProjectShapefileListMutation
} from "../mutations";

export function useUploadProjectShapefileList() {
  const projectDispatch = useProjectDispatch();
  const { selectedProjectId, isUploadingShapefileList } = useProjectContext();

  const uploadProjectShapefileListMutation = useUploadProjectShapefileListMutation({
    onSuccess: () => {
      toast.success(`Uploaded successfully.`);
    },
    onError: () => {
      toast.error(`Upload failed.`);
    },
    onSettled: () => {
      projectDispatch({
        payload: {
          isUploadingShapefileList: false
        }
      });
    },
    projectId: selectedProjectId
  });

  const uploadProjectGeoTiffMutation = useUploadProjectGeoTiffMutation({
    onSuccess: () => {
      toast.success(`Uploaded successfully.`);
    },
    onError: () => {
      toast.error(`Upload failed.`);
    },
    onSettled: () => {
      projectDispatch({
        payload: {
          isUploadingShapefileList: false
        }
      });
    },
    projectId: selectedProjectId
  });

  function uploadShapefileList({
    files,
    shapefileNodeId
  }: {
    files: File[];
    shapefileNodeId: string;
  }) {
    const onlyShapefiles: File[] = [];

    projectDispatch({
      payload: {
        isUploadingShapefileList: true
      }
    });

    const geoTiffFiles = [];

    for (const file of files) {
      if (file.type === "image/tiff") {
        geoTiffFiles.push(file);
      } else {
        onlyShapefiles.push(file);
      }
    }

    if (onlyShapefiles.length > 0) {
      uploadProjectShapefileListMutation.mutate({
        files: onlyShapefiles,
        visibility: "user",
        projectId: selectedProjectId,
        shapefileNodeId,
        thickness: "2.0"
      });
    }

    if (geoTiffFiles.length > 0) {
      uploadProjectGeoTiffMutation.mutate({
        files: geoTiffFiles,
        visibility: "user",
        projectId: selectedProjectId,
        shapefileNodeId,
        thickness: "2.0"
      });
    }
  }

  return {
    ...uploadProjectShapefileListMutation,
    mutate: uploadShapefileList,
    isLoading: isUploadingShapefileList
  };
}
