import { useContext } from "react";

import { ProjectContext } from "./ProjectContext";

export function useProjectContext() {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProjectContext must be within a Project provider");
  }

  return context;
}
