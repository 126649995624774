import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { IS_DEV_ENV } from "constants/app.constants";
import styled from "styled-components/macro";

import { useUser } from "hooks";

export const SessionInfo = () => {
  const [showSession, setShowSession] = useState(IS_DEV_ENV);
  const { sessionId } = useUser();
  useHotkeys("ctrl+shift+I", () => setShowSession(!showSession));

  if (!showSession) {
    return null;
  }

  return <SessionInfoWrapper>{sessionId || "No session ID"}</SessionInfoWrapper>;
};

const SessionInfoWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 45px;
  background-color: #f2f2f2;
  border: 1px solid #e0e0e0;
  padding: 2px 10px;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: bottom left;
`;
