/**
 * Adds "Forecast" to the given title.
 *
 * @param {string} title title
 * @returns {string} title with "Forecast" added
 */
const getCumPlusForecastTitle = (title, source: string, subgroup?: string) => {
  if (!title) return "";

  const cumIndex = title.indexOf("Cum");

  if (cumIndex < 0 || subgroup === "Cumulative Ratios") return title;

  const cumPlusForecastTitle =
    title.substr(0, cumIndex + 3) +
    ` + Forecast${!source ? "" : " (" + source + ")"}` +
    title.substr(cumIndex + 3);
  return cumPlusForecastTitle;
};

export default getCumPlusForecastTitle;
