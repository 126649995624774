import { toast } from "react-toastify";

import { getProductLabel } from "constants/chart.constants";
import {
  FORECAST_KEY,
  MCDANIEL_SEARCH_FOLDER_NAME,
  SYNC_KEY
} from "constants/settings.constants";
import { getCumPlusForecastTitle } from "utils";

import { IGroupBy, IGroupByListColumn } from "models";
import { EntityKind } from "models/entityKind";

import { geoVolumeCategoryTitle } from "components/groupBy/hooks/useCategories";
import { DefaultBinSettings } from "components/user-settings/models";

const updateGroupBy = (
  key,
  columnsDictionary: Record<string, IGroupByListColumn>,
  updateField,
  overrideBin = null,
  selectedForecastFolderName = "",
  fullContextTitleWithNormalization = "",
  partialContextTitle = "",
  isForecastToggleOn = false,
  selectedPdensource: string = "Public",
  entityKind: EntityKind = EntityKind.Well,
  defaultBinSettings: DefaultBinSettings = null,
  dataType: string = ""
) => {
  let property = key;
  let isForecast = false;
  const fcstIndex = key.indexOf(FORECAST_KEY);
  if (fcstIndex > 0) {
    property = key.substr(0, fcstIndex - 1);
    isForecast = true;
  }
  const syncIndex = key.indexOf(SYNC_KEY);
  if (syncIndex > 0) {
    property = key.substr(0, syncIndex - 1);
    isForecast = false;
  }

  const groupby = columnsDictionary[property];

  if (!groupby) {
    toast.warn(`Unable to find field`);
    return;
  }

  const selected = groupby;
  let title = selected.title;
  if (isForecast) {
    if (selectedForecastFolderName === MCDANIEL_SEARCH_FOLDER_NAME) {
      title = getCumPlusForecastTitle(title, "", selected.subgroup);
    } else {
      title = getCumPlusForecastTitle(
        title,
        selectedForecastFolderName,
        selected.subgroup
      );
    }
  }
  if (selected?.product) {
    //add product for performance
    title += ` (${getProductLabel(selected.product, entityKind)})`;
  }

  if (
    selected?.group === geoVolumeCategoryTitle &&
    selected?.metadata?.type === "Summary"
  ) {
    if (!title.includes(selected.metadata.radius)) {
      title += ` (${selected.metadata.radius})`;
    }
  }

  let defaultBin = {
    BinSize: "",
    GreaterThan: "",
    LessThan: "",
    MinSize: "",
    BinType: "BinSize",
    Quantile: null,
    UseDynamicBins: false
  };

  if (defaultBinSettings && defaultBinSettings.useDynamicBins) {
    defaultBin = {
      ...defaultBin,
      UseDynamicBins: true
    };
  }

  if (
    defaultBinSettings &&
    defaultBinSettings?.type === "Quantile" &&
    dataType.toLowerCase() !== "text"
  ) {
    defaultBin = {
      ...defaultBin,
      BinType: "Quantile",
      Quantile: {
        quantileType: defaultBinSettings.quantile.quantileType,
        numQuantiles: defaultBinSettings.quantile.numQuantiles
      }
    };
  }

  if (
    overrideBin &&
    overrideBin.BinType === "Quantile" &&
    dataType.toLowerCase() === "text"
  ) {
    overrideBin = {
      ...overrideBin,
      BinType: "BinSize"
    };
  }

  const gb: IGroupBy = {
    property: key,
    groupByField: key,
    title: title,
    entityKind,
    canBin: selected.canBin,
    dataType: selected.dataType,
    categoryId: selected.categoryId,
    product: selected.product,
    subgroup: selected.subgroup,
    tooltip: "",
    display: "",
    pdenSource: selectedPdensource,
    bin: overrideBin ? overrideBin : defaultBin,
    forecastFolder: selectedForecastFolderName,
    partialContextTitle: partialContextTitle,
    isForecastToggleOn: isForecastToggleOn,
    defaultUnit: selected.defaultUnit,
    fullContextTitleWithNormalization: fullContextTitleWithNormalization
  };

  updateField && updateField(gb);
};

export default updateGroupBy;
