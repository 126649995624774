import { memo } from "react";

function DataTableView({ size = 20, ...props }) {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 25 25" fill="currentColor">
      <rect width={size} height={size} rx={size / 8} ry={size / 8} />
    </svg>
  );
}

export default memo(DataTableView);
