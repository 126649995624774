import axios from "axios";

const openTag = (link) =>
  '<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.2493%;"><iframe src=' +
  link +
  '?rel=0&showinfo=0" style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no">';
const closeTag = () => "</iframe></div>";

function isVideo(URL) {
  if (URL.includes("eva/userdoc/download/")) {
    return new Promise(function (resolve, reject) {
      axios.get(URL, {}).then(
        (response) => {
          if (response.headers["content-type"].includes("video")) {
            resolve(response);
            return true;
          } else {
            return false;
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  } else {
    return false;
  }
}

export default function VideoPlayer(md) {
  const originalLinkOpenRenderer = md.renderer.rules.link_open;
  const originalLinkCloseRenderer = md.renderer.rules.link_close;

  md.renderer.rules.link_open = (tokens, idx, options, env) => {
    const href = tokens[idx].href;
    if (isVideo(href)) {
      env.iframe = true;
      return openTag(href);
    }

    return originalLinkOpenRenderer(tokens, idx, options, env);
  };

  md.renderer.rules.link_close = (tokens, idx, options, env) => {
    if (env.iframe) {
      env.iframe = null;
      return closeTag();
    }

    return originalLinkCloseRenderer(tokens, idx, options, env);
  };
}
