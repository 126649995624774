import { useQuery } from "react-query";

import axios from "axios";

import { userServiceRoot } from "api/userSettings";

import { IUserSettingsDefaults } from "./userSettingsDefaultsModels";

export const useUserSettingsQueryKeys = {
  userSettings: "user-settings-defaults"
};

export type UseUserSettingsQueryKeysT = keyof typeof useUserSettingsQueryKeys;

export interface IUseUserSettingsDefaultsQueryParams {
  onSuccess?: (data) => void;
  onError?: (error) => void;
  select?;
  queryKey?: UseUserSettingsQueryKeysT;
  isAutoFetching?: boolean;
}

export interface IUseUserSettingsDefaultsQueryReturn {
  data: IUserSettingsDefaults;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export async function getUserSettingsDefaults() {
  return await axios.get(`${userServiceRoot}/user/settings`);
}

export function useUserSettingsDefaultsQuery(
  params: IUseUserSettingsDefaultsQueryParams = {}
): IUseUserSettingsDefaultsQueryReturn {
  const { onError, onSuccess } = params;

  const nextIsAutoFetching =
    params.isAutoFetching === undefined ? true : params.isAutoFetching;

  const queryInfo = useQuery({
    queryKey: useUserSettingsQueryKeys.userSettings,
    queryFn: getUserSettingsDefaults,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data?.data);
    },
    enabled: nextIsAutoFetching
  });

  return {
    data: queryInfo.data?.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
