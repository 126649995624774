import { useState } from "react";

import styled from "styled-components";
import { getFilesFromEvent } from "utils/upload/getFilesFromEvent";

import { IShapefileNode } from "models/projects";

import { ShapefileFolderActions } from "components/project/layers/components/layer-managers/shared/shapefile-folder-actions/ShapefileFolderActions";
import {
  useCreateProjectShapefileFolderMutation,
  useDeleteProjectShapefileFolderMutation,
  useUpdateProjectShapefileFolderMutation,
  useUploadProjectShapefileListMutation
} from "components/project/shapefiles/mutations";

import EditableLabel from "./EditableLabel";

export default function SystemFolderNode({ node }) {
  const [inEditMode, setInEditMode] = useState(false);
  const [acceptChanges, setAcceptChanges] = useState(false);
  const [canDrop, setCanDrop] = useState(false);

  const createProjectFolderMutation = useCreateProjectShapefileFolderMutation({
    projectId: "system"
  });

  const deleteFolder = useDeleteProjectShapefileFolderMutation({
    projectId: "system"
  });

  const uploadShapefileList = useUploadProjectShapefileListMutation({
    projectId: "system"
  });

  const updateFolderMutation = useUpdateProjectShapefileFolderMutation({
    projectId: "system"
  });

  const sf = node as IShapefileNode;
  if (inEditMode && true) {
    return (
      <LeafTitle>
        <FlexItem>
          <EditableLabel
            editKey={sf.shapefileId}
            value={sf.title}
            onChange={(newTitle) => {
              updateFolderMutation.mutate({
                body: {
                  key: "name",
                  value: newTitle
                },
                shapefileNodeId: sf.shapefileNodeId,
                projectId: "system"
              });
              setInEditMode(false);
              setAcceptChanges(false);
            }}
            inEditMode={inEditMode}
            setInEditMode={setInEditMode}
            acceptChanges={acceptChanges}
            setAcceptChanges={setAcceptChanges}></EditableLabel>
        </FlexItem>
      </LeafTitle>
    );
  }
  return (
    <LeafTitle
      canDrop={canDrop}
      onDragOver={(e) => {
        e.preventDefault();
        const files = getFilesFromEvent(e);
        setCanDrop(files.length > 0);
      }}
      onDrop={(ev) => {
        ev.preventDefault();
        const files = getFilesFromEvent(ev);
        if (files?.length > 0) {
          uploadShapefileList.mutate({
            files,
            visibility: "organization",
            projectId: "",
            shapefileNodeId: sf.shapefileNodeId
          });
        }
        setCanDrop(false);
      }}
      onDragEnter={(e) => {
        const files = getFilesFromEvent(e);
        setCanDrop(files.length > 0);
      }}
      onDragLeave={() => {
        setCanDrop(false);
      }}>
      <FlexItem>
        <label>{node.title}</label>
      </FlexItem>
      <FlexItem>
        <ShapefileFolderActions
          addFolder={() => {
            createProjectFolderMutation.mutate({
              parentId: sf.shapefileNodeId,
              order: node.children.length
            });
          }}
          deleteFolder={() => {
            deleteFolder.mutate({
              shapefileNodeId: sf.shapefileNodeId,
              projectId: "system"
            });
          }}
          setIsRenaming={() => {
            setInEditMode(true);
          }}
          shapefileNodeId={sf.shapefileNodeId}
        />
      </FlexItem>
    </LeafTitle>
  );
}

//Styled Components

const ActionButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled] > svg {
    color: lightgray;
  }
`;

const FlexItem = styled.div`
  display: inline-flex;
  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 24px;
  }
  .ant-btn:hover {
    color: var(--color-primary);
  }
  .ant-btn.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  .ant-btn.active:hover {
    background-color: #fff;
    color: var(--color-primary);
  }
`;

const LeafTitle = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  align-items: center;
  justify-content: space-between;

  .drag-container {
    position: absolute;
    opacity: 0.5;
    left: -20px;
    top: 4px;
    color: #a2aaad;
    cursor: move;
  }

  ${ActionButton} {
    svg {
      opacity: 0;
    }
  }

  &:hover,
  &.in-edit-mode {
    svg {
      opacity: 1;
    }
  }

  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  align-items: center;
  justify-content: space-between;

  border: ${(props) => (props.canDrop ? "1px dashed green" : "none")};

  input[type="text"] {
    height: 24px;
    outline: none;
  }

  input[type="text"]:focus {
    outline: none 0;
  }

  label {
    margin-left: 5px !important;
  }
`;
