import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ForecastActivityType,
  MCDANIEL_FOLDER_ID,
  TYPE_WELLS
} from "constants/settings.constants";

import { ForecastFolder, UserArpsItem } from "models/UserArpsModel";

import { ICheckedForecast } from "../app/appSlice";

export const ARPS_CHART_PRODUCT_KEYS = {
  "typecurve-chart-1": "Oil + Cond",
  "typecurve-chart-2": "Oil + Cond",
  "typecurve-chart-3": "Gas",
  "typecurve-chart-4": "Gas"
};

export interface IArpsSlice {
  checkedForecasts: ICheckedForecast[];
  checkedTypeWells: ICheckedForecast[];

  // "Global" referring to charts/other components that need to react upon changes to typewells
  checkedGlobalForecasts: ICheckedForecast[];
  checkedGlobalTypeWells: ICheckedForecast[];

  checkedForecastKeys: string[];
  checkedTypeWellKeys: string[];

  expandedForecastKeys: string[];
  expandedTypeWellKeys: string[];

  forecastFolders: ForecastFolder[];
  typeWellFolders: ForecastFolder[];

  filteredForecastFolders: ForecastFolder[];
  filteredTypeWellFolders: ForecastFolder[];

  hasTypeWellSaveSucceeded: boolean;
  isTypeWellSavable: boolean;
  selectedTypeWell: UserArpsItem;
  hasWellDataError: boolean;
  useMcDanielForecasts: boolean;
  useNoviForecasts: boolean;
}
export const initialArpsState: IArpsSlice = {
  checkedForecasts: [],
  checkedTypeWells: [],

  checkedGlobalForecasts: [],
  checkedGlobalTypeWells: [],

  checkedForecastKeys: [MCDANIEL_FOLDER_ID],
  checkedTypeWellKeys: [],

  expandedForecastKeys: [],
  expandedTypeWellKeys: [],

  forecastFolders: [],
  typeWellFolders: [],

  filteredForecastFolders: [],
  filteredTypeWellFolders: [],

  hasTypeWellSaveSucceeded: false,
  isTypeWellSavable: true,
  selectedTypeWell: undefined,
  hasWellDataError: false,
  useMcDanielForecasts: true,
  useNoviForecasts: false
};

const arpsSlice = createSlice({
  name: "arps",
  initialState: initialArpsState,
  reducers: {
    setCheckedForecasts(
      state,
      action: PayloadAction<{
        type: ForecastActivityType;
        checkedForecasts: ICheckedForecast[];
      }>
    ) {
      action.payload.type === TYPE_WELLS
        ? (state.checkedTypeWells = action.payload.checkedForecasts)
        : (state.checkedForecasts = action.payload.checkedForecasts);
    },
    setCheckedGlobalForecasts(state, action: PayloadAction<ICheckedForecast[]>) {
      state.checkedGlobalForecasts = action.payload;
    },
    setCheckedGlobalTypeWells(state, action: PayloadAction<ICheckedForecast[]>) {
      state.checkedGlobalTypeWells = action.payload;
    },
    setCheckedKeys(
      state,
      action: PayloadAction<{
        type: ForecastActivityType;
        checkedKeys: string[];
      }>
    ) {
      action.payload.type === TYPE_WELLS
        ? (state.checkedTypeWellKeys = action.payload.checkedKeys)
        : (state.checkedForecastKeys = action.payload.checkedKeys);
    },
    setExpandedKeys(
      state,
      action: PayloadAction<{
        type: ForecastActivityType;
        expandedKeys: string[];
      }>
    ) {
      action.payload.type === TYPE_WELLS
        ? (state.expandedTypeWellKeys = action.payload.expandedKeys)
        : (state.expandedForecastKeys = action.payload.expandedKeys);
    },
    setForecastFolders(
      state,
      action: PayloadAction<{
        type: ForecastActivityType;
        forecastFolders: ForecastFolder[];
      }>
    ) {
      action.payload.type === TYPE_WELLS
        ? (state.typeWellFolders = action.payload.forecastFolders)
        : (state.forecastFolders = action.payload.forecastFolders);
    },
    setFilteredForecastFolders(
      state,
      action: PayloadAction<{
        type: ForecastActivityType;
        filteredForecastFolders: ForecastFolder[];
      }>
    ) {
      action.payload.type === TYPE_WELLS
        ? (state.filteredTypeWellFolders = action.payload.filteredForecastFolders)
        : (state.filteredForecastFolders = action.payload.filteredForecastFolders);
    },
    setHasTypeWellSaveSucceeded(state, action: PayloadAction<boolean>) {
      state.hasTypeWellSaveSucceeded = action.payload;
    },
    setIsTypeWellSavable(state, action: PayloadAction<boolean>) {
      state.isTypeWellSavable = action.payload;
    },
    setSelectedTypeWell(state, action: PayloadAction<UserArpsItem>) {
      state.selectedTypeWell = action.payload;
    },
    setUseMcDanielForecasts(state, action: PayloadAction<boolean>) {
      state.useMcDanielForecasts = action.payload;
    },
    setHasWellDataError(state, action: PayloadAction<boolean>) {
      state.hasWellDataError = action.payload;
    },
    setUseNoviForecasts(state, action: PayloadAction<boolean>) {
      state.useNoviForecasts = action.payload;
    }
  }
});

export const {
  setCheckedForecasts,
  setCheckedGlobalForecasts,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setExpandedKeys,
  setFilteredForecastFolders,
  setForecastFolders,
  setHasTypeWellSaveSucceeded,
  setIsTypeWellSavable,
  setSelectedTypeWell,
  setUseMcDanielForecasts,
  setHasWellDataError,
  setUseNoviForecasts
} = arpsSlice.actions;
export default arpsSlice.reducer;
