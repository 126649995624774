import { memo } from "react";

function ProductGas() {
  return (
    <svg width="30px" height="27px" viewBox="0 0 30 27">
      <g id="IconsChart---PRPGas" transform="translate(5, 0)">
        <path
          d="M19.4786 16.6962C19.141 11.248 9.59585 0 9.59585 0C7.43684 9.69182 -0.00861992 10.7961 7.49155e-06 17.1761C0.00539963 21.4812 1.95735 23.981 4.39244 25.3085C3.62136 22.73 3.93411 19.7374 6.24949 17.574C10.5244 13.5796 10.9946 10.8932 10.9946 10.8932C15.4172 18.7452 14.3323 23.679 12.6661 26.1669C16.7232 25.0465 19.7881 21.6839 19.4786 16.6962"
          id="Gas"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default memo(ProductGas);
