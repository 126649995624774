import { useMemo } from "react";

import { IChartScreenshotSettings } from "models/chart";

import {
  useScreenshotContext,
  useScreenshotPresetContext
} from "components/screenshot/hooks";

function useMapScreenshotCalculatedDefaults(): IChartScreenshotSettings {
  // hooks
  const { widget } = useScreenshotContext();
  const { selectedPreset: preset } = useScreenshotPresetContext();

  return useMemo(() => {
    return widget?.widgetComponentType !== "map"
      ? {}
      : {
          showLegendInScreenshot: true,
          showTownshipBorderInScreenshot: true,
          legendFontSize: preset?.legendFontSize,
          legendOpacity: 1,
          legendShowCounts: true,
          mapScreenshotScale: 1,
          showIpdbLegendInScreenshot: true,
          showIpdbLegendBorderInScreenshot: true,
          ipdbTitleSize: 15,
          ipdbLabelsSize: 14,
          ipdbLegendOpacity: 1,
          applyAbbreviations: false
        };
  }, [preset?.legendFontSize, widget?.widgetComponentType]);
}

export default useMapScreenshotCalculatedDefaults;
