import { memo, useState } from "react";

import { Button } from "antd";
import styled from "styled-components/macro";
import { removeCount } from "utils";

import { Uwi } from "models";

import {
  updateContextMenuPosition,
  useChartDispatch,
  useChartState
} from "components/chart/context";
import ClipboardIcon from "components/icons/Clipboard";
import ClipboardCheckFilledIcon from "components/icons/ClipboardCheckFilled";

const CopyUWIButton = ({ uwi }) => {
  const dispatch = useChartDispatch();
  const { instance } = useChartState();

  // sync Icon with copy status
  const [copied, setCopied] = useState(false);
  const [copiedUnformatted, setCopiedUnformatted] = useState(false);

  const handleClick = (useFormatted) => () => {
    const uwiConverter = new Uwi();
    // copy to clipboard
    const uwiToCopy = useFormatted
      ? uwiConverter.toFormatted(removeCount(uwi))
      : uwiConverter.toUnformatted(removeCount(uwi));
    navigator.clipboard.writeText(uwiToCopy).then(() => {
      if (useFormatted) {
        setCopied(true);
      } else {
        setCopiedUnformatted(true);
      }
    });

    // hide context menu after 1 second
    setTimeout(() => {
      setCopied(false);
      setCopiedUnformatted(false);
      updateContextMenuPosition(dispatch, null);
    }, 1000);

    // turn tooltips ON
    instance.setOption({
      tooltip: { show: true, showContent: true }
    });
  };

  return (
    <>
      <StyledButton type="text" onClick={handleClick(true)}>
        Copy UWI
        {copied ? <ClipboardCheckFilledIcon /> : <ClipboardIcon />}
      </StyledButton>
      <StyledButton type="text" onClick={handleClick(false)}>
        Copy Unformatted UWI
        {copiedUnformatted ? <ClipboardCheckFilledIcon /> : <ClipboardIcon />}
      </StyledButton>
    </>
  );
};

export default memo(CopyUWIButton);

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 12px;
`;
