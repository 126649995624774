import { useQuery } from "react-query";

import axios from "axios";

const JOB_SERVICE_URL = process.env.REACT_APP_JOB_SERVICE;

export const VALNAV_ENTITIES_QUERY_KEYS = {
  valNavEntities: "valNavEntities"
};

function getWells(nodes): string[] {
  let wells = [];

  for (const child of nodes) {
    if (!child.children || child.children.length == 0) {
      wells.push(child.value?.value);
    } else {
      const childWells = getWells(child.children);
      wells = [...wells, ...childWells];
    }
  }
  return wells;
}

const fetchEntities = async ({ queryKey }) => {
  try {
    const [, job, hierarchy] = queryKey;
    const entities = await axios.get<string[]>(
      `${JOB_SERVICE_URL}/api/v1/hierarchy/${job}/${hierarchy}`
    );

    if (entities.status === 200) {
      const newData = getWells(entities.data);
      entities.data = newData;
    }

    return entities;
  } catch (error) {
    if (error?.response?.status === 400 && error.response?.data === "invalid hierarchy") {
      throw new Error(
        "Unable to fetch Type Wells due to an invalid hierarchy, try another hierarchy"
      );
    } else {
      throw new Error("Unable to fetch Type Wells");
    }
  }
};

export default function useValNavEntitiesQuery(
  job: string,
  hierarchy: string,
  isAutoRefetching?: boolean
) {
  const nextIsAutoFetching =
    isAutoRefetching === undefined
      ? !!(job && hierarchy)
      : !!(isAutoRefetching && job && hierarchy);
  const queryInfo = useQuery({
    queryKey: [VALNAV_ENTITIES_QUERY_KEYS.valNavEntities, job, hierarchy],
    queryFn: fetchEntities,
    enabled: nextIsAutoFetching
  });

  return {
    data: queryInfo?.data?.data,
    error: queryInfo.error,
    isLoading: queryInfo.isFetching,
    isError: queryInfo.isError,
    refetch: queryInfo.refetch
  };
}
