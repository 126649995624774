import { useContext } from "react";

import { GeomBinDispatchContext } from "../contexts/GeomBinContext";

export function useGeomBinDispatch() {
  const context = useContext(GeomBinDispatchContext);
  if (context === undefined) {
    throw new Error("useGeomBinContextDispatch must be used within a Geom Bin Provider");
  }
  return context;
}
