import { Select } from "antd";
import { SUPPORTED_FONT_FAMILIES, THEME_FONT_FAMILY } from "constants/style.constants";

import { ScreenshotSetting } from "components/screenshot/components";
import {
  SettingsControl,
  SettingsGroups,
  useScreenshotContext
} from "components/screenshot/hooks";

const { Option } = Select;

function useMPCScreenshotSettingsControls(): SettingsControl[] {
  // hooks
  const { widget } = useScreenshotContext();

  const ChartSettingsGroups = {
    title: "Chart Title",
    axis: "Chart Axis",
    legend: "Chart Legend",
    advanced: "Advanced Chart Settings"
  };

  const controls: SettingsControl[] = [];

  if (widget?.widgetComponentType !== "mpc") {
    return controls;
  }

  controls.push(
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="legendVisible"
          name="legendVisible"
          label="Legend"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.quick,
      control: (
        <ScreenshotSetting.Item
          key="applyAbbreviations"
          name="applyAbbreviations"
          label="Use Abbreviations"
          valuePropName="checked">
          <ScreenshotSetting.Input type="boolean" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="titleFontSize"
          name="titleFontSize"
          label="Title Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="axisFontSize"
          name="axisFontSize"
          label="Axis Title Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="axisLabelFontSize"
          name="axisLabelFontSize"
          label="Axis Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: SettingsGroups.preset,
      control: (
        <ScreenshotSetting.Item
          key="legendFontSize"
          name="legendFontSize"
          label="Legend Labels Size">
          <ScreenshotSetting.Input type="number" min={0} unit="pt" />
        </ScreenshotSetting.Item>
      )
    }
  );

  controls.push(
    {
      group: ChartSettingsGroups.title,
      control: (
        <ScreenshotSetting.Item key="titleText" name="titleText" label="Title">
          <ScreenshotSetting.Input type="text" />
        </ScreenshotSetting.Item>
      )
    },
    {
      group: ChartSettingsGroups.axis,
      control: (
        <ScreenshotSetting.Item
          key="axisFontWeight"
          name="axisFontWeight"
          label="Title Font Weight">
          <ScreenshotSetting.Input type="number" step={100} min={100} />
        </ScreenshotSetting.Item>
      )
    }
  );

  controls.push({
    group: ChartSettingsGroups.advanced,
    control: (
      <ScreenshotSetting.Item
        key="fontFamily"
        name="fontFamily"
        label="Font Family"
        valuePropName="value">
        <Select defaultValue={THEME_FONT_FAMILY}>
          {SUPPORTED_FONT_FAMILIES.map((option) => (
            <Option key={option.value} value={option.value} label={option.label}>
              <span style={{ fontFamily: option.value }}>{option.label}</span>
            </Option>
          ))}
        </Select>
      </ScreenshotSetting.Item>
    )
  });

  return controls;
}

export default useMPCScreenshotSettingsControls;
