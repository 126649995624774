import { useQuery } from "@apollo/client";
import styled from "styled-components";

import { GET_WELL_LIST_RECORDS } from "api/userArps";

import { UserArpsItem, WellListRecordCollection } from "models/UserArpsModel";

import { ForecastWellListRecords } from "components/project/forecasts/ForecastWellListRecords";

interface TypeWellEditorWellListProps {
  arpsItem: UserArpsItem;
}

function TypeWellEditorWellList({ arpsItem }: TypeWellEditorWellListProps) {
  const { data: wellListRecordData, loading: isWellListRecordLoading } =
    useQuery<WellListRecordCollection>(GET_WELL_LIST_RECORDS, {
      variables: {
        forecastId: arpsItem?.id
      }
    });

  return (
    <ForecastWellListRecordsWrapper>
      <ForecastWellListRecords
        data={wellListRecordData}
        isLoading={isWellListRecordLoading}
      />
    </ForecastWellListRecordsWrapper>
  );
}

export default TypeWellEditorWellList;

const ForecastWellListRecordsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0px 0px 20px 20px;
  background: white;
`;
