import { memo } from "react";

function TrendDate() {
  return (
    <svg
      width="33"
      height="22"
      viewBox="0 0 33 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_873_2)">
        <path d="M1.125 1.125V20.125H19.125" stroke="currentColor" strokeWidth={2} />
        <path
          d="M4 8L12.5 8.99985C19 11.5 12.5 8.99985 19 11.5"
          stroke="currentColor"
          strokeWidth={2}
        />
        <path
          d="M4 14.5L10.9634 6.50003C19 5 10.9634 6.50003 19 5"
          stroke="currentColor"
          strokeWidth={2}
        />
        <path
          d="M30.3685 14.1617H22.2018V11.7617C22.2018 11.4311 22.4637 11.1617 22.7852 11.1617H23.3685V11.7617C23.3685 12.0917 23.631 12.3617 23.9518 12.3617C24.2727 12.3617 24.5352 12.0917 24.5352 11.7617V11.1617H28.0352V11.7617C28.0352 12.0917 28.2977 12.3617 28.6185 12.3617C28.9393 12.3617 29.2018 12.0917 29.2018 11.7617V11.1617H29.7852C30.1066 11.1617 30.3685 11.4311 30.3685 11.7617V14.1617ZM29.7852 9.96172H29.2018V9.36172C29.2018 9.03172 28.9393 8.76172 28.6185 8.76172C28.2977 8.76172 28.0352 9.03172 28.0352 9.36172V9.96172H24.5352V9.36172C24.5352 9.03172 24.2727 8.76172 23.9518 8.76172C23.631 8.76172 23.3685 9.03172 23.3685 9.36172V9.96172H22.7852C21.8203 9.96172 21.0352 10.7693 21.0352 11.7617V18.9617C21.0352 19.9541 21.8203 20.7617 22.7852 20.7617H29.7852C30.75 20.7617 31.5352 19.9541 31.5352 18.9617V11.7617C31.5352 10.7693 30.75 9.96172 29.7852 9.96172Z"
          fill="currentColor"
        />
        <path
          d="M33.2838 21.9619C22.189 14.6413 11.0942 7.32072 33.2838 21.9619Z"
          fill="currentColor"
          strokeWidth={2}
        />
      </g>
    </svg>
  );
}

export default memo(TrendDate);
