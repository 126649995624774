/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProjectModel, ProjectPermissions } from "api/project";

import { IUser } from "models/User";

export const getProjectPermissions = (
  project: ProjectModel,
  user: IUser,
  isAtLeastPowerUser: boolean,
  isAtLeastAdmin: boolean,
  isReadonly: boolean
): ProjectPermissions => {
  const viewable = true;
  let editable = false;
  let deletable = false;
  let canEditProjectItems = false;

  if (project) {
    if (isReadonly) {
      editable = false;
      canEditProjectItems = false;
      deletable = false;
    } else {
      switch (project.projectType) {
        case "McDaniel":
          editable = false;
          canEditProjectItems = false;
          deletable = false;
          break;
        case "Organization":
          editable = isAtLeastPowerUser;
          canEditProjectItems = isAtLeastPowerUser;
          deletable = isAtLeastPowerUser;
          break;

        case "Shared":
          editable = project.owner === user.id || isAtLeastAdmin;
          canEditProjectItems = !isReadonly;
          deletable = project.owner === user.id || isAtLeastAdmin;
          break;

        case "Personal":
        default:
          editable = project.owner === user.id && project.projectName !== "Default";
          canEditProjectItems = project.owner === user.id;
          deletable = project.owner === user.id;
          break;
      }
    }
  }

  return {
    canCreate: !isReadonly,
    canView: viewable,
    canEdit: editable,
    canDelete: deletable,
    canEditProjectItems,
    canEditFilters: canEditProjectItems,
    canEditForecasts: canEditProjectItems,
    canEditShapefiles: canEditProjectItems,
    canEditTypeWells: canEditProjectItems
  };
};
