import { memo } from "react";

function C4() {
  const moleculeRadius = 3; // Adjust the size of the circles as needed
  const bondLength = 5; // Adjust the distance between the circles as needed

  return (
    <svg width="30px" height="22px" viewBox="0 0 30 22">
      {/* Connected carbon molecules forming an "M" shape */}
      <circle cx={bondLength} cy={15} r={moleculeRadius} fill="currentColor" />
      <circle cx={bondLength * 2} cy={3} r={moleculeRadius} fill="currentColor" />
      <circle cx={bondLength * 4} cy={15} r={moleculeRadius} fill="currentColor" />
      <circle cx={bondLength * 5} cy={3} r={moleculeRadius} fill="currentColor" />

      {/* Connecting bonds between molecules */}
      <line
        x1={bondLength}
        y1={15}
        x2={bondLength * 2}
        y2={3}
        stroke="currentColor"
        strokeWidth="1"
      />
      <line
        x1={bondLength * 2}
        y1={3}
        x2={bondLength * 4}
        y2={15}
        stroke="currentColor"
        strokeWidth="1"
      />
      <line
        x1={bondLength * 4}
        y1={15}
        x2={bondLength * 5}
        y2={3}
        stroke="currentColor"
        strokeWidth="1"
      />
    </svg>
  );
}

export default memo(C4);
