import React, { useEffect } from "react";

import { getJobStatus } from "../apis/getJobStatus";
import { GeoCalculationsJob } from "../models/GeoCalculations";

export function useJobStatus(
  jobs: GeoCalculationsJob[],
  setJobStatus: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >
) {
  useEffect(() => {
    const interval = setInterval(() => {
      (jobs ?? []).forEach(async (job) => {
        try {
          const status = await getJobStatus(job.jobId);
          setJobStatus((prevState) => ({ ...prevState, [job.jobId]: status }));
        } catch (e) {
          setJobStatus((prevState) => ({ ...prevState, [job.jobId]: "Completed" }));
        }
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [jobs]);
}
