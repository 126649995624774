import { useCallback, useEffect } from "react";

import { ALL_CHART_TYPES } from "constants/chart.constants";

import {
  updateBrushEndPosition,
  updateContextMenuPosition,
  useChartDispatch,
  useChartState
} from "components/chart/context";

/**
 * Simple `hook` to that extracts eChart event handling
 */
const useEchartEvents = () => {
  const { contextMenuPosition, instance, lasso, settings } = useChartState();
  const dispatch = useChartDispatch();

  const isCrossPlot = settings.chartType === ALL_CHART_TYPES.CrossPlot.label;
  const isProbit = settings.chartType === ALL_CHART_TYPES.Probit.label;
  const isBoxPlot = settings.chartType === ALL_CHART_TYPES.BoxPlot.label;
  const isContextMenuAllowed = settings.sum || isCrossPlot || isProbit || isBoxPlot;

  const clickHandler = useCallback(() => {
    const zr = instance?.getZr();
    if (!zr) return;
    if (!contextMenuPosition) return;

    updateContextMenuPosition(dispatch, null);

    instance.setOption({
      tooltip: { show: true, showContent: true }
    });
  }, [contextMenuPosition, dispatch, instance]);

  const contextMenuHandler = useCallback(
    (params) => {
      // turn tooltips OFF
      updateContextMenuPosition(dispatch, null);
      instance.setOption({
        tooltip: { show: false, showContent: false }
      });

      const { event } = params;
      const { zrX, zrY } = event;
      updateContextMenuPosition(dispatch, { x: zrX, y: zrY });
      event.preventDefault();
    },
    [instance, dispatch]
  );

  const mouseoverHandler = useCallback(() => {
    dispatch({ type: "mouseoverPlot", payload: true });
  }, [dispatch]);

  const mouseoutHandler = useCallback(() => {
    dispatch({ type: "mouseoverPlot", payload: false });
  }, [dispatch]);

  // when lasso is ON, save the mouseup position to show `Add to Filter` menu
  const mouseupHandler = useCallback(
    (e) => {
      const { event } = e;

      const { offsetX, offsetY } = event;
      const position = { x: offsetX, y: offsetY };
      updateBrushEndPosition(dispatch, position);
    },
    [dispatch]
  );

  const mousedownHandler = useCallback(() => {
    updateBrushEndPosition(dispatch, null);
  }, [dispatch]);

  useEffect(() => {
    if (!instance) return;

    instance.on("mouseover", mouseoverHandler);
    instance.on("mouseout", mouseoutHandler);

    return () => {
      instance.off("mouseover");
      instance.off("mouseout");
    };
  }, [instance, mouseoutHandler, mouseoverHandler]);

  useEffect(() => {
    // zRender attach events to listen for blank area on chart as well
    const zr = instance?.getZr();
    if (!zr) return;

    if (isContextMenuAllowed) {
      zr.on("click", clickHandler);
      zr.on("contextmenu", contextMenuHandler);
    }

    if (lasso) {
      zr.on("mousedown", mousedownHandler);
      zr.on("mouseup", mouseupHandler);
    }
    return () => {
      const zr = instance?.getZr();
      if (!zr) return;

      if (isContextMenuAllowed) {
        zr.off("click", clickHandler);
        zr.off("contextmenu", contextMenuHandler);
      }

      if (lasso) {
        zr.off("mousedown", mousedownHandler);
        zr.off("mouseup", mouseupHandler);
      }
    };
  }, [
    clickHandler,
    contextMenuHandler,
    dispatch,
    instance,
    isContextMenuAllowed,
    lasso,
    mousedownHandler,
    mouseoutHandler,
    mouseupHandler
  ]);
};

export default useEchartEvents;
