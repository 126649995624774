import { useEffect, useState } from "react";

import { ALL_CHART_TYPES, ChartTypeLabels } from "constants/chart.constants";

import { ChartAxisType } from "models/chart";

const useLogToggle = (axisType: ChartAxisType, chartType: ChartTypeLabels): boolean => {
  const [showLogToggle, setShowLogToggle] = useState(updateLogToggle(chartType));
  function updateLogToggle(chartType: ChartTypeLabels) {
    const showXAxisToggle =
      chartType === ALL_CHART_TYPES.RateCum.label ||
      chartType === ALL_CHART_TYPES.RateTime.label;

    const showYAxisToggle =
      chartType === ALL_CHART_TYPES.RateCum.label ||
      chartType === ALL_CHART_TYPES.RateTime.label ||
      chartType === ALL_CHART_TYPES.RateDate.label ||
      chartType === ALL_CHART_TYPES.CAGR.label ||
      chartType === ALL_CHART_TYPES.BaseDeclineRate.label ||
      chartType === ALL_CHART_TYPES.TotalRateDate.label ||
      chartType === ALL_CHART_TYPES.CrossPlot.label;

    return {
      x: showXAxisToggle,
      y: showYAxisToggle
    };
  }
  useEffect(() => {
    setShowLogToggle(updateLogToggle(chartType));
    return () => {
      setShowLogToggle(undefined);
    };
  }, [chartType]);

  return (showLogToggle ?? { x: false, y: false })[axisType];
};

export default useLogToggle;
