// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { mdiPlus } from "@mdi/js";
import { Button } from "antd";
import _debounce from "lodash/debounce";
import { setSelectedTypeWell } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { WellData } from "models/UserArpsModel";

import { useSelectedProjectPermissions } from "components/project/projects/hooks";

export interface AddNormalizationFieldButtonProps {
  validateTypeWellData: (wellData: WellData) => boolean;
  onChange?: (value?: WellData) => void;
}

export function AddNormalizationFieldButton({
  validateTypeWellData,
  onChange
}: AddNormalizationFieldButtonProps) {
  const dispatch = useDispatch();
  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const wellData = selectedTypeWell?.wellData;

  const projectPermissions = useSelectedProjectPermissions();

  const changeDebounceRef = useRef(
    _debounce((fn) => {
      fn && fn();
    }, 600)
  );

  const setWellData = (data) => {
    dispatch(
      setSelectedTypeWell({
        ...selectedTypeWell,
        wellData: {
          ...wellData,
          ...data
        }
      })
    );
  };

  const addNormalizationField = () => {
    const newAdditionalData = [
      ...(wellData?.AdditionalData ?? []),
      {
        field: "",
        value: 0
      }
    ];
    const updatedWellData: WellData = {
      ...wellData,
      AdditionalData: newAdditionalData
    };
    setWellData(updatedWellData);
    if (validateTypeWellData(updatedWellData)) {
      changeDebounceRef.current(() => onChange && onChange(updatedWellData));
    }
  };

  return (
    <ButtonWrapper
      onClick={addNormalizationField}
      disabled={!projectPermissions?.canEditTypeWells}>
      <Icon path={mdiPlus} />
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled(Button)`
  width: 25%;
  display: flex;
  align-items: center;
  background-color: var(--ant-primary-color);
  color: white;
  &:focus {
    background-color: var(--ant-primary-color);
    color: white;
  }
  &:hover {
    background-color: white;
    color: black;
  }
`;
