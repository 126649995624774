import { ProductTypeEnum } from "../../components/arps/models/ArpsSegment";

export function productStringToProductTypeEnum(product: string): ProductTypeEnum {
  switch (product.replace(".", "").toLocaleLowerCase()) {
    case "oil":
      return ProductTypeEnum.Oil;
    case "cond":
    case "condensate":
      return ProductTypeEnum.Oil;
    case "gas":
      return ProductTypeEnum.Gas;
    case "water":
      return ProductTypeEnum.Water;
    case "gor":
      return ProductTypeEnum.GOR;
    case "cgr":
      return ProductTypeEnum.CGR;
    case "ogr":
      return ProductTypeEnum.OGR;
    case "wgr":
      return ProductTypeEnum.WGR;
    case "wor":
      return ProductTypeEnum.WOR;
    case "1+wor":
    case "inverse_oil_cut":
      return ProductTypeEnum.OnePlusWOR;
    case "oilpluswater":
    case "o+w":
      return ProductTypeEnum.OilPlusWater;
    case "oil cut":
      return ProductTypeEnum.OilCut;
    case "water cut":
      return ProductTypeEnum.WaterCut;
    case "sales gas":
      return ProductTypeEnum.SalesGas;
    default:
      // eslint-disable-next-line no-console
      console.warn("unknown product: " + product);
      return ProductTypeEnum.Unknown;
  }
}

export function getDefaultRateUnits(product: ProductTypeEnum): string {
  switch (product) {
    case ProductTypeEnum.Oil:
    case ProductTypeEnum.Cond:
    case ProductTypeEnum.Water:
      return "bbl/d";
    case ProductTypeEnum.Gas:
    case ProductTypeEnum.SalesGas:
      return "mcf/d";
    case ProductTypeEnum.GOR:
      return "scf/bbl";
    case ProductTypeEnum.WOR:
    case ProductTypeEnum.OnePlusWOR:
      return "bbl/bbl";
    case ProductTypeEnum.CGR:
    case ProductTypeEnum.OGR:
    case ProductTypeEnum.WGR:
      return "bbl/MMcf";
    case ProductTypeEnum.OilPlusWater:
      return "bbl/d";
  }
}
export function getDefaultRateUnitsFromString(product: string): string {
  switch (product.toLowerCase()) {
    case "oil":
    case "cond":
    case "water":
      return "bbl/d";
    case "gas":
      return "mcf/d";
    case "gor":
      return "scf/bbl";
    case "wor":
    case "onepluswor":
    case "1+wor":
    case "inverse_oil_cut":
      return "bbl/bbl";
    case "cgr":
    case "ogr":
    case "wgr":
      return "bbl/MMcf";
    case "oilpluswater":
    case "o+w":
      return "bbl/d";
  }
}

export function getDatabaseRateUnits(product: ProductTypeEnum): string {
  switch (product) {
    case ProductTypeEnum.Oil:
    case ProductTypeEnum.Cond:
    case ProductTypeEnum.Water:
      return "bbl/d";
    case ProductTypeEnum.Gas:
    case ProductTypeEnum.SalesGas:
      return "mcf/d";
    case ProductTypeEnum.GOR:
      return "Mcf/bbl";
    case ProductTypeEnum.WOR:
    case ProductTypeEnum.OnePlusWOR:
      return "bbl/bbl";
    case ProductTypeEnum.CGR:
    case ProductTypeEnum.OGR:
    case ProductTypeEnum.WGR:
      return "bbl/Mcf";
    case ProductTypeEnum.OilPlusWater:
      return "bbl/d";
  }
}

export function getDefaultCumUnits(product: ProductTypeEnum, isEur: boolean) {
  switch (product) {
    case ProductTypeEnum.Oil:
    case ProductTypeEnum.Water:
    case ProductTypeEnum.OilPlusWater:
      return "Mbbl";
    case ProductTypeEnum.Gas:
    case ProductTypeEnum.SalesGas:
      return "MMcf";
    case ProductTypeEnum.GOR:
    case ProductTypeEnum.WOR:
    case ProductTypeEnum.OnePlusWOR:
      return isEur ? "MMcf" : "Mbbl";
    case ProductTypeEnum.CGR:
    case ProductTypeEnum.WGR:
      return isEur ? "Mbbl" : "MMcf";
  }
}

export function getDefaultDeclineUnits(product: ProductTypeEnum) {
  switch (product) {
    case ProductTypeEnum.Oil:
    case ProductTypeEnum.Water:
    case ProductTypeEnum.Gas:
    case ProductTypeEnum.SalesGas:
      return "%/yr";
    default:
      return "%/vol";
  }
}
