import { toast } from "react-toastify";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cookies } from "constants/settings.constants";
import Cookies from "js-cookie";
import { AppThunk } from "store/store";

import { loginUser, refreshToken, registerUser, signoutUser } from "api/auth";

import { IOrganization, IUser } from "models/User";

interface IAuthState {
  unmodifiedUser: IUser;
  user: IUser;
  jwtToken: string;
  dataAccess: string[];
  dataSources: string[];
}

const initialState: IAuthState = {
  user: null,
  jwtToken: "",
  dataAccess: [],
  dataSources: [],
  unmodifiedUser: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<IUser>) {
      state.user = Object.assign(state.user, action.payload);
    },
    updateUserOrganization(state, action: PayloadAction<IOrganization>) {
      state.user = Object.assign(state.user, { organization: action.payload });
    },
    setUser(state, action: PayloadAction<IUser>) {
      const user = action.payload;

      if (!user) {
        state.user = null;
        state.unmodifiedUser = null;

        return;
      }

      state.unmodifiedUser = { ...user };

      // parse json object from base64 encoded jwt token
      const parsedToken = JSON.parse(atob(user.jwtToken.split(".")[1]));
      const newUser = { ...user, modules: parsedToken.modules };
      state.user = newUser;

      state.jwtToken = newUser.jwtToken;
      state.dataAccess = [...newUser.dataAccess];
      state.dataSources = [...newUser.dataSources];

      axios.defaults.headers.common["Authorization"] = "Bearer " + state.jwtToken;
      localStorage.setItem(cookies.LAST_LOGGED_IN, new Date().toString());
    },
    updateJwtToken(state, action: PayloadAction<string>) {
      state.jwtToken = action.payload;
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.jwtToken;
    },

    logout(state) {
      Cookies.remove("user");
      localStorage.removeItem(cookies.LAST_LOGGED_IN);
      state.user = null;
      state.unmodifiedUser = null;

      state.jwtToken = "";
      state.dataAccess = [];
      state.dataSources = [];
      axios.defaults.headers.common["Authorization"] = null;
    }
  }
});

export default authSlice.reducer;
export const { setUser, logout, updateUser, updateUserOrganization, updateJwtToken } =
  authSlice.actions;

export const register =
  (user): AppThunk =>
  async () => {
    try {
      await registerUser(user);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

export const logoutUser = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    if (!state) {
      return;
    }
    const user = state.auth.user;
    await signoutUser(user.email);
    dispatch(logout());
  } catch (err) {
    toast.error(`error signing out. ${err.message}`);
  }
};

export const login =
  (credentials, onSuccess, onError): AppThunk =>
  async (dispatch) => {
    try {
      loginUser(
        credentials,
        (user) => {
          if (user.requiresMfa) {
            onSuccess(user);
          } else {
            dispatch(setUser(user));
            onSuccess(user);
          }
        },
        onError
      );
    } catch (err) {
      onError(err);
    }
  };

export const updateTokenFromRefreshToken =
  (username: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const user = await refreshToken();

    if (user) {
      if (JSON.stringify(user) !== JSON.stringify(state.auth.unmodifiedUser)) {
        dispatch(setUser(user));
      }
    } else {
      //sign out
      await signoutUser(username);
      dispatch(logout());
    }
  };

export const setUserFromCookie = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const userJson = Cookies.get("user");
  if (!userJson) {
    return;
  }
  const user: IUser = JSON.parse(userJson);
  if (!state.auth.user) {
    dispatch(setUser(user));
  }
  const jwtToken = JSON.parse(atob(user.jwtToken.split(".")[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = Math.max(10, expires.getTime() - Date.now() - 60 * 1000);
  setTimeout(async () => {
    const refreshResponse = await refreshToken();
    if (refreshResponse) {
      dispatch(setUser(refreshResponse));
    } else {
      Cookies.remove("user");
      dispatch(setUser(null));
    }
  }, timeout);
};
