import { useQuery } from "react-query";

import { ApiResult } from "../api/apiResult";
import { IpdbSourcePlay, fetchPlayTo3DDataPrivateSources } from "../api/ipdp";

///hook to fetch ipdb fields
export default function usePlayTo3DDataPrivateSources(): ApiResult<IpdbSourcePlay[]> {
  const { data, isLoading, isError, refetch } = useQuery<IpdbSourcePlay[]>(
    "fetchPlayTo3DDataPrivateSources",
    fetchPlayTo3DDataPrivateSources,
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
