import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

type PortalT = {
  children: ReactNode;
  container: HTMLElement;
};

const Portal: FC<PortalT> = ({ children, container = document.body }) => {
  return createPortal(children, container);
};

export default Portal;
