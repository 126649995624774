import { memo } from "react";

import GasInjection from "./GasInjection";

function TotalGasInjection() {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="currentColor"
      height="30"
      width="30"
      transform="scale(1.5, 1.5) translate(2, 2)">
      <path
        d="M5.30686 2L5.30686 0L0 0L2.22569 3.30138L0 6.63918L5.30686 6.63918L5.30686 4.63918"
        transform="translate(3, 11) scale(1.75, 1.75)"
        id="Path-8"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <g
        transform="translate(12, 5) scale(0.75, 0.75)"
        id="Path-8"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor">
        <GasInjection />
      </g>
    </svg>
  );
}

export default memo(TotalGasInjection);
