import { memo } from "react";

function SteamInjection() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
      <g id="IconsChart---PRPSteam-Inj">
        <path
          d="M0 0L30 0L30 30L0 30L0 0Z"
          id="Rectangle-Copy-21"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Steam-Inj" transform="translate(6.219503 0.108675174)">
          <path
            d="M5.30974 0C5.74145 7.40337 -0.409987 7.41479 0.0217215 14.8182"
            transform="translate(0 2.1963587)"
            id="Stroke-1"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M5.68217 3.55302e-17C7.63031 9.75397 -1.11303 9.53667 0.119649 20.4817"
            transform="translate(5.80946 0)"
            id="Stroke-3"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M5.30974 0C5.74145 7.40337 -0.409987 7.41479 0.0217215 14.8182"
            transform="translate(12.229531 3.481734)"
            id="Stroke-5"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M5.62 0L5.62 5.62L0 5.62"
            transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 8.008963 19.749456)"
            id="Rectangle-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(SteamInjection);
