export const applyMultiPhaseChartDefaults = (settings) => {
  if (!settings) return settings;

  const newSettings = { ...settings };

  // legend
  if (newSettings.legend === undefined) {
    newSettings.legend = {
      visible: false,
      type: "bottom",
      position: "NE"
    };
  }

  if (newSettings.axisMinMax === undefined) {
    newSettings.axisMinMax = [
      {
        xMin: null,
        xMax: null,
        yMin: null,
        yMax: null
      },
      {
        xMin: null,
        xMax: null,
        yMin: null,
        yMax: null
      },
      {
        xMin: null,
        xMax: null,
        yMin: null,
        yMax: null
      },
      {
        xMin: null,
        xMax: null,
        yMin: null,
        yMax: null
      }
    ];
  }

  return newSettings;
};
