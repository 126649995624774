import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { updateUserDefinedColumns } from "store/features";

import { updateUserDefinedColumnOrder } from "api/data";

const useCustomColumnOrderMutation = (isOrg) => {
  const dispatch = useDispatch();
  const mutation = useMutation(
    async (data) => {
      const result = await updateUserDefinedColumnOrder(data, isOrg);
      return result;
    },
    {
      onSuccess: () => {
        dispatch(updateUserDefinedColumns());
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (err.response?.data) {
          toast.error(`${err?.response.data}`);
        } else {
          toast.error("Error could not update order");
        }
      }
    }
  );

  return mutation;
};

export default useCustomColumnOrderMutation;
