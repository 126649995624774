import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { ORGANIZATION_SHAPEFILES_QUERY_KEYS } from "../../queries";

export interface IUseUpdateProjectShapefileMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
  projectId: string;
}

export type UpdateProjectShapefileParamsT = {
  body: {
    // Could use a union type here
    key: string;
    value: string;
  };
  projectId: string; // check if we need project id here, deosnt seem to be used
  shapefileId: string;
};

async function updateProjectShapefile(params: UpdateProjectShapefileParamsT) {
  const url =
    params.projectId === "system"
      ? `${projectServiceEndpoint}/system-shapefile/update/${params.shapefileId}`
      : `${projectServiceEndpoint}/shapefile/update/${params.shapefileId}`;
  return await axios.put(url, params.body);
}

export function useUpdateProjectShapefileMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess,
  projectId
}: IUseUpdateProjectShapefileMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: UpdateProjectShapefileParamsT) => updateProjectShapefile({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        if (projectId === "organization") {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });
        }
        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
