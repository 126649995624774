import { memo } from "react";

import { SpaghettiMode } from "components/icons";

import { useChartDispatch, useChartState } from "../context";
import { getSpaghettiToggleDisabledStatus } from "../utils/toggles";
import ToolbarButton from "./ToolbarButton";

const SpaghettiToggle = () => {
  const dispatch = useChartDispatch();
  const { settings } = useChartState();
  const isDisabled = getSpaghettiToggleDisabledStatus(settings.chartType);

  const toggle = () => (value) => {
    const next = { ...settings, sum: value };
    dispatch({ type: "settings", payload: next });
  };

  return (
    <ToolbarButton
      active={settings.sum}
      icon={<SpaghettiMode />}
      overflowLabel="Spaghetti"
      tooltipText="Toggle Spaghetti Plot"
      onToggle={toggle()}
      disabled={isDisabled}
    />
  );
};

export default memo(SpaghettiToggle);
