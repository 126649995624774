import { useCallback, useEffect, useRef, useState } from "react";

import { Business, Person } from "@material-ui/icons";
import { Button, Input, Modal, Select } from "antd";
import styled from "styled-components";

import { useUser } from "hooks";

export interface NewDashboardInputInputModel {
  // eslint-disable-next-line no-unused-vars
  onAdd: (title: string, type?: string) => void;
  onCancel: () => void;
  isLoading: boolean;
  error: string;
  visible: boolean;
  title: string;
  dashboardTitle: string;
  setTitle?: boolean;
  setType?: boolean;
  addButtonLabel?: string;
}

export default function NewDashboardInput(input: NewDashboardInputInputModel) {
  const [title, setTitle] = useState(input.dashboardTitle);
  const [type, setType] = useState("Personal");
  const titleRef = useRef(null);
  const { isAtLeastPowerUser } = useUser();

  // Reset the component to its initial state.
  useEffect(() => {
    if (input.visible) {
      setTitle(input.dashboardTitle);
      if (titleRef.current) {
        titleRef.current.select();
      }
    }
  }, [input.visible, input.dashboardTitle]);

  const getDashboardTypeOptions = useCallback(() => {
    if (isAtLeastPowerUser) {
      return [
        {
          label: (
            <DashboardTypeItem>
              <Person /> Personal
            </DashboardTypeItem>
          ),
          value: "Personal"
        },
        {
          label: (
            <DashboardTypeItem>
              <Business /> Organization
            </DashboardTypeItem>
          ),
          value: "Organization"
        }
      ];
    } else {
      return [
        {
          label: (
            <DashboardTypeItem>
              <Person /> Personal
            </DashboardTypeItem>
          ),
          value: "Personal"
        }
      ];
    }
  }, [isAtLeastPowerUser]);

  const handleCancel = () => {
    input.onCancel();
  };

  const includeTitle = input.setTitle === undefined || input.setTitle;
  const includeType = input.setType;

  return (
    <Modal
      open={input.visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      closable={false}
      title={<NewDashboardPopoverTitle>{input.title}</NewDashboardPopoverTitle>}
      wrapClassName="copy-dashboard-modal-root"
      footer={[
        <Button key="cancel" onClick={input.onCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          disabled={input.isLoading}
          loading={input.isLoading}
          onClick={() => {
            input.onAdd(title, type);
          }}>
          {input.addButtonLabel ?? "Save"}
        </Button>
      ]}>
      <CopyDashboardContainer>
        <Options>
          {includeTitle && (
            <OptionItem>
              <Label>Dashboard Title</Label>
              <Input
                ref={titleRef}
                value={title}
                placeholder="Enter the new dashboards name"
                onChange={(e) => setTitle(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </OptionItem>
          )}
          {includeType && (
            <OptionItem>
              <Label>Dashboard Type</Label>
              <SelectWrapper
                popupClassName="modal-select"
                value={type}
                onChange={(value) => {
                  setType(value);
                }}
                options={getDashboardTypeOptions()}
              />
            </OptionItem>
          )}
        </Options>

        {input.error !== "" && <ErrorContainer>{input.error}</ErrorContainer>}
      </CopyDashboardContainer>
    </Modal>
  );
}

const CopyDashboardContainer = styled.div``;

const Label = styled.label`
  min-width: 120px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    padding: 5px;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;
const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
  .ant-slider {
    width: 80px;
  }

  .ant-select {
    width: 100px;
  }
  .ant-btn {
    border-radius: 5px;
  }
  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  padding: 2px 0;
`;

const DashboardTypeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const SelectWrapper = styled(Select)`
  width: 240px !important;
`;

const NewDashboardPopoverTitle = styled.p`
  font-weight: var(--fontWeightBold);
`;

const ErrorContainer = styled.div`
  display: inline-flex;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;
