// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import useResizeObserver from "@react-hook/resize-observer";
import { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { mdiPlus, mdiRun } from "@mdi/js";
import { Button, Tabs, TabsProps, Typography } from "antd";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { AxiosErrorWithData } from "../../utils/errorUtil";
import PadScenarioModal from "./PadScenarioModal";
import { ScenarioConfigurationTable } from "./ScenarioConfigurationTable";
import ScenarioList from "./ScenarioList";
import ScenarioSticks from "./ScenarioSticks";
import { usePadScenario } from "./contexts/usePadScenario";
import useRunScenario from "./hooks/postRunScenario";
import useAddPadLayer from "./hooks/useAddPadLayer";

export const ScenarioToastContainerId = "scenario-container";

export default function PadScenario() {
  const { mutateAsync: runScenario, isLoading } = useRunScenario();
  const { mutateAsync: addPadLayer } = useAddPadLayer();
  const { state } = usePadScenario();
  const [showNewScenarioModal, setShowNewScenarioModal] = useState(false);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);
  useResizeObserver(containerRef, (entry) => {
    setContainerHeight(entry.contentRect.height);
  });
  const tabItems: TabsProps["items"] = [
    {
      key: "Layer Configuration",
      label: "Layer Configuration",
      children: (
        <ScenarioConfigurationTable containerHeight={containerHeight} editId={editId} />
      )
    },
    {
      key: "Wells",
      label: "Wells",
      children: <ScenarioSticks containerHeight={containerHeight} />
    }
  ];

  const handleNewRow = async () => {
    const newUuid = uuidv4();

    let layer = {
      id: newUuid,
      referenceZone: "MONT A1",
      onProdDate: new Date().toISOString(),
      azimuth: 0,
      hzLength: 2500,
      minHeelOffset: 100,
      minToeOffset: 100,
      horizontalSpacingBetweenSticks: 0,
      leftHorizontalOffset: 100,
      rightHorizontalOffset: 100,
      verticalOffset: "T-0",
      includeExistingWells: false,
      layerNumber: 0
    };
    if (state.scenario.layerPlacements.length > 0) {
      const last =
        state.scenario.layerPlacements[state.scenario.layerPlacements.length - 1];
      layer = {
        ...last,
        id: newUuid
      };
    }
    try {
      await addPadLayer({ scenarioId: state.scenario?._id?.$oid, layer });
      setEditId(newUuid);
    } catch (err) {
      const axiosError = err as AxiosErrorWithData;
      const errormsg = axiosError?.response?.data?.error ?? "An error occurred";
      toast.error(errormsg, {
        containerId: "scenario-container"
      });
    }
  };
  const handleRunScenario = async () => {
    try {
      await runScenario(state.scenario?._id?.$oid);
    } catch (err) {
      const axiosError = err as AxiosErrorWithData;
      const errormsg = axiosError?.response?.data?.error ?? "An error occurred";
      toast.error(errormsg, {
        containerId: "scenario-container"
      });
    }
  };
  return (
    <RootContainer>
      <ScenarioWrapper>
        <ScenarioList
          title={
            <>
              <Typography.Title level={5}>Scenarios</Typography.Title>
              <Button
                type="primary"
                icon={<Icon path={mdiPlus} size={1.2} />}
                onClick={() => setShowNewScenarioModal(true)}
                loading={isLoading}>
                New
              </Button>
            </>
          }
        />
        <TabsWrapper ref={containerRef}>
          <Tabs items={tabItems} />
        </TabsWrapper>
      </ScenarioWrapper>
      <GenerateWrapper>
        <Button onClick={handleNewRow} icon={<Icon path={mdiPlus} size={1.2} />}>
          Add Row
        </Button>

        <Button
          type="primary"
          icon={<Icon path={mdiRun} size={1.2} />}
          onClick={handleRunScenario}
          loading={isLoading}>
          Run Scenario
        </Button>
      </GenerateWrapper>
      <PadScenarioModal
        open={showNewScenarioModal}
        mode={"add"}
        onClose={() => setShowNewScenarioModal(false)}
      />
      <div id="scenario-modal-container" />
      <ToastContainer containerId={"scenario-container"} />
    </RootContainer>
  );
}

const RootContainer = styled.div`
  padding: 5px;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  -ms-flex-direction: column;
  grid-template-rows: minmax(0, 1fr);

  .ant-btn {
    display: flex;
    align-items: center;
  }

  .ant-form-item-label {
    min-width: 150px;
  }
`;

const ScenarioWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
`;

const GenerateWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 5px;
  display: flex;
  gap: 5px;
`;

const TabsWrapper = styled.div`
  padding: 5px;
  height: 100%;
`;
