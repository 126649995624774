// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiBarrel, mdiHistory, mdiKeyboardBackspace, mdiShape } from "@mdi/js";
import styled from "styled-components";

import { ColumnDataAnalyzer, PerformanceDataAnalyzer } from ".";
import { Heading } from "../base";

const DataAnalyzer = () => {
  const [lastClickedAnalyzer, setLastClickedAnalyzer] = useState("");

  const handleAnalyzerClick = (analyzer: string) => {
    setLastClickedAnalyzer(analyzer);
  };

  if (lastClickedAnalyzer) {
    let analyzerComponent = null;
    switch (lastClickedAnalyzer) {
      case "Column Data":
        analyzerComponent = <ColumnDataAnalyzer />;
        break;
      case "Performance Data":
        analyzerComponent = <PerformanceDataAnalyzer />;
        break;
      // case "Production History":
      //   analyzerComponent = <div>Under Construction</div>;
      //   break;
    }

    if (analyzerComponent) {
      return (
        <>
          <div />
          <RootContainer>
            <HeaderContainer>
              <Heading element="h4">{lastClickedAnalyzer} Analyzer</Heading>

              <HeaderActionContainer onClick={() => setLastClickedAnalyzer("")}>
                <Icon path={mdiKeyboardBackspace} size={1} /> Back
              </HeaderActionContainer>
            </HeaderContainer>

            {analyzerComponent}
          </RootContainer>
        </>
      );
    }
  }

  return (
    <>
      <div />
      <RootContainer>
        <Container>
          <Heading element="h4">Data Analyzers</Heading>
          <p>
            Data Analyzers allow you to analyze EVA data in order to gain insights into
            where the data is coming from and how it is being used.
          </p>
        </Container>
        <SectionContainer>
          <SectionHeading>Well Data</SectionHeading>
          <AnalyzersContainer>
            <AnalyzerButton
              data-testid="analyzer-column"
              onClick={() => handleAnalyzerClick("Column Data")}>
              <Icon path={mdiShape} size={1.4} />
              <div>Column Data Analyzer</div>
            </AnalyzerButton>
            <AnalyzerButton
              data-testid="analyzer-performance"
              onClick={() => handleAnalyzerClick("Performance Data")}>
              <Icon path={mdiBarrel} size={1.4} />
              <div>Performance Data Analyzer</div>
            </AnalyzerButton>
            <AnalyzerButton
              data-testid="analyzer-pden"
              onClick={() => handleAnalyzerClick("Production History")}
              disabled={true}>
              <Icon path={mdiHistory} size={1.4} />
              <div>Production History Analyzer</div>
            </AnalyzerButton>
          </AnalyzersContainer>
        </SectionContainer>
      </RootContainer>
    </>
  );
};

export default DataAnalyzer;

const RootContainer = styled.div`
  max-width: 1000px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
`;

const HeaderActionContainer = styled.button`
  flex: 1;
  display: flex;
  max-width: 650px;
  flex-direction: row;
  flex-grow: 1;
  border: 0;
  justify-content: flex-end;
  cursor: pointer;
  background: transparent;
  color: var(--color-primary);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--color-text);
  }
`;

export const SectionContainer = styled.div`
  padding: 18px 0;
`;

export const SectionHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 1.6rem;
  padding-bottom: 30px;
  margin-bottom: 3px;
  font-weight: var(--fontWeightBold);
  padding: 1px;
`;

const AnalyzersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`;

const AnalyzerButton = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  background-color: var(--color-text);
  color: #b5b5b5;

  border-radius: 5px;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-text);
  }
`;
