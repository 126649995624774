import { memo } from "react";

import CameraAltIcon from "@material-ui/icons/CameraAlt";

import { ToolbarButton } from "components/chart";
import { useScreenshotDispatch } from "components/screenshot/hooks";

import { useChartSettings } from "../../../../context";

const ScreenshotToggle = () => {
  const screenshotDispatch = useScreenshotDispatch();
  const [chartSettings] = useChartSettings();

  // update screenshot state when legend is toggled
  const toggle = () => (value) => {
    const next = { ...chartSettings.screenshot, visible: value };

    const widget = value
      ? {
          widgetId: chartSettings.id,
          widgetComponentType: "mpc"
        }
      : null;

    const widgetState = value
      ? {
          currentPreset: chartSettings.currentPreset,
          settings: chartSettings.settings,
          data: chartSettings.data
        }
      : null;

    screenshotDispatch({
      payload: {
        settings: next.preset,
        widget,
        widgetState
      }
    });
  };

  return (
    <ToolbarButton
      key={"multiphasechart-toolbar-screenshot-toggle"}
      testId={"multiphasechart-toolbar-screenshot-toggle"}
      active={chartSettings.screenshot.visible}
      icon={<CameraAltIcon fontSize="large" />}
      overflowLabel="Screenshot"
      tooltipText="Screenshot"
      onToggle={toggle()}
    />
  );
};

export default memo(ScreenshotToggle);
