import { useQuery } from "react-query";

import axios from "axios";
import { IS_DEV_ENV, SAM_SERVICE_URI } from "constants/app.constants";

const useInjectionWellPairsQuery = (wellList) => {
  const {
    data: chartData,
    refetch: updateData,
    isFetching
  } = useQuery(
    "injection-well-pairs",
    async () => {
      if (IS_DEV_ENV) {
        return devResponse;
      }
      const response = await axios.post(
        `${SAM_SERVICE_URI}/SamChart/injection-well-pairs`,
        {
          Ooip: 295.9596797619047,
          PoreVolume: 393.79703517887043,
          ShowForecast: true,
          WellPairUwis: wellList
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  return { chartData, updateData, isFetching };
};

const devResponse = {
  title: "",
  series: [
    {
      x: [
        "2003-10-01T00:00:00",
        "2003-11-01T00:00:00",
        "2003-12-01T00:00:00",
        "2004-01-01T00:00:00",
        "2004-02-01T00:00:00",
        "2004-03-01T00:00:00",
        "2004-04-01T00:00:00",
        "2004-05-01T00:00:00",
        "2004-06-01T00:00:00",
        "2004-07-01T00:00:00",
        "2004-08-01T00:00:00",
        "2004-09-01T00:00:00",
        "2004-10-01T00:00:00",
        "2004-11-01T00:00:00",
        "2004-12-01T00:00:00",
        "2005-01-01T00:00:00",
        "2005-02-01T00:00:00",
        "2005-03-01T00:00:00",
        "2005-04-01T00:00:00",
        "2005-05-01T00:00:00",
        "2005-06-01T00:00:00",
        "2005-07-01T00:00:00",
        "2005-08-01T00:00:00",
        "2005-09-01T00:00:00",
        "2005-10-01T00:00:00",
        "2005-11-01T00:00:00",
        "2005-12-01T00:00:00",
        "2006-01-01T00:00:00",
        "2006-02-01T00:00:00",
        "2006-03-01T00:00:00",
        "2006-04-01T00:00:00",
        "2006-05-01T00:00:00",
        "2006-06-01T00:00:00",
        "2006-07-01T00:00:00",
        "2006-08-01T00:00:00",
        "2006-09-01T00:00:00",
        "2006-10-01T00:00:00",
        "2006-11-01T00:00:00",
        "2006-12-01T00:00:00",
        "2007-01-01T00:00:00",
        "2007-02-01T00:00:00",
        "2007-03-01T00:00:00",
        "2007-04-01T00:00:00",
        "2007-05-01T00:00:00",
        "2007-06-01T00:00:00",
        "2007-07-01T00:00:00",
        "2007-08-01T00:00:00",
        "2007-09-01T00:00:00",
        "2007-10-01T00:00:00",
        "2007-11-01T00:00:00",
        "2007-12-01T00:00:00",
        "2008-01-01T00:00:00",
        "2008-02-01T00:00:00",
        "2008-03-01T00:00:00",
        "2008-04-01T00:00:00",
        "2008-05-01T00:00:00",
        "2008-06-01T00:00:00",
        "2008-07-01T00:00:00",
        "2008-08-01T00:00:00",
        "2008-09-01T00:00:00",
        "2008-10-01T00:00:00",
        "2008-11-01T00:00:00",
        "2008-12-01T00:00:00",
        "2009-01-01T00:00:00",
        "2009-02-01T00:00:00",
        "2009-03-01T00:00:00",
        "2009-04-01T00:00:00",
        "2009-05-01T00:00:00",
        "2009-06-01T00:00:00",
        "2009-07-01T00:00:00",
        "2009-08-01T00:00:00",
        "2009-09-01T00:00:00",
        "2009-10-01T00:00:00",
        "2009-11-01T00:00:00",
        "2009-12-01T00:00:00",
        "2010-01-01T00:00:00",
        "2010-02-01T00:00:00",
        "2010-03-01T00:00:00",
        "2010-04-01T00:00:00",
        "2010-05-01T00:00:00",
        "2010-06-01T00:00:00",
        "2010-07-01T00:00:00",
        "2010-08-01T00:00:00",
        "2010-09-01T00:00:00",
        "2010-10-01T00:00:00",
        "2010-11-01T00:00:00",
        "2010-12-01T00:00:00",
        "2011-01-01T00:00:00",
        "2011-02-01T00:00:00",
        "2011-03-01T00:00:00",
        "2011-04-01T00:00:00",
        "2011-05-01T00:00:00",
        "2011-06-01T00:00:00",
        "2011-07-01T00:00:00",
        "2011-08-01T00:00:00",
        "2011-09-01T00:00:00",
        "2011-10-01T00:00:00",
        "2011-11-01T00:00:00",
        "2011-12-01T00:00:00",
        "2012-01-01T00:00:00",
        "2012-02-01T00:00:00",
        "2012-03-01T00:00:00",
        "2012-04-01T00:00:00",
        "2012-05-01T00:00:00",
        "2012-06-01T00:00:00",
        "2012-07-01T00:00:00",
        "2012-08-01T00:00:00",
        "2012-09-01T00:00:00",
        "2012-10-01T00:00:00",
        "2012-11-01T00:00:00",
        "2012-12-01T00:00:00",
        "2013-01-01T00:00:00",
        "2013-02-01T00:00:00",
        "2013-03-01T00:00:00",
        "2013-04-01T00:00:00",
        "2013-05-01T00:00:00",
        "2013-06-01T00:00:00",
        "2013-07-01T00:00:00",
        "2013-08-01T00:00:00",
        "2013-09-01T00:00:00",
        "2013-10-01T00:00:00",
        "2013-11-01T00:00:00",
        "2013-12-01T00:00:00",
        "2014-01-01T00:00:00",
        "2014-02-01T00:00:00",
        "2014-03-01T00:00:00",
        "2014-04-01T00:00:00",
        "2014-05-01T00:00:00",
        "2014-06-01T00:00:00",
        "2014-07-01T00:00:00",
        "2014-08-01T00:00:00",
        "2014-09-01T00:00:00",
        "2014-10-01T00:00:00",
        "2014-11-01T00:00:00",
        "2014-12-01T00:00:00",
        "2015-01-01T00:00:00",
        "2015-02-01T00:00:00",
        "2015-03-01T00:00:00",
        "2015-04-01T00:00:00",
        "2015-05-01T00:00:00",
        "2015-06-01T00:00:00",
        "2015-07-01T00:00:00",
        "2015-08-01T00:00:00",
        "2015-09-01T00:00:00",
        "2015-10-01T00:00:00",
        "2015-11-01T00:00:00",
        "2015-12-01T00:00:00",
        "2016-01-01T00:00:00",
        "2016-02-01T00:00:00",
        "2016-03-01T00:00:00",
        "2016-04-01T00:00:00",
        "2016-05-01T00:00:00",
        "2016-06-01T00:00:00",
        "2016-07-01T00:00:00",
        "2016-08-01T00:00:00",
        "2016-09-01T00:00:00",
        "2016-10-01T00:00:00",
        "2016-11-01T00:00:00",
        "2016-12-01T00:00:00",
        "2017-01-01T00:00:00",
        "2017-02-01T00:00:00",
        "2017-03-01T00:00:00",
        "2017-04-01T00:00:00",
        "2017-05-01T00:00:00",
        "2017-06-01T00:00:00",
        "2017-07-01T00:00:00",
        "2017-08-01T00:00:00",
        "2017-09-01T00:00:00",
        "2017-10-01T00:00:00",
        "2017-11-01T00:00:00",
        "2017-12-01T00:00:00",
        "2018-01-01T00:00:00",
        "2018-02-01T00:00:00",
        "2018-03-01T00:00:00",
        "2018-04-01T00:00:00",
        "2018-05-01T00:00:00",
        "2018-06-01T00:00:00",
        "2018-07-01T00:00:00",
        "2018-08-01T00:00:00",
        "2018-09-01T00:00:00",
        "2018-10-01T00:00:00",
        "2018-11-01T00:00:00",
        "2018-12-01T00:00:00",
        "2019-01-01T00:00:00",
        "2019-02-01T00:00:00",
        "2019-03-01T00:00:00",
        "2019-04-01T00:00:00",
        "2019-05-01T00:00:00",
        "2019-06-01T00:00:00",
        "2019-07-01T00:00:00",
        "2019-08-01T00:00:00",
        "2019-09-01T00:00:00",
        "2019-10-01T00:00:00",
        "2019-11-01T00:00:00",
        "2019-12-01T00:00:00",
        "2020-01-01T00:00:00",
        "2020-02-01T00:00:00",
        "2020-03-01T00:00:00",
        "2020-04-01T00:00:00",
        "2020-05-01T00:00:00",
        "2020-06-01T00:00:00",
        "2020-07-01T00:00:00",
        "2020-08-01T00:00:00",
        "2020-09-01T00:00:00",
        "2020-10-01T00:00:00",
        "2020-11-01T00:00:00",
        "2020-12-01T00:00:00",
        "2021-01-01T00:00:00",
        "2021-02-01T00:00:00",
        "2021-03-01T00:00:00",
        "2021-04-01T00:00:00",
        "2021-05-01T00:00:00",
        "2021-06-01T00:00:00",
        "2021-07-01T00:00:00",
        "2021-08-01T00:00:00",
        "2021-09-01T00:00:00",
        "2021-10-01T00:00:00",
        "2021-11-01T00:00:00",
        "2021-12-01T00:00:00",
        "2022-01-01T00:00:00",
        "2022-02-01T00:00:00",
        "2022-03-01T00:00:00",
        "2022-04-01T00:00:00",
        "2022-05-01T00:00:00",
        "2022-06-01T00:00:00",
        "2022-07-01T00:00:00",
        "2022-08-01T00:00:00",
        "2022-09-01T00:00:00",
        "2022-10-01T00:00:00",
        "2022-11-01T00:00:00",
        "2022-12-01T00:00:00",
        "2023-01-01T00:00:00",
        "2023-02-01T00:00:00",
        "2023-03-01T00:00:00",
        "2023-04-01T00:00:00"
      ],
      y: [
        0, 0, 0, 1061.5665998810446, 2694.9759026471284, 3862.015626617624,
        4472.615526608353, 5085.735281281148, 4245.421936945441, 2569.926982406353,
        1124.982491611198, 954.4396199106413, 2416.1779542488043, 2041.742705514652,
        3174.306414058678, 3268.8009646361, 4180.196337549557, 5766.887729106148,
        2404.086170368972, 3062.110628657731, 5197.638076059825, 7014.642087933726,
        6591.294321598146, 6392.192645732387, 6691.8381473790205, 9710.171375831183,
        12193.532147431799, 10337.25724238309, 8396.127187896114, 11247.571662596602,
        12562.960166131774, 11973.971876960179, 12822.813752018967, 13118.46226612345,
        12559.229103103606, 14674.931302833891, 13586.93600151029, 11995.909634384245,
        12035.479608055686, 11373.977154851296, 11635.494484748782, 15933.932982962564,
        16169.08942168523, 16776.142308184186, 9559.582992469534, 7942.25175134637,
        12541.324872467883, 13393.430243534076, 15237.982140428445, 18915.717492081472,
        16793.275154733557, 13521.551050401831, 14094.293234730569, 13984.767194252783,
        16940.301638243873, 18548.539343633343, 12809.66165334676, 17002.868330826335,
        16808.96673101634, 12199.211293604349, 15045.420312978946, 14576.007383634342,
        14838.039791239109, 14544.325150572144, 18168.99952353983, 18151.01264460914,
        18631.762213412207, 20729.14065782823, 22317.685062823824, 21200.00974379899,
        27364.72866564763, 26523.48289388123, 24167.321328161, 23316.19575021501,
        27953.25412436741, 28187.592489966933, 29220.538130298373, 31957.245022035204,
        28946.237912445205, 26099.882059433123, 28359.931197952716, 28346.781805891344,
        26104.043473583148, 18567.239317852876, 25295.734448998322, 26027.667652549666,
        25658.345868324734, 27321.632654718334, 26163.012307056113, 24577.575864609913,
        26606.968304910544, 27612.119661971374, 26156.126109118373, 27552.154699048577,
        28158.23929552334, 23066.977114928784, 35205.44191173335, 37776.24651270113,
        40493.03622866262, 43241.78222203276, 45297.49001278105, 49430.53584804547,
        55954.124976401734, 54409.860724573264, 59672.05755878591, 67565.71481930328,
        77001.08196767901, 76138.67388249113, 81083.42924885459, 78399.93287605143,
        87079.78344406757, 91860.43631919876, 95651.96382498628, 95467.83429857845,
        100168.35525349781, 79331.00700132558, 108521.17550814923, 112028.29355630389,
        119014.02904058086, 118367.08409372182, 114181.8964274767, 125169.36211272632,
        119983.2325459126, 115759.78287567932, 132615.92365849492, 137620.4424902714,
        134792.56602269626, 134518.58528158566, 137253.0782623288, 140750.13042480935,
        134635.59206773562, 134066.41075661284, 134528.3493801523, 142685.16770499028,
        145252.86173346243, 143608.3723592782, 149859.53414781057, 147201.9869230098,
        150808.61284165038, 118134.22489094055, 146943.15440605787, 152345.29181662982,
        161235.67610800194, 166217.96404673505, 167419.6796320093, 177405.03010089567,
        173714.68126612547, 179944.7648395013, 181466.67274254144, 185939.3732707295,
        189377.6351393871, 28643.784978715896, 132718.16332095736, 183389.0556837812,
        189665.4629015004, 198418.28708073762, 202235.3695843254, 199187.50655507308,
        200463.419139663, 201081.56168738232, 203067.05502665482, 200831.53174550016,
        150754.68294422422, 98848.34385866621, 132534.09686824837, 198145.93774930405,
        212181.49449576845, 217775.3655108762, 217089.161850591, 213863.7383844106,
        219596.8706165592, 213293.8912470237, 219308.7731600098, 217037.82420968637,
        211250.2254945149, 214475.2659752631, 218545.67574937598, 220647.5566409065,
        225072.49995432596, 228144.1067270782, 222115.43965847982, 222760.66117089332,
        208519.24704793358, 191480.38755960474, 209415.7519530372, 215122.98501281912,
        160654.68923709434, 186182.13596253507, 213685.9438256457, 216515.962575692,
        204580.60050221157, 222313.57372097418, 218013.0769902216, 206306.12715792994,
        226593.64907301965, 220024.29859871982, 215282.38712435376, 202350.1840157038,
        191260.0843244603, 195599.72067776264, 208017.9976087095, 204180.92204757835,
        183416.4601184277, 205871.77228200456, 152853.5730307885, 223629.76947118907,
        234336.09204642117, 236992.41404649828, 243886.1844530148, 247148.76142105192,
        247327.37608289803, 241405.5633032291, 216616.323705242, 241888.6121379317,
        204133.19773213085, 240971.57720302898, 246712.38132357333, 247139.30316950878,
        204125.30119503642, 233585.6571278935, 239842.40855111156, 247231.84760698382,
        246102.9933085814, 223255.03784856922, 113178.2560359113, 212506.83588397584,
        235110.46017120656, 243837.3293058964, 252357.87755698612, 258001.55224131065,
        254213.15778255495, 256539.1649970262, 261065.51776836836, 260716.4128204035,
        252723.53323684272
      ],
      label: "Bitumen",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        "2003-10-01T00:00:00",
        "2003-11-01T00:00:00",
        "2003-12-01T00:00:00",
        "2004-01-01T00:00:00",
        "2004-02-01T00:00:00",
        "2004-03-01T00:00:00",
        "2004-04-01T00:00:00",
        "2004-05-01T00:00:00",
        "2004-06-01T00:00:00",
        "2004-07-01T00:00:00",
        "2004-08-01T00:00:00",
        "2004-09-01T00:00:00",
        "2004-10-01T00:00:00",
        "2004-11-01T00:00:00",
        "2004-12-01T00:00:00",
        "2005-01-01T00:00:00",
        "2005-02-01T00:00:00",
        "2005-03-01T00:00:00",
        "2005-04-01T00:00:00",
        "2005-05-01T00:00:00",
        "2005-06-01T00:00:00",
        "2005-07-01T00:00:00",
        "2005-08-01T00:00:00",
        "2005-09-01T00:00:00",
        "2005-10-01T00:00:00",
        "2005-11-01T00:00:00",
        "2005-12-01T00:00:00",
        "2006-01-01T00:00:00",
        "2006-02-01T00:00:00",
        "2006-03-01T00:00:00",
        "2006-04-01T00:00:00",
        "2006-05-01T00:00:00",
        "2006-06-01T00:00:00",
        "2006-07-01T00:00:00",
        "2006-08-01T00:00:00",
        "2006-09-01T00:00:00",
        "2006-10-01T00:00:00",
        "2006-11-01T00:00:00",
        "2006-12-01T00:00:00",
        "2007-01-01T00:00:00",
        "2007-02-01T00:00:00",
        "2007-03-01T00:00:00",
        "2007-04-01T00:00:00",
        "2007-05-01T00:00:00",
        "2007-06-01T00:00:00",
        "2007-07-01T00:00:00",
        "2007-08-01T00:00:00",
        "2007-09-01T00:00:00",
        "2007-10-01T00:00:00",
        "2007-11-01T00:00:00",
        "2007-12-01T00:00:00",
        "2008-01-01T00:00:00",
        "2008-02-01T00:00:00",
        "2008-03-01T00:00:00",
        "2008-04-01T00:00:00",
        "2008-05-01T00:00:00",
        "2008-06-01T00:00:00",
        "2008-07-01T00:00:00",
        "2008-08-01T00:00:00",
        "2008-09-01T00:00:00",
        "2008-10-01T00:00:00",
        "2008-11-01T00:00:00",
        "2008-12-01T00:00:00",
        "2009-01-01T00:00:00",
        "2009-02-01T00:00:00",
        "2009-03-01T00:00:00",
        "2009-04-01T00:00:00",
        "2009-05-01T00:00:00",
        "2009-06-01T00:00:00",
        "2009-07-01T00:00:00",
        "2009-08-01T00:00:00",
        "2009-09-01T00:00:00",
        "2009-10-01T00:00:00",
        "2009-11-01T00:00:00",
        "2009-12-01T00:00:00",
        "2010-01-01T00:00:00",
        "2010-02-01T00:00:00",
        "2010-03-01T00:00:00",
        "2010-04-01T00:00:00",
        "2010-05-01T00:00:00",
        "2010-06-01T00:00:00",
        "2010-07-01T00:00:00",
        "2010-08-01T00:00:00",
        "2010-09-01T00:00:00",
        "2010-10-01T00:00:00",
        "2010-11-01T00:00:00",
        "2010-12-01T00:00:00",
        "2011-01-01T00:00:00",
        "2011-02-01T00:00:00",
        "2011-03-01T00:00:00",
        "2011-04-01T00:00:00",
        "2011-05-01T00:00:00",
        "2011-06-01T00:00:00",
        "2011-07-01T00:00:00",
        "2011-08-01T00:00:00",
        "2011-09-01T00:00:00",
        "2011-10-01T00:00:00",
        "2011-11-01T00:00:00",
        "2011-12-01T00:00:00",
        "2012-01-01T00:00:00",
        "2012-02-01T00:00:00",
        "2012-03-01T00:00:00",
        "2012-04-01T00:00:00",
        "2012-05-01T00:00:00",
        "2012-06-01T00:00:00",
        "2012-07-01T00:00:00",
        "2012-08-01T00:00:00",
        "2012-09-01T00:00:00",
        "2012-10-01T00:00:00",
        "2012-11-01T00:00:00",
        "2012-12-01T00:00:00",
        "2013-01-01T00:00:00",
        "2013-02-01T00:00:00",
        "2013-03-01T00:00:00",
        "2013-04-01T00:00:00",
        "2013-05-01T00:00:00",
        "2013-06-01T00:00:00",
        "2013-07-01T00:00:00",
        "2013-08-01T00:00:00",
        "2013-09-01T00:00:00",
        "2013-10-01T00:00:00",
        "2013-11-01T00:00:00",
        "2013-12-01T00:00:00",
        "2014-01-01T00:00:00",
        "2014-02-01T00:00:00",
        "2014-03-01T00:00:00",
        "2014-04-01T00:00:00",
        "2014-05-01T00:00:00",
        "2014-06-01T00:00:00",
        "2014-07-01T00:00:00",
        "2014-08-01T00:00:00",
        "2014-09-01T00:00:00",
        "2014-10-01T00:00:00",
        "2014-11-01T00:00:00",
        "2014-12-01T00:00:00",
        "2015-01-01T00:00:00",
        "2015-02-01T00:00:00",
        "2015-03-01T00:00:00",
        "2015-04-01T00:00:00",
        "2015-05-01T00:00:00",
        "2015-06-01T00:00:00",
        "2015-07-01T00:00:00",
        "2015-08-01T00:00:00",
        "2015-09-01T00:00:00",
        "2015-10-01T00:00:00",
        "2015-11-01T00:00:00",
        "2015-12-01T00:00:00",
        "2016-01-01T00:00:00",
        "2016-02-01T00:00:00",
        "2016-03-01T00:00:00",
        "2016-04-01T00:00:00",
        "2016-05-01T00:00:00",
        "2016-06-01T00:00:00",
        "2016-07-01T00:00:00",
        "2016-08-01T00:00:00",
        "2016-09-01T00:00:00",
        "2016-10-01T00:00:00",
        "2016-11-01T00:00:00",
        "2016-12-01T00:00:00",
        "2017-01-01T00:00:00",
        "2017-02-01T00:00:00",
        "2017-03-01T00:00:00",
        "2017-04-01T00:00:00",
        "2017-05-01T00:00:00",
        "2017-06-01T00:00:00",
        "2017-07-01T00:00:00",
        "2017-08-01T00:00:00",
        "2017-09-01T00:00:00",
        "2017-10-01T00:00:00",
        "2017-11-01T00:00:00",
        "2017-12-01T00:00:00",
        "2018-01-01T00:00:00",
        "2018-02-01T00:00:00",
        "2018-03-01T00:00:00",
        "2018-04-01T00:00:00",
        "2018-05-01T00:00:00",
        "2018-06-01T00:00:00",
        "2018-07-01T00:00:00",
        "2018-08-01T00:00:00",
        "2018-09-01T00:00:00",
        "2018-10-01T00:00:00",
        "2018-11-01T00:00:00",
        "2018-12-01T00:00:00",
        "2019-01-01T00:00:00",
        "2019-02-01T00:00:00",
        "2019-03-01T00:00:00",
        "2019-04-01T00:00:00",
        "2019-05-01T00:00:00",
        "2019-06-01T00:00:00",
        "2019-07-01T00:00:00",
        "2019-08-01T00:00:00",
        "2019-09-01T00:00:00",
        "2019-10-01T00:00:00",
        "2019-11-01T00:00:00",
        "2019-12-01T00:00:00",
        "2020-01-01T00:00:00",
        "2020-02-01T00:00:00",
        "2020-03-01T00:00:00",
        "2020-04-01T00:00:00",
        "2020-05-01T00:00:00",
        "2020-06-01T00:00:00",
        "2020-07-01T00:00:00",
        "2020-08-01T00:00:00",
        "2020-09-01T00:00:00",
        "2020-10-01T00:00:00",
        "2020-11-01T00:00:00",
        "2020-12-01T00:00:00",
        "2021-01-01T00:00:00",
        "2021-02-01T00:00:00",
        "2021-03-01T00:00:00",
        "2021-04-01T00:00:00",
        "2021-05-01T00:00:00",
        "2021-06-01T00:00:00",
        "2021-07-01T00:00:00",
        "2021-08-01T00:00:00",
        "2021-09-01T00:00:00",
        "2021-10-01T00:00:00",
        "2021-11-01T00:00:00",
        "2021-12-01T00:00:00",
        "2022-01-01T00:00:00",
        "2022-02-01T00:00:00",
        "2022-03-01T00:00:00",
        "2022-04-01T00:00:00",
        "2022-05-01T00:00:00",
        "2022-06-01T00:00:00",
        "2022-07-01T00:00:00",
        "2022-08-01T00:00:00",
        "2022-09-01T00:00:00",
        "2022-10-01T00:00:00",
        "2022-11-01T00:00:00",
        "2022-12-01T00:00:00",
        "2023-01-01T00:00:00",
        "2023-02-01T00:00:00",
        "2023-03-01T00:00:00",
        "2023-04-01T00:00:00"
      ],
      y: [
        10671.1244547025, 11413.693285507521, 10016.036669163515, 10775.362804265349,
        14958.195812420392, 15707.77534859456, 13808.7596752879, 13711.656628523757,
        11353.11392192646, 11951.926531755651, 2383.5971263912825, 4388.1232563505555,
        10291.441087570365, 8769.492165376629, 12041.569482421577, 12948.920163777022,
        18050.356446146518, 20524.074288347434, 6868.479013277957, 16462.29048294734,
        14426.992217817216, 21985.441140349274, 27673.749190554205, 29749.396933274595,
        31937.49352950849, 36011.18033268307, 39884.35328579173, 31151.73733965869,
        34798.13371450147, 41384.512637504304, 42573.259496989915, 44030.44125199697,
        48232.20690957146, 44245.170222138324, 49102.65701218584, 51521.13355568142,
        46313.007362658114, 49311.87464602606, 51057.42548531223, 41256.50347833325,
        50130.44220897723, 49526.57316679557, 54040.672917584394, 57907.37707081103,
        35559.582992469535, 39548.35461744425, 55665.369523426736, 49179.24191890588,
        50825.48247029664, 50930.33792712856, 45936.69372600844, 49236.776345303995,
        50565.85922632416, 52000.117737571076, 52246.59660604535, 48402.99134628852,
        36431.14551213475, 55509.30634301015, 63101.12642376188, 50983.17706039058,
        56190.96628036563, 46039.68703458981, 48936.07864869748, 56876.950028520914,
        69080.99373409928, 61319.71637425122, 62453.44324879912, 72422.61292149543,
        85192.89744719233, 85844.16823480933, 90900.79391662151, 82981.49896167644,
        67226.95540790995, 66381.78843370461, 76881.9843246634, 81952.04832924299,
        85616.16188858026, 83081.2937329103, 82077.63304176368, 92181.27120037514,
        92500.97539487763, 89840.38439287, 89309.50974481399, 62285.57044868165,
        81538.18046733696, 85825.0162565813, 85425.44877299183, 84882.19138039181,
        80014.58597409136, 84137.56214209303, 91197.36538501878, 99299.806544991,
        102732.69985106874, 105859.14932574944, 116110.33634376123, 87643.92842908985,
        138355.1046341914, 149093.07154993393, 154384.40504972386, 163860.51345760742,
        167037.88090851682, 176718.47119793922, 182721.2258511107, 178733.54972686918,
        189919.68200029372, 194372.75309010377, 211252.82925413246, 228883.2253057286,
        244986.20643456126, 279477.44006041164, 286629.0109435042, 294680.63683846203,
        290616.56643023464, 303118.01567533653, 319419.37784490176, 252688.6592329601,
        346440.2072451914, 331970.07435736596, 358425.2193877239, 346818.97510121024,
        331478.39820064517, 346106.6012208168, 324276.9999654906, 308829.97273766244,
        339734.23860765767, 345611.88012691285, 346599.1441696557, 355516.30969853105,
        349510.8971535252, 353271.17601563886, 343811.7335639365, 315315.8811067063,
        327750.8672996077, 358111.90820800036, 368441.68234809296, 365659.29994864203,
        380986.0927569065, 378981.91510309145, 391312.3361231723, 337450.12900383875,
        390765.7164432698, 407708.74566857674, 435760.777555159, 438505.2755228325,
        463343.016233575, 480408.0506785811, 461072.42687583383, 468269.9884901378,
        463596.02811393986, 487427.0889791545, 492051.37079688726, 96892.82430103459,
        338355.7149749336, 504368.8555705096, 511102.47025603853, 530775.2186772388,
        554532.7746888582, 537902.3346548361, 553094.5290598655, 572274.1620840365,
        581659.1826460623, 583857.8948116299, 427275.5438088646, 278756.24465867283,
        365196.2116921528, 550014.8186942904, 586435.2514407628, 588139.2612170413,
        581245.8258986113, 563874.79285969, 564115.2732019142, 556491.7045761348,
        557765.6265451245, 572576.7273421151, 563381.5786713656, 586727.9714019499,
        575427.0761227532, 580070.0741543703, 581560.510006678, 606297.3590921484,
        594172.9037129966, 594531.7894825161, 574648.0154114423, 564366.3384224386,
        591354.2031877886, 586357.7679392475, 405393.9966018503, 528796.8438211114,
        535506.7858116754, 546324.2330310728, 503769.8352282993, 586805.9278837078,
        590113.1295661371, 531543.93472196, 567161.4892584764, 597417.446678075,
        572640.8789186683, 529927.8552883453, 520671.0506995574, 531591.9134588254,
        568436.9139764645, 559619.4234512943, 470356.33884872956, 581118.2220544124,
        403480.3632001072, 580108.3632244667, 630766.3903893662, 641790.240367341,
        606379.5544647916, 620869.8370552617, 600570.4277054095, 602609.3081699725,
        532343.4438780857, 625236.1754777004, 548920.1231778588, 636406.8969857154,
        636569.9594617366, 620948.859941686, 510947.3205567766, 614370.5201361695,
        634513.6510333789, 643320.0777067966, 639243.9955530383, 537014.5608896089,
        263943.4900258007, 498802.9133958967, 624185.7127487463, 632278.732196421,
        652056.04308383, 649391.6053111826, 632118.241000688, 645875.9573789989,
        640906.8250671992, 613216.8137371326, 590231.8712898287
      ],
      label: "SteamInjection",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        "2003-10-01T00:00:00",
        "2003-11-01T00:00:00",
        "2003-12-01T00:00:00",
        "2004-01-01T00:00:00",
        "2004-02-01T00:00:00",
        "2004-03-01T00:00:00",
        "2004-04-01T00:00:00",
        "2004-05-01T00:00:00",
        "2004-06-01T00:00:00",
        "2004-07-01T00:00:00",
        "2004-08-01T00:00:00",
        "2004-09-01T00:00:00",
        "2004-10-01T00:00:00",
        "2004-11-01T00:00:00",
        "2004-12-01T00:00:00",
        "2005-01-01T00:00:00",
        "2005-02-01T00:00:00",
        "2005-03-01T00:00:00",
        "2005-04-01T00:00:00",
        "2005-05-01T00:00:00",
        "2005-06-01T00:00:00",
        "2005-07-01T00:00:00",
        "2005-08-01T00:00:00",
        "2005-09-01T00:00:00",
        "2005-10-01T00:00:00",
        "2005-11-01T00:00:00",
        "2005-12-01T00:00:00",
        "2006-01-01T00:00:00",
        "2006-02-01T00:00:00",
        "2006-03-01T00:00:00",
        "2006-04-01T00:00:00",
        "2006-05-01T00:00:00",
        "2006-06-01T00:00:00",
        "2006-07-01T00:00:00",
        "2006-08-01T00:00:00",
        "2006-09-01T00:00:00",
        "2006-10-01T00:00:00",
        "2006-11-01T00:00:00",
        "2006-12-01T00:00:00",
        "2007-01-01T00:00:00",
        "2007-02-01T00:00:00",
        "2007-03-01T00:00:00",
        "2007-04-01T00:00:00",
        "2007-05-01T00:00:00",
        "2007-06-01T00:00:00",
        "2007-07-01T00:00:00",
        "2007-08-01T00:00:00",
        "2007-09-01T00:00:00",
        "2007-10-01T00:00:00",
        "2007-11-01T00:00:00",
        "2007-12-01T00:00:00",
        "2008-01-01T00:00:00",
        "2008-02-01T00:00:00",
        "2008-03-01T00:00:00",
        "2008-04-01T00:00:00",
        "2008-05-01T00:00:00",
        "2008-06-01T00:00:00",
        "2008-07-01T00:00:00",
        "2008-08-01T00:00:00",
        "2008-09-01T00:00:00",
        "2008-10-01T00:00:00",
        "2008-11-01T00:00:00",
        "2008-12-01T00:00:00",
        "2009-01-01T00:00:00",
        "2009-02-01T00:00:00",
        "2009-03-01T00:00:00",
        "2009-04-01T00:00:00",
        "2009-05-01T00:00:00",
        "2009-06-01T00:00:00",
        "2009-07-01T00:00:00",
        "2009-08-01T00:00:00",
        "2009-09-01T00:00:00",
        "2009-10-01T00:00:00",
        "2009-11-01T00:00:00",
        "2009-12-01T00:00:00",
        "2010-01-01T00:00:00",
        "2010-02-01T00:00:00",
        "2010-03-01T00:00:00",
        "2010-04-01T00:00:00",
        "2010-05-01T00:00:00",
        "2010-06-01T00:00:00",
        "2010-07-01T00:00:00",
        "2010-08-01T00:00:00",
        "2010-09-01T00:00:00",
        "2010-10-01T00:00:00",
        "2010-11-01T00:00:00",
        "2010-12-01T00:00:00",
        "2011-01-01T00:00:00",
        "2011-02-01T00:00:00",
        "2011-03-01T00:00:00",
        "2011-04-01T00:00:00",
        "2011-05-01T00:00:00",
        "2011-06-01T00:00:00",
        "2011-07-01T00:00:00",
        "2011-08-01T00:00:00",
        "2011-09-01T00:00:00",
        "2011-10-01T00:00:00",
        "2011-11-01T00:00:00",
        "2011-12-01T00:00:00",
        "2012-01-01T00:00:00",
        "2012-02-01T00:00:00",
        "2012-03-01T00:00:00",
        "2012-04-01T00:00:00",
        "2012-05-01T00:00:00",
        "2012-06-01T00:00:00",
        "2012-07-01T00:00:00",
        "2012-08-01T00:00:00",
        "2012-09-01T00:00:00",
        "2012-10-01T00:00:00",
        "2012-11-01T00:00:00",
        "2012-12-01T00:00:00",
        "2013-01-01T00:00:00",
        "2013-02-01T00:00:00",
        "2013-03-01T00:00:00",
        "2013-04-01T00:00:00",
        "2013-05-01T00:00:00",
        "2013-06-01T00:00:00",
        "2013-07-01T00:00:00",
        "2013-08-01T00:00:00",
        "2013-09-01T00:00:00",
        "2013-10-01T00:00:00",
        "2013-11-01T00:00:00",
        "2013-12-01T00:00:00",
        "2014-01-01T00:00:00",
        "2014-02-01T00:00:00",
        "2014-03-01T00:00:00",
        "2014-04-01T00:00:00",
        "2014-05-01T00:00:00",
        "2014-06-01T00:00:00",
        "2014-07-01T00:00:00",
        "2014-08-01T00:00:00",
        "2014-09-01T00:00:00",
        "2014-10-01T00:00:00",
        "2014-11-01T00:00:00",
        "2014-12-01T00:00:00",
        "2015-01-01T00:00:00",
        "2015-02-01T00:00:00",
        "2015-03-01T00:00:00",
        "2015-04-01T00:00:00",
        "2015-05-01T00:00:00",
        "2015-06-01T00:00:00",
        "2015-07-01T00:00:00",
        "2015-08-01T00:00:00",
        "2015-09-01T00:00:00",
        "2015-10-01T00:00:00",
        "2015-11-01T00:00:00",
        "2015-12-01T00:00:00",
        "2016-01-01T00:00:00",
        "2016-02-01T00:00:00",
        "2016-03-01T00:00:00",
        "2016-04-01T00:00:00",
        "2016-05-01T00:00:00",
        "2016-06-01T00:00:00",
        "2016-07-01T00:00:00",
        "2016-08-01T00:00:00",
        "2016-09-01T00:00:00",
        "2016-10-01T00:00:00",
        "2016-11-01T00:00:00",
        "2016-12-01T00:00:00",
        "2017-01-01T00:00:00",
        "2017-02-01T00:00:00",
        "2017-03-01T00:00:00",
        "2017-04-01T00:00:00",
        "2017-05-01T00:00:00",
        "2017-06-01T00:00:00",
        "2017-07-01T00:00:00",
        "2017-08-01T00:00:00",
        "2017-09-01T00:00:00",
        "2017-10-01T00:00:00",
        "2017-11-01T00:00:00",
        "2017-12-01T00:00:00",
        "2018-01-01T00:00:00",
        "2018-02-01T00:00:00",
        "2018-03-01T00:00:00",
        "2018-04-01T00:00:00",
        "2018-05-01T00:00:00",
        "2018-06-01T00:00:00",
        "2018-07-01T00:00:00",
        "2018-08-01T00:00:00",
        "2018-09-01T00:00:00",
        "2018-10-01T00:00:00",
        "2018-11-01T00:00:00",
        "2018-12-01T00:00:00",
        "2019-01-01T00:00:00",
        "2019-02-01T00:00:00",
        "2019-03-01T00:00:00",
        "2019-04-01T00:00:00",
        "2019-05-01T00:00:00",
        "2019-06-01T00:00:00",
        "2019-07-01T00:00:00",
        "2019-08-01T00:00:00",
        "2019-09-01T00:00:00",
        "2019-10-01T00:00:00",
        "2019-11-01T00:00:00",
        "2019-12-01T00:00:00",
        "2020-01-01T00:00:00",
        "2020-02-01T00:00:00",
        "2020-03-01T00:00:00",
        "2020-04-01T00:00:00",
        "2020-05-01T00:00:00",
        "2020-06-01T00:00:00",
        "2020-07-01T00:00:00",
        "2020-08-01T00:00:00",
        "2020-09-01T00:00:00",
        "2020-10-01T00:00:00",
        "2020-11-01T00:00:00",
        "2020-12-01T00:00:00",
        "2021-01-01T00:00:00",
        "2021-02-01T00:00:00",
        "2021-03-01T00:00:00",
        "2021-04-01T00:00:00",
        "2021-05-01T00:00:00",
        "2021-06-01T00:00:00",
        "2021-07-01T00:00:00",
        "2021-08-01T00:00:00",
        "2021-09-01T00:00:00",
        "2021-10-01T00:00:00",
        "2021-11-01T00:00:00",
        "2021-12-01T00:00:00",
        "2022-01-01T00:00:00",
        "2022-02-01T00:00:00",
        "2022-03-01T00:00:00",
        "2022-04-01T00:00:00",
        "2022-05-01T00:00:00",
        "2022-06-01T00:00:00",
        "2022-07-01T00:00:00",
        "2022-08-01T00:00:00",
        "2022-09-01T00:00:00",
        "2022-10-01T00:00:00",
        "2022-11-01T00:00:00",
        "2022-12-01T00:00:00",
        "2023-01-01T00:00:00",
        "2023-02-01T00:00:00",
        "2023-03-01T00:00:00",
        "2023-04-01T00:00:00"
      ],
      y: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 51.93915823333334, 100.29841122580612,
        136.36462074193517, 213.84972324999964, 206.43611351612873, 51.8208458,
        138.42554699999968, 95.47813369999967, 198.53589619354807, 169.22494496774164,
        198.173325833333, 176.55268277419322, 229.052870933333, 219.14515877419322,
        220.5191096129029, 265.69592171428536, 276.507606290322, 290.220398966666,
        297.0023729677413, 281.11034159999934, 28.165992193548387, 0, 0, 0, 0, 0, 0, 0, 0,
        9.9382444, 40.8750374516129, 29.223171033333333, 32.28784470967742,
        81.40658719354839, 94.7682591, 27.82250448387097, 0, 0, 0, 0, 10.533623096774194,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15.6172412, 85.52843970967741, 121.7434939,
        292.53703274193487, 311.6578485806445, 358.74019964285645, 414.131681967741,
        492.53465996666563, 503.5529823870959, 536.5468851666657, 603.0499222903212,
        600.6455083225794, 597.5961007666652, 512.941646451612, 524.1240796666657,
        489.813473999999, 505.4994127419345, 541.532909142856, 556.6790814838697,
        583.9901709333319, 182.73546154838678, 551.217626899999, 543.7410444193539,
        737.6971044838696, 883.2023148333319, 928.7907669677403, 778.850748633332,
        827.5763885161277, 929.4777423870954, 888.5671717241365, 922.0355086774177,
        897.873056566665, 141.86042409677387, 565.6517437666657, 931.309676838708,
        922.0355086774177, 937.0344719999987, 747.7727439677407, 803.8146720666653,
        490.61494532258007, 359.5171361290316, 382.9519940357136, 738.4985758064504,
        598.7792250999987, 685.8304603225794, 689.9981111999987, 694.0741653548374,
        8341.599029516114, 15618.424324333302, 14982.361416612875, 17055.09220229997,
        16591.14335283868, 11288.952570354815, 15883.190648357117, 12768.926615451588,
        12217.770053033311, 10322.378155322562, 5778.970806166658, 2181.2614523548364,
        1374.1798305161276, 1163.7210942666652, 2196.9473910967713, 3516.245518666661,
        4827.0327840967675, 4615.215363129025, 6534.26892967856, 1227.7395702903216,
        1041.1494133333326, 342.1137588387097, 813.1613542999992, 2601.0034335806417,
        4403.283446258057, 6219.684620333323, 5938.4445167096665, 18185.21256549996,
        6141.560249032248, 1998.8694785161272, 2560.933815586206, 2434.182902580645,
        3517.665267866666, 4869.62526009677, 6189.869887133329, 8303.357397838698,
        8846.983946354829, 8354.277542533322, 12240.528022064493, 7701.902785133324,
        6029.583255677412, 5443.593222967738, 6224.079082142853, 5915.888823774185,
        5425.334942933326, 5227.997437193545, 5132.7482952999935, 5976.915140193542,
        11374.023026451598, 10069.807825866652, 10569.231322677408, 11155.087776833321,
        6764.87594619354, 8098.4097310645075, 12079.403662249983, 7112.828996096768,
        3792.2684256333323, 3266.224631322577, 1061.6174642999993, 1529.4362752903226,
        1799.4176150967746, 3806.3476051999983, 2774.808214677418, 3309.6720100666653,
        2573.7534086129026, 2619.093786290321, 1961.6623991071424, 2308.9243844516104,
        3816.1675371666656
      ],
      label: "GasInjection",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    },
    {
      x: [
        "2003-10-01T00:00:00",
        "2003-11-01T00:00:00",
        "2003-12-01T00:00:00",
        "2004-01-01T00:00:00",
        "2004-02-01T00:00:00",
        "2004-03-01T00:00:00",
        "2004-04-01T00:00:00",
        "2004-05-01T00:00:00",
        "2004-06-01T00:00:00",
        "2004-07-01T00:00:00",
        "2004-08-01T00:00:00",
        "2004-09-01T00:00:00",
        "2004-10-01T00:00:00",
        "2004-11-01T00:00:00",
        "2004-12-01T00:00:00",
        "2005-01-01T00:00:00",
        "2005-02-01T00:00:00",
        "2005-03-01T00:00:00",
        "2005-04-01T00:00:00",
        "2005-05-01T00:00:00",
        "2005-06-01T00:00:00",
        "2005-07-01T00:00:00",
        "2005-08-01T00:00:00",
        "2005-09-01T00:00:00",
        "2005-10-01T00:00:00",
        "2005-11-01T00:00:00",
        "2005-12-01T00:00:00",
        "2006-01-01T00:00:00",
        "2006-02-01T00:00:00",
        "2006-03-01T00:00:00",
        "2006-04-01T00:00:00",
        "2006-05-01T00:00:00",
        "2006-06-01T00:00:00",
        "2006-07-01T00:00:00",
        "2006-08-01T00:00:00",
        "2006-09-01T00:00:00",
        "2006-10-01T00:00:00",
        "2006-11-01T00:00:00",
        "2006-12-01T00:00:00",
        "2007-01-01T00:00:00",
        "2007-02-01T00:00:00",
        "2007-03-01T00:00:00",
        "2007-04-01T00:00:00",
        "2007-05-01T00:00:00",
        "2007-06-01T00:00:00",
        "2007-07-01T00:00:00",
        "2007-08-01T00:00:00",
        "2007-09-01T00:00:00",
        "2007-10-01T00:00:00",
        "2007-11-01T00:00:00",
        "2007-12-01T00:00:00",
        "2008-01-01T00:00:00",
        "2008-02-01T00:00:00",
        "2008-03-01T00:00:00",
        "2008-04-01T00:00:00",
        "2008-05-01T00:00:00",
        "2008-06-01T00:00:00",
        "2008-07-01T00:00:00",
        "2008-08-01T00:00:00",
        "2008-09-01T00:00:00",
        "2008-10-01T00:00:00",
        "2008-11-01T00:00:00",
        "2008-12-01T00:00:00",
        "2009-01-01T00:00:00",
        "2009-02-01T00:00:00",
        "2009-03-01T00:00:00",
        "2009-04-01T00:00:00",
        "2009-05-01T00:00:00",
        "2009-06-01T00:00:00",
        "2009-07-01T00:00:00",
        "2009-08-01T00:00:00",
        "2009-09-01T00:00:00",
        "2009-10-01T00:00:00",
        "2009-11-01T00:00:00",
        "2009-12-01T00:00:00",
        "2010-01-01T00:00:00",
        "2010-02-01T00:00:00",
        "2010-03-01T00:00:00",
        "2010-04-01T00:00:00",
        "2010-05-01T00:00:00",
        "2010-06-01T00:00:00",
        "2010-07-01T00:00:00",
        "2010-08-01T00:00:00",
        "2010-09-01T00:00:00",
        "2010-10-01T00:00:00",
        "2010-11-01T00:00:00",
        "2010-12-01T00:00:00",
        "2011-01-01T00:00:00",
        "2011-02-01T00:00:00",
        "2011-03-01T00:00:00",
        "2011-04-01T00:00:00",
        "2011-05-01T00:00:00",
        "2011-06-01T00:00:00",
        "2011-07-01T00:00:00",
        "2011-08-01T00:00:00",
        "2011-09-01T00:00:00",
        "2011-10-01T00:00:00",
        "2011-11-01T00:00:00",
        "2011-12-01T00:00:00",
        "2012-01-01T00:00:00",
        "2012-02-01T00:00:00",
        "2012-03-01T00:00:00",
        "2012-04-01T00:00:00",
        "2012-05-01T00:00:00",
        "2012-06-01T00:00:00",
        "2012-07-01T00:00:00",
        "2012-08-01T00:00:00",
        "2012-09-01T00:00:00",
        "2012-10-01T00:00:00",
        "2012-11-01T00:00:00",
        "2012-12-01T00:00:00",
        "2013-01-01T00:00:00",
        "2013-02-01T00:00:00",
        "2013-03-01T00:00:00",
        "2013-04-01T00:00:00",
        "2013-05-01T00:00:00",
        "2013-06-01T00:00:00",
        "2013-07-01T00:00:00",
        "2013-08-01T00:00:00",
        "2013-09-01T00:00:00",
        "2013-10-01T00:00:00",
        "2013-11-01T00:00:00",
        "2013-12-01T00:00:00",
        "2014-01-01T00:00:00",
        "2014-02-01T00:00:00",
        "2014-03-01T00:00:00",
        "2014-04-01T00:00:00",
        "2014-05-01T00:00:00",
        "2014-06-01T00:00:00",
        "2014-07-01T00:00:00",
        "2014-08-01T00:00:00",
        "2014-09-01T00:00:00",
        "2014-10-01T00:00:00",
        "2014-11-01T00:00:00",
        "2014-12-01T00:00:00",
        "2015-01-01T00:00:00",
        "2015-02-01T00:00:00",
        "2015-03-01T00:00:00",
        "2015-04-01T00:00:00",
        "2015-05-01T00:00:00",
        "2015-06-01T00:00:00",
        "2015-07-01T00:00:00",
        "2015-08-01T00:00:00",
        "2015-09-01T00:00:00",
        "2015-10-01T00:00:00",
        "2015-11-01T00:00:00",
        "2015-12-01T00:00:00",
        "2016-01-01T00:00:00",
        "2016-02-01T00:00:00",
        "2016-03-01T00:00:00",
        "2016-04-01T00:00:00",
        "2016-05-01T00:00:00",
        "2016-06-01T00:00:00",
        "2016-07-01T00:00:00",
        "2016-08-01T00:00:00",
        "2016-09-01T00:00:00",
        "2016-10-01T00:00:00",
        "2016-11-01T00:00:00",
        "2016-12-01T00:00:00",
        "2017-01-01T00:00:00",
        "2017-02-01T00:00:00",
        "2017-03-01T00:00:00",
        "2017-04-01T00:00:00",
        "2017-05-01T00:00:00",
        "2017-06-01T00:00:00",
        "2017-07-01T00:00:00",
        "2017-08-01T00:00:00",
        "2017-09-01T00:00:00",
        "2017-10-01T00:00:00",
        "2017-11-01T00:00:00",
        "2017-12-01T00:00:00",
        "2018-01-01T00:00:00",
        "2018-02-01T00:00:00",
        "2018-03-01T00:00:00",
        "2018-04-01T00:00:00",
        "2018-05-01T00:00:00",
        "2018-06-01T00:00:00",
        "2018-07-01T00:00:00",
        "2018-08-01T00:00:00",
        "2018-09-01T00:00:00",
        "2018-10-01T00:00:00",
        "2018-11-01T00:00:00",
        "2018-12-01T00:00:00",
        "2019-01-01T00:00:00",
        "2019-02-01T00:00:00",
        "2019-03-01T00:00:00",
        "2019-04-01T00:00:00",
        "2019-05-01T00:00:00",
        "2019-06-01T00:00:00",
        "2019-07-01T00:00:00",
        "2019-08-01T00:00:00",
        "2019-09-01T00:00:00",
        "2019-10-01T00:00:00",
        "2019-11-01T00:00:00",
        "2019-12-01T00:00:00",
        "2020-01-01T00:00:00",
        "2020-02-01T00:00:00",
        "2020-03-01T00:00:00",
        "2020-04-01T00:00:00",
        "2020-05-01T00:00:00",
        "2020-06-01T00:00:00",
        "2020-07-01T00:00:00",
        "2020-08-01T00:00:00",
        "2020-09-01T00:00:00",
        "2020-10-01T00:00:00",
        "2020-11-01T00:00:00",
        "2020-12-01T00:00:00",
        "2021-01-01T00:00:00",
        "2021-02-01T00:00:00",
        "2021-03-01T00:00:00",
        "2021-04-01T00:00:00",
        "2021-05-01T00:00:00",
        "2021-06-01T00:00:00",
        "2021-07-01T00:00:00",
        "2021-08-01T00:00:00",
        "2021-09-01T00:00:00",
        "2021-10-01T00:00:00",
        "2021-11-01T00:00:00",
        "2021-12-01T00:00:00",
        "2022-01-01T00:00:00",
        "2022-02-01T00:00:00",
        "2022-03-01T00:00:00",
        "2022-04-01T00:00:00",
        "2022-05-01T00:00:00",
        "2022-06-01T00:00:00",
        "2022-07-01T00:00:00",
        "2022-08-01T00:00:00",
        "2022-09-01T00:00:00",
        "2022-10-01T00:00:00",
        "2022-11-01T00:00:00",
        "2022-12-01T00:00:00",
        "2023-01-01T00:00:00",
        "2023-02-01T00:00:00",
        "2023-03-01T00:00:00",
        "2023-04-01T00:00:00"
      ],
      y: [
        0, 0, 0, 3, 5, 5, 5, 4, 4, 2, 2, 1, 2, 2, 3, 2, 3, 3, 3, 3, 3, 4, 4, 4, 4, 5, 8,
        8, 7, 8, 8, 9, 9, 8, 9, 9, 9, 9, 9, 9, 9, 8, 8, 8, 8, 8, 8, 10, 8, 8, 8, 8, 8, 8,
        8, 8, 8, 11, 13, 13, 13, 13, 13, 12, 13, 13, 13, 16, 19, 19, 19, 19, 19, 19, 19,
        20, 20, 20, 19, 19, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 25, 25,
        30, 34, 34, 34, 38, 42, 44, 52, 52, 58, 64, 67, 66, 79, 66, 68, 71, 79, 94, 98,
        99, 101, 102, 99, 101, 101, 101, 100, 100, 100, 101, 103, 104, 104, 103, 103, 102,
        104, 105, 106, 106, 105, 130, 133, 137, 138, 138, 153, 170, 177, 179, 181, 187,
        193, 192, 196, 193, 187, 192, 191, 192, 191, 193, 196, 195, 194, 195, 195, 141,
        188, 197, 205, 204, 210, 209, 211, 210, 207, 214, 216, 225, 232, 236, 235, 236,
        234, 234, 244, 239, 241, 247, 246, 243, 243, 245, 246, 252, 255, 259, 260, 261,
        270, 273, 272, 276, 279, 279, 272, 271, 263, 274, 278, 279, 283, 287, 291, 290,
        292, 296, 296, 302, 307, 313, 314, 317, 319, 322, 326, 327, 241, 331, 329, 332,
        339, 351, 354, 357, 354, 355, 366
      ],
      label: "WellPairs",
      style: {
        thickness: 0,
        color: 0,
        dashed: false,
        hexColor: ""
      },
      isSecondaryYAxis: false,
      mode: 0,
      type: 0
    }
    // comment out pressure dev response https://turinganalytics.atlassian.net/browse/EVA-2468
    // {
    //   x: [
    //     "2003-10-01T00:00:00",
    //     "2003-11-01T00:00:00",
    //     "2003-12-01T00:00:00",
    //     "2004-01-01T00:00:00",
    //     "2004-02-01T00:00:00",
    //     "2004-03-01T00:00:00",
    //     "2004-04-01T00:00:00",
    //     "2004-05-01T00:00:00",
    //     "2004-06-01T00:00:00",
    //     "2004-07-01T00:00:00",
    //     "2004-08-01T00:00:00",
    //     "2004-09-01T00:00:00",
    //     "2004-10-01T00:00:00",
    //     "2004-11-01T00:00:00",
    //     "2004-12-01T00:00:00",
    //     "2005-01-01T00:00:00",
    //     "2005-02-01T00:00:00",
    //     "2005-03-01T00:00:00",
    //     "2005-04-01T00:00:00",
    //     "2005-05-01T00:00:00",
    //     "2005-06-01T00:00:00",
    //     "2005-07-01T00:00:00",
    //     "2005-08-01T00:00:00",
    //     "2005-09-01T00:00:00",
    //     "2005-10-01T00:00:00",
    //     "2005-11-01T00:00:00",
    //     "2005-12-01T00:00:00",
    //     "2006-01-01T00:00:00",
    //     "2006-02-01T00:00:00",
    //     "2006-03-01T00:00:00",
    //     "2006-04-01T00:00:00",
    //     "2006-05-01T00:00:00",
    //     "2006-06-01T00:00:00",
    //     "2006-07-01T00:00:00",
    //     "2006-08-01T00:00:00",
    //     "2006-09-01T00:00:00",
    //     "2006-10-01T00:00:00",
    //     "2006-11-01T00:00:00",
    //     "2006-12-01T00:00:00",
    //     "2007-01-01T00:00:00",
    //     "2007-02-01T00:00:00",
    //     "2007-03-01T00:00:00",
    //     "2007-04-01T00:00:00",
    //     "2007-05-01T00:00:00",
    //     "2007-06-01T00:00:00",
    //     "2007-07-01T00:00:00",
    //     "2007-08-01T00:00:00",
    //     "2007-09-01T00:00:00",
    //     "2007-10-01T00:00:00",
    //     "2007-11-01T00:00:00",
    //     "2007-12-01T00:00:00",
    //     "2008-01-01T00:00:00",
    //     "2008-02-01T00:00:00",
    //     "2008-03-01T00:00:00",
    //     "2008-04-01T00:00:00",
    //     "2008-05-01T00:00:00",
    //     "2008-06-01T00:00:00",
    //     "2008-07-01T00:00:00",
    //     "2008-08-01T00:00:00",
    //     "2008-09-01T00:00:00",
    //     "2008-10-01T00:00:00",
    //     "2008-11-01T00:00:00",
    //     "2008-12-01T00:00:00",
    //     "2009-01-01T00:00:00",
    //     "2009-02-01T00:00:00",
    //     "2009-03-01T00:00:00",
    //     "2009-04-01T00:00:00",
    //     "2009-05-01T00:00:00",
    //     "2009-06-01T00:00:00",
    //     "2009-07-01T00:00:00",
    //     "2009-08-01T00:00:00",
    //     "2009-09-01T00:00:00",
    //     "2009-10-01T00:00:00",
    //     "2009-11-01T00:00:00",
    //     "2009-12-01T00:00:00",
    //     "2010-01-01T00:00:00",
    //     "2010-02-01T00:00:00",
    //     "2010-03-01T00:00:00",
    //     "2010-04-01T00:00:00",
    //     "2010-05-01T00:00:00",
    //     "2010-06-01T00:00:00",
    //     "2010-07-01T00:00:00",
    //     "2010-08-01T00:00:00",
    //     "2010-09-01T00:00:00",
    //     "2010-10-01T00:00:00",
    //     "2010-11-01T00:00:00",
    //     "2010-12-01T00:00:00",
    //     "2011-01-01T00:00:00",
    //     "2011-02-01T00:00:00",
    //     "2011-03-01T00:00:00",
    //     "2011-04-01T00:00:00",
    //     "2011-05-01T00:00:00",
    //     "2011-06-01T00:00:00",
    //     "2011-07-01T00:00:00",
    //     "2011-08-01T00:00:00",
    //     "2011-09-01T00:00:00",
    //     "2011-10-01T00:00:00",
    //     "2011-11-01T00:00:00",
    //     "2011-12-01T00:00:00",
    //     "2012-01-01T00:00:00",
    //     "2012-02-01T00:00:00",
    //     "2012-03-01T00:00:00",
    //     "2012-04-01T00:00:00",
    //     "2012-05-01T00:00:00",
    //     "2012-06-01T00:00:00",
    //     "2012-07-01T00:00:00",
    //     "2012-08-01T00:00:00",
    //     "2012-09-01T00:00:00",
    //     "2012-10-01T00:00:00",
    //     "2012-11-01T00:00:00",
    //     "2012-12-01T00:00:00",
    //     "2013-01-01T00:00:00",
    //     "2013-02-01T00:00:00",
    //     "2013-03-01T00:00:00",
    //     "2013-04-01T00:00:00",
    //     "2013-05-01T00:00:00",
    //     "2013-06-01T00:00:00",
    //     "2013-07-01T00:00:00",
    //     "2013-08-01T00:00:00",
    //     "2013-09-01T00:00:00",
    //     "2013-10-01T00:00:00",
    //     "2013-11-01T00:00:00",
    //     "2013-12-01T00:00:00",
    //     "2014-01-01T00:00:00",
    //     "2014-02-01T00:00:00",
    //     "2014-03-01T00:00:00",
    //     "2014-04-01T00:00:00",
    //     "2014-05-01T00:00:00",
    //     "2014-06-01T00:00:00",
    //     "2014-07-01T00:00:00",
    //     "2014-08-01T00:00:00",
    //     "2014-09-01T00:00:00",
    //     "2014-10-01T00:00:00",
    //     "2014-11-01T00:00:00",
    //     "2014-12-01T00:00:00",
    //     "2015-01-01T00:00:00",
    //     "2015-02-01T00:00:00",
    //     "2015-03-01T00:00:00",
    //     "2015-04-01T00:00:00",
    //     "2015-05-01T00:00:00",
    //     "2015-06-01T00:00:00",
    //     "2015-07-01T00:00:00",
    //     "2015-08-01T00:00:00",
    //     "2015-09-01T00:00:00",
    //     "2015-10-01T00:00:00",
    //     "2015-11-01T00:00:00",
    //     "2015-12-01T00:00:00",
    //     "2016-01-01T00:00:00",
    //     "2016-02-01T00:00:00",
    //     "2016-03-01T00:00:00",
    //     "2016-04-01T00:00:00",
    //     "2016-05-01T00:00:00",
    //     "2016-06-01T00:00:00",
    //     "2016-07-01T00:00:00",
    //     "2016-08-01T00:00:00",
    //     "2016-09-01T00:00:00",
    //     "2016-10-01T00:00:00",
    //     "2016-11-01T00:00:00",
    //     "2016-12-01T00:00:00",
    //     "2017-01-01T00:00:00",
    //     "2017-02-01T00:00:00",
    //     "2017-03-01T00:00:00",
    //     "2017-04-01T00:00:00",
    //     "2017-05-01T00:00:00",
    //     "2017-06-01T00:00:00",
    //     "2017-07-01T00:00:00",
    //     "2017-08-01T00:00:00",
    //     "2017-09-01T00:00:00",
    //     "2017-10-01T00:00:00",
    //     "2017-11-01T00:00:00",
    //     "2017-12-01T00:00:00",
    //     "2018-01-01T00:00:00",
    //     "2018-02-01T00:00:00",
    //     "2018-03-01T00:00:00",
    //     "2018-04-01T00:00:00",
    //     "2018-05-01T00:00:00",
    //     "2018-06-01T00:00:00",
    //     "2018-07-01T00:00:00",
    //     "2018-08-01T00:00:00",
    //     "2018-09-01T00:00:00",
    //     "2018-10-01T00:00:00",
    //     "2018-11-01T00:00:00",
    //     "2018-12-01T00:00:00",
    //     "2019-01-01T00:00:00",
    //     "2019-02-01T00:00:00",
    //     "2019-03-01T00:00:00",
    //     "2019-04-01T00:00:00",
    //     "2019-05-01T00:00:00",
    //     "2019-06-01T00:00:00",
    //     "2019-07-01T00:00:00",
    //     "2019-08-01T00:00:00",
    //     "2019-09-01T00:00:00",
    //     "2019-10-01T00:00:00",
    //     "2019-11-01T00:00:00",
    //     "2019-12-01T00:00:00",
    //     "2020-01-01T00:00:00",
    //     "2020-02-01T00:00:00",
    //     "2020-03-01T00:00:00",
    //     "2020-04-01T00:00:00",
    //     "2020-05-01T00:00:00",
    //     "2020-06-01T00:00:00",
    //     "2020-07-01T00:00:00",
    //     "2020-08-01T00:00:00",
    //     "2020-09-01T00:00:00",
    //     "2020-10-01T00:00:00",
    //     "2020-11-01T00:00:00",
    //     "2020-12-01T00:00:00",
    //     "2021-01-01T00:00:00",
    //     "2021-02-01T00:00:00",
    //     "2021-03-01T00:00:00",
    //     "2021-04-01T00:00:00",
    //     "2021-05-01T00:00:00",
    //     "2021-06-01T00:00:00",
    //     "2021-07-01T00:00:00",
    //     "2021-08-01T00:00:00",
    //     "2021-09-01T00:00:00",
    //     "2021-10-01T00:00:00",
    //     "2021-11-01T00:00:00",
    //     "2021-12-01T00:00:00",
    //     "2022-01-01T00:00:00",
    //     "2022-02-01T00:00:00",
    //     "2022-03-01T00:00:00",
    //     "2022-04-01T00:00:00",
    //     "2022-05-01T00:00:00",
    //     "2022-06-01T00:00:00",
    //     "2022-07-01T00:00:00",
    //     "2022-08-01T00:00:00",
    //     "2022-09-01T00:00:00",
    //     "2022-10-01T00:00:00",
    //     "2022-11-01T00:00:00",
    //     "2022-12-01T00:00:00",
    //     "2023-01-01T00:00:00",
    //     "2023-02-01T00:00:00",
    //     "2023-03-01T00:00:00",
    //     "2023-04-01T00:00:00"
    //   ],
    //   y: [],
    //   label: "Pressure",
    //   style: {
    //     thickness: 0,
    //     color: 0,
    //     dashed: false,
    //     hexColor: ""
    //   },
    //   isSecondaryYAxis: false,
    //   mode: 0,
    //   type: 0
    // }
  ],
  layout: null,
  requestId: ""
};

export default useInjectionWellPairsQuery;
