import React, { memo } from "react";

import Flared from "./Flared";
import Fuel from "./Fuel";
import Vented from "./Vented";

function FFV() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 150 150"
        style={{ position: "relative" }}
        transform="scale(2.0) translate(5.5, 6.5)">
        <g style={{ position: "absolute", top: 0, left: 0 }}>
          <path
            d="M5.30686 2L5.30686 0L0 0L2.22569 3.30138L0 6.63918L5.30686 6.63918L5.30686 4.63918"
            transform="translate(4, 30) scale(5)"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="1.25"
          />
        </g>
        <g
          style={{ position: "absolute", top: 0, left: 0 }}
          transform="translate(60, 41) scale(1.15, 1.15)">
          <Fuel />
        </g>
        <g
          style={{ position: "absolute", top: 0, left: 0 }}
          transform="translate(35, 44)">
          <Vented />
        </g>
        <g style={{ position: "absolute", top: 0, left: 0 }} transform="translate(46, 5)">
          <Flared />
        </g>
      </svg>
    </div>
  );
}
export default memo(FFV);
