import { THEME_COLOR } from "constants/style.constants";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";
import _first from "lodash/first";
import _flatten from "lodash/flatten";
import _last from "lodash/last";
import _map from "lodash/map";
import _max from "lodash/max";
import _min from "lodash/min";
import _orderBy from "lodash/orderBy";
import _reverse from "lodash/reverse";
import _sortBy from "lodash/sortBy";
import Two from "two.js";

import {
  Direction,
  IZoneData,
  IZoneVisOptions,
  LegendInfo,
  LsdInfo,
  Perf,
  Point2F,
  Spacing,
  StationSurvey,
  WellInfo,
  WellVisData,
  ZoneInfo
} from "./xdaModels";

export class Visualization {
  _screenWidth: number;
  _screenHeight: number;

  _totalWidthMeters: number;
  _totalHeightMeters: number;

  //minimum x value in mercator meters
  _minX: number;
  //maximum y value in depth meters
  _topYm: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  _scene: Two;
  _zoneTextSpace = 120;
  _legendSpace = 130;
  _lsdTextSpace = 30;
  _zoneLineColor = "black";
  _labelColor = "black";
  _direction: Direction;
  _wellInfo: WellInfo[];
  _lsds: LsdInfo[];
  _spacing: Spacing;
  _legend: LegendInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _wellData: any;
  _showGrid: boolean;
  options: IZoneVisOptions;
  _padZones: string[];

  /**
   *
   */
  constructor(screenWidth, screenHeight, options, el = null) {
    this.options = options;
    if (el == null) {
      el = document.getElementById("zonevis");
    }

    this._screenHeight = screenHeight;
    this._screenWidth = screenWidth;
    this._scene = new Two({
      width: this._screenWidth,
      height: this._screenHeight,
      autostart: true
    }).appendTo(el);
  }

  UpdateWidthHeight(width: number, height: number) {
    this._zoneTextSpace = this.options.zoneDepthFontSize * 10;
    this._screenWidth = width;
    this._screenHeight = height;
    this._scene.renderer.setSize(this._screenWidth, height);
    this._scene.height = height;

    if (this.options.showIpdb) {
      for (const lsd of this._lsds) {
        const location = lsd.Survey.replaceAll("-", "");
        const data = this.zoneValueData.locationValues[location];

        for (const zone of lsd.Zones) {
          const zoneData = data[zone.Name];
          zone.value = zoneData;
        }
      }
    }

    if (this.scene === "zone") {
      this.DrawZoneScene(
        this._wellInfo,
        this._lsds,
        this._spacing,
        this._legend,
        this._wellData,
        this._padZones
      );
      if (this._showGrid) {
        this.showGrid();
      }
    } else if (this.scene === "count") {
      this.DrawCrossSectionWellCountScene(
        this._wellInfo,
        this._lsds,
        this._spacing,
        this._legend,
        this._uwi,
        this._rx,
        this._ry
      );
      if (this._showGrid) {
        this.showGrid();
      }
    } else {
      this.DrawWellVizScene(this.wellVizData);
    }
  }

  scene: string;
  wellVizData: WellVisData;
  zoneValueData: IZoneData;
  ipdbColors: string[];
  steps: number;
  minValue: number;
  setIpdbZoneData(data, colors, min, steps) {
    this.zoneValueData = data;
    this.ipdbColors = colors;
    this.steps = steps;
    this.minValue = min;
  }

  DrawWellVizScene(data: WellVisData) {
    this.wellVizData = data;
    if (!data) return;

    this.scene = "well";
    this._scene.clear();
    this._direction = Direction.X;
    const spacing = new Spacing();
    const lsds = data.lsds;
    const well = data.well;
    const totalX =
      _max(_map(lsds, (lsd: LsdInfo) => Math.abs(lsd.ELon))) -
      _min(_map(lsds, (lsd: LsdInfo) => Math.abs(lsd.WLon)));
    this._minX = _min(_map(lsds, (lsd: LsdInfo) => Math.abs(lsd.WLon)));
    this._topYm = _max(
      _map(lsds, (lsd: LsdInfo) =>
        _max(
          _map(lsd.Zones, (zone: ZoneInfo) => {
            return zone.DepthSS;
          })
        )
      )
    );

    //set total width and height in meters
    this._totalWidthMeters = totalX;
    this._totalHeightMeters = this.GetTotalDepthMeters(lsds, spacing, true);

    //render lsds
    const lsdScreenCoords = this.GetWellSceenLsdXCoordinates(lsds, this._direction);
    this.RenderLsds(lsdScreenCoords, true);
    this.RenderZones(lsdScreenCoords, true);
    if (well && well.Surveys) {
      this.RenderSurveyData(well.Surveys);
      this.RenderCompletions(data.completions, well.Surveys);
    }
  }

  RenderCompletions(completions: Perf[], surveys: StationSurvey[]) {
    for (const perf of completions) {
      const md = perf.MeasuredDepth;
      const index = _findIndex(surveys, (s: StationSurvey) => s.MeasuredDepth > md);
      if (index < 0) continue;
      const station = surveys[index];
      const screen = this.GetScreenCoordinates(
        Math.abs(station.Location.X),
        station.DepthSS
      );
      this._scene.makeRectangle(screen.screenx, screen.screeny, 5, 10);
      const text = this._scene.makeText(
        Math.round(md).toString(),
        screen.screenx,
        screen.screeny - 15
      );
      text.fill = "black";
      text.alignment = "center";
    }
  }

  RenderSurveyData(survey: StationSurvey[]) {
    const count = survey.length;

    for (let i = 1; i < count; i++) {
      const prev = survey[i - 1];
      const cur = survey[i];
      const p1 = this.GetScreenCoordinates(Math.abs(prev.Location.X), prev.DepthSS);
      const p2 = this.GetScreenCoordinates(Math.abs(cur.Location.X), cur.DepthSS);
      const line = this._scene.makeLine(p1.screenx, p1.screeny, p2.screenx, p2.screeny);
      line.stroke = "red";
      line.linewidth = 3;
    }
  }

  DrawZoneScene(
    wellInfo: WellInfo[],
    lsds: LsdInfo[],
    spacing: Spacing,
    legend: LegendInfo = null,
    wellData,
    padZones: string[]
  ): void {
    this.scene = "zone";
    this._wellData = wellData;
    this._legend = legend;
    this._scene.clear();
    this._wellInfo = wellInfo;
    this._padZones = padZones;
    this._lsds = _map(lsds, (lsd) => {
      if (lsd.WLon < lsd.ELon) {
        const temp = lsd.ELon;
        lsd.ELon = lsd.WLon;
        lsd.WLon = temp;
      }

      if (lsd.SLat > lsd.NLat) {
        const temp = lsd.NLat;
        lsd.NLat = lsd.SLat;
        lsd.SLat = temp;
      }
      return lsd;
    });
    this._spacing = spacing;

    this._direction = Direction.X;

    this._minX = _min(
      _map(lsds, (lsd: LsdInfo) =>
        this._direction === Direction.X
          ? Math.min(Math.abs(lsd.ELon), Math.abs(lsd.WLon))
          : lsd.SLat
      )
    );

    const wellMinX = _min(
      _map(wellInfo, (wi: WellInfo) => _min(_map(wi.Shape, (point: Point2F) => point.X)))
    );
    const maxWellX = _max(
      _map(wellInfo, (wi: WellInfo) => _max(_map(wi.Shape, (point: Point2F) => point.X)))
    );

    this._minX = Math.min(wellMinX, this._minX);

    const maxLsdX = _max(
      _map(lsds, (lsd: LsdInfo) => Math.max(Math.abs(lsd.ELon), Math.abs(lsd.WLon)))
    );
    const maxX = Math.max(maxLsdX, maxWellX);
    this._topYm =
      _max(
        _map(lsds, (lsd: LsdInfo) =>
          _max(_map(lsd.Zones, (zone: ZoneInfo) => zone.DepthSS))
        )
      ) + spacing.Vertical;

    const totalX = maxX - this._minX;

    const totalY =
      _max(_map(lsds, (lsd: LsdInfo) => lsd.NLat)) -
      _min(_map(lsds, (lsd: LsdInfo) => lsd.SLat));

    //set total width and height in meters
    this._totalWidthMeters =
      (this._direction === Direction.X ? totalX : totalY) + spacing.Lateral;

    this._totalHeightMeters = this.GetTotalDepthMeters(lsds, spacing, false);

    //render lsds
    const lsdScreenCoords = this.GetWellSceenLsdXCoordinates(lsds, this._direction);
    this.CalculateZoneOffset(lsds);
    this.RenderLsds(lsdScreenCoords);
    this.RenderZones(lsdScreenCoords);
    if (this.options.showPadOutline) {
      this.DrawPadOutline(wellInfo, lsds, padZones);
    }
    this.RenderWells(
      wellInfo,
      lsds,
      this._direction,
      wellData,
      this.options.showXDAOutline
    );
  }

  _uwi: string;
  _rx: number;
  _ry: number;

  DrawCrossSectionWellCountScene(
    wellInfo: WellInfo[],
    lsds: LsdInfo[],
    spacing: Spacing,
    legend: LegendInfo = null,
    uwi: string,
    rx: number,
    ry: number
  ) {
    this.scene = "count";
    this._uwi = uwi;
    this._rx = rx;
    this._ry = ry;
    this._wellData = {};
    this._legend = legend;
    this._scene.clear();
    this._wellInfo = wellInfo;
    this._padZones = [];
    this._lsds = _map(lsds, (lsd) => {
      if (lsd.WLon < lsd.ELon) {
        const temp = lsd.ELon;
        lsd.ELon = lsd.WLon;
        lsd.WLon = temp;
      }

      if (lsd.SLat > lsd.NLat) {
        const temp = lsd.NLat;
        lsd.NLat = lsd.SLat;
        lsd.SLat = temp;
      }
      return lsd;
    });
    this._spacing = spacing;

    this._direction = Direction.X;

    this._minX = _min(
      _map(lsds, (lsd: LsdInfo) =>
        this._direction === Direction.X
          ? Math.min(Math.abs(lsd.ELon), Math.abs(lsd.WLon))
          : lsd.SLat
      )
    );

    const wellMinX = _min(
      _map(wellInfo, (wi: WellInfo) => wi.CrossSectionPointWithOffset.X)
    );
    const maxWellX = _max(
      _map(wellInfo, (wi: WellInfo) => wi.CrossSectionPointWithOffset.X)
    );

    this._minX = Math.min(wellMinX, this._minX);

    const maxLsdX = _max(
      _map(lsds, (lsd: LsdInfo) => Math.max(Math.abs(lsd.ELon), Math.abs(lsd.WLon)))
    );
    const maxX = Math.max(maxLsdX, maxWellX);
    this._topYm =
      _max(
        _map(lsds, (lsd: LsdInfo) =>
          _max(
            _map(lsd.Zones, (zone: ZoneInfo) => {
              return zone.DepthSS;
            })
          )
        )
      ) + spacing.Vertical;

    const totalX = maxX - this._minX;

    const totalY =
      _max(_map(lsds, (lsd: LsdInfo) => lsd.NLat)) -
      _min(_map(lsds, (lsd: LsdInfo) => lsd.SLat));
    //set total width and height in meters
    this._totalWidthMeters =
      (this._direction === Direction.X ? totalX : totalY) + spacing.Lateral;

    this._totalHeightMeters = this.GetTotalDepthMeters(lsds, spacing, false);
    //
    //render lsds
    const lsdScreenCoords = this.GetWellSceenLsdXCoordinates(lsds, this._direction);

    this.CalculateZoneOffset(lsds);
    this.RenderLsds(lsdScreenCoords);
    this.RenderZones(lsdScreenCoords);
    this.RenderWells(wellInfo, lsds, this._direction, [], false);
    const well = wellInfo.find((wi) => wi.Uwi === uwi);

    this.RenderCountArea(well, rx, ry);
  }

  RenderCountArea(p: WellInfo, width, height) {
    const screenXY = this.GetScreenCoordinates(
      p.CrossSectionPointWithOffset.X,
      -p.CrossSectionPointWithOffset.Y
    );
    const quarterWidth = width * 0.5;
    const quarterHeight = height * 0.5;
    const leftX = this.GetScreenCoordinates(
      p.CrossSectionPointWithOffset.X - quarterWidth,
      -p.CrossSectionPointWithOffset.Y
    ).screenx;
    const rightX = this.GetScreenCoordinates(
      p.CrossSectionPointWithOffset.X + quarterWidth,
      -p.CrossSectionPointWithOffset.Y
    ).screenx;
    const widthPixel = rightX - leftX;

    const topY = this.GetScreenCoordinates(
      p.CrossSectionPointWithOffset.X,
      -p.CrossSectionPointWithOffset.Y - quarterHeight
    ).screeny;
    const botY = this.GetScreenCoordinates(
      p.CrossSectionPointWithOffset.X,
      -p.CrossSectionPointWithOffset.Y + quarterHeight
    ).screeny;

    const heightPixel = Math.abs(botY - topY);

    const ellipse = this._scene.makeEllipse(
      screenXY.screenx,
      screenXY.screeny,
      widthPixel,
      heightPixel
    );
    ellipse.fill = "transparent";
    ellipse.stroke = p.Color;
    ellipse.linewidth = 3;
  }

  showGrid() {
    this.renderGrid(50, 10, this._lsds);
    this._showGrid = true;
  }

  hideGrid() {
    this.DrawZoneScene(
      this._wellInfo,
      this._lsds,
      this._spacing,
      this._legend,
      this._wellData,
      this._padZones
    );
    this._showGrid = false;
  }

  renderGrid(size, verticalSize, lsd: LsdInfo[]) {
    const minx = _min(_map(lsd, (l: LsdInfo) => Math.abs(l.WLon)));
    const maxx = _max(
      _map(lsd, (l: LsdInfo) => Math.abs(l.WLon) + l.getWidth(Direction.X))
    );
    const count = (maxx - minx) / size;

    const gridColor = "#a3a3a3";

    //draw x line
    let fromXY = this.GetScreenCoordinates(minx, this._topYm);
    const toXY = this.GetScreenCoordinates(maxx, this._topYm);

    const startY = fromXY.screeny + 20;
    let line = this._scene.makeLine(fromXY.screenx, startY, toXY.screenx, startY);
    line.stroke = gridColor;
    line.linewidth = 2;

    //draw y lines
    for (let i = 0; i < count; i++) {
      const offset = i * size;
      const x = minx + offset;
      fromXY = this.GetScreenCoordinates(x, this._topYm);
      line = this._scene.makeLine(
        fromXY.screenx,
        startY,
        fromXY.screenx,
        this._screenHeight
      );
      line.stroke = gridColor;
      line.linewidth = 1;

      if (i % 5 === 0) {
        const text = this._scene.makeText(offset + "", fromXY.screenx, startY - 5);
        text.alignment = "center";
        text.size = 10;
      }
    }

    //draw vertical grid
    const countY = this._totalHeightMeters / verticalSize;
    //draw y lines
    for (let i = 1; i < countY; i++) {
      const offset = i * verticalSize;
      const label = (i - 1) * verticalSize;
      const y = this._topYm - offset;
      fromXY = this.GetScreenCoordinates(minx, y);
      const line = this._scene.makeLine(
        fromXY.screenx,
        fromXY.screeny,
        toXY.screenx,
        fromXY.screeny
      );
      line.stroke = gridColor;
      line.linewidth = 1;
      if ((i - 1) % 5 === 0) {
        const text = this._scene.makeText(
          label + "",
          fromXY.screenx - 10,
          fromXY.screeny
        );
        text.alignment = "right";
        text.size = 10;
      }
    }
  }

  RenderLegend(legend: LegendInfo) {
    if (!legend.LegendItems) return;
    const title = legend.GroupByTitle;
    const startLegendX = this._screenWidth - this._legendSpace - this._zoneTextSpace + 40;

    const text = this._scene.makeText(title, startLegendX, 15);
    text.size = this.options.lsdHeaderFontSize;
    text.alignment = "left";
    text.fill = "black";
    let legendY = 40;
    for (const item of legend.LegendItems) {
      const rect = this._scene.makeRectangle(startLegendX + 10, legendY, 15, 15);
      rect.fill = item.Color;
      const itemText = this._scene.makeText(item.Title, startLegendX + 60, legendY);
      itemText.alignment = "left";
      itemText.size = 12;
      legendY += 25;
    }
  }

  DrawPolygon(shape: Point2F[], color) {
    color = color || THEME_COLOR;

    const anchorPoints = [];
    for (let i = 0; i < shape.length - 1; i++) {
      const start = shape[i];
      const end = shape[i + 1];
      const p1 = this.GetScreenCoordinates(start.X, start.Y);
      const p2 = this.GetScreenCoordinates(end.X, end.Y);

      const a1 = new Two.Anchor(p1.screenx, p1.screeny);
      const a2 = new Two.Anchor(p2.screenx, p2.screeny);
      anchorPoints.push(a1);
      anchorPoints.push(a2);
    }
    const poly = this._scene.makePath(anchorPoints);
    poly.stroke = color;
    poly.linewidth = 3;
    if (this.options.fillXDA) {
      poly.fill = this.options.fillColor;
    } else {
      poly.fill = "transparent";
    }
  }

  DrawPadOutline(wellInfo: WellInfo[], lsds, padZones) {
    const verts = [];

    const wellMinX = _min(
      _map(wellInfo, (wi: WellInfo) => _min(_map(wi.Shape, (point: Point2F) => point.X)))
    );
    const maxWellX = _max(
      _map(wellInfo, (wi: WellInfo) => _max(_map(wi.Shape, (point: Point2F) => point.X)))
    );

    let topY = this._topYm - this._spacing.Vertical;

    let minY = _min(
      _map(lsds, (lsd: LsdInfo) =>
        _min(
          _map(lsd.Zones, (zone: ZoneInfo) => {
            return zone.DepthSS;
          })
        )
      )
    );

    if (padZones != null && padZones.length > 0) {
      const zones = _reverse(_sortBy(lsds[0].Zones, (zone) => zone.DepthSS));

      const allZonesName = _map(zones, (z) => z.Name);
      const zoneDict = {};
      let i = 0;
      for (const zone of allZonesName) {
        zoneDict[zone] = i++;
      }
      //sort pad zones based on depth order
      padZones = _sortBy(padZones, (pz) => zoneDict[pz]);
      const topZoneName = padZones[0];
      let baseZoneName = padZones[padZones.length - 1];
      const nextZoneIdx = _findIndex(zones, (zone) => zone.Name === baseZoneName) + 1;

      let validTop = false;
      if (nextZoneIdx > 0 && zones.length > nextZoneIdx) {
        validTop = true;
      }
      baseZoneName = validTop ? zones[nextZoneIdx].Name : baseZoneName;
      const topZones = _flatten(
        _map(lsds, (lsd: LsdInfo) =>
          _filter(lsd.Zones, (zone: ZoneInfo) => zone.Name === topZoneName)
        )
      );

      topY = _max(topZones.map((z: ZoneInfo) => z.DepthSS));
      const baseZones = _flatten(
        _map(lsds, (lsd: LsdInfo) =>
          _map(
            _filter(lsd.Zones, (zone: ZoneInfo) => {
              return zone.Name === baseZoneName;
            }),
            (zone: ZoneInfo) => {
              return zone.Name.includes("BASE") || validTop
                ? zone.DepthSS + lsd.ZoneOffset
                : zone.DepthSS + lsd.ZoneOffset + zone.Pay;
            }
          )
        )
      );

      minY = _min(baseZones.map((z) => z));
    }

    const p1 = this.GetScreenCoordinates(wellMinX, topY);
    const p2 = this.GetScreenCoordinates(wellMinX, minY);
    const p3 = this.GetScreenCoordinates(maxWellX, minY);
    const p4 = this.GetScreenCoordinates(maxWellX, topY);
    verts.push(new Two.Anchor(p1.screenx, p1.screeny, 0, 0, 0, 0));
    verts.push(new Two.Anchor(p2.screenx, p2.screeny, 0, 0, 0, 0));
    verts.push(new Two.Anchor(p3.screenx, p3.screeny, 0, 0, 0, 0));
    verts.push(new Two.Anchor(p4.screenx, p4.screeny, 0, 0, 0, 0));

    const poly = this._scene.makePath(verts);
    poly.fill = "transparent";
    poly.linewidth = this.options.padOutlineWidth;
    poly.stroke = this.options.padOutlineColor;
    if (this.options.padOutlineDashed) {
      poly.dashes = [5, 2];
    }
  }

  RenderWells(
    wellInfo: WellInfo[],
    lsdInfo: LsdInfo[],
    direction,
    wellData,
    drawPoly = true
  ) {
    if (!wellData) {
      wellData = {};
    }
    for (const well of wellInfo) {
      const lsdIndex = _findIndex(lsdInfo, (lsd: LsdInfo) => lsd.Survey === well.Survey);
      let offset = 0;
      if (lsdIndex >= 0) {
        offset = lsdInfo[lsdIndex].ZoneOffset;
      }
      const y = well.AverageToeLsdSSDepth + offset;
      const x = Math.abs(direction === Direction.X ? well.Location.X : well.Location.Y);

      const data = wellData[well.Uwi];

      const wellScreenCoordinate = this.GetScreenCoordinates(x, y);

      if (typeof data !== "undefined" && data != null) {
        const item1 = data.Item2;
        const item2 = data.Item3;
        if (item1 != null && item1 !== "") {
          const text = this._scene.makeText(
            item1,
            wellScreenCoordinate.screenx,
            wellScreenCoordinate.screeny - 12
          );
          text.size = 10;
        }
        if (item2 != null && item2 !== "") {
          const text = this._scene.makeText(
            item2,
            wellScreenCoordinate.screenx + 12,
            wellScreenCoordinate.screeny
          );
          text.size = 10;
          text.alignment = "left";
        }
      }
      if (drawPoly) {
        this.DrawPolygon(well.Shape, well.Color);
      }
      const circle = this._scene.makeCircle(
        wellScreenCoordinate.screenx,
        wellScreenCoordinate.screeny,
        5
      );
      circle.fill = well.Color;
      if (this.options.showValue) {
        const wellText = this._scene.makeText(
          well.Value,
          wellScreenCoordinate.screenx,
          wellScreenCoordinate.screeny + 15
        );
        wellText.size = this.options.valueFontSize;
        wellText.fill = well.Color;

        if (this.options.showWellLabel) {
          const wellLabelText = this._scene.makeText(
            well.Uwi,
            wellScreenCoordinate.screenx,
            wellScreenCoordinate.screeny + 30
          );
          wellLabelText.size = this.options.valueFontSize;
          wellLabelText.fill = well.Color;
        }
      }
    }
  }

  CalculateZoneOffset(lsds: LsdInfo[]) {
    for (const lsd of lsds) {
      const zones = this.OrderZonesByDepth(lsd.Zones);
      for (const zone of zones) {
        const top = _max(
          _map(lsds, (other: LsdInfo) => {
            const foundIndex = _findIndex(
              other.Zones,
              (otherZone: ZoneInfo) => otherZone.Name === zone.Name
            );
            return foundIndex >= 0 ? other.Zones[foundIndex].DepthSS : 0;
          })
        );
        if (top === 0) continue;
        lsd.ZoneOffset = top - zone.DepthSS;
        break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RenderZones(lsdScreenCoordinates: any[], checkOverlaps = false) {
    for (let i = 0; i < lsdScreenCoordinates.length; i++) {
      const lsdcoord = lsdScreenCoordinates[i];
      const lsd = lsdcoord.lsd;
      const left = lsdcoord.left;
      const right = lsdcoord.right;
      if (lsd.Zones.length === 0) continue;
      if (
        i > 0 &&
        checkOverlaps &&
        this.IsLsdOverlap(lsdScreenCoordinates[i - 1].lsd, lsd, this._direction)
      ) {
        continue;
      }
      if (!lsd.ZoneOffset) {
        lsd.ZoneOffset = 0;
      }

      const lastZoneIndex = lsd.Zones.length - 1;

      // buble sort lsd array by increasing depth
      for (let i = 0; i < lsd.Zones.length; i++) {
        for (let j = 0; j < lsd.Zones.length - 1; j++) {
          if (
            lsd.Zones[j].DepthSS + lsd.ZoneOffset <
            lsd.Zones[j + 1].DepthSS + lsd.ZoneOffset
          ) {
            const swap = lsd.Zones[j];
            lsd.Zones[j] = lsd.Zones[j + 1];
            lsd.Zones[j + 1] = swap;
          }
        }
      }

      // track bottom of previous text
      let previoustextbot = 0;
      for (let j = 0; j < lsd.Zones.length; j++) {
        const zone = lsd.Zones[j];
        const depth = zone.DepthSS + lsd.ZoneOffset;
        const top = this.GetScreenCoordinates(0, depth);

        const line = this._scene.makeLine(
          i === 0 ? this._zoneTextSpace : left.screenx,
          top.screeny,
          right.screenx,
          top.screeny
        );
        if (this.options.showIpdb && zone.value) {
          const value = zone.value;
          const bot = this.GetScreenCoordinates(
            0,
            depth - (Math.abs(value.base) - Math.abs(value.top))
          );

          const width = right.screenx - left.screenx;
          const height = bot.screeny - top.screeny;
          const rect = this._scene.makeRectangle(
            left.screenx + width / 2,
            top.screeny + height / 2,
            width,
            height
          );
          const index = ((value.value - this.minValue) / this.steps) | 0;

          const color = this.ipdbColors[index];

          rect.fill = color;
        }

        line.stroke = this._zoneLineColor;
        if (i === 0) {
          const next = j < lastZoneIndex ? lsd.Zones[j + 1] : zone;
          const prev = j > 0 ? lsd.Zones[j - 1] : zone;
          const screeny =
            j > 0 && Math.abs(depth - (prev.DepthSS + lsd.ZoneOffset)) < 6
              ? top.screeny + 5
              : zone !== next && Math.abs(depth - (next.DepthSS + lsd.ZoneOffset)) < 6
              ? top.screeny - 5
              : top.screeny;

          // first label
          if (previoustextbot === 0) {
            const text = this._scene.makeText(
              zone.Name + Math.round(depth),
              this._zoneTextSpace - 5,
              screeny - 5
            );
            text.alignment = "right";
            text.fill = this._labelColor;
            text.size = this.options.zoneDepthFontSize;
            previoustextbot = screeny;
            // if label is too close to the previous one
          } else if (screeny - 7 < previoustextbot) {
            const text = this._scene.makeText(
              zone.Name + Math.round(depth),
              this._zoneTextSpace - 5,
              previoustextbot + 5
            );
            text.alignment = "right";
            text.fill = this._labelColor;
            text.size = this.options.zoneDepthFontSize;
            previoustextbot = previoustextbot + 10;
            // if label is not too close
          } else {
            const text = this._scene.makeText(
              zone.Name + Math.round(depth),
              this._zoneTextSpace - 5,
              screeny - 5
            );
            text.alignment = "right";
            text.fill = this._labelColor;
            text.size = this.options.zoneDepthFontSize;
            previoustextbot = screeny;
          }
        }
      }
    }
  }

  IsLsdOverlap(lsd1, lsd2, direction) {
    const x1 = direction === Direction.X ? lsd1.WLon : lsd1.SLat;
    const x2 = direction === Direction.X ? lsd2.WLon : lsd2.SLat;

    const overlap = Math.abs(Math.abs(x2) - Math.abs(x1)) <= 10;
    return overlap;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RenderLsds(lsdsScreenCoords: any[], checkOverlaps = false) {
    for (let i = 0; i < lsdsScreenCoords.length; i++) {
      const coords = lsdsScreenCoords[i];
      const lsd: LsdInfo = coords.lsd;
      const left = coords.left;
      const right = coords.right;
      if (i > 0 && checkOverlaps) {
        const prev = lsdsScreenCoords[i - 1];
        if (this.IsLsdOverlap(prev.lsd, lsd, this._direction)) {
          const text2 = this._scene.makeText(
            lsd.Survey,
            left.screenx + (right.screenx - left.screenx) / 2,
            40
          );
          text2.fill = this._labelColor;
          text2.size = 11;

          //overlaps
          continue;
        }
      }
      const leftLine = this._scene.makeLine(
        left.screenx,
        0,
        left.screenx,
        this._screenHeight
      );
      const rightLine = this._scene.makeLine(
        right.screenx,
        0,
        right.screenx,
        this._screenHeight
      );
      leftLine.stroke = this._zoneLineColor;
      rightLine.stroke = this._zoneLineColor;

      // const text = this._scene.makeText(
      //   lsd.Survey,
      //   left.screenx + (right.screenx - left.screenx) / 2,
      //   15 + offset
      // );
      // text.size = this.options.lsdHeaderFontSize;
      // text.fill = this._labelColor;
    }
  }

  GetWellSceenLsdXCoordinates(lsds: LsdInfo[], direction) {
    return _orderBy(
      _map(lsds, (lsd: LsdInfo) => {
        const x = Math.abs(lsd.WLon);
        const width = lsd.getWidth(direction);
        const screen = {
          lsd: lsd,
          left: this.GetScreenCoordinates(x, 0),
          right: this.GetScreenCoordinates(x + width, 0)
        };

        return screen;
      }),
      (lsd) => lsd.left.screenx
    );
  }

  GetLsdXCoordinates(lsds: LsdInfo[], direction) {
    return _orderBy(
      _map(lsds, (lsd: LsdInfo) => {
        const x = Math.abs(
          direction === Direction.X
            ? Math.min(Math.abs(lsd.WLon), Math.abs(lsd.ELon))
            : lsd.SLat
        );
        const width = lsd.getWidth(direction);
        const screen = {
          lsd: lsd,
          left: this.GetScreenCoordinates(x, 0),
          right: this.GetScreenCoordinates(x + width, 0)
        };
        return screen;
      }),
      (lsd) => lsd.left.screenx
    );
  }

  GetTotalDepthMeters(lsds: LsdInfo[], spacing: Spacing, isWell) {
    if (isWell) {
      const totalY =
        _max(
          _map(lsds, (lsd: LsdInfo) =>
            _max(
              _map(
                lsd.Zones,
                (zone: ZoneInfo) => zone.DepthSS + (isNaN(zone.Pay) ? 20 : zone.Pay * 1.5)
              )
            )
          )
        ) -
        _min(
          _map(lsds, (lsd: LsdInfo) =>
            _min(_map(lsd.Zones, (zone: ZoneInfo) => zone.DepthSS))
          )
        );

      return totalY;
    }
    return (
      _max(
        _map(lsds, (lsd: LsdInfo) => {
          if (lsd.Zones.length === 0) return 0;
          const zones = this.OrderZonesByDepth(lsd.Zones);

          const first: ZoneInfo = _first(zones);
          const last: ZoneInfo = _last(zones);
          if (!first && !last) return 0;
          if (!last.Pay) last.Pay = 0;
          const depth = first.DepthSS - last.DepthSS + last.Pay * 1.5;
          return depth;
        })
      ) +
      spacing.Vertical * 2
    );
  }

  OrderZonesByDepth(zones: ZoneInfo[]) {
    return _orderBy(zones, (z: ZoneInfo) => z.DepthSS, "desc");
  }

  GetScreenCoordinates(position, depth) {
    const x = Math.abs(position) - this._minX;
    const y = this._topYm - depth;

    const legendSpace =
      !this._legend || this._legend === null || this._legend.GroupByTitle === ""
        ? 0
        : this._legendSpace;

    const availableScreenWidth = this._screenWidth - this._zoneTextSpace - legendSpace;
    const availableScreenHeight = this._screenHeight - this._lsdTextSpace;
    const screenx =
      this._zoneTextSpace +
      Math.round((x / this._totalWidthMeters) * availableScreenWidth);
    const screeny =
      this._lsdTextSpace +
      Math.round((y / this._totalHeightMeters) * availableScreenHeight);

    const result = { screenx: screenx, screeny: screeny };

    //
    return result;
  }

  GetDirection(totalWidth, totalHeight) {
    return totalHeight > totalWidth ? Direction.Y : Direction.X;
  }
}
