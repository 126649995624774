import { useContext } from "react";

import { ShapefileContext } from "./ShapefileContext";

export function useShapefileContext() {
  const context = useContext(ShapefileContext);
  if (context === undefined) {
    throw new Error("useProjectContext must be within a Project provider");
  }

  return context;
}
