import { memo, useState } from "react";
import { SketchPicker } from "react-color";

import { Add } from "@material-ui/icons";
import { Button } from "antd";
import { cookies } from "constants/settings.constants";
import Cookies from "js-cookie";
import styled from "styled-components";

import { BaseRangeSlider } from "components/base";

import "./ColorPicker.scss";

const color_grid_list = [
  [
    "transparent",
    "#FFFFFF",
    "#D0D4DD",
    "#B1B5BF",
    "#9498A2",
    "#777B87",
    "#5C606C",
    "#424652",
    "#292E3A",
    "#000000"
  ],
  [
    "#FF2825",
    "#FF9300",
    "#FFEB00",
    "#00B241",
    "#009988",
    "#00BFD8",
    "#0097FB",
    "#6F33BE",
    "#AB06B6",
    "#FE0061"
  ],
  [
    "#FFCBD1",
    "#FFDFAC",
    "#FFF9BD",
    "#C0E7C6",
    "#A6E1DB",
    "#A2EDF4",
    "#B2DFFE",
    "#E8BCEA",
    "#FFB8D1",
    "#FFBDD4"
  ],
  [
    "#FD9598",
    "#FFCA72",
    "#FFF590",
    "#97D8A2",
    "#66CDC4",
    "#5BE1EC",
    "#7FCBFE",
    "#B89BE0",
    "#D98FDD",
    "#FF88B2"
  ],
  [
    "#F56B6F",
    "#FFB42B",
    "#FFF25D",
    "#68CA7D",
    "#00B9AC",
    "#00D3E4",
    "#44B6FC",
    "#9C72D3",
    "#C760CE",
    "#FF5492"
  ],
  [
    "#FF4248",
    "#FFA300",
    "#FFEF26",
    "#3FBE60",
    "#00A99A",
    "#00C9DD",
    "#00A6FC",
    "#8652C9",
    "#B93AC2",
    "#FF237A"
  ],
  [
    "#E50922",
    "#FF7400",
    "#FFBE00",
    "#00912E",
    "#007B6B",
    "#009AAA",
    "#0076D9",
    "#5826AF",
    "#8705A8",
    "#D4005B"
  ],
  [
    "#C80009",
    "#F94200",
    "#FF7700",
    "#006013",
    "#004F40",
    "#006265",
    "#0046A7",
    "#371398",
    "#520492",
    "#950050"
  ]
];
const colors_with_border = ["transparent", "#FFFFFF", "#FFDFAC", "#FFF9BD"];
const transparent_color_selected_bg = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='rgb(214,40,11)' /></svg>")`;

function ColorChooser({
  selectedColor,
  onChange,
  customColors,
  setCustomColors,
  includeOpacity = false,
  onChangeOpacity = null,
  selectedOpacity = 100,
  includeThickness = false,
  onChangeThickness = null,
  selectedThickenss = 2
}) {
  const [screen, setScreen] = useState("list");
  const [customColor, setCustomColor] = useState(selectedColor);
  const [opacity, setOpacity] = useState(selectedOpacity);
  const [thickness, setThickness] = useState(selectedThickenss);

  function update(event) {
    const color = event["hex"];
    setCustomColor(color);
  }

  function saveCustomColor() {
    let savedColors = Cookies.get(cookies.CUSTOM_COLORS);
    if (!savedColors) savedColors = [];
    else savedColors = JSON.parse(savedColors);
    Cookies.set(cookies.CUSTOM_COLORS, [...savedColors, customColor]);
    setCustomColors([...savedColors, customColor]);
    setScreen("list");
  }

  return (
    <div>
      {screen === "list" && (
        <ColorChooserContainer>
          <FlexColumn>
            {color_grid_list.map((row, rowIdx) => (
              <FlexRow key={rowIdx}>
                {row.map((color) => (
                  <ColorSquare
                    key={color}
                    backgroundColor={color}
                    classNames={`${
                      color.toLocaleLowerCase() === selectedColor ? "selected" : ""
                    }`}
                    onClick={() => onChange(color)}
                  />
                ))}
              </FlexRow>
            ))}
            <FlexRow className="justify-between" style={{ marginTop: "10px" }}>
              <span>Custom colours</span>
              <CustomColorBtn onClick={() => setScreen("custom")}>
                <Add style={{ fontSize: "18px", color: "#A2AAAD" }}></Add>
              </CustomColorBtn>
            </FlexRow>
            {includeOpacity ? (
              <FlexRow className="justify-between" style={{ marginTop: "10px" }}>
                <BaseRangeSlider
                  value={opacity}
                  label="Opacity"
                  max={100}
                  unit="%"
                  onChange={(val) => {
                    setOpacity(val);
                    if (val) {
                      const o = Number(val) / 100.0;
                      onChangeOpacity && onChangeOpacity(o);
                    }
                  }}
                />
              </FlexRow>
            ) : null}
            {includeThickness ? (
              <FlexRow className="justify-between" style={{ marginTop: "10px" }}>
                <BaseRangeSlider
                  value={thickness}
                  label="Thickness"
                  max={10}
                  step={0.5}
                  unit="pt"
                  onChange={(val) => {
                    setThickness(val);
                    if (val) {
                      onChangeThickness && onChangeThickness(Number(val));
                    }
                  }}
                />
              </FlexRow>
            ) : null}
            <FlexRow>
              {customColors.slice(Math.max(customColors.length - 10, 0)).map((color) => (
                <ColorSquare
                  key={color}
                  backgroundColor={color}
                  classNames={`${
                    color.toLocaleLowerCase() === selectedColor ? "selected" : ""
                  }`}
                  onClick={() => onChange(color)}
                />
              ))}
            </FlexRow>
          </FlexColumn>
        </ColorChooserContainer>
      )}
      {screen === "custom" && (
        <CustomColorContainer>
          <SketchPicker
            color={customColor}
            onChange={update}
            disableAlpha
            presetColors={[]}
          />
          <StyledButton type="primary" size="small" onClick={saveCustomColor}>
            Add
          </StyledButton>
        </CustomColorContainer>
      )}
    </div>
  );
}

export default memo(ColorChooser);

const FlexRow = styled.div`
  display: inline-flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColorChooserContainer = styled.div`
  display: flex;
  & > div > div:nth-child(2) {
    margin-bottom: 5px;
  }
`;

const CustomColorContainer = styled.div`
  display: grid;
  padding: 8px;
  font-family: var(--fontFamily) !important;

  .chrome-picker {
    box-shadow: none !important;
    & > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
      display: none;
    }
    label {
      display: none !important;
    }
  }
`;

const StyledButton = styled(Button)`
  justify-self: center;
`;

const ColorSquare = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
    colors_with_border.includes(props.backgroundColor) ? "1px solid #D9E1E2" : "none"};
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  background-image: ${(props) =>
    props.backgroundColor === "transparent" ? transparent_color_selected_bg : "none"};
  &:hover,
  &.selected {
    box-shadow: ${(props) =>
        ["#FFFFFF", "transparent"].includes(props.backgroundColor)
          ? "#D0D4DD"
          : props.backgroundColor}
      0px 0px 3px;
  }
`;

const CustomColorBtn = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9e1e2;
  cursor: pointer;
`;
