import { useState } from "react";
import { useSelector } from "react-redux";

import { Avatar, Popover } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import useBetaFeatures from "hooks/useBetaFeatures";

import { AddDashboard, DashboardNav } from "components/dashboard";
import { FilesToggle } from "components/files";
import { UserMenu } from "components/user";
import { DocumentationToggle } from "components/user-documentation";
import WorkspaceNav from "components/workspace/WorkspaceNav";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";

import { Logo } from "../base";
import "./NavigationBar.scss";

const userServiceRoot = process.env.REACT_APP_USER_SERVICE + "/user";

function NavigationBar({ showDashboardButtons = true }) {
  const user = useSelector((state: RootState) => state.auth.user);
  const { activityBarWidth } = useWorkspaceContext();
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const { hasFeature } = useBetaFeatures();

  // derived state
  const offset = Math.max(420, activityBarWidth);

  const initials = user ? user?.firstName[0] + user?.lastName[0] : "";
  const imageSrc = `${userServiceRoot}/${user?.profilePictureImageSrc}`;

  const avatarSrc = user?.profilePictureImageSrc ? (
    <img
      className="absolute fill inline-block"
      src={imageSrc}
      alt={user?.firstName ?? "avatar"}
    />
  ) : (
    <>{initials}</>
  );

  return (
    <Wrapper className="Navbar" showDashboardButtons offset={offset}>
      <LogoSection>
        <Logo />
        {showDashboardButtons && <WorkspaceNav />}
      </LogoSection>

      <TabsSection>
        {showDashboardButtons && <DashboardTabs />}
        {showDashboardButtons && <AddDashboard />}
      </TabsSection>

      <ProfileSection>
        {hasFeature("File Management") && <FilesToggle />}
        <DocumentationToggle />
        <Popover
          arrowPointAtCenter
          content={<UserMenu onClose={() => setUserMenuVisible(false)} />}
          placement="bottomRight"
          trigger="click"
          open={userMenuVisible}
          onOpenChange={(visible) => {
            setUserMenuVisible(visible);
          }}>
          <StyledAvatar src={avatarSrc} />
        </Popover>
      </ProfileSection>
    </Wrapper>
  );
}

export default NavigationBar;

const Wrapper = styled.div`
  width: 100%;
  height: var(--navbar-height, 4.5rem);
  display: grid;
  grid-template-columns: ${(props) => `${props.offset}px minmax(0, 1fr) auto`};
  align-items: center;
  box-shadow: 0 1px 1px var(--color-text-10);
  overflow: hidden;
  z-index: 1;
`;

const NavSection = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-4);
`;

const LogoSection = styled(NavSection)`
  padding-left: var(--space-4);
`;
const TabsSection = styled(NavSection)`
  height: 100%;
  gap: var(--space-2);
  margin-right: var(--space-8);
`;
const ProfileSection = styled(NavSection)`
  padding-right: var(--space-4);
`;

const DashboardTabs = styled(DashboardNav)`
  padding-left: ${(props) => props.offset}px;
  padding-right: 120px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: var(--color-primary-1);
  font-weight: 500;
  cursor: pointer;
`;
