import { CSSProperties, ReactNode, memo } from "react";

import classnames from "classnames";

type HeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface NamedSubHeadingProps {
  className?: string;
  children: ReactNode;
  element?: HeadingType;
  style?: CSSProperties;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SubHeadingProps = NamedSubHeadingProps & Record<string, any>;

function SubHeading({
  className = "",
  children,
  element = "h3",
  ...rest
}: SubHeadingProps) {
  const headingClasses = classnames(element, className, "sub-heading");
  const HeadingElement = element as keyof JSX.IntrinsicElements;

  return (
    <HeadingElement className={headingClasses} {...rest}>
      {children}
    </HeadingElement>
  );
}

export default memo(SubHeading);
