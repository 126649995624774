import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Drawer } from "antd";
import { hide } from "store/features/userDocumentation/documentationSlice";
import { RootState } from "store/rootReducer";

import DocumentationArea from "./DocumentationArea";

const DocumentationDrawer: FunctionComponent = () => {
  // context and dispatch
  const dispatch = useDispatch();

  // state from store
  const isDrawerOpen = useSelector((state: RootState) => state.documentation.display);

  return (
    <Drawer
      closable={false}
      placement="right"
      open={isDrawerOpen}
      width={450}
      onClose={() => dispatch(hide())}
      forceRender={true}>
      {isDrawerOpen && <DocumentationArea />}
    </Drawer>
  );
};

export default DocumentationDrawer;
