import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentShapefileFeatureProperty: undefined,
  currentShapefileIdForFeatureProperty: undefined,
  currentShapefileFeatureSortDirection: undefined,
  /**
   * Indicates whether initial values of the project and it's dependences have been set.
   * For more implementation details, see handling in the [useProjectInitialization hook](../../../components/project/projects/hooks/useProjectInitialization/useProjectInitialization.tsx)
   */
  hasProjectInitialized: false,
  hasViewItemsChanged: false,
  projectTree: [],
  selectedFeatureProperty: {},
  selectedProjectTransaction: "",
  selectedShapefile: null,
  shapefileFeatures: {}
};

const createRandomTransactionId = () => {
  const length = 5;
  const charset = "abcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += charset[Math.floor(Math.random() * charset.length)];
  }
  return result;
};
const projectSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setProjectTree(state, action) {
      state.projectTree = action.payload;
    },
    setHasProjectInitialized(state, action) {
      state.hasProjectInitialized = action.payload;
    },
    setHasViewItemsChanged(state, action) {
      state.hasViewItemsChanged = action.payload;
    },
    setSelectedShapefile(state, action) {
      state.selectedShapefile = action.payload;
    },
    setShapefileFeatures(state, action) {
      state.shapefileFeatures = action.payload;
    },
    setCurrentShapefileIdForFeatureProperty(state, action) {
      state.currentShapefileIdForFeatureProperty = action.payload;
    },
    setCurrentShapefileFeatureProperty(state, action) {
      state.currentShapefileFeatureProperty = action.payload;
    },
    setCurrentShapefileFeatureSortDirection(state, action) {
      state.currentShapefileFeatureSortDirection = action.payload;
    },
    // TODO BF: rename, and keep, will be useful if we use multiple shapefile properties
    // Stores all the selected feature properties for each shapefile
    setSelectedFeatureProperty(state, action) {
      state.selectedFeatureProperty[state.selectedShapefile?.key] = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSelectedProjectTransaction(state, action) {
      state.selectedProjectTransaction = createRandomTransactionId();
    }
  }
});

export const {
  setCurrentShapefileIdForFeatureProperty,
  setCurrentShapefileFeatureProperty,
  setCurrentShapefileFeatureSortDirection,
  setProjectTree,
  setHasProjectInitialized,
  setHasViewItemsChanged,
  setSelectedFeatureProperty,
  setSelectedShapefile,
  setShapefileFeatures,
  updateSelectedProjectTransaction
} = projectSlice.actions;

export default projectSlice.reducer;
