import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { setAttentionPolygon } from "store/features";
import styled from "styled-components";

import { Badge, BaseIconToggle, BaseTree } from "components/base";

import { EntityKind } from "../../models/entityKind";
import "./PredicateTree.scss";

export default function PredicateTree({
  predicates = [],
  onDeleteNode,
  onMouseDoubleClick,
  entityKind
}) {
  const dispatch = useDispatch();

  const [state, setState] = useState(predicates);

  // sync predicates with local state
  useEffect(() => {
    //exclude polygon item when using well list
    for (const predicate of predicates) {
      predicate.children = predicate.children.filter(
        (predicate) =>
          predicate.displayTitle !== "Polygon = Map View" ||
          (predicate.displayTitle === "Polygon = Map View" && predicate.count !== 0)
      );
    }
    setState(predicates);

    // rebuild the tooltips after active filter tree gets rendered
    ReactTooltip.rebuild();
  }, [predicates]);

  const onMouseDoubleClickRef = useRef(onMouseDoubleClick);
  const onDeleteNodeRef = useRef(onDeleteNode);

  function dispatchAttentionPolygon(node) {
    if (node?.actualValue?.type !== "Polygon") {
      dispatch(setAttentionPolygon(undefined));
      return;
    }
    dispatch(setAttentionPolygon(node.actualValue));
  }

  const itemTemplate = useCallback(({ node, depth }) => {
    const showCount = node.actualValue && node.actualValue.type === "Polygon";
    let nodeTitle = node.displayTitle || node.name.split(" ").slice(1).join(" ");
    if (nodeTitle === "Well List" && entityKind === EntityKind.Facility) {
      nodeTitle = "Facility List";
    }

    return (
      <div
        onMouseEnter={() => dispatchAttentionPolygon(node)}
        onMouseLeave={() => dispatchAttentionPolygon(null)}
        className={`item-template leaf ${showCount ? "show-count" : ""}`}
        onDoubleClick={() =>
          onMouseDoubleClickRef.current && onMouseDoubleClickRef.current(node)
        }>
        <div
          title={node.title}
          style={{
            color: `${node.operator === "nin" ? "#ff2855" : ""}`
          }}
          className={`label depth-${depth}`}>
          {nodeTitle}
        </div>
        <TreeItemWrapper>
          {
            //only show badge for polygon predicates or well lists
            node.actualValue &&
              (node.actualValue.type === "Polygon" ||
                node.actualValue.type === "WellList") && <Badge>{node.count}</Badge>
          }
          <RemoveButtonWrapper>
            <BaseIconToggle
              size={26}
              toggle={(val, evt) => {
                evt.stopPropagation();
                onDeleteNodeRef.current && onDeleteNodeRef.current(node);
              }}>
              <RemoveCircleOutlineIcon style={{ fontSize: 20 }} />
            </BaseIconToggle>
          </RemoveButtonWrapper>
        </TreeItemWrapper>
      </div>
    );
  }, []);

  const nodeTemplate = useCallback(
    ({ node }) => (
      <div className="item-template node">
        <div
          style={{
            maxWidth: `90%`,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}>
          {node.name}
        </div>
        <TreeItemWrapper title={node.name}>
          <Badge>{node.count}</Badge>
          <RemoveButtonWrapper>
            <BaseIconToggle
              size={26}
              toggle={(_, evt) => {
                evt.stopPropagation();
                onDeleteNodeRef.current && onDeleteNodeRef.current(node);
                onDeleteNodeRef.current && onDeleteNodeRef.current(node);
              }}>
              <RemoveCircleOutlineIcon style={{ fontSize: 20 }} />
            </BaseIconToggle>
          </RemoveButtonWrapper>
        </TreeItemWrapper>
      </div>
    ),
    []
  );

  return (
    <div className="predicate-tree">
      <BaseTree nodes={state} itemTemplate={itemTemplate} nodeTemplate={nodeTemplate} />
    </div>
  );
}

const TreeItemWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: var(--fontMono);

  .Badge {
    background: rgba(81, 181, 160, 0.2);
  }
`;

const RemoveButtonWrapper = styled.div`
  margin-right: 5px;
  cursor: pointer;

  &:hover .icon-toggle {
    color: var(--color-primary);
  }

  .icon-toggle {
    color: rgba(194, 194, 194, 0.8);
    opacity: 0;
  }
`;
