// Decreases by linear shade
function lightenColorByPercentage(color: string, percentage: number): string {
  const hexColor = color.replace("#", "");
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const lightenedR = Math.round(r + (255 - r) * (percentage / 100));
  const lightenedG = Math.round(g + (255 - g) * (percentage / 100));
  const lightenedB = Math.round(b + (255 - b) * (percentage / 100));

  return `#${componentToHex(lightenedR)}${componentToHex(lightenedG)}${componentToHex(
    lightenedB
  )}`;
}

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export default lightenColorByPercentage;
