import { useMemo } from "react";

import { IChartScreenshotSettings } from "models/chart";

import {
  useScreenshotContext,
  useScreenshotPresetContext
} from "components/screenshot/hooks";

function useXDAScreenshotCalculatedDefaults(): IChartScreenshotSettings {
  // hooks
  const { widget } = useScreenshotContext();
  const { selectedPreset: preset } = useScreenshotPresetContext();

  return useMemo(() => {
    return widget?.widgetComponentType !== "xdaViewer"
      ? {}
      : {
          legendVisible: true,
          legendOpacity: 1,
          legendBoarderVisible: true
        };
  }, [
    widget?.widgetComponentType,
    preset?.topsLabelsFontSize,
    preset?.dataLabelsFontSize,
    preset?.gridLabelsFontSize,
    preset?.legendFontSize
  ]);
}

export default useXDAScreenshotCalculatedDefaults;
