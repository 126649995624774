import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { ErrorOutline } from "@material-ui/icons";

import { deleteDataSource } from "api/dataSource";

import { BaseButton } from "components/base";
import BaseModal from "components/base/BaseModal";

export default function RemoveProdViewDataSourceModal({
  open,
  onClose,
  orgId
}): JSX.Element {
  const mutation = useMutation(async (orgId: string) => {
    const ok = deleteDataSource(orgId, "ProdView");
    if (ok) {
      onClose && onClose();
    }
    toast.success("Successfully removed ProdView data source.");
  });

  async function removeDataSource() {
    await mutation.mutate(orgId);
  }

  return (
    <BaseModal
      size="xs"
      title={
        <div className="flex-row items-center">
          <ErrorOutline style={{ fontSize: "40px" }} />
          Remove ProdView Data Source
        </div>
      }
      open={open}
      onClose={() => onClose()}
      className="remove-data-source-dialog">
      <div className="flex-column container">
        <div className="flex-column">
          <p>Are you sure you want to delete this Prod View source?</p>
        </div>
      </div>
      <div className="flex-row justify-center">
        <BaseButton appearance="danger" onClick={removeDataSource}>
          Remove Data Source
        </BaseButton>
      </div>
    </BaseModal>
  );
}
