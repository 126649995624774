import React, { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

import { produce } from "immer";

import {
  StructuredNodeReducerActionsT,
  structuredNodeReducer
} from "./reducer/structuredNodeReducer";

export interface IStructuredNodeState {
  canDrop: boolean;
  inEditMode: boolean;
  acceptChanges: boolean;
}

export const initialStructuredNodeState: IStructuredNodeState = {
  canDrop: false,
  inEditMode: false,
  acceptChanges: false
};

export type StructuredNodeDispatchT = (action: StructuredNodeReducerActionsT) => void;
export type ChartSettingsProviderT = {
  children: ReactNode;
  initialState?: IStructuredNodeState;
};

const curriedStructuredNodeReducer = produce(structuredNodeReducer);

const StructuredNodeContext =
  createContext<[IStructuredNodeState, React.Dispatch<StructuredNodeReducerActionsT>]>(
    undefined
  );
StructuredNodeContext.displayName = "ChartSettingsContext";

const StructuredNodeProvider = ({ children, initialState }: ChartSettingsProviderT) => {
  const newState = initialState ? initialState : initialStructuredNodeState;

  const [state, dispatch] = useReducer(curriedStructuredNodeReducer, newState);
  const value: [IStructuredNodeState, StructuredNodeDispatchT] = useMemo(
    () => [state, dispatch],
    [state]
  );

  return (
    <StructuredNodeContext.Provider value={value}>
      {children}
    </StructuredNodeContext.Provider>
  );
};

const useStructuredNodeContext = () => {
  const context = useContext(StructuredNodeContext);
  if (context === undefined) {
    throw new Error(
      "useStructuredNodeContext must be used within StructuredNodeProvider"
    );
  }
  return context;
};

export { StructuredNodeProvider, curriedStructuredNodeReducer, useStructuredNodeContext };
