// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiFormTextbox } from "@mdi/js";

import { ShapefileAction } from "../..";

interface IShapefileRenameActionProps {
  isDisabled?: boolean;
  setIsRenaming: () => void;
}

export function ShapefileRenameAction({
  isDisabled,
  setIsRenaming
}: IShapefileRenameActionProps) {
  return (
    <ShapefileAction
      disabled={isDisabled}
      placement="bottom"
      onClick={(evt) => {
        setIsRenaming();
        evt.stopPropagation();
        evt.preventDefault();
      }}>
      <Icon path={mdiFormTextbox} size={1} />
      Rename
    </ShapefileAction>
  );
}
