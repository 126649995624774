import { useContext } from "react";

import { WorkspaceDispatchContext } from "../contexts/WorkspaceContext";

export function useWorkspaceDispatch() {
  const context = useContext(WorkspaceDispatchContext);
  if (context === undefined) {
    throw new Error("useWorkspaceDispatch must be used within a Workspace Provider");
  }
  return context;
}
