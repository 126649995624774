import { useMemo } from "react";

import { useGlobalProductionSettings } from "hooks";

import { EntityKind } from "../../../models/entityKind";
import { useChartSettings } from "../context";

export interface GlobalSettings {
  usingGlobalNormalizeBy: boolean;
  usingAllGlobalSettings: boolean;
}
const useGlobalSettings = (): GlobalSettings => {
  const [chartSettings] = useChartSettings();
  const globalSettings = useGlobalProductionSettings(EntityKind.Well);

  const isUsingGlobalNormalizeBy = (settings, globalSettings): boolean => {
    return (
      settings?.useNormalizeBy === undefined ||
      (!settings?.useNormalizeBy && !globalSettings?.normalizeBy) ||
      (settings?.useNormalizeBy &&
        !!globalSettings?.normalizeBy &&
        JSON.stringify(settings?.normalizeBy) ===
          JSON.stringify(globalSettings?.normalizeBy))
    );
  };

  return useMemo(() => {
    const usingGlobalNormalizeBy: boolean = isUsingGlobalNormalizeBy(
      chartSettings.settings,
      globalSettings
    );

    return {
      usingGlobalNormalizeBy,
      usingAllGlobalSettings: usingGlobalNormalizeBy
    };
  }, [
    JSON.stringify(globalSettings?.normalizeBy),

    JSON.stringify(chartSettings?.settings?.normalizeBy),
    chartSettings?.settings?.useNormalizeBy
  ]);
};

export default useGlobalSettings;
