// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiDelete } from "@mdi/js";
import { Popconfirm } from "antd";

import { ShapefileAction } from "../..";

interface IShapefileDeleteActionProps {
  isDisabled?: boolean;
  deleteShapefile: () => void;
}

export function ShapefileDeleteAction({
  isDisabled,
  deleteShapefile
}: IShapefileDeleteActionProps) {
  return (
    <Popconfirm
      placement="bottom"
      onConfirm={(evt) => {
        deleteShapefile();
        evt.stopPropagation();
        evt.preventDefault();
      }}
      onCancel={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
      disabled={isDisabled}
      okText="Delete"
      okType="danger"
      title={`Are you sure you want to delete the shapefile?`}>
      <ShapefileAction disabled={isDisabled} danger>
        <Icon path={mdiDelete} size={1} />
        Delete
      </ShapefileAction>
    </Popconfirm>
  );
}
