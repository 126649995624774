import { memo } from "react";
import { Range, getTrackBackground } from "react-range";

import "./RangeInput.scss";

function RangeInput({ values, min, max, step, onChange }) {
  return (
    <div className="Range-Input">
      <Range
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        allowOverlap={false}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              background: getTrackBackground({
                values,
                colors: ["#a2aaad", "#00c7b1", "#a2aaad"],
                min,
                max
              })
            }}
            className="range-track">
            {children}
          </div>
        )}
        renderThumb={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style
            }}
            className="range-thumb">
            <div className="range-label">{values[index]}</div>
          </div>
        )}></Range>
    </div>
  );
}

export default memo(RangeInput);
