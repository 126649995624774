import { TLockKeys } from "./axisTypes";

export function getAxesNumberMinMax(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  series: any[],
  axisIndex: number,
  lockKey: TLockKeys
) {
  if (!series?.length) return null;

  let axisMinMax = null;

  switch (lockKey) {
    case "xMax": {
      const xAxisValues = series
        .filter((series) => {
          return series.xAxisIndex === axisIndex;
        })
        .reduce((currentData, series) => [...currentData, ...series.x], []);

      axisMinMax = Math.max(...xAxisValues);
      break;
    }
    case "yMax": {
      const yAxisValues = series
        .filter((series) => {
          return series.yAxisIndex === axisIndex;
        })
        .reduce((currentData, series) => [...currentData, ...series.y], []);

      axisMinMax = Math.max(...yAxisValues);
      break;
    }
    default: {
      axisMinMax = 0;
      break;
    }
  }

  if (axisMinMax >= 1) axisMinMax = axisMinMax.toFixed();

  return axisMinMax;
}
