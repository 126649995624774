import { memo } from "react";

function StackedBar() {
  return (
    <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
      <g id="Iconschart-typestacked-bar" transform="translate(1.125 1.125)">
        <path
          d="M0 0L0 20L20 20"
          transform="translate(0 0.37842178)"
          id="Path-3-Copy-6"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 4.55358L0.5 0"
          transform="translate(4.9399567 12)"
          id="Path-5"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 4.55358L0.5 0"
          transform="translate(9.939957 12)"
          id="Path-5-Copy-2"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 4.55358L0.560043 0.878422"
          transform="translate(14.939957 0)"
          id="Path-5-Copy-6"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 3.55358L0.560043 0.878422"
          transform="translate(4.9399567 7)"
          id="Path-5-Copy"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 6.55358L0.560043 0.878422"
          transform="translate(9.939957 4)"
          id="Path-5-Copy-3"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 4.55358L0.5 0"
          transform="translate(14.939957 12)"
          id="Path-5-Copy-5"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M0.5 5.55358L0.560043 0.878422"
          transform="translate(14.939957 5)"
          id="Path-5-Copy-4"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.25"
        />
      </g>
    </svg>
  );
}

export default memo(StackedBar);
