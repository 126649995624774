// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { mdiDelete, mdiPlus } from "@mdi/js";
import { Button, Modal, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";

// import { useJobStatus } from "./hooks/useJobStatus";
import useCutoffSources from "hooks/useCutoffSources";
import useDeleteCutoffSource from "hooks/useDeleteCutoffSource";

import { CutoffSource } from "api/ipdp";

import { Heading } from "../base";
import { CreateCutoffGeoModel } from "./modals/CreateCutoffGeoModel";

export function OrganizationGeoModelConfigurations() {
  const {
    data: cutoffSources,
    refetch: refetchCutoffSources,
    error: refetchCutoffSourceErrors
  } = useCutoffSources();

  const deleteCutoffGeoModel = useDeleteCutoffSource();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    refetchCutoffSources();
  }, []);

  const columns: ColumnsType<CutoffSource> = [
    {
      title: "Name",
      dataIndex: "source",
      key: "source"
    },
    {
      title: "Source Model",
      dataIndex: "originalSource",
      key: "originalSource"
    },
    {
      title: "Cutoff Definitions",
      dataIndex: "cutoff",
      key: "cutoff"
    },
    {
      title: "Status",
      dataIndex: "cutoffStatus",
      key: "cutoff"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <ButtonWrapper>
          <Tooltip title="Delete" overlayInnerStyle={{ padding: "10px" }}>
            <Button
              style={{ margin: "1px" }}
              type="primary"
              danger
              icon={<Icon path={mdiDelete} size={1.4} />}
              onClick={() => handleDelete(record.source)}
            />
          </Tooltip>
        </ButtonWrapper>
      )
    }
  ];

  async function handleDelete(source: string) {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this cutoff model all calculated data?",
      onOk: async () => {
        await deleteCutoffGeoModel
          .mutateAsync(source)
          .catch(() => {
            toast.error("An error occurred while deleting the cutoff model.");
          })
          .then(() => {
            refetchCutoffSources();
          });
      }
    });
  }

  async function handleSuccess() {
    setShowModal(false);
    refetchCutoffSources();
  }

  useEffect(() => {
    refetchCutoffSources();
  }, []);

  if (refetchCutoffSourceErrors) {
    return <ErrorMessage>Error occurred while fetching tokens</ErrorMessage>;
  }

  return (
    <div>
      <Heading element="h4">Geo Model Configurations </Heading>
      <ButtonWrapper>
        <Button
          icon={<Icon path={mdiPlus} size={1} />}
          type="primary"
          onClick={() => {
            setShowModal(true);
          }}>
          Add New Cutoff Geo Model
        </Button>
      </ButtonWrapper>
      <Table dataSource={cutoffSources} columns={columns} rowKey="jobId" />

      <CreateCutoffGeoModel
        requestShowModal={showModal}
        sources={cutoffSources}
        onClose={() => setShowModal(false)}
        onSuccess={handleSuccess}
      />
    </div>
  );
}

const ErrorMessage = styled.div`
  color: red;
  font-size: 20px;
  margin: 20px;
  border: 1px solid red;
  padding: 10px;
  background-color: #ffe6e6;
  border-radius: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
