//need to store the bin used to derive the legend item
import { IPredicate, PropertyFilterModel } from "models/filter";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

//so we can go back to the same legend groupings
export const getLegendFilter = (
  property,
  binable,
  items,
  categoryId,
  bin,
  normalizeBySetting,
  negate: boolean,
  pdenSource: PdenSourceEnum = PdenSourceEnum.Public,
  legendKey: string = undefined,
  groupByTitle?,
  partialContextTitle?,
  isForecastToggleOn?,
  selectedForecastFolderName?
): PropertyFilterModel => {
  try {
    const countPattern = "\\(\\d+\\)\\s*";
    const firstRangeValuePattern = "(-?[\\s\\d.\\w]+)?";
    const rangeSeparatorPattern = "([-≥<])*\\s*";
    const secondRangeValuePattern = "(-?[\\s\\d.\\w]+)?";
    const quantileValuePattern = "(\\(.*)?";

    // Example: "(10) 100 - 150", "(23) ≥ 100"
    const binablePattern = new RegExp(
      `${countPattern}${firstRangeValuePattern}${rangeSeparatorPattern}${secondRangeValuePattern}${quantileValuePattern}`
    );
    const nonBinablePattern = /\(\d+\) (.*)/;
    //match entire string if it is not binable
    const pattern = binable ? binablePattern : nonBinablePattern;
    let predicates: IPredicate[] = [];

    const values = items
      .map((legend: string) => {
        const nanMatch = legend.match(nonBinablePattern);
        const isnan = nanMatch?.length > 1 && nanMatch[1].toUpperCase() === "N/A";
        const match = legend.match(pattern);
        if (match.length < 2) {
          return null;
        }
        const capture = (match[1] || "").trim();
        if (!binable) {
          return { value: isnan ? "" : capture };
        }
        const signCapture = match.length > 2 && match[2] ? match[2] : null;
        const toCapture = match.length > 3 && match[3] ? match[3].trim() : null;
        let filterType = "";
        if (signCapture == null) {
          if (toCapture == null) {
            filterType = "=";
          } else {
            filterType = "between";
          }
        } else {
          if (signCapture === "≥") {
            filterType = ">=";
          } else if (signCapture === "<") {
            filterType = "<";
          } else if (signCapture === "-") {
            filterType = "between";
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let value: any = "";
        if (capture && toCapture) {
          value = [capture, toCapture];
        } else if (toCapture && !isNaN(parseFloat(toCapture.trim()))) {
          value = toCapture.trim();
        } else if (capture && !isNaN(parseFloat(capture.trim()))) {
          value = capture;
        } else if (capture && !isnan) {
          value = capture;
        }
        if (negate) {
          if (filterType === "=") {
            filterType = "!=";
          } else if (filterType === "<") {
            filterType = ">=";
          } else if (filterType === ">=") {
            filterType = "<";
          } else if (filterType === "between") {
            filterType = "not between";
          }
        }
        return {
          value: value,
          categoryId: categoryId,
          type: filterType
        };
      })
      .filter((f) => f != null);

    if (!binable) {
      predicates.push({
        type: "Property",
        property,
        categoryId: categoryId,
        operator: negate ? "nin" : "in",
        bin: bin,
        value: values.map((v) => v.value),
        pdenSource,
        sourceKey: legendKey,
        negatedOperator: negate
      });
    } else {
      predicates = values.map((v) => {
        const predicate = {
          type: "Property",
          property: property,
          categoryId: categoryId,
          operator: v.type,
          value: v.value,
          bin: bin,
          pdenSource,
          normalizeBySetting: normalizeBySetting,
          sourceKey: legendKey,
          negatedOperator: negate,
          groupByTitle: groupByTitle,
          partialContextTitle: partialContextTitle,
          isForecastToggleOn: isForecastToggleOn,
          selectedForecastFolderName: selectedForecastFolderName
        } as IPredicate;
        return predicate;
      });
    }
    const query: PropertyFilterModel = {
      predicates: predicates,
      source: "legend"
    };
    return query;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
