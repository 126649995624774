import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { setTypeLogWells } from "store/features";

import { WellDetailCardRequest, fetchTypeLogData, getWellDetailCard } from "api/data";

import { Uwi } from "models";
import { EntityKind } from "models/entityKind";

import { useSelectedWellFromStore } from "components/data-analyzer/hooks";

import {
  setPreviouslySelectedWells,
  setSelectedWell,
  setTypeLogDocument,
  setTypeLogErrorMessage,
  setWells,
  useTypeLog
} from "../context";

const group = "Type Log";
const fields = ["Geology.TypeLog"];

export function useTypeLogData() {
  // hooks
  const selectedWell = useSelectedWellFromStore();
  const [state, dispatch] = useTypeLog();

  // dispatch
  const stateDispatch = useDispatch();

  const [typeLogWells, setSelectedTypeLogWells] = useState(null);

  // sync selected type log well with selected well
  useEffect(() => {
    if (
      selectedWell?.Uwi !== state?.selectedWell?.Uwi ||
      selectedWell?.FormattedUwi !== state?.selectedWell?.FormattedUwi
    ) {
      const selectedWellUwi = selectedWell?.Uwi || selectedWell?.FormattedUwi;
      const selectedWellIndex = typeLogWells?.findIndex(
        (well) => well.id === selectedWellUwi || well.value === selectedWellUwi
      );
      if (selectedWellIndex !== -1) {
        setSelectedWell(dispatch, selectedWell);
        setPreviouslySelectedWells(dispatch, selectedWell);
      }
    }
  }, [selectedWell]);

  const getInfoMutation = useMutation(
    async (request: WellDetailCardRequest) => await getWellDetailCard(request),
    {
      onSuccess: (response, variables) => {
        if (variables.group === "Type Log") {
          setTypeLogDocument(dispatch, {
            group: variables.group,
            wellData: response.data
          });
        }
        setTypeLogErrorMessage(dispatch, null);
      },
      onError: (error, request) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err) {
          if (err && err.response?.status === 401) {
            setTypeLogErrorMessage(dispatch, "Requires subscription.");
          } else if (err && err.response?.status === 404) {
            if (!state?.selectedWell) {
              setTypeLogErrorMessage(dispatch, "Please select a well to view type logs.");
            } else {
              setTypeLogErrorMessage(dispatch, "No type log available.");
            }
          } else {
            if (err && err.response?.data) {
              // eslint-disable-next-line no-console
              console.error(err.response?.data);
            }
            setTypeLogErrorMessage(
              dispatch,
              `Error loading details for well ${new Uwi().toFormatted(request.uwid)}.`
            );
          }
        }
      }
    }
  );

  // fetch type log pdf
  useEffect(() => {
    getInfoMutation.mutate({
      uwid: state?.selectedWell?.Uwi,
      group: group,
      fields: [...new Set(fields)],
      entityKind: EntityKind.Well
    });
  }, [state?.selectedWell]);

  // fetch type log wells
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchTypeLogData();
      const modified = result?.map((item) => {
        return {
          id: item?.uniqueId,
          value: item?.formattedId
        };
      });
      setSelectedTypeLogWells(modified);
      stateDispatch(setTypeLogWells(modified));
      setWells(dispatch, modified);
      if (modified?.length > 0) {
        const obj = {
          Uwi: modified[0].id,
          FormattedUwi: modified[0].value
        };
        // setSelectedTypeLogWell(obj);
        setSelectedWell(dispatch, obj);
      }
    };

    fetchData();
  }, []);

  return {
    typeLogWells
  };
}
