export interface IScreenshotSettings {
  id?: string;
  width?: number | undefined | null;
  height?: number | undefined | null;
  legendVisible?: boolean;
  legendFontSize?: number | undefined | null;
  mapScreenshotScale?: number | undefined | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

export interface IWidgetScreenshot {
  widgetId: string;
  widgetComponentType: string;
}

export interface IScreenshotAbbreviation {
  type: "system" | "org" | "user";
  current: string;
  abbreviation: string;
  caseSensitive: boolean;
}

export class ScreenshotError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ScreenshotError";
  }
}

export class ScreenshotNotFocusedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "ScreenshotNotFocusedError";
  }
}
