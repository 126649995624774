import { SegmentDto } from "arps_wasm";

import { ArpSegment } from "models/UserArpsModel";

import { ProductTypeEnum } from "../models/ArpsSegment";
import { arpsWasm } from "./UpdateSegment";
import {
  AVG_DAYS_IN_MONTH,
  getDurationInDays,
  getDurationInMonths,
  getStartOfNextMonth
} from "./dates";
import { getDeclineTitleWithType } from "./declineHelpers";

export function ArpsSegmentToSegmentDto(
  arpsWasm: typeof import("wasm/arps"),
  segment: ArpSegment[]
): SegmentDto[] {
  if (!segment) {
    return [];
  }
  return segment.map((s) => arpsWasm.arpSegmentToSegmentDto(s));
}

export function getDefaultSegment(
  arpsWasm: typeof import("wasm/arps")
): (product: ProductTypeEnum) => SegmentDto[] {
  return (product) => {
    const date = getStartOfNextMonth();
    return arpsWasm.getArpsSegmentsFromBasicArps(
      product,
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      100.0,
      30.0,
      0.0,
      12,
      null,
      5.0,
      null,
      null
    );
  };
}

export const declineTypes = {
  Secant: "sec",
  Tangent: "tan",
  Nominal: "nom"
};

export function convertDecline(di, b, type) {
  if (type === "Tangent") {
    return arpsWasm.nominalToTangent(di) * 100;
  } else if (type === "Secant") {
    return arpsWasm.nominalToSecant(di, b) * 100;
  } else {
    return di * 100;
  }
}

export function convertDeclineToNominal(di, b, type) {
  if (type === "Tangent") {
    return arpsWasm.tangentToNominal(di / 100.0);
  } else if (type === "Secant") {
    return arpsWasm.secantToNominal(di / 100.0, b);
  } else {
    return di / 100;
  }
}

export function getSpacingBetweenValues(
  start: number,
  end: number,
  numberOfItems: number
) {
  return (start > end ? start - end : end - start) / numberOfItems;
}

export function getRampUpSegmentCumVolume(arpsSegment: ArpSegment) {
  const monthDifference = getDurationInMonths(arpsSegment.startDate, arpsSegment.endDate);

  const dayDifference = getDurationInDays(arpsSegment.startDate, arpsSegment.endDate);

  const daySpacing = getSpacingBetweenValues(
    arpsSegment.qi,
    arpsSegment.qf,
    dayDifference
  );

  let start = new Date(arpsSegment.startDate.split("Z")[0]);
  const endDate = new Date(arpsSegment.endDate.split("Z")[0]);
  let idx = 0;
  let currentCum = 0;
  let currentRate = arpsSegment.qi;

  while (idx < monthDifference) {
    const nextDay = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    // stop right before end date
    const days = nextDay < endDate ? nextDay.getDate() : endDate.getDate() - 1;

    for (let i = 1; i <= days; i++) {
      currentRate += daySpacing;
      currentCum += currentRate;
    }

    start.setMonth(start.getMonth() + 1);
    if (start > endDate) {
      start = endDate;
    }

    idx++;
  }
  return currentCum;
}

export function getTypewellTemplateFields(
  productSegments: ArpSegment[],
  declineType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultTypeWell?: any
) {
  const segmentLength = productSegments.length;
  let eur = 0;

  const hasRampUp =
    segmentLength > 2 &&
    productSegments[0].qi <= productSegments[0].qf &&
    productSegments[0].di < 0;

  productSegments.forEach((s, i) => {
    if (hasRampUp && s.qi <= s.qf && s.di < 0 && i == 0) {
      eur += getRampUpSegmentCumVolume(s) * 0.001;
    } else {
      const startDate_unixTimestamp_seconds = Math.round(
        new Date(s.startDate).getTime() / 1000
      );
      const endDate_unixTimestamp_seconds = Math.round(
        new Date(s.endDate).getTime() / 1000
      );
      const segVolume =
        arpsWasm.getSegmentVolume(
          s.qi,
          s.di,
          s.b,
          s.qf,
          BigInt(startDate_unixTimestamp_seconds),
          BigInt(endDate_unixTimestamp_seconds)
        ) * 0.001;
      eur += segVolume;
    }
  });

  const seg1 = productSegments[0];
  const seg2 = hasRampUp ? productSegments[1] : null;
  const lastSegment = productSegments[segmentLength - 1];
  const secondToLastSegment =
    segmentLength > 2 ? productSegments[segmentLength - 2] : null;

  // tRamp only available for 2 ramp-up segment templates
  const tRamp = hasRampUp
    ? Math.round(
        (getDurationInDays(seg1.startDate, seg1.endDate) / AVG_DAYS_IN_MONTH) * 10
      ) / 10 // show precision to one decimal place
    : undefined;

  const is3SegmentsTemplate = (segmentLength === 3 && !hasRampUp) || segmentLength === 4;

  const Bhyp = is3SegmentsTemplate
    ? secondToLastSegment.b
    : segmentLength > 1
    ? hasRampUp
      ? seg2.b
      : seg1.b
    : seg1.b;

  const Btrans = is3SegmentsTemplate
    ? hasRampUp
      ? seg2.b
      : seg1.b
    : defaultTypeWell?.values.Btrans; // Previous segment didn't have Btrans, use the default

  const Bf = segmentLength > 1 ? lastSegment?.b ?? seg2.b : defaultTypeWell?.values.Bf; // Previous segment didn't have Bf, use the default

  // tTrans only available for 3 segment templates
  const tTrans = is3SegmentsTemplate
    ? hasRampUp
      ? Math.round(getDurationInDays(seg2.startDate, seg2.endDate) / AVG_DAYS_IN_MONTH)
      : Math.round(getDurationInDays(seg1.startDate, seg1.endDate) / AVG_DAYS_IN_MONTH)
    : undefined;

  if (!declineType) {
    return {
      "Start Date": seg1.startDate.split("Z")[0],
      Q0: hasRampUp ? seg1.qi : undefined,
      Qi: hasRampUp ? seg2.qi : seg1.qi,
      Di: hasRampUp ? seg2.di : seg1.di,
      B: hasRampUp ? seg2.b : seg1.b,
      Btrans: Btrans,
      Bhyp: Bhyp,
      Bf: Bf,
      tRamp: tRamp,
      tTrans: tTrans,
      Df: secondToLastSegment ? secondToLastSegment.df : seg1.df,
      Qf: lastSegment.qf,
      EUR: eur
    };
  }

  return {
    "Start Date": seg1.startDate.split("Z")[0],
    Q0: seg1.qi,
    Qi: hasRampUp ? seg2.qi : seg1.qi,
    [getDeclineTitleWithType("Di", declineType)]: roundTo6Decimal(
      convertDecline(
        hasRampUp ? seg2.di : seg1.di,
        hasRampUp ? seg2.b : seg1.b,
        declineType
      )
    ),
    B: segmentLength === 1 ? seg1.b : undefined, // B only available for 1 segment templates
    Btrans: is3SegmentsTemplate ? Btrans : undefined, // Btrans only available for 3 segment templates
    Bhyp: segmentLength > 1 ? Bhyp : undefined, // Bhyp only available for 2 segment templates and above
    Bf: segmentLength > 1 ? Bf : undefined, // Bf only available for 2 segment templates and above
    tRamp: tRamp,
    tTrans: tTrans,
    [getDeclineTitleWithType("Df", declineType)]: roundTo6Decimal(
      secondToLastSegment
        ? convertDecline(secondToLastSegment.df, secondToLastSegment.b, declineType)
        : convertDecline(seg1.df, seg1.b, declineType)
    ),
    Qf: lastSegment.qf.toFixed(2),
    EUR: eur.toFixed(2)
  };
}

const roundTo6Decimal = (val) => {
  return Math.round(val * 1000000) / 1000000;
};
