/**
 * Created with the intention of a one property object being compared
 * @param objectToCompare One property object
 * @param baseObject Object you are matching key and value to
 * @returns boolean
 */
const checkKeyValuePairMatch = (objectToCompare, baseObject) => {
  return Object.keys(objectToCompare).some(
    (key) =>
      // eslint-disable-next-line no-prototype-builtins
      baseObject.hasOwnProperty(key) &&
      objectToCompare?.[key]?.toString() === baseObject?.[key]?.toString()
  );
};

export default checkKeyValuePairMatch;
