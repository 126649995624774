import { EntitiesInGroupModel } from "../contexts/MapContextState";

export interface EntityInfo {
  uwi: string;
  group: string;
  color: string;
}
/**
 * helper function to search the group entities to find information that matches the uwis list
 * @param uwis the uwi list
 * @param groups map of group to entities on map
 * @returns
 */
export function findEntitiesFromGroup(
  uwis: string[],
  groups: { [group: string]: EntitiesInGroupModel }
) {
  const result: { [uwi: string]: EntityInfo } = {};

  const found = uwis
    .map((uwi) => {
      for (const groupTitle of Object.keys(groups)) {
        if (uwi in groups[groupTitle].uwis) {
          const group = groups[groupTitle].group;
          return {
            uwi,
            color: group.color,
            group: group.title
          } as EntityInfo;
        }
      }
      return undefined;
    })
    .filter((ent) => !!ent);
  for (const item of found) {
    result[item.uwi] = item;
  }
  return result;
}
