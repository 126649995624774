import {
  LegendDimensionsT,
  LegendPositionT,
  LegendT,
  ParentDimensionsT
} from "../Legend";

const getUpdateChartSizeLegend = (
  storedParentDimensions: ParentDimensionsT,
  parentDimensions: ParentDimensionsT,
  storedPosition: LegendPositionT,
  storedDimensions: LegendDimensionsT,
  calculatedDimensions: LegendDimensionsT
): LegendT => {
  const updatedLegend: LegendT = {
    x: null,
    y: null,
    width: calculatedDimensions.width,
    height: calculatedDimensions.height
  };

  // in the below calculations 5 is used instead of 0 to account for the legend being nearly but not perfectly in the corner
  // if legend is in top left
  if (storedPosition.x <= 5 && storedPosition.y <= 5) {
    updatedLegend.x = 0;
    updatedLegend.y = 0;
  }
  //if legend is in top right
  else if (
    storedPosition.x + storedDimensions.width >= storedParentDimensions.width - 5 &&
    storedPosition.y <= 5
  ) {
    updatedLegend.x = parentDimensions.width - calculatedDimensions.width;
    updatedLegend.y = 0;
  }
  // if legend is in bottom left
  else if (
    storedPosition.x <= 5 &&
    storedPosition.y + storedDimensions.height >= storedParentDimensions.height - 5
  ) {
    updatedLegend.x = 0;
    updatedLegend.y = parentDimensions.height - calculatedDimensions.height;
  }
  // if legend is in bottom right
  else if (
    storedPosition.x + storedDimensions.width >= storedParentDimensions.width - 5 &&
    storedPosition.y + storedDimensions.height >= storedParentDimensions.height - 5
  ) {
    updatedLegend.x = parentDimensions.width - calculatedDimensions.width;
    updatedLegend.y = parentDimensions.height - calculatedDimensions.height;
  }
  // if legend is not in a corner calculate how far away the center of the legend is (in percentage) and apply that percentage to find the position of the new legend
  else {
    const xPercentage =
      (storedPosition.x + storedDimensions.width / 2) / storedParentDimensions.width;
    const yPercentage =
      (storedPosition.y + storedDimensions.height / 2) / storedParentDimensions.height;
    updatedLegend.x =
      parentDimensions.width * xPercentage - calculatedDimensions.width / 2;
    updatedLegend.y =
      parentDimensions.height * yPercentage - calculatedDimensions.height / 2;
  }
  return updatedLegend;
};

export default getUpdateChartSizeLegend;
