import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import axios from "axios";
import { IMapRequest, setTxnId } from "store/features/map/mapSlice";

import { EMPTY_MAP_RESULT, IMapResult, getTxnId } from "api/map";

import { EntityKind } from "../../../models/entityKind";
import { RootState } from "../../../store/rootReducer";

function useMapUpdater() {
  const [mapCancelTokenSource, setMapCancelTokenSource] = useState(null);
  const dispatch = useDispatch();
  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);

  useEffect(() => {
    return () => {
      if (mapCancelTokenSource) {
        mapCancelTokenSource.cancel();
      }
    };
  }, [mapCancelTokenSource]);

  const updateMap = async (request: IMapRequest, screenshot = false) => {
    try {
      const noWellFilter = !request?.FilterId && activeEntities.includes(EntityKind.Well);
      const isWellActive = activeEntities.includes(EntityKind.Well);
      if (noWellFilter || !isWellActive) {
        // If filter is null,undefined or empty, don't update map
        return null;
      }
      if (mapCancelTokenSource != null) {
        mapCancelTokenSource.cancel();
      }
      // eslint-disable-next-line import/no-named-as-default-member
      const cancelTokenSource = axios.CancelToken.source();
      setMapCancelTokenSource(cancelTokenSource);
      const result = await getTxnId(request, cancelTokenSource.token);

      if (result.error !== null) {
        toast.error(`An error occurred generating map.`);
        return null;
      }

      if (!screenshot) {
        dispatch(
          setTxnId({
            ...result
          })
        );
      }

      return result;
    } catch (err) {
      return null;
    }
  };

  const updateWellAndFacilityMaps = async (
    wellRequest: IMapRequest,
    facilityRequest: IMapRequest,
    screenshot = false
  ) => {
    try {
      const isWellActive = activeEntities.includes(EntityKind.Well);
      const isFacilityActive = activeEntities.includes(EntityKind.Facility);
      if (!wellRequest?.FilterId && isWellActive) {
        // If filter is null,undefined or empty, don't update map
        return null;
      }
      if (mapCancelTokenSource != null) {
        mapCancelTokenSource.cancel();
      }
      // eslint-disable-next-line import/no-named-as-default-member
      const cancelTokenSource = axios.CancelToken.source();
      setMapCancelTokenSource(cancelTokenSource);
      const wellResult = activeEntities.includes(EntityKind.Well)
        ? await getTxnId(wellRequest, cancelTokenSource.token)
        : (EMPTY_MAP_RESULT as IMapResult);

      if (wellResult.error !== null) {
        toast.error(`An error occurred generating map.`);
        return null;
      }
      let facilityResult = EMPTY_MAP_RESULT;
      if (facilityRequest?.FilterId && isFacilityActive) {
        facilityResult = await getTxnId(facilityRequest, cancelTokenSource.token);
        if (facilityResult.error !== null) {
          toast.error(`An error occurred generating facility map.`);
          return null;
        }
      }

      if (!screenshot) {
        dispatch(
          setTxnId({
            ...wellResult,
            facilityLegend: facilityResult?.legend ?? {
              legendItems: [],
              title: ""
            },
            facilityId: facilityResult?.id ?? "",
            filterId: wellRequest?.FilterId
          })
        );
      }

      return wellResult;
    } catch (err) {
      return null;
    }
  };

  return { updateMap, updateWellAndFacilityMaps };
}

export default useMapUpdater;
