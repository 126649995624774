import { useMutation, useQueryClient } from "react-query";

import { updateUserSetting } from "api/userSettings";

import { useUserSettingsQueryKeys } from "../queries";

interface UserSettingT {
  id?: string;
}

export default function useUserSettingMutation<T extends UserSettingT>(
  setting: string,
  onSuccess: (data: T) => void = null,
  handleError: (err) => void = null
) {
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError, error } = useMutation(
    "update-user-setting",
    async (value: T) => {
      return updateUserSetting<T>(setting, value);
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(useUserSettingsQueryKeys.userSettings);
        onSuccess && onSuccess(data as T);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );
  return { data, mutate, isLoading, isError, error };
}
