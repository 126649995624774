import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHasProjectInitialized } from "store/features";
import { RootState } from "store/rootReducer";

import { useUser } from "hooks";

import { useClearProjectItems } from "components/project/views/hooks/useClearProjectItems";
import { useUserSettingsDefaultsQuery } from "components/user/queries";

import { useProjectContext, useProjectDispatch } from "../context";
import { useProjectListQuery } from "../queries";
import { getSelectedProjectIdFromSesssion } from "../session";
import { getProjectPermissions } from "../utils";

/**
 * Used to initialize the project, and project dependencies. With the way things are set up in the Home.tsx,
 * loading it here instead of passing the ProjectProvider an initial value (essentially what's in the useEffect),
 * allows the layout to be unnaffected by the project context not being fully initialized.
 */
export function useProjectInitialization() {
  const dispatch = useDispatch();

  const mapLayers = useSelector((state: RootState) => state.map.layers);
  const hasProjectInitialized = useSelector(
    (state: RootState) => state.project.hasProjectInitialized
  );

  const projectContext = useProjectContext();
  const projectDispatch = useProjectDispatch();

  const { user, isAtLeastPowerUser, isAtLeastAdmin, isReadonly } = useUser();

  const userSettingsDefaultsQuery = useUserSettingsDefaultsQuery();
  const projectListQuery = useProjectListQuery();

  const clearView = useClearProjectItems({ isClearingFilter: false });

  /**
   * Loading initial project
   */
  useEffect(() => {
    // Assuming the initial selected project Id has been loaded from this hook
    if (hasProjectInitialized && !projectContext.selectedProjectId) {
      dispatch(setHasProjectInitialized(false));
      return;
    }

    if (hasProjectInitialized) return;

    if (projectListQuery.isSuccess && userSettingsDefaultsQuery.isSuccess && user) {
      const defaultProjectId = Object.values(
        userSettingsDefaultsQuery?.data?.Project ?? {}
      )?.[0]?.projectId;

      // Kind of a hack, just ensuring the default project is in the list of projects.
      const matchedDefaultProjectId = projectListQuery.data?.find((project) => {
        return project.projectId === defaultProjectId;
      })?.projectId;

      const projectId =
        getSelectedProjectIdFromSesssion() ||
        matchedDefaultProjectId ||
        projectListQuery.data?.[0].projectId;

      const project =
        projectListQuery.data?.find((project) => {
          return project.projectId === projectId;
        }) || projectListQuery.data?.[0];

      const initialPermissions = getProjectPermissions(
        project,
        user,
        isAtLeastPowerUser,
        isAtLeastAdmin,
        isReadonly
      );

      projectDispatch({
        payload: {
          permissions: initialPermissions,
          project: project,
          selectedProjectId: project.projectId
        }
      });
    }
  }, [
    hasProjectInitialized,
    projectListQuery.isSuccess,
    userSettingsDefaultsQuery.isSuccess,
    user,
    isAtLeastPowerUser,
    isReadonly,
    projectContext.selectedProjectId
  ]);

  /**
   * Initialization once project dependences have been loaded
   */
  useEffect(() => {
    if (!hasProjectInitialized && projectContext.selectedProjectId && mapLayers?.length) {
      clearView.update(projectContext.selectedProjectId);

      // // TODO BF: consider moving this to a separate hook, with it's own flag.
      // // Because map layers are side loaded independently, we need to update the visibility of the layers once they've
      // // been loaded, so we know which layers to update. This does make the initial project loading dependant on the map.
      // // If we need to, we can separate this out into another hook dependant on map layers and project initialization,
      // // create a flag from that, and call it in the useManageMapboxLayerStyles hook.
      dispatch(setHasProjectInitialized(true));
    }
  }, [hasProjectInitialized, projectContext.selectedProjectId, mapLayers]);
}
