// eslint-disable-next-line import/no-named-as-default
import { toast } from "react-toastify";

import { IS_INTERNAL_ENV } from "constants/app.constants";

import { useUser } from "hooks";

import { useProjectContext } from "components/project/projects/context";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { useDeleteProjectShapefileMutation } from "components/project/shapefiles/mutations";
import { usePromoteToMcdanShapefileMutation } from "components/project/shapefiles/mutations/promote-to-mcdan-shapefile/usePromoteToMcdanShapefileMutation";

import { ShapefileActions } from "./ShapefileActions";

interface IProjectShapefileActionsProps {
  setIsRenaming?: () => void;
  shapefileId: string;
  viewShapefileDetails?: () => void;
  canExportShapefile: boolean;
}

/**
 * Some actions and properties have been left for dev to implement based on use case, since the properties are different between
 * the layer tree layers, and layer list layers.
 * @param params
 * @returns
 */
export function ProjectShapefileActions(
  params: IProjectShapefileActionsProps
): JSX.Element {
  const { selectedProjectId } = useProjectContext();
  const { user, isAtLeastAdmin } = useUser();
  const isSystemAdmin =
    isAtLeastAdmin &&
    (user?.organization?.emailDomain === "mcdan.com" ||
      user?.organization?.emailDomain === "turinganalytics.net");
  const showPromote = isSystemAdmin && IS_INTERNAL_ENV;

  const projectPermissions = useSelectedProjectPermissions();

  const deleteProjectShapefileMutation = useDeleteProjectShapefileMutation({
    projectId: selectedProjectId
  });

  const promoteShapefileMutation = usePromoteToMcdanShapefileMutation({});

  function deleteShapefile() {
    deleteProjectShapefileMutation.mutate({ shapefileId: params.shapefileId });
  }

  const promoteShapefile = showPromote
    ? (shapefilePlay: string) => {
        promoteShapefileMutation.mutate({
          shapefileId: params.shapefileId,
          shapefilePlay
        });
        toast.success("Shapefile promoted successfully");
      }
    : null;

  return (
    <ShapefileActions
      exportShapefile={params.canExportShapefile ? () => params.shapefileId : null}
      isDisabled={!projectPermissions.canEditShapefiles}
      deleteShapefile={deleteShapefile}
      promoteShapefile={promoteShapefile}
      {...params}
    />
  );
}
