// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useRef, useState } from "react";

import { mdiAlertCircle } from "@mdi/js";
import { Button, Input } from "antd";
import styled from "styled-components";
import formatPropertyName from "utils/column/formatPropertyName";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";

import { useColumns, useGlobalProductionSettings } from "hooks";

import { WellColumnDataAnalyzerResult } from "api/appTools";

import { IGroupBy } from "models";
import { EntityKind } from "models/entityKind";

import { GeomBinProvider } from "components/geom-bin/contexts/GeomBinContext";
import GroupBy from "components/groupBy/GroupBy";

import { ColumnDataAnalyzerTable } from "./components";
import { useSelectedWellFromStore, useWellColumnDataAnalyzer } from "./hooks";

const ColumnDataAnalyzer = () => {
  // State
  const [uwi, setUwi] = useState("");
  const [selectedPropertyName, setSelectedPropertyName] = useState("Header.ResourcePlay");
  const [data, setData] = useState<WellColumnDataAnalyzerResult>(null);
  const [columnWarning, setColumnWarning] = useState<string>(null);
  const [analyzerError, setAnalyzerError] = useState<string>(null);
  const [analyzerErrorDetails, setAnalyzerErrorDetails] = useState<string>(null);

  // Refs
  const uwiInputRef = useRef(null);

  // Hooks
  const selectedWell = useSelectedWellFromStore();
  const columns = useColumns();
  const globalSettings = useGlobalProductionSettings(EntityKind.Well);
  const { mutate, isLoading, isError } = useWellColumnDataAnalyzer(
    (data) => {
      setData(data);
    },
    (err) => {
      if (err.response?.data) {
        setAnalyzerErrorDetails(err.response?.data);
      } else {
        setAnalyzerErrorDetails(err.message);
      }
      setAnalyzerError("Failed to analyze column data");
    }
  );

  // Constants
  const containsFcst = selectedPropertyName.includes(".fcst");
  const propertyName = formatPropertyName(selectedPropertyName);
  const column = columns.find((c) => propertyName === c.property);
  const label = getColumnLabelWithContext(
    column,
    !!globalSettings.normalizeBy,
    globalSettings.normalizeBy,
    containsFcst
  );

  // Callbacks
  const handlePropertyChange = useCallback((property: IGroupBy) => {
    const unsupportedGroups = [
      "Performance",
      "Forecast",
      "Forecast",
      "Forecast",
      "Shapefile",
      "ShapefileGroup"
    ];
    if (unsupportedGroups.includes(property.group)) {
      setColumnWarning(`${property.group} not supported`);
    } else {
      setColumnWarning(null);
      setSelectedPropertyName(property.property);
    }
  }, []);

  const handleUwiChange = useCallback((e) => {
    setUwi(e.target.value);
  }, []);

  const startAnalysis = useCallback(() => {
    setAnalyzerError(null);
    mutate({
      uwi,
      settings: {
        propertyName
      }
    });
  }, [uwi, propertyName]);

  // Effects
  useEffect(() => {
    if (uwiInputRef.current) {
      uwiInputRef.current.focus();
    }
  }, [uwiInputRef]);

  useEffect(() => {
    setUwi(selectedWell?.Uwi);
  }, [selectedWell?.Uwi]);

  return (
    <GeomBinProvider>
      <div>
        <p>
          The Column Data Analyzer inspects all the stored well data for a specific column
          for each of the data sources. This allows you to compare different data sources
          as well as mongo data vs cached data.
        </p>
        <SettingsContainer>
          <SettingWrapper>
            <SettingTitle>UWI</SettingTitle>
            <SettingInputWrapper>
              <Input
                ref={uwiInputRef}
                value={uwi}
                onChange={handleUwiChange}
                placeholder="UWI to analyze data for"
              />
            </SettingInputWrapper>
          </SettingWrapper>
          <SettingWrapper>
            <SettingTitle>
              Column
              {columnWarning && (
                <span>
                  <Icon path={mdiAlertCircle} size={1} />
                  {columnWarning}
                </span>
              )}
            </SettingTitle>
            <SettingInputWrapper>
              <GroupBy
                excludedCategoryFilters={["Shapefile"]}
                value={column}
                entityKind={EntityKind.Well}
                onChange={(groupBy) => {
                  handlePropertyChange(groupBy);
                }}>
                <StyledButton>{label?.lightContextTitle ?? column.title}</StyledButton>
              </GroupBy>
            </SettingInputWrapper>
          </SettingWrapper>
        </SettingsContainer>
        <ActionsContainer>
          <Button
            type="primary"
            disabled={!uwi || !column}
            loading={isLoading && !isError}
            onClick={() => startAnalysis()}>
            {isLoading && !isError ? "Analyzing" : "Start Analysis"}
          </Button>
        </ActionsContainer>
        <ColumnDataAnalyzerTable
          data={data}
          isLoading={isLoading}
          analyzerError={analyzerError}
          analyzerErrorDetails={analyzerErrorDetails}
        />
      </div>
    </GeomBinProvider>
  );
};

export default ColumnDataAnalyzer;

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const SettingWrapper = styled.div`
  width: 100%;
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;

  span {
    display: flex;
    align-items: center;
    color: var(--orange);
    gap: 5px;
    font-size: 12px;
    font-weight: default;
  }
`;

const SettingInputWrapper = styled.div`
  margin-top: 5px;
`;

const StyledButton = styled(Button)`
  position: relative;
  text-align: center;
  width: 100%;
  background-color: rgba(var(--color-text-rgb), 0.05);
  color: var(--color-text);

  &:hover {
    background-color: var(--color-text);
    color: #fff;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  padding-top: 20px;
`;
