import { Children, cloneElement, isValidElement, memo, useEffect, useState } from "react";

import "./RadioTabs.scss";

export const RadioTab = ({
  label = "",
  name = "",
  tooltip = null,
  onClick = null,
  value = null,
  isChecked = false,
  isDisabled = false,
  ...otherProps
}): JSX.Element => {
  return (
    <div
      className={`RadioTab ${isChecked ? "isChecked" : ""} ${
        isDisabled ? "isDisabled" : ""
      }`}
      onClick={onClick}>
      <div className="radio-container">
        <input
          type="radio"
          name={name || value}
          value={value}
          checked={isChecked}
          onChange={() => void 0}
          {...otherProps}
        />
        <div className={`circle ${isChecked ? "isChecked" : ""}`} />
        {label}
        {!!tooltip && { tooltip }}
      </div>
    </div>
  );
};

export interface RadioTabsModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  id?: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
}

function RadioTabs({
  value,
  disabled = false,
  className = "",
  onChange = null,
  children,
  ...otherProps
}: RadioTabsModel) {
  const [localValue, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);

  function handleChange(value) {
    setValue(value);
    onChange && onChange(value);
  }
  return (
    <div
      className={`RadioTabs flex-row  ${disabled ? "disabled" : ""}
      ${className}`}
      {...otherProps}>
      {Children.toArray(children).map((tab, index) => {
        if (
          isValidElement<{
            value: string;
            disabled: boolean;
            label: string;
            isChecked: boolean;
            onClick: (any) => void;
          }>(tab)
        ) {
          return cloneElement(tab, {
            key: tab.props.value,
            disabled: tab.props.disabled,
            isChecked: tab.props.value === localValue,
            onClick: () => handleChange(tab.props.value)
          });
        }
        return <div key={index}></div>;
      })}
    </div>
  );
}

export default memo(RadioTabs);
