import { Select } from "antd";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";
import { useGroupedProductOptions } from "./hooks";

export default function ProductSelect() {
  const productOptions = useGroupedProductOptions();

  const { selectedProduct } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();

  const changeProduct = () => (value) => {
    return updateGroupByState({ type: "selected_product", payload: value });
  };

  return (
    <Select
      data-testid="product-select"
      value={selectedProduct}
      onChange={changeProduct()}
      showSearch={true}
      options={productOptions}
      popupClassName="modal-select"
    />
  );
}
