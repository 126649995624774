import styled from "styled-components";
import { capitalizeFirstLetter } from "utils";

const Badge = ({ children, variation = "default" }) => {
  const variationCapitalized = capitalizeFirstLetter(variation);
  return (
    <Wrapper className="Badge" variation={variationCapitalized}>
      {children}
    </Wrapper>
  );
};

export default Badge;

const Wrapper = styled.span`
  display: inline-block;
  font-size: 1.4rem;
  color: var(--badgeColor ${(props) => props.variation});
  background-color: var(--badgeBackground ${(props) => props.variation});
  border-radius: 100rem;
  padding: 0px 6px;
  text-align: center;
`;
