import { memo } from "react";

function GPWater() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
      <defs>
        <path d="M0 0L29.5 0L29.5 22L0 22L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use href="#path_1" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPWater">
        <path
          d="M0 0L30 0L30 30L0 30L0 0Z"
          id="Rectangle-Copy-18"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Water" transform="translate(0.46990013 4.885146)">
          <path
            d="M0 0L29.5 0L29.5 22L0 22L0 0Z"
            id="Mask-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_1)">
            <g id="Group-2" transform="translate(0 2.4942544)">
              <path
                d="M39.7579 2.27331C37.7526 2.27331 35.8848 1.81654 34.3238 1.03092C31.5895 -0.34364 28.048 -0.34364 25.3137 1.03092C23.7513 1.81654 21.8849 2.27331 19.8796 2.27331C17.873 2.27331 16.0066 1.81654 14.4442 1.03092C11.7099 -0.34364 8.16833 -0.34364 5.43409 1.03092C3.87305 1.81654 2.00529 2.27331 0 2.27331"
                transform="translate(0 6.769231)"
                id="Stroke-1-Copy-5"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M39.7579 2.27331C37.7526 2.27331 35.8848 1.81654 34.3238 1.03092C31.5895 -0.34364 28.048 -0.34364 25.3137 1.03092C23.7513 1.81654 21.8849 2.27331 19.8796 2.27331C17.873 2.27331 16.0066 1.81654 14.4442 1.03092C11.7099 -0.34364 8.16833 -0.34364 5.43409 1.03092C3.87305 1.81654 2.00529 2.27331 0 2.27331"
                transform="translate(0 13.108681)"
                id="Stroke-4-Copy-4"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M39.7579 2.27331C37.7526 2.27331 35.8848 1.81654 34.3238 1.03092C31.5895 -0.34364 28.048 -0.34364 25.3137 1.03092C23.7513 1.81654 21.8849 2.27331 19.8796 2.27331C17.873 2.27331 16.0066 1.81654 14.4442 1.03092C11.7099 -0.34364 8.16833 -0.34364 5.43409 1.03092C3.87305 1.81654 2.00529 2.27331 0 2.27331"
                id="Stroke-4-Copy-6"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(GPWater);
