import { Icon } from "@mdi/react";
import { useSelector } from "react-redux";

import { mdiCheck, mdiContentSavePlus } from "@mdi/js";
import { RootState } from "store/rootReducer";

import { TypeWellAction } from "./TypeWellAction";

interface SaveAsToggleProps {
  setCloneTypeWellOpen: (isOpen: boolean) => void;
  setIsPopoverOpen: (isOpen: boolean) => void;
}

export function SaveAsToggle(props: SaveAsToggleProps) {
  const { setCloneTypeWellOpen, setIsPopoverOpen } = props;

  const hasWellDataError = useSelector((state: RootState) => state.arps.hasWellDataError);
  const isTypeWellSavable = useSelector(
    (state: RootState) => state.arps.isTypeWellSavable
  );

  return (
    <TypeWellAction
      onClick={() => {
        setCloneTypeWellOpen(true);
        setIsPopoverOpen(false);
      }}
      disabled={hasWellDataError}>
      {isTypeWellSavable ? (
        <Icon path={mdiContentSavePlus} size={1} />
      ) : (
        <Icon path={mdiCheck} size={1} />
      )}
      Save As
    </TypeWellAction>
  );
}
