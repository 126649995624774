/**
 *
 * @param el
 * @returns
 */
export const isIntersectingViewport = (el) => {
  const rect = el.getBoundingClientRect();

  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

  const isIntersecting = {
    top: rect.top < 0,
    left: rect.left < 0,
    bottom: rect.bottom > viewportHeight,
    right: rect.right > viewportWidth,
    any: false,
    offset: null
  };

  // if any of the edges are intersecting
  isIntersecting.any =
    isIntersecting.top ||
    isIntersecting.left ||
    isIntersecting.bottom ||
    isIntersecting.right;

  // add offset values
  const offset = {
    top: isIntersecting.top ? rect.top : null,
    left: isIntersecting.left ? rect.left : null,
    right: isIntersecting.right ? rect.right - viewportWidth : null,
    bottom: isIntersecting.bottom ? rect.bottom - viewportHeight : null
  };
  isIntersecting.offset = offset;

  return isIntersecting;
};
