import { MenuProps } from "antd";

export const typeWellProductsMenuItems: MenuProps["items"] = [
  {
    label: "Oil",
    key: "OIL"
  },
  {
    label: "Condensate",
    key: "CONDENSATE"
  },
  {
    label: "Gas",
    key: "GAS"
  },
  {
    label: "Sales Gas",
    key: "SALES GAS"
  },
  {
    label: "Water",
    key: "WATER"
  },
  {
    type: "divider"
  },
  {
    label: "O+W",
    key: "O+W"
  },
  {
    label: "CGR",
    key: "CGR"
  },
  {
    label: "GOR",
    key: "GOR"
  },
  {
    label: "OGR",
    key: "OGR"
  },
  {
    label: "WGR",
    key: "WGR"
  },
  {
    label: "WOR",
    key: "WOR"
  }
];
