import { useMutation } from "react-query";

import axios from "axios";

const COLOR_SVC_URI = process.env.REACT_APP_COLOR_SERVICE;

export default function useDeletePalette(onSuccess) {
  const result = useMutation(
    "delete-palette",
    async (name: string) => {
      const response = await axios.delete<boolean>(
        `${COLOR_SVC_URI}/palette/user/${name}`
      );
      if (response.status !== 200) {
        throw "Unable to fetch color palettes.";
      }
      return true;
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      }
    }
  );
  return result;
}
