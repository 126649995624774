import { ALL_PRODUCT_TYPES } from "constants/chart.constants";

import { IProductType } from "models/chart";

/**
 * Get product-type for given label.
 *
 * @param {string} label label of product type
 * @returns {IProductType} product-type
 */
const getProductType = (label: string): IProductType => {
  if (!label) {
    return ALL_PRODUCT_TYPES.Oil;
  }
  label = label
    .replace("\u{2082}", "_2")
    .replace("\u{2083}", "3")
    .replace("\u{2084}", "4"); //replace unicode subscript with normal numbers
  // TODO: add shims and jest.config.js to pass tests for replaceAll

  let productTypeKey = label.replace(/[ ,+]+/g, "");

  if (label == ALL_PRODUCT_TYPES.Oil.label) {
    productTypeKey = ALL_PRODUCT_TYPES.Oil.key;
  }
  if (label == ALL_PRODUCT_TYPES.CGR.label) {
    productTypeKey = ALL_PRODUCT_TYPES.CGR.key;
  }

  if (label == ALL_PRODUCT_TYPES.OnePlusWOR.label) {
    return ALL_PRODUCT_TYPES.OnePlusWOR;
  }

  if (label == ALL_PRODUCT_TYPES.OnTime.label) {
    return ALL_PRODUCT_TYPES.OnTime;
  }

  if (label == ALL_PRODUCT_TYPES.TotalFluid.label) {
    return ALL_PRODUCT_TYPES.TotalFluid;
  }

  //GasEnergyYieldsSales is really HeatingValue, but we don't want to change the label
  if (label == ALL_PRODUCT_TYPES.GasEnergyYieldsSales.label || label == "HeatingValue") {
    return ALL_PRODUCT_TYPES.GasEnergyYieldsSales;
  }

  if (label === ALL_PRODUCT_TYPES.CasingPressure.label) {
    return ALL_PRODUCT_TYPES.CasingPressure;
  }

  if (label === ALL_PRODUCT_TYPES.TubingPressure.label) {
    return ALL_PRODUCT_TYPES.TubingPressure;
  }

  if (label === ALL_PRODUCT_TYPES.CO_2.label.replace("\u{2082}", "2")) {
    return ALL_PRODUCT_TYPES.CO_2;
  }

  if (label === ALL_PRODUCT_TYPES.CO_2Inj.label.replace("\u{2082}", "2")) {
    return ALL_PRODUCT_TYPES.CO_2Inj;
  }

  if (label === ALL_PRODUCT_TYPES.CO_2YieldsInlet.label.replace("\u{2082}", "2")) {
    return ALL_PRODUCT_TYPES.CO_2YieldsInlet;
  }

  if (label === ALL_PRODUCT_TYPES.CO_2YieldsOutlet.label.replace("\u{2082}", "2")) {
    return ALL_PRODUCT_TYPES.CO_2YieldsOutlet;
  }

  let productType = ALL_PRODUCT_TYPES[productTypeKey];

  // last resort case
  // This was primarily added for the sulphur yield products, which has a different
  // label than the product keys.
  // For the other midstream yield products, there is a correponding well product,
  // and therefore the previous case should return a product (albeit the wrong one)
  // "label" is sometimes passed into the function with spaces stripped, and
  // thus that case needs to be accounted for.
  if (!productType) {
    productType = Object.values(ALL_PRODUCT_TYPES).find((productType) => {
      const strippedProductKey = productType.key.replace(/[ ,+]+/g, "");
      if (productType.key === label || strippedProductKey === label) {
        return productType;
      }
    });
  }

  if (productType) {
    return productType;
  }
  throw "invalid product type - " + label + " key: " + productTypeKey + "";
};

export default getProductType;
