import { memo } from "react";

function MaterialBalanceSheet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 25 25">
      <path
        d="M1.125 3.125V23.125H21.125"
        stroke="currentColor"
        fill="none"
        strokeWidth="2.25"
      />
      <path
        d="M5.875 12.5C5.875 12.7071 5.70711 12.875 5.5 12.875C5.29289 12.875 5.125 12.7071 5.125 12.5C5.125 12.2929 5.29289 12.125 5.5 12.125C5.70711 12.125 5.875 12.2929 5.875 12.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path
        d="M13.875 14.5C13.875 14.7071 13.7071 14.875 13.5 14.875C13.2929 14.875 13.125 14.7071 13.125 14.5C13.125 14.2929 13.2929 14.125 13.5 14.125C13.7071 14.125 13.875 14.2929 13.875 14.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path
        d="M10.875 5.5C10.875 5.70711 10.7071 5.875 10.5 5.875C10.2929 5.875 10.125 5.70711 10.125 5.5C10.125 5.29289 10.2929 5.125 10.5 5.125C10.7071 5.125 10.875 5.29289 10.875 5.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path
        d="M19.875 10.5C19.875 10.7071 19.7071 10.875 19.5 10.875C19.2929 10.875 19.125 10.7071 19.125 10.5C19.125 10.2929 19.2929 10.125 19.5 10.125C19.7071 10.125 19.875 10.2929 19.875 10.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path d="M3 9.12891H13" stroke="currentColor" strokeWidth="2.25" />
      <path
        d="M12.2812 8.93555L19.7446 14.9672"
        stroke="currentColor"
        strokeWidth="2.25"
      />
    </svg>
  );
}

export default memo(MaterialBalanceSheet);
