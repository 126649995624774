import { useDispatch } from "react-redux";

import {
  setOrganizationSettingsOpen,
  setUserSettingsOpen
} from "store/features/userSettings/userSettingsSlice";
import styled from "styled-components";

import { IconLogo } from "components/icons";

const Logo = () => {
  const dispatch = useDispatch();
  function closeSettings() {
    dispatch(setOrganizationSettingsOpen(false));
    dispatch(setUserSettingsOpen(false));
  }
  return (
    <Wrapper onClick={() => closeSettings()}>
      <IconLogo />
    </Wrapper>
  );
};

export default Logo;

const Wrapper = styled.div`
  display: inline-flex;
`;
