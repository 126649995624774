const default_transform = { scaleX: 0, scaleY: 0 };

interface Scale {
  scaleX?: number;
  scaleY?: number;
}

/**
 * Parses the transforms applied on given element, and return an object with each transform as its properties.
 *
 * @param {HTMLElement} el dom element
 * @returns {Object} object of transforms as key-value pairs
 */
export const parseTransform = (el): Scale => {
  const transform = getComputedStyle(el).transform;

  if (transform === "none") {
    return default_transform;
  }

  const matrixProps = ["scaleX", "skewY", "skewX", "scaleY", "translateX", "translateY"];

  /*
    Example: matrix3d(a, b, 0, 0, c, d, 0, 0, 0, 0, 1, 0, tx, ty, 0, 1)
    Map each value in the css matrix to a transform property
  */
  const result: Scale = {};

  transform
    .split(/\(|,|\)/) // ['matrix3d', 'a', ' b', ' 0', ' 0', ' c', ' d', ' 0', ' 0', ' 0', ' 0', ' 1', ' 0', ' tx', ' ty', ' 0', ' 1', '']
    .slice(1, -1) // ['a', ' b', ' 0', ' 0', ' c', ' d', ' 0', ' 0', ' 0', ' 0', ' 1', ' 0', ' tx', ' ty', ' 0', ' 1']
    .map((value, index) => (result[matrixProps[index]] = parseFloat(value)));

  return result;
};
