import { EChartOption } from "echarts";

import { IMPCSeriesLine } from "components/multiphase-chart/models/options.models";

/**
 *
 * @param options
 * @returns options with series updated to their original styling
 */
const getOptionsUnhighlighted = (options: EChartOption): EChartOption => {
  if (!options?.series) return {};

  const updatedSeries: IMPCSeriesLine[] = options.series.map((trace: IMPCSeriesLine) => {
    trace.lineStyle.width = trace.lineStyle.originalWidth;
    trace.lineStyle.color = trace.lineStyle.originalColor;
    trace.lineStyle.opacity = 1.0;

    return trace;
  });

  const newOptions = { ...options, series: updatedSeries };
  return newOptions;
};

export default getOptionsUnhighlighted;
