import { IScreenshotAbbreviation } from "models/screenshot";

export const applyAbbreviations = (
  text: string,
  abbreviations: IScreenshotAbbreviation[]
): string => {
  let modifiedText = text;

  abbreviations.forEach((abbreviation: IScreenshotAbbreviation) => {
    const escapeRegExp = (string: string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const flags = abbreviation.caseSensitive ? "g" : "gi";
    const escapedCurrent = escapeRegExp(abbreviation.current);
    const regex = new RegExp(escapedCurrent, flags);
    modifiedText = modifiedText.replace(regex, abbreviation.abbreviation);
  });

  return modifiedText;
};
