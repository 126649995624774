import formatPropertyName from "utils/column/formatPropertyName";

import { useColumns } from "hooks";

import { useUserDefaults } from "components/user/hooks";

import { useFacilityColumns } from "../../../hooks/useColumns";
import { EntityKind } from "../../../models/entityKind";

const useTileColumns = (entityKind: EntityKind) => {
  const columns = useColumns();
  const facilityColumns = useFacilityColumns();
  const defaults = useUserDefaults();

  const tiles =
    entityKind == EntityKind.Well
      ? defaults?.focusTiles?.tiles ?? []
      : defaults?.facilityFocusTiles?.tiles ?? [];

  const getColumnsByEntityKind = () =>
    entityKind === EntityKind.Well ? columns : facilityColumns;

  const tileColumns = tiles?.reduce((list, tile) => {
    const propertyName = formatPropertyName(tile);
    const column = getColumnsByEntityKind().find((c) => propertyName === c.property);
    if (!column) return list;

    list.push(column);
    return list;
  }, []);

  const isLoading = tileColumns?.length === 0;

  return {
    isLoading,
    tileColumns
  };
};

export default useTileColumns;
