// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useState } from "react";

import { mdiDelete } from "@mdi/js";
import { Button, Checkbox, Input, Select, Tooltip } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { Calculation, Field, Raster } from "./models/mapLayer";

interface MapProps {
  mapTypes: Field[];
  map: { Raster?: Raster; Calculation?: Calculation };
  onDeleteMap: () => void;
  onUseExpressionChanged?: (checked: boolean) => void;
  onExpressionChanged: (expression: string) => Promise<void>;
  onMapNameChanged: (value: string) => void;
  onChange: (o: { min?: number; max?: number; unit?: string }) => Promise<void>;
}

export const MapRow: React.FC<MapProps> = ({
  mapTypes,
  map,
  onUseExpressionChanged,
  onExpressionChanged,
  onDeleteMap,
  onMapNameChanged,
  onChange
}) => {
  const [min, setMin] = useState<number>((map.Raster ?? map.Calculation)?.min ?? null);
  const [max, setMax] = useState<number>((map.Raster ?? map.Calculation)?.max ?? null);

  const [localExpression, setLocalExpression] = useState<string>(
    (map.Raster ?? map.Calculation)?.transformer?.expression ?? ""
  );
  useEffect(() => {
    setLocalExpression((map.Raster ?? map.Calculation)?.transformer?.expression ?? "");
    setMin((map.Raster ?? map.Calculation)?.min ?? null);
    setMax((map.Raster ?? map.Calculation)?.max ?? null);
  }, [map, onMapNameChanged]);

  const debouncedOnExpression = useCallback(
    _debounce(async (val) => {
      await onExpressionChanged(val);
    }, 500),
    [onExpressionChanged]
  );
  const debouncedOnChange = useCallback(
    _debounce(async (val) => {
      await onChange(val);
    }, 500),
    [onChange]
  );
  const useExpression = !!map.Calculation || (map.Raster && !!map.Raster.transformer);
  return (
    <MapLayout>
      <Select
        showSearch
        value={(map.Raster ?? map.Calculation)?.name}
        onSearch={(e) => {
          onMapNameChanged(e);
        }}
        onChange={(e: string) => {
          onMapNameChanged(e);
        }}
        options={mapTypes.map((type) => ({ label: type.name, value: type.name }))}
      />

      <Input
        style={{ width: "80px" }}
        bordered={false}
        onChange={async (e) => {
          await onChange({ unit: e.target.value, ...map });
        }}
        placeholder={"Unit"}
        value={(map.Raster ?? map.Calculation)?.unit ?? ""}
      />
      <Input
        type={"number"}
        data-testid={"geo-map-min-input"}
        className="min number-input"
        onChange={(e) => {
          const val = e.target.valueAsNumber;
          setMin(val);
          debouncedOnChange({ ...(map.Raster ?? map.Calculation), min: val });
        }}
        value={min}
        placeholder={"Min"}
        bordered={false}
      />
      <Input
        type="number"
        data-testid={"geo-map-max-input"}
        className="max number-input"
        onChange={(e) => {
          const val = e.target.valueAsNumber;
          setMax(val);
          debouncedOnChange({ ...(map.Raster ?? map.Calculation), max: val });
        }}
        value={max}
        placeholder={"Max"}
        bordered={false}
      />
      {map.Raster && (
        <Tooltip title={"Override Values"}>
          <Checkbox
            checked={useExpression}
            onChange={(e) => {
              onUseExpressionChanged(e.target.checked);
            }}></Checkbox>
        </Tooltip>
      )}
      {map.Raster && !useExpression && (
        <Input value={map.Raster.filepath} disabled={true} />
      )}

      {(useExpression || !!map.Calculation) && (
        <Input
          value={localExpression}
          onChange={(e) => {
            const val = e.target.value;
            setLocalExpression(val);
            debouncedOnExpression(val);
          }}
          disabled={!useExpression}
        />
      )}
      <Button icon={<Icon path={mdiDelete} size={1.2} />} onClick={onDeleteMap} />
    </MapLayout>
  );
};

const MapLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  .ant-select {
    min-width: 140px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-btn {
    min-width: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .number-input {
    max-width: 65px;
  }
`;
