import { useEffect, useState } from "react";
import { Portal } from "react-portal";

import { BaseIcon } from "./";
import "./Drawer.scss";

export const drawer_positions = Object.freeze({
  left: "left",
  right: "right"
});
export const drawer_widths = Object.freeze({
  narrow: "narrow",
  medium: "medium",
  wide: "wide"
});
export default function Drawer({
  isOpen = false,
  position = drawer_positions.left,
  children = null,
  width = drawer_widths.medium,
  onClose
}) {
  function close() {
    setOpen(false);
    onClose();
  }

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function drawerActionIcon() {
    return `arrow-${drawer_positions[position]}`;
  }
  return (
    open && (
      <Portal>
        <div className={`drawer-overlay fixed ${open ? "open" : ""}`}>
          <div
            className="drawer-background fixed pin-topleft pin-bottomright"
            onClick={close}></div>

          <div className={`drawer flex fixed ${position} ${width} ${open ? "open" : ""}`}>
            <div className="drawer-action" onClick={close}>
              <BaseIcon iconName={drawerActionIcon()} />
            </div>
            <div className="drawer-content">{children}</div>
          </div>
        </div>
      </Portal>
    )
  );
}
