import { memo } from "react";

import "./BaseTooltip.scss";

const positions = {
  top: "top",
  left: "left",
  right: "right",
  bottom: "bottom"
};
function BaseTooltip({
  text = "",
  position = positions.bottom,
  children,
  style = null,
  className = ""
}) {
  return (
    <div className={`BaseTooltip ${position} ${className}`} data-tip={text} style={style}>
      {children}
    </div>
  );
}
export default memo(BaseTooltip);
