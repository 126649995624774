import styled from "styled-components";

import CategoryList from "./CategoryList";
import FilterableFieldList from "./FilterableFieldList";
import SearchBar from "./SearchBar";
import { useCategoryList, useProps } from "./hooks";

function GroupByWrapper({
  dataTypeFilters = [],
  excludedCategoryFilters = [],
  editable = true,
  value = null,
  // To be used in VH value if necessary for the wrapper max-height
  // Potential use case: when the modal has jumped to the top of the screen, decrease the height to fit appropriately
  // height = null,
  onChange,
  chartType = null,
  axisType = null,
  entityKind
}): JSX.Element {
  useCategoryList(entityKind); // syncs categoryList in redux-store with context
  useProps({
    dataTypeFilters,
    excludedCategoryFilters,
    editable,
    value,
    onChange,
    chartType,
    axisType
  }); // sets initial state

  return (
    <Wrapper>
      <SearchBar />
      <Content>
        <CategoryList />
        <FilterableFieldList />
      </Content>
    </Wrapper>
  );
}

export default GroupByWrapper;

const Wrapper = styled.div`
  --category-col-width: max-content;
  --field-col-width: 300px;
  --search-width: 180px;
  display: grid;
  grid-template-rows: max-content 1fr;
  gap: 1px;
  min-height: 200px;
  max-height: 800px;
  height: ${(props) => props.offset || 65}vh;
  color: var(--color-text);
  background-color: var(--color-accent);
  border-radius: 4px;
  box-shadow: 0 1px 16px 0 rgba(130, 169, 217, 0.15);
  overflow: hidden;
`;

const Content = styled.div`
  min-height: 0;
  display: grid;
  grid-template-columns: var(--category-col-width) 1fr;
  align-items: stretch;
  justify-items: stretch;
  gap: 1px;
  overflow: hidden;
`;
