import { TLockKeys } from "./axisTypes";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCombinedXValues(series: any[], axisIndex: number) {
  const xAxisValues = series
    .filter((series) => {
      return series.xAxisIndex === axisIndex;
    })
    .reduce((currentData, series) => [...currentData, ...series.x], []);

  return xAxisValues;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCombinedYValues(series: any[], axisIndex: number) {
  const yAxisValues = series
    .filter((series) => {
      return series.xAxisIndex === axisIndex;
    })
    .reduce((currentData, series) => [...currentData, ...series.x], []);

  return yAxisValues;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAxesDateMinMax(series: any[], axisIndex: number, lockKey: TLockKeys) {
  if (!series?.length) return null;

  let axisMinMax = null;

  switch (lockKey) {
    case "xMax": {
      const xAxisValues = getCombinedXValues(series, axisIndex);
      const dates = xAxisValues.map((dateStr) => new Date(dateStr));

      axisMinMax = new Date(Math.max.apply(null, dates));
      break;
    }
    case "yMax": {
      const yAxisValues = getCombinedYValues(series, axisIndex);
      const dates = yAxisValues.map((dateStr) => new Date(dateStr));

      axisMinMax = new Date(Math.max.apply(null, dates));
      break;
    }
    case "xMin": {
      const xAxisValues = getCombinedXValues(series, axisIndex);
      const dates = xAxisValues.map((dateStr) => new Date(dateStr));

      axisMinMax = new Date(Math.min.apply(null, dates));
      break;
    }
    case "yMin": {
      const yAxisValues = getCombinedYValues(series, axisIndex);
      const dates = yAxisValues.map((dateStr) => new Date(dateStr));

      axisMinMax = new Date(Math.min.apply(null, dates));
      break;
    }
    default: {
      axisMinMax = null;
      break;
    }
  }

  return axisMinMax ? axisMinMax.getFullYear().toString() : null;
}
