import React, { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

import { produce } from "immer";

import { IChartScreenshotSettings } from "models/chart";
import { IChartResult } from "models/model";

import { tabs } from "../constants/shared.constants";
import { IChartPreset, IMultiphaseChartSettings } from "../models/shared.models";
import {
  ChartReducerActionsT,
  chartSettingsReducer
} from "./reducer/chartSettingsReducer";

export type ScreenshotT = {
  visible: boolean;
  preset: IChartScreenshotSettings | null;
};

export type LegendT = {
  visible: boolean;
  type?: "bottom" | "inplot";
  position?: "NW" | "NE" | "SW" | "SE";
};

export type IAxisMinMax = {
  xMin?: number;
  xMax?: number;
  yMin?: number;
  yMax?: number;
};

export interface IChartSettingsState {
  id: string;
  bounds: DOMRect | null;
  currentPreset: IChartPreset;
  currentTab: string;
  error: string;
  isFetching: boolean;
  isSettingsOpen: boolean;
  isViewMode: boolean;
  isFullscreen: boolean;
  savedPresets: IChartPreset[];
  storedPreset: IChartPreset;
  isMouseOver: boolean;
  isReadOnly: boolean;
  screenshot: ScreenshotT;
  settings: IMultiphaseChartSettings;
  data: IChartResult | undefined;
  legend: LegendT;
  axisMinMax: IAxisMinMax[];
  overflowIndex: number;
}

export const initialPresetState: IChartPreset = {
  // TODO: clean up chartType redundancy
  chartType: "monthlyRateCum",
  chartTypeId: 1,
  id: "",
  mBblPosition: "bottom",
  mMcfPosition: "bottom",
  name: undefined,
  ownerId: "",
  products: [],
  rateType: "",
  roleType: "Personal",
  style: []
};

export const initialChartSettingsState: IChartSettingsState = {
  id: "",
  bounds: null,
  currentPreset: initialPresetState,
  currentTab: tabs.chartSettings,
  error: undefined,
  isFetching: false,
  isSettingsOpen: false,
  isViewMode: true,
  isFullscreen: false,
  savedPresets: undefined,
  storedPreset: initialPresetState,
  isMouseOver: false,
  isReadOnly: false,
  screenshot: {
    visible: false,
    preset: null
  },
  settings: {
    useNormalizeBy: false,
    normalizeBy: {
      field: "Well_Design.HzLength_m",
      display: "Hz Length (m)",
      per: 100,
      unit: "m",
      useMultilinearNormalization: true,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    logs: [
      { x: false, y: false },
      { x: false, y: false },
      { x: false, y: false },
      { x: false, y: false }
    ],
    forecast: true,
    producing: false,
    debugMode: true,
    backfit: true
  },
  data: undefined,
  legend: {
    visible: false,
    type: "bottom",
    position: "NE"
  },
  axisMinMax: [
    {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null
    },
    {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null
    },
    {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null
    },
    {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null
    }
  ],
  overflowIndex: undefined
};

export type ChartSettingsDispatchT = (action: ChartReducerActionsT) => void;
export type ChartSettingsProviderT = {
  children: ReactNode;
  initialState?: IChartSettingsState;
};

const curriedReducer = produce(chartSettingsReducer);

const ChartSettingsContext =
  createContext<[IChartSettingsState, React.Dispatch<ChartReducerActionsT>]>(undefined);
ChartSettingsContext.displayName = "ChartSettingsContext";

const ChartSettingsProvider = ({ children, initialState }: ChartSettingsProviderT) => {
  const newState = initialState?.settings ? initialState : initialChartSettingsState;

  const [state, dispatch] = useReducer(curriedReducer, newState);
  const value: [IChartSettingsState, ChartSettingsDispatchT] = useMemo(
    () => [state, dispatch],
    [state]
  );

  return (
    <ChartSettingsContext.Provider value={value}>
      {children}
    </ChartSettingsContext.Provider>
  );
};

const useChartSettings = () => {
  const context = useContext(ChartSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useMultiPhaseChartSettings must be used within ChartSettingsProvider"
    );
  }
  return context;
};

export { curriedReducer, ChartSettingsProvider, useChartSettings };
