import useResizeObserver from "@react-hook/resize-observer";
import { useState } from "react";

import { Tooltip } from "components/base";

export type FieldOverflowTooltipT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  title: string;
  titleId: string;
};

function FieldOverflowTooltip({ children, title, titleId }: FieldOverflowTooltipT) {
  const [hasOverflow, setHasOverflow] = useState(false);
  const titleElement = document.getElementById(titleId);

  //checking if the title has overflow, used to determine whether a tooltip is neede
  useResizeObserver(titleElement, () => {
    if (titleElement) {
      if (
        titleElement.getBoundingClientRect().width === 0 &&
        titleElement.scrollWidth === 0
      ) {
        return;
      }
      setHasOverflow(titleElement.offsetWidth < titleElement.scrollWidth);
    }
  });

  return (
    <>
      {hasOverflow ? (
        <Tooltip title={title} placement="right">
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default FieldOverflowTooltip;
