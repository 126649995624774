// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, { RefObject, useEffect, useState } from "react";

import { mdiPlus } from "@mdi/js";
import { Alert, Button, Input, Modal, Typography } from "antd";
import { AxiosError } from "axios";
import styled from "styled-components";

import { GeoMapSettings } from "./models/settings";

export interface GeoMapSettingsProps {
  settings: GeoMapSettings;
  showSettings: boolean;
  containerRef: RefObject<HTMLDivElement>;
  onSave: (settings: GeoMapSettings) => Promise<void>;
  onCancel: () => void;
}

export function SettingsModal({
  settings,
  containerRef,
  showSettings,
  onCancel,
  onSave
}: GeoMapSettingsProps) {
  const [error, setError] = useState<AxiosError>(undefined);
  const [customFields, setCustomFields] = useState(settings?.customFields ?? []);

  useEffect(() => {
    setCustomFields(settings?.customFields ?? []);
  }, [settings]);

  if (!settings) {
    return null;
  }

  return (
    <Modal
      open={showSettings}
      onCancel={onCancel}
      width={780}
      title={"Custom Field Settings"}
      onOk={async () => {
        setError(undefined);
        try {
          await onSave(
            new GeoMapSettings(settings.id, customFields, settings.systemFields)
          );
        } catch (err) {
          setError(err);
        }
      }}
      getContainer={() => containerRef.current}>
      <RootContainer>
        <Table>
          <Header>
            <Col width={160}>
              <Typography.Title level={5}>Name</Typography.Title>
            </Col>
            <Col>
              <Typography.Title level={5}>Unit</Typography.Title>
            </Col>
            <Col>
              <Typography.Title level={5}>Min</Typography.Title>
            </Col>
            <Col>
              <Typography.Title level={5}>Max</Typography.Title>
            </Col>
            <Col width={200}>
              <Typography.Title level={5}>Notes</Typography.Title>
            </Col>
          </Header>
          {customFields.map((field, i) => {
            return (
              <Row key={i}>
                <Col width={160}>
                  <Input
                    type="text"
                    value={field.name}
                    onPressEnter={() => {
                      setCustomFields([
                        ...customFields,
                        { name: "", unit: null, min: null, max: null }
                      ]);
                    }}
                    onChange={(e) => {
                      field.name = e.target.value;
                      setCustomFields([...customFields]);
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    onChange={(e) => {
                      field.unit = e.target.value;
                      setCustomFields([...customFields]);
                    }}
                    placeholder={"Unit"}
                    value={field.unit}
                  />
                </Col>
                <Col>
                  <Input
                    type={"number"}
                    data-testid={"geo-map-min-input"}
                    className="min number-input"
                    value={field.min}
                    placeholder={"Min"}
                    onChange={(e) => {
                      field.min = e.target.valueAsNumber;
                      setCustomFields([...customFields]);
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    type={"number"}
                    data-testid={"geo-map-min-input"}
                    className="min number-input"
                    value={field.max}
                    placeholder={"Max"}
                    onChange={(e) => {
                      field.max = e.target.valueAsNumber;
                      setCustomFields([...customFields]);
                    }}
                  />
                </Col>
                <Col width={200}>
                  <Input
                    type={"text"}
                    data-testid={"geo-map-min-input"}
                    className="min number-input"
                    value={field.notes}
                    placeholder={"Notes"}
                    onChange={(e) => {
                      field.notes = e.target.value;
                      setCustomFields([...customFields]);
                    }}
                  />
                </Col>
              </Row>
            );
          })}
        </Table>
        <ButtonWrapper>
          <Button
            icon={<Icon path={mdiPlus} size={1.0} />}
            type="primary"
            onClick={() => {
              setCustomFields([
                ...customFields,
                { name: "", unit: null, min: null, max: null }
              ]);
            }}>
            Add New Row
          </Button>
        </ButtonWrapper>
        {error && (
          <Alert
            type={"error"}
            message={error.response?.data ?? error?.message}
            showIcon
          />
        )}
      </RootContainer>
    </Modal>
  );
}
const Table = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`;
const RootContainer = styled.div`
  padding: 1rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

const Header = styled.div`
  display: table-row;
`;

const Row = styled.div`
  display: table-row;
  justify-content: space-between;
  padding: 10px;
`;
const Col = styled.div`
  display: table-cell;
  padding: 2px;
  width: ${(props) => (props.width ? props.width + "px" : "100px")};
`;
