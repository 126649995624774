import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { booleanOverlap, difference } from "@turf/turf";
import { RootState } from "store/rootReducer";
import { rgbStringToHex } from "utils/color/rgbHelpers";

import { useMapContext } from "components/map/hooks/useMapContext";
import { IColorPalette } from "components/user-settings/models";

import { useGeomBinContext } from "./hooks/useGeomBinContext";
import { useGeomBinDispatch } from "./hooks/useGeomBinDispatch";

const DEFAULT_COLOR = "rgb(0,0,0)"; //Black

export interface LassoSelectionComponentModel {
  drawRef;
  portalRef?: React.MutableRefObject<HTMLDivElement>;
}

export default function GeomBinDrawer({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  portalRef,
  drawRef
}: LassoSelectionComponentModel): JSX.Element {
  const { mapbox } = useMapContext();
  const geomBinDispatch = useGeomBinDispatch();
  const {
    isActive: isGeomBinOpen,
    geomBinItems,
    selectedGeomBinItem
  } = useGeomBinContext();
  const activeColorPalette = useSelector(
    (state: RootState) => state.userSetting.activeColorPalette
  );

  useHotkeys(
    "esc",
    () => {
      cancelPolygonSelection();
    },
    {},
    [mapbox]
  );

  function cancelPolygonSelection() {
    if (!drawRef?.current) {
      return;
    }
    try {
      drawRef.current.trash();

      // drawRef.current.deleteAll();
      // updateSelectionCentroid(mapbox, null);
      // mapDispatch({
      //   payload: {
      //     selectedFeatures: []
      //   }
      // });
      // setLassoSelect(false);

      // geomBinDispatch({
      //   payload: {
      //     isLassoSelected: false,
      //     geomBinItems: [...geomBinItems]
      //   }
      // });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  useEffect(() => {
    if (!drawRef?.current) {
      return;
    }

    drawRef.current.deleteAll();
  }, [drawRef, isGeomBinOpen]);

  useEffect(() => {
    if (geomBinItems.some((item) => !item.color)) {
      // Some polygons didn't have color, trigger to fill in color for the polygon
      const coloredGeomBinItems = colorPolygons(null);
      if (coloredGeomBinItems) {
        geomBinDispatch({
          payload: {
            geomBinItems: coloredGeomBinItems
          }
        });
      }
      return;
    }

    if (!drawRef?.current) {
      return;
    }

    drawRef.current.deleteAll();

    geomBinItems.forEach((item) => {
      const feature = JSON.parse(item.geom);
      // feature.properties.color = item.color;
      drawRef.current.add(feature);
      drawRef.current.setFeatureProperty(feature.id, "portColor", item.color);
      drawRef.current.setFeatureProperty(feature.id, "portStroke", item.strokeColor);
      drawRef.current.setFeatureProperty(feature.id, "portOpacity", item.opacity);
      drawRef.current.setFeatureProperty(feature.id, "portThickness", item.thickness);
    });
  }, [geomBinItems]);

  const onDrawEnd = (e) => {
    //calls when finished drawing
    if (e.features.length > 0) {
      const feature = e.features[0];

      const coloredGeomBinItems = colorPolygons(feature);

      if (coloredGeomBinItems) {
        const newGeomBinItems = coloredGeomBinItems.map((item) => {
          if (item.id !== feature.id && booleanOverlap(feature, JSON.parse(item.geom))) {
            // if the existing polygon overlap with selected polygon
            // trim the existing polygon
            const trim = difference(JSON.parse(item.geom), feature);
            trim.id = item.id;
            return {
              id: item.id,
              name: item.name,
              geom: JSON.stringify(trim),
              order: item.order,
              color: rgbStringToHex(item.color),
              strokeColor: rgbStringToHex(item.strokeColor),
              opacity: item.opacity,
              thickness: item.thickness
            };
          }

          return item;
        });

        geomBinDispatch({
          payload: {
            geomBinItems: newGeomBinItems,
            isLassoSelected: false
          }
        });
      }
    }
  };

  const colorPolygons = (newPolygon) => {
    const palette = activeColorPalette as unknown as IColorPalette;

    if (!palette) {
      toast.error("Error drawing new polygon, color palette not found.");
      geomBinDispatch({
        payload: {
          isLassoSelected: false
        }
      });
      return;
    }

    const totalItems = geomBinItems.length + 1;
    const requiredColorInterpolation = totalItems > palette.colors.length;

    // Calculate step to pick colors from palette manually
    let colorIndexStep =
      totalItems <= 2
        ? palette.colors.length - 1
        : Math.floor(palette.colors.length / (totalItems - 1)) - 1;
    colorIndexStep = colorIndexStep <= 0 ? 1 : colorIndexStep;
    let colorIndex = 0;

    // Start and End color of the palette
    let color1, colorX;

    // Start and End color rgb channels
    let r1, g1, b1, rx, gx, bx;

    const paletteFirstColor = palette.colors[0].replaceAll(" ", "");
    const paletteLastColor = palette.colors[palette.colors.length - 1].replaceAll(
      " ",
      ""
    );

    // check if seeding color has correct rgb color format
    // Also, stop calculate color interpolation if we still haven't run out of color in the palete
    if (
      requiredColorInterpolation &&
      /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.test(paletteFirstColor) &&
      /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.test(paletteLastColor)
    ) {
      // Start color and rgb channel
      color1 = paletteFirstColor.split(",");
      r1 = parseInt(color1[0].split("(")[1]);
      g1 = parseInt(color1[1]);
      b1 = parseInt(color1[2].split(")")[0]);

      // End color and rgb channel
      colorX = paletteLastColor.split(",");
      rx = parseInt(colorX[0].split("(")[1]);
      gx = parseInt(colorX[1]);
      bx = parseInt(colorX[2].split(")")[0]);
    }

    const newGeomBinItems = !newPolygon
      ? geomBinItems
      : [
          ...geomBinItems,
          {
            id: newPolygon.id.toString(),
            name: "Polygon " + totalItems,
            geom: JSON.stringify(newPolygon),
            order: totalItems,
            color: rgbStringToHex(palette.colors[totalItems - 1]),
            strokeColor: rgbStringToHex(palette.colors[totalItems - 1]),
            opacity: 0.3,
            thickness: 2
          }
        ];

    const coloredGeomBinItems = newGeomBinItems.map((item, idx) => {
      // ratio of the current item compared to the start and end items
      const ratio = idx / (totalItems > 1 ? totalItems - 1 : 1);
      let calculatedColor = palette.colors[colorIndex];

      if (requiredColorInterpolation) {
        // calculate color interpolation for the item if we have correct seeding data
        // Fall back to default black color if needed
        calculatedColor = color1?.length
          ? `rgb(${Math.trunc(ratio * rx + (1 - ratio) * r1)},${Math.trunc(
              ratio * gx + (1 - ratio) * g1
            )},${Math.trunc(ratio * bx + (1 - ratio) * b1)})`
          : DEFAULT_COLOR;
      }

      const coloredItem = {
        ...item,
        color: item.color ?? rgbStringToHex(calculatedColor),
        strokeColor: item.strokeColor ?? rgbStringToHex(calculatedColor),
        opacity: item.opacity ?? 0.3,
        thickness: item.thickness ?? 2
      };

      // Step up color until we reach the end of the palette
      colorIndex += colorIndex == palette.colors.length - 1 ? 0 : colorIndexStep;

      return coloredItem;
    });

    return coloredGeomBinItems;
  };

  async function onMouseUp() {
    const selected = drawRef.current.getSelected();

    if (!selected?.features?.length) {
      return;
    }

    const hasChanged = geomBinItems.some(
      (item) =>
        item.id === selected.features[0].id &&
        JSON.stringify(JSON.parse(item.geom).geometry) !==
          JSON.stringify(selected.features[0].geometry)
    );

    if (!hasChanged) {
      return;
    }

    const newGeomBinItems = geomBinItems.map((item) => {
      if (item.id === selected.features[0].id) {
        return {
          id: item.id,
          name: item.name,
          geom: JSON.stringify(selected.features[0]),
          order: item.order,
          color: rgbStringToHex(item.color),
          strokeColor: rgbStringToHex(item.strokeColor),
          opacity: item.opacity,
          thickness: item.thickness
        };
      }

      if (booleanOverlap(selected.features[0], JSON.parse(item.geom))) {
        // if the existing polygon overlap with selected polygon
        // trim the existing polygon
        const trim = difference(JSON.parse(item.geom), selected.features[0]);
        trim.id = item.id;
        return {
          id: item.id,
          name: item.name,
          geom: JSON.stringify(trim),
          order: item.order,
          color: rgbStringToHex(item.color),
          strokeColor: rgbStringToHex(item.strokeColor),
          opacity: item.opacity,
          thickness: item.thickness
        };
      }

      return item;
    });

    geomBinDispatch({
      payload: {
        geomBinItems: newGeomBinItems,
        selectedGeomBinItem: undefined
      }
    });

    setTimeout(function () {
      geomBinDispatch({
        payload: {
          selectedGeomBinItem: selected.features[0].id
        }
      });
    }, 100);
  }

  async function onPolygonSelected() {
    if (!drawRef?.current) {
      return;
    }

    const selected = drawRef.current.getSelected();

    if (selected?.features[0]?.id === selectedGeomBinItem) {
      return;
    }

    if (selected?.features[0]?.id) {
      drawRef.current.changeMode("direct_select", {
        featureId: selected?.features[0]?.id
      });
    }

    geomBinDispatch({
      payload: {
        selectedGeomBinItem: selected?.features[0]?.id
      }
    });
  }

  useEffect(() => {
    if (!mapbox || !isGeomBinOpen) {
      // Do not subscribe to map events when geom bin drawer is closed
      return;
    }

    mapbox.on("draw.create", onDrawEnd);
    mapbox.on("click", onPolygonSelected);
    mapbox.on("mouseup", onMouseUp);
    mapbox.on("touchend", onMouseUp);
    return () => {
      mapbox.off("draw.create", onDrawEnd);
      mapbox.off("click", onPolygonSelected);
      mapbox.off("mouseup", onMouseUp);
      mapbox.off("touchend", onMouseUp);
    };
  }, [mapbox, geomBinItems, isGeomBinOpen]);

  useEffect(() => {
    if (!drawRef?.current) {
      return;
    }

    if (!selectedGeomBinItem) {
      drawRef.current.changeMode("simple_select", { featureIds: [] });
      return;
    }

    const selected = drawRef.current.getSelected();

    if (selected?.features[0]?.id === selectedGeomBinItem) {
      // if the polygon is already selected, move on
      return;
    }

    // select the polygon
    drawRef.current.changeMode("direct_select", { featureId: selectedGeomBinItem });
  }, [selectedGeomBinItem]);

  return <></>;
}
