import { useEffect, useRef, useState } from "react";

import axios from "axios";
import {
  FACILITY_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import _debounce from "lodash/debounce";
import { PointLike } from "mapbox-gl";
import styled from "styled-components";

import { useMapContext } from "./hooks/useMapContext";

const wellDataEndpoint = process.env.REACT_APP_WELL_DETAIL_SERVICE;
const margin_from_viewport = 30;

function WellDetail() {
  const { entitiesInGroup, mapbox } = useMapContext();
  const entitiesInGroupRef = useRef(entitiesInGroup);

  useEffect(() => {
    entitiesInGroupRef.current = entitiesInGroup;
  }, [entitiesInGroup]);

  const popupRef = useRef(null);
  const [wellDetail, setWellDetail] = useState({
    uwi: "",
    formattedUwi: "",
    vintage: 0,
    operator: "",
    facilityType: "",
    show: false,
    x: 0,
    y: 0,
    facilityName: ""
  });

  const updateWellDetailDebounce = useRef(
    _debounce(
      async (args: {
        position: { x: number; y: number };
        show: boolean;
        uwi: string;
        isFacility: boolean;
      }) => {
        return await updateWellDetail(args);
      },
      500
    )
  ).current;

  async function updateWellDetail({
    position,
    show,
    uwi,
    isFacility
  }: {
    position: { x: number; y: number };
    show: boolean;
    uwi: string;
    isFacility: boolean;
  }) {
    const newWellDetail = {
      formattedUwi: uwi,
      uwi,
      show,
      operator: "",
      vintage: 0,
      facilityType: "",
      x: 0,
      y: 0,
      facilityName: ""
    };
    if (!show) {
      setWellDetail(newWellDetail);

      return false;
    } else {
      newWellDetail.show = show;
      const response = await axios.get(
        `${wellDataEndpoint}/${uwi}?entityKind=${isFacility ? "Facility" : "Well"}`
      );
      if (response.status === 200) {
        const data = response.data;
        newWellDetail.operator = data.operator;
        newWellDetail.vintage = data.vintage;
        newWellDetail.facilityType = data.facilityType;
        newWellDetail.facilityName = data.facilityName;
        newWellDetail.formattedUwi =
          (data.formattedUwi ?? "").length > 0 ? data.formattedUwi : uwi;
      }
    }
    newWellDetail.x = position.x + 10;
    newWellDetail.y = position.y - 50;
    setWellDetail(newWellDetail);

    return true;
  }

  function adjustOverflow(wellDetail) {
    if (popupRef == null || !wellDetail || popupRef.current == null) return;

    const element = popupRef.current;

    const popupRect = element.getBoundingClientRect();
    const mapRect = document.getElementById("map").getBoundingClientRect();

    if (popupRect.right <= mapRect.right && popupRect.bottom <= mapRect.bottom) {
      return;
    }

    if (popupRect.right > mapRect.right) {
      element.style.left = `${
        wellDetail.x - (popupRect.right - mapRect.right + margin_from_viewport)
      }px`;
    }

    if (popupRect.bottom > mapRect.bottom) {
      element.style.top = `${
        wellDetail.y - (popupRect.bottom - mapRect.bottom + margin_from_viewport)
      }px`;
    }
  }

  async function onMouseMouse(e) {
    if (!mapbox) {
      return;
    }

    let props;
    const buffer = 2;
    const bbox = [
      [e.point.x - buffer, e.point.y - buffer],
      [e.point.x + buffer, e.point.y + buffer]
    ] as [PointLike, PointLike];

    if (!mapbox.getLayer(WELL_LAYER)) {
      return;
    }

    const features = mapbox.queryRenderedFeatures(bbox, {
      layers: [WELL_LAYER, FACILITY_LAYER, WELL_LAYER_POINT]
    });

    let isFacility = false;
    if (features.length > 0) {
      props = features[0].properties;
      isFacility = features[0].layer.id === FACILITY_LAYER;
    }
    const uwi = props?.Uwi ?? "";

    if (mapbox.getZoom() < 6 || wellDetail.uwi === uwi || !uwi) {
      if (!uwi) {
        await updateWellDetailDebounce({
          position: e.point,
          show: false,
          uwi: uwi,
          isFacility: isFacility
        });
      }
      return;
    }
    await updateWellDetailDebounce({
      position: e.point,
      show: true,
      uwi: uwi,
      isFacility: isFacility
    });
  }

  async function onMouseLeave(e) {
    await updateWellDetail({
      position: e.point,
      show: false,
      uwi: "",
      isFacility: false
    });
    updateWellDetailDebounce.cancel();
  }

  useEffect(() => {
    if (!mapbox) {
      return;
    }
    mapbox.on("mousemove", onMouseMouse);
    mapbox.on("mouseleave", [WELL_LAYER_POINT, FACILITY_LAYER, WELL_LAYER], onMouseLeave);
    return () => {
      updateWellDetailDebounce.cancel();
      mapbox.off("mouseleave", WELL_LAYER_POINT, onMouseLeave);
      mapbox.off("mouseleave", WELL_LAYER, onMouseLeave);
      mapbox.off("mouseleave", FACILITY_LAYER, onMouseLeave);
      mapbox.off("mousemove", WELL_LAYER_POINT, onMouseMouse);
      mapbox.off("mousemove", WELL_LAYER, onMouseMouse);
      mapbox.off("mousemove", FACILITY_LAYER, onMouseLeave);
    };
  }, [mapbox]);

  useEffect(() => {
    adjustOverflow(wellDetail);
  }, [wellDetail, popupRef]);

  if (!mapbox || !wellDetail.show) {
    return null;
  }
  const hasFacility = !!wellDetail.facilityType;

  return (
    <Wrapper ref={popupRef} left={wellDetail.x} top={wellDetail.y}>
      <Item>
        <Title hasFacility={hasFacility}>
          {wellDetail.facilityType ? "ID:" : "UWI:"}
        </Title>
        <ItemInfo>{wellDetail.formattedUwi}</ItemInfo>
      </Item>
      {wellDetail.facilityName && (
        <Item>
          <Title hasFacility={hasFacility}>Name:</Title>
          <ItemInfo>{wellDetail.facilityName}</ItemInfo>
        </Item>
      )}
      <Item>
        <Title hasFacility={hasFacility}>Operator:</Title>
        <ItemInfo>{wellDetail.operator}</ItemInfo>
      </Item>
      {wellDetail.vintage && (
        <Item>
          <Title hasFacility={hasFacility}>Vintage:</Title>
          <ItemInfo>{wellDetail.vintage}</ItemInfo>
        </Item>
      )}
      {wellDetail.facilityType && (
        <Item>
          <Title hasFacility={hasFacility}>Facility Type:</Title>
          <ItemInfo>{wellDetail.facilityType}</ItemInfo>
        </Item>
      )}
    </Wrapper>
  );
}

export default WellDetail;

const Wrapper = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? props.left : 0)}px;
  top: ${(props) => (props.top ? props.top : 0)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 200px;
  border-radius: 10px;
  background: rgba(160, 160, 160, 0.1);
  backdrop-filter: blur(4px);
  color: white;
  font-family: "Open Sans", sans-serif;
  padding: 10px;
  max-width: 270px;
  margin: 0;
  z-index: 20;
  pointer-events: none;
  box-shadow: var(--shadow-drawer);
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1px 5px;
`;

const Title = styled.h5`
  font-size: 1.2rem;
  color: white;
  min-width: ${(props) => (props.hasFacility ? "76px" : "60px")};
  text-align: right;
`;

const ItemInfo = styled.span`
  display: inline-block;
  width: max-content;
  margin-left: 5px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  max-width: 220px;
  font-size: 1.2rem;
`;
