import { MapScreenshotPolygon } from "models/filter";

import { ParentDimensionsT } from "components/chart/legend/Legend";

/**
 * Calculate the coordinates of the screenshot area to fetch the corresponding
 * filterId which will be used to fetch the correct well count inside the
 * screenshot area.
 * @param {mapboxgl.Map} mapbox Mapbox object
 * @param {ParentDimensionsT} parentDimensions Dimensions of the parent of the legend (screenshot bounds)
 * @param {boolean} showTownshipBorderInScreenshot If the township border is included in the screemshot
 * @returns {MapScreenshotPolygon} The coordinates of the screenshot area
 */
export const calculateMapScreenshotPolygon = (
  mapbox: mapboxgl.Map,
  parentDimensions: ParentDimensionsT,
  showTownshipBorderInScreenshot: boolean
): MapScreenshotPolygon => {
  const TOWNSHIP_BORDER_WIDTH = 35;
  const TOWNSHIP_BORDER_HEIGHT = 30;

  const center = mapbox.project(mapbox.getCenter());
  // Adjust for township border in map
  center.x -= TOWNSHIP_BORDER_WIDTH;
  center.y -= TOWNSHIP_BORDER_HEIGHT;
  const sw = Object.assign({}, center);
  sw.x -= parentDimensions.width / 2;
  sw.y += parentDimensions.height / 2;
  const ne = Object.assign({}, center);
  ne.x += parentDimensions.width / 2;
  ne.y -= parentDimensions.height / 2;
  if (showTownshipBorderInScreenshot) {
    // Adjust for township border in screenshot
    sw.x += TOWNSHIP_BORDER_WIDTH;
    ne.y += TOWNSHIP_BORDER_HEIGHT;
  }

  // Convert from pixels to lng/lat values
  const coordSW = mapbox.unproject(sw);
  const coordNE = mapbox.unproject(ne);

  const polygonFilter = {
    id: "",
    type: "Polygon",
    coordinates: [
      [
        [coordSW.lng, coordNE.lat],
        [coordNE.lng, coordNE.lat],
        [coordNE.lng, coordSW.lat],
        [coordSW.lng, coordSW.lat],
        [coordSW.lng, coordNE.lat]
      ]
    ],
    source: "selection"
  };
  return polygonFilter;
};
