import { useQuery } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

export const ORGANIZATION_SHAPEFILES_QUERY_KEYS = {
  organizationShapefiles: "organizationShapefiles"
};

export type UseOrganizationShapefilesQueryKeysT =
  keyof typeof ORGANIZATION_SHAPEFILES_QUERY_KEYS;

export type UseOrganizationShapefilesQueryParamsT = {
  onSuccess?: (data) => void;
  onError?: (error) => void;
  select?;
  queryKey?: UseOrganizationShapefilesQueryKeysT;
};

export type UseOrganizationShapefilesQueryReturnT = {
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
  data;
};

async function fetchOrganizationShapefile() {
  return await axios.get(`${projectServiceEndpoint}/shapefile-node/orgTree`);
}

export function useOrganizationShapefilesQuery({
  onSuccess,
  onError,
  select,
  queryKey = "organizationShapefiles"
}: UseOrganizationShapefilesQueryParamsT): UseOrganizationShapefilesQueryReturnT {
  const queryInfo = useQuery({
    queryKey: [queryKey],
    queryFn: fetchOrganizationShapefile,
    select,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    }
  });

  return {
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch,
    data: queryInfo.data?.data
  };
}
