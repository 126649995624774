import { ForecastFolder, UserArpsItem } from "models/UserArpsModel";

export const transformFolderToNestedTreeData = (
  data: ForecastFolder[]
): ForecastFolder[] | UserArpsItem[] => {
  const map = {};
  const treeData = [];
  data.forEach((item) => {
    map[item.folderId] = {
      ...item,
      key: item.folderId,
      children: item.children ? [...item.children] : [],
      type: "folder",
      isFolder: true
    };
  });
  data.forEach((item) => {
    if (item.parentId && item.parentId !== "") {
      if (!map[item.parentId]) {
        map[item.parentId] = { children: [] };
      }
      map[item.parentId].children.push(map[item.folderId]);
      map[item.parentId].children.sort((a, b) => a.order - b.order);
    } else {
      treeData.push(map[item.folderId]);
    }
  });
  return treeData;
};
