import { ReactNode, createContext } from "react";

export interface ScreenshotSettingContextState {
  name?: string;
  calculatedValue?: string | number | undefined;
}

export const initialContextState: ScreenshotSettingContextState = {};
export const ScreenshotSettingContext = createContext(initialContextState);

export interface ScreenshotSettingProviderModel {
  children: ReactNode;
  state?: ScreenshotSettingContextState;
}

export function ScreenshotSettingProvider({
  children,
  state: overrideState
}: ScreenshotSettingProviderModel) {
  const state = overrideState ?? initialContextState;
  return (
    <ScreenshotSettingContext.Provider value={state}>
      {children}
    </ScreenshotSettingContext.Provider>
  );
}
