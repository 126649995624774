import { memo } from "react";

function Sulphur() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-20 -20 512 512"
      width="35"
      height="35">
      <path
        transform="translate(0,-1)"
        fill="currentColor"
        d="M203.1 39.16l12.7 63.24c29.8-.6 29.9 42.8 39.6 75.3 15.2-37.4 11.6-150.5-52.3-138.54zm-17.4 4.73L17.5 102.4v19.9L189 60.41zm6.9 34.42l-24.7 8.91L197 100.5zm-49 17.65l-42.5 15.34 39.8 18 40.5-16.2zM76.76 120.1l-42.63 15.4 42.02 19.7 41.65-16.6zM17.5 147.5v31.2l35.89-14.4zm288.7 7l-24.6 10.6c16.8 7.7 34 17.8"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1 -5 24 24"
        width="420"
        height="420">
        <path
          transform="translate(0,-1)"
          fill="CurrentColor"
          d="M14,6L10.25,11L13.1,14.8L11.5,16C9.81,13.75 7,10 7,10L1,18H23L14,6Z"
        />
      </svg>
    </svg>
  );
}

export default memo(Sulphur);
