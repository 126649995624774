import { ALL_CHART_TYPES } from "constants/chart.constants";

export const verifyChartTypeCanUseStats = (chartType: string | number): boolean => {
  let chartTypeId: number | undefined;
  if (typeof chartType === "number") {
    chartTypeId = chartType as number;
  }
  if (typeof chartType === "string") {
    chartTypeId = parseInt(chartType, 10);
    if (isNaN(chartTypeId)) {
      const result = Object.values(ALL_CHART_TYPES).find((x) => x.label === chartType);
      if (result) {
        chartTypeId = result.id;
      }
    }
  }

  if (chartTypeId !== undefined) {
    return (
      chartTypeId === 1 || chartTypeId === 2 || chartTypeId === 10 || chartTypeId === 11
    );
  }
  return false;
};
