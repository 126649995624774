import { useQuery } from "react-query";

import { ApiResult } from "../api/apiResult";
import { fetchOriginalPlayTo3DDataSources } from "../api/ipdp";

///hook to fetch ipdb fields
export default function useOriginalPlayTo3DDataSources(): ApiResult<string[]> {
  const { data, isLoading, isError, refetch } = useQuery<string[]>(
    "fetchOriginalPlayTo3DDataSources",
    fetchOriginalPlayTo3DDataSources,
    {
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
