import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { useGlobalNormalizeBy } from "components/chart/hooks";
import { applyRemoveUUID } from "components/chart/utils";
import { addNormalizationBasisToTitle } from "components/chart/utils/addNormalizationBasisToTitle";
import { useScreenshotContext } from "components/screenshot/hooks";
import { applyAbbreviations } from "components/screenshot/utils";
import { useUserSettings } from "components/user/hooks";

const useLegendTitle = (): string => {
  // state from store
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const globalUseNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const { userAbbreviations } = useUserSettings();
  const screenshotState = useScreenshotContext();

  // state
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (!txnId.legend.title) return;

    const title = globalGroupBy.title;
    const isForecastEnabled = title.includes("Forecast");
    let nextTitle = isForecastEnabled ? applyRemoveUUID(title) : title;

    nextTitle = globalUseNormalizeBy
      ? addNormalizationBasisToTitle(title, globalNormalizeBy)
      : nextTitle;

    if (
      screenshotState.widget?.widgetId === "mb" &&
      screenshotState.settings?.applyAbbreviations
    ) {
      nextTitle = applyAbbreviations(nextTitle, userAbbreviations?.abbreviations);
    }

    setValue(nextTitle);
  }, [
    globalGroupBy,
    txnId.legend.title,
    useGlobalNormalizeBy,
    globalNormalizeBy,
    screenshotState.widget?.widgetId,
    screenshotState.settings?.applyAbbreviations,
    userAbbreviations?.abbreviations
  ]);

  return value;
};

export default useLegendTitle;
