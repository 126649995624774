import { IconSpinner } from "../icons";
import styles from "./BaseLoader.module.scss";

export default function BaseLoader({
  iconSize = 20,
  message = "",
  backgroundColor = "rgba(var(--color-primary-rgb), 0.1)"
}) {
  const backgroundStyle = {
    backgroundColor: backgroundColor
  };
  return (
    <div
      className={`${styles.loadingOverlay} absolute-center fill`}
      style={backgroundStyle}>
      <div className="absolute-center flex items-center">
        <IconSpinner size={iconSize} />
        {!!message && <span className={styles.loadingMessage}>{message}</span>}
      </div>
    </div>
  );
}
