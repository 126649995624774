import { useCallback, useEffect, useState } from "react";

import { Error, Info, Warning } from "@material-ui/icons";
import styled from "styled-components";

import { useUserSettingFetcher, useUserSettingMutation } from "hooks";

import { BaseSlideToggle, Heading, Tooltip } from "components/base";
import { IconSpinner } from "components/icons";

import { FORECAST_EXPORT_SETTING } from "./constants/userSetting.constants";
import { ForecastExportSetting } from "./models/ForecastExportSetting";

export default function ThirdPartyIntegration() {
  // state
  const [{ enableMosaic, enableValNav }, setExportForecastPreference] = useState({
    enableMosaic: true,
    enableValNav: true
  });

  // hooks
  const {
    data: exportForecastPreference,
    isLoading: exportForecastLoading,
    isError: exportForecastError,
    refetch: exportForecastRefresh
  } = useUserSettingFetcher<ForecastExportSetting>(FORECAST_EXPORT_SETTING);
  const updateExportForecastMutation = useUserSettingMutation<ForecastExportSetting>(
    FORECAST_EXPORT_SETTING
  );

  // Take action when the export forecast preferences change.
  const updateExportForecastPreference = useCallback(
    (setting: ForecastExportSetting) => {
      updateExportForecastMutation.mutate(setting);
    },
    [updateExportForecastMutation]
  );

  // Update state when the export forecast preferences change.
  useEffect(() => {
    if (!exportForecastPreference) return;
    setExportForecastPreference({
      enableMosaic: exportForecastPreference.enableMosaic,
      enableValNav: exportForecastPreference.enableValNav
    });
  }, [exportForecastPreference]);

  // Reload the forecast export preferences from the backend.
  useEffect(() => {
    exportForecastRefresh();
  }, [exportForecastRefresh, updateExportForecastMutation?.data]);

  // Initial load of the forecast export preferences
  useEffect(() => {
    exportForecastRefresh();
  }, [exportForecastRefresh]);

  return (
    <Wrapper>
      <Header>
        <Heading element="h4">Third Party Integrations</Heading>
      </Header>

      <ContentWrapper>
        <DescriptionText>
          Your organization has access to the following integrations.
        </DescriptionText>

        <GridContainer>
          <CategoryWrapper>
            <CategoryHeaderWrapper>
              Arps Export
              <Tooltip
                title="The applications exported EVA forecasts and/or type wells would be imported to."
                overlayClassName="add-dashboard-tooltip-root">
                <Info
                  style={{
                    color: "#A2AAAD",
                    fontSize: "16px",
                    top: "1px"
                  }}
                />
              </Tooltip>
            </CategoryHeaderWrapper>
            {exportForecastError && (
              <ErrorContainer>
                <Error style={{ top: 3 }} /> Error occurred loading preferences
              </ErrorContainer>
            )}
            {(exportForecastLoading || updateExportForecastMutation.isLoading) && (
              <OverlayContainer>
                <OverlayMessages>
                  <StyledSpinner />
                </OverlayMessages>
              </OverlayContainer>
            )}
            {!exportForecastLoading && !exportForecastError && (
              <CategoryListWrapper>
                <CategoryListItemWrapper>
                  <CategoryListItemTitleWrapper>ValNav</CategoryListItemTitleWrapper>
                  <BaseSlideToggle
                    checked={enableValNav}
                    classic
                    onChange={(val) => {
                      setExportForecastPreference({
                        enableMosaic,
                        enableValNav: val
                      });
                      updateExportForecastPreference({
                        id: exportForecastPreference?.id,
                        enableMosaic,
                        enableValNav: val
                      });
                    }}
                  />
                </CategoryListItemWrapper>
                <CategoryListItemWrapper>
                  <CategoryListItemTitleWrapper>Mosaic</CategoryListItemTitleWrapper>
                  <BaseSlideToggle
                    checked={enableMosaic}
                    classic
                    onChange={(val) => {
                      setExportForecastPreference({
                        enableMosaic: val,
                        enableValNav
                      });
                      updateExportForecastPreference({
                        id: exportForecastPreference?.id,
                        enableMosaic: val,
                        enableValNav
                      });
                    }}
                  />
                </CategoryListItemWrapper>
              </CategoryListWrapper>
            )}
            {!enableMosaic && !enableValNav && (
              <WarningContainer>
                <Warning style={{ top: 3, right: 1 }} /> You will not be presented an
                option to export forecasts or type wells.
              </WarningContainer>
            )}
          </CategoryWrapper>
        </GridContainer>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  overflow-y: auto;
  padding: 32px 100px;
  gap: 30px;
`;
const Header = styled.div`
  margin-top: -10px;
  margin-bottom: 10px;
`;
const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 1;
`;
const OverlayMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: inherit;
`;
const ContentWrapper = styled.div`
  max-width: 791px;
`;
const DescriptionText = styled.div`
  text-align: left;
  font-size: 1.6rem;
  font-weight: var(--fontWeightMedium);
  max-width: 500px;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 30px;
  margin-bottom: 40px;
  grid-gap: 20px;
  gap: 20px;
`;
const CategoryWrapper = styled.div`
  padding: 15px 30px 10px 25px;
  border: 1px solid #f3f3f4;
  background-color: #ffffff;
  height: -moz-fit-content;
  height: fit-content;
`;
const CategoryHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.6rem;
  font-weight: var(--fontWeightBold);
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f4;
  margin-bottom: 16px;
`;
const CategoryListWrapper = styled.div``;
const CategoryListItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #f3f3f4;
  border-radius: 4px;
  align-items: center;
  padding: 5px 10px 5px 3px;
  justify-content: space-between;
`;
const CategoryListItemTitleWrapper = styled.div`
  font-size: 1.4rem;
  color: var(--color-text);
  font-weight: var(--fontWeightBold);
`;
const StyledSpinner = styled(IconSpinner)`
  align-self: flex-start;
`;
const ErrorContainer = styled.div`
  padding-top: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
const WarningContainer = styled.div`
  padding: 8px 10px 8px 20px;
  text-align: left;
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
`;
