import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styled from "styled-components";

import { TypeLogWellSelector } from "./TypeLogWellSelector";
import { updateUwidFormat, useTypeLog } from "./context";
import { useTypeLogData } from "./hooks/useTypeLogData";

export default function TypeLogWidget() {
  useTypeLogData();
  const [state, dispatch] = useTypeLog();
  const [typeLog, setTypeLog] = useState(null);
  const typeLogMessage = state?.typeLogErrorMessage;

  useHotkeys(
    "u",
    () => {
      updateUwidFormat(dispatch, state?.uwidFormat === "uwid" ? "entityName" : "uwid");
    },
    [updateUwidFormat, state?.uwidFormat]
  );

  useEffect(() => {
    if (!typeLogMessage) {
      // create link to pdf
      const pdfBlob = new Blob([state?.document], {
        type: "application/pdf"
      });
      const pdfObjectUrl = URL.createObjectURL(pdfBlob);
      setTypeLog(
        <object data={pdfObjectUrl} type="application/pdf" width="100%" height="100%">
          <p>
            PDF settings may be configured to by download PDFs on your browser, if so,
            navigate to the PDF documents settings to open PDFs in browser. You can
            download the PDF{" "}
            <a
              href={pdfObjectUrl}
              target="_blank"
              download={`${state?.selectedWell?.Uwi}.pdf`}
              rel="noopener noreferrer">
              here
            </a>
            .
          </p>
        </object>
      );
    } else {
      setTypeLog(<p style={{ marginLeft: "20px" }}>{typeLogMessage}</p>);
    }
  }, [typeLogMessage, state, state?.selectedWell?.Uwi]);

  return (
    <TabContentContainer>
      <TypeLogWellSelectorStyled state={state} dispatch={dispatch} />
      <TypeLogContainer>{typeLog}</TypeLogContainer>
    </TabContentContainer>
  );
}

const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const TypeLogWellSelectorStyled = styled(TypeLogWellSelector)`
  flex: 0 0 10%; // This will take up 30% of the space
  overflow: auto;
`;

const TypeLogContainer = styled.div`
  flex: 1 0 90%; // This will take up the remaining 70% of the space
  overflow: auto;
`;
