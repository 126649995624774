import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import {
  BaseLayerState,
  BubbleVisLegendState,
  ConfirmSizeState,
  CreatedState,
  EntitiesInGroupState as EntitiesOnMapState,
  FacilitiesInGroupState,
  FullscreenState,
  HighlightedFeaturesState,
  IsPolygonFilterControlVisibleState,
  MapAlertState,
  MapBoundState,
  MapContextState,
  MapInitializedState,
  MapboxglState,
  NoWellsAvailableState,
  OverrideSizeLimitState,
  SelectedAndHighlightFeatureState,
  SelectedFeaturesState,
  StyleLoadedState,
  UpdateLoadingState
} from "./MapContextState";

export const initialMapContextState: MapContextState = {
  isLoading: false,
  isStyleLoaded: false,
  isMapInitialized: false,
  noWellsAvailable: false,
  created: false,
  mapbox: undefined,
  bounds: undefined,
  isFullscreen: false,
  isPolygonFilterControlVisible: false,
  highlightedFeatures: { line: [], point: [], facility: [] },
  selectedFeatures: [],
  entitiesInGroup: {},
  facilitiesInGroup: {},
  baseLayer: "Street",
  mapAlert: "",
  bubbleVisLegend: undefined,
  hasToConfirmSize: false,
  overrideSizeLimit: false
};
export const MapContext = createContext(initialMapContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const MapDispatchContext =
  createContext<React.Dispatch<Action<MapUpdates, "update">>>(undefined);

export type MapUpdates =
  | UpdateLoadingState
  | CreatedState
  | MapInitializedState
  | MapboxglState
  | MapAlertState
  | MapBoundState
  | StyleLoadedState
  | NoWellsAvailableState
  | MapContextState
  | EntitiesOnMapState
  | FacilitiesInGroupState
  | SelectedFeaturesState
  | HighlightedFeaturesState
  | IsPolygonFilterControlVisibleState
  | SelectedAndHighlightFeatureState
  | BaseLayerState
  | BubbleVisLegendState
  | FullscreenState
  | ConfirmSizeState
  | OverrideSizeLimitState;

function mapReducer(state: MapContextState, action: Action<MapUpdates, "update">) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface MapProviderModel {
  children: ReactNode;
  state?: MapContextState;
}

export function MapProvider({ children, state: overrideState }: MapProviderModel) {
  const [state, dispatch] = useReducer(
    mapReducer,
    overrideState ?? initialMapContextState
  );

  return (
    <MapContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapContext.Provider>
  );
}
