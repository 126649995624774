import { useMutation } from "react-query";

import axios from "axios";

import { GEO_MAP_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { Calculation, Raster } from "../models/mapLayer";

const updateMap = async (data: {
  id: number;
  map: { Raster?: Raster; Calculation?: Calculation };
}) => {
  const response = await axios.put(
    `${GEO_MAP_SERVICE_ADDRESS}/map-layer/${data.id}/map`,
    data.map
  );
  return response.data;
};

const useUpdateGeoMap = () => {
  return useMutation(updateMap);
};

export default useUpdateGeoMap;
