import {
  IPredicateOperator,
  IPredicatePolygonOperator,
  IPredicatePropertyOperator,
  IPredicateType
} from "models/filter";

export const PREDICATE_TYPE: IPredicateType = {
  Property: "Property",
  WellList: "WellList",
  Polygon: "Polygon"
};
export const PREDICATE_OPERATOR: IPredicateOperator = {
  And: "and",
  Or: "or"
};
export const PREDICATE_POLYGON_OPERATOR: IPredicatePolygonOperator = {
  Equals: "=",
  NotEqual: "!="
};
export const PREDICATE_PROPERTY_OPERATOR: IPredicatePropertyOperator = {
  Between: "between",
  NotBetween: "not between",
  GreaterThan: ">",
  GreaterThanOrEqual: ">=",
  Equals: "=",
  NotEqual: "!=",
  LessThan: "<",
  LessThanOrEqual: "<=",
  In: "in",
  NotIn: "nin"
};
