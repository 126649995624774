import mapboxgl, { GeoJSONSource } from "mapbox-gl";

import { emptyFeatureCollection } from "./emptyFeatureCollection";

export const setGeojsonSource = (
  mapbox: mapboxgl.Map,
  layer: string,
  geojson: GeoJSON.FeatureCollection
) => {
  if (!mapbox) {
    return false;
  }
  if (mapbox.getLayer(layer) && mapbox.getSource(layer)) {
    const source = mapbox.getSource(layer) as GeoJSONSource;
    if (source) {
      source.setData(geojson);
      return true;
    }
  }
  return false;
};

export const clearGeojsonSource = (mapbox: mapboxgl.Map, layer: string) => {
  if (!mapbox) {
    return;
  }
  if (mapbox.getLayer(layer)) {
    (mapbox.getSource(layer) as GeoJSONSource)?.setData({ ...emptyFeatureCollection });
  }
};
