import { useEffect, useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ConfigProvider } from "antd";
import { RootState } from "store/rootReducer";

import { refreshToken } from "api/auth";

import { ShapefileProvider } from "components/project/shapefiles/context";
import { PostProvider } from "components/user-documentation";
import { UserProvider } from "components/user/context";

ConfigProvider.config({
  theme: {
    primaryColor: "#041C2C"
  }
});

type AppProviderPropsT = {
  // currentJwtToken: string;
  children: React.ReactNode | React.ReactNode[] | null;
};

function checkTokenExpire(currentJwtToken): boolean {
  const jwtToken = JSON.parse(atob(currentJwtToken.split(".")[1]));
  const expires = new Date(jwtToken.exp * 1000);

  //check if token is about to expire
  const nowPlusMinutes = new Date();
  nowPlusMinutes.setMinutes(nowPlusMinutes.getMinutes() + 2);

  if (nowPlusMinutes >= expires) {
    return true;
  }

  return false;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const AppProvider = ({
  children = null
}: // currentJwtToken
AppProviderPropsT): JSX.Element => {
  const currentJwtToken = useSelector((state: RootState) => state.auth.jwtToken);
  const currentJwtTokenRef = useRef(currentJwtToken);

  useEffect(() => {
    currentJwtTokenRef.current = currentJwtToken;
  }, [currentJwtToken]);

  const userArpsLink = createHttpLink({
    uri: process.env.REACT_APP_TYPE_WELL_GQL_SERVICE
  });

  const savedFiltersLink = createHttpLink({
    uri: process.env.REACT_APP_SAVED_FILTER_GQL_SERVICE
  });

  const workspacesLink = createHttpLink({
    uri: process.env.REACT_APP_WORKSPACE_SERVICE
  });

  const authLink = setContext(async (_, { headers }) => {
    const jwtToken = currentJwtTokenRef.current;

    if (!jwtToken) {
      return;
    }

    if (!checkTokenExpire(jwtToken)) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${jwtToken}`
        }
      };
    } else {
      try {
        const refreshedToken = await refreshToken();

        return {
          headers: {
            ...headers,
            authorization: `Bearer ${refreshedToken?.jwtToken}`
          }
        };
      } catch (error) {
        throw new Error("Token refresh failed");
      }
    }
  });

  const splitLink = authLink.split(
    (operation) => operation.getContext().clientName === "saved-filters",
    savedFiltersLink,
    authLink.split(
      (operation) => operation.getContext().clientName === "workspaces",
      workspacesLink,
      userArpsLink
    )
  );

  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <UserProvider>
            <ShapefileProvider>
              <PostProvider>{children}</PostProvider>
            </ShapefileProvider>
          </UserProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default AppProvider;
