export const FORECAST_EXPORT_SETTING = "forecastExportSetting";
export const USER_DASHBOARD_SETTING = "userDashboardSetting";
export const USER_DOC_SETTING = "userDocSetting";
export const USER_ABBREVIATIONS_SETTING = "userAbbreviationsSetting";
export const DEFAULT_DECLINE_TYPE_SETTING = "defaultDeclineType";
export const DEFAULT_PROJECT_SETTING = "defaultProject";
export const DEFAULT_BIN_SETTINGS_SETTING = "defaultBinSettings";
export const DEFAULT_FILTER_SETTING = "defaultFilter";
export const DEFAULT_FACILITY_FILTER_SETTING = "defaultFacilityFilter";
export const DEFAULT_MAP_SETTING = "defaultMapCenter";
export const DEFAULT_FOCUS_TILES_SETTING = "defaultFocusTile";
export const DEFAULT_FACILITY_FOCUS_TILES_SETTING = "defaultFacilityFocusTile";
export const DEFAULT_COLOR_PALETTE_SETTING = "defaultColorPalette";
export const DEFAULT_FACILITY_COLOR_PALETTE_SETTING = "defaultFacilityColorPalette";
export const DEFAULT_TYPE_WELL_DASHBOARD_SETTING = "defaultTypeWellDashboard";
export const USER_MIDSTREAM_SETTINGS = "midstreamSettings";
export const USER_GEO_MODEL_SETTINGS = "geoModelSettings";
