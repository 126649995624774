// noinspection CssUnusedSymbol
// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";

import { mdiCog, mdiDelete, mdiDotsVertical, mdiMap, mdiPlus, mdiRename } from "@mdi/js";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Dropdown,
  Input,
  Layout,
  List,
  Row,
  Select,
  Tabs,
  Typography
} from "antd";
import styled from "styled-components";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import { StyledSpinner } from "../user-settings/components/defaults/shared";
import { FieldTemplatePopoverActions } from "./FieldTemplatePopoverActions";
import { LayerPopoverActions } from "./LayerPopoverActions";
import { MapRow } from "./MapRow";
import { SettingsModal } from "./SettingsModal";
import { TemplateNameModal } from "./TemplateNameModal";
import useGeoMapLayers from "./hooks/getGeoMapLayers";
import useMutateGeoMapLayers from "./hooks/uploadRasterMap";
import useDeleteFieldTemplate from "./hooks/useDeleteFieldTemplate";
import useDeleteMapFromLayer from "./hooks/useDeleteMapFromLayer";
import useDeleteMapLayer from "./hooks/useDeleteMapLayer";
import useFetchFieldTemplates, {
  GeoMapFieldTemplatesQueryKey
} from "./hooks/useFetchFieldTemplates";
import useGetGeoMapSettings, { GetGeoMapSettingsKey } from "./hooks/useGetGeoMapSettings";
import useMakeMap, { MapKind } from "./hooks/useMakeMap";
import { usePostFieldTemplate } from "./hooks/usePostFieldTemplate";
import usePostMapLayer from "./hooks/usePostMapLayer";
import useUpdateGeoMap from "./hooks/useUpdateMap";
import useUpdateMapLayer from "./hooks/useUpdateMapLayer";
import { useUpdateSettings } from "./hooks/useUpdateSettings";
import { Calculation, Field, FieldTemplate, MapLayer, Raster } from "./models/mapLayer";

const { Title } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;
export type Group = {
  key: string;
  label: string;
  children: MapLayer[];
  isRenaming?: boolean;
};
const toastContainerId = 111110;

export function GeoMap() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: uploadMap,
    isError: mutateMapError,
    error: uploadMapError
  } = useMutateGeoMapLayers();
  const { mutateAsync: updateMap, isError: isUpdateError } = useUpdateGeoMap();
  const {
    mutateAsync: makeMap,
    isLoading: isMakingMap,
    isError: isMakeMapError,
    error: makeMapError
  } = useMakeMap();
  const { data: settings } = useGetGeoMapSettings();

  const { mutateAsync: addNewFieldTemplateAsync } = usePostFieldTemplate();
  const { data: calculationFieldTemplates } = useFetchFieldTemplates();
  const calculationContainerRef = useRef(null);
  const { mutateAsync: deleteMap, isError: deleteError } = useDeleteMapFromLayer();
  const { mutateAsync: addMapLayer, isError: postMapError } = usePostMapLayer();
  const { mutateAsync: updateMapLayer, isError: updateMapLayerError } =
    useUpdateMapLayer();
  const { mutateAsync: deleteMapLayer, isError: deleteMapError } = useDeleteMapLayer();
  const [hasError, setHasError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: deleteFieldTemplate } = useDeleteFieldTemplate();
  const { mutateAsync: saveSettings } = useUpdateSettings();

  const { data, refetch } = useGeoMapLayers();
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedLayer, setSelectedLayer] = useState<MapLayer>(null); // Add selected group state
  const [newLayerName, setNewLayerName] = useState<string>("");
  const [groupRename, setGroupRename] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);
  const [selectedCalculationMapTemplate, setSelectedCalculationMapTemplate] =
    useState<string>("Single");
  const [showSettings, setShowSettings] = useState(false);
  const { hasFeature } = useBetaFeatures();

  useEffect(() => {
    const hasError =
      isUpdateError ||
      deleteError ||
      postMapError ||
      updateMapLayerError ||
      deleteMapError ||
      isMakeMapError ||
      mutateMapError;
    setHasError(hasError);
  }, [
    isUpdateError,
    deleteError,
    postMapError,
    updateMapLayerError,
    deleteMapError,
    isMakeMapError,
    mutateMapError
  ]);

  useEffect(() => {
    if (!data) return;
    const groups: Group[] = [];

    const seen_groups = {} as Record<string, MapLayer[]>;

    data.map((layer) => {
      if (layer.group && !seen_groups[layer.group]) {
        seen_groups[layer.group] = [];
      }
      seen_groups[layer.group].push(layer);
    });
    for (const [key, value] of Object.entries(seen_groups)) {
      const o = {
        key,
        label: key,
        children: value.map((layer) => {
          const child = {
            key: layer._id,
            label: layer.name,
            ...layer
          };
          if (child._id === selectedLayer?._id) {
            setSelectedLayer(child);
          }
          return child;
        })
      };

      groups.push(o);
    }
    setGroups(groups);
  }, [data]);

  const handleDrop = useCallback(
    async (files: File[]) => {
      if (!selectedLayer) {
        return;
      }
      setIsUploading(true);
      try {
        const calculateSimilarityScore = (str1: string, str2: string): number => {
          const m = str1.length;
          const n = str2.length;
          const dp: number[][] = [];

          for (let i = 0; i <= m; i++) {
            dp[i] = [];
            dp[i][0] = i;
          }

          for (let j = 0; j <= n; j++) {
            dp[0][j] = j;
          }

          for (let i = 1; i <= m; i++) {
            for (let j = 1; j <= n; j++) {
              if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
              } else {
                dp[i][j] = Math.min(
                  dp[i - 1][j] + 1, // deletion
                  dp[i][j - 1] + 1, // insertion
                  dp[i - 1][j - 1] + 1 // substitution
                );
              }
            }
          }

          return dp[m][n];
        };

        const findBestMatch = (fileName: string, mapTypes: Field[]): Field => {
          let bestMatch = mapTypes[0];
          let minDistance = Infinity;

          for (const mapType of mapTypes) {
            const distance = calculateSimilarityScore(fileName, mapType.name);
            if (distance < minDistance) {
              minDistance = distance;
              bestMatch = mapType;
            }
          }
          return bestMatch;
        };

        const tifAndZFiles = files.filter(
          (file) =>
            file.name.endsWith(".tif") ||
            file.name.endsWith(".tiff") ||
            file.name.endsWith(".z") ||
            file.name.endsWith(".zmap")
        );
        const projFiles = files.filter((file) => file.name.endsWith(".prj"));

        for (const file of tifAndZFiles) {
          let projFile: File = null;
          let overrideFileName = undefined;
          if (file.name.endsWith(".z")) {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            const path = (file as any).path;
            if (projFiles.length == 0) {
              continue;
            }
            const fileNameWithoutExtension = file.name.replace(".z", "");
            for (const projectionFile of projFiles) {
              if (projectionFile.name.includes(fileNameWithoutExtension)) {
                projFile = projectionFile;
                break;
              }
            }
            if (!projFile) {
              projFile = projFiles[0];
            }

            if (path.length > 0) {
              const folder = path.split("/");
              if (folder.length > 2) {
                overrideFileName = folder[1] + ".z";
              }
            }
          }

          const bestMatch = findBestMatch(
            overrideFileName ?? file.name,
            settings.getAllSettings()
          );
          const expression = bestMatch?.expression ?? "";
          const id = await uploadMap({
            id: selectedLayer._id,
            file,
            name: bestMatch.name,
            expression,
            projFile,
            unit: bestMatch.unit,
            min: bestMatch.min,
            max: bestMatch.max,
            overrideFileName: overrideFileName ?? ""
          });
          const map = {
            Raster: {
              name: bestMatch.name,
              id,
              filepath: file.name,
              createdDate: {
                $date: new Date().toISOString()
              },
              createdBy: "",
              transformer: expression
                ? {
                    type: "ExpressionTransform",
                    expression
                  }
                : undefined,
              unit: bestMatch.unit,
              min: bestMatch.min,
              max: bestMatch.max
            }
          };
          selectedLayer.maps.push(map);
        }
        await refetch();
      } catch (error) {
        toast.error("Error uploading map", {
          autoClose: false,
          containerId: toastContainerId
        });
      } finally {
        setIsUploading(false);
      }
    },
    [selectedLayer]
  );
  const { getRootProps, isDragActive } = useDropzone({ onDrop: handleDrop });

  const onAddNewCalculationMap = async () => {
    if (!selectedLayer) {
      return;
    }

    const selectedTemplate = calculationFieldTemplates.find(
      (template) => template.name === selectedCalculationMapTemplate
    );
    let maps = [
      {
        id: selectedLayer._id,
        file: null,
        name: "New Calculation",
        expression: "$",
        proj: "",
        unit: "",
        overrideFileName: ""
      }
    ];

    if (selectedTemplate) {
      maps = [];
      for (const field of selectedTemplate.fields) {
        if (selectedLayer.maps.find((m) => m.Calculation?.name == field.name)) {
          continue;
        }
        const map = {
          id: selectedLayer._id,
          file: null,
          name: field.name,
          unit: field.unit ?? "",
          expression: field.expression ?? "",
          proj: "",
          overrideFileName: ""
        };
        maps.push(map);
      }
    }

    for (const map of maps) {
      const id = await uploadMap(map);
      const newMap = {
        Calculation: {
          name: map.name,
          id,
          unit: map.unit,
          transformer: {
            type: "ExpressionTransform",
            expression: map.expression
          },
          createdDate: {
            $date: new Date().toISOString()
          },
          createdBy: ""
        }
      };
      selectedLayer.maps.push(newMap);
      setSelectedLayer({ ...selectedLayer });
    }
  };
  const onDeleteMap = async (map: { Raster?: Raster; Calculation?: Calculation }) => {
    selectedLayer?.maps.splice(selectedLayer?.maps.indexOf(map), 1);

    try {
      await deleteMap({
        id: selectedLayer._id,
        mapId: map.Raster?.id ?? map.Calculation?.id
      });
      setSelectedLayer({ ...selectedLayer });
    } catch (e) {
      toast.error("Error deleting map", {
        autoClose: false,
        containerId: toastContainerId
      });
    }
  };
  const onMapNameChanged = async (
    name: string,
    map: { Raster?: Raster; Calculation?: Calculation }
  ) => {
    if (name === "" || !selectedLayer) {
      return;
    }
    (map.Raster ?? map.Calculation).name = name;
    const foundField: Field = settings.getAllSettings().find((f) => f.name === name);
    if (foundField) {
      (map.Raster ?? map.Calculation).unit = foundField.unit;
      (map.Raster ?? map.Calculation).min = foundField.min;
      (map.Raster ?? map.Calculation).max = foundField.max;
      if (foundField?.expression?.length > 0) {
        (map.Calculation ?? map.Raster).transformer = {
          type: "ExpressionTransform",
          expression: foundField.expression
        };
      }
    }
    await updateMap({
      id: selectedLayer._id,
      map
    });
    await refetch();
    setSelectedLayer({ ...selectedLayer });
  };

  const onExpressionChanged = async (
    expr: string,
    map: { Raster?: Raster; Calculation?: Calculation }
  ) => {
    (map.Raster ?? map.Calculation).transformer.expression = expr;
    await updateMap({
      id: selectedLayer._id,
      map
    });
    await refetch();
    setSelectedLayer({ ...selectedLayer });
  };

  const addNewLayerGroup = () => {
    groups.push({
      key: "New Group",
      label: "New Group",
      children: [],
      isRenaming: true
    });
    setGroupRename("New Group");
    setGroups([...groups]);
  };

  const onAddNewMapLayer = async (group: Group) => {
    setNewLayerName("");
    group.children.push({
      name: "",
      projectId: null,
      group: group.label,
      metadata: null,
      maps: [],
      order: group.children.length + 1
    });
    setGroups([...groups]);
  };

  const inputRef = useRef(null);

  const handleSetGroupName = async (group: Group, newName: string) => {
    if (newName.length === 0 || !group.isRenaming) {
      return;
    }
    group.label = newName;
    group.key = newName;
    group.isRenaming = false;

    for (const layer of group.children) {
      layer.group = newName;
      await updateMapLayer(layer);
    }
    setGroups([...groups]);
    await refetch();
  };

  const handleSetLayerName = async (name: string, group: string) => {
    if (name.length === 0) {
      return;
    }
    await addMapLayer({
      name,
      projectId: null,
      group,
      metadata: null,
      maps: []
    });
    await refetch();
  };

  const handleRenameMapLayer = async (layer: MapLayer, newName: string) => {
    if (newName.length === 0) {
      return;
    }
    layer.name = newName;
    await updateMapLayer(layer);
    await refetch();
  };
  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "white",

      // styles we need to apply on draggables
      ...draggableStyle,
      left: 0
    };
  };
  if (!hasFeature("Geo Model Loader")) {
    return null;
  }

  async function saveCalculationMapsAsNewTemplate(name: string) {
    if (!selectedLayer) {
      return;
    }
    const maps = selectedLayer?.maps.filter((f) => f.Calculation);
    const fields = maps.map((m) => {
      const field = m.Calculation;
      return {
        name: field.name,
        unit: field.unit,
        expression: field.transformer?.expression,
        min: field.min,
        max: field.max
      } as Field;
    });
    const template = {
      name,
      fields
    } as FieldTemplate;
    await addNewFieldTemplateAsync(template);
    await queryClient.invalidateQueries(GeoMapFieldTemplatesQueryKey);
    setShowModal(false);
  }

  return (
    <StyledTabs defaultActiveKey="1">
      <Tabs.TabPane tab="2D Layers" key="1">
        <RootLayout>
          <ToastContainer
            className="geo-toast"
            containerId={toastContainerId}
            closeButton={true}
            enableMultiContainer
            limit={2}
          />
          <SettingsModal
            showSettings={showSettings}
            containerRef={calculationContainerRef}
            settings={settings}
            onCancel={() => setShowSettings(false)}
            onSave={async (settings) => {
              // Save settings
              await saveSettings(settings);
              setShowSettings(false);
              await queryClient.invalidateQueries(GetGeoMapSettingsKey);
            }}
          />
          <StyledSider width={200}>
            <TitleContainer>
              <Title level={5}>Layer Groups </Title>
              <Button
                onClick={addNewLayerGroup}
                type="primary"
                icon={<Icon path={mdiPlus} size={1.2} />}
                title="Add New Layer Group"
              />
            </TitleContainer>
            <Divider />
            <StyledCollapse
              bordered={false}
              accordion
              onClick={(e) => {
                setSelectedLayer(e.item.props);
              }}>
              {groups.map((group) => (
                <Panel
                  key={group.key}
                  header={
                    group.isRenaming ? (
                      <Input
                        autoFocus
                        value={groupRename}
                        onPressEnter={() => handleSetGroupName(group, groupRename)}
                        onChange={(e) => {
                          setGroupRename(e.target.value);
                        }}
                        onBlur={() => handleSetGroupName(group, groupRename)}
                      />
                    ) : (
                      <GroupTitleContainer>
                        <Title level={5}>{group.label}</Title>{" "}
                        <LayerPopoverActions
                          group={group}
                          onAddNewMapLayer={onAddNewMapLayer}
                          onRenameGroup={(g) => {
                            for (const group of groups) {
                              group.isRenaming = false;
                            }
                            g.isRenaming = true;
                            setGroupRename(g.label);
                            setGroups([...groups]);
                          }}
                        />
                      </GroupTitleContainer>
                    )
                  }>
                  <DragDropContext
                    onDragEnd={async (e) => {
                      const source = e.source;
                      const destination = e.destination;
                      if (!destination) {
                        return;
                      }
                      const sourceGroup = groups.find(
                        (g) => g.key === source.droppableId
                      );
                      const destinationGroup = groups.find(
                        (g) => g.key === destination.droppableId
                      );
                      if (!sourceGroup || !destinationGroup) {
                        return;
                      }
                      const [removed] = sourceGroup.children.splice(source.index, 1);
                      destinationGroup.children.splice(destination.index, 0, removed);
                      let i = 1;
                      for (const item of sourceGroup.children) {
                        const newOrder = i++;
                        if (newOrder != item.order) {
                          item.order = newOrder;
                          await updateMapLayer(item);
                        }
                      }
                      await refetch();
                    }}>
                    <Droppable droppableId={group.key}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          <List
                            dataSource={group.children}
                            renderItem={(layer, index) => {
                              return layer.name.length === 0 || layer.isRename ? (
                                <StyledListItem>
                                  <Input
                                    placeholder="Layer Name"
                                    autoFocus
                                    value={newLayerName}
                                    ref={inputRef}
                                    onPressEnter={() => inputRef.current?.blur()}
                                    onChange={(e) => {
                                      setNewLayerName(e.target.value);
                                    }}
                                    onBlur={async () => {
                                      if (layer.isRename) {
                                        await handleRenameMapLayer(layer, newLayerName);
                                      } else
                                        await handleSetLayerName(newLayerName, group.key);
                                    }}
                                  />
                                </StyledListItem>
                              ) : (
                                <Draggable
                                  draggableId={layer.name}
                                  key={layer._id}
                                  index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}>
                                      <StyledListItem
                                        selected={layer?.name == selectedLayer?.name}
                                        onClick={() => {
                                          setSelectedLayer(layer);
                                          setHasError(false);
                                        }}>
                                        <ListItemContentWrapper
                                          selected={layer?.name == selectedLayer?.name}>
                                          {layer.name}
                                          <Dropdown
                                            trigger={["click"]}
                                            menu={{
                                              items: [
                                                {
                                                  icon: (
                                                    <Icon path={mdiRename} size={1.0} />
                                                  ),
                                                  label: "Rename",
                                                  key: "rename",
                                                  onClick: () => {
                                                    setNewLayerName(layer.name);
                                                    layer.isRename = true;
                                                    setSelectedLayer(layer);
                                                    setGroups([...groups]);
                                                  }
                                                },
                                                {
                                                  key: "delete",
                                                  label: "Delete",
                                                  icon: (
                                                    <Icon path={mdiDelete} size={1.0} />
                                                  ),
                                                  onClick: async () => {
                                                    await deleteMapLayer({
                                                      id: layer._id
                                                    });
                                                    await refetch();
                                                  }
                                                }
                                              ]
                                            }}>
                                            <Button
                                              icon={
                                                <Icon path={mdiDotsVertical} size={1.0} />
                                              }
                                              type="text"
                                              className="action-btn"
                                            />
                                          </Dropdown>
                                        </ListItemContentWrapper>
                                      </StyledListItem>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Panel>
              ))}
            </StyledCollapse>
          </StyledSider>

          <StyledContent>
            <StyledRow>
              <Section {...getRootProps()} isDragActive={isDragActive}>
                <Title level={5}>
                  {selectedLayer?.name} File Map (Drag and drop files: tiff (.tif), GGX Z
                  file (.z), Z Map+ file (.zmap))
                  {isUploading && (
                    <span>
                      {" "}
                      <StyledSpinner />
                    </span>
                  )}
                </Title>
                <Divider />
                {selectedLayer && (
                  <MapsContainer>
                    {selectedLayer?.maps
                      ?.filter((f) => f.Raster)
                      .map((map) => (
                        <MapRow
                          key={map.Raster.id}
                          map={map}
                          mapTypes={settings.getAllSettings()}
                          onDeleteMap={() => onDeleteMap(map)}
                          onChange={async (e) => {
                            const newMap = { ...map };
                            newMap.Raster = { ...newMap.Raster, ...e };
                            const arg = { map: newMap, id: selectedLayer._id };
                            const index = selectedLayer.maps.findIndex((m) => m == map);
                            if (index >= 0) {
                              selectedLayer.maps[index] = newMap;
                            }
                            setSelectedLayer({ ...selectedLayer });
                            await updateMap(arg);
                          }}
                          onUseExpressionChanged={async (checked) => {
                            if (checked) {
                              const field = settings
                                .getAllSettings()
                                .find((f) => f.name === map.Raster.name);
                              const expression = field?.expression
                                ? field.expression
                                : map.Raster?.transformer?.expression ?? "$Value";

                              if (!map.Raster.transformer) {
                                map.Raster.transformer = {
                                  type: "ExpressionTransform",
                                  expression: expression
                                };
                              } else if (!map.Raster.transformer.expression) {
                                map.Raster.transformer.expression = expression;
                              }
                            } else {
                              delete map.Raster.transformer;
                            }
                            await updateMap({ map, id: selectedLayer._id });
                            setSelectedLayer({ ...selectedLayer });
                          }}
                          onExpressionChanged={(e) => onExpressionChanged(e, map)}
                          onMapNameChanged={async (name) => {
                            await onMapNameChanged(name, map);
                          }}
                        />
                      ))}
                  </MapsContainer>
                )}
              </Section>
            </StyledRow>
            <StyledRow>
              <Section ref={calculationContainerRef}>
                <TitleContainer>
                  <Title level={5}>Calculation Map</Title>
                  <HorizontalWrapper>
                    <Select
                      value={selectedCalculationMapTemplate}
                      onChange={(value) => {
                        setSelectedCalculationMapTemplate(value);
                      }}
                      options={[
                        {
                          label: "Single",
                          value: "Single"
                        }
                      ].concat(
                        (calculationFieldTemplates ?? []).map((template) => ({
                          label: template.name,
                          value: template.name
                        }))
                      )}
                    />

                    <Button
                      type="primary"
                      onClick={onAddNewCalculationMap}
                      icon={<Icon path={mdiPlus} size={1.2} />}>
                      Add Maps
                    </Button>
                    <FieldTemplatePopoverActions
                      template={selectedCalculationMapTemplate}
                      saveMapAsNewTemplate={() => {
                        setTimeout(() => {
                          //setTimeout to allow the menu to close before the modal opens
                          setShowModal(true);
                        }, 500);
                      }}
                      deleteSelectedTemplate={async () => {
                        const found = calculationFieldTemplates.find(
                          (f) => f.name === selectedCalculationMapTemplate
                        );
                        if (found && found.id["$oid"]) {
                          await deleteFieldTemplate({ id: found.id["$oid"] });
                          await queryClient.invalidateQueries(
                            GeoMapFieldTemplatesQueryKey
                          );
                          setSelectedCalculationMapTemplate("Single");
                        }
                      }}
                    />
                  </HorizontalWrapper>
                </TitleContainer>
                <Divider />
                {selectedLayer && (
                  <MapsContainer>
                    {selectedLayer?.maps
                      ?.filter((f) => f.Calculation)
                      .map((map) => (
                        <MapRow
                          key={map.Calculation.id}
                          map={map}
                          onChange={async (e) => {
                            const newMap = { ...map };
                            newMap.Calculation = { ...newMap.Calculation, ...e };
                            const index = selectedLayer.maps.findIndex((m) => m == map);
                            if (index >= 0) {
                              selectedLayer.maps[index] = newMap;
                            }
                            setSelectedLayer({ ...selectedLayer });
                            await updateMap({ map: newMap, id: selectedLayer._id });
                          }}
                          mapTypes={settings.getAllSettings()}
                          onDeleteMap={() => onDeleteMap(map)}
                          onMapNameChanged={async (name) => {
                            if (name === "") {
                              return;
                            }
                            await onMapNameChanged(name, map);
                          }}
                          onExpressionChanged={(e) => onExpressionChanged(e, map)}
                        />
                      ))}
                  </MapsContainer>
                )}
              </Section>
              <TemplateNameModal
                containerRef={calculationContainerRef}
                isVisible={showModal}
                onOk={saveCalculationMapsAsNewTemplate}
                onCancel={() => setShowModal(false)}
              />
            </StyledRow>
            <StyledRow className="button-row">
              {hasError && (
                <Alert
                  closable
                  onClose={() => setHasError(false)}
                  message={
                    makeMapError?.response?.data ??
                    makeMapError?.message ??
                    uploadMapError?.response?.data ??
                    uploadMapError?.message ??
                    "Error occurred while updating."
                  }
                  type="error"
                />
              )}
              {!hasError && <ShowMapContainer></ShowMapContainer>}
              <HorizontalWrapper>
                <Button
                  type="primary"
                  loading={isMakingMap}
                  icon={<Icon path={mdiMap} size={1.2} />}
                  disabled={!selectedLayer?.hasMap}
                  onClick={async () => {
                    if (!selectedLayer) return;
                    await makeMap({
                      id: selectedLayer._id,
                      kind: MapKind.LSD
                    });
                    await refetch();
                    toast.success("LSD Map Created", {
                      autoClose: false,
                      containerId: toastContainerId
                    });
                  }}>
                  Lsd Map
                </Button>
                <Button
                  type="primary"
                  loading={isMakingMap}
                  icon={<Icon path={mdiMap} size={1.2} />}
                  onClick={async () => {
                    if (!selectedLayer) return;
                    await makeMap({
                      id: selectedLayer._id,
                      kind: MapKind.Native
                    });
                    await refetch();
                    toast.success("Map Created", {
                      autoClose: false,
                      containerId: toastContainerId
                    });
                  }}>
                  Make Layer Map
                </Button>
              </HorizontalWrapper>
            </StyledRow>
          </StyledContent>
          <ButtonContainer>
            <Button
              type="ghost"
              title="Settings"
              icon={<Icon path={mdiCog} size={1.5} />}
              onClick={() => setShowSettings(true)}
            />
          </ButtonContainer>
        </RootLayout>
      </Tabs.TabPane>
    </StyledTabs>
  );
}

const ButtonContainer = styled.div`
  position: absolute;
  top: -60px;
  padding: 5px;
  right: 0;

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ListItemContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .action-btn {
    visibility: ${(props) => (props.selected ? "visible" : "hidden")};
  }

  &:hover {
    .action-btn {
      visibility: visible;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-nav-wrap {
    padding-left: 5px;
  }

  .ant-tabs-content {
    height: 100%;

    .ant-tabs-tabpane {
      height: 100%;
    }
  }
`;

const StyledListItem = styled(List.Item)`
  cursor: pointer;

  &:hover {
    background-color: rgb(219, 219, 219);
  }

  background-color: ${(props) => (props.selected ? "rgba(4, 28, 44, 0.1)" : "white")};

  & {
    padding: 5px;
  }
`;

const ShowMapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-select {
    display: flex;
    min-width: 140px;
  }
`;

const MapsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: white;
  height: calc(100% - 38px);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RootLayout = styled(Layout)`
  height: 100%;
  display: flex;
  padding: 5px;

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ant-divider {
    margin: 5px 0;
  }

  .ant-typography {
    margin: 0;
  }

  .ant-checkbox {
    margin-top: -4px;
  }

  .ant-collapse-header {
    padding: 5px 0 !important;
    display: flex !important;
    align-items: center !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;

    .ant-list-item {
      padding-left: 5px;
    }

    .ant-btn {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

const GroupTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledSider = styled(Layout.Sider)`
  width: 200px;
  padding: 5px;
  height: 100%;
  overflow-y: auto;
  background-color: var(--color-background);
`;
const getColor = (props) => {
  if (props.isDragActive) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  return "none";
};
const StyledCollapse = styled(Collapse)``;
const Section = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: white;
  border: 3px dashed ${(props) => getColor(props)};
`;

const StyledRow = styled(Row)`
  height: 100%;
  position: relative;
  overflow-y: auto;
  background-color: white;

  &.button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-btn {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledContent = styled(Content)`
  height: 100%;
  display: grid;
  padding: 5px;
  padding-top: 0;
  gap: 5px;
  grid-template-rows: 1fr 1fr;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  .ant-select {
    min-width: 140px;
  }
`;
