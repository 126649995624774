import { RangeModel, TownshipModel } from "api/useTownshipRangeGrid";

export const removeOverlappingLabels = (
  array: RangeModel[] | TownshipModel[],
  minimumSpacing: number,
  coordinate: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => {
  let i = 0;
  let next = 1;
  let largestGap = 1;
  let noOverlapArray = [];
  const evenlySpacedArray = [];
  if (
    array &&
    array.length > 0 &&
    Object.prototype.hasOwnProperty.call(array[0], coordinate)
  ) {
    array.sort((a, b) => a[coordinate] - b[coordinate]);
    if (coordinate === "centerX") {
      while (i < array.length) {
        if (i + next < array.length) {
          if (
            Math.abs(array[i][coordinate] - array[i + next][coordinate]) > minimumSpacing
          ) {
            noOverlapArray.push(array[i]);
            if (next > largestGap) {
              largestGap = next;
            }
            i = i + next;
            next = 1;
          } else {
            next++;
          }
        } else {
          noOverlapArray.push(array[i]);
          break;
        }
      }
    } else if (coordinate === "centerY") {
      while (i < array.length) {
        if (i + next < array.length) {
          if (
            Math.abs(array[i][coordinate] - array[i + next][coordinate]) > minimumSpacing
          ) {
            if (next > largestGap) {
              largestGap = next;
            }
            i = i + next;
            next = 1;
          } else {
            next++;
          }
        } else {
          break;
        }
      }
      for (let x = 0; x < array.length; x = x + largestGap) {
        evenlySpacedArray.push(array[x]);
      }
      noOverlapArray = evenlySpacedArray;
    }
  }

  return noOverlapArray;
};

export default removeOverlappingLabels;
