import { useQuery } from "react-query";

import axios from "axios";
import { IS_DEV_ENV, SAM_SERVICE_URI } from "constants/app.constants";

const useRfPvQuery = (wellList, publicDataOnly) => {
  const {
    data: chartData,
    refetch: updateData,
    isFetching
  } = useQuery(
    "sam-rv-pv",
    async () => {
      if (IS_DEV_ENV) return devResponse;

      const response = await axios.post(`${SAM_SERVICE_URI}/SamChart/rf-pv`, {
        ShowForecast: true,
        WellPairUwis: wellList,
        PublicDataOnly: publicDataOnly
      });
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  return { chartData, updateData, isFetching };
};

const devResponse = {
  title: "",
  series: [],
  label: "Bitumen",
  style: {
    thickness: 0,
    color: 0,
    dashed: false,
    hexColor: ""
  },
  isSecondaryYAxis: false,
  mode: 0,
  type: 0
};

export default useRfPvQuery;
