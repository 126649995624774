import { useMutation } from "react-query";

import { loadSavedFilters } from "api/userDefaults";

import { SavedFilterModel } from "models";

import { EntityKind } from "../../../../../models/entityKind";

// TODO BF: refactor this to use the useQuery hook
export function useGetProjectSavedFilters(
  handleSuccess: (data: { savedFilters: SavedFilterModel[] }) => void,
  handleError: (err) => void = null
) {
  const { isLoading, isError, error, mutate, data } = useMutation(
    "loadSavedFilters",
    (data: { projectId: string; entityKind: EntityKind }) => {
      return loadSavedFilters(data.projectId, data.entityKind);
    },
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );

  return { isLoading, isError, error, mutate, data };
}
