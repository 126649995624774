import { IScreenshotAbbreviation } from "models/screenshot";

export const DEFAULT_USER_ABBREVIATIONS: IScreenshotAbbreviation[] = [
  {
    type: "system",
    current: "Average",
    abbreviation: "Avg",
    caseSensitive: true
  },
  {
    current: "Calendar Day",
    type: "system",
    abbreviation: "Cal. Day",
    caseSensitive: true
  },
  {
    type: "system",
    current: "Producing Day",
    abbreviation: "Prod. Day",
    caseSensitive: true
  },
  {
    type: "system",
    current: "Total Aggregate Rate",
    abbreviation: "Total Rate",
    caseSensitive: true
  },
  {
    type: "system",
    current: "Concentration",
    abbreviation: "Conc.",
    caseSensitive: true
  },
  {
    type: "system",
    current: "Operator (Short)",
    abbreviation: "Operator",
    caseSensitive: true
  },
  {
    type: "system",
    current: "Less Than",
    abbreviation: "Remaining",
    caseSensitive: true
  },
  {
    type: "system",
    current: " Sample Size",
    abbreviation: "",
    caseSensitive: true
  },
  {
    type: "system",
    current: "zOther",
    abbreviation: "Other",
    caseSensitive: true
  }
];
