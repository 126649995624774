import { memo, useState } from "react";

import { Popover } from "antd";

import CollapseVerticalIcon from "components/icons/CollapseVertical";
import { useChartSettings } from "components/multiphase-chart/context";

import ToolbarButton from "../../../toolbar/ToolbarButton";
import Normalize from "./Normalize";

const NormalizeToggle = () => {
  const [chartSettings] = useChartSettings();
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      arrowPointAtCenter
      content={<Normalize />}
      onOpenChange={(v) => setVisible(v)}
      open={visible}
      overlayClassName="normalize-popover"
      placement="bottomRight"
      trigger="click">
      <ToolbarButton
        testId="multiphasechart-toolbar-normalize-toggle"
        icon={<CollapseVerticalIcon />}
        overflowLabel="Normalize"
        tooltipText="Normalize"
        active={visible || chartSettings.settings?.useNormalizeBy}
        isGlobalSetting={true}
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
};

export default memo(NormalizeToggle);
