import { useMutation } from "react-query";

import { BetaFeatureEntry, getBetaFeatures } from "api/appTools";

export default function useGetBetaFeatures(
  handlePostData: (postData: BetaFeatureEntry[]) => void = null,
  handleError: (err) => void = null
) {
  const { isLoading, isError, error, mutate, data } = useMutation<BetaFeatureEntry[]>(
    "getBetaFeatures",
    async () => {
      const result = await getBetaFeatures();
      return result as BetaFeatureEntry[];
    },
    {
      onSuccess: (postData) => {
        handlePostData && handlePostData(postData);
      },
      onError: (err) => {
        if (handleError) {
          handleError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
  );

  return { isLoading, isError, error, mutate, data };
}
