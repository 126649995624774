import { memo } from "react";

function Heading({ element = "h2", className = "", children }) {
  const classes = `${element} heading ${className}`;
  return element === "h1" ? (
    <h1 className={classes}>{children}</h1>
  ) : element === "h2" ? (
    <h2 className={classes}>{children}</h2>
  ) : element === "h3" ? (
    <h3 className={classes}>{children}</h3>
  ) : element === "h4" ? (
    <h4 className={classes}>{children}</h4>
  ) : element === "h5" ? (
    <h5 className={classes}>{children}</h5>
  ) : (
    <h6 className={classes}>{children}</h6>
  );
}

export default memo(Heading);
