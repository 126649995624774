import { WELL_LAYER } from "constants/mapLayers.constants";

export async function getSingleWell(map) {
  if (!map) {
    return null;
  }

  const center = map.getCenter();

  const coordinates = map.project([center.lng, center.lat]);
  const bbox = [
    [coordinates.x - 5, coordinates.y - 5],
    [coordinates.x + 5, coordinates.y + 5]
  ];
  if (!map.getLayer(WELL_LAYER)) {
    return null;
  }
  if (!map.getLayer(WELL_LAYER)) {
    return;
  }
  let features = map.queryRenderedFeatures(bbox, {
    layers: [WELL_LAYER]
  });

  if (!features || features.length === 0) {
    features = map.queryRenderedFeatures({ layers: [WELL_LAYER] });
  }

  if (!features || features.length === 0) {
    return null;
  }
  const well = features[0];
  return well;
}
