import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { fetchEntitiesOnMap } from "api/map";

import { updateColorMap, useTable } from "components/data-table/context";

import { TxnT } from "../../../models";
import { EntityKind } from "../../../models/entityKind";

/**
 * Simple `hook` that
 * - fetches entities on Map,
 * - extracts uwi: #hexcode pair, and
 * - saves it to TableContext as `colorMap`
 */
const useEntitiesOnMap = () => {
  const [{ entityKind }, dispatch] = useTable();
  const txnId: TxnT = useSelector((state: RootState) => state.map.txnId);

  // useQuery arguments
  const queryKey = "entitiesOnMap";
  const queryFn = () =>
    fetchEntitiesOnMap(entityKind == EntityKind.Well ? txnId.id : txnId.facilityId);
  const { data, isSuccess } = useQuery([queryKey, txnId], queryFn);

  useEffect(() => {
    if (!txnId?.id && !txnId.facilityId) {
      return;
    }
    if (!isSuccess) return;

    // extract {uwi: #hexCode} pairs
    const nextValue = Object.keys(data).reduce(
      (prev, curr) => ({ ...prev, ...data[curr].uwis }),
      {}
    );
    // save to context
    updateColorMap(dispatch, nextValue);
  }, [txnId, isSuccess]);
};

export default useEntitiesOnMap;
