import { useQuery } from "react-query";

import axios from "axios";

import { IColorPalette } from "../models";

const COLOR_SVC_URI = process.env.REACT_APP_COLOR_SERVICE;

export type ColorPaletteType = "org" | "user" | "both";

export default function useColorPalettes(type: ColorPaletteType) {
  const result = useQuery(
    ["color-palette", type],
    async (type) => {
      const response = await axios.get<IColorPalette[]>(
        `${COLOR_SVC_URI}/palette/${type.queryKey[1]}`
      );
      if (response.status !== 200) {
        throw "Unable to fetch color palettes.";
      }
      return response.data;
    },
    {
      enabled: false
    }
  );
  return result;
}
