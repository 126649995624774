// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert, mdiUndo } from "@mdi/js";
import { Button } from "antd";
import _debounce from "lodash/debounce";

import { DEFAULT_MAP_SETTING } from "components/user-settings/constants/userSetting.constants";
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM,
  DefaultMapSettings as DefaultMapSettingsModel
} from "components/user-settings/models";
import { useUserContext } from "components/user/context";
import { updateSettingMap } from "components/user/context/reducer";
import { useUserDefaults, useUserSettingMutation } from "components/user/hooks";

import {
  ErrorContainer,
  SectionActionsContainer,
  SectionContainer,
  SectionHeading,
  SectionInputWrapper,
  StyledNumberInput,
  SubsectionContainer,
  SubsectionHeading
} from "./shared";

type DefaultMapSettingsT = {
  enableZoom?: boolean;
};
const DefaultMapSettings = ({ enableZoom = true }: DefaultMapSettingsT) => {
  // hooks
  const [, dispatch] = useUserContext();
  const defaults = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultMapSettingsModel>(DEFAULT_MAP_SETTING);

  const mapSettings = defaults?.mapSettings;

  // state
  const [currentMapSettings, setCurrentMapSettings] =
    useState<DefaultMapSettingsModel>(mapSettings);
  const [updateError, setUpdateError] = useState("");

  const debouncedSettingsChange = _debounce((settings: DefaultMapSettingsModel) => {
    mutation.mutate(settings);
  }, 400);

  const updateMapSettings = useCallback(
    (settings: DefaultMapSettingsModel) => {
      debouncedSettingsChange({
        id: mapSettings?.id,
        latitude: settings.latitude,
        longitude: settings.longitude,
        zoom: settings.zoom
      });
    },
    [mapSettings?.id]
  );

  const handleLatitudeChanged = useCallback(
    (value: number) => {
      const newSettings = {
        latitude: value,
        longitude: currentMapSettings?.longitude,
        zoom: currentMapSettings.zoom
      };
      setCurrentMapSettings(newSettings);
      updateMapSettings(newSettings);
    },
    [currentMapSettings?.longitude, currentMapSettings?.zoom, updateMapSettings]
  );

  const handleLongitudeChanged = useCallback(
    (value: number) => {
      const newSettings = {
        longitude: value,
        latitude: currentMapSettings?.latitude,
        zoom: currentMapSettings.zoom
      };
      setCurrentMapSettings(newSettings);
      updateMapSettings(newSettings);
    },
    [currentMapSettings?.latitude, currentMapSettings?.zoom, updateMapSettings]
  );

  const handleZoomChanged = useCallback(
    (value: number) => {
      const newSettings = {
        longitude: currentMapSettings?.longitude,
        latitude: currentMapSettings?.latitude,
        zoom: value
      };
      setCurrentMapSettings(newSettings);
      updateMapSettings(newSettings);
    },
    [currentMapSettings?.latitude, currentMapSettings?.longitude, updateMapSettings]
  );

  const handleResetMapSettings = useCallback(() => {
    const newSettings = {
      longitude: DEFAULT_LONGITUDE,
      latitude: DEFAULT_LATITUDE,
      zoom: DEFAULT_ZOOM
    };
    setCurrentMapSettings(newSettings);
    updateMapSettings(newSettings);
  }, [updateMapSettings]);

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    updateSettingMap(dispatch, {
      id: mutation.data.id,
      longitude: mutation.data.longitude,
      latitude: mutation.data.latitude,
      zoom: mutation.data.zoom
    });
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update default map center point.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  return (
    <SectionContainer>
      <SectionHeading>
        Map Center Point
        <SectionActionsContainer>
          <Button
            type="text"
            data-testid="reset-button"
            onClick={handleResetMapSettings}
            icon={<Icon path={mdiUndo} size={1} />}>
            Reset
          </Button>
        </SectionActionsContainer>
      </SectionHeading>
      <SubsectionContainer>
        <SubsectionHeading>Latitude</SubsectionHeading>
        <SectionInputWrapper>
          <StyledNumberInput
            data-testid="input-latitude"
            step={0.000001}
            value={currentMapSettings?.latitude}
            onChange={handleLatitudeChanged}
          />
        </SectionInputWrapper>
      </SubsectionContainer>
      <SubsectionContainer>
        <SubsectionHeading>Longitude</SubsectionHeading>
        <SectionInputWrapper>
          <StyledNumberInput
            data-testid="input-longitude"
            step={0.000001}
            value={currentMapSettings?.longitude}
            onChange={handleLongitudeChanged}
          />
        </SectionInputWrapper>
      </SubsectionContainer>
      {enableZoom && (
        <SubsectionContainer>
          <SubsectionHeading>Zoom</SubsectionHeading>
          <SectionInputWrapper>
            <StyledNumberInput
              data-testid="input-zoom"
              step={0.01}
              value={currentMapSettings?.zoom}
              onChange={handleZoomChanged}
            />
          </SectionInputWrapper>
        </SubsectionContainer>
      )}

      {updateError && (
        <ErrorContainer>
          <Icon path={mdiAlert} size={1} /> {updateError}
        </ErrorContainer>
      )}
    </SectionContainer>
  );
};

export default DefaultMapSettings;
