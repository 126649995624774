import styled from "styled-components";

import DashboardPane from "components/dashboard/DashboardPane";
import DashboardWrapper from "components/dashboard/DashboardWrapper";
import ScreenshotActivity from "components/screenshot/ScreenshotActivity";
import { useScreenshotContext } from "components/screenshot/hooks";

function NewWindowDashboard(): JSX.Element {
  // context from hooks
  const { widget } = useScreenshotContext();

  return (
    <Wrapper>
      {widget ? (
        <ActivityWrapper>
          <ScreenshotActivity />
        </ActivityWrapper>
      ) : (
        <div></div>
      )}
      <DashboardWrapper isPopoutDashboard={true}>
        <DashboardPane isPopoutDashboard={true} />
      </DashboardWrapper>
    </Wrapper>
  );
}

export default NewWindowDashboard;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  width: 100%;
`;

const ActivityWrapper = styled.div`
  border-right: 1px solid rgba(var(--color-text-rgb), 0.1);
  width: 359px;
`;
