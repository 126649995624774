import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { GEO_CALCULATION_SERVICE_ADDRESS } from "../modals/constants";

export function useDeleteGeoCalculationJob() {
  const queryClient = useQueryClient();
  const deleteRecord = async (id: string) => {
    // Here you would make your HTTP request or whatever your deletion logic needs to be
    const response = await axios.delete(
      `${GEO_CALCULATION_SERVICE_ADDRESS}/geo-calculation/jobs/${id}`
    );

    if (response.status !== 200) {
      throw new Error("Problem deleting record");
    }
  };
  return useMutation(deleteRecord, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("geoCalculations");
    }
  });
}
