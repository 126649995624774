import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import styled from "styled-components";

import {
  WellColumnDataAnalyzerResult,
  WellPerformanceDataAnalyzerResult
} from "api/appTools";

import { Uwi } from "models";

import {
  useSelectedWellFromStore,
  useWellColumnDataAnalyzer,
  useWellPerformanceDataAnalyzer
} from "../hooks";
import ColumnDataAnalyzerTable from "./ColumnDataAnalyzerTable";
import PerformanceDataAnalyzerTable from "./PerformanceDataAnalyzerTable";

const WellDataAnalyzerWidget = () => {
  const [columnData, setColumnData] = useState<WellColumnDataAnalyzerResult>(null);
  const [performanceData, setPerformanceData] =
    useState<WellPerformanceDataAnalyzerResult>(null);
  const [analyzerError, setAnalyzerError] = useState<string>(null);
  const [analyzerErrorDetails, setAnalyzerErrorDetails] = useState<string>(null);

  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);

  const selectedWell = useSelectedWellFromStore();
  const { mutate: columnMutate, isLoading: columnIsLoading } = useWellColumnDataAnalyzer(
    (data) => {
      setColumnData(data);
    },
    (err) => {
      if (err.response?.data) {
        setAnalyzerErrorDetails(err.response?.data);
      } else {
        setAnalyzerErrorDetails(err.message);
      }
      setAnalyzerError("Failed to analyze column data");
    }
  );

  const { mutate: performanceMutate, isLoading: performanceIsLoading } =
    useWellPerformanceDataAnalyzer(
      (data) => {
        setPerformanceData(data);
      },
      (err) => {
        if (err.response?.data) {
          setAnalyzerErrorDetails(err.response?.data);
        } else {
          setAnalyzerErrorDetails(err.message);
        }
        setAnalyzerError("Failed to analyze performance data");
      }
    );

  const unsupportedGroups = [
    "Forecast",
    "Forecast",
    "Forecast",
    "Shapefile",
    "ShapefileGroup"
  ];

  const uwi = selectedWell?.Uwi;
  const propertyName = groupBy?.property;

  useEffect(() => {
    if (!uwi || !propertyName) {
      return;
    }

    setAnalyzerError(null);

    if (propertyName.startsWith("Performance")) {
      performanceMutate({
        uwi,
        settings: {
          performanceProperty: propertyName,
          product: ""
        }
      });
    } else {
      columnMutate({
        uwi,
        settings: {
          propertyName
        }
      });
    }
  }, [uwi, propertyName]);

  const isLoading = columnIsLoading || performanceIsLoading;
  const isReady = uwi && propertyName && !unsupportedGroups.includes(groupBy?.group);

  return (
    <Wrapper>
      <InnerWrapper>
        {!isReady && (
          <OverlayContainer>
            <OverlayMessages>
              <NoSelectedWellContainer>
                Please select a well on the map
              </NoSelectedWellContainer>
            </OverlayMessages>
          </OverlayContainer>
        )}
        {isReady && (
          <DetailsWrapper>
            <DetailsTitle>{new Uwi().toFormatted(uwi)}</DetailsTitle>
            <DetailsTitle>{groupBy?.title}</DetailsTitle>
          </DetailsWrapper>
        )}
        {isReady && !propertyName.startsWith("Performance") && (
          <ColumnDataAnalyzerTable
            data={columnData}
            isLoading={isLoading}
            analyzerError={analyzerError}
            analyzerErrorDetails={analyzerErrorDetails}
          />
        )}
        {isReady && propertyName.startsWith("Performance") && (
          <PerformanceDataAnalyzerTable
            data={performanceData}
            isLoading={isLoading}
            analyzerError={analyzerError}
            analyzerErrorDetails={analyzerErrorDetails}
          />
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default WellDataAnalyzerWidget;

const Wrapper = styled.div`
  height: 100%;
  background-color: #f5f5f5;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 10px;
  overflow: auto;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;

  & > div:first-child {
    color: var(--color-primary);
    font-size: 18px;
  }
`;

const DetailsTitle = styled.div`
  font-weight: 600;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 4;
`;
const OverlayMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: inherit;
`;

const NoSelectedWellContainer = styled.div`
  color: var(--color-white);
  background-color: var(--color-text);
  border-radius: 5px;
  padding: 2px 20px;
  margin: 0 20px;
`;
