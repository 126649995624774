import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import {
  ORGANIZATION_SHAPEFILES_QUERY_KEYS,
  PROJECT_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

export interface UploadShapefilesParams {
  files: File[];
  visibility: string;
  projectId: string;
  shapefileNodeId?: string;
  thickness?: string;
}

export async function uploadShapefiles(params: UploadShapefilesParams) {
  const { files, visibility, projectId, shapefileNodeId, thickness } = params;

  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));

  formData.append("projectId", projectId);
  formData.append("shapefileNodeId", shapefileNodeId);
  formData.append("thickness", thickness || "2.0");
  formData.append("visibility", visibility);
  try {
    return axios.post(`${projectServiceEndpoint}/shapefile/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  } catch (error) {
    return error;
  }
}

export interface IUploadProjectShapefileListMutationParams {
  onMutate?: () => void;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  onSettled?: () => void;
  projectId?: string;
}

export function useUploadProjectShapefileListMutation({
  onError,
  onSuccess,
  onSettled,
  onMutate,
  projectId
}: IUploadProjectShapefileListMutationParams) {
  const queryClient = useQueryClient();
  return useMutation(
    (params: UploadShapefilesParams) => uploadShapefiles({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        if (projectId === "organization") {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });
        }
        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });

        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
