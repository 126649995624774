import { ProjectTreeNodeT } from "components/project/layers/hooks";

/**
 * Removed the descendant folders from the expandedKeys when a node is collapsed
 * The primary reason for doing this is because antd will not collapse the node,
 * if the expandedKeys includes the keys of the descendant folders
 *
 * @param expandedKeys
 * @param node
 * @param isExpanded
 * @returns
 */
export function getKeysExcludingDescendantFolders(
  expandedKeys: string[],
  node: ProjectTreeNodeT,
  isExpanded: boolean
): string[] {
  let nextExpandedKeys: string[] = [...expandedKeys];

  if (node && !isExpanded) {
    // If collapsing a node, remove its key and the keys of its descendants from the expandedKeys
    const descendantKeys: string[] = [];

    const removeKeys = (node: ProjectTreeNodeT) => {
      if (node.children) {
        descendantKeys.push(node.key);

        node.children.forEach((childNode) => {
          removeKeys(childNode);
        });
      }
    };

    removeKeys(node);
    nextExpandedKeys = expandedKeys.filter((key) => !descendantKeys.includes(key));
  }

  return nextExpandedKeys;
}
