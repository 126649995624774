// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiContentCopy } from "@mdi/js";
import { Alert, Button, Popover, Tooltip } from "antd";

import { WellListRecord } from "models/UserArpsModel";

interface ICopyWellListButton {
  wellListRecord: WellListRecord;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const CopyWellListButton = function ({
  wellListRecord,
  setErrorMessage
}: ICopyWellListButton) {
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    if (showCopySuccessMessage && !isCancelled) {
      setTimeout(() => {
        setShowCopySuccessMessage(false);
      }, 2000);
    }

    return () => {
      isCancelled = true;
    };
  }, [showCopySuccessMessage]);

  const copyToClipboard = useCallback((wellListRecords: string[]) => {
    // Formatting not applied
    const text = wellListRecords.map((record) => record).join("\n");

    navigator.clipboard.writeText(text).then(
      function () {
        setErrorMessage(undefined);
        setShowCopySuccessMessage(true);
      },
      function () {
        // eslint-disable-next-line no-console
        setErrorMessage("Error copying well list. Please check browser permission.");
      }
    );
  }, []);

  return (
    <Tooltip title="Copy Well List">
      <Popover
        open={showCopySuccessMessage}
        trigger="click"
        overlayClassName="overlay-no-padding"
        content={<Alert type="success" message="Copied to Clipboard!" />}>
        <Button
          data-testid="copyToClipboardBtn"
          onClick={() => {
            copyToClipboard(wellListRecord.wellList);
          }}>
          <Icon path={mdiContentCopy} className="activity-action-icon" size={1} />
        </Button>
      </Popover>
    </Tooltip>
  );
};
