import { removeCount, removeForecast } from "utils";

import { IChartResult } from "models/model";

import { ChartDataTableColumn, ChartDataTableRow } from "../hooks/useDataTableData";

// NOTE: Changing this logic should result in changes to the chart-svc backend export logic.
const stackedBarDataTable = (
  responseData: IChartResult,
  isHistogram: boolean
): { columns: ChartDataTableColumn[]; rows: ChartDataTableRow[] } => {
  const columns = responseData.layout.xAxis?.data.map((series) => {
    return {
      key: series,
      name: series,
      resizable: false
    };
  });
  let rows;
  if (isHistogram && responseData.series.length === columns.length) {
    const summedValues = responseData.series.reduce((result, series) => {
      series.y.forEach((value, index) => {
        result[index] = (result[index] || 0) + value;
      });
      return result;
    }, []); // sum the y-values in each series
    rows = [
      {
        Series: responseData?.layout?.yAxis?.title,
        ...Object.fromEntries(
          summedValues.map((value, index) => [columns[index].key, value])
        )
      }
    ];
  } else {
    rows = responseData.series?.map((series) => {
      const keys = series.x;
      const values = series.y;
      return {
        Series: removeForecast(removeCount(series.label)),
        SeriesColor: series.style.hexColor,
        ...Object.fromEntries(
          keys.map((key: string, index: number) => [key, values[index]])
        )
      };
    });
  }
  return {
    columns,
    rows
  };
};

export default stackedBarDataTable;
