import { Layout } from "react-grid-layout";

import { GridWidgetTemplate } from "models/dashboard";
import { DashboardLayout } from "models/workspace";

import { WidgetTypes } from "../constants/widgets.constants";

export interface IWidgetDimensions {
  x: number;
  y: number;
  w: number;
  h: number;
}

export const calculateIdealWidgetSize = (
  type: string,
  droppedWidget: Layout,
  widgets: Layout[],
  layout?: DashboardLayout
): IWidgetDimensions => {
  if (!droppedWidget) return { x: 0, y: 0, w: 0, h: 0 };

  let myDims: { x: number; y: number; w: number; h: number } = {
    x: droppedWidget.x,
    y: droppedWidget.y,
    w: droppedWidget.w,
    h: droppedWidget.h
  };

  const template: GridWidgetTemplate = WidgetTypes.find((t) => t.component === type);

  if (!template) {
    return myDims;
  }

  // Shrink the widget down
  if (
    template.maxExpandH &&
    template.maxExpandW &&
    (droppedWidget.h > template.maxExpandH || droppedWidget.w > template.maxExpandW)
  ) {
    droppedWidget.h = template.maxExpandH;
    droppedWidget.w = template.maxExpandW;
  }

  const heights: number[] = widgets.map((widget) => widget.y + widget.h);

  const maxWidth = layout?.width ?? 12;
  const maxHeight = Math.max(layout?.height ?? 12, Math.max(...heights));

  const coords: number[][] = [];
  for (let y = 0; y < maxHeight; y++) {
    const row = [];
    for (let x = 0; x < maxWidth; x++) {
      row.push(0);
    }
    coords.push(row);
  }

  if (widgets) {
    widgets.forEach((widget) => {
      if (widget.i === droppedWidget.i) {
        return;
      }
      for (let x = widget.x; x < widget.x + widget.w; x++) {
        for (let y = widget.y; y < widget.y + widget.h; y++) {
          coords[y][x] = 1;
        }
      }
    });
    myDims = expand(
      template,
      coords,
      droppedWidget.x,
      droppedWidget.y,
      droppedWidget.w,
      droppedWidget.h
    );
  }

  return myDims;
};

const expand = (
  template: GridWidgetTemplate,
  takenCoords: number[][],
  x: number,
  y: number,
  w: number,
  h: number
): { x: number; y: number; w: number; h: number } => {
  const maxHeight = takenCoords.length;
  const maxWidth = takenCoords[0].length;

  // If row is less than 0
  if (x < 0) {
    return;
  }

  // If column is less than 0
  if (y < 0) {
    return;
  }

  // If row is greater than max width
  if (x + w > maxWidth) {
    return;
  }

  //If column is greater than max height
  if (y + h > maxHeight) {
    return;
  }

  // Check for a collision
  for (let x1 = x; x1 < x + w; x1++) {
    for (let y1 = y; y1 < y + h; y1++) {
      if (takenCoords[y1][x1] > 0) {
        return null;
      }
    }
  }

  let myDims: { x: number; y: number; w: number; h: number } = {
    x,
    y,
    w,
    h
  };

  if (!template.maxExpandW || myDims.w < template.maxExpandW) {
    // Expand Left
    myDims =
      expand(template, takenCoords, myDims.x - 1, myDims.y, myDims.w + 1, myDims.h) ??
      myDims;
  }

  if (!template.maxExpandW || myDims.w < template.maxExpandW) {
    // Expand Right
    myDims =
      expand(template, takenCoords, myDims.x, myDims.y, myDims.w + 1, myDims.h) ?? myDims;
  }

  if (!template.maxExpandH || myDims.h < template.maxExpandH) {
    // Expand Up
    myDims =
      expand(template, takenCoords, myDims.x, myDims.y - 1, myDims.w, myDims.h + 1) ??
      myDims;
  }

  if (!template.maxExpandH || myDims.h < template.maxExpandH) {
    // Expand Down
    myDims =
      expand(template, takenCoords, myDims.x, myDims.y, myDims.w, myDims.h + 1) ?? myDims;
  }

  return myDims;
};
