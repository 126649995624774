import React, { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

import { IS_INTERNAL_ENV } from "constants/app.constants";
import { produce } from "immer";
import { uid } from "utils";

import { IUser } from "models/User";

import * as models from "components/user-settings/models";

import { DEFAULT_USER_ABBREVIATIONS } from "../constants/userAbbreviations";
import { UserReducer, UserReducerActionsT } from "./reducer";

export type UserDefaultsT = {
  mapSettings: models.DefaultMapSettings;
  focusTiles?: models.DefaultFocusTiles;
  facilityFocusTiles?: models.DefaultFocusTiles;
  facilityFilter?: models.DefaultFilter;
  facilityPalette?: models.DefaultPalette;
  project?: models.DefaultProject;
  filter?: models.DefaultFilter;
  colorPalette: models.DefaultPalette;
  typeWellDashboard?: models.DefaultTypeWellDashboard;
  binSettings?: models.DefaultBinSettings;
};

export type UserSettingsT = {
  defaults: UserDefaultsT;
  forecastExport?: models.ForecastExportSetting;
  userDocSettings?: models.UserDocSetting;
  declineType?: models.DefaultDeclineType;
  screenshotPresets?: models.UserScreenshotPresets;
  userDashboardSettings?: models.UserDashboardSettings;
  userAbbreviations?: models.UserAbbreviations;
  midstreamSettings?: models.MidstreamSettings;
  geoModelSettings?: models.GeoModelSettings;
};

export interface IUserState {
  user?: IUser;
  sessionId?: string;
  settings?: UserSettingsT;
  email?: string;
  fullName?: string;
  hasTrialAccount?: boolean;
  initials?: string;
  imgSrc?: string;
  isAdmin?: boolean;
  isReadonly?: boolean;
  isUser?: boolean;
  isSuperUser?: boolean;
  isPowerUser?: boolean;
  isAtLeastPowerUser?: boolean;
  isAtLeastAdmin?: boolean;
  isAtLeastUser?: boolean;
}

export const initialUserState: IUserState = {
  sessionId: uid(),
  settings: {
    defaults: {
      mapSettings: {
        longitude: models.DEFAULT_LONGITUDE,
        latitude: models.DEFAULT_LATITUDE,
        zoom: models.DEFAULT_ZOOM
      },
      colorPalette: {
        name: models.DEFAULT_COLOR_PALETTE_NAME,
        reverse: false
      },
      facilityPalette: {
        name: models.DEFAULT_FACILITY_COLOR_PALETTE_NAME,
        reverse: false
      },
      binSettings: {
        type: "BinSize",
        quantile: {
          quantileType: "NumberOfQuantile",
          numQuantiles: 4
        }
      }
    },
    declineType: {
      decline: models.DEFAULT_DECLINE
    },
    userDashboardSettings: {
      borderType: "borderless",
      borderColor: "#ccc",
      borderThickness: 1
    },
    userAbbreviations: {
      abbreviations: DEFAULT_USER_ABBREVIATIONS
    },
    geoModelSettings: {
      organizationEnabled: IS_INTERNAL_ENV ? false : true // turn off org geo models for internal env
    }
  }
};

export type UserDispatchT = (action: UserReducerActionsT) => void;
export type UserProviderT = {
  children: ReactNode;
  initialState?: IUserState;
};

const curriedReducer = produce(UserReducer);

const UserContext =
  createContext<[IUserState, React.Dispatch<UserReducerActionsT>]>(undefined);
UserContext.displayName = "UserContext";

const UserProvider = ({ children, initialState }: UserProviderT) => {
  const newState = initialState ?? initialUserState;

  const [state, dispatch] = useReducer(curriedReducer, newState);
  const value: [IUserState, UserDispatchT] = useMemo(() => [state, dispatch], [state]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within UserProvider");
  }
  return context;
};

export { curriedReducer, UserProvider, useUserContext };
