// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";

import { mdiAlert, mdiDelete, mdiPlus } from "@mdi/js";
import { Button } from "antd";
import _debounce from "lodash/debounce";
import styled from "styled-components";
import formatPropertyName from "utils/column/formatPropertyName";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";

import { useColumns, useGlobalProductionSettings } from "hooks";

import { Tooltip } from "components/base";
import { GeomBinProvider } from "components/geom-bin/contexts/GeomBinContext";
import GroupBy from "components/groupBy";
import { DefaultFocusTiles as DefaultFocusTilesModel } from "components/user-settings/models";
import { useUserContext } from "components/user/context";
import { updateSettingFocus } from "components/user/context/reducer";
import { useUserDefaults, useUserSettingMutation } from "components/user/hooks";

import { useFacilityColumns } from "../../../../hooks/useColumns";
import { EntityKind } from "../../../../models/entityKind";
import { updateSettingFacilityFocus } from "../../../user/context/reducer/UserReducer";
import {
  DEFAULT_FACILITY_FOCUS_TILES_SETTING,
  DEFAULT_FOCUS_TILES_SETTING
} from "../../constants/userSetting.constants";
import {
  ErrorContainer,
  SectionActionsContainer,
  SectionContainer,
  SectionHeading,
  SectionInputWrapper,
  StyledSpinner,
  WarningContainer
} from "./shared";

interface DefaultFocusTilesProps {
  entityKind: EntityKind;
}

const DefaultFocusTiles = ({ entityKind }: DefaultFocusTilesProps) => {
  // hooks
  const [, dispatch] = useUserContext();
  const defaults = useUserDefaults();
  const mutation = useUserSettingMutation<DefaultFocusTilesModel>(
    entityKind == EntityKind.Well
      ? DEFAULT_FOCUS_TILES_SETTING
      : DEFAULT_FACILITY_FOCUS_TILES_SETTING
  );
  const columns = useColumns();
  const facilityColumns = useFacilityColumns();
  const globalSettings = useGlobalProductionSettings(EntityKind.Well);
  const columnsToUse = entityKind === EntityKind.Well ? columns : facilityColumns;

  // state
  const [updateError, setUpdateError] = useState("");
  const [warning, setWarning] = useState("");
  const defaultTiles = useCallback(() => {
    return entityKind === EntityKind.Well
      ? defaults?.focusTiles
      : defaults?.facilityFocusTiles;
  }, [entityKind, defaults]);
  const tiles = defaultTiles()?.tiles ?? [];

  const handleDefaultFocusTilesChange = useCallback(
    (index: number, propertyName: string) => {
      if (defaultTiles().tiles?.includes(propertyName)) {
        setUpdateError("Tile already set as a default");
        return;
      }
      const newTiles = [...defaultTiles().tiles];
      newTiles[index] = propertyName;
      mutation.mutate({
        id: defaultTiles()?.id,
        tiles: newTiles
      });
    },
    [defaultTiles]
  );

  // Handle reload
  useEffect(() => {
    if (!mutation?.data?.id) return;

    setUpdateError("");
    if (entityKind === EntityKind.Well) {
      updateSettingFocus(dispatch, {
        id: mutation.data.id,
        tiles: mutation.data.tiles
      });
    } else {
      updateSettingFacilityFocus(dispatch, {
        id: mutation.data.id,
        tiles: mutation.data.tiles
      });
    }
  }, [mutation?.data]);

  // Handle reload
  useEffect(() => {
    if (mutation?.error) {
      // eslint-disable-next-line no-console
      console.error(mutation?.error);
      setUpdateError("Failed to update default focus tiles.");
    } else {
      setUpdateError("");
    }
  }, [mutation?.error]);

  useEffect(() => {
    if (tiles.length < 6 || tiles.length > 8) {
      setWarning("EVA is optimized for 6-8 focus tiles");
    } else {
      setWarning("");
    }
  }, [tiles]);

  const debouncedAddFocusTile = _debounce((settings: DefaultFocusTilesModel) => {
    mutation.mutate(settings);
  }, 400);

  const handleAddFocusTile = useCallback(() => {
    const focusTiles = defaultTiles();
    const next = columnsToUse.filter((c) => !focusTiles.tiles.includes(c.property))[0];
    const newTiles = [...focusTiles.tiles, next.property];

    debouncedAddFocusTile({
      id: focusTiles?.id,
      tiles: newTiles
    });
  }, [defaultTiles]);

  const handleRemoveFocusTile = useCallback(
    (e: MouseEvent, index: number) => {
      const focusTiles = defaultTiles();
      const newTiles = [...focusTiles.tiles];

      newTiles.splice(index, 1);

      mutation.mutate({
        id: focusTiles?.id,
        tiles: newTiles
      });

      e.stopPropagation();
    },
    [defaultTiles]
  );

  const isLoading = columnsToUse.length === 0;

  return (
    <GeomBinProvider>
      <SectionContainer>
        <SectionHeading>
          {entityKind} Focus Tiles
          {tiles.length < 12 && (
            <SectionActionsContainer>
              <Button
                data-testid={`${entityKind}-default-focus-tiles-add`}
                type="text"
                icon={<Icon path={mdiPlus} size={1} />}
                onClick={handleAddFocusTile}
                loading={mutation.isLoading}>
                Add
              </Button>
            </SectionActionsContainer>
          )}
        </SectionHeading>
        <SectionInputWrapper>
          {isLoading && <StyledSpinner />}
          {!isLoading && (
            <FocusGrid data-testid={`${entityKind}-focus-tiles`}>
              {tiles.map((tile, index) => {
                const propertyName = formatPropertyName(tile);
                const column = columnsToUse.find((c) => propertyName === c.property);

                if (!column) return;

                const containsFcst = tile.includes(".fcst");
                const label = getColumnLabelWithContext(
                  column,
                  !!globalSettings.normalizeBy,
                  globalSettings.normalizeBy,
                  containsFcst,
                  entityKind
                );
                return (
                  <GroupBy
                    key={tile}
                    excludedCategoryFilters={["Shapefile"]}
                    value={column}
                    entityKind={entityKind}
                    onChange={(groupBy) => {
                      handleDefaultFocusTilesChange(index, groupBy.property);
                    }}>
                    <StyledButton
                      data-testid={`${entityKind}-default-focus-tiles-button-${index}`}>
                      <FocusTileTitle>
                        {label?.lightContextTitle ?? column.title}
                      </FocusTileTitle>
                      {tiles.length > 2 && (
                        <Tooltip title="Remove focus tile">
                          <StyledDeleteButton
                            type="text"
                            data-testid={`${entityKind}-default-focus-tiles-delete-${index}`}
                            icon={<Icon path={mdiDelete} size={1.3} />}
                            onClick={(e) => handleRemoveFocusTile(e, index)}
                          />
                        </Tooltip>
                      )}
                    </StyledButton>
                  </GroupBy>
                );
              })}
            </FocusGrid>
          )}
        </SectionInputWrapper>

        {updateError && (
          <ErrorContainer>
            <Icon path={mdiAlert} size={1} /> {updateError}
          </ErrorContainer>
        )}
        {warning && (
          <WarningContainer>
            <Icon path={mdiAlert} size={1} /> {warning}
          </WarningContainer>
        )}
      </SectionContainer>
    </GeomBinProvider>
  );
};

export default DefaultFocusTiles;

const FocusGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

const StyledDeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 2px;
  padding: 0;
  color: #b5b5b5;
  opacity: 0;

  &:hover {
    color: var(--color-danger);
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
  background-color: rgba(var(--color-text-rgb), 0.05);
  color: var(--color-text);

  &:hover {
    background-color: var(--color-text);
    color: #fff;

    ${StyledDeleteButton} {
      opacity: 1;
    }
  }
`;
const FocusTileTitle = styled.div`
  text-align: center;
`;
