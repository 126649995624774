import { GEO_MAP_SERVICE_ADDRESS } from "constants/app.constants";

import { Shapefile } from "components/project/shapefiles/queries";

interface IAddGeoTiffLayerParams {
  shapefile: Shapefile;
  layerName: string;
  mapbox: mapboxgl.Map;
  addLayer;
}

export function addGeoTiffLayer(params: IAddGeoTiffLayerParams) {
  const { shapefile, layerName, mapbox, addLayer } = params;
  if (shapefile.boundingBox?.length !== 4) return;

  mapbox.addSource(layerName, {
    type: "image",
    url: `${GEO_MAP_SERVICE_ADDRESS}/geo-tiff/${layerName}`,
    coordinates: [
      [
        Math.min(shapefile.boundingBox[0], shapefile.boundingBox[2]),
        Math.max(shapefile.boundingBox[1], shapefile.boundingBox[3])
      ],
      [
        Math.max(shapefile.boundingBox[0], shapefile.boundingBox[2]),
        Math.max(shapefile.boundingBox[1], shapefile.boundingBox[3])
      ],
      [
        Math.max(shapefile.boundingBox[0], shapefile.boundingBox[2]),
        Math.min(shapefile.boundingBox[1], shapefile.boundingBox[3])
      ],
      [
        Math.min(shapefile.boundingBox[0], shapefile.boundingBox[2]),
        Math.min(shapefile.boundingBox[1], shapefile.boundingBox[3])
      ]
    ]
  });

  addLayer({
    id: layerName,
    source: layerName,
    layout: {
      visibility: "visible"
    },
    paint: {
      "raster-opacity": shapefile.opacity ?? 0.8
    },
    type: "raster"
  });
}
