import { memo, useEffect, useRef, useState } from "react";

import BusinessIcon from "@material-ui/icons/Business";

import { getOrganizationLogo, updateOrganizationLogo } from "api/users";

import "./CompanyLogo.scss";

function CompanyLogo({ user }) {
  const { organization } = user;
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!selectedFile) return;
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    async function updatePic() {
      const data = new FormData();
      data.append("profile", selectedFile);
      await updateOrganizationLogo({
        organizationId: organization.id,
        data
      });
    }
    updatePic();
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    getOrganizationLogo(organization.id)
      .then((data) => {
        if (data) {
          const dataUrl = URL.createObjectURL(data);
          setPreview(dataUrl);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="logo-container">
      <input type="file" onChange={onSelectFile} ref={inputRef} />
      <div className="input-container" onClick={handleClick}>
        {selectedFile || preview ? (
          <div className="img-circle-bg">
            <div className="img-logo" style={{ backgroundImage: `url(${preview})` }} />
          </div>
        ) : (
          <div className="placeholder-bg">
            <BusinessIcon style={{ fontSize: "102px" }} />
          </div>
        )}
        <span className="select-label">
          {selectedFile || preview ? "Change" : "Upload"} Logo
        </span>
      </div>
    </div>
  );
}

export default memo(CompanyLogo);
