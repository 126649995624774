// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";

import { mdiAlert, mdiAlertCircle, mdiFireAlert } from "@mdi/js";
import { Switch, Table } from "antd";
import { formatDistance } from "date-fns";
import styled from "styled-components";

import { DataCollectionFreshnessResult, DataFreshnessResult } from "api/appTools";

import { Heading } from "components/base";

import { useDataFreshnessAnalyzer } from "./hooks";

type TableColumn = {
  dataIndex: string;
  key: string;
  title: string;
  width: number | string;
  render?: (value, record, index: number) => React.ReactNode;
};

const DataFreshnessAnalyzer = () => {
  // State
  const [includePerformance, setIncludePerformance] = useState<boolean>(true);
  const [useMaxDate, setUseMaxDate] = useState<boolean>(true);
  const [data, setData] = useState<DataFreshnessResult>(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [analyzerError, setAnalyzerError] = useState<string>(null);
  const [analyzerErrorDetails, setAnalyzerErrorDetails] = useState<string>(null);
  const [showAnalyzerErrorDetails, setShowAnalyzerErrorDetails] =
    useState<boolean>(false);

  const heatMap = [
    "#ff0000",
    "#ff3300",
    "#ff6600",
    "#ff9900",
    "#ffcc00",
    "#ffff00",
    "#ccff00",
    "#99ff00",
    "#66ff00",
    "#33ff00",
    "#00ff00"
  ].reverse();

  // Hooks
  const { mutate, isLoading } = useDataFreshnessAnalyzer(
    (data) => {
      setData(data);
    },
    (err) => {
      if (err.response?.data) {
        setAnalyzerErrorDetails(err.response?.data);
      } else {
        setAnalyzerErrorDetails(err.message);
      }
      setAnalyzerError("Failed to analyze data freshness");
    }
  );

  // Handle the component initially loading
  useEffect(() => {
    mutate(includePerformance);
  }, []);

  useEffect(() => {
    const columns: TableColumn[] = [];

    const tableData: {
      collectionName: string;
      dataSources: Record<string, DataCollectionFreshnessResult>;
    }[] = [];

    // clear tableData
    tableData.splice(0, tableData.length);
    columns.splice(0, columns.length);

    columns.push({
      dataIndex: "collectionName",
      key: "collectionName",
      title: "Collection",
      width: "20%",
      render: (text) => {
        return <HeatMapRowCell>{text}</HeatMapRowCell>;
      }
    });

    const currentTime = new Date();
    const numberSources = data?.dataSources?.length || 1;
    data?.dataSources?.forEach((dataSource) => {
      columns.push({
        dataIndex: undefined,
        key: dataSource.dataSourceName,
        title: dataSource.dataSourceName,
        width: `${80 / numberSources}%`,
        render: (_, record) => {
          let value: number = -1;
          let distanceDate: string = "";
          const entry = record.dataSources[dataSource.dataSourceName];
          if (useMaxDate && entry?.maxLastUpdated) {
            const utcDate = new Date(entry?.maxLastUpdated);
            value = Math.abs(
              Math.round((currentTime.getTime() - utcDate.getTime()) / 36e5)
            );
            distanceDate = formatDistance(utcDate, currentTime, {
              addSuffix: true,
              includeSeconds: true
            });
          } else if (entry?.minLastUpdated) {
            const utcDate = new Date(entry?.minLastUpdated);
            value = Math.abs(
              Math.round((currentTime.getTime() - utcDate.getTime()) / 36e5)
            );
            distanceDate = formatDistance(utcDate, currentTime, {
              addSuffix: true,
              includeSeconds: true
            });
          }

          if (value >= 0) {
            let bucket;
            switch (true) {
              case value <= 24:
                bucket = 0;
                break;
              case value <= 48:
                bucket = 1;
                break;
              case value <= 72:
                bucket = 2;
                break;
              case value <= 96:
                bucket = 3;
                break;
              case value <= 120:
                bucket = 4;
                break;
              case value <= 144:
                bucket = 5;
                break;
              case value <= 168:
                bucket = 6;
                break;
              case value <= 240:
                bucket = 7;
                break;
              case value <= 336:
                bucket = 8;
                break;
              default:
                bucket = 9;
                return (
                  <HeatMapTile color={heatMap[bucket]}>
                    <Icon path={mdiFireAlert} size={1.5} />
                    {distanceDate}
                  </HeatMapTile>
                );
            }
            return <HeatMapTile color={heatMap[bucket]}>{`${distanceDate}`}</HeatMapTile>;
          }

          return <HeatMapTile color="#ccc">--</HeatMapTile>;
        }
      });

      dataSource.collections.forEach((collection) => {
        let collectionData = tableData.find(
          (item) => item.collectionName === collection.collectionName
        );
        if (!collectionData) {
          collectionData = { collectionName: collection.collectionName, dataSources: {} };
          tableData.push(collectionData);
        }
        collectionData.dataSources[dataSource.dataSourceName] = collection;
      });
    });

    setTableColumns(columns);
    setTableData(tableData);
  }, [data, useMaxDate]);

  const handleIncludePerformance = (checked: boolean) => {
    setAnalyzerError(null);
    setIncludePerformance(checked);
    mutate(checked);
  };

  const handleDateMapped = (checked: boolean) => {
    setUseMaxDate(checked);
  };

  return (
    <RootContainer>
      <HeaderContainer>
        <Heading element="h4">Data Freshness</Heading>
      </HeaderContainer>
      <div>
        <p>
          The data freshness analyzer allows you to see a heatmap of the hours since each
          data sources collections were last updated.
        </p>
        <SettingsContainer>
          <SettingWrapper>
            <SettingTitle>Include Performance & Pden Collections</SettingTitle>
            <SettingInputWrapper>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={handleIncludePerformance}
              />
              <span className="warning">
                <Icon path={mdiAlertCircle} size={1} />
                Enabling can add ~2 minutes to analysis time
              </span>
            </SettingInputWrapper>
          </SettingWrapper>
          <SettingWrapper>
            <SettingTitle>Last Updated Value Shown</SettingTitle>
            <SettingInputWrapper>
              <Switch
                checkedChildren="Newest"
                unCheckedChildren="Oldest"
                onChange={handleDateMapped}
              />
            </SettingInputWrapper>
          </SettingWrapper>
        </SettingsContainer>
        <ResultsContainer>
          {!analyzerError && (
            <>
              <ResultsTable>
                <Table
                  dataSource={tableData}
                  columns={tableColumns}
                  rowKey="key"
                  pagination={false}
                  loading={isLoading}
                />
              </ResultsTable>
            </>
          )}
          {analyzerError && (
            <ErrorContainer>
              <ErrorMessage>
                <Icon path={mdiAlert} size={1} /> {analyzerError}
                <ErrorDetailsToggle
                  onClick={() => setShowAnalyzerErrorDetails(!showAnalyzerErrorDetails)}>
                  {showAnalyzerErrorDetails ? "hide details" : "show details"}
                </ErrorDetailsToggle>
              </ErrorMessage>
              {showAnalyzerErrorDetails && (
                <ErrorMessageDetails>{analyzerErrorDetails}</ErrorMessageDetails>
              )}
            </ErrorContainer>
          )}
        </ResultsContainer>
      </div>
    </RootContainer>
  );
};

export default DataFreshnessAnalyzer;

const RootContainer = styled.div`
  max-width: 1000px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
`;

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 0;
`;

const SettingWrapper = styled.div`
  width: 100%;
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
`;

const SettingInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 10px;

  span.warning {
    display: flex;
    align-items: center;
    color: var(--orange);
    gap: 5px;
    font-size: 12px;
    font-weight: default;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .ant-table-tbody .ant-table-cell {
    padding: 0;
  }
`;

const HeatMapRowCell = styled.div`
  padding: 16px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const HeatMapTile = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px;
  background-color: ${({ color }) => color};

  svg {
    color: #fff;
  }

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const ResultsTable = styled.div`
  width: 100%;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 1px solid var(--color-danger);
  border-radius: 5px;
  padding: 3px 15px;
  font-weight: var(--fontWeightMedium);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-danger);
`;

const ErrorDetailsToggle = styled.span`
  color: #a2aaad;
  cursor: pointer;
`;

const ErrorMessageDetails = styled.div`
  width: 100%;
  color: var(--color-text);
  font-family: var(--fontMono);
  text-align: left;
`;
