import { useEffect, useState } from "react";

import { LegendItemModel } from "models";

import { useChartState } from "../context";
import { createLegendItem } from "../utils";

const useSlopeLegendItem = (): LegendItemModel[] => {
  const { settings } = useChartState();
  const { unitSlopeStyle, halfSlopeStyle, quarterSlopeStyle } = settings;
  const [items, setItems] = useState<LegendItemModel[]>([]);

  const isUnitSlopeEnabled = Boolean(settings.showUnitSlope);
  const isHalfSlopeEnabled = Boolean(settings.showHalfSlope);
  const isQuarterSlopeEnabled = Boolean(settings.showQuarterSlope);

  const canChartShowsStats = settings.chartType === "Material Balance Time";

  useEffect(() => {
    if (!canChartShowsStats) {
      setItems([]);
      return;
    }

    const unitSlopeColor = unitSlopeStyle?.color ? unitSlopeStyle.color : "#000000";
    const unitSlope = createLegendItem("Unit Slope", true, unitSlopeColor);

    const halfSlopeColor = halfSlopeStyle?.color ? halfSlopeStyle.color : "#000000";
    const halfSlope = createLegendItem("Half Slope", true, halfSlopeColor);

    const quarterSlopeColor = quarterSlopeStyle?.color
      ? quarterSlopeStyle.color
      : "#000000";
    const quarterSlope = createLegendItem("Quarter Slope", true, quarterSlopeColor);

    const slopes = [];
    if (isUnitSlopeEnabled) {
      slopes.push(unitSlope);
    }
    if (isHalfSlopeEnabled) {
      slopes.push(halfSlope);
    }
    if (isQuarterSlopeEnabled) {
      slopes.push(quarterSlope);
    }

    setItems(slopes);
  }, [
    isUnitSlopeEnabled,
    isHalfSlopeEnabled,
    isQuarterSlopeEnabled,
    canChartShowsStats,
    unitSlopeStyle,
    halfSlopeStyle,
    quarterSlopeStyle
  ]);

  return items;
};

export default useSlopeLegendItem;
