import { memo } from "react";

function CSOR() {
  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31">
      <g id="IconsChart---PRPCSOR">
        <path
          d="M5.18287 14.071C8.04529 14.071 10.3657 11.7506 10.3657 8.88813C10.3657 6.02571 5.18287 1.99961e-13 5.18287 1.99961e-13C5.18287 1.99961e-13 0 6.02571 0 8.88813C0 11.7506 2.32045 14.071 5.18287 14.071Z"
          transform="translate(20.023579 15.244834)"
          id="Oval-Copy-20"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 18.464L18.5964 0"
          transform="translate(11.085341 1.8905864)"
          id="Path-8-Copy-7"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <g
          id="Group-7-Copy-2"
          transform="matrix(0.9945219 0.104528464 -0.104528464 0.9945219 6.6262493 -1.1920929E-07)">
          <path
            d="M2.4563 0C3.22041 4.6472 -0.663982 5.06275 0.100131 9.70995"
            transform="translate(9.094947E-13 1.7975538)"
            id="Stroke-1-Copy-8"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M2.41627 0C4.29418 6.03114 -1.24241 6.4743 0.262692 13.3052"
            transform="translate(3.7986162 0)"
            id="Stroke-3-Copy-4"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M2.4563 0C3.22041 4.6472 -0.663982 5.06275 0.100131 9.70995"
            transform="translate(7.8093104 1.7975538)"
            id="Stroke-5-Copy-4"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
        <path
          d="M5.30686 2L5.30686 0L0 0L2.22569 3.30138L0 6.63918L5.30686 6.63918L5.30686 4.63918"
          transform="translate(3.1976929 21.112848)"
          id="Path-8"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default memo(CSOR);
