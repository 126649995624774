import { FunctionComponent, useEffect, useState } from "react";

import iframify from "@noticeable/remarkable-iframify";
import { Remarkable } from "remarkable";
import styled from "styled-components/macro";

import { useDebounce } from "hooks";

import { TagT } from "components/user-documentation/constants";

import DocumentationTag from "./DocumentationTag";
import VideoPlayer from "./VideoPlayer";

const md = new Remarkable({ html: true });
md.use(iframify);
md.use(VideoPlayer);

type PostViewT = {
  title: string;
  tags: string[];
  content: string;
};

const PostView: FunctionComponent<PostViewT> = ({ title, tags, content }) => {
  const [markup, setMarkup] = useState(null);
  const debouncedMarkup = useDebounce(markup, 500);

  // sync markup with content update
  useEffect(() => setMarkup(md.render(content)), [content]);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <TagsContainer>
        {tags.map((t) => (
          <DocumentationTag key={t} value={t as TagT} />
        ))}
      </TagsContainer>
      <div dangerouslySetInnerHTML={{ __html: debouncedMarkup }} />
    </Wrapper>
  );
};

export default PostView;

const Wrapper = styled.div`
  display: grid;
  align-content: flex-start;
  padding: 20px 24px;
`;

const Title = styled.h1`
  font-size: 64px;
  line-height: 1;
  margin-bottom: 16px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
`;
