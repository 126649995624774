import React, { memo } from "react";

function InletUtilization() {
  return (
    <svg
      id="svg2"
      viewBox="210 310 90 90"
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(0.78, 0.78) translate(0, 1.8)"
      height="35"
      width="35">
      <path
        fill="currentColor"
        d="M 221.655 369.384 L 221.655 376.178 L 236.024 376.178 L 236.024 369.384 L 221.655 369.384 M 221.655 355.798 L 221.655 362.591 L 245.503 362.839 L 245.503 355.798 L 221.655 355.798 M 214.472 335.418 L 232.431 349.004 L 232.431 335.418 L 250.392 349.004 L 250.392 335.418 L 268.352 349.004 L 271.944 315.037 L 282.72 315.037 L 286.312 349.004 L 286.312 382.971 L 214.472 382.971 L 214.472 335.418 Z"
      />
      <path
        fill="white"
        d="M 278.194 354.412 L 256.272 376.459 L 259.196 379.398 L 274.268 364.242 L 281.118 357.354 M 261.386 355.149 C 258.966 355.149 257.005 357.123 257.005 359.554 C 257.005 361.993 258.966 363.966 261.386 363.966 C 263.812 363.966 265.773 361.993 265.773 359.554 C 265.773 357.123 263.812 355.149 261.386 355.149 M 276.003 369.842 C 273.581 369.842 271.617 371.82 271.617 374.254 C 271.617 376.69 273.581 378.664 276.003 378.664 C 278.423 378.664 280.389 376.69 280.389 374.254 C 280.389 371.82 278.423 369.842 276.003 369.842 Z"
      />
    </svg>
  );
}

export default memo(InletUtilization);
