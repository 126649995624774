import { memo, useState } from "react";

import { Error } from "@material-ui/icons";

import BaseButton from "./BaseButton";
import "./BaseInput.scss";

function BaseInput({
  label = "",
  actionButton = null,
  tooltip = null,
  onActionClick = null,
  actionIcon = null,
  value,
  disabled = false,
  blur = null,
  focus = null,
  keypress = null,
  input = null,
  type = "",
  step = "",
  min = "",
  className = "",
  max = "",
  id = "",
  placeholder = "",
  required = false,
  onChange = null,
  innerRef = null,
  errors = null,
  ...otherProps
}) {
  const [hovered, setHover] = useState(false);
  const [focused, setFocus] = useState(false);

  function handleMouseenter() {
    setHover(true);
  }

  function handleMouseleave() {
    setHover(false);
  }

  function handleBlur(evt) {
    setFocus(false);
    blur && blur(evt.target.value);
  }

  function handleFocus(event) {
    setFocus(true);
    focus && focus(event);
  }

  function handleChange(evt) {
    onChange && onChange(evt.target.value);
  }

  function handleKeypress(event) {
    keypress && keypress(event);
  }

  function handleInput(event) {
    input && input(event);
  }
  return (
    <div
      className={`BaseInput flex-column  ${disabled ? "disabled" : ""} ${
        focused ? "focused" : ""
      } ${hovered ? "hovered" : ""} ${errors ? "has-errors" : ""} ${className}`}
      onMouseEnter={handleMouseenter}
      onMouseLeave={handleMouseleave}>
      {!!label && (
        <div className="flex-row justify-between">
          <label className="label" htmlFor={id}>
            {label}
            {!!tooltip && <span className="label-tooltip">{tooltip}</span>}
          </label>
          {!!actionButton && (
            <BaseButton
              appearance="subtle"
              onClick={() => {
                onActionClick && onActionClick();
              }}
              noTabIndex={true}>
              {actionIcon && actionIcon}
              {actionButton}
            </BaseButton>
          )}
        </div>
      )}

      <input
        className="input"
        ref={innerRef}
        tabIndex={disabled ? -1 : 0}
        value={value}
        type={type}
        step={step}
        placeholder={placeholder}
        id={id}
        min={min}
        max={max}
        required={required}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeypress}
        onChange={handleChange}
        {...otherProps}
      />
      {errors && (
        <div className="error-message">
          <div className="flex-row items-center">
            {" "}
            <Error style={{ marginRight: "5px" }} /> {errors}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(BaseInput);
