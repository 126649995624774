import bbox from "@turf/bbox";
import {
  Feature,
  GeometryCollection,
  LineString,
  Point,
  featureCollection,
  geometryCollection,
  point
} from "@turf/helpers";
import axios from "axios";
import {
  WELL_LABEL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT,
  XDA_INTERCEPT_LAYER,
  XDA_LABEL
} from "constants/mapLayers.constants";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { ISelectedWell } from "store/features";

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;

export interface FeatureIntersectionResult {
  selectedWells: { [name: string]: ISelectedWell };
  selectedFeatures: GeoJSON.Feature[];
  wellsIntersect: { [uwi: string]: string };
}
export interface Point2 {
  x: number;
  y: number;
}
export interface Line {
  start: Point2;
  end: Point2;
}

export function setXdaLine(
  line: Line,
  interceptPoints: Point2[],
  mapbox: mapboxgl.Map
): Feature<GeometryCollection> {
  if (!(line && line.start && line.end) || !mapbox) {
    return;
  }
  const ls: LineString = {
    type: "LineString",
    coordinates: [
      [line.start.x, line.start.y],
      [line.end.x, line.end.y]
    ]
  };

  const geoms: (Point | LineString)[] = interceptPoints.map((p) => {
    const point: Point = { type: "Point", coordinates: [p.x, p.y] };
    return point;
  });
  geoms.push(ls);
  const gcoll = geometryCollection(geoms);
  const source = mapbox.getSource(XDA_INTERCEPT_LAYER) as GeoJSONSource;
  if (!source) {
    return;
  }

  source.setData(gcoll);
  const labelSource = mapbox.getSource(XDA_LABEL) as GeoJSONSource;

  let a = [line.start.x, line.start.y];
  let aprime = [line.end.x, line.end.y];
  if (line.start.x > line.end.x) {
    const temp = a;
    a = aprime;
    aprime = temp;
  }

  const fc = featureCollection([
    point(a, {
      label: "A"
    }),
    point(aprime, {
      label: "A'"
    })
  ]);
  labelSource.setData(fc);
  return gcoll;
}

export async function getFeaturesIntersectingPolygon(
  mapbox: mapboxgl.Map,
  polygon: GeoJSON.Geometry | GeoJSON.Feature
): Promise<FeatureIntersectionResult> {
  if (!mapbox) {
    return {
      selectedWells: null,
      selectedFeatures: null,
      wellsIntersect: null
    };
  }
  if (!mapbox.getLayer(WELL_LAYER)) {
    return {
      selectedWells: null,
      selectedFeatures: null,
      wellsIntersect: null
    };
  }
  const bounding = bbox(polygon);
  const p1 = mapbox.project({
    lng: bounding[0],
    lat: bounding[1]
  });
  const p2 = mapbox.project({
    lng: bounding[2],
    lat: bounding[3]
  });
  //polygon can be a feature or geometry
  let geom = polygon;
  if (polygon.type === "Feature" && polygon.geometry) {
    geom = polygon.geometry;
  }
  if (!mapbox.getLayer(WELL_LAYER)) {
    return {
      selectedWells: null,
      selectedFeatures: null,
      wellsIntersect: null
    };
  }
  const features = mapbox.queryRenderedFeatures([p1, p2], {
    layers: [WELL_LAYER, WELL_LAYER_POINT]
  });
  let wellsIntersect: { [uwi: string]: string } = null;
  try {
    const response = await axios.post<{ [uwi: string]: string }>(
      `${mapServiceEndpoint}/map/wells-intersect`,
      geom
    );

    if (response.status !== 200) {
      return {
        selectedWells: null,
        selectedFeatures: null,
        wellsIntersect: null
      };
    }
    wellsIntersect = await response.data;
  } catch (err) {
    return {
      selectedWells: null,
      selectedFeatures: null,
      wellsIntersect: null
    };
  }

  const selectedFeatures = [];
  for (const feat of features) {
    if (wellsIntersect[feat.properties.Uwi]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedFeatures.push((feat as any).toJSON());
    }
  }
  const selectedWells = {};
  for (const feat of selectedFeatures) {
    selectedWells[feat.properties.Uwi] = feat.properties;
  }
  const uwis = Object.keys(selectedWells);
  const labelFeatures = mapbox.querySourceFeatures(WELL_LABEL_LAYER, {
    sourceLayer: "eva-wells-label",
    filter: ["in", ["get", "Uwi"], ["literal", uwis]]
  });
  const labelDict = {};
  for (const label of labelFeatures) {
    if (!labelDict[label.properties.Uwi]) {
      labelDict[label.properties.Uwi] = label.properties.value;
    }
  }
  for (const uwi of Object.keys(selectedWells)) {
    if (labelDict[uwi]) {
      selectedWells[uwi]["value"] = labelDict[uwi];
    }
  }
  return {
    selectedWells,
    selectedFeatures,
    wellsIntersect
  };
}
