import React, { ReactNode, createContext, useContext, useMemo, useReducer } from "react";

import { produce } from "immer";

import { EntityKind } from "../../../models/entityKind";
import { WellListReducerActionsT, wellListReducer } from "./reducer/wellListReducer";

export type WellInfoT = {
  id: string;
  label: string;
};

export type WellGroupInfoT = {
  group?: string;
  color?: string;
};

export type UwidFormatT = "entityName" | "uwid";
export type CopyWellListFormatT = "newLine" | "csv" | "mongo";

export type SelectedWellT = {
  Uwi?: string;
  FormattedUwi?: string;
};

export interface IWellGroupMap {
  [uwid: string]: WellGroupInfoT;
}

export type WellListSettings = {
  scrollToSelected: boolean;
  showFocusColor: boolean;
  uwidFormat: UwidFormatT;
  copyFormat: CopyWellListFormatT;
};

export interface IWellListState {
  previouslySelectedWells: SelectedWellT[];
  selectedWell?: SelectedWellT;
  settings: WellListSettings;
  wells: WellInfoT[];
  wellGroupMap?: IWellGroupMap;
  entityKind: EntityKind;
}

export const initialWellListState: IWellListState = {
  previouslySelectedWells: [],
  settings: {
    scrollToSelected: true,
    showFocusColor: true,
    uwidFormat: "entityName",
    copyFormat: "newLine"
  },
  entityKind: EntityKind.Well,
  wells: []
};

export type WellListDispatchT = (action: WellListReducerActionsT) => void;
export type WellListProviderT = {
  children: ReactNode;
  initialState?: IWellListState;
};

const curriedReducer = produce(wellListReducer);

const WellListContext =
  createContext<[IWellListState, React.Dispatch<WellListReducerActionsT>]>(undefined);
WellListContext.displayName = "WellListContext";

const WellListProvider = ({ children, initialState }: WellListProviderT) => {
  const newState = initialState ?? initialWellListState;

  const [state, dispatch] = useReducer(curriedReducer, newState);
  const value: [IWellListState, WellListDispatchT] = useMemo(
    () => [state, dispatch],
    [state]
  );

  return <WellListContext.Provider value={value}>{children}</WellListContext.Provider>;
};

const useWellList = () => {
  const context = useContext(WellListContext);
  if (context === undefined) {
    throw new Error("useWellList must be used within WellListProvider");
  }
  return context;
};

export { curriedReducer, WellListProvider, useWellList };
