import { Icon } from "@mdi/react";
import { memo, useEffect, useMemo, useState } from "react";

import { mdiFormatPaint } from "@mdi/js";
import { Popconfirm } from "antd";
import { cookies } from "constants/settings.constants";
import Cookies from "js-cookie";
import styled from "styled-components";

import { BaseMenu } from "./";
import ColorChooser from "./ColorChooser";
import "./ColorPicker.scss";

const default_color = "#025f9c";
const transparent_color_selected_bg = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='rgb(214,40,11)' /></svg>")`;

const ColorOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  & > div:first-child {
    padding: 16px 15px 0 18px;
  }
  & > div:last-child {
    padding: 10px 15px 17px 18px;
  }
  .ColorPicker {
    width: 18px;
    height: 18px;
    top: 3px;
  }
  .checkbox-input {
    .checkbox {
      width: 18px;
      height: 18px;
      margin: 0;
      margin-right: 8px;
    }
  }
  .thickness-dropdown {
    height: 28px;
  }
  span {
    margin-left: 12px;
    margin-right: 20px;
    font-size: 1.4rem;
    font-weight: var(--fontWeightMedium);
  }
  .BaseDropdown {
    min-width: 68px;
  }
`;

const FlexRow = styled.div`
  display: inline-flex;
  align-items: center;
`;

function ColorPicker({
  values = {
    color: default_color,
    strokeColor: default_color,
    thickness: 2,
    opacity: 100
  },
  place = "bottom",
  align = "start",
  onChange = null,
  onChangeOpacity = null,
  onChangeThickness = null,
  isAtFolderLevel = false,
  isAtAttributeLevel = false,
  isReadOnly = false,
  hasOutline = true,
  includeOpacity = true
}) {
  const [isOpen, setOpen] = useState(false);
  const [isOpenFolder, setIsOpenFolder] = useState(false);
  const [currentValues, setValues] = useState(values);
  const [customColors, setCustomColors] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function toggle() {}

  function update(prop, value) {
    const newValues = { ...currentValues };
    newValues[prop] = value;
    setValues(newValues);

    if (onChangeOpacity && prop === "opacity") {
      onChangeOpacity(prop, value);
    } else if (onChangeThickness && prop === "thickness") {
      onChangeThickness(prop, value);
    } else {
      onChange && onChange(prop, value);
    }
  }

  useEffect(() => {
    const storedCustomColors = Cookies.get(cookies.CUSTOM_COLORS);
    if (storedCustomColors && !customColors.length)
      setCustomColors([...JSON.parse(storedCustomColors)]);
  }, [customColors]);

  // TODO: Fix infinite loop caused by this useEffect when no values are passed into the ColorPicker
  useEffect(() => {
    setValues(values);
  }, [values]);

  const styleMultiObject = (isDisabled) => {
    const styles = {
      backgroundColor: values.color,
      flex: "100%",
      borderRadius: "2px",
      border: "",
      boxShadow: "",
      backgroundImage: "",
      cursor: isDisabled ? "not-allowed" : "pointer"
    };

    if (values.strokeColor === "#FFFFFF") {
      styles.border = "1px solid #D9E1E2";
      styles.boxShadow = isDisabled ? "" : `inset 0 0 0 ${values.thickness}px #FFFFFF`;
    } else {
      styles.border = `3px solid ${values.strokeColor}`;
    }

    if (values.strokeColor === "transparent" && values.color === "transparent") {
      styles.backgroundImage = transparent_color_selected_bg;
      styles.border = "1px solid #D9E1E2";
    }
    return styles;
  };

  const styleObject = (prop, isDisabled) => {
    const styles = {
      flex: "100%",
      borderRadius: "2px",
      border: `${values.thickness}px solid ${
        prop === "strokeColor" ? values.strokeColor : "transparent"
      }`,
      backgroundColor: prop === "color" ? values.color : "white",
      boxShadow: "",
      backgroundImage: "",
      cursor: isDisabled ? "not-allowed" : "pointer"
    };

    if (prop === "color" && values.color === "#FFFFFF") {
      styles.backgroundColor = "white";
      styles.border = "1px solid #D9E1E2";
    } else if (prop === "strokeColor" && values.strokeColor === "#FFFFFF") {
      styles.backgroundColor = "#D9E1E2";
      styles.border = "1px solid #D9E1E2";
      styles.boxShadow = isDisabled ? "" : `inset 0 0 0 ${values.thickness}px #FFFFFF`;
    }

    if (values[prop] === "transparent") {
      styles.backgroundImage = transparent_color_selected_bg;
      styles.border = "1px solid #D9E1E2";
    }
    return styles;
  };

  const classNames = useMemo(() => {
    function variation() {
      return `${place}-${align}`;
    }
    return `ColorPicker ${isOpen ? "isOpen" : ""} ${variation()} ${
      isAtFolderLevel ? "atFolderLevel" : ""
    } ${!isReadOnly ? "hasAnimations" : ""}`;
  }, [align, isOpen, place]);

  const trigger = (prop) => {
    // show confirmation message only when opening the color picker options on the folder level
    if (prop === null && isAtFolderLevel) {
      return (
        <Popconfirm
          overlayClassName="set-color-popconfirm"
          placement="bottom"
          onConfirm={(evt) => {
            setIsOpenFolder(true);
            evt.stopPropagation();
            evt.preventDefault();
          }}
          onCancel={(evt) => {
            setIsOpenFolder(false);
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Select Colour"
          title={`Are you sure you want to set the colour for all shapefiles in the folder?`}>
          <div
            tabIndex={0}
            role="button"
            className={classNames}
            onClick={() => setOpen(!isOpen)}>
            {prop === null ? (
              isAtFolderLevel ? (
                <ProjectFolderActionButton>
                  <Icon path={mdiFormatPaint} size={1} />
                  Set Shapefile Colours
                </ProjectFolderActionButton>
              ) : (
                <div
                  className="flex flex-centered"
                  style={styleMultiObject(isReadOnly)}></div>
              )
            ) : (
              <div
                className="flex flex-centered"
                style={styleObject(prop, isReadOnly)}></div>
            )}
          </div>
        </Popconfirm>
      );
    } else {
      return (
        <div
          tabIndex={0}
          role="button"
          className={classNames}
          onClick={() => setOpen(!isOpen)}>
          {prop === null ? (
            <div
              className="flex flex-centered"
              style={styleMultiObject(isReadOnly)}></div>
          ) : (
            <div
              className="flex flex-centered"
              style={styleObject(prop, isReadOnly)}></div>
          )}
        </div>
      );
    }
  };

  return !isAtAttributeLevel ? (
    <BaseMenu
      place={place}
      align={align}
      onClose={() => {
        setOpen(false);
        setIsOpenFolder(false);
      }}
      toggle={toggle}
      trigger={trigger(null)}>
      {() =>
        (isOpenFolder || !isAtFolderLevel) &&
        !isReadOnly && (
          <ColorOptionsContainer>
            <FlexRow>
              <BaseMenu
                place={place}
                align={align}
                toggle={toggle}
                trigger={trigger("color")}>
                {() => (
                  <ColorChooserWrapper>
                    <ColorChooser
                      selectedColor={values.color}
                      onChange={(color) => {
                        update("color", color);
                      }}
                      customColors={customColors}
                      setCustomColors={setCustomColors}
                      includeOpacity={includeOpacity}
                      selectedOpacity={values.opacity}
                      onChangeOpacity={(opacity) => {
                        update("opacity", opacity);
                      }}
                    />
                  </ColorChooserWrapper>
                )}
              </BaseMenu>
              <span>Fill</span>
            </FlexRow>
            {hasOutline && (
              <FlexRow>
                <BaseMenu
                  place={place}
                  align={align}
                  toggle={toggle}
                  trigger={trigger("strokeColor")}>
                  {() => (
                    <ColorChooserWrapper>
                      <ColorChooser
                        selectedColor={values.strokeColor}
                        onChange={(color) => {
                          update("strokeColor", color);
                        }}
                        customColors={customColors}
                        setCustomColors={setCustomColors}
                        includeThickness={true}
                        selectedThickenss={values.thickness}
                        onChangeThickness={(thickness) => {
                          update("thickness", thickness);
                        }}
                      />
                    </ColorChooserWrapper>
                  )}
                </BaseMenu>
                <span>Outline</span>
              </FlexRow>
            )}
          </ColorOptionsContainer>
        )
      }
    </BaseMenu>
  ) : (
    <ColorOptionsContainer>
      <FlexRow>
        <BaseMenu place={place} align={align} toggle={toggle} trigger={trigger("color")}>
          {() => (
            <ColorChooserWrapper>
              <ColorChooser
                selectedColor={values.color}
                onChange={(color) => {
                  update("color", color);
                }}
                customColors={customColors}
                setCustomColors={setCustomColors}
                includeOpacity={includeOpacity}
                selectedOpacity={values.opacity}
                onChangeOpacity={(opacity) => {
                  update("opacity", opacity);
                }}
              />
            </ColorChooserWrapper>
          )}
        </BaseMenu>
        <span>Fill</span>
      </FlexRow>
      {hasOutline && (
        <FlexRow>
          <BaseMenu
            place={place}
            align={align}
            toggle={toggle}
            trigger={trigger("strokeColor")}>
            {() => (
              <ColorChooserWrapper>
                <ColorChooser
                  selectedColor={values.strokeColor}
                  onChange={(color) => {
                    update("strokeColor", color);
                  }}
                  customColors={customColors}
                  setCustomColors={setCustomColors}
                  includeThickness={true}
                  selectedThickenss={values.thickness}
                  onChangeThickness={(thickness) => {
                    update("thickness", thickness);
                  }}
                />
              </ColorChooserWrapper>
            )}
          </BaseMenu>
          <span>Outline</span>
        </FlexRow>
      )}
    </ColorOptionsContainer>
  );
}

const ColorChooserWrapper = styled.div`
  padding: 10px 16px;
`;

const ProjectFolderActionButton = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);
  cursor: pointer;
  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;

export default memo(ColorPicker);
