import { IGroupByListColumn } from "models";

import {
  IGNORE_METADATA_FILTERS_FOR_GROUPS,
  MetadataFiltersT
} from "../group-by.context";

const filterByMetadata = (
  filters: MetadataFiltersT,
  columns: IGroupByListColumn[]
): IGroupByListColumn[] => {
  if (!filters) return columns;

  return columns.filter((column) => {
    const metadata = column.metadata;
    if (!metadata || IGNORE_METADATA_FILTERS_FOR_GROUPS.includes(column.group))
      return true;

    return Object.keys(filters).every((key) => {
      // if the metadata does not have the key, we return true
      if (!metadata[key] || !filters[key]) return true;

      return metadata[key] === filters[key];
    });
  });
};

export default filterByMetadata;
