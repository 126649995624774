import { memo } from "react";

function AcidGasInjection() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30px"
      height="30px"
      transform="translate(0, 0)">
      <path
        d="M5.61892 0L5.61892 5.61892L0 5.61892"
        transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 12.008963 20.249456)"
        id="Rectangle-Copy"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2.25"
      />
      <path
        transform="scale(0.058, 0.058)"
        fill="currentColor"
        stroke="currentColor"
        d="M90.214,105.033c5.514,0,10.699-2.147,14.598-6.047c1.916-1.915,3.416-4.155,4.443-6.61
		c7.85-0.185,15.2-3.326,20.765-8.893c4.397-4.396,7.29-9.935,8.396-15.95c8.382-0.414,16.205-3.876,22.182-9.854
		c13.173-13.173,13.173-34.608,0-47.783C154.217,3.515,145.732,0,136.707,0s-17.51,3.515-23.892,9.896
		c-5.98,5.98-9.442,13.867-9.855,22.183c-6.015,1.105-11.552,3.999-15.948,8.396c-7.131,7.131-10.191,17.121-8.434,26.855
		c-1.055,0.722-2.044,1.543-2.962,2.46c-3.899,3.899-6.047,9.084-6.047,14.599s2.147,10.699,6.047,14.599
		C79.515,102.886,84.699,105.033,90.214,105.033z"
      />

      <path
        transform="scale(0.05, 0.05) translate(25, 17)"
        fill="currentColor"
        stroke="currentColor"
        d="M365.948,156.804c-1.278,0-3.212,0.409-5.161,2.358l-71.623,71.622v-66.915c0-4.639-3.12-7.065-6.202-7.065
		c-1.278,0-3.212,0.409-5.161,2.358l-70.703,70.702v-65.995c0-4.639-3.12-7.065-6.202-7.065c-1.279,0-3.212,0.409-5.162,2.358
		l-62.785,62.784V130.39c0-5.514-4.486-10-10-10H57.74c-5.514,0-10,4.486-10,10v279.501c0,5.514,4.486,10,10,10h304.412
		c5.514,0,10-4.486,10-10V163.869C372.151,159.23,369.031,156.804,365.948,156.804z M147.244,353.862H86.008v-54.318h61.237V353.862
		z M240.563,353.862h-61.237v-54.318h61.237V353.862z M333.883,353.862h-61.237v-54.318h61.237V353.862z"
      />
    </svg>
  );
}

export default memo(AcidGasInjection);
