import { useMutation, useQueryClient } from "react-query";

import axios, { AxiosError } from "axios";

import { CutoffSource } from "api/ipdp";

const dataServiceEndPoint = process.env.REACT_APP_DATA_ROOT;

export function usePostCutoffGeoMap() {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, CutoffSource>(
    async (data: CutoffSource) => {
      const response = await axios.post(
        `${dataServiceEndPoint}/api/v1/data/ipdb/playto3d/sources/cutoff`,
        data
      );
      if (response.status !== 200) {
        throw new Error("Error creating access token");
      }

      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("ipdb/playto3d/sources/cutoff");
      }
    }
  );
}
