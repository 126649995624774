import { memo } from "react";

function WaterCut() {
  return (
    <svg width="31px" height="33px" viewBox="0 0 31 33" version="1.1">
      <defs>
        <path d="M0 0L13.6955 0L13.6955 8.85703L0 8.85703L0 0Z" id="path_waterCut" />
        <path d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z" id="path_waterCut_2" />
        <clipPath id="mask_133">
          <use href="#path_waterCut" />
        </clipPath>
        <clipPath id="mask_214">
          <use href="#path_waterCut_2" />
        </clipPath>
      </defs>
      <g id="IconsChart---PRPOil-+-Cond-Copy" transform="translate(15.44043 11.420532)">
        <path
          d="M7.26179 6.22664C7.26179 8.23193 5.63618 9.85754 3.63089 9.85754C1.62561 9.85754 0 8.23193 0 6.22664C0 4.22135 3.63089 0 3.63089 0C3.63089 0 7.26179 4.22135 7.26179 6.22664ZM11.5872 4.81009C11.5872 5.45907 11.0611 5.98517 10.4122 5.98517C9.7632 5.98517 9.2371 5.45907 9.2371 4.81009C9.2371 4.16112 10.4122 2.79496 10.4122 2.79496C10.4122 2.79496 11.5872 4.16112 11.5872 4.81009ZM10.0234 8.21921C10.0234 8.86818 9.49726 9.39428 8.84828 9.39428C8.19931 9.39428 7.67321 8.86818 7.67321 8.21921C7.67321 7.57023 8.84828 6.20407 8.84828 6.20407C8.84828 6.20407 10.0234 7.57023 10.0234 8.21921ZM13.2273 8.21921C13.2273 8.86818 12.7012 9.39428 12.0522 9.39428C11.4032 9.39428 10.8771 8.86818 10.8771 8.21921C10.8771 7.57023 12.0522 6.20407 12.0522 6.20407C12.0522 6.20407 13.2273 7.57023 13.2273 8.21921Z"
          transform="matrix(1 0 0 1 1.8189894E-12 0)"
          id="Combined-Shape"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
      <g id="Group-2-Copy-8" transform="translate(15.44043 22.738525)">
        <path
          d="M0 0L13.6955 0L13.6955 8.85703L0 8.85703L0 0Z"
          id="Mask-Copy-2"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g clipPath="url(#mask_133)">
          <g id="Group-2" transform="translate(0 1.1655965)">
            <path
              d="M18.4578 1.37283C17.5269 1.37283 16.6597 1.09699 15.935 0.622561C14.6656 -0.20752 13.0214 -0.20752 11.752 0.622561C11.0267 1.09699 10.1602 1.37283 9.22923 1.37283C8.29763 1.37283 7.43115 1.09699 6.70578 0.622561C5.4364 -0.20752 3.7922 -0.20752 2.52281 0.622561C1.79809 1.09699 0.930969 1.37283 0 1.37283"
              id="Stroke-1-Copy-5"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="1.8375"
            />
            <path
              d="M18.4578 1.37283C17.5269 1.37283 16.6597 1.09699 15.935 0.622561C14.6656 -0.20752 13.0214 -0.20752 11.752 0.622561C11.0267 1.09699 10.1602 1.37283 9.22923 1.37283C8.29763 1.37283 7.43115 1.09699 6.70578 0.622561C5.4364 -0.20752 3.7922 -0.20752 2.52281 0.622561C1.79809 1.09699 0.930969 1.37283 0 1.37283"
              transform="translate(0 3.828321)"
              id="Stroke-4-Copy-4"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="1.8375"
            />
          </g>
        </g>
      </g>
      <g id="IconsChart---PRPWater-Cut">
        <path
          d="M0 26.5666L27 0"
          transform="matrix(1 0 0 1 0 -1.1368684E-13)"
          id="Path-8-Copy-11"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
        <g id="Group-2-Copy-5" transform="translate(0.32551372 0)">
          <path
            d="M0 0L14.5 0L14.5 9.37728L0 9.37728L0 0Z"
            id="Mask-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_214)">
            <g id="Group-2" transform="translate(0 1.2340614)">
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                id="Stroke-1-Copy-5"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
              <path
                d="M19.542 1.45346C18.5564 1.45346 17.6383 1.16142 16.871 0.65913C15.5271 -0.21971 13.7863 -0.21971 12.4423 0.65913C11.6744 1.16142 10.757 1.45346 9.77134 1.45346C8.78501 1.45346 7.86764 1.16142 7.09967 0.65913C5.75572 -0.21971 4.01494 -0.21971 2.671 0.65913C1.9037 1.16142 0.985652 1.45346 0 1.45346"
                transform="translate(0 4.0531893)"
                id="Stroke-4-Copy-4"
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeWidth="2.25"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(WaterCut);
