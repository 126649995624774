function getDaysInMonth(unixTimestamp: number): number {
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();

  // Create a date for the first day of the next month
  const nextMonth = new Date(Date.UTC(year, month + 1, 1));

  // Subtract one day to get the last day of the current month
  const lastDayOfMonth = new Date(nextMonth.getTime() - 1);

  return lastDayOfMonth.getUTCDate();
}

export default getDaysInMonth;
