import styled from "styled-components";

import { EntityKind } from "../../models/entityKind";
import ForecastSourceSelect from "./ForecastSourceSelect";
import ForecastToggle from "./ForecastToggle";
import PdenSourceSelect from "./PdenSourceSelect";
import ProductSelect from "./ProductSelect";
import { useGroupByState } from "./group-by.context";
import { useSelectedCategory } from "./hooks";

function ProductToolbar() {
  const fields = useSelectedCategory();
  const { isForecastToggleOn, entityKind } = useGroupByState();

  // do not render if selected category has no product fields
  if (!fields.hasProductFields) return null;
  if (fields.hasProductFields && fields.hasPerformaceFields)
    return (
      <WrapperRow>
        <Wrapper>
          <ProductSelect />
          {entityKind === EntityKind.Well && <ForecastToggle />}
        </Wrapper>
        <WrapperRow>
          {entityKind === EntityKind.Well && <PdenSourceSelect />}
          {isForecastToggleOn && <ForecastSourceSelect showLabel={true} />}
        </WrapperRow>
      </WrapperRow>
    );

  if (fields.hasProductFields && fields.hasForecastFields)
    return (
      <WrapperRow>
        <Wrapper>
          <ProductSelect />
        </Wrapper>
        <WrapperRow>
          <ForecastSourceSelect showLabel={true} />
        </WrapperRow>
      </WrapperRow>
    );
}

export default ProductToolbar;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-content: center;
  gap: var(--space-2);
  padding: 0 var(--space-2);
`;

const WrapperRow = styled.div`
  display: flex;
  padding: 0 var(--space-2);
  gap: 10px;
  flex-direction: column;
`;
