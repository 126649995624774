/**
 * Converts x/y percentage values into pixel values
 *
 * @param {any} pointAsPercentage - point as a percentage.
 * @param {any} instance - chart instance.
 * @param {any} options - chart options.
 * @returns {number} point in pixels.
 */

export function convertPercentageToPixels(
  pointAsPercentage: number[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  grid: any
): number[] {
  const width = instance.getWidth();
  const height = instance.getHeight();
  const [x, y] = pointAsPercentage;
  const newX = x * (width - grid?.left - grid?.right) + grid?.left;
  const newY = y * (height - grid?.top - grid?.bottom) + grid?.top;
  return [newX, newY];
}
