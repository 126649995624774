import { AdditionalTypeWellData } from "models/UserArpsModel";

const DefaultFields = [
  "Well_Design.HzLength_m",
  "Well_Design.HzLengthSimple_m",
  "Well_Design.HzLengthDrilled_m",
  "Well_Design.HzLengthCompleted_m",
  "Frac_Data.StagesActual",
  "Frac_Data.ProppantTotal_t",
  "Frac_Data.StageSpacing_m",
  "Frac_Data.ProppantIntensity_tm"
];

export function checkAdditionalNormalizationParameters(data: AdditionalTypeWellData[]) {
  if (!data) return { errors: [], isValid: true };
  const errors = Array(data.length).fill(null);
  const fields = data.map((item) => item.field);
  let isValid = true;
  data.forEach((item, index) => {
    if (!item.field) {
      errors[index] = "Field is required";
      isValid = false;
    } else if (item.value <= 0) {
      errors[index] = "Value must be greater than 0";
      isValid = false;
    } else if (DefaultFields.includes(item.field)) {
      errors[index] = "Duplicate field";
      isValid = false;
    } else if (fields.filter((_, i) => i !== index).includes(item.field)) {
      errors[index] = "Duplicate field";
      isValid = false;
    }
  });
  return { errors, isValid };
}
