/**
 * Calculate the actual position for the jitter plot on the chart
 *
 * @param api The selected series.
 * @param {int} plotIndex The index of the plot in current category.
 * @returns Transformed series styles.
 */
import seedrandom from "seedrandom";

const NUM_BOXPLOTS_IN_COL = 1;

export function getJitterPlotPosition(api, plotIndex, size) {
  const coord = api.coord([api.value(0), api.value(1)]);
  const offset = api.size([1, 0]);

  const availableWidth = offset[0] * 0.8 - 2;
  const boxGap = (availableWidth / NUM_BOXPLOTS_IN_COL) * 0.3;
  const boxWidth =
    (availableWidth - boxGap * (NUM_BOXPLOTS_IN_COL - 1)) / NUM_BOXPLOTS_IN_COL;
  let boxBase = boxWidth / 2 - availableWidth / 2;

  const rng = new seedrandom(plotIndex.toString());
  boxBase += (rng() * 2 - 1) * (availableWidth - boxGap * 4);

  coord[0] += boxBase;

  return {
    type: "circle",
    shape: {
      cx: coord[0],
      cy: coord[1],
      r: size
    },
    style: api.style()
  };
}
