import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef } from "react";

import { Typography } from "antd";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import styled from "styled-components";

import { IChartScreenshotSettings } from "models/chart";
import { IScreenshotAbbreviation } from "models/screenshot";

import { useChartState } from "components/chart";
import { applyAbbreviations } from "components/screenshot/utils";

import { useChartStatsQuery } from "./hooks";

export interface StatsModel {
  wellList: string[];
  setting: IChartScreenshotSettings;
  abbreviations: IScreenshotAbbreviation[];
}
export default function Stats({ wellList, setting, abbreviations }: StatsModel) {
  const chartRef = useRef(null);

  const containerRef = useRef(null);
  const { data, updateData } = useChartStatsQuery(wellList);
  const { screenshot } = useChartState();

  const applyScreenshotAbbreviations = setting?.applyAbbreviations && screenshot?.visible;

  useEffect(() => {
    updateData();
  }, [updateData, wellList]);

  useResizeObserver(containerRef.current, () => {
    if (!chartRef.current) {
      return;
    }
    chartRef.current.resize();
  });
  if (!data) {
    return null;
  }
  return (
    <Wrapper
      ref={containerRef}
      fontFamily={screenshot?.visible ? setting?.fontFamily : 12}
      fontSize={screenshot?.visible ? setting?.titleFontSize : THEME_FONT_FAMILY}>
      <Typography.Text className="title">
        {data.lastQuarterTitle}{" "}
        {applyScreenshotAbbreviations
          ? applyAbbreviations("Production", abbreviations)
          : "Production"}
      </Typography.Text>
      <Typography.Text className="value" strong>
        {Math.round(data.lastQuarterBitumen)}{" "}
        {applyScreenshotAbbreviations
          ? applyAbbreviations("bbl/d", abbreviations)
          : "bbl/d"}
      </Typography.Text>

      <Typography.Text className="title">{data.lastQuarterTitle} SOR</Typography.Text>
      <Typography.Text className="value" strong>
        {data.lastQuarterISor}{" "}
        {applyScreenshotAbbreviations
          ? applyAbbreviations("bbl/bbl", abbreviations)
          : "bbl/bbl"}
      </Typography.Text>

      <Typography.Text className="title"> Current SOR</Typography.Text>
      <Typography.Text className="value" strong>
        {data.currentCSor}{" "}
        {applyScreenshotAbbreviations
          ? applyAbbreviations("bbl/bbl", abbreviations)
          : "bbl/bbl"}
      </Typography.Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: ${(s) => s.fontFamily};
  font-size: ${(s) => s.fontSize}px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  .title {
    padding-left: 15px;
  }
  .value {
    padding-left: 5px;
  }
`;
