import { LegendT, ParentDimensionsT } from "../Legend";

/*
    Used primarily to react on the parents dimensions changing

    Legend position should not be able to exceed it's parents bounds.
    Calculates relative position.
    Relative sizing applied as well.
    Couldn't find any automatic positioning/resizing based on parent changing out of the RND library.
  */
const getCalculatedLegend = (
  legend: LegendT,
  parentDimensions: ParentDimensionsT
): LegendT => {
  const adjustLegendToParent = (): LegendT => {
    if (!legend) return { x: 0, y: 0, width: 100, height: 100 };
    if (!parentDimensions)
      return { x: 0, y: 0, width: legend.width, height: legend.height };

    const { height: parentHeight, width: parentWidth } = parentDimensions;
    const { width: legendWidth, height: legendHeight, x: legendX, y: legendY } = legend;

    const newWidth = Math.round(
      legendWidth > parentWidth
        ? parentWidth * (parentWidth / legendWidth) // relative width ratio
        : legendWidth
    );

    const newHeight = Math.round(
      legendHeight > parentHeight
        ? parentHeight * (parentHeight / legendHeight) // relative height ratio
        : legendHeight
    );

    const newX = Math.round(
      legendX > parentWidth
        ? parentWidth * (parentWidth / legendX) // relative x ratio
        : legendX
    );

    const newY = Math.round(
      legendY > parentHeight
        ? parentHeight * (parentHeight / legendY) // relative y ratio
        : legendY
    );

    const maxCheckedX = Math.round(
      newX + newWidth > parentWidth ? parentWidth - newWidth : newX
    );

    const maxCheckedY = Math.round(
      newY + newHeight > parentHeight ? parentHeight - newHeight : newY
    );

    const minCheckedX = Math.round(maxCheckedX < 0 ? 0 : maxCheckedX);

    const minCheckedY = Math.round(maxCheckedY < 0 ? 0 : maxCheckedY);

    const newLegend: LegendT = {
      x: minCheckedX,
      y: minCheckedY,
      width: newWidth,
      height: newHeight
    };

    return newLegend;
  };

  // Autosizing isn't perfect yet-
  // For auto sizing edge case, There are times in which the legend is adjusted, but will overflow
  // depending on its Y position. For a clean look I'd suggest calculating the overflow, and deducting it
  // from the size, either ratio, or probably offsetting the x,y
  // E.g:
  // Parent height is 800
  // Legend Y position is at 100
  // Legend height (800) + y position (100) = overhang of 100 even though 800 would fit in the parent
  // legend height + y > parent height shouldn't be possible
  // We would need to do this for each side of potential overhang
  const adjustedLegend = adjustLegendToParent();
  return adjustedLegend;
};

export default getCalculatedLegend;

// References
// Automatic adjusting and resizing, https://jsitor.com/jl-kUdZCw
