import { memo, useState } from "react";

import FullScreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import { ToolbarButton } from "../components";

const FullscreenToggleButton = ({ onToggle }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleToggle = () => (value) => {
    setFullscreen(value);
    onToggle && onToggle(value);
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"FullscreenToggleButton" + fullscreen}
      active={fullscreen}
      activeIcon={<FullscreenExitIcon />}
      icon={<FullScreenIcon />}
      tooltipText="Toggle Fullscreen"
      onToggle={handleToggle()}
    />
  );
};

export default memo(FullscreenToggleButton);
