import React, { ReactNode, createContext, useContext, useReducer } from "react";

import { LegendItemModel } from "models/LegendItem";

export interface IpdbContextState {
  showIpdb: boolean;
  legendItems: LegendItemModel[];
  legendTitle: string;
}
const initialState: IpdbContextState = {
  showIpdb: false,
  legendItems: [],
  legendTitle: ""
};
const IpdbContext = createContext(initialState);

function useIpdbContext() {
  const context = useContext(IpdbContext);
  if (context === undefined) {
    throw new Error("useIpdbContext must be within a ipdb provider");
  }
  return context;
}
export interface Action<T, Type> {
  type: Type;
  payload: T;
}
const IpdbDispatchContext = createContext<React.Dispatch<Action<IpdbUdpates, "update">>>(
  () => {
    throw new Error("unimplemented");
  }
);

type LegendTitleState = {
  legendTitle: string;
};
type LegendItemsState = {
  legendItems: LegendItemModel[];
};
type ShowIpdbState = {
  showIpdb: boolean;
};
export type IpdbUdpates =
  | LegendItemsState
  | LegendTitleState
  | ShowIpdbState
  | IpdbContextState;
function ipdbReducer(state: IpdbContextState, action: Action<IpdbUdpates, "update">) {
  switch (action.type) {
    case "update": {
      const obj: IpdbContextState = Object.assign({}, state, action.payload);
      return obj;
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useIpdbDispatch() {
  const context = useContext(IpdbDispatchContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}

export interface IpdbProviderModel {
  children: ReactNode;
}

function IpdbProvider({ children }: IpdbProviderModel) {
  const [state, dispatch] = useReducer(ipdbReducer, initialState);
  return (
    <IpdbContext.Provider value={state}>
      <IpdbDispatchContext.Provider value={dispatch}>
        {children}
      </IpdbDispatchContext.Provider>
    </IpdbContext.Provider>
  );
}

export { useIpdbContext, IpdbProvider, useIpdbDispatch };
