import { useCallback, useEffect, useState } from "react";

import { Business, Error, Person, Warning } from "@material-ui/icons";
import { Button, Input, Modal, Select } from "antd";
import styled from "styled-components";

import { useUser } from "hooks";

import { Workspace } from "models/workspace";

export interface WorkspaceInputModel {
  workspace?: Workspace;
  isLoading: boolean;
  error: string;
  visible: boolean;
  onSave: (title: string, type: string) => void;
  onCancel: () => void;
}

export default function WorkspaceInput({
  workspace,
  isLoading,
  error,
  visible,
  onSave,
  onCancel
}: WorkspaceInputModel) {
  const [workspaceTitle, setWorkspaceTitle] = useState(workspace?.title);
  const [workspaceType, setWorkspaceType] = useState(workspace?.type ?? "Personal");
  const [workspaceOwner, setWorkspaceOwner] = useState<string>(workspace?.ownerName);
  const [warning, setWarning] = useState("");

  const { isAtLeastPowerUser } = useUser();

  useEffect(() => {
    if (workspace && visible) {
      setWorkspaceTitle(workspace.title);
      setWorkspaceType(workspace.type);
      setWorkspaceOwner(workspace.ownerName);

      setWarning("");
    }
  }, [workspace, visible]);

  const getWorkspaceTypeOptions = useCallback(() => {
    if (isAtLeastPowerUser) {
      return [
        {
          label: (
            <WorkspaceTypeItem>
              <Person /> Personal
            </WorkspaceTypeItem>
          ),
          value: "Personal"
        },
        {
          label: (
            <WorkspaceTypeItem>
              <Business /> Organization
            </WorkspaceTypeItem>
          ),
          value: "Organization"
        }
      ];
    } else {
      return [
        {
          label: (
            <WorkspaceTypeItem>
              <Person /> Personal
            </WorkspaceTypeItem>
          ),
          value: "Personal"
        }
      ];
    }
  }, [isAtLeastPowerUser]);

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      open={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      closable={false}
      title={"Edit Workspace Details"}
      wrapClassName="copy-dashboard-modal-root"
      footer={[
        <Button key="cancel" onClick={() => onCancel()}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            onSave(workspaceTitle, workspaceType);
          }}>
          Save
        </Button>
      ]}>
      <WorkspaceAddContainer>
        <Options>
          <OptionItem>
            <Label>Title</Label>
            <Input
              autoFocus
              value={workspaceTitle}
              onChange={(e) => setWorkspaceTitle(e.target.value)}
              placeholder="Enter a Workspace's title"
            />
          </OptionItem>

          <OptionItem>
            <Label>Type</Label>
            <SelectWrapper
              popupClassName="modal-select"
              value={workspaceType}
              onChange={(value) => {
                if (workspace && value !== workspace.type) {
                  if (value === "Personal") {
                    setWarning(
                      "Changing the workspace type can impact users who previously had access to the workspace."
                    );
                  } else {
                    setWarning(
                      "Any personal dashboards will be removed from the workspace when changing the workspace type to Organization."
                    );
                  }
                } else {
                  setWarning("");
                }
                setWorkspaceType(value);
              }}
              options={getWorkspaceTypeOptions()}
            />
          </OptionItem>

          <OptionItem>
            <Label>Owner</Label>
            <Label>{workspaceOwner}</Label>
          </OptionItem>
        </Options>

        {error !== "" && (
          <ErrorContainer>
            <Error style={{ top: 3 }} /> {error}
          </ErrorContainer>
        )}

        {warning && (
          <WarningContainer>
            <Warning style={{ top: 3 }} /> {warning}
          </WarningContainer>
        )}
      </WorkspaceAddContainer>
    </Modal>
  );
}

const WorkspaceAddContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    padding: 5px;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
  padding: 2px 0;
`;

const Label = styled.label`
  min-width: 100px;
`;

const SelectWrapper = styled(Select)`
  width: 100%;
`;

const WorkspaceTypeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;

const WarningContainer = styled.div`
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
`;
