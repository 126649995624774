import { combineReducers } from "@reduxjs/toolkit";
import { withReduxStateSync } from "redux-state-sync";
import appReducer from "store/features/app/appSlice";
import arpsReducer from "store/features/arps/arpsSlice";
import channelReducer from "store/features/channel/channelSlice";
import dataTableReducer from "store/features/dataTable/dataTableSlice";
import normalizeByReducer from "store/features/normalizeBy/normalizeBySlice";
import projectReducer from "store/features/project/projectSlice";
import documentationReducer from "store/features/userDocumentation/documentationSlice";
import userSettingReducer from "store/features/userSettings/userSettingsSlice";

import authReducer from "./features/auth/authSlice";
import filesReducer from "./features/files/filesSlice";
import facilityFilterReducer from "./features/filter/facilityFilterSlice";
import filterReducer from "./features/filter/filterSlice";
import groupByReducer from "./features/groupBy/groupBySlice";
import mapReducer from "./features/map/mapSlice";
import organizationSettingsReducer from "./features/organizationSettings/organizationSettingsSlice";

const rootReducer = combineReducers({
  organizationSettings: organizationSettingsReducer,
  files: filesReducer,
  filter: filterReducer,
  facilityFilter: facilityFilterReducer,
  auth: authReducer,
  groupBy: groupByReducer,
  map: mapReducer,
  channel: channelReducer,
  userSetting: userSettingReducer,
  arps: arpsReducer,
  project: projectReducer,
  normalizeBy: normalizeByReducer,
  documentation: documentationReducer,
  dataTable: dataTableReducer,
  app: appReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default withReduxStateSync(rootReducer);
