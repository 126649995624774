interface Product {
  name: string;
  items: string[];
}

/*
 * Checks if a given label belongs to a specified category in an array of Product objects.
 * Example Usage: isLabelInCategory(ChartProduct, "Percent", "On-Time") --> Returns True.
 */
export const isLabelInCategory = (
  products: Product[],
  categoryName: string,
  label: string
): boolean => {
  const productOrRatioLabels = products
    .filter((category) => category.name === categoryName)
    .map((category) => category.items)
    .flat();

  return productOrRatioLabels.includes(label);
};
