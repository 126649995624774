import { MutableRefObject, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { useTable } from "../context";

const jumperClassName = ".ant-pagination-options-quick-jumper";

/**
 * Simple `hook` that abstracts away changing the case from `Go to Page` to `Go to page` for Pagination jumper component.
 *
 * @param ref - ref to the pagination component wrapper
 */
const useLowercasePaginationJumper = (ref: MutableRefObject<HTMLDivElement>) => {
  const [{ pageSize }] = useTable();

  const filterCounts = useSelector((state: RootState) => state.filter.filterCounts);

  // derive total results from filterCounts state
  const totalResults = filterCounts.Active ?? filterCounts.UniqueId ?? 0;

  // antd re-renders the jumpers when pageSize changes, so we need to
  // include the pageSize in the effect dependency list to keep the text lowercase
  useEffect(() => {
    if (!totalResults) return;

    // make text node 'Page' lowercase
    const changePageCase = async () => {
      const jumper = ref.current.querySelector(jumperClassName);
      if (!jumper) return;

      const pageNode = jumper.childNodes[2];
      pageNode.textContent = pageNode.textContent.toLocaleLowerCase();
    };

    changePageCase();
  }, [ref, totalResults, pageSize]);
};

export default useLowercasePaginationJumper;
