import Autosizer from "react-virtualized-auto-sizer";
import { FixedSizeList as VirtualList } from "react-window";

import { Collapse } from "antd";
import { setSelectedShapefile } from "store/features/project/projectSlice";
import styled from "styled-components";

import { useUser } from "hooks";

import { GeomT } from "models/model";

import { ColorPicker } from "components/base";
import { IconSpinner } from "components/icons";
import {
  EditableGeomProperties,
  useUpdateBatchProjectGeomsMutation,
  useUpdateProjectGeomMutation
} from "components/project/shapefiles/mutations";

interface IShapefileFeatureGroup {
  groups: GeomT[][];
  shapefileFeaturePropertyKey;
  shapefileFeatures;
  selectedShapefile;
}

export default function OrganizationShapefileFeatureGroup({
  groups,
  shapefileFeaturePropertyKey,
  selectedShapefile
}: IShapefileFeatureGroup) {
  const { isAtLeastPowerUser } = useUser();
  const batchGeomUpdater = useUpdateBatchProjectGeomsMutation({
    onSuccess: () => {
      setSelectedShapefile({ ...selectedShapefile });
    }
  });

  const updateProjectGeom = useUpdateProjectGeomMutation({
    onSuccess: () => {
      setSelectedShapefile({ ...selectedShapefile });
    }
  });

  function updateFeatureProps(featureId, oldFeature, prop, value) {
    updateProjectGeom.mutate({
      body: {
        color: oldFeature.Color,
        thickness: oldFeature.Thickness,
        strokeColor: oldFeature.StrokeColor,
        [prop]: value
      },
      shapeFileGeomId: featureId
    });
  }

  function updateShapefileGeoms(
    currentGeomGroup: GeomT[],
    newProperties: EditableGeomProperties,
    propertyKey,
    value
  ) {
    const geomIds = currentGeomGroup.map((geom) => geom.shapefileGeomId);
    batchGeomUpdater.mutateAsync({
      shapeFileGeomIds: geomIds,
      update: {
        ...newProperties,
        [propertyKey]: value
      }
    });
  }

  const FeatureRowFn = ({ style, index, data }) => {
    const feature = data[index] as GeomT;
    const propertyValue = feature?.Properties?.[shapefileFeaturePropertyKey];

    return (
      <FeatureRow key={`${feature.shapefileGeomId}`} style={style}>
        <ColorPicker
          isReadOnly={!isAtLeastPowerUser}
          onChange={(key, prop) =>
            updateFeatureProps(feature.shapefileGeomId, feature, key, prop)
          }
          values={{
            color: feature.Color || selectedShapefile.layer.style.color,
            strokeColor: feature.StrokeColor || selectedShapefile.layer.style.strokeColor,
            thickness: feature.Thickness || selectedShapefile.layer.style.thickness,
            opacity: (feature.Opacity || selectedShapefile.layer.style.opacity || 1) * 100
          }}
        />
        <StyledPropertyValue>{propertyValue}</StyledPropertyValue>
      </FeatureRow>
    );
  };

  return (
    <Collapse collapsible={"icon"}>
      {groups?.map((featureGroup, i) => {
        const firstGeom = featureGroup?.[0];
        const geomGroupTitle = firstGeom?.Properties?.[shapefileFeaturePropertyKey];
        return (
          <StyledPanel
            header={
              <GroupedFeatureHeaderWrapper>
                <ColorPicker
                  isReadOnly={!isAtLeastPowerUser}
                  onChange={(propertyKey, value) =>
                    updateShapefileGeoms(featureGroup, null, propertyKey, value)
                  }
                  values={{
                    color: firstGeom?.Color || selectedShapefile.layer.style.color,
                    strokeColor:
                      firstGeom?.StrokeColor || selectedShapefile.layer.style.strokeColor,
                    thickness:
                      firstGeom?.Thickness || selectedShapefile.layer.style.thickness,
                    opacity:
                      (firstGeom?.Opacity || selectedShapefile.layer.style.opacity || 1) *
                      100
                  }}
                />
                <StyledGeomGroupTitle>{geomGroupTitle}</StyledGeomGroupTitle>
              </GroupedFeatureHeaderWrapper>
            }
            // If animation doesn't feel right, we'll need to know when map/project tree is finished loading
            // as this purely accounts for the update
            extra={
              batchGeomUpdater.isLoading &&
              batchGeomUpdater.variables.shapeFileGeomIds?.[0] ===
                firstGeom.shapefileGeomId && <IconSpinner size={14} />
            }
            key={`${geomGroupTitle}${i}`}>
            <GroupedFeaturesContainer>
              <Autosizer>
                {({ height, width }) => {
                  return (
                    <VirtualList
                      className="List"
                      height={height}
                      width={width}
                      itemCount={featureGroup?.length ?? 0}
                      itemSize={35}
                      itemData={featureGroup}>
                      {FeatureRowFn}
                    </VirtualList>
                  );
                }}
              </Autosizer>
            </GroupedFeaturesContainer>
          </StyledPanel>
        );
      })}
    </Collapse>
  );
}

const GroupedFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30vh;
`;

const GroupedFeatureHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const FeatureRow = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledPropertyValue = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  /* Imperfect solution to match the overflow from the 'width: 78%' in the StyledGeomGroupTitle */
  padding-right: 18px;
`;

const StyledGeomGroupTitle = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  /* ant-checkbox - Not perfect solution, but full width will cut into checkbox width,
   * and making checkbox width 100% takes up too much width probably because of the hidden styles */
  width: 78%;
`;

const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-header-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
