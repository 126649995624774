import { useEffect, useState } from "react";

import { IGroupByListColumn } from "models";

import useColumns from "./useColumns";

/**
 * Returns a filtered list of categories after applying:
 * - exclude-category filter
 */
const useOrgGeoSelector = (geoSyncJob: string): IGroupByListColumn[] => {
  const [list, setList] = useState<IGroupByListColumn[] | null>(null);

  const columns = useColumns();

  useEffect(() => {
    if (!columns?.length || !geoSyncJob) {
      setList([]);
      return;
    }

    const geoColumns = columns.filter((column) => column.subgroup === geoSyncJob);

    setList(geoColumns);
  }, [geoSyncJob, columns]);

  return list;
};

export default useOrgGeoSelector;
