import {
  NormalizeBySettings,
  NormalizeByToggle,
  NormalizeByWrapper
} from "components/filter";
import { useChartSettings } from "components/multiphase-chart/context";
import { changeSettings } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

const Normalize = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const updateChartSettings = (key) => (value) => {
    const nextSettings = { ...chartSettings.settings, [key]: value };

    changeSettings(chartSettingsDispatch, nextSettings);
  };

  return (
    <NormalizeByWrapper data-testid={"multiphasechart-normalize-by-container"}>
      <NormalizeByToggle
        checked={chartSettings.settings?.useNormalizeBy}
        onToggle={updateChartSettings("useNormalizeBy")}
      />

      {chartSettings.settings?.useNormalizeBy && (
        <NormalizeBySettings
          value={chartSettings.settings?.normalizeBy}
          onChange={updateChartSettings("normalizeBy")}
        />
      )}
    </NormalizeByWrapper>
  );
};

export default Normalize;
