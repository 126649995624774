import { useQuery } from "react-query";

import axios from "axios";

import { fileServiceEndpoint } from "api/files";

export interface IFile {
  fileId: string;
  fileName: string;
  fileExtension: string;
  fileSize: number;
  fileSizeFormatted: string;
  lastModified: Date;
  mimeType: string;
}

export interface IFolder {
  folderType: string;
  files: IFile[];
}

export const useFilesListQueryKeys = {
  currentFilesList: "currentFilesList"
};

export type UseFilesListQueryKeyT = keyof typeof useFilesListQueryKeys;

export interface UseFilesListQueryParamsT {
  isAutoRefetching?: boolean;
  onError?: (error) => void;
  onSuccess?: (data) => void;
  queryKey?: UseFilesListQueryKeyT;
  select?;
  filesType: string;
}

export interface UseFilesListQueryReturnT {
  data: IFolder;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchFilesList = async ({ queryKey }) => {
  const [, filesType] = queryKey;
  try {
    const response = await axios.get(`${fileServiceEndpoint}/files/${filesType}`);
    // console.log(data);
    return response.data as IFolder;
  } catch (error) {
    throw new Error("Unable to fetch Files list");
  }
};

export function useFilesListQuery(
  params: UseFilesListQueryParamsT
): UseFilesListQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true, filesType } = params;

  const queryInfo = useQuery({
    queryKey: [useFilesListQueryKeys.currentFilesList, filesType],
    queryFn: fetchFilesList,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching !== undefined ? isAutoRefetching : !!params.filesType
  });

  return {
    data: queryInfo.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
