import { useContext } from "react";

import { FilesDispatchContext } from "./FilesContext";

export function useFilesDispatch() {
  const context = useContext(FilesDispatchContext);
  if (context === undefined) {
    throw new Error("useFilesDispatch must be used within a Files Provider");
  }
  return context;
}
