import { memo } from "react";

function TwoFactorAuth({ size = 30, className = "" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      version="1.1"
      className={className}>
      <g id="IconsTrash-Copy">
        <path
          d="M0 0L30 0L30 30L0 30L0 0Z"
          transform="translate(0 0.0046874997)"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M8.65322 11.5812L8.65322 9.61832L2.32279 9.61832C2.32279 9.34569 2.39095 9.11668 2.52726 8.9313C2.66358 8.74591 2.8626 8.57688 3.12432 8.42421L3.12432 8.42421L7.06652 6.13413C7.58997 5.82879 7.97437 5.46619 8.21974 5.04635C8.4651 4.6265 8.58779 4.12214 8.58779 3.53326L8.58779 3.53326L8.58779 3.00981C8.58779 2.42094 8.44329 1.89749 8.15431 1.43948C7.86532 0.981461 7.45911 0.627045 6.93566 0.376227C6.41221 0.125409 5.80698 0 5.11996 0L5.11996 0L3.58233 0C2.45911 0 1.58124 0.332606 0.948746 0.997819C0.316249 1.66303 0 2.58997 0 3.77863L0 3.77863L2.25736 3.77863C2.25736 3.21156 2.37732 2.77263 2.61723 2.46183C2.85714 2.15104 3.18975 1.99564 3.61505 1.99564L3.61505 1.99564L5.11996 1.99564C5.47983 1.99564 5.77154 2.08833 5.99509 2.27372C6.21865 2.45911 6.33043 2.70447 6.33043 3.00981L6.33043 3.00981L6.33043 3.53326C6.33043 3.72955 6.27317 3.92585 6.15867 4.12214C6.04417 4.31843 5.88877 4.4711 5.69248 4.58015L5.69248 4.58015L1.63577 7.00109C1.0578 7.35005 0.648855 7.76445 0.408942 8.24428C0.169029 8.7241 0.0490731 9.29117 0.0490731 9.94547L0.0490731 9.94547L0.0490731 11.5812L8.65322 11.5812ZM12.3173 11.5812L12.3173 7.13195L18.0262 7.13195L18.0262 5.16903L12.3173 5.16903L12.3173 2.09378L18.6314 2.09378L18.6314 0.130862L10.06 0.130862L10.06 11.5812L12.3173 11.5812ZM21.3468 11.5812L22.361 8.91494L26.6467 8.91494L27.6445 11.5812L30 11.5812L25.9269 1.11232C25.6761 0.458015 25.1963 0.130862 24.4875 0.130862L24.4875 0.130862L23.4733 0.130862L18.9913 11.5812L21.3468 11.5812ZM26.0414 7.01745L22.9662 7.01745L24.5038 2.89531L24.5202 2.89531L26.0414 7.01745Z"
          transform="translate(0 8.75)"
          id="2FA"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}
export default memo(TwoFactorAuth);
