import React, { useEffect, useRef, useState } from "react";

import { Alert, Input, Modal } from "antd";
import { AxiosError } from "axios";
import styled from "styled-components";

interface FieldTemplatePopoverActionsProps {
  containerRef: React.RefObject<HTMLDivElement>;
  onCancel: () => void;
  onOk: (templateName: string) => Promise<void>;
  isVisible: boolean;
}

export const TemplateNameModal: React.FC<FieldTemplatePopoverActionsProps> = ({
  onCancel,
  onOk,
  containerRef,
  isVisible
}) => {
  const [templateName, setTemplateName] = useState("");
  const [error, setError] = useState<AxiosError>(undefined);
  const inputRef = useRef(null);
  const handleOk = async () => {
    setError(undefined);
    if (templateName.trim() === "") {
      setError(new AxiosError("Template name cannot be empty"));
      return;
    }
    let hasError = false;
    try {
      await onOk(templateName.trim());
      setError(undefined);
    } catch (err) {
      hasError = true;
      setError(err);
    } finally {
      if (!hasError) setTemplateName("");
    }
  };

  const handleCancel = () => {
    setTemplateName("");
    setError(undefined);
    onCancel();
  };

  useEffect(() => {
    if (!isVisible) return;
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  }, [isVisible]);

  return (
    <RootContainer>
      <Modal
        title="Enter template name"
        className={"template-name-modal"}
        wrapClassName={"template-name-modal-wrap"}
        getContainer={() => containerRef.current}
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Input
          autoFocus
          ref={inputRef}
          placeholder="Enter template name"
          value={templateName}
          onPressEnter={handleOk}
          onChange={(e) => setTemplateName(e.target.value)}
        />
        {error && (
          <Alert
            type={"error"}
            message={error.response?.data ?? error?.message}
            showIcon
          />
        )}
      </Modal>
    </RootContainer>
  );
};

const RootContainer = styled.div``;
