import { memo } from "react";

function CumTime() {
  return (
    <svg
      width="8.2733221mm"
      height="5.1754131mm"
      viewBox="0 0 8.2733221 5.1754131"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs2" />
      <g id="layer1" transform="translate(-73.178706,-78.47422)">
        <g id="g42" transform="matrix(0.26458333,0,0,0.26458333,72.998326,77.659568)">
          <g id="iconRate-time" transform="translate(1.18175,3.139617)">
            <g id="iconTotal-rate-date-Copy">
              <g id="area-chart-icon_3">
                <path
                  d="M 0,8.598 0.028,19 H 18"
                  id="Path-3"
                  fill="none"
                  fillRule="evenodd"
                  stroke="currentColor"
                  strokeWidth="2.25"
                />
                <path
                  d="M -0.49632038,9.553301 C 1.8779584,5.0415946 1.3534837,4.5324866 13.671573,3.800699"
                  id="Path-2"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.25"
                  transform="translate(3.4093895,6.1366997)"
                />
              </g>
            </g>
          </g>
          <path
            d="M 6.675,5.09 V 3.579 H 2.823 L 4.439,6.074 2.823,8.596 H 6.675 V 7.084"
            id="Path-8"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="m 26.701,11.772 c -2.895,0 -5.25,2.355 -5.25,5.25 0,2.895 2.355,5.25 5.25,5.25 2.895,0 5.25,-2.355 5.25,-5.25 0,-2.895 -2.355,-5.25 -5.25,-5.25 z m 2.497,7.966 c -0.086,0.085 -0.198,0.128 -0.31,0.128 -0.112,0 -0.224,-0.043 -0.309,-0.128 L 26.391,17.55 c -0.082,-0.082 -0.128,-0.193 -0.128,-0.309 v -2.844 c 0,-0.242 0.196,-0.437 0.438,-0.437 0.241,0 0.437,0.195 0.437,0.437 v 2.663 l 2.06,2.059 c 0.171,0.171 0.171,0.448 0,0.619 z"
            id="Shape"
            fill="currentColor"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(CumTime);
