import { useQuery } from "react-query";

import axios from "axios";

import { GEO_CALCULATION_SERVICE_ADDRESS } from "../modals/constants";
import { GeoCalculationsJob } from "../models/GeoCalculations";

export async function getGeoCalculationsJobs() {
  try {
    const response = await axios.get<GeoCalculationsJob[]>(
      `${GEO_CALCULATION_SERVICE_ADDRESS}/geo-calculation/jobs`
    );

    if (!Array.isArray(response.data)) {
      // API returns 200 with an empty array if there are no jobs
      return [];
    }

    return response.data;
  } catch (error) {
    // If the request fails, return an empty array
    return [];
  }
}

export function useGetGeoCalculationsJobs() {
  return useQuery<GeoCalculationsJob[]>("geoCalculations", getGeoCalculationsJobs);
}
