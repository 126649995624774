import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { updateUserOrganization } from "store/features";
import { RootState } from "store/rootReducer";

import { updateOrganizationProfile } from "api/users";

import {
  BaseButton,
  BaseDivider,
  BaseDropdown,
  BaseInput,
  Heading,
  SubHeading
} from "components/base";

import CompanyLogo from "./CompanyLogo";
import "./OrganizationProfile.scss";

const province_list = [
  "Ontario",
  "Quebec",
  "Nova Scotia",
  "New Brunswick",
  "Manitoba",
  "British Columbia",
  "Prince Edward Island",
  "Saskatchewan",
  "Alberta",
  "Newfoundland and Labrador"
];
const requiredKeys = [
  "name",
  "address",
  "city",
  "provinceState",
  "postalCode",
  "officeNumber",
  "firstName",
  "lastName",
  "contactPhoneNumber"
];

function formatKey(key) {
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
}

function validateString(key, value) {
  let error = null;
  if (!value.length) {
    error = `${formatKey(key)} cannot be blank`;
  }
  return error;
}

export default function OrganizationProfile(): JSX.Element {
  const user = useSelector((state: RootState) => state.auth.user);
  const { organization } = user;
  const [savingProfile, setSavingProfile] = useState(false);

  const [errors, setErrors] = useState({
    name: null,
    address: null,
    postalCode: null,
    city: null,
    provinceState: null,
    officeNumber: null,
    firstName: null,
    lastName: null,
    contactPhoneNumber: null
  });
  const [formValues, setFormValues] = useState({
    name: organization.name,
    address: organization.address,
    city: organization.city,
    provinceState: organization.provinceState,
    postalCode: organization.postalCode,
    officeNumber: organization.phoneNumber,
    firstName: organization.primaryContact.firstName,
    lastName: organization.primaryContact.lastName,
    contactPhoneNumber: organization.primaryContact.phoneNumber
  });

  const dispatch = useDispatch();
  const hasErrors = Object.keys(errors).some((key) => errors[key]);

  function validateFields() {
    let currErrors = false;
    Object.keys(formValues).forEach((key) => {
      if (requiredKeys.includes(key) && !formValues[key].length) {
        handleChange(key, formValues[key]);
        currErrors = true;
      }
    });
    return currErrors;
  }

  function handleChange(key, value) {
    switch (key) {
      case "name":
      case "address":
      case "city":
      case "provinceState":
      case "postalCode":
      case "officeNumber":
      case "firstName":
      case "lastName":
      case "contactPhoneNumber":
        setFormValues((prevState) => ({ ...prevState, [key]: value }));
        setErrors((prevState) => ({
          ...prevState,
          [key]: validateString(key, value)
        }));
        break;
    }
  }

  async function saveProfile() {
    const {
      name,
      address,
      city,
      provinceState,
      postalCode,
      officeNumber,
      firstName,
      lastName,
      contactPhoneNumber
    } = formValues;
    const updatedOrganization = await updateOrganizationProfile(organization.id, {
      id: organization.id,
      name,
      address,
      city,
      provinceState,
      postalCode,
      phoneNumber: officeNumber,
      primaryContact: {
        firstName,
        lastName,
        phoneNumber: contactPhoneNumber
      },
      maximumUsers: organization.maximumUsers
    });
    if (updatedOrganization) {
      dispatch(updateUserOrganization(updatedOrganization));
      toast.success("Successfully updated company profile.");
    } else {
      toast.error("Error updating company profile.");
    }
    setSavingProfile(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const currErrors = validateFields();
    if (currErrors) return;
    setSavingProfile(true);
    saveProfile();
  }
  return (
    <>
      <div className="organizationAccountHeader">
        <Heading element="h4">Company Profile</Heading>
      </div>

      <form className="organizationForm" onSubmit={handleSubmit}>
        {/*<AvatarField user={user} />*/}
        <div className="form-container">
          <div className="section-header">
            <SubHeading>Company Details</SubHeading>
            <BaseDivider />
          </div>

          <BaseInput
            label="Company Name"
            id="companyName"
            name="companyName"
            placeholder="Company Name"
            value={formValues.name}
            onChange={(value) => handleChange("name", value)}
            errors={errors.name}
          />

          <BaseInput
            label="Address"
            id="address"
            placeholder="123 Street Name"
            name="address"
            value={formValues.address}
            onChange={(value) => handleChange("address", value)}
            errors={errors.address}
          />

          <BaseInput
            label="City"
            name="city"
            placeholder="Calgary"
            value={formValues.city}
            onChange={(value) => handleChange("city", value)}
            errors={errors.city}
          />

          <BaseDropdown
            inputLabel="Province"
            options={province_list}
            value={formValues.provinceState}
            onChange={(value) => handleChange("provinceState", value)}
            errors={errors.provinceState}
          />

          <BaseInput
            label="Postal Code"
            id="postalCode"
            placeholder="T2T 2C2"
            name="postalCode"
            value={formValues.postalCode}
            onChange={(value) => handleChange("postalCode", value)}
            errors={errors.postalCode}
          />

          <BaseInput
            label="Office number"
            id="officeNumber"
            placeholder="1-800-888-8888"
            name="officeNumber"
            value={formValues.officeNumber}
            onChange={(value) => handleChange("officeNumber", value)}
            errors={errors.officeNumber}
          />
          <div className="section-header">
            <SubHeading>Primary Contact</SubHeading>
            <BaseDivider />
          </div>
          <BaseInput
            label="Your first name"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={formValues.firstName}
            onChange={(value) => handleChange("firstName", value)}
            errors={errors.firstName}
          />

          <BaseInput
            label="Your last name"
            id="lastName"
            placeholder="Last Name"
            name="lastName"
            value={formValues.lastName}
            onChange={(value) => handleChange("lastName", value)}
            errors={errors.lastName}
          />

          <BaseInput
            label="Phone number"
            id="phoneNumber"
            placeholder="1-403-000-0000"
            name="phoneNumber"
            value={formValues.contactPhoneNumber}
            onChange={(value) => handleChange("contactPhoneNumber", value)}
            errors={errors.contactPhoneNumber}
          />

          <div className="button-container">
            <BaseButton
              type="submit"
              className="justify-right"
              appearance="primary"
              isDisabled={hasErrors}
              isLoading={savingProfile}>
              Save Changes
            </BaseButton>
          </div>
        </div>
        <div className="company-logo">
          <CompanyLogo user={user} />
        </div>
      </form>
    </>
  );
}
