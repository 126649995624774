import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  FACILITY_LABEL_LAYER,
  GEO_MAP_LAYER,
  IPDB_CONTROL_WELLS_LAYER,
  IPDB_LAYER,
  WELL_LABEL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import { RootState } from "store/rootReducer";

import { useMapContext } from "../useMapContext";

interface IUseManageMapboxLayerStylesParams {
  mapbox: mapboxgl.Map;
}

/**
 * Visual changes to the mapbox layers are applied here based on the redux map layers. (visibility, zoom, etc)
 *
 * Example use cases:
 * Primarily to update properties on mapbox that aren't stored in the backend, the shapefiles are for the most part updated in the shapefile loader.
 *
 * Visibility is updated on layer tree/list check handler, and in project initialization (useProjectInitialization hook).
 * Both cases utilize the useUpdateMapLayersVisibility hook to set the redux map layers.
 *
 * Zoom is handled with the layer tree/list ZoomSlider component
 * @param param0
 */
export function useManageMapboxLayerStyles({
  mapbox
}: IUseManageMapboxLayerStylesParams) {
  const mapboxLayers = useSelector((state: RootState) => state.map.layers);
  const displaySettings = useSelector((state: RootState) => state.map.displaySettings);

  const { isStyleLoaded } = useMapContext();

  useEffect(() => {
    if (!mapbox || !isStyleLoaded) {
      return;
    }

    for (const layer of mapboxLayers) {
      if (
        layer.id === IPDB_LAYER ||
        layer.id === GEO_MAP_LAYER ||
        layer.id === IPDB_CONTROL_WELLS_LAYER
      ) {
        continue;
      }

      // Shapefiles can be removed from the map, but the layer will still exist in the mapboxLayers
      if (mapbox.getLayer(layer.id)) {
        const isLayerVisible = layer.isVisible ? "visible" : "none";
        mapbox.setLayoutProperty(layer.id, "visibility", isLayerVisible);

        if (layer.minzoom || layer.maxzoom) {
          mapbox.setLayerZoomRange(layer.id, layer.minzoom, layer.maxzoom);
        }

        if (layer.id === WELL_LAYER) {
          if (displaySettings.wellLineThickness > 0) {
            mapbox.setPaintProperty(
              layer.id,
              "line-width",
              displaySettings.wellLineThickness
            );
          }

          if (displaySettings.wellDotThickness > 0) {
            const radius = [
              "interpolate",
              ["linear"],
              ["zoom"],
              6,
              2,
              15,
              4 + displaySettings.wellDotThickness * 2,
              22,
              8 + displaySettings.wellDotThickness * 4
            ];
            mapbox.setPaintProperty(WELL_LAYER_POINT, "circle-radius", radius);
          }
        }

        if (layer.id === WELL_LABEL_LAYER) {
          mapbox.setLayoutProperty(layer.id, "text-size", displaySettings.wellLabelSize);
        } else if (layer.id === FACILITY_LABEL_LAYER) {
          mapbox.setLayoutProperty(
            layer.id,
            "text-size",
            displaySettings.facilityLabelSize
          );
        } else if (layer.id?.includes("-shapefile-label")) {
          mapbox.setLayoutProperty(
            layer.id,
            "text-size",
            displaySettings.shapefileNameLabelSize
          );
        } else if (layer.id?.includes("-property")) {
          mapbox.setLayoutProperty(
            layer.id,
            "text-size",
            displaySettings.shapefileDetailsLabelSize
          );
        }
      }
    }
  }, [mapbox, mapboxLayers, isStyleLoaded, displaySettings]);
}
