import { useCallback, useEffect, useState } from "react";

import { ALL_CHART_TYPES } from "constants/chart.constants";
import _max from "lodash/max";
import _min from "lodash/min";
import { getSeriesMinMax } from "utils";

const useMinMax = (chartType, data) => {
  const [value, setValue] = useState(null);
  const getMemoizedMinMax = useCallback(getSeriesMinMax, []);

  useEffect(() => {
    if (!chartType) return;
    if (!data) return;

    const hasSeries = Object.prototype.hasOwnProperty.call(data, "series");
    const hasLayout = Object.prototype.hasOwnProperty.call(data, "layout");

    if (!hasSeries && !hasLayout) return;

    const { series, layout } = data;
    if (!series.length) return;

    if (chartType === ALL_CHART_TYPES.TrendDate.label) {
      // if the first series or second series contains "N/A" or "< " then exclude those series as they inflate the y-max,
      // and are not valid in the context of a 'time' type x-axis.
      series?.forEach((t, i) => {
        if (t?.x?.[0]?.toString()?.toLowerCase() === "n/a") {
          t.x.shift();
          series[i].y.shift();
        }

        if (t?.x?.[0]?.toString()?.includes("< ")) {
          t.x.shift();
          series[i].y.shift();
        }
      });
    }

    let [xMin, xMax] = getMemoizedMinMax("x", series);
    const [yMin, yMax] = getMemoizedMinMax("y", series);

    if (chartType === ALL_CHART_TYPES.TotalRateDate.label) {
      xMin = _min(layout.xAxis.data);
      xMax = _max(layout.xAxis.data);
    }

    const newValue = {
      x: { min: xMin, max: xMax },
      y: { min: yMin, max: yMax }
    };
    setValue(newValue);
  }, [chartType, data]);

  return value;
};

export default useMinMax;
