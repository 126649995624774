export function isRatio(value: string): boolean {
  if (!value) {
    return false;
  }
  const productUppercase = value.toUpperCase();
  return (
    productUppercase !== "OIL + COND" &&
    productUppercase !== "OIL" &&
    productUppercase !== "WATER" &&
    productUppercase !== "GAS" &&
    productUppercase !== "COND" &&
    productUppercase !== "CONDENSATE" &&
    productUppercase !== "SALES GAS"
  );
}

export function isPrimaryProduct(value: string): boolean {
  return !isRatio(value);
}
