import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { LngLatLike } from "mapbox-gl";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { setMapState } from "store/features";

import { BaseDropdown, BaseIconToggle, BaseTooltip } from "components/base";

import { useMapContext } from "../hooks/useMapContext";

export default function ZoomControl() {
  const { mapbox } = useMapContext();
  const dispatch = useDispatch();
  const [selectedZoom, setSelectedZoom] = useState("Province/State");
  useEffect(() => {
    if (!mapbox) {
      return;
    }
    fromEvent(mapbox, "zoom")
      .pipe(debounceTime(1000))
      .subscribe(() => {
        const currZoom = mapbox.getZoom();
        findNewZoomLabel(currZoom);
      });
  }, [mapbox]);

  function handleZoomDropdownChange({ label, value }) {
    const newZoom = value;
    let center = mapbox.getCenter() as LngLatLike;
    if (selectedZoom === "World") {
      center = [-120.608666, 55.869737];
    }
    if (label === "Continent") center = [-100.39382659300577, 51.55723251975266];
    mapbox.flyTo({
      center,
      zoom: newZoom
    });
    const newMapState = {
      lng: center[1],
      lat: center[0],
      zoom: newZoom
    };
    dispatch(setMapState(newMapState));
  }
  function findNewZoomLabel(zoom) {
    let opt = "";
    const options = [...zoomOptions, { label: "INF", value: 9999 }];
    for (let k = 0; k < options.length; k++) {
      if (zoom >= options[k].value && zoom <= options[k + 1].value) {
        opt = options[k].label;
      }
    }
    setSelectedZoom(opt);
  }
  function handleZoomIncrease() {
    if (!mapbox) {
      return;
    }
    mapbox.zoomIn({ duration: 1000 });
    const newZoom = mapbox.getZoom();
    findNewZoomLabel(newZoom);
  }

  function handleZoomDecrease() {
    mapbox.zoomOut({ duration: 1000 });
    const newZoom = mapbox.getZoom();
    findNewZoomLabel(newZoom);
  }
  if (!mapbox) {
    return <></>;
  }
  return (
    <div className="zoom-control">
      <BaseTooltip text="Zoom Level">
        <div className="flex-row">
          <BaseIconToggle squareIcon>
            <AddIcon fontSize="large" onClick={handleZoomIncrease} />
          </BaseIconToggle>
          <BaseDropdown
            value={selectedZoom}
            onChange={handleZoomDropdownChange}
            options={zoomOptions}
          />
          <BaseIconToggle squareIcon>
            <RemoveIcon fontSize="large" onClick={handleZoomDecrease} />
          </BaseIconToggle>
        </div>
      </BaseTooltip>
    </div>
  );
}
const zoomOptions = [
  { label: "World", value: 0 },
  { label: "Continent", value: 3 },
  { label: "Province/State", value: 5 },
  { label: "Region", value: 7 },
  { label: "Area", value: 9 },
  { label: "Township", value: 10 },
  { label: "Section", value: 11 }
];
