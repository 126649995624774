import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { Switch } from "antd";
import * as echarts from "hd-echarts/echarts";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { getWellsFromFilterId } from "api/filter";

import { BusyIndicator } from "components/activity/shared";
import { Screenshot, useChartDispatch, useChartState } from "components/chart";
import IconSpinner from "components/icons/IconSpinner";
import { useScreenshotContext } from "components/screenshot/hooks";

import { ScreenshotToggle } from "./components";
import { useRfPvQuery } from "./hooks";

export interface RFvsPVChartModel {
  id: string;
}
export default function RFvsPVChart({ id }: RFvsPVChartModel) {
  const { screenshot } = useChartState();
  const { settings } = useScreenshotContext();

  const chartDispatch = useChartDispatch();

  const chartContainerRef = useRef(null);
  const echartRef = useRef(null);
  const [publicDataOnly, setPublicDataOnly] = useState(false);

  const containerRef = useRef(null);
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const { data: wellList, refetch: updateWellList } = useQuery(
    "well-list-query",
    async () => {
      const response = await getWellsFromFilterId(filterId.id);
      if (response.status === 200) {
        return response.data.uwiList?.map((w) => w.formattedId);
      }
      return [];
    }
  );
  useEffect(() => {
    updateWellList();
  }, [filterId, updateWellList]);

  const { chartData, updateData, isFetching } = useRfPvQuery(wellList, publicDataOnly);

  useEffect(() => {
    updateData();
  }, [updateData, publicDataOnly, wellList]);
  useEffect(() => {
    const doc = document.getElementById(id);
    if (doc) {
      echartRef.current = echarts.init(doc);
    }
  }, [id]);

  useEffect(() => {
    if (!echartRef.current || !chartData) {
      return;
    }
    const option = {
      title: {
        text: ""
      },
      grid: {
        left: 70,
        bottom: 70,
        top: 10,
        right: 30
      },
      tooltip: {
        trigger: "axis",
        confine: true,
        enterable: true,
        className: "chart-tooltip-container",
        extraCssText: "border-style:none;",

        axisPointer: {
          type: "cross",
          label: {
            show: true,
            precision: "2"
          }
        }
      },
      dataZoom: [
        {
          type: "slider",
          id: "xAxis-slider",
          xAxisIndex: 0,
          filterMode: "none",
          backgroundColor: "rgba(0,0,0,0)",
          fillerColor: "rgba(0,0,0,0)",
          handleSize: "14px",
          handleIcon: "path://M0 0L3 0L3 14L0 14L0 0Z",
          handleStyle: {
            opacity: 1,
            color: "#cdd2d5"
          },
          borderColor: "rgba(0,0,0,0)",
          showDataShadow: false,
          showDetail: false,
          moveHandleSize: 0,
          moveHandleStyle: {
            borderWidth: 0,
            opacity: 1
          },
          zlevel: -1,
          bottom: 32,
          brushSelect: false,
          height: "21px",
          show: true
        },
        {
          type: "slider",
          id: "yAxis-slider",
          yAxisIndex: 0,
          filterMode: "none",
          backgroundColor: "rgba(0,0,0,0)",
          fillerColor: "rgba(0,0,0,0)",
          handleSize: "14px",
          handleIcon: "path://M0 0L3 0L3 14L0 14L0 0Z",
          handleStyle: {
            opacity: 1,
            color: "#cdd2d5"
          },
          borderColor: "rgba(0,0,0,0)",
          showDataShadow: false,
          showDetail: false,
          moveHandleSize: 0,
          moveHandleStyle: {
            borderWidth: 0,
            opacity: 1
          },
          left: 50,
          zlevel: -1,
          brushSelect: false,
          triggerEvent: true
        },
        {
          type: "inside",
          orient: "vertical",
          filterMode: "none"
        },
        {
          type: "inside",
          filterMode: "none",
          orient: "horizontal"
        }
      ],
      legend: {
        top: "bottom"
      },
      xAxis: {
        type: "log",
        triggerEvent: true,
        name: "Pore Volume Injected (bbl/bbl)",
        id: "xAxis",
        nameLocation: "middle",
        data: null,
        min: 0.001,

        max: null,
        nameGap: 30,
        useY: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "rgb(155,155,155)",
            width: 1
          }
        },
        splitLine: {
          lineStyle: {
            color: "rgb(217,225,226)"
          }
        },
        axisTick: {
          lineStyle: {
            width: 1
          }
        },
        axisLabel: {
          fontWeight: 500,
          color: "#5B6770",
          fontSize: 12,
          padding: [5, 0, 0, 2]
        },
        nameTextStyle: {
          fontSize: 12
        }
      },
      yAxis: [
        {
          id: "yAxis",
          triggerEvent: true,
          min: 0,
          max: 100,
          type: "value",
          name: "Recovery Factor (%)",
          nameLocation: "middle",
          nameGap: 50,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "rgb(155,155,155)",
              width: 1
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgb(217,225,226)"
            }
          },
          axisTick: {
            lineStyle: {
              width: 1
            }
          },
          axisLabel: {
            fontWeight: 500,
            color: "#5B6770",
            padding: [0, 5, 0, 0],
            fontSize: 12,
            margin: 8
          },
          yAxisScaleToX: 1000,
          nameTextStyle: {
            fontSize: 12
          }
        }
      ],
      series: (chartData.series ?? []).map((cd) => {
        return {
          name: cd.label,
          type: cd.label == "IWB" ? "scatter" : "line",
          animation: false,
          itemStyle: {
            color:
              cd.label === "Gas Injection Start"
                ? "rgb(205, 148, 141)"
                : "rgb(97, 137, 171)"
          },
          zlevel: 0,
          blendMode: "source-over",
          large: true,
          largeThreshold: 2000,
          step: false,
          showSymbol: false,
          symbolSize: 4,
          lineStyle: {
            color:
              cd.label === "Gas Injection Start"
                ? "rgb(205, 148, 141)"
                : "rgb(97, 137, 171)",
            width: cd.label === "ISOR" ? 0 : 3,
            type: cd.label === "History" ? "solid" : "dashed"
          },
          data: cd.x.map((v, i) => [cd.x[i], cd.y[i]])
        };
      })
    };
    echartRef.current.setOption(option);
  }, [chartData]);

  useResizeObserver(containerRef.current, () => {
    if (!echartRef.current) {
      return;
    }
    echartRef.current.resize();
  });
  useEffect(() => {
    if (!echartRef.current) {
      return;
    }
    echartRef.current.resize();
  }, [screenshot]);

  // sync chart ID with context
  useEffect(() => {
    chartDispatch({ type: "id", payload: id });
  }, [id, chartDispatch]);

  if (wellList?.length > 200) {
    return (
      <OverlayContainer>
        <OverlayMessages>
          <TooManyWellsMessage>
            Unable to generate RF vs PV: Too many wells.
          </TooManyWellsMessage>
        </OverlayMessages>
      </OverlayContainer>
    );
  }

  return (
    <Wrapper ref={containerRef} screenshot={screenshot?.visible}>
      <Toolbar>
        <Switch
          checked={publicDataOnly}
          onChange={setPublicDataOnly}
          checkedChildren="Public EBIP"
          unCheckedChildren="McDan EBIP"
        />

        <ScreenshotToggle widgetComponentType="sam-rf-pv" />
      </Toolbar>
      <Screenshot key="screenshot" containerId="sam-rf-pv-screenshot-overlay" />

      <ChartContainer
        ref={chartContainerRef}
        width={settings?.width}
        height={settings?.height}
        screenshotVisible={screenshot?.visible}
        className="ChartPlot">
        <ScreenshotContainer id={"sam-rf-pv-screenshot-overlay"} />
        <div id={id} className="four-products" />
        <BusyIndicator
          className={`${!isFetching ? "hidden" : ""}`}
          onContextMenu={(e) => e.preventDefault()}>
          <IconSpinner showBackground />
        </BusyIndicator>
      </ChartContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  background-color: ${(props) =>
    props.screenshot ? "rgba(46, 72, 88, 0.24)" : "inherit"};
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);

  .four-products {
    width: 100%;
    height: 100%;
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ChartContainer = styled.div`
  display: grid;
  width: ${(props) =>
    props.screenshotVisible && props.width ? props.width + "px" : "100%"};
  height: ${(props) =>
    props.screenshotVisible && props.height ? props.height + "px" : "100%"};
  background-color: ${(props) => (props.screenshotVisible ? "#fff" : "inherit")};
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
`;

const ScreenshotContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  pointer-events: none;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 1;
`;
const OverlayMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const TooManyWellsMessage = styled.div`
  z-index: inherit;
  color: var(--color-text);
  background-color: var(--orange);
  border-radius: 5px;
  padding: 2px 20px;
  margin: 0 20px;
`;
