import { IEditableProjectLayer } from "../../useEditableProjectLayerList";

export interface ICreateReorderedLayerListParams {
  layerList: IEditableProjectLayer[];
  layerId: string;
  order: number;
}

export function createReorderedLayerList({
  layerList,
  layerId,
  order
}: ICreateReorderedLayerListParams): IEditableProjectLayer[] {
  const orderedLayerList = JSON.parse(JSON.stringify(layerList));

  // Find the layer that needs to be repositioned
  const layerToReposition = orderedLayerList.find((layer) => layer.layerId === layerId);

  if (!layerToReposition) {
    // eslint-disable-next-line no-console
    console.error(`Layer with id ${layerId} not found`);
    return orderedLayerList;
  }

  // Remove the layer from its current position
  const newList = orderedLayerList.filter((layer) => layer.layerId !== layerId);

  // Insert the layer at the new position
  newList.splice(order, 0, layerToReposition);

  newList.forEach((layer, index) => {
    layer.order = index;

    if (layer.layerId === layerId) {
      layer.isMoving = true;
    }
  });

  return newList;
}
