import { memo } from "react";

function Projects({ size = 20 }) {
  return (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 19 19">
      <path
        d="M17.6619 4.57699C17.6812 4.58724 17.6999 4.59846 17.7181 4.61063C18.01 4.80647 18.0878 5.20184 17.892 5.49373L9.00002 10.2015L0.10804 5.49373C0.0958733 5.47558 0.0846484 5.45682 0.0744029 5.43753C-0.0903918 5.12707 0.027733 4.74179 0.338187 4.57699L8.69533 0.076816C8.88555 -0.0256053 9.11453 -0.0256053 9.30475 0.076816L17.6619 4.57699ZM17.7181 8.46791C17.6999 8.45574 17.6812 8.44452 17.6619 8.43427L16.3845 7.7464L9.30087 11.497C9.11269 11.5966 8.88736 11.5966 8.69917 11.497L1.61556 7.7464L0.338187 8.43427C0.027733 8.59907 -0.0903918 8.98435 0.0744029 9.29481C0.0846484 9.3141 0.0958733 9.33286 0.10804 9.35102L9.00002 14.0588L17.892 9.35102C18.0878 9.05912 18.01 8.66375 17.7181 8.46791ZM17.9222 12.5519C17.8627 12.4416 17.7722 12.3511 17.6619 12.2916L16.3846 11.6037L9.30091 15.3542C9.11272 15.4539 8.8874 15.4539 8.69921 15.3542L1.61556 11.6037L0.338187 12.2916C0.0257366 12.4602 -0.0908062 12.8502 0.0778306 13.1626C0.137383 13.273 0.227897 13.3635 0.338187 13.423L8.69533 17.9232C8.88555 18.0256 9.11453 18.0256 9.30475 17.9232L17.6619 13.423C17.9743 13.2544 18.0909 12.8644 17.9222 12.5519Z"
        transform="translate(0.44921875 0.4501953)"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
      />
    </svg>
  );
}
export default memo(Projects);
