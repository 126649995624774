import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setGlobalNormalizeBy,
  setUseNormalizeBy
} from "store/features/normalizeBy/normalizeBySlice";
import { RootState } from "store/rootReducer";

import { INormalizeBySetting } from "models";

import NormalizeBySettings from "./NormalizeBySettings";
import NormalizeByToggle from "./NormalizeByToggle";
import NormalizeByWrapper from "./NormalizeByWrapper";

type NormalizeT = {
  onChange?: (v: INormalizeBySetting) => void;
  onToggle?: (v: boolean) => void;
};

const Normalize: FC<NormalizeT> = ({ onChange = () => null, onToggle = () => null }) => {
  const dispatch = useDispatch();

  // state from store
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const normalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );

  // handlers
  function handleToggle(v: boolean) {
    dispatch(setUseNormalizeBy(v));
    onToggle(v);
  }

  function handleSettingsChange(v: INormalizeBySetting) {
    dispatch(setGlobalNormalizeBy(v));
    onChange(v);
  }

  return (
    <NormalizeByWrapper>
      <NormalizeByToggle checked={useNormalizeBy} onToggle={handleToggle} />
      {useNormalizeBy && (
        <>
          <NormalizeBySettings value={normalizeBy} onChange={handleSettingsChange} />
        </>
      )}
    </NormalizeByWrapper>
  );
};

export default Normalize;
