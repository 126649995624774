import { FunctionComponent } from "react";

import { GetApp } from "@material-ui/icons";
import { Button } from "antd";
import styled from "styled-components/macro";

import { IconSpinner } from "components/icons";

type ExportButtonT = {
  onExport: () => void;
  children: React.ReactChild;
  exporting: boolean;
};

export const ExportButton: FunctionComponent<ExportButtonT> = ({
  onExport,
  children,
  exporting
}) => {
  return (
    <Button
      type="primary"
      disabled={exporting}
      onClick={async () => {
        await onExport();
      }}>
      <Wrapper>
        {exporting ? <IconSpinner size={15} /> : <GetApp />}
        {children}
      </Wrapper>
    </Button>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  div {
    padding: 0;
  }
`;
