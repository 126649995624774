import { memo } from "react";

function CGR() {
  return (
    <svg width="30px" height="29px">
      <g id="IconsChart---PRPCGR">
        <path
          d="M10.2441 8.78083C10.0666 5.91552 5.04663 0 5.04663 0C3.91117 5.0971 -0.00453337 5.67788 3.93994e-06 9.03322C0.00283976 11.2973 1.02941 12.612 2.31006 13.3102C1.90454 11.9541 2.06902 10.3802 3.28672 9.2425C5.53496 7.14173 5.78224 5.72892 5.78224 5.72892C8.10818 9.85844 7.53761 12.4532 6.66134 13.7617C8.79502 13.1724 10.4069 11.404 10.2441 8.78083"
          transform="translate(19.021885 15.202407)"
          id="Fill-1-Copy-4"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="IconsChart---PRPOil-+-Cond">
          <path
            d="M8.8828 7.61658C8.8828 10.0695 6.89431 12.058 4.4414 12.058C1.98848 12.058 0 10.0695 0 7.61658C0 5.16366 4.4414 0 4.4414 0C4.4414 0 8.8828 5.16366 8.8828 7.61658ZM14.1738 5.88382C14.1738 6.67766 13.5303 7.3212 12.7364 7.3212C11.9426 7.3212 11.299 6.67766 11.299 5.88382C11.299 5.08998 12.7364 3.41886 12.7364 3.41886C12.7364 3.41886 14.1738 5.08998 14.1738 5.88382ZM12.2608 10.0539C12.2608 10.8478 11.6173 11.4913 10.8234 11.4913C10.0296 11.4913 9.38605 10.8478 9.38605 10.0539C9.38605 9.26009 10.8234 7.58897 10.8234 7.58897C10.8234 7.58897 12.2608 9.26009 12.2608 10.0539ZM16.1799 10.0539C16.1799 10.8478 15.5364 11.4913 14.7426 11.4913C13.9487 11.4913 13.3052 10.8478 13.3052 10.0539C13.3052 9.26009 14.7426 7.58897 14.7426 7.58897C14.7426 7.58897 16.1799 9.26009 16.1799 10.0539Z"
            transform="matrix(1 0 0 1 1.8189894E-12 0)"
            id="Combined-Shape"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
        <path
          d="M0 26.5666L27 0"
          transform="translate(1.5697479 1.5388219)"
          id="Path-8-Copy-9"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeWidth="2.025"
        />
      </g>
    </svg>
  );
}

export default memo(CGR);
