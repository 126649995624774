export function nullBinValues(bin) {
  if (!bin) return;

  if (bin.BinSize === "") {
    bin.BinSize = 0;
  }

  if (bin.MinSize === "") {
    bin.MinSize = null;
  }

  if (bin.MaxBins === "") {
    bin.MaxBins = null;
  }

  if (bin.GreaterThan === "") {
    bin.GreaterThan = null;
  }

  if (bin.LessThan === "") {
    bin.LessThan = null;
  }

  if (bin.MaxBinsSortOrder === "") {
    bin.MaxBinsSortOrder = "WellCount";
  }
}
