// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { mdiAlertCircleOutline } from "@mdi/js";
import { Input, Popover } from "antd";
import _debounce from "lodash/debounce";
import { setHasWellDataError, setSelectedTypeWell } from "store/features";
import styled from "styled-components/macro";

import { AdditionalTypeWellData, UserArpsItem, WellData } from "models/UserArpsModel";

import { useSelectedProjectPermissions } from "components/project/projects/hooks";

import { checkAdditionalNormalizationParameters } from "./utils/checkAdditionalNormalizationParameters";
import { AddNormalizationFieldButton } from "./widget/TypeWellEditor/tabs/AddNormalizationFieldButton";
import { TypeWellNormalizationParameter } from "./widget/TypeWellEditor/tabs/TypeWellNormalizationParameter";

export interface ITypeWellData {
  length?: number;
  stage?: number;
  spacing?: number;
  proppant?: number;
  intensity?: number;
}

interface EditTypeWellDataProps {
  selectedTypeWell: UserArpsItem;
  onChange?: (value?: WellData) => void;
}
function EditTypeWellData({ selectedTypeWell = null, onChange }: EditTypeWellDataProps) {
  const projectPermissions = useSelectedProjectPermissions();

  const [lengthError, setLengthError] = useState(null);
  const [spacingError, setSpacingError] = useState(null);
  const [intensityError, setIntensityError] = useState(null);
  const [additionalErrors, setAdditionalErrors] = useState([]);

  const dispatch = useDispatch();

  const wellData = selectedTypeWell?.wellData;

  const changeDebounceRef = useRef(
    _debounce((fn) => {
      fn && fn();
    }, 600)
  );

  useEffect(() => {
    if (wellData) {
      validateTypeWellData(wellData);
    }
  }, [wellData]);

  const setWellData = (data) => {
    const newWellData = {
      ...wellData,
      ...data
    };

    dispatch(
      setSelectedTypeWell({
        ...selectedTypeWell,
        wellData: newWellData
      })
    );
  };

  const validateDefaultTypeWellData = (length, spacing, intensity) => {
    setLengthError(null);
    setSpacingError(null);
    setIntensityError(null);
    let isValid = true;
    if (length && length < 0) {
      setLengthError("Value must be non-negative");
      isValid = false;
    }
    if (intensity && intensity < 0) {
      setIntensityError("Value must be non-negative");
      isValid = false;
    }
    if (spacing && spacing > length && length) {
      setSpacingError("Stage Spacing can't be greater than the Length");
      isValid = false;
    }
    if (spacing && spacing < 0) {
      setSpacingError("Value must be non-negative");
      isValid = false;
    }
    dispatch(setHasWellDataError(!isValid));
    return isValid;
  };

  const validateAdditionalData = (data: AdditionalTypeWellData[]) => {
    const { errors, isValid } = checkAdditionalNormalizationParameters(data);
    setAdditionalErrors(errors);
    dispatch(setHasWellDataError(!isValid));
    return isValid;
  };

  const validateTypeWellData = (wellData: WellData) => {
    return (
      validateDefaultTypeWellData(
        wellData.HzLength,
        wellData.StageSpacing,
        wellData.ProppantIntensity
      ) && validateAdditionalData(wellData.AdditionalData)
    );
  };

  const handleChangeLength = (value) => {
    const newLength = parseInt(value);
    const spacing = wellData?.StageSpacing;
    const intensity = wellData?.ProppantIntensity;

    const updatedWellData: WellData = {
      ...wellData,
      HzLength: newLength,
      Stage: spacing ? newLength / spacing : wellData?.Stage,
      Proppant: intensity ? intensity * newLength : wellData?.Proppant
    };
    setWellData(updatedWellData);

    // Update charts if values are correct.
    if (validateTypeWellData(updatedWellData)) {
      changeDebounceRef.current(() => onChange && onChange(updatedWellData));
    }
  };

  const handleChangeStageSpacing = (value) => {
    const newSpacing = parseInt(value);
    const length = wellData?.HzLength;

    const updatedWellData: WellData = {
      ...wellData,
      Stage: length / newSpacing,
      StageSpacing: newSpacing
    };
    setWellData(updatedWellData);

    // Update charts if values are correct.
    if (validateTypeWellData(updatedWellData)) {
      changeDebounceRef.current(() => onChange && onChange(updatedWellData));
    }
  };

  const handleChangeProppantIntensity = (value) => {
    const newIntensity = parseFloat(value);
    const length = wellData?.HzLength;

    const updatedWellData: WellData = {
      ...wellData,
      Proppant: newIntensity * length,
      ProppantIntensity: newIntensity
    };
    setWellData(updatedWellData);

    // Update charts if values are correct.
    if (validateTypeWellData(updatedWellData)) {
      changeDebounceRef.current(() => onChange && onChange(updatedWellData));
    }
  };

  const ErrorPopover = ({ errorMessage }) => {
    return (
      errorMessage && (
        <div style={{ width: "15px" }}>
          <Popover content={<div>{errorMessage}</div>}>
            <ErrorWrapper
              color={errorMessage.toLowerCase().includes("field") ? "orange" : "red"}>
              <Icon path={mdiAlertCircleOutline} />
            </ErrorWrapper>
          </Popover>
        </div>
      )
    );
  };

  return (
    <EditContainer>
      <Wrapper>
        <div>{"Hz Length (m)"}</div>
        <InputWrapper
          disabled={!projectPermissions.canEditTypeWells}
          name={"Hz Length(m)"}
          value={wellData?.HzLength ?? undefined}
          placeholder={"e.g. 2000"}
          type="number"
          onChange={(e) => handleChangeLength(e.target.value)}
        />
        <ErrorPopover errorMessage={lengthError} />
      </Wrapper>
      <Wrapper>
        <div>{"Stage Spacing (m)"}</div>
        <InputWrapper
          disabled={!projectPermissions.canEditTypeWells}
          name={"Stage Spacing(m)"}
          value={wellData?.StageSpacing ?? undefined}
          type="number"
          placeholder={"e.g. 500"}
          onChange={(e) => handleChangeStageSpacing(e.target.value)}
        />
        <ErrorPopover errorMessage={spacingError} />
      </Wrapper>
      <Wrapper>
        <div>{"Proppant Intensity (t/m)"}</div>
        <InputWrapper
          disabled={!projectPermissions.canEditTypeWells}
          name={"Proppant Intensity(t/m)"}
          value={wellData?.ProppantIntensity ?? undefined}
          type="number"
          placeholder={"e.g. 2.5"}
          onChange={(e) => handleChangeProppantIntensity(e.target.value)}
        />
      </Wrapper>
      <ErrorPopover errorMessage={intensityError} />
      {wellData?.AdditionalData?.map((_, index) => (
        <Wrapper key={index}>
          <TypeWellNormalizationParameter
            index={index}
            validateTypeWellData={validateTypeWellData}
            onChange={onChange}
          />
          {additionalErrors[index] ? (
            <ErrorPopover errorMessage={additionalErrors[index]} />
          ) : (
            <></>
          )}
        </Wrapper>
      ))}
      <Wrapper>
        <AddNormalizationFieldButton
          validateTypeWellData={validateTypeWellData}
          onChange={onChange}
        />
      </Wrapper>
    </EditContainer>
  );
}

export default memo(EditTypeWellData);

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px;
  .update-tw-data-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5px;
    .primary {
      border-radius: 10px;
    }
  }
`;
const InputWrapper = styled(Input)`
  background-color: #ebf8ef;
  font-size: 1.3rem;
`;

const ErrorWrapper = styled.div`
  display: flex;
  color: ${(props) => props.color};
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 120px 30px auto;
  position: relative;
  align-items: center;
  gap: 5px;
  width: 100%;

  .error-message {
    color: red;
    padding-left: 10px;
    font-size: 1.2rem;
    margin-top: 5px;
  }
  .ant-input {
    padding-right: 35px !important;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
