export interface DefaultMapSettings {
  id?: string;
  latitude: number;
  longitude: number;
  zoom?: number;
}

export const DEFAULT_LATITUDE = 55.869602;
export const DEFAULT_LONGITUDE = -120.608871;
export const DEFAULT_ZOOM = 5;
