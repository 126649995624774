import React from "react";

import styled from "styled-components/macro";

import InvertCheckboxIcon from "components/icons/InvertCheckbox";

import { StyledCheckBoxEmptyIcon, StyledCheckBoxIcon } from "../shared";

const operations = [
  {
    label: "Check all",
    action: "checkAll",
    icon: <StyledCheckBoxIcon />
  },
  {
    label: "Uncheck all",
    action: "uncheckAll",
    icon: <StyledCheckBoxEmptyIcon />
  },
  {
    label: "Invert Checks",
    action: "invertChecks",
    icon: <InvertCheckboxIcon />
  }
];

const BatchOperations = ({ onSelect }) => {
  return (
    <Wrapper>
      {operations.map(({ action, icon, label }) => (
        <Item key={label} onClick={() => onSelect(action)}>
          {icon}
          {label}
        </Item>
      ))}
    </Wrapper>
  );
};

export default BatchOperations;

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  font-weight: var(--fontWeightMedium);
  padding: var(--space-2);
`;
const Item = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 8px;
  color: var(--color-text-50);
  padding: var(--space-2) var(--space-3);
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--color-text);
  }
`;
