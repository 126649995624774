// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiCog } from "@mdi/js";
import { Button, Popover, Tooltip } from "antd";

import {
  LayerSettingsRowItem,
  LayerSettingsWrapper,
  StyledDivider,
  ZoomSlider
} from "..";

interface ShapefileLayerSettingsProps {
  shapefileId: string;
}
export const ShapefileLayerSettings = ({
  shapefileId
}: ShapefileLayerSettingsProps): JSX.Element => {
  const settings = (
    <LayerSettingsWrapper direction="vertical" split={<StyledDivider />}>
      <LayerSettingsRowItem isFirst isSlider>
        <ZoomSlider
          defaultMax={22}
          defaultMin={0}
          layerName={shapefileId}
          isShapefileLayer={true}
        />
      </LayerSettingsRowItem>
    </LayerSettingsWrapper>
  );

  return (
    <Tooltip title="Settings">
      <Popover
        content={settings}
        placement="bottomLeft"
        trigger="click"
        arrowPointAtCenter={true}>
        <Button type="text" aria-label="Edit">
          <Icon path={mdiCog} size={1} />
        </Button>
      </Popover>
    </Tooltip>
  );
};
