import { useEffect, useRef, useState } from "react";

import "./BaseSlideToggle.scss";

export default function BaseSlideToggle({
  checked = true,
  classic = false,
  disabled = false,
  onChange = null,
  ...props
}) {
  const checkboxRef = useRef(null);
  const [isChecked, setChecked] = useState(checked);

  useEffect(() => {
    if (checked !== isChecked) {
      setChecked(checked);
    }
  }, [isChecked, checked]);

  function toggleValue(val) {
    const value = val.target.checked;
    setChecked(value);
    onChange && onChange(value);
  }

  return (
    <div
      {...props}
      role="switch"
      tabIndex={0}
      aria-checked={isChecked}
      className={`BaseSlideToggle ${isChecked ? "checked" : ""}  ${
        classic ? "classic" : ""
      } ${disabled ? "disabled" : ""}`}>
      <span className="slider-ring"></span>

      <input
        ref={checkboxRef}
        type="checkbox"
        className="checkbox"
        tabIndex={-1}
        checked={isChecked}
        disabled={disabled}
        onChange={toggleValue}
      />
    </div>
  );
}
