export function getTypeWellNormalizationValue(normalize, wellData) {
  let normalizeValue = 0.0;
  if (normalize.per === 0) return normalizeValue;
  switch (normalize.field) {
    case "Well_Design.HzLength_m":
    case "Well_Design.HzLengthSimple_m":
    case "Well_Design.HzLengthDrilled_m":
    case "Well_Design.HzLengthCompleted_m":
      normalizeValue =
        wellData.length > 0 ? 1.0 / (wellData.length / normalize.per) : 0.0;
      break;
    case "Frac_Data.StagesActual":
      normalizeValue = wellData.stage > 0 ? 1.0 / (wellData.stage / normalize.per) : 0.0;
      break;
    case "Frac_Data.ProppantTotal_t":
      normalizeValue =
        wellData.proppant > 0 ? 1.0 / (wellData.proppant / normalize.per) : 0.0;
      break;
    case "Frac_Data.StageSpacing_m":
      normalizeValue =
        wellData.stage_spacing > 0 ? 1.0 / (wellData.stage_spacing / normalize.per) : 0.0;
      break;
    case "Frac_Data.ProppantIntensity_tm":
      normalizeValue =
        wellData.proppant_intensity > 0
          ? 1.0 / (wellData.proppant_intensity / normalize.per)
          : 0.0;
      break;
    default:
      wellData.additional_data?.forEach((ad) => {
        if (ad.field === normalize.field) {
          normalizeValue = ad.value > 0 ? 1.0 / (ad.value / normalize.per) : 0.0;
        }
      });
      break;
  }
  return normalizeValue;
}
