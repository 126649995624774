import { useEffect, useState } from "react";

const useMouseover = (ref) => {
  const [isMouseover, setIsMouseover] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsMouseover(true);
    };

    const handleMouseLeave = () => {
      setIsMouseover(false);
    };

    const element = ref.current;

    if (element) {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [ref]);

  return isMouseover;
};

export default useMouseover;
