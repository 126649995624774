import { Divider, Input, Space } from "antd";
import styled from "styled-components";

interface LayerListLayerWrapperProps {
  useBackgroundColor: boolean;
}

// TODO BF: Need to organize this file better. It's a mess. Sorry.

const FlexItem = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

// going to be removed, replaced by <ShapefileAction>
const ProjectActionButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled] > svg {
    color: lightgray;
  }
`;

const LayerActionsWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding-right: 4px;
  padding-left: 6px;

  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 24px;
  }
  .ant-btn:hover {
    color: var(--color-primary);
  }
  .ant-btn.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  .ant-btn.active:hover {
    background-color: #fff;
    color: var(--color-primary);
  }
`;

const LayerDragWrapper = styled.div`
  padding-left: 6px;
  cursor: move;
  display: flex;
  align-items: center;
  color: #a2aaad;
  opacity: 0;
`;

const LayerListLayerWrapper = styled.div<LayerListLayerWrapperProps>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ useBackgroundColor }) =>
    useBackgroundColor ? "rgba(var(--color-primary-rgb), 0.4)" : "transparent"};
  padding-top: 2px;

  align-items: center;
  width: 100%;
  overflow: hidden;

  ${LayerActionsWrapper} {
    opacity: 0;
  }

  &:hover {
    background-color: #eee;

    ${LayerDragWrapper} {
      opacity: 1;
    }

    ${LayerActionsWrapper} {
      opacity: 1;
    }
  }
`;

const NodeTitle = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  align-items: center;
  justify-content: space-between;

  ${ProjectActionButton} {
    svg {
      opacity: 0;
    }
  }

  ${LayerActionsWrapper} {
    opacity: 0;
  }

  border: ${(props) => (props.canDrop ? "1px dashed green" : "none")};

  &:hover,
  &.visible-always,
  &.in-edit-mode {
    svg {
      opacity: 1;
    }

    ${LayerActionsWrapper} {
      opacity: 1;
    }
  }
`;

const LeafTitle = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  align-items: center;
  justify-content: space-between;

  .drag-container {
    position: absolute;
    opacity: 0.5;
    left: -46px;
    top: 4px;
    color: #a2aaad;
    cursor: move;
  }

  ${ProjectActionButton} {
    svg {
      opacity: 0;
    }
  }

  &:hover,
  &.in-edit-mode {
    svg {
      opacity: 1;
    }
  }

  input[type="text"] {
    height: 24px;
    outline: none;
  }

  input[type="text"]:focus {
    outline: none 0;
  }

  label {
    margin-left: 5px !important;
  }
`;

const ProjectFolderActionButton = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-${(p) => (p.selected ? "text" : "text-50")});
  background: ${(p) => (p.selected ? "var(--color-text-06)" : "transparent")};
  padding: var(--space-2) var(--space-5);
  user-select: none;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
    background-color: var(--color-text-06);
  }
`;

const ShapefileAction = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &[disabled] {
    color: lightgray;
  }

  &:hover {
    color: ${(props) =>
      props.disabled
        ? "lightgray"
        : props.danger
        ? "var(--color-danger)"
        : "var(--color-primary)"};
  }
`;

const LayerSettingsWrapper = styled(Space)`
  --tooltip: var(--color-text);
  width: 260px;
  display: flex;
`;

const LayerSettingsRowItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${({ isFirst }) => (isFirst ? "0px" : "4px")};
  padding-bottom: ${({ isLast, isSlider }) =>
    isSlider ? "6px" : isLast ? "0px" : "4px"};
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const RightInputNumber = styled(Input)`
  width: 130px;
  margin-left: auto;
`;

const ActionListWrapper = styled.div`
  padding: 6px;
`;

export {
  FlexItem,
  LeafTitle,
  NodeTitle,
  ProjectActionButton,
  ProjectFolderActionButton,
  LayerActionsWrapper,
  LayerDragWrapper,
  LayerListLayerWrapper,
  ShapefileAction,
  LayerSettingsWrapper,
  LayerSettingsRowItem,
  StyledDivider,
  RightInputNumber,
  ActionListWrapper
};
