import { useEffect, useState } from "react";

import { IGroupByListColumn } from "models";

/**
 * @typedef {Object} FieldMetaT
 * @property {boolean} isUserDefinedColumn - field is a user defined column
 * @property {boolean} isOrgDefinedColumn - field is an org defined column
 */

/**
 * Returns a meta object for the given field.
 * @return {FieldMetaT}
 */
const useField = (field: IGroupByListColumn) => {
  const [value, setValue] = useState({
    isUserDefinedColumn: false,
    isOrgDefinedColumn: false
  });

  useEffect(() => {
    const { property } = field;

    const isUserDefinedColumn = property?.indexOf("My_Focus_Fields") >= 0;
    const isOrgDefinedColumn = property?.indexOf("Org_Focus_Fields") >= 0;

    setValue(() => ({
      isUserDefinedColumn,
      isOrgDefinedColumn
    }));
  }, [field]);

  return value;
};

export default useField;
