import axios from "axios";

import { getUsersInOrg } from "./users";

const adminServiceRoot = `${process.env.REACT_APP_USER_SERVICE}/Admin`;

export async function updateUsersMfa(payload) {
  try {
    const response = await axios.post(`${adminServiceRoot}/mfa`, payload.data);
    if (response.status != 200) {
      return null;
    }
    const organization = await getUsersInOrg(payload.organizationId);
    return organization;
  } catch (error) {
    return null;
  }
}

export async function updateUsersRole(payload) {
  try {
    const response = await axios.post(`${adminServiceRoot}/role`, payload.data);
    if (response.status != 200) {
      return null;
    }
    const organization = await getUsersInOrg(payload.organizationId);
    return organization;
  } catch (error) {
    return null;
  }
}

export async function deleteUsers(payload) {
  try {
    const response = await axios.delete(`${adminServiceRoot}/users`, {
      data: payload.data
    });
    if (response.status != 200) {
      return null;
    }
    const organization = await getUsersInOrg(payload.organizationId);
    return organization;
  } catch (error) {
    return null;
  }
}
