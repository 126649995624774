import { useQuery } from "react-query";

import { getPresets } from "api/multiProduct";

const useChartPresetFetcher = () => {
  const {
    data: presets,
    isFetching: isFetchingPresets,
    refetch: refetchPresets
  } = useQuery(
    "chartRetrieval",
    async () => {
      const presets = await getPresets();
      return presets;
    },
    {
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      },
      enabled: false
    }
  );

  return { isFetchingPresets, presets, refetchPresets };
};

export default useChartPresetFetcher;
