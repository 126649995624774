import { useCallback, useEffect } from "react";

import { getOptionsWithTooltips } from "components/multiphase-chart/util";

import getOptionsUnhighlighted from "./util/getOptionsUnhighlighted";

/**
 * Handle on lineblur event to unhighlight series.
 */
const useHoverOffSeries = (
  echarts,
  options,
  currentlyHighlightedSeries,
  setOptionsToChart
) => {
  const onLineBlur = useCallback(() => {
    currentlyHighlightedSeries.current = "";
    const unhighlightedOptions = getOptionsUnhighlighted(options);
    const optionsWithTooltips = getOptionsWithTooltips(unhighlightedOptions, "");

    setOptionsToChart(optionsWithTooltips);
  }, [currentlyHighlightedSeries, options, setOptionsToChart]);

  useEffect(() => {
    if (!echarts) return;

    echarts.on("lineBlur", onLineBlur);

    return () => {
      echarts.off("lineBlur", onLineBlur);
    };
  }, [echarts, onLineBlur]);
};

export default useHoverOffSeries;
