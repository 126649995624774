import { memo } from "react";

function SlopeLines() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <path
        d="M4 4L4 20L22 20"
        id="LShapeLine"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <g>
        <path
          d="M8.721425592899323,6.454280853271484 C8.721425592899323,6.661380853271485 8.553525592899323,6.829280853271484 8.346425592899323,6.829280853271484 C8.139325592899322,6.829280853271484 7.9714255928993225,6.661380853271485 7.9714255928993225,6.454280853271484 C7.9714255928993225,6.247180853271484 8.139325592899322,6.079280853271484 8.346425592899323,6.079280853271484 C8.553525592899323,6.079280853271484 8.721425592899323,6.247180853271484 8.721425592899323,6.454280853271484 z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.25"
          id="svg_9"
        />
        <path
          d="M11.799488499760628,18.228984169662 C11.799488499760628,18.436094169662 11.631588499760628,18.603984169662002 11.424488499760628,18.603984169662002 C11.217388499760625,18.603984169662002 11.049488499760628,18.436094169662 11.049488499760628,18.228984169662 C11.049488499760628,18.021874169662 11.217388499760625,17.853984169662 11.424488499760628,17.853984169662 C11.631588499760628,17.853984169662 11.799488499760628,18.021874169662 11.799488499760628,18.228984169662 z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M5.841725841164589,12.61921340227127 C5.841725841164589,12.826313402271271 5.673835841164589,12.99421340227127 5.466725841164589,12.99421340227127 C5.259615841164589,12.99421340227127 5.091725841164589,12.826313402271271 5.091725841164589,12.61921340227127 C5.091725841164589,12.41211340227127 5.259615841164589,12.24421340227127 5.466725841164589,12.24421340227127 C5.673835841164589,12.24421340227127 5.841725841164589,12.41211340227127 5.841725841164589,12.61921340227127 z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M13.542074084281921,10.989204920828342 C13.542074084281921,11.196314920828343 13.374174084281922,11.364204920828342 13.167074084281921,11.364204920828342 C12.95997408428192,11.364204920828342 12.792074084281921,11.196314920828343 12.792074084281921,10.989204920828342 C12.792074084281921,10.782094920828342 12.95997408428192,10.614204920828342 13.167074084281921,10.614204920828342 C13.374174084281922,10.614204920828342 13.542074084281921,10.782094920828342 13.542074084281921,10.989204920828342 z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M17.65298569202423,14.543963104486465 C17.65298569202423,14.751063104486466 17.48508569202423,14.918963104486465 17.27798569202423,14.918963104486465 C17.07088569202423,14.918963104486465 16.90298569202423,14.751063104486466 16.90298569202423,14.543963104486465 C16.90298569202423,14.336863104486465 17.07088569202423,14.168963104486465 17.27798569202423,14.168963104486465 C17.48508569202423,14.168963104486465 17.65298569202423,14.336863104486465 17.65298569202423,14.543963104486465 z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2.25"
        />
        <path
          d="M-0.0458279258148975,11.957140079295309 H20.109482704983442 "
          stroke="currentColor"
          strokeWidth="1.75"
          transform="rotate(47.37417984008789 10.031827926635748,11.957140922546383) "
        />
      </g>
    </svg>
  );
}

export default memo(SlopeLines);
