import { useMutation } from "react-query";

import axios from "axios";

const chartServiceApiRoot = process.env.REACT_APP_CHART_SERVICE;

export default function useExportChartDataMutation(onSuccess, onError) {
  return useMutation(
    async (request) => {
      const response = await axios.post(
        `${chartServiceApiRoot}/chart/export?exportformat=CSV`,
        request,
        {
          responseType: "blob"
        }
      );
      return response;
    },
    {
      onSuccess: onSuccess,
      onError: onError
    }
  );
}
