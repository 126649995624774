import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Portal } from "@material-ui/core";

import BaseIconToggle from "components/base/BaseIconToggle";
import BaseTooltip from "components/base/BaseTooltip";
import MapScreenshot from "components/icons/MapScreenshot";
import { useScreenshotContext, useScreenshotDispatch } from "components/screenshot/hooks";

import TownshipRangeGrid from "../TownshipRangeGrid";
import { useMapContext } from "../hooks/useMapContext";
import { useMapDispatch } from "../hooks/useMapDispatch";
import { BubbleVisLegend } from "../toolbar/BubbleVisLegend";
import "./Screenshot.scss";
import { IpdbLegendWrapper } from "./legend/IpdbLegendWrapper";
import LegendWrapper from "./legend/LegendWrapper";

export default function Screenshot() {
  const { mapbox } = useMapContext();

  const mapDispatch = useMapDispatch();
  const screenshotDispatch = useScreenshotDispatch();

  const [screenshotOverlayVisible, setScreenshotOverlayVisible] = useState(false);
  const copyOverlayRef = useRef(null);

  const { settings, widget } = useScreenshotContext();

  useEffect(() => {
    const screenshotActive = widget?.widgetId === "mb";

    setScreenshotOverlayVisible(screenshotActive);
    mapDispatch({
      payload: {
        isFullscreen: screenshotActive
      }
    });
  }, [mapDispatch, settings, widget]);

  const toggleScreenshot = useCallback(() => {
    setScreenshotOverlayVisible(!screenshotOverlayVisible);
  }, [screenshotOverlayVisible]);

  useHotkeys(
    "esc",
    () => {
      screenshotDispatch({
        payload: {
          widget: null,
          widgetState: null
        }
      });
    },
    [mapbox]
  );

  useEffect(() => {
    mapDispatch({
      payload: {
        isFullscreen: screenshotOverlayVisible
      }
    });

    const widget = screenshotOverlayVisible
      ? {
          widgetId: "mb",
          widgetComponentType: "map"
        }
      : null;

    screenshotDispatch({
      payload: {
        widget
      }
    });
  }, [mapDispatch, screenshotDispatch, screenshotOverlayVisible]);

  useEffect(() => {
    if (screenshotOverlayVisible) {
      screenshotDispatch({
        payload: {
          widgetState: {
            mapbox,
            copyOverlay: copyOverlayRef.current
          }
        }
      });
    }
  }, [screenshotDispatch, screenshotOverlayVisible, mapbox]);

  // render
  if (!mapbox) return null;

  const screenshotBounds = {
    width: (settings?.width || 1152) * (settings?.mapScreenshotScale ?? 1),
    height: (settings?.height || 681) * (settings?.mapScreenshotScale ?? 1)
  };

  return (
    <div className="Screenshot">
      <Portal container={document.getElementById("map-screenshot-overlay")}>
        {screenshotOverlayVisible && (
          <div className="copy-overlay-container ">
            <div
              className="copy-overlay"
              ref={copyOverlayRef}
              style={{
                width: `${screenshotBounds.width}px`,
                height: `${screenshotBounds.height}px`
              }}>
              <div className="buttons" />
              <LegendWrapper
                width={250}
                parentDimensions={screenshotBounds}
                isScreenshotOverlayVisible={screenshotOverlayVisible}
              />
              <IpdbLegendWrapper isScreenshotOverlayVisible={screenshotOverlayVisible} />
              {settings?.showTownshipBorderInScreenshot && (
                <TownshipRangeGrid containerSize={screenshotBounds}></TownshipRangeGrid>
              )}
              <BubbleVisLegend
                screenshotLegend={true}
                parentDimensions={{
                  width: screenshotBounds.width,
                  height: screenshotBounds.height
                }}
              />
            </div>
          </div>
        )}
      </Portal>

      {/*
       "Key" will help react to re-render the button and correct the state of tooltip
      Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
      */}
      <BaseTooltip
        key={"MapScreenshot" + screenshotOverlayVisible}
        text="Toggle Screenshot">
        <BaseIconToggle
          squareIcon
          className="item"
          value={screenshotOverlayVisible}
          size={30}
          toggle={toggleScreenshot}>
          <MapScreenshot style={{ fontSize: "13px" }} />
        </BaseIconToggle>
      </BaseTooltip>
    </div>
  );
}
