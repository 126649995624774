import { ReactNode, memo } from "react";

import { Popover } from "antd";
import styled from "styled-components";

type PositionT = {
  x: number;
  y: number;
};

type ContextMenuT = {
  allow?: boolean;
  content: ReactNode;
  position?: PositionT | null;
  visible?: boolean | null;
  className?: string;
};

function ContextMenu({
  allow,
  content,
  position = null,
  visible = null,
  className
}: ContextMenuT) {
  if (!allow || !position) return null;

  return (
    <Popover
      content={<ContentWrapper>{content}</ContentWrapper>}
      placement="right"
      className={className}
      trigger=""
      open={visible && allow}>
      {/* invisible trigger to position contextmenu */}
      <Trigger x={position.x} y={position.y} />
    </Popover>
  );
}

export default memo(ContextMenu);

const ContentWrapper = styled.div`
  display: grid;
  margin: -12px -16px;
`;

const Trigger = styled.div`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  width: 20px;
  height: 20px;
  background-color: red;
  transform: translateX(-50%);
  visibility: hidden;
  z-index: 100;
`;
