// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";

import { mdiCancel, mdiContentSave, mdiDelete, mdiPencil } from "@mdi/js";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Table
} from "antd";
import moment from "moment";
import styled from "styled-components";

import { AxiosErrorWithData } from "../../utils/errorUtil";
import { useProjectShapefileFeaturesQuery } from "../project/shapefiles/queries";
import { usePadScenario } from "./contexts/usePadScenario";
import useDeletePadLayer from "./hooks/useDeletePadLayer";
import useUpdatePadLayer from "./hooks/useUpdatePadLayer";
import { PadLayerConfiguration } from "./models/scenario";

interface ScenarioConfigurationTableProps {
  editId: string;
  containerHeight: number;
}

export function ScenarioConfigurationTable({
  editId,
  containerHeight
}: ScenarioConfigurationTableProps) {
  const { state } = usePadScenario();
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const containerRef = useRef(null);
  const isEditing = (record: PadLayerConfiguration) => record.id === editingKey;
  const edit = (record: Partial<PadLayerConfiguration>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };
  const { mutateAsync: updatePadLayer } = useUpdatePadLayer();
  const { mutateAsync: deletePadLayer } = useDeletePadLayer();
  const { data: shapefileData } = useProjectShapefileFeaturesQuery({
    id: state.scenario?.shapefileId,
    isProjectLinkedShapefile: false
  });

  const shapefileProperties = useMemo(() => {
    let keys = [];
    const values = {};
    if (shapefileData?.geoms && shapefileData?.geoms.length > 0) {
      keys = Object.keys(shapefileData.geoms[0].Properties);
      for (const geom of shapefileData.geoms) {
        for (const key of keys) {
          if (!values[key]) {
            values[key] = [];
          }
          if (!values[key].includes(geom.Properties[key])) {
            values[key].push(geom.Properties[key]);
          }
        }
      }
    }
    return { keys, values };
  }, [shapefileData]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (id ? id.substring(0, 8) : "")
    },
    {
      title: "Polygon",
      dataIndex: "polygon",
      key: "polygon",
      inputType: "polygon",
      editable: true,
      render: (_: string, record: PadLayerConfiguration) => {
        return isEditing(record) ? (
          <></>
        ) : (
          <>
            {record.polygon ? `${record.polygon?.field}=${record.polygon?.value}` : "All"}
          </>
        );
      }
    },
    {
      title: "Reference Zone",
      dataIndex: "referenceZone",
      key: "referenceZone",
      inputType: "text",
      focusOnEdit: true,
      editable: true
    },
    {
      title: "On Prod Date",
      dataIndex: "onProdDate",
      key: "onProdDate",
      inputType: "date",
      editable: true,
      getValueProps: (value: string | moment.Moment) => {
        if (!value) {
          return {
            value: null
          };
        }
        if (value instanceof moment) {
          return {
            value: value
          };
        }
        const dt = moment(value);
        return {
          value: dt
        };
      },
      render: (date: string) => date.split("T")[0]
    },
    {
      title: "Azimuth",
      dataIndex: "azimuth",
      key: "azimuth",
      editable: true
    },

    {
      title: "Hz Length",
      dataIndex: "hzLength",
      key: "hzLength",
      editable: true
    },
    {
      title: "Min Heel Offset",
      dataIndex: "minHeelOffset",
      key: "minHeelOffset",
      editable: true
    },
    {
      title: "Min Toe Offset",
      dataIndex: "minToeOffset",
      key: "minToeOffset",
      editable: true
    },
    {
      title: "Spacing",
      dataIndex: "horizontalSpacingBetweenSticks",
      key: "horizontalSpacingBetweenSticks",
      editable: true
    },

    {
      title: "Left Horizontal Offset",
      dataIndex: "leftHorizontalOffset",
      key: "leftHorizontalOffset",
      editable: true
    },
    {
      title: "Right Horizontal Offset",
      dataIndex: "rightHorizontalOffset",
      key: "rightHorizontalOffset",
      editable: true
    },
    {
      title: "Vertical Offset",
      dataIndex: "verticalOffset",
      key: "verticalOffset",
      inputType: "text",
      editable: true
    },
    {
      title: "Action",
      key: "action",
      fixed: "right" as "left" | "right" | boolean,
      width: 100,
      render: (_: string, record: PadLayerConfiguration) => {
        if (isEditing(record)) {
          return (
            <ActionWrapper>
              <Space size="middle">
                <IconButton
                  type="text"
                  shape={"circle"}
                  onClick={() => savePadLayerConfiguration(record.id)}
                  icon={<Icon path={mdiContentSave} size={1.2} />}></IconButton>
                <IconButton
                  type={"text"}
                  shape={"circle"}
                  onClick={() => setEditingKey("")}
                  icon={<Icon path={mdiCancel} size={1.2} />}></IconButton>
              </Space>
            </ActionWrapper>
          );
        } else {
          return (
            <ActionWrapper>
              <Space size="middle">
                <IconButton
                  type="text"
                  shape={"circle"}
                  onClick={() => edit(record)}
                  icon={<Icon path={mdiPencil} size={1.2} />}></IconButton>
                <Popconfirm
                  title={"Confirm delete?"}
                  onConfirm={() => handleDelete(record.id)}
                  okButtonProps={{ danger: true }}
                  okText={"Delete"}>
                  <IconButton
                    type={"text"}
                    shape={"circle"}
                    icon={<Icon path={mdiDelete} size={1.2} />}
                  />
                </Popconfirm>
              </Space>
            </ActionWrapper>
          );
        }
      }
    }
  ];

  const handleDelete = async (id: string) => {
    try {
      await deletePadLayer({ scenarioId: state.scenario?._id?.$oid, padLayerId: id });
      setEditingKey("");
    } catch (err) {
      const axiosError = err as AxiosErrorWithData;
      const errormsg = axiosError?.response?.data?.error ?? "An error occurred";
      toast.error(errormsg, {
        containerId: "scenario-container"
      });
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: PadLayerConfiguration) => ({
        record,
        focusOnEdit: col.focusOnEdit,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        fixed: col.fixed,
        getValueProps: col.getValueProps,
        editing: isEditing(record)
      })
    };
  });
  const savePadLayerConfiguration = async (id: string) => {
    try {
      const row = {
        ...(await form.validateFields()),
        layerNumber: 0,
        id,
        includeExistingWells: false
      } as PadLayerConfiguration;
      await updatePadLayer({
        scenarioId: state.scenario?._id?.$oid,
        padLayerId: id,
        layer: row
      });
      setEditingKey("");
    } catch (err) {
      const axiosError = err as AxiosErrorWithData;
      const errormsg = axiosError?.response?.data?.error ?? "An error occurred";
      toast.error(errormsg, {
        containerId: "scenario-container"
      });
    }
  };

  const EditableCell: FC<EditableCellProps> = ({
    editing,
    dataIndex,
    inputType,
    record,
    focusOnEdit,
    children,
    getValueProps,

    ...restProps
  }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (editing && inputRef.current && focusOnEdit) {
        inputRef.current.focus();
      }
    }, [editing]);
    let inputNode: ReactNode;
    switch (inputType) {
      case "number":
        inputNode = (
          <InputNumber onPressEnter={() => savePadLayerConfiguration(record.id)} />
        );
        break;
      case "date": {
        inputNode = <DatePicker format={"YYYY-MM-DD"} />;
        break;
      }
      case "polygon": {
        inputNode = (
          <StyledSelect
            showSearch
            onChange={(value: string) => {
              if (value.includes("|~|")) {
                form.setFieldsValue({
                  polygon: {
                    field: value.split("|~|")[0],
                    value: value.split("|~|")[1]
                  }
                });
              } else {
                form.setFieldsValue({
                  polygon: null
                });
              }
            }}
            options={shapefileProperties.keys.map((key) => {
              return {
                label: key,
                options: shapefileProperties.values[key].map((value) => {
                  return {
                    label: value,
                    value: key + "|~|" + value
                  };
                })
              };
            })}
          />
        );
        break;
      }
      case "text": {
        inputNode = (
          <Input
            ref={inputRef}
            onPressEnter={() => savePadLayerConfiguration(record.id)}
          />
        );
        break;
      }
      default:
        inputNode = (
          <InputNumber
            type={"number"}
            onPressEnter={() => savePadLayerConfiguration(record.id)}
          />
        );
        break;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            valuePropName={inputType === "date" ? "date" : undefined}
            style={{ margin: 0 }}
            getValueProps={getValueProps}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  useEffect(() => {
    if (!state.scenario?.layerPlacements) {
      return;
    }
    const found = state.scenario.layerPlacements.find((l) => l.id === editId);
    if (found) {
      edit(found);
    }
  }, [editId]);

  return (
    <RootContainer ref={containerRef}>
      <Form form={form} component={false} size={"middle"}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          dataSource={state.scenario.layerPlacements}
          size={"small"}
          scroll={{ y: containerHeight - 130 }}
          bordered
          columns={mergedColumns}
          pagination={false}
        />
      </Form>
    </RootContainer>
  );
}

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  focusOnEdit?: boolean;
  inputType: "number" | "text" | "date" | "polygon";
  record: PadLayerConfiguration;
  index: number;
  children: ReactNode;
  getValueProps: (value) => Record<string, unknown>;
  onPressEnter: (record: PadLayerConfiguration) => Promise<void>;
  fixed: "left" | "right" | boolean;
}

const RootContainer = styled.div`
  overflow-y: auto;

  .ant-table {
    font-size: 1.3rem;
    min-height: 100px;
  }

  .ant-table-cell {
    padding: 5px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a2aaad; // var(--color-btn);

  &:hover {
    color: var(--color-primary);
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
