// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";

import { mdiClose, mdiContentSavePlus, mdiDelete } from "@mdi/js";
import { Button, Input, Popconfirm, Select, Tooltip } from "antd";
import styled from "styled-components";

import { useMapContext } from "../hooks/useMapContext";
import {
  deleteMapLocation,
  getSavedMapLocations,
  saveMapLocation
} from "./api/savedMapLocation";

const { Option } = Select;

const SYSTEM_DEFAULT_LOCATION = {
  id: "",
  name: "System Default",
  centerLatitude: 55.869602,
  centerLongitude: -120.608871,
  zoomLevel: 5.0
};

export default function SavedMapLocationSelector() {
  const map = useMapContext();

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isAddingLocation, setIsAddingLocation] = useState<boolean>(false);
  const [newLocationName, setNewLocationName] = useState<string>("");

  const [saveError, setSaveError] = useState<string>("");

  const savedLocationQuery = useQuery("savedMapLocations", getSavedMapLocations);

  const saveLocation = useMutation(saveMapLocation, {
    onSuccess: () => {
      setIsAddingLocation(false);
      savedLocationQuery.refetch();
    }
  });

  const onSaveToggle = () => {
    if (isAddingLocation) {
      setNewLocationName("");
      setSaveError("");
    }
    setIsAddingLocation(!isAddingLocation);
  };

  const onSaveLocation = () => {
    if (savedLocationQuery.data?.find((location) => location.name === newLocationName)) {
      setSaveError("Duplicate name detected");
      return;
    } else if (newLocationName.toLocaleLowerCase() === "system default") {
      setSaveError("Cannot use that name");
      return;
    }
    saveLocation.mutate({
      name: newLocationName,
      centerLatitude: map.bounds.getCenter().lat,
      centerLongitude: map.bounds.getCenter().lng,
      zoomLevel: map.mapbox.getZoom()
    });
    setNewLocationName("");
    setSaveError("");
  };

  const deleteLocation = useMutation(deleteMapLocation, {
    onSuccess: () => {
      savedLocationQuery.refetch();
    }
  });

  const onSelectLocation = (locationId) => {
    let location;
    if (locationId === "") {
      location = SYSTEM_DEFAULT_LOCATION;
    } else {
      location = savedLocationQuery.data?.find((location) => location.id === locationId);
    }

    setSelectedLocation(locationId);
    map.mapbox.flyTo({
      center: [location.centerLongitude, location.centerLatitude],
      zoom: location.zoomLevel
    });
  };

  const savedLocations = savedLocationQuery?.data?.map((location) => {
    return {
      id: location?.id,
      name: location.name,
      centerLatitude: location.centerLatitude,
      centerLongitude: location.centerLongitude,
      zoomLevel: location.zoomLevel
    };
  });

  const locationList = [SYSTEM_DEFAULT_LOCATION, ...(savedLocations || [])];

  return (
    <>
      <SavedLocationRow>
        <SavedLocationSelect
          placeholder="Select Saved Location"
          value={selectedLocation}
          onChange={(value) => {
            onSelectLocation(value);
          }}>
          {locationList.map((location) => (
            <Option key={location.id} value={location.id}>
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                placement="left"
                title={`Latitude: ${location.centerLatitude.toFixed(6)} 
                                    Longitude: ${location.centerLongitude.toFixed(6)}
                                    Zoom: ${location.zoomLevel.toFixed(2)}`}>
                <SavedLocationContainer>
                  {location.name}
                  <Popconfirm
                    placement="right"
                    title={"Are you sure you want to delete this saved map location?"}
                    okText="Delete"
                    okType="danger"
                    onConfirm={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteLocation.mutate(location.id);
                    }}
                    onCancel={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}>
                    {location.name !== "System Default" && (
                      <DeleteButton
                        icon={<Icon path={mdiDelete} size={1.1} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      />
                    )}
                  </Popconfirm>
                </SavedLocationContainer>
              </Tooltip>
            </Option>
          ))}
        </SavedLocationSelect>
        <Tooltip placement="right" title={!isAddingLocation && "Save current location"}>
          <SaveToggle
            icon={
              <Icon path={isAddingLocation ? mdiClose : mdiContentSavePlus} size={1.1} />
            }
            onClick={onSaveToggle}
          />
        </Tooltip>
      </SavedLocationRow>

      {isAddingLocation && (
        <>
          <Input
            placeholder={"New Saved Location Name"}
            value={newLocationName}
            onChange={(e) => setNewLocationName(e.target.value)}
          />
          <SaveButton onClick={onSaveLocation} disabled={!newLocationName}>
            Save
          </SaveButton>
          <ErrorContainer>{saveError}</ErrorContainer>
        </>
      )}
    </>
  );
}

const SavedLocationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px 0px;
`;

const SaveToggle = styled(Button)`
  margin-left: 5px;
  color: #a2aaad;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    color: #a2aaad;
  }
  &:hover {
    color: black;
  }
`;
const SaveButton = styled(Button)`
  margin-top: 5px;
  background-color: var(--color-primary);
  align-self: flex-end;
`;

const SavedLocationSelect = styled(Select)`
  width: fill-available;
  .ant-select-selector .ant-select-selection-item {
    div button {
      display: none;
    }
  }
`;

const SavedLocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  height: 10px;
  color: #a2aaad;
  &:hover {
    background-color: transparent;
    color: red;
  }
  &::after {
    all: unset;
  }
`;

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;
