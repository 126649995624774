import { useCallback, useEffect, useRef, useState } from "react";

import { BaseInput } from "components/base";

import { useStructuredNodeContext } from "../../project-layer-tree/node-manager/context/StructuredNodeContext";
import {
  setAcceptChanges,
  setInEditMode
} from "../../project-layer-tree/node-manager/context/reducer";

export interface IEditableLabel {
  editKey;
  value;
  onChange;
}

export function EditableLabel({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editKey,
  value,
  onChange
}: IEditableLabel) {
  const [{ acceptChanges, inEditMode }, nodeDispatch] = useStructuredNodeContext();

  const labelRef = useRef(null);
  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    const handleClickOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (inEditMode && labelRef.current && !labelRef.current.contains(e.target)) {
        setAcceptChanges(nodeDispatch, true);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inEditMode, setAcceptChanges, setInEditMode]);

  const onKeyDown = useCallback(
    (evt) => {
      //ensure we only listen to the correct keydown event
      //by checking against the tag we set
      if (
        evt.key === "Enter" &&
        (document.activeElement === document.body ||
          document.activeElement.getAttribute("tag") === "editLabel")
      ) {
        if (!inEditMode) {
          setInEditMode(nodeDispatch, true);
        } else {
          setAcceptChanges(nodeDispatch, true);
        }
      }
    },
    [inEditMode, setAcceptChanges, setInEditMode]
  );

  useEffect(() => {
    if (acceptChanges) {
      onChange && onChange(textValue);
    } else if (!inEditMode && textValue !== value) {
      setTextValue(value);
    }
  }, [acceptChanges, inEditMode, onChange, textValue, value]);
  return !inEditMode ? (
    <label ref={labelRef}>{textValue}</label>
  ) : (
    <div ref={labelRef}>
      {inEditMode && (
        <BaseInput
          value={textValue}
          onChange={setTextValue}
          keypress={onKeyDown}
          autoFocus
          tag="editLabel"></BaseInput>
      )}
    </div>
  );
}
