import { useEffect, useRef } from "react";

import { ALL_CHART_TYPES, ChartTypeLabels } from "constants/chart.constants";

import { AxisLockStatus, ChartAxisType, IChartSettings, IChartType } from "models/chart";

/**
 * Returns a boolean to indicate whether the axis should show locks,
 * based on the selected chart type and the data-type of the axis.
 */
const useLockToggle = (
  axisType: ChartAxisType,
  { chartType, chartTypeParam }: IChartSettings
): boolean => {
  const {
    x: { dataType: xAxisDataType },
    y: { dataType: yAxisDataType }
  } = chartTypeParam;

  // allow lock axis only when data-type is "numeric" or "date"
  const xAxisLockAllowed =
    xAxisDataType === "Number" || xAxisDataType === "Integer" || xAxisDataType === "Date";
  const yAxisLockAllowed =
    yAxisDataType === "Number" || yAxisDataType === "Integer" || yAxisDataType === "Date";

  function getLockStatus(chartType: ChartTypeLabels): AxisLockStatus {
    // find chart-type selected, and apply lock constraints, if available.
    const { lock } =
      Object.values(ALL_CHART_TYPES).find((c: IChartType) => c.label === chartType) ?? {};
    const xAxisLockConstraint = lock ? lock.x && xAxisLockAllowed : true;
    const yAxisLockConstraint = lock ? lock.y && yAxisLockAllowed : true;

    // finally, merge axis-lock constraint with data-type lock constraint.
    return {
      x: xAxisLockConstraint,
      y: yAxisLockConstraint
    };
  }
  const showLock = useRef(getLockStatus(chartType));

  useEffect(() => {
    showLock.current = getLockStatus(chartType);
  }, [chartType, xAxisDataType, yAxisDataType]);

  return showLock.current[axisType];
};

export default useLockToggle;
