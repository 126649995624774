import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { ScenarioStickListKey } from "./useScenarioSticks";

const useRunScenario = () => {
  const client = useQueryClient();
  return useMutation(
    async (scenarioId: string) => {
      const response = await axios.post<string>(
        `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario/${scenarioId}/generate`
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        await client.invalidateQueries(ScenarioStickListKey);
      }
    }
  );
};

export default useRunScenario;
