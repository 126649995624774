import { memo } from "react";

function Mosaic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      width="23pt"
      height="23pt">
      <g>
        <rect width="23" height="23" fillOpacity="0" />
        <g>
          <rect x="0" y="1" width="2" height="20" fill="currentColor" />
          <rect x="0" y="21" width="22" height="2" fill="currentColor" />
        </g>
        <rect x="6" y="1" width="3.556" height="8" fill="currentColor" />
        <rect x="10.444" y="1" width="5.333" height="6.222" fill="currentColor" />
        <rect x="16.667" y="1" width="5.333" height="4" fill="currentColor" />
        <rect x="10.444" y="8.111" width="5.333" height="4.444" fill="currentColor" />
        <rect x="10.444" y="13.444" width="5.333" height="3.556" fill="currentColor" />
        <rect x="6" y="9.889" width="3.556" height="3.556" fill="currentColor" />
        <rect x="16.667" y="5.444" width="5.333" height="6.222" fill="currentColor" />
        <rect x="16.667" y="12.556" width="5.333" height="4.444" fill="currentColor" />
        <rect x="6" y="14.333" width="3.556" height="2.667" fill="currentColor" />
      </g>
    </svg>
  );
}

export default memo(Mosaic);
