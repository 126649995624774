// Define the type for the conversion function
export type ConversionFunction = (input: number) => number;

// Define the conversion types as an enum
export enum ConversionType {
  BblPerMcfToBblPerMcf,
  BblPerMcfToBblPerMMcf,
  BblPerMMcfToBblPerMMcf,
  BblPerMMcfToBblPerMcf,
  McfPerBblToMcfPerBbl,
  McfPerBblToScfPerBbl,
  ScfPerBblToScfPerBbl,
  ScfPerBblToMcfPerBbl,
  ScfPerBblToScfPerMbbl,
  ScfPerMbblToScfPerBbl,
  BblPerDayToBblPerDay,
  BblPerBblToBblPerBbl
}

const conversionFunctions: { [unit in ConversionType]: ConversionFunction } = {
  [ConversionType.BblPerMcfToBblPerMcf]: (value: number) => value,
  [ConversionType.BblPerMcfToBblPerMMcf]: (value: number) => value * 1000,
  [ConversionType.BblPerMMcfToBblPerMMcf]: (value: number) => value,
  [ConversionType.BblPerMMcfToBblPerMcf]: (value: number) => value / 1000,
  [ConversionType.McfPerBblToMcfPerBbl]: (value: number) => value,
  [ConversionType.McfPerBblToScfPerBbl]: (value: number) => value * 1000,
  [ConversionType.ScfPerBblToScfPerBbl]: (value: number) => value,
  [ConversionType.ScfPerBblToMcfPerBbl]: (value: number) => value / 1000,
  [ConversionType.ScfPerBblToScfPerMbbl]: (value: number) => value * 1000,
  [ConversionType.ScfPerMbblToScfPerBbl]: (value: number) => value / 1000,
  [ConversionType.BblPerDayToBblPerDay]: (value: number) => value,
  [ConversionType.BblPerBblToBblPerBbl]: (value: number) => value
};

// Map from input-output unit strings to ConversionType values
const conversionTypeMap: { [unit: string]: ConversionType } = {
  "bbl/mcf-bbl/mcf": ConversionType.BblPerMcfToBblPerMcf,
  "bbl/mcf-bbl/mmcf": ConversionType.BblPerMcfToBblPerMMcf,
  "bbl/mmcf-bbl/mmcf": ConversionType.BblPerMMcfToBblPerMMcf,
  "bbl/mmcf-bbl/mcf": ConversionType.BblPerMMcfToBblPerMcf,
  "mcf/bbl-mcf/bbl": ConversionType.McfPerBblToMcfPerBbl,
  "mcf/bbl-scf/bbl": ConversionType.McfPerBblToScfPerBbl,
  "scf/bbl-scf/bbl": ConversionType.ScfPerBblToScfPerBbl,
  "scf/bbl-mcf/bbl": ConversionType.ScfPerBblToMcfPerBbl,
  "scf/bbl-scf/mbbl": ConversionType.ScfPerBblToScfPerMbbl,
  "scf/mbbl-scf/bbl": ConversionType.ScfPerMbblToScfPerBbl,
  "bbl/d-bbl/d": ConversionType.BblPerDayToBblPerDay,
  "bbl/bbl-bbl/bbl": ConversionType.BblPerBblToBblPerBbl
};

// Function for converting units
export const convert = (value: number, fromUnit: string, toUnit: string): number => {
  if (!toUnit) {
    return value;
  }
  // Convert the unit strings to lowercase
  const fromUnitLower = fromUnit.toLowerCase();
  const toUnitLower = toUnit.toLowerCase();
  // Get the conversion type from the map
  const conversionType = conversionTypeMap[`${fromUnitLower}-${toUnitLower}`];

  // If conversion type is not found, throw an error
  if (conversionType === undefined) {
    throw new Error(`Unsupported conversion from ${fromUnit} to ${toUnit}`);
  }

  // Return the conversion result
  return conversionFunctions[conversionType](value);
};
