import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "antd";
import axios from "axios";

import PostForm from "./PostForm";
import PostView from "./PostView";
import { ModalFormWrapper as Wrapper } from "./SharedStyles";
import {
  DELETE_FILE_URL,
  DELETE_POST_URL,
  SINGLE_POST_URL,
  UPDATE_URL
} from "./constants";
import { setEditPostId, usePost } from "./context";

function UpdatePost({ postId, onUpdate }) {
  // context and dispatch
  const [{ editPostId }, dispatch] = usePost();

  // state
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState("");

  // derived state
  const showEditModal = editPostId === postId;

  const retrievePost = useCallback(() => {
    axios.get(`${SINGLE_POST_URL}/${postId}`, {}).then((response) => {
      setTitle(response.data.title);
      setTags(response.data.tag);
      setContent(response.data.content);
      setDate(response.data.date);
      setFiles(response.data.attachments);
    });
  }, [postId]);

  useEffect(() => {
    if (showEditModal) {
      retrievePost();
    }
  }, [retrievePost, showEditModal]);

  function handleTitleChange(newTitle) {
    setTitle(newTitle);
  }

  function handleContentChange(newContent) {
    setContent(newContent);
  }

  function addFile(newFile) {
    setFiles((prevState) => [...prevState, newFile]);
  }

  function replace(before, after) {
    setContent((content) => {
      return content.replace(before, after);
    });
  }

  function cleanFiles() {
    const unreferencedFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (!content.includes(files[i])) {
        unreferencedFiles.push(files[i]);
        axios.delete(DELETE_FILE_URL + "/" + files[i], {});
      }
    }
    const temp = [...files];
    return temp.filter((item) => !unreferencedFiles.includes(item));
  }

  function submitUpdate() {
    const params = {
      id: postId,
      title: title,
      tag: tags,
      content: content,
      attachments: cleanFiles(),
      date: date
    };
    if (params.title === "" || params.tag.length === 0 || params.content === "") {
      toast.warning("Please fill all fields.");
      return;
    }
    axios
      .put(UPDATE_URL, params)
      .then(() => {
        toast.success("Post successfully updated.");
        onUpdate();
        setEditPostId(dispatch, null);
      })
      .catch(() => {
        toast.error("An error occurred when submitting the post.");
      });
  }

  function deleteConfirmation() {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this post?")) {
      deletePost();
    }
  }

  function deletePost() {
    axios.delete(`${DELETE_POST_URL}/${postId}`, {}).then(() => {
      toast.success("Post successfully deleted");
      setEditPostId(dispatch, null);
    });
  }

  return (
    <Modal
      centered
      closeIcon={<CloseIcon className="anticon" fontSize="large" />}
      footer={null}
      maskClosable={false}
      title="Edit post"
      wrapClassName="edit-post-modal"
      open={showEditModal}
      onCancel={() => setEditPostId(dispatch, null)}>
      <Wrapper>
        <PostForm
          title={title}
          tags={tags}
          content={content}
          replaceContent={replace}
          addFile={addFile}
          onTitleChange={handleTitleChange}
          onContentChange={handleContentChange}
          onTagSelection={(v) => setTags(v)}
          onSubmit={submitUpdate}
          onDelete={deleteConfirmation}
        />

        <PostView title={title} tags={tags} content={content} />
      </Wrapper>
    </Modal>
  );
}

export default UpdatePost;
