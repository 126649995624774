import { useSelector } from "react-redux";

import { useQuery } from "@apollo/client";
import { ForecastActivityType } from "constants/settings.constants";
import { RootState } from "store/rootReducer";

import { FOLDERS } from "api/userArps";

import { useSelectedProject } from "components/project/projects/hooks";

import { useTypeWellUpdater } from ".";
import "../context/reducer/arpsReducer";
import { IUndoRedoManager } from "./useUndoRedo";

export default function useTypeWellResetter(
  type: ForecastActivityType,
  undoRedoManager: IUndoRedoManager
) {
  const { selectedProject: project } = useSelectedProject();

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  const { onTypeWellValueChange } = useTypeWellUpdater(undoRedoManager);

  const { refetch } = useQuery(FOLDERS, {
    variables: {
      req: {
        projectId: project?.projectId,
        type: type
      }
    },
    skip: !project?.projectId
  });

  async function resetTypeWell() {
    const result = await refetch({
      req: {
        projectId: project?.projectId,
        type: type
      }
    });

    if (!selectedTypeWell || !result?.data) {
      return;
    }

    const folderId = selectedTypeWell.folderId;
    const folders = (result?.data?.folders ?? []).filter((f) => f.folderId === folderId);

    if (folders.length === 0) {
      return;
    }

    const forecast = folders[0].forecasts.filter((f) => f.id === selectedTypeWell.id);

    if (forecast.length === 0) {
      return;
    }

    const forecastClone = Object.assign({}, selectedTypeWell, forecast[0]);
    onTypeWellValueChange(forecastClone);
  }

  return { resetTypeWell };
}
