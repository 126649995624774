import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import {
  Business as BusinessIcon,
  Group as GroupIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Person as PersonIcon
} from "@material-ui/icons";
import { Select } from "antd";
import styled from "styled-components";

import { WORKSPACES, WorkspaceClientName } from "api/workspace";

import { WorkspaceQuery } from "models/workspace";

import { Tooltip } from "components/base";

import { useWorkspaceContext } from "./hooks/useWorkspaceContext";
import { useWorkspaceDispatch } from "./hooks/useWorkspaceDispatch";

const iconMap = Object.freeze({
  Personal: PersonIcon,
  Shared: GroupIcon,
  Organization: BusinessIcon
});

function WorkspaceNav() {
  const dispatch = useWorkspaceDispatch();
  const { workspace } = useWorkspaceContext();

  const [options, setOptions] = useState([]);

  const { data, refetch } = useQuery<WorkspaceQuery>(WORKSPACES, {
    context: {
      clientName: WorkspaceClientName
    },
    notifyOnNetworkStatusChange: true
  });

  // Get the list of workspaces for the dropdown.
  useEffect(() => {
    if (!data?.workspaces) return;
    const nextOptions = [];

    data.workspaces.forEach((w) => {
      const { dashboards, title, type, workspaceId } = w;
      if (!dashboards?.length) return;

      const Icon = iconMap[type];
      nextOptions.push({
        label: (
          <WorkspaceItem>
            <Icon /> {title}
          </WorkspaceItem>
        ),
        value: workspaceId
      });
    });

    setOptions(nextOptions);
  }, [data?.workspaces]);

  // If there are no workspaces when the workspace changes, refresh the list of workspaces.
  useEffect(() => {
    if (workspace && options.length) return;
    refetch();
  }, [workspace]);

  const handleWorkspaceChange = () => (workspaceId) => {
    dispatch({ payload: { workspaceId } });
  };

  if (!workspace?.workspaceId) return null;

  return (
    <Tooltip placement="bottomRight" title="Choose Workspace">
      <StyledSelect
        popupClassName="modal-select"
        value={workspace?.workspaceId}
        suffixIcon={<KeyboardArrowDownIcon fontSize="large" />}
        options={options}
        onChange={handleWorkspaceChange()}
      />
    </Tooltip>
  );
}

export default WorkspaceNav;

const WorkspaceItem = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: var(--color-text);
`;

const StyledSelect = styled(Select)`
  min-width: 0;
  flex-grow: 1;
  margin-right: var(--space-3);
`;
