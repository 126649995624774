import { PerformanceTypeT } from "components/multiphase-chart/components/settings/toggles/presets/Tabs/Products/ProductStyleSelector";

const getPerformanceTypeKey = (performanceType?: PerformanceTypeT) => {
  let performanceTypeKey: string;

  switch (performanceType) {
    case "forecast":
      performanceTypeKey = "-fcst";
      break;
    case "backfit":
      performanceTypeKey = "-backfit";
      break;
    default:
      performanceTypeKey = "";
      break;
  }

  return performanceTypeKey;
};

export default getPerformanceTypeKey;
