import { useMemo, useState } from "react";

import LockIcon from "@material-ui/icons/Lock";
import { Switch } from "antd";
import classnames from "classnames";
import { ALL_CHART_TYPES, AXIS_TYPE } from "constants/chart.constants";
import styled, { css } from "styled-components/macro";

import { useChartSettings } from "components/multiphase-chart/context";
import {
  changeAxisMinMax,
  changeSettings
} from "components/multiphase-chart/context/reducer/chartSettingsReducer";
import {
  getAxesDateMinMax,
  getAxesNumberMinMax
} from "components/multiphase-chart/util/chart/axis";

import { visibilityToggleStyles } from "../../../shared/SharedStyles";
import AxisInput from "./AxisInput";
import "./ChartAxis.scss";

type ChartAxisMinMaxT = {
  xMin?: number;
  xMax?: number;
  yMin?: number;
  yMax?: number;
};
type ChartAxisT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: any;
  isLogScale?: boolean;
  type?: string;
  axisIndex?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  axisOptions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  grid?: any;
  chartType?: number;
  axisMinMax?: ChartAxisMinMaxT;
};

function ChartAxis({
  chartData = null,
  isLogScale = false,
  type = null,
  axisIndex = 0,
  axisOptions = null,
  grid = null,
  chartType = null,
  axisMinMax = {
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null
  }
}: ChartAxisT): JSX.Element {
  const xAxis = type === AXIS_TYPE.x;
  const yAxis = type === AXIS_TYPE.y;

  const [chartSettings, chartSettingsDispatch] = useChartSettings();
  const [axisInput, setAxisInput] = useState({ type: null, value: 0 });
  const [axisInputStyle, setAxisInputStyle] = useState({});
  const [axisInputType, setAxisInputType] = useState("");

  const inputFormat = useMemo(() => {
    return chartType === ALL_CHART_TYPES.RateDate.id && xAxis ? "YYYY" : "number";
  }, [chartType, xAxis]);

  const defaultAxisMinMax = useMemo(() => {
    // Forecasts included in the series regardless of settings
    // Prevent forecasted series from increasing the max when not used
    const series = chartSettings?.settings?.forecast
      ? chartData?.series
      : chartData?.series.filter((series) => !series.isForecast);

    // const axisMaxProperty = axisInputType === 'xMax'
    const axisDataMax =
      inputFormat === "YYYY"
        ? getAxesDateMinMax(series, axisIndex, axisInput.type)
        : getAxesNumberMinMax(series, axisIndex, axisInput.type);

    return axisDataMax;
  }, [chartData, axisInput, inputFormat]);

  const onSaveAxis = (val) => {
    const { xMax, xMin, yMax, yMin } = val;
    if (xMax || xMin || yMax || yMin) {
      updateLockSettings(val);
    }
  };

  const updateLogSettings = (value) => {
    const updatedLogs = { ...chartSettings.settings.logs };
    if (xAxis) {
      updatedLogs[axisIndex] = { x: value, y: updatedLogs[axisIndex].y };
    }
    if (yAxis) {
      updatedLogs[axisIndex] = { x: updatedLogs[axisIndex].x, y: value };
    }

    const nextSettings = { ...chartSettings.settings, ["logs"]: updatedLogs };
    changeSettings(chartSettingsDispatch, nextSettings);
  };

  const updateLockSettings = (value) => {
    const updatedAxisMinMax = { ...chartSettings.axisMinMax };
    updatedAxisMinMax[axisIndex] = {
      ...chartSettings.axisMinMax[axisIndex],
      ...value
    };
    changeAxisMinMax(chartSettingsDispatch, updatedAxisMinMax);
  };

  function handleLockClick(event, minMax) {
    setAxisInputType(minMax);
    if (axisMinMax[`${type}${minMax}`]) {
      const obj = {
        [`${type}${minMax}`]: null
      };
      updateLockSettings(obj);
    } else {
      const key = minMax.toLowerCase();
      let lockValue = axisMinMax[key] || "";
      if (lockValue) {
        const isNumber = typeof lockValue === "number";
        lockValue = isNumber ? parseInt(lockValue.toString()) : lockValue;
        lockValue =
          !isNumber && lockValue.length > 7 ? lockValue.substring(0, 4) : lockValue;
      }
      setAxisInput({ type: `${type}${minMax}`, value: lockValue });
      setAxisInput({
        type: `${type}${minMax}`,
        value: lockValue
      });
      setAxisInputPosition(event);
    }
  }

  function setAxisInputPosition(event) {
    const containerRect = event.currentTarget.parentNode.getBoundingClientRect();
    const buttonRect = event.currentTarget.getBoundingClientRect();
    const top = buttonRect.top - containerRect.top;
    const left = buttonRect.left - containerRect.left;
    setAxisInputStyle({ top: top, left: left });
  }

  function getAxisLockType(minMax) {
    if (minMax === "Min") {
      if (yAxis) {
        return "yMin";
      } else if (xAxis) {
        return "xMin";
      }
    } else if (minMax === "Max") {
      if (yAxis) {
        return "yMax";
      } else if (xAxis) {
        return "xMax";
      }
    }
    return "";
  }

  const visibleClassnames = useMemo(
    () =>
      classnames({
        visible: chartSettings.isMouseOver && !chartSettings.screenshot?.visible
      }),
    [chartSettings.isMouseOver, chartSettings.screenshot?.visible]
  );

  const wrapperClassnames = useMemo(
    () =>
      classnames(visibleClassnames, {
        xAxis,
        yAxis,
        stackDown: true
      }),
    [visibleClassnames, xAxis, yAxis]
  );

  const logToggleClassnames = useMemo(
    () =>
      classnames({
        xAxis,
        yAxis,
        visible:
          chartSettings.isMouseOver &&
          !chartSettings.screenshot?.visible &&
          (chartType === 1 || !xAxis)
      }),
    [
      chartSettings.isMouseOver,
      chartSettings.screenshot?.visible,
      chartType,
      xAxis,
      yAxis
    ]
  );

  const axisInputClassnames = classnames({ xAxis, yAxis });

  const lockClassnames = (minMax) => {
    return classnames("multiphase-axis-lock ", "flex", {
      isLocked: axisMinMax[minMax],
      xAxis,
      yAxis,
      [minMax]: true,
      [axisOptions?.position]: true
    });
  };

  const axisWrapperClassnames = () => {
    return classnames({
      [axisOptions?.position]: true,
      xAxis,
      yAxis,
      [axisInputType]: true
    });
  };

  return (
    <Wrapper
      position={axisOptions?.position}
      data-testid={"multiphasechart-axis"}
      className={wrapperClassnames}>
      <LogToggle
        position={axisOptions?.position}
        className={logToggleClassnames}
        logPosition={axisOptions?.logPosition}
        data-testid={`${type}-axis-log`}>
        <span>Log</span>
        <Switch
          size="small"
          checked={isLogScale}
          onChange={(v) => updateLogSettings(v)}
        />
      </LogToggle>
      <LockIconWrapper
        className={lockClassnames(getAxisLockType("Min"))}
        onClick={(event) => handleLockClick(event, "Min")}
        logPosition={axisOptions?.logPosition}
        grid={grid}
        data-testid={`${type}-axis-min-lock`}>
        <LockIcon />
      </LockIconWrapper>

      <LockIconWrapper
        className={lockClassnames(getAxisLockType("Max"))}
        onClick={(event) => handleLockClick(event, "Max")}
        logPosition={axisOptions?.logPosition}
        grid={grid}
        data-testid={`${type}-axis-max-lock`}>
        <LockIcon />
      </LockIconWrapper>

      <AxisInputWrapper className={axisWrapperClassnames()} logPosition={axisInputStyle}>
        {axisInput.type && (
          <AxisInput
            axisInput={axisInput}
            className={axisInputClassnames}
            defaultValue={defaultAxisMinMax}
            inputFormat={inputFormat}
            onSave={onSaveAxis}
            setAxisInput={setAxisInput}
          />
        )}
      </AxisInputWrapper>
    </Wrapper>
  );
}

export default ChartAxis;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;

  &.xAxis {
    right: 0;
    ${(props) => (props.position === "top" ? "top: 0px;" : "bottom: 0px;")};
    height: 24px;
    z-index: 3;
  }
  &.yAxis {
    top: 0;
    ${(props) => (props.position === "right" ? "right: 0px;" : "left: 0px;")};
    z-index: 1;
  }
  &.yAxis.stackDown {
    z-index: auto;
  }
`;

const LogToggle = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--color-text);
  font-weight: 500;
  font-size: 12px;
  z-index: 3;
  &.xAxis {
    right: 44px;
    ${({ logPosition }) => logPosition && css(logPosition)}
  }
  &.yAxis {
    transform-origin: ${(props) =>
      props.position === "right" ? "right top" : "left top"};
    transform: ${(props) =>
      props.position === "right"
        ? "translateX(-20px) rotate(-90deg);"
        : "translateY(30px) rotate(-90deg);"};
    ${({ logPosition }) => logPosition && css(logPosition)}
  }
`;

const AxisInputWrapper = styled.div`
  ${({ logPosition }) => logPosition && css(logPosition)}
  position: absolute;
  z-index: 99;
  transform: translateY(-2px);
  &.xAxis.Max {
    transform: translateX(-70px);
  }
  &.yAxis.right {
    transform: translateX(-70px);
  }
`;

const LockIconWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100vmax;
  isolation: isolate;
  z-index: 9;

  &.stackDown {
    z-index: 0;
  }
  &.xAxis.top {
    top: ${(props) => parseInt(props.logPosition?.top)}px;
  }
  &.xAxis.bottom {
    bottom: ${(props) => parseInt(props.logPosition?.bottom) + 3}px;
  }
  &.xAxis.xMin {
    left: ${(props) => props.grid?.left}px;
  }
  &.xAxis.xMax {
    right: ${(props) => parseInt(props.logPosition?.right) - 16}px;
  }
  &.yAxis.yMax {
    top: 60px;
  }
  &.yAxis.yMin {
    bottom: ${(props) => parseInt(props.logPosition?.bottom) - 35}px;
  }
  &.yAxis.right {
    right: ${(props) => parseInt(props.logPosition?.right) + 35}px;
    bottom: ${(props) => parseInt(props.logPosition?.bottom) - 30}px;
  }
  &.yAxis.left {
    left: ${(props) => parseInt(props.logPosition?.left)}px;
    bottom: ${(props) => parseInt(props.logPosition?.bottom) - 30}px;
  }
`;
