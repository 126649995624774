import { memo, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { Save } from "@material-ui/icons";
import { Popover, Tooltip } from "antd";
import styled from "styled-components";

import { IColumnSet } from "models/columns";

import { useGroupByState } from "../groupBy/group-by.context";
import DataTableSaveColumnSet from "./DataTableSaveColumnSet";
import { useCreateColumnSetMutation, useUpdateColumnSetMutation } from "./hooks";

export interface ISaveButton {
  selectedColumnSet: IColumnSet;
  selectedProperties: string[];
}

const SaveButton = ({ selectedColumnSet, selectedProperties }: ISaveButton) => {
  const [saveColumnsOpen, setSaveColumnsOpen] = useState(false);
  const [saveColumnsError, setSaveColumnsError] = useState<string>();
  const { entityKind } = useGroupByState();
  const queryClient = useQueryClient();

  const createColumnSetMutation = useCreateColumnSetMutation(
    async () => {
      toast.success("Column set saved");
      await queryClient.invalidateQueries("getColumnSets");
      setSaveColumnsOpen(false);
    },
    (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as any;
      if (err && err.response?.data?.title) {
        setSaveColumnsError(err.response?.data.title);
      } else {
        setSaveColumnsError("Failed to save column set.");
      }
    }
  );

  const updateColumnSetMutation = useUpdateColumnSetMutation(
    async () => {
      toast.success("Column set updated");
      await queryClient.invalidateQueries("getColumnSets");
      setSaveColumnsOpen(false);
    },
    (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as any;
      if (err && err.response?.data?.title) {
        setSaveColumnsError(err.response?.data.title);
      } else {
        setSaveColumnsError("Failed to update column set.");
      }
    }
  );

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      open={saveColumnsOpen}
      onOpenChange={(visible) => {
        setSaveColumnsOpen(visible);
        setSaveColumnsError(null);
      }}
      title={<ActionPopupTitle>Save column set</ActionPopupTitle>}
      content={
        <DataTableSaveColumnSet
          selectedColumnSetName={selectedColumnSet?.title}
          onSave={(columnSetName) => {
            if (!selectedColumnSet || selectedColumnSet.title != columnSetName) {
              createColumnSetMutation.mutate({
                title: columnSetName,
                properties: selectedProperties,
                entityKind
              });
            } else {
              updateColumnSetMutation.mutate({
                columnSetId: selectedColumnSet.columnSetId,
                title: columnSetName,
                properties: selectedProperties
              });
            }
          }}
          isLoading={createColumnSetMutation.isLoading}
          visible={saveColumnsOpen}
          error={saveColumnsError}
        />
      }>
      <Tooltip title="Save Column Set" mouseEnterDelay={0.6}>
        <ActionButton>
          <Save fontSize="large" />
          {"Save"}
        </ActionButton>
      </Tooltip>
    </Popover>
  );
};

export default memo(SaveButton);

const ActionPopupTitle = styled.p`
  font-weight: var(--fontWeightBold);
`;

const ActionButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  background: transparent;
  border: none;
  color: #a2aaad;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;
