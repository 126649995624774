import { useEffect, useState } from "react";

import { LegendItemModel } from "models";

import { useChartState } from "../context";
import { createLegendItem, verifyChartTypeCanUseStats } from "../utils";

const useP50LegendItem = (): LegendItemModel[] => {
  const { settings } = useChartState();
  const [items, setItems] = useState<LegendItemModel[]>([]);

  const isP50Enabled = Boolean(settings.showP50);
  const canChartShowsStats =
    verifyChartTypeCanUseStats(settings.chartType) &&
    settings.chartType !== "Material Balance Time"; // at this time material balance does not support the p50 stat.

  useEffect(() => {
    if (!isP50Enabled || !canChartShowsStats) {
      setItems([]);
      return;
    }

    const color = settings?.p50Settings ? settings.p50Settings.color : "#000";
    const p50 = createLegendItem("P50", true, color);

    setItems([p50]);
  }, [isP50Enabled, canChartShowsStats, settings?.p50Settings]);

  return items;
};

export default useP50LegendItem;
