import { useEffect, useState } from "react";

import { useChartSettings } from "../context/ChartSettingsContext";

// This hook updates the dashboard title to be italicized if settings or presets
// are modified
const useChartStateForDashboard = (dashboardDispatch) => {
  const [lastSettings, setLastSettings] = useState<string>();
  const [chartSettings] = useChartSettings();

  useEffect(() => {
    // properties that can be changed by user
    const { currentPreset, settings, storedPreset } = chartSettings;
    const nextSettings = JSON.stringify({ currentPreset, settings, storedPreset });
    if (!lastSettings) {
      setLastSettings(nextSettings);
    } else if (nextSettings !== lastSettings) {
      dashboardDispatch({ payload: { hasModifiedWidgets: true } });
      setLastSettings(nextSettings);
    }
  }, [chartSettings, lastSettings, dashboardDispatch]);
};

export default useChartStateForDashboard;
