import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Popover } from "antd";
import _find from "lodash/find";
import { RootState } from "store/rootReducer";

import { EntityKind } from "models/entityKind";

import ToggleButton from "../ToggleButton";
import SortOptions, { options } from "./SortOptions";

export interface SortOptionsToggleProps {
  entityKind?: EntityKind;
}

function SortOptionsToggle({ entityKind = EntityKind.Well }: SortOptionsToggleProps) {
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState(null);

  const sortBy = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.sortBy : state.facilityFilter.sortBy
  );

  useEffect(() => {
    const initialIcon = _find(options, { name: sortBy }).icon;
    setIcon(initialIcon);
  }, []);

  const handleOptionSelect = () => (option) => {
    const { icon } = option;
    setIcon(icon);
    setVisible(false);
  };

  return (
    <Popover
      content={<SortOptions entityKind={entityKind} onSelect={handleOptionSelect()} />}
      overlayClassName="sort-options"
      placement="bottomLeft"
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToggleButton
        tooltipText="Change Legend Sorting"
        value={visible}
        onClick={() => setVisible(true)}>
        {icon}
      </ToggleButton>
    </Popover>
  );
}

export default SortOptionsToggle;
