export const polygonStyles = [
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#FF5252",
      "line-dasharray": [0.2, 2],
      "line-width": 2.5
    }
  },

  // polygon fill
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": "#FF5252",
      "fill-outline-color": "#FF5252",
      "fill-opacity": 0.1
    }
  },
  // polygon outline stroke
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#FF5252",
      "line-dasharray": [0.2, 2],
      "line-width": 2.5
    }
  },

  // vertex point halos
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"]
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": "#FFF"
    }
  },

  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"]
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#FF5252"
    }
  },

  // new styles for toggling colors
  {
    id: "gl-draw-polygon-color-picker",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["has", "user_portColor"]],
    paint: {
      "fill-color": ["get", "user_portColor"],
      "fill-outline-color": ["get", "user_portStroke"],
      "fill-opacity": ["get", "user_portOpacity"]
    }
  },
  {
    id: "gl-draw-line-color-picker",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["has", "user_portStroke"]],
    paint: {
      "line-color": ["get", "user_portStroke"],
      "line-width": ["get", "user_portThickness"]
    }
  },
  {
    id: "gl-draw-point-color-picker",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
    paint: {
      "circle-radius": 3,
      "circle-color": ["get", "user_portColor"]
    }
  },

  // polygon stroke (inactive state)
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "user_type", "overlay"],
      ["!=", "mode", "static"]
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": ["get", "user_portStroke"],
      "line-width": ["get", "user_portThickness"]
    }
  },

  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": ["get", "user_portStroke"],
      "line-dasharray": [0.2, 2],
      "line-width": ["get", "user_portThickness"]
    }
  },

  // polygon midpoint
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 4,
      "circle-color": "#fbb03b"
    }
  }
];
