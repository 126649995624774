import { memo } from "react";

function IconCheck({ color = "#fff", size = 12 }) {
  const inlineStyle = {
    backfaceVisibility: "hidden",
    display: "block",
    flexShrink: 0
  };
  return (
    <svg
      viewBox="0 0 14 14"
      className="icon check"
      width={size}
      height={size}
      fill={color}
      style={inlineStyle}>
      <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon>
    </svg>
  );
}
export default memo(IconCheck);
