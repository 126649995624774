import { useContext } from "react";

import { ScreenshotContext } from "../contexts/ScreenshotContext";

export function useScreenshotContext() {
  const context = useContext(ScreenshotContext);
  if (context === undefined) {
    throw new Error("useScreenshotContext must be within a screenshot provider");
  }
  return context;
}
