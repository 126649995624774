import { ScreenSize, XdaData, XdaLayoutOptions } from "models/xdaData";

// get ratio of distance between data and screensize
function getDataToScreenRatio(
  data: XdaData,
  screenSize: ScreenSize,
  xdaLayoutOptions: XdaLayoutOptions
) {
  const dataHeight =
    screenSize.height - xdaLayoutOptions.grid.top - xdaLayoutOptions.grid.bottom;
  const dataWidth =
    screenSize.width - xdaLayoutOptions.grid.left - xdaLayoutOptions.grid.right;
  const xRatio = dataWidth ? data.rect.width / dataWidth : dataWidth;
  const yRatio = dataHeight ? data.rect.height / dataHeight : dataHeight;

  return [xRatio, yRatio];
}

export default getDataToScreenRatio;
