import { PREDICATE_PROPERTY_OPERATOR } from "constants/filter.constants";

import { IPredicate } from "models/filter";

const predicateSourceMigration = (predicate: IPredicate): string => {
  if (Array.isArray(predicate?.value)) {
    if (predicate.value.length === 1 && typeof predicate.value[0] === "string") {
      return predicate.value[0];
    } else if (predicate.value.length === 1) {
      if (
        predicate.operator === PREDICATE_PROPERTY_OPERATOR.Equals ||
        predicate.operator === PREDICATE_PROPERTY_OPERATOR.In
      ) {
        return `${predicate.value[0]}`;
      } else {
        return `${predicate.operator} ${predicate.value[0]}`;
      }
    } else if (predicate.value.length === 2) {
      return `${predicate.value[0]} - ${predicate.value[1]}`;
    }
  } else if (predicate?.value) {
    if (
      predicate.operator === PREDICATE_PROPERTY_OPERATOR.Equals ||
      predicate.operator === PREDICATE_PROPERTY_OPERATOR.In
    ) {
      return `${predicate.value}`;
    } else {
      return `${predicate.operator} ${predicate.value}`;
    }
  }
  return "n/a";
};

export default predicateSourceMigration;
