import { IGroupByListItem } from "models";

/**
 * Returns an array of categories filtering out unwanted categories.
 */
const filterByCategory = (
  excludeCategoryList: string[],
  list: IGroupByListItem[]
): IGroupByListItem[] => {
  if (!excludeCategoryList?.length) return list;

  return list.filter((category) => !excludeCategoryList.includes(category.name));
};

export default filterByCategory;
