import { useQuery } from "react-query";

import axios from "axios";
import { IS_DEV_ENV, SAM_SERVICE_URI } from "constants/app.constants";

const useChartStatsQuery = (wellList) => {
  const { data, refetch: updateData } = useQuery(
    "sam-stats",
    async () => {
      if (IS_DEV_ENV) {
        return devResponse;
      }
      const response = await axios.post(`${SAM_SERVICE_URI}/SamChart/chart-stat`, {
        Ooip: 0,
        PoreVolume: 0,
        ShowForecast: true,
        WellPairUwis: wellList
      });
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  return { data, updateData };
};

const devResponse = {
  lastQuarterBitumen: 259440.4,
  lastQuarterISor: 2.4,
  currentCSor: 2.7,
  lastQuarterTitle: "Q1 2023"
};

export default useChartStatsQuery;
