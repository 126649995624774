export function getDifference(fromSet: Set<string>, toSet: Set<string>): string[] {
  const difference: string[] = [];

  if (!fromSet?.size) return difference;

  fromSet.forEach((element) => {
    if (!toSet.has(element)) {
      difference.push(element);
    }
  });

  return difference;
}
