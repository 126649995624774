import { Dashboard, WorkspaceDashboard } from "models/workspace";

export const getNewDashboardName = (
  dashboards: WorkspaceDashboard[],
  newDashboard: Dashboard | WorkspaceDashboard
) => {
  if (!dashboards || !newDashboard) return "";
  let count = 0;
  dashboards.map((w) => w.name).forEach((t) => t === newDashboard.name && count++);
  return `${newDashboard.title}${count > 0 ? ` ${count}` : ""}`;
};
