import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import { useProjectContext } from "components/project/projects/context";
import {
  ORGANIZATION_SHAPEFILES_QUERY_KEYS,
  PROJECT_SHAPEFILES_QUERY_KEYS,
  SYSTEM_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

export interface IUseUpdateProjectShapefileFolderMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
  projectId?: string;
}

export type UpdateProjectShapefileFolderParamsT = {
  body: {
    key: string;
    value: string;
  };
  shapefileNodeId: string;
  projectId?: string;
};

async function updateProjectShapefileFolder(params: UpdateProjectShapefileFolderParamsT) {
  const url =
    params.projectId === "system"
      ? `${projectServiceEndpoint}/system-shapefile-node/update/${params.shapefileNodeId}`
      : `${projectServiceEndpoint}/shapefile-node/update/${params.shapefileNodeId}`;
  return await axios.put(url, params.body);
}

export function useUpdateProjectShapefileFolderMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess,
  projectId
}: IUseUpdateProjectShapefileFolderMutationParams) {
  const queryClient = useQueryClient();

  const { selectedProjectId } = useProjectContext();
  return useMutation(
    (params: UpdateProjectShapefileFolderParamsT) =>
      updateProjectShapefileFolder({ ...params }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        if (projectId === "system") {
          queryClient.invalidateQueries({
            queryKey: [SYSTEM_SHAPEFILES_QUERY_KEYS.systemShapefiles]
          });
        } else {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });

          queryClient.invalidateQueries({
            queryKey: [
              PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles,
              selectedProjectId
            ]
          });

          queryClient.invalidateQueries({
            queryKey: [
              PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList,
              selectedProjectId
            ]
          });
        }
        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
