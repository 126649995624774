import { memo, useMemo, useRef, useState } from "react";

import { uid } from "utils";

import { ChevronRight } from "components/icons";

import "./BaseTreeNode.scss";

function BaseTreeNode({ node, leafTemplate = null, nodeTemplate = null, depth = 0 }) {
  const [isOpen, setOpen] = useState(node.expanded);
  const [isFolder] = useState(node.children && node.children.length > 0);
  const triangle = useMemo(() => <ChevronRight size={24}></ChevronRight>, []);

  const child = useMemo(() => {
    return function RecursiveNode(item) {
      return (
        <BaseTreeNode
          key={item.id}
          node={item}
          depth={depth + 1}
          nodeTemplate={nodeTemplate}
          leafTemplate={leafTemplate}
        />
      );
    };
  }, [depth, nodeTemplate, leafTemplate]);

  function handleClick() {
    if (isFolder) {
      setOpen(!isOpen);
      return;
    }
  }
  const id = uid();
  const childContainer = useRef(null);
  const getTotalChildren = (node) => {
    if (node.children.length === 0) {
      return 0;
    }
    let count = node.children.length;
    for (const child of node.children) {
      count += getTotalChildren(child);
    }
    return count;
  };
  const totalChildren = getTotalChildren(node);
  const [labelTop] = useState((totalChildren * 28) / 2 + 20);
  const className = useMemo(() => {
    return `node flex items-center ${isFolder ? "isFolder" : "isLeaf"} ${
      isOpen ? "isOpen" : ""
    }`;
  }, [isFolder, isOpen]);
  return (
    <li data-depth="depth" key={node.id} className="flex-column">
      <div
        role="treeitem"
        tabIndex={0}
        className={className}
        onClick={handleClick}
        aria-expanded={isOpen}>
        {isFolder && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row"
            }}>
            {depth > 1 && <div style={{ width: "17px", minWidth: "17px" }}></div>}
            <div
              style={{ marginLeft: "5px" }}
              className="folder-toggle flex flex-centered">
              {triangle}
            </div>
          </div>
        )}

        <div className="flex fill-width items-center" style={{ minWidth: "0px" }}>
          {isFolder && nodeTemplate && nodeTemplate({ node, depth })}
          {!isFolder && leafTemplate ? (
            <div
              className="fill-width"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}>
              <div style={{ width: "21px", minWidth: "21px" }}></div>
              <div className="fill-width"> {leafTemplate({ node, depth })}</div>
            </div>
          ) : (
            isOpen &&
            node.children.length > 1 && (
              <div
                style={{
                  position: "absolute",
                  transform: "rotate(-90deg)",
                  zIndex: 1,
                  left: `${depth > 1 ? depth * -27 : -44}px`,
                  top: `${labelTop}px`,
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  transformOrigin: "0 0",
                  alignItems: "center",
                  fontSize: "1.6rem"
                }}>
                {node.title}
              </div>
            )
          )}
        </div>
      </div>
      {isFolder && isOpen && (
        <ul
          className={`no-list-style tree-branch flex-column child-${id}`}
          style={{ marginLeft: `${depth * 14}px` }}
          role="tree"
          ref={childContainer}>
          {node.children.map(child)}
        </ul>
      )}
    </li>
  );
}

export default memo(BaseTreeNode);
