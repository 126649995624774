import { memo } from "react";

function DeclineRate() {
  return (
    <svg
      width="36px"
      height="26px"
      viewBox="0 0 33 23"
      version="1.1"
      id="svg1631"
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs1634" />
      <g id="iconRate-cum" transform="translate(10 3.75)">
        <polygon
          fill="currentColor"
          strokeWidth="2"
          points="18.55 13.55 13.32 4.5 6.81 8.25 2.04 0 .58 0 .58 1.46 6.08 11 12.58 7.22 16.82 14.55 18.55 13.55"
        />
        <polygon
          fill="currentColor"
          strokeWidth="2"
          points="20 16 20 18 0 18 0 0 2 0 2 16 20 16"
        />
      </g>
    </svg>
  );
}

export default memo(DeclineRate);
