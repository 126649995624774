import { memo } from "react";

function SpaghettiMode({ style = {} }) {
  return (
    <svg width="36px" height="36px" viewBox="0 0 36 36" style={{ ...style }}>
      <path
        d="M0 0L36 0L36 36L0 36L0 0Z"
        id="36x36-copy-2"
        fill="none"
        fillRule="evenodd"
        stroke="none"
      />
      <g id="iconmapspaghetti" transform="translate(5.576416 6)">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="Rectangle"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g
          id="Group-7"
          transform="matrix(0.19080888 0.9816272 -0.9816272 0.19080888 19.56649 -0.22122073)">
          <path
            d="M2.25564 19.6037C5.9869 10.3319 -2.6937 8.06055 0.884815 0"
            transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 11.08195 1.4156103E-07)"
            id="Stroke-1"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M0 4.39205C10.9418 5.60919 9.18439 -2.32703 20.7094 0.688965"
            transform="matrix(0.99254614 -0.12186935 0.12186935 0.99254614 7.4505806E-08 8.719071)"
            id="Stroke-3"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M4.63577e-13 15.8341C11.258 12.2056 6.15978 4.74039 16.7714 -1.80013e-12"
            transform="translate(1.1618996 1.4077628)"
            id="Stroke-5"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(SpaghettiMode);
