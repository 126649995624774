import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store/store";

import { getOrganizationPalettes } from "api/userSettings";

import {
  DEFAULT_COLOR_PALETTE_NAME,
  DEFAULT_FACILITY_COLOR_PALETTE_NAME
} from "components/user-settings/models";

import {
  DEFAULT_COLOR_SCALE,
  DEFAULT_FACILITY_COLOR_SCALE
} from "../../../components/user-settings/models/IColorPalette";

const initialState = {
  // settings
  screenshotPreset: [],
  colorPalettes: null,
  activeColorPalette: {
    id: "-1",
    name: DEFAULT_COLOR_PALETTE_NAME,
    colorScale: DEFAULT_COLOR_SCALE,
    reverse: false,
    shared: false,
    paletteType: 0,
    thickness: [],
    preferredColors: []
  },
  activeFacilityColorPalette: {
    id: "-1",
    name: DEFAULT_FACILITY_COLOR_PALETTE_NAME,
    colorScale: DEFAULT_FACILITY_COLOR_SCALE,
    reverse: false,
    shared: false,
    paletteType: 0,
    thickness: []
  },
  userSettingsOpen: false,
  organizationSettingsOpen: false,
  applicationToolsOpen: false,
  activeSetting: "",
  workSpaceId: null,
  typeWellSettings: null,
  isDefaultSettingsLoaded: false
};

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setColorPalettes(state, action) {
      state.colorPalettes = action.payload;
    },
    setScreenshotPresets(state, action) {
      state.screenshotPreset = action.payload;
    },
    setActiveColorPalette(state, action) {
      state.activeColorPalette = action.payload;
    },

    setActiveFacilityColorPalette(state, action) {
      state.activeFacilityColorPalette = action.payload;
    },
    setApplicationToolsOpen(state, action) {
      state.applicationToolsOpen = action.payload;
      if (action.payload && state.userSettingsOpen) {
        state.userSettingsOpen = false;
      }
      if (action.payload && state.organizationSettingsOpen) {
        state.organizationSettingsOpen = false;
      }
    },
    setUserSettingsOpen(state, action) {
      state.userSettingsOpen = action.payload;
      if (action.payload && state.organizationSettingsOpen) {
        state.organizationSettingsOpen = false;
      }
      if (action.payload && state.applicationToolsOpen) {
        state.applicationToolsOpen = false;
      }
    },
    setOrganizationSettingsOpen(state, action) {
      state.organizationSettingsOpen = action.payload;
      if (action.payload && state.userSettingsOpen) {
        state.userSettingsOpen = false;
      }
      if (action.payload && state.applicationToolsOpen) {
        state.applicationToolsOpen = false;
      }
    },
    setActiveSetting(state, action) {
      state.activeSetting = action.payload;
    },
    setWorkspaceId(state, action) {
      state.workSpaceId = action.payload;
    },
    setTypeWellSettings(state, action) {
      state.typeWellSettings = action.payload;
    },
    setIsDefaultSettingsLoaded(state, action) {
      state.isDefaultSettingsLoaded = action.payload;
    }
  }
});

export const {
  setColorPalettes,
  setActiveColorPalette,
  setActiveFacilityColorPalette,
  setApplicationToolsOpen,
  setScreenshotPresets,
  setUserSettingsOpen,
  setOrganizationSettingsOpen,
  setActiveSetting,
  setWorkspaceId,
  setTypeWellSettings,
  setIsDefaultSettingsLoaded
} = userSettingsSlice.actions;
export const updateColorPalettes = (): AppThunk => async (dispatch) => {
  try {
    let organizationPalettes = await getOrganizationPalettes();
    organizationPalettes = organizationPalettes.map((p) => ({
      ...p,
      reverse: false
    }));
    dispatch(setColorPalettes(organizationPalettes));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
export default userSettingsSlice.reducer;
