import { useContext } from "react";

import { ProjectDispatchContext } from "./ProjectContext";

export function useProjectDispatch() {
  const context = useContext(ProjectDispatchContext);
  if (context === undefined) {
    throw new Error("useProjectDispatch must be used within a Project Provider");
  }
  return context;
}
