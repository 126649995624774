import axios from "axios";

const BASE_URL = process.env.REACT_APP_USER_DOCUMENTATION_SERVICE;

export interface GetUserDocumentationPostsRequest {
  pageIndex: number;
  pageSize: number;
  query: string;
}

export function getUserDocumentationPosts(request: GetUserDocumentationPostsRequest) {
  return axios.get(
    `${BASE_URL}/get-posts/${request.pageSize}/${request.pageIndex}${request.query}`
  );
}

export function getUserDocumentationPostsCount(sinceDate: Date = null) {
  const since = sinceDate ? sinceDate.toISOString().replace("Z", "") : "";
  return axios.get(`${BASE_URL}/count/posts?since=${since}`);
}
