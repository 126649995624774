import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useLayoutEffect } from "react";

import { useChartDispatch } from "components/chart/context";

/**
 * Saves the chart bounds to context, syncs on resize
 * @param target reference of chart's wrapper element
 */
const useChartSize = (target: MutableRefObject<HTMLDivElement>): void => {
  const dispatch = useChartDispatch();

  // save
  useLayoutEffect(() => {
    if (!target.current) return;
    const next = target.current.getBoundingClientRect();
    dispatch({ type: "bounds", payload: next });
  }, [target]);

  // sync
  useResizeObserver(target, () => {
    if (!target.current) return;

    const next = target.current.getBoundingClientRect();
    dispatch({ type: "bounds", payload: next });
  });
};

export default useChartSize;
