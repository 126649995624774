import { FC } from "react";

import { InputNumber } from "antd";
import styled from "styled-components";

import { INormalizeBySetting } from "models";

import { calculateDisplayUnit } from "../util/calculateDislpayUnit";

type NormalizeSettingsT = {
  value: INormalizeBySetting;
  onChange: (v: INormalizeBySetting) => void;
};

const MultilinearNormalizeBySettings: FC<NormalizeSettingsT> = ({ value, onChange }) => {
  const { unit, field, display, threshold, higherScalar } = value;
  const displayUnit = calculateDisplayUnit(unit, field, display);

  function handleThresholdChange(newValue: number) {
    const nextValue = { ...value, threshold: newValue };
    onChange(nextValue);
  }

  function handleHigherScalerChange(newValue: number) {
    const nextValue = { ...value, higherScalar: newValue };
    onChange(nextValue);
  }

  return (
    <>
      <Row>
        <CenterField>
          <Label>Cut Off Threshold ({displayUnit})</Label>
          <StyledNumberInput
            value={threshold}
            controls={false}
            min={0}
            onChange={handleThresholdChange}
          />
        </CenterField>
        <CenterField>
          <Label>Beyond Threshold Scalar</Label>
          <StyledNumberInput
            value={higherScalar}
            controls={false}
            min={0}
            onChange={handleHigherScalerChange}
          />
        </CenterField>
      </Row>
    </>
  );
};

export default MultilinearNormalizeBySettings;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
`;

const CenterField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.span`
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-top: 0;
`;

const StyledNumberInput = styled(InputNumber)`
  width: 60%;
  height: 30px;
`;
