import { memo, useEffect, useMemo, useState } from "react";

import { cookies } from "constants/settings.constants";
import Cookies from "js-cookie";

import { BaseMenu } from "./";
import "./ColorPicker.scss";

const default_color = "#025f9c";
const transparent_color_selected_bg = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='rgb(214,40,11)' /></svg>")`;

function SimpleColorPicker({
  values = {
    name: "",
    color: default_color,
    strokeColor: default_color,
    thickness: 2,
    opacity: 100
  },
  place = "bottom",
  align = "start",
  setColor = null,
  isAtFolderLevel = false
}) {
  const [customColors, setCustomColors] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function toggle() {}

  const selectColor = () => {
    setColor();
  };

  useEffect(() => {
    const storedCustomColors = Cookies.get(cookies.CUSTOM_COLORS);
    if (storedCustomColors && !customColors.length)
      setCustomColors([...JSON.parse(storedCustomColors)]);
  }, [customColors]);

  // TODO: Fix infinite loop caused by this useEffect when no values are passed into the ColorPicker
  //   useEffect(() => {
  //     setValues(values);
  //   }, [values]);

  const styleMultiObject = () => {
    const styles = {
      backgroundColor: values.color,
      flex: "100%",
      borderRadius: "2px",
      border: "",
      boxShadow: "",
      backgroundImage: ""
    };
    if (values.strokeColor === "#FFFFFF") {
      styles.border = "1px solid #D9E1E2";
      styles.boxShadow = `inset 0 0 0 ${values.thickness}px #FFFFFF`;
    } else {
      styles.border = `3px solid ${values.strokeColor}`;
    }
    if (values.strokeColor === "transparent" && values.color === "transparent") {
      styles.backgroundImage = transparent_color_selected_bg;
      styles.border = "1px solid #D9E1E2";
    }
    return styles;
  };

  const styleObject = (prop) => {
    const styles = {
      flex: "100%",
      borderRadius: "2px",
      border: `${values.thickness}px solid ${
        prop === "strokeColor" ? values.strokeColor : "transparent"
      }`,
      backgroundColor: prop === "color" ? values.color : "white",
      boxShadow: "",
      backgroundImage: ""
    };
    if (prop === "color" && values.color === "#FFFFFF") {
      styles.backgroundColor = "white";
      styles.border = "1px solid #D9E1E2";
    } else if (prop === "strokeColor" && values.strokeColor === "#FFFFFF") {
      styles.backgroundColor = "#D9E1E2";
      styles.border = "1px solid #D9E1E2";
      styles.boxShadow = `inset 0 0 0 ${values.thickness}px #FFFFFF`;
    }
    if (values[prop] === "transparent") {
      styles.backgroundImage = transparent_color_selected_bg;
      styles.border = "1px solid #D9E1E2";
    }
    return styles;
  };

  const classNames = useMemo(() => {
    function variation() {
      return `${place}-${align}`;
    }
    return `ColorPicker ${""} ${variation()} ${isAtFolderLevel ? "atFolderLevel" : ""}`;
  }, [align, place]);

  const trigger = (prop) => {
    return (
      <div
        tabIndex={0}
        role="button"
        className={classNames}
        onClick={() => selectColor()}>
        {prop === null ? (
          <div className="flex flex-centered" style={styleMultiObject()}></div>
        ) : (
          <div className="flex flex-centered" style={styleObject(prop)}></div>
        )}
      </div>
    );
  };

  return (
    <BaseMenu
      place={place}
      align={align}
      onClose={() => null}
      toggle={toggle}
      trigger={trigger(null)}>
      {() => null}
    </BaseMenu>
  );
}

export default memo(SimpleColorPicker);
