import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { MoreVert } from "@material-ui/icons";
import { Popover, Tooltip } from "antd";
import styled from "styled-components";

import { getColumnSets } from "api/column";

import { IColumnSet, IColumns } from "models/columns";

import ForecastSourceSelect from "components/groupBy/ForecastSourceSelect";
import PdenSourceSelect from "components/groupBy/PdenSourceSelect";
import { useGroupByState } from "components/groupBy/group-by.context";

import { EntityKind } from "../../models/entityKind";
import { BaseDropdown } from "../base";
import CopyButton from "./CopyButton";
import "./DataTable.scss";
import DeleteButton from "./DeleteButton";
import ExportButton from "./ExportButton";
import SaveButton from "./SaveButton";
import { useTable } from "./context";

export interface DataSourceSetting {
  pdenSource: number;
  forecastSource: string;
  rescat: string;
}

type DataTableActionsT = {
  selectedProperties: string[];
  onSelectedColumnSetChanged: (columnSet: IColumnSet) => void;
  dataSources: DataSourceSetting;
  onSourcesChanged: (source: DataSourceSetting) => void;
  rows: string[];
  columns: IColumns[];
};

const DataTableActions: FC<DataTableActionsT> = ({
  selectedProperties,
  onSelectedColumnSetChanged,
  dataSources,
  onSourcesChanged,
  rows,
  columns
}) => {
  // context
  const { selectedPdenSource, selectedForecastFolder, selectedRescat, entityKind } =
    useGroupByState();

  // state from store
  const [{ overflowindex }] = useTable();

  // state
  const [columnSets, setColumnSets] = useState<IColumnSet[]>([]);
  const [selectedColumnSet, setSelectedColumnSet] = useState<IColumnSet>();
  const [overflowVisible, setOverflowVisible] = useState(false);

  // handlers
  function handleColumnSelection(columnSet) {
    setSelectedColumnSet(columnSet);
    onSelectedColumnSetChanged && onSelectedColumnSetChanged(columnSet);
  }

  const { data, isFetching } = useQuery(
    "getColumnSets",
    () => getColumnSets(entityKind),
    {
      enabled: true
    }
  );

  useEffect(() => {
    if (!data?.data) return;
    setColumnSets(data.data);
  }, [data]);

  useEffect(() => {
    onSourcesChanged &&
      onSourcesChanged({
        forecastSource: selectedForecastFolder,
        pdenSource: selectedPdenSource,
        rescat: selectedRescat
      });
  }, [selectedPdenSource, selectedForecastFolder, selectedRescat]);

  const components = [
    <SaveButton
      key="Save"
      selectedColumnSet={selectedColumnSet}
      selectedProperties={selectedProperties}
    />,
    <DeleteButton
      key="Delete"
      setSelectedColumnSet={setSelectedColumnSet}
      selectedColumnSet={selectedColumnSet}
    />,
    <ExportButton
      key="Export"
      selectedProperties={selectedProperties}
      dataSources={dataSources}
    />,
    <CopyButton key="Copy" rows={rows} columns={columns} />
  ];

  function getVisibleButtons() {
    if (overflowindex > 2) {
      return components;
    } else if (overflowindex == 2) {
      return components.slice(0, 1);
    }
    return null;
  }

  function getHiddenButtons() {
    if (overflowindex === 1) {
      return components;
    } else if (overflowindex === 2) {
      return components.slice(1, components.length);
    }
    return null;
  }

  return (
    <ActionsContainer>
      <DropDownContainer>
        <ColumnSetDropdownWrapper>
          <BaseDropdown
            value={selectedColumnSet}
            labelKey={"title"}
            onChange={handleColumnSelection}
            options={columnSets}
            inlineLabel={selectedColumnSet ? undefined : "Saved columns"}
            isLoading={isFetching}
          />
        </ColumnSetDropdownWrapper>
        {entityKind == EntityKind.Well && (
          <PdenSourceWrapper>
            <PdenSourceSelect showLabel={false} showGroup={true} />
            <ForecastSourceSelect showGroup={true} showLabel={false} />
          </PdenSourceWrapper>
        )}
      </DropDownContainer>
      <ButtonContainer>
        {getVisibleButtons()}
        {overflowindex >= 1 && overflowindex < 3 && (
          <Popover
            arrowPointAtCenter
            content={<OverflowWrapper>{getHiddenButtons()}</OverflowWrapper>}
            placement="bottomRight"
            trigger="click"
            open={overflowVisible}
            onOpenChange={(v) => setOverflowVisible(v)}>
            <Tooltip title="More" mouseEnterDelay={0.6}>
              <ActionButton>
                <MoreVert fontSize="large" />
              </ActionButton>
            </Tooltip>
          </Popover>
        )}
      </ButtonContainer>
    </ActionsContainer>
  );
};
export default DataTableActions;

const ButtonContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 36px;
  align-items: flex-end;
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ColumnSetDropdownWrapper = styled.div`
  max-width: 150px;
  min-width: 20px;
  overflow: hidden;

  & .BaseDropdown {
    max-width: 100%;
  }
`;
const ActionButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  background: transparent;
  border: none;
  color: #a2aaad;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;

const PdenSourceWrapper = styled.div`
  width: 350px;
  padding-left: 5px;
  display: grid;
  grid-template-columns: minmax(0, 120px) 1fr;
  gap: 5px;
`;

const OverflowWrapper = styled.div`
  display: grid;
  max-height: 300px;
  border-radius: var(--border-radius);
  padding: 8px 12px;
  margin: -12px -16px;
  overflow: hidden scroll;

  & > button {
    width: auto !important;
  }
`;
