import { createContext, useEffect, useState } from "react";

export const CollapseContext = createContext(null);

type CollapseT = {
  children?: React.ReactNode;
  defaultActive?: string[];
  onChange?: (keys: string[]) => void;
};

function Collapse({
  children,
  defaultActive = ["focus", "focusBin", "activeFilter"],
  onChange = () => null
}: CollapseT) {
  const [active, setActive] = useState(defaultActive);

  // trigger onChange when active Panels change
  useEffect(() => onChange(active), [onChange, active]);

  const contextValue = {
    active,
    setActive
  };

  return (
    <CollapseContext.Provider value={contextValue}>{children}</CollapseContext.Provider>
  );
}

export default Collapse;
