import { useState } from "react";
import { toast } from "react-toastify";

import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "antd";
import axios from "axios";

import PostForm from "./PostForm";
import PostView from "./PostView";
import { ModalFormWrapper as Wrapper } from "./SharedStyles";
import { DELETE_FILE_URL, POST_URL } from "./constants";
import { hideNewPostModal, usePost } from "./context";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function CreatePost(): JSX.Element {
  // context and dispatch
  const [{ newPostModalVisible }, dispatch] = usePost();

  // state
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);

  function handleTitleChange(newTitle) {
    setTitle(newTitle);
  }

  function handleContentChange(newContent) {
    setContent(newContent);
  }

  function addFile(newFile) {
    setFiles((prevState) => [...prevState, newFile]);
  }

  function replace(before, after) {
    setContent((content) => {
      return content.replace(before, after);
    });
  }

  function cleanFiles() {
    const unreferencedFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (!content.includes(files[i])) {
        unreferencedFiles.push(files[i]);
        axios.delete(DELETE_FILE_URL + "/" + files[i], {});
      }
    }
    const temp = [...files];
    return temp.filter((item) => !unreferencedFiles.includes(item));
  }

  function submitPost() {
    const params = {
      title: title,
      tag: tags,
      content: content,
      attachments: cleanFiles()
    };
    if (params.title === "" || params.tag.length === 0 || params.content === "") {
      toast.warning("Please fill all fields");
      return;
    }
    axios
      .post(POST_URL, params)
      .then(() => {
        toast.success("Post successfully submitted.");
        setTitle("");
        setContent("");
        setTags([]);
        setFiles([]);
        hideNewPostModal(dispatch);
      })
      .catch(() => {
        toast.error("An error occurred when submitting the post.");
      });
  }

  return (
    <Modal
      centered
      closeIcon={<CloseIcon className="anticon" fontSize="large" />}
      footer={null}
      maskClosable={false}
      title="Add new post"
      wrapClassName="new-post-modal"
      open={newPostModalVisible}
      onCancel={() => hideNewPostModal(dispatch)}>
      <Wrapper>
        <PostForm
          title={title}
          tags={tags}
          content={content}
          replaceContent={replace}
          addFile={addFile}
          onTitleChange={handleTitleChange}
          onTagSelection={(v) => setTags(v)}
          onContentChange={handleContentChange}
          onSubmit={submitPost}
        />

        <PostView title={title} tags={tags} content={content} />
      </Wrapper>
    </Modal>
  );
}

export default CreatePost;
