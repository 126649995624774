// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useQuery } from "@apollo/client";
import { Edit } from "@material-ui/icons";
import { mdiDelete, mdiDotsVertical, mdiFormTextbox } from "@mdi/js";
import { Popconfirm, Popover } from "antd";
import { TYPE_WELLS } from "constants/settings.constants";
import _debounce from "lodash/debounce";
import { ICheckedForecast, setCheckedGlobalTypeWells } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useUser } from "hooks";

import { WORKSPACES, WorkspaceClientName } from "api/workspace";

import { WorkspaceQuery } from "models/workspace";

import { useForecastFolderUpdater } from "components/arps/hooks";
import { Tooltip } from "components/base";
import { TypeWellEditorWidgetKey } from "components/dashboard/constants/widgets.constants";
import { useDashboardContext } from "components/dashboard/hooks";
import { ColorEditor } from "components/shared";
import { DefaultTypeWellDashboard } from "components/user-settings/models";
import { useWorkspaceDispatch } from "components/workspace/hooks/useWorkspaceDispatch";

import { useDeleteForecast } from "../../arps/hooks/useDeleteForecast";
import { useSelectedProjectPermissions } from "../projects/hooks";
import ExportForecastsButton, { WellSource } from "./ExportForecastsButton";

export default function ForecastNodeActions({
  fcstTwNode,
  type,
  source,
  scrollToTreeNode,
  widgetProps,
  setNameField,
  setChecked,
  setError
}) {
  const dispatch = useDispatch();
  const workspaceDispatch = useWorkspaceDispatch();
  const { settings } = useUser();
  let checkedForecasts = useSelector(
    (state: RootState) =>
      state.arps[type === TYPE_WELLS ? "checkedTypeWells" : "checkedForecasts"]
  );

  const filteredForecastFolders = useSelector(
    (state: RootState) => state.arps.filteredTypeWellFolders
  );

  checkedForecasts = checkedForecasts
    .map((forecast) =>
      filteredForecastFolders
        .flatMap((folder) => folder.children)
        .find((child) => child.id === forecast.id)
    )
    .filter(Boolean) as ICheckedForecast[];

  const checkedTypeWellStylesRef = useRef({});
  const { refetch: refetchWorkspace } = useQuery<WorkspaceQuery>(WORKSPACES, {
    context: {
      clientName: WorkspaceClientName
    },
    notifyOnNetworkStatusChange: true
  });

  const deleteForecast = useDeleteForecast(type);

  const selectedProjectPermissions = useSelectedProjectPermissions();

  function setNodeToEditable() {
    const cloneNode = { ...fcstTwNode };
    cloneNode.isEdit = true;
    cloneNode.editLocation = source;
    setNameField(cloneNode.title);
    updateTypeWellDataWithParentNode(cloneNode);
  }

  const { dashboard } = useDashboardContext();
  const hasTypeWellEditorWidget = useMemo(
    () => dashboard.widgets.find((widget) => widget.type === TypeWellEditorWidgetKey),
    [dashboard]
  );

  const { updateTypeWellDataWithParentNode } = useForecastFolderUpdater();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  async function SwitchToTwEditorDashboard(defaultDashboard: DefaultTypeWellDashboard) {
    const { data: workspacedata } = await refetchWorkspace();
    const errorMessage =
      "Default type well dashboard does not exist. Please set a default type well dashboard in your User Settings";

    if (!settings.defaults.typeWellDashboard) {
      const defaultWorkspace = workspacedata.workspaces?.find(
        (workspace) => workspace.title === "Explore"
      );
      const dashboard = defaultWorkspace?.dashboards?.find((dashboard) => {
        return dashboard.title === "Oil TW Editor";
      });
      if (dashboard) {
        workspaceDispatch({
          payload: {
            activeDashboardId: dashboard.dashboardId,
            workspaceId: defaultWorkspace.workspaceId
          }
        });
      } else {
        toast.error(errorMessage);
      }
      return;
    }
    const dashboardExists = workspacedata.workspaces?.find((workspace) => {
      return (
        workspace.workspaceId === defaultDashboard.workspaceId &&
        workspace.dashboards?.find((dashboard) => {
          return dashboard.dashboardId === defaultDashboard.dashboardId;
        })
      );
    });
    if (dashboardExists) {
      workspaceDispatch({
        payload: {
          activeDashboardId: defaultDashboard.dashboardId,
          workspaceId: defaultDashboard.workspaceId
        }
      });
    } else {
      toast.error(errorMessage);
    }
  }

  const updateColors = useCallback(() => {
    const checkedCopy = [...checkedForecasts];
    let j = 0;
    if (Object.keys(checkedTypeWellStylesRef.current).length === 0) {
      return;
    }
    for (const tw of checkedForecasts) {
      if ((tw?.children ?? []).length > 0) {
        //checked folder so search each child
        let i = 0;
        const childrenCopy = [...tw.children];
        for (const child of tw.children) {
          if (checkedTypeWellStylesRef.current[child.id]) {
            const style = checkedTypeWellStylesRef.current[child.id];
            const checked = Object.assign({}, child);
            checked.color = style.color;
            checked.thickness = style.thickness;
            childrenCopy.splice(i, 1, checked);
            const twCopy = Object.assign({}, tw);
            twCopy.children = childrenCopy;
            checkedCopy.splice(j, 1, twCopy);
          }
          i++;
        }
      } else if (checkedTypeWellStylesRef.current[tw.id]) {
        const style = checkedTypeWellStylesRef.current[tw.id];
        const checked = Object.assign({}, tw);
        checked.color = style.color;
        checked.thickness = style.thickness;
        checkedCopy.splice(j, 1, checked);
      }
      j++;
    }

    dispatch(setCheckedGlobalTypeWells(checkedCopy));
  }, [checkedForecasts, dispatch]);

  const updateColorsDebounce = useMemo(
    () => _debounce(updateColors, 1500),
    [updateColors]
  );

  function updateTypeWellColor(color, thickness) {
    //set local storage color for type well
    localStorage?.setItem(
      "tw-" + fcstTwNode.id,
      JSON.stringify({ color: color, thickness: thickness })
    );
    if (!checkedTypeWellStylesRef.current[fcstTwNode.id]) {
      checkedTypeWellStylesRef.current[fcstTwNode.id] = {};
    }
    checkedTypeWellStylesRef.current[fcstTwNode.id] = {
      id: fcstTwNode.id,
      color,
      thickness
    };
    updateColorsDebounce();

    const cloneNode = { ...fcstTwNode };
    cloneNode.color = color;
    cloneNode.thickness = thickness;
    updateTypeWellDataWithParentNode(cloneNode);
  }

  const TypeWellFolderMoreOptions = () => {
    return (
      <ActionListWrapper>
        {type === TYPE_WELLS && (
          <TypeWellAction
            disabled={!selectedProjectPermissions.canEditTypeWells}
            onClick={(evt) => {
              if (!selectedProjectPermissions.canEditTypeWells) return;

              setNodeToEditable();
              evt.stopPropagation();
              evt.preventDefault();
            }}>
            <Icon path={mdiFormTextbox} size={1} />
            Rename
          </TypeWellAction>
        )}
        <Popconfirm
          disabled={!selectedProjectPermissions.canEditTypeWells}
          placement="bottom"
          onConfirm={(evt) => {
            deleteForecast
              .delete([
                {
                  folderId: fcstTwNode.folderId,
                  id: fcstTwNode.id,
                  type: fcstTwNode.type ?? "forecast"
                }
              ])
              .then(async () => {
                setError(null);
                return;
              })
              .catch((err) => {
                setError(`Error deleting. ${err.message}`);
              });
            evt.stopPropagation();
            evt.preventDefault();
          }}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Delete"
          okType="danger"
          title={`Are you sure you want to delete the forecast`}>
          <TypeWellAction
            disabled={!selectedProjectPermissions.canEditTypeWells}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
            danger={true}>
            <Icon path={mdiDelete} size={1} />
            Delete
          </TypeWellAction>
        </Popconfirm>
        {type === TYPE_WELLS && (
          <ExportForecastsButton
            wellSource={WellSource.TypeWells}
            node={fcstTwNode}
            type="forecast"
          />
        )}
      </ActionListWrapper>
    );
  };

  return (
    <ColorEditorWrapper>
      {!fcstTwNode.source && type == "TYPEWELLS" && (
        <ForecastActionButton
          className={"visible-on-hover"}
          onClick={(e) => {
            e.stopPropagation();
            if (widgetProps) {
              widgetProps.setWidgetActiveTabKey();
            }
            if (!hasTypeWellEditorWidget) {
              SwitchToTwEditorDashboard(settings.defaults.typeWellDashboard);
            }
            setChecked(checkedForecasts, fcstTwNode);
            setTimeout(() => {
              scrollToTreeNode(fcstTwNode.key);
            }, 500);
          }}>
          <Edit fontSize="large" />
        </ForecastActionButton>
      )}
      <Tooltip className={"visible-on-hover"} title="More">
        <Popover
          arrowPointAtCenter={true}
          onOpenChange={(isOpen) => {
            setIsPopoverOpen(isOpen);
          }}
          content={TypeWellFolderMoreOptions()}
          open={isPopoverOpen}
          overlayClassName="popover-no-padding"
          placement="bottomLeft"
          trigger="click">
          <ForecastActionButton
            data-testid="forecast-more-action-button"
            type={"text"}
            onClick={(event) => event.stopPropagation()}>
            <Icon path={mdiDotsVertical} size={1} />
          </ForecastActionButton>
        </Popover>
      </Tooltip>
      {type == "TYPEWELLS" && (
        <ColorEditor
          data-testid="forecast-node-color-edit-button"
          isDisabled={!selectedProjectPermissions.canEditTypeWells}
          onChange={updateTypeWellColor}
          color={
            //load settings from local storage
            localStorage.getItem("tw-" + fcstTwNode.id)
              ? JSON.parse(localStorage.getItem(`tw-${fcstTwNode.id}`))?.color
              : fcstTwNode.color
          }
          thickness={
            //load settings from local storage
            localStorage.getItem("tw-" + fcstTwNode.id)
              ? JSON.parse(localStorage.getItem(`tw-${fcstTwNode.id}`))?.thickness
              : fcstTwNode.thickness
          }
        />
      )}
    </ColorEditorWrapper>
  );
}

const ColorEditorWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .title-reservecategory {
    display: block;
    background-color: #dfdfdf;
    padding: 1px 3px;
    margin-right: 5px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionListWrapper = styled.div`
  padding: 6px;
`;

const ForecastActionButton = styled.button`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 3px;
  cursor: pointer;
  background: transparent;
  color: ${(props) => props.color?.toString() ?? "#a2aaad"};

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled]:hover > svg {
    cursor: default;
    color: lightgray;
  }
`;

const TypeWellAction = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    color: ${(props) =>
      props.disabled
        ? "var(--color-text-50)"
        : props.danger
        ? "var(--color-danger)"
        : "var(--color-primary)"};
  }
`;
