import { FC } from "react";

import { Button, Collapse, Form } from "antd";
import { groupBy } from "lodash";
import _debounce from "lodash/debounce";
import styled from "styled-components/macro";
import {
  checkKeyValuePairMatch,
  excludePropertiesByKeys,
  getFirstPropertyName
} from "utils/objects";

import { useLocalStorage } from "hooks";

import {
  useScreenshotCalculatedDefaults,
  useScreenshotContext,
  useScreenshotDispatch
} from "./hooks";
import {
  SettingsControl,
  SettingsGroups,
  useScreenshotSettingsControls
} from "./hooks/useScreenshotSettingsControls";
import { getStoredScreenshotSettingsKey } from "./utils";

const { Panel } = Collapse;

const getSettingsWithExcludedProperty = (settings, objectWithPropertyToRemove) => {
  const propertyKeyToRemove = getFirstPropertyName(objectWithPropertyToRemove);
  const excludedSettings = excludePropertiesByKeys(settings, [propertyKeyToRemove]);

  return excludedSettings;
};

type IScreenshotSettingsForm = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  setFormValues;
};

const defaultActiveKeys = ["Preset", "Quick Actions"];

const ScreenshotSettingsForm: FC<IScreenshotSettingsForm> = ({ form, setFormValues }) => {
  // context
  const calculatedDefaults = useScreenshotCalculatedDefaults();
  const controlGroups = useScreenshotSettingsControls();
  const { widget } = useScreenshotContext();

  // dispatch
  const screenshotDispatch = useScreenshotDispatch();

  // Hooks
  const {
    appendToStoredValue: appendToStoredSettings,
    setStoredValue: setStoredScreenshotSettings,
    getStoredValue: getStoredSettings
  } = useLocalStorage(getStoredScreenshotSettingsKey(widget?.widgetId), undefined);

  // event handlers
  const handleResetToPreset = () => {
    screenshotDispatch({
      payload: {
        isResettingPreset: true
      }
    });
  };

  const debouncedFormChange = _debounce((nextValue) => {
    // Happens when backspacing to reset value to calculated
    const isResettingValueToCalculatedDefault = checkKeyValuePairMatch(
      nextValue,
      calculatedDefaults
    );

    if (isResettingValueToCalculatedDefault) {
      const nextSettings = getSettingsWithExcludedProperty(
        getStoredSettings(),
        nextValue
      );
      setStoredScreenshotSettings(nextSettings);
    } else {
      appendToStoredSettings({ ...nextValue });
    }

    setFormValues((prevObject) => {
      if (isResettingValueToCalculatedDefault) {
        return getSettingsWithExcludedProperty(prevObject, nextValue);
      } else {
        return {
          ...prevObject,
          ...nextValue
        };
      }
    });

    screenshotDispatch({
      payload: {
        isEditing: true
      }
    });

    const nextPropertyName = getFirstPropertyName(nextValue);
    screenshotDispatch({
      payload: {
        currentFieldKey: nextPropertyName
      }
    });
  }, 400);

  const handleFormChange = (nextValue, allValues) => {
    debouncedFormChange(nextValue, allValues);
  };

  const handleFormInputOnBlur = () => {
    screenshotDispatch({
      payload: {
        isEditing: false
      }
    });

    screenshotDispatch({
      payload: {
        currentFieldKey: ""
      }
    });
  };

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="horizontal"
      onValuesChange={handleFormChange}
      onBlur={handleFormInputOnBlur}>
      <Collapse defaultActiveKey={defaultActiveKeys}>
        {Object.entries(groupBy(controlGroups, "group")).map((group) => {
          const key = group[0];
          const label = key;
          const controls = group[1] as SettingsControl[];
          return (
            <Panel header={label} key={key}>
              {controls.map((sc) => sc.control)}
              {label === SettingsGroups.preset && (
                <PresetActionsContainer>
                  <Button
                    type="primary"
                    onClick={handleResetToPreset}
                    data-testid="reset-screenshot-defaults"
                    data-qa="reset-screenshot-defaults">
                    Restore Defaults
                  </Button>
                </PresetActionsContainer>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </Form>
  );
};

export default ScreenshotSettingsForm;

const PresetActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
