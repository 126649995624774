import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { Add, Close, Error } from "@material-ui/icons";
import { Input } from "antd";
import { hide } from "store/features/userDocumentation/documentationSlice";
import styled from "styled-components/macro";

import { useUser } from "hooks";

import {
  GetUserDocumentationPostsRequest,
  getUserDocumentationPosts
} from "api/userDocumentation";

import CreatePost from "./CreatePost";
import PostPreview from "./PostPreview";
import TagSelect from "./TagSelect";
import {
  appendToPostList,
  setPostList,
  showNewPostModal,
  updateTotalPosts,
  usePost
} from "./context";

const page_size = 6;

type DocumentationAreaT = {
  openDocInNewTab?: boolean;
  closeable?: boolean;
};
const DocumentationArea: FunctionComponent<DocumentationAreaT> = ({
  openDocInNewTab = true,
  closeable = true
}) => {
  // context and dispatch
  const dispatch = useDispatch();
  const [{ posts, totalPosts }, postDispatch] = usePost();
  const { user, isAdmin } = useUser();

  // state
  const [allPostsLoaded, setAllPostsLoaded] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const [loadPostsError, setLoadPostsError] = useState("");

  const options = {};
  const [ref, inView] = useInView(options);

  const getPostsMutation = useMutation(
    (request: GetUserDocumentationPostsRequest) => getUserDocumentationPosts(request),
    {
      onError: (error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err && err.response?.data) {
          // eslint-disable-next-line no-console
          console.error("Get User Doc error", err.response?.data);
        }

        setPostList(postDispatch, []);
        updateTotalPosts(postDispatch, 0);
        setLoadPostsError("Failed to load notifications");
      }
    }
  );

  // Take action when a project is loaded.
  useEffect(() => {
    if (!getPostsMutation.data?.data) return;
    setLoadPostsError("");

    const loadedPosts = getPostsMutation.data?.data?.posts ?? [];

    if (getPostsMutation.variables.pageIndex > 1) {
      appendToPostList(postDispatch, loadedPosts);
    } else {
      setPostList(postDispatch, loadedPosts);
    }

    // Set the current page number to the loaded index.
    setPageNum(getPostsMutation.variables.pageIndex);

    if (getPostsMutation.data?.data) {
      updateTotalPosts(postDispatch, getPostsMutation.data?.data?.count);
    }
  }, [getPostsMutation.data, postDispatch]);

  const retrievePosts = useCallback(
    (append = false) => {
      let query = "";
      query += tags.length ? `?tag=${tags.join(",")}` : "";

      if (search !== "") {
        if (query === "") {
          query += `?search=${search}`;
        } else {
          query += `&search=${search}`;
        }
      }

      // If appending we want the next page, otherwise take the first.
      const pageIndex = append ? pageNum + 1 : 1;

      getPostsMutation.mutate({
        pageIndex: pageIndex,
        pageSize: page_size,
        query: query
      });
    },
    [tags, search, getPostsMutation]
  );

  useEffect(() => {
    retrievePosts();
  }, [tags]);

  const handleUserInput = () => (e) => {
    if (e.keyCode == 13) {
      retrievePosts();
    }
  };

  useEffect(() => {
    setAllPostsLoaded(posts?.length >= totalPosts);
  }, [posts, totalPosts]);

  // derived state
  const isSystemAdmin =
    isAdmin &&
    (user?.organization?.emailDomain === "mcdan.com" ||
      user?.organization?.emailDomain === "turinganalytics.net");

  useEffect(() => {
    if (inView && !allPostsLoaded) {
      retrievePosts(true);
    }
  }, [inView]);

  return (
    <>
      <DocumentationContent>
        <DocumentationHeader>
          {closeable ? (
            <DocumentationTitleRow>
              <DocumentationTitle>Notifications</DocumentationTitle>

              <DocumentationHideButton onClick={() => dispatch(hide())}>
                <Close fontSize="large" />
              </DocumentationHideButton>
            </DocumentationTitleRow>
          ) : null}

          {isSystemAdmin && (
            <NewPostButton
              data-testid="newPost-button"
              onClick={() => showNewPostModal(postDispatch)}>
              <Add fontSize="large" />
              New
            </NewPostButton>
          )}

          <Input
            allowClear
            value={search}
            autoFocus
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            onKeyDown={handleUserInput()}
          />

          <TagSelect
            placeholder="Filter by tag"
            value={tags}
            onChange={(v) => setTags(v)}
          />
        </DocumentationHeader>

        {loadPostsError !== "" && (
          <ErrorContainer>
            <Error style={{ top: 3 }} /> {loadPostsError}
          </ErrorContainer>
        )}

        <DocumentationBody id="posts-list">
          {posts.map((post, index) => {
            const lastElement = index === posts.length - 1;
            return (
              <div key={index} ref={lastElement ? ref : null}>
                <PostPreview
                  title={post.title}
                  date={post.date}
                  content={post.content}
                  id={post.id}
                  onUpdate={retrievePosts}
                  openDocInNewTab={openDocInNewTab}
                />
              </div>
            );
          })}
        </DocumentationBody>
      </DocumentationContent>

      <CreatePost />
    </>
  );
};

export default DocumentationArea;

const DocumentationContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DocumentationHeader = styled.div`
  display: grid;
  gap: 12px;
  background-color: #f4f6f8;
  backdrop-filter: blur(20px);
  border-bottom: 1px solid var(--color-text-10);
  padding: 16px 20px;
  z-index: 99;
`;

const DocumentationTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DocumentationTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: var(--color-text);
`;

const DocumentationBody = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const NewPostButton = styled.button`
  height: 32px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--color-text-80);
  background-color: var(--color-text-06);
  border-radius: var(--border-radius);
  border: none;
  font-weight: 600;
  padding: 0px 10px;
  padding-left: 6px;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-text-10);
  }
`;

const DocumentationHideButton = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vmax;
  color: var(--color-text-60);
  transition: color 0.2s;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
  }
`;

const ErrorContainer = styled.div`
  padding: 8px;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
