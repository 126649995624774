import { css } from "styled-components/macro";

export const visibilityToggleStyles = css`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: visibility 0s var(--ease) 0.5s, opacity 0.2s var(--ease);

  &.visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    pointer-events: all;
  }
`;
