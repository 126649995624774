import { useUserContext } from "components/user/context";
import { IUserState } from "components/user/context/UserContext";

const useUser = (): IUserState => {
  const [state] = useUserContext();

  return state;
};

export default useUser;
