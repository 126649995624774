import { Rescat } from "api/userForecasts";

import { GroupByValueT } from "../groupBy";

const SYNC_PATTERN = /M[A|S](\d{2})_.{4,10}/;
export function isSyncFolder(name: string): boolean {
  const isSync = SYNC_PATTERN.test(name);
  return isSync;
}

export const RESERVE_CATEGORIES = [
  "PDP",
  "PD",
  "TP",
  "P+PDP",
  "P+PD",
  "TPP",
  "P+P+PDP",
  "P+P+PD",
  "TPPP"
];

export function rescatStringToNumber(rescat: Rescat) {
  switch (rescat) {
    case "PDP":
      return "1111";
    case "DP":
      return "1211";
    case "TP":
      return "1311";
    case "P+PDP":
      return "2111";
    case "P+PD":
      return "2211";
    case "TPP":
      return "2311";
    case "P+P+PDP":
      return "3111";
    case "P+P+PD":
      return "3211";
    case "TPPP":
      return "3311";
    default:
      return "2111";
  }
}

export function numberToRescatString(rescat: string): Rescat {
  switch (rescat) {
    case "1111":
      return "PDP";
    case "1211":
      return "DP";
    case "1311":
      return "TP";
    case "2111":
      return "P+PDP";
    case "2211":
      return "P+PD";
    case "2311":
      return "TPP";
    case "3111":
      return "P+P+PDP";
    case "3211":
      return "P+P+PD";
    case "3311":
      return "TPPP";
    default:
      return "P+PDP";
  }
}
export const getSyncedSchemaFromProperty = (value?: GroupByValueT): null | string => {
  if (!value) {
    return null;
  }
  const pattern = /.+.fcst.(M[A|S].{2}_.+)\..+/gm;
  const match = pattern.exec(value.property);
  if (match && match.length > 1) {
    return match[1];
  }
  return null;
};
