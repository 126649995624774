// eslint-disable-next-line import/no-named-as-default
import { useCallback, useEffect, useRef, useState } from "react";

import { Button, Input, Select } from "antd";
import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import styled from "styled-components";

import { useCategories } from "hooks";

import { WellPerformanceDataAnalyzerResult } from "api/appTools";

import { GroupByProvider } from "components/groupBy/group-by.context";

import { PerformanceDataAnalyzerTable } from "./components";
import { useSelectedWellFromStore, useWellPerformanceDataAnalyzer } from "./hooks";

const PerformanceDataAnalyzer = () => {
  // State
  const [uwi, setUwi] = useState("");
  const [product, setProduct] = useState("Oil");
  const [column, setColumn] = useState("EUR");
  const [data, setData] = useState<WellPerformanceDataAnalyzerResult>(null);
  const [analyzerError, setAnalyzerError] = useState<string>(null);
  const [analyzerErrorDetails, setAnalyzerErrorDetails] = useState<string>(null);

  // Refs
  const uwiInputRef = useRef(null);

  // Hooks
  const selectedWell = useSelectedWellFromStore();
  const { categories } = useCategories();
  const { mutate, isLoading, isError } = useWellPerformanceDataAnalyzer(
    (data) => {
      setData(data);
    },
    (err) => {
      if (err.response?.data) {
        setAnalyzerErrorDetails(err.response?.data);
      } else {
        setAnalyzerErrorDetails(err.message);
      }
      setAnalyzerError("Failed to analyze performance data");
    }
  );

  // Callbacks
  const handleUwiChange = useCallback((e) => {
    setUwi(e.target.value);
  }, []);

  const startAnalysis = useCallback(() => {
    setAnalyzerError(null);
    mutate({
      uwi,
      settings: {
        performanceProperty: `Performance.${product}.${column}`,
        product: ""
      }
    });
  }, [uwi, product, column]);

  // Effects
  useEffect(() => {
    if (uwiInputRef.current) {
      uwiInputRef.current.focus();
    }
  }, [uwiInputRef]);

  useEffect(() => {
    setUwi(selectedWell?.Uwi);
  }, [selectedWell?.Uwi]);

  const products = Array.from(
    new Set(
      categories
        .filter((x) => x.name === "Performance")[0]
        .columns.map((c) => {
          const parts = c.property.split(".");
          if (parts.length < 3) return c.product;

          return parts[1];
        })
    )
  ).map((product) => {
    const key = product.replace("+", "");
    const prod = ALL_PRODUCT_TYPES[key];

    return {
      key: product,
      value: product,
      label: prod?.label ?? product
    };
  });

  const columns = categories
    .filter((x) => x.name === "Performance")[0]
    .columns.filter((c) => c.product === "Oil")
    .map((column) => ({
      key: column.property.replace(`Performance.Oil.`, ""),
      value: column.property.replace(`Performance.Oil.`, ""),
      label: column.title
    }));

  return (
    <GroupByProvider>
      <p>
        The Performance Data Analyzer allows for comparing the various performance data
        sources, including forecast sources. Its also allows comparing stored data vs
        cached data.
      </p>
      <SettingsContainer>
        <SettingWrapper>
          <SettingTitle>Product</SettingTitle>
          <SettingInputWrapper>
            <StyledSelect
              value={product}
              onSelect={setProduct}
              options={products}
              showSearch={true}
            />
          </SettingInputWrapper>
        </SettingWrapper>
        <SettingWrapper>
          <SettingTitle>Performance Column</SettingTitle>
          <SettingInputWrapper>
            <StyledSelect value={column} onSelect={setColumn} options={columns} />
          </SettingInputWrapper>
        </SettingWrapper>
        <SettingWrapper>
          <SettingTitle>UWI</SettingTitle>
          <SettingInputWrapper>
            <Input
              ref={uwiInputRef}
              value={uwi}
              onChange={handleUwiChange}
              placeholder="UWI to analyze data for"
            />
          </SettingInputWrapper>
        </SettingWrapper>
      </SettingsContainer>
      <ActionsContainer>
        <Button
          type="primary"
          disabled={!uwi}
          loading={isLoading && !isError}
          onClick={() => startAnalysis()}>
          {isLoading && !isError ? "Analyzing" : "Start Analysis"}
        </Button>
      </ActionsContainer>
      <PerformanceDataAnalyzerTable
        data={data}
        isLoading={isLoading}
        analyzerError={analyzerError}
        analyzerErrorDetails={analyzerErrorDetails}
      />
    </GroupByProvider>
  );
};

export default PerformanceDataAnalyzer;

const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const SettingWrapper = styled.div`
  width: 100%;
`;

const SettingTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const SettingInputWrapper = styled.div`
  margin-top: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
  padding-top: 20px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
