import { memo } from "react";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { EChartsType } from "hd-echarts/echarts";

import { lineCoordinates } from "models/chart";

import { convertPercentageToPixels } from "components/chart/utils/convertPercentageToPixels";
import { useScreenshotDispatch } from "components/screenshot/hooks";

import {
  updateHalfSlopePosition,
  updateQuarterSlopePosition,
  updateUnitSlopePosition,
  useChartDispatch,
  useChartState
} from "../../context";
import ToolbarButton from "../ToolbarButton";

const ScreenshotToggle = () => {
  const screenshotDispatch = useScreenshotDispatch();
  const chartDispatch = useChartDispatch();
  const {
    screenshot,
    id,
    request,
    response,
    instance,
    settings,
    unitSlopePosition,
    quarterSlopePosition,
    halfSlopePosition
  } = useChartState();

  // update screenshot state when legend is toggled
  const toggle = () => (value) => {
    const options = instance.getOption();
    const referenceLines = settings.referenceLine?.lines;

    if (settings.showUnitSlope) {
      const updatedUnitSlope = UpdateSlopeLinePositions(
        unitSlopePosition,
        instance,
        options
      );
      updateUnitSlopePosition(chartDispatch, updatedUnitSlope);
    }
    if (settings.showHalfSlope) {
      const updatedHalfSlope = UpdateSlopeLinePositions(
        halfSlopePosition,
        instance,
        options
      );
      updateHalfSlopePosition(chartDispatch, updatedHalfSlope);
    }
    if (settings.showQuarterSlope) {
      const updatedQuarterSlope = UpdateSlopeLinePositions(
        quarterSlopePosition,
        instance,
        options
      );
      updateQuarterSlopePosition(chartDispatch, updatedQuarterSlope);
    }

    // Save the grid coordinates of the current reference lines for scaling
    if (referenceLines?.length) {
      if (value) {
        referenceLines.map((line) => {
          const point1 = convertPercentageToPixels(
            [line.point1.x, line.point1.y],
            instance,
            options.grid[0]
          );
          const point2 = convertPercentageToPixels(
            [line.point2.x, line.point2.y],
            instance,
            options.grid[0]
          );
          line.point1.chartX = instance.convertFromPixel("grid", point1)[0];
          line.point1.chartY = instance.convertFromPixel("grid", point1)[1];
          line.point2.chartX = instance.convertFromPixel("grid", point2)[0];
          line.point2.chartY = instance.convertFromPixel("grid", point2)[1];
        });
        const newReferenceLine = {
          points: settings.referenceLine?.points,
          lines: referenceLines,
          useChartValues: true
        };
        const next = { ...settings, referenceLine: newReferenceLine };
        chartDispatch({ type: "settings", payload: next });
      } else {
        const next = {
          ...settings,
          referenceLine: { ...settings.referenceLine, useChartValues: false }
        };
        chartDispatch({ type: "settings", payload: next });
      }
    }

    const widget = value
      ? {
          widgetId: id,
          widgetComponentType: "chart"
        }
      : null;

    const widgetState = value
      ? {
          request,
          response
        }
      : null;

    const next = { ...screenshot, visible: value };
    screenshotDispatch({
      payload: {
        settings: next.preset,
        widget,
        widgetState
      }
    });
  };

  const UpdateSlopeLinePositions = (
    linePosition: lineCoordinates,
    instance: EChartsType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any
  ) => {
    if (linePosition?.p1?.x) {
      const point1 = convertPercentageToPixels(
        [linePosition.p1.x, linePosition.p1.y],
        instance,
        options.grid[0]
      );
      const point2 = convertPercentageToPixels(
        [linePosition.p2.x, linePosition.p2.y],
        instance,
        options.grid[0]
      );

      instance.setOption(options, { notMerge: true });
      const chartPoint1 = instance.convertFromPixel("grid", point1);
      const chartPoint2 = instance.convertFromPixel("grid", point2);

      const updatedLinePosition = {
        ...linePosition,
        chartP1: { x: chartPoint1[0], y: chartPoint1[1] },
        chartP2: { x: chartPoint2[0], y: chartPoint2[1] }
      };

      return updatedLinePosition;
    }
  };

  return (
    <ToolbarButton
      key="screenshot"
      active={screenshot.visible}
      icon={<CameraAltIcon fontSize="large" />}
      overflowLabel="Screenshot"
      tooltipText="Screenshot"
      onToggle={toggle()}
    />
  );
};

export default memo(ScreenshotToggle);
