import { useState } from "react";

import { Button, Input } from "antd";
import styled from "styled-components";

import { UserArpsItem } from "models/UserArpsModel";

import { useAddCommentMutation } from "./useAddCommentMutation";

interface TypeWellCommentFieldProps {
  selectedTypeWell: UserArpsItem;
}

function TypeWellCommentField({ selectedTypeWell }: TypeWellCommentFieldProps) {
  const [commentInput, setCommentInput] = useState("");

  const [addComment] = useAddCommentMutation({
    commentInput,
    selectedTypeWellId: selectedTypeWell?.id,
    onCompleted: () => {
      setCommentInput("");
    }
  });

  return (
    <FieldContainer>
      <Input.TextArea
        placeholder="Add a comment"
        autoSize={{ minRows: 1, maxRows: 6 }}
        value={commentInput}
        onChange={(e) => setCommentInput(e.target.value)}
      />
      {!!commentInput && (
        <>
          <SubmitButton onClick={addComment} visible={!!commentInput}>
            Submit
          </SubmitButton>
          <CancelButton onClick={() => setCommentInput("")} visible={!!commentInput}>
            Cancel
          </CancelButton>
        </>
      )}
    </FieldContainer>
  );
}

export default TypeWellCommentField;

const FieldContainer = styled.div`
  display: block;
  padding: 15px 15px 10px 15px;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  background-color: var(--color-primary);
  color: white;
  margin-top: 5px;
  &:focus {
    background-color: var(--ant-primary-color);
    color: white;
  }
  &:hover {
    background-color: white;
    color: black;
  }
`;

const CancelButton = styled(Button)`
  margin-left: 2px;
`;
