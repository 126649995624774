import { useEffect, useRef } from "react";

import { ALL_CHART_TYPES } from "constants/chart.constants";

import { useChartState } from "../context";

const useFieldSelection = (axisType): boolean => {
  const { settings: chartSettings } = useChartState();
  const { chartType, showScatter, showHistogram } = chartSettings;

  const allowFieldSelection = useRef({
    x: false,
    y: false
  });

  useEffect(() => {
    const allowXAxisSelection =
      chartType === ALL_CHART_TYPES.CrossPlot.label ||
      (chartType === ALL_CHART_TYPES.BoxPlot.label && showScatter) ||
      chartType === ALL_CHART_TYPES.Mosaic.label ||
      chartType === ALL_CHART_TYPES.Probit.label ||
      (chartType === ALL_CHART_TYPES.StackedBar.label && !showHistogram) ||
      chartType === ALL_CHART_TYPES.TrendDate.label;
    const allowYAxisSelection =
      chartType === ALL_CHART_TYPES.TrendDate.label ||
      chartType === ALL_CHART_TYPES.CrossPlot.label ||
      chartType === ALL_CHART_TYPES.StackedBar.label ||
      chartType === ALL_CHART_TYPES.BoxPlot.label;

    allowFieldSelection.current = {
      x: allowXAxisSelection,
      y: allowYAxisSelection
    };
  }, [chartType, showScatter, showHistogram]);

  return allowFieldSelection.current[axisType];
};

export default useFieldSelection;
