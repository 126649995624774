import { useDispatch } from "react-redux";

import { setHasViewItemsChanged } from "store/features";

import { useClearFilter } from "components/project/filters/hooks/useClearFilter/useClearFilter";
import { useClearForecasts } from "components/project/forecasts/hooks/useClearForecasts";
import { useClearTypewells } from "components/project/forecasts/hooks/useClearTypeWells";
import { useClearLayers } from "components/project/layers/hooks";
import { useProjectDispatch } from "components/project/projects/context";

interface IClearProjectItems {
  // For the purpose of not overriding filter defaults, as this is used in initial project loading
  // If it gets more complex we can just make a different purposed hook
  isClearingFilter?: boolean;
}

export function useClearProjectItems(
  params: IClearProjectItems = { isClearingFilter: true }
) {
  const dispatch = useDispatch();
  const projectDispatch = useProjectDispatch();

  const clearLayers = useClearLayers();
  const clearForecasts = useClearForecasts();
  const clearTypeWells = useClearTypewells();
  const clearFilter = useClearFilter();

  function clearView(nextProjectId: string) {
    clearLayers.update(nextProjectId);
    clearForecasts.update();
    clearTypeWells.update();

    if (params.isClearingFilter) {
      clearFilter.update(nextProjectId);
    }

    projectDispatch({
      payload: {
        selectedViewId: undefined
      }
    });

    dispatch(setHasViewItemsChanged(false));
  }

  return { update: clearView };
}
