import { memo } from "react";

function RateDate() {
  return (
    <svg
      width="33px"
      height="22px"
      viewBox="0 0 33 22"
      version="1.1"
      id="svg5732"
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs5719">
        <path
          d="M9.33333 5.4L1.16667 5.4L1.16667 3C1.16667 2.6694 1.42858 2.4 1.75 2.4L2.33333 2.4L2.33333 3C2.33333 3.33 2.59583 3.6 2.91667 3.6C3.2375 3.6 3.5 3.33 3.5 3L3.5 2.4L7 2.4L7 3C7 3.33 7.2625 3.6 7.58333 3.6C7.90417 3.6 8.16667 3.33 8.16667 3L8.16667 2.4L8.75 2.4C9.07142 2.4 9.33333 2.6694 9.33333 3L9.33333 5.4ZM8.75 1.2L8.16667 1.2L8.16667 0.6C8.16667 0.27 7.90417 0 7.58333 0C7.2625 0 7 0.27 7 0.6L7 1.2L3.5 1.2L3.5 0.6C3.5 0.27 3.2375 0 2.91667 0C2.59583 0 2.33333 0.27 2.33333 0.6L2.33333 1.2L1.75 1.2C0.785167 1.2 0 2.0076 0 3L0 10.2C0 11.1924 0.785167 12 1.75 12L8.75 12C9.71483 12 10.5 11.1924 10.5 10.2L10.5 3C10.5 2.0076 9.71483 1.2 8.75 1.2L8.75 1.2Z"
          id="path_RateDate1"
        />
        <clipPath id="mask_RateDate1">
          <use href="#path_RateDate1" id="use5716" />
        </clipPath>
      </defs>
      <g id="iconRate-date" transform="translate(1.125 1.125)">
        <g id="iconTotal-rate-date-Copy">
          <g id="area-chart-icon_3">
            <path
              d="M0 0L0 19L18 19"
              id="Path-3-Copy"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2.25"
            />
            <path
              d="M 0,6 7.3412098,0.32665406 c 8.4725902,2.60529304 0,0 8.4725902,2.60529304"
              transform="translate(3.4093895,6.1366997)"
              id="Path-2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2.25"
            />
          </g>
        </g>
        <g id="IconFillcalendar" transform="translate(19.90939 7.6366997)">
          <path
            d="M9.33333 5.4L1.16667 5.4L1.16667 3C1.16667 2.6694 1.42858 2.4 1.75 2.4L2.33333 2.4L2.33333 3C2.33333 3.33 2.59583 3.6 2.91667 3.6C3.2375 3.6 3.5 3.33 3.5 3L3.5 2.4L7 2.4L7 3C7 3.33 7.2625 3.6 7.58333 3.6C7.90417 3.6 8.16667 3.33 8.16667 3L8.16667 2.4L8.75 2.4C9.07142 2.4 9.33333 2.6694 9.33333 3L9.33333 5.4ZM8.75 1.2L8.16667 1.2L8.16667 0.6C8.16667 0.27 7.90417 0 7.58333 0C7.2625 0 7 0.27 7 0.6L7 1.2L3.5 1.2L3.5 0.6C3.5 0.27 3.2375 0 2.91667 0C2.59583 0 2.33333 0.27 2.33333 0.6L2.33333 1.2L1.75 1.2C0.785167 1.2 0 2.0076 0 3L0 10.2C0 11.1924 0.785167 12 1.75 12L8.75 12C9.71483 12 10.5 11.1924 10.5 10.2L10.5 3C10.5 2.0076 9.71483 1.2 8.75 1.2L8.75 1.2Z"
            id="Mask"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
          <g clipPath="url(#mask_RateDate1)" id="g5728">
            <g id="🎨-Color" transform="translate(-1.75 -1.2)">
              <path
                d="M 14,14.4 C -8.1895933,-0.24113313 2.9052033,7.0794334 14,14.4 Z"
                id="Base"
                fill="currentColor"
                fillRule="evenodd"
                stroke="none"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(RateDate);
