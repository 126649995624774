import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";

import { DELETE_FORECAST, FOLDERS } from "../../../api/userArps";
import { ForecastActivityType, TYPE_WELLS } from "../../../constants";
import {
  setCheckedForecasts,
  setCheckedGlobalTypeWells,
  setCheckedKeys,
  setSelectedTypeWell
} from "../../../store/features";
import { RootState } from "../../../store/rootReducer";
import { useSelectedProject } from "../../project/projects/hooks";

type idsToDelete = {
  folderId: string;
  id: string;
  type: string;
};

export const useDeleteForecast = (forecastType: ForecastActivityType) => {
  const dispatch = useDispatch();
  const { selectedProject: project } = useSelectedProject();
  const { checkedGlobalTypeWells, selectedTypeWell, checkedKeys } = useSelector(
    (state: RootState) => ({
      checkedGlobalTypeWells: state.arps.checkedGlobalTypeWells,
      selectedTypeWell: state.arps.selectedTypeWell,
      checkedKeys:
        state.arps[
          forecastType === TYPE_WELLS ? "checkedTypeWellKeys" : "checkedForecastKeys"
        ]
    })
  );

  const updateGlobalTypeWells = async (
    idsToDelete: idsToDelete[],
    forecastType: ForecastActivityType
  ) => {
    const updatedGlobalTypeWells = checkedGlobalTypeWells.filter((well) => {
      return !idsToDelete.some((wellToDelete) => well.id === wellToDelete.id);
    });

    dispatch(setCheckedGlobalTypeWells(updatedGlobalTypeWells));
    dispatch(setCheckedKeys({ type: forecastType, checkedKeys }));
    dispatch(
      setCheckedForecasts({
        type: forecastType,
        checkedForecasts: updatedGlobalTypeWells
      })
    );

    const isWellBeingDeletedAlsoSelected = idsToDelete.some(
      (wellToDelete) => wellToDelete.id === selectedTypeWell?.id
    );

    if (isWellBeingDeletedAlsoSelected) {
      // Unselect and change the widget back to the selector tab.
      dispatch(setSelectedTypeWell(null));
    }
  };

  const [deleteForecastMutation, { loading, error }] = useMutation(DELETE_FORECAST);

  const deleteForecast = async (idsToDelete: idsToDelete[]) => {
    await deleteForecastMutation({
      variables: {
        input: {
          ids: idsToDelete,
          projectId: project?.projectId,
          type: forecastType
        }
      },
      refetchQueries: [
        {
          query: FOLDERS,
          variables: { req: { projectId: project?.projectId, type: forecastType } }
        }
      ],
      onCompleted: async () => {
        await updateGlobalTypeWells(idsToDelete, forecastType);
      },
      onError: (err) => {
        toast.error(err.message);
      }
    });
  };

  return { delete: deleteForecast, loading, error };
};
