import { useMutation, useQueryClient } from "react-query";

import axios, { AxiosError } from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { PadLayerConfiguration } from "../models/scenario";
import { GetScenarioListKey } from "./useScenarioList";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

export interface PadLayerInput {
  scenarioId: string;
  padLayerId: string;
  layer: PadLayerConfiguration;
}

const updatePadLayer = async (data: PadLayerInput) => {
  const response = await axios.put(
    `${API_URL}/${data.scenarioId}/layer/${data.padLayerId}`,
    data.layer
  );
  return response.data;
};

const useUpdatePadLayer = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, PadLayerInput, unknown>(updatePadLayer, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetScenarioListKey);
    }
  });
};

export default useUpdatePadLayer;
