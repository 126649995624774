import { FC, useEffect, useMemo, useRef } from "react";

import styled from "styled-components/macro";

import { useScreenshotDispatch } from "components/screenshot/hooks";
import { useScreenshotContext } from "components/screenshot/hooks/useScreenshotContext";

import { Portal } from "../ui";

type ScreenshotT = {
  containerId: string;
};

const Screenshot: FC<ScreenshotT> = ({ containerId }) => {
  // context
  const { settings, widget } = useScreenshotContext();

  // dispatch
  const screenshotDispatch = useScreenshotDispatch();

  // ref
  const overlayRef = useRef<HTMLDivElement>(null);

  // derived state
  const screenshotContainer = document.getElementById(containerId);

  const screenshotOverlayVisible = useMemo(() => {
    return widget?.widgetId === "ml" || widget?.widgetId === "xda";
  }, [widget]);

  useEffect(() => {
    if (screenshotOverlayVisible && screenshotContainer) {
      screenshotDispatch({
        payload: {
          screenshotOverlay: overlayRef.current
        }
      });
    }
  }, [screenshotOverlayVisible, screenshotContainer, screenshotDispatch]);

  if (!screenshotOverlayVisible || !screenshotContainer) return null;

  const screenshotBounds = {
    width: settings?.width || 1152,
    height: settings?.height || 681
  };

  return (
    <Portal container={screenshotContainer}>
      <Overlay
        ref={overlayRef}
        width={screenshotBounds.width}
        height={screenshotBounds.height}></Overlay>
    </Portal>
  );
};

export default Screenshot;

const Overlay = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(${(p) => p.width}px + 4px);
  height: calc(${(p) => p.height}px + 4px);
  border: 2px solid rgb(255, 208, 0);
  z-index: 1;
  pointer-events: none;
`;
