import { useQuery } from "react-query";

import axios, { CancelToken } from "axios";

import { getExploitableReport } from "../api/exploitableReport";
import { ExploitableReportT } from "../models/exploitableReport";

export default function useExploitableReport(
  folderId,
  txnId: string,
  spacing: number,
  cancelToken: CancelToken
) {
  return useQuery(
    "exploitable-report",
    async () => {
      try {
        return await getExploitableReport(folderId, txnId, spacing, cancelToken);
      } catch (error) {
        if (axios.isCancel(error)) {
          return { rows: [] } as ExploitableReportT;
        }
        throw error;
      }
    },
    {
      enabled: false,
      retry: false // Optionally prevent automatic retries
    }
  );
}
