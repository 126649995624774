import { getCheckedLayersKeyFromSession } from "./getCheckedLayersSessionKey";

export function setCheckedLayersToSession({
  checkedLayerKeys,
  projectId
}: {
  checkedLayerKeys: string[];
  projectId: string;
}) {
  const sessionKey = getCheckedLayersKeyFromSession(projectId);
  sessionStorage.setItem(sessionKey, JSON.stringify(checkedLayerKeys));
}
