import { useMemo } from "react";

import { IChartScreenshotSettings } from "models/chart";

function useSamScreenshotCalculatedDefaults(): IChartScreenshotSettings {
  return useMemo(() => {
    return {};
  }, []);
}

export default useSamScreenshotCalculatedDefaults;
