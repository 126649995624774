import { useEffect } from "react";

export default function useOnWindowFocus(handler) {
  useEffect(() => {
    const listener = (event) => {
      handler && handler(event);
    };

    window.addEventListener("focus", listener);

    return () => {
      window.removeEventListener("focus", listener);
    };
  }, [handler]);
}
