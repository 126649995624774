import { gql } from "@apollo/client";

export const WorkspaceClientName = "workspaces";

export const WORKSPACE = gql`
  query WorkspaceQuery($id: String) {
    workspace(id: $id) {
      workspaceId
      title
      owner
      ownerName
      type
      defaultProject
      dashboards {
        dashboardId
        title
        name
        favourite
        type
        owner
        widgetTypes
      }
    }
  }
`;

export const WORKSPACES = gql`
  query WorkspaceQuery {
    workspaces {
      workspaceId
      title
      owner
      ownerName
      type
      defaultProject
      dashboards {
        dashboardId
        title
        widgetTypes
      }
    }
  }
`;

export const DASHBOARD = gql`
  query WorkspaceQuery($id: String!) {
    dashboard(id: $id) {
      dashboardId
      title
      type
      owner
      ownerName
      layout {
        width
        height
        format
      }
      widgets {
        widgetId
        title
        type
        x
        y
        width
        height
        settings
      }
    }
  }
`;

export const DASHBOARDS = gql`
  query WorkspaceQuery {
    dashboards {
      dashboardId
      title
      name
      type
      owner
      ownerName
      layout {
        width
        height
        format
      }
      widgets {
        title
        type
        x
        y
        width
        height
        settings
      }
    }
  }
`;

export const ADD_WORKSPACE = gql`
  mutation WorkspaceMutation($workspace: WorkspaceInput!) {
    addWorkspace(workspace: $workspace) {
      workspaceId
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation WorkspaceMutation($workspace: WorkspaceUpdate!) {
    updateWorkspace(workspace: $workspace)
  }
`;

export const DELETE_WORKSPACE = gql`
  mutation WorkspaceMutation($id: String!) {
    deleteWorkspace(id: $id)
  }
`;

export const ADD_WORKSPACE_DASHBOARD = gql`
  mutation WorkspaceMutation(
    $workspaceId: String!
    $dashboardId: String!
    $position: Int
  ) {
    addWorkspaceDashboard(
      workspaceId: $workspaceId
      dashboardId: $dashboardId
      position: $position
    )
  }
`;

export const REMOVE_WORKSPACE_DASHBOARD = gql`
  mutation WorkspaceMutation($workspaceId: String!, $dashboardId: String!) {
    removeWorkspaceDashboard(workspaceId: $workspaceId, dashboardId: $dashboardId)
  }
`;

export const ADD_DASHBOARD = gql`
  mutation WorkspaceMutation(
    $dashboard: DashboardInput!
    $workspaceId: String
    $position: Int
  ) {
    addDashboard(dashboard: $dashboard, workspaceId: $workspaceId, position: $position) {
      dashboardId
    }
  }
`;

export const UPDATE_DASHBOARD = gql`
  mutation WorkspaceMutation($dashboard: DashboardUpdate!) {
    updateDashboard(dashboard: $dashboard)
  }
`;

export const DELETE_DASHBOARD = gql`
  mutation WorkspaceMutation($id: String!) {
    deleteDashboard(id: $id)
  }
`;
