import { useSelector } from "react-redux";

import { FracAccess, WellSurveyAccess } from "constants/settings.constants";
import { RootState } from "store/rootReducer";

export default function useDataAccess() {
  const dataAccess = useSelector((state: RootState) => state.auth.dataAccess);
  return {
    hasSurvey: dataAccess ? dataAccess.indexOf(WellSurveyAccess) >= 0 : false,
    hasFrac: dataAccess ? dataAccess.indexOf(FracAccess) >= 0 : false
  };
}
