import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import tcircle from "@turf/circle";
import { Point, Units, feature, featureCollection } from "@turf/helpers";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import {
  HIGHLIGHTED_WELL_LAYER,
  SYNC_WELL_LAYER,
  WELL_LAYER
} from "constants/mapLayers.constants";
import { SyncWells, filterWellsFromMapExtent, setMapState } from "store/features";
import { RootState } from "store/rootReducer";

import { waitForStyleToLoad } from "components/map/utils";
import { emptyFeatureCollection } from "components/map/utils/emptyFeatureCollection";

export default function GoToSyncWells({ mapbox, onSync }) {
  const dispatch = useDispatch();

  const syncWells: SyncWells = useSelector((state: RootState) => state.app.syncWells);

  useEffect(() => {
    if (!IS_INTERNAL_ENV) {
      return;
    }

    const updateHighlightedFeatures = (feats) => {
      if (!mapbox) {
        return;
      }
      const source = mapbox.getSource(HIGHLIGHTED_WELL_LAYER);
      if (!source) {
        return;
      }
      source.setData(feats);
    };

    const removeHighlightedFeatures = () => {
      if (!mapbox) {
        return;
      }
      const source = mapbox.getSource(HIGHLIGHTED_WELL_LAYER);
      if (!source) {
        return;
      }
      source.setData([]);
    };

    const goToPointAndHighlightPolygon = (mapbox, centroid: Point, zoom: number = 5) => {
      const paintActiveSyncWell = () => {
        const wells = mapbox.querySourceFeatures(WELL_LAYER, {
          sourceLayer: "eva-wells",
          filter: ["==", ["get", "Uwi"], syncWells.activeWells[0]]
        });

        const source = mapbox.getSource(SYNC_WELL_LAYER);
        if (source && wells.length > 0) {
          const features = wells.map((feat) => feature(feat.geometry));
          const collection = featureCollection(features);
          source.setData(collection);
        }
      };

      const clearSyncWell = () => {
        const source = mapbox.getSource(SYNC_WELL_LAYER);
        source?.setData({ ...emptyFeatureCollection });
      };

      const lng = centroid.coordinates[0];
      const lat = centroid.coordinates[1];

      const newMapState = {
        lng,
        lat,
        zoom
      };

      dispatch(setMapState(newMapState));

      const center = centroid.coordinates;
      const radius = localStorage.getItem("xMiles")
        ? parseFloat(localStorage.getItem("xMiles"))
        : syncWells.xMiles;

      const unit: Units = "miles";

      const options = {
        steps: 24,
        units: unit,
        properties: {
          color: "#51b5a0"
        }
      };

      onSync && onSync(syncWells.isSync && syncWells.showWellsWithin);

      if (syncWells.isSync) {
        mapbox.once("moveend", function () {
          if (syncWells.activeWells.length > 0 && syncWells.xMiles > 0) {
            // After the fly to, it takes awhile before the layers are rendered
            // Without waiting, the SYNC_WELL_LAYER will prematurely set data, and
            // seems to get painted over.
            waitForStyleToLoad(mapbox, 100).then(() => {
              paintActiveSyncWell();

              // This seems to be a bug where highighted features are not painted
              if (syncWells.showWellsWithin) {
                const circle = tcircle(center, radius, options);
                updateHighlightedFeatures(circle);

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const geom: any = circle.geometry;
                geom.source = "selection";
                geom.operator = "=";

                dispatch(filterWellsFromMapExtent(geom));
              }
            });
          }
        });

        clearSyncWell();

        // We may only want to fly to when sync is on
        mapbox.flyTo({
          center: [lng, lat],
          zoom,
          duration: 0,
          essential: true
        });
      } else if (!syncWells.isSync) {
        //remove circle
        removeHighlightedFeatures();
        clearSyncWell();
      }
    };

    if (mapbox && syncWells && syncWells.centroid && syncWells.showWellsWithin) {
      goToPointAndHighlightPolygon(mapbox, syncWells.centroid, 10);
    }
    //only watch syncWells due to infinite loops
  }, [dispatch, syncWells]);

  return <></>;
}
