// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";

import { mdiCloudUpload } from "@mdi/js";
import { Button, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import styled from "styled-components/macro";

import { useFilesContext, useFilesDispatch } from "../context";
import { useMultiFileUpload } from "../hooks";

const FileUploaderInput = () => {
  const filesDispatch = useFilesDispatch();
  const fileUpload = useMultiFileUpload();
  const { filesType } = useFilesContext();

  const fileDropProps: UploadProps = {
    multiple: true,
    accept: filesType === "productionData" ? ".csv" : "*.*",
    showUploadList: false,
    beforeUpload: () => false,
    onChange: (info) => {
      filesDispatch({
        payload: {
          isUploadingFiles: true,
          isFileUploaderOpen: false
        }
      });

      const fileList = info.fileList.map((file) => file.originFileObj);

      fileUpload.mutate(fileList);
    },
    onDrop: (e) => {
      filesDispatch({
        payload: {
          isUploadingFiles: true,
          isFileUploaderOpen: false
        }
      });

      const fileList = Array.from(e.dataTransfer.files).map((file) => file);

      fileUpload.mutate(fileList);
    }
  };

  function handleCancel(): void {
    filesDispatch({
      payload: {
        isFileUploaderOpen: false,
        isUploadingFiles: false,
        uploadingFilesProgress: {}
      }
    });
  }

  return (
    <Wrapper className="ant-upload-text">
      <UploadWrapper>
        <Dragger {...fileDropProps}>
          <Icon path={mdiCloudUpload} size={2} />
          <p>Drag and drop here or click to select files</p>
        </Dragger>
        <ActionsWrapper>
          <Button onClick={handleCancel}>Cancel</Button>
        </ActionsWrapper>
      </UploadWrapper>
    </Wrapper>
  );
};

export default FileUploaderInput;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-upload.ant-upload-drag {
    padding: 18px 24px;
    background-color: #fafafa;
    border: 4px dashed #d9d9d9;
    cursor: pointer;

    &:hover {
      border-color: "var(--color-primary)";
    }
  }
`;
const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: end;
  justify-content: flex-end;
`;
