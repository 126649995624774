import { memo } from "react";

function DataSource({ size = 24 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1">
      <g id="Icon-Copy-4">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="Rectangle-Copy-34"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M1 4C0.45 4 0 4.45 0 5L0 18C0 19.1 0.9 20 2 20L15 20C15.55 20 16 19.55 16 19C16 18.45 15.55 18 15 18L3 18C2.45 18 2 17.55 2 17L2 5C2 4.45 1.55 4 1 4ZM18 0L6 0C4.9 0 4 0.9 4 2L4 14C4 15.1 4.9 16 6 16L18 16C19.1 16 20 15.1 20 14L20 2C20 0.9 19.1 0 18 0ZM16 9L8 9C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7L16 7C16.55 7 17 7.45 17 8C17 8.55 16.55 9 16 9ZM12 13L8 13C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11L12 11C12.55 11 13 11.45 13 12C13 12.55 12.55 13 12 13ZM16 5L8 5C7.45 5 7 4.55 7 4C7 3.45 7.45 3 8 3L16 3C16.55 3 17 3.45 17 4C17 4.55 16.55 5 16 5Z"
          transform="translate(2 2)"
          id="Icon"
          fill="currentColor"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
}
export default memo(DataSource);
