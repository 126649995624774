import axios from "axios";

import { EntityKind } from "../models/entityKind";

const columnRoot = process.env.REACT_APP_COLUMN_SET_SERVICE;

export interface CreateColumnSetRequest {
  title: string;
  properties: string[];
  entityKind: EntityKind;
}

export interface UpdateColumnSetRequest {
  columnSetId: string;
  title: string;
  properties: string[];
}

export function getColumnSets(entityKind: EntityKind) {
  return axios.get(`${columnRoot}/column-sets?entityKind=${entityKind}`);
}

export function createColumnSet(request: CreateColumnSetRequest) {
  return axios.post(`${columnRoot}/column-sets`, request);
}

export function updateColumnSet(request: UpdateColumnSetRequest) {
  return axios.put(`${columnRoot}/column-set/${request.columnSetId}`, request);
}

export function deleteColumnSet(columnSetId: string) {
  return axios.delete(`${columnRoot}/column-set/${columnSetId}`);
}

export function getFavouriteColumns(entityKind: EntityKind) {
  return axios.get(`${columnRoot}/column/favourites?entityKind=${entityKind}`);
}
