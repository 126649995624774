// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { CheckCircle, RemoveCircleOutline } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import { mdiFactory } from "@mdi/js";
import { Popconfirm } from "antd";
import classnames from "classnames";
import styled from "styled-components";

import { IDashboardGroup } from "models/dashboard";
import { Dashboard, Workspace, WorkspaceDashboardInput } from "models/workspace";

import { Tooltip } from "components/base";
import {
  IconAllCharts,
  IconDataTableView,
  IconHorizontalMap,
  IconLargeMap,
  IconSplitView
} from "components/icons";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";

import { getDashboardTypeIcon } from "./utils/getDashboardTypeIcon";

export interface AddDashboardLinksInputModel {
  workspace: Workspace;
  dashboardGroups: IDashboardGroup[];
  selectedDashboard: Dashboard | null;
  // eslint-disable-next-line no-unused-vars
  onDashboardSelected: (dashboard: Dashboard) => void;
  showActive?: boolean;
  showDivider?: boolean;
  allowRemove?: boolean;

  // eslint-disable-next-line no-unused-vars
  onDashboardRemoved?: (dashboard: Dashboard) => void;
  enableReorder?: boolean;
  // eslint-disable-next-line no-unused-vars
  onReorderDashboards?: (order: WorkspaceDashboardInput[]) => void;
}

export default function AddDashboardLinks(input: AddDashboardLinksInputModel) {
  const getIcon = (format: string) => {
    const size = 24;
    return (
      <>
        {format === "split" && <IconSplitView size={size} />}
        {format === "large-map" && <IconLargeMap size={size} />}
        {format === "horizontal-map" && <IconHorizontalMap size={size} />}
        {format === "all-charts" && <IconAllCharts size={size} />}
        {format === "full" && <IconDataTableView size={size} />}
        {!format && <IconAllCharts size={size} />}
      </>
    );
  };

  const { workspace } = useWorkspaceContext();

  // Take action when a dashboard link is selected
  const handleDashboardSelected = useCallback((dashboard: Dashboard) => {
    input.onDashboardSelected && input.onDashboardSelected(dashboard);
  }, []);

  const onDragEnd = (result) => {
    if (result.destination.index === result.source.index) return;

    // Get the original list of dashboards without the moved.
    const workspaceDashboards: WorkspaceDashboardInput[] =
      input.dashboardGroups[0].dashboards?.reduce((list, d) => {
        if (d.dashboardId !== result.draggableId) {
          list.push({
            dashboardId: d.dashboardId,
            favourite: false
          });
        }
        return list;
      }, []);

    // Add the moved dashboard at the destination index.
    workspaceDashboards.splice(result.destination.index, 0, {
      dashboardId: result.draggableId,
      favourite: false
    });

    input.onReorderDashboards && input.onReorderDashboards(workspaceDashboards);
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  });

  if (!input.dashboardGroups) {
    return null;
  }

  return (
    <>
      {input.dashboardGroups.map((group) => {
        const canUserAddPersonalDashboard =
          (group.groupTitle.toLocaleLowerCase() !== "personal" &&
            workspace?.type.toLocaleLowerCase() === "organization") ||
          workspace?.type.toLocaleLowerCase() !== "organization";
        return (
          canUserAddPersonalDashboard && (
            <DashboardsGroup key={group.groupKey}>
              <DashboardsGroupHeading divider={input.showDivider}>
                {getDashboardTypeIcon(group.groupTitle)}
                {group.groupTitle}
                <Tooltip title={group.help} overlayClassName="add-dashboard-tooltip-root">
                  <InfoIcon
                    style={{
                      color: "#A2AAAD",
                      fontSize: "16px",
                      top: "2px",
                      left: "5px"
                    }}
                  />
                </Tooltip>
              </DashboardsGroupHeading>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <DashboardLinksContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}>
                      {group.dashboards?.map((dashboard, index) => {
                        const renderFacilityIcon = dashboard?.widgets?.some(
                          (widget) =>
                            widget.type.includes("facility") ||
                            widget.type.includes("midstream")
                        );
                        return (
                          <Draggable
                            key={dashboard?.dashboardId}
                            draggableId={dashboard?.dashboardId}
                            index={index}
                            isDragDisabled={input.enableReorder !== true}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(provided.draggableProps.style)}>
                                <div style={{ position: "relative" }}>
                                  {renderFacilityIcon && (
                                    <StyledIcon path={mdiFactory} size={1.0} />
                                  )}
                                </div>
                                <Tooltip
                                  key={dashboard?.dashboardId}
                                  title={
                                    <>
                                      {getDashboardTypeIcon(dashboard)}
                                      {dashboard?.title}
                                    </>
                                  }
                                  overlayClassName="add-dashboard-tooltip-root">
                                  <DashboardLink
                                    className={classnames({
                                      selected:
                                        input.selectedDashboard?.dashboardId ===
                                        dashboard?.dashboardId
                                    })}
                                    onClick={() => handleDashboardSelected(dashboard)}>
                                    <DashboardLinkIcon>
                                      {getIcon(dashboard?.layout?.format)}
                                    </DashboardLinkIcon>
                                    <DashboardLinkTitle>
                                      {dashboard?.title}
                                    </DashboardLinkTitle>
                                    {input.showActive &&
                                      input.workspace.dashboards.some(
                                        (d) => d?.dashboardId === dashboard?.dashboardId
                                      ) && (
                                        <DashboardInUseIcon>
                                          <CheckCircle fontSize="small" />
                                        </DashboardInUseIcon>
                                      )}

                                    {input.allowRemove &&
                                      input.workspace.dashboards.some(
                                        (d) => d.dashboardId === dashboard?.dashboardId
                                      ) && (
                                        <Popconfirm
                                          onConfirm={(evt) => {
                                            input.onDashboardRemoved &&
                                              input.onDashboardRemoved(dashboard);
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                          }}
                                          onCancel={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                          }}
                                          okText="Hide"
                                          okType="danger"
                                          title={
                                            <>
                                              <div>{`Are you sure you want to hide the dashboard "${dashboard.title}" from the workspace?`}</div>
                                            </>
                                          }>
                                          <DashboardRemoveIcon>
                                            <RemoveCircleOutline fontSize="medium" />
                                          </DashboardRemoveIcon>
                                        </Popconfirm>
                                      )}
                                  </DashboardLink>
                                </Tooltip>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </DashboardLinksContainer>
                  )}
                </Droppable>
              </DragDropContext>
            </DashboardsGroup>
          )
        );
      })}
    </>
  );
}

const DashboardsGroup = styled.div``;

const DashboardsGroupHeading = styled.div`
  border-bottom: ${(props) => (props.divider == true ? "1px solid #d9e1e2" : "")};
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DashboardLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-content: stretch;
  align-items: center;
  text-align: center;
  gap: 6px;
  overflow-y: overlay;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const DashboardLinkIcon = styled.div``;

const DashboardLinkTitle = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DashboardInUseIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  color: var(--color-primary);
  line-height: 0;
`;

const DashboardRemoveIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  color: #a2aaad;
  line-height: 0;
  opacity: 0;

  &:hover {
    color: var(--color-danger);
    visibility: visible;
  }
`;

const DashboardLink = styled.div`
  position: relative;
  width: 150px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  border-radius: var(--border-radius);
  background-color: rgba(var(--color-text-rgb), 0.05);

  &:hover {
    background-color: rgba(var(--color-text-rgb), 0.1);

    ${DashboardRemoveIcon} {
      opacity: 1;
    }
  }

  &.selected:hover,
  &.selected {
    background-color: hsl(204, 100%, 6%);
    color: var(--color-white);
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  left: 6px;
`;
