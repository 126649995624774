import { FC, HTMLAttributes, ReactNode, useEffect, useState } from "react";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Form, Input, InputNumber, Popconfirm, Table } from "antd";
import { AlignType, FixedType } from "rc-table/lib/interface";
import styled from "styled-components";

import { ScreenshotPresetModel } from "models/model";

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: ScreenshotPresetModel;
  index: number;
  children: ReactNode;
}

const EditableCell: FC<EditableCellProps> = ({
  children,
  dataIndex,
  editing,
  inputType,
  title,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

type ScreenshotPresetTableT = {
  title?: ReactNode;
  dataSource: ScreenshotPresetModel[];
  loading: boolean;
  editable?: boolean;
  onChange?: (preset: ScreenshotPresetModel) => void;
  onDelete?: (preset: ScreenshotPresetModel) => void;
};

const ScreenshotPresetTable: FC<ScreenshotPresetTableT> = ({
  title = null,
  dataSource = [],
  editable = false,
  loading = false,
  onChange,
  onDelete
}) => {
  // hooks
  const [form] = Form.useForm();

  // state
  const [data, setData] = useState(dataSource);
  const [editId, setEditId] = useState("");

  // derived state
  const isEditing = (record: ScreenshotPresetModel) => record.id === editId;

  // sync data source
  useEffect(() => {
    setData(dataSource);
    editId && setEditId(""); // once saved this will close the edit form
  }, [dataSource]);

  // editable row actions
  const edit = (record: Partial<ScreenshotPresetModel>) => {
    form.setFieldsValue({
      title: "",
      width: "",
      height: "",
      titleFontSize: "",
      axisFontSize: "",
      axisLabelFontSize: "",
      legendFontSize: "",
      ...record
    });
    setEditId(record.id);
  };

  const cancel = () => {
    setEditId("");
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as ScreenshotPresetModel;
      onChange({ ...row, id });
      // cancel();
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      fixed: "left" as FixedType,
      width: "260px",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Width (px)",
      dataIndex: "width",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Height (px)",
      dataIndex: "height",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Title Size (pt)",
      dataIndex: "titleFontSize",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Axis Title (pt)",
      dataIndex: "axisFontSize",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Axis Labels (pt)",
      dataIndex: "axisLabelFontSize",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Tops Labels (pt)",
      dataIndex: "topsLabelsFontSize",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Data Labels (pt)",
      dataIndex: "dataLabelsFontSize",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Grid Labels (pt)",
      dataIndex: "gridLabelsFontSize",
      editable: true
    },
    {
      align: "right" as AlignType,
      title: "Legend Size (pt)",
      dataIndex: "legendFontSize",
      editable: true,
      render: (_, record: ScreenshotPresetModel) => record.legendFontSize || 14
    }
  ];

  const operationColumn = {
    align: "center" as AlignType,
    title: "Actions",
    dataIndex: "operation",
    editable: false,
    fixed: "right" as FixedType,
    render: (_, record: ScreenshotPresetModel) => {
      const editable = isEditing(record);
      return editable ? (
        <>
          <SaveButton
            shape="circle"
            type="text"
            onClick={() => save(record.id)}
            icon={<CheckCircleIcon />}
          />
          <CancelButton
            shape="circle"
            type="text"
            onClick={cancel}
            icon={<CloseIcon />}
          />
        </>
      ) : (
        <>
          <EditButton
            shape="circle"
            type="text"
            onClick={() => edit(record)}
            icon={<EditIcon />}
          />

          <Popconfirm
            onConfirm={() => onDelete(record)}
            title={`Please confirm you wish to delete preset ${record.title}.`}>
            <DeleteButton shape="circle" type="text" icon={<DeleteIcon />} />
          </Popconfirm>
        </>
      );
    }
  };

  const editableColumns = [...columns, operationColumn];

  const mergedColumns = editableColumns.map((col) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ScreenshotPresetModel) => ({
        record,
        inputType: col.dataIndex === "title" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  if (editable) {
    return (
      <Form form={form} component={false}>
        <StyledTable<ScreenshotPresetModel>
          components={{
            body: {
              cell: EditableCell
            }
          }}
          columns={mergedColumns}
          dataSource={data}
          loading={loading}
          pagination={false}
          size="middle"
          title={() => title}
        />
      </Form>
    );
  }

  return (
    <StyledTable<ScreenshotPresetModel>
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={false}
      size="middle"
      title={() => title}
    />
  );
};

export default ScreenshotPresetTable;

const StyledTable = styled(Table)`
  margin-bottom: 24px;
`;

const EditButton = styled(Button)`
  color: #a2aaad;
`;

const DeleteButton = styled(Button)`
  color: #a2aaad;

  &:hover {
    color: #ff4d4f;
  }
`;

const SaveButton = styled(Button)`
  color: #00c7b1;
`;

const CancelButton = styled(Button)`
  color: #a2aaad;
`;
