import { useState } from "react";

import styled from "styled-components";

const sprite_path = "/icon-sprite.svg";

function BaseIcon({
  size = 20,
  color = "currentColor",
  iconName = "vue",
  className = "",
  onClick = null
}) {
  const iconClassNames = `icon ${className}`;
  const [path] = useState(`${sprite_path}#${iconName}`);

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      className={iconClassNames}
      aria-labelledby={iconName}
      width={size}
      height={size}
      fill={color}
      onClick={onClick}>
      <use href={path} />
    </SVG>
  );
}

export default BaseIcon;

const SVG = styled.svg`
  display: inline-block;
  color: currentColor;
  vertical-align: baseline;
  transform-origin: center;
  backface-visibility: hidden;
  user-select: none;
`;
