import { memo } from "react";

import { useMapContext } from "components/map/hooks/useMapContext";

import "./Indicator.scss";

function Indicator({ map = false, message }) {
  const { noWellsAvailable } = useMapContext();
  if (!noWellsAvailable && map) {
    return <></>;
  }
  return (
    <div data-testid={"indicator-container"} className="indicator-container">
      <div className="content">{message}</div>
    </div>
  );
}
export default memo(Indicator);
