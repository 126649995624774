import html2canvas from "html2canvas";

import {
  IScreenshotSettings,
  ScreenshotError,
  ScreenshotNotFocusedError
} from "models/screenshot";

import { copyMapToClipboard } from "components/map/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const copyToClipboard = async (
  widgetType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widgetState: any,
  settings: IScreenshotSettings,
  screenshotOverlay: HTMLDivElement
): Promise<void> => {
  let blob: Blob | null = null;
  if (widgetType === "map") {
    blob = await copyMapToClipboard(widgetState, settings);
  } else {
    let selector = "";
    switch (widgetType) {
      case "chart":
      case "mpc":
        selector = document.querySelector(".fullscreen .ChartPlot")
          ? ".fullscreen .ChartPlot"
          : ".App .chart-wrapper > div > .ChartPlot";
        break;
      case "sam":
      case "sam-rf-pv":
        selector = ".App .ChartPlot";
        break;
      case "threeDViewer":
        selector = ".App .three-d-viewer-container";
        break;
      case "xdaViewer":
        selector = ".App .xda-viewer-container";
        break;
      default:
        throw new Error(`Unsupported widget type`);
    }

    if (screenshotOverlay) {
      screenshotOverlay.style.border = "none";
    }

    const canvas = await html2canvas(document.querySelector(selector));
    blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
  }

  if (!blob) return;
  if (!navigator.clipboard) return;

  const data = [new ClipboardItem({ "image/png": blob })];

  if (data && document.hasFocus()) {
    try {
      await navigator.clipboard.write(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw new ScreenshotError("Error copying to clipboard");
    }
  } else {
    // Prevent "Uncaught (in promise) NotAllowedError: "Failed to execute 'write' on 'Clipboard':""
    throw new ScreenshotNotFocusedError("Error copying XDA to clipboard");
  }
};

export default copyToClipboard;
