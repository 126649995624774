import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { Uwi } from "models";

import { SelectedWellT } from "components/well-list/context/WellListContext";

const useSelectedWellFromStore = (): SelectedWellT | null => {
  const selectedWells = useSelector((state: RootState) => state.map.selectedWells);

  if (selectedWells) {
    const selectedWell: SelectedWellT =
      Object.keys(selectedWells).length > 0
        ? { ...(Object.values(selectedWells)[0] as SelectedWellT) }
        : null;

    if (selectedWell?.Uwi && !selectedWell?.FormattedUwi) {
      selectedWell.FormattedUwi = new Uwi().toFormatted(selectedWell?.Uwi);
    }

    return selectedWell;
  }
  return null;
};

export default useSelectedWellFromStore;
