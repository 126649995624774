import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "store/rootReducer";

import { getUsersInOrg } from "api/users";

import { BaseButton, BaseDivider, Heading, SubHeading } from "components/base";

import "./OrganizationSubscription.scss";

export default function OrganizationSubscription() {
  const history = useHistory();
  function goTo(path) {
    history.push(path);
  }
  const orgId = useSelector((state: RootState) => state.auth.user.organization.id);
  const maxUsers = useSelector(
    (state: RootState) => state.auth.user.organization.maximumUsers
  );
  const [userCount, setUserCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [powerCount, setPowerCount] = useState(0);
  const [standardCount, setStandardCount] = useState(0);
  const updateUsers = useCallback(async () => {
    try {
      const users = await getUsersInOrg(orgId);
      let numusers = 0;
      let numadmin = 0;
      let numstandard = 0;
      let numpower = 0;
      for (let i = 0; i < users.length; i++) {
        numusers++;
        const role = users[i].role;
        if (role === 1) {
          numstandard++;
        } else if (role === 2) {
          numadmin++;
        } else if (role === 3) {
          numpower++;
        }
      }
      setUserCount(numusers);
      setAdminCount(numadmin);
      setStandardCount(numstandard);
      setPowerCount(numpower);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [orgId]);

  useEffect(() => {
    updateUsers();
  }, [orgId, updateUsers]);

  return (
    <>
      <div className="organizationAccountHeader">
        <Heading element="h4">Manage Subscription</Heading>
      </div>
      <div className="subscriptionInfo">
        <div className="info-card">
          <div className="card-heading flex-row">
            <SubHeading>Monthly Subscription</SubHeading>
          </div>
          <BaseDivider />
          <div className="content">
            <div className="column">
              <span className="label">Your Plan</span>
              <span className="value">Free Trial</span>
            </div>
            <div className="column">
              <span className="label">Cost</span>
              <span className="value">$0.00</span>
            </div>
            <div className="column">
              <span className="label">Total</span>
              <span className="value">$0.00 / mo</span>
            </div>
          </div>
        </div>
        <div className="info-card">
          <div className="card-heading flex-row justify-between items-baseline">
            <SubHeading>Users</SubHeading>
            <BaseButton appearance="subtle" onClick={() => goTo("/manage-users")}>
              Manage Users
            </BaseButton>
          </div>
          <BaseDivider />
          <div className="content">
            <div className="column">
              <span className="label">Maximum Users</span>
              <span className="value">{maxUsers}</span>
            </div>
            <div className="column">
              <span className="label">Total users</span>
              <span className="value">{userCount}</span>
            </div>
            <div className="column">
              <span className="label">Admin</span>
              <span className="value">{adminCount}</span>
            </div>
            <div className="column">
              <span className="label">Power Users</span>
              <span className="value">{powerCount}</span>
            </div>
            <div className="column">
              <span className="label">Standard Users</span>
              <span className="value">{standardCount}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
