import IconComponent from "@mdi/react";
import { useState } from "react";

import { mdiContentCopy } from "@mdi/js";
import { Alert, Button, Popover } from "antd";
import styled from "styled-components";

import { Tooltip } from "components/base";

import { useUser } from "../../hooks";
import { IColumns } from "../../models/columns";

type CopyButtonT = {
  rows: string[];
  columns: IColumns[];
};

export default function CopyButton(props: CopyButtonT) {
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);
  const { hasTrialAccount, isReadonly } = useUser();

  function formatTableDataForClipboard(columns: IColumns[], rows: string[]) {
    const header = columns.map((col) => col.name).join("\t");
    const data = rows
      .map((row) => columns.map((col) => row[col.key]).join("\t"))
      .join("\n");
    return `${header}\n${data}`;
  }

  function copyTable(columns: IColumns[], rows: string[]) {
    const formattedData = formatTableDataForClipboard(columns, rows);
    navigator.clipboard
      .writeText(formattedData)
      .then(() => {
        setShowCopySuccessMessage(true);
        setTimeout(() => {
          setShowCopySuccessMessage(false);
        }, 2000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console -- Necessary for debugging copy errors.
        console.error(`Failed to copy. ${err}`);
      });
  }

  return (
    !hasTrialAccount &&
    !isReadonly && (
      <Tooltip title="Copy Data Table">
        <Popover
          open={showCopySuccessMessage}
          trigger="click"
          overlayClassName="overlay-no-padding"
          content={<Alert type="success" message="Data Table Copied to Clipboard!" />}>
          <ActionButton onClick={copyTable.bind(null, props.columns, props.rows)}>
            <IconComponent path={mdiContentCopy} size={1.2} />
            {"Copy"}
          </ActionButton>
        </Popover>
      </Tooltip>
    )
  );
}

const ActionButton = styled(Button)`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  background: transparent;
  border: none;
  color: #a2aaad;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;
