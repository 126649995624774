import { useEffect, useMemo, useState } from "react";
import { Rnd } from "react-rnd";
import { FixedSizeList as List } from "react-window";

import { Typography } from "antd";
import classnames from "classnames";
import styled from "styled-components";

import { useChartState } from "../context";
import { useChartLegendItems, useChartLegendTitle } from "../hooks";
import ChartLegendItem from "./LegendItem";
import getCalculatedLegend from "./utils/getCalculatedLegend";
import getUpdatedChartSizeLegend from "./utils/getUpdatedChartSizeLegend";

const { Text } = Typography;
const noWellsFoundText = "No entities were found";

export type ParentDimensionsT = { width: number; height: number };
export type LegendPositionT = { x: number; y: number };
export type LegendDimensionsT = { width: number; height: number };

export type LegendT = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ChartLegendT = {
  parentDimensions: ParentDimensionsT;
  id: string;
  className?: string;
  disableDragging?: boolean;
  showLegendBorder?: boolean;
  width?: number;
};
export type Ref = HTMLDivElement;

const ChartLegend = ({
  parentDimensions = undefined,
  id,
  className = "",
  disableDragging = false,
  showLegendBorder = false,
  width = 200
}: ChartLegendT) => {
  // context
  const { bounds, settings, screenshot } = useChartState();

  // hooks
  const title = useChartLegendTitle();
  const items = useChartLegendItems();

  // calculate height of list
  const legendFontSize = screenshot.visible
    ? Number(screenshot.preset?.legendFontSize) || 14
    : 14;

  const headingFontSizePx = `${legendFontSize + 2}px`;
  const itemSize = screenshot.visible ? legendFontSize + 12 : 30; // 30px for chart, 12px gap around when taking screenshot
  const itemsHeight = items.length * itemSize;
  const backgroundOpacity = screenshot.visible
    ? Number(screenshot.preset?.legendOpacity) ?? 1
    : 1;

  const showLegendCounts = screenshot.visible
    ? screenshot.preset?.legendShowCounts ?? true
    : true;
  const fontFamilyCss = screenshot.visible
    ? screenshot.preset?.fontFamily ?? "inherit"
    : "inherit";

  const positionStorageKey: string = `${id}-position`;
  const parentDimensionsStorageKey: string = `${id}-parentDimensions`;
  const dimensionsStorageKey = `${id}-dimensions`;
  const getStoredObj = (key: string) => JSON.parse(localStorage.getItem(key));

  const storedPosition: LegendPositionT = getStoredObj(positionStorageKey);
  const storedDimensions: LegendDimensionsT = getStoredObj(dimensionsStorageKey);
  const storedParentDimensions: LegendDimensionsT =
    getStoredObj(parentDimensionsStorageKey) || parentDimensions;

  // state
  const [dimensions, setDimensions] = useState<LegendDimensionsT>(() => {
    const storedDimensions = getStoredObj(dimensionsStorageKey);

    return storedDimensions ? storedDimensions : { width, height: itemsHeight + 32 }; // 32px for title
  });

  useEffect(() => {
    if (items.length > 0 && storedDimensions?.height == 32) {
      const calculatedItemsHeight = items.length * itemSize;
      const calculatedDimensions = { width, height: calculatedItemsHeight + 32 };
      setDimensions(calculatedDimensions);
    }
  }, [items]);

  const [position, setPosition] = useState<LegendPositionT>(() => {
    let currentPosition;
    if (!!storedPosition && !disableDragging) {
      currentPosition = storedPosition;
    } else if (!dimensions || !parentDimensions) {
      currentPosition = { x: 0, y: 0 };
    } else {
      currentPosition = { x: parentDimensions?.width - dimensions?.width - 20, y: 50 };
    }
    return currentPosition;
  });

  // markup: no wells found
  const noWellsMarkup = items.length ? null : (
    <StyledText type="danger">{noWellsFoundText}</StyledText>
  );

  // markup: react-window item
  const Row = ({ index, style }) => (
    <ChartLegendItem
      useCount={showLegendCounts}
      style={style}
      key={items[index].value}
      legend={items[index]}
    />
  );

  // handlers
  function handleDragStop(_, data) {
    const nextPosition = { x: data.x, y: data.y };
    setPosition(nextPosition);

    localStorage.setItem(positionStorageKey, JSON.stringify(nextPosition));
    localStorage.setItem(
      parentDimensionsStorageKey,
      JSON.stringify({ width: parentDimensions?.width, height: parentDimensions?.height })
    );
  }

  function handleResize(e, direction, ref, delta, p) {
    const nextDimensions = {
      width: ref.offsetWidth,
      height: ref.offsetHeight
    };
    setDimensions(nextDimensions);
    setPosition(p);

    localStorage.setItem(dimensionsStorageKey, JSON.stringify(nextDimensions));
    localStorage.setItem(positionStorageKey, JSON.stringify(p));
    localStorage.setItem(
      parentDimensionsStorageKey,
      JSON.stringify({ width: parentDimensions?.width, height: parentDimensions?.height })
    );
  }

  function calculateLegendPosition() {
    if (!!storedPosition && !disableDragging) {
      const updatedLegend =
        storedParentDimensions.width != parentDimensions.width ||
        storedParentDimensions.height != parentDimensions.height
          ? getUpdatedChartSizeLegend(
              storedParentDimensions,
              parentDimensions,
              storedPosition,
              storedDimensions,
              currentDimensions
            )
          : currentLegend;
      const calculatedLegend = getCalculatedLegend(updatedLegend, parentDimensions);
      return { x: calculatedLegend.x, y: calculatedLegend.y };
    } else if (!dimensions || !parentDimensions) {
      return { x: 0, y: 0 };
    } else {
      return { x: parentDimensions?.width - dimensions?.width - 20, y: 50 };
    }
  }

  // derived classes and styles
  const wrapperClassNames = classnames(
    "chart-legend",
    className,
    settings.legend.position
  );

  const currentLegend: LegendT = {
    ...position,
    ...dimensions
  };

  // update the currentDimensions for rendering the legend
  const currentDimensions: LegendDimensionsT = useMemo(() => {
    const calculatedDimensions = getCalculatedLegend(currentLegend, parentDimensions);
    return { width: calculatedDimensions.width, height: calculatedDimensions.height };
  }, [dimensions]);

  // update currentPosition for rendering the legend
  const currentPosition: LegendPositionT = useMemo(() => {
    return calculateLegendPosition();
  }, [position]);

  // update legend dimensions in local storage for future renders
  useEffect(() => {
    localStorage.setItem(dimensionsStorageKey, JSON.stringify(currentDimensions));
  }, [currentDimensions]);

  // update legend position in local storage for future renders
  useEffect(() => {
    localStorage.setItem(positionStorageKey, JSON.stringify(currentPosition));
  }, [currentPosition]);

  // setPosition and setDimensions when changing parent dimensions as handleDragStop and handleResize does not account for parent dimension changes
  // also update parent dimensions and legend dimensions in local storage for future renders
  useEffect(() => {
    const currentPosition = calculateLegendPosition();

    setPosition(currentPosition);
    setDimensions(currentDimensions);

    localStorage.setItem(
      parentDimensionsStorageKey,
      JSON.stringify({ width: parentDimensions?.width, height: parentDimensions?.height })
    );
    localStorage.setItem(dimensionsStorageKey, JSON.stringify(currentDimensions));
  }, [parentDimensions]);

  if (!bounds) return null;

  return (
    <StyledRnd
      data-testid="chart-legend"
      bounds="parent"
      className={wrapperClassNames}
      disableDragging={disableDragging}
      minWidth="140px"
      onDragStop={handleDragStop}
      onResize={handleResize}
      position={{ x: currentPosition.x, y: currentPosition.y }}
      size={{ width: currentDimensions.width, height: currentDimensions.height }}
      backgroundopacity={backgroundOpacity}
      showLegendBorder={screenshot?.visible ? showLegendBorder : 1}>
      <Wrapper style={{ fontFamily: fontFamilyCss }}>
        <StyledTitle style={{ fontSize: headingFontSizePx }}>{title}</StyledTitle>
        {noWellsMarkup}
        <List
          width="100%"
          height={itemsHeight}
          itemCount={items.length}
          itemSize={itemSize}>
          {Row}
        </List>
      </Wrapper>
    </StyledRnd>
  );
};

export default ChartLegend;
ChartLegend.displayName = "ChartLegend";

const StyledRnd = styled(Rnd)`
  position: absolute;
  margin: 10px;
  background: ${(props) =>
    props.backgroundopacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundopacity})`};
  border: ${(props) => (props.showLegendBorder ? "1px solid gray" : "")};
  overflow: hidden;
  z-index: 10;
  min-height: 35px;

  & > div:last-child {
    position: unset;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  opacity: 1;
`;

const StyledTitle = styled.span`
  font-weight: 600;
  text-align: left;
  padding: 6px 12px;
  margin-bottom: 0 !important;
`;
const StyledText = styled(Text)`
  text-align: center;
  padding: 4px 16px;
`;
