import { memo } from "react";

import CrossPlot from "components/icons/CrossPlot";

import { useChartDispatch, useChartState } from "../context";
import useChartDependencies from "../hooks/useChartDependencies";
import { getJitterPlotToggleDisabledStatus } from "../utils/toggles";
import ToolbarButton from "./ToolbarButton";

const JitterPlotToggle = () => {
  const dispatch = useChartDispatch();

  const { settings, options, entityKind } = useChartState();
  const { groupBy } = useChartDependencies(entityKind);

  const toggle = () => (value) => {
    const next = {
      ...settings,
      showScatter: value,
      chartTypeParam: {
        ...settings.chartTypeParam,
        ["x"]: value
          ? {
              canBin: groupBy.canBin,
              dataType: groupBy.dataType,
              id: "",
              property: groupBy.property,
              title: groupBy.title
            }
          : { ...settings.chartTypeParam.x }
      }
    };
    dispatch({ type: "settings", payload: next });
  };

  if (!options || getJitterPlotToggleDisabledStatus(options.chartType)) {
    return <></>;
  }

  return (
    <ToolbarButton
      icon={<CrossPlot />}
      active={settings.showScatter}
      overflowLabel="Show Jitter Plots"
      tooltipText="Toggle Jitter Plot"
      onToggle={toggle()}
    />
  );
};

export default memo(JitterPlotToggle);
