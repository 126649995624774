import { useEffect, useState } from "react";

import { IGroupByListItem } from "models";

import { useGroupByState } from "../group-by.context";
import filterByCategory from "../helpers/filterByCategory";

export const geoVolumeCategoryTitle = "Sampled 3D Geo";
/**
 * Returns a filtered list of categories after applying:
 * - exclude-category filter
 */
const useCategories = (): IGroupByListItem[] => {
  const [list, setList] = useState<IGroupByListItem[] | null>(null);

  const { categoryList, props } = useGroupByState();

  useEffect(() => {
    if (!categoryList?.length) {
      setList([]);
      return;
    }

    const newCategoryList = [...categoryList];

    const filteredCategories = filterByCategory(
      props.excludedCategoryFilters,
      newCategoryList
    );

    setList(filteredCategories);
  }, [props?.excludedCategoryFilters, categoryList]);

  return list;
};

export default useCategories;
