import { memo } from "react";

function TotalRateDate() {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 23 23"
      version="1.1"
      id="svg3392"
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs3396" />
      <g id="Iconschart-typeTotal-rate-date" transform="translate(1.125 1.125)">
        <g id="area-chart-icon_3">
          <path
            d="M 0.244904,13 H 16.7551 C 16.8891,13 17.000268,12.8965 17,12.7714 L 16.981349,4.072826 C 11.771869,2.704485 14.257062,3.2986688 8.1404867,1.7178601 8.0434467,1.6272701 7.8350045,1.6174761 7.7379745,1.7080661 L 0.0693123,8.00016 C 0.0231041,8.0433 0,8.09938 0,8.15977 V 12.767 C 0,12.8965 0.1109,13 0.244904,13 Z"
            transform="translate(2.9093895,4)"
            id="Shape"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M0 0L0 20L20 20"
            id="Path-3-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(TotalRateDate);
