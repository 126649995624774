import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { setUser } from "store/features";

import {
  IMfaPayload,
  MfaDeliveryMethod,
  authenticateMfa,
  requestMfaCode
} from "api/auth";

import { BaseButton, BaseInput, Heading } from "components/base";

import "./Login.scss";
import "./TwoFactorAuth.scss";

const requestLinkMessage = "Didn't receive a code?";

export default function TwoFactorAuth(): JSX.Element {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const codeRefs = useRef([]);
  const requestTokenRef = useRef(token);
  const [, setErrors] = useState("");
  const [isBusy, setBusy] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultMfaMethod = parseInt(urlParams.get("method")) as MfaDeliveryMethod;
  const [mfaMethod, setMfaMethod] = useState(defaultMfaMethod);

  function handleSubmitError() {
    setErrors("Invalid code. Please try again.");
  }
  async function submit(mfaCode = null) {
    if (!mfaCode) {
      mfaCode = code;
    }
    const payload: IMfaPayload = {
      requestId: requestTokenRef.current,
      code: mfaCode.join(""),
      deliveryMethod: mfaMethod
    };
    setBusy(true);
    const data = await authenticateMfa(payload, handleSubmitError);
    setBusy(false);

    if (!data) {
      return;
    }
    dispatch(setUser(data));
    history.push("/");
  }

  async function resendMfa() {
    const payload: IMfaPayload = {
      requestId: requestTokenRef.current,
      code: "",
      deliveryMethod: mfaMethod
    };
    const data = await requestMfaCode(payload, handleSubmitError);
    if (data) {
      requestTokenRef.current = data.requestId;
      setMfaMethod(data.deliveryMethod);
      toast.success("Verfication code has been sent.");
    }
  }

  function allFilledIn(code): boolean {
    return code.reduce((acc, val) => acc && val !== "", true);
  }

  function onKeyDown(idx, event) {
    const newCode = [...code];
    switch (event.key) {
      case "Backspace":
      case "Delete":
        event.preventDefault();
        newCode[idx] = "";
        setCode(newCode);
        if (idx - 1 >= 0) {
          codeRefs.current[idx - 1].select();
        }
        break;
      case "ArrowLeft":
      case "ArrowDown":
        event.preventDefault();
        if (idx - 1 >= 0) {
          codeRefs.current[idx - 1].select();
        }
        break;
      case "ArrowRight":
      case "ArrowUp":
        event.preventDefault();
        if (idx + 1 < code.length) {
          codeRefs.current[idx + 1].select();
        }
        break;
      case "Enter":
        submit(newCode);
        break;
      default:
        break;
    }
  }

  function handlePaste(idx, e) {
    const pastedCode = e.clipboardData.getData("text/plain").trim().slice(0, 6).split("");
    if (pastedCode) {
      const newCode = code.map((x, _i) => pastedCode[_i] || "");
      setCode(newCode);
      if (allFilledIn(newCode)) submit(newCode);
      codeRefs.current[0].select();
    }
  }

  async function handleChange(idx, value) {
    const newCode = [...code];
    newCode[idx] = value;
    setCode(newCode);
    if (idx + 1 < code.length) {
      codeRefs.current[idx + 1].select();
    }
    if (allFilledIn(newCode)) {
      await submit(newCode);
    }
  }
  return (
    <div className="content-container">
      <div>
        <Heading element="h3" className="align-center medium">
          Two factor authentication
        </Heading>
        <form name="two-factor" className="two-factor-form">
          <p>
            {mfaMethod === MfaDeliveryMethod.App
              ? "Please enter the code displayed in your authenticator app."
              : `You will have received a verification code in a ${
                  mfaMethod === MfaDeliveryMethod.Sms ? "text message" : "email"
                }. Please enter it here.`}
          </p>
          <div className="code-container">
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              [...Array(6).keys()].map((_, index) => {
                return (
                  <BaseInput
                    key={index}
                    type="text"
                    innerRef={(el) => (codeRefs.current[index] = el)}
                    maxLength="1"
                    onChange={async (val) => await handleChange(index, val)}
                    onKeyDown={(e) => onKeyDown(index, e)}
                    onPaste={(e) => handlePaste(index, e)}
                    value={code[index]}
                  />
                );
              })
            }
          </div>
          {mfaMethod === MfaDeliveryMethod.App ? (
            <div className="request-link">
              <p>
                {"Can't access the app?"}
                <BaseButton appearance="subtle" onClick={resendMfa}>
                  Send code through sms/email
                </BaseButton>
              </p>
            </div>
          ) : (
            <div className="request-link">
              <p>
                {requestLinkMessage}
                <BaseButton appearance="subtle" onClick={resendMfa}>
                  Resend
                </BaseButton>
              </p>
            </div>
          )}
          <div className="login-btn">
            <BaseButton
              appearance="primary"
              isLoading={isBusy}
              onClick={() => submit(null)}>
              Login
            </BaseButton>
          </div>
        </form>
      </div>
    </div>
  );
}
