import { Icon } from "@mdi/react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  AddLocation,
  Delete,
  DeleteOutline,
  Functions,
  GridOn,
  KeyboardArrowDown
} from "@material-ui/icons";
import { mdiPlaylistEdit } from "@mdi/js";
import { Button, Popconfirm } from "antd";
import { requestAddNewField as addField } from "store/features";
import styled from "styled-components";

import { useUser } from "hooks";

import { Popover, Tooltip } from "components/base";
import { useGeomBinDispatch } from "components/geom-bin/hooks/useGeomBinDispatch";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";
import {
  useMutationFieldDelete,
  useMutationGeomBinDelete,
  useSelectedCategory
} from "./hooks";

function UserFieldToolbar({ className = "" }) {
  const dispatch = useDispatch();
  const { checkedFields, customFieldsEditToggle, selectedTab } = useGroupByState();
  const stateDispatch = useGroupByUpdater();
  const { isAtLeastPowerUser } = useUser();
  const { isOrgDefinedColumn, isUserDefinedColumn } = useSelectedCategory();
  const { mutate: fieldDelete } = useMutationFieldDelete(
    checkedFields,
    isOrgDefinedColumn
  );

  const { mutate: geomFieldDelete } = useMutationGeomBinDelete(checkedFields);
  const { props } = useGroupByState();

  const toggleEditMode = () => {
    stateDispatch({
      type: "custom_fields_edit_toggle",
      payload: !customFieldsEditToggle
    });
  };
  const [overflowVisible, setOverflowVisible] = useState(false);

  // derived state
  const isOrgAdmin = isAtLeastPowerUser && isOrgDefinedColumn;
  const isAddActionAllowed = (isOrgAdmin || isUserDefinedColumn) && props?.editable;

  // if field addition allowed, derive field type: true org, false user
  const isOrg = isUserDefinedColumn ? false : true;

  const geomBinDispatch = useGeomBinDispatch();

  const handleDelete = () => {
    if (selectedTab === "fields") {
      fieldDelete();
    } else {
      geomFieldDelete();
    }
  };

  const openGeomTool = () => {
    setOverflowVisible(false);
    geomBinDispatch({
      payload: {
        isActive: true,
        selectedGeomBinId: undefined,
        isOrg
      }
    });
  };

  // do not render if not Org or User field
  if (!isAddActionAllowed) return null;

  return (
    <Wrapper className={className}>
      {customFieldsEditToggle && (
        <Popconfirm
          placement="bottom"
          onConfirm={(evt) => {
            handleDelete();
            evt.stopPropagation();
            evt.preventDefault();
          }}
          onCancel={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
          okText="Delete"
          okType="danger"
          title={`Are you sure you want to delete the selected fields?`}>
          <Tooltip placement="top" title="Delete checked items">
            <StyledButton
              danger
              disabled={!(checkedFields.length > 0)}
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
              }}>
              {checkedFields.length > 0 ? (
                <Delete fontSize="large" />
              ) : (
                <DeleteOutline fontSize="large" />
              )}
            </StyledButton>
          </Tooltip>
        </Popconfirm>
      )}
      {(isUserDefinedColumn || isOrgDefinedColumn) && (
        <Tooltip title={"Toggle List Edit"}>
          <StyledButton
            className={"toolbar-button"}
            customFieldsEditToggle={customFieldsEditToggle}
            onClick={toggleEditMode}>
            <Icon
              style={{
                marginLeft: "-8px"
              }}
              path={mdiPlaylistEdit}
              className="activity-action-icon"
              size={1.4}
            />
          </StyledButton>
        </Tooltip>
      )}

      <Popover
        arrowPointAtCenter
        content={
          <OverflowWrapper>
            <Tooltip title={"Add Calculated Field"}>
              <StyledButton
                className={"toolbar-button"}
                onClick={() =>
                  dispatch(
                    addField({
                      type: "Calculated",
                      isOrg
                    })
                  )
                }>
                {<Functions fontSize="large" />}
                {"Calculated Field"}
              </StyledButton>
            </Tooltip>
            <Tooltip title={"Excel Fields"}>
              <StyledButton
                className={"toolbar-button"}
                onClick={() =>
                  dispatch(
                    addField({
                      type: "Excel",
                      isOrg
                    })
                  )
                }>
                {<GridOn fontSize="large" />}
                {"Excel Fields"}
              </StyledButton>
            </Tooltip>
            <Tooltip title={"Add Polygons Bin"}>
              <StyledButton className={"toolbar-button"} onClick={openGeomTool}>
                {<AddLocation fontSize="large" />}
                {"Polygons Bin"}
              </StyledButton>
            </Tooltip>
          </OverflowWrapper>
        }
        placement="bottomLeft"
        trigger="click"
        open={overflowVisible}
        onOpenChange={(v) => setOverflowVisible(v)}>
        <Button>
          {"Add"}
          <KeyboardArrowDown fontSize="large" />
        </Button>
      </Popover>
    </Wrapper>
  );
}

export default UserFieldToolbar;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--space-4);
  gap: 5px;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const OverflowWrapper = styled.div`
  display: grid;
  max-height: 300px;
  border-radius: var(--border-radius);
  padding: 8px 12px;
  margin: -12px -16px;
  overflow: hidden;

  & > button {
    width: auto !important;
  }
`;

const StyledButton = styled.button`
  width: 32px;
  height: 32px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  background: ${() => "transparent"};
  color: ${() => "#a2aaad"};
  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
    background: ${() => "transparent"};
  }
  border: none;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  ${({ customFieldsEditToggle }) =>
    customFieldsEditToggle && `color: var(--color-primary);`}
`;
