import { ISeriesStyle } from "components/multiphase-chart/models/shared.models";

const modifySeriesStyles = (
  styles: ISeriesStyle[],
  productKeys: string[],
  color: string,
  thickness: number
) => {
  const newStyles = styles ? [...styles] : [];

  productKeys.forEach((key) => {
    if (!key) return;

    const existingStyleIndex = styles?.findIndex((item) => item.product === key);

    // Use lightenColorByPercentage if needed in the future (key.includes("-fcst") ? lightenColorByPercentage(color, 25) : color)
    const newColor = color || "#000000";
    const newThickness = thickness ? Math.round(thickness) : 2;
    const isDashed = key.includes("-fcst");

    if (existingStyleIndex !== -1 && existingStyleIndex !== undefined) {
      newStyles[existingStyleIndex] = {
        ...newStyles[existingStyleIndex],
        ...{ color: newColor, width: newThickness }
      };
    } else {
      newStyles.push({
        product: key,
        color: newColor,
        width: newThickness,
        dashed: isDashed
      });
    }
  });

  return newStyles;
};

export default modifySeriesStyles;
