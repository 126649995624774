import { ForecastFolder } from "models/UserArpsModel";

export const getNewFolderName = (forecastFolders: ForecastFolder[]) => {
  let name = "New Folder";
  const existingNames = {};
  for (const item of forecastFolders) {
    existingNames[item.title] = true;
  }
  let number = 1;
  while (existingNames[name]) {
    name = `New Folder ${number++}`;
  }
  return name;
};
