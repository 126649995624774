import { Button } from "antd";
import styled from "styled-components";

export const BusyIndicator = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    display: none;
  }
`;

export const ErrorOverlay = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  right: 0;
  bottom: 0;
  background: #a8a8a811;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 997;
`;

export const BusyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UserArpsContainer = styled.div`
  grid-template-columns: minmax(0, 1fr);
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  overflow-y: hidden;

  .sub-heading {
    flex-direction: row;
    display: flex;
    padding: 5px 20px;
  }

  .ant-collapse-header {
    overflow: hidden;
  }

  .ant-tabs-content-holder {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const ButtonIconCentered = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-action-button);
  &:hover {
    color: var(--color-primary);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  color: var(--color-text);
  width: 100%;
  padding: 8px 10px 8px 20px;
  border-bottom: 1px solid #e2e5e6;

  h3 {
    margin: 0;
    margin-right: auto;
  }

  .label {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    color: var(--lead);
  }

  .icon {
    place-self: center;
  }

  .BaseIcon {
    color: var(--fume);
  }
`;
