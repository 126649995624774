import { FC, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import styled from "styled-components/macro";

import { useScreenshotDispatch } from "components/screenshot/hooks";
import { useScreenshotContext } from "components/screenshot/hooks/useScreenshotContext";

import { Portal } from "../ui";
import { useChartDispatch, useChartState } from "./context";
import { ChartLegend } from "./legend";

type ScreenshotT = {
  containerId: string;
};

const Screenshot: FC<ScreenshotT> = ({ containerId }) => {
  // context
  const { id, screenshot, request, response, options } = useChartState();
  const { settings, widget } = useScreenshotContext();

  // dispatch
  const dispatch = useChartDispatch();
  const screenshotDispatch = useScreenshotDispatch();

  // ref
  const overlayRef = useRef<HTMLDivElement>(null);

  // derived state
  const screenshotContainer = document.getElementById(containerId);

  useEffect(() => {
    const screenshotActive = !!id && widget?.widgetId === id;

    const next = { visible: screenshotActive, preset: settings };
    dispatch({ type: "screenshot", payload: next });

    dispatch({ type: "fullscreen", payload: screenshotActive });
  }, [dispatch, id, settings, widget]);

  // Update the widgets state.
  useEffect(() => {
    const screenshotActive = !!id && widget?.widgetId === id;

    if (screenshotActive) {
      // Copy the options to prevent mutation.
      const optionsCopy = JSON.parse(JSON.stringify(options));
      screenshotDispatch({
        payload: {
          widgetState: {
            request,
            response,
            options: optionsCopy
          }
        }
      });
    }
  }, [request, response, options, screenshotDispatch, id, widget]);

  if (!screenshot.visible || !screenshotContainer) return null;

  // Dismiss toast messages when screenshot first gets toggled
  toast.dismiss(`sync-${id}`);
  toast.dismiss(`type-well-only-${id}`);
  toast.dismiss(`well-norm-${id}`);
  toast.dismiss(`type-well-norm-${id}`);

  // Update the overlay.
  screenshotDispatch({
    payload: {
      screenshotOverlay: overlayRef.current
    }
  });

  const screenshotBounds = {
    width: settings?.width || 1152,
    height: settings?.height || 681
  };

  return (
    <Portal container={screenshotContainer}>
      <Overlay
        ref={overlayRef}
        width={screenshotBounds.width}
        height={screenshotBounds.height}>
        {settings?.legendVisible && (
          <ChartLegend
            parentDimensions={screenshotBounds}
            id={containerId + "chart-legend"}
            disableDragging={false}
            showLegendBorder={settings?.legendBorderVisible}
            width={250}
          />
        )}
      </Overlay>
    </Portal>
  );
};

export default Screenshot;

const Overlay = styled.div`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border: 2px solid rgb(255, 208, 0);
  z-index: 1;
  pointer-events: none;
`;
