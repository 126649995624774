/**
 * Space out colors in array while keeping first and last one
 * and having a maximum length of 8
 *
 * @param {string[]} colors array containing colors
 * @returns {string[]} returns an array of 8 colors spaced out
 */
export function spaceOutColorsForDisplay(colors: string[]): string[] {
  const len = colors.length;
  const spacedOutColors = [];
  if (len) {
    const step = Math.max(Math.floor((len - 2) / 6), 1);
    spacedOutColors.push(colors[0]);

    let count = 0;
    for (let i = 1; i < len - 1; i += step) {
      if (count >= 6) {
        break;
      }
      spacedOutColors.push(colors[i]);
      count++;
    }
    spacedOutColors.push(colors[len - 1]);
  }

  return spacedOutColors;
}
