import { getDatabaseRateUnits, productStringToProductTypeEnum } from "utils";
import { convert } from "utils/units/unitConversion";

import { ForecastConstant } from "models/UserArpsModel";

export function convertConstantsToDatabaseUnits(constants: ForecastConstant[]) {
  return constants.map((c) => {
    const dbUnit = getDatabaseRateUnits(productStringToProductTypeEnum(c.product));
    const convertedValue = convert(c.value, c.unit, dbUnit);
    return {
      ...c,
      value: convertedValue,
      unit: dbUnit
    };
  });
}
