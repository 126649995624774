import { FC } from "react";
import { useSelector } from "react-redux";

import { Button, Popover } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import CollapseVerticalIcon from "components/icons/CollapseVertical";

import { Normalize } from "../normalize";

const NormalizeSelect: FC = () => {
  // state from store
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );

  return (
    <Popover
      content={<Normalize />}
      overlayClassName="normalize-select-popover"
      placement="bottom"
      trigger="click">
      <StyledButton
        data-testid="focus-normalize-button"
        icon={<CollapseVerticalIcon />}
        useNormalizeBy={useNormalizeBy}
      />
    </Popover>
  );
};

const StyledButton = styled(Button)`
  background: ${(props) =>
    props.useNormalizeBy ? "var(--color-primary)" : "transparent"};
  font-size: 1.3rem;
  color: ${(props) => (props.useNormalizeBy ? "white" : "#a2aaad")};
  display: flex;
  align-items: center;
  &:hover {
    color: ${(props) =>
      props.useNormalizeBy ? "var(--color-primary)" : "var(--color-text)"};
  }
`;

export default NormalizeSelect;
