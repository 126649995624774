import { IGroupByListColumn, IGroupByListItem } from "models";

//Given a list of groupings and a property string.
//This function returns the groupings and the supplied properties (the property value)
const getGroupByListColumnByProperty = (
  categories: IGroupByListItem[],
  propertyName: string
): IGroupByListColumn => {
  const cols = categories?.map((l) => l.columns).flat();
  const map = {};
  cols?.forEach((col) => {
    map[col.property] = col;
  });
  return map[propertyName] ?? "";
};
export default getGroupByListColumnByProperty;
