import { memo } from "react";

import classnames from "classnames";
import styled from "styled-components";

const defaultSize = 36;
const defaultColor = "var(--color-text)";

function BaseIconToggle({
  activeIcon = null,
  children = null,
  className = "",
  color = defaultColor,
  disabled = false,
  size = defaultSize,
  squareIcon = false,
  toggle = null,
  value = false,
  ...rest
}) {
  const handleClick = () => (e) => {
    if (!toggle) return;

    const nextValue = !value;
    toggle(nextValue, e);
  };

  const containerClassNames = classnames("icon-toggle", className, {
    isActive: value,
    squareIcon
  });

  return (
    <Wrapper {...rest}>
      <StyledButton
        role="button"
        aria-pressed={value}
        className={containerClassNames}
        disabled={disabled}
        color={color}
        size={size}
        tabIndex={0}
        onClick={handleClick()}>
        {value && activeIcon ? activeIcon : children}
      </StyledButton>
    </Wrapper>
  );
}

export default memo(BaseIconToggle);

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${(props) => props.color};

  background-color: transparent;
  border: none;

  position: relative;
  padding: var(--space-2);
  transition: color var(--duration-short) var(--ease);
  user-select: none;
  cursor: pointer;

  &:hover:not(:disabled),
  &.isActive:not(:disabled) {
    color: var(--color-primary);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
