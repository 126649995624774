import { Business, Computer, Group, Person } from "@material-ui/icons";

import { Dashboard, WorkspaceDashboard } from "models/workspace";

export const getDashboardTypeIcon = (
  dashboard: Dashboard | WorkspaceDashboard | string
): React.ReactNode => {
  if (!dashboard) return <></>;

  const style = {
    fontSize: "18px",
    top: "4px",
    right: "2px",
    marginLeft: "3px"
  };

  const d = dashboard as Dashboard;
  const wd = dashboard as WorkspaceDashboard;
  let type: string = dashboard as string;

  if (d?.type) {
    type = d.type.toLowerCase();
  }
  if (wd?.type) {
    type = wd.type.toLowerCase();
  }

  switch (type) {
    case "shared":
      return <Group style={style} data-testid="dashboard-icon-shared" />;
    case "organization":
      return <Business style={style} data-testid="dashboard-icon-organization" />;
    case "system":
      return <Computer style={style} data-testid="dashboard-icon-system" />;
    case "personal":
      return <Person style={style} data-testid="dashboard-icon-personal" />;
    default:
      return <></>;
  }
};
