import { memo } from "react";

function ProductBoe() {
  return (
    <svg width="29px" height="26px" viewBox="0 0 29 26" version="1.1">
      <g id="IconsChart---PRPBOE">
        <path
          d="M7.01719 19.051C10.8927 19.051 14.0344 15.9093 14.0344 12.0338C14.0344 8.15833 7.01719 2.70731e-13 7.01719 2.70731e-13C7.01719 2.70731e-13 0 8.15833 0 12.0338C0 15.9093 3.1417 19.051 7.01719 19.051Z"
          transform="translate(14.159365 6.601632)"
          id="path_111"
        />

        <g id="Group">
          <path
            d="M0.5 0L0.5 20"
            transform="translate(1.9735096 0.5)"
            id="Path-3-Copy-3"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M0 0L14.9756 0L14.9756 19.5L0 19.5L0 0Z"
            transform="translate(1.6018982 0.5)"
            id="Rectangle"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
          />
          <path
            d="M0.5 0L0.5 20"
            transform="translate(16.077505 0.5)"
            id="Path-3-Copy-4"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M-5.55426e-13 0.5L18.5376 0.5"
            transform="translate(0.25671387 0)"
            id="Path-7"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M-5.70809e-13 0.5L19.051 0.5"
            transform="translate(0 1.8430958)"
            id="Path-7-Copy-3"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="#FFFFFF"
            strokeWidth="1.5"
          />
          <path
            d="M-5.55426e-13 0.5L18.5376 0.5"
            transform="translate(0.25671387 10)"
            id="Path-7-Copy"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
          <path
            d="M-5.70809e-13 0.5L19.051 0.5"
            transform="translate(0 11.843096)"
            id="Path-7-Copy-4"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="#FFFFFF"
            strokeWidth="1.5"
          />
          <path
            d="M-5.55426e-13 0.5L18.5376 0.5"
            transform="translate(0.25671387 20)"
            id="Path-7-Copy-2"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
        <g id="Oval-Copy-11">
          <use href="#path_111" fill="none" stroke="#FFFFFF" strokeWidth="4.5" />
          <use stroke="none" fill="currentColor" href="#path_111" fillRule="evenodd" />
        </g>
      </g>
    </svg>
  );
}

export default memo(ProductBoe);
