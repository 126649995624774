import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { CameraAltOutlined, ErrorOutlined } from "@material-ui/icons";
import { Button, Form } from "antd";
import styled from "styled-components/macro";
import { excludePropertiesByKeys, removePropertyFromObject } from "utils/objects";

import { useLocalStorage } from "hooks";

import { ScreenshotNotFocusedError } from "models/screenshot";

import { ErrorBoundary, SubHeading } from "components/base";

import ScreenshotSettingsForm from "./ScreenshotSettingsForm";
import { PresetSelect } from "./components";
import {
  useScreenshotCalculatedDefaults,
  useScreenshotContext,
  useScreenshotDispatch,
  useScreenshotPresetContext
} from "./hooks";
import { getStoredScreenshotPresetKey, getStoredScreenshotSettingsKey } from "./utils";
import copyToClipboard from "./utils/copyToClipboard";

const resetFieldsKeys = [
  "width",
  "height",
  "titleFontSize",
  "axisFontSize",
  "axisLabelFontSize",
  "topsLabelsFontSize",
  "dataLabelsFontSize",
  "gridLabelsFontSize",
  "legendFontSize"
];

export default function ScreenshotActivity() {
  // hooks
  const {
    currentFieldKey,
    isChangingPreset,
    isEditing,
    isResettingPreset,
    settings,
    widget,
    widgetState,
    screenshotOverlay
  } = useScreenshotContext();

  const defaultSettings = useScreenshotCalculatedDefaults();
  const { selectedPreset } = useScreenshotPresetContext();

  // Local Storage
  const {
    setStoredValue: setStoredScreenshotSettings,
    getStoredValue: getStoredScreenshot
  } = useLocalStorage(getStoredScreenshotSettingsKey(widget?.widgetId), undefined);

  const { setStoredValue: setStoredPreset } = useLocalStorage(
    getStoredScreenshotPresetKey(widget?.widgetId),
    undefined
  );

  // dispatch
  const screenshotDispatch = useScreenshotDispatch();

  // state
  const [form] = Form.useForm();
  const [copyError, setCopyError] = useState("");
  // Should be only kept to values that have been edited
  const [formValues, setFormValues] = useState({});
  const [isInitiallyLoading, setIsInitiallyLoading] = useState(false);
  const [isGeneratingScreenshot, setIsGeneratingScreenshot] = useState(false);

  const handleTakeScreenshot = useCallback(async () => {
    setIsGeneratingScreenshot(true);
    try {
      await copyToClipboard(
        widget?.widgetComponentType,
        widgetState,
        settings,
        screenshotOverlay
      );

      setCopyError("");
      toast.success("Screenshot copied to clipboard");

      screenshotDispatch({
        payload: {
          widget: null,
          widgetState: null
        }
      });
    } catch (err) {
      if (err instanceof ScreenshotNotFocusedError) {
        setCopyError("Please focus on the browser while the screenshot is being taken.");
      } else {
        setCopyError("Error copying screenshot to clipboard.");
      }
    } finally {
      setIsGeneratingScreenshot(false);
    }
  }, [
    settings,
    widget?.widgetComponentType,
    widgetState,
    screenshotOverlay,
    screenshotDispatch
  ]);

  const handleCancel = useCallback(() => {
    setIsGeneratingScreenshot(false);
    setCopyError("");
    screenshotDispatch({
      payload: {
        widget: null,
        widgetState: null
      }
    });
  }, [screenshotDispatch]);

  // Merge properties that have been automatically calculated, and properties that have been manually inputted
  const getMergedSettings = (defaultSettings, settingsToMerge) => {
    const calculatedSettings = defaultSettings
      ? Object.fromEntries(
          Object.entries(defaultSettings).filter(([, value]) => value !== undefined)
        )
      : {};

    const incomingSettings = settingsToMerge
      ? Object.fromEntries(
          Object.entries(settingsToMerge).filter(([, value]) => value !== undefined)
        )
      : {};

    const nextSettings = {
      ...calculatedSettings,
      ...incomingSettings
    };

    return nextSettings;
  };

  /**
   * Conditionally controls the form fields setting/resetting.
   * Since we are using the calculated settings (defaultSettings) hook,
   * we need to keep all of the inputs up to date when anything changes on the form.
   *
   * Form fields will be set by each condition (isResetting, isEditing, etc).
   *
   * The screenshot will be updated by formValues changing, which is set by each condition, except field input editing.
   * In the case of field input editing, formValues is updated in the form input handler.
   */
  useEffect(() => {
    const handleResetToPreset = () => {
      if (isResettingPreset) {
        const fieldsToKeep = excludePropertiesByKeys(formValues, resetFieldsKeys);

        setStoredScreenshotSettings(fieldsToKeep);
        setFormValues(fieldsToKeep);

        form.resetFields(resetFieldsKeys);

        screenshotDispatch({
          payload: {
            isResettingPreset: false
          }
        });
      }
    };

    const handleChangingPreset = () => {
      if (isChangingPreset) {
        const fieldsToKeep = excludePropertiesByKeys(formValues, resetFieldsKeys);

        setFormValues(fieldsToKeep);
        setStoredScreenshotSettings(fieldsToKeep);
        setStoredPreset({ ...selectedPreset });

        form.resetFields();

        screenshotDispatch({
          payload: {
            isChangingPreset: false
          }
        });
      }
    };

    const handleFormFieldEditing = () => {
      // This avoids rerendering the input you are editing.
      // The other fields need to be rerendered for updated calculations.
      if (currentFieldKey && isEditing) {
        const nextSettings = getMergedSettings(defaultSettings, formValues);

        const settingsWithoutCurrentField = removePropertyFromObject(
          nextSettings,
          currentFieldKey
        );

        form.setFieldsValue(settingsWithoutCurrentField);
      }
    };

    const handleInitialLoading = () => {
      if (isInitiallyLoading) {
        const nextSettings = getMergedSettings(defaultSettings, formValues);
        form.setFieldsValue(nextSettings);
        setIsInitiallyLoading(false);
      }
    };

    handleResetToPreset();
    handleChangingPreset();
    handleFormFieldEditing();
    handleInitialLoading();

    // Ensure formValues is updated in order for the screenshot to update
    const nextSettings = getMergedSettings(defaultSettings, formValues);
    screenshotDispatch({ payload: { settings: nextSettings } });

    if (isInitiallyLoading) {
      // By this render, the form instance's inputs have been set to their stored values
      // Force ScreenshotSettingItemInput to reevaluate it's initial value
      form.resetFields();
    }
  }, [
    defaultSettings,
    isChangingPreset,
    isEditing,
    isResettingPreset,
    selectedPreset,
    formValues,
    currentFieldKey
  ]);

  // Handle initial load for screenshot memory
  useEffect(() => {
    const storedSettings = getStoredScreenshot() ?? {};
    setFormValues(storedSettings);
    setIsInitiallyLoading(true);
  }, []);

  // Ensure form fields are up to date with calculated values, and form values
  const nextSettings = getMergedSettings(defaultSettings, formValues);
  form.setFieldsValue(nextSettings);

  return (
    <ErrorBoundary>
      <ActivityWrapper>
        <ScreenshotHeaderContainer>
          <SubHeading>Screenshot Settings</SubHeading>
          <PresetContainer>
            <PresetSelect />
          </PresetContainer>
        </ScreenshotHeaderContainer>
        <SettingsContainer>
          <ScreenshotSettingsForm form={form} setFormValues={setFormValues} />
        </SettingsContainer>
        <FooterContainer>
          {copyError && (
            <ErrorContainer>
              <ErrorOutlined /> {copyError}
            </ErrorContainer>
          )}
          <ActionsContainer>
            <StyledButton
              type="default"
              onClick={handleCancel}
              data-testid="cancel-screenshot"
              data-qa="cancel-screenshot">
              Cancel
            </StyledButton>
            <StyledButton
              type="primary"
              loading={isGeneratingScreenshot}
              onClick={handleTakeScreenshot}
              data-testid="take-screenshot"
              data-qa="take-screenshot"
              disabled={isGeneratingScreenshot}>
              <CameraAltOutlined /> Take Screenshot
            </StyledButton>
          </ActionsContainer>
        </FooterContainer>
      </ActivityWrapper>
    </ErrorBoundary>
  );
}

const ActivityWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  width: 100%;
`;

const ScreenshotHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 10px 8px 20px;
  border-bottom: 1px solid #e2e5e6;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  h3 {
    margin: 0;
    margin-right: auto;
    line-height: 32px;
  }
`;

const PresetContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  justify-content: space-between;

  button {
    color: #a2aaad;
    display: flex;
    align-items: center;
    padding: 2px;
  }
`;

const SettingsContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  .ant-collapse-header-text {
    font-weight: 800;
  }
  .ant-form-item {
    margin-bottom: 5px;
    .ant-row {
      gap: 5px;
    }
    .ant-col.ant-form-item-label {
      width: 42%;
      /* label {
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      } */
    }
    .ant-col.ant-form-item-control {
      width: 58%;
    }
  }
  .ant-input-suffix {
    opacity: 0.6;
  }
  .ant-space-compact {
    button.ant-btn {
      display: flex;
      align-items: center;
      padding: 3px 0;
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 10px 8px 20px;
  border-top: 1px solid #e2e5e6;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;

  .ant-btn {
    border-radius: 5px;
    > span {
      margin-left: 0;
    }
  }
  .ant-btn:hover {
    border-radius: 5px;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  button[disabled],
  button[disabled]:hover {
    background-color: #a2aaad;
    border-color: rgba(0, 0, 0, 0.15);
    color: white;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 8px 0;
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
  text-align: left;
`;
