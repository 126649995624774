import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { FileCopyOutlined } from "@material-ui/icons";
import { Button, Popover } from "antd";
import axios from "axios";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { getWellsFromFilterId } from "api/filter";

const SAM_SERVICE_URI = process.env.REACT_APP_SAM_CHART_SERVICE;
export default function SamTable() {
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const { data: wellList, refetch: updateWellList } = useQuery(
    "well-list-query",
    async () => {
      const response = await getWellsFromFilterId(filterId.id);
      if (response.status === 200) {
        return response.data.uwiList?.map((w) => w.formattedId);
      }
      return [];
    }
  );
  useEffect(() => {
    updateWellList();
  }, [filterId, updateWellList]);
  const { data, refetch: updateData } = useQuery(
    "sam-type-well",
    async () => {
      const response = await axios.post(`${SAM_SERVICE_URI}/SamChart/rollup`, {
        Ooip: 295.9596797619047,
        PoreVolume: 393.79703517887043,
        ShowForecast: true,
        WellPairUwis: wellList
      });
      if (response.status === 200) {
        return response.data;
      }
      return {};
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    updateData();
  }, [updateData, wellList]);

  function numberWithCommas(x: number) {
    const dotIndex = x.toString().indexOf(".");
    let beforeDot = x.toString();
    let afterDot = "";
    if (dotIndex > 0) {
      afterDot = beforeDot.substring(dotIndex);
      beforeDot = beforeDot.substring(0, dotIndex);
    }
    return (
      beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (afterDot.length > 0 ? afterDot : "")
    );
  }

  function copyTableToClipboard() {
    if (!navigator.clipboard) {
      toast.error("Clipboard copy is not supported by the browser.");
    }
    if (!tableRef.current) {
      toast.error("Table is not available.");
    }
    const spreadSheetRow = new Blob([tableRef.current.outerHTML], {
      type: "text/html"
    });
    navigator.clipboard
      .write([new ClipboardItem({ "text/html": spreadSheetRow })])
      .then(() => {
        setCopiedToClipboard(true);
        setTimeout(() => {
          setCopiedToClipboard(false);
        }, 2000);
      })
      .catch(() => setCopiedToClipboard(false));
  }

  return (
    <Container>
      <CopyWrapper>
        <Popover
          open={copiedToClipboard}
          trigger="click"
          content={<div>Copied to clipboard.</div>}>
          <Button type="primary" onClick={copyTableToClipboard}>
            <FileCopyOutlined /> Copy
          </Button>
        </Popover>
      </CopyWrapper>
      <table ref={tableRef} width="100%" className="rollup" cellSpacing={0}>
        <thead>
          <tr className="heading1">
            <th />
            <th />
            <th colSpan={9} style={{ borderBottom: "1px solid black" }}>
              Production
            </th>
            <th colSpan={6} style={{ borderBottom: "1px solid black" }}>
              Injection
            </th>
            <th colSpan={3} style={{ borderBottom: "1px solid black" }}>
              Stats
            </th>
          </tr>
          <tr className="heading2">
            <th></th>
            <th>{"Producing SAGD Wells\t"}</th>
            <th>{"Daily Bitumen Prod\t"}</th>
            <th>{"Monthly Bitumen Prod\t"}</th>
            <th>{"Cum Bitumen Prod\t"}</th>
            <th>{"Daily Gas Prod\t"}</th>
            <th>{"Monthly Gas Prod\t"}</th>
            <th>{"Cum Gas Prod\t"}</th>
            <th>{"Daily Water Prod\t"}</th>
            <th>{"Monthly Water Prod\t"}</th>
            <th>{"Cum Water Prod\t"}</th>
            <th>{"Daily Water Inj.\t"}</th>
            <th>{"Monthly Water Inj.\t"}</th>
            <th>{"Cum Water Inj.\t"}</th>
            <th>{"Daily Gas Inj.\t"}</th>
            <th>{"Monthly Gas Inj.\t"}</th>
            <th>{"Cum Gas Inj.\t"}</th>
            <th>{"\t"}</th>
            <th>{"\t"}</th>
            <th>{"Bitumen Prod Per Pair\t"}</th>
          </tr>
          <tr className="units">
            <th>Month</th>
            <th>#</th>
            <th>bopd</th>
            <th>Mbbl</th>
            <th>Mbbl</th>
            <th>mcfpd</th>
            <th>MMcf</th>
            <th>MMcf</th>
            <th>bopd</th>
            <th>Mbbl</th>
            <th>Mbbl</th>
            <th>bopd</th>
            <th>Mbbl</th>
            <th>Mbbl</th>
            <th>mcfpd</th>
            <th>MMcf</th>
            <th>MMcf</th>
            <th>ISOR</th>
            <th>CSOR</th>
            <th>bopd</th>
          </tr>
        </thead>
        <tbody>
          {((data ?? {}).rollupsByMonth ?? []).map((item) => (
            <tr key={item.prodDate}>
              <td style={{ textAlign: "left" }}>{item.prodDate + "\t"}</td>
              <td>{numberWithCommas(item.producingWellPairCount) + "\t"}</td>
              <td>{numberWithCommas(item.dailyBitumen) + "\t"}</td>
              <td>{numberWithCommas(item.monthlyBitumen) + "\t"}</td>
              <td>{numberWithCommas(item.cumBitumen) + "\t"}</td>
              {/* gas production */}
              <td>{numberWithCommas(item.dailyGas) + "\t"}</td>
              <td>{numberWithCommas(item.monthlyGas) + "\t"}</td>
              <td>{numberWithCommas(item.cumGas) + "\t"}</td>

              <td>{numberWithCommas(item.dailyWater) + "\t"}</td>
              <td>{numberWithCommas(item.monthlyWater) + "\t"}</td>
              <td>{numberWithCommas(item.cumWater) + "\t"}</td>
              <td>{numberWithCommas(item.dailyWaterInj) + "\t"}</td>
              <td>{numberWithCommas(item.monthlyWaterInj) + "\t"}</td>
              <td>{numberWithCommas(item.cumWaterInj) + "\t"}</td>
              {/* gas inj */}
              <td>{numberWithCommas(item.dailyGasInj) + "\t"}</td>
              <td>{numberWithCommas(item.monthlyGasInj) + "\t"}</td>
              <td>{numberWithCommas(item.cumGasInj) + "\t"}</td>

              <td>{numberWithCommas(item.isor) + "\t"}</td>
              <td>{numberWithCommas(item.csor) + "\t"}</td>
              <td>{numberWithCommas(item.bitumenProdPerPair) + "\t"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

const CopyWrapper = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  overflow-x: auto;
  min-width: 400px;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  .rollup {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    padding: 10px;
    thead {
      background: white;
      position: sticky;
      top: 0;
      z-index: 10;
      th {
        min-width: 80px;
      }
      .units {
        th {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid black;
          position: sticky;
          background: white;
        }
      }
      .heading2 {
        th {
          color: rgb(35, 49, 87);
          background: white;
          padding: 0;
          margin: 0;
          max-height: 80px;
          height: 80px;
        }
      }
      .heading1 {
        th {
          background: white;
          padding: 0;
          margin: 0;
        }
      }
    }
    td {
      text-align: right;
      min-width: 60px;
      background: white;
      margin: 0;
      padding: 0;
    }
  }
`;
