export default class Uwi {
  constructor() {
    this.name = "Uwi";
  }

  toUnformatted(s) {
    const removedFormatting = this.replaceAll(this.replaceAll(s, "-", ""), "/", "");
    const uwi = this.parse(removedFormatting.toUpperCase());
    return uwi.toUnformatted();
  }
  toFormatted(s) {
    const removedFormatting = this.replaceAll(this.replaceAll(s, "-", ""), "/", "");
    const uwi = this.parse(removedFormatting.toUpperCase());
    return uwi.toFormatted();
  }

  parse(s) {
    const dls = this.parseDls(s);
    if (dls.id) return dls;
    const nts = this.parseNts(s);
    if (nts.id) return nts;
    const api = this.parseUsApi(s);
    if (api.id) return api;
    return new Unknown("N/A", s);
  }
  escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\]\\])/g, "\\$1");
  }
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
  }
  parseDls(s) {
    const re =
      /^(1?)([0-9A-Z]{2})([0-9|X]{2})([0-9|X]{2})([0-9]{3})([0-9]{2})(W{1})([1-6]{1})([0-9|X]{0,2})$/g;
    const matches = [...s.matchAll(re)];
    if (matches.length === 0) return {};

    return new DLS(
      1,
      matches[0][2],
      matches[0][3],
      matches[0][4],
      matches[0][5],
      matches[0][6],
      matches[0][8],
      matches[0][9].padStart(2, "0")
    );
  }

  parseNts(s) {
    const re =
      /^(2?)([0-9A-Z]{2})([A-Z]{1})([0-9|X]{3})([A-Z]{1})([0-9]{3})([A-Z]{1})([0-9]{2})([0-9|X]{0,2})$/g;
    const matches = [...s.matchAll(re)];
    if (matches.length === 0) return {};

    const event = matches[0][9].padStart(2, "0");
    return new NTS(
      2,
      matches[0][2],
      matches[0][3],
      matches[0][4],
      matches[0][5],
      matches[0][6],
      matches[0][7],
      matches[0][8],
      event
    );
  }
  parseUsApi(s) {
    const re = /^5?([0-9]{2})([0-9]{3})([0-9]{5})([0-9]{2})([0-9]{0,2})$/g;
    const matches = [...s.matchAll(re)];
    if (matches.length === 0) return {};
    return new UsAPI(
      5,
      matches[0][1],
      matches[0][2],
      matches[0][3],
      matches[0][4],
      matches[0][5]
    );
  }
}

class DLS {
  constructor(id, loc, lsd, sec, twp, range, mer, event) {
    this.id = id;
    this.loc = loc;
    this.lsd = lsd;
    this.sec = sec;
    this.twp = twp;
    this.range = range;
    this.mer = mer;
    this.event = event;
  }

  toUnformatted() {
    return `${this.id}${this.loc}${this.lsd}${this.sec}${this.twp}${this.range}W${this.mer}${this.event}`;
  }
  toFormatted() {
    return `${this.id}${this.loc}/${this.lsd}-${this.sec}-${this.twp}-${this.range}W${this.mer}/${this.event}`;
  }
}

class NTS {
  constructor(id, loc, qtr, unit, block, series, area, sheet, event) {
    this.id = id;
    this.loc = loc;
    this.qtr = qtr;
    this.unit = unit;
    this.block = block;
    this.series = series;
    this.area = area;
    this.sheet = sheet;
    this.event = event;
  }

  toUnformatted() {
    return `${this.id}${this.loc}${this.qtr}${this.unit}${this.block}${this.series}${this.area}${this.sheet}${this.event}`;
  }
  toFormatted() {
    return `${this.id}${this.loc}/${this.qtr}-${this.unit}-${this.block}/${this.series}-${this.area}-${this.sheet}/${this.event}`;
  }
}

class UsAPI {
  constructor(id, state, county, uwi, sidetrack, event) {
    this.id = id;
    this.state = state;
    this.county = county;
    this.uwi = uwi;
    this.sidetrack = sidetrack;
    this.event = event;
  }
  toUnformatted() {
    return `${this.state}${this.county}${this.uwi}${this.sidetrack}${this.event}`;
  }
  toFormatted() {
    return `${this.state}-${this.county}-${this.uwi}-${this.sidetrack}-${this.event}`;
  }
}

class Unknown {
  constructor(id, uwi) {
    this.id = id;
    this.uwi = uwi;
  }
  toUnformatted() {
    return `${this.uwi}`;
  }
  toFormatted() {
    return `${this.uwi}`;
  }
}
