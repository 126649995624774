import { IEditableProjectLayer, ProjectTreeNodeT } from "../hooks";

interface IGetShapefileNodeFromLayerListLayerParams {
  shapefileLayer: IEditableProjectLayer;
}
export function getShapefileNodeFromLayer(
  props: IGetShapefileNodeFromLayerListLayerParams
) {
  const { shapefileLayer } = props;

  const type =
    shapefileLayer.projectLayerType === "Organization"
      ? "orgShapefile"
      : shapefileLayer.projectLayerType === "McDaniel"
      ? "mcdanShapefile"
      : "projectShapefile";

  const key = shapefileLayer.projectShapefileId
    ? shapefileLayer.projectShapefileId
    : shapefileLayer.shapefileId;

  const shapefileNode: ProjectTreeNodeT = {
    checked: true,
    children: undefined,
    key: key,
    name: key,
    projectId: undefined,
    projectShapefileId: shapefileLayer.projectShapefileId,
    properties: shapefileLayer.properties,
    shapefileId: shapefileLayer.shapefileId,
    shapefileNodeId: undefined,
    source: undefined,
    title: shapefileLayer.name,
    type: "layer",
    layer: {
      name: shapefileLayer.name,
      type: type,
      selected: true,
      zoom: [0, 22],
      style: {
        color: shapefileLayer.Color,
        strokeColor: shapefileLayer.StrokeColor,
        thickness: shapefileLayer.Thickness,
        opacity: shapefileLayer.opacity
      }
    }
  };

  return shapefileNode;
}
