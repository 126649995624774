import axios from "axios";

import { EntityKind } from "../models/entityKind";

const userServiceRoot = process.env.REACT_APP_USER_SERVICE;

export async function loadSavedFilters(projectId: string, entityKind: EntityKind) {
  const response = await axios.post(process.env.REACT_APP_SAVED_FILTER_GQL_SERVICE, {
    operationName: "savedFilters",
    variables: { req: { projectID: projectId, entityKind } },
    query:
      "query savedFilters($req: SavedFilterInput!) {\n  savedFilters(req: $req) {\n    id\n    name\n    projectID\n    filterJSON\n    owner\n    __typename\n  }\n}\n"
  });
  if (response.status === 200) {
    return response.data.data;
  }
  return null;
}

export async function setDefaultTypeWell(payload) {
  try {
    const response = await axios.post(
      `${userServiceRoot}/user/setting/DefaultTypeWell/`,
      payload
    );
    if (response.status !== 200) {
      return null;
    }
    return response;
  } catch (error) {
    return null;
  }
}
