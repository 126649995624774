// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import React, { FC, useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";

import { mdiClose } from "@mdi/js";
import { Button } from "antd";
import styled from "styled-components";

import { useScreenshotContext } from "../screenshot/hooks";

interface DraggableLegendBaseProps {
  parentDimensions: { width: number; height: number };
  screenshotLegend?: boolean;
  legendTitle: string | React.ReactNode;
  closeable?: boolean;
  visible?: boolean;
  children?: React.ReactNode;
  initialWidth?: number;
  initialHeight?: number;
  onClose?: () => void;
}

export const DraggableLegendBase: FC<DraggableLegendBaseProps> = ({
  parentDimensions,
  screenshotLegend = false,
  legendTitle,
  visible,
  closeable,
  children,
  initialWidth = 210,
  initialHeight = 210,
  onClose
}) => {
  const { widget, settings } = useScreenshotContext();
  const [size, setSize] = useState({ width: initialWidth, height: initialHeight });
  const xOffset = screenshotLegend ? size.width + 270 : size.width + 10;

  const previousParentDimensionsRef = useRef<{ width: number; height: number }>({
    width: 0,
    height: 0
  });
  const [position, setPosition] = useState({
    x: parentDimensions.width - xOffset,
    y: parentDimensions.height - size.height
  });

  useEffect(() => {
    if (position.x < 0) setPosition({ x: 0, y: position.y });
    if (position.y < 0) setPosition({ x: position.x, y: 0 });

    /** Bugfix: The check for parentDimensions.width - size.width > 0 is to prevent infinite rerender,
     * if position is set to a negative number, line 33/34 will retrigger, this occured when opening the datatable */
    if (
      parentDimensions.width - size.width > 0 &&
      position.x > parentDimensions.width - size.width
    )
      setPosition({ x: parentDimensions.width - size.width - 5, y: position.y });
    if (
      parentDimensions.height - size.height > 0 &&
      position.y > parentDimensions.height - size.height
    )
      setPosition({ x: position.x, y: parentDimensions.height - size.height - 5 });
  }, [position, size]);

  useEffect(() => {
    if (settings?.showLegendInScreenshot) {
      setPosition({
        x: parentDimensions.width - xOffset,
        y: parentDimensions.height - size.height - 5
      });
    } else {
      setPosition({ x: parentDimensions.width, y: parentDimensions.height });
    }
  }, [widget, settings?.showLegendInScreenshot]);

  useEffect(() => {
    const previousParentDimensions = previousParentDimensionsRef.current;
    if (
      previousParentDimensions.width !== parentDimensions.width ||
      previousParentDimensions.height !== parentDimensions.height
    ) {
      const diffX = parentDimensions.width - previousParentDimensions.width;
      const diffY = parentDimensions.height - previousParentDimensions.height;
      setPosition({ x: position.x + diffX, y: position.y + diffY });
      previousParentDimensions.width = parentDimensions.width;
      previousParentDimensions.height = parentDimensions.height;
    }
  }, [parentDimensions]);

  if (!visible) {
    return null;
  }
  if ((screenshotLegend && !widget) || (!screenshotLegend && widget)) {
    return null;
  }
  return (
    <StyledRnd
      default={{
        x: position.x,
        y: position.y,
        ...size
      }}
      position={position}
      bounds={"parent"}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      enableResizing={true}
      onResizeStop={(e, direction, ref, delta, position) => {
        setPosition({ x: position.x, y: position.y });
        setSize({ width: ref.offsetWidth, height: ref.offsetHeight });
      }}
      disableDragging={false}>
      <LegendContainer className="bubble-vis-legend">
        <Title className="bubble-vis-legend-title">
          {legendTitle}
          {closeable && (
            <Button
              className={"close-btn"}
              type={"text"}
              icon={<Icon path={mdiClose} />}
              onClick={onClose}></Button>
          )}
        </Title>
        {children}
      </LegendContainer>
    </StyledRnd>
  );
};

const StyledRnd = styled(Rnd)`
  position: absolute;
  margin: 10px;
  background: ${(props) =>
    props.backgroundOpacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundOpacity})`};
  border: ${(props) => (props.showLegendBorder ? "1px solid gray" : "")};
  overflow: hidden;
  z-index: 9998; /* Set a high z-index value to ensure legend is on top within the screenshot component. */

  & > div:last-child {
    position: unset;
  }
`;

const Title = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .close-btn {
    align-self: flex-start;
  }
`;

const LegendContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid black;
  padding: 5px;

  .ant-divider {
    margin: 5px 0;
  }

  .bubble-vis-legend-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
