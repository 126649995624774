import { memo } from "react";

import ProducingRateIcon from "components/icons/ProducingRate";
import { useChartSettings } from "components/multiphase-chart/context";
import { changeSettings } from "components/multiphase-chart/context/reducer/chartSettingsReducer";

import ToolbarButton from "../../../toolbar/ToolbarButton";

const ProducingToggle = () => {
  const [chartSettings, chartSettingsDispatch] = useChartSettings();

  const onToggle = (value) => {
    const nextSettings = { ...chartSettings.settings, producing: value };
    changeSettings(chartSettingsDispatch, nextSettings);
  };

  return (
    <ToolbarButton
      testId="multiphasechart-toolbar-producing-toggle"
      icon={<ProducingRateIcon producing={chartSettings.settings?.producing} />}
      overflowLabel="Producing"
      tooltipText="Toggle Producing Day Rate"
      active={chartSettings.settings?.producing}
      onToggle={(v) => onToggle(v)}
    />
  );
};

export default memo(ProducingToggle);
