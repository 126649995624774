import { THEME_FONT_FAMILY } from "constants/style.constants";

export const calculateTextDimensions = (
  text: string,
  fontSize: number = 12,
  fontFamily: string = THEME_FONT_FAMILY
): { height: number; width: number } => {
  if (!text) return { height: 0, width: 0 };

  const span = document.createElement("span");
  document.body.appendChild(span);

  span.style.fontFamily = fontFamily;
  span.style.fontSize = `${fontSize}px`;
  span.style.height = "auto";
  span.style.width = "auto";
  span.style.top = "0px";
  span.style.position = "absolute";
  span.style.whiteSpace = "no-wrap";
  span.innerHTML = text;

  const width = Math.ceil(span.clientWidth);
  const height = Math.ceil(span.clientHeight);

  span.remove();
  return {
    width,
    height
  };
};
