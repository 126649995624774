import React, { memo } from "react";

import Step from "./Step";
import "./StepIndicator.scss";

function StepIdicator({ steps = 3, currentStep = 1, onClick = null }) {
  return (
    <div className="flex steps-container">
      {Array.from({ length: steps }, (_, i) => i + 1).map((option) => {
        const clickable = option === 1 && currentStep === 2;
        return (
          <Step
            key={option}
            number={option}
            past={option < currentStep}
            active={option === currentStep}
            onClick={onClick}
            clickable={clickable}
          />
        );
      })}
    </div>
  );
}
export default memo(StepIdicator);
