import { memo } from "react";

function RateTime() {
  return (
    <svg
      width="33px"
      height="23px"
      viewBox="0 0 33 23"
      version="1.1"
      id="svg27"
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs31" />
      <g id="iconRate-time" transform="translate(1.125 1.125)">
        <g id="iconTotal-rate-date-Copy">
          <g id="area-chart-icon_3">
            <path
              d="M0 0L0 19L18 19"
              id="Path-3"
              fill="none"
              fillRule="evenodd"
              stroke="currentColor"
              strokeWidth="2.25"
            />
            <path
              d="M 0.09629893,-3.8091417 C 3.3094483,3.9585044 1.6543684,5.2106634 13.30567,7.9440059"
              transform="translate(3.4093895,6.1366997)"
              id="Path-2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2.25"
            />
            <path
              d="M5.25 0C2.35503 0 0 2.35503 0 5.25C0 8.14497 2.35503 10.5 5.25 10.5C8.14497 10.5 10.5 8.14497 10.5 5.25C10.5 2.35503 8.14497 0 5.25 0ZM7.74683 7.96552C7.66151 8.05084 7.54952 8.09378 7.43753 8.09378C7.32553 8.09378 7.21346 8.05084 7.12823 7.96552L4.9407 5.77808C4.85843 5.69629 4.81253 5.58509 4.81253 5.46878L4.81253 2.625C4.81253 2.38307 5.00847 2.18753 5.25 2.18753C5.49153 2.18753 5.68747 2.38307 5.68747 2.625L5.68747 5.28765L7.74683 7.34692C7.91786 7.51804 7.91786 7.79449 7.74683 7.96552L7.74683 7.96552Z"
              transform="translate(19.90939 9.6367)"
              id="Shape"
              fill="currentColor"
              stroke="none"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(RateTime);
