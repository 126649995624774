import { useMutation, useQueryClient } from "react-query";

import axios, { AxiosError } from "axios";

import { PAD_SCENARIO_SERVICE_ADDRESS } from "../../../constants/app.constants";
import { PadLayerConfiguration } from "../models/scenario";
import { GetScenarioListKey } from "./useScenarioList";

const API_URL = `${PAD_SCENARIO_SERVICE_ADDRESS}/api/v1/pad-scenario`;

export interface PadLayerInput {
  scenarioId: string;
  layer: PadLayerConfiguration;
}

const postPadLayer = async (data: PadLayerInput) => {
  const response = await axios.post(`${API_URL}/${data.scenarioId}/layer`, data.layer);
  return response.data;
};

const usePostPadLayer = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, PadLayerInput, unknown>(postPadLayer, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(GetScenarioListKey);
    }
  });
};

export default usePostPadLayer;
