import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { RootState } from "store/rootReducer";

import { MemberAccountModel, postMemberAccount } from "api/users";

import { BaseButton, BaseInput, Heading } from "components/base";
import FieldErrorMessage from "components/base/FieldErrorMessage";
import PasswordInput from "components/base/PasswordInput";
import PasswordChecklist from "components/user/PasswordChecklist";

import "./SignUp.scss";

export default function SignUp() {
  const data = {
    title: "Set up user account",
    firstNameLabel: "Your first name",
    lastNameLabel: "Your last name",
    emailLabel: "Email address",
    passwordLabel: "Create a password",
    submitLabel: "Create Account"
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();

  //fill in form from b64 encoded token
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const tokenData = token ? JSON.parse(atob(token)) : {};
  useEffect(() => {
    const email = tokenData.Email;
    setEmail(email);
  }, []);

  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user, history]);

  const createAccount = useMutation(
    async (payload: MemberAccountModel) => await postMemberAccount(payload),
    {
      onSuccess: () => {
        toast.success("Your account has successfully been created.");
        setTimeout(() => {
          if (history) {
            history.push("/login");
          }
        }, 1000);
      },
      onError: (error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err.response?.data) {
          setErrors(`${err?.response.data}`);
        } else {
          setErrors(
            "We're sorry, we were unable to create your account due to a network error. Please try again at a later time."
          );
        }
      }
    }
  );

  return (
    <div className="content-container">
      <div>
        <Heading className="align-center large" element="h3">
          {data.title}
        </Heading>

        <form name="login" className="signup-form">
          <fieldset className="fieldset">
            <BaseInput
              type="text"
              value={firstName}
              label={data.firstNameLabel}
              required
              onChange={setFirstName}
              placeholder={"Firstname"}
            />
            <BaseInput
              type="text"
              value={lastName}
              label={data.lastNameLabel}
              required
              onChange={setLastName}
              placeholder={"Lastname"}
            />
            <BaseInput
              type="email"
              value={email}
              label={data.emailLabel}
              onChange={setEmail}
              required
              disabled
              readonly
            />
            <PasswordInput
              value={password}
              label={data.passwordLabel}
              onChange={setPassword}
              required
            />
            <PasswordChecklist password={password} setValidity={setIsValidPassword} />
            <div className="flex items-center justify-center">
              <BaseButton
                appearance="primary"
                className="justify-end login-btn"
                onClick={() =>
                  createAccount.mutateAsync({
                    firstName: firstName,
                    lastName: lastName,
                    password: password,
                    inviteToken: tokenData.InviteToken
                  })
                }
                isDisabled={!firstName || !lastName || !email || !isValidPassword}>
                {data.submitLabel}
              </BaseButton>
            </div>
          </fieldset>
          <div className="flex items-center justify-center login-row">
            <span>Already have an account?</span>
            <BaseButton
              appearance="subtle"
              className="sign-up-btn"
              onClick={() => history.push("/login")}>
              Login
            </BaseButton>
          </div>
          {errors && <FieldErrorMessage message={errors} />}
        </form>
      </div>
    </div>
  );
}
