import { useState } from "react";
import { Rnd } from "react-rnd";
import { FixedSizeList as List } from "react-window";

import { Typography } from "antd";
import classnames from "classnames";
import styled from "styled-components";

import getCalculatedLegend from "components/chart/legend/utils/getCalculatedLegend";
import {
  PositionsT,
  getPositionFromChartSettings
} from "components/chart/legend/utils/getPositionFromChartSettings";
import { createLegendItem } from "components/chart/utils";
import { useChartSettings } from "components/multiphase-chart/context";
import { getCurrentSeriesStyleByProductLabel } from "components/multiphase-chart/util/productStyles";

import ChartLegendItem from "./ChartLegendItem";

const { Text } = Typography;
const noWellsFoundText = "No wells were found";

export type ParentDimensionsT = { width: number; height: number };
export type LegendPositionT = { x: number; y: number };
export type LegendDimensionsT = { width: number; height: number };

export type LegendT = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ChartLegendT = {
  parentDimensions: ParentDimensionsT;
  id: string;
  className?: string;
  disableDragging?: boolean;
  width?: number;
};
export type Ref = HTMLDivElement;

const ChartLegend = ({
  parentDimensions = undefined,
  id,
  disableDragging = true,
  width = 200
}: ChartLegendT) => {
  const [chartSettings] = useChartSettings();
  const { screenshot, legend, bounds, data } = chartSettings;

  const title = "Products";
  const items = getLegendItems();

  function getLegendItems() {
    if (!data?.series) {
      return [];
    }

    const legendItems = data.series.reduce((items, series) => {
      const currentStyle = getCurrentSeriesStyleByProductLabel(
        series.label,
        chartSettings?.storedPreset?.style
      );

      const isForecast = series.label.indexOf("Forecast") > 0;

      if (!isForecast) {
        const legendItem = createLegendItem(series.label, true, currentStyle.color);
        items.push(legendItem);
      }

      return items;
    }, []);
    return legendItems;
  }
  // calculate height of list
  const legendFontSize = screenshot.visible
    ? Number(screenshot.preset?.legendFontSize) || 14
    : 14;

  const headingFontSizePx = `${legendFontSize + 2}px`;
  const itemSize = screenshot.visible ? legendFontSize + 12 : 30; // 30px for chart, 12px gap around when taking screenshot
  const itemsHeight = items.length * itemSize;
  const backgroundOpacity = screenshot.visible
    ? Number(screenshot.preset?.legendOpacity) ?? 1
    : 1;

  const showLegendCounts = screenshot.visible
    ? screenshot.preset?.legendShowCounts ?? true
    : true;
  const fontFamilyCss = screenshot.visible
    ? screenshot.preset?.fontFamily ?? "inherit"
    : "inherit";

  const positionStorageKey: string = `${id}-position`;
  const getStoredObj = (key: string) => JSON.parse(localStorage.getItem(key));

  // state
  const [dimensions, setDimensions] = useState<LegendDimensionsT>(() => {
    const dimensionsStorageKey = `${id}-dimensions`;
    const storedDimensions = getStoredObj(dimensionsStorageKey);

    return storedDimensions ? storedDimensions : { width, height: itemsHeight + 32 }; // 32px for title
  });

  const storedPosition = getStoredObj(positionStorageKey);

  const [position, setPosition] = useState<LegendPositionT>(() => {
    const currentPosition =
      !!storedPosition && !disableDragging
        ? storedPosition
        : getPositionFromChartSettings(
            dimensions,
            parentDimensions,
            legend.position as PositionsT
          );
    return currentPosition;
  });

  // markup: no wells found
  const noWellsMarkup = items.length ? null : (
    <StyledText type="danger">{noWellsFoundText}</StyledText>
  );

  // markup: react-window item
  const Row = ({ index, style }) => (
    <ChartLegendItem
      useCount={showLegendCounts}
      style={style}
      key={index}
      legend={items[index]}
    />
  );

  // handlers
  function handleDragStop(_, data) {
    const storageKey = `${id}-position`;
    const nextPosition = { x: data.x, y: data.y };
    setPosition(nextPosition);
    localStorage.setItem(storageKey, JSON.stringify(nextPosition));
  }

  function handleResize(e, direction, ref, delta, p) {
    const dimensionsStorageKey = `${id}-dimensions`;
    const positionStorageKey = `${id}-position`;

    const nextDimensions = {
      width: ref.offsetWidth,
      height: ref.offsetHeight
    };
    setDimensions(nextDimensions);
    setPosition(p);

    localStorage.setItem(dimensionsStorageKey, JSON.stringify(nextDimensions));
    localStorage.setItem(positionStorageKey, JSON.stringify(p));
  }

  // derived classes and styles
  const wrapperClassNames = classnames("chart-legend", legend.position);

  const currentLegend: LegendT = {
    ...position,
    ...dimensions
  };

  const calculatedLegend = getCalculatedLegend(currentLegend, parentDimensions);

  const currentPosition =
    !!storedPosition && !disableDragging
      ? { x: calculatedLegend.x, y: calculatedLegend.y }
      : getPositionFromChartSettings(
          { width: calculatedLegend.width, height: calculatedLegend.height },
          parentDimensions,
          legend.position as PositionsT
        );

  if (!bounds) return null;

  return (
    <StyledRnd
      bounds="parent"
      disableDragging={disableDragging}
      minWidth="140px"
      className={wrapperClassNames}
      onDragStop={handleDragStop}
      onResize={handleResize}
      position={{ x: currentPosition.x, y: currentPosition.y }}
      size={{ width: calculatedLegend.width, height: calculatedLegend.height }}
      backgroundopacity={backgroundOpacity}
      data-testid="multiphasechart-legend">
      <Wrapper style={{ fontFamily: fontFamilyCss }}>
        <StyledTitle style={{ fontSize: headingFontSizePx }}>{title}</StyledTitle>
        {noWellsMarkup}
        <List
          width="100%"
          height={itemsHeight}
          itemCount={items.length}
          itemSize={itemSize}>
          {Row}
        </List>
      </Wrapper>
    </StyledRnd>
  );
};

export default ChartLegend;
ChartLegend.displayName = "ChartLegend";

const StyledRnd = styled(Rnd)`
  position: absolute;
  margin: 10px;
  background: ${(props) =>
    props.backgroundopacity === undefined
      ? "White"
      : `rgba(255, 255, 255, ${props.backgroundopacity})`};
  border: 1px solid gray;
  overflow: hidden;
  z-index: 99999;
  min-height: 35px;
  height: 100px;

  & > div:last-child {
    position: unset;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  opacity: 1;
`;

const StyledTitle = styled.span`
  font-weight: 600;
  text-align: left;
  padding: 6px 12px;
  margin-bottom: 0 !important;
`;
const StyledText = styled(Text)`
  text-align: center;
  padding: 4px 16px;
`;
