import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { useUserSettingMutation } from "hooks";

import { USER_DOC_SETTING } from "components/user-settings/constants/userSetting.constants";
import { useUserContext } from "components/user/context";
import { updateSettingUserDoc } from "components/user/context/reducer";
import { useUserSettings } from "components/user/hooks";

import { updateTotalNewPosts, usePost } from "../context";
import useGetNewPostsCount from "./useGetNewPostsCount";

export interface UserDocSetting {
  id: string;
  userDocsLastVisited: string;
}

export default function useNewPostsCountManager() {
  const settings = useUserSettings();
  const [, userDispatch] = useUserContext();
  const userSettingMutate = useUserSettingMutation<UserDocSetting>(USER_DOC_SETTING);
  const [, dispatch] = usePost();
  const documentationOpen = useSelector(
    (state: RootState) => state.documentation.display
  );

  const userDocSettings = settings?.userDocSettings;

  const handleNewPostsCount = useCallback(
    (response) => {
      const count: number = Number.parseInt(response.data);
      if (!isNaN(count)) {
        updateTotalNewPosts(dispatch, count ?? 0);
      }
    },
    [dispatch]
  );

  const {
    isLoading,
    isError,
    error,
    mutate: getNewPostsCountMutate
  } = useGetNewPostsCount(handleNewPostsCount);

  const updateLastVisited = useCallback(() => {
    userSettingMutate.mutate({
      id: userDocSettings?.id,
      userDocsLastVisited: new Date().toISOString()
    });
  }, [userDocSettings?.id]);

  // Handle getting the last visited date from settings.
  useEffect(() => {
    if (userDocSettings === undefined) return;

    let since: Date = new Date(2023, 6, 12);
    const sinceNum = Date.parse(userDocSettings?.userDocsLastVisited);
    if (!isNaN(sinceNum)) {
      since = new Date(sinceNum);
    }
    getNewPostsCountMutate(since);
  }, [userDocSettings]);

  // Handle the notifications being viewed.
  useEffect(() => {
    if (documentationOpen) {
      updateTotalNewPosts(dispatch, 0);
      updateLastVisited();
    }
  }, [documentationOpen]);

  // Handle reload
  useEffect(() => {
    if (!userSettingMutate?.data?.id) return;

    updateSettingUserDoc(userDispatch, {
      id: userSettingMutate.data.id,
      userDocsLastVisited: userSettingMutate.data.userDocsLastVisited
    });
  }, [userSettingMutate?.data]);

  return {
    isLoading,
    isError,
    error
  };
}
