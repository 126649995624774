import { useContext } from "react";

import { DashboardContext } from "../contexts/DashboardContext";

export function useDashboardContext() {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error("useDashboardContext must be within a Dashboard provider");
  }
  return context;
}
