import { Icon } from "@mdi/react";

import { mdiFolderPlus } from "@mdi/js";
import { Tooltip } from "antd";
import styled from "styled-components";

import { useCreateProjectShapefileFolderMutation } from "components/project/shapefiles/mutations";

export default function SystemRootNode({ node }) {
  const createProjectFolderMutation = useCreateProjectShapefileFolderMutation({
    projectId: "system"
  });

  return (
    <NodeTitle className="visible-always">
      <FlexItem>
        <label>{node.title}</label>
      </FlexItem>
      <FlexItem>
        <Tooltip placement="top" title="Create New Folder">
          <ActionButton
            onClick={(evt) => {
              createProjectFolderMutation.mutate({
                parentId: "",
                order: node.children.length
              });
              evt.stopPropagation();
              evt.preventDefault();
            }}>
            <Icon path={mdiFolderPlus} size={1} />
          </ActionButton>
        </Tooltip>
      </FlexItem>
    </NodeTitle>
  );
}

//Styled Components

const ActionButton = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }

  &[disabled] > svg {
    color: lightgray;
  }
`;

const NodeTitle = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  align-items: center;
  justify-content: space-between;

  ${ActionButton} {
    svg {
      opacity: 0;
    }
  }

  border: ${(props) => (props.canDrop ? "1px dashed green" : "none")};

  &:hover,
  &.visible-always,
  &.in-edit-mode {
    svg {
      opacity: 1;
    }
  }
`;

const FlexItem = styled.div`
  display: inline-flex;
  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 24px;
  }
  .ant-btn:hover {
    color: var(--color-primary);
  }
  .ant-btn.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  .ant-btn.active:hover {
    background-color: #fff;
    color: var(--color-primary);
  }
`;
