import { useSelector } from "react-redux";

import { TYPE_WELLS } from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { WidgetOverlayMessage } from "components/base";
import ForecastDetails from "components/project/forecasts/ForecastDetails";
import { DEFAULT_DECLINE_TYPE_SETTING } from "components/user-settings/constants/userSetting.constants";
import { useUserSettings } from "components/user/hooks";

import { getTitle } from "../../utils/getTitle";

export function TypeWellDetailsWidget() {
  const { declineType: declineTypeSetting } = useUserSettings();
  const declineType = declineTypeSetting?.decline ?? DEFAULT_DECLINE_TYPE_SETTING;

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const hasTypeWellSaveSucceeded = useSelector(
    (state: RootState) => state.arps.hasTypeWellSaveSucceeded
  );

  const type = TYPE_WELLS;

  return selectedTypeWell?.id && !selectedTypeWell?.isFolder ? (
    <ForecastDetailsWrapper>
      <ForecastDetails
        arpsItem={selectedTypeWell}
        declineType={declineType}
        getTitle={getTitle}
        hasTypeWellSaved={hasTypeWellSaveSucceeded}
        type={type}
      />
    </ForecastDetailsWrapper>
  ) : (
    <WidgetOverlayMessage
      testId={"typewell-details-no-typewell-selected-label"}
      message={"Please select a typewell to edit"}
    />
  );
}

const ForecastDetailsWrapper = styled.div`
  padding: 10px;
  height: 100%;
  overflow-y: auto;
`;
