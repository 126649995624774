/* eslint-disable no-debugger */
import { useEffect, useState } from "react";

import { IGroupByListColumn } from "models";

import { useGroupByState } from "../group-by.context";
import {
  authorizeColumnsByForecast,
  filterByMetadata,
  filterByProduct,
  filterByText,
  filterByType
} from "../helpers";
import useSelectedCategory from "./useSelectedCategory";

/**
 * Returns a filtered list of columns after applying:
 * - data-type filter
 * - product filter
 * - text filter
 * - authorization (enabling disabling based on forecast toggle)
 */
const useColumns = (): IGroupByListColumn[] => {
  const [list, setList] = useState<IGroupByListColumn[] | null>(null);

  const {
    allColumns,
    categoryList,
    filterText,
    isFiltering,
    props,
    selectedCategory,
    selectedProduct,
    selectedMetadataFilters,
    isForecastToggleOn
  } = useGroupByState();

  const { hasProductFields } = useSelectedCategory();

  // We can consider simplifying the filters by applying them in a single function, where we apply each filter in a single pass
  // Will need to change the existing filter function to just accept one column, and return a boolean
  useEffect(() => {
    if (!categoryList?.length || (!selectedCategory && !isFiltering)) {
      // Allow search to work when there is no field selected
      setList([]);
      return;
    }

    const columns = isFiltering ? allColumns : selectedCategory.columns;

    const typeColumns = filterByType(
      props.dataTypeFilters,
      columns,
      props.chartType,
      props.axisType
    );

    const productColumns =
      selectedProduct?.length && hasProductFields && !isFiltering
        ? filterByProduct(selectedProduct, typeColumns)
        : typeColumns;

    const metadataColumns = !isFiltering
      ? filterByMetadata(selectedMetadataFilters, productColumns)
      : productColumns;

    // Consider that everything before this, will include the filters
    const filteredColumns = isFiltering
      ? filterByText(filterText, metadataColumns)
      : metadataColumns;

    const authorizedByForecastColumns =
      selectedCategory?.name === "Performance"
        ? authorizeColumnsByForecast(filteredColumns, isForecastToggleOn)
        : filteredColumns;

    setList(authorizedByForecastColumns);
  }, [
    props?.dataTypeFilters,
    props?.chartType,
    allColumns,
    hasProductFields,
    selectedCategory,
    selectedProduct,
    selectedMetadataFilters,
    filterText,
    isForecastToggleOn
  ]);

  return list;
};

export default useColumns;
