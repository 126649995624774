import styled from "styled-components";

import {
  ForecastChangeRecords,
  ForecastChangeRecordsModel
} from "components/project/forecasts/ForecastChangeRecords";

function TypeWellEditorChangeRecords({ forecastId }: ForecastChangeRecordsModel) {
  return (
    <ForecastChangeRecordsWrapper>
      <ForecastChangeRecords forecastId={forecastId} />
    </ForecastChangeRecordsWrapper>
  );
}

export default TypeWellEditorChangeRecords;

const ForecastChangeRecordsWrapper = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0px 0px 20px 20px;
  background: white;
`;
