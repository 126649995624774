import mapboxglx from "mapbox-gl";

// Consider map.on('styledata', function() { - if possible
// If using a ref, this requires mapbox.current
export const waitForStyleToLoad = async (
  mapbox: mapboxglx.Map,
  timeout: number
): Promise<boolean> => {
  if (!mapbox) return false;

  let count = 0;

  while (mapbox && !mapbox.isStyleLoaded() && count < 10) {
    await new Promise((r) => setTimeout(r, timeout));
    count++;
  }

  return mapbox.isStyleLoaded();
};
