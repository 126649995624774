import { WELL_LAYER } from "constants/mapLayers.constants";

export async function getXdaLineNearCenterOfMap(map: mapboxgl.Map) {
  if (!map) {
    return { line: null, wells: null };
  }
  const searchBuffer = 200; //in screen coords
  const center = map.getCenter();
  //project to screen coordaintes
  const coordinates = map.project([center.lng, center.lat]);
  const bbox = [
    [coordinates.x - searchBuffer, coordinates.y - searchBuffer],
    [coordinates.x + searchBuffer, coordinates.y + searchBuffer]
  ] as [mapboxgl.PointLike, mapboxgl.PointLike];
  if (!map.getLayer(WELL_LAYER)) {
    return { line: null, wells: null };
  }
  if (!map.getLayer(WELL_LAYER)) {
    return;
  }
  //find well near center
  const features = map.queryRenderedFeatures(bbox, {
    layers: [WELL_LAYER]
  });
  if (!features || features.length === 0) {
    return { line: null, wells: null };
  }
  const well = features[0];
  const geomCoords = (well.geometry as GeoJSON.LineString).coordinates;

  const heel = geomCoords[0];
  const toe = geomCoords[geomCoords.length - 1];
  const v = [toe[0] - heel[0], toe[1] - heel[1]];
  const vp = [-v[1], v[0]];

  const p = [(heel[0] + toe[0]) / 2, (heel[1] + toe[1]) / 2];
  const vp_l = { x: p[0] - vp[0], y: p[1] - vp[1] };
  const vp_r = { x: p[0] + vp[0], y: p[1] + vp[1] };

  //find all wells intercepting this line

  return { line: { start: vp_l, end: vp_r }, wells: [well.properties.Uwi] };
}
