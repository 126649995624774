import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { IconLogo, IconSpinner } from "components/icons";

function Loading({ redirect = false }) {
  const user = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();

  useEffect(() => {
    if (redirect) {
      const path = user ? "/app" : "/login";
      history.push(path);
    }
  }, [user, history, redirect]);

  return (
    <Wrapper>
      <IconLogo />
      <LoadingText>
        Loading <IconSpinner />
      </LoadingText>
    </Wrapper>
  );
}

export default memo(Loading);

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > svg {
    width: 200px;
    height: 100px;
  }
`;

const LoadingText = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;
