import { ChartLayout } from "models/chart";

/**
 * Finds the minimum log value in the series for given axis.
 *
 * @param {Array} series array of series objects from responseData
 * @param {string} axis axis for which to find the min-log10 value
 * @returns {number} minimum log10 value
 */
const getLogMinForAxis = (layout: ChartLayout): number => {
  return layout?.yAxis?.logMin;
};

export default getLogMinForAxis;
