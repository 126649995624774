import { memo } from "react";

interface RatioIconProps {
  numeratorIcon: JSX.Element;
  denominatorIcon: JSX.Element;
}
function RatioIcon({ numeratorIcon, denominatorIcon }: RatioIconProps) {
  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
      <g transform="scale(0.48)">{numeratorIcon}</g>
      <path
        d="M0 26.5666L27 0"
        transform="translate(2.179209 1.5388219)"
        id="Path-8-Copy-10"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2.025"
      />
      <g transform="scale(0.55) translate(23, 22)">{denominatorIcon}</g>
    </svg>
  );
}

export default memo(RatioIcon);
