import { memo } from "react";

function GasInjection() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
      <g id="IconsChart---PRPGas-Inj">
        <path
          d="M0 0L30 0L30 30L0 30L0 0Z"
          id="Rectangle-Copy-22"
          fill="none"
          fillRule="evenodd"
          stroke="none"
        />
        <g id="Gas-Inj" transform="translate(8.08412 0.83501226)">
          <path
            d="M13.8165 11.8429C13.5771 7.9784 6.80651 0 6.80651 0C5.27508 6.87459 -0.00611427 7.65789 5.31389e-06 12.1833C0.00383005 15.237 1.38839 17.0101 3.11564 17.9518C2.5687 16.1228 2.79053 14.0001 4.43288 12.4656C7.46513 9.63222 7.79865 7.72674 7.79865 7.72674C10.9357 13.2963 10.1662 16.796 8.98432 18.5607C11.8621 17.7659 14.036 15.3808 13.8165 11.8429"
            id="Fill-1-Copy"
            fill="currentColor"
            fillRule="evenodd"
            stroke="none"
            transform="translate(0, 2)"
          />
          <path
            d="M5.61892 0L5.61892 5.61892L0 5.61892"
            transform="matrix(0.70710665 0.7071069 -0.7071069 0.70710665 6.915881 19.649136)"
            id="Rectangle-Copy"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="2.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default memo(GasInjection);
