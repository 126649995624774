import { useCallback, useEffect, useRef, useState } from "react";

import * as _isEqual from "lodash/isEqual";

import { uid } from "../../utils";
import "./BaseDualThumbRangeSlider.scss";

const THUMB = Object.freeze({
  lower: "lower",
  upper: "upper"
});

// list of keyCodes used for keyboard shortcuts
export const key_code = Object.freeze({
  alt: 18,
  esc: 27,
  pageUp: 33,
  pageDown: 34,
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  p: 80,
  r: 82,
  z: 90
});
export default function BaseDualThumbRangeSlider({
  label = "",
  prefix = "",
  min = 0,
  value = [0, 0],
  max = 100,
  step = 1,
  onChange = null,
  suffix = null,
  disabled = false
}) {
  const [lowerValue, setLower] = useState(value[0]);
  const [upperValue, setUpper] = useState(value[1]);
  const [trackRect, setTrackRect] = useState(null);
  const trackRef = useRef(null);
  const rangeRef = useRef(null);
  const thumbLower = useRef(null);
  const thumbUpper = useRef(null);
  const thumbWidth = 24;

  const thumbLowerId = `thumb-lower-${uid()}`;
  const thumbUpperId = `thumb-upper-${uid()}`;
  const range = useCallback(() => {
    return Math.abs(max - min);
  }, [max, min]);

  const trackWidth = useCallback(() => {
    if (!trackRef) return;
    if (trackRect == null) {
      setTrackRect(trackRef.current.getBoundingClientRect());
    }
    const { width } = trackRef.current.getBoundingClientRect();
    const adjustedTrackWidth = width - thumbWidth;
    return adjustedTrackWidth;
  }, [trackRef, thumbWidth, trackRect]);
  const thumbLowerLeftPosition = useCallback(() => {
    if (min > 0) {
      return ((lowerValue - min) / range()) * trackWidth();
    }
    return (lowerValue / range()) * trackWidth();
  }, [lowerValue, min, range, trackWidth]);

  function roundedToStep(value) {
    return Math.round(value / step) * step;
  }
  function sanitizeValue(value, control) {
    let lowerValue = setBoundsForthumbLower(roundedToStep(value[0]));
    let upperValue = setBoundsForthumbUpper(roundedToStep(value[1]));
    const maxLowerValue = upperValue - step;
    const minUpperValue = lowerValue + step;

    if (control === THUMB.upper && lowerValue > maxLowerValue) {
      lowerValue = maxLowerValue;
    } else if (control === THUMB.lower && upperValue < minUpperValue) {
      upperValue = minUpperValue;
    }

    return [lowerValue, upperValue];
  }

  function setBoundsForthumbLower(value) {
    const upperMax = upperValue - step;

    if (value < min) {
      return min;
    } else if (value > upperMax) {
      return upperMax;
    } else {
      return value;
    }
  }

  function setBoundsForthumbUpper(value) {
    const lowerMin = lowerValue + step;

    if (value < lowerMin) {
      return lowerMin;
    } else if (value > max) {
      return max;
    } else {
      return value;
    }
  }

  function handleMouseDownThumbLower(event) {
    if (disabled) return;
    registerMouseMoveHandler(handleMouseMoveThumbLower);
    event.stopPropagation();
  }
  function handleMouseMoveThumbUpper(event) {
    setValue([lowerValue, actualXPosition(event.clientX)], THUMB.lower);
  }
  function handleMouseDownThumbUpper(event) {
    if (disabled) return;
    registerMouseMoveHandler(handleMouseMoveThumbUpper);
    event.stopPropagation();
  }
  function handleMouseMoveThumbLower(event) {
    setValue([actualXPosition(event.clientX), upperValue], THUMB.upper);
  }
  function setValue(value, control) {
    const sanitizedValue = sanitizeValue(value, control);
    if (!_isEqual(sanitizedValue, value) && sanitizedValue[0] && sanitizedValue[1]) {
      setLower(sanitizedValue[0]);
      setUpper(sanitizedValue[1]);
      onChange && onChange([sanitizedValue[0], sanitizedValue[1]]);
    }
  }
  function registerMouseMoveHandler(handler) {
    document.addEventListener("mousemove", handler);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handler);
      },
      { once: true }
    );
  }

  function incrementValueUpper() {
    setValue([lowerValue, upperValue + step], THUMB.lower);
  }

  function trackLeft() {
    const { left } = trackRect;
    const adjustedTrackLeft = left + thumbWidth / 2;
    return adjustedTrackLeft;
  }
  function actualXPosition(dirtyXPosition) {
    const relativeX = dirtyXPosition - trackLeft();
    const percentageOfTrack = relativeX / trackWidth();
    if (min > 0) {
      return percentageOfTrack * range() + min;
    }
    return percentageOfTrack * range();
  }

  useEffect(() => {
    function setProgress() {
      if (!rangeRef) return;
      rangeRef.current.style.setProperty(
        "--progress-lower",
        `${thumbLowerLeftPosition()}px`
      );
      rangeRef.current.style.setProperty(
        "--progress-upper",
        `${thumbUpperLeftPosition()}px`
      );
    }

    function thumbUpperLeftPosition() {
      if (min > 0) {
        return ((upperValue - min) / range()) * trackWidth();
      }
      return (upperValue / range()) * trackWidth();
    }
    function percentLower() {
      const percent = (thumbLowerLeftPosition() / trackWidth()) * 100;
      return percent.toFixed(2);
    }
    function percentUpper() {
      const percent = (thumbUpperLeftPosition() / trackWidth()) * 100;
      return percent.toFixed(2);
    }

    function setOutputFactor() {
      if (!rangeRef) {
        return;
      }
      const factorLower = parseFloat(percentLower()) / 100 - 0.5;
      const factorUpper = parseFloat(percentUpper()) / 100 - 0.5;

      rangeRef.current.style.setProperty("--output-factor-lower", `${factorLower}`);
      rangeRef.current.style.setProperty("--output-factor-upper", `${factorUpper}`);
    }
    setProgress();
    setOutputFactor();
  }, [min, range, trackWidth, thumbLowerLeftPosition, lowerValue, upperValue]);

  function handleMouseDownTrack(event) {
    if (disabled) return;
    event.preventDefault();

    const clickXPosition = actualXPosition(event.clientX);
    const distanceFromthumbLower = Math.abs(lowerValue - clickXPosition);
    const distanceFromthumbUpper = Math.abs(upperValue - clickXPosition);

    if (distanceFromthumbLower <= distanceFromthumbUpper) {
      setValue([clickXPosition, upperValue], THUMB.upper);
      registerMouseMoveHandler(handleMouseMoveThumbLower);
      thumbLower.current.focus();
    } else {
      setValue([lowerValue, clickXPosition], THUMB.lower);
      registerMouseMoveHandler(handleMouseMoveThumbUpper);
      thumbUpper.current.focus();
    }
  }

  return (
    <div ref={rangeRef} className="BaseDualThumbRangeSlider">
      {label && <span className="label">{label}</span>}

      {prefix && (
        <div className="prefix">
          <slot name="prefix">{prefix}</slot>
        </div>
      )}
      <div tabIndex={0} className="track-wrapper flex" onMouseDown={handleMouseDownTrack}>
        <div ref={trackRef} className="track" />

        <button
          id={thumbLowerId}
          ref={thumbLower}
          role="slider"
          className="thumb lower"
          disabled={disabled}
          aria-disabled={disabled}
          aria-valuemin={min}
          aria-valuemax={upperValue}
          aria-valuenow={lowerValue}
          aria-label={lowerValue.toString()}
          onMouseDown={handleMouseDownThumbLower}
        />
        <label className="output lower" htmlFor={thumbLowerId}>
          <span>{lowerValue}</span>
        </label>

        <button
          id={thumbUpperId}
          ref={thumbUpper}
          role="slider"
          className="thumb upper"
          disabled={disabled}
          aria-disabled={disabled}
          aria-valuemin={lowerValue}
          aria-valuemax={max}
          aria-valuenow={upperValue}
          aria-label={upperValue.toString()}
          onKeyDown={incrementValueUpper}
          onMouseDown={handleMouseDownThumbUpper}
        />

        <label className="output upper" htmlFor={thumbUpperId}>
          <span>{upperValue}</span>
        </label>
      </div>

      {suffix && <div className="suffix">{suffix}</div>}
    </div>
  );
}
