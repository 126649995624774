import { useCallback, useState } from "react";

import { IUser } from "models/User";

import "./Avatar.scss";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const placeholderImage = require("../../assets/placeholder-user.png");

const userServiceRoot = process.env.REACT_APP_USER_SERVICE + "/user";
export const appearanceOptions = Object.freeze({
  circle: "circle",
  rounded: "rounded",
  square: "square"
});

export const sizeOptions = Object.freeze({
  xsmall: "xsmall",
  small: "small",
  regular: "regular",
  medium: "medium",
  large: "large"
});

interface IAvatarProps {
  appearance?: string;
  size?: string;
  user: IUser;
  initials?: boolean;
}

function Avatar(props: IAvatarProps) {
  const picSrc =
    props.user &&
    props.user.profilePictureImageSrc &&
    props.user.profilePictureImageSrc.length > 0
      ? `${userServiceRoot}/${props.user.profilePictureImageSrc}`
      : null;
  const [focused, setFocused] = useState(false);
  const imageSrc = picSrc || placeholderImage.default;
  const getInitials = useCallback(() => {
    if (!props.user) {
      // eslint-disable-next-line no-console
      console.warn("Please provide 'user' attribute to display intials");
      return;
    }
    try {
      const { firstName, lastName } = props.user;
      let initials = "";

      if (firstName && firstName.length > 0 && lastName && lastName.length > 0) {
        initials = firstName[0] + lastName[0];
      }

      return initials.toUpperCase();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [props.user]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={`avatar flex flex-centered ${props.appearance} ${props.size} ${
        focused ? "has-focus" : ""
      }`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}>
      {!picSrc ? (
        <div className="avatar-initials flex flex-centered fill">
          <span>{getInitials()}</span>
        </div>
      ) : (
        <img
          className="absolute fill inline-block"
          src={imageSrc}
          alt={props.user ? props.user.firstName : "avatar"}
        />
      )}
    </div>
  );
}

export default Avatar;
