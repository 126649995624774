export const getUnit = (title) => {
  if (title.toLowerCase() === "well count") {
    return "";
  }
  const splittedUnit = title.split(" ").splice(-1)[0];
  const unit = splittedUnit.substr(1, splittedUnit.length - 2);
  return unit;
};

export const getPrecision = (unit) => {
  if (!unit) return;

  if (unit === "bbl/d" || unit === "Mcf/d" || unit === "bbl/MMcf" || unit === "scf/bbl") {
    return 0;
  } else if (unit === "%") {
    return 2;
  } else if (
    unit === "Mbbl/d" ||
    unit === "MMcf/d" ||
    unit === "bbl/bbl" ||
    unit === "Mbbl" ||
    unit === "MMcf"
  ) {
    return 3;
  } else if (unit === "MMbbl/d" || unit === "Bcf/d" || unit === "Bcf") {
    return 6;
  } else {
    return 4;
  }
};
